import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetSELDeptCurExport } from 'src/app/_Models/AD/ADSEL/ADSEL0201/getSELDeptCurExport';
import { GetClassList } from 'src/app/_Models/getClassList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSEL0201',
    templateUrl: './ADSEL0201.component.html',
    styleUrls: ['./ADSEL0201.component.css']
})
export class ADSEL0201Component implements OnInit {
    baseUrl = environment.apiUrl;
    modal = {
        Sel_Years: '',
        Sel_Term: ''
    }
    Status = '1';
    sELDeptCurExport!: GetSELDeptCurExport[];
    dataKeyArray: Array<string> = [];
    classList!: GetClassList[];
    constructor(private http: HttpClient, private toastr: ToastrService, private timeService: TimeService, private systemService: SystemService) { }

    ngOnInit(): void {
        this.getTimeCntr();

    }

    getTimeCntr() {
        this.timeService.getTimeCntr().subscribe(response => {
            this.modal.Sel_Years = response.Sel_Years;
            this.modal.Sel_Term = response.Sel_Term
            this.getClassList();
        });
    }

    getClassList() {
        this.systemService.getClassList('', '', '1', this.modal.Sel_Years, this.modal.Sel_Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error));
    }


    setDatakey(type: string, dataKey: string, event: any) {
        if (!this.classList) {
            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.classList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.classList.forEach(element => {
                    this.dataKeyArray.push(element.Class);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.classList.forEach(element => {
                    element.checked = false;
                });
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

    getSELDeptCurList() {

        if (this.dataKeyArray.length == 0) {
            this.toastr.info("尚未選取班級");
            return;
        }

        let dataKey = '';
        this.dataKeyArray.forEach(element => {
            dataKey = dataKey + element + ',';
        });

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0201/Get_SEL_DeptCur_List", {
            Years: this.modal.Sel_Years,
            Term: this.modal.Sel_Term,
            ClassList: dataKey.substring(0, dataKey.length - 1)
        }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sELDeptCurExport = []
                return;
            }
            this.sELDeptCurExport = response;
        });
    }

    getSELDeptCurExport() {

        if (this.dataKeyArray.length == 0) {
            this.toastr.info("尚未選取班級");
            return;
        }

        Swal.fire({
            title: `確定會出${this.dataKeyArray.length}個班級？`,
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {

            if (result.isConfirmed) {
                let dataKey = '';
                this.dataKeyArray.forEach(element => {
                    dataKey = dataKey + element + ',';
                });

                this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0201/Get_SEL_DeptCur_Export", {
                    Years: this.modal.Sel_Years,
                    Term: this.modal.Sel_Term,
                    Status: this.Status,
                    ClassList: dataKey.substring(0, dataKey.length - 1)
                }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.info(response.Message);
                        return;
                    }
                    this.toastr.success("匯出成功");
                });
            }
            else {
                this.toastr.info("動作已取消");
            }
        });
    }

}
