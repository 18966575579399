<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">欄位名稱</span>
                </div>
                <select class="form-select shadow-none" (change)="search()" [(ngModel)]="Con_Column_No">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor=" let item of dropDownListOtherCode" [value]="item.ItemValue">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-12">
            <button (click)="clearData()" class="other-Button-gray float-end">清除</button>
            <button (click)="deleteData()" *ngIf="IsEdit" class="other-Button-gray float-end me-3">刪除</button>
            <button (click)="updateData()" *ngIf="IsEdit" class="other-Button-gray float-end me-3">修改</button>
            <button (click)="insertData()" *ngIf="!IsEdit" class="add-btn float-end me-3">新增</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6" style="max-height: 400px; overflow-y: auto;">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>欄位名稱</th>
                        <th>其他代碼</th>
                        <th>代碼名稱</th>
                    </tr>
                </thead>
                <tbody class="rtable1">
                    <tr *ngFor="let item of searchDataList; let i=index" class="pointer" [ngClass]="{'':item.selected == false, 'row-selected-bg':item.selected == true}" (click)="searchDetail(i)" ng-model="all">
                        <td>{{i+1}}</td>
                        <td>{{item.Column_Name}}</td>
                        <td>{{item.Item_No}}</td>
                        <td>{{item.Item_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <form [formGroup]="searchData">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">欄位名稱</span>
                            </div>
                            <select formControlName="Column_No" class="form-select shadow-none" [attr.disabled]="IsEdit ? '' : null">
                                <option *ngFor=" let item of dropDownListOtherCode" [value]="item.ItemValue">
                                    {{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>    
                <div class="row">    
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">其他代碼</span>
                            <input formControlName="Item_No" type="text" class="form-control shadow-none" [readonly]="IsEdit">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">代碼名稱</span>
                            <input formControlName="Item_Name" type="text" class="form-control" class="form-control shadow-none">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">英文名稱</span>
                            <input formControlName="Item_EngName" type="text" class="form-control" class="form-control shadow-none">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="input-group mb-3">
                            <span class="input-group-text">對應值一</span>
                            <input formControlName="Key1" type="text" class="form-control" class="form-control shadow-none">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group mb-3">
                            <span class="input-group-text">對應值二</span>
                            <input formControlName="Key2" type="text" class="form-control" class="form-control shadow-none">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group mb-3">
                            <span class="input-group-text">對應值三</span>
                            <input formControlName="Key3" type="text" class="form-control" class="form-control shadow-none">
                        </div>
                    </div>
                </div>                                
            </form>
        </div>
    </div>
</div>
