<div class="container">
    <app-year-term-input (childEvent)="getCosTest($event)"></app-year-term-input>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>{{'開課班級'|translate}}</th>
                <th>{{'開課序號'|translate}}</th>
                <th>{{'科目'|translate}}</th>
                <th>{{'操作'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cosTestList">
                <td>{{item.ClassName}}</td>
                <td>{{item.Serial}}</td>
                <td>{{item.CosName}}</td>
                <td><a class="pointer text-decoration-none" (click)="openModal(template, item.DataKey)"><span
                            class="icon-Homework-correction"></span>{{'批改'|translate}}</a></td>
            </tr>
        </tbody>
    </table>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'作業批改'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'作業清單'|translate}}</span>
                    </div>
                    <select class="form-select shadow-none" #homework>
                        <option *ngFor="let item of testList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <button class="search-btn" (click)="getStdHomeworkList(homework.value)">{{'查詢'|translate}}</button>
            </div>
        </div>
        <div>

        </div>

        <hr>
        <button class="modify-btn mb-3" [hidden]="!btnHidden" (click)="editData()" *ngIf="stdTestList">{{'編輯'|translate}}</button>
        <button class="save-btn-gray mb-3" [hidden]="btnHidden" (click)="saveData()" *ngIf="stdTestList">{{'儲存'|translate}}</button>
        <table class="table table-hover table-striped text-center" id="stdHomework">
            <thead class="thead-dark-red">
                <tr>
                    <th>{{'學號'|translate}}</th>
                    <th>{{'姓名'|translate}}</th>
                    <th>{{'組別'|translate}}</th>
                    <th>{{'分數'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of stdTestList">
                    <td>{{item.StdNo}}</td>
                    <td>{{item.StdName}}</td>
                    <td>{{item.TeamNo}}</td>
                    <td>
                        <ng-container *ngIf="item.SendHomeWork == 'Y'">
                            <input type="number" min="0" max="100" [defaultValue]="0" class="form-control text-center"
                                [disabled]="btnHidden" [(ngModel)]="item.Score">
                        </ng-container>
                        <ng-container *ngIf="item.SendHomeWork != 'Y'">
                            <span class="text-danger fw-bolder">{{'尚未繳交作業'|translate}}</span>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>