import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADCUR0403',
  templateUrl: './ADCUR0403.component.html',
  styleUrls: ['./ADCUR0403.component.css']
})
export class ADCUR0403Component implements OnInit {
  baseUrl = environment.apiUrl;
  Years!: Array<string>;
  initialData = new InitialData(this.http);
  eduList!: GetEduList[];
  deptList!: GetDeptList[];
  EduNoData = {
    EduNo: ''
  };
  constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService) { }

  ngOnInit(): void {
  }

}
