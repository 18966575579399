<div class="container" style="margin-bottom: 100px;">
  <ul class="list-group list-group-horizontal text-center mb-3">
    <li class="list-group-item list-group-item-action list-group-item-secondary">{{'尚未修課' | translate}}</li>
    <li class="list-group-item list-group-item-action list-group-item-danger">{{'學分未過' | translate}}</li>
    <li class="list-group-item list-group-item-action list-group-item-warning">{{'正在修課' | translate}}</li>
    <li class="list-group-item list-group-item-action list-group-item-success">{{'學分已過' | translate}}</li>
  </ul>
  <tabset #staticTabs>
    <tab *ngFor="let name of itemName" heading={{name}}>
      <div class="row">
        <ng-container *ngFor="let semester of semesters; let i = index">
          <div class="col-12 col-md-3">
            <div class="Semester">{{semester}}</div>
            <ng-container *ngFor="let item of stdInfo">
              <div class="list-group" *ngFor="let CurMap of item.CurMap">
                <ng-container *ngFor="let CosData of CurMap.CosData;">
                  <div *ngIf="name == CurMap.Item_Name && parseInt(CosData.Grade) == (i+1)">
                    <a class="list-group-item list-group-item-action list-group-item-secondary"
                      *ngIf="CosData.Status == '0'">{{CosData.Cos_Name +'(' + CosData.Cos_Hours + '/' +
                      CosData.Cos_Credit + ')'}}</a>
                    <a class="list-group-item list-group-item-action list-group-item-danger"
                      *ngIf="CosData.Status == '1'">{{CosData.Cos_Name +'(' + CosData.Cos_Hours + '/' +
                      CosData.Cos_Credit + ')'}}</a>
                    <a class="list-group-item list-group-item-action list-group-item-warning"
                      *ngIf="CosData.Status == '2'">{{CosData.Cos_Name +'(' + CosData.Cos_Hours + '/' +
                      CosData.Cos_Credit + ')'}}</a>
                    <a class="list-group-item list-group-item-action list-group-item-success"
                      *ngIf="CosData.Status == '3'">{{CosData.Cos_Name +'(' + CosData.Cos_Hours + '/' +
                      CosData.Cos_Credit + ')'}}</a>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </tab>
  </tabset>
</div>

<ng-template #nothing>
  <a class="list-group-item list-group-item-action list-group-item-light">{{'尚未有資料' | translate}}</a>
</ng-template>