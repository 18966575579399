<div class="container">
    <div class="row">
        <div class="col-md">
            <button class="other-Button-gray float-end" (click)="getSTDDelete()">
                {{'刪除' | translate}}
            </button>
            <button class="other-Button-gray float-end me-3" (click)="openDetail(template, '', '', '')">
                {{'申請' | translate}}
            </button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3 table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'刪除' | translate}}</th>
                <th>{{'申請學年' | translate}}</th>
                <th>{{'申請學期' | translate}}</th>
                <th>{{'申請項目' | translate}}</th>
                <th>{{'申請日期' | translate}}</th>
                <th>{{'編輯' | translate}}</th>
                <th>{{'審核狀態' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sTDOutList">
                <td data-th="刪除">
                    <input type="checkbox" class="pointer" *ngIf="item.Status == '0' || item.Status == '4'"
                        [(ngModel)]="item.Checked">
                </td>
                <td data-th="申請學年">{{item.Years}}</td>
                <td data-th="申請學期">{{item.Term}}</td>
                <td data-th="申請項目">{{item.ApplyName}}</td>
                <td data-th="申請日期">{{item.ApplyDate}}</td>
                <td data-th="編輯">
                    <a class="pointer text-decoration-none"
                        (click)="openDetail(template, item.DataKey, item.StdNo, item.SerialNo)">
                        <span class="icon-Fill-in-button"></span>{{'編輯' | translate}}
                    </a>
                </td>
                <td data-th="審核狀態">
                    <ng-container *ngIf="item.Status != '0' else other">
                        <a (click)="openFlow(flowTemplate, item.DataKey)" class="pointer text-decoration-none">
                            {{item.StatusName}}
                        </a>
                    </ng-container>
                    <ng-template #other>{{item.StatusName}}</ng-template>
                </td>
                <td class="td-rwd-hide"></td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'退學申請' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6"></div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'申請項目' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.ApplyName">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'班級' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.ClassName">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學號' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.StdNo">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'姓名' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.StdName">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'身分證號碼' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.IDNo">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'出生日期' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.Birthday">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'性別' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.Sex">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'身分別' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.EntryName">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'手機號碼' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.MobilePhone">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'住家電話號碼' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.ComTel">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Email</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.EMail">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'通訊地址' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.ComAddress">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"> {{'已修業學期' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.TermCount">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'申請日期' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.ApplyDate">
                </div>
                <small class="text-danger fw-bolder">{{'*此日期為送出休學申請當日，若只有儲存沒有送出則無申請日。' | translate}}
                </small>
            </div>
        </div>
        <hr>
        <label class="text-primary fw-bolder">{{'退學學年期：' | translate}}</label>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學年' | translate}}</span>
                    </div>
                    <!-- <input type="text" class="form-control shadow-none" required [(ngModel)]="sTDOutDetail.Years"> -->
                    <select class="form-select shadow-none" #Years required (change)="initTerm($event)" [(ngModel)]="sTDOutDetail.Years">
                        <option value="{{item}}" *ngFor="let item of Years_Range">{{item}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學期' | translate}}</span>
                    </div>
                    <select class="form-select shadow-none" required [(ngModel)]="sTDOutDetail.Term">
                        <option value="{{item.ItemValue}}" *ngFor="let item of term">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'退學原因' | translate}}</span>
                    </div>
                    <select class="form-select shadow-none" required [(ngModel)]="sTDOutDetail.OutNo">
                        <option value="" disabled>{{'--請選擇--' | translate}}</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of outReasonList">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="text-primary fw-bolder label me-3">
                    {{'退學證明文件' | translate}}：{{sTDOutDetail.OutPaper}}
                </label>
                <button class="other-Button-gray upload" (click)="importInputCkick('OutPaper')"
                    [hidden]="sTDOutDetail.OutPaper != ''">
                    {{'上傳' | translate}}
                </button>
                <button class="other-Button-gray upload" (click)="clearfile('OutPaper')"
                    [hidden]="sTDOutDetail.OutPaper == ''">{{'清除' | translate}}</button>
                <input type="file" accept=".docx" style="display: none;" id="OutPaper" value=""
                    (change)="importData($event, '', 'OutPaper')">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <label class="text-primary fw-bolder label me-3" (click)="importInputCkick('AgreePaper')">
                    {{'家長同意書' | translate}}：{{sTDOutDetail.AgreePaper}}
                </label>
                <button class="other-Button-gray upload" (click)="importInputCkick('AgreePaper')"
                    [hidden]="sTDOutDetail.AgreePaper != ''">
                    {{'上傳' | translate}}
                </button>
                <button class="other-Button-gray upload" (click)="clearfile('AgreePaper')"
                    [hidden]="sTDOutDetail.AgreePaper == ''">
                    {{'清除' | translate}}
                </button>
                <input type="file" accept=".docx" style="display: none;" id="AgreePaper" value=""
                    (change)="importData($event, '', 'AgreePaper')">
                <a class="pointer text-decoration-none float-end"><span class="icon-Downlad-button"></span>
                    {{'家長同意書下載' | translate}}
                </a>
                <br>
                <small class="text-danger fw-bolder">{{'*大學部學生必須上傳家長證明書，否則無法送出申請。' | translate}}</small>
            </div>
        </div>
        <hr>
        <div class="row" *ngIf="sTDOutDetail.Status!=''">
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'是否核准' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.Approve">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'核准日期' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDOutDetail.ApproveDate">
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <button class="send-btn float-start me-3" *ngIf="showButton" (click)="getSTDOutSave('send')">
                    {{'送出' | translate}}
                </button>
                <button class="other-Button-gray float-start me-3" *ngIf="showButton" (click)="getSTDOutSave('save')">
                    {{'儲存' | translate}}
                </button>
                <button class="other-Button-gray float-start me-3" (click)="modalRef.hide()">
                    {{'關閉' | translate}}
                </button>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #flowTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'簽核流程' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <app-std-sign-off [inputstd]="eDocFlow"></app-std-sign-off>
    </div>
</ng-template>