import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ADCUP0506',
    templateUrl: './ADCUP0506.component.html',
    styleUrls: ['./ADCUP0506.component.css']
})
export class ADCUP0506Component implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
