import { ADSYS0501Component } from './ADWEB/ADSYS/ADSYS0501/ADSYS0501.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BootstrapModule } from './_Modules/bootstrap/bootstrap.module';
import { ThirdPartModule } from './_Modules/third-part/third-part.module';
import { ErrorModule } from './_Modules/error/error.module';
import { DatePipe } from '@angular/common'
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';

// interceptor
import { ErrorInterceptor } from './_Interceptors/error.interceptor';
import { JwtInterceptor } from './_Interceptors/jwt.interceptor';

// componenet
import { AppComponent } from './app.component';
import { HomeComponent } from './Portal/home/home.component';
import { NavbarComponent } from './_SharedComponont/navbar/navbar.component';
import { FooterComponent } from './_SharedComponont/footer/footer.component';
import { LocationComponent } from './_SharedComponont/location/location.component';
import { LoadingInterceptor } from './_Interceptors/loading.interceptor';
import { CurClassComponent } from './_SharedComponont/cur-class/cur-class.component';
import { StdSignOffComponent } from './_SharedComponont/std-sign-off/std-sign-off.component';
import { CurClassRwdComponent } from './_SharedComponont/cur-class-rwd/cur-class-rwd.component';
import { YearTermInputComponent } from './_SharedComponont/year-term-input/year-term-input.component';
import { FunctionSearchComponent } from './_SharedComponont/function-search/function-search.component';
import { ContrastComponent } from './_SharedComponont/Contrast/Contrast.component';
import { SearchTeacherComponent } from './_SharedComponont/SearchTeacher/SearchTeacher.component';
import { GrdScoreInputComponent } from './_SharedComponont/GrdScoreInput/GrdScoreInput.component';
import { LoginComponent } from './Portal/login/login.component';
import { FavouriteComponent } from './Portal/favourite/favourite.component';

import { STDW00001Component } from './STDWEB/STDW00001/STDW00001.component';
import { STDW00002Component } from './STDWEB/STDW00002/STDW00002.component';
import { STDW00003Component } from './STDWEB/STDW00003/STDW00003.component';
import { STDW10001Component } from './STDWEB/STDW10001/STDW10001.component';
import { STDW10002Component } from './STDWEB/STDW10002/STDW10002.component';
import { STDW10003Component } from './STDWEB/STDW10003/STDW10003.component';
import { STDW10004Component } from './STDWEB/STDW10004/STDW10004.component';
import { STDW10005Component } from './STDWEB/STDW10005/STDW10005.component';
import { STDW10006Component } from './STDWEB/STDW10006/STDW10006.component';
import { STDW10013ISUComponent } from './STDWEB/STDW10013ISU/STDW10013ISU.component';
import { STDW20001Component } from './STDWEB/STDW20001/STDW20001.component';
import { STDW20002Component } from './STDWEB/STDW20002/STDW20002.component';
import { STDW20003Component } from './STDWEB/STDW20003/STDW20003.component';
import { STDW20004Component } from './STDWEB/STDW20004/STDW20004.component';
import { STDW2000607Component } from './STDWEB/STDW2000607/STDW2000607.component';
import { STDW30001Component } from './STDWEB/STDW30001/STDW30001.component';
import { STDW30002Component } from './STDWEB/STDW30002/STDW30002.component';
import { STDW30003Component } from './STDWEB/STDW30003/STDW30003.component';
import { STDW30004Component } from './STDWEB/STDW30004/STDW30004.component';
import { STDW30005Component } from './STDWEB/STDW30005/STDW30005.component';
import { STDW30006Component } from './STDWEB/STDW30006/STDW30006.component';
import { STDW30007Component } from './STDWEB/STDW30007/STDW30007.component';
import { STDW30008Component } from './STDWEB/STDW30008/STDW30008.component';
import { STDW30009Component } from './STDWEB/STDW30009/STDW30009.component';
import { STDW30010Component } from './STDWEB/STDW30010/STDW30010.component';
import { STDW40001Component } from './STDWEB/STDW40001/STDW40001.component';
import { STDW40002Component } from './STDWEB/STDW40002/STDW40002.component';
import { STDW40003Component } from './STDWEB/STDW40003/STDW40003.component';
import { STDW40004Component } from './STDWEB/STDW40004/STDW40004.component';
import { STDW40005Component } from './STDWEB/STDW40005/STDW40005.component';
import { STDW40006Component } from './STDWEB/STDW40006/STDW40006.component';
import { STDW50001Component } from './STDWEB/STDW50001/STDW50001.component';
import { STDW50002Component } from './STDWEB/STDW50002/STDW50002.component';
import { STDW50003Component } from './STDWEB/STDW50003/STDW50003.component';
import { STDW50004Component } from './STDWEB/STDW50004/STDW50004.component';
import { STDW60001Component } from './STDWEB/STDW60001/STDW60001.component';
import { STDW60002Component } from './STDWEB/STDW60002/STDW60002.component';
import { STDW60003Component } from './STDWEB/STDW60003/STDW60003.component';
import { STDW70001Component } from './STDWEB/STDW70001/STDW70001.component';
import { STDW10009Component } from './STDWEB/STDW10009/STDW10009.component';
import { STDW10010Component } from './STDWEB/STDW10010/STDW10010.component';
import { STDW10011Component } from './STDWEB/STDW10011/STDW10011.component';


import { TCH0114Component } from './TCHWEB/TCH0114/TCH0114.component';
import { TCH0101Component } from './TCHWEB/TCH0101/TCH0101.component';
import { TCH0102Component } from './TCHWEB/TCH0102/TCH0102.component';
import { TCH0103Component } from './TCHWEB/TCH0103/TCH0103.component';
import { TCH0104Component } from './TCHWEB/TCH0104/TCH0104.component';
import { TCH0105Component } from './TCHWEB/TCH0105/TCH0105.component';
import { TCH0106Component } from './TCHWEB/TCH0106/TCH0106.component';
import { TCH0107Component } from './TCHWEB/TCH0107/TCH0107.component';
import { TCH0108Component } from './TCHWEB/TCH0108/TCH0108.component';
import { TCH0109Component } from './TCHWEB/TCH0109/TCH0109.component';
import { TCH0110Component } from './TCHWEB/TCH0110/TCH0110.component';
import { TCH0111Component } from './TCHWEB/TCH0111/TCH0111.component';
import { TCH0112Component } from './TCHWEB/TCH0112/TCH0112.component';
import { TCH0113Component } from './TCHWEB/TCH0113/TCH0113.component';
import { TCH0115Component } from './TCHWEB/TCH0115/TCH0115.component';
import { TCH0116Component } from './TCHWEB/TCH0116/TCH0116.component';
import { TCH0117Component } from './TCHWEB/TCH0117/TCH0117.component';
import { TCH0118Component } from './TCHWEB/TCH0118/TCH0118.component';
import { TCH0201Component } from './TCHWEB/TCH0201/TCH0201.component';
import { TCH0202Component } from './TCHWEB/TCH0202/TCH0202.component';
import { TCH0203Component } from './TCHWEB/TCH0203/TCH0203.component';
import { TCH0204Component } from './TCHWEB/TCH0204/TCH0204.component';
import { TCH0301Component } from './TCHWEB/TCH0301/TCH0301.component';
import { TCH0302Component } from './TCHWEB/TCH0302/TCH0302.component';
import { TCH0303Component } from './TCHWEB/TCH0303/TCH0303.component';
import { TCH0304Component } from './TCHWEB/TCH0304/TCH0304.component';
import { TCH0305Component } from './TCHWEB/TCH0305/TCH0305.component';
import { TCH0306Component } from './TCHWEB/TCh0306/TCh0306.component';
import { TCH0307Component } from './TCHWEB/TCH0307/TCH0307.component';
import { TCH0308Component } from './TCHWEB/TCH0308/TCH0308.component';
import { TCH0309Component } from './TCHWEB/TCH0309/TCH0309.component';
import { TCH0310Component } from './TCHWEB/TCH0310/TCH0310.component';
import { TCH0401Component } from './TCHWEB/TCH0401/TCH0401.component';
import { TCH0402Component } from './TCHWEB/TCH0402/TCH0402.component';
import { TCH0101DetailComponent } from './TCHWEB/TCH0101-detail/TCH0101-detail.component';
import { TCH0111DetailComponent } from './TCHWEB/TCH0111-detail/TCH0111-detail.component';
import { ProfileComponent } from './Portal/profile/profile.component';


import { ADFunctionListComponent } from './_SharedComponont/ADFunctionList/ADFunctionList.component';

import { ADASM0101Component } from './ADWEB/ADASM/ADASM0101/ADASM0101.component';
import { ADASM0102Component } from './ADWEB/ADASM/ADASM0102/ADASM0102.component';
import { ADASM0103Component } from './ADWEB/ADASM/ADASM0103/ADASM0103.component';
import { ADASM0104Component } from './ADWEB/ADASM/ADASM0104/ADASM0104.component';
import { ADASM0105Component } from './ADWEB/ADASM/ADASM0105/ADASM0105.component';

import { ADTFS01A03Component } from './ADWEB/ADTFS/ADTFS01A03/ADTFS01A03.component';
import { ADTFS01B02Component } from './ADWEB/ADTFS/ADTFS01B02/ADTFS01B02.component';
import { ADTFS01B01Component } from './ADWEB/ADTFS/ADTFS01B01/ADTFS01B01component';
import { ADTFS01B03Component } from './ADWEB/ADTFS/ADTFS01B03/ADTFS01B03.component';
import { ADTFS01B04Component } from './ADWEB/ADTFS/ADTFS01B04/ADTFS01B04.component';
import { ADTFS01C01Component } from './ADWEB/ADTFS/ADTFS01C01/ADTFS01C01.component';
import { ADTFS01D01Component } from './ADWEB/ADTFS/ADTFS01D01/ADTFS01D01.component';
import { ADTFS01D02Component } from './ADWEB/ADTFS/ADTFS01D02/ADTFS01D02.component';
import { ADTFS01D03Component } from './ADWEB/ADTFS/ADTFS01D03/ADTFS01D03.component';
import { ADTFS0301Component } from './ADWEB/ADTFS/ADTFS0301/ADTFS0301.component';
import { ADTFS0302Component } from './ADWEB/ADTFS/ADTFS0302/ADTFS0302.component';
import { ADTFS0502Component } from './ADWEB/ADTFS/ADTFS0502/ADTFS0502.component';
import { ADTFS0501Component } from './ADWEB/ADTFS/ADTFS0501/ADTFS0501.component';
import { ADTFS0601Component } from './ADWEB/ADTFS/ADTFS0601/ADTFS0601.component';
import { ADTFS0602Component } from './ADWEB/ADTFS/ADTFS0602/ADTFS0602.component';
import { ADTFS01E03Component } from './ADWEB/ADTFS/ADTFS01E03/ADTFS01E03.component';
import { ADTFS01E02Component } from './ADWEB/ADTFS/ADTFS01E02/ADTFS01E02.component';
import { ADTFS01E01Component } from './ADWEB/ADTFS/ADTFS01E01/ADTFS01E01.component';
import { ADTFS0303Component } from './ADWEB/ADTFS/ADTFS0303/ADTFS0303.component';
import { ADTFS0701Component } from './ADWEB/ADTFS/ADTFS0701/ADTFS0701.component';
import { ADTFS0702Component } from './ADWEB/ADTFS/ADTFS0702/ADTFS0702.component';
import { ADTFS0401Component } from './ADWEB/ADTFS/ADTFS0401/ADTFS0401.component';
import { ADTFS01A04Component } from './ADWEB/ADTFS/ADTFS01A04/ADTFS01A04.component';



import { TextareaAutoresizeDirective } from './_Directives/textarea-autoresize.directive';

import { ADPUB0101Component } from './ADWEB/ADPUB/ADPUB0101/ADPUB0101.component';
import { ADPUB0102Component } from './ADWEB/ADPUB/ADPUB0102/ADPUB0102.component';
import { ADPUB0103Component } from './ADWEB/ADPUB/ADPUB0103/ADPUB0103.component';

import { ADTFS0603Component } from './ADWEB/ADTFS/ADTFS0603/ADTFS0603.component';
import { ADTFS0503Component } from './ADWEB/ADTFS/ADTFS0503/ADTFS0503.component';

import { ADSTD0101Component } from './ADWEB/ADSTD/ADSTD0101/ADSTD0101.component';
import { ADSTD0101ISUComponent } from './ADWEB/ADSTD/ADSTD0101ISU/ADSTD0101ISU.component';
import { ADSTD0102Component } from './ADWEB/ADSTD/ADSTD0102/ADSTD0102.component';
import { ADSTD0103Component } from './ADWEB/ADSTD/ADSTD0103/ADSTD0103.component';
import { ADSTD0104Component } from './ADWEB/ADSTD/ADSTD0104/ADSTD0104.component';
import { ADSTD0106Component } from './ADWEB/ADSTD/ADSTD0106/ADSTD0106.component';
import { ADSTD0201Component } from './ADWEB/ADSTD/ADSTD0201/ADSTD0201.component';
import { ADSTD0202Component } from './ADWEB/ADSTD/ADSTD0202/ADSTD0202.component';
import { ADSTD0203Component } from './ADWEB/ADSTD/ADSTD0203/ADSTD0203.component';
import { ADSTD0204Component } from './ADWEB/ADSTD/ADSTD0204/ADSTD0204.component';
import { ADSTD0205Component } from './ADWEB/ADSTD/ADSTD0205/ADSTD0205.component';
import { ADSTD0206Component } from './ADWEB/ADSTD/ADSTD0206/ADSTD0206.component';


import { ADSTDSearchInputComponent } from './_SharedComponont/ADSTD-search-input/ADSTD-search-input.component';

import { ADSTD0301Component } from './ADWEB/ADSTD/ADSTD0301/ADSTD0301.component';
import { ADSTD0302Component } from './ADWEB/ADSTD/ADSTD0302/ADSTD0302.component';
import { ADSTD0303Component } from './ADWEB/ADSTD/ADSTD0303/ADSTD0303.component';
import { ADSTD0304Component } from './ADWEB/ADSTD/ADSTD0304/ADSTD0304.component';
import { ADSTD0305Component } from './ADWEB/ADSTD/ADSTD0305/ADSTD0305.component';
import { ADSTD0401Component } from './ADWEB/ADSTD/ADSTD0401/ADSTD0401.component';
import { ADSTD0401ISU01Component } from './ADWEB/ADSTD/ADSTD0401ISU01/ADSTD0401ISU01.component';

import { ADSTD0501Component } from './ADWEB/ADSTD/ADSTD0501/ADSTD0501.component';
import { ADSTD0501ISU01Component } from './ADWEB/ADSTD/ADSTD0501ISU01/ADSTD0501ISU01.component';

import { ADSTD0601Component } from './ADWEB/ADSTD/ADSTD0601/ADSTD0601.component';
import { ADSTD0601ISU01Component } from './ADWEB/ADSTD/ADSTD0601ISU01/ADSTD0601ISU01.component';
import { ADSTD0601ISU02Component } from './ADWEB/ADSTD/ADSTD0601ISU02/ADSTD0601ISU02.component';
import { ADSTD0601ISU03Component } from './ADWEB/ADSTD/ADSTD0601ISU03/ADSTD0601ISU03.component';
import { ADSTD0602Component } from './ADWEB/ADSTD/ADSTD0602/ADSTD0602.component';
import { ADSTD0207Component } from './ADWEB/ADSTD/ADSTD0207/ADSTD0207.component';
import { ADSTD0701Component } from './ADWEB/ADSTD/ADSTD0701/ADSTD0701.component';
import { ADSTD0701ISUComponent } from './ADWEB/ADSTD/ADSTD0701ISU/ADSTD0701ISU.component';
import { ADSTD0703Component } from './ADWEB/ADSTD/ADSTD0703/ADSTD0703.component';
import { ADSTD0704Component } from './ADWEB/ADSTD/ADSTD0704/ADSTD0704.component';

import { ADSTD0705Component } from './ADWEB/ADSTD/ADSTD0705/ADSTD0705.component';
import { ADSTD0815Component } from './ADWEB/ADSTD/ADSTD0815/ADSTD0815.component';
import { ADSTD0706Component } from './ADWEB/ADSTD/ADSTD0706/ADSTD0706.component';
import { ADSTD0801Component } from './ADWEB/ADSTD/ADSTD0801/ADSTD0801.component';
import { ADSTD0802Component } from './ADWEB/ADSTD/ADSTD0802/ADSTD0802.component';
import { ADSTD0803Component } from './ADWEB/ADSTD/ADSTD0803/ADSTD0803.component';
import { ADSTD0804Component } from './ADWEB/ADSTD/ADSTD0804/ADSTD0804.component';
import { ADSTD0805Component } from './ADWEB/ADSTD/ADSTD0805/ADSTD0805.component';
import { ADSTD0806Component } from './ADWEB/ADSTD/ADSTD0806/ADSTD0806.component';
import { ADSTD0807Component } from './ADWEB/ADSTD/ADSTD0807/ADSTD0807.component';
import { ADSTD0808Component } from './ADWEB/ADSTD/ADSTD0808/ADSTD0808.component';
import { ADSTD0809Component } from './ADWEB/ADSTD/ADSTD0809/ADSTD0809.component';
import { ADSTD0810Component } from './ADWEB/ADSTD/ADSTD0810/ADSTD0810.component';
import { ADSTD0811Component } from './ADWEB/ADSTD/ADSTD0811/ADSTD0811.component';
import { ADSTD0812Component } from './ADWEB/ADSTD/ADSTD0812/ADSTD0812.component';
import { ADSTD0813Component } from './ADWEB/ADSTD/ADSTD0813/ADSTD0813.component';
import { ADSTD0814Component } from './ADWEB/ADSTD/ADSTD0814/ADSTD0814.component';
import { ADSTD0819Component } from './ADWEB/ADSTD/ADSTD0819/ADSTD0819.component';
import { ADSTD0820Component } from './ADWEB/ADSTD/ADSTD0820/ADSTD0820.component';
import { ADSTD0811ISUComponent } from './ADWEB/ADSTD/ADSTD0811ISU/ADSTD0811ISU.component';
import { ADSTD0812ISUComponent } from './ADWEB/ADSTD/ADSTD0812ISU/ADSTD0812ISU.component';
import { ADSTD0813ISUComponent } from './ADWEB/ADSTD/ADSTD0813ISU/ADSTD0813ISU.component';
import { ADSTD0814ISUComponent } from './ADWEB/ADSTD/ADSTD0814ISU/ADSTD0814ISU.component';
import { ADSTD0816Component } from './ADWEB/ADSTD/ADSTD0816/ADSTD0816.component';
import { ADSTD0817Component } from './ADWEB/ADSTD/ADSTD0817/ADSTD0817.component';
import { ADSTD0818Component } from './ADWEB/ADSTD/ADSTD0818/ADSTD0818.component';


import { ADRGP0101Component } from './ADWEB/ADRGP/ADRGP0101/ADRGP0101.component';
import { ADRGP0102Component } from './ADWEB/ADRGP/ADRGP0102/ADRGP0102.component';
import { ADRGP0103Component } from './ADWEB/ADRGP/ADRGP0103/ADRGP0103.component';
import { ADRGP0104Component } from './ADWEB/ADRGP/ADRGP0104/ADRGP0104.component';
import { ADRGP0105Component } from './ADWEB/ADRGP/ADRGP0105/ADRGP0105.component';
import { ADRGP0106Component } from './ADWEB/ADRGP/ADRGP0106/ADRGP0106.component';
import { ADRGP0107Component } from './ADWEB/ADRGP/ADRGP0107/ADRGP0107.component';
import { ADRGP0108Component } from './ADWEB/ADRGP/ADRGP0108/ADRGP0108.component';
import { ADRGP0201Component } from './ADWEB/ADRGP/ADRGP0201/ADRGP0201.component';
import { ADRGP0202Component } from './ADWEB/ADRGP/ADRGP0202/ADRGP0202.component';
import { ADRGP0203Component } from './ADWEB/ADRGP/ADRGP0203/ADRGP0203.component';
import { ADRGP0302Component } from './ADWEB/ADRGP/ADRGP0302/ADRGP0302.component';
import { ADRGP0401Component } from './ADWEB/ADRGP/ADRGP0401/ADRGP0401.component';
import { ADRGP0402Component } from './ADWEB/ADRGP/ADRGP0402/ADRGP0402.component';
import { ADRGP0403Component } from './ADWEB/ADRGP/ADRGP0403/ADRGP0403.component';
import { ADRGP0405Component } from './ADWEB/ADRGP/ADRGP0405/ADRGP0405.component';
import { ADRGP0501Component } from './ADWEB/ADRGP/ADRGP0501/ADRGP0501.component';
import { ADRGP0502Component } from './ADWEB/ADRGP/ADRGP0502/ADRGP0502.component';
import { ADRGP0503Component } from './ADWEB/ADRGP/ADRGP0503/ADRGP0503.component';
import { ADRGP0504Component } from './ADWEB/ADRGP/ADRGP0504/ADRGP0504.component';
import { ADRGP0505Component } from './ADWEB/ADRGP/ADRGP0505/ADRGP0505.component';
import { ADRGP0507Component } from './ADWEB/ADRGP/ADRGP0507/ADRGP0507.component';

import { ADEMP0101Component } from './ADWEB/ADEMP/ADEMP0101/ADEMP0101.component';
import { ADEMP0102Component } from './ADWEB/ADEMP/ADEMP0102/ADEMP0102.component';
import { ADEMP0103Component } from './ADWEB/ADEMP/ADEMP0103/ADEMP0103.component';
import { ADEMP0104Component } from './ADWEB/ADEMP/ADEMP0104/ADEMP0104.component';
import { ADEMP0201Component } from './ADWEB/ADEMP/ADEMP0201/ADEMP0201.component';
import { ADEMP0202Component } from './ADWEB/ADEMP/ADEMP0202/ADEMP0202.component';
import { ADEMP0301Component } from './ADWEB/ADEMP/ADEMP0301/ADEMP0301.component';
import { ADEMP0302Component } from './ADWEB/ADEMP/ADEMP0302/ADEMP0302.component';
import { ADEMP0303Component } from './ADWEB/ADEMP/ADEMP0303/ADEMP0303.component';


import { ADSYS0101Component } from './ADWEB/ADSYS/ADSYS0101/ADSYS0101.component';
import { ADSYS0102Component } from './ADWEB/ADSYS/ADSYS0102/ADSYS0102.component';
import { ADSYS0103Component } from './ADWEB/ADSYS/ADSYS0103/ADSYS0103.component';
import { ADSYS0201Component } from './ADWEB/ADSYS/ADSYS0201/ADSYS0201.component';
import { ADSYS0301Component } from './ADWEB/ADSYS/ADSYS0301/ADSYS0301.component';
import { ADSYS0302Component } from './ADWEB/ADSYS/ADSYS0302/ADSYS0302.component';
import { ADSYS0401Component } from './ADWEB/ADSYS/ADSYS0401/ADSYS0401.component';

import { ADCUP0101Component } from './ADWEB/ADCUP/ADCUP0101/ADCUP0101.component';
import { ADCUP0102Component } from './ADWEB/ADCUP/ADCUP0102/ADCUP0102.component';
import { ADCUP0401Component } from './ADWEB/ADCUP/ADCUP0401/ADCUP0401.component';
import { ADCUP0103Component } from './ADWEB/ADCUP/ADCUP0103/ADCUP0103.component';
import { ADCUP0104Component } from './ADWEB/ADCUP/ADCUP0104/ADCUP0104.component';
import { ADCUP0201Component } from './ADWEB/ADCUP/ADCUP0201/ADCUP0201.component';
import { ADCUP0202Component } from './ADWEB/ADCUP/ADCUP0202/ADCUP0202.component';
import { ADCUP0301Component } from './ADWEB/ADCUP/ADCUP0301/ADCUP0301.component';
import { ADCUP0302Component } from './ADWEB/ADCUP/ADCUP0302/ADCUP0302.component';
import { ADCUP0303Component } from './ADWEB/ADCUP/ADCUP0303/ADCUP0303.component';
import { ADCUP0304Component } from './ADWEB/ADCUP/ADCUP0304/ADCUP0304.component';
import { ADCUP0402Component } from './ADWEB/ADCUP/ADCUP0402/ADCUP0402.component';
import { ADCUP0501Component } from './ADWEB/ADCUP/ADCUP0501/ADCUP0501.component';
import { ADCUP0502Component } from './ADWEB/ADCUP/ADCUP0502/ADCUP0502.component';
import { ADCUP0503Component } from './ADWEB/ADCUP/ADCUP0503/ADCUP0503.component';
import { ADCUP0504Component } from './ADWEB/ADCUP/ADCUP0504/ADCUP0504.component';
import { ADCUP0505Component } from './ADWEB/ADCUP/ADCUP0505/ADCUP0505.component';
import { ADCUP0506Component } from './ADWEB/ADCUP/ADCUP0506/ADCUP0506.component';
import { ADCUP0601Component } from './ADWEB/ADCUP/ADCUP0601/ADCUP0601.component';
import { ADCUP0602Component } from './ADWEB/ADCUP/ADCUP0602/ADCUP0602.component';
import { ADCUP0305Component } from './ADWEB/ADCUP/ADCUP0305/ADCUP0305.component';
import { ADCUP0306Component } from './ADWEB/ADCUP/ADCUP0306/ADCUP0306.component';
import { ADCUP0603Component } from './ADWEB/ADCUP/ADCUP0603/ADCUP0603.component';
import { ADCUP0604Component } from './ADWEB/ADCUP/ADCUP0604/ADCUP0604.component';
import { ADCUP0499ISU01Component } from './ADWEB/ADCUP/ADCUP0499ISU01/ADCUP0499ISU01.component';
import { ADCUP0499ISU02Component } from './ADWEB/ADCUP/ADCUP0499ISU02/ADCUP0499ISU02.component';

import { ADCUR0201Component } from './ADWEB/ADCUR/ADCUR0201/ADCUR0201.component';
import { ADCUR0202Component } from './ADWEB/ADCUR/ADCUR0202/ADCUR0202.component';
import { ADCUR0202ISUComponent } from './ADWEB/ADCUR/ADCUR0202ISU/ADCUR0202ISU.component';
import { ADCUR0301Component } from './ADWEB/ADCUR/ADCUR0301/ADCUR0301.component';
import { ADCUR0302Component } from './ADWEB/ADCUR/ADCUR0302/ADCUR0302.component';
import { CurClassTimeInputDirective } from './_Directives/curClassTimeInput.directive';
import { ADCURClassSetComponent } from './_SharedComponont/ADCUR-class-set/ADCUR-class-set.component';
import { ADCUR0101Component } from './ADWEB/ADCUR/ADCUR0101/ADCUR0101.component';
import { ADCUR0102Component } from './ADWEB/ADCUR/ADCUR0102/ADCUR0102.component';
import { ADCUR0103Component } from './ADWEB/ADCUR/ADCUR0103/ADCUR0103.component';
import { ADCUR0104Component } from './ADWEB/ADCUR/ADCUR0104/ADCUR0104.component';
import { ADCUR0105Component } from './ADWEB/ADCUR/ADCUR0105/ADCUR0105.component';
import { ADCUR0501Component } from './ADWEB/ADCUR/ADCUR0501/ADCUR0501.component';
import { ADCUR0401Component } from './ADWEB/ADCUR/ADCUR0401/ADCUR0401.component';
import { ADCUR0402Component } from './ADWEB/ADCUR/ADCUR0402/ADCUR0402.component';
import { ADCUR0403Component } from './ADWEB/ADCUR/ADCUR0403/ADCUR0403.component';
import { ADCUR0404Component } from './ADWEB/ADCUR/ADCUR0404/ADCUR0404.component';
import { ADCUR0405Component } from './ADWEB/ADCUR/ADCUR0405/ADCUR0405.component';
import { ADCUR0406Component } from './ADWEB/ADCUR/ADCUR0406/ADCUR0406.component';
import { ADCUR0502Component } from './ADWEB/ADCUR/ADCUR0502/ADCUR0502.component';
import { ADCUR0503Component } from './ADWEB/ADCUR/ADCUR0503/ADCUR0503.component';
import { ADCUR0504Component } from './ADWEB/ADCUR/ADCUR0504/ADCUR0504.component';
import { ADCUR0505Component } from './ADWEB/ADCUR/ADCUR0505/ADCUR0505.component';
import { ADCUR0505ISUComponent } from './ADWEB/ADCUR/ADCUR0505ISU/ADCUR0505ISU.component';
import { ADCUR0506Component } from './ADWEB/ADCUR/ADCUR0506/ADCUR0506.component';
import { ADCUR0506ISUComponent } from './ADWEB/ADCUR/ADCUR0506ISU/ADCUR0506ISU.component';
import { ADCUR0507Component } from './ADWEB/ADCUR/ADCUR0507/ADCUR0507.component';
import { ADCUR0508Component } from './ADWEB/ADCUR/ADCUR0508/ADCUR0508.component';
import { ADCUR0509Component } from './ADWEB/ADCUR/ADCUR0509/ADCUR0509.component';
import { ADCUR0509ISUComponent } from './ADWEB/ADCUR/ADCUR0509ISU/ADCUR0509ISU.component';
import { ADCUR0601Component } from './ADWEB/ADCUR/ADCUR0601/ADCUR0601.component';
import { ADCUR0602Component } from './ADWEB/ADCUR/ADCUR0602/ADCUR0602.component';
import { ADCUR0603Component } from './ADWEB/ADCUR/ADCUR0603/ADCUR0603.component';
import { ADCUR0604Component } from './ADWEB/ADCUR/ADCUR0604/ADCUR0604.component';

import { InputTextComponent } from './_Forms/input-text/input-text.component';
import { InputSelectComponent } from './_Forms/input-select/input-select.component';
import { InputDateComponent } from './_Forms/input-date/input-date.component';

import { ADSEL0102Component } from './ADWEB/ADSEL/ADSEL0102/ADSEL0102.component';
import { ADSEL0103Component } from './ADWEB/ADSEL/ADSEL0103/ADSEL0103.component';
import { ADSEL0104Component } from './ADWEB/ADSEL/ADSEL0104/ADSEL0104.component';
import { ADSEL0105Component } from './ADWEB/ADSEL/ADSEL0105/ADSEL0105.component';
import { ADSEL0106Component } from './ADWEB/ADSEL/ADSEL0106/ADSEL0106.component';
import { ADSEL0201Component } from './ADWEB/ADSEL/ADSEL0201/ADSEL0201.component';
import { ADSEL0202Component } from './ADWEB/ADSEL/ADSEL0202/ADSEL0202.component';
import { ADSEL0203Component } from './ADWEB/ADSEL/ADSEL0203/ADSEL0203.component';
import { ADSEL0204Component } from './ADWEB/ADSEL/ADSEL0204/ADSEL0204.component';
import { ADSEL0300ISU01Component } from './ADWEB/ADSEL/ADSEL0300ISU01/ADSEL0300ISU01.component';
import { ADSEL0303Component } from './ADWEB/ADSEL/ADSEL0303/ADSEL0303.component';
import { ADSEL0304Component } from './ADWEB/ADSEL/ADSEL0304/ADSEL0304.component';
import { ADSEL0301Component } from './ADWEB/ADSEL/ADSEL0301/ADSEL0301.component';
import { ADSEL0302Component } from './ADWEB/ADSEL/ADSEL0302/ADSEL0302.component';
import { ADSEL0305Component } from './ADWEB/ADSEL/ADSEL0305/ADSEL0305.component';
import { ADSEL0401Component } from './ADWEB/ADSEL/ADSEL0401/ADSEL0401.component';
import { ADSEL0402Component } from './ADWEB/ADSEL/ADSEL0402/ADSEL0402.component';
import { ADSEL0403Component } from './ADWEB/ADSEL/ADSEL0403/ADSEL0403.component';
import { ADSEL0501Component } from './ADWEB/ADSEL/ADSEL0501/ADSEL0501.component';
import { ADSEL0502Component } from './ADWEB/ADSEL/ADSEL0502/ADSEL0502.component';
import { ADSEL0504Component } from './ADWEB/ADSEL/ADSEL0504/ADSEL0504.component';

import { ADGRD0102Component } from './ADWEB/ADGRD/ADGRD0102/ADGRD0102.component';
import { ADGRD0103Component } from './ADWEB/ADGRD/ADGRD0103/ADGRD0103.component';
import { ADGRD0201Component } from './ADWEB/ADGRD/ADGRD0201/ADGRD0201.component';
import { ADGRD0202Component } from './ADWEB/ADGRD/ADGRD0202/ADGRD0202.component';
import { ADGRD0301Component } from './ADWEB/ADGRD/ADGRD0301/ADGRD0301.component';
import { ADGRD0302Component } from './ADWEB/ADGRD/ADGRD0302/ADGRD0302.component';
import { ADGRD0303Component } from './ADWEB/ADGRD/ADGRD0303/ADGRD0303.component';
import { ADGRD0304Component } from './ADWEB/ADGRD/ADGRD0304/ADGRD0304.component';
import { ADGRD0305Component } from './ADWEB/ADGRD/ADGRD0305/ADGRD0305.component';
import { ADGRD0306Component } from './ADWEB/ADGRD/ADGRD0306/ADGRD0306.component';
import { ADGRD0307Component } from './ADWEB/ADGRD/ADGRD0307/ADGRD0307.component';
import { ADGRD0401Component } from './ADWEB/ADGRD/ADGRD0401/ADGRD0401.component';
import { ADGRD0402Component } from './ADWEB/ADGRD/ADGRD0402/ADGRD0402.component';
import { ADGRD0403Component } from './ADWEB/ADGRD/ADGRD0403/ADGRD0403.component';
import { ADGRD0404Component } from './ADWEB/ADGRD/ADGRD0404/ADGRD0404.component';
import { ADGRD0501Component } from './ADWEB/ADGRD/ADGRD0501/ADGRD0501.component';
import { ADGRD0502Component } from './ADWEB/ADGRD/ADGRD0502/ADGRD0502.component';
import { ADGRD0601Component } from './ADWEB/ADGRD/ADGRD0601/ADGRD0601.component';
import { ADGRD0602Component } from './ADWEB/ADGRD/ADGRD0602/ADGRD0602.component';
import { ADGRD0602ISUComponent } from './ADWEB/ADGRD/ADGRD0602ISU/ADGRD0602ISU.component';
import { ADGRD0604Component } from './ADWEB/ADGRD/ADGRD0604/ADGRD0604.component';
import { ADGRD0701Component } from './ADWEB/ADGRD/ADGRD0701/ADGRD0701.component';
import { ADGRD0702Component } from './ADWEB/ADGRD/ADGRD0702/ADGRD0702.component';
import { ADGRD0703Component } from './ADWEB/ADGRD/ADGRD0703/ADGRD0703.component';

import { ADABS0101Component } from './ADWEB/ADABS/ADABS0101/ADABS0101.component';
import { ADABS0102Component } from './ADWEB/ADABS/ADABS0102/ADABS0102.component';
import { ADABS0103Component } from './ADWEB/ADABS/ADABS0103/ADABS0103.component';
import { ADABS0104Component } from './ADWEB/ADABS/ADABS0104/ADABS0104.component';
import { ADABS0105Component } from './ADWEB/ADABS/ADABS0105/ADABS0105.component';
import { ADABS0201Component } from './ADWEB/ADABS/ADABS0201/ADABS0201.component';
import { ADABS0202Component } from './ADWEB/ADABS/ADABS0202/ADABS0202.component';
import { ADABS0203Component } from './ADWEB/ADABS/ADABS0203/ADABS0203.component';
import { ADABS0301Component } from './ADWEB/ADABS/ADABS0301/ADABS0301.component';
import { ADABS0302Component } from './ADWEB/ADABS/ADABS0302/ADABS0302.component';
import { ADABS0303Component } from './ADWEB/ADABS/ADABS0303/ADABS0303.component';
import { ADABS0304Component } from './ADWEB/ADABS/ADABS0304/ADABS0304.component';
import { ADABS0305Component } from './ADWEB/ADABS/ADABS0305/ADABS0305.component';
import { ADABS0306Component } from './ADWEB/ADABS/ADABS0306/ADABS0306.component';
import { ADABS0307Component } from './ADWEB/ADABS/ADABS0307/ADABS0307.component';
import { ADABS0308Component } from './ADWEB/ADABS/ADABS0308/ADABS0308.component';
import { ADABS0309Component } from './ADWEB/ADABS/ADABS0309/ADABS0309.component';

import { ADSRP0101Component } from './ADWEB/ADSRP/ADSRP0101/ADSRP0101.component';
import { ADSRP0102Component } from './ADWEB/ADSRP/ADSRP0102/ADSRP0102.component';
import { ADSRP0103Component } from './ADWEB/ADSRP/ADSRP0103/ADSRP0103.component';
import { ADSRP0201Component } from './ADWEB/ADSRP/ADSRP0201/ADSRP0201.component';
import { ADSRP0202Component } from './ADWEB/ADSRP/ADSRP0202/ADSRP0202.component';
import { ADSRP0202ISUComponent } from './ADWEB/ADSRP/ADSRP0202ISU/ADSRP0202ISU.component';
import { ADSRP0203Component } from './ADWEB/ADSRP/ADSRP0203/ADSRP0203.component';
import { ADSRP0204Component } from './ADWEB/ADSRP/ADSRP0204/ADSRP0204.component';
import { ADSRP0301Component } from './ADWEB/ADSRP/ADSRP0301/ADSRP0301.component';
import { ADSRP0301ISUComponent } from './ADWEB/ADSRP/ADSRP0301ISU/ADSRP0301ISU.component';
import { ADSRP0302Component } from './ADWEB/ADSRP/ADSRP0302/ADSRP0302.component';
import { ADSRP0303Component } from './ADWEB/ADSRP/ADSRP0303/ADSRP0303.component';
import { ADSRP0304Component } from './ADWEB/ADSRP/ADSRP0304/ADSRP0304.component';
import { ADSRP0305Component } from './ADWEB/ADSRP/ADSRP0305/ADSRP0305.component';
import { ADSRP0306Component } from './ADWEB/ADSRP/ADSRP0306/ADSRP0306.component';
import { ADSRP0307Component } from './ADWEB/ADSRP/ADSRP0307/ADSRP0307.component';
import { ADSRP0308Component } from './ADWEB/ADSRP/ADSRP0308/ADSRP0308.component';
import { ADSRP0309Component } from './ADWEB/ADSRP/ADSRP0309/ADSRP0309.component';

import { ADSAC0101Component } from './ADWEB/ADSAC/ADSAC0101/ADSAC0101.component';
import { ADSAC0102Component } from './ADWEB/ADSAC/ADSAC0102/ADSAC0102.component';
import { ADSAC0103Component } from './ADWEB/ADSAC/ADSAC0103/ADSAC0103.component';
import { ADSAC0201Component } from './ADWEB/ADSAC/ADSAC0201/ADSAC0201.component';
import { ADSAC0202Component } from './ADWEB/ADSAC/ADSAC0202/ADSAC0202.component';
import { ADSAC0203Component } from './ADWEB/ADSAC/ADSAC0203/ADSAC0203.component';
import { ADSAC0301Component } from './ADWEB/ADSAC/ADSAC0301/ADSAC0301.component';
import { ADSAC0302Component } from './ADWEB/ADSAC/ADSAC0302/ADSAC0302.component';
import { ADSAC0303Component } from './ADWEB/ADSAC/ADSAC0303/ADSAC0303.component';
import { ADSAC0304Component } from './ADWEB/ADSAC/ADSAC0304/ADSAC0304.component';


import { ADCOM0101Component } from './ADWEB/ADCOM/ADCOM0101/ADCOM0101.component';

import { ADSSS0101Component } from './ADWEB/ADSSS/ADSSS0101/ADSSS0101.component';
import { ADSSS0201Component } from './ADWEB/ADSSS/ADSSS0201/ADSSS0201.component';
import { ADSSS0202Component } from './ADWEB/ADSSS/ADSSS0202/ADSSS0202.component';
import { ADSSS0203Component } from './ADWEB/ADSSS/ADSSS0203/ADSSS0203.component';
import { ADSSS0204Component } from './ADWEB/ADSSS/ADSSS0204/ADSSS0204.component';
import { ADSSS0205Component } from './ADWEB/ADSSS/ADSSS0205/ADSSS0205.component';
import { ADSSS0206Component } from './ADWEB/ADSSS/ADSSS0206/ADSSS0206.component';
import { ADSSS0207Component } from './ADWEB/ADSSS/ADSSS0207/ADSSS0207.component';
import { ADSSS0208Component } from './ADWEB/ADSSS/ADSSS0208/ADSSS0208.component';
import { ADSSS0301Component } from './ADWEB/ADSSS/ADSSS0301/ADSSS0301.component';


import { FlowComponent } from './_SharedComponont/flow/flow.component';

import { InlineStylesCSPModule } from './inline-styles-csp/inline-styles-csp.module';




@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        NavbarComponent,
        FooterComponent,
        StdSignOffComponent,
        ContrastComponent,
        SearchTeacherComponent,
        GrdScoreInputComponent,
        LocationComponent,
        TCH0308Component,
        TCH0114Component,
        STDW30002Component,
        ADEMP0104Component,
        STDW30001Component,
        STDW50001Component,
        STDW50003Component,
        STDW50002Component,
        ADPUB0101Component,
        ADPUB0102Component,
        ADPUB0103Component,
        ADASM0101Component,
        ADASM0102Component,
        ADASM0103Component,
        ADASM0104Component,
        ADASM0105Component,
        CurClassComponent,
        CurClassRwdComponent,
        YearTermInputComponent,
        STDW2000607Component,
        FunctionSearchComponent,
        STDW00003Component,
        FavouriteComponent,
        STDW00001Component,
        STDW00002Component,
        STDW10001Component,
        STDW10002Component,
        STDW10003Component,
        STDW10004Component,
        STDW10005Component,
        STDW10006Component,
        STDW10013ISUComponent,
        STDW20001Component,
        STDW20002Component,
        STDW20003Component,
        STDW20004Component,
        STDW30003Component,
        STDW30004Component,
        STDW30005Component,
        STDW40001Component,
        STDW40002Component,
        STDW40003Component,
        STDW40004Component,
        STDW40005Component,
        STDW40006Component,
        STDW50004Component,
        STDW60001Component,
        STDW60002Component,
        STDW60003Component,
        STDW70001Component,
        TCH0101Component,
        TCH0102Component,
        TCH0103Component,
        TCH0104Component,
        TCH0105Component,
        TCH0106Component,
        TCH0107Component,
        TCH0108Component,
        TCH0109Component,
        TCH0110Component,
        TCH0111Component,
        TCH0112Component,
        TCH0113Component,
        TCH0115Component,
        TCH0201Component,
        TCH0202Component,
        TCH0203Component,
        TCH0204Component,
        TCH0301Component,
        TCH0302Component,
        TCH0303Component,
        TCH0304Component,
        TCH0305Component,
        TCH0306Component,
        TCH0307Component,
        TCH0309Component,
        TCH0401Component,
        TCH0402Component,
        TCH0101DetailComponent,
        TCH0111DetailComponent,
        ProfileComponent,
        TCH0310Component,
        TCH0116Component,
        TCH0117Component,
        TCH0118Component,
        STDW30006Component,
        ADFunctionListComponent,
        ADSTD0201Component,
        ADSTD0101Component,
        ADSTD0101ISUComponent,
        ADSTDSearchInputComponent,
        ADSTD0103Component,
        ADSTD0104Component,
        ADSTD0203Component,
        ADTFS01A03Component,
        ADTFS01B01Component,
        ADTFS01B02Component,
        ADTFS01B03Component,
        ADTFS01B04Component,
        ADTFS01C01Component,
        ADTFS01D01Component,
        ADTFS01D02Component,
        ADTFS01D03Component,
        ADTFS0301Component,
        ADTFS0302Component,
        ADTFS0502Component,
        ADTFS0501Component,
        ADTFS0601Component,
        ADTFS0602Component,
        ADTFS01E03Component,
        ADTFS01E02Component,
        ADTFS01E01Component,
        ADTFS0303Component,
        ADTFS0701Component,
        ADTFS0702Component,
        ADTFS0401Component,
        ADTFS01A04Component,
        ADSTD0205Component,
        ADSTD0206Component,
        ADSTD0202Component,
        ADSTD0106Component,
        STDW30007Component,
        STDW30008Component,
        STDW10009Component,
        STDW10010Component,
        STDW10011Component,
        TextareaAutoresizeDirective,
        ADTFS0603Component,
        ADTFS0503Component,
        ADSTD0102Component,
        ADSTD0301Component,
        ADSTD0302Component,
        ADSTD0303Component,
        ADSTD0304Component,
        ADSTD0305Component,
        ADSTD0401Component,
        ADSTD0401ISU01Component,

        ADSTD0501Component,
        ADSTD0501ISU01Component,


        ADSTD0601Component,
        ADSTD0601ISU01Component,
        ADSTD0601ISU02Component,
        ADSTD0601ISU03Component,
        ADSTD0602Component,
        ADSTD0207Component,
        ADSTD0204Component,
        ADSTD0701Component,
        ADSTD0701ISUComponent,
        ADSTD0703Component,
        ADSTD0704Component,
        ADSTD0706Component,
        ADSTD0705Component,
        ADSTD0811Component,
        ADSTD0812Component,
        ADSTD0813Component,
        ADSTD0814Component,
        ADSTD0811ISUComponent,
        ADSTD0812ISUComponent,
        ADSTD0813ISUComponent,
        ADSTD0814ISUComponent,
        ADSTD0815Component,
        ADSTD0816Component,
        ADSTD0817Component,
        ADSTD0818Component,
        ADRGP0101Component,
        ADRGP0102Component,
        ADRGP0103Component,
        ADRGP0104Component,
        ADRGP0105Component,
        ADRGP0106Component,
        ADRGP0107Component,
        ADRGP0108Component,
        ADRGP0201Component,
        ADRGP0202Component,
        ADRGP0203Component,
        ADRGP0302Component,
        ADRGP0401Component,
        ADRGP0402Component,
        ADRGP0403Component,
        ADRGP0405Component,
        ADRGP0501Component,
        ADRGP0502Component,
        ADRGP0503Component,
        ADRGP0504Component,
        ADRGP0505Component,
        ADRGP0507Component,
        ADEMP0201Component,
        ADEMP0202Component,
        ADSTD0801Component,
        ADSTD0802Component,
        ADSTD0803Component,
        ADSTD0804Component,
        ADSTD0805Component,
        ADSTD0806Component,
        ADSTD0807Component,
        ADSTD0808Component,
        ADSTD0809Component,
        ADSTD0810Component,
        ADSTD0819Component,
        ADSTD0820Component,
        ADSYS0101Component,
        ADSYS0102Component,
        ADSYS0103Component,
        ADSYS0302Component,
        ADSYS0401Component,
        ADSYS0501Component,
        ADCUP0101Component,
        ADCUP0102Component,
        ADCUP0401Component,
        ADCUP0103Component,
        ADCUP0104Component,
        ADCUP0201Component,
        ADCUP0202Component,
        ADCUP0301Component,
        ADCUP0302Component,
        ADCUP0303Component,
        ADCUP0304Component,
        ADCUP0402Component,
        ADCUP0501Component,
        ADCUP0502Component,
        ADCUP0503Component,
        ADCUP0504Component,
        ADCUP0505Component,
        ADCUP0506Component,
        ADCUP0601Component,
        ADCUP0602Component,
        ADSYS0201Component,
        ADCUP0305Component,
        ADCUP0306Component,
        ADCUP0603Component,
        ADCUP0604Component,
        ADCUR0201Component,
        ADCUR0202Component,
        ADCUR0202ISUComponent,
        ADCUR0301Component,
        ADCUR0302Component,
        CurClassTimeInputDirective,
        ADCURClassSetComponent,
        ADCUR0101Component,
        ADCUR0102Component,
        ADCUR0103Component,
        ADCUR0104Component,
        ADCUR0105Component,
        ADCUR0501Component,
        ADEMP0101Component,
        ADEMP0102Component,
        ADEMP0103Component,
        ADEMP0301Component,
        ADEMP0302Component,
        ADEMP0303Component,
        ADCUR0401Component,
        ADCUR0402Component,
        ADCUR0403Component,
        ADCUR0404Component,
        ADCUR0405Component,
        ADCUR0406Component,
        ADCUR0502Component,
        ADCUR0503Component,
        ADCUR0504Component,
        ADCUR0505Component,
        ADCUR0505ISUComponent,
        ADCUR0506Component,
        ADCUR0506ISUComponent,
        ADCUR0507Component,
        ADCUR0508Component,
        ADCUR0509Component,
        ADCUR0509ISUComponent,
        ADCUR0601Component,
        ADCUR0602Component,
        ADCUR0603Component,
        ADCUR0604Component,
        ADSEL0102Component,
        ADSEL0103Component,
        ADSEL0104Component,
        ADSEL0105Component,
        ADSEL0106Component,
        ADSEL0201Component,
        STDW30009Component,
        STDW30010Component,
        ADSEL0202Component,
        ADSEL0203Component,
        ADSEL0204Component,
        ADSEL0300ISU01Component,
        ADSEL0302Component,
        ADSEL0303Component,
        ADSEL0304Component,
        ADSEL0305Component,
        ADSEL0401Component,
        ADSEL0402Component,
        ADSEL0403Component,
        InputTextComponent,
        InputSelectComponent,
        InputDateComponent,
        ADSEL0301Component,
        ADSEL0501Component,
        ADSEL0502Component,
        ADSEL0504Component,
        ADGRD0102Component,
        ADGRD0103Component,
        ADGRD0201Component,
        ADGRD0202Component,
        ADGRD0301Component,
        ADGRD0302Component,
        ADSYS0301Component,
        ADGRD0303Component,
        ADGRD0304Component,
        ADGRD0305Component,
        ADGRD0306Component,
        ADGRD0307Component,
        ADGRD0401Component,
        ADGRD0402Component,
        ADGRD0403Component,
        ADGRD0404Component,
        ADGRD0501Component,
        ADGRD0601Component,
        ADGRD0602Component,
        ADGRD0602ISUComponent,
        ADGRD0604Component,
        ADGRD0701Component,
        ADGRD0702Component,
        ADGRD0703Component,
        ADABS0101Component,
        ADABS0102Component,
        ADABS0103Component,
        ADABS0104Component,
        ADABS0105Component,
        ADABS0201Component,
        ADABS0202Component,
        ADABS0203Component,
        ADABS0301Component,
        ADABS0302Component,
        ADABS0303Component,
        ADABS0304Component,
        ADABS0305Component,
        ADABS0306Component,
        ADABS0307Component,
        ADABS0308Component,
        ADABS0309Component,

        ADSRP0101Component,
        ADSRP0102Component,
        ADSRP0103Component,
        ADSRP0201Component,
        ADSRP0202Component,
        ADSRP0202ISUComponent,
        ADSRP0203Component,
        ADSRP0204Component,
        ADSRP0301Component,
        ADSRP0301ISUComponent,
        ADSRP0302Component,
        ADSRP0303Component,
        ADSRP0304Component,
        ADSRP0305Component,
        ADSRP0306Component,
        ADSRP0307Component,
        ADSRP0308Component,
        ADSRP0309Component,

        ADSAC0101Component,
        ADSAC0102Component,
        ADSAC0103Component,
        ADSAC0201Component,
        ADSAC0202Component,
        ADSAC0203Component,
        ADSAC0301Component,
        ADSAC0302Component,
        ADSAC0303Component,
        ADSAC0304Component,
        ADSSS0101Component,
        ADSSS0201Component,
        ADSSS0202Component,
        ADSSS0203Component,
        ADSSS0204Component,
        ADSSS0205Component,
        ADSSS0206Component,
        ADSSS0207Component,
        ADSSS0208Component,
        ADSSS0301Component,
        ADGRD0502Component,
        ADCOM0101Component,
        ADCUP0499ISU01Component,
        ADCUP0499ISU02Component,
        FlowComponent,

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ThirdPartModule,
        BootstrapModule,
        ReactiveFormsModule,
        FormsModule,
        ErrorModule,
        InlineStylesCSPModule,
        BsDropdownModule.forRoot(),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        DatePipe,
        BsDropdownConfig
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
