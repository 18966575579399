<div class="container">
    <div class="row">
        <div class="col-md-10">
            <div class="input-group flex-nowrap mb-1">
                <button class="search-btn" style="width: 130px;" (click)="getConfirmList()">{{'重新整理'|translate}}

                </button>
            </div>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3 table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th style="width:60px;">{{'學年'|translate}}

                </th>
                <th style="width:60px;">{{'學期'|translate}}

                </th>
                <th style="width:200px;">{{'下載'|translate}}

                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of ConfirmLis;">
                <td style="width:60px;">{{item.Years}}</td>
                <td style="width:60px;">{{item.Term}}</td>
                <td style="width:200px;">
                    <a class="pointer text-decoration-none" (click)="exportCertFile(item.DataKey)">
                        <span class="icon-Fill-in-button"></span>{{'下載教師鐘點費確認表'|translate}}


                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>