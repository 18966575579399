<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" [(ngModel)]="stdContrastListInput.Years">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" [(ngModel)]="stdContrastListInput.Term">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #eduNo (change)="getDeptList(eduNo.value, false)"
                    [(ngModel)]="stdContrastListInput.EduNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" #deptNo
                    (change)="getClassList(eduNo.value, deptNo.value, false)" [(ngModel)]="stdContrastListInput.DeptNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">組別</span>
                <select class="form-select shadow-none" [(ngModel)]="stdContrastListInput.TeamNo">
                    <option value="" selected>--請選擇組別--</option>
                    <option *ngFor="let item of stdTeamList" value="{{item.Team_No}}">{{item.Team_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班級</span>
                <select class="form-select shadow-none" [(ngModel)]="stdContrastListInput.Class">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdContrastListInput.StdNoFrom">
                    <span class="input-group-text">至</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdContrastListInput.StdNoTo">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="exportReportContrastFile()">列印</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal(template)">審核</button>
            <button class="search-btn float-end me-3" (click)="getStdContrastList()">查詢</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" class="pointer" (click)="setDatakey('all', '', $event.target)">&ensp;全選</th>
                <th>學號</th>
                <th>姓名</th>
                <th>性別</th>
                <th>學制</th>
                <th>系所</th>
                <th>班級</th>
                <th>修業狀態</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdContrastList">
                <td><input type="checkbox" class="pointer" (click)="setDatakey('', item.DataKey, $event.target)"
                        [checked]="item.checked"></td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.Sex}}</td>
                <td>{{item.EduName}}</td>
                <td>{{item.DeptName}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.Status}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">畢業資格審核</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #gEduNo (change)="getDeptList(gEduNo.value ,true)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">科系</span>
                    <select class="form-select shadow-none" #gDeptNo
                        (change)="getClassList(gEduNo.value, gDeptNo.value, false)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">班級</span>
                    <select class="form-select shadow-none" #gClass>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <button class="search-btn mb-3"
                    (click)="getStdContrastUnSetList(gDeptNo.value, gClass.value)">查詢</button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5">
                <select multiple class="form-control shadow-none" style="height: 300px;" #leftItems>
                    <option value="{{i}}" *ngFor="let item of itemLeft; let i = index">{{item.StdName}}</option>
                </select>
            </div>
            <div class="col-lg-2 text-center mt-3">
                <div class="btn-group">
                    <button class="other-Button-gray mb-3" (click)="setItems('goRightAll', leftItems)">>></button><br>
                    <button class="other-Button-gray mb-3" (click)="setItems('goRight', leftItems)">></button><br>
                    <button class="other-Button-gray mb-3" (click)="setItems('goLeft', RightItems)"></button><br>
                    <button class="other-Button-gray mb-3" (click)="setItems('goLeftAll', RightItems)"></button><br>
                </div>
            </div>
            <div class="col-lg-5">
                <select multiple class="form-control shadow-none" style="height: 300px;" #RightItems>
                    <option value="{{i}}" *ngFor="let item of itemRight; let i = index">{{item.StdName}}</option>
                </select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">比對至學年</span>
                    <select class="form-select shadow-none" #gYear>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">比對至學期</span>
                    <select class="form-select shadow-none" #gTerm>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <button class="save-btn-gray mx-auto"
                    (click)="getStdContrastProcess(gYear.value, gTerm.value)">儲存</button>
            </div>
        </div>
    </div>
</ng-template>
