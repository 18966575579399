<div class="container">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>

    <table class="table table-hover text-center table-striped table-rwd">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'約見日期' | translate}}</th>
                <th>{{'節次' | translate}}</th>
                <th>{{'教師' | translate}} </th>
                <th>{{'申請時間' | translate}}</th>
                <th>{{'回覆時間' | translate}}</th>
                <th>{{'回覆內容' | translate}}</th>
                <th>{{'狀態' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td data-th="約見日期">{{item.Meeting_Date}}</td>
                <td data-th="節次">{{item.Cur_Time}}</td>
                <td data-th="教師">{{item.Tch_Name}}</td>
                <td data-th="申請時間">{{item.Apply_Date}}</td>
                <td data-th="回覆時間">{{item.Reply_Date}}</td>
                <td data-th="回覆內容">{{item.Reply_Memo}}</td>
                <td data-th="狀態" class="fw-bolder" [ngClass]="{
                    'text-primary': item.Status == '待審核', 
                    'text-success': item.Status == '同意', 
                    'text-danger': item.Status == '不同意'}">{{item.Status}}
                </td>
                <td class="td-rwd-hide" data-th=""></td>
            </tr>
        </tbody>
    </table>
</div>