<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">處理學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled selected>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">處理學期</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">處理日期</span>
                </div>
                <input type="text" placeholder="--請選擇日期--" class="form-control shadow-none" bsDaterangepicker
                    #dpr="bsDaterangepicker" formControlName="Date">
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-8">
            <button type="button" class="search-btn float-start me-3">查詢</button>
        </div>
        <div class="col-md-4">
            <button type="button" class="other-Button-gray float-end me-3" (click)="openModal(column)">新增</button>
        </div>
    </div>
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" (click)="setDatakey('all', '', $event.target)" class="pointer">&ensp;全選</th>
                <th>假別</th>
                <th>天數</th>
                <th>編輯</th>
                <th>設定關卡</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #column>
    <div class="modal-header">
        <h4 class="modal-title pull-left">修改簽核人員</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <span class="input-group-text">簽核角色名稱</span>
                    <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group  mb-3">
                    <span class="input-group-text">簽核人員</span>
                    <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">限制審核期限</span>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                            value="option1">
                        <label class="form-check-label" for="inlineRadio1">是</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                            value="option2">
                        <label class="form-check-label" for="inlineRadio2">否</label>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">排序</span>
                    <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
            </div>
            <div>
                <button type="button" class="other-Button-gray float-end me-3" (click)="modalRef?.hide()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>