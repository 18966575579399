import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetMoneyList } from 'src/app/_Models/TCHWEB/TCH0108/getMoneyList';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0108',
    templateUrl: './TCH0108.component.html',
    styleUrls: ['./TCH0108.component.css']
})
export class TCH0108Component implements OnInit {
    baseUrl = environment.apiUrl;
    moneyList!: GetMoneyList[];
    constructor(private http: HttpClient, private toastr: ToastrService, private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.getMoneyList({ Years: response.Now_Years, Term: response.Now_Term });
        })
    }


    getMoneyList(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_08/List_Money", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.moneyList = [];
                return;
            }
            this.moneyList = response;
        })
    }

}
