<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group mb-3">
                <span class="input-group-text">節次代碼</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Section" maxlength="2">
                <!-- <select class="form-select shadow-none" [(ngModel)]="dataDetail.Section">
                    <option value="" disabled>--請選擇--</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                </select> -->
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <span class="input-group-text">節次名稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Section_Name">
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <span class="input-group-text">上課時間</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Start_Time">
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <span class="input-group-text">下課時間</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.End_Time">
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <span class="input-group-text">排序</span>
                <input type="number" class="form-control shadow-none" [(ngModel)]="dataDetail.Sort" max="999" min="1">
            </div>
        </div>          
        <div class="col-md-2">
            <div class="input-group mb-3">
                <span class="input-group-text">對應碼</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.RCode" maxlength="2">
            </div>
        </div>        
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">修改者</span>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Update_User">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">修改時間</span>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Update_Time">
            </div>
        </div>
        <div class="col-md-6">
            <button class="other-Button-gray float-end" (click)="clear()">清除</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="add-btn float-end me-3" (click)="insert()">新增</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th>節次代碼</th>
                <th>節次名稱</th>
                <th>上課時間</th>
                <th>下課時間</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data; let i = index" class="pointer"
                [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                (click)="searchDetail(i)">
                <td>{{i + 1}}</td>
                <td>{{item.Section}}</td>
                <td>{{item.Section_Name}}</td>
                <td>{{item.Start_Time}}</td>
                <td>{{item.End_Time}}</td>
            </tr>
        </tbody>
    </table>
</div>
