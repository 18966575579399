<div class="container" style="margin-bottom: 100px;">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="Years">{{'類型'|translate}}</span>
                </div>
                <select [(ngModel)]="Type" name="Type" class="form-control shadow-none">
                    <option value="" selected disabled>{{'--請選擇評量類型--'|translate}}</option>
                    <option value="M">{{'期中評量'|translate}}</option>
                    <option value="F">{{'期末評量'|translate}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6"></div>
    </div>
    <button type="button" class="search-btn mb-3" (click)="queryTeachEval()">{{'查詢'|translate}}</button>

    <table class="table table-hover table-striped table-rwd text-center">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'學年'|translate}}</th>
                <th>{{'學期'|translate}}</th>
                <th>{{'科目代號'|translate}}</th>
                <th>{{'班級名稱'|translate}}</th>
                <th>{{'課程名稱'|translate}}</th>
                <th>{{'詳細'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let Course of data">
                <td data-th="學年">{{Course.Years}}</td>
                <td data-th="學期">{{Course.Term}}</td>
                <td data-th="科目代號">{{Course.Cos_ID}}</td>
                <td data-th="班級名稱">{{Course.Class_Name}}</td>
                <td data-th="課程名稱">{{Course.Cos_Name}}</td>
                <td data-th="詳細"><a (click)="viewData(Course.DataKey)" class="pointer text-decoration-none"><span
                            class="icon-View-button"></span>檢視</a></td>
            </tr>
        </tbody>
    </table>
</div>