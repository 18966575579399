import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { GetEMPCertList,GetEMPCertFile } from 'src/app/_Models/AD/ADEMP/ADEMP0301/getEMPCertList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetEDocFlow } from 'src/app/_Models/getEDocFlow';
import { Search } from 'src/app/_Models/AD/ADEMP/ADEMP0104/search'
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import { DatePipe } from '@angular/common';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADEMP0301',
  templateUrl: './ADEMP0301.component.html',
  styleUrls: ['./ADEMP0301.component.css']
})

export class ADEMP0301Component implements OnInit {
  baseUrl = environment.apiUrl;
  modalRef!: BsModalRef;
  unitList!: GetSelectList[];
  CertList!: GetEMPCertList[];
  ColumnItemList!: Search[];
  eDocFlow!: GetEDocFlow[];
  CertFile!:GetEMPCertFile;
  bsConfig = environment.bsDatePickerConfig;
  fileExchange = new FileExchange();

  ngFormInput = new UntypedFormGroup({
    Code: new UntypedFormControl(''),
    IDNo: new UntypedFormControl(''),
    Name: new UntypedFormControl(''),
    UnitCode: new UntypedFormControl(''),
    EndDateS: new UntypedFormControl(''),
    EndDateE: new UntypedFormControl(''),
    LeaveStatus: new UntypedFormControl(''),
    Type: new UntypedFormControl('')
  });

  ngFormEdit = new UntypedFormGroup({
    DataKey: new UntypedFormControl(''),
    Code: new UntypedFormControl(''),
    UserName: new UntypedFormControl(''),
    Sex: new UntypedFormControl(''),
    IDNo: new UntypedFormControl(''),
    Birthday: new UntypedFormControl(''),
    CertNo: new UntypedFormControl(''),
    Reason: new UntypedFormControl(''),
    ReasonType: new UntypedFormControl(''),
    ReasonTypeName: new UntypedFormControl(''),
    EndDate: new UntypedFormControl(''),
    SalaryLevel: new UntypedFormControl(''),
    Memo: new UntypedFormControl(''),
    Description: new UntypedFormControl(''),
    SerialNo: new UntypedFormControl(''),
    JobDetail: new UntypedFormControl('')
  });

  ngFormMemoEdit = new UntypedFormGroup({
    Contents: new UntypedFormControl('')
  });

  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private systemService: SystemService,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    private datePipe: DatePipe,
    private fileExchangeServices: FileExchangeService) { }

  ngOnInit(): void {
    this.getUnitList();
    this.getColumnItemList();
    this.getCertMemo();
    this.localeService.use('zh-cn');
  }

  getUnitList() {
    this.systemService.getUnitList('', '', '').subscribe(response => {
      if (response.Info == false) {
        return;
      }
      this.unitList = response;
    })
  }

  getColumnItemList() {
    this.ColumnItemList = [];
    this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0104/Search", { Column_No: '71' }).subscribe(response => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.ColumnItemList = response;
    });
  }

  getCertList() {
    this.CertList = [];
    this.ngFormInput.get("Type")?.setValue("1");
    this.ngFormInput.get("EndDateS")?.setValue(this.datePipe.transform(this.ngFormInput.get("EndDateS")?.value, 'yyyy-MM-dd')!.toString());
    this.ngFormInput.get("EndDateE")?.setValue(this.datePipe.transform(this.ngFormInput.get("EndDateE")?.value, 'yyyy-MM-dd')!.toString());

    this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0301/Get_EMP_Cert_List", this.ngFormInput.value).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.CertList = response;
      this.CertList.forEach(element => {
        element.Checked = false;
      });
    })
  }

  openModal(template: TemplateRef<any>) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-md'
    };

    this.modalRef = this.modalService.show(template, modalConfig);
  }

  openModalFile(template: TemplateRef<any>,Cert:GetEMPCertList ){
    this.CertFile={
      DataKey:Cert.DataKey,
      SerialNo:Cert.SerialNo,
      Code:Cert.Code,
      FileData:{Info:true,FileName:"",Base64:""}
    };

    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-md'
    };

    this.modalRef = this.modalService.show(template, modalConfig);
  }

  openModalMemo(template: TemplateRef<any>) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };

    this.modalRef = this.modalService.show(template, modalConfig);
  }

  getCertDetail(thisItem: GetEMPCertList) {
    this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0301/Get_EMP_Cert_Detail", thisItem).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.ngFormEdit.setValue(response);
    })
  }

  getCertMemo() {
    this.http.get<any>(this.baseUrl + "Public/Get_EMP_Cert_Memo").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      let memo=response.Contents;
      let re = /\\n\\/gi;
      this.ngFormMemoEdit.get("Contents")?.setValue(memo.replace(re,"\n"));
    })
  }

  getCertMemoSave() {
    let memo=this.ngFormMemoEdit.get("Contents")?.value;
    let re = /\n/gi;
    memo=memo.replace(re,"\\n\\");

    this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0301/Get_EMP_Cert_Memo_Save", {Contents:memo}).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("儲存完成!");
    })
  }

  setCheck(thisCheck: boolean, index: number) {
    this.CertList[index].Checked = thisCheck;
  }

  getCertModify() {
    this.ngFormEdit.get("EndDate")?.setValue(this.datePipe.transform(this.ngFormEdit.get("EndDate")?.value, 'yyyy-MM-dd')!.toString());
    this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0301/Get_EMP_Cert_Save", this.ngFormEdit.value).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("儲存完成!");
    })
  }

  exportCertFile() {
    let blnCheck = false;

    for (let i = 0; i < this.CertList.length; i++) {
      if (this.CertList[i].Checked) {
        blnCheck = true;
        this.CertList[i].Type=this.ngFormInput.get("Type")?.value;
      }
    }

    if (!blnCheck) {
      this.toastr.warning("請勾選欲列印的離職證明書項目!");
      return;
    }

    this.fileExchangeServices.ExportFile("ACAD/EMP/ADEMP0301/Get_EMP_Cert_File", this.CertList).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }

      if (response.FileName == '') {
        this.toastr.warning("查無資料");
        return;
      }

      let url = window.URL.createObjectURL(response.blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = response.FileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => console.log(error.error))
  }

  openModal_status(template: TemplateRef<any>, dataKey: string) {
    let modalConfig = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-xl'
    };
    this.getEDocFlow(dataKey);
    this.modalRef = this.modalService.show(template, modalConfig);
  }

  getEDocFlow(dataKey: string) {
    this.http.post<any>(this.baseUrl + "Public/Get_EDoc_Flow", { DataKey: dataKey }).subscribe((response) => {
        if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
        }
        this.eDocFlow = response;
    }, error => this.toastr.error(error.error))
  }

  getCertFileDownLoad(Cer:GetEMPCertList){
    this.CertFile={
      DataKey:Cer.DataKey,
      SerialNo:Cer.SerialNo,
      Code:Cer.Code,
      FileData:{Info:false,FileName:"",Base64:""}
    };

    this.fileExchangeServices.ExportFile("ACAD/EMP/ADEMP0301/Get_EMP_Cert_FileDownload", this.CertFile).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning("無附件");
        return;
      }

      if (response.FileName == '') {
        this.toastr.warning("查無資料");
        return;
      }

      let url = window.URL.createObjectURL(response.blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = response.FileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => console.log(error.error))
  }

  importAttFile($event: any, dataKey: string, type: string) {
    let file = $event.target.files[0];
    if (!file) return;
    this.fileExchange.FileToBase64(this, file, this.GetFileBase64, dataKey, type)
  }

  GetFileBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
    Swal.fire({
        title: '確定上傳 ' + file.name + ' ?',
        showDenyButton: true,
        confirmButtonText: '是',
        denyButtonText: '否',
    }).then((result) => {
        if (result.isConfirmed) {
          that.CertFile.FileData.FileName=file.name;
          that.CertFile.FileData.Base64=base64;
        }
        else if (result.isDenied) {
            let file = <HTMLInputElement>document.querySelector('#file');
            file.value = '';
            that.toastr.info("已取消上傳。");
            that.CertFile.FileData.FileName="";
            that.CertFile.FileData.Base64="";
        }
    });
  }

  getCertFileUpload(){
    this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0301/Get_EMP_Cert_FileUpload", this.CertFile).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("附件上傳成功!");
      this.getCertList();
      this.modalRef?.hide();
    })
  }

  getCertFileDelete(Cer:GetEMPCertList){
    this.CertFile={
      DataKey:Cer.DataKey,
      SerialNo:Cer.SerialNo,
      Code:Cer.Code,
      FileData:{Info:false,FileName:"",Base64:""}
    };

    Swal.fire({
        title: '確定進行刪除？',
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '確定'
    }).then(result => {
        if (result.isConfirmed) {
          this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0301/Get_EMP_Cert_FileDelete", this.CertFile).subscribe((response) => {
            if (response.Info == false) {
              this.toastr.warning(response.Message);
              return;
            }
            this.toastr.success("附件刪除完成!");
            this.getCertList();
          });
        } else {
            this.toastr.info('動作已取消');
        }
    })
  }
}
