import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { Week } from 'src/app/_Models/week';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0202',
    templateUrl: './TCH0202.component.html',
    styleUrls: ['./TCH0202.component.css']
})
export class TCH0202Component implements OnInit {
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    data!: Get_Cur_List[];
    functionType = "tchRollCall";
    code!: string;
    week!: Week[];
    nowYear = '';
    nowTerm = '';
    selectedWeek: string = ''
    constructor(private http: HttpClient, private toastr: ToastrService, private accountService: AccountService,
        private systemService: SystemService, private timeService: TimeService) { }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.code = response.Code;
            }
        });

        this.timeService.getTimeCntr().subscribe((response) => {
            this.nowTerm = response.Now_Term;
            this.nowYear = response.Now_Years;
            this.getCurList({ Years: this.nowYear, Term: this.nowTerm })
            this.getWeek();
        });
    }

    getCurList(model: any) {

        // 給周次onchange用
        this.nowYear = model.Years;
        this.nowTerm = model.Term;
        model.WeekIndex = this.selectedWeek;
        model.KeyValue = this.code;
        model.FormType = '06';
        this.http.post<Get_Cur_List[]>(this.baseUrl + "Public/Get_CurForm", model)
            .subscribe((response) => {
                this.data = response;
            },
                error => this.toastr.error(error.error)
            );
    }

    getWeek() {
        this.systemService.getWeek(this.nowYear, this.nowTerm).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.week = response;
        },
            error => this.toastr.error(error.error)
        );
    }
}
