<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year formControlName="Years">
                        <option value="">--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term formControlName="Term">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" #edu
                        (change)="getDeptList(edu.value)" formControlName="EduNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                            {{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" #dept formControlName="DeptNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">
                            {{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="other-Button-gray float-end" (click)="openModal(this.thisGRDCETList,template)">新增</button>&nbsp;&nbsp;
                <button class="search-btn float-end me-3" (click)="doGetGRDCETList()">查詢</button>&nbsp;&nbsp;
            </div>         
        </div>
    </form>
    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 120px;">班級名稱</th>
                <th style="width: 80px;">學號</th>
                <th style="width: 100px;">學生姓名</th>
                <th style="width: 340px;">論文名稱</th>
                <th style="width: 80px;">口試學年期</th>
                <th style="width: 80px;">口試日期</th>
                <th style="width: 60px;">成績</th>
                <th style="width: 60px;"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of GRDCETList">
                <td style="width: 120px;">{{item.ClassName}}</td>
                <td style="width: 80px;">{{item.StdNo}}</td>
                <td style="width: 100px;">{{item.StdName}}</td>
                <td style="width: 340px;text-align: left;">{{item.ThesisName}}</td>
                <td style="width: 80px;">{{item.Years}}{{item.Term}}</td>
                <td style="width: 80px;">{{item.TestingDate}}</td>
                <td style="width: 60px;">{{item.ThesisScore}}</td>
                <td style="width: 60px;"><a class="fw-bolder text-decoration-none pointer"
                        (click)="openModal(item,template)"><span class="icon-Fill-in-button"></span>編輯</a></td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">論文資料維護</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="GetGRDCETDetail.StdNo" #StdNo
                        [disabled]="!isNewData">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">姓名</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="GetGRDCETDetail.StdName" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="GetGRDCETDetail.ClassName" disabled>
                </div>
            </div>            
        </div>    
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">口試學年</span>
                    </div>
                    <select class="form-select shadow-none" #RestYear [(ngModel)]="GetGRDCETDetail.Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">口試學期</span>
                    </div>
                    <select class="form-select shadow-none" #RestTerm [(ngModel)]="GetGRDCETDetail.Term">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md">
                <label for="">論文名稱</label>
                <textarea class="form-control shadow-none" [(ngModel)]="GetGRDCETDetail.ThesisName"
                    appTextareaAutoresize></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">口試日期</span>
                    </div>
                    <input type="text" placeholder="民國年,輸入3碼數字" class="form-control pointer shadow-none" 
                        [(ngModel)]="GetGRDCETDetail.TestingDateY"
                        style="background-color: transparent;" maxlength="3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">年</span>
                    </div>                        
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <input type="text" placeholder="輸入2碼數字" class="form-control pointer shadow-none" 
                        [(ngModel)]="GetGRDCETDetail.TestingDateM"
                        style="background-color: transparent;" maxlength="2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">月</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <input type="text" placeholder="輸入2碼數字" class="form-control pointer shadow-none" 
                        [(ngModel)]="GetGRDCETDetail.TestingDateD"
                        style="background-color: transparent;" maxlength="2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">日</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">成績</span>
                    </div>
                    <input type="text" placeholder="輸入數字0~100" class="form-control pointer shadow-none" 
                        [(ngModel)]="GetGRDCETDetail.ThesisScore"
                        style="background-color: transparent;" maxlength="3">                      
                </div>
            </div>            
        </div>
        <div class="row">
            <div class="col">
                <button class="other-Button-gray float-end  mx-auto" (click)="getCETDelete()">刪除</button>
                <button class="save-btn-gray float-end me-3 mx-auto" (click)="getCETModify()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>