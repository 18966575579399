<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getCUPCurPlanDetail()">
        <button #submit [hidden]="true"></button>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" formControlName="Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value, false, false, false)">
                        <option value="" selected disabled>--請選擇學制--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" #dept (change)="getTeamList(dept.value, false, false, false)" formControlName="DeptNo">
                        <option value="" selected disabled>--請選擇系所--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">組別</span>
                    <select class="form-select shadow-none" formControlName="TeamNo">
                        <option value="" selected disabled>--請選擇組別--</option>
                        <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </form>
    <div class="row mb-3">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="openModal('credits', gradCreditsTemplate)">畢業學分</button>
            <button class="other-Button-gray float-end me-3" (click)="getCUPCurPlanDeleteAll()">刪除全部</button>
            <button class="other-Button-gray float-end me-3" (click)="getCUPCurPlanDelete()">刪除單筆</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal('', copyTemplate)">複製課程</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal('edit', editTemplate)">修改</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal('new', newTemplate)">新增</button>
            <button class="search-btn float-end me-3" (click)="submit.click()">查詢</button>
        </div>
    </div>
    <tabset #tabs>
        <tab *ngFor="let item of cUPCurPlanDetail; let j = index" heading="{{item.PlanYear == ''? '未指定':item.Grade + '年級' + ' (' + item.PlanYear + '學年)'}}">
            <div class="row">
                <div class="col-lg-6">
                    <div class="card mt-3">
                        <div class="card-header">
                            上學期科目
                        </div>
                        <div class="card-body">
                            <table class="table table-hover table-striped text-center" *ngIf="item.Term1CosList.length != 0 else nothing">
                                <thead class="thead-dark-red">
                                    <tr>
                                        <th style="width:40px;"></th>
                                        <th style="width:120px;">代碼</th>
                                        <th>名稱</th>
                                        <th style="width:50px;">學分</th>
                                        <th style="width:50px;">時數</th>
                                        <th style="width:70px;">選修別</th>
                                        <!--<th>註記</th>-->
                                        <th style="width:50px;">群組</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="pointer" [ngClass]="{'':detail.Selected == false, 'row-selected-bg':detail.Selected == true}" *ngFor="let detail of item.Term1CosList; let i = index" (click)="chooseData(detail.DataKey, j, i, 1)">
                                        <td style="width:40px;">{{i + 1}}</td>
                                        <td style="width:120px;">{{detail.CosID}}</td>
                                        <td style="text-align:left;">{{detail.CosName}}</td>
                                        <td style="width:50px;text-align:center;">{{detail.Credits}}</td>
                                        <td style="width:50px;text-align:center;">{{detail.CosHours}}</td>
                                        <td style="width:70px;text-align:center;">{{detail.SelKindName}}</td>
                                        <!--<td>{{detail.SelStyle}}</td>-->
                                        <td style="width:50px;text-align:center;">{{detail.CosCluster}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card mt-3">
                        <div class="card-header">
                            下學期科目
                        </div>
                        <div class="card-body">
                            <table class="table table-hover table-striped text-center" *ngIf="item.Term2CosList.length != 0 else nothing">
                                <thead class="thead-dark-red">
                                    <tr>
                                        <th style="width:40px;"></th>
                                        <th style="width:120px;">代碼</th>
                                        <th>名稱</th>
                                        <th style="width:50px;">學分</th>
                                        <th style="width:50px;">時數</th>
                                        <th style="width:70px;">選修別</th>
                                        <!--<th>註記</th>-->
                                        <th style="width:50px;">群組</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="pointer" [ngClass]="{'':detail.Selected == false, 'row-selected-bg':detail.Selected == true}" *ngFor="let detail of item.Term2CosList; let i = index" (click)="chooseData(detail.DataKey, j, i, 2)">
                                        <td style="width:40px;">{{i + 1}}</td>
                                        <td style="width:120px;">{{detail.CosID}}</td>
                                        <td style="text-align:left;">{{detail.CosName}}</td>
                                        <td style="width:50px;text-align:center;">{{detail.Credits}}</td>
                                        <td style="width:50px;text-align:center;">{{detail.CosHours}}</td>
                                        <td style="width:70px;text-align:center;">{{detail.SelKindName}}</td>
                                        <!--<td>{{detail.SelStyle}}</td>-->
                                        <td style="width:50px;text-align:center;">{{detail.CosCluster}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
    </tabset>
</div>

<ng-template #nothing>
    <h2 class="text-danger text-center">暫無資料</h2>
</ng-template>

<ng-template #newTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">新增標準課程</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none shadow-none" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormNewInput" (ngSubmit)="getCosData()">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學制</span>
                        <select class="form-select shadow-none" #edu (ngModelChange)="getDeptList(edu.value, true, false, false)" formControlName="EduNo">
                            <option value="" selected disabled>--請選擇學制--</option>
                            <option *ngFor="let item of newEduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" formControlName="DeptNo">
                            <option value="" disabled>--請選擇系所--</option>
                            <option *ngFor="let item of newDeptList" value="{{item.Dept_No}}">
                                {{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">狀態</span>
                        <select class="form-select shadow-none" formControlName="Status">
                            <option value="" disabled>--請選擇--</option>
                            <option value="1">啟用</option>
                            <option value="0">未啟用</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group mb-3">
                        <span class="input-group-text">關鍵字</span>
                        <input type="text" class="form-control shadow-none" formControlName="KeyWord">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="search-btn">查詢</button>
                </div>
            </div>
        </form>
        <hr>
        <form [formGroup]="ngFormNew" (ngSubmit)="getCUPCurPlanAddNew()">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學年</span>
                        <select class="form-select shadow-none" formControlName="Years">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" #dept (change)="getTeamList(dept.value, false, false, false)" formControlName="DeptNo">
                            <option value="" selected disabled>--請選擇系所--</option>
                            <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">組別</span>
                        <select class="form-select shadow-none" formControlName="TeamNo">
                            <option value="" selected>--請選擇組別--</option>
                            <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">年級</span>
                        <select class='form-select shadow-none' formControlName="Grade">
                            <option value="">--請選擇--</option>
                            <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學期</span>
                        <select class='form-select shadow-none' formControlName="Term">
                            <option value="">--請選擇--</option>
                            <option value="1">上學期</option>
                            <option value="2">下學期</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">群組</span>
                        <select class='form-select shadow-none' formControlName="CosCluster">
                            <option value="">--請選擇--</option>
                            <option *ngFor="let item of group" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">應過科目數</span>
                        <input type="number" [min]="0" class="form-control shadow-none" formControlName="MustPassNum">
                    </div>
                </div>
                <div class="col-md-3">
                    <button class="other-Button-gray">儲存</button>
                </div>
            </div>
        </form>

        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th></th>
                    <th>科目代碼</th>
                    <th>科目名稱</th>
                    <th>學分</th>
                    <th>選修別</th>
                    <th>開課學期別</th>
                    <th>狀態</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of cUPPermanentList">
                    <td><input type="checkbox" class="pointer form-check-input" (click)="setDatakey('', item.DataKey, $event)"></td>
                    <td>{{item.CosID}}</td>
                    <td>{{item.CosName}}</td>
                    <td>{{item.CosCredits}}</td>
                    <td>{{item.SelKindName}}</td>
                    <td>{{item.CosTerm}}</td>
                    <td>{{item.Status}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #editTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">修改標準課程</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none shadow-none" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormEdit" (ngSubmit)="getCUPCurPlanModify()">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">科目代碼</span>
                        <input type="text" class="form-control shadow-none" formControlName="CosID">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">科目名稱</span>
                        <input type="text" class="form-control shadow-none" formControlName="CosName">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">必選修別</span>
                        <input type="text" class="form-control shadow-none" formControlName="SelKindName">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學分數</span>
                        <input type="text" class="form-control shadow-none" formControlName="Credits">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">時數</span>
                        <input type="text" class="form-control shadow-none" formControlName="CosHours">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">年級</span>
                        <select class='form-select shadow-none' formControlName="Grade">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學期</span>
                        <select class='form-select shadow-none' formControlName="PlanTerm">
                            <option value="" disabled>--請選擇--</option>
                            <option value="1">上學期</option>
                            <option value="2">下學期</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">課程類別</span>
                        <input type="text" class="form-control shadow-none" formControlName="CurClass">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">群組</span>
                        <select class='form-select shadow-none' formControlName="CosCluster">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of group" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">應過科目數</span>
                        <input type="number" [min]="0" class="form-control shadow-none" formControlName="MustPassNum">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-check form-check-inline mb-3">
                        <input class="form-check-input pointer shadow-none" type="checkbox" id="SelOpenLog" formControlName="SelOpenLog">
                        <label class="form-check-label pointer" for="SelOpenLog">限制當學期開課</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" formControlName="UpdateUser">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" formControlName="UpdateTime">
                    </div>
                </div>
                <div class="col-md-3">
                    <button class="save-btn-gray">儲存</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #copyTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">複製標準課程檔</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormCopy" (ngSubmit)="getCUPCurPlanCopyTo()">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">來源課程</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">學年</span>
                                        <select class="form-select shadow-none" formControlName="Years">
                                            <option value="" disabled>--請選擇--</option>
                                            <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">學制</span>
                                        <select class="form-select shadow-none" #copyEdu (change)="getDeptList(copyEdu.value, false, true, false)">
                                            <option value="" selected disabled>--請選擇學制--</option>
                                            <option *ngFor="let item of copyEduList" value="{{item.Edu_No}}">
                                                {{item.Edu_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">系所</span>
                                        <select class="form-select shadow-none" #copyDept (change)="getTeamList(copyDept.value, false, true, false)" formControlName="DeptNo">
                                            <option value="" selected disabled>--請選擇系所--</option>
                                            <option *ngFor="let item of copyDeptList" value="{{item.Dept_No}}">
                                                {{item.Dept_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">組別</span>
                                        <select class="form-select shadow-none" formControlName="TeamNo">
                                            <option value="" selected>--請選擇組別--</option>
                                            <option *ngFor="let item of copyTeamList" value="{{item.Team_No}}">
                                                {{item.Team_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">建立目標</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">學年</span>
                                        <select class="form-select shadow-none" formControlName="TargetYears">
                                            <option value="" disabled>--請選擇--</option>
                                            <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">學制</span>
                                        <select class="form-select shadow-none" #targetEdu (change)="getDeptList(targetEdu.value, false, true, true)">
                                            <option value="" selected disabled>--請選擇學制--</option>
                                            <option *ngFor="let item of targetEduList" value="{{item.Edu_No}}">
                                                {{item.Edu_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">系所</span>
                                        <select class="form-select shadow-none" #targetDept (change)="getTeamList(targetDept.value, false, true, true)" formControlName="TargetDeptNo">
                                            <option value="" selected disabled>--請選擇系所--</option>
                                            <option *ngFor="let item of targetDeptList" value="{{item.Dept_No}}">
                                                {{item.Dept_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">組別</span>
                                        <select class="form-select shadow-none" formControlName="TargetTeamNo">
                                            <option value="" selected>--請選擇組別--</option>
                                            <option *ngFor="let item of targetTeamList" value="{{item.Team_No}}">
                                                {{item.Team_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <button class="save-btn-gray mx-auto">儲存</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #gradCreditsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">畢業學分設定</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none shadow-none" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div style="height:360px;overflow-y:scroll;">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width:auto;text-align:center;">項目</th>
                    <th style="width:110px;text-align:center;">學分/科目數</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of gradCreditsList" [ngStyle]="{'display':item.Show == false?'none':'compact'}">
                    <td style="width:auto;text-align:center;"><p style="margin:3px;">{{item.SelkindName}}</p></td>
                    <td style="width:110px;text-align:center;">
                        <input type="text"
                        class="form-control form-control-sm shadow-none text-center"
                        [(ngModel)]="item.Values" maxlength="3">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style="height:210px;overflow-y:hidden;">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width:auto;text-align:center;">備註</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of gradCreditsList" [ngStyle]="{'display':item.Show == false?'compact':'none'}">
                    <td style="width:auto;text-align:center;">
                        <textarea style="width:99%;height:140px;" [(ngModel)]="item.Memo">{{item.Memo}}</textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="mt-3" style="height:50px;">
        <button class="save-btn-gray mx-auto" (click)="saveGradCredits()">儲存</button>
    </div>
</ng-template>
