import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetEDocFlow } from 'src/app/_Models/getEDocFlow';
import { GetSTDRecoverDetail } from 'src/app/_Models/STDWEB/STDW10010/getSTDRecoverDetail';
import { GetSTDRecoverList } from 'src/app/_Models/STDWEB/STDW10010/getSTDRecoverList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-STDW10010',
    templateUrl: './STDW10010.component.html',
    styleUrls: ['./STDW10010.component.css']
})
export class STDW10010Component implements OnInit {
    modalRef!: BsModalRef;
    check: false | undefined;
    baseUrl = environment.apiUrl;
    sTDRecoverList!: GetSTDRecoverList[];
    sTDRecoverDetail!: GetSTDRecoverDetail;
    term = require('../../_Models/_SelectInput/term.json').term;
    Years_Range: string[] = [];
    checkType = new CheckType();
    eDocFlow!: GetEDocFlow[];
    showButton: boolean = false;
    subpageconfig = { backdrop: true, ignoreBackdropClick: true, class: "modal-lg" };


    constructor(
        private modalService: BsModalService,
        private http: HttpClient,
        private toastr: ToastrService,
        private domSanitizer: DomSanitizer,
        private systemService: SystemService,
        private translateService: TranslateService
    ) {

    }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage);
        this.getSTDRecoverList();
    }

    openDetail(template: TemplateRef<any>, dataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_10/STD_Recover_Detail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
            } else {
                this.sTDRecoverDetail = response;

                for (let i = Number(this.sTDRecoverDetail.RestYear); i <= Number(this.sTDRecoverDetail.EndYear); i++) {
                    this.Years_Range.push(i.toString());
                }

                switch (this.sTDRecoverDetail.Status) {
                    case '':
                        this.showButton = true;
                        break;
                    case '0':
                        this.showButton = true;
                        break;
                    case '4':
                        this.showButton = true;
                        break;
                    default:
                        this.showButton = false;
                        break;
                }

                this.modalRef = this.modalService.show(template, this.subpageconfig);
            }
        });
    }

    openFlow(template: TemplateRef<any>, dataKey: string) {
        const senddata = this.http.post<any>(this.baseUrl + "Public/Get_EDoc_Flow", { DataKey: dataKey });
        senddata.subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eDocFlow = response;
            this.modalRef = this.modalService.show(template, this.subpageconfig);
        }, error => this.toastr.error(error.error));
    }

    getSTDRecoverList() {
        this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW100_10/STD_Recover_List").subscribe((response) => {
            this.sTDRecoverList = []
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sTDRecoverList = response;
        }, error => this.toastr.error(error.error));
    }

    getSTDRecoverSave(type: string) {
        if (this.checkType.CheckIsNumber(String(this.sTDRecoverDetail.Years))) {
            this.toastr.info("復學年請填入數字");
            return;
        }

        if (this.sTDRecoverDetail.Term == '') {
            this.toastr.info("請選擇復學學期");
            return;
        }

        if (
            Number(this.sTDRecoverDetail.Years + this.sTDRecoverDetail.Term) < Number(this.sTDRecoverDetail.RestYear + this.sTDRecoverDetail.RestTerm)
            || Number(this.sTDRecoverDetail.Years + this.sTDRecoverDetail.Term) > Number(this.sTDRecoverDetail.EndYear + this.sTDRecoverDetail.EndTerm)
        ) {
            this.toastr.info("數字不在學年範圍內");
            return;
        }

        if (type == 'save') {
            this.sTDRecoverDetail.Send = '0'
            this.sTDRecoverDetail.Years = String(this.sTDRecoverDetail.Years);
            console.log(this.sTDRecoverDetail)
            this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW100_10/STD_Recover_Save', this.sTDRecoverDetail).subscribe((response) => {
                for (let i = 0; i < 2; i++) {
                    if (response[i].Info == false) {
                        this.toastr.warning(response[i].Message);
                        return;
                    }
                }
                this.toastr.success("儲存成功");
                this.modalRef.hide();
                this.getSTDRecoverList();
            })
        }
        else {
            Swal.fire({
                title: "一旦送出即不能修改，確定?",
                showDenyButton: true,
                confirmButtonText: '是',
                denyButtonText: '否',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.sTDRecoverDetail.Send = '1';

                    this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW100_10/STD_Recover_Save', this.sTDRecoverDetail).subscribe((response) => {
                        for (let i = 0; i < 2; i++) {
                            if (response[i].Info == false) {
                                this.toastr.warning(response[i].Message);
                                return;
                            }
                        }
                        this.toastr.success("送出成功");
                        this.modalRef.hide();
                        this.getSTDRecoverList();
                    })
                }
                else {
                    this.toastr.info("已取消");
                }
            })
        }
    }

    getSTDRecoverDelete() {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_10/STD_Recover_Delete", this.sTDRecoverList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.getSTDRecoverList();
        })
    }

    getEDocFlow(dataKey: string) {
        this.http.post<any>(this.baseUrl + "Public/Get_EDoc_Flow", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eDocFlow = response;
        }, error => this.toastr.error(error.error))
    }


}
