import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { Data } from'src/app/_Models/AD/ADSRP/ADSRP0103/Data';
import { SearchData03 } from'src/app/_Models/AD/ADSRP/ADSRP0103/searchData';
import { SearchData } from'src/app/_Models/AD/ADSRP/ADSRP0102/searchData';
@Component({
    selector: 'app-ADSRP0103',
    templateUrl: './ADSRP0103.component.html',
    styleUrls: ['./ADSRP0103.component.css']
})
export class ADSRP0103Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    url=environment.apiUrl;
    modalRef?: BsModalRef;
    data!: Data[];
    data_detail!:SearchData[];
    search_data:Data={
        R_Code: '',
        SugCode: '',
        SugName: '',
        K_Delete: 0,
        checked: false,
        K_PC_EmpID: ''
    }
    insert_data:Data={
        R_Code: '',
        SugCode: '',
        SugName: '',
        K_Delete: 0,
        checked: false,
        K_PC_EmpID: ''
    }
    ngOnInit(): void {
        this.detail();

    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService, private modalService: BsModalService) { }


    setDatakey(event: EventTarget | null) {
        let input = <HTMLInputElement>event;
        if(input.checked==true){
            this.data.forEach(m=>m.K_Delete=1)
            this.data.forEach(m=>m.checked=true)
        }
        else{
            this.data.forEach(m=>m.K_Delete=0)
            this.data.forEach(m=>m.checked=false)
        }
    }

    choose_delete(index:number,num:number){
        if(num==0){
            this.data[index].K_Delete=1;
        }
        else{
            this.data[index].K_Delete=0;
        }
    }

    detail(){
        this.http.get<any>(this.url+"ACAD/SRP/ADSRP0102/SRP0102_search").subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
          this.data_detail=response;
        })
    }

    search(){
        this.http.post<any>(this.url+"ACAD/SRP/ADSRP0103/SRP0103_search",this.search_data).subscribe(response=>{
            this.data=[];
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data=response;
        })
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    save(){
        this.http.post<any>(this.url+"ACAD/SRP/ADSRP0103/SRP0103_insert",this.insert_data).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search();
        })
    }

    update(){
        this.http.post<any>(this.url+"ACAD/SRP/ADSRP0103/SRP0103_update",this.data).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.search();
        })
    }

    delete(){
        for(let y of this.data)
        {
            if(y.K_Delete==1){
                this.http.post<any>(this.url+"ACAD/SRP/ADSRP0103/SRP0103_delete",{"R_Code":y.R_Code,"SugCode":y.SugCode,"SugName":y.SugName,"K_Delete":y.K_Delete}).subscribe(response=>{
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.search();
                })
            }
        }
    }
}
