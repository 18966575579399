import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { parseDate } from 'ngx-bootstrap/chronos';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/assets/environments/environment';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { ResultOutput,ContrastUserType } from 'src/app/_Models/AD/ADSTD/ADSTD0601ISU01/getData';

@Component({
    selector: 'app-STDW30010',
    templateUrl: './STDW30010.component.html',
    styleUrls: ['./STDW30010.component.css']
})

export class STDW30010Component implements OnInit {
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    stdList!: ResultOutput[];
    stdSelects!: ResultOutput[];
    stdSelect!: ResultOutput;
    UserType!:ContrastUserType;
    thisUserType:string="";
    EnableConfirm:boolean=false;
    EnableQuery:boolean=false;

    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl({ value: ''}, Validators.required),
        Term: new UntypedFormControl({ value: ''}, Validators.required),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        TeamNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        StdNoFrom: new UntypedFormControl(''),
        StdNoTo: new UntypedFormControl(''),
        Status: new UntypedFormControl(''),
        DataKey: new UntypedFormControl('')
    });

    model = {
        Years: '',
        Term: '',
        SDate:'',
        EDate:'',
        OpenSDate:'',
        OpenEDate:''
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Contrast_Years;
            this.model.Term = response.Contrast_Term;
            this.model.SDate = response.Contrast_StartTime;
            this.model.EDate = response.Contrast_EndTime;
            this.model.OpenSDate = response.Contrast_OpenStartTime;
            this.model.OpenEDate = response.Contrast_OpenEndTime;

            let thisSDate=new Date(this.model.SDate);
            let thisEDate=new Date(this.model.EDate);
            let thisOpenSDate=new Date(this.model.OpenSDate);
            let thisOpenEDate=new Date(this.model.OpenEDate);
            let thisNow=new Date();

            if(thisNow>=thisSDate && thisNow<=thisEDate){
                this.EnableConfirm=true;
            };

            if(thisNow>=thisOpenSDate && thisNow<=thisOpenEDate){
                this.EnableQuery=true;
            };
        });

        this.getUserType();
    }

    getUserType(){
        this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW300_10/Get_ADSTD0601_ISU01_UserKey").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.UserType = response;
            this.thisUserType=this.UserType.UserType;
            this.getStdList();
        })
    }

    getStdList(){
        this.stdList = [];
        this.ngFormInput.get("Years")?.setValue(this.model.Years);
        this.ngFormInput.get("Term")?.setValue(this.model.Term);
        this.ngFormInput.get("DataKey")?.setValue(this.UserType.DataKey);
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW300_10/Get_ADSTD0601_ISU01_StdList", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.stdList = response;

            this.stdSelect=this.stdList[0];

            this.getContrastResultData(this.stdSelect);
        })
    }

    getContrastResultData(thisStd: ResultOutput){
        this.stdSelect=thisStd;
        this.stdSelect.UserType=this.UserType.UserType;
        this.stdSelects=[];
        this.stdSelects.push(thisStd);
    }
}
