import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0102',
    templateUrl: './TCH0102.component.html',
    styleUrls: ['./TCH0102.component.css']
})
export class TCH0102Component implements OnInit {
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    data!: Get_Cur_List[];
    functionType = "tchCurForm";
    code!: string;
    model = {
        Years: '',
        Term: ''
    }
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private accountService: AccountService,
        private timeService: TimeService, private systemService: SystemService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.code = response.Code;
            }
        });
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
            this.getCurList(this.model);
        });
    }

    getCurList(model: any) {
        model.KeyValue = this.code;
        model.FormType = '03';
        this.http.post<Get_Cur_List[]>(this.baseUrl + "Public/Get_CurForm", model)
            .subscribe((response) => {
                this.data = response;
            },
                error => this.toastr.error(error.error)
            )
    }

}
