<div class="container">
    <table class="table table-borderless text-center">
        <thead>
            <tr>
                <th colspan="3"><h2>統一收據</h2></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colspan="3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">撥存帳戶</span>
                        </div>
                        <input type="text" class="form-control shadow-none">
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">經辦人</span>
                        </div>
                        <input type="text" class="form-control shadow-none">
                    </div>
                </td>
                <td>
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">主辦出納</span>
                        </div>
                        <input type="text" class="form-control shadow-none">
                    </div>
                </td>
                <td>
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">撥存帳戶</span>
                        </div>
                        <input type="text" class="form-control shadow-none">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">繳費單備註</span>
                        </div>
                        <textarea rows="3" style="resize: none;" type="text" class="form-control shadow-none"></textarea>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">修改者</span>
                        </div>
                        <input type="text" class="form-control shadow-none" readonly>
                    </div>
                </td>
                <td>
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">修改時間</span>
                        </div>
                        <input type="text" class="form-control shadow-none" readonly>
                    </div>
                </td>
                <td>
                    <button class="modify-btn">修改</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
