<div class="container">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">樣式</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="ReportType">
                        <option value="C">中文</option>
                        <option value="E">英文</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學籍狀態</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Status" #status (change)="clearall(status.value)">
                        <option value="" selected disabled>請選擇</option>
                        <option *ngFor="let item of sTDStatusList" [value]="item.Status_No">{{item.Status_Name}}
                        </option>
                    </select>
                    <div class="form-check mt-2 ms-2" *ngIf="status.value == '在學'">
                        <input class="form-check-input shadow-none pointer" type="checkbox" id="flexCheckDefault"
                            formControlName="Grad">
                        <label class="form-check-label pointer" for="flexCheckDefault">預設畢業</label>
                    </div>
                </div>
            </div>
            <div class="col-md-3" *ngIf="status.value == '退學' || status.value == '離校' || status.value == '畢業'">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year (change)="getEduList()" formControlName="Years">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>

            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value)"
                        formControlName="EduNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                            {{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" #Dept formControlName="DeptNo" (change)="getClassList(status.value,edu.value,Dept.value)">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoTo">
                </div>
            </div>
            <div class="col-md-3">
                <button class="export-btn" (click)="export_Report_ADGRD0602()">匯出</button>
            </div>        
        </div>
    </form>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th><input type="checkbox" class="form-check-input shadow-none pointer"
                        (click)="setAll($event.target)">&ensp;全選</th>
                <th>班級代碼</th>
                <th>班級名稱</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of classList; let i = index">
                <td>{{i + 1}}</td>
                <td><input type="checkbox" class="form-check-input shadow-none pointer" [(ngModel)]="item.checked"></td>
                <td>{{item.ItemValue}}</td>
                <td>{{item.ItemName}}</td>
            </tr>
        </tbody>
    </table>
</div>