
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADSSS0101',
  templateUrl: './ADSSS0101.component.html',
  styleUrls: ['./ADSSS0101.component.css']
})
export class ADSSS0101Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}