<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getCURArrangeList()">
        <button #submit [hidden]="true"></button>
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Years formControlName="Years"
                        (change)="getDeptList(Years.value, Term.value)" [(ngModel)]="cUROpen01Time.Years">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of YearsList" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term formControlName="Term"
                        (change)="getDeptList(Years.value, Term.value)" [(ngModel)]="cUROpen01Time.Term">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" #Dept
                        (change)="getClassList(Dept.value, Years.value, Term.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>          
        </div>
    </form>
    <div class="row">
        <div class="col-md-8">
            <div class="RowHeight50">
                排課作業時間:<strong>{{cUROpen01Time.Years}}</strong>學年第<strong>{{cUROpen01Time.Term}}</strong>學期，期間：<strong>{{cUROpen01Time.Times}}</strong>
                <small class="text-danger">　*點選資料行可查詢排課詳細資料</small>    
            </div>
        </div>
        <div class="col-md-4">
            <button class="other-Button-gray float-end" (click)="getCURArrangeDelete('all', $event)">取消排課</button>
            <button class="other-Button-gray float-end me-3" (click)="getCURArrangeModify()">儲存</button>
            <button class="search-btn float-end me-3" (click)="submit.click()">查詢</button>
        </div>          
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-sm table-striped table-hover text-center table-responsive-xxl">
                <thead class="thead-dark-red">
                    <tr>
                        <th class="ColW30"></th>
                        <th class="ColW30 ColVAlignMiddle">已排</th>
                        <th class="ColW30 ColVAlignMiddle">序號</th>
                        <th class="ColW60 ColVAlignMiddle">組別</th>
                        <th class="ColW90 ColVAlignMiddle">代碼</th>
                        <th class="ColW140 ColVAlignMiddle">名稱</th>
                        <th class="ColW40 ColVAlignMiddle">必選修</th>
                        <th class="ColW40 ColVAlignMiddle">學分</th>
                        <th class="ColW40 ColVAlignMiddle">授課時數</th>
                        <th class="ColW40 ColVAlignMiddle">已排時數</th>
                        <th class="ColW40 ColVAlignMiddle">檢查衝堂</th>
                        <th class="ColW40 ColVAlignMiddle">單雙週</th>
                        <th class="ColW30 ColVAlignMiddle">停開</th>
                        <th class="ColW30 ColVAlignMiddle">人數</th>                            
                        <th class="ColW150 ColVAlignMiddle">上課節次</th>
                        <th class="ColW90 ColVAlignMiddle">教師</th>
                        <th class="ColW80 ColVAlignMiddle">教室</th>
                        <th class="ColW100 ColVAlignMiddle">備註</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="cURArrangeList">
                        <tr *ngFor="let item of cURArrangeList.CosData; let i = index" class="pointer"
                            [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                            (click)="getCURArrangeDetail(item.DataKey, i)">
                            <td class="ColW30">{{i + 1}}</td>
                            <td class="ColW30 ColVAlignMiddle">{{item.Arrange}}</td>
                            <td class="ColW30 ColVAlignMiddle">{{item.Serial}}</td>
                            <td class="ColW60 ColVAlignMiddle">{{item.TeamName}}</td>
                            <td class="ColW90 ColVAlignMiddle">{{item.CosID}}</td>
                            <td class="ColW140 ColVAlignMiddle ColTxtAlignLeft">{{item.CosName}}</td>
                            <td class="ColW40 ColVAlignMiddle">{{item.SelKindName}}</td>
                            <td class="ColW40 ColVAlignMiddle">{{item.Credits}}</td>
                            <td class="ColW40 ColVAlignMiddle">{{item.Hours}}</td>
                            <td class="ColW40 ColVAlignMiddle">{{item.ArrangeHours}}</td>
                            <td class="ColW40 ColVAlignMiddle"><input type="checkbox" class="form-check-input shadow-none pointer"
                                    [(ngModel)]="item.ChkTime"></td>
                            <td class="ColW40 ColVAlignMiddle">{{item.WeekMark}}</td>
                            <td class="ColW30 ColVAlignMiddle">{{item.StopMark}}</td>
                            <td class="ColW30 ColVAlignMiddle">{{item.ElectNum}}</td>                                        
                            <td class="ColW150 ColVAlignMiddle"><input type="text" class="form-control form-control-sm shadow-none"
                                    [(ngModel)]="item.CurTime" 
                                    (ngModelChange)="setCurTime(i)"></td>
                            <td class="ColW90 ColVAlignMiddle">{{item.TchName}}</td>
                            <td class="ColW80 ColVAlignMiddle">{{item.RoomName}}</td>
                            <td class="ColW100 ColVAlignMiddle ColTxtAlignLeft ColFontSize10">{{item.Memo}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <span class="text-danger">*雙擊課表可以取消當節排課</span>
            <table class="table table-sm table-bordered text-center" id="curTime">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>一</th>
                        <th>二</th>
                        <th>三</th>
                        <th>四</th>
                        <th>五</th>
                        <th>六</th>
                        <th>日</th>
                    </tr>
                </thead>
                <tbody class="RowHeight500">
                    <ng-container *ngIf="cURArrangeList">
                        <tr *ngFor="let item of cURArrangeList.CurForm">
                            <td>{{item.SectionName}}</td>
                            <td id="{{'1'+item.Section}}" name="curTd" class="pointer" container="body"
                                (onShown)="onShown(item.Week01)" [popover]="popTemplate"
                                triggers="mouseenter:mouseleave" (dblclick)="getCURArrangeDelete('', $event)">
                                {{item.Week01?.length || ''}}
                            </td>
                            <td id="{{'2'+item.Section}}" name="curTd" class="pointer" container="body"
                                (onShown)="onShown(item.Week02)" [popover]="popTemplate"
                                triggers="mouseenter:mouseleave" (dblclick)="getCURArrangeDelete('', $event)">
                                {{item.Week02?.length || ''}}
                            </td>
                            <td id="{{'3'+item.Section}}" name="curTd" class="pointer" container="body"
                                (onShown)="onShown(item.Week03)" [popover]="popTemplate"
                                triggers="mouseenter:mouseleave" (dblclick)="getCURArrangeDelete('', $event)">
                                {{item.Week03?.length || ''}}
                            </td>
                            <td id="{{'4'+item.Section}}" name="curTd" class="pointer" container="body"
                                (onShown)="onShown(item.Week04)" [popover]="popTemplate"
                                triggers="mouseenter:mouseleave" (dblclick)="getCURArrangeDelete('', $event)">
                                {{item.Week04?.length || ''}}
                            </td>
                            <td id="{{'5'+item.Section}}" name="curTd" class="pointer" container="body"
                                (onShown)="onShown(item.Week05)" [popover]="popTemplate"
                                triggers="mouseenter:mouseleave" (dblclick)="getCURArrangeDelete('', $event)">
                                {{item.Week05?.length || ''}}
                            </td>
                            <td id="{{'6'+item.Section}}" name="curTd" class="pointer" container="body"
                                (onShown)="onShown(item.Week06)" [popover]="popTemplate"
                                triggers="mouseenter:mouseleave" (dblclick)="getCURArrangeDelete('', $event)">
                                {{item.Week06?.length || ''}}
                            </td>
                            <td id="{{'7'+item.Section}}" name="curTd" class="pointer" container="body"
                                (onShown)="onShown(item.Week07)" [popover]="popTemplate"
                                triggers="mouseenter:mouseleave" (dblclick)="getCURArrangeDelete('', $event)">
                                {{item.Week07?.length || ''}}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="col-md-7">
            <span class="text-danger">*上課節次空白將會自動帶入課程節次</span>
            <table class="table-hover table-sm table table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>教師代碼</th>
                        <th>教師姓名</th>
                        <th>應排時數</th>
                        <th>已排時數</th>
                        <th class="ColW150">上課節次</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="cURArrangeDetail">
                        <tr *ngFor="let item of cURArrangeDetail.TchList; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{item.TchNo}}</td>
                            <td>{{item.TchName}}</td>
                            <td>{{item.Hours}}</td>
                            <td>{{item.ArrangeHours}}</td>
                            <td class="ColW150"><input type="text"
                                    class="form-control form-control-sm shadow-none"
                                    [(ngModel)]="item.CurTime"></td>
                            <td><a class="pointer text-decoration-none"
                                    (click)="openModal(tchTemplate, 'teacher', i)">置換教師</a></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <table class="table-hover table-sm table table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>教室代碼</th>
                        <th>教室名稱</th>
                        <th>應排時數</th>
                        <th>已排時數</th>
                        <th class="ColW150">上課節次</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="cURArrangeDetail">
                        <tr *ngFor="let item of cURArrangeDetail.ClassroomList; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{item.Classroom}}</td>
                            <td>{{item.ClassroomName}}</td>
                            <td>{{item.Hours}}</td>
                            <td>{{item.ArrangeHours}}</td>
                            <td class="ColW150"><input type="text"
                                    class="form-control form-control-sm shadow-none"
                                    [(ngModel)]="item.CurTime"></td>
                            <td><a class="pointer text-decoration-none"
                                    (click)="openModal(classTemplate, 'classRoom', i)">置換教室</a></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #popTemplate>
    <div [innerHtml]="htmlTemplate"></div>
</ng-template>

<ng-template #tchTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢教師</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <app-SearchTeacher id="SearchTeacher" (triggerEvent)="SelectTch($event)" [DeptNo]="thisDeptNo"></app-SearchTeacher>
</ng-template>

<ng-template #classTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢教室</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th class="ColW40"></th>
                    <th>所屬大樓</th>
                    <th>教室型態</th>
                    <th>教室代碼</th>
                    <th>教室名稱</th>
                </tr>
            </thead>
            <tbody class="RowHeight500">
                <tr *ngFor="let item of data; let i = index" class="pointer"
                    (click)="getCURArrangeReplaceClassroom(item.Classroom)">
                    <td class="ColW40">{{i + 1}}</td>
                    <td>{{item.ClassKind_Name}}</td>
                    <td>{{item.ClassType_Name}}</td>
                    <td>{{item.Classroom}}</td>
                    <td>{{item.Classroom_Name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>