import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-STDW10003',
  templateUrl: './STDW10003.component.html',
  styleUrls: ['./STDW10003.component.css']
})
export class STDW10003Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
