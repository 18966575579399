<div class="container">
    <div class="row">
        <div class="col-md">
            <button class="other-Button-gray float-end" (click)="getSTD_Rest_Delete()">
                {{'刪除' | translate}}
            </button>
            <button class="other-Button-gray float-end me-3" (click)="openModal(template, false, false, '')">
                {{'申請' | translate}}
            </button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3 table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'刪除' | translate}}</th>
                <th>{{'申請學年' | translate}}</th>
                <th>{{'申請學期' | translate}}</th>
                <th>{{'申請項目' | translate}}</th>
                <th>{{'申請日期' | translate}}</th>
                <th>{{'編輯' | translate}}</th>
                <th>{{'審核狀態' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sTDRestList">
                <td data-th="刪除">
                    <input type="checkbox" class="pointer" *ngIf="item.Status == '0' || item.Status == '4'"
                        [(ngModel)]="item.Checked">
                </td>
                <td data-th="申請學年">{{item.Years}}</td>
                <td data-th="申請學期">{{item.Term}}</td>
                <td data-th="申請項目">{{item.ApplyName}}</td>
                <td data-th="申請日期">{{item.ApplyDate}}</td>
                <td data-th="編輯">
                    <a class="pointer text-decoration-none" (click)="openModal(template, true, false, item.DataKey)">
                        <span class="icon-Fill-in-button"></span>{{'編輯' | translate}}
                    </a>
                </td>
                <td data-th="審核狀態">
                    <ng-container *ngIf="item.Status != '0' else other">
                        <a (click)="openFlow(flowTemplate, item.DataKey)" class="pointer text-decoration-none">
                            {{item.StatusName}}
                        </a>
                    </ng-container>
                    <ng-template #other>{{item.StatusName}}</ng-template>
                </td>
                <td class="td-rwd-hide"></td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'休學申請' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body" *ngIf="sTDRestDetail">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'班級' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.ClassName">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學號' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.StdNo">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'姓名' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.StdName">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'身分證號碼' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.IDNo">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'出生日期' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.Birthday">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'性別' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.Sex">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'手機號碼' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none"
                        [(ngModel)]="sTDRestDetail.MobilePhone">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'住家電話號碼' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.ComTel">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Email</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.EMail">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'通訊地址' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.ComAddress">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'已休學期數' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none"
                        [(ngModel)]="sTDRestDetail.AllRestCount">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'是否續休' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.Delay">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'申請日期' | translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="sTDRestDetail.ApplyDate">
                </div>
                <small class="text-danger fw-bolder">{{'*此日期為送出休學申請當日，若只有儲存沒有送出則無申請日。' | translate}}</small>
            </div>
        </div>
        <hr>
        <label class="text-primary fw-bolder">{{'預計休學：' | translate}}</label>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學年' | translate}}</span>
                    </div>
                    <!-- <input type="text" class="form-control shadow-none" required [(ngModel)]="sTDRestDetail.Years"> -->
                    <select class="form-select shadow-none" required [(ngModel)]="sTDRestDetail.Years">
                        <option value="" disabled>{{'--請選擇--' | translate}}</option>
                        <option value="{{item}}" *ngFor="let item of Years_Range">{{item}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學期' | translate}}</span>
                    </div>
                    <select class="form-select shadow-none" required [(ngModel)]="sTDRestDetail.Term">
                        <option value="" disabled>{{'--請選擇--' | translate}}</option>
                        <option value="{{item.itemValue}}" *ngFor="let item of term">{{item.itemName}}
                        </option>
                    </select>


                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'申請休學期數' | translate}}</span>
                    </div>
                    <input type="text" class="form-control shadow-none" required [(ngModel)]="sTDRestDetail.RestCount">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'休學原因' | translate}}</span>
                    </div>
                    <select class="form-select shadow-none" required [(ngModel)]="sTDRestDetail.RestNo">
                        <option value="" disabled>{{'--請選擇--' | translate}}</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of restReasonList">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <label class="text-primary fw-bolder">{{'預計復學：' | translate}}</label>
        <div class="row mb-3">
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學年' | translate}}</span>
                    </div>
                    <!-- <input type="text" class="form-control shadow-none" required [(ngModel)]="sTDRestDetail.RecoverYear"> -->
                    <select class="form-select shadow-none" required [(ngModel)]="sTDRestDetail.RecoverYear">
                        <option value="" disabled>{{'--請選擇--' | translate}}</option>
                        <option value="{{item}}" *ngFor="let item of Years_Range">{{item}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學期' | translate}}</span>
                    </div>
                    <select class="form-select shadow-none" required [(ngModel)]="sTDRestDetail.RecoverTerm">
                        <option value="" disabled>{{'--請選擇--' | translate}}</option>
                        <option value="{{item.itemValue}}" *ngFor="let item of term">{{item.itemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="ps-3">
                <small class="text-danger fw-bolder">{{'*第一學期復學請於' | translate}}<span
                        class="text-danger fw-bolder h5">{{'7/31' | translate}}</span>{{'以前辦理復學手續，第二學期復學請於' |
                    translate}}<span class="text-danger fw-bolder h5">{{'1/31' | translate}}</span>{{'以前辦理手續' |
                    translate}}</small>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="text-primary fw-bolder label me-3">
                    {{'休學證明文件' | translate}}：{{sTDRestDetail.RestPaper}}
                </label>
                <button class="other-Button-gray upload" (click)="importInputCkick('RestPaper')"
                    [hidden]="sTDRestDetail.RestPaper != ''">
                    {{'上傳' | translate}}
                </button>
                <button class="other-Button-gray upload" (click)="clear('RestPaper')"
                    [hidden]="sTDRestDetail.RestPaper == ''">
                    {{'清除' | translate}}
                </button>
                <input type="file" accept=".docx" style="display: none;" id="RestPaper" value=""
                    (change)="importData($event, '', 'RestPaper')">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <label class="text-primary fw-bolder label me-3">
                    {{'家長同意書' | translate}}：{{sTDRestDetail.AgreePaper}}
                </label>
                <button class="other-Button-gray upload" (click)="importInputCkick('AgreePaper')"
                    [hidden]="sTDRestDetail.AgreePaper != ''">
                    {{'上傳' | translate}}
                </button>
                <button class="other-Button-gray upload" (click)="clear('AgreePaper')"
                    [hidden]="sTDRestDetail.AgreePaper == ''">
                    {{'清除' | translate}}
                </button>
                <input type="file" accept=".docx" style="display: none;" id="AgreePaper" value=""
                    (change)="importData($event, '', 'AgreePaper')">
                <!-- <a class="pointer text-decoration-none float-end" href="../../../assets/file/家長同意書.docx">
                    <span class="icon-Downlad-button"></span>{{'家長同意書下載' | translate}}
                </a> -->
                <br>
                <small class="text-danger fw-bolder">{{'*大學部學生必須上傳家長證明書，否則無法送出申請。' | translate}}</small>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'是否核准' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDRestDetail.Approve">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'核准日期' | translate}}</span>
                    </div>
                    <input type="text" disabled readonly class="form-control shadow-none"
                        [(ngModel)]="sTDRestDetail.ApproveDate">
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <button class="send-btn float-start me-3" *ngIf="showButton" (click)="getSTDRestSave('send')">
                    {{'送出' | translate}}
                </button>
                <button class="other-Button-gray float-start me-3" *ngIf="showButton" (click)="getSTDRestSave('save')">
                    {{'儲存' | translate}}</button>
                <button class="other-Button-gray float-start me-3" (click)="modalRef.hide()">
                    {{'關閉' | translate}}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #flowTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'簽核流程' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <app-std-sign-off [inputstd]="eDocFlow"></app-std-sign-off>
    </div>
</ng-template>