import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0501/search';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0501/searchDetail';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADRGP0501',
    templateUrl: './ADRGP0501.component.html',
    styleUrls: ['./ADRGP0501.component.css']
})
export class ADRGP0501Component implements OnInit {
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    moeTypeList!: GetSelectList[];
    initialData = new InitialData(this.http);
    month = require('src/app/_Models/_SelectInput/month.json').month;
    bsConfig = environment.bsDatePickerConfig;
    DNMArkList!: GetSelectList[];
    data!: Search[];
    dataDetail: SearchDetail = {
        Moe_No: '',
        Moe_Type: '',
        Moe_Title: '',
        Moe_Number: '',
        Moe_Main: '',
        Memo: '',
        Moe_Date: '',
        Update_User: '',
        Update_Time: '',
        Moe_Type_Name: ''
    };
    search1 = {
        Moe_Type: ''
    }
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private localeService: BsLocaleService,) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getMoeType();
    }

    getMoeType() {
        this.systemService.getMoeType().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.moeTypeList = response;
        }, error => this.toastr.error(error.error))
    }


    search(Moe_Type: string) {
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0501/Search", { Moe_Type: Moe_Type }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        })
    }

    searchDetail(Moe_No: string, index: number) {
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0501/Search_Detail", { Moe_No: Moe_No }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.dataDetail = response;
            this.dataDetail.Moe_Title = this.dataDetail.Moe_Title;
            this.dataDetail.Moe_Main = this.dataDetail.Moe_Main;
            this.dataDetail.Moe_Date = this.dataDetail.Moe_Date;
            this.dataDetail.Update_Time = this.dataDetail.Update_Time;
            this.dataDetail.Update_User = this.dataDetail.Update_User;
            this.dataDetail.Memo = this.dataDetail.Memo;
        });
    }

    insert() {

        if (this.dataDetail.Moe_No == '' || this.dataDetail.Moe_Number == '' || this.dataDetail.Moe_Title == '' || this.dataDetail.Moe_Type == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0501/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功，請重新查詢");
            this.search(this.search1.Moe_Type)
        }, error => this.toastr.error(error.error))
    }

    update() {
        if (this.dataDetail.Moe_No == '' || this.dataDetail.Moe_Number == '' || this.dataDetail.Moe_Title == '' || this.dataDetail.Moe_Type == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0501/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功，請重新查詢");
            this.search(this.search1.Moe_Type)
        }, error => this.toastr.error(error.error))
    }

    delete() {
        if (this.dataDetail.Moe_No == '') {
            this.toastr.info("請選擇文號");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0501/Delete", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功，請重新查詢");
            this.clear();
        }, error => this.toastr.error(error.error))
    }

    clear() {
        this.dataDetail = {
            Moe_No: '',
            Moe_Type: '',
            Moe_Title: '',
            Moe_Number: '',
            Moe_Main: '',
            Memo: '',
            Moe_Date: '',
            Update_User: '',
            Update_Time: '',
            Moe_Type_Name: ''
        }
    }

}
