
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADCOM0101',
  templateUrl: './ADCOM0101.component.html',
  styleUrls: ['./ADCOM0101.component.css']
})
export class ADCOM0101Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}