import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetSelectList} from 'src/app/_Models/getSelectList';
import { GetSELSetDefaultStdList } from 'src/app/_Models/AD/ADSEL/ADSEL0302/getSELSetDefaultStdList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSEL0302',
    templateUrl: './ADSEL0302.component.html',
    styleUrls: ['./ADSEL0302.component.css']
})
export class ADSEL0302Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    ClassList?: GetClassList[];
    CosList?: GetSelectList[];
    ClassListTo?: GetClassList[];
    CosListTo?: GetSelectList[];
    SELSelChooseOpClassSelList!: GetSELSetDefaultStdList[];
    SELSelChooseOpClassSelListTo!: GetSELSetDefaultStdList[];
    dataKey?: string
    modalRef?: BsModalRef;
    QueryInput = {
        Years: '',
        Term:'',
        Class: ''
      };
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl({ value: ''}, Validators.required),
        Term: new UntypedFormControl({ value: ''}, Validators.required),
        OpClass: new UntypedFormControl(''),
        CosData: new UntypedFormControl(''),
        YearsTo: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
        TermTo: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
        OpClassTo: new UntypedFormControl(''),
        CosDataTo: new UntypedFormControl('')
    });

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private modalService: BsModalService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.getYears();
        this.timeService.currentTime$.subscribe((response) => {
            this.ngFormInput.get("Years")?.setValue(response.find(n => n.ItemID == "SelYear")?.ItemValue || '');
            this.ngFormInput.get("Term")?.setValue(response.find(n => n.ItemID == "SelTerm")?.ItemValue || '');
            this.ngFormInput.get("YearsTo")?.setValue(response.find(n => n.ItemID == "SelYear")?.ItemValue || '');
            this.ngFormInput.get("TermTo")?.setValue(response.find(n => n.ItemID == "SelTerm")?.ItemValue || '');
        });
        this.getClassList(this.ngFormInput.get("Years")?.value,this.ngFormInput.get("Term")?.value,"1");
        this.getClassList(this.ngFormInput.get("YearsTo")?.value,this.ngFormInput.get("TermTo")?.value,"2");
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    setAll(eventTarget: EventTarget | null,DataType: string) {
        let input = <HTMLInputElement>eventTarget;
        if(DataType=="1"){
            this.SELSelChooseOpClassSelList.forEach(element => {
                element.Checked = input.checked;
            });
        }
        else{
            this.SELSelChooseOpClassSelListTo.forEach(element => {
                element.Checked = input.checked;
            });
        }
    }

    getClassList(Years: string, Term: string,DataType: string) {
        if(DataType=="1"){
            this.ClassList = undefined;
        }
        else{
            this.ClassListTo = undefined;
        }

        this.systemService.getClassList('', '', '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }

            if(DataType=="1"){
                this.ClassList = response;
            }
            else{
                this.ClassListTo = response;
            }
        })
    }

    getCosList(Years: string, Term: string, OpClass: string,DataType: string) {
        if(DataType=="1"){
            this.CosList = undefined;
        }
        else{
            this.CosListTo = undefined;
        }
        this.QueryInput.Years=Years;
        this.QueryInput.Term=Term;
        this.QueryInput.Class=OpClass;
        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0302/Get_GRD_TermScore_Cos_List", this.QueryInput).subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if(DataType=="1"){
                this.CosList = response;
            }
            else{
                this.CosListTo = response;
            }
        }));
    }

    getStdList(Year: string,Term: string,DataKey: string,DataType: string) {
        if(DataType=="1"){
            this.SELSelChooseOpClassSelList = [];
        }
        else{
            this.SELSelChooseOpClassSelListTo = [];
        }

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0302/"+((DataType=="1")?'Get_SEL_SetDefault_StdList':'Get_SEL_SetDefault_StdAddList'), {DataKey:DataKey}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if(DataType=="1"){
                this.SELSelChooseOpClassSelList = response;
                this.SELSelChooseOpClassSelList.forEach(element => {
                    element.Checked = false;
                })
            }
            else{
                this.SELSelChooseOpClassSelListTo = response;
                this.SELSelChooseOpClassSelListTo.forEach(element => {
                    element.Checked = false;
                })
            }
        })
    }

    getSELSetDefaultProcess(IsAdd: boolean) {
        let addcosdatakey='';
        let additems=0;

        if(IsAdd){
            this.doProcess(IsAdd);
        }
        else{
            Swal.fire({
                title: "是否確定移除?",
                showDenyButton: true,
                confirmButtonText: '是',
                denyButtonText: '否',
            }).then((result) => {
                if(result.isConfirmed){
                    this.doProcess(IsAdd);
                }
            })
        }
    }

    doProcess(IsAdd: boolean){
        let addcosdatakey='';
        let additems=0;

        if(IsAdd){
            addcosdatakey=this.ngFormInput.get("CosDataTo")?.value;

            if(addcosdatakey==""){
                this.toastr.info("請選擇欲加入的科目!");
                return;
            }

            this.SELSelChooseOpClassSelList.forEach(element => {
                if (element.Checked) {
                    element.AddCosDataKey=addcosdatakey;
                    additems+=1;
                }
            });
        }
        else{
            this.SELSelChooseOpClassSelListTo.forEach(element => {
                if (element.Checked) {
                    additems+=1;
                }
            });
        }

        if (additems == 0) {
            this.toastr.info("請選擇學生!");
            return;
        }

        this.http.post<any>(this.baseUrl + 'ACAD/SEL/ADSEL0302/'+((IsAdd)?'Get_SEL_SetDefault_Add':'Get_SEL_SetDefault_Delete'),((IsAdd)?this.SELSelChooseOpClassSelList:this.SELSelChooseOpClassSelListTo)).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.getStdList(this.ngFormInput.get("YearsTo")?.value,this.ngFormInput.get("TermTo")?.value,this.ngFormInput.get("CosDataTo")?.value,"2");
            this.toastr.success(((IsAdd)?"配課完成。":"移除完成。"));
        });
    }
}
