import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetStdModifyList } from 'src/app/_Models/AD/ADSTD/ADSTD0202/getStdModifyList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSTD0202',
    templateUrl: './ADSTD0202.component.html',
    styleUrls: ['./ADSTD0202.component.css']
})

export class ADSTD0202Component implements OnInit {
    bsConfig = environment.bsDatePickerConfig;
    baseUrl = environment.apiUrl;
    eduList!: GetEduList[];
    Years!: Array<string>;
    stdModifyList!: GetStdModifyList;
    initialData = new InitialData(this.http);
    constructor(private http: HttpClient, private toastr: ToastrService,
        private systemService: SystemService,
        private localeService: BsLocaleService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.timeService.getTimeCntr().subscribe((response) => {
            this.getEduList(response.Now_Years, response.Now_Term);
        })
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        }, error => this.toastr.error(error.error));
    }

    getEduList(Years: string, Term: string) {
        this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getStdModifyList(eduNo: string) {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_02/Get_Std_ModifyList", { DataKey: eduNo }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdModifyList = response;

            // 解碼
            this.stdModifyList.DateS = this.stdModifyList.DateS;
            this.stdModifyList.DateE = this.stdModifyList.DateE;
            for (let index = 0; index < this.stdModifyList.ModifyList.length; index++) {
                this.stdModifyList.ModifyList[index].ItemName = this.stdModifyList.ModifyList[index].ItemName;
            }


            // 拆解日期
            let sDate = this.stdModifyList.DateS.split(' ');
            let eDate = this.stdModifyList.DateE.split(' ');
            this.stdModifyList.sDate = sDate[0];
            this.stdModifyList.sTime = sDate[1];
            this.stdModifyList.eDate = eDate[0];
            this.stdModifyList.eTime = eDate[1];
        })
    }

    setCheck(index: number, event: any, type: string) {
        let input = <HTMLInputElement>event;
        if (type == 'freshman') {
            if (input.checked == false) {
                this.stdModifyList.Freshman = 'N';
            }
            else {
                this.stdModifyList.Freshman = 'Y';
            }
        }
        else {

            if (input.checked == false) {
                this.stdModifyList.ModifyList[index].Modify = '0';
            }
            else {
                this.stdModifyList.ModifyList[index].Modify = '1';
            }
        }
    }

    getStdModifyListSave() {
        this.stdModifyList.DateS = this.stdModifyList.sDate + ' ' + this.stdModifyList.sTime;
        this.stdModifyList.DateE = this.stdModifyList.eDate + ' ' + this.stdModifyList.eTime;
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_02/Get_Std_ModifyList_Save", this.stdModifyList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }

}
