<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text shadow-none">學位別</span>
                <select class="form-select shadow-none">
                    <option value="">--請選擇--</option>
                </select>
            </div>
        </div>
        <div class="col-md-9">
            <button class="other-Button-gray float-end">清除</button>
            <button class="other-Button-gray float-end me-3">刪除</button>
            <button class="other-Button-gray float-end me-3">修改</button>
            <button class="add-btn float-end me-3">新增</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th>代碼</th>
                <th>名稱</th>
                <th style="width: 500px;">備註</th>
                <th>修改者</th>
                <th>修改時間</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style="width: 500px;"></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
