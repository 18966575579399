import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetEDocFlow } from 'src/app/_Models/getEDocFlow';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetSTDRestDetail } from 'src/app/_Models/STDWEB/STDW10009/getSTDRestDetail';
import { GetSTDRestList } from 'src/app/_Models/STDWEB/STDW10009/getSTDRestList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-STDW10009',
    templateUrl: './STDW10009.component.html',
    styleUrls: ['./STDW10009.component.css']
})
export class STDW10009Component implements OnInit {
    modalRef!: BsModalRef;
    restReasonList!: GetSelectList[];
    sTDRestList!: GetSTDRestList[];
    sTDRestDetail!: GetSTDRestDetail;
    fileExchange = new FileExchange();
    checkType = new CheckType();
    term = require('../../_Models/_SelectInput/term.json').term;
    eDocFlow!: GetEDocFlow[];
    baseUrl = environment.apiUrl;
    hide!: boolean;
    showButton: boolean = false;
    Years_Range: string[] = [];
    subpageconfig = { backdrop: true, ignoreBackdropClick: true, class: "modal-lg" };

    constructor(private modalService: BsModalService,
        private http: HttpClient,
        private toastr: ToastrService,
        private fileExchangeServices: FileExchangeService,
        private domSanitizer: DomSanitizer,
        private systemService: SystemService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.getSTDRestList();
        this.getRestReason();
        this.hide = true;
    }

    openFlow(template: TemplateRef<any>, dataKey: string) {
        const senddata = this.http.post<any>(this.baseUrl + "Public/Get_EDoc_Flow", { DataKey: dataKey });
        senddata.subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eDocFlow = response;
            this.modalRef = this.modalService.show(template, this.subpageconfig);
        }, error => this.toastr.error(error.error));
    }

    openModal(template: TemplateRef<any>, detail: boolean, flow: boolean, dataKey: string) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: "modal-xl"
        };

        if (!detail && !flow) {
            this.modalRef = this.modalService.show(template, modalConfig);
            this.getSTDRestDetail(dataKey);
            return;
        }
        else if (detail && !flow) {
            this.getSTDRestDetail(dataKey);
            this.modalRef = this.modalService.show(template, modalConfig);
            return;
        }
        else {
            this.getEDocFlow(dataKey);
            this.modalRef = this.modalService.show(template, modalConfig);
        }
    }

    getSTDRestList() {
        this.sTDRestList = [];
        this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW100_09/STD_Rest_List").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sTDRestList = response;
        }, error => this.toastr.error(error.error));
    }

    getSTDRestDetail(dataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_09/STD_Rest_Detail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }


            this.sTDRestDetail = response;

            for (let i = Number(this.sTDRestDetail.StartYear); i <= Number(this.sTDRestDetail.EndYear); i++) {
                this.Years_Range.push(i.toString());
            }

            this.sTDRestDetail.StdName = this.sTDRestDetail.StdName;
            this.sTDRestDetail.ClassName = this.sTDRestDetail.ClassName;
            this.sTDRestDetail.Sex = this.sTDRestDetail.Sex;
            this.sTDRestDetail.ComTel = this.sTDRestDetail.ComTel;
            this.sTDRestDetail.ComAddress = this.sTDRestDetail.ComAddress;
            this.sTDRestDetail.Approve = this.sTDRestDetail.Approve;

            switch (this.sTDRestDetail.Status) {
                case '':
                    this.showButton = true;
                    break;
                case '0':
                    this.showButton = true;
                    break;
                case '4':
                    this.showButton = true;
                    break;
                default:
                    this.showButton = false;
                    break;
            }


        }, error => this.toastr.error(error.error));
    }

    getRestReason() {
        this.systemService.getRestReason().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.restReasonList = response;
        })
    }

    importInputCkick(type: string) {
        if (type == 'AgreePaper') {
            document.getElementById('AgreePaper')?.click();
        }
        else {
            document.getElementById('RestPaper')?.click();
        }

    }

    importData($event: any, dataKey: string, type: string) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type);
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {

            let RestPaper = <HTMLInputElement>document.getElementById("RestPaper");
            let AgreePaper = <HTMLInputElement>document.getElementById("AgreePaper");

            if (result.isConfirmed) {
                if (type == 'RestPaper') {
                    that.sTDRestDetail.RestPaper = file.name;
                    that.sTDRestDetail.RestPaperBase64 = base64;
                }
                else {
                    that.sTDRestDetail.AgreePaper = file.name;
                    that.sTDRestDetail.AgreePaperBase64 = base64;
                }
            }
            else if (result.isDenied) {
                if (type == 'RestPaper') {
                    RestPaper.value = '';
                }
                else {
                    AgreePaper.value = ''
                }

                that.toastr.info("已取消上傳。");
            }
        });
    }

    clear(type: string) {

        let RestPaper = <HTMLInputElement>document.getElementById("RestPaper");
        let AgreePaper = <HTMLInputElement>document.getElementById("AgreePaper");

        if (type == 'RestPaper') {
            this.sTDRestDetail.RestPaper = '';
            this.sTDRestDetail.RestPaperBase64 = '';
            RestPaper.value = '';
        }
        else {
            this.sTDRestDetail.AgreePaper = '';
            this.sTDRestDetail.AgreePaperBase64 = '';
            AgreePaper.value = ''
        }
    }

    getSTDRestSave(type: string) {

        if (this.sTDRestDetail.RestPaper == '') {
            this.toastr.info("請上傳證明文件");
            return;
        }

        if (this.sTDRestDetail.AgreePaper == '') {
            this.toastr.info("請上傳家長同意書");
            return;
        }

        if (this.checkType.CheckIsNumber(this.sTDRestDetail.RestCount)) {
            this.toastr.info("休學期數請填入數字");
            return;
        }

        if (this.checkType.CheckIsNumber(this.sTDRestDetail.Years)) {
            this.toastr.info("預計休學學年請填入數字");
            return;
        }

        if (this.sTDRestDetail.Term == '') {
            this.toastr.info("請選擇預計休學學期");
            return;
        }

        if (this.checkType.CheckIsNumber(this.sTDRestDetail.RecoverYear)) {
            this.toastr.info("預計復學學年請填入數字");
            return;
        }

        if (this.sTDRestDetail.RecoverTerm == '') {
            this.toastr.info("請選擇預計復學學期");
            return;
        }

        if (this.sTDRestDetail.RestNo == '') {
            this.toastr.info("請選擇休學原因");
            return;
        }

        if (
            Number(this.sTDRestDetail.Years + this.sTDRestDetail.Term) < Number(this.sTDRestDetail.StartYear + this.sTDRestDetail.StartTerm)
            || Number(this.sTDRestDetail.Years) > Number(this.sTDRestDetail.EndYear)
        ) {
            this.toastr.info("數字不在學年範圍內");
            return;
        }

        if(this.sTDRestDetail.DataKey==null){
            this.sTDRestDetail.DataKey='';
        }

        if (type == 'save') {
            this.sTDRestDetail.Send = '0'
            this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW100_09/STD_Rest_Save', this.sTDRestDetail).subscribe((response) => {

                for (let i = 0; i < 2; i++) {
                    if (response[i].Info == false) {
                        this.toastr.warning(response[i].Message);
                        return;
                    }
                }

                this.toastr.success("儲存成功");
                this.modalRef.hide();
                this.getSTDRestList();
            });
        }
        else {
            Swal.fire({
                title: "一旦送出即不能修改，確定?",
                showDenyButton: true,
                confirmButtonText: '是',
                denyButtonText: '否',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.sTDRestDetail.Send = '1';
                    this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW100_09/STD_Rest_Save', this.sTDRestDetail).subscribe((response) => {

                        for (let i = 0; i < 2; i++) {
                            if (response[i].Info == false) {
                                this.toastr.warning(response[i].Message);
                                return;
                            }
                        }

                        this.toastr.success("送出成功");
                        this.modalRef.hide();
                        this.getSTDRestList();
                    }, error => this.toastr.error(error.error))
                }
                else {
                    this.toastr.info("已取消");
                }
            })
        }
    }

    getSTD_Rest_Delete() {
        this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW100_09/STD_Rest_Delete', this.sTDRestList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.getSTDRestList();
        });
    }

    getEDocFlow(dataKey: string) {
        this.http.post<any>(this.baseUrl + "Public/Get_EDoc_Flow", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eDocFlow = response;
            this.eDocFlow.forEach(element => {
                element.SafeUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + element.StampBase64);
            });
        }, error => this.toastr.error(error.error))
    }
}

