<div class="container" style="margin-bottom: 100px;">
    <app-year-term-input></app-year-term-input>
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'開課班級'|translate}}</span>
                </div>
                <select class="form-select shadow-none" (change)="getCosList($event)">
                    <option value="" disabled selected>{{'--請選擇開課班級--'|translate}}</option>
                    <option *ngFor="let item of opClassList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'授課科目'|translate}}</span>
                </div>
                <select class="form-select shadow-none" #cos>
                    <option value="" disabled selected>{{'--請選擇授課科目--'|translate}}</option>
                    <option *ngFor="let item of cosList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <button class="search-btn mb-3" (click)="getTeamList(cos.value)">{{'查詢'|translate}}</button>
    <br>
    <span class="text-danger fw-bolder">{{'*組別請填寫A-Z'|translate}}</span>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>{{'學號'|translate}}</th>
                <th>{{'姓名'|translate}}</th>
                <th>{{'組別'|translate}}</th>
                <th>{{'組長'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of teamList">
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td><input type="text" class="text-center form-control" maxlength="1" [(ngModel)]="item.TeamNo"
                        placeholder="{{'只接受A-Z'|translate}}"></td>
                <td>
                    <select class="form-select shadow-none" [(ngModel)]="item.Leader">
                        <option value="">{{'否'|translate}}</option>
                        <option value="Y">{{'是'|translate}}</option>
                    </select>
                </td>
            </tr>
        </tbody>
    </table>
    <button class="save-btn-gray" style="margin: auto;" (click)="saveData()" *ngIf="teamList">{{'儲存'|translate}}</button>
</div>