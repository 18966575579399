import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexTitleSubtitle, ChartComponent } from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';
export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    title: ApexTitleSubtitle;
};
@Component({
    selector: 'app-STDW30009',
    templateUrl: './STDW30009.component.html',
    styleUrls: ['./STDW30009.component.css']
})

export class STDW30009Component implements OnInit {
    @ViewChild("chart") chart!: ChartComponent;
    baseUrl = environment.apiUrl;
    public chartOptions: Partial<any>;
    modal = {
        Now_Years: '',
        Now_Term: ''
    }
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private translateService: TranslateService) {
        this.chartOptions = {
            series: [
                {
                    name: '未修過',
                    data: []
                },
                {
                    name: '已修過',
                    data: []
                },
                {
                    name: '超修',
                    data: []
                }
            ],
            dataLabels: {
                enabled: true,
                enabledOnSeries: undefined,
                formatter: function (val: any, opts: any) {
                    return val
                },
            },
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                },
                redrawOnWindowResize: true
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10
                },
            },
            xaxis: {
                type: 'string',
                categories: [],
            },
            legend: {
                position: 'right',
                offsetY: 40
            },
            fill: {
                opacity: 1
            }
        };
    }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.getListAlert();
    }

    getListAlert() {
        this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW300_09/Get_Std_GradCredit").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            for (const item of response) {
                if (item.MustCredit - item.Credits > 0) {
                    this.chartOptions.series[0].data.push(item.MustCredit - item.Credits);
                }
                else {
                    this.chartOptions.series[0].data.push(0)
                }
                this.chartOptions.series[1].data.push(item.Credits);
                if (item.MustCredit - item.Credits < 0) {
                    this.chartOptions.series[2].data.push(item.Credits - item.MustCredit)
                }
                else {
                    this.chartOptions.series[2].data.push(0);
                }
                this.chartOptions.xaxis.categories.push(item.SelKindName)
            }
        })
    }



}
