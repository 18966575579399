<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="RowH50">
                <button class="save-btn-gray float-end me-3" (click)="getHelpModify('1',helpItemList)">儲存</button>
                <button class="other-Button-gray float-end me-3" (click)="showSample(template)">參考樣式</button>
            </div>
        </div>
        <div class="col-md-3">
            <button class="save-btn-gray float-end me-3" (click)="getHelpModify('2',helpSubItemList)">儲存</button>            
            <button class="other-Button-gray float-end me-3" (click)="addItem($event, myParameter.SysSub, 0)">新增</button>
        </div>
        <div class="col-md-6">

        </div>
    </div>
    <div class="row">
        <div class="col-md-3 SysTable">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th class="SysTableCell160">系統</th>
                        <th class="SysTableCell70">排序</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of helpItemList; let i = index" class="pointer" 
                        (click)="getHelpSubItemList(item.DataKey,i)"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}">
                        <td class="SysTableCell160">{{item.ItemName}}</td>
                        <td class="SysTableCell70"><input type="text" class="form-control shadow-none TextCenter" [(ngModel)]="item.OrderNo"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-3 SysTable">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th class="SysTableCell140">子系統</th>
                        <th class="SysTableCell50">排序</th>
                        <th class="SysTableCell40"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of helpSubItemList; let i = index" class="pointer" 
                        (click)="getHelpOperationItemList(item.DataKey,i)"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}">
                        <td class="SysTableCell140"><input type="text" class="form-control shadow-none" [(ngModel)]="item.ItemName"></td>
                        <td class="SysTableCell50"><input type="text" class="form-control shadow-none TextCenter" [(ngModel)]="item.OrderNo"></td>
                        <td class="SysTableCell40">
                            <button class="btn btn-light shadow-none" (click)="removeItem($event, myParameter.SysSub, item.DataKey)">X</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div> 
        <div class="col-md-6 SysTable">
            <accordion [closeOthers]="true" [isAnimated]="true">
                <button class="btn btn-light shadow-none mb-3" (click)="addItem($event, myParameter.Operation, 0)">新增操作</button>
                <button class="save-btn-gray float-end" (click)="getHelpModify('3',helpOperationItemList)">儲存</button>                    
                <accordion-group *ngFor="let HelpItem of helpOperationItemList; let i = index">
                    <div class="row" accordion-heading>
                        <div class="col-md-8" (click)="getHelpStepItemList(HelpItem.DataKey)">
                            <div class="input-group">
                                <span class="input-group-text">操作</span>
                                <input type="text"  class="form-control shadow-none" placeholder="輸入操作名稱" [(ngModel)]="HelpItem.ItemName">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <span class="input-group-text">排序</span>
                                <input type="text"  class="form-control shadow-none TextCenter" placeholder="輸入排序編號" [(ngModel)]="HelpItem.OrderNo">
                            </div>
                        </div>
                        <div class="DivW40">
                            <button class="btn btn-light shadow-none" (click)="removeItem($event, myParameter.Operation, HelpItem.DataKey)">X</button>
                        </div>
                    </div>
                    <div class="ps-5">
                        <button class="btn btn-light shadow-none mb-3" (click)="addItem($event, myParameter.Step, i)">新增步驟</button>
                        <button class="save-btn-gray float-end" (click)="getHelpModify('4',helpStepItemList)">儲存</button>
                    </div>
                    <div class="row" *ngFor="let Main of helpStepItemList; let j = index">
                        <div class="col-md-8">
                            <div class="input-group ps-5">
                                <span class="input-group-text">步驟</span>
                                <input type="text"  class="form-control shadow-none" placeholder="輸入操作步驟" [(ngModel)]="Main.ItemName">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <span class="input-group-text">排序</span>
                                <input type="text"  class="form-control shadow-none TextCenter" placeholder="輸入排序編號" [(ngModel)]="Main.OrderNo">
                            </div>
                        </div>                     
                        <div class="DivW40">
                            <button class="btn btn-light shadow-none" (click)="removeItem($event, myParameter.Step, Main.DataKey)">X</button>
                        </div>
                    </div>
                </accordion-group>
            </accordion>
        </div>                      
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">參考樣式</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
        </button>
    </div>    
    <div class="row mb-3">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body mx-auto">
                    <figure class="figure">
                        <img src="../../../../assets/Image/helpInfomation.png" class="SampleImg" alt="...">
                    </figure>
                </div>
            </div>
        </div>
    </div>
</ng-template>