<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">單位</span>
                <select class="form-select shadow-none" #UnitCode>
                    <option value="" disabled>--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of unitList">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">在職狀態</span>
                <select class="form-select shadow-none" #Status>
                    <option value="" disabled>--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of titleStatus">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">專兼任別</span>
                <select class="form-select shadow-none" #TimeCase>
                    <option value="" disabled>--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of timeCase">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" #TchName>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="openModal(template)">發送內容</button>
            <button class="search-btn float-end me-3"
                (click)="getCUPMailTchList(UnitCode.value, Status.value, TimeCase.value, TchName.value)">查詢</button>
        </div>
    </div>
    <table class="table table-striped table-hover text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" class="form-check-input pointer shadow-none"
                        (click)="setDatakey('all', '', $event.target)">&ensp;全選</th>
                <th>編號</th>
                <th>姓名</th>
                <th>單位</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cUPMailTchList">
                <td><input type="checkbox" class="form-check-input pointer shadow-none"
                        (click)="setDatakey('', item.DataKey, $event.target)" [checked]="item.checked"></td>
                <td>{{item.TchNo}}</td>
                <td>{{item.TchName}}</td>
                <td>{{item.UnitName}}</td>
            </tr>
        </tbody>
    </table>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">編輯信件內容</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="input-group mb-3">
            <span class="input-group-text">主旨</span>
            <input type="text" class="form-control shadow-none" #Subject>
        </div>
        <div class="input-group mb-3">
            <span class="input-group-text">內容</span>
            <textarea class="form-control shadow-none" rows="10" #Content></textarea>
        </div>
        <div class="input-group">
            <input type="file" class="form-control shadow-none" multiple (change)="import($event, '', '')">
        </div>
        <button class="send-btn mx-auto mt-3" (click)="getCUPMailTchSendEMail(Subject.value, Content.value)">送出</button>
    </div>
</ng-template>