import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { getHours } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { Data } from'src/app/_Models/AD/ADASM/ADASM0102/data';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADASM0102',
    templateUrl: './ADASM0102.component.html',
    styleUrls: ['./ADASM0102.component.css']
})
export class ADASM0102Component implements OnInit {
    url = environment.apiUrl;
    ngOnInit(): void {
        this.search();
    }
    data!:Data[];
    data_detail:Data={
        SelStyle: '',
        SelStyle_Name: '',
        Type:'0',
        MidTerm_Log: '0',
        FinalTerm_Log: '0',
        SelValue: '',
        State_Log: '',
        Update_User: '',
        Update_Time: '',
        K_Delete: '',
        State: '',
        Checked: false,
        Checkedend: false,
        Checkedtype: false
    }
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService) { }

    search(){
        this.http.get<any>(this.url+"ACAD/ASM/ADASM0102/ASM0102_DropDownList").subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            console.log(response)
           this.data=response;
           this.data.forEach(m=>{
            if(m.State_Log=='1'){
                m.State="啟用"
            }
            else{
                m.State="不啟用"
            }
           })
        })
    }

    search_deatil(i:number){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0102/ASM0102_DropDownListModelsdetail",this.data[i]).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            console.log(response)
           this.data_detail.SelStyle=response[0].SelStyle;
           this.data_detail.SelStyle_Name=response[0].SelStyle_Name;
           this.data_detail.SelValue=response[0].SelValue;
           this.data_detail.State_Log=response[0].State_Log;
           this.data_detail.Type=response[0].Type;
           this.data_detail.Update_Time=response[0].Update_Time;
           this.data_detail.Update_User=response[0].Update_User;
           this.data_detail.FinalTerm_Log=response[0].FinalTerm_Log;
           this.data_detail.MidTerm_Log=response[0].MidTerm_Log;
           if(this.data_detail.Type=="1")this.data_detail.Checkedtype=true;
           if(this.data_detail.Type=="0")this.data_detail.Checkedtype=false;
           if(this.data_detail.MidTerm_Log=="0")this.data_detail.Checked=false;
           if(this.data_detail.MidTerm_Log=="1")this.data_detail.Checked=true;
           if(this.data_detail.FinalTerm_Log=="0")this.data_detail.Checkedend=false;
           if(this.data_detail.FinalTerm_Log=="1")this.data_detail.Checkedend=true;
        })
    }

    change(event: EventTarget | null){
        let input = <HTMLInputElement>event;
        if(input.checked==true){
            this.data_detail.Type="1";
        }
        else{
            this.data_detail.Type="0"
        }
    }

    insert(){
        if(this.data_detail.Checkedtype==true)this.data_detail.Type='1';
        if(this.data_detail.Checkedtype==false)this.data_detail.Type='0';
        if(this.data_detail.Checked==false)this.data_detail.MidTerm_Log="0";
        if(this.data_detail.Checked==true)this.data_detail.MidTerm_Log="1";
        if(this.data_detail.Checkedend==false)this.data_detail.FinalTerm_Log="0";
        if(this.data_detail.Checkedend==true)this.data_detail.FinalTerm_Log="1";
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0102/ASM0102_insert",this.data_detail).subscribe(response=>{
            if (response[0].Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.clear();
            this.search();
        })
    }

    update(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0102/ASM0102_update",this.data_detail).subscribe(response=>{
            if (response[0].Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.clear();
            this.search();
        })
    }

    delete(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0102/ASM0102_delete",this.data_detail).subscribe(response=>{
            if (response[0].Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.clear();
            this.search();
        })
    }

    clear(){
        this.data_detail={
            SelStyle: '',
            SelStyle_Name: '',
            Type: '',
            MidTerm_Log: '',
            FinalTerm_Log: '',
            SelValue: '',
            State_Log: '',
            Update_User: '',
            Update_Time:'',
            K_Delete: '',
            State: '',
            Checkedtype:false,
            Checked: false,
            Checkedend: false
        }
    }
}

