import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0101/search';
import { SearchDetail } from 'src/app/_Models/AD/ADCUP/ADCUP0101/searchDetail';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUP0101',
    templateUrl: './ADCUP0101.component.html',
    styleUrls: ['./ADCUP0101.component.css']
})
export class ADCUP0101Component implements OnInit {

    baseUrl = environment.apiUrl;
    data!: Search[];
    dataDetail: SearchDetail = {
        ClassArea: '',
        ClassArea_Name: '',
        Update_User: '',
        Update_Time: ''
    }
    constructor(private http: HttpClient, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.search();
    }


    search() {
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0101/Search").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        })
    }

    searchDetail(ClassArea: string, index: number) {
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0101/Search_Detail", { ClassArea: ClassArea }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.dataDetail = response;
            this.dataDetail.ClassArea_Name = this.dataDetail.ClassArea_Name;
            this.dataDetail.Update_Time = this.dataDetail.Update_Time;
            this.dataDetail.Update_User = this.dataDetail.Update_User;
        });
    }

    insert() {
        if (this.dataDetail.ClassArea_Name == '' || this.dataDetail.ClassArea == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0101/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search();
        }, error => this.toastr.error(error.error))
    }

    update() {
        if (this.dataDetail.ClassArea_Name == '' || this.dataDetail.ClassArea == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0101/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.search();
        }, error => this.toastr.error(error.error))
    }

    delete() {
        if (this.dataDetail.ClassArea == '') {
            this.toastr.info("請選擇原因");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0101/Delete", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.search();
            this.clear();
        }, error => this.toastr.error(error.error))
    }

    clear() {
        this.dataDetail = {
            ClassArea: '',
            ClassArea_Name: '',
            Update_User: '',
            Update_Time: ''
        }
    }


}
