import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { parseDate } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { TimeService } from 'src/app/_Services/_Shared/time.service';

@Component({
    selector: 'app-ADSTD0601ISU03',
    templateUrl: './ADSTD0601ISU03.component.html',
    styleUrls: ['./ADSTD0601ISU03.component.css']
})

export class ADSTD0601ISU03Component implements OnInit {
    initialData = new InitialData(this.http);
    bsConfig = environment.bsDatePickerConfig;
    Years!: Array<string>;
    eduList!: GetEduList[];
    term = require('src/app/_Models/_SelectInput/term.json').term;
    fromTime: Date | undefined = new Date();
    toTime: Date | undefined = new Date();
    fromDate: Date | undefined = new Date();
    toDate: Date | undefined = new Date();
    baseUrl = environment.apiUrl;

    model = {
        Years: '',
        Term: '',
        fromDate:new Date(),
        fromTime:new Date(),
        toDate:new Date(),
        toTime:new Date(),
        DeptfromDate:new Date(),
        DeptfromTime:new Date(),
        DepttoDate:new Date(),
        DepttoTime:new Date(),
        OpenfromDate:new Date(),
        OpenfromTime:new Date(),
        OpentoDate:new Date(),
        OpentoTime:new Date()
    }

    TimeData={
        Contrast_Years:'',
        Contrast_Term:'',
        Contrast_StartTime:'',
        Contrast_EndTime:'',
        Contrast_DeptStartTime:'',
        Contrast_DeptEndTime:'',
        Contrast_OpenStartTime:'',
        Contrast_OpenEndTime:''
    }

    constructor(private localeService: BsLocaleService,
        private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private datePipe: DatePipe,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Contrast_Years;
            this.model.Term = response.Contrast_Term;
            this.model.fromDate = new Date(response.Contrast_StartTime);
            this.model.toDate = new Date(response.Contrast_EndTime);
            this.model.fromTime = this.model.fromDate;
            this.model.toTime = this.model.toDate;
            this.model.DeptfromDate = new Date(response.Contrast_DeptStartTime);
            this.model.DepttoDate = new Date(response.Contrast_DeptEndTime);
            this.model.DeptfromTime = this.model.DeptfromDate;
            this.model.DepttoTime = this.model.DepttoDate;
            this.model.OpenfromDate = new Date(response.Contrast_OpenStartTime);
            this.model.OpentoDate = new Date(response.Contrast_OpenEndTime);
            this.model.OpenfromTime = this.model.OpenfromDate;
            this.model.OpentoTime = this.model.OpentoDate;
        });
        this.localeService.use('zh-cn');
        this.getYears();
        this.fromTime = void 0;
        this.toTime = void 0;
        this.fromDate = void 0;
        this.toDate = void 0;
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    SaveData(){
        this.TimeData.Contrast_Years=this.model.Years;
        this.TimeData.Contrast_Term=this.model.Term;
        this.TimeData.Contrast_StartTime=this.datePipe.transform(this.model.fromDate, 'yyyy-MM-dd') + ' ' + this.datePipe.transform(this.model.fromTime, 'HH:mm');
        this.TimeData.Contrast_EndTime=this.datePipe.transform(this.model.toDate, 'yyyy-MM-dd') + ' ' + this.datePipe.transform(this.model.toTime, 'HH:mm');
        this.TimeData.Contrast_DeptStartTime=this.datePipe.transform(this.model.DeptfromDate, 'yyyy-MM-dd') + ' ' + this.datePipe.transform(this.model.DeptfromTime, 'HH:mm');
        this.TimeData.Contrast_DeptEndTime=this.datePipe.transform(this.model.DepttoDate, 'yyyy-MM-dd') + ' ' + this.datePipe.transform(this.model.DepttoTime, 'HH:mm');
        this.TimeData.Contrast_OpenStartTime=this.datePipe.transform(this.model.OpenfromDate, 'yyyy-MM-dd') + ' ' + this.datePipe.transform(this.model.OpenfromTime, 'HH:mm');
        this.TimeData.Contrast_OpenEndTime=this.datePipe.transform(this.model.OpentoDate, 'yyyy-MM-dd') + ' ' + this.datePipe.transform(this.model.OpentoTime, 'HH:mm');

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_01_ISU03/Get_ADSTD0601_ISU03_Save", this.TimeData).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }
}
