
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADGRD0402',
  templateUrl: './ADGRD0402.component.html',
  styleUrls: ['./ADGRD0402.component.css']
})
export class ADGRD0402Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}