<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year (change)="getEduList(Year.value, Term.value, 'refused')">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #term (change)="getEduList(Year.value, Term.value, 'refused')">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">製單類別</span>
                </div>
                <select #MakeDocNo class="form-select shadow-none">
                    <option value="" disabled>--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of makeDocList">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select #EduNo class="form-select shadow-none"
                    (change)="getDeptList(Year.value, Term.value, EduNo.value, 'refused')">
                    <option value="">--全部--</option>
                    <option value="{{item.Edu_No}}" *ngFor="let item of eduList">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">系所</span>
                </div>
                <select class="form-select shadow-none" #DeptNo
                    (change)="getClassList(Year.value, Term.value, EduNo.value, DeptNo.value, 'refused');">
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">班別</span>
                </div>
                <select class="form-select shadow-none" #Class>
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="search-btn float-start me-3"
                (click)="getTFSMakeDocRollbackList(Year.value, term.value, EduNo.value, DeptNo.value, Class.value, MakeDocNo.value)">查詢</button>
            <button class="other-Button-gray float-end" (click)="getTFSMakeDocRollback()">進行退單</button>
            <div class="form-check form-check-inline float-end mt-2">
                <input class="form-check-input pointer" type="checkbox" #ch1 value="01"
                    (click)="setFeeUnitNo(ch1.value, $event.target)">
                <label class="form-check-label pointer">教務處註冊組</label>
            </div>
            <div class="form-check form-check-inline float-end mt-2">
                <input class="form-check-input pointer" type="checkbox" #ch2 value="02"
                    (click)="setFeeUnitNo(ch2.value, $event.target)">
                <label class="form-check-label pointer">教務處課務組</label>
            </div>
            <div class="form-check form-check-inline float-end mt-2">
                <input class="form-check-input pointer" type="checkbox" #ch3 value="03"
                    (click)="setFeeUnitNo(ch3.value, $event.target)">
                <label class="form-check-label pointer">學務處衛保組</label>
            </div>
            <div class="form-check form-check-inline float-end mt-2">
                <input class="form-check-input pointer" type="checkbox" #ch4 value="04"
                    (click)="setFeeUnitNo(ch4.value, $event.target)">
                <label class="form-check-label pointer">學務處生輔組</label>
            </div>
            <div class="form-check form-check-inline float-end mt-2">
                <input class="form-check-input pointer" type="checkbox" #ch9 value="09"
                    (click)="setFeeUnitNo(ch9.value, $event.target)">
                <label class="form-check-label pointer">學務處生輔組(宿舍)</label>
            </div>
        </div>
    </div>

</div>

<div class="container">
    <div class="row mt-3">
        <div class="col-md-5">
            <table class="table table-hover text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th><input type="checkbox" (click)="setDatakey('all', '', $event.target)"
                                class="pointer">&ensp;全選</th>
                        <th>學號</th>
                        <th>姓名</th>
                        <th>班級</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of tFSMakeDocRollbackList; let i = index" class="pointer"
                        (click)="getTFSMakeDocRollbackSingleDetail(i, item.DataKey)">
                        <td><input type="checkbox" (click)="setDatakey('', item.DataKey, $event.target)"
                                [checked]="item.checked"></td>
                        <td>{{item.StdNo}}</td>
                        <td>{{item.StdName}}</td>
                        <td>{{item.ClassName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-7">
            <table class="table table-hover text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 50px;"></th>
                        <th style="width: 60px;">代碼</th>
                        <th>名稱</th>
                        <th style="width: 70px;">應收</th>
                        <th style="width: 100px;">狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tFSMakeDocRollbackSingleDetail; let i = index">
                        <td style="width: 50px;">{{i + 1}}</td>
                        <td style="width: 60px;">{{item.FeeNo}}</td>
                        <td>{{item.FeeName}}</td>
                        <td style="width: 70px;">{{item.MustMoney}}</td>
                        <td style="width: 100px;">{{item.StatusName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>