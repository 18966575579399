<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">{{'公告類型'|translate}}</span>
                <select class="form-select shadow-none" [(ngModel)]="condition.Type_ID">
                    <option value="">{{'全部'|translate}}</option>
                    <option value="1">{{'一般'|translate}}</option>
                    <option value="2">{{'防疫'|translate}}</option>
                    <option value="3">{{'遠距'|translate}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-5">
            <div class="input-group  mb-3">
                <span class="input-group-text">{{'公告主旨'|translate}}</span>
                <input value="" type="text" class="form-control shadow-none ng-pristine ng-valid ng-touched" size="30" placeholder="{{'範例:補助'|translate}}" [(ngModel)]="condition.Post_Subject">
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group  mb-3">
                <span class="input-group-text">{{'公告單位'|translate}}</span>
                <input value="" type="text" class="form-control shadow-none ng-pristine ng-valid ng-touched" size="30" placeholder="{{'範例:教務處'|translate}}" [(ngModel)]="condition.Post_UnitName">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="search-btn float-end col-md-1" (click)="Search()">{{'查詢'|translate}}</button>
        </div>
    </div>
    <hr>
    <div class="row">
        <table class="table table-hover table-striped table-rwd text-center">
            <thead class="table-rwd-hide thead-dark-red">
                <tr>
                    <th>{{'公告類型'|translate}}</th>
                    <th>{{'公告主旨'|translate}}</th>
                    <th>{{'公告單位'|translate}}</th>
                    <th>{{'對象單位'|translate}}</th>
                    <th>{{'公告日期'|translate}}</th>
                    <th>{{'詳細資料'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of listData; let i=index">
                    <td data-th="公告類型">{{item.Type_Name}}</td>
                    <td data-th="公告主旨" style="text-align: left;">{{item.Post_Subject}}</td>
                    <td data-th="公告單位">{{item.Post_UnitName}}</td>
                    <td data-th="對象單位">{{item.Unit_Name}}</td>
                    <td data-th="公告日期">{{item.Post_Date}}</td>
                    <td data-th="詳細資料"><a class="pointer text-decoration-none" (click)="OpenDialog(template, i)">{{'詳細資料'|translate}}</a></td>
                </tr>
                <tr *ngIf="listData.length == 0">
                    <td colspan="6" style="height:60px; vertical-align: middle;"><label style="color:red;">{{'查無資料'|translate}}</label></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<!--小視窗-->
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'公告內容'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">{{'公告類型'|translate}}</span>
                    <select class="form-select shadow-none" [(ngModel)]="detailData.Type_ID">
                        <option value="1">{{'一般'|translate}}</option>
                        <option value="2">{{'防疫'|translate}}</option>
                        <option value="3">{{'遠距'|translate}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">{{'公告單位'|translate}}</span>
                    <input type="text" class="form-control shadow-none ng-pristine ng-valid ng-touched" [(ngModel)]="detailData.Post_UnitName" size="30" placeholder="請輸入公告單位">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">{{'公告主旨'|translate}}</span>
                    <input type="text" class="form-control shadow-none ng-pristine ng-valid ng-touched" [(ngModel)]="detailData.Post_Subject" size="30" placeholder="請輸入主旨">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-2">
                    <span class="input-group-text">{{'公告內容'|translate}}</span>
                    <textarea class="form-control shadow-none" placeholder="請輸入公告內容" [(ngModel)]="detailData.Post_Content" style="height:180px;"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">{{'公告日期'|translate}}</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" [(ngModel)]="detailData.Post_Date" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">{{'截止日期'|translate}}</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" [(ngModel)]="detailData.End_Date" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="input-group flex-nowrap mb-3">
                <label>{{'下載檔案：'|translate}}</label>
                <label class="link1" *ngFor="let item of detailData.Attachment" (click)="Download(item.FileName,item.Base64)">{{item.FileName}} </label>
            </div>
        </div>
    </div>
</ng-template>
