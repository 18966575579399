<div class="container">
    <div class="row">
        <div class="modal-body">
            <div class="row" *ngFor="let item of thiscosInfo;">
                <div class="col-md-3">開課班級：{{item.Class_Name}}</div>
                <div class="col-md-3">科目名稱：{{item.Cos_Name}}</div>
                <div class="col-md-3">修改日期：{{item.UpdateTime}}</div>
                <div class="col-md-3">修改人員：{{item.UpdateUser}}</div>
                <div class="col-md-3">授課教師：{{item.TchName}}</div>
                <div class="col-md-3">成績百分比：{{item.Grade_Mode}}</div>
                <div class="col-md-3">學生人數：{{item.Counts}}</div>
            </div>
            <div class="row">
                <table class="table table-hover table-striped table-rwd text-center">
                    <thead class="table-rwd-hide thead-dark-red">
                        <tr *ngFor="let item of thiscosInfo;">
                            <th style="width:150px;">班級</th>
                            <th style="width:100px;">學號</th>
                            <th style="width:150px;">姓名</th>
                            <th style="width:60px;">狀態</th>
                            <ng-container *ngIf="item.Grade_Mode_Id == '1'">
                                <th style="width:70px;">期中</th>
                                <th style="width:70px;">平時</th>
                                <th style="width:70px;">期末</th>
                            </ng-container>
                            <th style="width:80px;">學期成績</th>
                            <th style="width:80px;">待補登</th>
                            <th style="width:250px;">備註(100字)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ScoreData; " id="{{item.Std_No}}">
                            <td data-th="班級" style="width:150px;">{{item.Class_Short}}</td>
                            <td data-th="學號" style="width:100px;">{{item.Std_No}}</td>
                            <td data-th="姓名" style="width:150px;">{{item.Std_Name}}</td>
                            <td data-th="狀態" style="width:60px;">{{item.Mark_Status}}</td>
                            <ng-container *ngIf="item.Grade_Mode == '1'">
                                <td data-th="期中" style="width:70px;"><input type="text" maxLength="3" [(ngModel)]="item.MTerm_Score"
                                        name="MTerm_Score" (ngModelChange)="caculateScore(item.Std_No)"
                                        id="{{item.Std_No+'MT'}}" class="form-control shadow-none"
                                        [ngModelOptions]="{standalone: true}" /></td>
                                <td data-th="平時" style="width:70px;"><input type="text" maxLength="3" [(ngModel)]="item.Quiz_Score"
                                        name="Quiz_Score" (ngModelChange)="caculateScore(item.Std_No)"
                                        id="{{item.Std_No+'QU'}}" class="form-control shadow-none"
                                        [ngModelOptions]="{standalone: true}" /></td>
                                <td data-th="期末" style="width:70px;"><input type="text" maxLength="3" [(ngModel)]="item.FTerm_Score"
                                        name="FTerm_Score" (ngModelChange)="caculateScore(item.Std_No)"
                                        id="{{item.Std_No+'FT'}}" class="form-control shadow-none"
                                        [ngModelOptions]="{standalone: true}" /></td>
                            </ng-container>
                            <td data-th="學期成績" style="width:80px;">
                                <ng-container *ngIf="item.Grade_Mode == '1'">
                                    <input type="text" maxLength="3" disabled readonly [(ngModel)]="item.Final_Score"
                                        name="Final_Score" id="{{item.Std_No + 'FI'}}" class="form-control shadow-none"
                                        [ngModelOptions]="{standalone: true}" />
                                </ng-container>
                                <ng-container *ngIf="item.Grade_Mode == '2'">
                                    <input type="text" maxLength="3" [(ngModel)]="item.Final_Score" name="Final_Score"
                                        id="{{item.Std_No + 'FI2'}}" class="form-control shadow-none"
                                        [ngModelOptions]="{standalone: true}" />
                                </ng-container>
                            </td>
                            <td data-th="待補登" style="width:80px;">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="{{item.Std_No + 'c'}}"
                                        [(ngModel)]="item.Rest_Mark_boolean" name="Rest_Mark_boolean"
                                        [ngModelOptions]="{standalone: true}">
                                    <label class="custom-control-label" for="{{item.Std_No + 'c'}}"></label>
                                </div>
                            </td>
                            <td data-th="備註(100字)" style="width:250px;"><input type="text" maxLength="100" [(ngModel)]="item.ReMark"
                                    name="ReMark" class="form-control shadow-none" /></td>
                            <td class="td-rwd-hide" data-th=""></td>
                            <td style="display: none;"><input type="hidden" [(ngModel)]="item.DataKey" name="DataKey"></td>
                            <td style="display: none;"><input type="hidden" [(ngModel)]="item.DataKeySave"
                                    name="DataKeySave"></td>
                            <td style="display: none;"><input type="hidden" [(ngModel)]="item.FTerm_Ratio"
                                    name="FTerm_Ratio" id="{{item.Std_No+'FTR'}}"></td>
                            <td style="display: none;"><input type="hidden" [(ngModel)]="item.MTerm_Ratio"
                                    name="MTerm_Ratio" id="{{item.Std_No+'MTR'}}"></td>
                            <td style="display: none;"><input type="hidden" [(ngModel)]="item.Quiz_Ratio" name="Quiz_Ratio"
                                    id="{{item.Std_No+'QUR'}}"></td>
                            <td style="display: none;"><input type="hidden" [(ngModel)]="item.Send" name="Send"></td>
                            <td style="display: none;"><input type="hidden" [(ngModel)]="item.IP" name="IP"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="container mb-3 text-center">
                <button class="save-btn-gray float-start me-3" type="button"
                    (click)="CheckData('temp_ScoreData')">儲存</button>
                <button class="send-btn float-start" type="button" (click)="CheckData('save_ScoreData')">送出</button>
            </div>
        </div>
    </div>
</div>