<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getGRDScoreOrderList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year formControlName="Years"
                        (change)="getEduList(Year.value, Term.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term formControlName="Term"
                        (change)="getEduList(Year.value, Term.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" #edu
                        (change)="getDeptList(edu.value, Year.value, Term.value)" formControlName="EduNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                            {{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" #Dept
                        (change)="getClassList(edu.value, Dept.value, Year.value, Term.value)" formControlName="DeptNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">年級</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Grade">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">排名依據</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Sort">
                        <option value="" selected>--請選擇--</option>
                        <option value="A">班級學期排名</option>
                        <option value="B">班級學年排名</option>
                        <option value="C">系所學期排名</option>
                        <option value="D">系所學年排名</option>
                        <option value="E">畢業成績排名</option>
                        <option value="F">累積成績排名</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">計算至小數點第</span>
                    </div>
                    <input type="number" class="form-control shadow-none" formControlName="Points" value="2" min="0" max="4">
                    <div class="input-group-prepend">
                        <span class="input-group-text">位</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">顯示前百分之</span>
                    </div>
                    <input type="number" class="form-control shadow-none" formControlName="ShowPercent">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">顯示前</span>
                    </div>
                    <input type="number" class="form-control shadow-none" formControlName="ShowOrder">
                    <div class="input-group-prepend">
                        <span class="input-group-text">位</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">排序</span>
                    </div>
                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                        <input type="radio" class="btn-check" id="btncheck1" name="Orderby" autocomplete="off" value="1"
                            formControlName="Orderby">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck1">學號</label>

                        <input type="radio" class="btn-check" id="btncheck2" name="Orderby" autocomplete="off" value="2"
                            formControlName="Orderby">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck2">名次</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button type="button" class="other-Button-gray float-end" (click)="exportReportADGRD0303()">匯出</button>
                <button type="button" class="other-Button-gray float-end me-3"
                    (click)="getGRDScoreOrderConfirm()">確認資料</button>
                <button type="button" class="other-Button-gray float-end me-3"
                    (click)="getGRDScoreOrderCompute()">計算</button>
                <button class="search-btn float-end me-3">查詢</button>
            </div>
        </div>
    </form>

    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th>學年學期</th>
                <th>系所</th>
                <th style="width: 80px;">年級</th>
                <th>班級</th>
                <th>名次</th>
                <th>學號</th>
                <th>姓名</th>
                <th style="width: 80px;">總分</th>
                <th style="width: 80px;">總學分</th>
                <th style="width: 80px;">平均分數</th>
                <th style="width: 80px;">百分比</th>
                <th style="width: 80px;">班級人數</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of gRDScoreOrderList">
                <td>{{item.Years}} - {{item.Term}}</td>
                <td>{{item.DeptName}}</td>
                <td style="width: 80px;">{{item.Grade}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.Orders}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td style="width: 80px;">{{item.AllScore}}</td>
                <td style="width: 80px;">{{item.AllCredit}}</td>
                <td style="width: 80px;">{{item.AvgScore}}</td>
                <td style="width: 80px;">{{item.Percents}}</td>
                <td style="width: 80px;">{{item.Classmates}}</td>
            </tr>
        </tbody>
    </table>
</div>