import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { GetSTDOutlist } from 'src/app/_Models/STDWEB/STDW10011/getSTDOutList';
import { GetSTDOutDetail } from 'src/app/_Models/STDWEB/STDW10011/getSTDOutDetail';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { GetEDocFlow } from 'src/app/_Models/getEDocFlow';
import { GetSelectList } from 'src/app/_Models/getSelectList';

@Component({
    selector: 'app-STDW10011',
    templateUrl: './STDW10011.component.html',
    styleUrls: ['./STDW10011.component.css']
})
export class STDW10011Component implements OnInit {
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    checkType = new CheckType();
    fileExchange = new FileExchange();

    sTDOutList!: GetSTDOutlist[];
    sTDOutDetail!: GetSTDOutDetail;
    outReasonList!: GetSelectList[];

    //term = require('../../_Models/_SelectInput/term.json').term;

    term!:GetSelectList[];
    Years_Range: string[] = [];
    NowYears:string="";
    NowTerm:string="";
    eDocFlow!: GetEDocFlow[];
    showButton: boolean = false;
    subpageconfig = { backdrop: true, ignoreBackdropClick: true, class: "modal-lg" };

    constructor(
        private modalService: BsModalService,
        private systemService: SystemService,
        private translateService: TranslateService,
        private http: HttpClient,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage);
        this.getSTDOutList();
        this.getOutReason();
        this.systemService.getNowYearTerm().subscribe((response) =>{
            this.NowYears=response.Now_Years;
            this.NowTerm=response.Now_Term;
        });
    }

    openDetail(template: TemplateRef<any>, dataKey: string, stdno: string, serialno: string) {
        this.Years_Range=[];
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_11/STD_Out_Detail", { DataKey: dataKey, StdNo: stdno, SerialNo: serialno }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
            } else {
                this.sTDOutDetail = response;

                if(this.NowTerm=="1"){
                    this.Years_Range.push(this.NowYears);
                    this.term=[{ItemName:"1",ItemValue:"1",DataKey:""},{ItemName:"2",ItemValue:"2",DataKey:""}];
                }
                else{
                    for (let i = Number(this.NowYears); i <= Number(this.NowYears)+1; i++) {
                        this.Years_Range.push(i.toString());
                    }
                    this.term=[{ItemName:this.NowTerm,ItemValue:this.NowTerm,DataKey:""}];
                }

                switch (this.sTDOutDetail.Status) {
                    case '':
                        this.showButton = true;
                        break;
                    case '0':
                        this.showButton = true;
                        break;
                    case '4':
                        this.showButton = true;
                        break;
                    default:
                        this.showButton = false;
                        break;
                }

                this.modalRef = this.modalService.show(template, this.subpageconfig);
            }
        });
    }

    initTerm(event:any){
        this.term=[];
        if(this.NowTerm=="1"){
            this.term=[{ItemName:"1",ItemValue:"1",DataKey:""},{ItemName:"2",ItemValue:"2",DataKey:""}];
        }
        else{
            let index:number = event.target["selectedIndex"];
            this.term=[(index==0)?{ItemName:"2",ItemValue:"2",DataKey:""}:{ItemName:"1",ItemValue:"1",DataKey:""}];
        }
    }

    openFlow(template: TemplateRef<any>, dataKey: string) {
        const senddata = this.http.post<any>(this.baseUrl + "Public/Get_EDoc_Flow", { DataKey: dataKey });
        senddata.subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eDocFlow = response;
            this.modalRef = this.modalService.show(template, this.subpageconfig);
        }, error => this.toastr.error(error.error));
    }

    getSTDOutList() {
        this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW100_11/STD_Out_List").subscribe((response) => {
            this.sTDOutList = []
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sTDOutList = response;
        }, error => this.toastr.error(error.error));
    }

    getOutReason() {
        this.systemService.getStdOutReason().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.outReasonList = response;
        })
    }

    importInputCkick(type: string) {
        if (type == 'AgreePaper') {
            document.getElementById('AgreePaper')?.click();
        }
        else {
            document.getElementById('OutPaper')?.click();
        }

    }

    importData($event: any, dataKey: string, type: string) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type)
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {

            let OutPaper = <HTMLInputElement>document.getElementById("OutPaper");
            let AgreePaper = <HTMLInputElement>document.getElementById("AgreePaper");

            if (result.isConfirmed) {
                if (type == 'OutPaper') {
                    that.sTDOutDetail.OutPaper = file.name;
                    that.sTDOutDetail.OutPaperBase64 = base64;
                }
                else {
                    that.sTDOutDetail.AgreePaper = file.name;
                    that.sTDOutDetail.AgreePaperBase64 = base64;
                }
            }
            else if (result.isDenied) {
                if (type == 'OutPaper') {
                    OutPaper.value = '';
                }
                else {
                    AgreePaper.value = ''
                }

                that.toastr.info("已取消上傳。");
            }
        });
    }

    clearfile(type: string) {

        let OutPaper = <HTMLInputElement>document.getElementById("OutPaper");
        let AgreePaper = <HTMLInputElement>document.getElementById("AgreePaper");

        if (type == 'OutPaper') {
            this.sTDOutDetail.OutPaper = '';
            this.sTDOutDetail.OutPaperBase64 = '';
            OutPaper.value = '';
        }
        else {
            this.sTDOutDetail.AgreePaper = '';
            this.sTDOutDetail.AgreePaperBase64 = '';
            AgreePaper.value = ''
        }
    }

    getSTDDelete() {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_11/STD_Out_Delete", this.sTDOutList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.getSTDOutList();
        })
    }

    getSTDOutSave(type: string) {

        if (this.checkType.CheckIsNumber(this.sTDOutDetail.Years)) {
            this.toastr.info("退學年請填入數字");
            return;
        }

        if (this.sTDOutDetail.Term == '') {
            this.toastr.info("請選擇退學學期");
            return;
        }

        if (this.sTDOutDetail.OutNo == '') {
            this.toastr.info("請選擇退學原因");
            return;
        }

        if (this.sTDOutDetail.OutPaper == '') {
            this.toastr.info("請上傳證明文件");
            return;
        }

        if (this.sTDOutDetail.AgreePaper == '') {
            this.toastr.info("請上傳家長同意書");
            return;
        }

        if (
            Number(this.sTDOutDetail.Years + this.sTDOutDetail.Term) < Number(this.sTDOutDetail.StartYear + this.sTDOutDetail.StartTerm)
            || Number(this.sTDOutDetail.Years) > Number(this.sTDOutDetail.EndYear)
        ) {
            this.toastr.info("數字不在學年範圍內");
            return;
        }

        if (type == 'save') {
            this.sTDOutDetail.Send = '0'
            this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW100_11/STD_Out_Save', this.sTDOutDetail).subscribe((response) => {
                for (let i = 0; i < 2; i++) {
                    if (response[i].Info == false) {
                        this.toastr.warning(response[i].Message);
                        return;
                    }
                }
                this.toastr.success("儲存成功");
                this.modalRef.hide();
                this.getSTDOutList();
            })
        }
        else {
            Swal.fire({
                title: "一旦送出即不能修改，確定?",
                showDenyButton: true,
                confirmButtonText: '是',
                denyButtonText: '否',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.sTDOutDetail.Send = '1';
                    this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW100_11/STD_Out_Save', this.sTDOutDetail).subscribe((response) => {
                        for (let i = 0; i < 2; i++) {
                            if (response[i].Info == false) {
                                this.toastr.warning(response[i].Message);
                                return;
                            }
                        }
                        this.toastr.success("送出成功");
                        this.modalRef.hide();
                        this.getSTDOutList();
                    })
                }
                else {
                    this.toastr.info("已取消");
                }
            })
        }
    }

}
