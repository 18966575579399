<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Condition_Year [(ngModel)]="condition_model.Years">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of years_data" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Condition_Term [(ngModel)]="condition_model.Term">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term_data" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-7">
            <button class="search-btn float-start me-3 mb-3"
                (click)="Search(Condition_Year.value, Condition_Term.value)">查詢</button>
            <button class="other-Button-gray float-end me-3 mb-3" (click)="Clear_Data()">清除</button>
            <button class="other-Button-gray float-end me-3 mb-3" [disabled]="!have_selected"
                (click)="Delete()">刪除</button>
            <button class="other-Button-gray float-end me-3 mb-3" [disabled]="!have_selected"
                (click)="Insert()">儲存</button>
            <button class="other-Button-gray float-end me-3 mb-3" [hidden]="have_selected"
                (click)="Insert()">新增</button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <table class="table table-hover text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th>學年</th>
                        <th>學期</th>
                        <th>身分別</th>
                        <th>保險金額</th>
                        <th>減免後金額</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" class="pointer"
                        *ngFor="let item of data_list_model; let i = index" (click)="Search_Detail(item.DataKey, i)">
                        <td>{{item.Years}}</td>
                        <td>{{item.Term}}</td>
                        <td>{{item.RedClassName}}</td>
                        <td>{{item.SIMoney}}</td>
                        <td>{{item.RCMoney}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-4">
            <div class="mt-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year [disabled]="have_selected"
                        [(ngModel)]="data_model.Years">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of years_data" [value]="item">{{item}}學年</option>
                    </select>
                </div>
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term [disabled]="have_selected"
                        [(ngModel)]="data_model.Term">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term_data" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">減免身分別</span>
                    </div>
                    <select class="form-select shadow-none" #RedClass_No [disabled]="have_selected"
                        [(ngModel)]="data_model.RedClassNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of redClass" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
                <div class="input-group flex-nowrap mb-3 mt-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">保險金額　</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="data_model.SIMoney" />
                </div>
                <div class="input-group flex-nowrap mb-3 mt-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">減免後金額</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="data_model.RCMoney" />
                </div>
                <div class="input-group flex-nowrap mb-3 mt-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">修改者</span>
                    </div>
                    <input type="text" readonly class="form-control shadow-none" [(ngModel)]="data_model.UpdateUser" />
                </div>
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">修改時間</span>
                    </div>
                    <input type="text" readonly class="form-control shadow-none" [(ngModel)]="data_model.UpdateTime" />
                </div>
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">修改者IP</span>
                    </div>
                    <input type="text" readonly class="form-control shadow-none" [(ngModel)]="data_model.UpdateIP" />
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <br />
    </div>
</div>