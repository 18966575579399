import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetOpClassList } from 'src/app/_Models/getOpClassList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetCosQuestionList } from 'src/app/_Models/TCHWEB/TCH0309/getCosQuestionList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0309',
    templateUrl: './TCH0309.component.html',
    styleUrls: ['./TCH0309.component.css']
})
export class TCH0309Component implements OnInit {
    opClassList!: GetSelectList[] | undefined;
    cosList!: GetSelectList[] | undefined;
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    cosQuestionList!: GetCosQuestionList[];
    getOpClassListInput: GetOpClassList = {
        Years: '',
        Term: '',
        DeptNo: '',
        TeamNo: '',
        TchNo: '',
        Classroom: '',
        UserCode: '',
        CHeckRight: ''
    };
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private accountService: AccountService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.getOpClassListInput.TchNo = response.Code;
            }
        }, error => this.toastr.error(error.error))

        this.timeService.getTimeCntr().subscribe((response) => {
            this.getOpClassListInput.Years = response.Now_Years;
            this.getOpClassListInput.Term = response.Now_Term;
            this.getOpClassList(this.getOpClassListInput)
        }, error => this.toastr.error(error.error))
    }

    getOpClassList(model: GetOpClassList) {
        this.systemService.getOpClassList(model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.opClassList = response;
            this.getCosList(response[0].ItemValue);
        }, error => this.toastr.error(error.error));
    }

    getCosList(opClass: any) {
        this.http.post<any>(this.baseUrl + "Public/List_Cos", {
            Years: this.getOpClassListInput.Years,
            Term: this.getOpClassListInput.Term,
            OpClass: opClass
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosList = response;
        }, error => this.toastr.error(error.error));
    }

    setModel(model: any) {
        this.getOpClassListInput.Years = model.Years;
        this.getOpClassListInput.Term = model.Term;
        this.opClassList = undefined;
        this.cosList = undefined;
        this.getOpClassList(this.getOpClassListInput);
    }

    getCosQuestionList(dataKey: string, status: string) {
        if (dataKey == '') {
            this.toastr.info("請選擇科目");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_09/Get_Cos_Question_List", { Status: status, DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cosQuestionList = []
                return;
            }
            this.cosQuestionList = response;
            this.cosQuestionList.forEach(element => {
                element.AnsContent = element.AnsContent;
                element.Status = element.Status;
            });
        })
    }

    getCosQuestionAns(ans: string, dataKey: string, index: number) {

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_09/Get_Cos_Question_Ans", {
            QuestContent: ans,
            DataKey: dataKey
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosQuestionList[index].Status = '已答覆';
            this.toastr.success("已送出答覆");
        });
    }

    editAnswer(index: number) {
        this.cosQuestionList[index].Status = '未答覆';
    }


}
