import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetCUPClassTeamDetail } from 'src/app/_Models/AD/ADCUP/ADCUP0601/getCUPClassTeamDetail';
import { GetCUPClassTeamList } from 'src/app/_Models/AD/ADCUP/ADCUP0601/getCUPClassTeamList';
import { GetCUPClassTeamTeamList } from 'src/app/_Models/AD/ADCUP/ADCUP0601/getCUPClassTeamTeamList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUP0601',
    templateUrl: './ADCUP0601.component.html',
    styleUrls: ['./ADCUP0601.component.css']
})
export class ADCUP0601Component implements OnInit {
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    eduList!: GetEduList[];
    detailEduList!: GetEduList[];
    deptList!: GetDeptList[];
    detailDeptList!: GetDeptList[];
    teamList!: GetTeamList[];
    classList!: GetClassList[];
    cUPClassTeamList!: GetCUPClassTeamList[];
    cUPClassTeamTeamList!: GetCUPClassTeamTeamList[];
    cUPClassTeamDetail: GetCUPClassTeamDetail = {
        DataKey: '',
        Years: '',
        EduNo: '',
        DeptNo: '',
        Class: '',
        TeamNo: '',
        UpdateUser: '',
        UpdateTime: '',
    };

    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl(''),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl('')
    })

    constructor(private http: HttpClient, private systemService: SystemService, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('0', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
            this.detailEduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, isDeatil: boolean) {
        this.systemService.getDeptList(edu, '0', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (isDeatil) {
                this.detailDeptList = response;
                return;
            }
            this.deptList = response;


        }, error => this.toastr.error(error.error));
    }

    getTeamList(dept: any) {
        this.systemService.getTeamList('0', '', '', dept, '').subscribe((response) => {
            this.teamList = response;
        });
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '0', '', '', 'Y', '').subscribe((response) => {
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getCUPClassTeamList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請至少選擇開課學年");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0601/Get_CUP_ClassTeam_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUPClassTeamList = response;

            this.cUPClassTeamList.forEach(element => {
                element.Selected = false;
            })
        })
    }

    getCUPClassTeamTeamList(datakey: string, index: number) {

        this.cUPClassTeamList.forEach(element => {
            element.Selected = false;
        });
        this.cUPClassTeamList[index].Selected = true;

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0601/Get_CUP_ClassTeam_TeamList", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cUPClassTeamTeamList = [];
                return;
            }
            this.cUPClassTeamTeamList = response;

            this.cUPClassTeamTeamList.forEach(element => {
                element.Selected = false;
            })
        })
    }

    getCUPClassTeamDetail(datakey: string, index: number) {

        this.cUPClassTeamTeamList.forEach(element => {
            element.Selected = false;
        });
        this.cUPClassTeamTeamList[index].Selected = true;

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0601/Get_CUP_ClassTeam_Detail", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.cUPClassTeamDetail = response;
            this.getDeptList(this.cUPClassTeamDetail.EduNo, true);
            this.getTeamList(this.cUPClassTeamDetail.DeptNo);
            this.getClassList(this.cUPClassTeamDetail.EduNo, this.cUPClassTeamDetail.DeptNo);
        })
    }

    clear() {
        this.cUPClassTeamDetail = {
            DataKey: '',
            Years: '',
            EduNo: '',
            DeptNo: '',
            Class: '',
            TeamNo: '',
            UpdateUser: '',
            UpdateTime: '',
        };
        this.cUPClassTeamList = [];
        this.cUPClassTeamTeamList = [];
    }

    getCUPClassTeamModify() {

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0601/Get_CUP_ClassTeam_Modify", this.cUPClassTeamDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        });
    }

    getCUPClassTeamDelete() {

        if (this.cUPClassTeamDetail.DataKey == '') {
            this.toastr.info("請選擇要刪除的項目");
            return;
        }

        Swal.fire({
            title: `確定要刪除？`,
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0601/Get_CUP_ClassTeam_Delete", { DataKey: this.cUPClassTeamDetail.DataKey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                });
            }
            else {
                this.toastr.info("動作取消");
            }
        })
    }

    getCUPClassTeamCopyTo() {
        Swal.fire({
            title: "確定要複製上一年度資料？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0601/Get_CUP_ClassTeam_CopyTo", this.ngFormInput.value).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("複製成功");
                });
            }
            else {
                this.toastr.info("動作取消");
            }
        })
    }

}
