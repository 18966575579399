import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetTFSFeeItemList } from 'src/app/_Models/AD/ADTFS/ADTFS01A03/getTFSFeeItemList';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADTFS01A03',
    templateUrl: './ADTFS01A03.component.html',
    styleUrls: ['./ADTFS01A03.component.css']
})
export class ADTFS01A03Component implements OnInit {

    baseUrl = environment.apiUrl;
    itemList!: GetTFSFeeItemList[];
    itemDetail: any = {
        DataKey: "",
        FeeItemNo: "",
        FeeItemName: "",
        UnitNo: "",
        UnitName: "",
        AFMark: "",
        PEMark: "",
        SLMark: "",
        TEMark: "",
        TFMark: "",
        UpdateUser: "",
        UpdateTime: "",
        UpdateIP: ""
    };
    constructor(private http: HttpClient, private toastr: ToastrService) { }

    ngOnInit(): void {
    }

    getTFSFeeItemList(feeItemName: string, unitName: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01A03/Get_TFS_FeeItemList",
            {
                FeeItemName: feeItemName,
                UnitName: unitName
            }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    this.itemList = []
                    return;
                }
                this.itemList = response;
            })
    }

    getTFSFeeItemDetail(index: number, datakey: string) {

        this.itemList.forEach(element => {
            element.Selected = false;
        })
        this.itemList[index].Selected = true;

        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01A03/Get_TFS_FeeItemDetail", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            // 解碼
            this.itemDetail = response;
            this.itemDetail.FeeItemName = response.FeeItemName;
            this.itemDetail.UnitName = response.UnitName;
            this.itemDetail.UpdateIP = response.UpdateIP;
            this.itemDetail.UpdateTime = response.UpdateTime;
            this.itemDetail.UpdateUser = response.UpdateUser;
        })
    }

    setCheckbox(type: string, event: any) {
        let input = <HTMLInputElement>event.target;
        if (input.checked) {
            this.itemDetail[type] = 'Y'
        } else {
            this.itemDetail[type] = ''
        }
    }

    saveData() {

        if (this.itemDetail.DataKey == '') return;

        // 編碼回去
        this.itemDetail.FeeItemName = this.itemDetail.FeeItemName;
        this.itemDetail.UnitName = this.itemDetail.UnitName;
        this.itemDetail.UpdateIP = this.itemDetail.UpdateIP;
        this.itemDetail.UpdateTime = this.itemDetail.UpdateTime;
        this.itemDetail.UpdateUser = this.itemDetail.UpdateUser;

        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01A03/Get_TFS_FeeItemSave", this.itemDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.toastr.success("修改成功");
            this.resetData();
        }, error => this.toastr.error(error.error))
    }

    resetData() {
        this.itemDetail = {
            DataKey: "",
            FeeItemNo: "",
            FeeItemName: "",
            UnitNo: "",
            UnitName: "",
            AFMark: "",
            PEMark: "",
            SLMark: "",
            TEMark: "",
            TFMark: "",
            UpdateUser: "",
            UpdateTime: "",
            UpdateIP: ""
        };
    }


}
