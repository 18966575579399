import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0303/search';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUP0303',
    templateUrl: './ADCUP0303.component.html',
    styleUrls: ['./ADCUP0303.component.css']
})
export class ADCUP0303Component implements OnInit {
    baseUrl = environment.apiUrl;
    data!: Search[];
    dataDetail: Search = {
        Attributes_No: '',
        Attributes_Name: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
    };
    constructor(private http: HttpClient, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.search();
    }

    search() {
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0303/Search").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        });
    }

    searchDetail(index: number) {
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.dataDetail = this.data[index];
    }

    update() {
        if (this.dataDetail.Attributes_No == '' || this.dataDetail.Attributes_Name == '') {
            this.toastr.info("請填妥欄位");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0303/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.search();
        });
    }

    insert() {
        if (this.dataDetail.Attributes_No == '' || this.dataDetail.Attributes_Name == '') {
            this.toastr.info("請填妥欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0303/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search();
        });
    }

    clear() {
        this.dataDetail = {
            Attributes_No: '',
            Attributes_Name: '',
            Update_User: '',
            Update_Time: '',
            Selected: false
        };
    }

    delete() {

        if (this.dataDetail.Attributes_No == '' || this.dataDetail.Attributes_Name == '') {
            this.toastr.info("請選擇資料");
            return;
        }

        Swal.fire({
            title: `確定要刪除？`,
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0303/Delete", this.dataDetail).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.search();
                });
            }
            else {
                this.toastr.info("動作取消");
            }
        })
    }

}
