<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="exportReportADSEL0502()">
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" formControlName="Years" (change)="getDeptList()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學期</span>
                    <select class="form-select shadow-none" formControlName="Term" (change)="getDeptList()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">異常類型</span>
                    <select class="form-select shadow-none" formControlName="CheckType">
                        <option value="A1">選課人數不足</option>
                        <option value="A2">選課人數超過上限</option>
                        <option value="A3">停開課程</option>
                        <option value="B1">超修不足表</option>
                        <option value="B2">衝堂</option>
                        <option value="B3">擋修</option>
                        <option value="C1">重複修課</option>
                        <option value="C2">有註冊未選課</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="" selected disabled>--請選擇系所--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div>
            <button class="mx-auto export-btn">列印</button>
        </div>
    </form>
</div>