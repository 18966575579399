<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <h5 for=""> {{'目前選課學年期:'|translate}}{{'第'|translate}}{{modal.Sel_Years}}{{'學年'|translate}}{{modal.Sel_Term}}{{'學期'|translate}}</h5>
        </div>
        <div class="col-md-6">
            <div class="form-check form-check-inline">
                <input class="form-check-input pointer shadow-none" type="radio" name="flexRadioDefault"
                    id="flexRadioDefault1" value="1" checked [(ngModel)]="Status">
                <label class="form-check-label pointer" for="flexRadioDefault1">{{'選課階段'|translate}}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input pointer shadow-none" type="radio" name="flexRadioDefault"
                    id="flexRadioDefault2" value="2" [(ngModel)]="Status">
                <label class="form-check-label pointer shadow-none" for="flexRadioDefault2">{{'加退選階段'|translate}}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input pointer shadow-none" type="radio" name="flexRadioDefault"
                    id="flexRadioDefault3" value="3" [(ngModel)]="Status">
                <label class="form-check-label pointer shadow-none" for="flexRadioDefault3">{{'暑修選課'|translate}}</label>
            </div>
        </div>
        <div class="col-md-3 mb-3">
            <button class="other-Button-gray float-end" (click)="getSELDeptCurList()">{{'查詢'|translate}}</button>
            <button class="export-btn float-end me-3" (click)="getSELDeptCurExport()">{{'匯出'|translate}}</button>

        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 80px;"><input type="checkbox" class="form-check-input pointer shadow-none"
                                (click)="setDatakey('all', '', $event.target)">&ensp;{{'全選'|translate}}</th>
                        <th>{{'班級代碼'|translate}}</th>
                        <th>{{'班級名稱'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of classList">
                        <td style="width: 80px;"><input type="checkbox" class="form-check-input pointer shadow-none"
                                (click)="setDatakey('', item.Class, $event.target)" [(ngModel)]="item.checked"></td>
                        <td>{{item.Class}}</td>
                        <td>{{item.Class_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red ">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>{{'班級'|translate}}</th>
                        <th>{{'組別'|translate}}</th>
                        <th>{{'科目'|translate}}</th>
                        <th>{{'開課序號'|translate}}</th>
                        <th>{{'必選修別'|translate}}</th>
                        <th>{{'學分'|translate}}</th>
                        <th>{{'上課時間'|translate}}</th>
                        <th>{{'教師'|translate}}</th>
                        <th>{{'代碼'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of sELDeptCurExport; let i = index">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.ClassName}}</td>
                        <td>{{item.TchName}}</td>
                        <td>{{item.CosName}}</td>
                        <td>{{item.Serial}}</td>
                        <td>{{item.Credit}}</td>
                        <td>{{item.CurTime}}</td>
                        <td>{{item.TchName}}</td>
                        <td>{{item.SelNum}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
