<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="ngFormInput" (ngSubmit)="getSACTotalList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getEduList()" formControlName="Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getEduList()" formControlName="Term">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getDeptList()" formControlName="EduNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getClassList();" formControlName="DeptNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoTo">
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <button class="other-Button-gray float-end" type="button">刪除</button>
                <button class="other-Button-gray float-end me-3" type="button">儲存</button>
                <button class="search-btn float-end me-3">查詢</button>
            </div>
        </div>
    </form>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 80px;"><input type="checkbox" class="form-check-input shadow-none pointer">&ensp;全選
                </th>
                <th>學年期</th>
                <th>班級</th>
                <th>學號</th>
                <th>姓名</th>
                <th style="width: 80px;">基本分</th>
                <th style="width: 80px;">缺曠扣分</th>
                <th style="width: 100px;">獎懲加減分</th>
                <th style="width: 80px;">導師評分</th>
                <th style="width: 120px;">輔導教官評分</th>
                <th style="width: 100px;">系所長評分</th>
                <th style="width: 80px;">競賽加分</th>
                <th style="width: 80px;">全勤加分</th>
                <th style="width: 80px;">操行總分</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sACTotalList">
                <td style="width: 80px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                        [(ngModel)]="item.Checked"></td>
                <td>{{item.Years}}-{{item.Term}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td style="width: 80px;">{{item.BaseScore}}</td>
                <td style="width: 80px;">{{item.SACScore}}</td>
                <td style="width: 100px;">{{item.SRPScore}}</td>
                <td style="width: 80px;"><input type="number" max="100" min="0"
                        class="form-control form-control-sm shadow-none" [(ngModel)]="item.TeacherScore"></td>
                <td style="width: 120px;"><input type="number" max="100" min="0"
                        class="form-control form-control-sm shadow-none" [(ngModel)]="item.RectorScore"></td>
                <td style="width: 100px;"><input type="number" max="100" min="0"
                        class="form-control form-control-sm shadow-none" [(ngModel)]="item.DrillmasterScore"></td>
                <td style="width: 80px;"><input type="number" max="100" min="0"
                        class="form-control form-control-sm shadow-none" [(ngModel)]="item.RaceScore"></td>
                <td style="width: 80px;"><input type="number" max="100" min="0"
                        class="form-control form-control-sm shadow-none" [(ngModel)]="item.NoneLeaveScore"></td>
                <td style="width: 80px;">{{item.TotalScore}}</td>
            </tr>
        </tbody>
    </table>
</div>