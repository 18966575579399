import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW40002',
    templateUrl: './STDW40002.component.html',
    styleUrls: ['./STDW40002.component.css']
})
export class STDW40002Component implements OnInit {
    baseUrl = environment.apiUrl;
    data!: Get_Cur_List[];
    functionType = 'class';
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    class = ''
    eduNo!: any;
    deptNo!: any;
    model = {
        Years: '',
        Term: '',
        FormType: '04',
        KeyValue: '',
        WeekIndex: null
    }
    constructor(private http: HttpClient, private systemService: SystemService, private toastr: ToastrService, private timeService: TimeService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Term = response.Now_Term;
            this.model.Years = response.Now_Years;
            this.getEduList();
        });
    }

    getEduList() {
        this.systemService.getEduList('2', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        })
    }




    getCurList() {
        if (this.class == '') {
            this.toastr.warning("請選擇班級");
            return;
        }
        this.model.KeyValue = this.class;
        this.http.post<any>(this.baseUrl + "Public/Get_CurForm", this.model)
            .subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning("查無資料");
                    return;
                }
                this.data = response;
            },
                error => this.toastr.error(error.error)
            );
    }

    setModel(model: any) {
        this.model.Years = model.Years;
        this.model.Term = model.Term;
    }

    getDeptList(event: any) {
        this.eduNo = <HTMLInputElement>event.target;
        this.systemService.getDeptList(this.eduNo.value, '2', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning("查無資料");
                return;
            }
            this.deptList = response;
        })

    }

    getClassList(event: any) {
        this.deptNo = <HTMLInputElement>event.target;
        this.systemService.getClassList(this.eduNo.value, this.deptNo.value, '2', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning("查無資料");
                return;
            }
            this.classList = response;
        })
    }

}
