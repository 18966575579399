<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業年度</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSchNoListInput.GradYear">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}</option>
                </select>
            </div>
        </div>        
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業學年</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSchNoListInput.Years">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業學期</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSchNoListInput.Term">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #eduNo [(ngModel)]="stdGradSchNoListInput.EduNo" (change)="getDeptList(eduNo.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>        
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSchNoListInput.DeptNo">
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdGradSchNoListInput.StdNoFrom">
                    <span class="input-group-text">至</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdGradSchNoListInput.StdNoTo">
            </div>
        </div>
        <div class="col-md-1">
            <div class="input-group flex-nowrap mb-1">
                <span class="input-group-text"><input type="checkbox" [(ngModel)]="stdGradSchNoListInput.Maked">&nbsp;未編號</span>
            </div>
        </div>   
        <div class="col-md-3">
            <button class="other-Button-gray float-end me-3" (click)="openModal(ADNoTemplate)">設定證號</button>
            <button class="search-btn float-end me-3" (click)="getStdGradSchNoList()">查詢</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" (click)="setAll(-1,$event.target)">&ensp;全選</th>
                <th>畢業日期</th>                
                <th>畢業學年</th>
                <th>畢業學期</th>
                <th>學制</th>
                <th>系所</th>
                <th>班級</th>                                         
                <th>學號</th>
                <th>姓名</th>
                <th>畢業證書號</th>
                <th>狀態</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdGradSchNoList; let i = index">
                <td><input type="checkbox" [hidden]="item.SchNo != ''" [checked]="item.Checked"
                        (click)="setAll(i, $event.target)"></td>
                <td>{{item.GradDate}}</td>
                <td>{{item.Years}}</td>
                <td>{{item.Term}}</td>
                <td>{{item.EduName}}</td>                  
                <td>{{item.DeptName}}</td>                  
                <td>{{item.ClassName}}</td>               
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td class="TxtAlignLeft">{{item.SchNo}}</td>
                <td>{{item.Status}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #ADNoTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定畢業證號</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業學年</span>
                    <select class="form-select shadow-none" [ngModel]="stdGradSchNoListInput.Years" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item" disabled>{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業學期</span>
                    <select class="form-select shadow-none" [ngModel]="stdGradSchNoListInput.Term" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}" disabled>{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業日期</span>
                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly required [bsValue]="model.GradDate" (ngModelChange)="ModelShowYear($event)" [(ngModel)]="model.GradDate"
                        style="background-color: transparent;">
                        <span class="input-group-text">
                            畢業年度：<input type="text" readonly class="form-control pointer shadow-none TextW80" [(ngModel)]="model.ShortYear">
                        </span>                        
                </div>           
            </div>
        </div>
        <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-7">
                <button class="save-btn-gray" (click)="getStdGradSchNoSetADNo()">設定</button>
            </div>
        </div>
    </div>    
</ng-template>