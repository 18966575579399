import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetCosHomework } from 'src/app/_Models/TCHWEB/TCH0306/getCosHomework';
import { GetStdHomework } from 'src/app/_Models/TCHWEB/TCH0306/getStdHomework';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCh0306',
    templateUrl: './TCh0306.component.html',
    styleUrls: ['./TCh0306.component.css']
})
export class TCH0306Component implements OnInit {

    baseUrl = environment.apiUrl;
    cosHomeworkList!: GetCosHomework[];
    homeworkList!: GetSelectList[];
    StdHomeworkList!: GetStdHomework[];
    modalRef!: BsModalRef;
    btnHidden = true;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private timeService: TimeService) { }

    ngOnInit(): void {

        this.timeService.getTimeCntr().subscribe((response) => {
            this.getCosHomeWork({ Years: response.Now_Years, Term: response.Now_Term });
        }, error => this.toastr.error(error.error))
    }

    getCosHomeWork(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_06/Get_Cos_HomeWork", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cosHomeworkList = []
                return;
            }

            this.cosHomeworkList = response;
        })
    }

    openModal(template: TemplateRef<any>, dataKey: string) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_06/Get_Cos_HomeWork_List", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.homeworkList = response;
            this.modalRef = this.modalService.show(template, modalConfig);
        }, error => this.toastr.error(error.error))
    }

    getStdHomeworkList(dataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_06/Get_Cos_HomeWork_Std", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.StdHomeworkList = response;
        })
    }

    editData() {
        this.btnHidden = !this.btnHidden;
    }

    saveData() {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_06/Get_Cos_HomeWork_Save", this.StdHomeworkList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.btnHidden = !this.btnHidden;
            this.toastr.success("儲存成功");
        }, error => this.toastr.error(error.error))
    }

}
