<div class="container">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
    <div class="row">
        <div class="col-md">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'單位' | translate}}</span>
                </div>
                <select class="form-select shadow-none" (change)="getTeacherList($event)">
                    <option disabled selected value="">{{'--請選擇單位--' | translate}}</option>
                    <option *ngFor="let item of unitData" value="{{item.ItemValue}}">
                        {{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'教師' | translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="teacher">
                    <option disabled selected value="">{{'--請選擇教師--' | translate}}</option>
                    <option *ngFor="let item of teacherList" value="{{item.ItemValue}}">
                        {{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <button class="search-btn mb-3" (click)="getCurList()">{{'查詢' | translate}}</button>
    <app-cur-class id="cur-class" [curList]="data" [functionType]="functionType"></app-cur-class>
    <app-cur-class-rwd id="cur-class-rwd" [curList]="data" [functionType]="functionType"></app-cur-class-rwd>
</div>