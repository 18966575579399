import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ExportReportADSTD0704Input } from 'src/app/_Models/AD/ADSTD/ADSTD0704/exportReportADSTD0704Input';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSTD0704',
    templateUrl: './ADSTD0704.component.html',
    styleUrls: ['./ADSTD0704.component.css']
})
export class ADSTD0704Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    diplomaList!: GetSelectList[];
    exportReportInput: ExportReportADSTD0704Input = {
        Years: '',
        Term: '',
        EduNo: '',
        DeptNo: '',
        TeamNo: '',
        Class: '',
        StdNo: '',
        ReportType: ''
    };
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private fileExchangeServices: FileExchangeService,
        private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
        this.getDiploma();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }


    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))
    }

    getDiploma() {
        this.systemService.getDiploma().subscribe((response) => {
            this.diplomaList = response;
        })
    }

    exportReportADSTD0704() {

        this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD07_04/Export_Report_ADSTD0704", this.exportReportInput)
            .subscribe((response) => {

                if (response.FileName == '') {
                    this.toastr.warning("查無資料");
                    return;
                }

                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = response.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            },
                error => this.toastr.error(error.error));
    }
}
