import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetCURTchMoneyModifyList } from 'src/app/_Models/AD/ADCUR/ADCUR0507/getCURTchMoneyModifyList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-adcur0507',
    templateUrl: './adcur0507.component.html',
    styleUrls: ['./adcur0507.component.css']
})
export class ADCUR0507Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    timeCaseList!: GetSelectList[];
    titleList!: GetSelectList[];
    unitData!: GetSelectList[];
    cURTchMoneyModifyList!: GetCURTchMoneyModifyList[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        TimeCase: new UntypedFormControl('', [Validators.required]),
        UnitCode: new UntypedFormControl(''),
        Title: new UntypedFormControl(''),
        TchNo: new UntypedFormControl(''),
        TchName: new UntypedFormControl(''),
        SortIndex: new UntypedFormControl(''),
        MinHours: new UntypedFormControl(0, [Validators.required]),
        MaxHours: new UntypedFormControl(0, [Validators.required])
    });

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,) { }
    ngOnInit(): void {
        this.getYears();
        this.getTimeCase();
        this.getTitleList();
    }

    getYears(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getTimeCase() {
        this.systemService.getTimeCase().subscribe((response) => {
            if (response.Info == false) {
                return;
            };
            this.timeCaseList = response;
        })
    }
    getTitleList() {
        this.systemService.getTitleList('B').subscribe((response) => {
            if (response.Info == false) {
                return;
            };
            this.titleList = response;
        })
    }

    getUnitList(Years: string, Term: string) {

        if (Years == '' || Term == '') {
            return;
        }

        this.systemService.getUnitList(Years, Term, '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.unitData = response;
        })
    }

    getCURTchMoneyModifyList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("欄位不正確");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0507/Get_CUR_TchMoney_Modify_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cURTchMoneyModifyList = []
                return;
            }
            this.cURTchMoneyModifyList = response;
            console.log(response);
        })
    }

    getCURTchMoneyModifySave() {

        if (!this.cURTchMoneyModifyList) {
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0507/Get_CUR_TchMoney_Modify_Save", this.cURTchMoneyModifyList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            };
            this.getCURTchMoneyModifyList()
            this.toastr.success("儲存成功");
        })
    }

    exportReport() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("欄位不正確");
            return;
        };

        this.fileExchangeServices.ExportFile("ACAD/CUR/ADCUR0507/Export_Report_ADCUR0507", this.ngFormInput.value)
            .subscribe((response) => {

                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }

                if (response.FileName == '') {
                    this.toastr.warning("查無資料");
                    return;
                }
                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = response.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }, error => this.toastr.error(error));
    }
}
