import { environment } from 'src/assets/environments/environment';
import { Component, OnInit } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADEMP/ADEMP0104/search'
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';

@Component({
    selector: 'app-ADSRP0308',
    templateUrl: './ADSRP0308.component.html',
    styleUrls: ['./ADSRP0308.component.css']
})
export class ADSRP0308Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    exportReportInput={
        In_Year: '',
        In_Term: '',
        Std_No: '',
        Std_Name:'',
        Class:''
    };
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private fileExchangeServices: FileExchangeService,
        private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }


    exportReportADSRP0308() {
        if(this.exportReportInput.In_Year == '')
        {
            this.toastr.warning('請填寫學年');
            return;
        }
        if(this.exportReportInput.In_Term == '')
        {
            this.toastr.warning('請填寫學期');
            return;
        }
        this.fileExchangeServices.ExportFile("ACAD/SRP/ADSRP0308/Get_ADSRP0308", this.exportReportInput)
            .subscribe((response) => {

                if (response.FileName == '') {
                    this.toastr.warning("查無資料");
                    return;
                }

                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = response.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            },
                error => this.toastr.error(error.error));
    }
}
