<div class="container" style="margin-bottom: 100px;">
    <app-ADSTD-search-input (childEvent)="setModel($event)"></app-ADSTD-search-input>
    <div class="row">
        <div class="col-md">
            <button class="search-btn me-3 float-start" (click)="getStdList()">查詢</button>
            <button class="upload-btn float-start me-3" (click)="import()">上傳</button>
            <input type="file" style="display: none;" accept=".zip" id="importData"
                (change)="getStdPicUpload($event, '', '')">
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-6">
            <table class="table table-striped table-hover text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th><input type="checkbox" class="pointer" (click)="setDataKey('', 'all', $event)">&ensp;全選</th>
                        <th>學號</th>
                        <th>姓名</th>
                        <th>班級</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdList">
                        <td><input type="checkbox" class="pointer" (click)="setDataKey(item.DataKey, '', $event)"
                                [checked]="item.Selected"></td>
                        <td>{{item.StdNo}}</td>
                        <td>{{item.StdName}}</td>
                        <td>{{item.ClassName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <ul class="list-group list-group-flush">
                <li class="list-group-item text-danger fw-bolder">1. 學生照片請依(身分證、入台證、護照號碼)三擇一命名，格式須為*.jpg檔。</li>
                <li class="list-group-item text-danger fw-bolder">2. 請將欲上傳的學生照片檔把包壓縮為*.zip檔後，再統一上傳。</li>
                <li class="list-group-item text-danger fw-bolder">3. 上傳照片較多或檔案較大時，執行時間會比較長，麻煩耐心等候。</li>
                <li class="list-group-item text-danger fw-bolder">4. 建議選擇在不影響其他操作功能的時間進行上傳作業。</li>
            </ul>
            <button class="other-Button-gray float-start ms-3 mt-3" (click)="getStdPicDownload()">下載</button>
        </div>
    </div>
</div>