<div class="container">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">人事代碼</span>
                    <input type="text" class="form-control shadow-none" formControlName="Code">
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">姓名</span>
                    <input type="text" class="form-control shadow-none" formControlName="Name">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">身分證號</span>
                    <input type="text" class="form-control shadow-none" formControlName="IDNo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">單位</span>
                    <select class="form-select shadow-none" formControlName="UnitCode">
                        <option value="">--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of unitList">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">類型</span>
                    <select class="form-select shadow-none" formControlName="Type">
                        <option value="1" selected>中文版</option>
                        <option value="3" selected>英文版</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group mb-4">
                    <div class="input-group-prepend">
                        <span class="input-group-text text-primary fw-bolder">離職日由</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                        bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                        formControlName="EndDateS">
                    <div class="input-group-prepend">
                        <span class="input-group-text text-primary fw-bolder">至</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                        bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                        formControlName="EndDateE">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">離校程序狀態</span>
                    <select class="form-select shadow-none" formControlName="LeaveStatus">
                        <option value="">--請選擇--</option>
                        <option value="0" selected>未送出</option>
                        <option value="1" selected>簽核中</option>
                        <option value="2" selected>核准</option>
                        <option value="3" selected>不核准</option>
                    </select>
                </div>
            </div>
            <div class="col-md-5">
                <div class="input-group flex-nowrap mb-3">
                    <div class="col-md-12">
                        <button class="other-Button-gray float-end me-3" (click)="openModalMemo(Memotemplate)">編輯備註</button>
                        <button class="other-Button-gray float-end me-3" (click)="exportCertFile()">匯出</button>
                        <button class="search-btn float-end me-3" (click)="getCertList()">查詢</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 30px;"></th>
                <th style="width: 100px;vertical-align: middle;text-align:left;">單位</th>
                <th style="width: 60px;vertical-align: middle;">職編</th>
                <th style="width: 80px;vertical-align: middle;">姓名</th>
                <th style="width: 70px;vertical-align: middle;text-align:left;">職稱</th>
                <th style="width: 70px;vertical-align: middle;">到職日</th>
                <th style="width: 70px;vertical-align: middle;">離職日</th>
                <th style="width: 70px;vertical-align: middle;">離職申請日</th>
                <th style="width: 70px;vertical-align: middle;">離職核准日</th>
                <th style="width: 50px;vertical-align: middle;">離校程序</th>
                <th style="width: 60px;vertical-align: middle;">附件</th>
                <th style="width: 40px;"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of CertList; let i = index" class="pointer">
                <td style="width: 30px;">
                    <input *ngIf="item.Status== '2'" type="checkbox"
                    [(ngModel)]="item.Checked"
                    (change)="setCheck(item.Checked,i)" class="pointer">
                </td>
                <td style="width: 100px;text-align:left;">{{item.UnitName}}</td>
                <td style="width: 60px;">{{item.Code}}</td>
                <td style="width: 80px;">{{item.UserName}}</td>
                <td style="width: 70px;text-align:left;">{{item.JobName}}</td>
                <td style="width: 70px;">{{item.StartDate}}</td>
                <td style="width: 70px;">{{item.EndDate}}</td>
                <td style="width: 70px;">{{item.ApplyDate}}</td>
                <td style="width: 70px;">{{item.ApproveDate}}</td>
                <td style="width: 50px;">
                    <ng-container *ngIf="item.Status != '0' else other">
                        <a class="pointer text-decoration-none" (click)="openModal_status(floatTemplate,item.LeaveDocNo)">
                            {{item.StatusName}}
                        </a>
                    </ng-container>
                    <ng-template #other>{{item.StatusName}}</ng-template>
                </td>
                <td style="width: 60px;">
                    <p style="margin:3px;" *ngIf="item.FileName != ''">
                        <a class="pointer text-decoration-none" (click)="getCertFileDownLoad(item)">
                            <span>{{item.FileName}}</span>
                        </a>
                    </p>
                    <ng-container *ngIf="item.FileName == ''">
                        <a class="pointer text-decoration-none" (click)="openModalFile(CertFiletemplate,item)">
                            <span>上傳</span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="item.FileName != ''">
                        <a class="pointer text-decoration-none" (click)="getCertFileDelete(item)">
                            <span>刪除</span>
                        </a>
                    </ng-container>
                </td>
                <td style="width: 40px;">
                    <a class="pointer text-decoration-none"
                        *ngIf="item.Status == '2'"
                        (click)="openModal(template)"
                        (click)="getCertDetail(item)">
                        <span class="icon-Fill-in-button"></span>編輯
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">離職申請書維護</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormEdit">
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">離職日期</span>
                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                            bsDatepicker [bsConfig]="bsConfig" readonly
                            style="background-color: transparent;" formControlName="EndDate">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">離職原因</span>
                        <select class="form-select shadow-none" formControlName="ReasonType">
                            <option value="">--請選擇--</option>
                            <option value="{{item.Item_No}}" *ngFor="let item of ColumnItemList">{{item.Item_Name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">核敍薪額</span>
                        <input type="text" class="form-control shadow-none" formControlName="SalaryLevel">
                    </div>
                </div>
            </div>
            <div class="row">
                <div clsaa="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">備註</span>
                        <textarea class="form-control shadow-none" formControlName="Memo"
                            appTextareaAutoresize></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div clsaa="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">說明</span>
                        <textarea class="form-control shadow-none" formControlName="Description" rows="7"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="other-Button-gray float-end" (click)="getCertModify()">儲存</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #floatTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'簽核流程' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <table class="table table-hover table-striped text-center table-rwd table-borderless">
            <thead class="thead-dark-red table-rwd-hide">
                <tr>
                    <th>{{'項次' | translate}}</th>
                    <th>{{'簽核名單' | translate}}</th>
                    <th>{{'代理/加簽' | translate}}</th>
                    <th>{{'簽核單位' | translate}}</th>
                    <th>{{'簽收時間' | translate}}</th>
                    <th>{{'核稿時間' | translate}}</th>
                    <th>{{'簽核意見' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of eDocFlow">
                    <td data-th="項次">{{item.Stage}}</td>
                    <td data-th="簽核名單">{{item.UserName}}</td>
                    <td data-th="代理/加簽">{{item.AgentName}}</td>
                    <td data-th="簽核單位">{{item.UnitName}}</td>
                    <td data-th="簽收時間">{{item.ReadDate}}</td>
                    <td data-th="核稿時間">{{item.SignDate}}</td>
                    <td data-th="簽核意見">{{item.Content}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #CertFiletemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">上傳附件</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef!.hide()">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <input class="form-control shadow-none" type="file" (change)="importAttFile($event, '', '')">
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <button class="other-Button-gray float-end me-3" (click)="getCertFileUpload()">上傳</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #Memotemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">離職申請書備註維護</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormMemoEdit">
            <div class="row">
                <div clsaa="col-md-12">
                    <div class="input-group mb-3">
                        <textarea class="form-control shadow-none" formControlName="Contents"
                            appTextareaAutoresize></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="other-Button-gray float-end" (click)="getCertMemoSave()">儲存</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
