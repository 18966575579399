import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetEMPMainList } from 'src/app/_Models/AD/ADEMP/ADEMP0302/getEMPMainList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';

@Component({
  selector: 'app-ADEMP0302',
  templateUrl: './ADEMP0302.component.html',
  styleUrls: ['./ADEMP0302.component.css']
})

export class ADEMP0302Component implements OnInit {
  baseUrl = environment.apiUrl;
  modalRef!: BsModalRef;
  imageChangedEvent: any = '';
  bloodType = require('src/app/_Models/_SelectInput/bloodType.json').bloodType;
  marriage = require('src/app/_Models/_SelectInput/marriage.json').marriage;
  month = require('src/app/_Models/_SelectInput/month.json').month;
  EMPMainList!: GetEMPMainList[];
  fileExchange = new FileExchange();
  unitList!: GetSelectList[];
  bsConfig = environment.bsDatePickerConfig;

  ngFormInput = new UntypedFormGroup({
      Code: new UntypedFormControl(''),
      Name: new UntypedFormControl(''),
      IDNo: new UntypedFormControl(''),
      UnitCode: new UntypedFormControl(''),
      Type: new UntypedFormControl('2')
  });

  constructor(private http: HttpClient,
    private systemService: SystemService,
    private toastr: ToastrService,
    private fileExchangeServices: FileExchangeService,
    private localeService: BsLocaleService) { }

  ngOnInit(): void {
    this.getUnitList();
    this.localeService.use('zh-cn');
  }

  getUnitList() {
    this.systemService.getUnitList('', '', '').subscribe(response => {
        if (response.Info == false) {
            return;
        }
        this.unitList = response;
    })
  }

  setCheck(thisCheck: boolean, index: number) {
    this.EMPMainList[index].Checked = thisCheck;
  }

  setAll(event: EventTarget | null) {
    if (!this.EMPMainList) return;

    let input = <HTMLInputElement>event;

    this.EMPMainList.forEach(element => {
        input.checked == true ? element.Checked = true : element.Checked = false;
    });
  }

  getEMPMainList() {
    this.EMPMainList = []
    this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0302/Get_EMP_Cert_List", this.ngFormInput.value).subscribe((response) => {
        if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
        }
        this.EMPMainList = response;
        this.EMPMainList.forEach(element => {
            element.Checked = false;
        });
    })
  }

  getEMPMainExport(){
    this.fileExchangeServices.ExportFile("ACAD/EMP/ADEMP0302/Get_EMP_Cert_File", this.EMPMainList).subscribe((response) => {
        if (response.FileName == '') {
            this.toastr.warning("查無資料");
            return;
        }
        let url = window.URL.createObjectURL(response.blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = response.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }, error => {
        this.toastr.error("伺服器發生問題");
        console.error(error);
    })
  }
}
