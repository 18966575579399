import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-ADEMP0303',
  templateUrl: './ADEMP0303.component.html',
  styleUrls: ['./ADEMP0303.component.css']
})
export class ADEMP0303Component implements OnInit {
  titleStatus!: GetSelectList[];
  unitList!: GetSelectList[];
  baseUrl: string = environment.apiUrl;
  constructor(private http: HttpClient,private systemService: SystemService, private toastr: ToastrService) { }
 searchdata={
  item:'',
  unit:''
 }
  ngOnInit(): void {
    this.getTitleStatus();
    this.getUnitList();
  }
  getTitleStatus() {
    this.systemService.getTitleStatus().subscribe(response => {
        if (response.Info == false) {
            return;
        }
        this.titleStatus = response;
    });
}
getUnitList() {
  this.systemService.getUnitList('', '', '').subscribe(response => {
    if (response.Info == false) {
      return;
    }
    this.unitList = response;
  })
}
export(){
  if(this.searchdata.item=='')
  {
    this.toastr.warning("請輸入在職狀態!")
    return;
  }
  this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0303/Get_EMP_Cert_List", this.searchdata).subscribe(response => {
    if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
    }
    let link1 = document.createElement('a');
    link1.href = 'data:octet-stream;base64, ' + response.Base64;
    link1.download = response.FileName;
    document.body.appendChild(link1);
    link1.click();
    document.body.removeChild(link1);
});
}
}
