import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { GetStdData } from 'src/app/_Models/STDWEB/STDW00001/getStdData';
import { environment } from 'src/assets/environments/environment';
import { GetSTDStatusList, GetSTDStatusSubList } from 'src/app/_Models/getSTDStatusList';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import Swal from 'sweetalert2';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { GetRelNameList } from 'src/app/_Models/getRelNameList';
import { DatePipe } from '@angular/common';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-STDW00001',
  templateUrl: './STDW00001.component.html',
  styleUrls: ['./STDW00001.component.css']
})
export class STDW00001Component implements OnInit {
  @ViewChild('tabs', { static: false }) tabs!: TabsetComponent;
  baseUrl = environment.apiUrl;
  stdData!: GetStdData;
  showCropper = false;
  countryList!: GetSelectList[];
  cityList!: GetSelectList[];
  modalRef?: BsModalRef;
  imageChangedEvent: any = '';
  comTownList!: GetSelectList[];
  nomTownList!: GetSelectList[];
  sTDStatusList!: GetSTDStatusList[];
  sTDStatusSubList!: GetSTDStatusSubList[];
  relNameList!: GetRelNameList[];
  fileExchange = new FileExchange();
  army = require('src/app/_Models/_SelectInput/army.json').army;
  marriage = require('src/app/_Models/_SelectInput/marriage.json').marriage;
  bloodType = require('src/app/_Models/_SelectInput/bloodType.json').bloodType;
  ImgUrl!: SafeUrl;
  croppedImage: any = '';
  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private domSanitizer: DomSanitizer,
    private systemService: SystemService,
    private datePipe: DatePipe,
    private fileExchangeServices: FileExchangeService, private modalService: BsModalService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.use(this.systemService.currentLanguage)
    this.getStdData();
    this.getRelNameList();
  }



  fileChangeEvent(event: any): void {
    this.fileChangeEvent = event;
    sessionStorage.setItem("file", event.target.files[0].name)
  }
  imageLoaded(image: LoadedImage) {
    this.showCropper = true;
    this.toastr.info("圖片加載成功!");
  }


  clearImage() {
    this.showCropper = false;
    this.imageChangedEvent = null;
    this.croppedImage = '';
    let img = <HTMLInputElement>document.getElementById('image');
    img.value = '';
  }

  loadImageFailed() {
    this.toastr.info("圖片格式不符");
  }
  getCountryList() {
    this.systemService.getCountryList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.countryList = response;
    }, error => this.toastr.error(error.error));
  }
  imageCropped1(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

  }
  openModal1(template: TemplateRef<any>) {


    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };

    this.modalRef = this.modalService.show(template, modalConfig);
  }
  uploadImage1() {
    let y = sessionStorage.getItem("file")
    this.GetBase64(this, this.croppedImage.split(',')[1], y, '', '')
  }
  getCityList() {
    this.systemService.getCityList('000').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.cityList = response;
    }, error => this.toastr.error(error.error))
  }

  getTownList(city: string) {
    this.systemService.getTownList('000', city).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.nomTownList = response;
    }, error => this.toastr.error(error.error));
  }


  // 如果縣市有值，則觸發該縣市的區域下拉
  initiateTownList(nomCity: string) {
    this.systemService.getTownList('000', nomCity).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.nomTownList = response;
    }, error => this.toastr.error(error.error));

  }

  getRelNameList() {
    this.systemService.getRelNameList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.relNameList = response;
    })
  }

  getSTDStatusSubList(event: any) {
    let sTDStatus = <HTMLInputElement>event.target;
    this.http.post<any>(this.baseUrl + "Public/Get_STD_StatusSub_List", { Status_No: sTDStatus.value }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.sTDStatusSubList = response;
    })
  }

  getStdData() {
    this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW000_01/Get_Std_Data").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.stdData = response;
      if (response.PIC !== null) {
        this.ImgUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + response.PIC);
      }

      // 為了抓到學生資料，下拉式選單在這裡啟動
      this.getCountryList();
      this.getCityList();
      this.initiateTownList(this.stdData.Nom_City);
      // 取消編碼給ng-Model用
      this.stdData.Std_Name = response.Std_Name;
      this.stdData.Std_EngName = response.Std_EngName;
      this.stdData.Sex = response.Sex;
      this.stdData.Birthday = response.Birthday;
      this.stdData.Marry = response.Marry;
      this.stdData.Military_Service = response.Military_Service;
      this.stdData.Other_Email = response.Other_Email;
      this.stdData.Village_Name = response.Village_Name;
      this.stdData.Nom_Address = response.Nom_Address;
      this.stdData.Com_Address = response.Com_Address;
      this.stdData.Eng_Address = response.Eng_Address;
      this.stdData.Nom_Tel = response.Nom_Tel;
      this.stdData.Com_Tel = response.Com_Tel;


      if (this.stdData.STD02) {
        this.stdData.STD02.forEach(element => {
          element.Dept_Name = element.Dept_Name;
          element.Sch_Name = element.Sch_Name;
          element.Grad_Type = element.Grad_Type;
        });
      }

      if (this.stdData.STD03) {
        this.stdData.STD03.forEach(element => {
          element.Dep_Name = element.Dep_Name;
          element.Birthday = element.Birthday;
          element.Occ_No = element.Occ_No;
          element.Com_Address = element.Com_Address;
        });
      }


      if (this.stdData.STD04) {
        this.stdData.STD04.forEach(element => {
          element.Company = element.Company;
          element.Duty = element.Duty;
          element.Memo = element.Memo;
        });
      }


      if (this.stdData.STD05) {
        this.stdData.STD05.forEach(element => {
          element.Class_Name = element.Class_Name;
          element.Regi_Date = element.Regi_Date;
        });
      }


      this.stdData.PIC = '';



    });
  }

  importData($event: any) {
    let file = $event.target.files[0];
    if (!file) return;
    this.fileExchange.FileToBase64(this, file, this.GetBase64, '', '')
  }

  GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
    Swal.fire({
      title: '確定上傳 ' + file + ' ?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: '是',
      denyButtonText: '否',
    }).then((result) => {
      if (result.isConfirmed) {
        that.stdData.PIC = base64;
        that.fileExchangeServices.ImportFile('ACAD/STDWEB/STDW000_01/Get_Std_Modify', that.stdData)
          .subscribe((response: any) => {
            if (response.Info == true) { that.toastr.success("上傳成功!!"); }
            else { that.toastr.error(response.Message, "執行錯誤!"); }
          });
        that.ImgUrl = that.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + base64);
        console.log(that.ImgUrl)
      }
      else if (result.isDenied) {
        document.getElementById('img')?.setAttribute('value', '');
        that.toastr.info("已取消上傳。");
      }
    });
  }

  deleteData(type: string) {
    if (type == 'family') {
      this.stdData.STD03.pop();
    }

    if (type == 'school') {
      this.stdData.STD02.pop();
    }

  }

  addData(type: string) {
    let date = new Date();
    if (type == 'family') {

      if (this.stdData.STD03 == null) {
        this.stdData.STD03 = [];
      }

      this.stdData.STD03.push({
        Birthday: '',
        Com_Address: '',
        Com_Tel: '',
        Com_Zip: '',
        Dep_ID: '',
        Dep_Name: '',
        I_Num: '',
        Mark: '',
        Memo: '',
        Occ_No: '',
        Rel_Name: '',
        Rel_No: '',
        Std_No: this.stdData.Std_No,
        Update_Date: (this.datePipe.transform(date, 'yyyy-MM-dd') || '').toString(),
        Update_User: this.stdData.Std_No,
        checkedTag: false
      });
    }

    if (type == 'school') {
      if (this.stdData.STD02 == null) {
        this.stdData.STD02 = [];
      }

      this.stdData.STD02.push({
        ClassSub: '',
        Dept_Name: '',
        Grad_Month: '',
        Grad_Type: '',
        Grad_Year: '',
        I_Num: '',
        Mark: '',
        SchClass: '',
        SchClass_Sub: '',
        Sch_Name: '',
        Sch_No: '',
        Std_No: this.stdData.Std_No,
        Turn_In: '',
        Update_Date: (this.datePipe.transform(date, 'yyyy-MM-dd') || '').toString(),
        Update_User: this.stdData.Std_No
      });
    }

  }

  saveStdData() {

    // 編碼
    this.stdData.Std_Name = this.stdData.Std_Name;
    this.stdData.Std_EngName = this.stdData.Std_EngName;
    this.stdData.Sex = this.stdData.Sex;
    this.stdData.Birthday = this.stdData.Birthday;
    this.stdData.Marry = this.stdData.Marry;
    this.stdData.Military_Service = this.stdData.Military_Service;
    this.stdData.Other_Email = this.stdData.Other_Email;
    this.stdData.Village_Name = this.stdData.Village_Name;
    this.stdData.Nom_Address = this.stdData.Nom_Address;
    this.stdData.Com_Address = this.stdData.Com_Address;
    this.stdData.Eng_Address = this.stdData.Eng_Address;
    this.stdData.Nom_Tel = this.stdData.Nom_Tel;
    this.stdData.Com_Tel = this.stdData.Com_Tel;
    console.log(this.stdData)
    this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW000_01/Get_Std_Modify", this.stdData).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.getStdData();
        return;
      }
      this.toastr.success("儲存成功");
      this.getStdData();
    })
  }


}

