<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-6" *ngFor="let item of cUPTimeCenterList">
            <ng-container *ngIf="item.IsDatetime && !item.IsTermList">
                <div class="input-group mb-3">
                    <span class="input-group-text">{{item.ItemName}}</span>
                    <input type="text" placeholder="點選設定日期" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly [(ngModel)]="item.Date" style="background-color: transparent;">
                    <timepicker [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="item.Time"
                        class="shadow-none"></timepicker>
                </div>
            </ng-container>
            <ng-container *ngIf="!item.IsDatetime && item.IsTermList">
                <div class="input-group mb-3">
                    <span class="input-group-text">{{item.ItemName}}</span>
                    <select class="form-select shadow-none" [(ngModel)]="item.ItemValue">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </ng-container>
            <ng-container *ngIf="!item.IsDatetime && !item.IsTermList">
                <div class="input-group mb-3">
                    <span class="input-group-text">{{item.ItemName}}</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="item.ItemValue">
                </div>
            </ng-container>
        </div>
        <div class="col-md-6">
            <button (click)="getCUPTimeCenterModify()" class="save-btn-gray">儲存</button>
        </div>
    </div>
</div>
