<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">申請狀態</span>
                </div>
                <select class="form-select shadow-none" #MakeDocMark>
                    <option value="">--全部--</option>
                    <option value="0">申請中</option>
                    <option value="1">已收件</option>
                    <option value="2">通過</option>
                    <option value="3">作廢</option>
                    <option value="4">放棄</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">審核類型</span>
                </div>
                <select class="form-select shadow-none" #LoanType>
                    <option value="">--全部--</option>
                    <option value="A">A類</option>
                    <option value="B">B類</option>
                    <option value="C">C類</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學號</span>
                </div>
                <input type="text" class="form-control shadow-none" #StdNo>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">身分證</span>
                </div>
                <input type="text" class="form-control shadow-none" #IDNo>
            </div>
        </div>
        <div class="col-md-3" [hidden]="true">
            <div class="input-group flex-nowrap mb-3">
                <input type="text" placeholder="點選設定申請日期區間" class="form-control pointer shadow-none" bsDaterangepicker
                    [bsConfig]="bsConfig" readonly [(bsValue)]="bsValue">
                <div class="input-group-prepend">
                    <button class="btn btn-outline-secondary" (click)="clearDatePicker()">清除</button>
                </div>
            </div>
        </div>
        <div class="col-md">
            <button class="search-btn float-start me-3"
                (click)="getTFSMakeDocLoanFeeList(Year.value, Term.value, StdNo.value, IDNo.value, MakeDocMark.value, LoanType.value)">查詢</button>
            <button class="other-Button-gray float-start me-3" style="width: 120px;"
                (click)="openModal(template)">新增申請名單</button>
            <button class="other-Button-gray float-start me-3" style="width: 120px;">匯出各類清冊</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 50px;"></th>
                <th>班級</th>
                <th>學號</th>
                <th style="width: 80px;">姓名</th>
                <th style="width: 100px;">應繳金額</th>
                <th style="width: 100px;">就貸金額</th>
                <th style="width: 100px;">補繳金額</th>
                <th style="width: 80px;">狀態</th>
                <th style="width: 100px;">審核類型</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tFSMakeDocLoanFeeList; let i = index">
                <td style="width: 50px;">{{i + 1}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.StdNo}}</td>
                <td style="width: 80px;">{{item.StdName}}</td>
                <td style="width: 100px;">{{item.MustTMoney}}</td>
                <td style="width: 100px;">{{item.LoanMoney}}</td>
                <td style="width: 100px;">{{item.FinalMoney}}</td>
                <td style="width: 80px;"><a (click)="getTFSMakeDocLoanFeeDetail(item.DataKey, template, false)"
                        class="pointer text-primary fw-bolder text-decoration-none">{{item.MakeDocMark}}</a>
                </td>
                <td style="width: 100px;">{{item.LoanType}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">就貸申請</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <h4>學生資料</h4>
        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #dYear [disabled]="tFSMakeDocLoanFeeDetail.DataKey != ''"
                        [(ngModel)]="Year.value">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of dYears" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #dTerm [disabled]="tFSMakeDocLoanFeeDetail.DataKey != ''"
                        [(ngModel)]="Term.value">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" #StdNo
                        [readonly]="tFSMakeDocLoanFeeDetail.DataKey != ''" [(ngModel)]="tFSMakeDocLoanFeeDetail.StdNo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">姓名</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.StdName">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.ClassName">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">身分證</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.IDNo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">生日</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.Birthday">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">入學年月</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.EntryDate">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">電話</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.ComTel">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">手機</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.MobilePhone">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Email</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.EMail">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">住址</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.ComAddress">
                </div>
            </div>
        </div>
        <button class="search-btn" style="margin: auto;"
            (click)="getTFSMakeDocLoanFeeDetail(dYear.value + ',' + dTerm.value + ',' + StdNo.value, template, true)">查詢</button>
        <h4>繳費科目</h4>

        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th>代碼</th>
                    <th>類型</th>
                    <th>科目</th>
                    <th>擬請金額</th>
                    <th>金額上限</th>
                </tr>
            </thead>
            <tbody *ngIf="tFSMakeDocLoanFeeDetail.LoanFeeData.length != 0">
                <tr *ngFor="let item of tFSMakeDocLoanFeeDetail.LoanFeeData; let i = index">
                    <td>{{item.FeeNo}}</td>
                    <td>{{item.FeeType}}</td>
                    <td>{{item.FeeName}}</td>
                    <td style="width: 150px;"><input type="text" class="form-control shadow-none"
                            [(ngModel)]="item.LooanMoney" (blur)="caculate(item.LooanMoney, i)"></td>
                    <td>{{item.MustMoney}}</td>
                </tr>
            </tbody>
        </table>
        <h4 *ngIf="tFSMakeDocLoanFeeDetail.LoanFeeData.length == 0" class="text-center">請先輸入學號查詢。</h4>


        <div class="row mt-3">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">註冊應繳金額</span>
                    </div>
                    <input type="text" class="form-control shadow-none" disabled
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.MustTMoney">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學雜費減免金額</span>
                    </div>
                    <input type="text" class="form-control shadow-none" disabled
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.TEMoney">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">就學貸款金額</span>
                    </div>
                    <input type="text" class="form-control shadow-none" disabled
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.LoanMoney">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">補繳差額金額</span>
                    </div>
                    <input type="text" class="form-control shadow-none" disabled
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.FinalMoney">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">弱勢助學金減免</span>
                    </div>
                    <input type="text" class="form-control shadow-none" disabled
                        [(ngModel)]="tFSMakeDocLoanFeeDetail.SIMoney">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">狀態</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="tFSMakeDocLoanFeeDetail.MakeDocMark">
                        <option value="" disabled selected>--請選擇--</option>
                        <option value="0">申請中</option>
                        <option value="1">已收件</option>
                        <option value="2">通過</option>
                        <option value="3">作廢</option>
                        <option value="4">放棄</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">補繳差額狀態</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="tFSMakeDocLoanFeeDetail.PaymentCheck" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option value="Y">已繳費</option>
                        <option value="N">未繳費</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">核定類型</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="tFSMakeDocLoanFeeDetail.LoanType">
                        <option value="" disabled>--請選擇--</option>
                        <option value="A">A類</option>
                        <option value="B">B類</option>
                        <option value="C">C類</option>
                    </select>
                </div>
            </div>
        </div>
        <button class="save-btn-gray" style="margin: auto;" (click)="getTFSMakeDocLoanFeeSave()">儲存</button>
    </div>
</ng-template>