<div id="main">
    <div class="container">
        <div class="row">
            <div class="col-8">
                <h1 class="info">哎呀呀!找不到網頁呢!</h1>
                <p>我知道這看起來很可怕，不過不必擔心，會出現此狀況有以下幾點：</p>
                <ul>
                    <li>瀏覽器上方網址列是否有被異動過(打字正確很重要!)。</li>
                    <li>曾經嘗試修改網頁程式碼(不可以這樣做喔!)。</li>
                    <li>找不到呼叫的程式碼(這個比較嚴重!)。</li>
                    <li>如果問題無法排除，請聯繫系統廠商。</li>

                </ul>
            </div>
            <div class="col-4">
                <h1 class="heading">404</h1>
            </div>
        </div>

        <hr />
        <a class="btn btn-outline-info" routerLink="/Home">按我回到首頁喔!</a>
    </div>
</div>
