import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSRP0202ISU',
    templateUrl: './ADSRP0202ISU.component.html',
    styleUrls: ['./ADSRP0202ISU.component.css']
})
export class ADSRP0202ISUComponent implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    dataKeyArray: Array<string> = [];
    modalRef?: BsModalRef;

    ngOnInit(): void {
        this.getYears();
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService, private modalService: BsModalService) { }

    getYears() {
        this.initialData.getYears().subscribe((response: string[]) => {
            if (response) {
                this.Years = response;
            }
        }, (error: { error: string | undefined; }) => this.toastr.error(error.error));
    }
}
