import { HttpClient } from '@angular/common/http';
import { Component, OnInit,TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-ADSYS0103',
    templateUrl: './ADSYS0103.component.html',
    styleUrls: ['./ADSYS0103.component.css']
})
export class ADSYS0103Component implements OnInit {
    constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService,private systemService: SystemService, private router: Router, public accountService: AccountService,) { }
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    type: any;

    ngOnInit(): void {
        this.getACADHost();
        document.getElementById("Loginform")?.click();
    }

    search(id: string, pwd: string) {
        this.http.post<any>(this.baseUrl + "Portal/Get_ChangeAccount", { "Type": id, "Code": pwd }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.accountService.setCurrentUser(response)
            this.router.navigateByUrl('/Home');
            this.modalRef?.hide();
        })
    }
    getACADHost() {
        this.http.get<any>(this.baseUrl + 'Login/Get_ACADHost').subscribe((response) => {
            if (response) {
                this.type = response;
            }
        })
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-md'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
      }
}
