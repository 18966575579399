import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/assets/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADEMP/ADEMP0103/search'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
@Component({
  selector: 'app-ADEMP0103',
  templateUrl: './ADEMP0103.component.html',
  styleUrls: ['./ADEMP0103.component.css']
})
export class ADEMP0103Component implements OnInit {
  constructor(private http: HttpClient, private toaster: ToastrService) { }
  url = environment.apiUrl;
  further = ''
  data!: Search[];
  detail1 = {
    data:false,
    Further: '',
    Further_Name: '',
    Del_Hours: '',
    Memo: '',
    Update_User: '',
    Update_Time: '',
    Key: 0
  }
  detail = new UntypedFormGroup({
    Further: new UntypedFormControl({ value: '', disabled: false }, Validators.required),
    Further_Name: new UntypedFormControl('', Validators.required),
    Del_Hours: new UntypedFormControl('', Validators.required),
    Memo: new UntypedFormControl('', Validators.required),
    Update_User: new UntypedFormControl({ value: '', disabled: true }),
    Update_Time: new UntypedFormControl({ value: '', disabled: true }),
    Selected: new UntypedFormControl(false),
  });

  ngOnInit(): void {
    this.search()
  }
  search() {
    this.http.get<any>(this.url + "ACAD/EMP/ADEMP0103/Search").subscribe((response) => {
      if (response.Info == false) {
        this.toaster.warning(response.Message);
        this.data = []
        return;
      }
      this.data = response;
      this.data.forEach(element => {
        element.Selected = false;
      });
    }, error => this.toaster.error(error.error))
  }
  searchDetail(index: number) {
    this.detail1.data=true;
    this.data.forEach(element => {
      element.Selected = false;
    });
    this.data[index].Selected = true;
    this.detail.setValue(this.data[index]);
  }
  insert() {
    this.http.post<any>(this.url + "ACAD/EMP/ADEMP0103/Insert", this.detail.value).subscribe(response => {
      if (response.Info == false) {
        this.toaster.warning(response.Message);
        return;
      }
      this.toaster.success("新增成功")
      this.data = response;
      this.search()
    })
  }
  update() {
    this.http.post<any>(this.url + "ACAD/EMP/ADEMP0103/Update", this.detail.value).subscribe(response => {
      if (response.Info == false) {
        this.toaster.warning(response.Message)
        return;
      }
      this.toaster.success("修改成功")
      this.search()
    })
  }
  delete() {
    this.http.post<any>(this.url + "ACAD/EMP/ADEMP0103/Delete", { Further: this.detail.get('Further')?.value }).subscribe(response => {
      if (response.info == false) {
        this.toaster.warning(response.Message)
        return;
      }

      this.toaster.success("刪除成功")
      this.search()
    })
  }
  clear() {
    this.detail1.data=false;
    this.detail.reset()
  }
}
