<div class="container" style="margin-bottom: 100px;">
    <alert type="danger">{{"請確認已完成"|translate}}<a class="text-decoration-none pointer"
            routerLink="/ADSEL0204"><strong>{{"選課註冊檔匯入"|translate}}</strong></a>{{"再設定此功能。"|translate}}</alert>
    <form [formGroup]="ngForm">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"學制"|translate}}</span>
                    <select class="form-select shadow-none search-input" formControlName="EduNo">
                        <option value="" selected>{{"--全部--"|translate}}</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"年級"|translate}}</span>
                    <select class="form-select shadow-none search-input" formControlName="Grade">
                        <option value="" selected>{{"--全部--"|translate}}</option>
                        <option value="{{item.itemValue}}" *ngFor="let item of grade">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <button type="button" class="save-btn-gray float-start me-3" (click)="getSELTimeSave()">{{"儲存"|translate}}</button>
                <button class="other-Button-gray float-start" (click)="setTime()">{{"設定"|translate}}</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"第一次選課開始時間"|translate}}</span>
                    <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none search-input"
                        style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig" readonly
                        formControlName="DesireStart">
                    <timepicker [showMeridian]="false" [showSpinners]="false" class="search-input"
                        formControlName="DesireStartTime">
                    </timepicker>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"第一次選課結束時間"|translate}}</span>
                    <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none search-input"
                        style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig" readonly
                        formControlName="DesireEnd">
                    <timepicker [showMeridian]="false" [showSpinners]="false" class="search-input"
                        formControlName="DesireEndTime"></timepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"第二次選課開始時間"|translate}}</span>
                    <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none search-input"
                        style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig" readonly
                        formControlName="SelStart">
                    <timepicker [showMeridian]="false" [showSpinners]="false" formControlName="SelStartTime"
                        class="search-input">
                    </timepicker>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"第二次選課結束時間"|translate}}</span>
                    <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none search-input"
                        style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig" readonly
                        formControlName="SelEnd">
                    <timepicker [showMeridian]="false" [showSpinners]="false" formControlName="SelEndTime"
                        class="search-input">
                    </timepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"寒暑修選課開始時間"|translate}}</span>
                    <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none search-input"
                        style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig" readonly
                        formControlName="SummerStart">
                    <timepicker [showMeridian]="false" [showSpinners]="false" formControlName="SummerStartTime"
                        class="search-input">
                    </timepicker>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"寒暑修選課結束時間"|translate}}</span>
                    <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none search-input"
                        style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig" readonly
                        formControlName="SummerEnd">
                    <timepicker [showMeridian]="false" [showSpinners]="false" formControlName="SummerEndTime"
                        class="search-input">
                    </timepicker>
                </div>
            </div>
        </div>
    </form>

    <div style="overflow-x: scroll;">
        <table class="table table-bordered table-hover table-striped text-center" style="width: 1500px;">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width: 100px;">{{"學制"|translate}}</th>
                    <th style="width: 150px;">{{"系所"|translate}}</th>
                    <th style="width: 60px;">{{"年級"|translate}}</th>
                    <th style="width: 300px;">{{"第一次選課開始時間"|translate}}</th>
                    <th style="width: 300px;">{{"第一次選課結束時間"|translate}}</th>
                    <th style="width: 300px;">{{"第二次選課開始時間"|translate}}</th>
                    <th style="width: 300px;">{{"第二次選課結束時間"|translate}}</th>
                    <th style="width: 300px;">{{"寒暑修選課開始時間"|translate}}</th>
                    <th style="width: 300px;">{{"寒暑修選課結束時間"|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of sELTimeList">
                    <td style="width: 100px;">{{item.EduName}}</td>
                    <td style="width: 150px;">{{item.DeptName}}</td>
                    <td style="width: 60px;">{{item.Grade}}</td>
                    <td style="width: 300px;">
                        <div class="input-group flex-nowrap mb-3">
                            <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig"
                                readonly [(ngModel)]="item.DesireStart">
                            <timepicker [showMeridian]="false" [showSpinners]="false"
                                [(ngModel)]="item.DesireStartTime">
                            </timepicker>
                        </div>
                    </td>
                    <td style="width: 300px;">
                        <div class="input-group flex-nowrap mb-3">
                            <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig"
                                readonly [(ngModel)]="item.DesireEnd">
                            <timepicker [showMeridian]="false" [showSpinners]="false" [(ngModel)]="item.DesireEndTime">
                            </timepicker>
                        </div>
                    </td>
                    <td style="width: 300px;">
                        <div class="input-group flex-nowrap mb-3">
                            <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig"
                                readonly [(ngModel)]="item.SelStart">
                            <timepicker [showMeridian]="false" [showSpinners]="false" [(ngModel)]="item.SelStartTime">
                            </timepicker>
                        </div>
                    </td>
                    <td style="width: 300px;">
                        <div class="input-group flex-nowrap mb-3">
                            <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig"
                                readonly [(ngModel)]="item.SelEnd">
                            <timepicker [showMeridian]="false" [showSpinners]="false" [(ngModel)]="item.SelEndTime">
                            </timepicker>
                        </div>
                    </td>
                    <td style="width: 300px;">
                        <div class="input-group flex-nowrap mb-3">
                            <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig"
                                readonly [(ngModel)]="item.SummerStart">
                            <timepicker [showMeridian]="false" [showSpinners]="false"
                                [(ngModel)]="item.SummerStartTime">
                            </timepicker>
                        </div>
                    </td>
                    <td style="width: 400px;">
                        <div class="input-group flex-nowrap mb-3">
                            <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig"
                                readonly [(ngModel)]="item.SummerEnd">
                            <timepicker [showMeridian]="false" [showSpinners]="false" [(ngModel)]="item.SummerEndTime">
                            </timepicker>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>