import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetTFSMakeDocSDRDetail } from 'src/app/_Models/AD/ADTFS/ADTFS01C01/getTFSMakeDocSDRDetail';
import { GetTFSMakeDocSDRList } from 'src/app/_Models/AD/ADTFS/ADTFS01C01/getTFSMakeDocSDRList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-ADTFS01C01',
    templateUrl: './ADTFS01C01.component.html',
    styleUrls: ['./ADTFS01C01.component.css']
})
export class ADTFS01C01Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    detailTerm = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    detailYear!: Array<string>;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    makeDocList!: GetSelectList[];
    tFSMakeDocSDRList!: GetTFSMakeDocSDRList[];
    tFSMakeDocSDRDetail!: GetTFSMakeDocSDRDetail[] | undefined;
    fileExchange = new FileExchange();
    checkType = new CheckType();
    dataKeyArray: Array<string> = [];
    model = {
        Years: '',
        Term: '',
        MakeDocNo: '',
        MakeDocStatus: ''
    }

    constructor(private toastr: ToastrService,
        private systemService: SystemService,
        private http: HttpClient,
        private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
        this.getYears();
        this.getMakeDocList();
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        }, error => this.toastr.error(error.error));
    }

    getMakeDocList() {
        this.systemService.getMakeDocList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.makeDocList = response;
        }, error => this.toastr.error(error.error))
    }

    import() {
        if (this.model.Years == '' || this.model.Term == '' || this.model.MakeDocNo == '') {
            this.toastr.info("請選擇學年學期及製單類別");
            return;
        }
        document.getElementById("importData")?.click();
    }

    importSDRFileBase64($event: any, dataKey: string, type: string) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type);
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string) {
        let json = {
            Years: that.model.Years,
            Term: that.model.Term,
            MakeDocNo: that.model.MakeDocNo,
            UnitNo: '01',
            FileBase64: base64
        }
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
            cancelButtonText: '取消',
        }).then((result) => {
            if (result.isConfirmed) {
                that.fileExchangeServices.ImportFile('ACAD/TFS/ADTFS01C01/Import_SDRFile_Base64', json)
                    .subscribe((response: any) => {
                        if (response.Info == true) { that.toastr.success("上傳成功!!"); }
                        else { that.toastr.error(response.Message, "執行錯誤!"); }
                    });
            }
            else if (result.isDenied) {
                document.getElementById("importData")?.setAttribute('value', '');
                that.toastr.info("已取消上傳。");
            }
        });
    }

    getTFSMakeDocSDRList() {

        if (this.model.Years == '' || this.model.Term == '' || this.model.MakeDocNo == '') {
            this.toastr.info("請選擇學年學期及製單類別");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01C01/Get_TFS_MakeDoc_SDRList", {
            DataKey: '',
            Years: this.model.Years,
            Term: this.model.Term,
            EduNo: '',
            DeptNo: '',
            Class: '',
            StdNo: '',
            EntryID: '',
            MakeDocNo: this.model.MakeDocNo,
            MakeDocStatus: this.model.MakeDocStatus,
            UnitNo: '01'
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.tFSMakeDocSDRList = []
                return;
            }
            this.tFSMakeDocSDRList = response;
            for (let index = 0; index < this.tFSMakeDocSDRList.length; index++) {
                this.tFSMakeDocSDRList[index].checked = false;
            }
        }, error => this.toastr.error(error.error))
    }

    getTFSMakeDocSDRDetail(index: number, datakey: string) {

        this.tFSMakeDocSDRList.forEach(element => {
            element.Selected = false;
        })
        this.tFSMakeDocSDRList[index].Selected = true;

        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01C01/Get_TFS_MakeDoc_SDRDetail", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.tFSMakeDocSDRDetail = undefined
                return;
            }

            this.tFSMakeDocSDRDetail = response;

        }, error => this.toastr.error(error.error))
    }

    getTFSMakeDocSDRSave() {

        if (!this.tFSMakeDocSDRDetail) return;
        let pass = true;
        for (let index = 0; index < this.tFSMakeDocSDRDetail.length; index++) {
            if (this.checkType.CheckIsNumber(this.tFSMakeDocSDRDetail[index].MustMoney)) {
                this.toastr.warning(`第${index + 1}項非數字`);
                pass = false;
                break;
            }
        }

        if (pass) {
            this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01C01/Get_TFS_MakeDoc_SDRSave", this.tFSMakeDocSDRDetail).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }

                this.toastr.success("儲存成功");
                this.getTFSMakeDocSDRList()
            }, error => this.toastr.error(error.error))
        }
    }

    setDatakey(type: string, dataKey: string, event: any) {
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.tFSMakeDocSDRList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.tFSMakeDocSDRList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.tFSMakeDocSDRList.forEach(element => {
                    element.checked = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

    getTFSMakeDocSDRDelete() {

        if (this.dataKeyArray.length == 0) return;

        let dataKey = '';
        this.dataKeyArray.forEach(element => {
            dataKey = dataKey + element + ',';
        });

        Swal.fire({
            title: '確定刪除?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01C01/Get_TFS_MakeDoc_SDRDelete", { DataKey: dataKey.substring(0, dataKey.length - 1) }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                })
            }
        });
    }

    getTFSMakeDocSDRSend(type: string) {

        let model = {
            DataKey: '',
            Years: this.model.Years,
            Term: this.model.Term,
            EduNo: '',
            DeptNo: '',
            Class: '',
            StdNo: '',
            EntryID: '',
            MakeDocNo: this.model.MakeDocNo,
            MakeDocStatus: '',
            UnitNo: '01'
        }

        if (type == 'all') {
            this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01C01/Get_TFS_MakeDoc_SDRSend", model).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("送繳成功");
            })
        }
        else {
            if (this.dataKeyArray.length == 0) {
                this.toastr.info("請先勾選學生");
                return;
            }
            let dataKey = '';
            this.dataKeyArray.forEach(element => {
                dataKey = dataKey + element + ',';
            });
            model.StdNo = dataKey.substring(0, dataKey.length - 1)
            this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01C01/Get_TFS_MakeDoc_SDRSend", model).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("送繳成功");
                this.dataKeyArray = [];
            })
        }
    }

    exportExemptionFileBase64() {
        this.http.get<any>(this.baseUrl + "ACAD/TFS/ADTFS01C01/Export_ExemptionFile_Base64").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            let contentType = "application/octet-stream"
            let blob = this.fileExchange.Base64ToBlob(response.Base64, contentType, 512);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = "學年住宿費維護匯入範本.xls";
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        })
    }

    getTFSMakeDocReportBase64(Years: string, Term: string, MakeDocNo: string, MakeDocStatus: string) {

        if (Years == '' || Term == '' || MakeDocNo == '') {
            this.toastr.info("請選擇學年學期及製單類別");
            return;
        }

        this.fileExchangeServices.ExportFile("Public/Get_TFS_MakeDoc_Report_Base64", {
            DataKey: '',
            Years: Years,
            Term: Term,
            EduNo: '',
            DeptNo: '',
            Class: '',
            StdNo: '',
            EntryID: '',
            MakeDocNo: MakeDocNo,
            MakeDocStatus: MakeDocStatus,
            UnitNo: '01'
        })
            .subscribe((response) => {
                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = '繳交清冊.xls';
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }, error => this.toastr.error(error.error));
    }

}
