<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select class="form-select shadow-none" #edu [(ngModel)]="data_detail.Edu02">
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">類別</span>
                </div>
                <select class="form-select shadow-none" #edu [(ngModel)]="data_detail.H_Type">
                    <option value=""  selected>--請選擇--</option>
                    <option value="A">請假</option>
                    <option value="B">缺曠</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-8">
            <button class="search-btn float-start me-3" (click)="search()">查詢</button>
        </div>
        <div class="col-md-4">
            <button type="button" class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button type="button" class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button type="button" class="other-Button-gray float-end me-3" (click)="openModal(column)">新增</button>
        </div>
    </div>
</div>
<div class="container">
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" (click)="setDatakey($event.target)" class="pointer">&ensp;刪除</th>
                <th>代碼</th>
                <th>類別名稱</th>
                <th>操行扣分</th>
                <th>扣考節數</th>
                <th>修改者</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of data; let i = index ">
                <td><input type="checkbox" (click)="choose_delete(i,a.K_Delete)" [(ngModel)]="a.checked"></td>
                <td>{{a.H_Code}}</td>
                <td>{{a.H_Name}}</td>
                <td><input type="number" style="width: 60px;" class="text-center"  [(ngModel)]="a.H_Score"></td>
                <td><input type="number" style="width: 60px;" class="text-center" [(ngModel)]="a.H_Times"></td>
                <td>{{a.Update_User}}</td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #column>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定欄位</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">代碼</span>
                    <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;"  [(ngModel)]="data_detail.H_Code">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group  mb-3">
                    <span class="input-group-text">類別名稱</span>
                    <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;" [(ngModel)]="data_detail.H_Name">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">請假/缺曠代碼</span>
                    </div>
                    <select class="form-select shadow-none" #edu [(ngModel)]="data_detail.H_Type">
                        <option value=""  selected>--請選擇--</option>
                        <option value="A">請假</option>
                        <option value="B">缺曠</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">操行扣分</span>
                    <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;"  [(ngModel)]="data_detail.H_Score">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">扣考節數</span>
                    <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;" [(ngModel)]="data_detail.H_Times">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
            </div>
            <div>
                <button type="button" class="other-Button-gray float-end me-3" (click)="modalRef?.hide()" (click)="save()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>