import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0105/search';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0105/searchDetail';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADRGP0105',
    templateUrl: './ADRGP0105.component.html',
    styleUrls: ['./ADRGP0105.component.css']
})
export class ADRGP0105Component implements OnInit {
    baseUrl = environment.apiUrl;
    modalRef?: BsModalRef;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    data!: Search[];
    search1 = {
        Edu_No: '',
        Dept_No: ''
    }
    dataDetail: SearchDetail = {
        Dept_No: '',
        Dept_Name: '',
        Team_No: '',
        Team_Name: '',
        Team_EngName: '',
        ADName: '',
        EADName: '',
        Grade_Years: '',
        Limit_Years: '',
        OnJobLimit_Years: '',
        Print_Log: '',
        Moe: '',
        State_Log: '',
        Exam_DeptNo:'',
        Update_User: '',
        Update_Time: '',
        Allow_Data: '',
        Edu_No: ''
    };
    constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService, private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getEduList();
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    search(Edu_No: string, Dept_No: string) {

        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0105/Search", { Edu_No: Edu_No, Dept_No: Dept_No }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        })
    }

    searchDetail(Dept_No: string, Team_No: string, index: number) {
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0105/Search_Detail", { Dept_No: Dept_No, Team_No: Team_No }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.getDeptList(response.Edu_No)
            this.dataDetail = response;
            this.dataDetail.Edu_No = this.dataDetail.Edu_No;
            this.dataDetail.Team_Name = this.dataDetail.Team_Name;
            this.dataDetail.Update_Time = this.dataDetail.Update_Time;
            this.dataDetail.Update_User = this.dataDetail.Update_User;
        });
    }

    insert() {
        if (this.dataDetail.Dept_No == '' || this.dataDetail.ADName == '' || this.dataDetail.EADName == '' ||
            this.dataDetail.Grade_Years == '' || this.dataDetail.Limit_Years == '' || this.dataDetail.OnJobLimit_Years == '' ||
            this.dataDetail.State_Log == '' || this.dataDetail.Team_EngName == '' || this.dataDetail.Team_Name == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0105/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功，請重新查詢");
            this.search(this.search1.Edu_No, this.search1.Dept_No)
        }, error => this.toastr.error(error.error))
    }

    update() {
        if (this.dataDetail.Dept_No == '' || this.dataDetail.ADName == '' || this.dataDetail.EADName == '' ||
            this.dataDetail.Grade_Years == '' || this.dataDetail.Limit_Years == '' || this.dataDetail.OnJobLimit_Years == '' ||
            this.dataDetail.State_Log == '' || this.dataDetail.Team_EngName == '' || this.dataDetail.Team_Name == '' || this.dataDetail.Team_No == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0105/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功，請重新查詢");
            this.search(this.search1.Edu_No, this.search1.Dept_No)
        }, error => this.toastr.error(error.error))

    }

    delete() {
        if (this.dataDetail.Team_No == '') {
            this.toastr.info("請選擇組別");
            return;
        }

        Swal.fire({
            title: "確定刪除？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0105/Delete", this.dataDetail).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功，請重新查詢");
                    this.clear();
                }, error => this.toastr.error(error.error))
            }
            else {
                this.toastr.info("動作取消");
            }
        })


    }

    clear() {
        this.dataDetail = {
            Dept_No: '',
            Dept_Name: '',
            Team_No: '',
            Team_Name: '',
            Edu_No: '',
            Team_EngName: '',
            ADName: '',
            EADName: '',
            Grade_Years: '',
            Limit_Years: '',
            OnJobLimit_Years: '',
            Print_Log: '',
            Moe: '',
            State_Log: '',
            Exam_DeptNo:'',
            Update_User: '',
            Update_Time: '',
            Allow_Data: ''
        };
    }

}
