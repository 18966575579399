import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADRGP0201',
  templateUrl: './ADRGP0201.component.html',
  styleUrls: ['./ADRGP0201.component.css']
})
export class ADRGP0201Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
