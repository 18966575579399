import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetCurrentLocation } from 'src/app/_Models/getCurrentLocation';
import { SystemService } from 'src/app/_Services/_Shared/system.service';

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

    @Input() currentLocation!: GetCurrentLocation[] | undefined;

    constructor(private systemService: SystemService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
    }

}
