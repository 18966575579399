<div class="container">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">人事代碼</span>
                    <input type="text" class="form-control shadow-none" formControlName="Code">
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">姓名</span>
                    <input type="text" class="form-control shadow-none" formControlName="Name">
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">單位</span>
                    <select class="form-select shadow-none" formControlName="UnitCode">
                        <option value="">--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of unitList">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">狀態</span>
                    <select class="form-select shadow-none" formControlName="Stataus">
                        <option value="" selected>請選擇</option>
                        <option value="0" selected>未送出</option>
                        <option value="1" selected>簽核中</option>
                        <option value="2" selected>核准</option>
                        <option value="3" selected>不核准</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group mb-4">
                    <div class="input-group-prepend">
                        <span class="input-group-text text-primary fw-bolder">申請日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" 
                        bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" 
                        formControlName="ApplyDateFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text text-primary fw-bolder">至</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" 
                        bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" 
                        formControlName="ApplyDateTo">
                </div>     
            </div>         
        </div>
        <div class="row">          
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="col-md-12">
                        <button class="other-Button-gray float-end me-3" (click)="openModal(template)">新增</button>
                        <button class="other-Button-gray float-end me-3" (click)="getEMPMainDelete()">刪除</button>
                        <button class="search-btn float-end me-3" (click)="getEMPMainList()">查詢</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 30px;"></th>
                <th style="width: 100px;vertical-align: middle;text-align:left;">單位</th>
                <th style="width: 60px;vertical-align: middle;">職編</th>
                <th style="width: 80px;vertical-align: middle;">姓名</th>
                <th style="width: 70px;vertical-align: middle;text-align:left;">職稱</th>
                <th style="width: 70px;vertical-align: middle;">到職日</th>
                <th style="width: 70px;vertical-align: middle;">離職日</th>
                <th style="width: 120px;vertical-align: middle;">離校程序申請日期</th>
                <th style="width: 70px;vertical-align: middle;">離校程序狀態</th>
                <th style="width: 40px;vertical-align: middle;"></th>
                <!-- <th style="width: 60px;vertical-align: middle;">附件</th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of EMPMainList; let i = index" class="pointer">
                <td style="width: 30px;">
                    <input *ngIf="item.LeaveStatus== '0'" type="checkbox" [(ngModel)]="item.Checked"
                    (change)="setCheck(item.Checked,i)" class="pointer">                
                </td>
                <td style="width: 100px;text-align:left;">{{item.UnitName}}</td>
                <td style="width: 60px;">{{item.Code}}</td>
                <td style="width: 80px;">{{item.TchName}}</td>
                <td style="width: 70px;text-align:left;">{{item.JobName}}</td>
                <td style="width: 70px;">{{item.StartDate}}</td>
                <td style="width: 70px;">{{item.EndDate}}</td>
                <td style="width: 180px;">{{item.LeaveApplyDate}}</td>
                <td style="width: 70px;">
                    <ng-container *ngIf="item.LeaveStatus != '0' else other">
                        <a class="pointer text-decoration-none" (click)="openModal_status(flowTemplate,item.LeaveDocNo)">
                            {{item.LeaveStatusName}}
                        </a>
                    </ng-container>                    
                    <ng-template #other>{{item.LeaveStatusName}}</ng-template>                
                </td>
                <td style="width: 40px;">
                    <a class="pointer text-decoration-none"
                        *ngIf="item.LeaveStatus == '0'"
                        (click)="openModal(template)"
                        (click)="getEMPData(item.Code,item.SerialNo)">
                        <span class="icon-Fill-in-button"></span>編輯
                    </a>
                </td>                
                <!-- <td style="width: 60px;">
                    <p style="margin:3px;" *ngIf="item.FileName != ''">
                        <a class="pointer text-decoration-none">
                            <span>{{item.FileName}}</span>
                        </a>  
                    </p>                    
                    <ng-container *ngIf="item.FileName == ''">
                        <a class="pointer text-decoration-none">
                            <span>上傳</span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="item.FileName != ''">
                        <a class="pointer text-decoration-none">
                            <span>刪除</span>
                        </a>
                    </ng-container>         
                </td> -->
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">離校程序資料維護</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormEdit">
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">人事代碼</span>
                        <input type="text" class="form-control shadow-none" formControlName="Code">
                        <button class="search-btn float-end me-3" (click)="getEMPData('','')">查詢</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">教職員姓名</span>
                        <input type="text" class="form-control shadow-none" readonly formControlName="UserName">
                    </div>
                </div>
            </div>                      
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">離職日期</span>
                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                            bsDatepicker [bsConfig]="bsConfig" readonly
                            style="background-color: transparent;" formControlName="EndDate">
                    </div>
                </div>   
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">離職原因</span>
                        <input type="text" class="form-control shadow-none" formControlName="Reason">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="other-Button-gray float-end" (click)="getLeaveModify('0')">儲存</button>
                    <button class="other-Button-gray float-end" (click)="getLeaveModify('1')">送出</button>
                </div>              
            </div>
        </form>
    </div>
</ng-template>

<ng-template #flowTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'簽核流程' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <table class="table table-hover table-striped text-center table-rwd table-borderless">
            <thead class="thead-dark-red table-rwd-hide">
                <tr>
                    <th>{{'項次' | translate}}</th>
                    <th>{{'簽核名單' | translate}}</th>
                    <th>{{'代理/加簽' | translate}}</th>
                    <th>{{'簽核單位' | translate}}</th>
                    <th>{{'簽收時間' | translate}}</th>
                    <th>{{'核稿時間' | translate}}</th>
                    <th>{{'簽核意見' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of eDocFlow">
                    <td data-th="項次">{{item.Stage}}</td>
                    <td data-th="簽核名單">{{item.UserName}}</td>
                    <td data-th="代理/加簽">{{item.AgentName}}</td>
                    <td data-th="簽核單位">{{item.UnitName}}</td>
                    <td data-th="簽收時間">{{item.ReadDate}}</td>
                    <td data-th="核稿時間">{{item.SignDate}}</td>
                    <td data-th="簽核意見">{{item.Content}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>