import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { queryInput } from 'src/app/_Models/TCHWEB/TCH0308/queryInput';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-TCH0308',
  templateUrl: './TCH0308.component.html',
  styleUrls: ['./TCH0308.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class TCH0308Component implements OnInit {
  @ViewChild("chart") chart!: ChartComponent;
  public barchartOptions!: Partial<any>;
  public mixchartOptions!: Partial<any>;
  public homeworkOptions!: Partial<any>;
  public scoreOptions!: Partial<any>;
  baseUrl = environment.apiUrl;
  model: queryInput = {
    Years: '',
    Term: '',
    Type: '',
    Tch_No: JSON.parse(sessionStorage.getItem('auth') || '').Code
  };
  items: Array<string> = [];
  IsQuery: boolean = false;

  initialData = new InitialData(this.http);

  constructor(private http: HttpClient, private toastr: ToastrService) {
    this.barchartOptions = {
      series: [],
      chart: {
        height: 350,
        type: "bar",
        stacked: false,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      title: {
        text: "討論度統計"
      },
      xaxis: {
        categories: []
      }
    };
    this.homeworkOptions = {
      series: [],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',

      },
      title: {
        text: '作業完成度統計'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1
      },
      theme: {
        palette: 'palette5'
      },
      legend: {
        position: 'right',
        offsetX: 0,
        offsetY: 50
      },
    }
    this.scoreOptions = {
      series: [],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true
      },
      title: {
        text: '班級平均成績'
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return "$ " + val + " thousands"
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.getYears()
  }

  query(model: queryInput) {
    if (model.Years == '' || model.Term == '') {
      this.toastr.error("請選擇學年學期!", "查詢錯誤!")
      return;
    }
    if (model.Type == '') {
      this.toastr.error("請選擇查詢類別!", "查詢錯誤!")
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/E-Learning/TCH03_08/Get_StatusData", model).subscribe((response) => {
      if (response) {
        this.dataBinding(response, model.Type);
        this.IsQuery = true;
      }
      else {
        this.toastr.warning("查無資料!", "查詢結果")
        this.IsQuery = false;
      }
    });
  }

  dataBinding(data: any, type: string) {
    if (type == 'A') {
      this.barchartOptions.series = data.series;
      this.barchartOptions.xaxis.categories = data.categories;
    }
    if (type == 'B') {
      this.homeworkOptions.series = data.series;
      this.homeworkOptions.xaxis.categories = data.categories;
    }
    if (type == 'C') {
      this.scoreOptions.series = data.series;
      this.scoreOptions.xaxis.categories = data.categories;
    }
  }

  reset() {
    this.IsQuery = false;
  }

  getYears() {
    this.initialData.getYears().subscribe((resppnse) => this.items = resppnse);
  }

}
