import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCosMaterialList } from 'src/app/_Models/STDWEB/STDW50001/getCosMaterialList';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { environment } from 'src/assets/environments/environment';
import Hls from 'hls.js'
import { GetHomeworkList } from 'src/app/_Models/STDWEB/STDW50002/getHomeworkList';
import Swal from 'sweetalert2';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { GetTestList } from 'src/app/_Models/STDWEB/STDW50003/getTestList';
import { GetRollCallList } from 'src/app/_Models/TCHWEB/TCH0202/getRollCallList';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cur-class',
    templateUrl: './cur-class.component.html',
    styleUrls: ['./cur-class.component.css'],
})
export class CurClassComponent implements OnInit {
    @Output() childEvent = new EventEmitter();
    @Input() curList!: Get_Cur_List[];
    @Input() functionType!: string;
    @Input() week!: string;
    routerPath = ''
    materialModalRef!: BsModalRef;
    videoModalRef!: BsModalRef;
    homeworkModelRef!: BsModalRef;
    testModelRef!: BsModalRef;
    rollCallModelRef!: BsModalRef;
    officeHourDataKey: string = '';
    videoSource!: string;
    videoName!: string;
    homeWorkDataKey!: string;
    testDataKey!: string;
    baseUrl = environment.apiUrl;
    fileExchange = new FileExchange();
    cosMaterialData!: GetCosMaterialList[];
    homeworkData!: GetHomeworkList[];
    testData!: GetTestList[];
    rollCallData!: GetRollCallList;
    officeHourReason = '';

    // 線上點名人數宣告
    totalStudent!: Array<number>;
    lateCount!: Array<number>;
    absenteeismCount!: Array<number>;

    constructor(private toastr: ToastrService,
        private modalService: BsModalService,
        private http: HttpClient,
        private fileExchangeServices: FileExchangeService,
        private domSanitizer: DomSanitizer,
        private router: Router, private systemService: SystemService, private translateService: TranslateService
    ) {
        this.router.events.subscribe((data) => {
            let screenWidth = window.innerWidth;
            if (data instanceof NavigationEnd) {
                this.routerPath = data.url.substr(1);
                if (this.routerPath.substring(0, 8) == 'TCH03_10' && this.routerPath.length > 50 && screenWidth > 575.98) {
                    let token = this.routerPath.split('?')[1].split('&')[0].substring(5);
                    if (JSON.parse(sessionStorage.getItem('onlineMeeting') || '').Type == 'add') {
                        this.getOnlineMeeting(token, JSON.parse(sessionStorage.getItem('onlineMeeting') || '{}').DataKey, JSON.parse(sessionStorage.getItem('onlineMeeting') || '{}').MeetingID);
                    }
                    else {
                        this.cancelOnlineMeeting(token, JSON.parse(sessionStorage.getItem('onlineMeeting') || '{}').DataKey, JSON.parse(sessionStorage.getItem('onlineMeeting') || '{}').MeetingID);
                    }
                }
            }
        })
    }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
    }

    ngOnDestroy() {
        sessionStorage.removeItem('onlineMeetingDataKey');
    }

    openNewTab(url: string) {
        window.open(url, '_blank');
    }


    setSafeUrl(base64: string) {
        return this.domSanitizer.bypassSecurityTrustUrl(base64);
    }

    getCosMaterialList(dataKey: string, template: TemplateRef<any>) {

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };

        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW500_01/Get_CosMaterial_List", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosMaterialData = response;
            this.materialModalRef = this.modalService.show(template, modalConfig);
        });
    }

    exportCosFile(dataKey: string) {
        this.fileExchangeServices.ExportFile("ACAD/STDWEB/STDW500_01/Export_CosFile_Base64", { DataKey: dataKey }).subscribe((response) => {
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    getVideo(template: TemplateRef<any>, path: string, fileName: string) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.videoSource = path;
        this.materialModalRef.hide();
        this.videoModalRef = this.modalService.show(template, modalConfig);
        this.videoName = fileName;
        this.vedioStart(this.videoSource);
    }

    vedioStart(source: any) {
        var video = <HTMLVideoElement>document.getElementById('active_video');
        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.attachMedia(video);
            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                hls.loadSource(source);
            });
        }
        else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = source;
        }
        else {
            this.toastr.error("您的瀏覽器不支援影片撥放!");
        }
    }

    getHomeworkList(dataKey: string, template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW500_02/Get_Homework_List", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.homeworkData = response;
            this.homeworkModelRef = this.modalService.show(template, modalConfig);
        });
    }

    exportHomeworkFile(dataKey: string) {
        this.fileExchangeServices.ExportFile("ACAD/STDWEB/STDW500_02/Export_HomeworkFile_Base64", { DataKey: dataKey }).subscribe((response) => {
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    importInputCkick(dataKey: string) {
        this.homeWorkDataKey = dataKey;
        document.getElementById(dataKey)?.click();
    }

    importData($event: any, dataKey: string, type: string) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type)
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        let json = {
            FileName: file.name,
            ContentType: file.type,
            Base64: base64,
            DataKey: dataKey,
            IP: '',
        }

        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                if (type == 'homework') {
                    that.fileExchangeServices.ImportFile('ACAD/STDWEB/STDW500_02/Import_HomeworkFile_Base64', json)
                        .subscribe((response: any) => {
                            if (response.Info == true) { that.toastr.success("上傳成功!!"); }
                            else { that.toastr.error(response.Message, "執行錯誤!"); }
                        });
                }

                if (type == 'test') {
                    that.fileExchangeServices.ImportFile('ACAD/STDWEB/STDW500_03/Import_TestFile_Base64', json)
                        .subscribe((response: any) => {
                            if (response.Info == true) { that.toastr.success("上傳成功!!"); }
                            else { that.toastr.error(response.Message, "執行錯誤!"); }
                        });
                }
            }
            else if (result.isDenied) {
                document.getElementById(dataKey)?.setAttribute('value', '');
                that.toastr.info("已取消上傳。");
            }
        });
    }

    getTestList(dataKey: string, template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW500_03/Get_Test_List", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.testData = response;
            this.testModelRef = this.modalService.show(template, modalConfig);
        });
    }

    exportTestFile(dataKey: string) {
        this.fileExchangeServices.ExportFile("ACAD/STDWEB/STDW500_03/Export_TestFile_Base64", { DataKey: dataKey }).subscribe((response) => {
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    getRollCall(dataKey: string, template: TemplateRef<any>, week: string) {
        if (week == '') {
            this.toastr.info("請選擇週次");
            return;
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH02_02/Get_RollCall_List", { DataKey: dataKey, CurWeek: week }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.rollCallData = response[0];
            this.totalStudent = response[0].StdCount;
            this.totalStudent = [];
            this.lateCount = [];
            this.absenteeismCount = [];
            for (let index = 0; index < response[0].SectionList[0].SectionItem.length; index++) {

                // 將每一節遲到、曠課、出席的人數先存進陣列以供以後計算
                this.absenteeismCount.push(parseInt(response[0].SectionList[0].SectionItem[0].HA));
                this.lateCount.push(parseInt(response[0].SectionList[0].SectionItem[0].HB));
                this.totalStudent.push(parseInt(response[0].SectionList[0].SectionItem[0].HH));
            }
            this.rollCallModelRef = this.modalService.show(template, modalConfig);
        });
    }

    studentCount(rowIndex: number, curIndex: number, H_Code: string) {


        if (this.rollCallData.SectionList[rowIndex].SectionItem[curIndex].H_Code == H_Code) {
            return;
        }

        if (this.rollCallData.SectionList[rowIndex].SectionItem[curIndex].H_Code == 'A' && H_Code == '') {
            this.absenteeismCount[curIndex] -= 1;
            this.totalStudent[curIndex] += 1;
        }
        else if (this.rollCallData.SectionList[rowIndex].SectionItem[curIndex].H_Code == 'B' && H_Code == '') {
            this.lateCount[curIndex] -= 1;
            this.totalStudent[curIndex] += 1;
        }
        else if (this.rollCallData.SectionList[rowIndex].SectionItem[curIndex].H_Code == 'A' && H_Code == 'B') {
            this.absenteeismCount[curIndex] -= 1;
            this.lateCount[curIndex] += 1;
        }
        else if (this.rollCallData.SectionList[rowIndex].SectionItem[curIndex].H_Code == 'B' && H_Code == 'A') {
            this.absenteeismCount[curIndex] += 1;
            this.lateCount[curIndex] -= 1;
        }
        else if (this.rollCallData.SectionList[rowIndex].SectionItem[curIndex].H_Code == '' && H_Code == 'A') {
            this.absenteeismCount[curIndex] += 1;
            this.totalStudent[curIndex] -= 1;
        }
        else {
            this.lateCount[curIndex] += 1;
            this.totalStudent[curIndex] -= 1;
        }

        this.rollCallData.SectionList[rowIndex].SectionItem[curIndex].H_Code = H_Code;

    }

    saveRollCall() {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH02_02/Get_RollCall_Save", this.rollCallData).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message);
                return;
            }

            this.toastr.success("儲存成功");
        }
            , error => this.toastr.error(error.error)
        )
    }

    newOfficeHour(dataKey: string) {
        this.officeHourReason = '';
        this.officeHourDataKey = dataKey;
    }

    saveNewOfficeHour(week: string) {

        if (week == '') {
            this.toastr.info("請選擇週次");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW400_05/Get_OH_Apply", { DataKey: this.officeHourDataKey, Reason: this.officeHourReason, Weeks: week }).subscribe((response) => {
            if (response.Info == true) {
                this.toastr.success("送出成功");
                return;
            }

            this.toastr.error(response.Message, "送出失敗");
        }, error => this.toastr.error(error.error))
    }

    deleteOfficeHour(dataKey: string) {

        Swal.fire({
            title: '確定刪除?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_09/Get_OH_Delete", { DataKey: dataKey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }

                    this.toastr.success("刪除成功");

                    this.childEvent.emit();


                }, error => this.toastr.error(error.error));
            }
            else {
                this.toastr.info("已取消");
            }
        })


    }

    getAzureToken(dataKey: string, type: string, meetingID: string) {
        this.http.get<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_10/Init_Meeting").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            sessionStorage.setItem('onlineMeeting', JSON.stringify({ DataKey: dataKey, Type: type, MeetingID: meetingID }));
            window.location.href = "https://login.microsoftonline.com/" +
                response.TenantID +
                "/oauth2/v2.0/authorize?client_id=" +
                response.ClientID +
                "&response_type=code&response_mode=query&redirect_uri=" +
                response.ReDirectURL +
                "&scope=https%3A%2F%2Fgraph.microsoft.com%2FOnlineMeetings.ReadWrite";
        })
    }

    getOnlineMeeting(token: string, onlineMeetingDataKey: string, meetingID: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_10/Insert_Meeting", {
            WeekIndex: null,
            MeetingToken: token,
            DataKey: onlineMeetingDataKey,
            MeetingID: meetingID
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("已儲存");
            this.childEvent.emit();
            this.router.navigateByUrl('/TCH03_10');

        }, error => this.toastr.error(error.error))
    }

    cancelOnlineMeeting(token: string, onlineMeetingDataKey: string, meetingID: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_10/Delete_Meeting", {
            WeekIndex: null,
            MeetingToken: token,
            DataKey: onlineMeetingDataKey,
            MeetingID: meetingID
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("取消成功");
            this.childEvent.emit();
            this.router.navigateByUrl('/TCH03_10');

        }, error => this.toastr.error(error.error))
    }

}
