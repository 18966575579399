import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0106/search';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUR0402',
    templateUrl: './ADCUR0402.component.html',
    styleUrls: ['./ADCUR0402.component.css']
})
export class ADCUR0402Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    deptList!: GetDeptList[];
    baseUrl = environment.apiUrl;
    data!: Search[];
    dataKeyArray: Array<string> = [];
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
        this.getYears();
    }

    getYears(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }


    getDeptList(Years: string, Term: string) {
        if (Years == '' || Term == '') {
            return;
        }
        this.systemService.getDeptList('', '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    search(Dept_No: string) {

        if (Dept_No == '') {
            this.toastr.info("請選擇系所");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0106/Search", { Edu_No: '', Dept_No: Dept_No }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
        })
    }

    setDatakey(type: string, Class: string, event: any) {
        if (!this.data) {
            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.data.length && input.checked == true) {
                this.dataKeyArray = [];
                this.data.forEach(element => {
                    this.dataKeyArray.push(element.Class);
                    element.Selected = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.data.forEach(element => {
                    element.Selected = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(Class);
            if (index == -1) {
                this.dataKeyArray.push(Class);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

    exportReport(Years: string, Term: string, DeptNo: string) {
        let dataKey = '';
        for (let i = 0; i < this.dataKeyArray.length; i++) {
            dataKey += this.dataKeyArray[i] + ','
        }
        dataKey = dataKey.substring(0, dataKey.length - 1);

        this.fileExchangeServices.ExportFile("ACAD/CUR/ADCUR0402/Export_Report_ADCUR0402", { Years: Years, Term: Term, DeptNo: DeptNo, ClassList: dataKey })
            .subscribe((response) => {

                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }

                if (response.FileName == '') {
                    this.toastr.warning("查無資料");
                    return;
                }
                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = response.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                this.dataKeyArray = [];
            }, error => this.toastr.error(error));
    }

}
