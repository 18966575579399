<div class="container">
    <h3 class="text-center fw-bolder">{{'歷年成績' | translate}}</h3>
    <table class="table table-hover table-striped table-rwd text-center">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'學年' | translate}}</th>
                <th>{{'學期' | translate}}</th>
                <th>{{'科目代碼' | translate}}</th>
                <th>{{'科目名稱' | translate}}</th>
                <th>{{'選修別' | translate}}</th>
                <th>{{'學分數' | translate}}</th>
                <th>{{'成績' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data.YearScore">
                <td data-th="學年">{{item.Years}}</td>
                <td data-th="學期">{{item.Term}}</td>
                <td data-th="科目代碼">{{item.Cos_ID}}</td>
                <td data-th="科目名稱">{{item.Cos_Name}}</td>
                <td data-th="選修別">{{item.SelKind_Name}}</td>
                <td data-th="學分數">{{item.Cos_Credit}}</td>
                <td class="fw-bolder" [ngClass]="{'text-danger': checkScore(item.Score) == true}" data-th="成績">
                    {{item.Score}}</td>
                <td class="td-rwd-hide" data-th=""></td>
            </tr>
        </tbody>
    </table>

    <h3 class="text-center fw-bolder">{{'學年總成績' | translate}}</h3>
    <table class="table table-hover table-striped table-rwd">
        <thead class="table-rwd-hide">
            <tr>
                <th>{{'學年' | translate}}</th>
                <th>{{'學期' | translate}}</th>
                <th>{{'學期成績' | translate}}</th>
                <th>{{'操行成績' | translate}}</th>
                <th>{{'總修學分' | translate}}</th>
                <th>{{'實得學分' | translate}}</th>
                <th>{{'班級名次' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data.GrdReport">
                <td data-th="學年">{{item.Years}}</td>
                <td data-th="學期">{{item.Term }}</td>
                <td data-th="學期成績">{{item.Grd_Avg}}</td>
                <td data-th="操行成績">{{item.Action_Score}}</td>
                <td data-th="總修學分">{{item.All_Credit}}</td>
                <td data-th="實得學分">{{item.Get_Credit}}</td>
                <td data-th="班級名次">{{item.Sort_Class}}</td>
                <td class="td-rwd-hide" data-th=""></td>
            </tr>
        </tbody>
    </table>
</div>