<div class="container">
    <app-year-term-input (childEvent)="getRecord($event)"></app-year-term-input>
    <h5 class="fw-bolder">{{stdInfo}}</h5>
    <span>{{'*註：00節為朝會' | translate}}</span>
    <div class="text-center" *ngIf="data">
        <span>{{'109學年第二學期累計缺矌、請假節數：' | translate}}{{data.length}}{{'節' | translate}}</span>
    </div>
    <table class="table table-hover table-striped text-center table-rwd">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'假別' | translate}}</th>
                <th>{{'日期' | translate}}</th>
                <th>{{'星期' | translate}}</th>
                <th>{{'節次' | translate}}</th>
                <th>{{'課程名稱' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of data">
                <td data-th="假別">{{data.H_Name}}</td>
                <td data-th="日期">{{data.RecDate}}</td>
                <td data-th="星期">{{data.Week_Mark}}</td>
                <td data-th="節次">{{data.Cur_Time}}</td>
                <td data-th="課程名稱">{{data.Cos_Name}}</td>
                <td class="td-rwd-hide" data-th=""></td>
            </tr>
        </tbody>
    </table>
</div>