import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetTFSMakeDocLoanItemDetail } from 'src/app/_Models/AD/ADTFS/ADTFS0301/getTFSMakeDocLoanItemDetail';
import { GetTFSMakeDocLoanItemList } from 'src/app/_Models/AD/ADTFS/ADTFS0301/getTFSMakeDocLoanItemList';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADTFS0301',
  templateUrl: './ADTFS0301.component.html',
  styleUrls: ['./ADTFS0301.component.css']
})
export class ADTFS0301Component implements OnInit {

  baseUrl = environment.apiUrl;
  disabled = true;
  tFSMakeDocLoanItemList!: GetTFSMakeDocLoanItemList[];
  tFSMakeDocLoanItemDetail: GetTFSMakeDocLoanItemDetail = {
    DataKey: '',
    LoanItem: '',
    LoanItemName: '',
    Money: '',
    Apply: '',
    UpdateUser: '',
    UpdateTime: '',
    UpdateIP: ''
  };
  constructor(private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getTFSMakeDocLoanItemList();
  }



  getTFSMakeDocLoanItemList() {
    this.http.get<any>(this.baseUrl + "ACAD/TFS/ADTFS0301/Get_TFS_MakeDoc_LoanItemList").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tFSMakeDocLoanItemList = response;
    })
  }


  getTFSMakeDocLoanItemDetail(dataKey: string) {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0301/Get_TFS_MakeDoc_LoanItemDetail", { DataKey: dataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tFSMakeDocLoanItemDetail = response;
      this.tFSMakeDocLoanItemDetail.LoanItemName = this.tFSMakeDocLoanItemDetail.LoanItemName;
      this.tFSMakeDocLoanItemDetail.UpdateIP = this.tFSMakeDocLoanItemDetail.UpdateIP;
      this.tFSMakeDocLoanItemDetail.UpdateUser = this.tFSMakeDocLoanItemDetail.UpdateUser;
      this.tFSMakeDocLoanItemDetail.UpdateTime = this.tFSMakeDocLoanItemDetail.UpdateTime;
      if (!this.disabled) {
        this.disabled = !this.disabled
      }

    })
  }

  setApply(event: any) {
    if (this.tFSMakeDocLoanItemDetail.Apply == '1' && event.checked == false) {
      this.tFSMakeDocLoanItemDetail.Apply = '0';
    }
    else {
      this.tFSMakeDocLoanItemDetail.Apply = '1';
    }
  }

  getTFSMakeDocLoanItemSave() {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0301/Get_TFS_MakeDoc_LoanItemSave", this.tFSMakeDocLoanItemDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.disabled = !this.disabled
      this.toastr.success("儲存成功");
      this.tFSMakeDocLoanItemDetail = {
        DataKey: '',
        LoanItem: '',
        LoanItemName: '',
        Money: '',
        Apply: '',
        UpdateUser: '',
        UpdateTime: '',
        UpdateIP: ''
      };
    })
  }

  clear() {
    this.tFSMakeDocLoanItemDetail = {
      DataKey: '',
      LoanItem: '',
      LoanItemName: '',
      Money: '',
      Apply: '',
      UpdateUser: '',
      UpdateTime: '',
      UpdateIP: ''
    };
  }



}
