<div class="container">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'開課班級'|translate}}</span>
                </div>
                <select class="form-select shadow-none" #opClass (change)="getCosList(opClass.value)">
                    <option value="" disabled selected>{{'--請選擇開課班級--'|translate}}</option>
                    <option *ngFor="let item of opClassList" value="{{item.ItemValue}}">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'授課科目'|translate}}</span>
                </div>
                <select class="form-select shadow-none" #cos>
                    <option value="" disabled selected>{{'--請選擇授課科目--'|translate}}</option>
                    <option *ngFor="let item of cosList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="search-btn mb-3 me-3 float-start" (click)="getHomeworkList(cos.value)">{{'查詢'|translate}}</button>
            <button class="add-btn mb-3 float-start" (click)="openModal(template, cos.value)">{{'新增'|translate}}</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>{{'作業序號'|translate}}</th>
                <th>{{'作業名稱'|translate}}</th>
                <th>{{'作業類型'|translate}}</th>
                <th>{{'開放上傳時間'|translate}}</th>
                <th>{{'上傳截止時間'|translate}}</th>
                <th>{{'操作'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of homeworkList">
                <td>{{item.Homework_No}}</td>
                <td>{{item.Homework_Name}}</td>
                <td>{{item.Homework_Type}}</td>
                <td>{{item.Start_Date}}</td>
                <td>{{item.End_Date}}</td>
                <td>
                    <a class="pointer text-decoration-none me-3"
                        (click)="getHomeworkDetail(item.DataKey, template)"><span
                            class="icon-Modify-button"></span>{{'編輯'|translate}}</a>
                    <a class="pointer text-decoration-none" (click)="deleteData(item.DataKey, cos.value)"><span
                            class="icon-Clear-button"></span>{{'刪除'|translate}}</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'設定教材'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <td>{{'作業序號'|translate}}</td>
                    <td>
                        <input type="text" class="form-control shadow-none" disabled placeholder="由系統自動給予"
                            [(ngModel)]="homeworkDetail.Homework_No">
                    </td>
                </tr>
                <tr>
                    <td>{{'作業名稱'|translate}}</td>
                    <td>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="homeworkDetail.Homework_Name">
                    </td>
                </tr>
                <tr>
                    <td>{{'是否分組?'|translate}}</td>
                    <td>
                        <select class="form-select shadow-none" [(ngModel)]="homeworkDetail.Usual_Mark">
                            <option value="0">{{'不分組'|translate}}</option>
                            <option value="1">{{'分組'|translate}}</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>{{'檔案'|translate}}</td>
                    <td>
                        <button class="other-Button-gray me-3"
                            (click)="setHidden()">{{'選擇'|translate}}</button><span>{{homeworkDetail.File_Name}}</span>
                        <ul *ngIf="tchFileList" [hidden]="fileHidden">
                            <li *ngFor="let item of tchFileList" (click)="setFile(item.File_No, item.File_Name)"
                                class="text-primary fw-bolder pointer">{{item.File_Name}}</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>{{'開放上傳時間'|translate}}</td>
                    <td>
                        <input type="datetime-local" class="form-control shadow-none"
                            [(ngModel)]="homeworkDetail.Start_Date">
                    </td>
                </tr>
                <tr>
                    <td>{{'上傳截止時間'|translate}}</td>
                    <td>
                        <input type="datetime-local" class="form-control shadow-none"
                            [(ngModel)]="homeworkDetail.End_Date">
                    </td>
                </tr>
                <tr>
                    <td>{{'列入平時成績?'|translate}}</td>
                    <td>
                        <select class="form-select shadow-none">
                            <option value="N">{{'不列入'|translate}}</option>
                            <option value="Y">{{'列入'|translate}}</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>{{'更新者'|translate}}</td>
                    <td>
                        <input type="text" class="form-control shadow-none" [disabled]="true"
                            [(ngModel)]="homeworkDetail.Update_User">
                    </td>
                </tr>
                <tr>
                    <td>{{'更新時間'|translate}}</td>
                    <td>
                        <input type="text" class="form-control shadow-none" [disabled]="true"
                            [(ngModel)]="homeworkDetail.Update_Time">
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="save-btn-gray" style="margin: auto;" (click)="saveData(cos.value)">{{'儲存'|translate}}</button>
    </div>
</ng-template>