
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADSSS0202',
  templateUrl: './ADSSS0202.component.html',
  styleUrls: ['./ADSSS0202.component.css']
})
export class ADSSS0202Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}