<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year [(ngModel)]="model.Years">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term [(ngModel)]="model.Term">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">製單類別</span>
                </div>
                <select #MakeDocNo class="form-select shadow-none" [(ngModel)]="model.MakeDocNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of makeDocList">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">申請種類</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="model.RedClassNo">
                    <option value="">--全部--</option>
                    <option *ngFor="let item of redClassList;" value="{{item.ItemValue}}">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學號</span>
                </div>
                <input type="text" class="form-control shadow-none" #StdNo [(ngModel)]="model.StdNo">
            </div>
        </div>
        <div class="col-md-3" [hidden]="true">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">申請狀態</span>
                </div>
                <select class="form-select shadow-none">
                    <option value="">--全部--</option>
                    <option value="0">申請中</option>
                    <option value="1">通過</option>
                    <option value="2">未通過</option>
                </select>
            </div>
        </div>
        <div class="col-md-3" [hidden]="true">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">申請日</span>
                </div>
                <input type="text" class="form-control shadow-none" bsDaterangepicker [bsConfig]="{ isAnimated: true }">
            </div>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-12">
            <button class="search-btn float-start me-3" (clcik)="getTFSMakeDocExemptionList()">查詢</button>
            <button style="width: 110px;" class="other-Button-gray float-start me-3"
                (click)="exportExemptionFileBase64()">下載匯入檔</button>
            <button class="other-Button-gray float-start me-3" (click)="importClick()">匯入清冊</button>
            <input type="file" style="display: none;" id="import" (change)="importExemptionFileBase64($event, '', '')">
        </div>
    </div>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>學年</th>
                <th>學期</th>
                <th>班級</th>
                <th>學號</th>
                <th>姓名</th>
                <th>申請日期</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tFSMakeDocExemptionList">
                <td>{{item.Years}}</td>
                <td>{{item.Term}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.RedClassName}}</td>
            </tr>
        </tbody>
    </table>
</div>