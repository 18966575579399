import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetOpClassList } from 'src/app/_Models/getOpClassList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetCoseList } from 'src/app/_Models/TCHWEB/TCH0115/getCosList';
import { GetTchFileList } from 'src/app/_Models/TCHWEB/TCH0301/getTchFileList';
import { GetMaterialList } from 'src/app/_Models/TCHWEB/TCH0303/getMaterialList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0303',
    templateUrl: './TCH0303.component.html',
    styleUrls: ['./TCH0303.component.css']
})
export class TCH0303Component implements OnInit {
    opClassList!: GetSelectList[] | undefined;
    cosList!: GetCoseList[] | undefined;
    baseUrl = environment.apiUrl;
    materialList!: GetMaterialList[] | undefined;
    modalRef!: BsModalRef;
    tchFileList!: GetTchFileList[];
    materialDataKey = '';
    getOpClassListInput: GetOpClassList = {
        Years: '',
        Term: '',
        DeptNo: '',
        TeamNo: '',
        TchNo: '',
        Classroom: '',
        UserCode: '',
        CHeckRight: ''
    };
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private accountService: AccountService,
        private modalService: BsModalService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.getOpClassListInput.TchNo = response.Code;
            }
        }, error => this.toastr.error(error.error))

        this.timeService.getTimeCntr().subscribe((response) => {
            this.getOpClassListInput.Years = response.Now_Years;
            this.getOpClassListInput.Term = response.Now_Term;
            this.getOpClassList(this.getOpClassListInput)
        }, error => this.toastr.error(error.error))
    }

    setModel(model: any) {
        this.getOpClassListInput.Years = model.Years;
        this.getOpClassListInput.Term = model.Term;
        this.opClassList = undefined;
        this.cosList = undefined;
        this.getOpClassList(this.getOpClassListInput);
    }


    getOpClassList(model: GetOpClassList) {
        this.systemService.getOpClassList(model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.opClassList = response;
        }, error => this.toastr.error(error.error));
    }

    getCosList(opClass: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_15/List_Cos", {
            Years: this.getOpClassListInput.Years,
            Term: this.getOpClassListInput.Term,
            OpClass: opClass
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cosList = []
                return;
            }
            this.cosList = response;
        }, error => this.toastr.error(error.error));
    }

    getMaterialList(dataKey: string, template: TemplateRef<any>) {

        this.materialDataKey = dataKey;
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_03/Get_Material_List", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.materialList = undefined;
            }
            else {
                this.materialList = response;
            }

            this.openModal(template);
        }, error => this.toastr.error(error.error));
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_01/Get_TchFile_List", {
            Type_No: 'A',
            FileName: ''
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning("永久檔案無資料");
                return;
            }
            this.tchFileList = response;
            this.modalRef = this.modalService.show(template, modalConfig);
        }, error => this.toastr.error(error.error))
    }

    addFile(File_No: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_03/Insert_Material", {
            DataKey: this.materialDataKey,
            File_No: File_No
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_03/Get_Material_List", { DataKey: this.materialDataKey }).subscribe((response) => {
                if (response.Info == false) {
                    this.materialList = undefined;
                }
                else {
                    this.materialList = response;
                    this.toastr.success("已新增成功");
                }
            }, error => this.toastr.error(error.error));
        });
    }

    removeFile(dataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_03/Delete_Material", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_03/Get_Material_List", { DataKey: this.materialDataKey }).subscribe((response) => {
                if (response.Info == false) {
                    this.materialList = undefined;
                }
                else {
                    this.materialList = response;
                    this.toastr.success("已取消成功");
                }
            }, error => this.toastr.error(error.error));
        });
    }

}
