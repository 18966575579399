import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetTFSC04List } from 'src/app/_Models/STDWEB/STDW10006/getTFSC04List';
import { GetTFSC04 } from 'src/app/_Models/STDWEB/STDW10006/getTFS_C04';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-STDW10006',
  templateUrl: './STDW10006.component.html',
  styleUrls: ['./STDW10006.component.css']
})
export class STDW10006Component implements OnInit {

  baseUrl = environment.apiUrl;
  tFSC04List!: GetTFSC04List[];
  modalRef!: BsModalRef;
  tFSC04Data!: GetTFSC04;
  constructor(private http: HttpClient, private modalService: BsModalService, private toastr: ToastrService, private systemService: SystemService, private translateService: TranslateService) { }
  ngOnInit(): void {
    this.translateService.use(this.systemService.currentLanguage)
    this.getTFSC04List();
  }


  getTFSC04List() {
    this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW100_06/TFS_C04_List").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tFSC04List = response;
    })
  }

  getTFSC04(template: TemplateRef<any>) {
    this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW100_06/TFS_C04").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tFSC04Data = response;
      let modalConfig = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: "modal-lg"
      };
      this.modalRef = this.modalService.show(template, modalConfig);
    })
  }

}
