<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">科目代碼</span>
                </div>
                <input type="text" class="form-control shadow-none" disabled
                    [(ngModel)]="tFSMakeDocLoanItemDetail.LoanItem">
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">科目名稱</span>
                </div>
                <input type="text" class="form-control shadow-none" disabled
                    [(ngModel)]="tFSMakeDocLoanItemDetail.LoanItemName">
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">可貸金額</span>
                </div>
                <input type="text" class="form-control shadow-none" [(ngModel)]="tFSMakeDocLoanItemDetail.Money">
            </div>
        </div>
        <div class="col-md">
            <div class="form-check mt-2">
                <input class="form-check-input pointer" type="checkbox" #apply id="flexCheckDefault"
                    [checked]="tFSMakeDocLoanItemDetail.Apply == '1'" (click)="setApply(apply)" [disabled]="disabled">
                <label class="form-check-label pointer">可由學生填寫</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">修改者</span>
                </div>
                <input type="text" class="form-control shadow-none" disabled
                    [(ngModel)]="tFSMakeDocLoanItemDetail.UpdateUser">
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">修改時間</span>
                </div>
                <input type="text" class="form-control shadow-none" disabled
                    [(ngModel)]="tFSMakeDocLoanItemDetail.UpdateTime">
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">修改IP</span>
                </div>
                <input type="text" class="form-control shadow-none" disabled
                    [(ngModel)]="tFSMakeDocLoanItemDetail.UpdateIP">
            </div>
        </div>
        <div class="col-md">
            <button class="modify-btn float-start me-3" (click)="getTFSMakeDocLoanItemSave()">修改</button>
            <button class="other-Button-gray float-start" (click)="clear()">清除</button>
        </div>
    </div>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>科目代碼</th>
                <th>科目名稱</th>
                <th>就貸金額</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tFSMakeDocLoanItemList" class="pointer"
                (click)="getTFSMakeDocLoanItemDetail(item.DataKey)">
                <td>{{item.LoanItem}}</td>
                <td>{{item.LoanItemName}}</td>
                <td>{{item.Money}}</td>
            </tr>
        </tbody>
    </table>
</div>