<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">查詢代碼或關鍵字</span>
                <input type="text" class="form-control shadow-none">
            </div>
        </div>
        <div class="col-md-6">
            <button class="other-Button-gray float-end">刪除</button>
            <button class="other-Button-gray float-end me-3">修改</button>
            <button class="other-Button-gray float-end me-3">新增</button>
            <button class="search-btn float-end me-3 mb-3">查詢</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th>國籍代碼</th>
                <th>國籍名稱</th>
                <th>英文名稱</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
