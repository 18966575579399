import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADTFS0601',
  templateUrl: './ADTFS0601.component.html',
  styleUrls: ['./ADTFS0601.component.css']
})
export class ADTFS0601Component implements OnInit {
  initialData = new InitialData(this.http);
  baseUrl = environment.apiUrl;
  Years!: Array<string>;
  term = require('src/app/_Models/_SelectInput/term.json').term;
  makeDocList!: GetSelectList[];
  bsConfig = environment.bsDatePickerConfig;

  constructor(private toastr: ToastrService, private http: HttpClient, private systemService: SystemService, private localeService: BsLocaleService) { }

  ngOnInit(): void {
    this.localeService.use('zh-cn');
    this.getYears();
    this.getMakeDocList();
  }



  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getMakeDocList() {
    this.systemService.getMakeDocList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.makeDocList = response;
    }, error => this.toastr.error(error.error))
  }

}
