<h2 style="text-align: center;">
    {{initData.Years}}{{'學年第' | translate}}{{initData.Term}}{{'學期' | translate}}
    <font *ngIf="initData.MF == 'M'">{{'期中' | translate}}</font>
    <font *ngIf="initData.MF == 'F'">{{'期末' | translate}}</font>{{'教學評量' | translate}}
</h2>

<div class="container" style="margin-bottom: 100px;">
    <table class="table table-hover table-striped table-rwd text-center">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'項次' | translate}}</th>
                <th>{{'開課班級' | translate}}</th>
                <th>{{'科目代碼' | translate}}</th>
                <th>{{'科目名稱' | translate}}</th>
                <th>{{'授課教師' | translate}}</th>
                <th>{{'狀態' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data; let index = index">
                <td data-th="項次">{{index+1}}</td>
                <td data-th="開課班級">{{item.Class_Short}}</td>
                <td data-th="科目代碼">{{item.Cos_Id}}</td>
                <td data-th="科目名稱">{{item.Cos_Name}}</td>
                <td data-th="授課教師">{{item.Tch_Name}}</td>
                <td data-th="狀態">
                    <ng-container *ngIf="item.Que_Finish != 'Y'">
                        <span class="icon-Fill-in-button icon"></span>
                        <a class="pointer text-decoration-none fw-bolder"
                            (click)="getASMContent(item.DataKey, item.Cos_Name, item.Tch_Name,ASMQuestion)">{{'填寫' |
                            translate}}</a>
                    </ng-container>
                    <ng-container *ngIf="item.Que_Finish == 'Y'">
                        <font class="fw-bolder text-success">{{'已填寫' | translate}}</font>
                    </ng-container>
                </td>
                <td class="td-rwd-hide" data-th=""></td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #ASMQuestion>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'教師教學學生意見調查表' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="ASMModelRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <p>{{'科目：' | translate}}{{ASMCos}} | {{'教師：' | translate}}{{ASMTch}}</p>
        <div>
            <form [formGroup]="ansFormGroup" (ngSubmit)="saveASMContent()">
                <ng-container *ngFor="let item of aSMSubjects">
                    <h4 style="color: rgb(0, 179, 179);">{{item.SubjectName}}</h4>
                    <ng-container *ngFor="let question of item.Question">
                        <p>{{question.QuestionName}}</p>
                        <ng-container *ngIf="question.AnswerKind == 'CheckBox'">
                            <div>
                                <ng-container *ngFor="let item of question.AnswerType">
                                    <ng-container *ngIf="item.TextLog == '0'">
                                        <div class="btn-group">
                                            <label class="btn btn-outline-primary rounded-pill me-3" btnCheckbox
                                                formControlName="{{question.DataKey+item.AnswerNo}}" tabindex="0"
                                                role="checkbox"
                                                (click)="setAns('checkbox', item.AnswerNo, question.DataKey,question.DataKey+item.AnswerNo, $event)">{{item.AnswerName}}</label>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="item.TextLog == '1'">
                                        <input type="text" class="form-control shadow-none"
                                            placeholder="{{item.AnswerName}}"
                                            onblur="setAns('checkbox', item.AnswerNo, question.DataKey,question.DataKey+item.AnswerNo, $event)"
                                            value="">
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="question.AnswerKind == 'Option'">
                            <div>
                                <div btnRadioGroup>
                                    <ng-container *ngFor="let item of question.AnswerType">
                                        <ng-container *ngIf="item.TextLog == '0'">
                                            <label class="btn btn-outline-primary rounded-pill me-3"
                                                btnRadio="{{item.AnswerName}}"
                                                (click)="setAns('option', item.AnswerNo, question.DataKey, '', $event)">{{item.AnswerName}}</label>
                                        </ng-container>

                                        <ng-container *ngIf="item.TextLog == '1'">
                                            <input type="text" class="form-control shadow-none"
                                                placeholder="{{item.AnswerName}}"
                                                (blur)="setAns('option', item.AnswerNo, question.DataKey,question.DataKey+item.AnswerNo, $event)"
                                                value="">
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <hr>
                    </ng-container>
                </ng-container>
                <h3>{{'其他意見' | translate}}</h3>
                <textarea class="form-control mb-3" cols="50" rows="3" id="otherAnswer"></textarea>
                <button class="other-Button-gray float-end me-3" type="button" (click)="ASMModelRef.hide()">{{'取消' |
                    translate}}</button>
                <button class="other-Button-gray float-end me-3" type="submit">{{'送出' | translate}}</button>
            </form>
        </div>
    </div>
</ng-template>