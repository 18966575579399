import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetStdRegiList } from 'src/app/_Models/AD/ADSTD/ADSTD0206/getStdRegiList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';
import { GetData } from'src/app/_Models/AD/ADABS/ADABS0102/Data';
@Component({
    selector: 'app-ADABS0102',
    templateUrl: './ADABS0102.component.html',
    styleUrls: ['./ADABS0102.component.css']
})
export class ADABS0102Component implements OnInit {
    eduList!: GetEduList[];
    modalRef?: BsModalRef;
    change!: boolean;
    url = environment.apiUrl;
    data!:GetData[];
    data_detail:GetData={
        H_Code: '',
        H_Name: '',
        H_Score: 0.0,
        H_Times: 0,
        Update_User: '',
        H_Type: '',
        Edu02: '',
        K_Delete: 0,
        checked: false
    }
    model = {
        Years: '',
        Term: ''
    }
    ngOnInit(): void {
        this.getEduList();
    }
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService, private timeService: TimeService, private modalService: BsModalService) { }

    setDatakey(event: EventTarget | null) {
        let input = <HTMLInputElement>event;
        if(input.checked==true){
            this.data.forEach(m=>m.K_Delete=1)
            this.data.forEach(m=>m.checked=true)
        }
        else{
            this.data.forEach(m=>m.K_Delete=0)
            this.data.forEach(m=>m.checked=false)
        }
    }
    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }
    getEduList() {
        this.systemService.getEduList('3', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }
    choose_delete(index:number,num:number){
        if(num==0){
            this.data[index].K_Delete=1;
        }
        else{
            this.data[index].K_Delete=0;
        }
    }

    search(){
        this.data=[];
        if(this.data_detail.Edu02==''){
            this.toastr.warning('請輸入學制');
            return;
        }
        this.http.post<any>(this.url+ "ACAD/ABS/ADABS0102/ABS0102_search",this.data_detail).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data=response;
        })
    }

    update(){
            this.http.post<any>(this.url+ "ACAD/ABS/ADABS0102/ABS0102_update",this.data).subscribe(response=>{
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("修改成功");
                this.search();
            })
    }

    delete(){
        for(let y of this.data)
        {
            if(y.K_Delete==1){
                this.http.post<any>(this.url+ "ACAD/ABS/ADABS0102/ABS0102_delete",{"Edu02":y.Edu02,"H_Code":y.H_Code,"H_Name":y.H_Name,"H_Score":y.H_Score,"H_Times":y.H_Times,"H_Type":y.H_Type,"K_Delete":y.K_Delete,"Update_User":y.Update_User}).subscribe(response=>{
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.search();
                })
            }
        }
    }

    save(){
        this.http.post<any>(this.url+ "ACAD/ABS/ADABS0102/ABS0102_insert",this.data_detail).subscribe(response=>{
            console.log(response)
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search();
        })
    }
}
