<div class="container">
    <app-year-term-input (childEvent)="getCosList($event)"></app-year-term-input>
    <div class="row">
        <div class="col-md">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'授課科目'|translate}}</span>
                </div>
                <select class="form-select shadow-none" #cos>
                    <option value="" selected disabled>{{'--請選擇科目--'|translate}}</option>
                    <option *ngFor="let item of cosList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <button class="search-btn mb-3" (click)="getStdList(cos.value)">{{'查詢'|translate}}</button>
        </div>
    </div>

    <div class="row" style="margin-bottom: 100px;">
        <div class="col-md-4">
            <table class="table table-hover text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 50px;"></th>
                        <th>{{'班級'|translate}}</th>
                        <th>{{'學號'|translate}}</th>
                        <th>{{'姓名'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdList; let i = index" (click)="getStdDetail(item.DataKey, i)"
                        class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}">
                        <td style="width: 50px;">{{i+1}}</td>
                        <td>{{item.Class_Name}}</td>
                        <td>{{item.Std_No}}</td>
                        <td>{{item.Std_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8" style="height: 500px; overflow-y: scroll;">
            <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let item of stdDetail">
                    <ng-container *ngIf="item.Title == '缺曠紀錄' || item.Title == '歷年成績' else other">
                        {{item.Title}}：<button class="other-Button-gray"
                            (click)="getStdInfo(item.Title, item.DataKey, template)">{{'查看'|translate}}</button>
                    </ng-container>
                    <ng-template #other>{{item.Title}}：{{item.Content}}</ng-template>
                </li>
            </ul>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover text-center" *ngIf="stdABS">
            <thead class="thead-dark-red">
                <tr>
                    <th>{{'學年'|translate}}</th>
                    <th>{{'學期'|translate}}</th>
                    <th>{{'學號'|translate}}</th>
                    <th>{{'日期'|translate}}</th>
                    <th>{{'節次'|translate}}</th>
                    <th>{{'週次'|translate}}</th>
                    <th>{{'原因'|translate}}</th>
                    <th>{{'科目'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of stdABS">
                    <td>{{item.Years}}</td>
                    <td>{{item.Term}}</td>
                    <td>{{item.Std_No}}</td>
                    <td>{{item.RecDate}}</td>
                    <td>{{item.Cur_Time}}</td>
                    <td>{{item.Week_Mark}}</td>
                    <td>{{item.H_Name}}</td>
                    <td>{{item.Cos_Name}}</td>
                </tr>
            </tbody>
        </table>

        <h3 class="text-center fw-bolder" *ngIf="stdScore">{{'歷年成績'|translate}}</h3>
        <table class="table table-hover table-striped table-rwd text-center" *ngIf="stdScore">
            <thead class="table-rwd-hide thead-dark-red">
                <tr>
                    <th>{{'學年'|translate}}</th>
                    <th>{{'學期'|translate}}</th>
                    <th>{{'科目代碼'|translate}}</th>
                    <th>{{'科目名稱'|translate}}</th>
                    <th>{{'選修別'|translate}}</th>
                    <th>{{'學分數'|translate}}</th>
                    <th>{{'成績'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of stdScore.YearScore">
                    <td data-th="學年">{{item.Years}}</td>
                    <td data-th="學期">{{item.Term}}</td>
                    <td data-th="科目代碼">{{item.Cos_ID}}</td>
                    <td data-th="科目名稱">{{item.Cos_Name}}</td>
                    <td data-th="選修別">{{item.SelKind_Name}}</td>
                    <td data-th="學分數">{{item.Cos_Credit}}</td>
                    <td class="fw-bolder" [ngClass]="{'text-danger': checkScore(item.Score) == true}" data-th="成績">
                        {{item.Score}}</td>
                    <td class="td-rwd-hide" data-th=""></td>
                </tr>
            </tbody>
        </table>

        <h3 class="text-center fw-bolder" *ngIf="stdScore">{{'學年總成績'|translate}}</h3>
        <table class="table table-hover table-striped table-rwd text-center" *ngIf="stdScore">
            <thead class="table-rwd-hide">
                <tr>
                    <th>{{'學年'|translate}}</th>
                    <th>{{'學期'|translate}}</th>
                    <th>{{'學期成績'|translate}}</th>
                    <th>{{'操行成績'|translate}}</th>
                    <th>{{'總修學分'|translate}}</th>
                    <th>{{'實得學分'|translate}}</th>
                    <th>{{'班級名次'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of stdScore.GrdReport">
                    <td data-th="學年">{{item.Years}}</td>
                    <td data-th="學期">{{item.Term}}</td>
                    <td data-th="學期成績">{{item.Grd_Avg}}</td>
                    <td data-th="操行成績">{{item.Action_Score}}</td>
                    <td data-th="總修學分">{{item.All_Credit}}</td>
                    <td data-th="實得學分">{{item.Get_Credit}}</td>
                    <td data-th="班級名次">{{item.Sort_Class}}</td>
                    <td class="td-rwd-hide" data-th=""></td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>