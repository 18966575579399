import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetABSAdminContent } from 'src/app/_Models/AD/ADABS/ADABS0202/getABSAdminContent';
import { GetABSAdminList } from 'src/app/_Models/AD/ADABS/ADABS0202/getABSAdminList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetHolidayList } from 'src/app/_Models/SAC/SAC1701/getHolidayList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADABS0202',
    templateUrl: './ADABS0202.component.html',
    styleUrls: ['./ADABS0202.component.css']
})
export class ADABS0202Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    bsConfig = environment.bsDatePickerConfig;
    holidayList!: GetHolidayList[];
    classList?: GetClassList[];
    modalRef?: BsModalRef;
    curTime: Array<any> = [];
    aBSAdminList?: GetABSAdminList[];
    fileExchange = new FileExchange();
    ngSearchForm = new UntypedFormGroup({
        Years: new UntypedFormControl('', Validators.required),
        Term: new UntypedFormControl('', Validators.required),
        Class: new UntypedFormControl(''),
        HCode: new UntypedFormControl(''),
        FieldType: new UntypedFormControl(''),
        FieldTypeValueFrom: new UntypedFormControl(''),
        FieldTypeValueTo: new UntypedFormControl(''),
        RecDateFrom: new UntypedFormControl(null),
        RecDateTo: new UntypedFormControl(null)
    });
    aBSAdminContent: GetABSAdminContent = {
        DataKey: '',
        Years: '',
        Term: '',
        StdNo: '',
        StdName: '',
        ClassName: '',
        RecDateS: '',
        RecDateE: '',
        HCode: '',
        CurTimeList: [],
        Memo: '',
        Base64: ''
    };
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private systemService: SystemService,
        private modalService: BsModalService,
        private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getHolidayList();
        this.localeService.use('zh-cn');
        for (let index = 1; index < 16; index++) {
            this.curTime.push({
                checked: false,
                number: index
            });
        }
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getClassList() {
        this.systemService.getClassList('', '', '0', this.ngSearchForm.get('Years')?.value, this.ngSearchForm.get('Term')?.value, 'Y', '').subscribe(response => {
            this.classList = response;
        })
    }

    getHolidayList() {
        this.systemService.getHolidayList().subscribe(response => {
            this.holidayList = response;
        });
    }

    openModal(type: string, template: TemplateRef<any>, data?: GetABSAdminList) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.curTime.forEach(element => {
            element.checked = false;
        });
        this.aBSAdminContent = {
            DataKey: '',
            Years: '',
            Term: '',
            StdNo: '',
            StdName: '',
            ClassName: '',
            RecDateS: '',
            RecDateE: '',
            HCode: '',
            CurTimeList: [],
            Memo: '',
            Base64: ''
        }
        if (type != 'new') {
            this.getABSAdminContent(data);
        }
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getABSAdminList() {

        if (!this.ngSearchForm.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0202/Get_ABS_Admin_List", this.ngSearchForm.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.aBSAdminList = response;
        })
    }

    getABSAdminBatchDelete() {
        if (!this.aBSAdminList) {
            this.toastr.info('請先查詢資料');
            return;
        }

        Swal.fire({
            title: '確定刪除選取資料？',
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0202/Get_ABS_Admin_BatchDelete", this.aBSAdminList).subscribe(response => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.getABSAdminList();
                })
            }
            else {
                this.toastr.info('已取消');
            }
        })
    }

    getABSAdminAdjust() {
        if (!this.ngSearchForm.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0202/Get_ABS_Admin_Adjust", {
            Years: this.ngSearchForm.get('Years')?.value,
            Term: this.ngSearchForm.get('Term')?.value
        }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('檢整完成');
        })
    }

    getABSAdminSampleFile() {
        this.http.get<any>(this.baseUrl + 'ACAD/ABS/ADABS0202/Get_ABS_Admin_SampleFile').subscribe(response => {
            let contentType = "application/octet-stream"
            let blob = this.fileExchange.Base64ToBlob(response.Base64, contentType, 512);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = '學生請假紀錄匯入範本.xls';
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        })
    }

    getABSAdminContent(data?: GetABSAdminList) {
        if (data) {
            this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0202/Get_ABS_Admin_Content", data).subscribe(response => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.aBSAdminContent = response;
                this.setCheck('get');
            })
        }
    }

    setCheck(type: string) {
        this.curTime.forEach(element => {
            element.checked = false;
        });
        if (type == 'get') {
            for (let i = 0; i < this.aBSAdminContent!.CurTimeList.length; i++) {
                for (let j = 0; j < this.curTime.length; j++) {

                    if (parseInt(this.aBSAdminContent!.CurTimeList[i].Cur_Time) == this.curTime[j].number) {
                        this.curTime[j].checked = true;
                    }
                }
            }
        }
    }

    setCurTime(event: EventTarget | null,Iindex:any) {
        let input = <HTMLInputElement>event;
        this.curTime[Iindex].checked = input.checked;
    }

    importData($event: any) {
        this.aBSAdminContent.StdNo = '';
        this.aBSAdminContent.StdName = '';
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, '', '')
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.aBSAdminContent.Base64 = base64;
            }
            else if (result.isDenied) {
                let file = <HTMLInputElement>document.querySelector('#file');
                file.value = '';
                that.toastr.info("已取消上傳。");
                that.aBSAdminContent.Base64 = '';
            }
        });
    }

    resetFile() {
        let file = <HTMLInputElement>document.querySelector('#file');
        file.value = '';
        this.aBSAdminContent.Base64 = '';
    }

    getABSAdminSave() {
        this.aBSAdminContent.CurTimeList = [];
        this.curTime.forEach(element => {
            if (element.checked == true) {

                this.aBSAdminContent.CurTimeList.push({
                    Cur_Time: element.number.toString().padStart(2, '0')
                })
            }
        });
        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0202/Get_ABS_Admin_Save", this.aBSAdminContent).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('儲存成功');
            this.modalRef?.hide();
            this.aBSAdminContent = {
                DataKey: '',
                Years: '',
                Term: '',
                StdNo: '',
                StdName: '',
                ClassName: '',
                RecDateS: '',
                RecDateE: '',
                HCode: '',
                CurTimeList: [],
                Memo: '',
                Base64: ''
            }
        })
    }

}
