<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled selected>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">請假日期</span>
                </div>
                <input type="text" placeholder="--請選擇日期--" class="form-control shadow-none" bsDaterangepicker
                    #dpr="bsDaterangepicker" formControlName="Date">
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-8">
            <button type="button" class="search-btn float-start me-3">查詢</button>
        </div>
        <div class="col-md-4">
            <button type="button" class="other-Button-gray float-end me-3" (click)="openModal(column)">新增</button>
        </div>
    </div>
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" (click)="setDatakey('all', '', $event.target)" class="pointer">&ensp;全選</th>
                <th>學年</th>
                <th>學期</th>
                <th>假別</th>
                <th>請假日期</th>
                <th>修改者</th>
                <th>學生清單</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #column>
    <div class="modal-header">
        <h4 class="modal-title pull-left">修改簽核人員</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">匯入檔案</span>
                    </div>
                    <input class="form-control shadow-none" type="file" (change)="importData($event)" id="file"
                        value="">
                    <div class="input-group-prepend">
                        <button class="btn btn-light shadow-none" type="button">下載格式</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div>說明：</div>
                    <div>1.下載集體請公假的匯入範例檔下載格式</div>
                    <div>2.匯入的Excel檔案，附檔名請存成「xls」檔。</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">工作表名稱</span>
                    </div>
                    <input type="text" class="form-control shadow-none" value="sheet1" #SheetName>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">請假日期</span>
                    </div>
                    <input type="text" placeholder="--請選擇日期--" class="form-control shadow-none" bsDaterangepicker
                        #dpr="bsDaterangepicker" formControlName="Date">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">請假類別</span>
                    </div>
                    <select class="form-select shadow-none">
                        <option value="" selected disabled>--請選擇--</option>
                    </select>
                    <select class="form-select shadow-none">
                        <option value="" selected disabled>--請選擇--</option>
                    </select>
                </div>
            </div>
            <div class="col-md-7">
                <span>※請依照選修課程所屬學制(日間部或進修推廣部)、節次請假。</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <span>請假節次</span>
            </div>
            <div class="col-md-11">
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1">
                    <label class="form-check-label pointer" for="inlineCheckbox1">第1節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox2">
                    <label class="form-check-label pointer" for="inlineCheckbox2">第2節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1">
                    <label class="form-check-label pointer" for="inlineCheckbox1">第3節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox2">
                    <label class="form-check-label pointer" for="inlineCheckbox2">第4節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1">
                    <label class="form-check-label pointer" for="inlineCheckbox1">第5節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1">
                    <label class="form-check-label pointer" for="inlineCheckbox1">第6節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox2">
                    <label class="form-check-label pointer" for="inlineCheckbox2">第7節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1">
                    <label class="form-check-label pointer" for="inlineCheckbox1">第8節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox2">
                    <label class="form-check-label pointer" for="inlineCheckbox2">第9節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1">
                    <label class="form-check-label pointer" for="inlineCheckbox1">第10節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1">
                    <label class="form-check-label pointer" for="inlineCheckbox1">第11節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox2">
                    <label class="form-check-label pointer" for="inlineCheckbox2">第12節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1">
                    <label class="form-check-label pointer" for="inlineCheckbox1">第13節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox2">
                    <label class="form-check-label pointer" for="inlineCheckbox2">第14節</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1">
                    <label class="form-check-label pointer" for="inlineCheckbox1">第15節</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <span class="input-group-text" id="basic-addon1">備註</span>
                <textarea class="form-control shadow-none" placeholder="填寫於此" id="floatingTextarea"
                    appTextareaAutoresize></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <button type="button" class="other-Button-gray float-end me-3" (click)="modalRef?.hide()">儲存</button>
            </div>
            <div class="col-md-6">
            </div>
        </div>
    </div>
</ng-template>