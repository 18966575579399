<div class="container">
    <div class="row mt-3">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>代碼</th>
                        <th>問卷類別</th>
                        <th>狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let a of data; let i = index" (click)="search_deatil(i)">
                        <td style="width: 60px;">{{i}}</td>
                        <td>{{a.SelStyle}}</td>
                        <td>{{a.SelStyle_Name}}</td>
                        <td>{{a.State}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
                <div class="row mb-3">
                    <button class="other-Button-gray float-end me-3" (click)="clear()">清除</button>
                    <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
                    <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
                    <button class="other-Button-gray float-end me-3" (click)="insert()">新增</button>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">問卷類別代碼</span>
                            <input type="text" [(ngModel)]="data_detail.SelStyle" class="form-control shadow-none" maxlength="6" placeholder="至多六碼" >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">問卷類別名稱</span>
                            <input type="text" class="form-control shadow-none"  [(ngModel)]="data_detail.SelStyle_Name">
                        </div>
                    </div>
                </div>
                 <div class="form-check form-check-inline">
                    <input class="form-check-input pointer" type="checkbox" id="inlineCheckbox1" (click)="change($event.target)" [(ngModel)]="data_detail.Checkedtype">
                    <label class="form-check-label pointer" for="inlineCheckbox1">複合問卷(或不作評量)</label>
                    <label class="form-check-label pointer" for="inlineCheckbox1">(實體問卷請勿勾選)</label>
                 </div>
                 <div class="input-group mb-3" *ngIf="data_detail.Type=='1'">
                    <span class="input-group-text">包含問卷值</span>
                    <input type="text" class="form-control shadow-none"  [(ngModel)]="data_detail.SelValue">
                </div>
                 <div class="form-check form-check-inline" *ngIf="data_detail.Type=='0'">
                    <input class="form-check-input pointer" type="checkbox" id="inlineCheckbox2" [(ngModel)]="data_detail.Checked">
                    <label class="form-check-label pointer" for="inlineCheckbox2">期中問卷</label>
                 </div>
                 <div class="form-check form-check-inline " *ngIf="data_detail.Type=='0'">
                    <input class="form-check-input pointer" type="checkbox" id="inlineCheckbox3" [(ngModel)]="data_detail.Checkedend">
                    <label class="form-check-label pointer" for="inlineCheckbox3">期末問卷</label>
                 </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">使用狀態修改</span>
                            <select class="form-select shadow-none"  [(ngModel)]="data_detail.State_Log">
                                <option value="" disabled>--請選擇--</option>
                                <option value="1">啟用</option>
                                <option value="3">未啟用</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改時間</span>
                            <input type="text" class="form-control shadow-none"  [(ngModel)]="data_detail.Update_Time" [readonly]="true">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改者</span>
                            <input type="text" class="form-control shadow-none"  [(ngModel)]="data_detail.Update_User" [readonly]="true">
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>