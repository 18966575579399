import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GetGRDScoreOrderList } from 'src/app/_Models/AD/ADGRD/ADGRD0303/getGRDScoreOrderList';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADABS0309',
    templateUrl: './ADABS0309.component.html',
    styleUrls: ['./ADABS0309.component.css']
})
export class ADABS0309Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    eduList?: GetEduList[];
    deptList?: GetDeptList[];
    classList?: GetClassList[];
    gRDScoreOrderList?: GetGRDScoreOrderList[];
    fileExchange = new FileExchange();
    ngFormInput = new UntypedFormGroup({
        ReportType: new UntypedFormControl('C', [Validators.required]),
        Years: new UntypedFormControl('', Validators.required),
        Term: new UntypedFormControl('', Validators.required),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        StdNo: new UntypedFormControl('')

    })

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
                this.getClassList('', '', this.Years[0], '1')
            }

        });
    }


    getEduList(Years: string, Term: string) {
        this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.eduList = undefined;
            this.deptList = undefined;
            this.classList = undefined;
            this.eduList = response;
        })
    }

    getDeptList(edu: string, Years: string, Term: string) {
        this.systemService.getDeptList(edu, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = undefined;
            this.classList = undefined;
            this.deptList = response;

        });
    }

    getClassList(edu: string, dept: string, Years: string, Term: string) {
        this.systemService.getClassList(edu, dept, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classList = undefined;
            this.classList = response;
        })
    }

    getGRDScoreOrderList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0309/Get_ADABS0308", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.gRDScoreOrderList = undefined;
                return;
            }
            this.gRDScoreOrderList = response;
        });
    }

    getGRDScoreOrderCompute() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0309/Get_ABS_ScoreOrder_Compute", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("計算完成");
        });
    }

    getGRDScoreOrderConfirm() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0309/Get_ABS_ScoreOrder_Confirm", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("確認資料完成");
        });
    }

    exportReportADGRD0309() {
        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.fileExchangeServices.ExportFile("ACAD/ABS/ADABS0309/Get_ADABS0309", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    setAll(eventTarget: EventTarget | null) {
        let input = <HTMLInputElement>eventTarget;
        this.classList?.forEach(element => {
            input.checked == true ? element.checked = true : element.checked = false;
        });
    }
}
