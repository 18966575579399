import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetSELCreditsLimitList } from 'src/app/_Models/AD/ADSEL/ADSEL0102/getSELCreditsLimitList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0102',
    templateUrl: './ADSEL0102.component.html',
    styleUrls: ['./ADSEL0102.component.css']
})
export class ADSEL0102Component implements OnInit {
    eduList!: GetEduList[];
    detailEduList!: GetEduList[];
    deptList!: GetDeptList[];
    detailDeptList!: GetDeptList[];
    teamList!: GetTeamList[];
    detailTeamList!: GetTeamList[];
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    baseUrl = environment.apiUrl;
    sELCreditsLimitList!: GetSELCreditsLimitList[];
    search1 = {
        EduNo: '',
        DeptNo: '',
        TeamNo: ''
    }
    ngForm = new UntypedFormGroup({
        DataKey: new UntypedFormControl(''),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl('', [Validators.required]),
        TeamNo: new UntypedFormControl('', [Validators.required]),
        Grade: new UntypedFormControl('', [Validators.required]),
        CreditMin: new UntypedFormControl(0, [Validators.required]),
        CreditMax: new UntypedFormControl(0, [Validators.required]),
        CedCreditMin: new UntypedFormControl(0, [Validators.required]),
        OtherCreditMin: new UntypedFormControl(0, [Validators.required]),
        OtherCreditMax: new UntypedFormControl(0, [Validators.required]),
        UpdateUser: new UntypedFormControl({ value: '', disabled: true }),
        UpdateTime: new UntypedFormControl({ value: '', disabled: true })
    })

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private localeService: BsLocaleService) { }

    ngOnInit(): void {
        this.getEduList();
        this.localeService.use('zh-cn');
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
            this.detailEduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, isDetail: boolean) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (isDetail) {
                this.detailDeptList = response;
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getTeamList(dept: string, isDetail: boolean) {
        this.systemService.getTeamList('3', '', '', dept, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (isDetail) {
                this.detailTeamList = response;
            }
            this.teamList = response;
        });
    }

    getSELCreditsLimitList(EduNo: string, DeptNo: string, TeamNo: string) {
        if (EduNo == '') {
            this.toastr.info("請選擇學制");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0102/Get_SEL_CreditsLimit_List", {
            EduNo: EduNo,
            DeptNo: DeptNo,
            TeamNo: TeamNo
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sELCreditsLimitList = []
                return;
            }
            this.sELCreditsLimitList = response;
            this.sELCreditsLimitList.forEach(element => {
                element.Selected = false;
            });
        })
    }

    getSELCreditsLimitDetail(dataKey: string, index: number) {
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0102/Get_SEL_CreditsLimit_Detail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELCreditsLimitList.forEach(element => {
                element.Selected = false;
            });
            this.sELCreditsLimitList[index].Selected = true;
            this.ngForm.setValue(response);
        })
    }

    getSELCreditsLimitSave() {

        if (!this.ngForm.valid) {
            this.toastr.info("請確認欄位正確性");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0102/Get_SEL_CreditsLimit_Save", this.ngForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.getSELCreditsLimitList(this.search1.DeptNo, this.search1.EduNo, this.search1.TeamNo)
            this.toastr.success("儲存成功");
        });
    }

    clear() {
        this.ngForm.reset();
    }

}
