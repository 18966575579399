<div class="container">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">開課年度</span>
                    <select class="form-select shadow-none" #year formControlName="Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value, false)"
                        formControlName="EduNo">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </form>

    <div class="row mb-3">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="clear()">清除畫面</button>
            <button class="other-Button-gray float-end me-3" (click)="getCUPClassTeamDelete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="getCUPClassTeamModify()">儲存</button>
            <button class="other-Button-gray float-end me-3" (click)="getCUPClassTeamCopyTo()">複製</button>
            <button class="search-btn float-end me-3" (click)="getCUPClassTeamList()">查詢</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>科系代碼</th>
                        <th>科系名稱</th>
                        <th>班級</th>
                        <th>學制</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of cUPClassTeamList; let i = index"
                        (click)="getCUPClassTeamTeamList(item.DataKey, i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.DeptNo}}</td>
                        <td>{{item.DeptName}}</td>
                        <td>{{item.ClassName}}</td>
                        <td>{{item.EduNo}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-3">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>組別代碼</th>
                        <th>組別名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of cUPClassTeamTeamList; let i = index"
                        (click)="getCUPClassTeamDetail(item.DataKey, i)">
                        <td style="width: 60px;">{{i}}</td>
                        <td>{{item.TeamNo}}</td>
                        <td>{{item.TeamName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md">
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">開課年度</span>
                        <select class="form-select shadow-none" [(ngModel)]="cUPClassTeamDetail.Years">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學制</span>
                        <select class="form-select shadow-none" #dedu (change)="getDeptList(dedu.value, true)"
                            [(ngModel)]="cUPClassTeamDetail.EduNo" [disabled]="cUPClassTeamDetail.DataKey != ''">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of detailEduList" value="{{item.Edu_No}}">
                                {{item.Edu_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none"
                            (ngModelChange)="getClassList(dedu.value, $event); getTeamList($event)"
                            [(ngModel)]="cUPClassTeamDetail.DeptNo" [disabled]="cUPClassTeamDetail.DataKey != ''">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of detailDeptList" [ngValue]="item.Dept_No">
                                {{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">組別</span>
                        <select class="form-select shadow-none" [(ngModel)]="cUPClassTeamDetail.TeamNo"
                            [disabled]="cUPClassTeamDetail.DataKey != ''">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of teamList" [ngValue]="item.Team_No">{{item.Team_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">班別</span>
                        <select class="form-select shadow-none" [(ngModel)]="cUPClassTeamDetail.Class"
                            [disabled]="cUPClassTeamDetail.DataKey != ''">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of classList" [ngValue]="item.Class">{{item.Class_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="cUPClassTeamDetail.UpdateUser">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="cUPClassTeamDetail.UpdateTime">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>