import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SystemService } from 'src/app/_Services/_Shared/system.service';

@Component({
  selector: 'app-STDW30007',
  templateUrl: './STDW30007.component.html',
  styleUrls: ['./STDW30007.component.css']
})
export class STDW30007Component implements OnInit {

  constructor(private systemService: SystemService, private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.translateService.use(this.systemService.currentLanguage)
  }

}
