<div class="container">

    <div class="row mb-3">
        <div class="col-md-12">
            <button class="add-btn float-start me-3" (click)="addData()">新增</button>
            <button class="other-Button-gray float-start" (click)="deleteData()">刪除</button>
            <button class="save-btn-gray float-end" (click)="saveData()">儲存</button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 40px;"></th>
                        <th style="width: 120px;">減免代碼</th>
                        <th>減免名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" class="pointer"
                        *ngFor="let item of redClassList; let i = index" (click)="getRedClassDetail(item.ItemValue, i)">
                        <td style="width: 40px;">{{i + 1}}</td>
                        <td style="width: 120px;">{{item.ItemValue}}</td>
                        <td>{{item.ItemName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-5">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">抵免身分代碼</span>
                </div>
                <input type="text" class="form-control shadow-none" [(ngModel)]="redClassDetail.RedClass_No">
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">抵免身分名稱</span>
                </div>
                <input type="text" class="form-control shadow-none" [(ngModel)]="redClassDetail.RedClass_Name">
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學費減免金額</span>
                </div>
                <input type="number" class="form-control shadow-none" min="0" [(ngModel)]="redClassDetail.Tuition">
                <div class="input-group-prepend">
                    <span class="input-group-text">減免上限</span>
                </div>
                <input type="number" class="form-control shadow-none" min="0"
                    [(ngModel)]="redClassDetail.TuitionCapped">
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學費減免比例</span>
                </div>
                <input type="number" class="form-control shadow-none" min="0"
                    [(ngModel)]="redClassDetail.Tuition_Percent">
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">雜費減免金額</span>
                </div>
                <input type="number" class="form-control shadow-none" min="0" [(ngModel)]="redClassDetail.Other">
                <div class="input-group-prepend">
                    <span class="input-group-text">減免上限</span>
                </div>
                <input type="number" class="form-control shadow-none" min="0" [(ngModel)]="redClassDetail.OtherCapped">
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">雜費減免比例</span>
                </div>
                <input type="number" class="form-control shadow-none" [(ngModel)]="redClassDetail.Other_Percent">
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">教育部類別</span>
                </div>
                <select class="form-control shadow-none" [(ngModel)]="redClassDetail.Tch_No">
                    <option value="" selected disabled>--請選擇--</option>

                </select>
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">修改者</span>
                </div>
                <input type="text" readonly class="form-control shadow-none" [(ngModel)]="redClassDetail.Update_User">
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">修改時間</span>
                </div>
                <input type="text" readonly class="form-control shadow-none" [(ngModel)]="redClassDetail.Update_Time">
            </div>
        </div>
    </div>
</div>