import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SRPApplyList,SRPApplyDetail,SRPApplySignInfo,SRPApplyFlow } from 'src/app/_Models/AD/ADPUB/ADPUB0102/getSRPApply';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADPUB0102',
    templateUrl: './ADPUB0102.component.html',
    styleUrls: ['./ADPUB0102.component.css']
})
export class ADPUB0102Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    bsConfig = environment.bsDatePickerConfig;
    fileExchange = new FileExchange();
    deptList!: GetDeptList[];
    eduList!: GetEduList[];
    classList!: GetClassList[];
    rewardsList!: GetSelectList[];
    sugCodeList!: GetSelectList[];
    UnitList!: GetSelectList[];
    modalRef?: BsModalRef;
    stdList: Array<string> = [];
    typeList: Array<string> = [];
    sRPEmpty: SRPApplyList[]=[];
    sRPList: SRPApplyList[] = [];
    sRPFlow: SRPApplyFlow[]=[];
    sRPAgreeList: SRPApplyList[] = [];
    sRPDetailList: SRPApplyDetail[] = [];
    sRPSignInfo:SRPApplySignInfo={
        DataKey: "",
        Years: "",
        Term: "",
        SerialNo: "",
        Stage: "",
        UnitCode: "",
        Title: "",
        SignAgree: "",
        SignMemo: ""
    };
    sRPSignInfoList:SRPApplySignInfo[] = [];
    isSend:boolean=false;
    isShowAgree:boolean=false;
    nowYear = '';
    nowTerm = '';

    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        StdNoName: new UntypedFormControl(''),
        ApplyDateFrom: new UntypedFormControl(null),
        ApplyDateTo: new UntypedFormControl(null),
        TypeList: new UntypedFormControl(''),
        Status: new UntypedFormControl(''),
        Memo: new UntypedFormControl('')
    });

    ngFormDetail = new UntypedFormGroup({
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        SerialNo: new UntypedFormControl(''),
        RecDate: new UntypedFormControl(null),
        DocNo: new UntypedFormControl(''),
        Memo: new UntypedFormControl(''),
        FileName: new UntypedFormControl(''),
        FileBase64: new UntypedFormControl(''),
        Status: new UntypedFormControl(''),
        StatusName: new UntypedFormControl(''),
        ApplyUnitCode: new UntypedFormControl(''),
        ApplyUnitName: new UntypedFormControl(''),
        ApplyDate: new UntypedFormControl(null),
        ApplyUser: new UntypedFormControl(''),
        DataList: new UntypedFormControl(this.sRPDetailList),
        SignInfo: new UntypedFormControl(null),
        Checked: new UntypedFormControl(false),
        DataKey: new UntypedFormControl(''),
    });

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private systemService: SystemService,
        private timeService: TimeService,
        private modalService: BsModalService,
        private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.timeService.getTimeCntr().subscribe((response) => {
            this.nowTerm = response.Now_Term;
            this.nowYear = response.Now_Years;
        });
        this.getEduList();
        this.getRewardsList();
        this.getUnitList();
        this.getSRPAgreeList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList() {
        this.systemService.getDeptList(this.ngFormInput.get('EduNo')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList() {
        this.systemService.getClassList(this.ngFormInput.get('EduNo')?.value, this.ngFormInput.get('DeptNo')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getRewardsList() {
        this.rewardsList = [];

        this.systemService.getRewardsList('').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.rewardsList = response;
        }, error => this.toastr.error(error.error))
    }

    getSuggestList(thisYear: string) {
        this.sugCodeList = [];

        this.systemService.getSuggestList(thisYear).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sugCodeList = response;
        }, error => this.toastr.error(error.error))
    }

    getUnitList() {
        this.UnitList = [];

        this.http.get<any>(this.baseUrl + "ACAD/PUB/ADPUB0102/Get_SRP_Unit_List").subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.UnitList = response;
        });
    }

    openModel(template: TemplateRef<any>, type: string, thisApply: SRPApplyList,thisIndex: number,thisAgree:boolean) {
        this.isSend=false;
        this.isShowAgree=thisAgree;
        this.sRPDetailList=[];

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        if (type == 'new') {
            this.ngFormDetail.reset({
                DataKey: '',
                Status:'0'
            });
            this.ngFormDetail.get("Years")?.setValue(this.nowYear);
            this.ngFormDetail.get("Term")?.setValue(this.nowTerm);
            this.getSuggestList(this.nowYear);
        }
        else {
            this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0102/Get_SRP_Apply_Detail", thisApply).subscribe(response => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.ngFormDetail.setValue(response);
                this.getSuggestList(response.Years);
                this.sRPDetailList=this.ngFormDetail.get('DataList')?.value;
                this.sRPSignInfo=this.ngFormDetail.get('SignInfo')?.value;
                this.isSend=((this.sRPList[thisIndex-1].Status=='0')?false:true);
            })
        }
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    openFlowModel(template: TemplateRef<any>, thisApply: SRPApplyList){
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-lg'
        };

        this.getSRPFlow(thisApply);
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setCheck(event: EventTarget | null) {
        let input = <HTMLInputElement>event;
        let index = this.typeList.indexOf(input.value);
        if (input.checked && index == -1) {
            this.typeList.push(input.value);
        }
        else {
            this.typeList.splice(index, 1);
        }

        if (this.typeList.length == 0) return;

        let data = '';

        this.typeList.forEach(element => {
            data += element + ','
        });

        this.ngFormInput.get('TypeList')?.setValue(data);
    }

    getSRPAgreeList() {
        this.sRPAgreeList=[];

        this.http.get<any>(this.baseUrl + "ACAD/PUB/ADPUB0102/Get_SRP_Apply_SignList").subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sRPAgreeList = response;
        });
    }

    getSRPList() {
        this.sRPList=[];
        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0102/Get_SRP_Apply_List", this.ngFormInput.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sRPList = response;
        });
    }

    getSRPFlow(thisApply: SRPApplyList) {
        this.sRPFlow = [];
        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0102/Get_SRP_Apply_SignFlow",thisApply).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sRPFlow = response;
        });
    }

    getSRPBatchDelete() {
        if (!this.sRPList) {
            this.toastr.info('請選擇學生');
            return;
        };

        Swal.fire({
            title: '確定進行刪除？',
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + 'ACAD/PUB/ADPUB0102/Get_SRP_Apply_Delete', this.sRPList).subscribe(response => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success('刪除成功');
                    this.getSRPList();
                });
            } else {
                this.toastr.info('動作已取消');
            }
        })
    }

    getSRPSave(Status:string) {
        this.ngFormDetail.get('Status')?.setValue(Status);

        this.sRPDetailList.forEach(element => {
            element.Years=this.ngFormDetail.get('Years')?.value;
            element.Term=this.ngFormDetail.get('Term')?.value;
            element.ApplyDate=this.ngFormDetail.get('RecDate')?.value;
        });

        this.ngFormDetail.get('DataList')?.setValue(this.sRPDetailList);

        if(Status=='0'){
            this.http.post<any>(this.baseUrl + 'ACAD/PUB/ADPUB0102/Get_SRP_Apply_Save', this.ngFormDetail.value).subscribe(response => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success('儲存成功');
                this.getSRPList();
                this.modalRef?.hide();
            })
        }
        else{
            Swal.fire({
                title: "一旦送出即不能修改，確定?",
                showDenyButton: true,
                confirmButtonText: '是',
                denyButtonText: '否',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.http.post<any>(this.baseUrl + 'ACAD/PUB/ADPUB0102/Get_SRP_Apply_Save', this.ngFormDetail.value).subscribe(response => {
                        if (response.Info == false) {
                            this.toastr.warning(response.Message);
                            return;
                        }
                        this.toastr.success('送出成功');
                        this.getSRPList();
                        this.modalRef?.hide();
                    })
                }
                else {
                    this.toastr.info("已取消送出");
                }
            })
        }
    }

    getSRPSign(thisSignAgree:string,thisSignMemo:string){
        this.sRPSignInfoList=[];
        this.sRPSignInfo=this.ngFormDetail.get('SignInfo')?.value;
        this.sRPSignInfo.SignAgree=thisSignAgree;
        this.sRPSignInfo.SignMemo=thisSignMemo;
        this.sRPSignInfoList.push(<SRPApplySignInfo>this.sRPSignInfo);
        this.getSRPSignForm();
    }

    getSRPSignBatch(thisSignAgree:string,thisSignMemo:string){
        let i=0;
        this.sRPSignInfoList=[];
        this.sRPAgreeList.forEach(element => {
            if(element.Checked){
                if(element.SignInfo!=null){
                    element.SignInfo.SignAgree=thisSignAgree;
                    element.SignInfo.SignMemo=thisSignMemo;
                    this.sRPSignInfoList.push(element.SignInfo);
                }
            }
            i+=1;
        });

        this.getSRPSignForm();
    }

    getSRPSignForm(){
        if(this.sRPSignInfoList.length==0){
            this.toastr.warning("無簽核資料!");
            return;
        }

        Swal.fire({
            title: "一旦送出即不能修改，確定?",
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + 'ACAD/PUB/ADPUB0102/Get_SRP_Apply_Sign', this.sRPSignInfoList).subscribe(response => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success('送出成功');
                    this.getSRPAgreeList();
                    this.modalRef?.hide();
                })
            }
            else {
                this.toastr.info("已取消送出");
            }
            this.sRPSignInfoList=[];
        })
    }

    importAttFile($event: any, dataKey: string, type: string) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetFileBase64, dataKey, type)
    }

    GetFileBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.ngFormDetail.get('FileBase64')?.setValue(base64);
                that.ngFormDetail.get('FileName')?.setValue(file.name);
            }
            else if (result.isDenied) {
                let file = <HTMLInputElement>document.querySelector('#file');
                file.value = '';
                that.toastr.info("已取消上傳。");
                that.ngFormDetail.get('FileBase64')?.setValue('');
                that.ngFormDetail.get('FileName')?.setValue('');
            }
        });
    }

    setItemCheck(thisApply: SRPApplyList,Index:number,event: EventTarget | null){
        let input = <HTMLInputElement>event;
        this.sRPList[Index].Checked=input.checked;
    }

    setSignItemCheck(thisApply: SRPApplyList,Index:number,event: EventTarget | null){
        let input = <HTMLInputElement>event;
        this.sRPAgreeList[Index].Checked=input.checked;
    }

    setAll(event: EventTarget | null) {
        if (!this.sRPList) return;
        let input = <HTMLInputElement>event;
        this.sRPList.forEach(element => {
            if(element.Status=='0'){
                input.checked == true ? element.Checked = true : element.Checked = false;
            }
        })
    }

    getSRPFile(dataKey: string) {
        this.fileExchangeServices.ExportFile('ACAD/SRP/ADSRP0201/Get_SRP_File', { DataKey: dataKey }).subscribe((response) => {
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    addRow() {
        this.sRPDetailList.push({
            DataKey: '',
            Years: '',
            Term: '',
            SerialNo: '',
            StdNo: '',
            StdName: '',
            IssueDate: '',
            ApplyDate: '',
            ApproveDate: '',
            TypeID: '',
            Times: 1,
            SugCode: '',
            Reason: '',
            Means: '',
            Status: '',
            ID: '',
            SignInfo:undefined,
            Checked: false,
        });
    }

    deleteRow() {
        this.sRPDetailList.pop();
    }
}
