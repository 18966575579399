import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { parseDate } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { GetSELParametersPublicList } from 'src/app/_Models/AD/ADSEL/ADSEL0105/getSELParametersPublicList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0105',
    templateUrl: './ADSEL0105.component.html',
    styleUrls: ['./ADSEL0105.component.css']
})
export class ADSEL0105Component implements OnInit {
    baseUrl = environment.apiUrl;
    bsConfig = environment.bsDatePickerConfig;
    selectType = '';
    selectIndex = 0;
    modalRef?: BsModalRef;
    sELParametersPublicList!: GetSELParametersPublicList[];
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    dataArray: Array<string> = [];
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private modalService: BsModalService, private localeService: BsLocaleService,) { }

    ngOnInit(): void {
        this.getSELParametersPublicList();
        this.localeService.use('zh-cn');
        this.getEduList();
        this.getDeptList();
    }

    getSELParametersPublicList() {
        this.http.get<any>(this.baseUrl + "ACAD/SEL/ADSEL0105/Get_SEL_ParametersPublic_List").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELParametersPublicList = response;

            console.log(this.sELParametersPublicList)
        })
    }

    getSELParametersPublicSave() {

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0105/Get_SEL_ParametersPublic_Save", this.sELParametersPublicList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }

    getEduList() {
        this.systemService.getEduList('1', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        });
    }

    getDeptList() {
        this.systemService.getDeptList('', '1', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    openModal(template: TemplateRef<any>, type: string, index: number) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };

        this.selectType = type;

        this.selectIndex = index;

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setArray(code: string, event: EventTarget | null) {

        let input = <HTMLInputElement>event;
        let index = this.dataArray.indexOf(code);
        if (input.checked && index == -1) {
            this.dataArray.push(code);
        }
        else if (!input.checked && index != -1) {
            this.dataArray.splice(index, 1);
        }
        else {
            return;
        }
    }

    setData() {
        let data = '';
        this.dataArray.forEach(element => {
            data += element + ','
        });

        this.sELParametersPublicList[this.selectIndex].TextValue = data.substring(0, data.length - 1);
        this.dataArray = [];
        this.modalRef?.hide();
    }

    checkData(code: string): boolean {
        let array = this.sELParametersPublicList[this.selectIndex].TextValue.split(',');
        return array.includes(code);
    }

}
