import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetGRDTimeSave } from 'src/app/_Models/AD/ADGRD/ADGRD0102/getGRDTimeSave';
import { WithOtherData } from 'src/app/_Models/AD/ADGRD/ADGRD0102/withOtherData';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetCurDropdownListInput } from 'src/app/_Models/getCurDropdownListInput';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADGRD0102',
    templateUrl: './ADGRD0102.component.html',
    styleUrls: ['./ADGRD0102.component.css']
})
export class ADGRD0102Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    bsConfig = environment.bsDatePickerConfig;
    fromTime!: Date | undefined;
    toTime!: Date | undefined;
    thisYear: string='';
    thisTerm:string='';
    DataIndex: number=-1;
    gRDTimeSave: GetGRDTimeSave = {
        Years: '',
        Term: '',
        Type: '',
        All: {
            DataKey: '',
            StartDateTime: '',
            EndDateTime: ''
        },
        Edu: {
            DataKey: '',
            EduNo: '',
            EduName: '',
            ClassType: '',
            StartDateTime: '',
            EndDateTime: ''
        },
        Grade: {
            DataKey: '',
            EduNo: '',
            EduName: '',
            Grade: '',
            StartDateTime: '',
            EndDateTime: ''
        },
        Class: {
            DataKey: '',
            Class: '',
            ClassName: '',
            StartDateTime: '',
            EndDateTime: ''
        },
        Cos: {
            DataKey: '',
            OpClass: '',
            OpClassName: '',
            Serial: '',
            CosName: '',
            TchNo: '',
            TchName: '',
            StartDateTime: '',
            EndDateTime: ''
        }
    };
    gRDTimeCosList!: GetSelectList[];
    withOtherDataList!: WithOtherData[];
    withOtherData: WithOtherData = {
        DataKey: '',
        EduNo: '',
        EduName: '',
        Class: '',
        ClassName: '',
        ClassType: '',
        ClassTypeID: 'C',
        OpClass: '',
        OpClassName: '',
        Serial: '',
        CosName: '',
        TchNo: '',
        TchName: '',
        Grade: '1',
        StartDateTime: '',
        StartDate: '',
        EndDateTime: '',
        EndDate: '',
    };

    curDropdownListInput!: GetCurDropdownListInput;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    tabIndex: number = 0;

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private datePipe: DatePipe,
        private systemService: SystemService) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.fromTime = void 0;
        this.toTime = void 0;
    }

    getGRDTimeCosList(Years: string, Term: string, Class: string) {
        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0102/Get_GRD_Time_CosList", {
            Years: Years,
            Term: Term,
            Class: Class
        }).subscribe((response) => {
            this.gRDTimeCosList = response;
        })
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
                this.getGRDTimeList(this.Years[0], this.term[0].itemValue, this.tabIndex);
                this.getEduList(this.Years[0], this.term[0].itemValue);
            }
        });
    }

    getEduList(Years: string, Term: string) {
        this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.eduList = response;
        })
    }

    getDeptList(edu: string, Years: string, Term: string) {
        this.systemService.getDeptList(edu, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = response;

        });
    }

    getClassList(edu: string, dept: string, Years: string, Term: string) {
        this.systemService.getClassList(edu, dept, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classList = response;
        })

    }

    getGRDTimeList(Years: string, Term: string, index: number) {
        if(this.tabIndex != index){
            this.DataIndex=-1;
        }

        this.thisYear=Years;
        this.thisTerm=Term;
        this.tabIndex = index;

        let Type = '';

        switch (index) {
            case 0:
                Type = 'All';
                break;
            case 1:
                Type = 'Edu';
                break;
            case 2:
                Type = 'Grade'
                break;
            case 3:
                Type = 'Class'
                break;
            case 4:
                Type = 'Cos'
                break;
        }

        this.withOtherData = {
            DataKey: '',
            EduNo: '',
            EduName: '',
            Class: '',
            ClassName: '',
            ClassType: '',
            ClassTypeID: 'C',
            OpClass: '',
            OpClassName: '',
            Serial: '',
            CosName: '',
            TchNo: '',
            TchName: '',
            Grade: '1',
            StartDateTime: '',
            StartDate: '',
            EndDateTime: '',
            EndDate: '',
        };

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0102/Get_GRD_Time_List", {
            Years: Years,
            Term: Term,
            Type: Type
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.fromTime = undefined;
                this.toTime = undefined;
                return;
            }

            if (Type == 'All') {
                const fromtime = new Date();
                const totime = new Date();
                fromtime.setHours(
                    parseInt(response.StartDateTime.split('T')[1].split(':')[0]),
                    parseInt(response.StartDateTime.split('T')[1].split(':')[1]),
                    parseInt(response.StartDateTime.split('T')[1].split(':')[2])
                );
                totime.setHours(
                    parseInt(response.EndDateTime.split('T')[1].split(':')[0]),
                    parseInt(response.EndDateTime.split('T')[1].split(':')[1]),
                    parseInt(response.EndDateTime.split('T')[1].split(':')[2])
                );
                response.StartDate = response.StartDateTime.split('T')[0];
                response.EndDate = response.EndDateTime.split('T')[0];
                this.fromTime = fromtime;
                this.toTime = totime;
                this.withOtherData = response;
            }
            else {
                this.withOtherDataList = response;

                if(this.DataIndex!=-1){
                    this.getData(this.DataIndex);
                }
            }
        });
    }

    getData(index: number) {
        this.DataIndex=index;
        this.withOtherData =this.withOtherDataList[index];
        const fromtime = new Date();
        const totime = new Date();

        if(this.withOtherDataList[index].ClassType!=undefined){
            this.withOtherData.ClassTypeID = this.withOtherDataList[index].ClassType.substring(0,1);
        }

        fromtime.setHours(
            parseInt(this.withOtherDataList[index].StartDateTime.split('T')[1].split(':')[0]),
            parseInt(this.withOtherDataList[index].StartDateTime.split('T')[1].split(':')[1]),
            parseInt(this.withOtherDataList[index].StartDateTime.split('T')[1].split(':')[2])
        );
        totime.setHours(
            parseInt(this.withOtherDataList[index].EndDateTime.split('T')[1].split(':')[0]),
            parseInt(this.withOtherDataList[index].EndDateTime.split('T')[1].split(':')[1]),
            parseInt(this.withOtherDataList[index].EndDateTime.split('T')[1].split(':')[2])
        );
        this.withOtherData.StartDate = this.withOtherData.StartDateTime.split('T')[0];
        this.withOtherData.EndDate = this.withOtherData.EndDateTime.split('T')[0];
        this.fromTime = fromtime;
        this.toTime = totime;
    }

    clearData(){
        this.withOtherData = {
            DataKey: '',
            EduNo: '',
            EduName: '',
            Class: '',
            ClassName: '',
            ClassType: '',
            ClassTypeID: 'C',
            OpClass: '',
            OpClassName: '',
            Serial: '',
            CosName: '',
            TchNo: '',
            TchName: '',
            Grade: '1',
            StartDateTime: '',
            StartDate: '',
            EndDateTime: '',
            EndDate: '',
        };
    }

    deleteData(){
        if (this.withOtherData.DataKey == '') {
            this.toastr.info("請選擇刪除項目");
            return;
        }
        Swal.fire({
            title: "確定要刪除？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0102/Get_GRD_Time_Delete", { DataKey: this.withOtherData.DataKey}).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("已刪除");
                    this.DataIndex=-1;
                    this.getGRDTimeList(this.thisYear,this.thisTerm,this.tabIndex);
                })
            }
            else {
                this.toastr.info("動作已取消");
            }
        })
    }

    getGRDTimeSave(Years: string, Term: string, type: string) {
        this.withOtherData.StartDateTime=this.datePipe.transform(this.withOtherData.StartDate, 'yyyy-MM-dd')!.toString();
        this.withOtherData.EndDateTime=this.datePipe.transform(this.withOtherData.EndDate, 'yyyy-MM-dd')!.toString();
        this.gRDTimeSave.Years = Years;
        this.gRDTimeSave.Term = Term;
        this.gRDTimeSave.Type = type;
        this.gRDTimeSave.All = null;
        this.gRDTimeSave.Edu = null;
        this.gRDTimeSave.Class = null;
        this.gRDTimeSave.Cos = null;
        this.gRDTimeSave.Grade = null;
        let StartTime=this.datePipe.transform(this.fromTime, 'HH:mm:00');
        let EndTime=this.datePipe.transform(this.toTime, 'HH:mm:59');
        let DataKey=(this.withOtherData.DataKey==null)?'':this.withOtherData.DataKey;

        switch (type) {
            case 'All':
                this.gRDTimeSave.All={
                    DataKey: DataKey,
                    StartDateTime: this.withOtherData.StartDateTime + 'T' + StartTime,
                    EndDateTime: this.withOtherData.EndDateTime + 'T' + EndTime
                };
                break;
            case 'Edu':
                this.gRDTimeSave.Edu={
                    DataKey: DataKey,
                    EduNo: this.withOtherData.EduNo,
                    EduName: this.withOtherData.EduName,
                    ClassType: this.withOtherData.ClassTypeID,
                    StartDateTime: this.withOtherData.StartDateTime + 'T' + StartTime,
                    EndDateTime: this.withOtherData.EndDateTime + 'T' + EndTime
                };
                break;
            case 'Grade':
                this.gRDTimeSave.Grade={
                    DataKey: DataKey,
                    EduNo: this.withOtherData.EduNo,
                    EduName: this.withOtherData.EduName,
                    Grade: this.withOtherData.Grade,
                    StartDateTime: this.withOtherData.StartDateTime + 'T' + StartTime,
                    EndDateTime: this.withOtherData.EndDateTime + 'T' + EndTime
                };
                break;
            case 'Class':
                this.gRDTimeSave.Class={
                    DataKey: DataKey,
                    Class: this.withOtherData.Class,
                    ClassName: this.withOtherData.ClassName,
                    StartDateTime: this.withOtherData.StartDateTime + 'T' + StartTime,
                    EndDateTime: this.withOtherData.EndDateTime + 'T' + EndTime
                };
                break;
            case 'Cos':
                this.gRDTimeSave.Cos={
                    DataKey: DataKey,
                    OpClass: this.withOtherData.Class,
                    OpClassName: this.withOtherData.OpClassName,
                    Serial: this.withOtherData.Serial,
                    CosName: this.withOtherData.CosName,
                    TchNo: this.withOtherData.TchNo,
                    TchName: this.withOtherData.TchName,
                    StartDateTime: this.withOtherData.StartDateTime + 'T' + StartTime,
                    EndDateTime: this.withOtherData.EndDateTime + 'T' + EndTime
                };
                break;
        }

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0102/Get_GRD_Time_Save", this.gRDTimeSave).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('儲存成功');
            this.getGRDTimeList(Years,Term,this.tabIndex);
        })
    }
}
