import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUR0505',
    templateUrl: './ADCUR0505.component.html',
    styleUrls: ['./ADCUR0505.component.css']
})
export class ADCUR0505Component implements OnInit {
    baseUrl = environment.apiUrl;
    ngForm = new UntypedFormGroup({
        UsePercent: new UntypedFormControl(false),
        PresidentDefaultHours: new UntypedFormControl(0, [Validators.required]),
        FullTimeLimit: new UntypedFormControl(0, [Validators.required]),
        PartTimeLimit: new UntypedFormControl(0, [Validators.required]),
        OtherLimit: new UntypedFormControl(0, [Validators.required]),
        UseAllEng: new UntypedFormControl(false),
        EngPlus: new UntypedFormControl(0, [Validators.required]),
        UseDigital: new UntypedFormControl(false),
        DigitalPlus: new UntypedFormControl(0, [Validators.required]),
        UpdateUser: new UntypedFormControl('', [Validators.required]),
        UpdateTime: new UntypedFormControl('', [Validators.required])
    })
    constructor(private http: HttpClient, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.getCURTchMoneyOverLimitList();
    }

    getCURTchMoneyOverLimitList() {
        this.http.get<any>(this.baseUrl + "ACAD/CUR/ADCUR0505/Get_CUR_TchMoney_OverLimit_List").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.ngForm.setValue(response);
        })
    }

    getCURTchMoneyOverLimitModify() {
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0505/Get_CUR_TchMoney_OverLimit_Modify", this.ngForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.getCURTchMoneyOverLimitList();
        })
    }

}
