import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ADSEL300ISU01Input,ADSEL300ISU01List,ADSEL300ISU01StdList } from 'src/app/_Models/AD/ADSEL/ADSEL0300ISU01/getSELISUInterDept';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSEL0300ISU01',
    templateUrl: './ADSEL0300ISU01.component.html',
    styleUrls: ['./ADSEL0300ISU01.component.css']
})
export class ADSEL0300ISU01Component implements OnInit {
    baseUrl = environment.apiUrl;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    deptList!: GetDeptList[];
    selKindList!: GetSelectList[];
    CosList!: ADSEL300ISU01List[];
    StdList!:ADSEL300ISU01StdList[];
    selectedCos!:ADSEL300ISU01List;
    selectSelkind!:string;
    bsConfig = environment.bsDatePickerConfig;
    modalRef?: BsModalRef;
    model = {
        SchName:'',
        Years: '',
        Term: '',
    }
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
        Term: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
        DeptNo: new UntypedFormControl('', Validators.required)
    });

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private modalService: BsModalService,
        private timeService: TimeService
    ) { }

    ngOnInit(): void {
        this.getYears();
        this.timeService.currentTime$.subscribe((response) => {
            this.model.Years = response.find(n => n.ItemID == "NowYear")?.ItemValue || '';
            this.model.Term = response.find(n => n.ItemID == "NowTerm")?.ItemValue || '';
            this.ngFormInput.get("Years")?.setValue(response.find(n => n.ItemID == "NowYear")?.ItemValue || '');
            this.ngFormInput.get("Term")?.setValue(response.find(n => n.ItemID == "NowTerm")?.ItemValue || '');
        });
        this.getSchoolInfo();
        this.getDeptList();
        this.getSelKind();
        this.selectSelkind="";
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    // 取得學校各項資訊
    getSchoolInfo() {
        this.systemService.getSchoolInfo().subscribe((response) => {
            this.model.SchName = response.Sch_Name || '';
        });
    }

    getSelKind() {
        this.systemService.getSelKind().subscribe((response) => {
            this.selKindList = response;
        })
    }

    getDeptList() {
        this.systemService.getDeptList('', '3', this.ngFormInput.get("Years")?.value, this.ngFormInput.get("Term")?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getCosList(){
        this.CosList = [];
        this.ngFormInput.get("Years")?.setValue(this.model.Years);
        this.ngFormInput.get("Term")?.setValue(this.model.Term);

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0300ISU01/Get_ADSEL300ISU01_List", {"Years":this.ngFormInput.get("Years")?.value,"Term":this.ngFormInput.get("Term")?.value,"DeptNo":this.ngFormInput.get("DeptNo")?.value}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.CosList = response;
        })
    }

    CosItemValue(thisSelect:any){
        this.selectSelkind=thisSelect.value;
    }

    CosItemChange(thisItem:ADSEL300ISU01List,thisSelect:any){
        if(thisItem.Agree=="Y" && thisItem.AgreeSelkind==""){
            this.toastr.success('請點選系際選修別!');
            thisSelect.selectedIndex=0;
            thisItem.Agree="";
            return;
        }

        var msg="確定 ["+((thisItem.Agree=="Y")?"承認":((thisItem.Agree=="N")?"不承認":"取消設定"));

        msg+="] 該門課程，系際選修別為:"+thisItem.AgreeSelkind+"？";

        Swal.fire({
            title: msg,
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                let thisCosList!:ADSEL300ISU01List[];
                thisCosList=[];
                thisCosList.push(thisItem);

                this.http.post<any>(this.baseUrl + 'ACAD/SEL/ADSEL0300ISU01/Get_ADSEL300ISU01_List_Save', thisCosList).subscribe(response => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success('設定成功');
                    this.getCosList();
                });
            } else {
                this.toastr.info('動作已取消');
                thisSelect.selectedIndex=(this.selectSelkind=="Y")?1:((this.selectSelkind=="N")?2:0);
                thisItem.Agree=this.selectSelkind;
            }
        })
    }

    CosItemSave(thisItem:ADSEL300ISU01List){
        if(thisItem.Agree==""){
            return;
        }

        let thisCosList!:ADSEL300ISU01List[];
        thisCosList=[];
        thisCosList.push(thisItem);

        this.http.post<any>(this.baseUrl + 'ACAD/SEL/ADSEL0300ISU01/Get_ADSEL300ISU01_List_Save', thisCosList).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('儲存成功');
        });
    }

    openModel(template: TemplateRef<any>, thisItem:ADSEL300ISU01List) {
        this.StdList=[];
        this.selectedCos=thisItem;
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0300ISU01/Get_ADSEL300ISU01_Std_List", thisItem).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.StdList=response;
        })

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getStdList(thisItem:ADSEL300ISU01List){
        this.StdList = [];
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0300ISU01/Get_ADSEL300ISU01_Std_List", thisItem).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.StdList = response;
        })
    }

    StdItemChange(thisItem:ADSEL300ISU01StdList){
        if(this.selectedCos.Agree==""){
            this.toastr.success('尚未設定系際承認!');
            return;
        }

        let thisAgree=thisItem.Agree;
        let msg="確定設定此學生該門課 ["+((thisAgree==true)?"承認":"不承認");

        msg+="]？";

        Swal.fire({
            title: msg,
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                let thisStdList!:ADSEL300ISU01StdList[];
                thisStdList=[];
                thisStdList.push(thisItem);

                this.http.post<any>(this.baseUrl + 'ACAD/SEL/ADSEL0300ISU01/Get_ADSEL300ISU01_Std_List_Save', thisStdList).subscribe(response => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success('設定成功');
                });
            } else {
                this.toastr.info('動作已取消');
            }
        })
    }
}
