<div class="container">
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>簽核角色名稱</th>
                <th>人員姓名</th>
                <th>排序</th>
                <th>是否限制審核期限</th>
                <th>編輯</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><button type="button" class="other-Button-gray float-end me-3"
                        (click)="openModal(column)">編輯</button></td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #column>
    <div class="modal-header">
        <h4 class="modal-title pull-left">修改簽核人員</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-year-term-input></app-year-term-input>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">日期</span>
                    </div>
                    <input type="text" placeholder="--請選擇日期--" class="form-control shadow-none" bsDaterangepicker
                        #dpr="bsDaterangepicker" formControlName="Date">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">類別</span>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                            value="option1">
                        <label class="form-check-label" for="inlineRadio1">放假</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                            value="option2">
                        <label class="form-check-label" for="inlineRadio2">上課</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <div class="col-md-2"> <span class="input-group-text" id="basic-addon1">節次</span></div>
                    <div class="col-md-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                value="option2">
                            <label class="form-check-label" for="inlineRadio2">取消</label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                value="option1">
                            <label class="form-check-label" for="inlineRadio1">全選</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox1">
                                <label class="form-check-label pointer" for="inlineCheckbox1">第1節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox2">
                                <label class="form-check-label pointer" for="inlineCheckbox2">第2節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox1">
                                <label class="form-check-label pointer" for="inlineCheckbox1">第3節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox2">
                                <label class="form-check-label pointer" for="inlineCheckbox2">第4節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox1">
                                <label class="form-check-label pointer" for="inlineCheckbox1">第5節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox2">
                                <label class="form-check-label pointer" for="inlineCheckbox2">第6節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox1">
                                <label class="form-check-label pointer" for="inlineCheckbox1">第7節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox2">
                                <label class="form-check-label pointer" for="inlineCheckbox2">第8節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox1">
                                <label class="form-check-label pointer" for="inlineCheckbox1">第9節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox2">
                                <label class="form-check-label pointer" for="inlineCheckbox2">第10節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox1">
                                <label class="form-check-label pointer" for="inlineCheckbox1">第11節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox2">
                                <label class="form-check-label pointer" for="inlineCheckbox2">第12節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox1">
                                <label class="form-check-label pointer" for="inlineCheckbox1">第13節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox2">
                                <label class="form-check-label pointer" for="inlineCheckbox2">第14節</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox2">
                                <label class="form-check-label pointer" for="inlineCheckbox2">第15節</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <span class="input-group-text" id="basic-addon1">內容說明</span>
                <textarea class="form-control shadow-none" placeholder="填寫於此" id="floatingTextarea"
                    appTextareaAutoresize></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
            </div>
            <div>
                <button type="button" class="other-Button-gray float-end me-3" (click)="modalRef?.hide()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>