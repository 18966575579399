<div class="container">
    <alert type="danger">
        <strong>注意！</strong>列印之學生必須完成畢業證號與畢業日期設定。
    </alert>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">畢業學年</span>
                    <select class="form-select shadow-none" [(ngModel)]="exportReportInput.Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">畢業學期</span>
                    <select class="form-select shadow-none" [(ngModel)]="exportReportInput.Term">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #eduNo (change)="getDeptList(eduNo.value)" [(ngModel)]="exportReportInput.EduNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" #deptNo (change)="getClassList(eduNo.value, deptNo.value)" [(ngModel)]="exportReportInput.DeptNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">班級</span>
                    <select class="form-select shadow-none" [(ngModel)]="exportReportInput.Class">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學號</span>
                    <input type="text" class="form-control shadow-none" placeholder="若需輸入多個，請以逗號隔開。" [(ngModel)]="exportReportInput.StdNo">
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">證書樣式</span>
                    <select class="form-select shadow-none" [(ngModel)]="exportReportInput.ReportType">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of diplomaList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <button class="other-Button-gray" (click)="exportReportADSTD0704()">匯出</button>
        </div>
    </div>
</div>
