<div class="container-fluid" style="margin-bottom: 100px;">
    <h4>{{'目前學年期：'|translate}}{{getOpClassListInput.Years}}{{'學年第'|translate}}{{getOpClassListInput.Term}}{{'學期'|translate}}</h4>
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'開課班級'|translate}}</span>
                </div>
                <select class="form-select shadow-none" (change)="getCosList($event)">
                    <option value="" disabled selected>{{'--請選擇開課班級--'|translate}}</option>
                    <option *ngFor="let item of opClassList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'授課科目'|translate}}</span>
                </div>
                <select class="form-select shadow-none" #cos>
                    <option value="" disabled selected>{{'--請選擇授課科目--'|translate}}</option>
                    <option *ngFor="let item of cosList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <button class="search-btn" (click)="getListAlert(cos.value)">{{'查詢'|translate}}</button>
    <hr>
    <button class="other-Button-gray mb-3 me-3" style="width: 150px;" (click)="setAlertScoreAll()"
        id="AlertScore">{{'期中預警全選'|translate}}</button>
    <button class="other-Button-gray mb-3" style="width: 150px;" (click)="setAlertLeaveAll()"
        id="AlertLeave">{{'點名預警全選'|translate}}</button>
    <table class="table table-hover table-scripted text-center mb-3 table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'學號'|translate}}</th>
                <th>{{'姓名'|translate}}</th>
                <th>{{'班級'|translate}}</th>
                <th>{{'科目'|translate}}</th>
                <th>{{'成績'|translate}}</th>
                <th>{{'曠課'|translate}}</th>
                <th>{{'遲到'|translate}}</th>
                <th>{{'設定期中預警'|translate}}</th>
                <th>{{'設定點名預警'|translate}}</th>
                <th>{{'備註'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of alertList; let i = index">
                <td data-th="學號">{{item.Std_No}}</td>
                <td data-th="姓名">{{item.Std_Name}}</td>
                <td data-th="班級">{{item.Class_Name}}</td>
                <td data-th="科目">{{item.Cos_Name}}</td>
                <td data-th="成績">{{item.Score}}</td>
                <td data-th="曠課">{{item.ABS_A}}</td>
                <td data-th="遲到">{{item.ABS_B}}</td>
                <td data-th="設定期中預警">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="Alert_Score{{i}}"
                            [checked]="item.Alert_Score =='Y'" (click)="setAlertScore(i)">
                        <label class="custom-control-label" for="Alert_Score{{i}}"></label>
                    </div>
                </td>
                <td data-th="設定點名預警">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="Alert_Leave{{i}}"
                            [checked]="item.Alert_Leave =='Y'" (click)="setAlertLeave(i)">
                        <label class="custom-control-label" for="Alert_Leave{{i}}"></label>
                    </div>
                </td>
                <td data-th="備註"><input class="form-control shadow-none" type="text" maxlength="100" placeholder="限100字"
                        [(ngModel)]="item.Alert_Message" id="Alert_Message"></td>
            </tr>
        </tbody>
    </table>
    <button class="btn btn-outline-primary btn-block mb-3" *ngIf="alertList" (click)="saveData()">{{'儲存'|translate}}</button>
</div>