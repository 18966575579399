<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getSACCompute()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getEduList()" formControlName="Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getEduList()" formControlName="Term">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">登錄日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                        formControlName="Deadline">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">統計項目</span>
                    </div>
                    <select class="form-select shadow-none" #itemType (change)="getEduList()"
                        formControlName="FieldType">
                        <option value="1">學制</option>
                        <option value="2">系所</option>
                        <option value="3">班級</option>
                        <option value="4">學號</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" *ngIf="itemType.value != '4'">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制(起)</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getDeptList('start')" formControlName="StartEdu">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduListStart" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" *ngIf="itemType.value != '4'">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制(迄)</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getDeptList('end')" formControlName="EndEdu">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduListEnd" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" *ngIf="itemType.value == '3' || itemType.value == '2'">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所(起)</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getClassList('start')"
                        formControlName="StarttDept">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptListStart" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" *ngIf="itemType.value == '3' || itemType.value == '2'">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所(迄)</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getClassList('end')" formControlName="EndDept">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptListEnd" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" *ngIf="itemType.value == '3'">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班別(起)</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="StartClass">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classListStart" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" *ngIf="itemType.value == '3'">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班別(迄)</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="EndClass">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classListEnd" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" *ngIf="itemType.value == '4'">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號(起)</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StartStdNo">
                </div>
            </div>
            <div class="col-md-6" *ngIf="itemType.value == '4'">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號(迄)</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="EndStdNo">
                </div>
            </div>
            <div class="col-md-12 text-center">
                <button class="other-Button-gray">執行</button>
            </div>
        </div>
    </form>

</div>