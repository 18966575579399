import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { environment } from 'src/assets/environments/environment';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { GetStdRestListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0301/getStdRestListInput';
import { GetData } from 'src/app/_Models/AD/ADSTD/ADSTD0815/getdata';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
@Component({
    selector: 'app-ADSTD0815',
    templateUrl: './ADSTD0815.component.html',
    styleUrls: ['./ADSTD0815.component.css']
})
export class ADSTD0815Component implements OnInit {

    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    getdata!:GetData[];
  initialData = new InitialData(this.http);
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
       this.getYears();
       this.getEduList();
    }

    getYears(){
        this.initialData.getYears().subscribe((response: string[])=>{
          if(response){
            this.Years = response;
          }
        });
      }
      getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response:any) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, (error: { error: string | undefined; }) => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
      this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response:any) => {
          if (response.Info == false) {
              this.toastr.warning(response.Message);
              return;
          }
          this.deptList = response;
      }, (error: { error: string | undefined; }) => this.toastr.error(error.error));
  }
    export(Year:string,Term:string,EduNo:string,DeptNo:string){
      this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD08_15/Get_ADSTD08_15", {Year:Year,Term:Term,AcadNo:'',EduNo:EduNo,DeptNo:DeptNo}).subscribe((response:any) => {
        if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
        }

        if (response.FileName == '') {
            this.toastr.warning("查無資料");
            return;
        }
        let url = window.URL.createObjectURL(response.blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = response.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    });
    }
}
