import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetStdContrastList } from 'src/app/_Models/AD/ADSTD/ADSTD/getStdContrastList';
import { GetStdContrastListInput } from 'src/app/_Models/AD/ADSTD/ADSTD/getStdContrastListInput';
import { GetStdContrastUnSetList } from 'src/app/_Models/AD/ADSTD/ADSTD/getStdContrastUnSetList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSTD0601',
    templateUrl: './ADSTD0601.component.html',
    styleUrls: ['./ADSTD0601.component.css']
})
export class ADSTD0601Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    stdContrastList!: GetStdContrastList[];
    dataKeyArray: Array<string> = [];
    stdContrastListInput: GetStdContrastListInput = {
        Years: '',
        Term: '',
        EduNo: '',
        DeptNo: '',
        TeamNo: '',
        Class: '',
        StdNoFrom: '',
        StdNoTo: ''
    };
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    gEduList!: GetEduList[];
    gDptList!: GetDeptList[];
    gClassList!: GetClassList[];
    stdTeamList!: GetTeamList[];
    itemLeft: GetStdContrastUnSetList[] = [];
    itemRight: GetStdContrastUnSetList[] = [];
    modalRef!: BsModalRef;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,
        private modalService: BsModalService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
            this.gEduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, isModal: boolean) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
            if (isModal) this.gDptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList(edu: string, dept: string, isModal: boolean) {
        this.systemService.getClassList(edu, dept, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
            if (isModal) this.gClassList = response;
        }, error => this.toastr.error(error.error))
    }

    getTeamList(dept: string) {
        this.systemService.getTeamList('3', '', '', dept, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdTeamList = response;
        });
    }

    getStdContrastList() {

        if (this.stdContrastListInput.Years == '' || this.stdContrastListInput.Term == '') {
            this.toastr.info("請選擇學年學期");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_01/Get_Std_Contrast_List", this.stdContrastListInput).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdContrastList = []
                return;
            }
            this.stdContrastList = response;
            this.stdContrastList.forEach(element => {
                element.checked = false;
            });
        }, error => this.toastr.error(error.error));
    }

    setDatakey(type: string, dataKey: string, event: any) {
        if (!this.stdContrastList) {
            return;
        }

        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.stdContrastList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.stdContrastList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.stdContrastList.forEach(element => {
                    element.checked = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

    getStdContrastProcess(Years: string, Term: string) {

        if (Years == '' || Term == '') {
            this.toastr.info("請先選擇學年學期");
            return;
        };

        if (this.itemRight.length == 0) {
            this.toastr.info("請先選擇學生");
            return;
        };

        let dataKey = '';
        this.itemRight.forEach(element => {
            dataKey = dataKey + element.DataKey + ',';
        });
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_01/Get_Std_Contrast_Process", {
            Years: Years,
            Term: Term,
            StdList: dataKey.substring(0, dataKey.length - 1),
            ScoreCheck: '預設全及格'
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("審核完畢");
            this.dataKeyArray = [];
            this.getStdContrastList()
            this.modalService.hide();
        })
    }

    exportReportContrastFile() {

        if (this.dataKeyArray.length == 0) {
            this.toastr.info("請先選擇學生");
            return;
        };
        let dataKey = '';
        this.dataKeyArray.forEach(element => {
            dataKey = dataKey + element + ',';
        });

        this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD06_01/Export_Report_ContrastFile", { DataKey: dataKey.substring(0, dataKey.length - 1) })
            .subscribe((response) => {
                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = response.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                this.dataKeyArray = [];
            }, error => this.toastr.error(error.error));
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setItems(type: string, event: any) {
        var result = [];
        var options = event && event.options;
        var opt;
        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];
            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        if (type == 'goRight') {
            result.forEach(element => {
                this.itemRight.push(this.itemLeft[parseInt(element)]);
            });
            this.itemLeft = this.itemLeft.filter(x => !this.itemRight.includes(x));
        }
        else if (type == 'goLeft') {
            result.forEach(element => {
                this.itemLeft.push(this.itemRight[parseInt(element)]);
            });
            this.itemRight = this.itemRight.filter(x => !this.itemLeft.includes(x));
        }
        else if (type == 'goRightAll') {
            let tempArray = this.itemLeft.filter(x => !this.itemRight.includes(x));
            tempArray.forEach(element => {
                this.itemRight.push(element);
            });
            this.itemLeft = [];
        }
        else {
            let tempArray = this.itemRight.filter(x => !this.itemLeft.includes(x));
            tempArray.forEach(element => {
                this.itemLeft.push(element);
            });
            this.itemRight = [];
        }
    }

    getStdContrastUnSetList(DeptNo: string, Class: string) {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_01/Get_Std_Contrast_UnSetList", {
            DeptNo: DeptNo,
            Class: Class
        }).subscribe((response) => {
            this.itemLeft = response;
        }, error => this.toastr.error(error.error))
    }
}
