
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADGRD0301',
  templateUrl: './ADGRD0301.component.html',
  styleUrls: ['./ADGRD0301.component.css']
})
export class ADGRD0301Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}


