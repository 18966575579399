<div class="container">
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">收費科目</span>
                </div>
                <input type="text" class="form-control shadow-none" #FeeItemName>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">主辦單位</span>
                </div>
                <input type="text" class="form-control shadow-none" #UnitName>
            </div>
        </div>
        <div class="col-md-4">
            <button class="search-btn float-start me-3"
                (click)="getTFSFeeItemList(FeeItemName.value, UnitName.value)">查詢</button>
            <button class="other-Button-gray float-start me-3" (click)="saveData()">修改</button>
            <button class="other-Button-gray float-start me-3" (click)="resetData()">清除</button>
        </div>
    </div>

    <div class="row">
        <div class="col-md">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;">代碼</th>
                        <th>收費科目名稱</th>
                        <th>主辦單位</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of itemList; let i = index" class="pointer"
                        (click)="getTFSFeeItemDetail(i, item.DataKey)">
                        <td style="width: 60px;">{{item.FeeItemNo}}</td>
                        <td>{{item.FeeItemName}}</td>
                        <td>{{item.UnitName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md">
            <div style="width: 70%; margin: auto;" class="mt-5">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">收費科目代碼</span>
                    </div>
                    <input type="text" readonly class="form-control shadow-none" [(ngModel)]="itemDetail.FeeItemNo">
                </div>
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">收費科目名稱</span>
                    </div>
                    <input type="text" readonly class="form-control shadow-none" [(ngModel)]="itemDetail.FeeItemName">
                </div>
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">主辦單位</span>
                    </div>
                    <input type="text" readonly class="form-control shadow-none" [(ngModel)]="itemDetail.UnitName">
                </div>
                <label>特殊註記</label><br />
                <div class="form-check form-check-inline">
                    <input class="form-check-input pointer" type="checkbox" id="inlineCheckbox1"
                        [checked]="itemDetail.AFMark == 'Y'" (click)="setCheckbox('AFMark', $event)">
                    <label class="form-check-label pointer" for="inlineCheckbox1">可就貸</label>
                </div>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input pointer" type="checkbox" id="inlineCheckbox2"
                        [checked]="itemDetail.PEMark == 'Y'" (click)="setCheckbox('PEMark', $event)">
                    <label class="form-check-label pointer" for="inlineCheckbox2">減免</label>
                </div>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input pointer" type="checkbox" id="inlineCheckbox3"
                        [checked]="itemDetail.SLMark == 'Y'" (click)="setCheckbox('SLMark', $event)">
                    <label class="form-check-label pointer" for="inlineCheckbox3">公費減免</label>
                </div>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input pointer" type="checkbox" id="inlineCheckbox4"
                        [checked]="itemDetail.TEMark == 'Y'" (click)="setCheckbox('TEMark', $event)">
                    <label class="form-check-label pointer" for="inlineCheckbox4">學雜費申報</label>
                </div>
                <div class="form-check form-check-inline ">
                    <input class="form-check-input pointer" type="checkbox" id="inlineCheckbox5"
                        [checked]="itemDetail.TFMark == 'Y'" (click)="setCheckbox('TFMark', $event)">
                    <label class="form-check-label pointer" for="inlineCheckbox5">住宿費申報</label>
                </div>
                <div class="input-group flex-nowrap mb-3 mt-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">修改者</span>
                    </div>
                    <input type="text" readonly class="form-control shadow-none" [(ngModel)]="itemDetail.UpdateUser">
                </div>
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">修改時間</span>
                    </div>
                    <input type="text" readonly class="form-control shadow-none" [(ngModel)]="itemDetail.UpdateTime">
                </div>
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">修改IP</span>
                    </div>
                    <input type="text" readonly class="form-control shadow-none" [(ngModel)]="itemDetail.UpdateIP">
                </div>
            </div>
        </div>
    </div>
</div>