import { Component, Input, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { ConvertToFormControl } from 'src/app/_SharedFunctions/convertToFormControl';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-input-select',
    templateUrl: './input-select.component.html',
    styleUrls: ['./input-select.component.css']
})
export class InputSelectComponent implements ControlValueAccessor {
    private ConvertToFormControl = new ConvertToFormControl();
    baseUrl = environment.apiUrl;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    army = require('src/app/_Models/_SelectInput/army.json').army;
    marriage = require('src/app/_Models/_SelectInput/marriage.json').marriage;
    bloodType = require('src/app/_Models/_SelectInput/bloodType.json').bloodType;
    month = require('src/app/_Models/_SelectInput/month.json').month;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    day = require('src/app/_Models/_SelectInput/day.json').day;
    @Input() selectType!: string;
    @Input() years!: string;
    @Input() type!: string;
    @Input() eduNo!: string;
    @Input() deptNo!: string;
    @Input() classNo!: string;
    constructor(@Self() public ngControl: NgControl) {
        switch (this.selectType) {
            case 'Years': break;
            case 'Term': break;
            case 'grade': break;
            case 'army': break;
            case 'marriage': break;
            case 'bloodType': break;
            case 'month': break;
            case 'day': break;
        }
    }
    writeValue(obj: any): void { }
    registerOnChange(fn: any): void { }
    registerOnTouched(fn: any): void { }

    convertToFormControl(absCtrl: AbstractControl | null): UntypedFormControl {
        return this.ConvertToFormControl.convertToFormControl(absCtrl) as UntypedFormControl;
    }

}
