<div class="container">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
    <button class="search-btn mb-3" (click)="getAlertList()">{{'查詢' | translate}}</button>
    <table class="table table-hover table-striped text-center table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'學年' | translate}}</th>
                <th>{{'學期' | translate}}</th>
                <th>{{'班級' | translate}}</th>
                <th>{{'課程代碼' | translate}}</th>
                <th>{{'科目名稱' | translate}}</th>
                <th>{{'期中成績' | translate}}</th>
                <th>{{'點名預警' | translate}}</th>
                <th>{{'成績預警' | translate}}</th>
                <th>{{'曠課次數' | translate}}</th>
                <th>{{'遲到次數' | translate}}</th>
                <th>{{'備註' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td data-th="學年">{{item.Years}}</td>
                <td data-th="學期">{{item.Term}}</td>
                <td data-th="班級">{{item.Class_Name}}</td>
                <td data-th="課程代碼">{{item.Cos_ID}}</td>
                <td data-th="科目名稱">{{item.Cos_Name}}</td>
                <td data-th="期中成績">{{item.Score}}</td>
                <td data-th="點名預警">{{item.Alert_Leave == 'N'?'':'V'}}</td>
                <td data-th="成績預警">{{item.Alert_Score == 'N'?'':'V'}}</td>
                <td data-th="曠課次數">{{item.ABS_A}}</td>
                <td data-th="遲到次數">{{item.ABS_B}}</td>
                <td data-th="備註">{{item.Alert_Message}}</td>
                <td class="td-rwd-hide" data-th=""></td>
            </tr>
        </tbody>
    </table>
</div>