import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { getHours } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ToastrService } from 'ngx-toastr';
import { SaveData } from'src/app/_Models/AD/ADABS/ADABS0101/Data';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADABS0101',
    templateUrl: './ADABS0101.component.html',
    styleUrls: ['./ADABS0101.component.css']
})
export class ADABS0101Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    url = environment.apiUrl;
    initialData = new InitialData(this.http);
    data!:SaveData[]
    savedata:SaveData={
        S_Years: '',
        S_Terms: '',
        Leave_Deadline: 0,
        RollCall_Deadline: 0,
        Leave_Deadline_Time: '',
        date: new Date(""),
        if: false
    };
    ngOnInit(): void {
        this.getYears();
        this.search();
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService) { }

    getYears() {
        this.initialData.getYears().subscribe((response: string[]) => {
            if (response) {
                this.Years = response;
            }
        }, (error: { error: string | undefined; }) => this.toastr.error(error.error));
    }

    search(){
        this.http.get<any>(this.url+ "ACAD/ABS/ADABS0101/ABS0101_search").subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
           this.data=response;
           this.data.forEach(m=>{
            this.savedata.S_Years=m.S_Years;
            this.savedata.S_Terms=m.S_Terms;
            if(this.savedata.S_Years==''&&this.savedata.S_Terms==''){this.savedata.if=false;}
            else {this.savedata.if=true;}
            this.savedata.Leave_Deadline= m.Leave_Deadline;
            this.savedata.RollCall_Deadline= m.RollCall_Deadline;
            this.savedata.date=new Date('1968-11-16T'+m.Leave_Deadline_Time);
            console.log(this.savedata.date)
           })
        })
    }


    save(){
        this.savedata.Leave_Deadline_Time=this.savedata.date.getHours()+":"+this.savedata.date.getMinutes()+":"+59
        console.log(this.savedata.if)
        if(this.savedata.if==false)
        {
            this.http.post<any>(this.url+ "ACAD/ABS/ADABS0101/ABS0101_insert",this.savedata).subscribe(response=>{
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("新增成功");
            })
        }
        else{
            this.http.post<any>(this.url+ "ACAD/ABS/ADABS0101/ABS0101_update",this.savedata).subscribe(response=>{
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("修改成功");
            })
        }
    }
}
