import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCURTchMoneyAddHourList,GetCURTchMoneyTchList} from 'src/app/_Models/AD/ADCUR/ADCUR0503/getCURTchMoneyAddHourList';
import { GetEmployeeList } from 'src/app/_Models/getEmployeeList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUR0503',
    templateUrl: './ADCUR0503.component.html',
    styleUrls: ['./ADCUR0503.component.css']
})
export class ADCUR0503Component implements OnInit {
    Years!: Array<string>;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    HourTypeList?: GetSelectList[];
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    modalRef?: BsModalRef;
    condition = {
        Years: '',
        Term: '',
        TchNo: '',
        TchName: '',
        UnitCode: ''
    }
    unitData!: GetSelectList[];
    teacherList?: GetEmployeeList[];
    TchList!:GetCURTchMoneyTchList[];
    selectIndex=-1;
    nowYear = '';
    nowTerm = '';

    isEdit: boolean = false;
    dataList!: GetCURTchMoneyAddHourList[];
    data: any = {
        DataKey: '',
        Years: '',
        Term: '',
        TchNo: '',
        HourTypeNo: '',
        TchName: '',
        Hours: 0,
        Memo: '',
        UpdateUser: '',
        UpdateTime: ''
    };

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private modalService: BsModalService,private timeService: TimeService,) { }

    ngOnInit(): void {
        this.initYears();
        this.initHourTypeList();
        this.getUnitList();
        this.timeService.getTimeCntr().subscribe((response) => {
            this.nowTerm = response.Now_Term;
            this.nowYear = response.Now_Years;
        });
        this.isEdit = false;
        this.condition.Years=this.nowYear;
        this.condition.Term=this.nowTerm;
    }

    initYears(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getUnitList() {
        this.systemService.getUnitList('', '', 'B').subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          this.unitData = response;
        })
    }

    initHourTypeList() {
        this.systemService.getHourTypeList().subscribe((response) => {
            this.HourTypeList = response;
        });
    }

    getCURTchMoneyTchList() {
        if (this.condition.Years == '' || this.condition.Term == '') {
            this.toastr.info("請選擇學年學期");
            return;
        }

        this.dataList = [];
        this.TchList=[];
        if(this.isEdit){
            this.clear();
        }
        this.selectIndex=-1;

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0503/Get_CUR_TchMoney_Tch_List", this.condition).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.dataList = []
                return;
            }
            this.TchList = response;
            this.TchList.forEach(element => {
                element.Selected = false;
            });
        });
    }

    getCURTchMoneyAddHourList(thisItem:GetCURTchMoneyTchList,thisIndex:number) {
        this.dataList = [];
        if(this.selectIndex>=0){
            this.TchList[this.selectIndex].Selected = false;
        }
        if(this.isEdit){
            this.clear();
        }
        this.selectIndex=thisIndex;
        this.TchList[thisIndex].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0503/Get_CUR_TchMoney_AddHour_List", thisItem).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.dataList = response;
            this.dataList.forEach(element => {
                element.Selected = false;
            });
        });
    }

    getDetail(index: number) {
        this.dataList.forEach(element => {
            element.Selected = false;
        });
        this.dataList[index].Selected = true;
        this.data = this.dataList[index];

        this.isEdit = true;
    }

    getEmployeeList() {
        this.systemService.getEmployeeList().subscribe((response) => {
            this.teacherList = response;
        });
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };
        this.getEmployeeList();
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setTeacher(code: string, name: string) {
        this.data.TchNo = code;
        this.data.TchName = name;
        this.modalRef?.hide();
    }

    getCURTchMoneyAddHourModify() {
        if (this.data.Years == '') {
            this.toastr.info("請選擇學年");
            return;
        }

        if (this.data.Term == '') {
            this.toastr.info("請選擇學期");
            return;
        }

        if (this.data.HourTypeNo == '') {
            this.toastr.info("請選擇鐘點類別");
            return;
        }

        if (this.data.TchNo == '') {
            this.toastr.info("請選擇老師");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0503/Get_CUR_TchMoney_AddHour_Modify", this.data).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }

    getCURTchMoneyAddHourDelete() {
        if (this.data.DataKey == '') {
            this.toastr.info("請選擇資料");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0503/Get_CUR_TchMoney_AddHour_Delete", this.data).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.getCURTchMoneyAddHourList(this.TchList[this.selectIndex],this.selectIndex);
            this.clear();
        });
    }

    clear() {
        this.dataList.forEach(element => {
            element.Selected = false;
        });
        this.data = {
            DataKey: '',
            Years: '',
            Term: '',
            TchNo: '',
            HourTypeNo: '',
            TchName: '',
            Hours: 0,
            Memo: '',
            UpdateUser: '',
            UpdateTime: ''
        };
        this.isEdit = false;
    }
}
