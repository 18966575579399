import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CosDetail, Get_Cur_List } from 'src/app/_Models/getCurList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetSelInfoData } from 'src/app/_Models/STDWEB/STDW20001/getSelInfoData';
import { GetSelQuery, GetSelQueryInput } from 'src/app/_Models/STDWEB/STDW20001/getSelQuery';
import { GetSelSelChoose } from 'src/app/_Models/STDWEB/STDW20001/getSelSelChoose';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW20001',
    templateUrl: './STDW20001.component.html',
    styleUrls: ['./STDW20001.component.css']
})
export class STDW20001Component implements OnInit {

    infoData!: GetSelInfoData;
    getSelQueryInput: GetSelQueryInput = {
        Dept_No: '',
        Grade: '',
        Cos_ID: '',
        Cos_Name: '',
        Tch_Name: '',
        DataKey: ''
    };
    getSelQueryData!: GetSelQuery[];
    deptList!: GetDeptList[];
    selChooseData!: GetSelSelChoose[];
    SelMySelFormData!: Get_Cur_List[];
    baseUrl = environment.apiUrl;
    selIsCollapsed = false;
    cosIsCollapsed = false;
    scheduleIsCollapsed = false;
    cosDetail!: CosDetail[];
    constructor(private toastr: ToastrService, private http: HttpClient, private systemService: SystemService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.getSelInfoData();
    }

    getSelInfoData() {
        this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW200_01/Get_Sel_InfoData").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.infoData = response;
            this.getDeptList();
            this.getSelSelChoose();
            this.getSelMySelForm();
        }, error => this.toastr.error(error.error))
    }

    getSelSelChoose() {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW200_01/Get_Sel_SelChoose", { DataKey: this.infoData.DataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.selChooseData = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList() {
        this.systemService.getDeptList('', '2', this.infoData.Years, this.infoData.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning("查無資料");
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error))

    }

    getSelQuery() {
        this.getSelQueryInput.DataKey = this.infoData.DataKey;
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW200_01/Get_Sel_Query", this.getSelQueryInput).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.getSelQueryData = []
                return;
            }
            this.getSelQueryData = response;
        })
    }

    getSelMySelForm() {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW200_01/Get_Sel_MySelForm", { DataKey: this.infoData.DataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.SelMySelFormData = response;

        }, error => this.toastr.error(error.error))
    }


    getSelAdd(dataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW200_01/Get_Sel_Add", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("加選成功");
            this.getSelInfoData();
        }, error => this.toastr.error(error.error))

    }

    getSelDel(dataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW200_01/Get_Sel_Del", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("退選成功!");
            this.getSelInfoData();
        }, error => this.toastr.error(error.error))
    }


    getSelInfo(cosDetail: CosDetail[]) {
        this.cosDetail = cosDetail;
    }

}
