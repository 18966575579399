import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetOfficeApplyRecord } from 'src/app/_Models/TCHWEB/TCH0110/getOfficeApplyRecord';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-TCH0110',
    templateUrl: './TCH0110.component.html',
    styleUrls: ['./TCH0110.component.css']
})
export class TCH0110Component implements OnInit {

    baseUrl = environment.apiUrl;
    Years: string = '';
    Term: string = '';
    bsConfig = {
        isAnimated: true,
        dateInputFormat: 'YYYY-MM-DD',
        containerClass: 'theme-dark-blue',
        returnFocusToInput: true,
        showTodayButton: true,
        todayPosition: 'center',
        showClearButton: true,
        clearPosition: 'right'
    }
    searchForm!: UntypedFormGroup;
    data!: GetOfficeApplyRecord[];
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private datepipe: DatePipe,
        private formBuilder: UntypedFormBuilder,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.searchForm = this.formBuilder.group({
            Date: [],
            status: '',
            Years: '',
            Term: '',
            Meeting_Date_S: '',
            Meeting_Date_E: ''
        });

        this.timeService.getTimeCntr().subscribe((response) => {
            this.Years = response.Now_Years;
            this.Term = response.Now_Term;
        });

    }

    setModel(model: any) {
        this.Years = model.Years;
        this.Term = model.Term;
    }

    getOfficeApplyRecord() {

        if (this.searchForm.value.Date) {
            try {
                this.searchForm.value.Meeting_Date_S = this.datepipe.transform(this.searchForm.value.Date[0], 'yyyy-MM-dd');
                this.searchForm.value.Meeting_Date_E = this.datepipe.transform(this.searchForm.value.Date[1], 'yyyy-MM-dd');
            }
            catch
            {
                this.toastr.error("日期格式不正確");
                return;
            }
        }

        this.searchForm.value.Years = this.Years;
        this.searchForm.value.Term = this.Term;


        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_10/Get_OH_ApplyRecord", this.searchForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;

        }, error => this.toastr.error(error.error));
    }

    reply(dataKey: string) {
        Swal.fire({
            title: '請輸入欲回覆內容',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '同意',
            cancelButtonText: '取消',
            denyButtonText: '不同意',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                this.saveReply(result.value, dataKey, '1');
            }
            else if (result.isDenied) {
                this.saveReply(result.value, dataKey, '2');
            }
        })
    }

    saveReply(memo: string, dataKey: string, status: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_10/Get_OH_Sign", {
            Status: status,
            Memo: memo,
            DataKey: dataKey
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
            }
            this.toastr.success("已送出回覆");
            this.getOfficeApplyRecord();
        }, error => this.toastr.error(error.error));
    }
}
