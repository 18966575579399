import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetGRDUnPassEntryList } from 'src/app/_Models/AD/ADGRD/ADGRD0306/getGRDUnPassEntryList';
import { GetGRDUnPassList } from 'src/app/_Models/AD/ADGRD/ADGRD0306/getGRDUnPassList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADGRD0306',
    templateUrl: './ADGRD0306.component.html',
    styleUrls: ['./ADGRD0306.component.css']
})
export class ADGRD0306Component implements OnInit {
    modalRef?: BsModalRef;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    eduList?: GetEduList[];
    deptList?: GetDeptList[];
    classList?: GetClassList[];
    fileExchange = new FileExchange();
    baseUrl = environment.apiUrl;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', Validators.required),
        Term: new UntypedFormControl('', Validators.required),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        StdNo: new UntypedFormControl(''),
        MinCredit: new UntypedFormControl(0, Validators.required),
        UnPassType: new UntypedFormControl('1', Validators.required)
    });
    gRDUnPassEntryList?: GetGRDUnPassEntryList[];
    gRDUnPassList?: GetGRDUnPassList[];
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,
        private modalService: BsModalService) { }

    ngOnInit(): void {
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList(Years: string, Term: string) {
        this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.eduList = undefined;
            this.deptList = undefined;
            this.classList = undefined;
            this.eduList = response;
        })
    }

    getDeptList(edu: string, Years: string, Term: string) {
        this.systemService.getDeptList(edu, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = undefined;
            this.classList = undefined;
            this.deptList = response;

        });
    }

    getClassList(edu: string, dept: string, Years: string, Term: string) {
        this.systemService.getClassList(edu, dept, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classList = undefined;
            this.classList = response;
        })
    }

    getGRDUnPassEntryList() {
        this.http.get<any>(this.baseUrl + "ACAD/GRD/ADGRD0306/Get_GRD_UnPass_EntryList").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.gRDUnPassEntryList = response;
        })
    }

    getGRDUnPassEntrySave() {
        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0306/Get_GRD_UnPass_EntrySave", this.gRDUnPassEntryList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.modalRef?.hide();
        })
    }

    getGRDUnPassList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0306/Get_GRD_UnPass_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.gRDUnPassList = response;
        });
    }

    openModel(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };
        this.getGRDUnPassEntryList();
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    exportReportADGRD0306() {

        this.fileExchangeServices.ExportFile("ACAD/GRD/ADGRD0306/Export_Report_ADGRD0306", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

}
