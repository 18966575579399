import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetOpClassList } from 'src/app/_Models/getOpClassList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetAlertList } from 'src/app/_Models/TCHWEB/TCH0116/getAlertList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0116',
    templateUrl: './TCH0116.component.html',
    styleUrls: ['./TCH0116.component.css']
})
export class TCH0116Component implements OnInit {
    baseUrl = environment.apiUrl;
    AlertScoreAll = false;
    AlertLeaveAll = false;
    getOpClassListInput: GetOpClassList = {
        Years: '',
        Term: '',
        DeptNo: '',
        TeamNo: '',
        TchNo: '',
        Classroom: '',
        UserCode: '',
        CHeckRight: ''
    };
    opClassList!: GetSelectList[];
    cosList!: GetSelectList[];
    alertList!: GetAlertList[];
    constructor(private http: HttpClient, private toastr: ToastrService,
        private systemService: SystemService, private accountService: AccountService, private timeService: TimeService) { }

    ngOnInit(): void {

        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.getOpClassListInput.TchNo = response.Code;
            }
        }, error => this.toastr.error(error.error))

        this.timeService.getTimeCntr().subscribe((response) => {
            this.getOpClassListInput.Years = response.Now_Years;
            this.getOpClassListInput.Term = response.Now_Term;
            this.getOpClassList(this.getOpClassListInput)
        }, error => this.toastr.error(error.error))
    }

    getOpClassList(model: GetOpClassList) {
        this.systemService.getOpClassList(model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.opClassList = response;
        }, error => this.toastr.error(error.error));
    }

    getCosList(event: any) {
        let opClass = <HTMLInputElement>event.target;
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_16/List_Cos", {
            Years: this.getOpClassListInput.Years,
            Term: this.getOpClassListInput.Term,
            OpClass: opClass.value
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosList = response;
        }, error => this.toastr.error(error.error));
    }

    getListAlert(dataKey: string) {

        if (dataKey == '') {
            this.toastr.info("請選擇授課科目");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_16/List_Alert", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.alertList = []
                return;
            }
            this.alertList = response;
        }, error => this.toastr.error(error.error));
    }

    setAlertScoreAll() {
        let input = document.getElementById('AlertScore');
        if (this.AlertScoreAll == false) {
            this.alertList.forEach(element => {
                element.Alert_Score = 'Y';
            })
            this.AlertScoreAll = true;
            if (input) {
                input.setAttribute('class', 'other-Button-gray mb-3 me-3');
                input.innerText = "期中預警取消";
            }
        }
        else {
            this.alertList.forEach(element => {
                element.Alert_Score = 'N';
            })
            this.AlertScoreAll = false;
            if (input) {
                input.setAttribute('class', 'other-Button-gray mb-3 me-3');
                input.innerText = "期中預警全選";
            }

        }
    }

    setAlertLeaveAll() {
        let input = document.getElementById('AlertLeave');
        if (this.AlertLeaveAll == false) {
            this.alertList.forEach(element => {
                element.Alert_Leave = 'Y';
            })
            this.AlertLeaveAll = true;
            if (input) {
                input.setAttribute('class', 'other-Button-gray mb-3 me-3');
                input.innerText = "點名預警取消";
            }
        }
        else {
            this.alertList.forEach(element => {
                element.Alert_Leave = 'N';
            })
            this.AlertLeaveAll = false;
            if (input) {
                input.setAttribute('class', 'other-Button-gray mb-3 me-3');
                input.innerText = "點名預警全選";
            }
        }
    }


    setAlertScore(index: number) {
        if (this.alertList[index].Alert_Score == 'Y') {
            this.alertList[index].Alert_Score = 'N';
            return;
        }
        this.alertList[index].Alert_Score = 'Y';
    }

    setAlertLeave(index: number) {
        if (this.alertList[index].Alert_Leave == 'Y') {
            this.alertList[index].Alert_Leave = 'N';
            return;
        }
        this.alertList[index].Alert_Leave = 'Y';
    }

    saveData() {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_16/Save_Alert", this.alertList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功!");
        }, error => this.toastr.error(error.error))
    }

}
