
<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.Years" (change)="getWeek()">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" name="Term" [(ngModel)]="getStdData.Term" (change)="getWeek()">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.EduNo" (change)="getDeptList(getStdData.EduNo)"
                    [(ngModel)]="getStdListInput.EduNo" (ngModelChange)="GetData()">
                    <option value="" selected disabled>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">系所</span>
                </div>
                <select class="form-select shadow-none"  [(ngModel)]="getStdData.DeptNo"
                    (ngModelChange)="GetData()">
                    <option value="" selected>--請選擇系所--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">假別</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.HCode">
                    <option value=""selected>--請選擇--</option>
                    <option *ngFor="let item of holidayList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">請假日期</span>
                </div>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                bsDatepicker [bsConfig]="bsConfig" readonly
                style="background-color: transparent;" [(ngModel)]="getStdData.RecDateFrom" >
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                bsDatepicker [bsConfig]="bsConfig" readonly
                style="background-color: transparent;" [(ngModel)]="getStdData.RecDateTo" >
                <!-- <input type="text" placeholder="-訖-(月/日/年)" class="form-control shadow-none" bsDatepicker [(ngModel)]="getStdData.RecDateTo" > -->
            </div>
        </div>  
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學號</span>
                </div>
                <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;" [(ngModel)]="getStdData.StdNoFrom">
                <div class="input-group-prepend">
                    <span class="input-group-text">至</span>
                </div>
                <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;" [(ngModel)]="getStdData.StdNoTo">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">週次</span>
                </div>

                <select class="form-select shadow-none" [(ngModel)]="getStdData.WeekFrom">
                    <option value="" disabled>--請選擇週次--</option>
                    <option *ngFor="let item of week" value="{{item.Cur_Week}}">
                        <ng-container *ngIf="item.ThisWeek == 'Y'">(本週) {{item.WeekDate}}</ng-container>
                        <ng-container *ngIf="item.ThisWeek == 'N'">{{item.WeekDate}}</ng-container>
                    </option>
                </select>
                <div class="input-group-prepend">
                    <span class="input-group-text">至</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.WeekTo">
                <option value="" disabled>--請選擇週次--</option>
                <option *ngFor="let item of week" value="{{item.Cur_Week}}">
                    <ng-container *ngIf="item.ThisWeek == 'Y'">(本週) {{item.WeekDate}}</ng-container>
                    <ng-container *ngIf="item.ThisWeek == 'N'">{{item.WeekDate}}</ng-container>
                </option>
            </select>
                
            </div>
        </div> 
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">累積時數</span>
                </div>
                <input type="text" class="form-control pointer shadow-none" value="" style="background-color: transparent;" [(ngModel)]="getStdData.Hours">
            </div>
        </div>
    </div>
        <div >
            <button class="export-btn mx-auto"(click)="exportReportADABS0304()">匯出 </button>
        </div>
    </div>

