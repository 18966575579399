import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { FinalOutput } from 'src/app/_Models/AD/ADSTD/ADSTD0601ISU02/getData';

@Component({
    selector: 'app-ADSTD0601ISU02',
    templateUrl: './ADSTD0601ISU02.component.html',
    styleUrls: ['./ADSTD0601ISU02.component.css']
})

export class ADSTD0601ISU02Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    teamList!: GetTeamList[];
    classList!: GetClassList[];
    stdList!: FinalOutput[];

    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl({ value: ''}, Validators.required),
        Term: new UntypedFormControl({ value: ''}, Validators.required),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        TeamNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        StdNoFrom: new UntypedFormControl(''),
        StdNoTo: new UntypedFormControl(''),
        Status: new UntypedFormControl('')
    });

    model = {
        Years: '',
        Term: ''
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Contrast_Years;
            this.model.Term = response.Contrast_Term;
        });
        this.getYears();
        this.getEduList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.eduList=[];
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getClassList(edu: string, dept: string) {
        this.classList = [];
        this.systemService.getClassList(edu, dept, '6', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))
    }

    getTeamList(dept: string) {
        this.teamList = [];
        this.systemService.getTeamList('3','','',dept,'').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.teamList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.deptList = [];
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getStdList(){
        this.stdList = [];
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_01_ISU02/Get_ADSTD0601_ISU02_StdList", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.stdList = response;
        })
    }

    SaveData(){
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_01_ISU02/Get_ADSTD0601_ISU02_Save", this.stdList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.toastr.success("儲存完成");
        })
    }
}
