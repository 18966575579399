
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADGRD0703',
  templateUrl: './ADGRD0703.component.html',
  styleUrls: ['./ADGRD0703.component.css']
})
export class ADGRD0703Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}