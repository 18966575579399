import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetTFSMakeDocOFHIList } from 'src/app/_Models/AD/ADTFS/ADTFS01E01/getTFSMakeDocOFHIList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';
import { GetTFSMakeDocOFHIDetail } from 'src/app/_Models/AD/ADTFS/ADTFS01E01/getTFSMakeDocOFHIDetail';
@Component({
    selector: 'app-ADTFS01E01',
    templateUrl: './ADTFS01E01.component.html',
    styleUrls: ['./ADTFS01E01.component.css']
})
export class ADTFS01E01Component implements OnInit {
    Years!: Array<string>;
    detailYear!: Array<string>;
    copyYears!: Array<string>;
    detailTerm = require('src/app/_Models/_SelectInput/term.json').term;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    initialData = new InitialData(this.http);
    entryIdList!: GetSelectList[];
    checkType = new CheckType();
    baseUrl = environment.apiUrl;
    copyModalRef!: BsModalRef;
    tFSMakeDocOFHIList!: GetTFSMakeDocOFHIList[];
    edit = false;
    tFSMakeDocOFHIDetail: GetTFSMakeDocOFHIDetail = {
        DataKey: '',
        Years: '',
        Term: '',
        EntryID: '',
        Grade: '',
        Money: '',
        UpdateUser: '',
        UpdateTime: '',
        UpdateIP: ''
    };
    model = {
        Years: '',
        Term: ''
    }
    constructor(private toastr: ToastrService,
        private systemService: SystemService,
        private http: HttpClient,
        private modalService: BsModalService) { }

    ngOnInit(): void {
        this.getYears();
        this.getEntryIDList();
    }



    getEntryIDList() {
        this.systemService.getEntryIDList('A').subscribe((response) => {
            this.entryIdList = response;
        })
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
                this.detailYear = response;
                this.copyYears = response;
            }
        }, error => this.toastr.error(error.error));
    }

    getTFSMakeDocOFHIList() {

        if (this.model.Years == '' || this.model.Term == '') {
            this.toastr.info("請選擇學年學期");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01E01/Get_TFS_MakeDoc_OFHIList", { Years: this.model.Years, Term: this.model.Term }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.tFSMakeDocOFHIList = []
                return;
            }
            this.tFSMakeDocOFHIList = response;
        })
    }

    getTFSMakeDocOFHIDetail(index: number, dataKey: string) {

        this.edit = false;

        this.tFSMakeDocOFHIList.forEach(element => {
            element.Selected = false;
        })
        this.tFSMakeDocOFHIList[index].Selected = true;

        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01E01/Get_TFS_MakeDoc_OFHIDetail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.tFSMakeDocOFHIDetail = response;
            this.tFSMakeDocOFHIDetail.UpdateTime = this.tFSMakeDocOFHIDetail.UpdateTime;
            this.tFSMakeDocOFHIDetail.UpdateUser = this.tFSMakeDocOFHIDetail.UpdateUser;
        })
    }

    getTFSMakeDocOFHISave() {

        if (this.tFSMakeDocOFHIDetail.Years == '' || this.tFSMakeDocOFHIDetail.Term == '') return;

        if (this.checkType.CheckIsNumber(this.tFSMakeDocOFHIDetail.Money)) {
            this.toastr.warning("保險金額請輸入數字");
            return;
        }
        this.tFSMakeDocOFHIDetail.UpdateTime = this.tFSMakeDocOFHIDetail.UpdateTime;
        this.tFSMakeDocOFHIDetail.UpdateUser = this.tFSMakeDocOFHIDetail.UpdateUser;
        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01E01/Get_TFS_MakeDoc_OFHISave", this.tFSMakeDocOFHIDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.getTFSMakeDocOFHIList();
            if (this.tFSMakeDocOFHIDetail.DataKey == '') {
                this.edit = false;
            }
        })
    }

    copyData(YearFrom: string, TermFrom: string, YearTo: string, TermTo: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01E01/Get_TFS_MakeDoc_OFHICopy", {
            YearFrom: YearFrom,
            TermFrom: TermFrom,
            YearTo: YearTo,
            TermTo: TermTo
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("複製成功");
        }, error => this.toastr.error(error.error))
    }

    setCopy(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };
        this.copyModalRef = this.modalService.show(template, modalConfig);
    }

    getTFSMakeDocOFHIDelete() {

        if (this.tFSMakeDocOFHIDetail.DataKey == '') return;

        Swal.fire({
            title: '確定刪除?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01E01/Get_TFS_MakeDoc_OFHIDelete", this.tFSMakeDocOFHIDetail).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.getTFSMakeDocOFHIList();
                    this.tFSMakeDocOFHIDetail = {
                        DataKey: '',
                        Years: '',
                        Term: '',
                        EntryID: '',
                        Grade: '',
                        Money: '',
                        UpdateUser: '',
                        UpdateTime: '',
                        UpdateIP: ''
                    };
                }, error => this.toastr.error(error.error))
            }
        });
    }

    addData() {
        this.tFSMakeDocOFHIDetail = {
            DataKey: '',
            Years: '',
            Term: '',
            EntryID: '',
            Grade: '',
            Money: '',
            UpdateUser: '',
            UpdateTime: '',
            UpdateIP: ''
        };
        this.edit = true;
        this.tFSMakeDocOFHIList.forEach(element => {
            element.Selected = false;
        })
    }

}
