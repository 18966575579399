import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0402/search';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADRGP0402',
  templateUrl: './ADRGP0402.component.html',
  styleUrls: ['./ADRGP0402.component.css']
})
export class ADRGP0402Component implements OnInit {
  baseUrl = environment.apiUrl;
  data!: Search[];
  dataDetail: Search = {
    Entry_Duct: '',
    Entry_Duct_Name: '',
    Update_User: '',
    Update_Time: '',
    Selected: false
  }
  constructor(private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.search();
  }


  search() {
    this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0402/Search").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.data = response;
      this.data.forEach(element => {
        element.Selected = false;
      });
    }, error => this.toastr.error(error.error))
  }

  insert() {

    if (this.dataDetail.Entry_Duct == '' || this.dataDetail.Entry_Duct_Name == '') {
      this.toastr.info("請填寫欄位");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0402/Insert", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("新增成功");
      this.dataDetail = {
        Entry_Duct: '',
        Entry_Duct_Name: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
      }
      this.search();

    }, error => this.toastr.error(error.error))
  }

  update() {

    if (this.dataDetail.Entry_Duct == '' || this.dataDetail.Entry_Duct_Name == '') {
      this.toastr.info("請選擇資料");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0402/Update", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("修改成功");
      this.dataDetail = {
        Entry_Duct: '',
        Entry_Duct_Name: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
      }
      this.search();
    }, error => this.toastr.error(error.error))
  }

  detail(index: number) {
    this.dataDetail = this.data[index];
    this.data.forEach(element => {
      element.Selected = false;
    });
    this.data[index].Selected = true;
  }

  delete() {
    if (this.dataDetail.Entry_Duct == '' || this.dataDetail.Entry_Duct_Name == '') {
      this.toastr.info("請選擇資料");
      return;
    }

    Swal.fire({
      title: '確定刪除?',
      confirmButtonText: `確定刪除入學管道：${this.dataDetail.Entry_Duct_Name}?`,
      showCancelButton: true,
      cancelButtonText: '取消'
    }).then(result => {
      if (result.isConfirmed) {
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0402/Delete", this.dataDetail).subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          this.toastr.success("刪除成功");
          this.dataDetail = {
            Entry_Duct: '',
            Entry_Duct_Name: '',
            Update_User: '',
            Update_Time: '',
            Selected: false
          }
          this.search();
        }, error => this.toastr.error(error.error))
      }
      else {
        this.toastr.info("已取消");
      }
    })
  }

  clear() {
    this.dataDetail = {
      Entry_Duct: '',
      Entry_Duct_Name: '',
      Update_User: '',
      Update_Time: '',
      Selected: false
    }
  }

}
