<div class="container">
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="Years">學年</span>
                <select class="form-select shadow-none" name="Years" [(ngModel)]="model.Years">
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="Term">學期</span>
                <select class="form-select shadow-none" name="Term" [(ngModel)]="model.Term">
                    <option value="" disabled>--全部--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="Approve">是否核准</span>
                <select class="form-select shadow-none" name="Approve" [(ngModel)]="model.Approve">
                    <option value="" disabled>--全部--</option>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <button class="search-btn me-3 float-start" (click)="getStdKeepList()">查詢</button>
            <button class="other-Button-gray me-3 float-start" (click)="openModel(template)">新增</button>
            <button class="other-Button-gray me-3 float-start" (click)="deleteData()">刪除</button>
        </div>
    </div>
    <hr>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <td>刪除</td>
                <td>保留學年</td>
                <td>保留學期</td>
                <td>學號</td>
                <td>姓名</td>
                <td>狀態</td>
                <td>班級</td>
                <td>原因</td>
                <td>申請日</td>
                <td>核准日</td>
                <td>核准</td>
                <td>文號</td>
                <td>復學</td>
                <td>復學學年</td>
                <td>復學學期</td>
                <td>復學日</td>
                <td>保留延長</td>
                <td>備註</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdKeepList">
                <td>
                    <input type="checkbox" (click)="setDataKey(item.DataKey)">
                </td>
                <td>{{item.Years}}</td>
                <td>{{item.Term}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.Status}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.KeepName}}</td>
                <td>{{item.ApplyDate}}</td>
                <td>{{item.ApproveDate}}</td>
                <td>{{item.Approve}}</td>
                <td>{{item.KeepMoe}}</td>
                <td>{{item.Recover}}</td>
                <td>{{item.RecoverYear}}</td>
                <td>{{item.RecoverTerm}}</td>
                <td>{{item.RecoverDate}}</td>
                <td>{{item.Delay}}</td>
                <td>{{item.Memo}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">新增保留入學</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newYears">學年</span>
                    <select class="form-select shadow-none" name="newYears" [(ngModel)]="newStdKeepData.Years">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newTerm">學期</span>
                    <select class="form-select shadow-none" name="newTerm" [(ngModel)]="newStdKeepData.Term">
                        <option value="" disabled>--全部--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newApprove">是否核准</span>
                    <select class="form-select shadow-none" name="newApprove" [(ngModel)]="newStdKeepData.Approve">
                        <option value="" disabled>--全部--</option>
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newYears">學號</span>
                    <input type="text" class="form-control shadow-none" required [(ngModel)]="newStdKeepData.StdNo">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newTerm">原因</span>
                    <select class="form-select shadow-none" name="newTerm" [(ngModel)]="newStdKeepData.KeepName">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of keepReasonList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newApprove">文號</span>
                    <input type="text" class="form-control shadow-none" required [(ngModel)]="newStdKeepData.KeepMoe">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newYears">申請日</span>
                    <input type="date" class="form-control shadow-none" required [(ngModel)]="newStdKeepData.ApplyDate">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newTerm">核准日</span>
                    <input type="date" class="form-control shadow-none" required
                        [(ngModel)]="newStdKeepData.ApproveDate">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newApprove">保留延長</span>
                    <select class="form-select shadow-none" name="newApprove" [(ngModel)]="newStdKeepData.Delay">
                        <option value="" disabled>--全部--</option>
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="">備註</label>
                <textarea type="text" class="form-control shadow-none" [(ngModel)]="newStdKeepData.Memo"
                    appTextareaAutoresize></textarea>
            </div>
        </div>
        <div class="mt-3">
            <button class="add-btn mx-auto" (click)="newData()">新增</button>
        </div>

    </div>
</ng-template>