<!-- Angular Routing tag -->
<ng-container *ngIf="routerPath != '' && routerPath != 'login'">
    <app-navbar></app-navbar>
    <div class="container">
        <div class="row mb-3">
            <div class="col-md-12">
                <app-location [currentLocation]="currentLocation"></app-location>
                <app-function-search *ngIf="useSearch"></app-function-search>
            </div>
        </div>
    </div>
    <ngx-spinner type="ball-spin-fade" bdColor="rgba(255, 255, 255, 0.3)" color="#cc0000" size="medium">
        <h3 class="fw-bolder" style="color: #cc0000;">資料處理中，請稍後...</h3>
    </ngx-spinner>
</ng-container>
<router-outlet></router-outlet>
<app-footer *ngIf="routerPath != ''"></app-footer>
