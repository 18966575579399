import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-ADTFS01B02',
  templateUrl: './ADTFS01B02.component.html',
  styleUrls: ['./ADTFS01B02.component.css']
})
export class ADTFS01B02Component implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
    
  }
  
    
}
