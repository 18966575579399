import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0104/search';
import { ClassRoomList, GetCUROpen01CosDetail, SelClassList, SelDeptList, TchList, ColumnList } from 'src/app/_Models/AD/ADCUR/ADCUR0202/getCUROpen01CosDetail';
import { GetCUROpen01CosList } from 'src/app/_Models/AD/ADCUR/ADCUR0202/getCUROpen01CosList';
import { GetCUROpen01CurPlan } from 'src/app/_Models/AD/ADCUR/ADCUR0202/getCUROpen01CurPlan';
import { GetCUROpen01QueryCos } from 'src/app/_Models/AD/ADCUR/ADCUR0202/getCUROpen01QueryCos';
import { GetCUROpen01Time } from 'src/app/_Models/AD/ADCUR/ADCUR0202/getCUROpen01Time';
import { GetEmployeeList } from 'src/app/_Models/getEmployeeList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUR0202ISU',
    templateUrl: './ADCUR0202ISU.component.html',
    styleUrls: ['./ADCUR0202ISU.component.css']
})
export class ADCUR0202ISUComponent implements OnInit {
    selected?: string;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    modalRef?: BsModalRef;
    deptList!: GetSelectList[];
    classList!: GetSelectList[];
    teamList!: GetSelectList[];
    columnItems!:GetSelectList[];
    cosSerialItems!:GetSelectList[];
    cUROpen01Time!: GetCUROpen01Time;
    cUROpen01CosList?: GetCUROpen01CosList[];
    cUROpen01CosDetail = new GetCUROpen01CosDetail();
    cUROpen01QueryCos?: GetCUROpen01QueryCos[];
    bsConfig = environment.bsDatePickerConfig;
    isAddNew:boolean=false;
    CosSerialNo={
        CosSerialNo:''
    };

    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        DeptNo: new UntypedFormControl('', [Validators.required]),
        Class: new UntypedFormControl('', [Validators.required]),
        TeamNo: new UntypedFormControl('', [Validators.required]),
        TRType: new UntypedFormControl(''),
        TRValue: new UntypedFormControl(''),
        MergeNo: new UntypedFormControl('')
    });

    ngForm = new UntypedFormGroup({
        DataKey: new UntypedFormControl(''),
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        TeamNo: new UntypedFormControl(''),
        CosID: new UntypedFormControl(''),
        CosName: new UntypedFormControl(''),
        Serial: new UntypedFormControl(''),
        SelKindName: new UntypedFormControl(''),
        Credits: new UntypedFormControl(''),
        Hours: new UntypedFormControl(0, [Validators.required]),
        TchMLog: new UntypedFormControl('', [Validators.required]),
        TchMClass: new UntypedFormControl('', [Validators.required]),
        TSEndTime: new UntypedFormControl(this.cUROpen01CosDetail.TSEndTime, [Validators.required]),
        StopMark: new UntypedFormControl(false),
        WeekMarkS: new UntypedFormControl(false),
        WeekMarkD: new UntypedFormControl(false),
        ChkCosArrange: new UntypedFormControl(false),
        AllotMark: new UntypedFormControl(false),
        PayNo: new UntypedFormControl(''),
        Memo: new UntypedFormControl(''),
        OverGrade: new UntypedFormControl(1, [Validators.required]),
        UnderGrade: new UntypedFormControl(1, [Validators.required]),
        SelMax: new UntypedFormControl(0),
        SelMin: new UntypedFormControl(0),
        ChkSelMax: new UntypedFormControl(false),
        ChkSelMin: new UntypedFormControl(false),
        ChkAdd: new UntypedFormControl(false),
        ChkDel: new UntypedFormControl(false),
        ChkAddType: new UntypedFormControl(false),
        ChkEdu: new UntypedFormControl(false),
        ChkTime: new UntypedFormControl(false),
        ChkSelDept: new UntypedFormControl(false),
        ChkSelClass: new UntypedFormControl(false),
        ChkDept: new UntypedFormControl(false),
        ChkClass: new UntypedFormControl(false),
        ChkPrePass: new UntypedFormControl(false),
        ChkCredit: new UntypedFormControl(false),
        ChkDelMust: new UntypedFormControl(false),
        ChkPGSel: new UntypedFormControl(false),
        CreditMoney: new UntypedFormControl(0),
        MergeNo: new UntypedFormControl(''),
        SelDeptList: new UntypedFormControl(this.cUROpen01CosDetail.SelDeptList),
        SelClassList: new UntypedFormControl(this.cUROpen01CosDetail.SelClassList),
        TchList: new UntypedFormControl(this.cUROpen01CosDetail.TchList),
        ClassRoomList: new UntypedFormControl(this.cUROpen01CosDetail.ClassRoomList),
        ExpandList: new UntypedFormControl(this.cUROpen01CosDetail.ExpandList),
    });

    tchList: TchList[] = [];
    tchListIndex = 0;
    classRoomList: ClassRoomList[] = [];
    classRoomListIndex = 0;
    columnList:ColumnList[]=[];
    columnListIndex = 0;
    selDeptList: SelDeptList[] = [];
    selClassList: SelClassList[] = [];
    limitType = "";
    Time!: Date;
    employeeList!: GetEmployeeList[];
    data!: Search[];
    cUROpen01CurPlan!: GetCUROpen01CurPlan;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private systemService: SystemService,
        private localeService: BsLocaleService,
        private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.getCUROpen01Time();
        this.getClassRoom();
    }

    getYears(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getClassRoom(): void {
        this.data = [];
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0104/Search", {
            ClassArea: '',
            ClassKind: '',
            ClassType: ''
        }).subscribe((response) => {

            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response;
        });
    }

    getEmployeeList(NameCode:string) {
        let deptNo=this.cUROpen01CosDetail.Class.substring(0,3);

        this.systemService.getEmployeeOptionList(NameCode,((NameCode=='')?deptNo:''),true).subscribe((response) => {
            this.employeeList = response;
        });
    }

    getDeptList(Years: string, Term: string) {
        this.deptList = [];
        this.classList = [];
        this.teamList = [];
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_DeptList", { Years: Years, Term: Term }).subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = response;
        });
    }

    getClassList(Years: string, Term: string, DeptNo: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_ClassList", { Years: Years, Term: Term, DeptNo: DeptNo }).subscribe((response) => {
            if (response.Info == false) {
                this.classList = [];
                return;
            }
            this.classList = response;
        });
    }

    getTeamList(Years: string, Term: string, Class: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_TeamList", { Years: Years, Term: Term, Class: Class }).subscribe((response) => {
            if (response.Info == false) {
                this.teamList = [];
                return;
            }
            this.teamList = response;
        });
    }

    setTeacher(code: string, name: string, index: number) {
        this.tchList[index].TchName = code + ' ' + name;
        this.tchListIndex = 0;
        this.modalRef?.hide();
    }

    setClass(Classroom: string, Classroom_Name: string, index: number) {
        this.classRoomList[index].ClassRoom = Classroom + ' ' + Classroom_Name;
        this.classRoomListIndex = 0;
        this.modalRef?.hide();
    }

    openModal(template: TemplateRef<any>, type: string, index: number) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };

        if (type == 'import') {
            if (this.ngFormInput.get("Years")?.value == '' || this.ngFormInput.get("Term")?.value == '' || this.ngFormInput.get("Class")?.value == '' || this.ngFormInput.get("TeamNo")?.value == '') {
                this.toastr.info("請選擇學年、學期、班級、組別");
                return;
            };
            this.getCUROpen01CurPlan();
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };
            this.modalRef = this.modalService.show(template, modalConfig);
            return;
        }

        if (type == 'cos') {

            if (this.ngFormInput.get("Years")?.value == '' || this.ngFormInput.get("Class")?.value == '' || this.ngFormInput.get("TeamNo")?.value == '') {
                this.toastr.info("請選擇學年、班級、組別");
                return;
            };

            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-lg'
            };
            this.modalRef = this.modalService.show(template, modalConfig);
            return;
        }

        if (type == 'copy') {
            if (this.ngFormInput.get("Years")?.value == '' || this.ngFormInput.get("Term")?.value == '' || this.ngFormInput.get("Class")?.value == '' || this.ngFormInput.get("TeamNo")?.value == '') {
                this.toastr.info("請選擇學年、學期、班級、組別");
                return;
            };

            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-md'
            };
            this.modalRef = this.modalService.show(template, modalConfig);
            return;
        }

        if(type=='columnitems'){
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-md'
            };

            this.modalRef = this.modalService.show(template, modalConfig);
            this.columnListIndex = index;
            return;
        }

        if(type=='cosserialno'){
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-md'
            };

            this.modalRef = this.modalService.show(template, modalConfig);
            return;
        }

        if (type == 'limitDept') {
            this.limitType = 'limitDept';
            modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-md'
            };
        }
        else if (type == 'limitClass') {
            this.limitType = 'limitClass';
            modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-md'
            };
        }
        else if (type == 'classRoom') {
            this.classRoomListIndex = index;
            modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-lg'
            };
        }
        else {
            this.tchListIndex = index;
            this.getEmployeeList('');
        }


        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getCUROpen01Time() {
        this.http.get<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_Time").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUROpen01Time = response;
        });
    }

    getCUROpen01CosList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇學年學期");
            return;
        }

        if (this.ngFormInput.get("TRType")?.value != '' && this.ngFormInput.get("TRValue")?.value == '') {
            this.toastr.info("請輸入項目關鍵字");
            return;
        }

        if (this.ngFormInput.get("TRType")?.value != '' && this.ngFormInput.get("TRValue")?.value != '' && this.ngFormInput.get("Class")?.value == '') {
            this.toastr.info("請選擇班級");
            return;
        }

        this.ClearCos();

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_CosList", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cUROpen01CosList = undefined;
                return;
            }
            this.cUROpen01CosList = response;

            this.cUROpen01CosList!.forEach(element => {
                element.Selected = false;
            });
        })
    }

    getCUROpen01CosDetail(dayaKey: string, index: number) {

        if (!this.cUROpen01CosList) return;

        this.isAddNew=false;

        this.cUROpen01CosList.forEach(element => {
            element.Selected = false;
        });

        this.cUROpen01CosList[index].Selected = true;

        this.ClearCos();

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_CosDetail", { DataKey: dayaKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.ngForm.setValue(response);
            this.cUROpen01CosDetail=response;
            let time = this.datePipe.transform(this.ngForm.get('TSEndTime')?.value, 'HH:mm');

            if (time) {
                this.Time = new Date();
                this.Time.setHours(parseInt(time.split(':')[0]));
                this.Time.setMinutes(parseInt(time.split(':')[1]));
            }

            this.CosSerialNo.CosSerialNo=response.ExpandList[0].ItemValue;
            this.tchList = response.TchList;
            this.classRoomList = response.ClassRoomList;
            this.selClassList = response.SelClassList;
            this.selDeptList = response.SelDeptList;
            this.columnList = response.ExpandList;
        })
    }

    getCUROpen01QueryCos(KeyWord: string, UseCurPlan: boolean) {

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_QueryCos", {
            Years: this.ngFormInput.get("Years")?.value,
            Class: this.ngFormInput.get("Class")?.value,
            KeyWord: KeyWord,
            UseCurPlan: UseCurPlan
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUROpen01QueryCos = response;
        });
    }

    saveCos(DataKey: string, CosID: string, CosName: string) {
        if (this.ngForm.get("DataKey")?.value == '') {
            this.getCUROpen01ModifyNew(DataKey);
        }
        else {
            this.ngForm.get("CosID")?.setValue(CosID);
            this.ngForm.get("CosName")?.setValue(CosName);
        }
    }

    getCUROpen01ModifyNew(CosID: string) {
        if(!this.checkRights()){
            return;
        }

        Swal.fire({
            title: `確定新增？`,
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_Modify", {
                    DataKey: '',
                    Years: this.ngFormInput.get("Years")?.value,
                    Term: this.ngFormInput.get("Term")?.value,
                    Class: this.ngFormInput.get("Class")?.value,
                    TeamNo: this.ngFormInput.get("TeamNo")?.value,
                    CosID: CosID,
                }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("新增成功!");
                    this.modalRef?.hide();
                    this.getCUROpen01CosList();
                })
            }
            else {
                this.toastr.info("動作已取消");
            }
        });
    }

    getCUROpen01Modify() {
        if(!this.checkRights()){
            return;
        }

        if (!this.ngForm.valid) {
            this.toastr.info("有欄位尚未完成");
            return;
        }

        if (!this.checkData()) {
            return;
        }

        if(this.CosSerialNo.CosSerialNo=="" || this.CosSerialNo.CosSerialNo==null){
            this.toastr.info("課程代號不可空白!");
            return;
        }

        this.columnList[0].ItemValue=this.CosSerialNo.CosSerialNo;
        this.ngForm.get('TSEndTime')?.setValue(this.datePipe.transform(this.ngForm.get('TSEndTime')?.value, 'yyyy-MM-dd')+' '+this.datePipe.transform(this.Time, 'HH:mm'));
        this.ngForm.get('TchList')?.setValue(this.tchList);
        this.ngForm.get('ClassRoomList')?.setValue(this.classRoomList);
        this.ngForm.get('SelClassList')?.setValue(this.selClassList);
        this.ngForm.get('SelDeptList')?.setValue(this.selDeptList);
        this.ngForm.get('ExpandList')?.setValue(this.columnList);

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_Modify", this.ngForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.limitType = "";
            this.toastr.success("儲存成功");
        });
    }

    getCUROpen01Delete() {
        if(!this.checkRights()){
            return;
        }

        if (this.ngForm.get("DataKey")?.value == '') {
            this.toastr.info("請選擇科目");
            return;
        }

        Swal.fire({
            title: `確定刪除？`,
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_Delete", { DataKey: this.ngForm.get("DataKey")?.value }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.ngForm.reset();
                    this.getCUROpen01CosList();
                })
            }
            else {
                this.toastr.info("動作已取消");
            }
        })
    }

    addRow(type: string) {
        if (type == 'tch') {
            this.tchList.push({
                DataKey: '',
                TchName: '',
                ArrangeHours: 0,
                MoneyHours: 0,
                Score: 0,
                ChkTime: false,
                ChkMerge: false,
                Memo: '',
                Delete: false,
            });
        }
        else {
            this.classRoomList.push({
                DataKey: '',
                ClassRoom: '',
                ClassRoomType: '',
                ArrangeHours: 0,
                Delete: false
            });
        }
    }

    deleteRow(type: string) {
        if (type == 'tch') {
            this.tchList.pop();
        }
        else {
            this.classRoomList.pop();
        }
    }

    checkData(): boolean {
        let pass = true;
        let MoneyHours = 0;
        let ArrangeHours = 0;
        let ratio = 0;
        this.tchList.forEach(element => {
            if (element.TchName == '' || !element.Score || !element.MoneyHours || !element.ArrangeHours) {
                this.toastr.info("教師資訊請填寫完整");
                pass = false;
            }

            if (element.Score == 0 || element.MoneyHours == 0 || element.ArrangeHours == 0) {
                this.toastr.info("時數或分數不得為0");
                pass = false;
            }

            MoneyHours += element.MoneyHours;
            ArrangeHours += element.MoneyHours;
            ratio += element.Score;
        });

        if (MoneyHours > this.ngForm.get("Hours")?.value) {
            this.toastr.info("鐘點總時數大於科目設定的時數");
            pass = false;
        }

        if (ArrangeHours > this.ngForm.get("Hours")?.value) {
            this.toastr.info("排課總時數大於科目設定的時數");
            pass = false;
        }

        if (ratio != 1) {
            this.toastr.info("成績比例總和必須為1");
            pass = false;
        }

        if (this.ngForm.get("ChkSelMax")?.value == true && this.ngForm.get("SelMax")?.value == 0) {
            this.toastr.info("請填入人數上限的值");
            pass = false;
        }

        if (this.ngForm.get("ChkSelMin")?.value == true && this.ngForm.get("SelMin")?.value == 0) {
            this.toastr.info("請填入人數下限的值");
            pass = false;
        }

        if (!this.Time) {
            this.toastr.info("請填入教學大綱期限時間");
            pass = false;
        }

        return pass;
    }

    checkRights(): boolean{
        if(this.cUROpen01Time.Years+this.cUROpen01Time.Term!=this.ngFormInput.get("Years")?.value+this.ngFormInput.get("Term")?.value){
            if(!this.cUROpen01Time.Modify){
                this.toastr.info("未授予異動歷史資料權限!!");
                return false;
            }
        }
        else{
            if(!this.cUROpen01Time.Deadline){
                this.toastr.info("目前非開課作業時間!");
                return false;
            }
        }

        return true;
    }

    getCUROpen01Copy(years: string, trem: string) {
        if(!this.checkRights()){
            return;
        }

        Swal.fire({
            title: "將覆蓋所有已建立的資料，確定執行？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_Copy", {
                    Years: this.ngFormInput.get("Years")?.value,
                    Term: this.ngFormInput.get("Term")?.value,
                    Class: this.ngFormInput.get("Class")?.value,
                    TeamNo: this.ngFormInput.get("TeamNo")?.value,
                    YearsFrom: years,
                    TermFrom: trem
                }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("複製成功");
                    this.modalRef?.hide();
                })
            }
        })
    }

    getCUROpen01CurPlan() {
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_CurPlan", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUROpen01CurPlan = response;
        });
    }

    getCUROpen01CurPlanImport() {
        if(!this.checkRights()){
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_CurPlan_Import", this.cUROpen01CurPlan).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("匯入成功");
            this.getCUROpen01CosList();
            this.modalRef?.hide();
        });
    }

    getCUROpen01ColumnList(thisItem:ColumnList){
        this.columnItems=[];
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_ColumnItems", thisItem).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.columnItems = response;
        });
    }

    getCUROpen01CosSerialList(){
        this.cosSerialItems=[];
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0202ISU/Get_CUR_Open01_CosSerialNoList", {DataKey:this.ngForm.get("DataKey")?.value}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosSerialItems = response;
        });
    }

    saveColumnItem(itemValue:string,itemName:string,thisIndex:number){
        this.columnList[thisIndex].ItemValue = itemValue;
        this.columnList[thisIndex].ItemName = itemValue+" "+itemName;
        this.columnListIndex=0;
        this.modalRef?.hide();
    }

    saveCosSerialNo(itemValue:string){
        this.CosSerialNo.CosSerialNo=itemValue;
        this.modalRef?.hide();
    }

    ClearCos(){
        this.isAddNew=true;
        this.ngForm.reset();
        this.ngForm.get("DataKey")?.setValue("");
        this.CosSerialNo.CosSerialNo="";
        this.tchList = [];
        this.classRoomList = [];
        this.columnList=[];
    }
}
