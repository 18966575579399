import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetTFSMakeDocWriteOffDetail } from 'src/app/_Models/AD/ADTFS/ADTFS0503/getTFSMakeDocWriteOffDetail';
import { GetTFSMakeDocWriteOffList } from 'src/app/_Models/AD/ADTFS/ADTFS0503/getTFSMakeDocWriteOffList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetInTypeList } from 'src/app/_Models/getInTypeList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADTFS0503',
  templateUrl: './ADTFS0503.component.html',
  styleUrls: ['./ADTFS0503.component.css']
})
export class ADTFS0503Component implements OnInit {
  initialData = new InitialData(this.http);
  baseUrl = environment.apiUrl;
  Years!: Array<string>;
  makeDocList!: GetSelectList[];
  deptList!: GetDeptList[];
  eduList!: GetEduList[];
  classList!: GetClassList[];
  inTypeList!: GetInTypeList[];
  tFSMakeDocWriteOffList!: GetTFSMakeDocWriteOffList[];
  modalRef!: BsModalRef;
  fileExchange = new FileExchange();
  term = require('src/app/_Models/_SelectInput/term.json').term;
  tFSMakeDocWriteOffDetail!: GetTFSMakeDocWriteOffDetail;
  bsConfig = environment.bsDatePickerConfig;
  model = {
    Years: '',
    Term: '',
    EduNo: '',
    DeptNo: '',
    Class: '',
    MakeDocNo: '',
    Status: '',
    StdNo: '',
    StdName: '',
    DocNo: ''
  }
  constructor(private toastr: ToastrService,
    private http: HttpClient,
    private systemService: SystemService,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    private fileExchangeServices: FileExchangeService) { }

  ngOnInit(): void {
    this.localeService.use('zh-cn');
    this.getYears();
    this.getInType();
  }



  getEduList() {
    this.systemService.getEduList('0', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.eduList = response;
    }, error => this.toastr.error(error.error))
  }

  getDeptList() {
    this.systemService.getDeptList(this.model.EduNo, '0', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.deptList = response;
    }, error => this.toastr.error(error.error));
  }

  getClassList() {
    this.systemService.getClassList(this.model.EduNo, this.model.DeptNo, '0', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.classList = response;
    }, error => this.toastr.error(error.error))
  }

  getMakeDocUnitEduList() {
    this.systemService.getMakeDocUnitEduList(this.model.Years, this.model.Term, '', this.model.EduNo).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.makeDocList = response;
    }, error => this.toastr.error(error.error))
  }

  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getInType() {
    this.systemService.getInType().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.inTypeList = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocWriteOffList() {

    if (this.model.Years == '' || this.model.Term == '') {
      this.toastr.info("請選擇學年學期");
      return;
    }

    if (this.model.MakeDocNo == '') {
      this.toastr.info("請選擇製單類別");
      return;
    }

    if (this.model.EduNo == '') {
      this.toastr.info("請選擇學制");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0503/Get_TFS_MakeDoc_WriteOffList", {
      Years: this.model.Years,
      Term: this.model.Term,
      EduNo: this.model.EduNo,
      DeptNo: this.model.DeptNo,
      Class: this.model.Class,
      MakeDocNo: this.model.MakeDocNo,
      Status: this.model.Status,
      StdNo: this.model.StdNo,
      StdName: this.model.StdName,
      DocNo: this.model.DocNo
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocWriteOffList = []
        return;
      }
      this.tFSMakeDocWriteOffList = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocWriteOffDetail(dataKey: string, template: TemplateRef<any>) {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0503/Get_TFS_MakeDoc_WriteOffDetail", { DataKey: dataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tFSMakeDocWriteOffDetail = response;
      this.tFSMakeDocWriteOffDetail.StdName = this.tFSMakeDocWriteOffDetail.StdName;
      this.tFSMakeDocWriteOffDetail.MakeDocName = this.tFSMakeDocWriteOffDetail.MakeDocName;
      this.tFSMakeDocWriteOffDetail.PayDate = this.tFSMakeDocWriteOffDetail.PayDate;
      this.tFSMakeDocWriteOffDetail.InDate = this.tFSMakeDocWriteOffDetail.InDate;
      this.tFSMakeDocWriteOffDetail.InType = this.tFSMakeDocWriteOffDetail.InType;
      this.tFSMakeDocWriteOffDetail.Memo = this.tFSMakeDocWriteOffDetail.Memo;

      console.log(this.tFSMakeDocWriteOffDetail);


      let modalConfig = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-lg'
      };
      this.modalRef = this.modalService.show(template, modalConfig);
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocWriteOffSave() {
    if (this.tFSMakeDocWriteOffDetail.InType == '') {
      this.toastr.info("請選擇銷帳管道");
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0503/Get_TFS_MakeDoc_WriteOffSave", this.tFSMakeDocWriteOffDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("銷帳成功");
      this.modalRef.hide();
    })
  }

  getTFSMakeDocWriteOffDelete(dataKey: string) {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0503/Get_TFS_MakeDoc_WriteOffDelete", { DataKey: dataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("撤銷成功");
    })
  }

  import() {
    if (this.model.Years == '' || this.model.Term == '' || this.model.MakeDocNo == '') {
      this.toastr.info("請先選擇學年學期及製單類別");
      return;
    }

    document.getElementById("importData")?.click();
  }

  importFileBase64($event: any, dataKey: string, type: string) {
    let file = $event.target.files[0];
    if (!file) return;
    this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type);
  }

  GetBase64(that: any, base64: string, file: any, dataKey: string) {
    let json = {
      Years: that.model.Years,
      Term: that.model.Term,
      MakeDocNo: that.model.MakeDocNo,
      FileBase64: base64
    }
    Swal.fire({
      title: '確定上傳 ' + file.name + ' ?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: '是',
      denyButtonText: '否',
      cancelButtonText: '取消',
    }).then((result) => {
      if (result.isConfirmed) {
        that.fileExchangeServices.ImportFile('ACAD/TFS/ADTFS0503/Import_WriteOffFile_Base64', json)
          .subscribe((response: any) => {
            if (response.Info == true) { that.toastr.success("上傳成功!!"); }
            else { that.toastr.error(response.Message, "執行錯誤!"); }
          });
      }
      else if (result.isDenied) {
        document.getElementById("importData")?.setAttribute('value', '');
        that.toastr.info("已取消上傳。");
      }
    });
  }
}
