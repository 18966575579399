<div class="container">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #edu (ngModelChange)="getDeptList(edu.value, false, false)"
                        formControlName="EduNo">
                        <option value="" selected>--請選擇學制--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="">--請選擇系所--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">狀態</span>
                    <select class="form-select shadow-none" formControlName="Status">
                        <option value="">--請選擇--</option>
                        <option value="1">啟用</option>
                        <option value="0">未啟用</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">必選修別</span>
                    <select class="form-select shadow-none" formControlName="SelKind">
                        <option value="">--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of selKindList">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">關鍵字</span>
                    <input type="text" class="form-control shadow-none" formControlName="KeyWord">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-check form-check-inline mt-2">
                    <input class="form-check-input pointer shadow-none" type="radio" name="OrderBy" id="inlineRadio1"
                        value="1" formControlName="OrderBy">
                    <label class="form-check-label pointer" for="inlineRadio1">依科目排列</label>
                </div>
                <div class="form-check form-check-inline mt-2">
                    <input class="form-check-input pointer shadow-none" type="radio" name="OrderBy" id="inlineRadio2"
                        value="2" formControlName="OrderBy">
                    <label class="form-check-label pointer" for="inlineRadio2">依選修別排列</label>
                </div>
            </div>
            <div class="col-md-6">
                <button class="other-Button-gray float-end" (click)="ngForm.reset()">清除</button>
                <button class="other-Button-gray float-end me-3" (click)="getCUPPermanentDelete()">刪除</button>
                <button class="other-Button-gray float-end me-3" (click)="submit.click()">儲存</button>
                <button class="other-Button-gray float-end me-3" (click)="openModal(template)">匯出</button>
                <button class="search-btn me-3 float-end" (click)="getCUPPermanentList(false)">查詢</button>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-lg-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 50px;"></th>
                        <th style="width: 100px;vertical-align: middle;">代碼</th>
                        <th style="width: 170px;vertical-align: middle;text-align:left;">名稱</th>
                        <th style="width: 100px;vertical-align: middle;text-align:left;">系所</th>
                        <th style="width: 50px;vertical-align: middle;">選修別</th>
                        <th style="width: 50px;vertical-align: middle;">總學分</th>
                        <th style="width: 50px;vertical-align: middle;">開課學分</th>
                        <th style="width: 50px;vertical-align: middle;">狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of cUPPermanentList; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="getCUPPermanentDetail(item.DataKey, i)">
                        <td style="width: 50px;">{{i + 1}}</td>
                        <td style="width: 100px;">{{item.CosID}}</td>
                        <td style="width: 170px;text-align:left;">{{item.CosName}}</td>
                        <td style="width: 100px;text-align:left;">{{item.DeptName}}</td>
                        <td style="width: 50px;">{{item.SelKindName}}</td>
                        <td style="width: 50px;">{{item.AllCredits}}</td>
                        <td style="width: 50px;">{{item.CosCredits}}</td>
                        <td style="width: 50px;">{{item.Status}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6">
            <form [formGroup]="ngForm" (ngSubmit)="getCUPPermanentModify()">
                <button hidden #submit></button>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">代碼</span>
                            <input type="text" formControlName="CosID" class="form-control shadow-none"
                                placeholder="上限為10碼" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">名稱</span>
                            <input type="text" formControlName="CosName" class="form-control shadow-none" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">簡稱</span>
                            <input type="text" formControlName="CosShort" class="form-control shadow-none"
                                placeholder="上限為8個字" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">列印名稱</span>
                            <input type="text" formControlName="CosPrint" class="form-control shadow-none"
                                placeholder="上限為8個字" required>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">英文名稱</span>
                            <input type="text" formControlName="CosEngName" class="form-control shadow-none" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">所屬學制</span>
                            <select class="form-select shadow-none" formControlName="EduNo" #edu1
                                (ngModelChange)="getDeptList(edu1.value, true, false)" required>
                                <option value="" disabled>--請選擇學制--</option>
                                <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">所屬系所</span>
                            <select class="form-select shadow-none" formControlName="DeptNo" required #DeptNo
                                (ngModelChange)="getCurClass($event)">
                                <option value="" disabled>--請選擇系所--</option>
                                <option *ngFor="let item of detailDeptList" ngValue="{{item.Dept_No}}">
                                    {{item.Dept_Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">開課學期</span>
                            <select class="form-select shadow-none" #CosTerm formControlName="CosTerm" required>
                                <option value="3">單一學期</option>
                                <option value="1">上學期</option>
                                <option value="2">下學期</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">必選修別</span>
                            <select class="form-select shadow-none" formControlName="SelKind" required>
                                <option value="" selected disabled>--請選擇--</option>
                                <option ngValue="{{item.ItemValue}}" *ngFor="let item of selKindList">
                                    {{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">狀態</span>
                            <select class="form-select shadow-none" formControlName="Status" required>
                                <option value="1">啟用</option>
                                <option value="0">未啟用</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">課程類別</span>
                            <select class="form-select shadow-none" formControlName="CurClass" required>
                                <option value="" selected disabled>--請選擇--</option>
                                <option ngValue="{{item.ItemValue}}" *ngFor="let item of curClassList">
                                    {{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">總學分數</span>
                            <input type="number" step="0.5" min="0" formControlName="AllCredits"
                                class="form-control shadow-none" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">開課學分數</span>
                            <input type="number" step="0.5" min="0" formControlName="CosCredits"
                                class="form-control shadow-none" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">開課時數</span>
                            <input type="number" min="0" formControlName="CosHours" class="form-control shadow-none"
                                required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">實習時數</span>
                            <input type="number" min="0" formControlName="PracticeHours"
                                class="form-control shadow-none" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">選課人數上限</span>
                            <input type="number" min="0" formControlName="SelMax" class="form-control shadow-none"
                                required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">選課人數下限</span>
                            <input type="number" min="0" formControlName="SelMin" class="form-control shadow-none"
                                required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">及格分數</span>
                            <input type="number" min="0" formControlName="PassScore" class="form-control shadow-none"
                                required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">成績登打方式</span>
                            <select class="form-select shadow-none" formControlName="ScoreInputType" required>
                                <option value="1">登打成績</option>
                                <option value="2">通過及不通過</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">特殊註記</span>
                            <select class="form-select shadow-none" formControlName="SelKind" required
                                formControlName="SelStyle">
                                <option value="" selected disabled>--請選擇--</option>
                                <option value="{{item.ItemValue}}" *ngFor="let item of selStyleList">
                                    {{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-check form-check-inline mt-2">
                            <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineRadio3"
                                formControlName="TSMark">
                            <label class="form-check-label pointer" for="inlineRadio3">填寫教學進度</label>
                        </div>
                        <div class="form-check form-check-inline mt-2">
                            <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineRadio4"
                                formControlName="PrintMark">
                            <label class="form-check-label pointer" for="inlineRadio4">於歷年成績單列印</label>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="CosTerm.value != '3'">
                        <div class="input-group mb-3">
                            <span class="input-group-text" *ngIf="CosTerm.value == '2'">上學期科目代碼</span>
                            <span class="input-group-text" *ngIf="CosTerm.value == '1'">下學期科目代碼</span>
                            <input type="text" formControlName="RelCosID" class="form-control shadow-none" readonly>
                            <input type="text" formControlName="RelCosName" class="form-control shadow-none" readonly>
                            <button type="button" class="btn btn-light shadow-none"
                                (click)="openModal(cosTemplate)">查詢</button>
                            <button type="button" class="btn btn-light shadow-none" (click)="clearCos()">清除</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改者</span>
                            <input type="text" formControlName="UpdateUser" class="form-control shadow-none" readonly>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改時間</span>
                            <input type="text" formControlName="UpdateTime" class="form-control shadow-none" readonly>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢科目</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">報表顯示欄位</div>
                    <div class="card-body" style="height: 300px; overflow-y: scroll;">
                        <div class="list-group">
                            <label class="list-group-item">
                                <input class="form-check-input shadow-none me-1" type="checkbox" checked disabled>
                                科目代碼
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input shadow-none me-1" type="checkbox" checked disabled>
                                科目名稱
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">報表選用欄位</div>
                    <div class="card-body" style="height: 300px; overflow-y: scroll;">
                        <div class="list-group">
                            <ng-container *ngFor="let item of field">
                                <label class="list-group-item pointer">
                                    <input class="form-check-input shadow-none me-1" type="checkbox"
                                        value="{{item.value}}" (click)="setField(item.value)">
                                    {{item.name}}
                                </label>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12 text-center">
                <button class="other-Button-gray" (click)="exportCUPPermanent()">匯出</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #cosTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">課程查詢</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormClassInput" (ngSubmit)="getCUPPermanentList(true)">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學制</span>
                        <select class="form-select shadow-none" #edu2
                            (ngModelChange)="getDeptList(edu2.value, false, true)" formControlName="EduNo">
                            <option value="" selected disabled>--請選擇學制--</option>
                            <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" formControlName="DeptNo">
                            <option value="" disabled>--請選擇系所--</option>
                            <option *ngFor="let item of classDeptList" value="{{item.Dept_No}}">
                                {{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">狀態</span>
                        <select class="form-select shadow-none" formControlName="Status">
                            <option value="" disabled>--請選擇--</option>
                            <option value="1">啟用</option>
                            <option value="0">未啟用</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group mb-3">
                        <span class="input-group-text">關鍵字</span>
                        <input type="text" class="form-control shadow-none" formControlName="KeyWord">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="search-btn float-end">查詢</button>
                </div>
            </div>
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>代碼</th>
                        <th>名稱</th>
                        <th>系所</th>
                        <th style="width: 80px;">選修別</th>
                        <th style="width: 80px;">總學分</th>
                        <th style="width: 80px;">開課學分</th>
                        <th style="width: 80px;">狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of cUPPermanentClassList; let i = index" class="pointer"
                        (click)="setCos(item.CosID, item.CosName)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.CosID}}</td>
                        <td>{{item.CosName}}</td>
                        <td>{{item.DeptName}}</td>
                        <td style="width: 80px;">{{item.SelKindName}}</td>
                        <td style="width: 80px;">{{item.AllCredits}}</td>
                        <td style="width: 80px;">{{item.CosCredits}}</td>
                        <td style="width: 80px;">{{item.Status}}</td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</ng-template>
