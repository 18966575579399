import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
    selector: '[appTextareaAutoresize]'
})
export class TextareaAutoresizeDirective implements OnInit {

    // 為了動態改變text-area的高度，這裡使用ElementRef去獲取HTML reference
    constructor(private elementRef: ElementRef) { }

    resize() {
        this.elementRef.nativeElement.style.height = '0';
        this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px';
    }

    // 監聽input動作
    @HostListener(':input')
    onInput() {
        this.resize();
    }


    // 起始時設定原始的高度
    ngOnInit() {
        if (this.elementRef.nativeElement.scrollHeight) {
            setTimeout(() => this.resize());
        }
    }

}
