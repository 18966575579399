import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GetOutSideWeb, login } from 'src/app/_Models/login';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { FidoService } from 'src/app/_Services/_Shared/fido.service';
import { GoogleSSOService } from 'src/app/_Services/_Shared/google-sso.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { auth } from '../../_Models/auth';
import { map, Observable } from 'rxjs';
import { GetSelectList } from 'src/app/_Models/getSelectList';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    baseUrl = environment.apiUrl;
    ImgUrl!: SafeUrl;
    ImgUrl1!: SafeUrl;
    model: login = {
        type: '',
        userAccount: '',
        userPassword: '',
        validationCode: '',
        Validation_Code_Base64: '',
        SSO: '',
        GoAspx: false,
        isMobile: false
    };
    showGoogle = false;
    showFinger = false;
    showLine = false;
    imageSetInterval: any;
    secSetInterval: any;
    sec = 60;
    Validation_Code_Base64!: string;
    type: any;
    schoolName!: string;
    outSideWeb: GetOutSideWeb[] = [];
    noticeItem: GetSelectList[] = [];
    LineAddURL!: string;
    langu: string = "zh-tw";


    constructor(
        public accountService: AccountService,
        private router: Router,
        private http: HttpClient,
        private domSanitizer: DomSanitizer,
        private translateService: TranslateService,
        private systemService: SystemService,
        private fidoService: FidoService,
        private googleSSOService: GoogleSSOService,
        private route: ActivatedRoute
    ) { }

    access_token!: string;

    au1!: Observable<any>;

    ngOnInit(): void {
        this.getNoticeItem();
        this.getSchoolInfo();
        this.checkIsMobile();
        this.getValidationImage();
        this.getACADHost();
        this.setValidationTimer();
        this.getOutSideWeb();
        this.accountService.logout();
        this.getLoginType();
        this.getBackgroundImage();

        //Google回呼URL
        let googleCode = this.route.snapshot.queryParamMap.get('code');
        if (googleCode != null) {
            this.GoogleLogin(googleCode);
        }
    }

    // 當離開頁面時即消滅計時器。
    ngOnDestroy() {
        if (this.imageSetInterval) {
            clearInterval(this.imageSetInterval);
        }
        if (this.secSetInterval) {
            clearInterval(this.secSetInterval);
        }
    }

    // 取得學校各項資訊
    getSchoolInfo() {
        this.systemService.getSchoolInfo().subscribe((response) => {
            this.schoolName = response.Sch_Name || '';
            this.LineAddURL = response.LineAddURL || '';
        });
    }

    //取得背景圖片
    getBackgroundImage() {
        this.http.get<any>(this.baseUrl + 'Public/Get_SchoolImage').subscribe((response) => {
            this.ImgUrl1 = this.domSanitizer.bypassSecurityTrustUrl(response.Base64)
        })
    }

    // 取得驗證圖片
    getValidationImage() {
        this.http.get<any>(this.baseUrl + 'Login/Get_Validation_Code').subscribe(response => {
            this.sec = 60;
            // 使Base64轉換為SafeUrl狀態。
            this.ImgUrl = this.domSanitizer.bypassSecurityTrustUrl(response.Base64);
            this.model.Validation_Code_Base64 = response.Code;
        });
    }

    // 取得身分別下拉選單
    getACADHost() {
        this.http.get<any>(this.baseUrl + 'Login/Get_ACADHost').subscribe((response) => {
            if (response) {
                this.type = response;
            }
        })
    }

    // 取得校外網站清單
    getOutSideWeb() {
        this.http.get<any>(this.baseUrl + 'Login/Get_OutSideWeb').subscribe((response) => {
            this.outSideWeb = response;
        });
    }

    //注意事項清單
    getNoticeItem() {
        this.http.get<GetSelectList[]>(this.baseUrl + 'Login/Get_NoticeItem').subscribe((response) => {
            this.noticeItem = response;

        });
    }

    // 設定倒數計時器。
    setValidationTimer() {
        this.imageSetInterval = setInterval(() => {
            this.sec--;
            if (this.sec == 0) {
                this.getValidationImage();
                this.sec = 60;
            }
        }, 1000);
    }

    //登入後回傳解析
    LoginResult(response: any) {
        // console.log(response);
        if (!JSON.parse(sessionStorage.getItem('auth') || '').Info) {
            Swal.fire({
                icon: 'question',
                title: '驗證失敗：請重新登入。',
                text: response.Message,
                footer: '<a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html#sec10.4.2">為什麼會有這個錯誤訊息?</a>',
                confirmButtonText: 'OK',
            });
            return;
        } else {
            if (response.GoAspx) {

                window.location.href = `${response.Message}`
                return;
            }
            this.router.navigateByUrl('/Home');
        }
    }

    // 設定語言別
    changeLanguage($event: any) {
        let lang = <HTMLInputElement>$event.target;
        this.langu = lang.value;
        sessionStorage.setItem("yt", this.langu);
        this.translateService.use(this.langu);
        this.systemService.recoverModel(sessionStorage.getItem("yt") || "");
    }

    // 檢查是否為行動裝置
    checkIsMobile() {
        this.model.isMobile = this.systemService.checkIsMobile();
    }

    //校外網站用
    openNewTab(url: string) {
        window.open(url, '_blank')?.focus();
    }

    //取得可用SSO種類
    getLoginType() {
        this.http.get<any>(this.baseUrl + "Portal/Get_LoginType").subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.showGoogle = response.UseGoogleSSO;
            this.showFinger = response.UseFIDO;
            this.showLine = response.UseLine;

            // if (this.showGoogle){
            //     this.googleSSOService.getGoogleCode();
            // }
        });
    }

    //漢龍登入
    HLLogin() {
        this.accountService.login(this.model).subscribe((response) => this.LoginResult(response));
    }

    //使用Google登入
    GoogleLogin(code: string) {
        this.googleSSOService.GoogleLogin(code).subscribe((response) => this.LoginResult(response));
    }




    fidoLogin(code: string) {

        if (code == '') {
            Swal.fire("請輸入帳號");
            return;
        }

        if (this.model.type == '') {
            Swal.fire("請選擇身分");
            return;
        }

        let deviceKey = sessionStorage.getItem('DeviceKey')
        if (!deviceKey) {
            this.fidoService.getDeviceKey().subscribe(response => {
                if (response.Info == false) {
                    Swal.fire(response.Message);
                    return;
                }
                sessionStorage.setItem('DeviceKey', response.DeviceKey);
                this.requestAuth(code, response.DeviceKey);
            })
        }
        else {
            this.requestAuth(code, deviceKey);
        }
    }

    requestAuth(code: string, deviceKey: string) {
        this.fidoService.requestAuth(code, deviceKey).subscribe((response) => {
            if (response.Info == false) {
                Swal.fire("指紋驗證請求失敗");
                return;
            }
            this.doAuth(JSON.parse(response.Message), code, deviceKey);
        })
    }

    doAuth(getAssertReq: any, code: string, deviceKey: string) {
        var assertReq = this.fidoService.preformatGetAssertReq(getAssertReq.body);
        navigator.credentials.get({ "publicKey": assertReq }).then((newCredentialInfo) => {
            newCredentialInfo = this.fidoService.publicKeyCredentialToJSON(newCredentialInfo);
            var fido2DoAuthReq = {
                header: {
                    appVersion: "v1",
                    channelCode: "hi.fido2.poc",
                    deviceBrand: "google",
                    deviceType: "chrome",
                    deviceUuid: "xxx",
                    deviceVersion: "92.0.4515.107",
                    userIp: "localhost"
                },
                body: {}
            };
            fido2DoAuthReq.body = { publicKeyCredential: newCredentialInfo };
            this.fidoService.doAuth({
                Code: code,
                DeviceKey: deviceKey,
                PublicKey: JSON.stringify(fido2DoAuthReq),
                Model: "doAuth"
            }).subscribe((response) => {
                if (response.Info == false) {
                    Swal.fire("驗證失敗");
                    return;
                }
                this.model = JSON.parse(response.Message);
                this.HLLogin();
            })

        });
    }

    //登入驗證
    googleLogin() {
        this.googleSSOService.GetAuthGoogleURL("Login");
    }

}
