import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ADCUP0505',
    templateUrl: './ADCUP0505.component.html',
    styleUrls: ['./ADCUP0505.component.css']
})
export class ADCUP0505Component implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
