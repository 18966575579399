import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { parseDate } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetEDocFlow } from 'src/app/_Models/getEDocFlow';
import { GetEMPMainList } from 'src/app/_Models/AD/ADEMP/ADEMP0202/getEMPMainList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADEMP0202',
    templateUrl: './ADEMP0202.component.html',
    styleUrls: ['./ADEMP0202.component.css']
})
export class ADEMP0202Component implements OnInit {
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    imageChangedEvent: any = '';
    bloodType = require('src/app/_Models/_SelectInput/bloodType.json').bloodType;
    marriage = require('src/app/_Models/_SelectInput/marriage.json').marriage;
    month = require('src/app/_Models/_SelectInput/month.json').month;
    EMPMainList!: GetEMPMainList[];
    fileExchange = new FileExchange();
    unitList!: GetSelectList[];
    eDocFlow!: GetEDocFlow[];
    bsConfig = environment.bsDatePickerConfig;

    ngFormInput = new UntypedFormGroup({
        Code: new UntypedFormControl(''),
        Name: new UntypedFormControl(''),
        UnitCode: new UntypedFormControl(''),
        Stataus: new UntypedFormControl(''),
        ApplyDateFrom: new UntypedFormControl(''),
        ApplyDateTo: new UntypedFormControl('')
    });

    ngFormEdit = new UntypedFormGroup({
        DataKey: new UntypedFormControl(''),
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        SerialNo: new UntypedFormControl(''),
        UnitCode: new UntypedFormControl(''),
        UnitName: new UntypedFormControl(''),
        Code: new UntypedFormControl(''),
        UserName: new UntypedFormControl(''),
        JobNo: new UntypedFormControl(''),
        JobName: new UntypedFormControl(''),
        StartDate: new UntypedFormControl(''),
        EndDate: new UntypedFormControl(''),
        Reason: new UntypedFormControl(''),
        Status: new UntypedFormControl(''),
        Send: new UntypedFormControl(false)
    });

    constructor(private http: HttpClient,
        private systemService: SystemService,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private localeService: BsLocaleService,
        private datePipe: DatePipe,
        private domSanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.getUnitList();
        this.localeService.use('zh-cn');
    }

    getUnitList() {
        this.systemService.getUnitList('', '', '').subscribe(response => {
            if (response.Info == false) {
                return;
            }
            this.unitList = response;
        })
    }

    setCheck(thisCheck: boolean, index: number) {
        this.EMPMainList[index].Checked = thisCheck;
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    openModal_status(template: TemplateRef<any>, dataKey: string) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.getEDocFlow(dataKey);
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getEDocFlow(dataKey: string) {
        this.eDocFlow = [];
        this.http.post<any>(this.baseUrl + "Public/Get_EDoc_Flow", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eDocFlow = response;
        }, error => this.toastr.error(error.error))
      }

    getEMPMainList() {
        this.EMPMainList = []
        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0202/Get_EMP_Leave_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.EMPMainList = response;
            this.EMPMainList.forEach(element => {
                element.Checked = false;
            });
        })
    }

    getEMPData(code:string,serialno:string){
        if(code!=""){
            this.ngFormEdit.get("Code")?.setValue(code);
        }

        let re = / /gi;
        let Code=this.ngFormEdit.get("Code")?.value.replace(re,"");

        if(Code!=""){
            this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0202/Get_EMP_Leave_Detail", {Code:Code,SerialNo:serialno}).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.ngFormEdit.setValue(response);
            })
        }
        else{
            this.toastr.warning("請輸入人事代碼!");
        }
    }

    getLeaveModify(Status:string){
        let re = / /gi;
        let Data=this.ngFormEdit.get("UserName")?.value.replace(re,"");

        if(Data==""){
            this.toastr.warning("請確認是否有此人事資料!");
            return;
        }

        Data=this.ngFormEdit.get("EndDate")?.value;
        if(Data==""){
            this.toastr.warning("請輸入離職日期!");
            return;
        }

        Data=this.ngFormEdit.get("Reason")?.value.replace(re,"");
        if(Data==""){
            this.toastr.warning("請輸入離職原因!");
            return;
        }

        this.ngFormEdit.get("DataKey")?.setValue("");
        this.ngFormEdit.get("Status")?.setValue(Status);
        this.ngFormEdit.get("EndDate")?.setValue(this.datePipe.transform(this.ngFormEdit.get("EndDate")?.value, 'yyyy-MM-dd')!.toString());

        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0202/Get_EMP_Leave_Save", this.ngFormEdit.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success((Status=="1")?"送出成功":"儲存成功");
            this.getEMPMainList();
            this.modalRef.hide();
        });
    }

    getEMPMainDelete(){
        let blnDelete=false;

        for(var i=0;i<this.EMPMainList.length;i++){
            if(this.EMPMainList[i].Checked){
                blnDelete=true;
                break;
            }
        }

        if (!blnDelete) {
            this.toastr.info("請勾選欲刪除的資料!");
            return;
        }

        Swal.fire({
            title: `確定刪除勾選的資料?`,
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0202/Get_EMP_Leave_Delete", this.EMPMainList).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.getEMPMainList();
                });
            }
            else {
                this.toastr.info("已取消動作");
            }
        })
    }
}
