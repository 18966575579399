<div class="container" style="margin-bottom: 100px;">
    <tabset>
        <tab heading="系統設定">
            <div class="row mt-3">
                <div class="col-md-6">
                    <table class="table table-hover table-striped text-center">
                        <thead class="thead-dark-red">
                            <tr>
                                <th style="width: 60px;"></th>
                                <th>代碼</th>
                                <th>名稱</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of sysList; let i = index" (click)="getSYSDetail(item.DataKey)" class="pointer">
                                <td style="width: 60px;">{{i + 1}}</td>
                                <td>{{item.SysID}}</td>
                                <td>{{item.SysName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <form [formGroup]="ngSysForm">
                        <div class="row">
                            <div class="col-md-6">
                                <div class=" input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">系統代碼</span>
                                        <input type="text" class="form-control shadow-none" formControlName="SysID">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">系統名稱</span>
                                        <input type="text" class="form-control shadow-none" formControlName="SysName">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">英文名稱</span>
                                        <input type="text" class="form-control shadow-none" formControlName="SysEName">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">連結</span>
                                        <input type="text" class="form-control shadow-none" formControlName="RunExe">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">圖檔一 Url</span>
                                        <input type="text" class="form-control shadow-none" formControlName="Pic1">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">圖檔二 Url</span>
                                        <input type="text" class="form-control shadow-none" formControlName="Pic2">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">排序</span>
                                        <input type="number" class="form-control shadow-none" formControlName="Eorder">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">排序</span>
                                        <select class="form-select shadow-none">
                                            <option value="A">管理系統</option>
                                            <option value="W">教師</option>
                                            <option value="C">導師</option>
                                            <option value="S">學生</option>
                                            <option value="P">家長</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pt-2">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1" formControlName="FunOpen">
                                    <label class="form-check-label pointer" for="inlineCheckbox1">啟用</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox2" formControlName="CheckIP">
                                    <label class="form-check-label pointer" for="inlineCheckbox2">限制IP</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">系統分類</span>
                                        <input type="text" class="form-control shadow-none" formControlName="ParentID">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">系統描述</span>
                                        <input type="text" class="form-control shadow-none" formControlName="SysMemo">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button type="button" class="add-btn float-start me-3" (click)="getSYSModify()">新增</button>
                                <button type="button" class="other-Button-gray float-start me-3" (click)="getSYSModify()">修改</button>
                                <button type="button" class="other-Button-gray float-start me-3" (click)="getSYSDelete()">刪除</button>
                                <button type="button" class="other-Button-gray float-start me-3" (click)="reset('sys')">清除</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </tab>
        <tab heading="功能設定">
            <div class="row mt-3">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <div class="input-group">
                            <span class="input-group-text">系統分類</span>
                            <select class="form-select shadow-none" #sys (change)="getFunList()" [(ngModel)]="thisSysID">
                                <option value="" selected disabled>--請選擇--</option>
                                <option *ngFor="let item of allSysfuncList" value="{{item.DataKey}}">{{item.ItemName}} </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <table class="table table-hover table-striped text-center">
                        <thead class="thead-dark-red">
                            <tr>
                                <th style="width: 60px;"></th>
                                <th>代碼</th>
                                <th>名稱</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of funList; let i = index" (click)="getFunDetail(item.DataKey)">
                                <td style="width: 60px;">{{i + 1}}</td>
                                <td>{{item.FunID}}</td>
                                <td>{{item.FunName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <form [formGroup]="ngFunForm">
                        <div class="row">
                            <div class="col-md-6">
                                <div class=" input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">功能代碼</span>
                                        <input type="text" class="form-control shadow-none" formControlName="FunID">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">功能名稱</span>
                                        <input type="text" class="form-control shadow-none" formControlName="FunName">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">英文名稱</span>
                                        <input type="text" class="form-control shadow-none" formControlName="FunEName">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">連結</span>
                                        <input type="text" class="form-control shadow-none" formControlName="HyperLink">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">Icon 名稱</span>
                                        <input type="text" class="form-control shadow-none" formControlName="IconName">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">排序</span>
                                        <input type="number" class="form-control shadow-none" formControlName="Eorder">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text">上層功能</span>
                                        <input type="text" class="form-control shadow-none" formControlName="ParID">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 pt-2">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox3" formControlName="FunOpen">
                                    <label class="form-check-label pointer" for="inlineCheckbox3">啟用</label>
                                </div>
                            </div>
                            <div class="col-md-6 pt-2">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox4" formControlName="Std06Open">
                                    <label class="form-check-label pointer" for="inlineCheckbox4">是否開放給休學生</label>
                                </div>
                            </div>
                            <div class="col-md-6 pt-2">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox5" formControlName="Check_Purview">
                                    <label class="form-check-label pointer" for="inlineCheckbox5">是否檢查權限</label>
                                </div>
                            </div>
                            <div class="col-md-12 mt-3">
                                <button type="button" class="add-btn float-start me-3" (click)="getFunModify(true)">新增</button>
                                <button type="button" class="other-Button-gray float-start me-3" (click)="getFunModify(false)">修改</button>
                                <button type="button" class="other-Button-gray float-start me-3" (click)="getFunDelete()">刪除</button>
                                <button type="button" class="other-Button-gray float-start me-3" (click)="reset('func')">清除</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </tab>
    </tabset>
</div>
