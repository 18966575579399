import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADSTD0819',
  templateUrl: './ADSTD0819.component.html',
  styleUrls: ['./ADSTD0819.component.css']
})
export class ADSTD0819Component implements OnInit {
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  baseUrl = environment.apiUrl;
  eduList!: GetEduList[];
  deptList!: GetDeptList[];
  initialData = new InitialData(this.http);
  constructor(private http: HttpClient,
              private toastr: ToastrService,
              private fileExchangeServices: FileExchangeService,) { }

  ngOnInit(): void {
    this.getYears();
  }

  getYears(){
    this.initialData.getYears().subscribe((response: string[])=>{
      if(response){
        this.Years = response;
      }
    });
  }
  export(Year:string,Term:string){
    this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD08_19/Get_ADSTD08_19", {Years:Year,Term:Term}).subscribe((response:any) => {
      if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
      }


      let url = window.URL.createObjectURL(response.blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = response.FileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
  });
  }
}
