import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { SearchData } from'src/app/_Models/AD/ADSRP/ADSRP0102/searchData';
@Component({
    selector: 'app-ADSRP0102',
    templateUrl: './ADSRP0102.component.html',
    styleUrls: ['./ADSRP0102.component.css']
})
export class ADSRP0102Component implements OnInit {
    url=environment.apiUrl;
    searchdata!: SearchData[];
    ngOnInit(): void {
        this.search();
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService, private modalService: BsModalService) { }


    search(){
        this.searchdata=[];
        this.http.get<any>(this.url+"ACAD/SRP/ADSRP0102/SRP0102_search").subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
          this.searchdata=response;
        })
    }

    save(){
        this.http.post<any>(this.url+ "ACAD/SRP/ADSRP0102/SRP0102_update",this.searchdata).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }
}
