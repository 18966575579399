import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GetBankNo } from 'src/app/_Models/getBankNo';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetTchData } from 'src/app/_Models/TCHWEB/TCH0103/getTchData';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-TCH0103',
  templateUrl: './TCH0103.component.html',
  styleUrls: ['./TCH0103.component.css']
})
export class TCH0103Component implements OnInit {

  baseUrl = environment.apiUrl;
  tchData!: GetTchData;
  countryList!: GetSelectList[];
  bloodType = require('src/app/_Models/_SelectInput/bloodType.json').bloodType;
  marriage = require('src/app/_Models/_SelectInput/marriage.json').marriage;
  month = require('src/app/_Models/_SelectInput/month.json').month;
  fileExchange = new FileExchange();
  ImgUrl!: SafeUrl;
  bankNo!: GetBankNo[];
  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private systemService: SystemService,
    private fileExchangeServices: FileExchangeService,
    private domSanitizer: DomSanitizer, private translateService: TranslateService) { }


  ngOnInit(): void {
    this.translateService.use(this.systemService.currentLanguage)
    this.getTchData();
    this.getCountryList();
    this.getBankNo();
  }

  getBankNo() {
    this.systemService.getBankNo().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.bankNo = response;
    })
  }

  getTchData() {
    this.http.get<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_03/Get_Tch_Data").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tchData = response;
      this.tchData.EngName = this.tchData.EngName;
      this.tchData.Name = this.tchData.Name;
      this.tchData.Sex = this.tchData.Sex;
      this.tchData.MonkName = this.tchData.MonkName;
      this.tchData.Marry = this.tchData.Marry;
      this.tchData.StateName = this.tchData.StateName;
      this.tchData.License = this.tchData.License;
      this.tchData.Graduate = this.tchData.Graduate;
      this.tchData.Memo = this.tchData.Memo;
      this.tchData.NomAddress = this.tchData.NomAddress;
      this.tchData.ComAddress = this.tchData.ComAddress;
      this.tchData.NomTel = this.tchData.NomTel;
      this.tchData.ComTel = this.tchData.ComTel;
      this.tchData.EmployeeName = this.tchData.EmployeeName;
      this.tchData.TchLevelName = this.tchData.TchLevelName;
      this.tchData.TimeCaseName = this.tchData.TimeCaseName;
      this.tchData.UnitName1 = this.tchData.UnitName1;
      this.tchData.UnitName2 = this.tchData.UnitName2;
      this.tchData.DegreeType = this.tchData.DegreeType;
      this.tchData.TitleName = this.tchData.TitleName;
      this.tchData.FurtherName = this.tchData.FurtherName;
      this.tchData.ECIName = this.tchData.ECIName;
      this.tchData.EMail = this.tchData.EMail;
      this.tchData.OtherEmail = this.tchData.OtherEmail;
      this.tchData.Memo = this.tchData.Memo;
      this.tchData.LiaiseTel = this.tchData.LiaiseTel;
      this.tchData.LiaiseAddress = this.tchData.LiaiseAddress;
      this.tchData.LiaiseName = this.tchData.LiaiseName;
      this.tchData.LiaiseRelation = this.tchData.LiaiseRelation;
      this.tchData.Association = this.tchData.Association;
      this.tchData.Religion = this.tchData.Religion;
      this.tchData.DNMark = this.tchData.DNMark;



      if (response.PIC !== null) {
        this.ImgUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + response.PIC);
      }
    })
  }

  getCountryList() {
    this.systemService.getCountryList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.countryList = response;
    })
  }

  importInputCkick() {
    document.getElementById('img')?.click();
  }

  importData($event: any) {
    let file = $event.target.files[0];
    if (!file) return;
    this.fileExchange.FileToBase64(this, file, this.GetBase64, '', '')
  }

  GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
    Swal.fire({
      title: '確定上傳 ' + file.name + ' ?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: '是',
      denyButtonText: '否',
    }).then((result) => {
      if (result.isConfirmed) {
        that.stdData.PIC = base64;
        that.ImgUrl = that.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + base64);
      }
      else if (result.isDenied) {
        document.getElementById('img')?.setAttribute('value', '');
        that.toastr.info("已取消上傳。");
      }
    });
  }

  getTchModify() {
    this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_03/Get_Tch_Modify", this.tchData).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("儲存成功");
      this.getTchData();
    })
  }

}
