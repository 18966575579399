<div class="container">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="">--請選擇系所--</option>
                        <option *ngFor="let item of deptList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">關鍵字</span>
                    <input type="text" class="form-control shadow-none" formControlName="KeyWord">
                </div>
            </div>
            <div class="col-md-6">
                <button class="other-Button-gray float-end" (click)="ngForm.reset()">清除</button>
                <button class="other-Button-gray float-end me-3" (click)="getCUPCosNoDetailDelete()">刪除</button>                            
                <button class="other-Button-gray float-end me-3" (click)="getCUPCosNoDetailSave()">儲存</button>
                <button class="search-btn me-3 float-Start" (click)="getCUPCosNoList()">查詢</button>                
            </div>            
        </div>
    </form>
    <div class="row">
        <div class="col-lg-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 40px;"></th>
                        <th style="width: 70px;vertical-align: middle;">課程代碼</th>
                        <th style="width: 200px;vertical-align: middle;text-align:left;">課程名稱</th>
                        <th style="width: 120px;vertical-align: middle;text-align:left;">所屬系所</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of cosnoList; let i = index" class="pointer"
                        (click)="getCUPCosNoDetail(item)">
                        <td style="width: 40px;">{{i + 1}}</td>
                        <td style="width: 70px;">{{item.CosNo}}</td>
                        <td style="width: 200px;text-align:left;">{{item.CosName}}</td>
                        <td style="width: 120px;text-align:left;">{{item.DeptName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6">
            <form [formGroup]="ngForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">課程碼</span>
                            <input type="text" formControlName="CosNo" class="form-control shadow-none" maxlength="3"
                                placeholder="請輸入3碼" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">所屬系所</span>
                            <select class="form-select shadow-none" formControlName="DeptNo" required #DeptNo>
                                <option value="" disabled>--請選擇系所--</option>
                                <option *ngFor="let item of deptList" ngValue="{{item.ItemValue}}">
                                    {{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>                     
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">課程名稱</span>
                            <input type="text" formControlName="CosName" class="form-control shadow-none" required>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">課程簡稱</span>
                            <input type="text" formControlName="CosShort" class="form-control shadow-none" required>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">英文名稱</span>
                            <input type="text" formControlName="CosEngName" class="form-control shadow-none" required>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">教育部對照碼</span>
                            <input type="text" formControlName="EduCode" class="form-control shadow-none"
                                placeholder="上限為12碼">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">繳費科目分類</span>
                            <select class="form-select shadow-none" formControlName="SubjKind" #SubjKind>
                                <option value=""></option>
                                <option *ngFor="let item of SubjKindList" value="{{item.ItemValue}}">{{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">通識學門分類</span>
                            <select class="form-select shadow-none" formControlName="SubjGrp" #SubjGrp>
                                <option value=""></option>
                                <option *ngFor="let item of SubjGrpList" value="{{item.ItemValue}}">{{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">通識課程分類</span>
                            <select class="form-select shadow-none" formControlName="GCType" #GCType>
                                <option value=""></option>
                                <option *ngFor="let item of GcTypeList" value="{{item.ItemValue}}">{{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>                    
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">大班開課小班演練註記</span>
                            <select class="form-select shadow-none" formControlName="CollType" #CollType>
                                <option value=""></option>
                                <option *ngFor="let item of CollTypeList" value="{{item.ItemValue}}">{{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div> 
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-check form-check-inline mb-3">
                            <input class="form-check-input pointer shadow-none" type="checkbox" id="ProgType"
                                formControlName="ProgType">
                            <label class="form-check-label pointer" for="ProgType">程式設計課程註記</label>
                        </div>
                        <div class="form-check form-check-inline mb-3">
                            <input class="form-check-input pointer shadow-none" type="checkbox" id="PraType"
                                formControlName="PraType">
                            <label class="form-check-label pointer" for="PraType">校外實習課程註記</label>
                        </div>
                        <div class="form-check form-check-inline mb-3">
                            <input class="form-check-input pointer shadow-none" type="checkbox" id="IPraType"
                                formControlName="IPraType">
                            <label class="form-check-label pointer" for="IPraType">校內實習註記</label>
                        </div>                        
                    </div>                    
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改者</span>
                            <input type="text" formControlName="UpdateUser" class="form-control shadow-none" readonly>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改時間</span>
                            <input type="text" formControlName="UpdateDate" class="form-control shadow-none" readonly>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>