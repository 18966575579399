import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetStdList } from 'src/app/_Models/AD/ADSTD/ADSTD0201/getStdList';
import { GetStdListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0201/getStdListInput';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADSTD0203',
  templateUrl: './ADSTD0203.component.html',
  styleUrls: ['./ADSTD0203.component.css']
})
export class ADSTD0203Component implements OnInit {
  baseUrl = environment.apiUrl;
  fileExchange = new FileExchange();
  getStdListInput!: GetStdListInput;
  stdList!: GetStdList[];
  datakeyArray: Array<string> = [];
  constructor(private http: HttpClient, private toastr: ToastrService, private fileExchangeServices: FileExchangeService) { }

  ngOnInit(): void {
  }

  setModel(model: GetStdListInput) {
    this.getStdListInput = model;
  }

  setDataKey(datakey: string, type: string, event: any) {
    let input = <HTMLInputElement>event.target;
    if (type != 'all') {
      let index = this.datakeyArray.indexOf(datakey);
      if (index == -1 && input.checked == true) {
        this.datakeyArray.push(datakey);
      }
      else {
        this.datakeyArray.splice(index, 1);
      }
    }
    else if (type == 'all' && input.checked == true) {
      this.datakeyArray = [];
      this.stdList.forEach(element => {
        this.datakeyArray.push(element.DataKey);
        element.Selected = true;
      })
    }
    else {
      this.datakeyArray = [];
      for (let index = 0; index < this.stdList.length; index++) {
        this.stdList[index].Selected = false;
      }
    }
  }



  import() {
    document.getElementById("importData")?.click();
  }

  getStdPicUpload($event: any, dataKey: string, type: string) {
    let file = $event.target.files[0];
    if (!file) return;
    this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type);
  }

  GetBase64(that: any, base64: string, file: any, dataKey: string) {
    let json = {
      FileBase64: base64
    }
    Swal.fire({
      title: '確定上傳 ' + file.name + ' ?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: '是',
      denyButtonText: '否',
      cancelButtonText: '取消',
    }).then((result) => {
      if (result.isConfirmed) {
        that.fileExchangeServices.ImportFile('ACAD/STD/ADSTD02_03/Get_Std_Pic_Upload', json)
          .subscribe((response: any) => {
            if (response.Info == true) { that.toastr.success("上傳成功!!"); }
            else { that.toastr.error(response.Message, "執行錯誤!"); }
          });
      }
      else if (result.isDenied) {
        document.getElementById("importData")?.setAttribute('value', '');
        that.toastr.info("已取消上傳。");
      }
    });
  }

  getStdPicDownload() {

    let str = '';
    this.datakeyArray.forEach(element => {
      str += element + ','
    });
    let datakey = str.substring(0, str.length - 1);

    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_03/Get_Std_Pic_Download", { DataKey: datakey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      let contentType = "application/octet-stream"
      let blob = this.fileExchange.Base64ToBlob(response.Message, contentType, 512);
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = "學生照片.zip";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      this.datakeyArray = [];
    }, error => this.toastr.error(error.error))
  }

  getStdList() {
    if (this.getStdListInput == undefined) {
      this.toastr.info("未選取任何條件");
      return;
    }
    else {
      if (this.getStdListInput.Status == '') {
        this.toastr.info("請至少選擇學生狀態");
        return;
      }
      this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_01/Get_Std_List", this.getStdListInput).subscribe((response) => {
        if (response.Info == false) {
          this.toastr.warning(response.Message);
          this.stdList = []
          return;
        }
        this.stdList = response;
        this.stdList.forEach(element => {
          element.Selected = false;
        })
      }, error => this.toastr.error(error.error))
    }
  }

}
