import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADSTD0106',
  templateUrl: './ADSTD0106.component.html',
  styleUrls: ['./ADSTD0106.component.css']
})
export class ADSTD0106Component implements OnInit {
  term = require('src/app/_Models/_SelectInput/term.json').term;
  baseUrl = environment.apiUrl;
  Years!: Array<string>;
  initialData = new InitialData(this.http);
  fileExchange = new FileExchange();
  constructor(private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getYears();
  }

  getYears(){
    this.initialData.getYears().subscribe((response)=>{
      if(response){
        this.Years = response;
      }
    });
  }

  exportReportBase64(Years: string, Term: string){
    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_06/Export_Report_Base64", {Years: Years, Term: Term}).subscribe((response)=>{

      if(response.Info == false){
        this.toastr.warning(response.Message);
        return;
      }

      let contentType = "application/octet-stream"
      let blob = this.fileExchange.Base64ToBlob(response.Base64, contentType, 512);

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = "保留入學資格學生名冊.docx";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => this.toastr.error(error.error))
  }

}
