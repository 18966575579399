import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from 'src/assets/environments/environment';
import { auth } from '../../_Models/auth';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    baseUrl = environment.apiUrl;
    private currentUserSource = new ReplaySubject<auth | null>(1);
    currentUser$ = this.currentUserSource.asObservable();

    user!: auth;
    constructor(private http: HttpClient) { }

    login(model: any) {
        return this.http.post<auth>(this.baseUrl + 'Login/Check_Validation_Code', model).pipe(
            map((auth: auth) => {
                if (auth) {
                    this.setCurrentUser(auth);
                }
                return auth;
            }));
    }

    setCurrentUser(auth: auth) {
        sessionStorage.setItem('auth', JSON.stringify(auth));
        this.currentUserSource.next(auth);
    }

    autoLogin() {
        this.user = JSON.parse(sessionStorage.getItem('auth') || '{}');
        this.currentUserSource.next(this.user);
    }

    logout() {
        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('onlineMeeting');
        this.currentUserSource.next(null);
    }
}
