<div class="container">
    <div class="row">
        <div class="col-md-4">
            <div class="input-group mb-3">
                <span class="input-group-text">代碼</span>
                <input type="text" class="form-control shadow-none" maxlength="5" [(ngModel)]="dataDetail.Entry_Duct"
                    placeholder="至多5碼">
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group mb-3">
                <span class="input-group-text">名稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Entry_Duct_Name">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="clear()">清除</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="add-btn float-end me-3" (click)="insert()">新增</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th>代碼</th>
                <th>名稱</th>
                <th>修改者</th>
                <th>修改時間</th>
            </tr>
        </thead>
        <tbody>
            <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                *ngFor="let item of data; let i = index" class="pointer" (click)="detail(i)">
                <td>{{i + 1}}</td>
                <td>{{item.Entry_Duct}}</td>
                <td>{{item.Entry_Duct_Name}}</td>
                <td>{{item.Update_User}}</td>
                <td>{{item.Update_Time}}</td>
            </tr>
        </tbody>
    </table>
</div>