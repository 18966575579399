<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year [(ngModel)]="model.Years">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term [(ngModel)]="model.Term">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">製單類別</span>
                </div>
                <select class="form-select shadow-none" #MakeDocNo [(ngModel)]="model.MakeDocNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of makeDocList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>

    </div>

    <div class="row mb-3">
        <div class="col-md-12">
            <button class="search-btn float-start me-3" (click)="getTFSMakeDocOFHIStdList()">查詢</button>
            <button class="other-Button-gray float-start me-3" (click)="getTFSMakeDocOFHIMakeDoc()">製作</button>
            <button class="other-Button-gray float-start me-3" (click)="getTFSMakeDocOFHISend()">繳交出納</button>
            <button class="other-Button-gray float-start me-3"
                (click)="getTFSMakeDocReportBase64(Year.value, Term.value, MakeDocNo.value)">繳交清冊</button>
            <button class="save-btn-gray float-end" (click)="getTFSMakeDocOFHISave()">儲存</button>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md">
            <table class="table table-hover text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 50px;"></th>
                        <th>班級</th>
                        <th>學號</th>
                        <th>姓名</th>
                        <th>保險金額</th>
                        <th>狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of tFSMakeDocOFHIStdList; let i = index" class="pointer"
                        (click)="getTFSMakeDocOFHIDetail(i, item.DataKey)">
                        <td style="width: 50px;">{{i + 1}}</td>
                        <td>{{item.ClassName}}</td>
                        <td>{{item.StdNo}}</td>
                        <td>{{item.StdName}}</td>
                        <td>{{item.Money}}</td>
                        <td>{{item.StatusName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md">
            <table class="table table-hover text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 50px;"></th>
                        <th>收費代碼</th>
                        <th>收費項目</th>
                        <th>預設金額</th>
                        <th>應繳金額</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let i = index; let item of tFSMakeDocOFHIDetail">
                        <td style="width: 50px;">{{i+1}}</td>
                        <td>{{item.FeeNo}}</td>
                        <td style="width: 120px;">{{item.FeeName}}</td>
                        <td>{{item.DefaultMoney}}</td>
                        <td><input type="text" style="width: 80px;" class="form-control form-control-input"
                                [(ngModel)]="item.MustMoney" [disabled]="item.Edit != 'Y'"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>