import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetFunDetail } from 'src/app/_Models/AD/ADSYS/ADSYS0301/getFunDetail';
import { GetFunList } from 'src/app/_Models/AD/ADSYS/ADSYS0301/getFunList';
import { GetSYSList } from 'src/app/_Models/AD/ADSYS/ADSYS0301/getSYSList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSYS0301',
    templateUrl: './ADSYS0301.component.html',
    styleUrls: ['./ADSYS0301.component.css']
})
export class ADSYS0301Component implements OnInit {
    baseUrl = environment.apiUrl;
    sysList!: GetSYSList[];
    funList!: GetFunList[];
    allSysfuncList!: GetSelectList[];
    thisSysID: string = "";

    ngSysForm = new UntypedFormGroup({
        DataKey: new UntypedFormControl(''),
        SysID: new UntypedFormControl('', [Validators.required]),
        SysName: new UntypedFormControl('', [Validators.required]),
        SysEName: new UntypedFormControl(''),
        Eorder: new UntypedFormControl(0, [Validators.required]),
        SysMemo: new UntypedFormControl(''),
        RunExe: new UntypedFormControl('', [Validators.required]),
        Pic1: new UntypedFormControl(''),
        Pic2: new UntypedFormControl(''),
        ApWeb: new UntypedFormControl(''),
        ParentID: new UntypedFormControl(''),
        TargetName: new UntypedFormControl(''),
        FunOpen: new UntypedFormControl(false),
        CheckIP: new UntypedFormControl(false),
    });

    ngFunForm = new UntypedFormGroup({
        DataKey: new UntypedFormControl(''),
        SysID: new UntypedFormControl('', [Validators.required]),
        FunID: new UntypedFormControl('', [Validators.required]),
        FunName: new UntypedFormControl('', [Validators.required]),
        FunEName: new UntypedFormControl(''),
        Help: new UntypedFormControl(''),
        Simple: new UntypedFormControl(''),
        HyperLink: new UntypedFormControl(''),
        Eorder: new UntypedFormControl(''),
        Kind: new UntypedFormControl(''),
        IconName: new UntypedFormControl(''),
        ParID: new UntypedFormControl('', [Validators.required]),
        FunOpen: new UntypedFormControl(false),
        TargetName: new UntypedFormControl(''),
        Std06Open: new UntypedFormControl(false),
        Check_Purview: new UntypedFormControl(false, [Validators.required]),
    });

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService) { }

    ngOnInit(): void {
        this.getSYSList();
        this.getAllSysFunc();
    }

    getAllSysFunc() {
        this.systemService.getAllSysFunc().subscribe((response) => {
            this.allSysfuncList = response;
        });
    }

    getSYSList() {
        this.http.get<any>(this.baseUrl + "ACAD/SYS/ADSYS0301/Get_SYS_List").subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sysList = response;
        });
    }

    getSYSDetail(datakey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0301/Get_SYS_Detail", { DataKey: datakey }).subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.ngSysForm.setValue(response);
        }));
    }

    getSYSModify() {

        if (!this.ngSysForm.valid) {
            this.toastr.info("請填寫欄位")
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0301/Get_SYS_Modify", this.ngSysForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.getSYSList();
        });
    }

    getSYSDelete() {

        if (this.ngSysForm.get('DataKey')?.value == '') return;

        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0301/Get_SYS_Delete", { DataKey: this.ngSysForm.get('DataKey')?.value }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.getSYSList();
        })
    }

    getFunList() {
        this.thisSysID;

        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0301/Get_Fun_List", { DataKey: this.thisSysID }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.funList = response;
        });
    }

    getFunDetail(datakey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0301/Get_Fun_Detail", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.ngFunForm.setValue(response);
        });
    }

    getFunModify(isInsert: boolean) {

        this.ngFunForm.get('SysID')?.setValue(this.thisSysID);

        if (!this.ngFunForm.valid) {
            this.toastr.info("請填寫欄位")
            return;
        }

        if (this.ngFunForm.get('HyperLink')?.value == null) {
            this.ngFunForm.get('HyperLink')?.setValue('');
        }

        if (this.ngFunForm.get('TargetName')?.value == null) {
            this.ngFunForm.get('TargetName')?.setValue('');
        }

        if (this.ngFunForm.get('IconName')?.value == null) {
            this.ngFunForm.get('IconName')?.setValue('');
        }

        if (this.ngFunForm.get('FunEName')?.value == null) {
            this.ngFunForm.get('FunEName')?.setValue('');
        }

        if (isInsert) {
            this.ngFunForm.get('DataKey')?.setValue('');
        }

        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0301/Get_Fun_Modify", this.ngFunForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.getFunList();
            this.toastr.success("儲存成功");
        });
    }

    getFunDelete() {

        if (this.ngFunForm.get('DataKey')?.value == '') return;

        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0301/Get_Fun_Delete", { DataKey: this.ngFunForm.get('DataKey')?.value }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.getFunList();
            this.toastr.success("刪除成功");
        });
    }

    reset(type: string) {
        if (type == 'sys') {
            this.ngSysForm.reset({
                FunOpen: false,
                CheckIP: false,
                DataKey: ''
            });
        } else {
            this.ngFunForm.reset({
                SysID: this.thisSysID,
                FunOpen: false,
                Std06Open: false,
                Check_Purview: false,
                Help: '',
                TargetName: '',
                Simple: '',
                Kind: '',
                FunEName: '',
                DataKey: ''
            });
        }
    }

}
