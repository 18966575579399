import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetApplyData } from 'src/app/_Models/STDWEB/STDW10013ISU/getApplyData';
import { GetSearchData } from 'src/app/_Models/STDWEB/STDW10013ISU/getSearchData';
import { GetExportData } from 'src/app/_Models/STDWEB/STDW10013ISU/getExportData';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW10013ISU',
    templateUrl: './STDW10013ISU.component.html',
    styleUrls: ['./STDW10013ISU.component.css']
})
export class STDW10013ISUComponent implements OnInit {
    modalRef!: BsModalRef;
    baseUrl = environment.apiUrl;
    getApplyData!: GetApplyData[];
    getSearchData!: GetSearchData[];
    getSearchdetailData!: GetSearchData[];
    getExportData!: GetExportData[];
    checkall!: boolean;
    check!: boolean;
    searchdata: GetSearchData = {
        SerialNo: '',
        Years: '',
        Term: '',
        StdNo: '',
        StdName: '',
        ApplyNo: '',
        ApplyName: '',
        ApplyDate: '',
        ChkStatus: '',
        ChkStatusName: '',
        NeedSend: true,
        Checked: false
    }
    constructor(
        private modalService: BsModalService,
        private http: HttpClient,
        private toastr: ToastrService, private fileExchangeServices: FileExchangeService
    ) { }

    ngOnInit(): void {
        this.applyselect();
    }

    search() {
        this.getSearchData = []
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_13ISU/Search", this.searchdata).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.getSearchData = response;
        })
    }

    searchdetail() {
        this.getSearchdetailData = []
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_13ISU/SearchDetail", this.searchdata).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.getSearchdetailData = response;
        })
    }

    checkchange() {
        for (let y of this.getSearchData) {
            if (y.Checked == true) {
                this.check = true;
                return;
            }
            this.check = false;
        }
    }

    insert(i: number) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_13ISU/Insert", this.getSearchdetailData[i]).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.toastr.success("新增成功")
            this.modalRef.hide()
            this.search()
        })

    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: "modal-lg"
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    allchange() {
        for (let y of this.getSearchData) {
            if (this.checkall == true) {
                y.Checked = true;
                this.check = true;
            }
            else {
                y.Checked = false;
                this.check = false;
            }
        }
    }


    change(ApplyNo: string) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_13ISU/SearchDetail", { ApplyNo: ApplyNo }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.getSearchdetailData = response;
        })
    }

    applyselect() {
        this.http.post<any>(this.baseUrl + "Public/ApplyCertificate_List", { ApplyType: "B" }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.getApplyData = response;
        })
    }

    delete() {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW100_13ISU/Delete", this.getSearchData).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.toastr.success("刪除成功")
            this.check = false;
            this.search()
        })
    }

    export(i: number) {
        this.getSearchdetailData.forEach(element => {
            element.Checked=false;
        });

        this.getSearchdetailData[i].Checked=true;

        this.fileExchangeServices.ExportFile("ACAD/STDWEB/STDW100_13ISU/Export", this.getSearchdetailData).subscribe((response) => {
            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }, error => this.toastr.error(error.error))
    }
}
