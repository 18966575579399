import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetStdListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0201/getStdListInput';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSTDStatusList } from 'src/app/_Models/getSTDStatusList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { GetHolidayList } from 'src/app/_Models/SAC/SAC1701/getHolidayList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADABS0301',
    templateUrl: './ADABS0301.component.html',
    styleUrls: ['./ADABS0301.component.css']
})
export class ADABS0301Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    dataKeyArray: Array<string> = [];
    eduList!: GetEduList[];
    teamList!: GetTeamList[];
    holidayList!: GetHolidayList[];
    deptList!: GetDeptList[];
    sTDStatusList!: GetSTDStatusList[];
    @Output() childEvent = new EventEmitter<any>();
    getStdListInput: GetStdListInput = {
        EduNo: '',
        DeptNo: '',
        TeamNo: '',
        Grade: '',
        Class: '',
        ClassID: '',
        Status: '',
        IDNo: '',
        StdName: '',
        StdNoFrom: '',
        StdNoTo: ''
    }
    model = {
        Years: '',
        Term: ''
    }

    ngOnInit(): void {
        this.getYears();
        this.getHolidayList();
    }

    getHolidayList() {
        this.systemService.getHolidayList().subscribe(response => {
            this.holidayList = response;
        });
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService) { }

    getYears() {
        this.initialData.getYears().subscribe((response: string[]) => {
            if (response) {
                this.Years = response;
            }
        }, (error: { error: string | undefined; }) => this.toastr.error(error.error));
    }

    GetData() {
        this.childEvent.emit(this.getStdListInput);
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getTeamList(dept: string) {
        this.systemService.getTeamList('', this.model.Years, this.model.Term, dept, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.teamList = response;
        });
    }

    getEduList() {
        this.systemService.getEduList('', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }
}
