<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value, false)">
                    <option ngValue="" selected disabled>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" ngValue="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" [(ngModel)]="Dept_No" (change)="search()">
                    <option ngValue="" disabled>--請選擇系所--</option>
                    <option *ngFor="let item of deptList" ngValue="{{item.Dept_No}}">{{item.Dept_Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="clear()">清除</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="add-btn float-end me-3" (click)="insert()">新增</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>類別代碼</th>
                        <th>類別名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="searchDetail(i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.CurClass}}</td>
                        <td>{{item.CurClass_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Dept_No" [disabled]="!isEdit">
                            <option ngValue="" disabled>--請選擇系所--</option>
                            <option *ngFor="let item of detailDeptList" ngValue="{{item.Dept_No}}">
                                {{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">類別代碼</span>
                        <input type="text" class="form-control shadow-none" maxlength="1" [disabled]="!isEdit"
                            [(ngModel)]="dataDetail.CurClass" placeholder="至多一碼">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">類別名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.CurClass_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_User">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_Time">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>