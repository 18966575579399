<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled selected>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學號</span>
                </div>
                <input type="text" class="form-control ">
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">姓名</span>
                </div>
                <input type="text" class="form-control ">
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">身分證</span>
                </div>
                <input type="text" class="form-control ">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">獎懲類別</span>
                </div>
                <select class="form-select shadow-none">
                    <option value="" selected disabled></option>
                </select>
            </div>
        </div>
        <div class="col-md-1">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">大於</span>
                </div>
                <input type="text" class="form-control ">
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-8">
            <button type="button" class="search-btn float-start me-3">查詢</button>
        </div>
    </div>
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>班級</th>
                <th>學號</th>
                <th>姓名</th>
                <th>嘉獎</th>
                <th>小功</th>
                <th>大功</th>
                <th>申誡</th>
                <th>小過</th>
                <th>大過</th>
                <th>退學</th>
                <th>留校察看</th>
                <th>警告</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #column>
    <div class="modal-header">
        <h4 class="modal-title pull-left">歷年獎懲資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <input type="text" class="form-control ">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control ">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">姓名</span>
                    </div>
                    <input type="text" class="form-control ">
                </div>
            </div>
        </div>
        <table class="table text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th>學年</th>
                    <th>學期</th>
                    <th>發生日期</th>
                    <th>事由</th>
                    <th>獎懲紀錄</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>