<div class="container">
    <form [formGroup]="ngSearchForm" (ngSubmit)="getABSAdminList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Years" (change)="getClassList()">
                        <option value="">--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Term" (change)="getClassList()">
                        <option value="">--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">假別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="HCode">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor=" let item of holidayList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">請假日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" formControlName="RecDateFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" formControlName="RecDateTo">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">指定</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="FieldType">
                        <option value="1">學號</option>
                        <option value="2">姓名</option>
                        <option value="3">身分證號</option>
                    </select>
                    <input type="text" class="form-control shadow-none" formControlName="FieldTypeValueFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="FieldTypeValueTo">
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <button class="search-btn float-start me-3">查詢</button>
                <button class="other-Button-gray float-start me-3" type="button" (click)="getABSAdminAdjust()">資料檢整</button>
                <button class="other-Button-gray float-start me-3" type="button" (click)="getABSAdminBatchDelete()">刪除</button>
                <button class="other-Button-gray float-start me-3" type="button" (click)="openModal('new', template)">新增請假</button>
            </div>
        </div>
    </form>


    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 80px;"><input type="checkbox" class="form-check-input pointer shadow-none">&ensp;全選
                </th>
                <th style="width: 80px;">學年</th>
                <th style="width: 80px;">學期</th>
                <th style="width: 80px;">假別</th>
                <th>學號</th>
                <th>姓名</th>
                <th>日期</th>
                <th>節次</th>
                <th>修改者</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of aBSAdminList">
                <td style="width: 80px;"><input type="checkbox" class="form-check-input pointer shadow-none" [(ngModel)]="item.Checked"></td>
                <td style="width: 80px;">{{item.Years}}</td>
                <td style="width: 80px;">{{item.Term}}</td>
                <td style="width: 80px;">{{item.HCodeName}}</td>
                <td><a class="pointer text-decoration-none" (click)="openModal('', template, item)">{{item.StdNo}}</a>
                </td>
                <td>{{item.StdName}}</td>
                <td>{{item.RecDate}}</td>
                <td>{{item.RecSection}}</td>
                <td>{{item.UpdateUserName}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">新增請假</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year [disabled]="aBSAdminContent.DataKey != ''" [(ngModel)]="aBSAdminContent.Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term [disabled]="aBSAdminContent.DataKey != ''" [(ngModel)]="aBSAdminContent.Term">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="aBSAdminContent.ClassName">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none pointer" [disabled]="aBSAdminContent.DataKey != ''" [(ngModel)]="aBSAdminContent.StdNo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">姓名</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="aBSAdminContent.StdName">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">請假日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" [disabled]="aBSAdminContent.DataKey != ''" [(ngModel)]="aBSAdminContent.RecDateS">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" [disabled]="aBSAdminContent.DataKey != ''" [(ngModel)]="aBSAdminContent.RecDateE">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">假別</span>
                    </div>
                    <select class="form-select shadow-none" [disabled]="aBSAdminContent.DataKey != ''" [(ngModel)]="aBSAdminContent.HCode">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor=" let item of holidayList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" [hidden]="aBSAdminContent.DataKey != ''">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">上傳範本</span>
                    </div>
                    <input class="form-control shadow-none" type="file" (change)="importData($event)" id="file" value="">
                    <div class="input-group-prepend">
                        <button class="btn btn-light shadow-none" type="button" (click)="getABSAdminSampleFile()">範本下載</button>
                    </div>
                    <div class="input-group-prepend">
                        <button class="btn btn-light shadow-none" type="button" (click)="resetFile()">清除</button>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <label class="me-3">節次</label>
                <ng-container *ngFor="let item of curTime; let i = index">
                    <div class="form-check form-check-inline me-2">
                        <input class="form-check-input pointer shadow-none" type="checkbox" value="{{item.number}}" id="{{item.number}}" [checked]="item.checked" (click)="setCurTime($event.target,i)">
                        <label class="form-check-label pointer" for="{{item.number}}">第{{item.number}}節</label>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-12">
                <div class="input-group">
                    <span class="input-group-text">請假事由</span>
                    <textarea class="form-control shadow-none" aria-label="With textarea" appTextareaAutoresize [(ngModel)]="aBSAdminContent.Memo"></textarea>
                </div>
            </div>
            <div>
                <button class="save-btn-gray mx-auto mt-3" (click)="getABSAdminSave()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>
