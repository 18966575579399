import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0101/searchDetail';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADRGP0101',
    templateUrl: './ADRGP0101.component.html',
    styleUrls: ['./ADRGP0101.component.css']
})
export class ADRGP0101Component implements OnInit {
    baseUrl = environment.apiUrl;
    data!: SearchDetail;
    constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService, private domSanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.searchDetail();
    }
    croppedImageA: any = '';
    imageChangedEvent: any = '';
    ImgUrl!: SafeUrl;
    modalRef?: BsModalRef;
    showCropper = false;
    croppedImage: any = '';
    searchDetail() {
        this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0101/Search_Detail").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response;
            this.data.Com_Address = this.data.Com_Address;
            this.data.Principal = this.data.Principal;
            this.data.Sch_Name = this.data.Sch_Name;
            this.data.Website = this.data.Website;
            this.data.Sch_Short = this.data.Sch_Short;
            this.data.Com_Fax = this.data.Com_Fax;
            this.data.Sch_EngName = this.data.Sch_EngName;
            this.data.Eng_Address = this.data.Eng_Address;
            this.data.Com_Tel = this.data.Com_Tel;
            this.ImgUrl = this.data.Sch_Image;
        }, error => this.toastr.error(error.error))
    }
    openModal1(template: TemplateRef<any>) {
        this.croppedImageA = '';
        this.imageChangedEvent = null;
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file + ' ?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.data.Sch_Image = base64;
                that.ImgUrl = that.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + base64);
            }
            else if (result.isDenied) {
                document.getElementById('img')?.setAttribute('value', '');
                that.toastr.info("已取消上傳。");
            }
        });
    }

    uploadImage1() {
        let y = sessionStorage.getItem("file")
        this.GetBase64(this, this.croppedImage.split(',')[1], y, '', '')
        this.imageChangedEvent = '';
        this.croppedImageA = '';
    }

    imageCropped1(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.croppedImageA = event.base64;
    }
    loadImageFailed() {
        this.toastr.info("圖片格式不符");
    }

    imageLoaded(image: LoadedImage) {
        this.showCropper = true;
        this.toastr.info("圖片加載成功!");
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        sessionStorage.setItem("file", event.target.files[0].name)
    }

    update() {
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0101/Update", this.data).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        }, error => this.toastr.error(error.error))
    }

}
