<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" #Year formControlName="Years" [(ngModel)]="model.Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學期</span>
                    <select class="form-select shadow-none" #Term formControlName="Term" [(ngModel)]="model.Term">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #edu
                        (change)="getDeptList(edu.value)" formControlName="EduNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                            {{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" #dept
                        formControlName="DeptNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">
                            {{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">班別</span>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">
                            {{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div> -->
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">狀態</span>
                    <select class="form-select shadow-none" formControlName="Status">
                        <option value="">全部</option>
                        <option value="1">未確認</option>
                        <option value="B">退回</option>
                        <option value="2">系辦已確認/系主任所長未確認</option>
                        <option value="3">系主任所長已確認/註冊組未確認</option>
                        <option value="4">註冊組已確認/學生未確認</option>
                        <option value="5" selected>學生已確認</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學號</span>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoFrom">
                </div>
            </div>
            <div class="col-md-3">
                <button type="button" class="other-Button-gray float-end" (click)="SaveData();">儲存</button>
                <button class="search-btn float-end me-3" (click)="getStdList();">查詢</button>
            </div>
        </div>
    </form>
    <div style="height:420px;overflow-y:scroll;">
        <table class="table table-hover table-striped text-center mt-3" style="font-size:14px;vertical-align:top;">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width:130px;text-align:left;">學制</th>
                    <th style="width:130px;text-align:left;">班級名稱</th>
                    <th style="width:100px;text-align:left;">學號</th>
                    <th style="width:100px;text-align:left;">姓名</th>
                    <th style="width:370px;text-align:left;">畢業審查結果</th>
                    <th style="width:240px;text-align:left;">狀態</th>
                    <th style="width:60px;">可畢業</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of stdList">
                    <td style="width:130px;text-align:left;">{{item.EduName}}</td>
                    <td style="width:130px;text-align:left;">{{item.ClassName}}</td>
                    <td style="width:100px;text-align:left;">{{item.StdNo}}</td>
                    <td style="width:100px;text-align:left;">{{item.StdName}}</td>
                    <td style="width:370px;text-align:left;">
                        <input type="checkbox" class="form-check-input shadow-none pointer" [disabled]="true" [(ngModel)]="item.PassGradCredit">已修滿本系畢業總學分及科目。
                        <input type="checkbox" class="form-check-input shadow-none pointer" [disabled]="true" [(ngModel)]="item.PassGradThreShold">已修滿本系畢業門檻。
                    </td>
                    <td style="width:240px;text-align:left;">{{item.Status}}</td>
                    <td style="width:60px;">
                        <input type="checkbox" class="form-check-input shadow-none pointer" #Graduate [(ngModel)]="item.Graduate">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>