import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0102/search';
import { GetSYSUserList } from 'src/app/_Models/AD/ADSYS/ADSYS0201/getSYSUserList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUR0401',
    templateUrl: './ADCUR0401.component.html',
    styleUrls: ['./ADCUR0401.component.css']
})
export class ADCUR0401Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    deptList!: GetDeptList[];
    eduList!: GetEduList[];
    classList!: GetClassList[];
    baseUrl = environment.apiUrl;
    buildingList!: Search[];
    unitData!: GetSelectList[];
    sYSUserList!: GetSYSUserList[];

    // 與大樓的介面都是叫Search，先用any替代，之後再來想要怎麼處理。
    classRoomList!: any;

    ngForm = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        Type: new UntypedFormControl('C', [Validators.required]),
        DeptNo: new UntypedFormControl(''),
        ClassFrom: new UntypedFormControl(''),
        ClassTo: new UntypedFormControl(''),
        UnitCode: new UntypedFormControl(''),
        TchNoFrom: new UntypedFormControl(''),
        TchNoTo: new UntypedFormControl(''),
        BuildingNo: new UntypedFormControl(''),
        ClassroomFrom: new UntypedFormControl(''),
        ClassroomTo: new UntypedFormControl(''),
    })

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
        this.getEduList();
        this.getYears();
        this.getBuildingList();
        this.getUnitList();
    }

    getYears(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }


    getEduList() {
        this.systemService.getEduList('1', this.ngForm.get('Years')?.value, this.ngForm.get('Term')?.value, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '1', this.ngForm.get('Years')?.value, this.ngForm.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '1', this.ngForm.get('Years')?.value, this.ngForm.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getBuildingList() {

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0102/Search", { ClassArea: '' }).subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.buildingList = response;
        })
    }

    getClassRoomList(ClassKind: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0104/Search", {
            ClassArea: '',
            ClassKind: ClassKind,
            ClassType: ''
        }).subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classRoomList = response;
        });
    }

    getUnitList() {
        this.systemService.getUnitList(this.ngForm.get('Years')?.value, this.ngForm.get('Term')?.value, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.unitData = response;
        })
    }

    getSYSUserList(UnitNo: string) {
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0201/Get_SYS_UserList", {
            UserCode: '',
            UserName: '',
            Account: '',
            UnitNo: UnitNo
        }).subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.sYSUserList = response;
        })
    }

    exportReport() {

        if (!this.ngForm.valid) {
            this.toastr.info("請選擇學年學期以及課表類型");
            return;
        }

        this.fileExchangeServices.ExportFile("ACAD/CUR/ADCUR0401/Export_Report_ADCUR0401", this.ngForm.value)
            .subscribe((response) => {

                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }

                if (response.FileName == '') {
                    this.toastr.warning("查無資料");
                    return;
                }
                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = response.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }, error => this.toastr.error(error));
    }


}
