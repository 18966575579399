import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADSTD0802',
  templateUrl: './ADSTD0802.component.html',
  styleUrls: ['./ADSTD0802.component.css']
})
export class ADSTD0802Component implements OnInit {
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  baseUrl = environment.apiUrl;
  initialData = new InitialData(this.http);
  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private fileExchangeServices: FileExchangeService,
    private systemService: SystemService,) { }

  ngOnInit(): void {
    this.getYears();
  }

  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    });
  }

  exportReportADSTD0802(Years: string, Term: string) {
    this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD08_02/Export_Report_ADSTD0802", { Years: Years, Term: Term }).subscribe((response) => {
      if (response.FileName == '') {
        this.toastr.warning("查無資料");
        return;
      }
      let url = window.URL.createObjectURL(response.blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = response.FileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => this.toastr.error(error.error))
  }

}
