import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { auth } from 'src/app/_Models/auth';
import { GetSAABSRecord } from 'src/app/_Models/STDWEB/STDW60003/getSAABSRecord';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW60003',
    templateUrl: './STDW60003.component.html',
    styleUrls: ['./STDW60003.component.css']
})
export class STDW60003Component implements OnInit {

    baseUrl = environment.apiUrl;
    records!: GetSAABSRecord[]
    checkType = new CheckType();
    inputModel = {
        StdNo: '',
        Years: '',
        Term: ''
    }
    user!: auth;
    searchForm: any;

    constructor(private http: HttpClient, private toastr: ToastrService, private timeService: TimeService, private accountService: AccountService, private systemService: SystemService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.inputModel.Years = response.Now_Years;
            this.inputModel.Term = response.Now_Term;
            this.query(this.inputModel)
        });
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.user = response;
                this.inputModel.StdNo = response.Code;
                this.user.UserName = response.UserName;
            }
        }, error => this.toastr.error(error.error));
    }
    getLeaveList() {
        throw new Error('Method not implemented.');
    }

    getRecord(model: any) {
        this.inputModel.Term = model.Term;
        this.inputModel.Years = model.Years;
    }
    query(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW600_03/Search", model).subscribe((response) => {
            this.records = []
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.records = response;
        });
    }

}
