<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">所屬校區</span>
                <select class="form-select" #ClassArea [(ngModel)]="Condition_ClassArea"
                    (ngModelChange)="ClassArea_Onchange(ClassArea.value, true)">
                    <option value="" disabled>--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of Condition_ClassArea_List">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">所屬大樓</span>
                <select class="form-select" [(ngModel)]="Condition_ClassKind">
                    <option value="">--全部--</option>
                    <option [ngValue]="item.ItemValue" *ngFor="let item of Condition_ClassKind_List">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">教室型態</span>
                <select class="form-select" [(ngModel)]="Condition_ClassType">
                    <option value="">--全部--</option>
                    <option [ngValue]="item.ItemValue" *ngFor="let item of Condition_ClassType_List">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-9">
            <button class="other-Button-gray float-end" (click)="clear()">清除</button>
            <button *ngIf="readonly==true" class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button *ngIf="readonly==true" class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="other-Button-gray float-end me-3" (click)="insert()">新增</button>
            <button class="search-btn me-3 float-end" (click)="search()">查詢</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-8">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width:40px;"></th>
                        <th>所屬大樓</th>
                        <th>教室型態</th>
                        <th>教室代碼</th>
                        <th>教室名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="searchDetail(item.Classroom, i)">
                        <td style="width:40px;">{{i + 1}}</td>
                        <td>{{item.ClassKind_Name}}</td>
                        <td>{{item.ClassType_Name}}</td>
                        <td>{{item.Classroom}}</td>
                        <td>{{item.Classroom_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">教室代碼</span>
                        <input type="text" [readonly]="readonly" class="form-control shadow-none"
                            [(ngModel)]="dataDetail.Classroom">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">教室名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Classroom_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">所屬校區</span>
                        <select #Data_ClassArea class="form-select" [(ngModel)]="dataDetail.ClassArea"
                            (ngModelChange)="ClassArea_Onchange(Data_ClassArea.value, false)">
                            <option value="" disabled>--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of Data_ClassArea_List">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">所屬大樓</span>
                        <select class="form-select" [(ngModel)]="dataDetail.ClassKind">
                            <option value="" disabled>--請選擇--</option>
                            <option [ngValue]="item.ItemValue" *ngFor="let item of Condition_ClassKind_List">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">教室型態</span>
                        <select class="form-select" [(ngModel)]="dataDetail.ClassType">
                            <option value="" disabled>--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of Data_ClassType_List">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">容納人數</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Capacity">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">教室列數</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Class_Cols">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">教室行數</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Class_Rows">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">備註</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Memo">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_User">
                    </div>
                </div>
                <div class="col-md">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_Time">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>