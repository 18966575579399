<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">退學學年</span>
                <select class="form-select shadow-none" [(ngModel)]="stdOutListInput.OutYear">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">退學學期</span>
                <select class="form-select shadow-none" [(ngModel)]="stdOutListInput.OutTerm">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">是否核准</span>
                <select class="form-select shadow-none" [(ngModel)]="stdOutListInput.Approve">
                    <option value="" disabled>--請選擇--</option>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #eduNo (change)="getDeptList(eduNo.value)"
                    [(ngModel)]="stdOutListInput.EduNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" #deptNo (change)="getClassList(eduNo.value, deptNo.value)"
                    [(ngModel)]="stdOutListInput.DeptNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班級</span>
                <select class="form-select shadow-none" [(ngModel)]="stdOutListInput.Class">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdOutListInput.StdNo">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdOutListInput.StdName">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">身分字號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdOutListInput.IDNo">
            </div>
        </div>
        <div class="col-md-3">
            <button class="search-btn float-start me-3" (click)="getStdOutList(1)">查詢</button>
            <button class="other-Button-gray float-start" (click)="newData(template)">新增</button>
        </div>
    </div>

    <table class="table table-striped table-hover text-center">
        <thead class="thead-dark-red">
            <tr>
                <th class="tdW60 TextVAlignMiddle">項次</th>
                <th class="tdW60 TextVAlignMiddle">學年</th>
                <th class="tdW60 TextVAlignMiddle">學期</th>
                <th class="TextVAlignMiddle">學號</th>
                <th class="TextVAlignMiddle">姓名</th>
                <th class="TextVAlignMiddle">班級</th>
                <th class="TextVAlignMiddle">退學原因</th>
                <th class="tdW60 TextVAlignMiddle">是否核准</th>
                <th class="tdW60 TextVAlignMiddle">完成註冊</th>
                <th class="tdW60 TextVAlignMiddle">名額內外</th>
                <th class="TextVAlignMiddle">詳細資料</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdOutList; index as i;">
                <td class="tdW60">{{i+1}}</td>
                <td class="tdW60">{{item.OutYear}}</td>
                <td class="tdW60">{{item.OutTerm}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.OutReason}}</td>
                <td class="tdW60">{{item.Approve}}</td>
                <td class="tdW60">{{item.RegiLog}}</td>
                <td class="tdW60">{{item.IsAddition}}</td>
                <td><a class="text-decoration-none pointer"
                        (click)="getStdOutDetail(item.DataKey, template, false)">詳細資料</a></td>
            </tr>
        </tbody>
    </table>
    <p>每頁{{pagecount}}筆 共{{this.stdOutListInput.Count}}筆資料    目前在{{this.stdOutListInput.Page}}/{{this.stdOutListInput.TotalPage}}頁</p>
    <button class="other-Button-gray float-start" (click)="minuspage()">上一頁</button><button class="other-Button-gray float-start" (click)="pluspage()">下一頁</button>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">學生退學明細</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學號</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdOutDetail.StdNo"
                        [disabled]="!isNewData" (blur)="getStdOutDetail(stdOutDetail.StdNo, template, true)">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">姓名</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdOutDetail.StdName" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">退學學年</span>
                    <select class="form-select shadow-none" #Year [(ngModel)]="stdOutDetail.OutYear"
                        [disabled]="!isNewData">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">退學學期</span>
                    <select class="form-select shadow-none" #Term [(ngModel)]="stdOutDetail.OutTerm"
                        [disabled]="!isNewData">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">退學原因</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdOutDetail.OutNo">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of stdOutReasonList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">退學日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" [(ngModel)]="stdOutDetail.OutDate"
                        style="background-color: transparent;">
                </div>
            </div>            
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">證書字號</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdOutDetail.CerSchNo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label>備註</label>
                <textarea class="form-control shadow-none" appTextareaAutoresize
                    [(ngModel)]="stdOutDetail.Memo"></textarea>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">核准日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly [disabled]="!isNewData" [(ngModel)]="stdOutDetail.ApproveDate"
                        style="background-color: transparent;">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">核准退學</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdOutDetail.Approve" [disabled]="!isNewData">
                        <option value="" disabled>--請選擇--</option>
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">審核人員</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdOutDetail.RegiName" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <button class="save-btn-gray float-start me-3" (click)="getStdOutModify()">儲存</button>
                <button class="other-Button-gray float-start" (click)="getStdOutDelete()">刪除</button>
            </div>
        </div>
    </div>
</ng-template>
