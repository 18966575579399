<div class="table-responsive-md">
    <table class="table table-sm table-bordered table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th>星期一</th>
                <th>星期二</th>
                <th>星期三</th>
                <th>星期四</th>
                <th>星期五</th>
                <th>星期六</th>
                <th>星期日</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of class">
                <td>第{{item}}節</td>
                <td class="pointer" [ngClass]="{'row-selected-bg': true}" (click)="setClass($event.target)">1{{item}}
                </td>
                <td class="pointer" (click)="setClass($event.target)">2{{item}}</td>
                <td class="pointer" (click)="setClass($event.target)">3{{item}}</td>
                <td class="pointer" (click)="setClass($event.target)">4{{item}}</td>
                <td class="pointer" (click)="setClass($event.target)">5{{item}}</td>
                <td class="pointer" (click)="setClass($event.target)">6{{item}}</td>
                <td class="pointer" (click)="setClass($event.target)">7{{item}}</td>
            </tr>
        </tbody>
    </table>
</div>
