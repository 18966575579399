
<div class="container">
    <alert type="danger">
        <strong>注意！</strong>
        須完成註冊才會納入註冊數統計
    </alert>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" #Term>
                    <option value="" disabled >--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">報表類型</span>
                <select class="form-select shadow-none" #ReportType>
                    <option value="" disabled >--請選擇--</option>
                    <option value="1">學生就學情況統計表</option>
                    <option value="2">僑生、港澳及中國地區來台畢業學生資料統計表</option>
                    <option value="3">僑生、港澳及中國地區來台學生資料統計表</option>
                    <option value="4">外國畢業學生資料統計表</option>
                    <option value="5">外國學生資料統計表</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">是否符合總量延畢生條件代碼</span>
                <select class="form-select shadow-none" #P1>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">是否符合相關定義之境外學生代碼</span>
                <select class="form-select shadow-none" #P2>
                    <option value="" disabled >--請選擇--</option>
                    <option value="1">依就學辦法入學之境外生</option>
                    <option value="2">依一般身份入學、且具正式學籍申請入學之境外生</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <select class="form-select shadow-none" #P3>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <button class="export-btn float-start" (click)="exportReportADSTD0804(Year.value, Term.value, ReportType.value, P1.value, P2.value, P3.value)">匯出</button>
        </div>
    </div>
</div>
