import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { GetTFSMakeDocWaitforList } from 'src/app/_Models/AD/ADTFS/ADTFS0501/getTFSMakeDocWaitforList';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { parseDate } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-ADTFS0501',
  templateUrl: './ADTFS0501.component.html',
  styleUrls: ['./ADTFS0501.component.css']
})
export class ADTFS0501Component implements OnInit {
  initialData = new InitialData(this.http);
  baseUrl = environment.apiUrl;
  Years!: Array<string>;
  term = require('src/app/_Models/_SelectInput/term.json').term;
  tFSMakeDocWaitforList!: GetTFSMakeDocWaitforList[];
  bsValue: Date[] = [];
  bsConfig = environment.bsDatePickerConfig
  constructor(private toastr: ToastrService,
    private http: HttpClient,
    private localeService: BsLocaleService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.localeService.use('zh-cn');
    this.getYears();
  }


  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getTFSMakeDocWaitforList(Years: string, Term: string) {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0501/Get_TFS_MakeDoc_WaitforList", { Years: Years, Term: Term }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocWaitforList = []
        return;
      }
      this.tFSMakeDocWaitforList = response;

      this.tFSMakeDocWaitforList.forEach(element => {
        element.Date = [];
        element.Date.push(parseDate(element.BusinessTime));
        element.Date.push(parseDate(element.SchoolTime));
      });
    })
  }

  getTFSMakeDocBuild(index: number) {

    this.tFSMakeDocWaitforList[index].BusinessTime = this.datePipe.transform(this.tFSMakeDocWaitforList[index].Date[0], 'yyyy-MM-dd')?.toString() || '';
    this.tFSMakeDocWaitforList[index].SchoolTime = this.datePipe.transform(this.tFSMakeDocWaitforList[index].Date[1], 'yyyy-MM-dd')?.toString() || '';

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0501/Get_TFS_MakeDoc_Build", this.tFSMakeDocWaitforList[index]).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("製單成功");
    })
  }

}
