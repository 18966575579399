import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0202/search';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUP0202',
    templateUrl: './ADCUP0202.component.html',
    styleUrls: ['./ADCUP0202.component.css']
})
export class ADCUP0202Component implements OnInit {
    baseUrl = environment.apiUrl;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    data!: Search[];
    bsConfig = environment.bsDatePickerConfig;
    dataDetail: Search = {
        Years: '',
        Term: '',
        Cur_Week: '',
        First_Date: '',
        Last_Date: '',
        Week_StartDay: '',
        Week_EndDay: '',
        Memo: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
    }
    term = require('src/app/_Models/_SelectInput/term.json').term;
    constructor(private http: HttpClient, private toastr: ToastrService, private localeService: BsLocaleService) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        }, error => this.toastr.error(error.error));
    }

    search(Years: string, Term: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0202/Search", { Years: Years, Term: Term }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        })
    }

    searchDetail(index: number) {
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.dataDetail = this.data[index];
    }

    insert(Years: string, Term: string, FirstDate: string, LastDate: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0202/Insert", {
            Years: Years,
            Term: Term,
            First_Date: FirstDate,
            Last_Date: LastDate
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search(Years, Term);
        });
    }

    update(Years: string, Term: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0202/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search(Years, Term);
        });
    }

}
