import { HttpClient } from '@angular/common/http';
import { Component, NgModule, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { environment } from 'src/assets/environments/environment';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { ResultOutput,DataKeyInput,ContrastUserType } from 'src/app/_Models/AD/ADSTD/ADSTD0601ISU01/getData';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSTD0601ISU01',
    templateUrl: './ADSTD0601ISU01.component.html',
    styleUrls: ['./ADSTD0601ISU01.component.css']
})

export class ADSTD0601ISU01Component implements OnInit {
    baseUrl = environment.apiUrl;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    teamList!: GetTeamList[];
    classList!: GetClassList[];
    StatusList!:GetSelectList[];
    initialData = new InitialData(this.http);
    stdList!: ResultOutput[];
    stdSelects!: ResultOutput[];
    stdSelect!: ResultOutput;
    UserType!:ContrastUserType;
    thisUserType:string="";
    DataKeys!:DataKeyInput[];

    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl({ value: ''}, Validators.required),
        Term: new UntypedFormControl({ value: ''}, Validators.required),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        TeamNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        StdNoFrom: new UntypedFormControl(''),
        StdNoTo: new UntypedFormControl(''),
        Status: new UntypedFormControl(''),
        DataKey: new UntypedFormControl('')
    });

    model = {
        Years: '',
        Term: '',
        DeptfromDate:'',
        DeptfromTime:'',
        DepttoDate:'',
        DepttoTime:'',
    }

    ThisDataKeyInput={
        DataKey:''
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private datePipe: DatePipe,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Contrast_Years;
            this.model.Term = response.Contrast_Term;
            this.model.DeptfromDate= this.datePipe.transform(new Date(response.Contrast_DeptStartTime),'yyyy-MM-dd')!;
            this.model.DepttoDate = this.datePipe.transform(new Date(response.Contrast_DeptEndTime),'yyyy-MM-dd')!;
            this.model.DeptfromTime = this.datePipe.transform(new Date(response.Contrast_DeptStartTime),'HH:mm')!;
            this.model.DepttoTime = this.datePipe.transform(new Date(response.Contrast_DeptEndTime),'HH:mm')!;
        });
        this.getUserType();
        this.getYears();

        this.StatusList=[{ItemName:"全部",ItemValue:"",DataKey:""},{ItemName:"承辦人未審核",ItemValue:"1",DataKey:""},{ItemName:"承辦人已審核",ItemValue:"2",DataKey:""},{ItemName:"主任已審核",ItemValue:"3",DataKey:""}];
    }

    setAll(event: EventTarget | null) {
        if (!this.stdList) return;
        let input = <HTMLInputElement>event;
        this.stdList.forEach(element => {
            if(element.Status=='1' || element.Status=='B'){
                input.checked == true ? element.Checked = true : element.Checked = false;
            }
        })
    }

    setCheck(index: number,event: EventTarget | null){
        let input = <HTMLInputElement>event;
        this.stdList[index].Checked=input.checked;
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        let sType=(this.UserType.UserType=='DeptMA')?'5':'3';
        this.eduList=[];
        this.systemService.getEduList(sType, '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getClassList(edu: string, dept: string) {
        let sType=(this.UserType.UserType=='DeptMA')?'5':'3';
        this.classList = [];
        this.systemService.getClassList(edu, dept, sType, '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))
    }

    getTeamList(dept: string) {
        let sType=(this.UserType.UserType=='DeptMA')?'5':'3';
        this.teamList = [];
        this.systemService.getTeamList(sType,'','',dept,'').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.teamList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        let sType=(this.UserType.UserType=='DeptMA')?'5':'3';
        this.deptList = [];
        this.systemService.getDeptList(edu, sType, '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getUserType(){
        this.http.get<any>(this.baseUrl + "ACAD/STD/ADSTD06_01_ISU01/Get_ADSTD0601_ISU01_UserKey").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.UserType = response;
            this.thisUserType=this.UserType.UserType;
            this.getDeptList('');
        })
    }

    getStdList(){
        this.stdList = [];
        this.ngFormInput.get("DataKey")?.setValue("");
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_01_ISU01/Get_ADSTD0601_ISU01_StdList", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.stdList = response;
        })
    }

    doCheck(){
        this.DataKeys=[];
        this.stdList.forEach(element => {
            if(element.Checked == true){
                this.DataKeys.push({DataKey:element.DataKey});
            }
        })

        if(this.DataKeys.length==0){
            this.toastr.warning("請勾選學生!");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_01_ISU01/Get_ADSTD0601_ISU01_Process", this.DataKeys).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("審查完成");
        })
    }

    getContrastResultData(thisStd: ResultOutput,index:number){
        this.stdList.forEach(element => {
            element.Selected = false;
        })
        this.stdList[index].Selected = true;

        this.stdSelect=thisStd;
        this.stdSelects=[];
        this.stdSelects.push(thisStd);
    }
}
