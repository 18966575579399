export class CheckType {

    // 檢查是否為數字，是為false，否為true
    CheckIsNumber(input: any): boolean {
        const rule = new RegExp('[0-9]');
        if (rule.test(input)) {
            return false;
        }
        return true;
    }

    // 檢查是否為大寫A-Z
    CheckIsAlphabet(input: any): boolean {
        var rules = new RegExp('[A-Z]')
        if (rules.test(input)) {
            return true;
        }
        return false;
    }

    // 檢查是否存在這一天
    CheckDate(data: string, year: string, month: string, day: string) {

        //列出12個月，每月最大日期限制
        var limitInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if (data !== '') {

            // yyyy/mm/dd
            var dateObj = data.split('/');
            var theYear = parseInt(dateObj[0]);
            var theMonth = parseInt(dateObj[1]);
            var theDay = parseInt(dateObj[2]);

            // 是否為閏年?
            var isLeap = new Date(theYear, 1, 29).getDate() === 29;

            // 若為閏年，最大日期限制改為 29
            if (isLeap) {
                limitInMonth[1] = 29;
            }

            // 比對該日是否超過每個月份最大日期限制
            return theDay <= limitInMonth[theMonth - 1];
        }
        else {
            var theYear = parseInt(year);
            var theMonth = parseInt(month);
            var theDay = parseInt(day);

            // 是否為閏年?
            var isLeap = new Date(theYear, 1, 29).getDate() === 29;

            // 若為閏年，最大日期限制改為 29
            if (isLeap) {
                limitInMonth[1] = 29;
            }

            // 比對該日是否超過每個月份最大日期限制
            return theDay <= limitInMonth[theMonth - 1];
        }
    }

}
