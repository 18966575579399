<div class="container">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <tabset>
                <tab heading="{{'申請'|translate}}">
                    <div class="RowMarginTop5">
                        <div class="row">
                            <div class="col-md-auto">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'學年'|translate}}</span>
                                    <select class="form-select shadow-none" formControlName="Years">
                                        <option value="">{{'--請選擇--'|translate}}</option>
                                        <option *ngFor=" let item of Years" [value]="item">{{item}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'學期'|translate}}</span>
                                    <select class="form-select shadow-none" formControlName="Term">
                                        <option value="">{{'--請選擇--'|translate}}</option>
                                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'學制'|translate}}</span>
                                    <select class="form-select shadow-none" (change)="getDeptList()" formControlName="EduNo">
                                        <option value="" selected>{{'--請選擇--'|translate}}</option>
                                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'系所'|translate}}</span>
                                    <select class="form-select shadow-none" (change)="getClassList();" formControlName="DeptNo">
                                        <option value="" selected>{{'--請選擇--'|translate}}</option>
                                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'班別'|translate}}</span>
                                    <select class="form-select shadow-none" formControlName="Class">
                                        <option value="" selected>{{'--請選擇--'|translate}}</option>
                                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'學號/姓名'|translate}}</span>
                                    <input type="text" class="form-control shadow-none" formControlName="StdNoName">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'填單日期'|translate}}</span>
                                    <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" formControlName="ApplyDateFrom">
                                        <span class="input-group-text">{{'至'|translate}}</span>
                                    <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" formControlName="ApplyDateTo">
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'狀態'|translate}}</span>
                                    <select class="form-select shadow-none" formControlName="Status">
                                        <option value="" selected>{{'--請選擇--'|translate}}</option>
                                        <option value="0">{{'未送出'|translate}}</option>
                                        <option value="1">{{'審核中'|translate}}</option>
                                        <option value="2">{{'已審核'|translate}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'備註'|translate}}</span>
                                    <input type="text" class="form-control shadow-none" formControlName="Memo">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'獎懲種類'|translate}}</span>
                                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                        <div *ngFor="let item of rewardsList; let i = index">
                                            <input type="checkbox" class="btn-check" autocomplete="off" id="btncheck{{i+1}}" value="{{item.ItemValue}}" (click)="setCheck($event.target)">
                                            <label class="btn btn-outline-secondary shadow-none" for="btncheck{{i+1}}">{{item.ItemName}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <button class="other-Button-gray float-end me-3" type="button" (click)="getSRPBatchDelete()">{{'刪除'|translate}}</button>
                                <button class="other-Button-gray float-end me-3" type="button" (click)="openModel(template, 'new',this.sRPEmpty[0], 0,false)">{{'新增'|translate}}</button>
                                <button class="search-btn float-end me-3" (click)="getSRPList()">{{'查詢'|translate}}</button>
                            </div>
                        </div>
                        <div class="row RowHeight340 DivScroll">
                            <table class="table table-hover table-striped text-center">
                                <thead class="thead-dark-red">
                                    <tr>
                                        <th class="ColW60"><input type="checkbox" class="form-check-input shadow-none pointer" (click)="setAll($event.target)">&ensp;{{'全選'|translate}} </th>
                                        <th class="ColW60">{{'學年期'|translate}}</th>
                                        <th class="ColW100">{{'案號'|translate}}</th>
                                        <th class="ColW140">{{'填單日期'|translate}}</th>
                                        <th class="ColW100">{{'記功過日期'|translate}}</th>
                                        <th class="ColW200">{{'備註'|translate}}</th>
                                        <th class="ColW100">{{'處理狀態'|translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of sRPList; let i = index">
                                        <td class="ColW60">
                                            <input type="checkbox" *ngIf="item.Status=='0'" class="form-check-input shadow-none pointer" (click)="setItemCheck(item,i,$event.target)" [(ngModel)]="item.Checked">
                                        </td>
                                        <td class="ColW50">{{item.Years}}-{{item.Term}}</td>
                                        <td class="ColW100">
                                            <a class="pointer text-decoration-none" (click)="openModel(template, '', item, (i+1),false)">{{item.SerialNo}}</a>
                                        </td>
                                        <td class="ColW140">{{item.ApplyDate}}</td>
                                        <td class="ColW100">{{item.RecDate}}</td>
                                        <td class="ColW300 ColTextAlignLeft">{{item.Memo}}</td>
                                        <td class="ColW60">
                                            <label *ngIf="item.Status=='0'">{{item.StatusName}}</label>
                                            <a class="pointer text-decoration-none" *ngIf="item.Status!='0'" (click)="openFlowModel(flowtemplate,item)">{{item.StatusName}}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </tab>
                <tab heading="{{'審核'|translate}}">
                    <div class="RowHeight500 DivScroll RowMarginTop5">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'狀態'|translate}}</span>
                                    <select class="form-select shadow-none" #SignAgree>
                                        <option value="Y" selected>{{'同意'|translate}}</option>
                                        <option value="N">{{'不同意'|translate}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">{{'意見'|translate}}</span>
                                    <input type="text" class="form-control shadow-none" #SignMemo>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <button class="other-Button-gray float-end me-3" type="button" (click)="getSRPSignBatch(SignAgree.value,SignMemo.value)">{{'送出'|translate}}</button>
                            </div>
                        </div>
                        <div class="row">
                            <table class="table table-hover table-striped text-center">
                                <thead class="thead-dark-red">
                                    <tr>
                                        <th class="ColW60"><input type="checkbox" class="form-check-input shadow-none pointer" (click)="setAll($event.target)">&ensp;{{'全選'|translate}} </th>
                                        <th class="ColW60">{{'學年期'|translate}}</th>
                                        <th class="ColW100">{{'案號'|translate}}</th>
                                        <th class="ColW100">{{'申請單位'|translate}}</th>
                                        <th class="ColW140">{{'填單日期'|translate}}</th>
                                        <th class="ColW100">{{'記功過日期'|translate}}</th>
                                        <th class="ColW200">{{'備註'|translate}}</th>
                                        <th class="ColW100">{{'處理狀態'|translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of sRPAgreeList; let i = index">
                                        <td class="ColW60">
                                            <input type="checkbox" *ngIf="item.Status=='1'" class="form-check-input shadow-none pointer" (click)="setSignItemCheck(item,i,$event.target)" [(ngModel)]="item.Checked">
                                        </td>
                                        <td class="ColW60">{{item.Years}}-{{item.Term}}</td>
                                        <td class="ColW100">
                                            <a class="pointer text-decoration-none" (click)="openModel(template, '', item, (i+1),true)">{{item.SerialNo}}</a>
                                        </td>
                                        <td class="ColW100">{{item.ApplyUnitName}}</td>
                                        <td class="ColW140">{{item.ApplyDate}}</td>
                                        <td class="ColW100">{{item.RecDate}}</td>
                                        <td class="ColW200 ColTextAlignLeft">{{item.Memo}}</td>
                                        <td class="ColW100">
                                            <label *ngIf="item.Status=='0'">{{item.StatusName}}</label>
                                            <a class="pointer text-decoration-none" *ngIf="item.Status!='0'" (click)="openFlowModel(flowtemplate,item)">{{item.StatusName}}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
    </form>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'學生獎懲建議'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormDetail">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">{{'學年'|translate}}</span>
                        <select class="form-select shadow-none" #Year formControlName="Years" (change)="getSuggestList(Year.value)">
                            <option value="" disabled>{{'--請選擇--'|translate}}</option>
                            <option *ngFor=" let item of Years" [value]="item">{{item}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">{{'學期'|translate}}</span>
                        <select class="form-select shadow-none" #Term formControlName="Term">
                            <option value="" disabled>{{'--請選擇--'|translate}}</option>
                            <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">{{'單位'|translate}}</span>
                        <ng-template [ngIf]="ngFormDetail.get('Status')?.value=='0' || ngFormDetail.get('Status')?.value==''">
                            <select class="form-select shadow-none" #ApplyUnitCode formControlName="ApplyUnitCode">
                                <option value="" disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor=" let item of UnitList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                            </select>
                        </ng-template>
                        <ng-template [ngIf]="ngFormDetail.get('Status')?.value!='0' && ngFormDetail.get('Status')?.value!=''">
                            <span class="input-group-text">{{ngFormDetail.controls.ApplyUnitName.value}}</span>
                        </ng-template>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">{{'證明文件'|translate}}</span>
                        <input class="form-control shadow-none" type="file" (change)="importAttFile($event, '', '')">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <label (click)="getSRPFile(ngFormDetail.get('DataKey')?.value)">{{ngFormDetail.get('FileName')?.value}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">{{'備註'|translate}}</span>
                        <input class="form-control shadow-none" type="text" #Memo formControlName="Memo" maxlength="300">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">{{'記功過日期'|translate}}</span>
                        <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly #Memo formControlName="RecDate">
                    </div>
                </div>
            </div>
        </form>
        <div class="row DivScroll">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 30px;"></th>
                        <th style="width: 90px;">{{'學號'|translate}}</th>
                        <th style="width: 80px;">{{'姓名'|translate}}</th>
                        <th style="width: 120px;">{{'處理意見'|translate}}</th>
                        <th style="width: 60px;">{{'次數'|translate}}</th>
                        <th style="width: 130px;">{{'發生日期'|translate}}</th>
                        <th style="width: 150px;">{{'事由'|translate}}</th>
                        <th style="width: 140px;">{{'引證條文'|translate}}</th>
                        <th style="width: 90px;">{{'批示日期'|translate}}</th>
                        <th style="width: 80px;">{{'狀態'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of sRPDetailList; let i = index">
                        <td style="width: 40px;height:30px;">{{i + 1}}</td>
                        <td style="width: 80px;">
                            <input type="text" class="form-control form-control-sm shadow-none text-center" [(ngModel)]="item.StdNo" maxlength="10">
                        </td>
                        <td style="width: 80px;">
                            <ng-container *ngIf="item.StdName != ''">{{item.StdName}}</ng-container>
                        </td>
                        <td style="width: 80px;">
                            <select class="form-select shadow-none" #TypeID [(ngModel)]="item.TypeID">
                                <option value="" disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor=" let itemRewards of rewardsList" [value]="itemRewards.ItemValue">{{itemRewards.ItemName}}</option>
                            </select>
                        </td>
                        <td style="width: 40px;"><input type="number" class="form-control form-control-sm shadow-none text-center" min="1" step="1" [(ngModel)]="item.Times" [ngModelOptions]="{standalone: true}"></td>
                        <td style="width: 110px;">
                            <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly [(ngModel)]="item.IssueDate">
                        </td>
                        <td style="width: 140px;">
                            <select class="form-select shadow-none" #SugCode [(ngModel)]="item.SugCode">
                                <option value="" disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor=" let itemSugCode of sugCodeList" [value]="itemSugCode.ItemValue">{{itemSugCode.ItemValue}}{{itemSugCode.ItemName}}</option>
                            </select>
                        </td>
                        <td style="width: 150px;">
                            <input type="text" class="form-control form-control-sm shadow-none" [(ngModel)]="item.Means" maxlength="300">
                        </td>
                        <td style="width: 90px;">
                            <ng-container *ngIf="item.ApproveDate != ''">{{item.ApproveDate}}</ng-container>
                        </td>
                        <td style="width: 80px;">
                            <select class="form-select shadow-none" disabled #Status [(ngModel)]="item.Status">
                                <option value="" disabled></option>
                                <option value="0" disabled></option>
                                <option value="1" disabled></option>
                                <option value="2" disabled>{{'通過'|translate}}</option>
                                <option value="3" disabled>{{'未通過'|translate}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr *ngIf="this.isSend==false && this.isShowAgree==false">
                        <td colspan="11">
                            <a class="pointer text-decoration-none float-end" (click)="deleteRow()">{{'刪除一筆'|translate}}</a>
                            <a class="pointer text-decoration-none me-3 float-end" (click)="addRow()">{{'新增一筆'|translate}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mb-3">
            <ng-template [ngIf]="this.isSend==false && this.isShowAgree==false">
                <div class="col-md-12">
                    <button class="other-Button-gray float-end me-3" type="button" (click)="getSRPSave('0')">{{'儲存'|translate}}</button>
                    <button class="other-Button-gray float-end me-3" type="button" (click)="getSRPSave('1')">{{'送出'|translate}}</button>
                </div>
            </ng-template>
        </div>
        <div class="row">
            <ng-template [ngIf]="this.isShowAgree==true">
                <div class="row">
                    <div class="col-md-2">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'狀態'|translate}}</span>
                            <select class="form-select shadow-none" #PSignAgree>
                                <option value="Y" selected>{{'同意'|translate}}</option>
                                <option value="N">{{'不同意'|translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'意見'|translate}}</span>
                            <input type="text" class="form-control shadow-none" #PSignMemo>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <button class="other-Button-gray float-end me-3" type="button" (click)="getSRPSign(PSignAgree.value,PSignMemo.value)">{{'送出'|translate}}</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>
<ng-template #flowtemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'學生獎懲建議簽核流程'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row DivScroll">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th class="ColW50">{{'關卡'|translate}}</th>
                        <th class="ColW140">{{'單位'|translate}}</th>
                        <th class="ColW100">{{'職稱'|translate}}</th>
                        <th class="ColW100">{{'簽核者'|translate}}</th>
                        <th class="ColW60">{{'狀態'|translate}}</th>
                        <th class="ColW200">{{'簽核意見'|translate}}</th>
                        <th class="ColW140">{{'簽核日期'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of sRPFlow; let i = index">
                        <td class="ColW50">{{item.Stage}}</td>
                        <td class="ColW140">{{item.UnitName}}</td>
                        <td class="ColW100">{{item.TitleName}}</td>
                        <td class="ColW100">{{item.UserName}}</td>
                        <td class="ColW60">{{item.SignAgree}}</td>
                        <td class="ColW200">{{item.SignMemo}}</td>
                        <td class="ColW140">{{item.SignDate}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>
