<div class="container" style="margin-bottom: 100px;">
    <form>
        <app-year-term-input></app-year-term-input>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">開始日期</span>
                    </div>
                    <input type="text" placeholder="--請選擇日期--" class="form-control shadow-none" bsDaterangepicker
                        #dpr="bsDaterangepicker" formControlName="Date">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">結束日期</span>
                    </div>
                    <input type="text" placeholder="--請選擇日期--" class="form-control shadow-none" bsDaterangepicker
                        #dpr="bsDaterangepicker" formControlName="Date">
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-8">
                <button type="button" class="search-btn float-start me-3">查詢</button>
            </div>
            <div class="col-md-4">
                <button type="button" class="other-Button-gray float-end me-3">新增</button>
                <button type="button" class="other-Button-gray float-end me-3">刪除</button>
            </div>
        </div>
    </form>
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" (click)="setDatakey('all', '', $event.target)" class="pointer">&ensp;全選</th>
                <th>代碼</th>
                <th>類別名稱</th>
                <th>操行扣分</th>
                <th>扣考結束</th>
                <th>修改者</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><input type="checkbox" style="width: 60px;" class="text-center"></td>
                <td></td>
                <td></td>
                <td><input type="text" style="width: 60px;" class="text-center"></td>
                <td><input type="text" style="width: 60px;" class="text-center"></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>