<div class="container">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
    <div class="row">
        <div class="col-md">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'學制'|translate}}</span>
                </div>
                <select class="form-select shadow-none" (change)="getDeptList($event)">
                    <option disabled selected value="">{{'--請選擇學制--'|translate}}</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'系所'|translate}}</span>
                </div>
                <select class="form-select shadow-none" (change)="getClassList($event)">
                    <option disabled selected value="">{{'--請選擇系所--'|translate}}</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'班級'|translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="class">
                    <option disabled selected value="">{{'--請選擇班級--'|translate}}</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>

    </div>
    <button class="search-btn mb-3" (click)="getCurList()">{{'查詢'|translate}}</button>
    <app-cur-class id="cur-class" [curList]="data" [functionType]="functionType"></app-cur-class>
    <app-cur-class-rwd id="cur-class-rwd" [curList]="data" [functionType]="functionType"></app-cur-class-rwd>
</div>