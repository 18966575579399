
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADGRD0404',
  templateUrl: './ADGRD0404.component.html',
  styleUrls: ['./ADGRD0404.component.css']
})
export class ADGRD0404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}