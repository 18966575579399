<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="exportReportADSEL0504('class')">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" formControlName="Years" (change)="getDeptList()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學期</span>
                    <select class="form-select shadow-none" formControlName="Term" (change)="getDeptList()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="" selected disabled>--請選擇系所--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">教師姓名</span>
                    <input type="text" class="form-control shadow-none" formControlName="TchData">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">課程關鍵字</span>
                    <input type="text" class="form-control shadow-none" formControlName="CosData">
                </div>
            </div>
            <div class="col-md-6">
                <button type="button" (click)="getClassList()" class="other-Button-gray float-start me-3">查班級</button>
                <button type="button" (click)="getSELReportCosList()"
                    class="other-Button-gray float-start me-3">查課程</button>
                <button class="export-btn float-start me-3" style="width: 150px;">依班級匯出</button>
                <button type="button" (click)="exportReportADSEL0504('cos')" class="export-btn float-start"
                    style="width: 150px;">依課程匯出</button>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-md-4">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th><input type="checkbox" class="form-check-input pointer shadow-none"
                                (click)="setAll('class', $event.target)">&ensp;全選</th>
                        <th>班級代碼</th>
                        <th>班級名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data">
                        <td><input type="checkbox" class="form-check-input pointer shadow-none"
                                [(ngModel)]="item.Selected"></td>
                        <td>{{item.Class}}</td>
                        <td>{{item.Class_Short}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th><input type="checkbox" class="form-check-input pointer shadow-none"
                                (click)="setAll('cos', $event.target)">&ensp;全選</th>
                        <th>開課班級</th>
                        <th>科目名稱</th>
                        <th>授課教師</th>
                        <th>上課時間</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of sELReportCosList">
                        <td><input type="checkbox" class="form-check-input pointer shadow-none"
                                [(ngModel)]="item.Selected"></td>
                        <td>{{item.ClassName}}</td>
                        <td>{{item.CosName}}</td>
                        <td>{{item.TchName}}</td>
                        <td>{{item.CurTime}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>