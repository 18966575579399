import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-TCH0401',
  templateUrl: './TCH0401.component.html',
  styleUrls: ['./TCH0401.component.css']
})
export class TCH0401Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
