<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="sYSStdListInput.StdNo">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="sYSStdListInput.StdName">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value)">
                    <option value="" selected disabled>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">系所</span>
                </div>
                <select class="form-select shadow-none" #dept (change)="getClassList(edu.value, dept.value);">
                    <option value="" selected disabled>--請選擇系所--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">班別</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="sYSStdListInput.Class">
                    <option value="" selected>--請選擇班別--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-9">
            <button class="other-Button-gray float-end me-3" (click)="getSYSStdModify()">修改</button>
            <button class="search-btn float-end me-3" (click)="getSYSStdList()">查詢</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>學號</th>
                        <th>名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of sYSStdList; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="getSYSStdDetail(item.DataKey, i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.StdNo}}</td>
                        <td>{{item.StdName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學號</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="sYSStdDetail.StdNo" disabled>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">姓名</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="sYSStdDetail.StdName" disabled>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">密碼</span>
                        <input type="password" class="form-control shadow-none" [(ngModel)]="sYSStdDetail.PassWord">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-check form-switch  mt-1">
                        <input class="form-check-input shadow-none pointer" type="checkbox" role="switch"
                            id="reset-password" [(ngModel)]="sYSStdDetail.DefaultPwd"
                            [checked]="sYSStdDetail.DefaultPwd">
                        <label class="form-check-label" for="reset-password">還原為預設密碼</label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-check form-switch  mt-1">
                        <input class="form-check-input shadow-none pointer" type="checkbox" role="switch"
                            id="change-password" [(ngModel)]="sYSStdDetail.OnlineChangePwd"
                            [checked]="sYSStdDetail.OnlineChangePwd">
                        <label class="form-check-label" for="change-password">啟用線上修改密碼</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-8">
                    <div class="input-group mb-3">
                        <span class="input-group-text">變更密碼時間</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="sYSStdDetail.ChangePwdTime"
                            disabled>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">變更密碼者</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="sYSStdDetail.ChangePwdUser"
                            disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>