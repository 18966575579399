<div class="container">
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 60px;"></th>
                <th>代碼</th>
                <th>特殊註記名稱</th>
                <th>備註</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data; let i = index">
                <td style="width: 60px;">{{i + 1}}</td>
                <td>{{item.SelStyle}}</td>
                <td>{{item.SelStyle_Name}}</td>
                <td>{{item.Memo}}</td>
            </tr>
        </tbody>
    </table>
</div>
