import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-STDW10005',
  templateUrl: './STDW10005.component.html',
  styleUrls: ['./STDW10005.component.css']
})
export class STDW10005Component implements OnInit {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private systemService: SystemService, private toastr: ToastrService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.use(this.systemService.currentLanguage)
  }



}
