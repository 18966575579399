<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">修讀學年</span>
                <select class="form-select shadow-none" [(ngModel)]="stdADDMListInput.AddYear">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">修讀學期</span>
                <select class="form-select shadow-none" [(ngModel)]="stdADDMListInput.AddTerm">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學籍狀態</span>
                <select class="form-select shadow-none" [(ngModel)]="stdADDMListInput.Status">
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of sTDStatusList" value="{{item.Status_No}}">{{item.Status_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">修讀類別</span>
                <select class="form-select shadow-none" [(ngModel)]="stdADDMListInput.AddType">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of addTypeList" value="{{item.Add_Type}}">{{item.Add_Type_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">修讀結果</span>
                <select class="form-select shadow-none" [(ngModel)]="stdADDMListInput.Result">
                    <option value="" selected>--請選擇--</option>
                    <option value="Y">進修中</option>
                    <option value="A">放棄</option>
                    <option value="F">修畢</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #eduNo (change)="getDeptList(eduNo.value)"
                    [(ngModel)]="stdADDMListInput.EduNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" [(ngModel)]="stdADDMListInput.DeptNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">身分證號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdADDMListInput.IDNo">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdADDMListInput.StdName">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdADDMListInput.StdNo">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="getStdADDMDelete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="getStdADDMDetail('', true, template)">新增</button>
            <button class="search-btn float-end me-3" (click)="getStdADDMList()">查詢</button>

        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 70px;"><input type="checkbox" class="pointer"
                        (click)="setDatakey('all', '', $event.target)">&ensp;刪除</th>
                <th style="width: 60px;">學年</th>
                <th style="width: 60px;">學期</th>
                <th>學號</th>
                <th>姓名</th>
                <th>學制</th>
                <th>系所</th>
                <th>申請日</th>
                <th style="width: 70px;">類別</th>
                <th>修讀學制</th>
                <th>修讀系所</th>
                <th>修讀結果</th>
                <th>備註</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdADDMList">
                <td style="width: 70px;"><input type="checkbox" class="pointer"
                        (click)="setDatakey('', item.DataKey, $event.target)" [checked]="item.checked"></td>
                <td style="width: 60px;">{{item.AddYear}}</td>
                <td style="width: 60px;">{{item.AddTerm}}</td>
                <td><a class="pointer text-decoration-none"
                        (click)="getStdADDMDetail(item.DataKey, false, template)">{{item.StdNo}}</a></td>
                <td>{{item.StdName}}</td>
                <td>{{item.EduName}}</td>
                <td>{{item.DeptName}}</td>
                <td>{{item.ApplyDate}}</td>
                <td style="width: 70px;">{{item.AddTypeName}}</td>
                <td>{{item.AddEduName}}</td>
                <td>{{item.AddDeptName}}</td>
                <td>{{item.ResultName}}</td>
                <td>{{item.Memo}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">輔系雙主修資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <small class="text-danger">*為必填欄位，請檢查後再送出。</small>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>修讀學年</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdADDMDetail.AddYear">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>修讀學期</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdADDMDetail.AddTerm">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>學號</span>
                    <input type="text" class="form-control shadow-none" (blur)="getStdName()" [(ngModel)]="stdADDMDetail.StdNo">
                </div>
            </div>            
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">姓名</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdADDMDetail.StdName" disabled
                        placeholder="儲存時自動帶入">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>修讀類別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdADDMDetail.AddType">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of addTypeList" value="{{item.Add_Type}}">{{item.Add_Type_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>修讀結果</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdADDMDetail.Result">
                        <option value="" disabled>--請選擇--</option>
                        <option value="Y">進修中</option>
                        <option value="A">放棄</option>
                        <option value="F">修畢</option>
                    </select>
                </div>
            </div>            
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text"><span class="text-danger">*</span>申請日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly required [(ngModel)]="stdADDMDetail.ApplyDate"
                        style="background-color: transparent;">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">放棄日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly [(ngModel)]="stdADDMDetail.GiveupDate">
                </div>
            </div>
        </div>        
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdADDMDetail.EduName" disabled
                        placeholder="儲存時自動帶入">
                </div>
            </div>            
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">班級</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdADDMDetail.ClassName" disabled
                        placeholder="儲存時自動帶入">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>修讀學制</span>
                    <select class="form-select shadow-none" #neweduNo (change)="getAllDeptList(neweduNo.value)" [(ngModel)]="stdADDMDetail.AddEduNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of neweduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                    </select>
                </div>
            </div>            
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>修讀系所</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdADDMDetail.AddDeptNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of newdeptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                    </select>
                </div>
            </div>            
        </div>
        <div class="row">
            <div class="col-md-12">
                <label>備註</label>
                <textarea class="form-control shadow-none" appTextareaAutoresize [(ngModel)]="stdADDMDetail.Memo"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="save-btn-gray mx-auto mt-3" (click)="getStdADDMModify()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>
