<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" name="Term" #Term>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value)"
                    [(ngModel)]="getStdListInput.EduNo" (ngModelChange)="GetData()">
                    <option value="" selected disabled>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">系所</span>
                </div>
                <select class="form-select shadow-none" #dept [(ngModel)]="getStdListInput.DeptNo"
                    (ngModelChange)="GetData()">
                    <option value="" selected disabled>--請選擇系所--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">假別</span>
                </div>
                <select class="form-select shadow-none">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor=" let item of holidayList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">請假日期</span>
                </div>
                <input type="text" placeholder="--請選擇日期--" class="form-control shadow-none" bsDaterangepicker
                    #dpr="bsDaterangepicker" formControlName="Date">
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學號</span>
                </div>
                <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;">
                <div class="input-group-prepend">
                    <span class="input-group-text">至</span>
                </div>
                <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">週次</span>
                </div>
                <select class="form-select shadow-none">
                    <option value="" selected disabled>--請選擇--</option>
                </select>
                <div class="input-group-prepend">
                    <span class="input-group-text">至</span>
                </div>
                <select class="form-select shadow-none">
                    <option value="" selected disabled>--請選擇--</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">累積時數</span>
                </div>
                <input type="text" class="form-control pointer shadow-none" style="background-color: transparent;">
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-8">
            <button type="button" class="search-btn float-start me-3">查詢</button>
        </div>
        <div class="col-md-4">
            <button type="button" class="other-Button-gray float-end me-3">匯出</button>
            <button type="button" class="other-Button-gray float-end me-3">列印</button>
        </div>
    </div>
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>學年</th>
                <th>學期</th>
                <th>班級</th>
                <th>學號</th>
                <th>姓名</th>
                <th>週次</th>
                <th>曠課</th>
                <th>遲到</th>
                <th>事假</th>
                <th>病假</th>
                <th>喪假</th>
                <th>公假</th>
                <th>以扣操行</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>