import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSAC0201',
    templateUrl: './ADSAC0201.component.html',
    styleUrls: ['./ADSAC0201.component.css']
})
export class ADSAC0201Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    bsConfig = environment.bsDatePickerConfig;
    deptListStart!: GetDeptList[];
    eduListStart!: GetEduList[];
    classListStart!: GetClassList[];
    deptListEnd!: GetDeptList[];
    eduListEnd!: GetEduList[];
    classListEnd!: GetClassList[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        Deadline: new UntypedFormControl(''),
        StartEdu: new UntypedFormControl(''),
        EndEdu: new UntypedFormControl(''),
        StarttDept: new UntypedFormControl(''),
        EndDept: new UntypedFormControl(''),
        StartClass: new UntypedFormControl(''),
        EndClass: new UntypedFormControl(''),
        StartStdNo: new UntypedFormControl(''),
        EndStdNo: new UntypedFormControl(''),
        FieldType: new UntypedFormControl('1', [Validators.required]),
        FieldTypeValueFrom: new UntypedFormControl(''),
        FieldTypeValueTo: new UntypedFormControl('')
    })
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private systemService: SystemService,
        private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.getYears();
        this.localeService.use('zh-cn');
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {

        if (this.ngFormInput.get('Years')?.value == '' || this.ngFormInput.get('Term')?.value == '') return;

        this.systemService.getEduList('0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduListStart = response;
            this.eduListEnd = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(type: string) {

        if (type == 'start') {
            this.systemService.getDeptList(this.ngFormInput.get('StartEdu')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.deptListStart = response;
            }, error => this.toastr.error(error.error));
        }
        else {
            this.systemService.getDeptList(this.ngFormInput.get('EndEdu')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.deptListEnd = response;
            }, error => this.toastr.error(error.error));
        }


    }

    getClassList(type: string) {

        if (type == 'start') {
            this.systemService.getClassList(this.ngFormInput.get('StartEdu')?.value, this.ngFormInput.get('StarttDept')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.classListStart = response;
            }, error => this.toastr.error(error.error))
        }
        else {
            this.systemService.getClassList(this.ngFormInput.get('EndEdu')?.value, this.ngFormInput.get('EndDept')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.classListEnd = response;
            }, error => this.toastr.error(error.error))
        }
    }

    getSACCompute() {

        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        switch (this.ngFormInput.get('FieldType')?.value) {
            case '1':
                this.ngFormInput.get('FieldTypeValueFrom')?.setValue(this.ngFormInput.get('StartEdu')?.value);
                this.ngFormInput.get('FieldTypeValueTo')?.setValue(this.ngFormInput.get('EndEdu')?.value);
                break;
            case '2':
                this.ngFormInput.get('FieldTypeValueFrom')?.setValue(this.ngFormInput.get('StarttDept')?.value);
                this.ngFormInput.get('FieldTypeValueTo')?.setValue(this.ngFormInput.get('EndDept')?.value);
                break;
            case '3':
                this.ngFormInput.get('FieldTypeValueFrom')?.setValue(this.ngFormInput.get('StartClass')?.value);
                this.ngFormInput.get('FieldTypeValueTo')?.setValue(this.ngFormInput.get('EndClass')?.value);
                break;
            case '4':
                this.ngFormInput.get('FieldTypeValueFrom')?.setValue(this.ngFormInput.get('StartStdNo')?.value);
                this.ngFormInput.get('FieldTypeValueTo')?.setValue(this.ngFormInput.get('EndStdNo')?.value);
                break;
        }

        this.ngFormInput.get('Deadline')?.setValue(this.datePipe.transform(this.ngFormInput.get('Deadline')?.value, 'yyyy-MM-dd'));

        this.http.post<any>(this.baseUrl + "ACAD/SAC/ADSAC0201/Get_SAC_Compute", this.ngFormInput.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('執行完畢');
        })
    }



}
