import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0102/search';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUP0102',
    templateUrl: './ADCUP0102.component.html',
    styleUrls: ['./ADCUP0102.component.css']
})
export class ADCUP0102Component implements OnInit {
    baseUrl = environment.apiUrl;
    readonly!: boolean;
    data!: Search[];
    dataDetail: Search = {
        ClassArea: '',
        ClassKind: '',
        ClassKind_Name: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
    }
    campusList!: GetSelectList[];
    ClassArea = '';
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService,) { }

    ngOnInit(): void {
        this.readonly = false;
        this.getCampus();
    }

    getCampus() {
        this.systemService.getCampus().subscribe((response) => {
            this.campusList = response;
        })
    }

    search() {

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0102/Search", { ClassArea: this.ClassArea }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        })
    }

    searchDetail(index: number) {
        this.readonly = true;
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.dataDetail.ClassArea = this.data[index].ClassArea;
        this.dataDetail.ClassKind = this.data[index].ClassKind;
        this.dataDetail.ClassKind_Name = this.data[index].ClassKind_Name;
        this.dataDetail.Update_Time = this.data[index].Update_Time;
        this.dataDetail.Update_User = this.data[index].Update_User;
    }

    insert() {
        if (this.dataDetail.ClassKind_Name == '' || this.dataDetail.ClassKind == '' || this.dataDetail.ClassArea == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0102/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search();
        }, error => this.toastr.error(error.error))
    }

    update() {
        if (this.dataDetail.ClassKind_Name == '' || this.dataDetail.ClassKind == '' || this.dataDetail.ClassArea == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0102/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.search();
        }, error => this.toastr.error(error.error))
    }

    delete() {
        if (this.dataDetail.ClassKind == '') {
            this.toastr.info("請選擇原因");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0102/Delete", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.search();
            this.clear();
        }, error => this.toastr.error(error.error))
    }

    clear() {
        this.readonly = false;
        this.dataDetail = {
            ClassArea: '',
            ClassKind: '',
            ClassKind_Name: '',
            Update_User: '',
            Update_Time: '',
            Selected: false
        }
    }

}
