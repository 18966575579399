<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" required [(ngModel)]="condition.Years">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學期</span>
                    <select class="form-select shadow-none" required [(ngModel)]="condition.Term">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">專兼職別</span>
                    <select class="form-select shadow-none" [(ngModel)]="condition.TimeCase">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of timeCaseList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">所屬單位</span>
                <select class="form-select shadow-none" [(ngModel)]="condition.UnitCode">
                    <option value="" selected>--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of unitData">{{item.ItemName}} </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">人事代碼</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="condition.TchNo">
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">姓名</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="condition.TchName">
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">職稱</span>
                    <select class="form-select shadow-none" [(ngModel)]="condition.Title">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor=" let item of titleList" value="{{item.ItemValue}}">{{item.ItemName}} </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <button type="button" class="search-btn float-start me-3" (click)="getCURTchMoneyResultList()">查詢</button>
            <button type="button" class="other-Button-gray me-3" (click)="getCURTchMoneyCompute()">計算</button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th><input type="checkbox" class="form-check-input shadow-none pointer" (click)="checkAll()" [(ngModel)]="isCheckAll">&emsp;全選</th>
                        <th>人事代碼</th>
                        <th>教師姓名</th>
                        <th>教師職稱</th>
                        <th>教學單位</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataList; let i = index;">
                        <td><input type="checkbox" class="form-check-input shadow-none pointer" [(ngModel)]="item.checked"></td>
                        <td>{{item.TchNo}}</td>
                        <td>{{item.TchName}}</td>
                        <td>{{item.TitleName}}</td>
                        <td>{{item.UnitName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
