import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { GetTFSMakeDocFeeList } from 'src/app/_Models/AD/ADTFS/ADTFS01B03/getTFSMakeDocFeeList';
import { GetTFSMakeDocFeeDetail } from 'src/app/_Models/AD/ADTFS/ADTFS01B03/getTFSMakeDocFeeDetail';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetTFSMakeDocStdList } from 'src/app/_Models/AD/ADTFS/ADTFS01B03/getTFSMakeDocStdList';
import { GetTFSMakeDocStdDetail } from 'src/app/_Models/AD/ADTFS/ADTFS01B03/getTFSMakeDocStdDetail';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADTFS01B03',
  templateUrl: './ADTFS01B03.component.html',
  styleUrls: ['./ADTFS01B03.component.css']
})
export class ADTFS01B03Component implements OnInit {
  Years!: Array<string>;
  stdYears!: Array<string>;
  baseUrl = environment.apiUrl;
  initialData = new InitialData(this.http);
  eduList!: GetEduList[];
  stdEduList!: GetEduList[];
  entryIDList!: GetSelectList[];
  term = require('src/app/_Models/_SelectInput/term.json').term;
  stdTerm = require('src/app/_Models/_SelectInput/term.json').term;;
  tFSMakeDocFeeList!: GetTFSMakeDocFeeList[];
  tFSMakeDocFeeDetail!: GetTFSMakeDocFeeDetail[] | undefined;
  makeDocList!: GetSelectList[];
  deptList!: GetDeptList[];
  classList!: GetClassList[];
  tFSMakeDocStdList!: GetTFSMakeDocStdList[];
  tFSMakeDocStdDetail!: GetTFSMakeDocStdDetail[] | undefined;
  signalModalRef!: BsModalRef;
  checkType = new CheckType()
  constructor(
    private toastr: ToastrService,
    private systemService: SystemService,
    private http: HttpClient,
    private modalService: BsModalService,
    private fileExchangeServices: FileExchangeService,) { }

  ngOnInit(): void {
    this.getYears();
    this.getEntryIDList();
    this.getMakeDocList();
  }


  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
        this.stdYears = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getEntryIDList() {
    this.systemService.getEntryIDList('A').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.entryIDList = response;
    }, error => this.toastr.error(error.error))
  }

  getEduList(Years: string, Term: string) {

    if (Years == '' || Term == '') return;

    this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.eduList = response;
      this.stdEduList = response;

    }, error => this.toastr.error(error.error))
  }

  getDeptList(eduNo: string, years: string, term: string) {
    this.systemService.getDeptList(eduNo, '1', years, term, 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.deptList = response;
    }, error => this.toastr.error(error.error))
  }

  getClassList(eduNo: string, deptNo: string, years: string, term: string) {
    this.systemService.getClassList(eduNo, deptNo, '1', years, term, 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.classList = response;
    }, error => this.toastr.error(error.error))
  }

  getMakeDocList() {
    this.systemService.getMakeDocList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.makeDocList = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocFeeList(Years: string, Term: string, EduNo: string, EntryID: string, MakeDocNo: string) {

    if (Years == '' || Term == '') {
      this.toastr.info("學年學期必選");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B03/Get_TFS_MakeDoc_FeeList", {
      DataKey: '',
      Years: Years,
      Term: Term,
      EduNo: EduNo,
      DeptNo: '',
      Class: '',
      StdNo: '',
      EntryID: EntryID,
      MakeDocNo: MakeDocNo
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocFeeList = []
        return;
      }
      this.tFSMakeDocFeeList = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocFeeDetail(index: number, datakey: string) {

    this.tFSMakeDocFeeList.forEach(element => {
      element.Selected = false;
    })
    this.tFSMakeDocFeeList[index].Selected = true;

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B03/Get_TFS_MakeDoc_FeeDetail", { DataKey: datakey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocFeeDetail = undefined
        return;
      }
      this.tFSMakeDocFeeDetail = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocFeeSave() {

    if (!this.tFSMakeDocFeeDetail) return;

    let pass = true;
    for (let index = 0; index < this.tFSMakeDocFeeDetail.length; index++) {
      if (this.checkType.CheckIsNumber(this.tFSMakeDocFeeDetail[index].Money)) {
        this.toastr.warning(`第${index + 1}項非數字`);
        pass = false;
        break;
      }
    }

    if (pass) {
      this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B03/Get_TFS_MakeDoc_FeeSave", this.tFSMakeDocFeeDetail).subscribe((response) => {
        if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
        }
        this.toastr.success("儲存成功!");
        this.getTFSMakeDocFeeList('', '', '', '', '')
      }, error => this.toastr.error(error.error))
    }
  }

  getTFSMakeDocFeeMakeDoc(Years: string, Term: string, MakeDocNo: string) {
    if (!this.tFSMakeDocFeeList) return;

    Swal.fire({
      title: '製作會將單筆設定的值還原，請問是否繼續?',
      showDenyButton: true,
      confirmButtonText: `是`,
      denyButtonText: `否`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B03/Get_TFS_MakeDoc_FeeMakeDoc", {
          DataKey: '',
          Years: Years,
          Term: Term,
          EduNo: '',
          DeptNo: '',
          Class: '',
          StdNo: '',
          EntryID: '',
          MakeDocNo: MakeDocNo,
          MakeDocStatus: '',
          UnitNo: ''
        }).subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          this.toastr.success("製作成功");
        }, error => this.toastr.error(error.error))
      } else if (result.isDenied) {
        this.toastr.info("已取消");
      }
    })
  }

  getTFSMakeDocFeeSend(Years: string, Term: string, MakeDocNo: string) {
    if (this.tFSMakeDocFeeList.length == 0) return;
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B03/Get_TFS_MakeDoc_FeeSend", {
      DataKey: '',
      Years: Years,
      Term: Term,
      EduNo: '',
      DeptNo: '',
      Class: '',
      StdNo: '',
      EntryID: '',
      MakeDocNo: MakeDocNo,
      MakeDocStatus: '',
      UnitNo: ''
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("送繳成功");
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocReportBase64(Years: string, Term: string, MakeDocNo: string, EduNo: string, EntryID: string) {

    if (Years == '' || Term == '' || MakeDocNo == '') {
      this.toastr.info("請選擇學年學期及製單類別");
      return;
    }

    this.fileExchangeServices.ExportFile("Public/Get_TFS_MakeDoc_Report_Base64", {
      DataKey: '',
      Years: Years,
      Term: Term,
      EduNo: EduNo,
      DeptNo: '',
      Class: '',
      StdNo: '',
      EntryID: EntryID,
      MakeDocNo: MakeDocNo,
      MakeDocStatus: '',
      UnitNo: '01'
    })
      .subscribe((response) => {
        let url = window.URL.createObjectURL(response.blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = '繳交清冊.xls';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => this.toastr.error(error.error));
  }

  openModal(template: TemplateRef<any>) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };

    this.signalModalRef = this.modalService.show(template, modalConfig);
  }

  getTFSMakeDocStdList(Years: string, Term: string, EduNo: string, DeptNo: string, Class: string, MakeDocNo: string, StdNo: string, Status: string) {
    if (Years == '' || Term == '') {
      this.toastr.info("學年學期必選");
      return;
    }

    if (MakeDocNo == '') {
      this.toastr.info("製單類別必選");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B03/Get_TFS_MakeDoc_StdList", {
      DataKey: '',
      Years: Years,
      Term: Term,
      EduNo: EduNo,
      DeptNo: DeptNo,
      Class: Class,
      StdNo: StdNo,
      EntryID: '',
      MakeDocNo: MakeDocNo,
      MakeDocStatus: Status,
      // 2021.07.06 啟興說寫死
      UnitNo: '01'
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tFSMakeDocStdList = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocStdDetail(index: number, datakey: string) {

    this.tFSMakeDocStdList.forEach(element => {
      element.Selected = false;
    })
    this.tFSMakeDocStdList[index].Selected = true;


    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B03/Get_TFS_MakeDoc_StdDetail", { DataKey: datakey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocStdDetail = undefined;
        return;
      }
      this.tFSMakeDocStdDetail = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocStdSave() {

    if (!this.tFSMakeDocStdDetail) return;
    let pass = true;
    for (let index = 0; index < this.tFSMakeDocStdDetail.length; index++) {
      if (this.checkType.CheckIsNumber(this.tFSMakeDocStdDetail[index].MustMoney)) {
        this.toastr.warning(`第${index + 1}項非數字`);
        pass = false;
        break;
      }
    }

    if (pass) {
      this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B03/Get_TFS_MakeDoc_StdSave", this.tFSMakeDocStdDetail).subscribe((response) => {
        if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
        }
        this.toastr.success("儲存成功!");
      }, error => this.toastr.error(error.error))
    }
  }

  closeSignalModalRef() {
    this.signalModalRef.hide();
    this.tFSMakeDocStdDetail = undefined;
    this.tFSMakeDocStdList = [];
  }
}
