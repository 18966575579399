<div class="container" style="margin-bottom: 100px;">
    <button type="button" class="other-Button-gray mb-3" style="margin: auto;" (click)="saveStdData()">{{'全部儲存' |
        translate}}</button>
    <tabset type="pills" #tabs *ngIf="stdData">
        <tab heading="{{'基本資料' | translate}}">
            <div class="row mt-5">
                <div class="col-md-3 mb-3">
                    <figure class="figure">
                        <img [src]="ImgUrl" class="figure-img img-fluid rounded" />
                        <figcaption class="figure-caption">
                            <button class="other-Button-gray img-btn" (click)="openModal1(template1)"
                                [hidden]="stdData.Write[0].PIC == '0' || stdData.Write[0].PIC == ''">{{'上傳' |
                                translate}}</button>
                            <small class="text-danger fw-bolder ps-3">{{'*請勿上傳生活照' | translate}}</small>
                        </figcaption>
                    </figure>
                </div>
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-12 col-md-4 mb-3">
                            <div class="input-group flex-nowrap">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{'中文姓名' | translate}}</span>
                                </div>
                                <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.Std_Name"
                                    disabled>
                            </div>
                        </div>
                        <div class="col-6 col-md-4 mb-3">
                            <div class="input-group flex-nowrap">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{'性別' | translate}}</span>
                                </div>
                                <select [(ngModel)]="stdData.Sex" disabled class="form-select shadow-none">
                                    <option value="" disabled selected>{{'請選擇' | translate}}</option>
                                    <option value="男">{{'男' | translate}}</option>
                                    <option value="女">{{'女' | translate}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 col-md-4 mb-3">
                            <div class="input-group flex-nowrap">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{'血型' | translate}}</span>
                                </div>
                                <select class="form-select shadow-none" [(ngModel)]="stdData.Blood" disabled>
                                    <option value="" disabled selected>{{'請選擇' | translate}}</option>
                                    <option *ngFor="let item of bloodType" value="{{item.itemValue}}">
                                        {{item.itemName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4 mb-3">
                            <div class="input-group flex-nowrap">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{'身分證字號' | translate}}</span>
                                </div>
                                <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.ID_No"
                                    disabled>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <div class="input-group flex-nowrap">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{'英文姓名' | translate}}</span>
                                </div>
                                <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.Std_EngName"
                                    [disabled]="stdData.Write[0].Std_EngName == '0' || stdData.Write[0].Std_EngName == ''">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <label class="input-group-text">{{'國籍' | translate}}</label>
                                </div>
                                <select class="form-select shadow-none" [(ngModel)]="stdData.Country_ID"
                                    [disabled]="stdData.Write[0].Country_ID == '0' || stdData.Write[0].Country_ID == ''">
                                    <option selected disabled value="">{{'請選擇' | translate}}</option>
                                    <option *ngFor="let item of countryList" value="{{item.ItemValue}}">
                                        {{item.ItemName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <label class="input-group-text">{{'婚姻狀況' | translate}}</label>
                                </div>
                                <select class="form-select shadow-none" [(ngModel)]="stdData.Marry"
                                    [disabled]="stdData.Write[0].Marry == '0' || stdData.Write[0].Marry == ''">
                                    <option selected disabled value="">{{'請選擇' | translate}}</option>
                                    <option *ngFor="let item of marriage" value="{{item.itemValue}}">
                                        {{item.itemName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <label class="input-group-text">{{'兵役狀況' | translate}}</label>
                                </div>
                                <select class="form-select shadow-none" [(ngModel)]="stdData.Military_Service"
                                    [disabled]="stdData.Write[0].Military_Service == '0' || stdData.Write[0].Military_Service == ''">
                                    <option disabled selected value="">{{'請選擇' | translate}}</option>
                                    <option *ngFor="let item of army" value="{{item.itemValue}}">
                                        {{item.itemName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input-group flex-nowrap mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{'個人信箱' | translate}}</span>
                                </div>
                                <input type="email" class="form-control shadow-none" placeholder="example@gmail.com"
                                    [(ngModel)]="stdData.Other_Email"
                                    [disabled]="stdData.Write[0].Other_Email == '0' || stdData.Write[0].Other_Email == ''">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <label>
                {{'戶籍地址：' | translate}}</label>
            <div class="row">

                <div class="col-md mb-3">
                    <div class="input-group flex-nowrap">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{'郵遞區號' | translate}}</span>
                        </div>
                        <input type="text" maxlength="6" placeholder="3+2 或 3+3" class="form-control shadow-none"
                            [(ngModel)]="stdData.Nom_Zip"
                            [disabled]="stdData.Write[0].Nom_Address == '0' || stdData.Write[0].Nom_Address == ''">
                    </div>
                </div>

                <div class="col-md">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <label class="input-group-text">{{'縣市' | translate}}</label>
                        </div>
                        <select class="form-select shadow-none" #city (change)="getTownList(city.value)"
                            [(ngModel)]="stdData.Nom_City"
                            [disabled]="stdData.Write[0].Nom_Address == '0' || stdData.Write[0].Nom_Address == ''">
                            <option selected disabled value="">{{'請選擇' | translate}}</option>
                            <option *ngFor="let item of cityList" value="{{item.ItemValue}}">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <label class="input-group-text">{{'區域' | translate}}</label>
                        </div>
                        <select class="form-select shadow-none" [(ngModel)]="stdData.Nom_Town"
                            [disabled]="stdData.Write[0].Nom_Address == '0' || stdData.Write[0].Nom_Address == ''">
                            <option selected disabled value="">{{'請選擇' | translate}}</option>
                            <option *ngFor="let item of nomTownList" value="{{item.ItemValue}}">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.Village_Name"
                            [disabled]="stdData.Write[0].Nom_Address == '0' || stdData.Write[0].Nom_Address == ''">
                        <span class="input-group-text">{{'村/里' | translate}}</span>
                    </div>
                </div>
                <div class="col-md">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.NeighborHood"
                            [disabled]="stdData.Write[0].Nom_Address == '0' || stdData.Write[0].Nom_Address == ''">
                        <span class="input-group-text">{{'鄰' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control shadow-none" placeholder="地址剩下部分請於此填寫，請勿再從頭寫一次"
                            [(ngModel)]="stdData.Nom_Address"
                            [disabled]="stdData.Write[0].Nom_Address == '0' || stdData.Write[0].Nom_Address == ''">
                    </div>
                </div>
            </div>

            <label>{{'通訊地址：' | translate}}</label>
            <div class="row">
                <div class="col">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <label class="input-group-text">{{'中文通訊地址' | translate}}</label>
                        </div>
                        <input type="address" class="form-control shadow-none" placeholder="請填寫完整地址"
                            [(ngModel)]="stdData.Com_Address"
                            [disabled]="stdData.Write[0].Com_Address == '0' || stdData.Write[0].Com_Address == ''">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <label class="input-group-text">{{'英文通訊地址' | translate}}</label>
                        </div>
                        <input type="email" class="form-control shadow-none"
                            placeholder="Please Enter Your Full English Address" [(ngModel)]="stdData.Eng_Address"
                            [disabled]="stdData.Write[0].Eng_Address == '0' || stdData.Write[0].Eng_Address == ''">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <label class="input-group-text">{{'戶籍電話' | translate}}</label>
                        </div>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.Nom_Tel"
                            [disabled]="stdData.Write[0].Nom_Tel == '0' || stdData.Write[0].Nom_Tel == ''">
                    </div>
                </div>
                <div class="col-md">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <label class="input-group-text">{{'通訊電話' | translate}}</label>
                        </div>
                        <input type="tel" class="form-control shadow-none" [(ngModel)]="stdData.Com_Tel"
                            [disabled]="stdData.Write[0].Com_Tel == '0' || stdData.Write[0].Com_Tel == ''">
                    </div>
                </div>
                <div class="col-md">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <label class="input-group-text">{{'行動電話' | translate}}</label>
                        </div>
                        <input type="tel" class="form-control shadow-none" [(ngModel)]="stdData.Mobile_Tel"
                            [disabled]="stdData.Write[0].Mobile_Tel == '0' || stdData.Write[0].Mobile_Tel == ''">
                    </div>
                </div>
            </div>
        </tab>

        <ng-template #template1>
            <div class="modal-header">
                <h4 class="modal-title pull-left">設定大頭貼</h4>
                <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
                    (click)="modalRef?.hide()">
                    <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">大頭貼設定</div>
                            <div class="card-body" style="height: 350px;">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="input-group mb-3">
                                            <input name="" type="file" id="image" accept=".png"
                                                class="form-control shadow-none" (change)="fileChangeEvent($event)" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <button class="other-Button-gray mt-3 float-end" [hidden]="!showCropper"
                                            (click)="clearImage()">清除</button>
                                        <button class="other-Button-gray mt-3 float-end me-3" [hidden]="!showCropper"
                                            (click)="uploadImage1()">上傳</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">大頭貼預覽</div>
                            <div class="card-body" style="height: 350px; overflow-y: scroll;">
                                <h5 [hidden]="!showCropper" class="card-title">移動匡線來調整位置</h5>
                                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                    [containWithinAspectRatio]="false" [cropperMinWidth]="128" [aspectRatio]="4.2/4.7"
                                    format="png" (imageCropped)="imageCropped1($event)"
                                    (imageLoaded)="imageLoaded($event)" (loadImageFailed)="loadImageFailed()"
                                    format="png" [style.display]="showCropper ? null : 'none'">
                                </image-cropper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <tab heading="{{'學歷資料' | translate}}">
            <button class='other-Button-gray me-3 mt-3 mb-3' style="width: 130px;"
                (click)="deleteData('school')">{{'刪除最後一筆' | translate}}</button>
            <button class='other-Button-gray mt-3 mb-3' (click)="addData('school')">{{'新增一筆' | translate}}</button>
            <table class="table table-hover text-center" id="school" *ngIf="stdData.STD02 else nothing">
                <thead class="thead-dark-red">
                    <tr>
                        <th>{{'學校類別' | translate}}</th>
                        <th>{{'學校名稱' | translate}}</th>
                        <th>{{'所屬縣市' | translate}}</th>
                        <th>{{'科系名稱' | translate}}</th>
                        <th style="width: 80px;">{{'畢業年' | translate}}</th>
                        <th style="width: 80px;">{{'畢業月' | translate}}</th>
                        <th>{{'畢(肄)業' | translate}}</th>
                        <th style="width: 120px;">{{'最高學歷註記' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdData.STD02">
                        <td>
                            <select class="form-select shadow-none"
                                [disabled]="stdData.Write[0].STD02 == '0' || stdData.Write[0].STD02 == ''">
                                <option selected disabled value="">{{'請選擇' | translate}}</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control shadow-none" [(ngModel)]="item.Sch_Name"
                                [disabled]="stdData.Write[0].STD02 == '0' || stdData.Write[0].STD02 == ''">
                        </td>
                        <td>
                            <select class="form-select shadow-none"
                                [disabled]="stdData.Write[0].STD02 == '0' || stdData.Write[0].STD02 == ''">
                                <option selected disabled value="">{{'請選擇' | translate}}</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control shadow-none" [(ngModel)]="item.Dept_Name"
                                [disabled]="stdData.Write[0].STD02 == '0' || stdData.Write[0].STD02 == ''">
                        </td>
                        <td style="width: 80px;">
                            <input type="text" class="form-control shadow-none" [(ngModel)]="item.Grad_Year"
                                [disabled]="stdData.Write[0].STD02 == '0' || stdData.Write[0].STD02 == ''">
                        </td>
                        <td style="width: 80px;">
                            <input type="text" class="form-control shadow-none" [(ngModel)]="item.Grad_Month"
                                [disabled]="stdData.Write[0].STD02 == '0' || stdData.Write[0].STD02 == ''">
                        </td>
                        <td>
                            <select class="form-select shadow-none" [(ngModel)]="item.Grad_Type"
                                [disabled]="stdData.Write[0].STD02 == '0' || stdData.Write[0].STD02 == ''">
                                <option selected disabled value="">{{'請選擇' | translate}}</option>
                                <option value="G">{{'畢業' | translate}}</option>
                                <option value="N">{{'肄業' | translate}}</option>
                            </select>
                        </td>
                        <td style="width: 120px;">
                            <select class="form-select shadow-none" [(ngModel)]="item.Mark"
                                [disabled]="stdData.Write[0].STD02 == '0' || stdData.Write[0].STD02 == ''">
                                <option value=""></option>
                                <option value="*">*</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="{{'家庭資料' | translate}}">
            <button class='other-Button-gray me-3 mt-3 mb-3' style="width: 130px;"
                (click)="deleteData('family')">{{'刪除最後一筆' | translate}}</button>
            <button class='other-Button-gray mt-3 mb-3' (click)="addData('family')">{{'新增一筆' | translate}}</button>
            <table class="table table-hover text-center" id="family" *ngIf="stdData.STD03 else nothing">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 110px;">{{'稱謂' | translate}}</th>
                        <th style="width: 100px;">{{'姓名' | translate}}</th>
                        <th style="width: 130px;">{{'身分證字號' | translate}}</th>
                        <th style="width: 130px;">{{'聯絡電話' | translate}}</th>
                        <th style="width: 90px;">{{'出生日期' | translate}}</th>
                        <th>{{'職業' | translate}}</th>
                        <th style="width: 100px;">{{'郵遞區號' | translate}}</th>
                        <th>{{'聯絡地址' | translate}}</th>
                        <th style="width: 90px;">{{'監護人' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdData.STD03">
                        <td style="width: 90px;">
                            <select class="form-select shadow-none" [(ngModel)]="item.Rel_No">
                                <option value="" disabled selected>{{'請選擇' | translate}}</option>
                                <option *ngFor="let item of relNameList" value="{{item.Rel_No}}">
                                    {{item.Rel_Name}}
                                </option>
                            </select>
                        </td>
                        <td style="width: 100px;"><input type="text" [(ngModel)]="item.Dep_Name"
                                class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD03 == '0' || stdData.Write[0].STD03 == ''"></td>
                        <td style="width: 130px;"><input type="text" [(ngModel)]="item.Dep_ID"
                                class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD03 == '0' || stdData.Write[0].STD03 == ''"></td>
                        <td style="width: 130px;"><input type="text" [(ngModel)]="item.Com_Tel"
                                class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD03 == '0' || stdData.Write[0].STD03 == ''"></td>
                        <td style="width: 90px;"><input type="date" [(ngModel)]="item.Birthday"
                                class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD03 == '0' || stdData.Write[0].STD03 == ''"></td>
                        <td><input type="text" [(ngModel)]="item.Occ_No" class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD03 == '0' || stdData.Write[0].STD03 == ''"></td>
                        <td style="width: 100px;"><input type="text" [(ngModel)]="item.Com_Zip"
                                class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD03 == '0' || stdData.Write[0].STD03 == ''"></td>
                        <td><input type="text" [(ngModel)]="item.Com_Address" class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD03 == '0' || stdData.Write[0].STD03 == ''"></td>
                        <td style="width: 90px;">
                            <select class="form-select shadow-none" [(ngModel)]="item.Mark"
                                [disabled]="stdData.Write[0].STD03 == '0' || stdData.Write[0].STD03 == ''">
                                <option value="">{{'否' | translate}}</option>
                                <option value="*">{{'是' | translate}}</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="{{'經歷資料' | translate}}">
            <table class="table table-hover text-center mt-5" id="job" *ngIf="stdData.STD04 else nothing">
                <thead class="thead-dark-red">
                    <tr>
                        <th>{{'服務機關' | translate}}</th>
                        <th>{{'職務名稱' | translate}}</th>
                        <th>{{'起始年' | translate}}</th>
                        <th>{{'起始月' | translate}}</th>
                        <th>{{'終止年' | translate}}</th>
                        <th>{{'終止月' | translate}}</th>
                        <th>{{'備註' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdData.STD04">
                        <td><input type="text" [(ngModel)]="item.Company" class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD04 == '0' || stdData.Write[0].STD04 == ''"></td>
                        <td><input type="text" [(ngModel)]="item.Duty" class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD04 == '0' || stdData.Write[0].STD04 == ''"></td>
                        <td><input type="text" [(ngModel)]="item.Start_Year" class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD04 == '0' || stdData.Write[0].STD04 == ''"></td>
                        <td><input type="text" [(ngModel)]="item.Start_Month" class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD04 == '0' || stdData.Write[0].STD04 == ''"></td>
                        <td><input type="text" [(ngModel)]="item.End_Year" class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD04 == '0' || stdData.Write[0].STD04 == ''"></td>
                        <td><input type="text" [(ngModel)]="item.End_Month" class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD04 == '0' || stdData.Write[0].STD04 == ''"></td>
                        <td><input type="text" [(ngModel)]="item.Memo" class="form-control shadow-none"
                                [disabled]="stdData.Write[0].STD04 == '0' || stdData.Write[0].STD04 == ''"></td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="{{'註冊資料' | translate}}">
            <table class="table table-hover text-center mt-5" id="regi" *ngIf="stdData.STD05 else nothing">
                <thead class="thead-dark-red">
                    <tr>
                        <th>{{'註冊學年' | translate}}</th>
                        <th>{{'註冊學期' | translate}}</th>
                        <th> {{'註冊班級' | translate}}</th>
                        <th>{{'總修業年限' | translate}}</th>
                        <th>{{'已修業年限' | translate}}</th>
                        <th>{{'可修業年限' | translate}}</th>
                        <th>{{'註冊日期' | translate}}</th>
                        <th>{{'完成註冊' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdData.STD05">
                        <td>{{item.Regi_Year}}</td>
                        <td>{{item.Regi_Term}}</td>
                        <td>{{item.Class_Name}}</td>
                        <td>{{item.Total_Term}}</td>
                        <td>{{item.Already_Term}}</td>
                        <td>{{item.Remain_Term}}</td>
                        <td>{{item.Regi_Date}}</td>
                        <td [ngClass]="{'text-success': item.Result_Log == 'Y', 'text-danger': item.Result_Log == ''}">
                            {{item.Result_Log == 'Y'? '已註冊' : '未註冊'}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="{{'輔系雙主修' | translate}}">
            <table class="table table-hover text-center mt-5" *ngIf="stdData.STD09 else nothing">
                <thead class="thead-dark-red">
                    <tr>
                        <th>{{'修讀學年' | translate}}</th>
                        <th>{{'修讀學期' | translate}}</th>
                        <th>{{'修讀類型' | translate}}</th>
                        <th>{{'加修科系' | translate}}</th>
                        <th>{{'核示狀態' | translate}}</th>
                        <th>{{'放棄日期' | translate}}</th>
                        <th>{{'修讀結果' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdData.STD09">
                        <td>{{item.Add_Year}}</td>
                        <td>{{item.Add_Term}}</td>
                        <td>{{item.Add_Type_Name}}</td>
                        <td>{{item.Dept_Name}}</td>
                        <td class="fw-bolder" [ngClass]="{'text-success' : item.Approve == 'Y', 
                                        'text-danger' : item.Approve == 'N'}">
                            {{item.Approve == 'Y'? '已通過' : '不通過'}}
                        </td>
                        <td>{{item.Give_Date}}</td>
                        <td class="fw-bolder" [ngClass]="{'text-warning' : item.Result_Log == 'Y', 
                                        'text-danger' : item.Result_Log == 'N',
                                        'text-success' : item.Result_Log == 'F'}">
                            {{item.Result_Log == 'Y'? '進修中' : item.Result_Log == 'N'? '放棄' : '完成'}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="{{'轉系資料' | translate}}">
            <table class="table table-hover text-center mt-5" id="job" *ngIf="stdData.STD08 else nothing">
                <thead class="thead-dark-red">
                    <tr>
                        <th>{{'轉入學年' | translate}}</th>
                        <th>{{'轉入學期' | translate}}</th>
                        <th>{{'申請原因' | translate}}</th>
                        <th>{{'核示狀態' | translate}}</th>
                        <th>{{'核示日期' | translate}}</th>
                        <th>{{'原屬科系' | translate}}</th>
                        <th>{{'原屬班級' | translate}}</th>
                        <th>{{'轉入科系' | translate}}</th>
                        <th>{{'轉入班級' | translate}}</th>
                        <th>{{'降轉狀態' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdData.STD08">
                        <td>{{item.Turn_Year}}</td>
                        <td>{{item.Turn_Term}}</td>
                        <td>{{item.Turn_Name}}</td>
                        <td>{{item.Approve == 'N'? '否' : '是'}}</td>
                        <td>{{item.Approve_Date}}</td>
                        <td>{{item.Old_Dept_Name}}</td>
                        <td>{{item.Old_Class_Name}}</td>
                        <td>{{item.Dept_Name}}</td>
                        <td>{{item.Class_Name}}</td>
                        <td>{{item.DownMark == 'N'? '否' : '是'}}</td>
                    </tr>
                </tbody>
            </table>
        </tab>
        <tab heading="{{'休復學資料' | translate}}">
            <table class="table table-hover text-center mt-5" id="job" *ngIf="stdData.STD06 else nothing">
                <thead class="thead-dark-red">
                    <tr>
                        <th>{{'休學學年' | translate}}</th>
                        <th>{{'休學學期' | translate}}</th>
                        <th>{{'休學學期數' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdData.STD06">
                        <td>{{item.Rest_Year}}</td>
                        <td>{{item.Rest_Term}}</td>
                        <td>{{item.TermCounts}}</td>
                    </tr>
                </tbody>
            </table>
        </tab>
    </tabset>

</div>

<ng-template #nothing>
    <h2 class="text-center mt-3 text-danger fw-bolder">{{'暫時沒有資料' | translate}}</h2>
</ng-template>