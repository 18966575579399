import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { environment } from 'src/assets/environments/environment';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { GetStdRestListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0301/getStdRestListInput';

@Component({
    selector: 'app-ADSTD0814ISU',
    templateUrl: './ADSTD0814ISU.component.html',
    styleUrls: ['./ADSTD0814ISU.component.css']
})
export class ADSTD0814ISUComponent implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    initialData = new InitialData(this.http);
    getStdRestListInput: GetStdRestListInput = {
        RestYear: '',
        RestTerm: '',
        Approve: '',
        EduNo: '',
        DeptNo: '',
        Class: '',
        Status: '',
        IDNo: '',
        StdNo: '',
        StdName: ''
    };

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '3', this.getStdRestListInput.RestYear, this.getStdRestListInput.RestTerm, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', this.getStdRestListInput.RestYear, this.getStdRestListInput.RestTerm, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

}
