import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GetOfficeHourRecord } from 'src/app/_Models/STDWEB/STDW40006/getOfficeHourRecord';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW40006',
    templateUrl: './STDW40006.component.html',
    styleUrls: ['./STDW40006.component.css']
})
export class STDW40006Component implements OnInit {

    baseUrl = environment.apiUrl;
    data!: GetOfficeHourRecord[];
    model = {
        Years: '',
        Term: ''
    }
    constructor(private systemService: SystemService, private http: HttpClient, private toastr: ToastrService, private timeService: TimeService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
            this.getOfficeHourRecord(this.model);
        });
    }

    setModel(model: any) {
        this.model.Years = model.Years;
        this.model.Term = model.Term;
        this.getOfficeHourRecord(this.model);
    }

    getOfficeHourRecord(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW400_06/Get_OH_Record", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;

        })
    }

}
