import { HttpClient } from '@angular/common/http';
import { Component, OnInit , TemplateRef} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetGRDCETList } from 'src/app/_Models/AD/ADGRD/ADGRD0502/getGRDCETList';
import { GetGRDCETDetail } from 'src/app/_Models/AD/ADGRD/ADGRD0502/getGRDCETDetail';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADGRD0502',
    templateUrl: './ADGRD0502.component.html',
    styleUrls: ['./ADGRD0502.component.css']
})
export class ADGRD0502Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    eduList?: GetEduList[];
    deptList?: GetDeptList[];
    baseUrl = environment.apiUrl;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    GRDCETList!:GetGRDCETList[];
    thisGRDCETList!:GetGRDCETList;
    GetGRDCETDetail!:GetGRDCETDetail;
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        StdNo: new UntypedFormControl('')
    });
    modalRef?: BsModalRef;
    isNewData = false;

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
        this.thisGRDCETList={
            Years:'',
            Term:'',
            StdNo:'',
            StdName:'',
            ClassName:'',
            ThesisName:'',
            TestingDate:'',
            ThesisScore:'',
            DataKey:''
        }
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('4', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.eduList = undefined;
            this.eduList = response;
        })
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = undefined;
            this.deptList = response;

        });
    }

    doGetGRDCETList(){
        this.GRDCETList=[];
        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0502/Get_GRD_Thesis_List", this.ngFormInput.value).subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.GRDCETList = response;
        }));
    }

    openModal(thisCET: GetGRDCETList,template: TemplateRef<any>){
        if(thisCET.DataKey!=""){
            this.isNewData = false;
            this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0502/Get_GRD_Thesis_Detail", thisCET).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.GetGRDCETDetail = response;
                this.GetGRDCETDetail.ClassName = this.GetGRDCETDetail.ClassName;
                this.GetGRDCETDetail.StdNo = this.GetGRDCETDetail.StdNo;
                this.GetGRDCETDetail.StdName = this.GetGRDCETDetail.StdName;
                this.GetGRDCETDetail.ThesisName = this.GetGRDCETDetail.ThesisName;
                this.GetGRDCETDetail.Years = this.GetGRDCETDetail.Years;
                this.GetGRDCETDetail.Term = this.GetGRDCETDetail.Term;
                this.GetGRDCETDetail.TestingDateY = this.GetGRDCETDetail.TestingDateY;
                this.GetGRDCETDetail.TestingDateM = this.GetGRDCETDetail.TestingDateM;
                this.GetGRDCETDetail.TestingDateD = this.GetGRDCETDetail.TestingDateD;
                this.GetGRDCETDetail.ThesisScore = this.GetGRDCETDetail.ThesisScore;
            })
        }
        else{
            this.isNewData = true;
            this.GetGRDCETDetail={
                Years:'',
                Term:'',
                StdNo:'',
                StdName:'',
                ClassName:'',
                ThesisName:'',
                TestingDateY:'',
                TestingDateM:'',
                TestingDateD:'',
                ThesisScore:'',
                DataKey:''
            }
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getCETModify(){
        if(this.GetGRDCETDetail.DataKey==''){
            if(this.GetGRDCETDetail.StdNo==''){
                this.toastr.info("請輸入學號!");
                return;
            }
        }

        if(this.GetGRDCETDetail.ThesisScore!=''){
            if(parseInt(this.GetGRDCETDetail.ThesisScore)<0 || parseInt(this.GetGRDCETDetail.ThesisScore)>100 ){
                this.toastr.info("成績錯誤!");
                return;
            }
        }

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0502/Get_GRD_Thesis_Save", this.GetGRDCETDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            if(this.isNewData == true){
                this.modalRef?.hide();
            }
        })
    }

    getCETDelete(){
        Swal.fire({
            title: `確定刪除此學生的論文資料?`,
            confirmButtonText: '確定',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: '取消'
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0502/Get_GRD_Thesis_Delete", this.GetGRDCETDetail).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Warning);
                        return;
                    }
                    this.doGetGRDCETList();
                    this.toastr.success("刪除成功");
                    this.modalRef?.hide();
                })
            }
        })
    }
}
