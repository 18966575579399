import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { zhCnLocale } from 'ngx-bootstrap/locale';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

// 定義DatePicker語言參數。
defineLocale('zh-cn', zhCnLocale);


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    SortableModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot()
  ],
  exports: [
    ButtonsModule,
    CollapseModule,
    BsDropdownModule,
    TabsModule,
    CarouselModule,
    ModalModule,
    AlertModule,
    BsDatepickerModule,
    AccordionModule,
    PopoverModule,
    ProgressbarModule,
    TimepickerModule,
    SortableModule,
    TypeaheadModule
  ]
})
export class BootstrapModule {}
