import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADGRD0601',
    templateUrl: './ADGRD0601.component.html',
    styleUrls: ['./ADGRD0601.component.css']
})
export class ADGRD0601Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    eduList?: GetEduList[];
    deptList?: GetDeptList[];
    classList?: GetClassList[];
    fileExchange = new FileExchange();
    baseUrl = environment.apiUrl;
    Years!: Array<string>;
    initialData = new InitialData(this.http);

    ngFormInput = new UntypedFormGroup({
        ReportType: new UntypedFormControl('C', [Validators.required]),
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        ClassList: new UntypedFormControl(''),
        StdNoFrom: new UntypedFormControl(''),
        StdNoTo: new UntypedFormControl('')
    });

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
                this.getClassList('', '', this.Years[0], '1')
            }
        });
    }

    getEduList(Years: string, Term: string) {
        this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.eduList = undefined;
            this.eduList = response;
        })
    }

    getDeptList(edu: string, Years: string, Term: string) {
        this.systemService.getDeptList(edu, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = undefined;
            this.deptList = response;

        });
    }

    getClassList(edu: string, dept: string, Years: string, Term: string) {
        this.systemService.getClassList(edu, dept, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classList = undefined;
            this.classList = response;
        })
    }

    setAll(eventTarget: EventTarget | null) {
        let input = <HTMLInputElement>eventTarget;
        this.classList?.forEach(element => {
            input.checked == true ? element.checked = true : element.checked = false;
        });
    }

    export_Report_ADGRD0601() {

        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        let classList = '';

        this.classList?.forEach(element => {
            if (element.checked == true) {
                classList += element.Class + ','
            }
        });

        if (classList == '' && this.ngFormInput.get('StdNoFrom')?.value=='' && this.ngFormInput.get('StdNoTo')?.value=='') {
            this.toastr.info('請勾選班級');
            return;
        }

        this.ngFormInput.get('ClassList')?.setValue(classList.substring(0, classList.length - 1));

        this.fileExchangeServices.ExportFile("ACAD/GRD/ADGRD0601/Export_Report_ADGRD0601", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

}
