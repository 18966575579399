import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetSRPList } from 'src/app/_Models/AD/ADSRP/ADSRP0201/getSRPList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSRP0201',
    templateUrl: './ADSRP0201.component.html',
    styleUrls: ['./ADSRP0201.component.css']
})
export class ADSRP0201Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    bsConfig = environment.bsDatePickerConfig;
    fileExchange = new FileExchange();
    deptList!: GetDeptList[];
    eduList!: GetEduList[];
    classList!: GetClassList[];
    rewardsList!: GetSelectList[];
    sugCodeList!: GetSelectList[];
    modalRef?: BsModalRef;
    stdList: Array<string> = [];
    typeList: Array<string> = [];
    nowYear = '';
    nowTerm = '';

    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        StdNoName: new UntypedFormControl(''),
        IssueDateFrom: new UntypedFormControl(null),
        IssueDateTo: new UntypedFormControl(null),
        ApplyDateFrom: new UntypedFormControl(null),
        ApplyDateTo: new UntypedFormControl(null),
        TypeList: new UntypedFormControl(''),
        Offset: new UntypedFormControl(0)
    });

    ngFormDetail = new UntypedFormGroup({
        ApplyDate: new UntypedFormControl(null),
        ApproveDate: new UntypedFormControl(''),
        DataKey: new UntypedFormControl(''),
        DocNo: new UntypedFormControl(''),
        FileBase64: new UntypedFormControl(''),
        FileName: new UntypedFormControl(''),
        SerialNo: new UntypedFormControl(''),
        ID: new UntypedFormControl(''),
        IssueDate: new UntypedFormControl(''),
        IssueUserCode: new UntypedFormControl({ value: '', disabled: true }),
        IssueUserName: new UntypedFormControl({ value: '', disabled: true }),
        SugCode: new UntypedFormControl(''),
        Means: new UntypedFormControl(''),
        Reason: new UntypedFormControl(''),
        Status: new UntypedFormControl(''),
        StdName: new UntypedFormControl(''),
        StdNo: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        Times: new UntypedFormControl(0),
        TypeID: new UntypedFormControl(''),
        Years: new UntypedFormControl(''),
        Base64: new UntypedFormControl('')
    });

    sRPList?: GetSRPList[];

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private systemService: SystemService,
        private modalService: BsModalService,
        private timeService: TimeService,
        private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.getEduList();
        this.getRewardsList();
        this.timeService.getTimeCntr().subscribe((response) => {
            this.nowTerm = response.Now_Term;
            this.nowYear = response.Now_Years;
        });
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList() {
        this.systemService.getDeptList(this.ngFormInput.get('EduNo')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList() {
        this.systemService.getClassList(this.ngFormInput.get('EduNo')?.value, this.ngFormInput.get('DeptNo')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getRewardsList() {
        this.rewardsList = [];

        this.systemService.getRewardsList('').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.rewardsList = response;
        }, error => this.toastr.error(error.error))
    }

    getSuggestList(thisYear: string) {
        this.sugCodeList = [];

        this.systemService.getSuggestList(thisYear).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sugCodeList = response;
        }, error => this.toastr.error(error.error))
    }

    openModel(template: TemplateRef<any>, type: string, dataKey: string) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.ngFormDetail.get('StdName')?.disable();

        if (type == 'new') {
            this.ngFormDetail.reset({
                DataKey: ''
            });
            this.ngFormDetail.get("Years")?.setValue(this.nowYear);
            this.ngFormDetail.get("Term")?.setValue(this.nowTerm);
            this.getSuggestList(this.nowYear);
        }
        else {
            this.http.post<any>(this.baseUrl + "ACAD/SRP/ADSRP0201/Get_SRP_Detail", { DataKey: dataKey }).subscribe(response => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.ngFormDetail.setValue(response);
                this.getSuggestList(response.Years);
            })
        }
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setCheck(event: EventTarget | null) {
        let input = <HTMLInputElement>event;
        let index = this.typeList.indexOf(input.value);
        if (input.checked && index == -1) {
            this.typeList.push(input.value);
        }
        else {
            this.typeList.splice(index, 1);
        }

        if (this.typeList.length == 0) return;

        let data = '';

        this.typeList.forEach(element => {
            data += element + ','
        });

        this.ngFormInput.get('TypeList')?.setValue(data);
    }

    getSRPList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SRP/ADSRP0201/Get_SRP_List", this.ngFormInput.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sRPList = undefined;
                return;
            }
            this.sRPList = response;
        });
    }

    getSRPBatchSign() {

        if (!this.sRPList) {
            this.toastr.info('請選擇學生');
            return;
        };

        Swal.fire({
            title: '確定進行審核？',
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/SRP/ADSRP0201/Get_SRP_BatchSign", this.sRPList).subscribe(response => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success('審核成功');
                });
            } else {
                this.toastr.info('動作已取消');
            }
        })


    }

    getSRPBatchDelete() {
        if (!this.sRPList) {
            this.toastr.info('請選擇學生');
            return;
        };

        Swal.fire({
            title: '確定進行刪除？',
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + 'ACAD/SRP/ADSRP0201/Get_SRP_BatchDelete', this.sRPList).subscribe(response => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success('刪除成功');
                    this.getSRPList();
                });
            } else {
                this.toastr.info('動作已取消');
            }
        })
    }

    getSRPSave() {
        this.ngFormDetail.get('Status')?.setValue('2');

        if(this.ngFormDetail.get('Base64')?.value==null){
            this.ngFormDetail.get('Base64')?.setValue('');
        }

        this.http.post<any>(this.baseUrl + 'ACAD/SRP/ADSRP0201/Get_SRP_Save', this.ngFormDetail.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('儲存成功');
            this.modalRef?.hide();
        })
    }

    importAttFile($event: any, dataKey: string, type: string) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetFileBase64, dataKey, type)
    }

    GetFileBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.ngFormDetail.get('FileBase64')?.setValue(base64);
                that.ngFormDetail.get('FileName')?.setValue(file.name);
            }
            else if (result.isDenied) {
                let file = <HTMLInputElement>document.querySelector('#file');
                file.value = '';
                that.toastr.info("已取消上傳。");
                that.ngFormDetail.get('FileBase64')?.setValue('');
                that.ngFormDetail.get('FileName')?.setValue('');
            }
        });
    }

    setAll(event: EventTarget | null) {
        if (!this.sRPList) return;
        let input = <HTMLInputElement>event;
        this.sRPList.forEach(element => {
            input.checked == true ? element.Checked = true : element.Checked = false;
        })
    }

    getSRPFile(dataKey: string) {
        this.fileExchangeServices.ExportFile('ACAD/SRP/ADSRP0201/Get_SRP_File', { DataKey: dataKey }).subscribe((response) => {
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    getSRPSampleFile() {
        this.http.get<any>(this.baseUrl + 'ACAD/SRP/ADSRP0201/Get_SRP_SampleFile').subscribe(response => {
            let contentType = "application/octet-stream"
            let blob = this.fileExchange.Base64ToBlob(response.Base64, contentType, 512);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = '學生獎懲紀錄匯入範本.xls';
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        })
    }

    resetFile() {
        this.ngFormDetail.get('StdNo')?.enable();
        let file = <HTMLInputElement>document.querySelector('#file');
        file.value = '';
        this.ngFormDetail.get('Base64')?.setValue('');
    }

    importData($event: any) {
        this.ngFormDetail.get('StdNo')?.disable();
        this.ngFormDetail.get('StdNo')?.setValue('');
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, '', '')
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                console.log('base64=' + base64);
                that.ngFormDetail.get('Base64')?.setValue(base64);
            }
            else if (result.isDenied) {
                let file = <HTMLInputElement>document.querySelector('#file');
                file.value = '';
                that.toastr.info("已取消上傳。");
                that.ngFormDetail.get('Base64')?.setValue('');
            }
        });
    }
}
