<div class="container" style="width: 40%;">
    <form [formGroup]="ngForm" (ngSubmit)="getCURTchMoneyOverLimitModify()">
        <div class="row">
            <div class="col-md-12">
                <div class="form-check mb-3">
                    <input class="form-check-input pinter shadow-none" type="checkbox" value="" id="flexCheckDefault"
                        formControlName="UsePercent">
                    <label class="form-check-label pinter" for="flexCheckDefault">使用鐘點比例</label>
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">校長應受鐘點時數</span>
                    </div>
                    <input type="number" class="form-control shadow-none" min="0" step="0.01" max="99"
                        formControlName="PresidentDefaultHours">
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">專任教師超支鐘點限制</span>
                    </div>
                    <input type="number" class="form-control shadow-none" min="0" step="0.01" max="99"
                        formControlName="FullTimeLimit">
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">通識與師培課程可超支鐘點</span>
                    </div>
                    <input type="number" class="form-control shadow-none" min="0" step="0.01" max="99"
                        formControlName="PartTimeLimit">
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <div class="form-check">
                                <input class="form-check-input pinter shadow-none" type="checkbox" value=""
                                    id="flexCheckDefault1" formControlName="UseAllEng">
                                <label class="form-check-label pinter" for="flexCheckDefault1">使用全程英語教學</label>
                            </div>
                        </span>
                    </div>
                    <input type="number" class="form-control shadow-none" min="0" step="0.1" max="99"
                        formControlName="EngPlus">
                    <div class="input-group-prepend">
                        <span class="input-group-text">倍計算</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <div class="form-check">
                                <input class="form-check-input pinter shadow-none" type="checkbox" value=""
                                    id="flexCheckDefault2" formControlName="UseDigital">
                                <label class="form-check-label pinter" for="flexCheckDefault2">使用遠距教學課程</label>
                            </div>
                        </span>
                    </div>
                    <input type="number" class="form-control shadow-none" min="0" step="0.1" max="99"
                        formControlName="DigitalPlus">
                    <div class="input-group-prepend">
                        <span class="input-group-text">倍計算</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">修改者</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly formControlName="UpdateUser">
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">修改時間</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly formControlName="UpdateTime">
                </div>
            </div>
            <div class="col-md-12">
                <button class="modify-btn mx-auto">修改</button>
            </div>
        </div>
    </form>
</div>
