<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">應復學年</span>
                <select class="form-select shadow-none" [(ngModel)]="stdRecoverListInput.EndYear">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">應復學期</span>
                <select class="form-select shadow-none" [(ngModel)]="stdRecoverListInput.EndTerm">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">實復學年</span>
                <select class="form-select shadow-none" [(ngModel)]="stdRecoverListInput.RecoverYear">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">實復學期</span>
                <select class="form-select shadow-none" [(ngModel)]="stdRecoverListInput.RecoverTerm">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #eduNo (change)="getDeptList(eduNo.value)"
                    [(ngModel)]="stdRecoverListInput.EduNo">
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" #deptNo (change)="getClassList(eduNo.value, deptNo.value)"
                    [(ngModel)]="stdRecoverListInput.DeptNo">
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班級</span>
                <select class="form-select shadow-none" [(ngModel)]="stdRecoverListInput.Class">
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學籍狀態</span>
                <select class="form-select shadow-none" [(ngModel)]="stdRecoverListInput.Status">
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of sTDStatusList" value="{{item.Status_No}}">{{item.Status_Name}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">是否核准</span>
                <select class="form-select shadow-none" [(ngModel)]="stdRecoverListInput.Recover">
                    <option value="">--請選擇--</option>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverListInput.StdNo">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverListInput.StdName">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">身分字號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverListInput.IDNo">
            </div>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md">
            <button class="search-btn float-start me-3" (click)="getStdRecoverList()">查詢</button>
        </div>
    </div>

    <table class="table table-striped table-hover text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>應復學年</th>
                <th>應復學期</th>
                <th>實復學年</th>
                <th>實復學期</th>
                <th>學號</th>
                <th>姓名</th>
                <th>學籍狀態</th>
                <th>休學原因</th>
                <th>休學時班級</th>
                <th>復學核准</th>
                <th>詳細資料</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdRecoverList">
                <td>{{item.EndYear}}</td>
                <td>{{item.EndTerm}}</td>
                <td>{{item.RecoverYear}}</td>
                <td>{{item.RecoverTerm}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.Status}}</td>
                <td>{{item.RestReason}}</td>
                <td>{{item.RestClassName}}</td>
                <td>{{item.Recover}}</td>
                <td><a class="text-decoration-none pointer"
                        (click)="getStdRecoverDetail(item.DataKey, template)">詳細資料</a></td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">復學詳細資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">休學學年</span>
                    <select class="form-select shadow-none" #Year [(ngModel)]="stdRecoverDetail.RestYear" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">休學學期</span>
                    <select class="form-select shadow-none" #Term [(ngModel)]="stdRecoverDetail.RestTerm" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學號</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverDetail.StdNo" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">姓名</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverDetail.StdName" disabled>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">休學延長</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdRecoverDetail.Delay" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">休學原因</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdRecoverDetail.RestNo" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of restReason" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">申請日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly [(ngModel)]="stdRecoverDetail.ApplyDate"
                        style="background-color: transparent;">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">應復學年</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverDetail.EndYear" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">應復學期</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdRecoverDetail.EndTerm" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">休學期數</span>
                    <input type="text" #count (blur)="caculate(Year.value, Term.value, count.value)"
                        class="form-control shadow-none" [(ngModel)]="stdRecoverDetail.TermCount">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">重估畢業審查學年度</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverDetail.ContrastYear">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">實復學年</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverDetail.RecoverYear"
                        maxlength="3" placeholder="民國年">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">實復學期</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdRecoverDetail.RecoverTerm">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>

            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">實復系所</span>
                        <select class="form-select shadow-none" #RecDeptNo (change)="getRecClassList(RecDeptNo.value)"
                        [(ngModel)]="stdRecoverDetail.RecoverDeptNo">
                        <option *ngFor="let item of RecdeptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                        </select>                        
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">實復班級</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdRecoverDetail.RecoverClass">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of  RecclassList" ngValue="{{item.Class}}"> {{item.Class_Name}} </option>
                    </select>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label>備註</label>
                <textarea class="form-control shadow-none" appTextareaAutoresize
                    [(ngModel)]="stdRecoverDetail.Memo"></textarea>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">核准日期</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverDetail.Approve_Date2">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">核准復學</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdRecoverDetail.Recover">
                        <option value="" disabled>--請選擇--</option>
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">審核人員</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRecoverDetail.RecoverName"
                        disabled>
                </div>
            </div>
 
            <div class="col-md-3">
                <button class="save-btn-gray" (click)="getStdRecoverModify()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>
