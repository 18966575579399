<nav aria-label="breadcrumb">
    <ol class="breadcrumb bg-transparent pt-1">
        <li><span class="me-1 icon-Current-position"></span>{{'目前位置：' | translate}}</li>
        <li class="breadcrumb-item active"><a class="text-primary text-decoration-none" routerLink="/Home">{{'首頁' |
                translate}}</a></li>
        <ng-container *ngIf="currentLocation">
            <li *ngFor="let item of currentLocation">/
                <ng-container *ngIf="item.URL == '' else other">
                    <span>{{item.FunName}}</span>
                </ng-container>
                <ng-template #other>
                    <a class="text-primary text-decoration-none" routerLink="{{item.URL}}">{{item.FunName}}</a>
                </ng-template>
            </li>
        </ng-container>
    </ol>
</nav>