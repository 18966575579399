import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GetAlertList } from 'src/app/_Models/STDWEB/STDW30006/getAlertList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW30006',
    templateUrl: './STDW30006.component.html',
    styleUrls: ['./STDW30006.component.css']
})
export class STDW30006Component implements OnInit {
    baseUrl = environment.apiUrl;
    model = {
        Years: '',
        Term: '',
    }
    data!: GetAlertList[];
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private timeService: TimeService, private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
        })
    }



    setModel(model: any) {
        this.model.Years = model.Years;
        this.model.Term = model.Term;
    }

    getAlertList() {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW300_06/List_Alert", this.model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = [];
                return;
            }
            this.data = response;
        })
    }

}
