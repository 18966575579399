<table class="table table-hover table-rwd text-center" id="cur-table">
    <thead class="table-rwd-hide thead-dark-red">
        <tr>
            <th>{{'節次' | translate}}</th>
            <th>{{'星期一' | translate}}</th>
            <th>{{'星期二' | translate}}</th>
            <th>{{'星期三' | translate}}</th>
            <th>{{'星期四' | translate}}</th>
            <th>{{'星期五' | translate}}</th>
            <th>{{'星期六' | translate}}</th>
            <th>{{'星期日' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="functionType == 'cosMaterial'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getCosMaterialList(cos.DataKey, getCosMaterial)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getCosMaterialList(cos.DataKey, getCosMaterial)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getCosMaterialList(cos.DataKey, getCosMaterial)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getCosMaterialList(cos.DataKey, getCosMaterial)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getCosMaterialList(cos.DataKey, getCosMaterial)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getCosMaterialList(cos.DataKey, getCosMaterial)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getCosMaterialList(cos.DataKey, getCosMaterial)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'homework'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getHomeworkList(cos.DataKey, getHomework)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getHomeworkList(cos.DataKey, getHomework)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getHomeworkList(cos.DataKey, getHomework)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getHomeworkList(cos.DataKey, getHomework)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getHomeworkList(cos.DataKey, getHomework)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getHomeworkList(cos.DataKey, getHomework)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getHomeworkList(cos.DataKey, getHomework)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'test'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getTestList(cos.DataKey, getTest)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getTestList(cos.DataKey, getTest)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getTestList(cos.DataKey, getTest)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getTestList(cos.DataKey, getTest)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getTestList(cos.DataKey, getTest)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getTestList(cos.DataKey, getTest)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getTestList(cos.DataKey, getTest)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'stdCurForm'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'tchRollCall'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getRollCall(cos.DataKey, getRollCallList, week)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getRollCall(cos.DataKey, getRollCallList, week)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getRollCall(cos.DataKey, getRollCallList, week)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getRollCall(cos.DataKey, getRollCallList, week)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getRollCall(cos.DataKey, getRollCallList, week)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getRollCall(cos.DataKey, getRollCallList, week)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                (click)="getRollCall(cos.DataKey, getRollCallList, week)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'stdOfficeHour'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a [popover]="pop" [outsideClick]="true" popoverTitle="申請"
                                (click)="newOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a [popover]="pop" [outsideClick]="true" popoverTitle="申請"
                                (click)="newOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a [popover]="pop" [outsideClick]="true" popoverTitle="申請"
                                (click)="newOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a [popover]="pop" [outsideClick]="true" popoverTitle="申請"
                                (click)="newOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a [popover]="pop" [outsideClick]="true" popoverTitle="申請"
                                (click)="newOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a [popover]="pop" [outsideClick]="true" popoverTitle="申請"
                                (click)="newOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a [popover]="pop" [outsideClick]="true" popoverTitle="申請"
                                (click)="newOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'tchOfficeHour'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a
                                (click)="deleteOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a
                                (click)="deleteOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a
                                (click)="deleteOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a
                                (click)="deleteOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a
                                (click)="deleteOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a
                                (click)="deleteOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            <a
                                (click)="deleteOfficeHour(cos.DataKey)">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'onlineClass'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL != ''">
                            <span class="pointer text-danger fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'delete', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL == ''">
                            <span class="pointer text-primary fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'add', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL != ''">
                            <span class="pointer text-danger fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'delete', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL == ''">
                            <span class="pointer text-primary fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'add', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL != ''">
                            <span class="pointer text-danger fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'delete', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL == ''">
                            <span class="pointer text-primary fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'add', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL != ''">
                            <span class="pointer text-danger fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'delete', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL == ''">
                            <span class="pointer text-primary fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'add', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL != ''">
                            <span class="pointer text-danger fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'delete', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL == ''">
                            <span class="pointer text-primary fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'add', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL != ''">
                            <span class="pointer text-danger fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'delete', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL == ''">
                            <span class="pointer text-primary fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'add', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL != ''">
                            <span class="pointer text-danger fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'delete', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.TeamMeetingJoinURL == ''">
                            <span class="pointer text-primary fw-bolder"
                                (click)="getAzureToken(cos.DataKey, 'add', cos.MeetingID)">{{cos.CosName}}</span><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'tchCurForm'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a><br>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a><br>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a><br>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a><br>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a><br>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a><br>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                            <ng-container *ngIf="cos.TeamMeetingJoinURL != ''">
                                <a class="text-primary pointer" (click)="openNewTab(cos.TeamMeetingJoinURL)">{{'遠距教學' |
                                    translate}}</a><br>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'class'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'teacher'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName && cos.CosName != '輔導時間 Office Hour'">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                        <ng-container *ngIf="cos.CosName && cos.CosName == '輔導時間 Office Hour'">
                            {{cos.CosName}}<br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="functionType == 'classroom'">
            <tr *ngFor="let item of curList">
                <td data-th="節次">{{item.Section_Name}}<br>{{item.Section_Time}}</td>
                <td data-th="星期一">
                    <ng-container *ngFor="let cos of item.Week01">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期二">
                    <ng-container *ngFor="let cos of item.Week02">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期三">
                    <ng-container *ngFor="let cos of item.Week03">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期四">
                    <ng-container *ngFor="let cos of item.Week04">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期五">
                    <ng-container *ngFor="let cos of item.Week05">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期六">
                    <ng-container *ngFor="let cos of item.Week06">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
                <td data-th="星期日">
                    <ng-container *ngFor="let cos of item.Week07">
                        <ng-container *ngIf="cos.CosName">
                            <a
                                [routerLink]="'/TCH01_01/' + cos.DataKey">{{cos.CosName}}</a><br />{{cos.TchName}}<br />{{cos.ClassName}}<br />
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-container>

    </tbody>
</table>

<ng-template #getCosMaterial>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'教材下載' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="materialModalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th>{{'序號' | translate}}</th>
                    <th>{{'檔名' | translate}}</th>
                    <th>{{'操作' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of cosMaterialData">
                    <td>{{item.Material_No}}</td>
                    <td>{{item.File_Name}}</td>
                    <td>
                        <ng-container *ngIf="item.Path == ''">
                            <span class="icon icon-Downlad-button"></span>
                            <a class="pointer" (click)="exportCosFile(item.DataKey)">{{'下載' | translate}}</a>
                        </ng-container>
                        <ng-container *ngIf="item.Path != ''">
                            <span class="icon icon-View-button"></span>
                            <a class="pointer" (click)="getVideo(vedio, item.Path, item.File_Name)">{{'觀看' |
                                translate}}</a>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #vedio>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{videoName}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="videoModalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <video src="" id="active_video" style="width: 100%;" controls preload="auto"></video>
    </div>
</ng-template>

<ng-template #getHomework>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'作業下載與繳交' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="homeworkModelRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th>{{'序號' | translate}}</th>
                    <th>{{'作業名稱' | translate}}</th>
                    <th>{{'類別' | translate}}</th>
                    <th>{{'檔名' | translate}}</th>
                    <th>{{'操作' | translate}}</th>
                    <th>{{'繳交日期' | translate}}</th>
                    <th>{{'分數' | translate}}</th>
                    <th>{{'教師評語' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of homeworkData">
                    <td>{{item.Homework_No}}</td>
                    <td>{{item.Homework_Name}}</td>
                    <td>{{item.Homework_Type}}</td>
                    <td>{{item.File_Name}}</td>
                    <td style="text-align: left;">
                        <button class="btn btn-outline-info" type="button"
                            (click)="exportHomeworkFile(item.DataKey)">{{'下載' | translate}}</button>
                        | <font style="color: red; font-weight: bolder;">{{item.Status}}</font>
                        <ng-container *ngIf="item.Leader_Mark == 'Y'">
                            <button class="btn btn-outline-info ms-1" type="button"
                                (click)="importInputCkick(item.DataKey)">{{'上傳' | translate}}</button>
                            <input type="file" style="display: none;"
                                (change)="importData($event, item.DataKey, 'homework')" id="{{item.DataKey}}">
                        </ng-container>
                    </td>
                    <td>{{item.SendTime}}</td>
                    <td>{{item.Score}}</td>
                    <td>{{item.Memo}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #getTest>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'測驗下載與繳交' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="testModelRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th>{{'序號' | translate}}</th>
                    <th>{{'作業名稱' | translate}}</th>
                    <th>{{'起始時間' | translate}}</th>
                    <th>{{'結束時間' | translate}}</th>
                    <th>{{'檔名' | translate}}</th>
                    <th>{{'操作' | translate}}</th>
                    <th>{{'繳交日期' | translate}}</th>
                    <th>{{'分數' | translate}}</th>
                    <th>{{'教師評語' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of testData">
                    <td>{{item.Test_No}}</td>
                    <td>{{item.Test_Name}}</td>
                    <td>{{item.Start_Date}}</td>
                    <td>{{item.End_Date}}</td>
                    <td>{{item.File_Name}}</td>
                    <td style="text-align: left;">
                        <button class="btn btn-outline-info" type="button" (click)="exportTestFile(item.DataKey)">{{'下載'
                            | translate}}</button>
                        | <span class="text-danger fw-bolder ">{{item.Status}}</span>
                        <ng-container *ngIf="item.Status=='Y'">
                            <button class="btn btn-outline-info ms-1" type="button"
                                (click)="importInputCkick(item.DataKey)">{{'上傳' | translate}}</button>
                            <input type="file" style="display: none;"
                                (change)="importData($event, item.DataKey, 'test')" #import id="testFileInput">
                        </ng-container>
                    </td>
                    <td>{{item.SendTime}}</td>
                    <td>{{item.Score}}</td>
                    <td>{{item.Memo}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #getRollCallList>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'課程線上點名' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="rollCallModelRef.hide()">

        </button>
    </div>
    <div class="modal-body">

        <div class="row" *ngIf="rollCallData">
            <div class="col-md-3">{{rollCallData.Years}}{{'學年第' | translate}}{{rollCallData.Term}}{{'學期' | translate}}
            </div>
            <div class="col-md-3">{{'上課班級：' | translate}}{{rollCallData.Class_Short}}</div>
            <div class="col-md-3">{{'科目名稱：' | translate}}{{rollCallData.Cos_Name}}</div>
            <div class="col-md-3">{{'上課日期：' | translate}}{{rollCallData.CosDate}}</div>
        </div>
        <div class="row mb-3" *ngIf="rollCallData">
            <div class="col-md-3">{{'點名截止：' | translate}}{{rollCallData.Deadline}}</div>
            <div class="col-md-3">{{'應到人數：' | translate}}{{rollCallData.StdCount}}</div>
            <div class="col-md-3"></div>
            <div class="col-md-3"></div>
        </div>

        <table class="table table-hover table-striped text-center">
            <thead>
                <tr>
                    <td class="align-middle">{{'應到人數：' | translate}}{{rollCallData.StdCount}}</td>
                    <td *ngFor="let item of rollCallData.SectionList[0].SectionItem; let i = index">
                        {{'出席人數：' | translate}}{{totalStudent[i]}}<br>
                        {{'曠課人數：' | translate}}{{absenteeismCount[i]}}<br>
                        {{'遲到人數：：' | translate}}{{lateCount[i]}}
                    </td>
                </tr>
                <tr>

                    <th>{{'學號姓名' | translate}}</th>
                    <th *ngFor="let item of rollCallData.SectionList[0].SectionItem">
                        {{'第' | translate}}{{item.Cur_Time}}{{'節' | translate}}
                        <br>
                        {{rollCallData.Cos_Name}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let list of rollCallData.SectionList; let i = index">

                    <td>
                        {{list.Std_No}}
                        <br>
                        {{list.Std_Name}}
                        <br>
                        <img [src]="setSafeUrl(list.PIC)" width="60" height="60">
                    </td>
                    <td *ngFor="let item of rollCallData.SectionList[0].SectionItem; let q = index">
                        <input type="radio" name="{{q}}_{{list.Std_No}}_{{item.Cur_Time}}" class="result"
                            id="A_{{list.Std_No}}_{{item.Cur_Time}}" value="A" (click)="studentCount(i, q, 'A')"
                            [checked]="item.H_Code == 'A'">
                        <label class="me-2 result" for="A_{{list.Std_No}}_{{item.Cur_Time}}">{{'曠課' |
                            translate}}</label>
                        <br>
                        <input type="radio" name="{{q}}_{{list.Std_No}}_{{item.Cur_Time}}" class="result"
                            id="B_{{list.Std_No}}_{{item.Cur_Time}}" value="B" (click)="studentCount(i, q, 'B')"
                            [checked]="item.H_Code == 'B'">
                        <label class="me-2 result" for="B_{{list.Std_No}}_{{item.Cur_Time}}">{{'遲到' |
                            translate}}</label>
                        <br>
                        <input type="radio" name="{{q}}_{{list.Std_No}}_{{item.Cur_Time}}" class="result"
                            id="{{list.Std_No}}_{{item.Cur_Time}}" value="" (click)="studentCount(i, q, '')"
                            [checked]="item.H_Code == ''">
                        <label class="me-2 result" for="{{list.Std_No}}_{{item.Cur_Time}}">{{'出席' | translate}}</label>
                    </td>
                </tr>
            </tbody>
        </table>
        <button type="button" class="send-btn" style="margin: auto;" (click)="saveRollCall()">{{'送出' |
            translate}}</button>
    </div>
</ng-template>

<ng-template #pop>
    <div class="input-group flex-nowrap mb-3">
        <div class="input-group-prepend">
            <button type="button" class="btn btn-success btn-small" (click)="saveNewOfficeHour(week)">{{'送出' |
                translate}}</button>
        </div>
        <input type="text" class="form-control shadow-none" [(ngModel)]="officeHourReason" placeholder="請輸入申請原因">
    </div>
</ng-template>