<div class="container" style="margin-bottom: 100px;">
  <button class="modify-btn mb-3 float-end" (click)="getTchModify()">{{'修改' | translate}}</button>
  <tabset *ngIf="tchData">
    <tab heading="{{'基本資料'|translate}}">
      <div class="row mt-3">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'人事代碼' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.Code">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'身分證字號' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.IDNo">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'中文姓名' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.Name">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'英文姓名' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.EngName">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'血型' | translate}}</span>
            </div>
            <input type="date" class="form-control shadow-none" [(ngModel)]="tchData.Birthday">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'性別' | translate}}</span>
            </div>
            <select class="form-select shadow-none" [(ngModel)]="tchData.Sex">
              <option value="男">{{'男' | translate}}</option>
              <option value="女">{{'女' | translate}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'血型' | translate}}</span>
            </div>
            <select class="form-select shadow-none" [(ngModel)]="tchData.Blood">
              <option value="{{item.itemValue}}" *ngFor="let item of bloodType">{{item.itemName}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'婚姻狀態' | translate}}</span>
            </div>
            <select class="form-select shadow-none" [(ngModel)]="tchData.Marry">
              <option value="">{{'--請選擇--' | translate}}</option>
              <option value="{{item.itemValue}}" *ngFor="let item of marriage">{{item.itemName}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'國籍' | translate}}</span>
            </div>
            <select class="form-select shadow-none" [(ngModel)]="tchData.Country">
              <option value="{{item.ItemValue}}" *ngFor="let item of countryList">{{item.ItemName}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'學位' | translate}}</span>
            </div>
            <select class="form-select shadow-none" [(ngModel)]="tchData.DegreeType">
              <option value="">{{'--請選擇--' | translate}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'在職狀態' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.StateName">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'出家法名' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.MonkName">
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'學歷' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.Graduate">
          </div>
        </div>
      </div>
      <hr class="">
      <label class="text-danger fw-bolder">{{'*注意事項：下方資料若有問題請洽管理者聯繫' | translate}}</label>
      <div class="row">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'行政單位' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.UnitName2">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'教學單位' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.UnitName1">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'行政職稱' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.TitleName">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'教師職稱' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.TchLevelName">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'進修狀態' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.FurtherName">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'專兼職別' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.TimeCaseName">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'專兼職別' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.EmployeeName">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'職級代碼' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="tchData.ECIName">
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'郵遞區號' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.ComZip">
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'通訊地址：' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'郵遞區號' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none">
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'戶籍地址' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'聯絡電話' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.ComTel">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'行動電話' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.MobileTel">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text text-primary fw-bolder">{{'辦公室電話' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.OfficeTel">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <th>{{'備註' | translate}}</th>
              </span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.Memo">
          </div>
        </div>
      </div>
    </tab>
    <tab heading="{{'聯絡資訊'|translate}}">
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'銀行名稱' | translate}}</span>
            </div>
            <select class="form-select shadow-none" [(ngModel)]="tchData.BankNo">
              <option value="">{{'--請選擇--' | translate}}</option>
              <option *ngFor="let item of bankNo" value="{{item.ItemValue}}">{{item.ItemName}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'銀行帳號' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.BankAccount">
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'緊急聯絡人' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.LiaiseName">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'聯絡人電話' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.LiaiseTel">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'聯絡人關係' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.LiaiseRelation">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'聯絡人地址' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.LiaiseAddress">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'校內Email' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.EMail">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'私人Email' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.OtherEmail">
          </div>
        </div>
      </div>
    </tab>
    <tab heading="{{'其他資訊' | translate}}">
      <div class="row mt-3">
        <div class="col-md-3">
          <figure class="figure">
            <img [src]="ImgUrl" class="figure-img img-fluid rounded" />
            <figcaption class="figure-caption">
              <button class="other-Button-gray img-btn" (click)="importInputCkick()">{{'上傳' | translate}}</button>
              <input type="file" style="display: none;" id="img" (change)="importData($event)" accept="image/*">
              <small class="text-danger fw-bolder ps-3">{{'*請勿上傳生活照' | translate}}</small>
            </figcaption>
          </figure>
        </div>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'信仰宗教' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.Religion">
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{'參加社團' | translate}}</span>
            </div>
            <input type="text" class="form-control shadow-none" [(ngModel)]="tchData.Association">
          </div>
        </div>
      </div>
    </tab>
  </tabset>

</div>