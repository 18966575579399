import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetABSLeaveContent } from 'src/app/_Models/AD/ADABS/ADABS0203/getABSLeaveContent';
import { GetABSLeaveList } from 'src/app/_Models/AD/ADABS/ADABS0203/getABSLeaveList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetHolidayList } from 'src/app/_Models/SAC/SAC1701/getHolidayList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADABS0203',
    templateUrl: './ADABS0203.component.html',
    styleUrls: ['./ADABS0203.component.css']
})
export class ADABS0203Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    deptList!: GetDeptList[];
    eduList!: GetEduList[];
    holidayList?: GetHolidayList[];
    leaveListStatus?: GetSelectList[];
    approveListStatus?: GetSelectList[];
    classList!: GetClassList[];
    baseUrl = environment.apiUrl;
    modalRef?: BsModalRef;
    bsConfig = environment.bsDatePickerConfig;
    curTime: Array<any> = [];
    aBSLeaveList?: GetABSLeaveList[];
    aBSLeaveContent?: GetABSLeaveContent;
    fileExchange = new FileExchange();
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        HCode: new UntypedFormControl(''),
        FieldType: new UntypedFormControl('1'),
        FieldTypeValueFrom: new UntypedFormControl(''),
        FieldTypeValueTo: new UntypedFormControl(''),
        RecDateFrom: new UntypedFormControl(null),
        RecDateTo: new UntypedFormControl(null),
        Status: new UntypedFormControl('')
    })
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private systemService: SystemService,
        private modalService: BsModalService,
        private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
        this.getHolidayList();
        this.getLeaveListStatus();
        this.getApproveLeaveList();
        this.localeService.use('zh-cn');
        for (let index = 1; index < 16; index++) {
            this.curTime.push({
                checked: false,
                number: index
            });
        }
    }

    getYears(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }


    getEduList() {
        this.systemService.getEduList('0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList() {
        this.systemService.getDeptList(this.ngFormInput.get('EduNo')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList() {
        this.systemService.getClassList(this.ngFormInput.get('EduNo')?.value, this.ngFormInput.get('DeptNo')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getHolidayList() {
        this.systemService.getHolidayList().subscribe(response => {
            this.holidayList = response;
        });
    }

    getLeaveListStatus() {
        this.systemService.getLeaveListStatus().subscribe(response => {
            this.leaveListStatus = response;
        });
    }

    getApproveLeaveList() {
        this.systemService.getApproveLeaveList().subscribe(response => {
            this.approveListStatus = response;
        });
    }

    openModel(template: TemplateRef<any>, datakey: string) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.getABSLeaveContent(datakey);
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getABSLeaveList() {
        this.aBSLeaveList=[];
        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0203/Get_ABS_Leave_List", this.ngFormInput.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.aBSLeaveList = undefined;
                return;
            }
            this.aBSLeaveList = response;
        })
    }

    getABSLeaveContent(datakey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0203/Get_ABS_Leave_Content", { DataKey: datakey }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.aBSLeaveContent = response;
            this.setCheck();
        })
    }

    setCheck() {
        for (let i = 0; i < this.aBSLeaveContent!.CurTimeList.length; i++) {
            for (let j = 0; j < this.curTime.length; j++) {
                if (parseInt(this.aBSLeaveContent!.CurTimeList[i].Cur_Time.substring(1)) == this.curTime[j].number) {
                    this.curTime[j].checked = true;
                }
            }
        }
    }

    getABSLeaveFile(datakey: string) {
        this.fileExchangeServices.ExportFile("ACAD/ABS/ADABS0203/Get_ABS_Leave_File", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    getABSLeaveSign() {
        // console.log(this.aBSLeaveContent);
        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0203/Get_ABS_Leave_Sign", this.aBSLeaveContent).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('送出成功');
            this.getABSLeaveList();
        })
    }

    setAll(event: EventTarget | null) {
        if (!this.aBSLeaveList) return;
        let input = <HTMLInputElement>event;
        this.aBSLeaveList.forEach(element => {
            input.checked == true ? element.Checked = true : element.Checked = false;
        })
    }

    getABSLeaveBatchSign() {
        let datakey = '';

        this.aBSLeaveList?.forEach(element => {
            if (element.Checked) datakey += element.DataKey + ',';
        });

        this.http.post<any>(this.baseUrl + "ACAD/ABS/ADABS0203/Get_ABS_Leave_BatchSign", { DataKey: datakey.substring(0, datakey.length - 1) }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('送出成功');
            this.getABSLeaveList();
        })
    }

}
