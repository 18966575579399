import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetTFSMakeDocSISingleDetail } from 'src/app/_Models/AD/ADTFS/ADTFS01D03/getTFSMakeDocSISingleDetail';
import { GetTFSMakeDocSISingleList } from 'src/app/_Models/AD/ADTFS/ADTFS01D03/getTFSMakeDocSISingleList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
@Component({
  selector: 'app-ADTFS01D03',
  templateUrl: './ADTFS01D03.component.html',
  styleUrls: ['./ADTFS01D03.component.css']
})
export class ADTFS01D03Component implements OnInit {
  checkType = new CheckType();
  Years!: Array<string>;
  initialData = new InitialData(this.http);
  term = require('src/app/_Models/_SelectInput/term.json').term;;
  deptList!: GetDeptList[];
  makeDocList!: GetSelectList[];
  tFSMakeDocSISingleDetail!: GetTFSMakeDocSISingleDetail[] | undefined;
  baseUrl = environment.apiUrl;
  dataKeyArray: Array<string> = [];
  model = {
    Years: '',
    Term: '',
    DeptNo: '',
    StdNo: '',
    MakeDocNo: '',
    MakeDocStatus: ''
  }
  tFSMakeDocSISingleList!: GetTFSMakeDocSISingleList[];
  constructor(private toastr: ToastrService,
    private systemService: SystemService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.getYears();
    this.getMakeDocList();
  }


  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getDeptList(years: string, term: string) {
    this.systemService.getDeptList('', '1', years, term, 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.deptList = response;
    }, error => this.toastr.error(error.error))
  }

  getMakeDocList() {
    this.systemService.getMakeDocList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.makeDocList = response;
    }, error => this.toastr.error(error.error))
  }

  setDatakey(type: string, dataKey: string, event: any) {
    let input = <HTMLInputElement>event;
    if (type == 'all') {
      if (this.dataKeyArray.length != this.tFSMakeDocSISingleList.length && input.checked == true) {
        this.dataKeyArray = [];
        this.tFSMakeDocSISingleList.forEach(element => {
          this.dataKeyArray.push(element.DataKey);
          element.checked = true;
        })
      }
      else {
        this.dataKeyArray = [];
        this.tFSMakeDocSISingleList.forEach(element => {
          element.checked = false;
        })
      }
    }
    else {
      let index = this.dataKeyArray.indexOf(dataKey);
      if (index == -1) {
        this.dataKeyArray.push(dataKey);
      }
      else {
        this.dataKeyArray.splice(index, 1);
      }
    }
  }

  getTFSMakeDocSISingleList() {
    if (this.model.Years == '' || this.model.Term == '' || this.model.MakeDocNo == '') {
      this.toastr.info("請選擇學年學期及製單類別");
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D03/Get_TFS_MakeDoc_SI_SingleList", {
      DataKey: '',
      Years: this.model.Years,
      Term: this.model.Term,
      EduNo: '',
      DeptNo: this.model.DeptNo,
      Class: '',
      StdNo: this.model.StdNo,
      EntryID: '',
      MakeDocNo: this.model.MakeDocNo,
      MakeDocStatus: this.model.MakeDocStatus,
      UnitNo: '01'
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tFSMakeDocSISingleList = response;
      for (let index = 0; index < this.tFSMakeDocSISingleList.length; index++) {
        this.tFSMakeDocSISingleList[index].checked = false;
      }
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocSISingleDetail(index: number, dataKey: string) {

    this.tFSMakeDocSISingleList.forEach(element => {
      element.Selected = false;
    })
    this.tFSMakeDocSISingleList[index].Selected = true;

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D03/Get_TFS_MakeDoc_SI_SingleDetail", { DataKey: dataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocSISingleDetail = undefined;
        return;
      }
      this.tFSMakeDocSISingleDetail = response;
    })
  }

  getTFSMakeDocSISingleSave() {
    if (!this.tFSMakeDocSISingleDetail) return;
    let pass = true;
    for (let index = 0; index < this.tFSMakeDocSISingleDetail.length; index++) {
      if (this.checkType.CheckIsNumber(this.tFSMakeDocSISingleDetail[index].MustMoney)) {
        this.toastr.warning(`第${index + 1}項非數字`);
        pass = false;
        break;
      }
    }

    if (pass) {
      this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D03/Get_TFS_MakeDoc_SI_SingleSave", this.tFSMakeDocSISingleDetail).subscribe((response) => {
        if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
        }
        this.toastr.success("儲存成功");
      })
    }

  }

  getTFSMakeDocSISingleSend() {
    if (this.dataKeyArray.length == 0) {
      this.toastr.info("請先選擇學生");
      return;
    }
    let dataKey = '';
    this.dataKeyArray.forEach(element => {
      dataKey = dataKey + element + ',';
    });
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D03/Get_TFS_MakeDoc_SI_SingleSend", { DataKey: dataKey.substring(0, dataKey.length - 1) }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("送繳成功");
    })
  }

  getTFSMakeDocSISingleMakeDoc() {
    if (this.dataKeyArray.length == 0) {
      this.toastr.info("請先選擇學生");
      return;
    }
    let dataKey = '';
    this.dataKeyArray.forEach(element => {
      dataKey = dataKey + element + ',';
    });
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D03/Get_TFS_MakeDoc_SI_SingleMakeDoc", { DataKey: dataKey.substring(0, dataKey.length - 1) }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("製作成功");
      this.dataKeyArray = [];
    })
  }





}
