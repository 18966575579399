<nav class="navbar navbar-expand-lg fixed-top navbar-light d-flex" id="main-nav">
    <button class="navbar-toggler p-0 border-0 me-3 ms-3 shadow-none" type="button" data-toggle="offcanvas" id="menu-btn" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" id="sch-main-title" routerLink="/Home">{{schoolName}}</a>
    <div class="navbar-collapse offcanvas-collapse" [class.open]="isCollapsed" id="collapseMenu">
        <ul class="navbar-nav me-auto">
            <li class="nav-item dropdown" *ngFor="let mainMenu of currentMenu; let i = index" dropdown #dropdown="bs-dropdown">
                <ng-container *ngIf="mainMenu.FullURL == 'Y'">
                    <a class="nav-link" href="{{mainMenu.HyperLink}}">{{mainMenu.FunName}}</a>
                </ng-container>
                <ng-container *ngIf="mainMenu.FullURL != 'Y'">
                    <a class="nav-link" [class.dropdown-toggle]="mainMenu.SubMenu" dropdownToggle aria-controls="dropdown-basic" name="dropdown-menu">{{mainMenu.FunName}}</a>
                </ng-container>
                <div class="dropdown-menu" *dropdownMenu role="menu" id="dropdown-basic">
                    <ng-container *ngFor="let SubMenu of mainMenu.SubMenu">
                        <div class="item">
                            <a role="menuitem" class="dropdown-item" href="{{SubMenu.FullURL == '' ? SubMenu.HyperLink : SubMenu.HyperLink}}"> {{SubMenu.FunName}}</a>
                        </div>
                    </ng-container>
                </div>
            </li>
        </ul>
    </div>
    <div class="flex-fill">
        <a class="pointer text-decoration-none float-end me-3" (click)="logout()"><span class="icon-Logout icon-link"></span></a>
        <a class="pointer text-decoration-none float-end me-3 func" routerLink="Favourite" *ngIf="useFavorite">
            <span class="icon-My-favorite icon-link"></span>
        </a>
        <a class="pointer text-decoration-none float-end me-3 func" routerLink="profile"><span class="icon-Profile icon-link"></span></a>
        <a class="pointer text-decoration-none float-end me-3 icon" *ngIf="helpAll" (click)="isRightCollapsed = !isRightCollapsed" [attr.aria-expanded]="!isRightCollapsed" aria-controls="offcanvasRight"><span class="icon-Help icon-link"></span></a>
    </div>
</nav>
<div class="offcanvas offcanvas-end" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" [collapse]="isRightCollapsed" [isAnimated]="true">
    <div class="offcanvas-header">
        <h5 id="offcanvasRightLabel">幫助中心</h5>
        <button type="button" class="btn-close text-reset shadow-none" (click)="isRightCollapsed = !isRightCollapsed" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body" style="overflow-y: scroll !important; height: 90%">
        <input [(ngModel)]="ItemName" [typeahead]="helpItem" [isAnimated]="true" [typeaheadOptionsInScrollableView]="6" class="form-control shadow-none mb-3" placeholder="在尋找什麼嗎？" (ngModelChange)="getHelp()">
        <accordion [closeOthers]="true" [isAnimated]="true">
            <accordion-group *ngFor="let group of helpCurrent; let i = index">
                <div accordion-heading class="text-black">{{group.ItemName}}</div>
                <accordion [closeOthers]="true" [isAnimated]="true">
                    <accordion-group *ngFor="let item of group.SubItem; let i = index">
                        <div accordion-heading class="text-black">{{item.SubItemName}}</div>
                        <ng-container *ngFor="let help of item.HelpItem">
                            <label>{{help.Title}}</label>
                            <div class="list-group" *ngFor="let main of help.Main; let i = index">
                                <ng-container *ngIf="main.URL == '' else otherLink">
                                    <a class="list-group-item list-group-item-action">{{main.Main}}</a>
                                </ng-container>
                                <ng-template #otherLink>
                                    <a routerLink="{{main.URL}}" class="list-group-item list-group-item-action text-primary">{{main.Main}}</a>
                                </ng-template>
                            </div>
                            <hr>
                        </ng-container>
                    </accordion-group>
                </accordion>
            </accordion-group>
        </accordion>
    </div>
</div>
