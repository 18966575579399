import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetStdInfo } from 'src/app/_Models/STDWEB/STDW30003/getStdInfo';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-STDW30003',
  templateUrl: './STDW30003.component.html',
  styleUrls: ['./STDW30003.component.css']
})
export class STDW30003Component implements OnInit {

  baseUrl = environment.apiUrl;
  stdInfo!: GetStdInfo[];
  semesters: string[] = ['一上', '一下', '二上', '二下', '三上', '三下', '四上', '四下', '五上', '五下'];
  itemName: string[] = [];

  constructor(private http: HttpClient, private systemService: SystemService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.use(this.systemService.currentLanguage)
    this.GetCourse();
  }

  GetCourse() {
    this.http.get<GetStdInfo[]>(this.baseUrl + "ACAD/STDWEB/STDW300_03/Get_CurMap_List").subscribe((response) => {
      this.itemName = [];
      if (response) {
        this.stdInfo = response;
        response.forEach(element => {
          element.CurMap.forEach(element => {
            this.itemName.push(element.Item_Name);
          });
        });
      }
    });
  }

  parseInt(string: string) {
    return parseInt(string);
  }

}
