import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetTFSMakeDocSingleDetail } from 'src/app/_Models/AD/ADTFS/ADTFS01B04/getTFSMakeDocSingleDetail';
import { GetTFSMakeDocSingleList } from 'src/app/_Models/AD/ADTFS/ADTFS01B04/getTFSMakeDocSingleList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
@Component({
  selector: 'app-ADTFS01B04',
  templateUrl: './ADTFS01B04.component.html',
  styleUrls: ['./ADTFS01B04.component.css']
})
export class ADTFS01B04Component implements OnInit {
  Years!: Array<string>;
  initialData = new InitialData(this.http);
  term = require('src/app/_Models/_SelectInput/term.json').term;
  deptList!: GetDeptList[];
  makeDocList!: GetSelectList[];
  baseUrl = environment.apiUrl;
  makeDocDeptList!: GetSelectList[];
  tFSMakeDocSingleList!: GetTFSMakeDocSingleList[];
  dataKeyArray: Array<string> = [];
  tFSMakeDocSingleDetail!: GetTFSMakeDocSingleDetail[] | undefined;
  checkType = new CheckType();
  constructor(private toastr: ToastrService,
    private systemService: SystemService,
    private http: HttpClient,) { }

  ngOnInit(): void {
    this.getYears();
    this.getMakeDocList();
  }


  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getMakeDocList() {
    this.systemService.getMakeDocList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.makeDocList = response;
    }, error => this.toastr.error(error.error))
  }

  setDatakey(type: string, dataKey: string, event: any) {
    let input = <HTMLInputElement>event;
    if (type == 'all') {
      if (this.dataKeyArray.length != this.tFSMakeDocSingleList.length && input.checked == true) {
        this.dataKeyArray = [];
        this.tFSMakeDocSingleList.forEach(element => {
          this.dataKeyArray.push(element.DataKey);
          element.checked = true;
        })
      }
      else {
        this.dataKeyArray = [];
        this.tFSMakeDocSingleList.forEach(element => {
          element.checked = false;
        })
      }
    }
    else {
      let index = this.dataKeyArray.indexOf(dataKey);
      if (index == -1) {
        this.dataKeyArray.push(dataKey);
      }
      else {
        this.dataKeyArray.splice(index, 1);
      }
    }
  }

  getTFSMakeDocSingleList(Years: string, Term: string, DeptNo: string, MakeDocNo: string, StdNo: string, MakeDocStatus: string) {

    if (Years == '' || Term == '' || MakeDocNo == '') {
      this.toastr.info("請選擇學年期與製單類別");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B04/Get_TFS_MakeDoc_SingleList", {
      DataKey: '',
      Years: Years,
      Term: Term,
      EduNo: '',
      DeptNo: DeptNo,
      Class: '',
      StdNo: StdNo,
      EntryID: '',
      MakeDocNo: MakeDocNo,
      MakeDocStatus: MakeDocStatus,
      UnitNo: '01'
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocSingleList = []
        return;
      }
      this.tFSMakeDocSingleList = response;
      for (let index = 0; index < this.tFSMakeDocSingleList.length; index++) {
        this.tFSMakeDocSingleList[index].checked = false;
      }
    }, error => this.toastr.error(error.error))
  }

  getMakeDocDeptList(MakeDocNo: string) {
    this.systemService.getMakeDocDeptList(MakeDocNo).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.makeDocDeptList = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocSingleMake() {
    if (this.dataKeyArray.length == 0) return;

    let dataKey = '';
    this.dataKeyArray.forEach(element => {
      dataKey = dataKey + element + ',';
    });

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B04/Get_TFS_MakeDoc_SingleMake", { DataKey: dataKey.substring(0, dataKey.length - 1) }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("製作完成");
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocSingleDetail(index: number, dataKey: string) {

    this.tFSMakeDocSingleList.forEach(element => {
      element.Selected = false;
    })
    this.tFSMakeDocSingleList[index].Selected = true;

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B04/Get_TFS_MakeDoc_SingleDetail", { DataKey: dataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocSingleDetail = undefined;
        return;
      }
      this.tFSMakeDocSingleDetail = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocSingleSave() {
    if (!this.tFSMakeDocSingleDetail) return;
    let pass = true;
    for (let index = 0; index < this.tFSMakeDocSingleDetail.length; index++) {
      if (this.checkType.CheckIsNumber(this.tFSMakeDocSingleDetail[index].MustMoney)) {
        this.toastr.warning(`第${index + 1}項非數字`);
        pass = false;
        break;
      }
    }

    if (pass) {
      this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B04/Get_TFS_MakeDoc_SingleSave", this.tFSMakeDocSingleDetail).subscribe((response) => {
        if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
        }
        this.toastr.success("儲存成功");
        this.getTFSMakeDocSingleList('', '', '', '', '', '')
      }, error => this.toastr.error(error.error))
    }
  }

  getTFSMakeDocSingleSend() {

    if (this.dataKeyArray.length == 0) return;

    let dataKey = '';
    this.dataKeyArray.forEach(element => {
      dataKey = dataKey + element + ',';
    });

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B04/Get_TFS_MakeDoc_SingleSend", { DataKey: dataKey.substring(0, dataKey.length - 1) }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("送繳成功");
      this.dataKeyArray = [];
    }, error => this.toastr.error(error.error))
  }
}
