<div class="container">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">人事代碼</span>
                    <input type="text" class="form-control shadow-none" formControlName="Code">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">姓名</span>
                    <input type="text" class="form-control shadow-none" formControlName="Name">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">單位</span>
                    <select class="form-select shadow-none" formControlName="UnitCode">
                        <option value="">--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of unitList">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="col-md-12">
                        <button class="other-Button-gray float-end me-3" (click)="getEMPMainExport()">匯出</button>
                        <button class="search-btn float-end me-3" (click)="getEMPMainList()">查詢</button>
                    </div>
                </div>
            </div>       
        </div>
    </form>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 50px;">
                    <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1" (click)="setAll($event.target)">
                    <label class="form-check-label pointer" for="inlineCheckbox1">全選</label>                      
                </th>
                <th style="width: 150px;vertical-align: middle;">單位</th>
                <th style="width: 100px;vertical-align: middle;">職稱</th>
                <th style="width: 80px;vertical-align: middle;">人事代碼</th>
                <th style="width: 100px;vertical-align: middle;">姓名</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of EMPMainList; let i = index" class="pointer">
                <td style="width: 50px;">
                    <input type="checkbox" [(ngModel)]="item.Checked" (change)="setCheck(item.Checked,i)" class="pointer">                
                </td>
                <td style="width: 150px;">{{item.UnitName}}</td>
                <td style="width: 100px;">{{item.JobName}}</td>
                <td style="width: 80px;">{{item.Code}}</td>
                <td style="width: 100px;">{{item.UserName}}</td>
            </tr>
        </tbody>
    </table>
</div>