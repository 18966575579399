<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text"><span class="fw-bolder text-danger">*</span>學年</span>
                </div>
                <select class="form-select shadow-none" #Year (change)="getEduList()" [(ngModel)]="model.Years">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text"><span class="fw-bolder text-danger">*</span>學期</span>
                </div>
                <select class="form-select shadow-none" #Term (change)="getEduList()" [(ngModel)]="model.Term">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">繳費狀態</span>
                </div>
                <select #Status class="form-select shadow-none" [(ngModel)]="model.Status">
                    <option value="" selected disabled>--請選擇--</option>
                    <option value="Y">已繳</option>
                    <option value="Y">未繳</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text"><span class="fw-bolder text-danger">*</span>學制</span>
                </div>
                <select class="form-select shadow-none" #stdEdu (change)="getDeptList(); getMakeDocUnitEduList();"
                    [(ngModel)]="model.EduNo">
                    <option value="" selected disabled>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">系所</span>
                </div>
                <select class="form-select shadow-none" #stdDept (change)="getClassList()" [(ngModel)]="model.DeptNo">
                    <option value="" selected disabled>--請選擇系所--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">班別</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="model.Class">
                    <option value="" selected>--請選擇班別--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text"><span class="fw-bolder text-danger">*</span>製單類別</span>
                </div>
                <select #MakeDocNo class="form-select shadow-none" [(ngModel)]="model.MakeDocNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of makeDocList">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學號</span>
                </div>
                <input type="text" class="form-control shadow-none" [(ngModel)]="model.StdNo">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">姓名</span>
                </div>
                <input type="text" class="form-control shadow-none" [(ngModel)]="model.StdName">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">銷帳編號</span>
                </div>
                <input type="text" class="form-control shadow-none" [(ngModel)]="model.DocNo">
            </div>
        </div>
        <div class="col-md-3">
            <button class="search-btn float-start me-3" (click)="getTFSMakeDocWriteOffList()">查詢</button>
            <button class="other-Button-gray float-start" (click)="import()">批次銷帳</button>
            <input type="hidden" id="importData" accept=".xls, xlsx" (change)="importFileBase64($event, '', '')">
        </div>
    </div>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>學年</th>
                <th>學期</th>
                <th>學號</th>
                <th>姓名</th>
                <th>製單類別</th>
                <th>銷帳編號</th>
                <th>金額</th>
                <th>繳費管道</th>
                <th>繳費狀態</th>
                <th>操作</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tFSMakeDocWriteOffList">
                <td>{{item.Years}}</td>
                <td>{{item.Term}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.MakeDocName}}</td>
                <td>{{item.DocNo}}</td>
                <td>{{item.Money}}</td>
                <td>{{item.InType}}</td>
                <td>{{item.PayStatus}}</td>
                <td>
                    <a class="text-decoration-none pointer" [hidden]="item.PayStatus == '未銷帳'"
                        (click)="getTFSMakeDocWriteOffDetail(item.DataKey, template)"><span
                            class="icon-Modify-button"></span>修改</a>
                    <a class="text-decoration-none pointer" [hidden]="item.PayStatus == '已銷帳'"
                        (click)="getTFSMakeDocWriteOffDetail(item.DataKey, template)"><span
                            class="icon-View-button"></span>詳細資料</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">人工銷帳</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocWriteOffDetail.Years">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocWriteOffDetail.Term">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocWriteOffDetail.StdNo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">姓名</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocWriteOffDetail.StdName">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">代收費用別</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocWriteOffDetail.MakeDocName">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">銷帳編號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocWriteOffDetail.DocNo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">金額</span>
                    </div>
                    <input type="text" class="form-control shadow-none" readonly
                        [(ngModel)]="tFSMakeDocWriteOffDetail.Money">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">繳費管道</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="tFSMakeDocWriteOffDetail.InType">
                        <option value="" disabled>--請選擇--</option>
                        <option value="{{item.Pipeline_No}}" *ngFor="let item of inTypeList">
                            {{item.Pipeline_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">繳費日期</span>
                    </div>
                    <input type="text" placeholder="點選設定日期" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" [(ngModel)]="tFSMakeDocWriteOffDetail.PayDate"
                        style="background-color: transparent;">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">入帳日期</span>
                    </div>
                    <input type="text" placeholder="點選設定日期" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" [(ngModel)]="tFSMakeDocWriteOffDetail.InDate"
                        style="background-color: transparent;">
                </div>
            </div>
            <div class="col-md-4">
                <button class="save-btn-gray float-start me-3" [hidden]="tFSMakeDocWriteOffDetail.PayStatus == 'N'"
                    (click)="getTFSMakeDocWriteOffSave()">銷帳</button>
                <button class="other-Button-gray float-start" [hidden]="tFSMakeDocWriteOffDetail.PayStatus == 'Y'"
                    (click)="getTFSMakeDocWriteOffDelete(tFSMakeDocWriteOffDetail.DataKey)">撤銷</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <div class="form-floating">
                    <textarea class="form-control shadow-none" placeholder="填寫於此" id="floatingTextarea"
                        [(ngModel)]="tFSMakeDocWriteOffDetail.Memo" appTextareaAutoresize></textarea>
                    <label for="floatingTextarea">備註</label>
                </div>
            </div>
        </div>
    </div>
</ng-template>