import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADSTD0304',
  templateUrl: './ADSTD0304.component.html',
  styleUrls: ['./ADSTD0304.component.css']
})
export class ADSTD0304Component implements OnInit {
  term = require('src/app/_Models/_SelectInput/term.json').term;
  grade = require('src/app/_Models/_SelectInput/grade.json').grade;
  Years!: Array<string>;
  baseUrl = environment.apiUrl;
  entryIDList!: GetSelectList[];
  stdOutReasonList!: GetSelectList[];
  eduList!: GetEduList[];
  deptList!: GetDeptList[];
  stdClassList!: GetClassList[];
  stdTeamList!: GetTeamList[];
  initialData = new InitialData(this.http);
  model = {
    Years: '',
    Term: ''
}
data={
  Years:"",
  Term:"",
  Sex:"",
  EduNo:"",
  DeptNo:"",
  TeamNo:"",
  Grade:"",
  Class:"",
  All:"",
  Reason:"",
  EntryID:"",
  Month:"",
  IDNo:"",
  Approve:"",
  StdName:"",
  StdNoS:"",
  StdNoE:""
}
  constructor(private http: HttpClient,
              private toastr: ToastrService,
              private systemService: SystemService,
              private fileExchangeServices: FileExchangeService,) { }

  ngOnInit(): void {
    this.getYears();
    this.getStdOutReason();
    this.getEntryIDList();
    this.getEduList();
  }

  getYears(){
    this.initialData.getYears().subscribe((response: string[])=>{
      if(response){
        this.Years = response;
      }
    });
  }
  getStdOutReason() {
    this.systemService.getStdOutReason().subscribe((response) => {
        this.stdOutReasonList = response;
    })
}
  getEduList() {
    this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
        if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
        }
        this.eduList = response;

    }, error => this.toastr.error(error.error))
}

getDeptList(edu: string) {
  this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
      if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
      }
      this.deptList = response;

  }, error => this.toastr.error(error.error));
}

  getTeamList(dept: string) {
    this.systemService.getTeamList('', this.model.Years, this.model.Term, dept, '').subscribe((response) => {
        if (response.Info == false) {
            return;
        }

            this.stdTeamList = response;

    });
}
getClassList(edu: string, dept: string) {

  this.systemService.getClassList(edu, dept, '', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
      if (response.Info == false) {
          return;
      }
          this.stdClassList = response;
  })
}
getEntryIDList() {
  this.systemService.getEntryIDList('').subscribe((response) => {
      if (response.Info == false) {
          return;
      }
      this.entryIDList = response;
  })
}
  export(){
    this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD03_04/Get_ADSTD03_04", this.data).subscribe((response:any) => {
      if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
      }


      let url = window.URL.createObjectURL(response.blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = response.FileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
  });
  }
}
