import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADCUR-class-set',
  templateUrl: './ADCUR-class-set.component.html',
  styleUrls: ['./ADCUR-class-set.component.css']
})
export class ADCURClassSetComponent implements OnInit {

  class = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15']
  result: Array<string> = [];
  constructor() { }

  ngOnInit(): void {
  }

  setClass(event: any){
    
    let index = this.result.indexOf(event.innerText);
    if(index == -1){
      this.result.push(event.innerText);
    }
    else{
      this.result.splice(index, 1);
    }
    this.result.sort();
  }

}
