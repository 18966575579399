import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCUROpenClassEmployeeList } from 'src/app/_Models/AD/ADCUR/ADCUR0201/getCUROpenClassEmployeeList';
import { GetCUROpenClassList } from 'src/app/_Models/AD/ADCUR/ADCUR0201/getCUROpenClassList';
import { GetSYSAccountList } from 'src/app/_Models/AD/ADSYS/ADSYS0101/getSYSAccountList';
import { GetSYSAccountListInput } from 'src/app/_Models/AD/ADSYS/ADSYS0101/getSYSAccountListInput';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADCUR0201',
  templateUrl: './ADCUR0201.component.html',
  styleUrls: ['./ADCUR0201.component.css']
})
export class ADCUR0201Component implements OnInit {
	term = require('src/app/_Models/_SelectInput/term.json').term;
  baseUrl = environment.apiUrl;
  initialData = new InitialData(this.http);
  copyYears!: Array<string>;
	Years!: Array<string>;
  modalRef!: BsModalRef;
  cUROpenClassList!: GetCUROpenClassList;
  cUROpenClassEmployeeList!: GetCUROpenClassEmployeeList;
  codeList: Array<string> = [];
  sYSAccountList!: GetSYSAccountList[];
  cROpenClassEmployeeDataKey: string = "";
  sYSAccountListInput: GetSYSAccountListInput = {
    UserCode: '',
    Account: '',
    UserName: '',
    UserType: '',
    UnitNo: '',
    Status: '1'
  };
  constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService, private fileExchangeServices: FileExchangeService) { }

  ngOnInit(): void {
    this.getYears();
  }

  getYears(): void {
		this.initialData.getYears().subscribe((response) => {
			if (response) {
				this.Years = response;
        this.copyYears = response;
			}
		});
	}

  openModal(template: TemplateRef<any>, DataKey: string, type: string) {

    if(type == 'copy'){
      let modalConfig = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-md'
      };
      this.modalRef = this.modalService.show(template, modalConfig);
      return;
    }

    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };
    this.cROpenClassEmployeeDataKey = DataKey;
    this.getCUROpenClassEmployeeList(DataKey);

    this.modalRef = this.modalService.show(template, modalConfig);
  }

  getSYSAccountList(){
    this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0101/Get_SYS_AccountList", this.sYSAccountListInput).subscribe((response)=>{
      if(response.Info == false){
        this.toastr.warning(response.Message);
        return;
      }
      this.sYSAccountList = response;
      this.sYSAccountList.forEach(element => {
        element.Selected = false;
      });
    })
  }

  getCUROpenClassList(Years: string, Term: string){

    if(Years == '' || Term == ''){
      this.toastr.info("請選擇學年學期");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0201/Get_CUR_OpenClass_List", {Years: Years, Term: Term}).subscribe((response)=>{
      if(response.Info == false){
        this.toastr.warning(response.Message);
        return;
      }
      this.cUROpenClassList = response;
    })
  }

  getCUROpenClassAddNew(){

    if(!this.cUROpenClassList){
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0201/Get_CUR_OpenClass_AddNew", this.cUROpenClassList).subscribe((response)=>{
      if(response.Info == false){
        this.toastr.warning(response.Message);
        return;
      }
      this.getCUROpenClassList(this.cUROpenClassList.Years, this.cUROpenClassList.Term);
    });
  }

  getCUROpenClassDelete(){

    if(!this.cUROpenClassList){
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0201/Get_CUR_OpenClass_Delete", this.cUROpenClassList).subscribe((response)=>{
      if(response.Info == false){
        this.toastr.warning(response.Message);
        return;
      }
      this.getCUROpenClassList(this.cUROpenClassList.Years, this.cUROpenClassList.Term);
    });
  }

  getCUROpenClassEmployeeList(DataKey: string){
    this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0201/Get_CUR_OpenClass_EmployeeList", {DataKey: DataKey}).subscribe((response)=>{
      if(response.Info == false){
        this.toastr.warning(response.Message);
        return;
      }
      this.cUROpenClassEmployeeList = response;
    });
  }

  setCodeList(code: string, event: EventTarget | null){

    let index = this.codeList.indexOf(code);

    let input = <HTMLInputElement>event;

    if(index == -1 && input.checked == true){
      this.codeList.push(code);
    }
    else{
      this.codeList.splice(index, 1);
    }
  }

  getCUROpenClassEmployeeAddNew(){
    if(this.codeList.length == 0){
      this.toastr.info("請選擇人員");
      return;
    }

    let code = '';
    this.codeList.forEach(element => {
      code = code + element + ',';
    });

    this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0201/Get_CUR_OpenClass_EmployeeAddNew", {DataKey: this.cROpenClassEmployeeDataKey, CodeList: code.substring(0, code.length - 1)}).subscribe((response)=>{
      if(response.Info == false){
        this.toastr.warning(response.Message);
        return;
      }
      this.codeList = [];
      this.getCUROpenClassEmployeeList(this.cROpenClassEmployeeDataKey);
      this.sYSAccountList.forEach(element=>{
        element.Selected = false;
      })
    })
  }

  getCUROpenClassEmployeeDelete(){

    this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0201/Get_CUR_OpenClass_EmployeeDelete", this.cUROpenClassEmployeeList).subscribe((response)=>{
      if(response.Info == false){
        this.toastr.warning(response.Message);
        return;
      }
      this.getCUROpenClassEmployeeList(this.cROpenClassEmployeeDataKey);
    })
  }

  exportCUROpenClass(Years: string, Term: string){

    if(Years == '' || Term == ''){
      this.toastr.info("請選擇學年學期");
      return;
    }

    this.fileExchangeServices.ExportFile("ACAD/CUR/ADCUR0201/Export_CUROpenClass", {Years: Years, Term: Term}).subscribe((response)=>{
      if(response.FileName == ''){
        this.toastr.warning("查無資料");
        return;
      }
      let url = window.URL.createObjectURL(response.blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = response.FileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    })
  }

  getCUROpenClassCopyTo(Years: string, Term: string, YearsFrom: string, TermFrom: string){
    this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0201/Get_CUR_OpenClass_CopyTo", {
      Years: Years,
      Term: Term,
      YearsFrom: YearsFrom,
      TermFrom: TermFrom
    }).subscribe((response)=>{
      if(response.Info == false){
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("儲存成功");
      this.modalRef?.hide();
    })
  }

  setAll(type: string, event: EventTarget | null){

    let input = <HTMLInputElement>event;

    if(!this.cUROpenClassList.UnSetClass || !this.cUROpenClassList.SetClass){
      return;
    }

    if(type == 'unset' && input.checked == true){
      this.cUROpenClassList.UnSetClass.forEach(element => {
        element.Checked = true;
      });
    }
    else{
      this.cUROpenClassList.UnSetClass.forEach(element => {
        element.Checked = false;
      });
    }

    if(type == 'set' && input.checked == true){
      this.cUROpenClassList.SetClass.forEach(element => {
        element.Checked = true;
      });
    }
    else{
      this.cUROpenClassList.SetClass.forEach(element => {
        element.Checked = false;
      });
    }
  }

}
