<div class="container">
  <tabset #staticTabs>
    <tab heading="{{'核心能力圓餅圖' | translate}}" (selectTab)="onSelect($event)">
      <div id="chart">
        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [labels]="chartOptions.labels"
          [legend]="chartOptions.legend" [title]="chartOptions.title" [dataLabels]="chartOptions.dataLabels"
          [grid]="chartOptions.grid"></apx-chart>
      </div>
    </tab>
    <tab heading="{{'大一雷達圖' | translate}}" (selectTab)="onSelect($event)">
      <div id="chart1">
        <apx-chart [series]="chartOptions1.series" [chart]="chartOptions1.chart" [xaxis]="chartOptions1.xaxis"
          [yaxis]="chartOptions1.yaxis" [plotOptions]="chartOptions1.plotOptions" [title]="chartOptions1.title">
        </apx-chart>
      </div>

      <div class="container" style="width: 50%;">
        <p class="text-danger fw-bolder">{{'*點擊可查看詳細資訊' | translate}}</p>
        <small><em>{{'具備紮實專業本位之職業就業能力(1)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="33" [max]="100" (click)="openModal(template)"><b>33%</b>
        </progressbar>

        <small><em>{{'具備廣泛資訊統合之實用創新能力(2)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="33" [max]="100" (click)="openModal(template)"><b>33%</b>
        </progressbar>

        <small><em>{{'具備博雅人文涵養之服務關懷能力(3)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="25" [max]="100" (click)="openModal(template)"><b>25%</b>
        </progressbar>

        <small><em>{{'具備得宜禮節儀態之誠心敬業態度(4)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="25" [max]="100" (click)="openModal(template)"><b>25%</b>
        </progressbar>

        <small><em>{{'具備適應企業實務之經營管理能力(5)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="25" [max]="100" (click)="openModal(template)"><b>25%</b>
        </progressbar>

        <small><em>{{'具備內化自律負責之團隊合作能力(6)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="7" [max]="100" (click)="openModal(template)"><b>7%</b>
        </progressbar>

        <small><em>{{'具備良好中外語文之溝通表達能力(7)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="25" [max]="100" (click)="openModal(template)"><b>25%</b>
        </progressbar>

        <small><em>{{'具備尊重多元文化之宏觀國際視野(8)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="22" [max]="100" (click)="openModal(template)"><b>22%</b>
        </progressbar>
      </div>
    </tab>
    <tab heading="{{'大二雷達圖' | translate}}" (selectTab)="onSelect($event)">
      <div id="chart2">
        <apx-chart [series]="chartOptions1.series" [chart]="chartOptions1.chart" [xaxis]="chartOptions1.xaxis"
          [yaxis]="chartOptions1.yaxis" [plotOptions]="chartOptions1.plotOptions" [title]="chartOptions1.title">
        </apx-chart>
      </div>
      <div class="container" style="width: 50%;">
        <p class="text-danger fw-bolder">{{'*點擊可查看詳細資訊' | translate}}</p>
        <small><em>{{'具備紮實專業本位之職業就業能力(1)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="66" [max]="100" (click)="openModal(template)"><b>66%</b>
        </progressbar>

        <small><em>{{'具備廣泛資訊統合之實用創新能力(2)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="66" [max]="100" (click)="openModal(template)"><b>66%</b>
        </progressbar>

        <small><em>{{'具備博雅人文涵養之服務關懷能力(3)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="50" [max]="100" (click)="openModal(template)"><b>50%</b>
        </progressbar>

        <small><em>{{'具備得宜禮節儀態之誠心敬業態度(4)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="50" [max]="100" (click)="openModal(template)"><b>50%</b>
        </progressbar>

        <small><em>{{'具備適應企業實務之經營管理能力(5)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="21" [max]="100" (click)="openModal(template)"><b>21%</b>
        </progressbar>

        <small><em>{{'具備內化自律負責之團隊合作能力(6)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="50" [max]="100" (click)="openModal(template)"><b>50%</b>
        </progressbar>

        <small><em>{{'具備良好中外語文之溝通表達能力(7)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="50" [max]="100" (click)="openModal(template)"><b>50%</b>
        </progressbar>

        <small><em>{{'具備尊重多元文化之宏觀國際視野(8)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="44" [max]="100" (click)="openModal(template)"><b>44%</b>
        </progressbar>
      </div>
    </tab>
    <tab heading="{{'大三雷達圖' | translate}}" (selectTab)="onSelect($event)">
      <div id="chart3">
        <apx-chart [series]="chartOptions1.series" [chart]="chartOptions1.chart" [xaxis]="chartOptions1.xaxis"
          [yaxis]="chartOptions1.yaxis" [plotOptions]="chartOptions1.plotOptions" [title]="chartOptions1.title">
        </apx-chart>
      </div>

      <div class="container" style="width: 50%;">
        <p class="text-danger fw-bolder">{{'*點擊可查看詳細資訊' | translate}}</p>
        <small><em>{{'具備紮實專業本位之職業就業能力(1)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="73" [max]="100" (click)="openModal(template)"><b>73%</b>
        </progressbar>

        <small><em>{{'具備廣泛資訊統合之實用創新能力(2)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="66" [max]="100" (click)="openModal(template)"><b>66%</b>
        </progressbar>

        <small><em>{{'具備博雅人文涵養之服務關懷能力(3)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="75" [max]="100" (click)="openModal(template)"><b>75%</b>
        </progressbar>

        <small><em>{{'具備得宜禮節儀態之誠心敬業態度(4)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="75" [max]="100" (click)="openModal(template)"><b>75%</b>
        </progressbar>

        <small><em>{{'具備適應企業實務之經營管理能力(5)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="93" [max]="100" (click)="openModal(template)"><b>93%</b>
        </progressbar>

        <small><em>{{'具備內化自律負責之團隊合作能力(6)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="75" [max]="100" (click)="openModal(template)"><b>75%</b>
        </progressbar>

        <small><em>{{'具備良好中外語文之溝通表達能力(7)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="75" [max]="100" (click)="openModal(template)"><b>75%</b>
        </progressbar>

        <small><em>{{'具備尊重多元文化之宏觀國際視野(8)' | translate}}</em></small>
        <progressbar class="mb-1" [animate]="false" [value]="75" [max]="100" (click)="openModal(template)"><b>78%</b>
        </progressbar>
      </div>
    </tab>
  </tabset>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'具備紮實專業本位之職業就業能力' | translate}}</h4>
    <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
      (click)="modalRef.hide()">

    </button>
  </div>
  <div class="modal-body">
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th style="text-align: center;">{{'序號' | translate}}</th>
          <th style="text-align: center;">{{'科目代碼' | translate}}</th>
          <th style="text-align: center;">{{'科目名稱' | translate}}</th>
          <th style="text-align: center;">{{'選修別' | translate}}</th>
          <th style="text-align: center;">{{'學分' | translate}}</th>
          <th style="text-align: center;">{{'職業就業能力' | translate}}</th>
        </tr>
      </thead>
      <tbody style="text-align: center;">
        <tr>
          <td>1</td>
          <td>12345</td>
          <td>{{'食品衛生與安全' | translate}}</td>
          <td>{{'專必' | translate}}</td>
          <td>2.0</td>
          <td>85.00%</td>
        </tr>
        <tr>
          <td>2</td>
          <td>12346</td>
          <td>{{'英文' | translate}}</td>
          <td>{{'專必' | translate}}</td>
          <td>2.0</td>
          <td>80.00%</td>
        </tr>
        <tr>
          <td>3</td>
          <td>12347</td>
          <td>{{'國文' | translate}}</td>
          <td>{{'專必' | translate}}</td>
          <td>3.0</td>
          <td>75.00%</td>
        </tr>
        <tr>
          <td>4</td>
          <td>12348</td>
          <td>{{'歷史' | translate}}</td>
          <td>{{'專選' | translate}}</td>
          <td>2.0</td>
          <td>65.00%</td>
        </tr>
        <tr>
          <td>5</td>
          <td>12349</td>
          <td>{{'廚藝概論' | translate}}</td>
          <td>{{'專選' | translate}}</td>
          <td>2.0</td>
          <td>78.00%</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>