<div class="container">
    <div class="row">
        <div class="col-md-12">
            <button (click)="clear()" class="other-Button-gray float-end">清除</button>
            <button (click)="delete()" class="other-Button-gray float-end me-3">刪除</button>
            <button (click)="update()" class="other-Button-gray float-end me-3">修改</button>
            <button (click)="insert()" class="add-btn float-end me-3">新增</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>代碼</th>
                        <th>名稱</th>
                        <th>減終點時數</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data;let i=index"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" class="pointer"
                        (click)="searchDetail(i)" ng-model="all">
                        <td>{{i+1}}</td>
                        <td>{{item.Further}}</td>
                        <td>{{item.Further_Name}}</td>
                        <td>{{item.Del_Hours}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <form [formGroup]="detail">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">進修代碼</span>
                            <input  formControlName="Further" type="text" class="form-control shadow-none"
                                [readonly]="detail1.data" maxlength="1" placeholder="至多一碼" >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">進修名稱</span>
                            <input  formControlName="Further_Name" type="text"
                                class="form-control shadow-none">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">減終點時數</span>
                            <input  formControlName="Del_Hours" type="number" class="form-control" max="99"
                                min="0">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改時間</span>
                            <input formControlName="Update_Time" type="text" class="form-control shadow-none">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改者</span>
                            <input type="text" formControlName="Update_User" class="form-control shadow-none">
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
