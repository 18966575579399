import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ADSTD0810Input } from 'src/app/_Models/AD/ADSTD/ADSTD0810/ADSTD0810Input';
import { Field } from 'src/app/_Models/field';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSTDStatusList } from 'src/app/_Models/getSTDStatusList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSTD0810',
    templateUrl: './ADSTD0810.component.html',
    styleUrls: ['./ADSTD0810.component.css']
})
export class ADSTD0810Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    deptList!: GetDeptList[];
    eduList!: GetEduList[];
    classList!: GetClassList[];
    teamList!: GetTeamList[];
    sTDStatusList!: GetSTDStatusList[];
    modalRef!: BsModalRef;
    name = ['學制', '系所', '組別', '年級', '班級', '英文姓名', '性別', '身分證號', '生日', '年齡', '減免註記', '戶籍地址',
        '通訊地址', '通訊電話', '行動電話', 'Email','學籍狀態','緊急聯絡人姓名', '緊急聯絡人地址', '緊急聯絡人電話', '監護人姓名',
        '監護人關係', '監護人地址', '監護人電話', '國籍身份別', '入學管道', '畢業學校(最高學歷)', '畢業科系(最高學歷)', '畢業狀態(最高學歷)',
        '國籍', '原住民別', '班導師'];
    value = ['03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
        '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33','34'];
    field: Field[] = [];
    filedsNoArray: Array<string> = ['01', '02'];
    input: ADSTD0810Input = {
        Years: '',
        Term: '',
        EduNo: '',
        DeptNo: '',
        TeamNo: '',
        Class: '',
        Status: '',
        FiledsNo: ''
    }
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private fileExchangeServices: FileExchangeService,
        private systemService: SystemService,
        private modalService: BsModalService) {
        for (let index = 0; index < this.name.length; index++) {
            this.field.push({ name: this.name[index], value: this.value[index],Checked:false })
        }
    }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
        this.getSTDStatusList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }


    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getTeamList(dept: string) {
        this.systemService.getTeamList('3', '', '', dept, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.teamList = response;
        });
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getSTDStatusList() {
        this.systemService.getSTDStatusList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sTDStatusList = response;
            for (let index = 0; index < this.sTDStatusList.length; index++) {
                this.sTDStatusList[index].Status_No = this.sTDStatusList[index].Status_No;
            }
        }, error => this.toastr.error(error.error))
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setField(index:number, event: any) {
        let input = <HTMLInputElement>event.target;

        this.field[index].Checked=input.checked;
        // let index = this.filedsNoArray.indexOf(value);
        // if (index == -1) {
        //     this.filedsNoArray.push(value);
        // }
        // else {
        //     this.filedsNoArray.splice(index, 1);
        // }
    }

    exportReportADSTD0810() {

        if (this.input.Years == '' || this.input.Term == '') {
            this.toastr.info("請選擇學年期");
            this.modalRef.hide();
            this.filedsNoArray = [];
            return;
        }

        let filedsNo = '';
        this.filedsNoArray.forEach(element => {
            filedsNo = filedsNo + element + ',';
        });

        this.field.forEach(element => {
            if(element.Checked){
                filedsNo = filedsNo + element.value + ',';
            }
        });

        this.input.FiledsNo = filedsNo.substring(0, filedsNo.length - 1);
        this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD08_10/Export_Report_ADSTD0810", this.input).subscribe((response) => {
            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }, error => {
            this.toastr.error("伺服器發生問題");
            console.error(error);
        })
    }
}
