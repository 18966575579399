<div class="container">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" formControlName="EduNo">
                        <option value="">--請選擇學制--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="">--請選擇系所--</option>
                        <option *ngFor="let item of deptList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">關鍵字</span>
                    <input type="text" class="form-control shadow-none" formControlName="KeyWord">
                </div>
            </div>
            <div class="col-md-6">
                <button class="other-Button-gray float-end" (click)="ngForm.reset()">清除</button>
                <button class="other-Button-gray float-end me-3" (click)="getCUPCosSerialNoDelete()">刪除</button>
                <button class="other-Button-gray float-end me-3" (click)="getCUPCosSerialNoDetailSave()">儲存</button>
                <button class="search-btn me-3 float-Start" (click)="getCUPCosSerialNoList()">查詢</button>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-lg-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 30px;"></th>
                        <th style="width: 80px;vertical-align: middle;">課程代號</th>
                        <th style="width: 140px;vertical-align: middle;text-align:left;">課程名稱</th>
                        <th style="width: 100px;vertical-align: middle;text-align:left;">所屬學制</th>
                        <th style="width: 120px;vertical-align: middle;text-align:left;">所屬系所</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of cosserialnoList; let i = index" class="pointer"
                        (click)="getCUPCosSerialNoDetail(item)">
                        <td style="width: 30px;">{{i + 1}}</td>
                        <td style="width: 80px;">{{item.CosSerialNo}}</td>
                        <td style="width: 140px;text-align:left;">{{item.CosName}}</td>
                        <td style="width: 100px;text-align:left;">{{item.EduName}}</td>
                        <td style="width: 120px;text-align:left;">{{item.DeptName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6">
            <form [formGroup]="ngForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">所屬學制</span>
                            <select class="form-select shadow-none" formControlName="EduNo">
                                <option value="" disabled>--請選擇學制--</option>
                                <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">課程5碼</span>
                            <input type="text" formControlName="CosNo" class="form-control shadow-none"
                                    maxlength="5" readonly required>
                            &nbsp;&nbsp;
                            <input type="text" formControlName="CosName" class="form-control shadow-none" readonly>
                            <button type="button" class="btn btn-light shadow-none"
                                    (click)="openModal(cosTemplate)">查詢</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">課程別碼</span>
                            <input type="text" formControlName="SerialNo" class="form-control shadow-none"
                                placeholder="由系統自動編碼2碼" maxlength="2" readonly>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">備註</span>
                            <textarea class="form-control shadow-none" formControlName="Memo"
                                appTextareaAutoresize></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改者</span>
                            <input type="text" formControlName="UpdateUser" class="form-control shadow-none" readonly>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改時間</span>
                            <input type="text" formControlName="UpdateDate" class="form-control shadow-none" readonly>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #cosTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">課程5碼檔查詢</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormCosNoInput">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" formControlName="DeptNo">
                            <option value="">--請選擇系所--</option>
                            <option *ngFor="let item of deptList" value="{{item.ItemValue}}">{{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group mb-3">
                        <span class="input-group-text">關鍵字</span>
                        <input type="text" class="form-control shadow-none" formControlName="KeyWord">
                    </div>
                </div>
                <div class="col-md-6">
                    <button class="search-btn me-3 float-Start" (click)="getCUPCosNoList()">查詢</button>
                </div>
            </div>
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 40px;"></th>
                        <th style="width: 70px;vertical-align: middle;">課程代碼</th>
                        <th style="width: 200px;vertical-align: middle;text-align:left;">課程名稱</th>
                        <th style="width: 120px;vertical-align: middle;text-align:left;">所屬系所</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of cosnoList; let i = index" class="pointer"
                        (click)="setCos(item.CosNo, item.CosName)">
                        <td style="width: 40px;">{{i + 1}}</td>
                        <td style="width: 70px;">{{item.CosNo}}</td>
                        <td style="width: 200px;text-align:left;">{{item.CosName}}</td>
                        <td style="width: 120px;text-align:left;">{{item.DeptName}}</td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</ng-template>
