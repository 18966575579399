<div class="container">
    <table class="table table-hover text-center table-striped table-rwd">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'學年期' | translate}}</th>
                <th>{{'繳費類別' | translate}}</th>
                <th>{{'銷帳編號' | translate}}</th>
                <th>{{'繳費期限' | translate}}</th>
                <th>{{'應繳金額' | translate}}</th>
                <th>{{'狀態' | translate}}</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data;">
                <td data-th="學年期">{{item.YearsTerm}}</td>
                <td data-th="繳費類別">{{item.MakeDoc_Name}}</td>
                <td data-th="銷帳編號">{{item.Virtual_Account}}</td>
                <td data-th="繳費期限">{{item.Down_Time}}</td>
                <td data-th="應繳金額">{{item.PayMoney}}</td>
                <ng-container *ngIf="item.FormStatus === '已完成繳費'">
                    <td data-th="狀態" class="text-success fw-bolder">{{item.FormStatus}}</td>
                </ng-container>
                <ng-container *ngIf="item.FormStatus !== '已完成繳費'">
                    <td data-th="狀態" class="text-danger fw-bolder">{{item.FormStatus}}</td>
                </ng-container>
                <td data-th="操作" class="table-rwd-hide">
                    <span *ngIf="item.FormStatus === '未下載'" class="icon-Payment-download"></span>
                    <span *ngIf="item.FormStatus === '已完成繳費'" class="icon-Teaching-Evaluation-Query"></span>
                    <a class="pointer" *ngIf="item.FormStatus === '未下載'"
                        (click)="exportFile(item.Virtual_Account)">{{'下載繳費單' | translate}}</a>
                    <a class="pointer" *ngIf="item.FormStatus === '已完成繳費'"
                        (click)="exportFile(item.Virtual_Account)">{{'下載繳費證明' | translate}}</a>
                </td>
                <td class="td-rwd-hide" data-th="條碼">
                    <span class="icon-Barcode-button"></span>
                    <a class="pointer" (click)="showBarcode(item.Virtual_Account, template)">{{'繳費條碼' | translate}}</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'繳費條碼' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="barcodeRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <h4 class="fw-bolder ps-1">{{'繳費類別：' | translate}}{{barcodeData.MakeDoc_Name}}</h4>
        <h4 class="fw-bolder ps-1">{{'繳費金額：' | translate}}{{barcodeData.Must_TMoney}}</h4>
        <h5 class="fw-bolder ps-1">{{'超商專用碼' | translate}}</h5>
        <div>
            <img [src]="CS_Barcode1_Base64" alt="">
            <p class="ps-2">{{barcodeData.CS_Barcode1}}</p>
        </div>
        <div>
            <img [src]="CS_Barcode2_Base64" alt="">
            <p class="ps-2">{{barcodeData.CS_Barcode2}}</p>
        </div>
        <div>
            <img [src]="CS_Barcode3_Base64" alt="">
            <p class="ps-2">{{barcodeData.CS_Barcode3}}</p>
        </div>
        <h5 class="fw-bolder ps-1">{{'郵局專用碼' | translate}}</h5>
        <div>
            <img [src]="Post_Barcode1_Base64" alt="">
            <p class="ps-2">{{barcodeData.Post_Barcode1}}</p>
        </div>
        <div>
            <img [src]="Post_Barcode2_Base64" alt="">
            <p class="ps-2">{{barcodeData.Post_Barcode2}}</p>
        </div>
        <div>
            <img [src]="Post_Barcode3_Base64" alt="">
            <p class="ps-2">{{barcodeData.Post_Barcode3}}</p>
        </div>
    </div>
</ng-template>