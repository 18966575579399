
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { MenuService } from 'src/app/_Services/_Shared/menu.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GetHelpAll } from 'src/app/_Models/getHelpAll';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

    baseUrl = environment.apiUrl;
    currentMenu!: any;
    isCollapsed = false;
    schoolName!: string;
    isRightCollapsed = true;
    ItemName!: string;
    helpItem: Array<string> = [];
    helpAll!: GetHelpAll[];
    helpCurrent!: GetHelpAll[];

    useFavorite: boolean = false;

    @Input() routePath!: string;
    @ViewChild('dropdown') public dropdown!: BsDropdownDirective;


    constructor(public menuService: MenuService,
        private accountService: AccountService,
        private router: Router,
        private toastr: ToastrService,
        private systemService: SystemService,
        private http: HttpClient
    ) {

    }

    ngOnInit(): void {
        this.getMenu();
        this.getSchool();
        this.accountService.currentUser$.subscribe(response => {
            if (response && response.Type == '1') {
                this.getHelpAll();
            }
        });

        this.CheckPurviewFavorite();
    }


    logout() {
        this.accountService.logout();
        this.router.navigateByUrl('/');
    }

    getSchool() {
        this.systemService.getSchoolInfo().subscribe((response) => {
            if (!response) {
                this.toastr.error("取得學校名稱失敗!", "系統訊息");
                return;
            }
            this.schoolName = response.Sch_Name;
        });
    }

    getMenu() {
        if (sessionStorage.getItem('auth')) {
            this.menuService.getMenu().subscribe(() => {
                this.menuService.currentMenu$.pipe(take(1)).subscribe((menu) => {
                    if (menu) this.currentMenu = menu;

                }, error => {
                    this.toastr.error(error.error);
                    this.router.navigateByUrl('');
                })
            }
            );
        };
    }

    getHelpAll() {
        this.http.get<any>(this.baseUrl + "Portal/HelpAll").subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.helpItem = [];
            this.helpAll = response.Value;
            this.helpCurrent = this.helpAll;
            this.helpAll.forEach(element => {
                this.helpItem.push(element.ItemName);
            });
        })
    }

    getHelp() {
        if (this.ItemName == '') {
            this.helpCurrent = this.helpAll;
        }
        else {
            this.helpCurrent = this.helpAll.filter(n => n.ItemName == this.ItemName);
        }
    }

    //檢查我的最愛權限
    CheckPurviewFavorite() {

        if (sessionStorage.getItem('auth') != null){
            // console.log(JSON.parse(sessionStorage.getItem('auth') || '').UseFavorite);
            if (JSON.parse(sessionStorage.getItem('auth') || '').UseFavorite) {
                this.useFavorite = true;
            }
        }
    }


}
