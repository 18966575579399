<div class="container">
    <p>{{'目前開放學年期：' | translate}}&ensp;<button class="other-Button-gray mb-3">{{'就貸申請' | translate}}</button></p>

    <table class="table table-hover table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'申請學年' | translate}}</th>
                <th>{{'申請學期' | translate}}</th>
                <th>{{'申請日期' | translate}}</th>
                <th>{{'繳費單類別' | translate}}</th>
                <th>{{'應繳金額' | translate}}</th>
                <th>{{'就學貸款金額' | translate}}</th>
                <th>{{'差額補繳金額' | translate}}</th>
                <th>{{'狀態' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td data-th="申請學年"></td>
                <td data-th="申請學期"></td>
                <td data-th="申請日期"></td>
                <td data-th="繳費單類別"></td>
                <td data-th="應繳金額"></td>
                <td data-th="就學貸款金額"></td>
                <td data-th="差額補繳金額"></td>
                <td data-th="狀態"></td>
            </tr>
        </tbody>
    </table>
</div>