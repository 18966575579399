import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCUPPermanentList } from 'src/app/_Models/AD/ADCUP/ADCUP0401/getCUPPermanentList';
import { GetCosData } from 'src/app/_Models/AD/ADCUP/ADCUP0503/getCosData';
import { GetCUPCurAssistPlanAddNew } from 'src/app/_Models/AD/ADCUP/ADCUP0503/getCUPCurAssistPlanAddNew';
import { GetCUPCurAssistPlanCopyToInput } from 'src/app/_Models/AD/ADCUP/ADCUP0503/getCUPCurAssistPlanCopyToInput';
import { GetCUPCurAssistPlanDetail } from 'src/app/_Models/AD/ADCUP/ADCUP0503/getCUPCurAssistPlanDetail';
import { GetCUPCurAssistPlanDetailInput } from 'src/app/_Models/AD/ADCUP/ADCUP0503/getCUPCurAssistPlanDetailInput';
import { GetCUPCurAssistPlanModifyInput } from 'src/app/_Models/AD/ADCUP/ADCUP0503/getCUPCurAssistPlanModifyInput';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUP0503',
    templateUrl: './ADCUP0503.component.html',
    styleUrls: ['./ADCUP0503.component.css']
})
export class ADCUP0503Component implements OnInit {
    dataKey = '';
    modalRef!: BsModalRef;
    group = require('src/app/_Models/_SelectInput/group.json').group;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    deptList!: GetDeptList[];
    newDeptList!: GetDeptList[];
    copyDeptList!: GetDeptList[];
    targetDeptList!: GetDeptList[];
    eduList!: GetEduList[];
    newEduList!: GetEduList[];
    copyEduList!: GetEduList[];
    targetEduList!: GetEduList[];
    teamList!: GetTeamList[];
    newNeamList!: GetTeamList[];
    copyTeamList!: GetTeamList[];
    targetTeamList!: GetTeamList[];
    cUPPermanentList!: GetCUPPermanentList[];
    cosData = new GetCosData();
    cUPCurAssistPlanDetailInput = new GetCUPCurAssistPlanDetailInput();
    cUPCurAssistPlanDetail!: GetCUPCurAssistPlanDetail[];
    dataKeyArray: Array<string> = [];
    cUPCurAssistPlanAddNew = new GetCUPCurAssistPlanAddNew();
    cUPCurAssistPlanModifyInput = new GetCUPCurAssistPlanModifyInput();
    cUPCurAssistPlanCopyToInput = new GetCUPCurAssistPlanCopyToInput();

    ngFormInput = new UntypedFormGroup({
        DeptNo: new UntypedFormControl(this.cUPCurAssistPlanDetailInput.DeptNo, [Validators.required]),
        TeamNo: new UntypedFormControl(this.cUPCurAssistPlanDetailInput.TeamNo, [Validators.required]),
        Years: new UntypedFormControl(this.cUPCurAssistPlanDetailInput.Years, [Validators.required]),
    });

    ngFormNewInput = new UntypedFormGroup({
        EduNo: new UntypedFormControl(this.cosData.EduNo),
        DeptNo: new UntypedFormControl(this.cosData.DeptNo),
        Status: new UntypedFormControl(this.cosData.Status),
        KeyWord: new UntypedFormControl(this.cosData.KeyWord),
        SelKind: new UntypedFormControl(this.cosData.SelKind),
        OrderBy: new UntypedFormControl(this.cosData.OrderBy),
        FiledsNo: new UntypedFormControl(this.cosData.FiledsNo),
    });

    ngFormNew = new UntypedFormGroup({
        Years: new UntypedFormControl({ value: this.cUPCurAssistPlanAddNew.Years, disabled: true }, [Validators.required]),
        DeptNo: new UntypedFormControl({ value: this.cUPCurAssistPlanAddNew.DeptNo, disabled: true }, [Validators.required]),
        TeamNo: new UntypedFormControl({ value: this.cUPCurAssistPlanAddNew.TeamNo, disabled: true }, [Validators.required]),
        AcadCourseNo: new UntypedFormControl(this.cUPCurAssistPlanAddNew.AcadCourseNo),
        CosList: new UntypedFormControl(this.cUPCurAssistPlanAddNew.CosList, [Validators.required]),
        CosCluster: new UntypedFormControl(this.cUPCurAssistPlanAddNew.CosCluster, [Validators.required]),
        MustPassNum: new UntypedFormControl(this.cUPCurAssistPlanAddNew.MustPassNum, [Validators.required]),
    });

    ngFormEdit = new UntypedFormGroup({
        DataKey: new UntypedFormControl(this.cUPCurAssistPlanModifyInput.DataKey),
        CosID: new UntypedFormControl({ value: this.cUPCurAssistPlanModifyInput.CosID, disabled: true }),
        CosName: new UntypedFormControl({ value: this.cUPCurAssistPlanModifyInput.CosName, disabled: true }),
        SelKindName: new UntypedFormControl({ value: this.cUPCurAssistPlanModifyInput.SelKindName, disabled: true }),
        Credits: new UntypedFormControl({ value: this.cUPCurAssistPlanModifyInput.Credits, disabled: true }),
        CosHours: new UntypedFormControl({ value: this.cUPCurAssistPlanModifyInput.CosHours, disabled: true }),
        CosTerm: new UntypedFormControl(this.cUPCurAssistPlanModifyInput.CosTerm, [Validators.required]),
        Grade: new UntypedFormControl(this.cUPCurAssistPlanModifyInput.Grade, [Validators.required]),
        SelStyle: new UntypedFormControl({ value: this.cUPCurAssistPlanModifyInput.SelStyle, disabled: true }),
        CurClass: new UntypedFormControl({ value: this.cUPCurAssistPlanModifyInput.CurClass, disabled: true }),
        CosCluster: new UntypedFormControl(this.cUPCurAssistPlanModifyInput.CosCluster),
        MustPassNum: new UntypedFormControl(this.cUPCurAssistPlanModifyInput.MustPassNum),
        UpdateUser: new UntypedFormControl({ value: this.cUPCurAssistPlanModifyInput.UpdateUser, disabled: true }),
        UpdateTime: new UntypedFormControl({ value: this.cUPCurAssistPlanModifyInput.UpdateTime, disabled: true }),
        Selected: new UntypedFormControl(this.cUPCurAssistPlanModifyInput.Selected),
    });

    ngFormCopy = new UntypedFormGroup({
        Years: new UntypedFormControl(this.cUPCurAssistPlanCopyToInput.Years, [Validators.required]),
        DeptNo: new UntypedFormControl(this.cUPCurAssistPlanCopyToInput.DeptNo, [Validators.required]),
        TeamNo: new UntypedFormControl(this.cUPCurAssistPlanCopyToInput.TeamNo, [Validators.required]),
        AcadCourseNo: new UntypedFormControl(this.cUPCurAssistPlanCopyToInput.AcadCourseNo),
        TargetYears: new UntypedFormControl(this.cUPCurAssistPlanCopyToInput.TargetYears, [Validators.required]),
        TargetDeptNo: new UntypedFormControl(this.cUPCurAssistPlanCopyToInput.TargetDeptNo, [Validators.required]),
        TargetTeamNo: new UntypedFormControl(this.cUPCurAssistPlanCopyToInput.TargetTeamNo, [Validators.required]),
        TargetAcadCourseNo: new UntypedFormControl(this.cUPCurAssistPlanCopyToInput.TargetAcadCourseNo),
    })
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private modalService: BsModalService) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
    }
    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
            this.newEduList = response;
            this.copyEduList = response;
            this.targetEduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, isNew: boolean, isCopy: boolean, isTarget: boolean) {
        this.systemService.getDeptList(edu, '', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (isNew) {
                this.newDeptList = response;
            }
            else if (isCopy) {
                isTarget ? this.targetDeptList = response : this.copyDeptList = response;
            }
            else {
                this.deptList = response;
            }

        }, error => this.toastr.error(error.error));
    }

    getTeamList(dept: string, isNew: boolean, isCopy: boolean, isTarget: boolean) {
        this.systemService.getTeamList('', '', '', dept, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (isNew) {
                this.newNeamList = response;
            }
            else if (isCopy) {
                isTarget ? this.targetTeamList = response : this.copyTeamList = response;
            }
            else {
                this.teamList = response;
            }

        });
    }

    openModal(type: string, template: TemplateRef<any>) {

        if (type == 'edit') {
            if (this.dataKey == '') {
                this.toastr.info("請選擇欲修改的資料");
                return;
            }
        }
        else if (type == 'new') {
            if (!this.ngFormInput.valid) {
                this.toastr.info("請選擇學年及組別");
                return;
            }

            this.ngFormNew.get('Years')?.setValue(this.ngFormInput.get('Years')?.value);
            this.ngFormNew.get('DeptNo')?.setValue(this.ngFormInput.get('DeptNo')?.value);
            this.ngFormNew.get('TeamNo')?.setValue(this.ngFormInput.get('TeamNo')?.value);
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setDatakey(type: string, dataKey: string, event: any) {
        if (!this.cUPPermanentList) {
            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.cUPPermanentList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.cUPPermanentList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.cUPPermanentList.forEach(element => {
                    element.checked = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

    getCosData() {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0401/Get_CUP_Permanent_List", this.ngFormNewInput.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUPPermanentList = response;
            this.cUPPermanentList.forEach(element => {
                element.Selected = false;
            });
        })
    }

    chooseData(dataKey: string, index: number) {
        this.cUPCurAssistPlanDetail.forEach(element => {
            element.Selected = false
        });
        this.cUPCurAssistPlanDetail[index].Selected = true;
        this.ngFormEdit.setValue(this.cUPCurAssistPlanDetail[index]);
        this.dataKey = dataKey;
    }

    getCUPCurAssistPlanDetail() {
        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇學年及組別");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0503/Get_CUP_CurAssistPlan_Detail", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cUPCurAssistPlanDetail = []
                return;
            }
            this.cUPCurAssistPlanDetail = response;
            this.cUPCurAssistPlanDetail.forEach(element => {
                element.Selected = false;
            })
        })
    }

    getCUPCurAssistPlanAddNew() {
        if (this.dataKeyArray.length == 0) {
            this.toastr.info("尚未選取科目");
            return;
        }

        let dataKey = '';
        this.dataKeyArray.forEach(element => {
            dataKey = dataKey + element + ',';
        });

        this.ngFormNew.get('CosList')?.setValue(dataKey.substring(0, dataKey.length - 1));

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0503/Get_CUP_CurAssistPlan_AddNew", this.ngFormNew.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Messgae);
                return;
            }
            this.toastr.success("儲存成功");
            this.dataKeyArray = [];
            this.modalRef.hide();
            this.getCUPCurAssistPlanDetail();
        })
    }

    getCUPCurAssistPlanModify() {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0503/Get_CUP_CurAssistPlan_Modify", this.ngFormEdit.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.modalRef.hide();
            this.getCUPCurAssistPlanDetail();
        });
    }

    getCUPCurAssistPlanDelete() {
        if (this.dataKey == '') {
            this.toastr.info("請選擇欲刪除的資料");
            return;
        }

        Swal.fire({
            title: "確定要刪除此筆資料？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0503/Get_CUP_CurAssistPlan_Delete", { DataKey: this.dataKey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.dataKey == '';
                    this.toastr.success("刪除成功");
                    this.getCUPCurAssistPlanDetail();
                })
            }
            else {
                this.toastr.info("已取消動作");
                this.dataKey = '';
                this.cUPCurAssistPlanDetail.forEach(element => {
                    element.Selected = false;
                });
            }
        });
    }

    getCUPCurAssistPlanDeleteAll() {
        if (this.cUPCurAssistPlanDetail.length == 0) {
            this.toastr.info("請先查詢資料");
            return;
        }

        Swal.fire({
            title: "確定要刪除所有資料？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0503/Get_CUP_CurAssistPlan_DeleteAll", { DataKey: this.cUPCurAssistPlanDetail[0].DataKey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.getCUPCurAssistPlanDetail();
                })
            }
        })

    }

    getCUPCurAssistPlanCopyTo() {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0503/Get_CUP_CurAssistPlan_CopyTo", this.ngFormCopy.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("建立完成");
            this.modalRef.hide();
            this.getCUPCurAssistPlanDetail();
        })
    }

}
