import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0302/search';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0302/searchDetail';
import { getSchoolClassList } from 'src/app/_Models/getSchoolTypeList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADRGP0302',
    templateUrl: './ADRGP0302.component.html',
    styleUrls: ['./ADRGP0302.component.css']
})
export class ADRGP0302Component implements OnInit {

    baseUrl = environment.apiUrl;
    fileExchange = new FileExchange();
    countryList!: GetSelectList[];
    cityList!: GetSelectList[];
    schoolTypeList!: GetSelectList[];
    input = {
        Country: '',
        City: '',
        SchClass: '',
        Search_Word: ''
    };
    workbook = '工作表1';
    data!: Search[];
    schoolClassList!: getSchoolClassList[];
    dataDetail: SearchDetail = {
        FileBase64: '',
        Work_Book: '',
        SchClass: '',
        SchClass_Name: '',
        Sch_No: '',
        Sch_Name: '',
        Country: '',
        City: '',
        City_Name: '',
        Com_Address: '',
        Com_Tel: '',
        Website: '',
        SchType: '',
        Memo: '',
        Update_Time: '',
        Update_User: ''
    }
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
        this.getCountryList();
        this.getSchoolClass();
        this.getSchoolType();
    }


    getCountryList() {
        this.systemService.getCountryList().subscribe((response) => {
            this.countryList = response;
        })
    }

    getCityList(country: string) {
        this.systemService.getCityList(country).subscribe((response) => {
            console.log(this.cityList);
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cityList = [];
                return;
            }
            this.cityList = response;
        })
    }

    getSchoolClass() {
        this.systemService.getSchoolClass().subscribe((response) => {
            this.schoolClassList = response;
        })
    }

    getSchoolType() {
        this.systemService.getSchoolType().subscribe((response) => {
            this.schoolTypeList = response;
        })
    }

    search() {
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0302/Search", this.input).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
        })
    }

    searchDetail(SchClass: string, Sch_No: string, index: number) {
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0302/Search_Detail", { SchClass: SchClass, Sch_No: Sch_No }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.dataDetail = response;
            this.dataDetail.Sch_Name = this.dataDetail.Sch_Name;
            this.dataDetail.Com_Address = this.dataDetail.Com_Address;
            this.dataDetail.Com_Tel = this.dataDetail.Com_Tel;
            this.dataDetail.Memo = this.dataDetail.Memo;
            this.dataDetail.Update_User = this.dataDetail.Update_User;
            this.dataDetail.Update_Time = this.dataDetail.Update_Time;
        });
    }

    importData($event: any, dataKey: string, type: string) {

        if (this.input.SchClass == '' || this.input.Country == '') {
            this.toastr.info("請選擇學校類別與國家");
            $event.target.value = "";
            return;
        }

        if (this.workbook == '') {
            this.toastr.info("請輸入Excel工作表名稱");
            return;
        }

        let file = $event.target.files[0];
        if (!file) {
            this.toastr.info("無偵測到檔案");
            return;
        }
        this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type)
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        let json = {
            SchClass: that.input.SchClass,
            Country: that.input.Country,
            FileBase64: base64,
            Work_Book: that.workbook
        }

        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showCancelButton: true,
            confirmButtonText: '是',
            cancelButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.fileExchangeServices.ImportFile('ACAD/RGP/ADRGP0302/Import', json)
                    .subscribe((response: any) => {
                        if (response.Info == true) { that.toastr.success("上傳成功!!"); }
                        else { that.toastr.error(response.Message, "執行錯誤!"); }
                    });
            }
            else if (result.isDenied) {
                document.getElementById(dataKey)?.setAttribute('value', '');
                that.toastr.info("已取消上傳。");
            }
        });
    }
}
