import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-function-search',
    templateUrl: './function-search.component.html',
    styleUrls: ['./function-search.component.css']
})

export class FunctionSearchComponent implements OnInit {

    searchText = ""
    baseUrl = environment.apiUrl;
    constructor(private toastr: ToastrService, private http: HttpClient, private router: Router, private systemService: SystemService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
    }

    search() {
        if (this.searchText == '') {
            return;
        }

        this.http.post<any>(this.baseUrl + "Portal/Get_VoiceSearch", { KeyWord: this.searchText }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message);
                return;
            }

            if (response[0].FullURL == 'Y') {
                this.router.navigateByUrl(response[0].HyperLink);
            }
            else {
                window.location.href = response[0].HyperLink;
            }

        });
    }
}
