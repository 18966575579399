<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdNo">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" disabled
                    [ngModel]="stdContrastReMark.StdData[0].StdName || ''">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班級</span>
                <input type="text" class="form-control shadow-none" disabled
                    [ngModel]="stdContrastReMark.StdData[0].ClassName || ''">
            </div>
        </div>
        <div class="col-md-3">
            <button class="search-btn" (click)="getStdContrastReMark()">查詢</button>
        </div>
    </div>

    <tabset>
        <tab heading="替代科目維護">
            <div class="row mt-3 mb-3">
                <div class="col-md-3">
                    <button class="other-Button-gray" style="width: 130px;"
                        (click)="getStdContrastReMarkAdd()">新增課程標準</button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <table class="table table-hover text-center">
                        <thead class="thead-dark-red">
                            <tr>
                                <th>修課學年</th>
                                <th>學期</th>
                                <th>科目編號</th>
                                <th>科目名稱</th>
                                <th>選修別</th>
                                <th>學分</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                                class="pointer" *ngFor="let item of stdContrastReMark.YearScoreData; let i = index"
                                (click)="setModel('YearScoreDataKey', item.DataKey, i)">
                                <td>{{item.Years}}</td>
                                <td>{{item.Term}}</td>
                                <td>{{item.CosID}}</td>
                                <td>{{item.CosName}}</td>
                                <td>{{item.SelKindName}}</td>
                                <td>{{item.Credit}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col">
                    <table class="table table-hover text-center">
                        <thead class="thead-dark-red">
                            <tr>
                                <th>課程學年</th>
                                <th>學期</th>
                                <th>科目編號</th>
                                <th>科目名稱</th>
                                <th>選修別</th>
                                <th>學分</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                                class="pointer" *ngFor="let item of stdContrastReMark.CurPlanData; let i = index"
                                (click)="setModel('CurPlanDataKey', item.DataKey, i)">
                                <td>{{item.Years}}</td>
                                <td>{{item.Term}}</td>
                                <td>{{item.CosID}}</td>
                                <td>{{item.CosName}}</td>
                                <td>{{item.SelKindName}}</td>
                                <td>{{item.Credit}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </tab>
        <tab heading="替代科目明細紀錄">
            <table class="table table-hover text-center table-striped">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 70px;"><input type="checkbox" class="pointer"
                                (click)="setDatakey('all', '', $event.target)">&ensp;刪除</th>
                        <th>原成績科目</th>
                        <th>原科目名稱</th>
                        <th>原學分</th>
                        <th>原選修別</th>
                        <th>新科目代碼</th>
                        <th>新科目名稱</th>
                        <th>新學分</th>
                        <th>新選修別</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stdContrastReMark.ReMarkData">
                        <td style="width: 70px;"><input type="checkbox" class="pointer"
                                (click)="setDatakey('', item.DataKey, $event.target)"></td>
                        <td>{{item.CosID}}</td>
                        <td>{{item.CosName}}</td>
                        <td>{{item.Credit}}</td>
                        <td>{{item.SelKindName}}</td>
                        <td>{{item.ReMarkCosID}}</td>
                        <td>{{item.ReMarkCosName}}</td>
                        <td>{{item.ReMarkCredit}}</td>
                        <td>{{item.ReMarkSelKindName}}</td>
                    </tr>
                </tbody>
            </table>
        </tab>
    </tabset>

</div>
