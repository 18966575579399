import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetStdGradSetList } from 'src/app/_Models/AD/ADSTD/ADSTD0703/getStdGradSetList';
import { GetStdGradSetListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0703/getStdGradSetListInput';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSTD0703',
    templateUrl: './ADSTD0703.component.html',
    styleUrls: ['./ADSTD0703.component.css']
})
export class ADSTD0703Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    dataKeyArray: Array<string> = [];
    deptList!: GetDeptList[];
    initialData = new InitialData(this.http);
    stdGradSetListInput: GetStdGradSetListInput = {
        Years: '',
        Term: '',
        DeptNo: '',
        StdNoFrom: '',
        StdNoTo: '',
        StdName: ''
    };
    stdGradSetList!: GetStdGradSetList[];
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getDeptList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getDeptList() {
        this.systemService.getDeptList('', '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getStdGradSetList() {

        if (this.stdGradSetListInput.DeptNo == '' || this.stdGradSetListInput.Term == '' || this.stdGradSetListInput.Years == '') {
            this.toastr.info("請選擇學年學期系所");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_03/Get_Std_Grad_SetList", this.stdGradSetListInput).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdGradSetList = []
                return;
            }
            this.stdGradSetList = response;
        }, error => this.toastr.error(error.error))
    }

    getStdGradSetGrad() {

        if (this.dataKeyArray.length == 0) {
            this.toastr.info("請先選擇學生");
            return;
        };

        Swal.fire({
            title: `確定設定此${this.dataKeyArray.length}位學生?`,
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then(result => {
            if (result.isConfirmed) {
                let dataKey = '';
                this.dataKeyArray.forEach(element => {
                    dataKey = dataKey + element + ',';
                });

                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_03/Get_Std_Grad_SetGrad", {
                    Years: this.stdGradSetListInput.Years,
                    Term: this.stdGradSetListInput.Term,
                    DeptNo: this.stdGradSetListInput.DeptNo,
                    StdList: dataKey.substring(0, dataKey.length - 1)
                }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("設定完成");
                    this.dataKeyArray = [];
                }, error => this.toastr.error(error.error))
            }
            else {
                this.toastr.info("動作已取消");
            }
        })
    }

    setDatakey(type: string, dataKey: string, event: any) {
        if (!this.stdGradSetList) {
            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.stdGradSetList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.stdGradSetList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.stdGradSetList.forEach(element => {
                    element.checked = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

}
