<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="export_Report_ADGRD0601()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">樣式</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="ReportType">
                        <option value="C">中文</option>
                        <option value="E">英文</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year formControlName="Years"
                        (change)="getEduList(Year.value, Term.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term formControlName="Term"
                        (change)="getEduList(Year.value, Term.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" #edu
                        (change)="getDeptList(edu.value, Year.value, Term.value)" formControlName="EduNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                            {{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" #Dept formControlName="DeptNo"
                        (change)="getClassList(edu.value, Dept.value, Year.value, Term.value)">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoTo">
                </div>
            </div>
            <div class="col-md-3">
                <button class="export-btn">匯出</button>
            </div>
        </div>
    </form>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th><input type="checkbox" class="form-check-input shadow-none pointer"
                        (click)="setAll($event.target)">&ensp;全選</th>
                <th>班級代碼</th>
                <th>班級名稱</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of classList; let i = index">
                <td>{{i + 1}}</td>
                <td><input type="checkbox" class="form-check-input shadow-none pointer" [(ngModel)]="item.checked"></td>
                <td>{{item.Class}}</td>
                <td>{{item.Class_Name}}</td>
            </tr>
        </tbody>
    </table>
</div>