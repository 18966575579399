import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetBarcode } from 'src/app/_Models/STDWEB/STDW00003/getBarcode';
import { GetData } from 'src/app/_Models/STDWEB/STDW00003/getData';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-STDW00003',
  templateUrl: './STDW00003.component.html',
  styleUrls: ['./STDW00003.component.css']
})
export class STDW00003Component implements OnInit {


  baseUrl = environment.apiUrl;
  data!: GetData[];
  barcodeData!: GetBarcode;
  barcodeRef!: BsModalRef;

  // 繳費圖片
  CS_Barcode1_Base64!: SafeUrl;
  CS_Barcode2_Base64!: SafeUrl;
  CS_Barcode3_Base64!: SafeUrl;
  Post_Barcode1_Base64!: SafeUrl;
  Post_Barcode2_Base64!: SafeUrl;
  Post_Barcode3_Base64!: SafeUrl;


  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private fileExchangeServices: FileExchangeService,
    private modalService: BsModalService,
    private domSanitizer: DomSanitizer, private systemService: SystemService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.use(this.systemService.currentLanguage)
    this.getData();
  }


  getData() {
    this.http.post<GetData[]>(this.baseUrl + "ACAD/STDWEB/STDW000_03/Search", {}).subscribe((response) => {
      if (response) {
        this.data = response;
        return;
      }
      this.toastr.error("查詢失敗");
    });
  }

  exportFile(data: string) {
    this.fileExchangeServices.ExportFile("ACAD/STDWEB/STDW000_03/Export", { Virtual_Account: data })
      .subscribe((response) => {
        let url = window.URL.createObjectURL(response.blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = response.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
        error => this.toastr.error(error.error));
  }


  showBarcode(Virtual_Account: string, template: TemplateRef<any>) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'modal-sm'
    };

    this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW000_03/Show_Barcode", { Virtual_Account: Virtual_Account }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.error(response.Message);
        return;
      }
      this.barcodeData = response;

      // 將base64轉為safeUrl
      this.CS_Barcode1_Base64 = this.domSanitizer.bypassSecurityTrustUrl(response.CS_Barcode1_Base64);
      this.CS_Barcode2_Base64 = this.domSanitizer.bypassSecurityTrustUrl(response.CS_Barcode2_Base64);
      this.CS_Barcode3_Base64 = this.domSanitizer.bypassSecurityTrustUrl(response.CS_Barcode3_Base64);
      this.Post_Barcode1_Base64 = this.domSanitizer.bypassSecurityTrustUrl(response.Post_Barcode1_Base64);
      this.Post_Barcode2_Base64 = this.domSanitizer.bypassSecurityTrustUrl(response.Post_Barcode2_Base64);
      this.Post_Barcode3_Base64 = this.domSanitizer.bypassSecurityTrustUrl(response.Post_Barcode3_Base64);

      this.barcodeRef = this.modalService.show(template, modalConfig);
    })

  }


}
