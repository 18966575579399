import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetASMList } from 'src/app/_Models/TCHWEB/TCH0111/getASMList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0111',
    templateUrl: './TCH0111.component.html',
    styleUrls: ['./TCH0111.component.css']
})
export class TCH0111Component implements OnInit {

    data!: GetASMList[];
    baseUrl = environment.apiUrl;
    Type: string = '';
    model = {
        Years: '',
        Term: '',
        Type: ''
    }
    constructor(private http: HttpClient,
        private router: Router,
        private toastr: ToastrService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
        })
    }

    setModel(model: any) {
        this.model.Years = model.Years;
        this.model.Term = model.Term;
    }

    queryTeachEval() {

        if (this.Type == '') {
            this.toastr.info("請選擇類型");
            return;
        }
        this.model.Type = this.Type;
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_11/Search", this.model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = [];
                return;
            }
            this.data = response;
        })
    }

    viewData(DataKey: string) {
        if (DataKey == null)
            return;
        this.router.navigate(['/TCH01_11', DataKey], { skipLocationChange: true });
    }

}
