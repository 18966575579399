import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetOpClassList } from 'src/app/_Models/getOpClassList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetTeamList } from 'src/app/_Models/TCHWEB/TCH0302/getTeamList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0302',
    templateUrl: './TCH0302.component.html',
    styleUrls: ['./TCH0302.component.css']
})
export class TCH0302Component implements OnInit {
    opClassList!: GetSelectList[];
    cosList!: GetSelectList[];
    teamList!: GetTeamList[];
    baseUrl = environment.apiUrl;
    checkType = new CheckType();
    getOpClassListInput: GetOpClassList = {
        Years: '',
        Term: '',
        DeptNo: '',
        TeamNo: '',
        TchNo: '',
        Classroom: '',
        UserCode: '',
        CHeckRight: ''
    };
    constructor(private http: HttpClient, private toastr: ToastrService,
        private systemService: SystemService, private accountService: AccountService, private timeService: TimeService) { }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.getOpClassListInput.TchNo = response.Code;
            }
        }, error => this.toastr.error(error.error))

        this.timeService.getTimeCntr().subscribe((response) => {
            this.getOpClassListInput.Years = response.Now_Years;
            this.getOpClassListInput.Term = response.Now_Term;
            this.getOpClassList(this.getOpClassListInput)
        }, error => this.toastr.error(error.error))
    }


    getOpClassList(model: GetOpClassList) {
        this.systemService.getOpClassList(model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.opClassList = response;
        }, error => this.toastr.error(error.error));
    }

    getCosList(event: any) {
        let opClass = <HTMLInputElement>event.target;
        this.http.post<any>(this.baseUrl + "Public/List_Cos", {
            Years: this.getOpClassListInput.Years,
            Term: this.getOpClassListInput.Term,
            OpClass: opClass.value
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosList = response;
        }, error => this.toastr.error(error.error));
    }

    getTeamList(dataKey: string) {

        if (dataKey == '') {
            this.toastr.info("請選擇授課科目");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_02/Get_Team_List", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.teamList = []
                return;
            }
            this.teamList = response;
        }, error => this.toastr.error(error.error))
    }


    saveData() {
        let pass = true;
        for (let i = 0; i < this.teamList.length; i++) {
            if (!this.checkType.CheckIsAlphabet(this.teamList[i].TeamNo) && this.teamList[i].TeamNo.length >= 1) {
                pass = false;
                this.toastr.warning(`第${i + 1}筆資料組別必須為A-Z`);
                break;
            }
        }
        if (pass) {
            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_02/Get_Team_Save", this.teamList).subscribe((response) => {
                if (response.info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("儲存成功");
            })
        }
    }

}
