import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HourSalaryModel, Search } from 'src/app/_Models/AD/ADEMP/ADEMP0102/search';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import { GetSelectList } from 'src/app/_Models/getSelectList';

@Component({
    selector: 'app-ADEMP0102',
    templateUrl: './ADEMP0102.component.html',
    styleUrls: ['./ADEMP0102.component.css']
})
export class ADEMP0102Component implements OnInit {
    baseUrl = environment.apiUrl;
    dataList: Search[] = [];
    hourSalaryList!: HourSalaryModel[];
    isEdit: boolean = false;

    data: Search = {
        ShowCode: false,
        Title: '',
        Title_Name: '',
        Title_EngName: '',
        Title_Type: '',
        Tch_Hours: 0,
        Del_Hours: 0,
        ManagerMark: '',
        ManagerLevel:'9',
        HourSalaryList: [],
        Update_User: '',
        Update_Time: '',
        Selected: false
    }


    constructor(private http: HttpClient, private toastr: ToastrService, private systemservice: SystemService) {

    }

    ngOnInit() {
        this.iniTchMClass();
        this.search('');
    }

    iniTchMClass() {
        this.http.get<any>(this.baseUrl + "ACAD/EMP/ADEMP0102/InitHourSalary").subscribe((response) => {
            if (response.Info == false) {
                this.hourSalaryList = [];
            }
            this.hourSalaryList = response;
        });
    }

    search(Title_Type: string) {
        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0102/Search", { Title_Type: Title_Type }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.dataList = []
                return;
            }
            this.dataList = response;

            if (this.dataList != null) {
                this.dataList.forEach(element => {
                    element.Selected = false;
                });
            }

        })
    }

    searchDetail(index: number) {
        if (this.dataList != null) {
            this.dataList.forEach(element => {
                element.Selected = false;
            });
            this.dataList[index].Selected = true;
            // this.detail.setValue(this.dataList[index]);
            this.data = this.dataList[index];
            this.hourSalaryList = this.data.HourSalaryList;
            this.isEdit = true;
        }

    }

    insert() {

        if (this.check_valid()) {
            this.toastr.info("請填寫欄位");
            return;
        }


        this.data.HourSalaryList = this.hourSalaryList;

        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0102/Insert", this.data).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.reset();
            this.search('');
            this.toastr.success("新增成功");
        });
    }

    update() {

        if (this.check_valid()) {
            this.toastr.info("請填寫欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0102/Update", this.data).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.reset();
            this.search('');
            this.toastr.success("修改成功");
        });
    }

    delete() {

        if (this.check_valid()) {
            this.toastr.info("請選擇項目");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0102/Delete", { Title: this.data.Title }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.reset();
            this.search('');
            this.toastr.success("刪除成功");
        });
    }

    reset() {
        this.isEdit = false;
        for (let i = 0; i < this.dataList?.length; i++) {
            this.dataList[i].Selected = false;
        }
        this.data = {
            ShowCode: false,
            Title: '',
            Title_Name: '',
            Title_EngName: '',
            Title_Type: '',
            Tch_Hours: 0,
            Del_Hours: 0,
            ManagerMark: '',
            ManagerLevel:'9',
            HourSalaryList: [],
            Update_User: '',
            Update_Time: '',
            Selected: false
        };
        this.iniTchMClass();
    }

    check_valid(): boolean {
        if (this.data.Title == '') return true;
        if (this.data.Title_Name == '') return true;
        if (this.data.Title_EngName == '') return true;
        if (this.data.Title_Type == '') return true;
        return false;
    }

}
