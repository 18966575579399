import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0310',
    templateUrl: './TCH0310.component.html',
    styleUrls: ['./TCH0310.component.css']
})
export class TCH0310Component implements OnInit {
    baseUrl = environment.apiUrl;
    data!: Get_Cur_List[];
    functionType = 'onlineClass';
    class = ''
    model = {
        Years: '',
        Term: '',
        FormType: '09',
        KeyValue: '',
        WeekIndex: null
    }
    constructor(private http: HttpClient,
        private systemService: SystemService,
        private toastr: ToastrService,
        private accountService: AccountService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.model.KeyValue = response.Code;
            }
        });

        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Term = response.Now_Term;
            this.model.Years = response.Now_Years;
            this.getCurList();
        });

    }

    getCurList() {
        this.http.post<any>(this.baseUrl + "Public/Get_CurForm", this.model)
            .subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning("查無資料");
                    return;
                }
                this.data = response;
            },
                error => this.toastr.error(error.error)
            );
    }

    resfresh() {
        this.getCurList();
    }

}
