import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { getHours } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ToastrService } from 'ngx-toastr';
import { SaveData } from'src/app/_Models/AD/ADABS/ADABS0101/Data';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADASM0101',
    templateUrl: './ADASM0101.component.html',
    styleUrls: ['./ADASM0101.component.css']
})
export class ADASM0101Component implements OnInit {
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

}

