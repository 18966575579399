<div class="container">
    <div class="jumbotron">
        <h1 class="display-4 fw-bolder">{{'教職員變更密碼'|translate}}</h1>
        <div class="form-group">
            <label>{{'請輸入舊密碼'|translate}}</label>
            <input type="password" class="form-control shadow-none" #oldPwd>
        </div>
        <div class="form-group">
            <label>{{'請輸入新密碼'|translate}}</label>
            <input type="password" class="form-control shadow-none" #newPwd>
        </div>
        <div class="form-group">
            <label>{{'請確認新密碼'|translate}}</label>
            <input type="password" class="form-control shadow-none" #confirmNewPwd>
        </div>
        <hr class="my-4">
        <p class="text-danger fw-bolder">{{'為提升資訊安全，密碼設置請遵循以下規範：'|translate}}</p>
        <ul>
            <li class="text-danger fw-bolder" *ngFor="let item of pwdRule">{{item.Rules}}</li>
        </ul>
        <button class="other-Button-gray" (click)="changePassword(oldPwd.value, newPwd.value, confirmNewPwd.value)"
            role="button">{{'確認變更'|translate}}</button>
    </div>
</div>