<div class="container">
    <div class="row">
        <div style="width:500px;">
            <form [formGroup]="ngFormInput">
                <div class="row">
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'學年'|translate}}
                                </span>
                            <select class="form-select shadow-none" #Year formControlName="Years" [(ngModel)]="model.Years">
                                <option value="" selected disabled>{{'--請選擇--'|translate}}
                                </option>
                                <option *ngFor="let item of Years" [value]="item">{{item}}{{'學年'|translate}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'學期'|translate}}
                                </span>
                            <select class="form-select shadow-none" #Term formControlName="Term" [(ngModel)]="model.Term">
                                <option value="" disabled>{{'--請選擇--'|translate}}
                                </option>
                                <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                            </select>
                        </div>
                    </div>                
                </div>
                <div class="row">
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'系所'|translate}}
                                </span>
                            <select class="form-select shadow-none" #DeptNo formControlName="DeptNo"
                                (change)="getClassList('',DeptNo.value);getTeamList(DeptNo.value);">
                                <option value="" selected>{{'--請選擇--'|translate}}
                                </option>
                                <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'組別'|translate}}
                                </span>
                            <select class="form-select shadow-none" #TeamNo formControlName="TeamNo">
                                <option value="" selected>{{'--請選擇--'|translate}}
                                </option>
                                <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}</option>
                            </select>
                        </div>
                    </div>                                       
                </div>  
                <div class="row">                  
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'班級'|translate}}
                                </span>
                            <select class="form-select shadow-none" #Class formControlName="Class">
                                <option value="" selected>{{'--請選擇--'|translate}}
                                </option>
                                <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'學號'|translate}}
                                </span>
                            <input type="text" #StdNoFrom formControlName="StdNoFrom" maxlength="10" style="width:170px;">
                        </div>
                    </div>                        
                </div>                             
                <div class="row">
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'狀態'|translate}}
                                </span>
                            <select class="form-select shadow-none" #Status formControlName="Status">
                                <option value="" selected>{{'全部'|translate}}
                                </option>
                                <option value="1">{{'系辦未確認'|translate}}
                                </option>
                                <option value="2">{{'系辦已確認'|translate}}
                                </option>
                                <option value="3">{{'註冊組未確認/系主任已確認'|translate}}
                                </option>
                                <option value="4">{{'註冊組已確認/學生未確認'|translate}}
                                </option>
                                <option value="5">{{'學生已確認'|translate}}
                                </option>
                            </select>
                        </div>                         
                    </div>                   
                    <div style="width:250px;">
                        <table style="width:250px;height:30px;">
                            <tr>
                                <td style="text-align:center;vertical-align: middle;">
                                    <button class="search-btn me-3 float-end" (click)="getStdList();">{{'查詢'|translate}}
                                    </button>
                                </td>
                            </tr>
                        </table>               
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="row" style="height:340px;overflow-y:scroll;">
                    <table class="table table-hover table-striped text-center" style="width:500px;height:30px;font-size:14px;">
                        <thead class="thead-dark-red">
                            <tr>
                                <th style="width: 140px;">{{'學制'|translate}}
                                </th>
                                <th style="width: 180px;">{{'班級'|translate}}
                                </th>
                                <th style="width: 180px;">{{'學號姓名'|translate}}
                                </th>
                            </tr>   
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of stdList" (click)="getContrastResultData(item);" style="cursor:pointer;">                               
                                <td style="width: 140px;">{{item.EduName}}</td>
                                <td style="width: 180px;">{{item.ClassName}}</td>
                                <td style="width: 180px;">{{item.StdNo}}{{item.StdName}}</td>                        
                            </tr>   
                        </tbody>                                      
                    </table>
                </div>
            </div>
        </div>
        <div style="width:780px;">
            <app-Contrast id="Contrast" *ngFor="let item of stdSelects" [inputstd]="item.DataKey" [stdSelect]="stdSelect" [UserType]="UserType" [thisUserType]="thisUserType" [EnableConfirm]="true"></app-Contrast>
        </div>
    </div>
</div>