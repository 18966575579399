import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0502/search';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0502/searchDetail';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADRGP0502',
  templateUrl: './ADRGP0502.component.html',
  styleUrls: ['./ADRGP0502.component.css']
})
export class ADRGP0502Component implements OnInit {
  mOERestReasonList!: GetSelectList[];
  baseUrl = environment.apiUrl;
  data!: Search[];
  dataDetail: SearchDetail = {
    Rest_No: '',
    Rest_Name: '',
    Rest_Kind: '',
    EduReport_Code: '',
    Update_User: '',
    Update_Time: ''
  }
  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private systemService: SystemService,) { }

  ngOnInit(): void {
    this.getMOERestReason();
    this.search();
  }

  getMOERestReason() {
    this.systemService.getMOERestReason().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.mOERestReasonList = response;

    })
  }

  search() {
    this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0502/Search").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.data = response;
      this.data.forEach(element => {
        element.Selected = false;
      });
    })
  }

  searchDetail(Rest_No: string, index: number) {
    this.data.forEach(element => {
      element.Selected = false;
    });
    this.data[index].Selected = true;
    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0502/Search_Detail", { Rest_No: Rest_No }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.dataDetail = response;
      this.dataDetail.Rest_Name = this.dataDetail.Rest_Name;
      this.dataDetail.Update_Time = this.dataDetail.Update_Time;
      this.dataDetail.Update_User = this.dataDetail.Update_User;
    });
  }

  insert() {
    if (this.dataDetail.Rest_No == '' || this.dataDetail.Rest_Name == '' || this.dataDetail.Rest_Kind == '' || this.dataDetail.EduReport_Code == '') {
      this.toastr.info("請將所有欄位填妥");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0502/Insert", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("新增成功");
      this.search();
    }, error => this.toastr.error(error.error))
  }

  update() {
    if (this.dataDetail.Rest_No == '' || this.dataDetail.Rest_Name == '' || this.dataDetail.Rest_Kind == '' || this.dataDetail.EduReport_Code == '') {
      this.toastr.info("請將所有欄位填妥");
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0502/Update", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("修改成功");
      this.search();
    }, error => this.toastr.error(error.error))
  }

  delete() {
    if (this.dataDetail.Rest_No == '') {
      this.toastr.info("請選擇原因");
      return;
    }


    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0502/Delete", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("刪除成功");
      this.search();
      this.clear();
    }, error => this.toastr.error(error.error))
  }

  clear() {
    this.dataDetail = {
      Rest_No: '',
      Rest_Name: '',
      Rest_Kind: '',
      EduReport_Code: '',
      Update_User: '',
      Update_Time: ''
    }
  }
}
