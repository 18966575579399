<footer class="footer pt-1" id="phone">
    <div class="container">
        <div class="row">
            <div class="col text-center p-0" id="favor" *ngIf="useFavorite">
                <a routerLink="Favourite">
                    <div><span class="icon-link icon-My-favorite"></span></div>
                    <div style="font-size: 12px;" class="fw-bolder">我的最愛</div>
                </a>
            </div>
            <div class="col text-center p-0" id="home">
                <a routerLink="/Home">
                    <div><span class="icon-link icon-Home"></span></div>
                    <div style="font-size: 12px;" class="fw-bolder">首頁</div>
                </a>
            </div>
            <div class="col text-center p-0" id="profile">
                <a routerLink="/profile">
                    <div><span class="icon-link icon-Profile"></span></div>
                    <div style="font-size: 12px;" class="fw-bolder">個人檔案</div>
                </a>
            </div>
        </div>
    </div>
</footer>
