<div class="container">
    <div class="row">
        <div>
            <tabset>
                <tab heading="審查結果" *ngIf="UserType.UserType!='PE' && UserType.UserType!='PEMA'">
                    <div style="height:530px; overflow-y: scroll;">
                        <table style="font-family:微軟正黑體;font-size:14px;">
                            <tr *ngFor="let item of resultDatas">
                                <td>
                                    <table>
                                        <tr>
                                            <td colspan="5" style="height:40px;">
                                                <p style="margin:3px;">
                                                    學號：{{stdSelect.StdNo}}&nbsp;&nbsp;&nbsp;
                                                    姓名：{{stdSelect.StdName}}&nbsp;&nbsp;&nbsp;
                                                    系所：{{stdSelect.DeptName}}&nbsp;&nbsp;&nbsp;
                                                    四年計畫表依據年度：{{stdSelect.ContrastYear}}
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                    <alert type="danger">
                                        <table>
                                            <tr>
                                                <td style="width:100px;"><strong>系所規定</strong></td>
                                                <td style="width:210px;">畢業總學分數：<label>{{item.GradCredits}}</label></td>
                                                <td style="width:170px;">必修學分數：<label>{{item.MustCredits}}</label></td>
                                                <td style="width:170px;">選修學分數：<label>{{item.SelCredits}}</label></td>
                                                <td style="width:170px;">通識學分數：<label>{{item.GenCredits}}</label></td>
                                            </tr>
                                        </table>
                                    </alert>
                                    <table>
                                        <tr>
                                            <td style="width:80px;text-align:right;vertical-align: top;"><p style="margin:3px;"><strong>系所內規</strong></p></td>
                                            <td><p style="margin:3px;">{{item.GradMemo}}</p></td>
                                        </tr>
                                    </table>
                                    <table>   
                                        <tr>
                                            <td style="width:80px;text-align:right;"><p style="margin:3px;"><strong>本系必修</strong></p></td>
                                            <td style="width:115px;text-align:center;"><p style="margin:3px;">{{item.DeptMustCredits}}學分</p></td>
                                            <td style="width:80px;text-align:center;"></td>
                                            <td style="width:115px;text-align:center;"></td>
                                            <td style="width:80px;text-align:center;"><p style="margin:3px;color:firebrick;"><strong>合計必修</strong></p></td>
                                            <td style="width:160px;text-align:center;"><p style="margin:3px;">{{item.DeptMustCredits}}學分<label *ngIf="item.LackDeptMustCredits!=''">(尚缺{{item.LackDeptMustCredits}}學分)</label></p></td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:right;"><p style="margin:3px;"><strong>本系選修</strong></p></td>
                                            <td style="text-align:center;"><p style="margin:3px;">{{item.DeptSelCredits}}學分</p></td>
                                            <td style="text-align:center;"><p style="margin:3px;"><strong>外系選修</strong></p></td>
                                            <td style="text-align:center;"><p style="margin:3px;">{{item.OtherDeptSelCredits}}學分</p></td>
                                            <td style="text-align:center;"><p style="margin:3px;color:firebrick;"><strong>合計選修</strong></p></td>
                                            <td style="text-align:center;"><p style="margin:3px;">{{item.GetDeptSelCredits}}學分<label *ngIf="item.LackDeptSelCredits!=''">(尚缺{{item.LackDeptSelCredits}}學分)</label></p></td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:right;"><p style="margin:3px;"><strong>通識</strong></p></td>
                                            <td style="text-align:center;"><p style="margin:3px;">{{item.DeptGenCredits}}學分</p></td>
                                            <td style="text-align:center;"></td>
                                            <td style="text-align:center;"></td>
                                            <td style="text-align:center;"><p style="margin:3px;color:firebrick;"><strong>合計通識</strong></p></td>
                                            <td style="text-align:center;"><p style="margin:3px;">{{item.GetDeptGenCredits}}學分<label *ngIf="item.LackDeptGenCredits!=''">(尚缺{{item.LackDeptGenCredits}}學分)</label></p></td>
                                        </tr>                                                                                                                                                                                
                                    </table>
                                    <table style="font-size:13px;">                                      
                                        <tr>
                                            <td colspan="6"><p style="margin:3px;">一、畢業學分</p></td>
                                        </tr>
                                        <tr>
                                            <td style="width:20px;text-align:center;">
                                                <input type="radio" disabled name="PassGradCredit" [checked]="item.PassGradCredit" />
                                            </td>
                                            <td colspan="5">已修滿本系畢業總學分及科目。</td>
                                        </tr>
                                        <tr>
                                            <td style="width:15px;text-align:center;">
                                                <input type="radio" disabled name="PassGradCredit" [checked]="!item.PassGradCredit" />
                                            </td>
                                            <td>
                                                不符合本系畢業資格，
                                                尚缺必修<input type="text" readonly style="width:40px;text-align:center;" [value]="item.LackDeptMustCredits" />學分，
                                                選修<input type="text" readonly style="width:40px;text-align:center;" [value]="item.LackDeptSelCredits" />學分，
                                                通識<input type="text" readonly style="width:40px;text-align:center;" [value]="item.LackDeptGenCredits" />學分，
                                            </td>
                                            <td style="width:15px;text-align:center;">    
                                                <input type="checkbox" disabled [checked]="!item.UnPassService1" />
                                            </td>
                                            <td>服務教育(一)</td>
                                            <td style="width:15px;text-align:center;">    
                                                <input type="checkbox" disabled [checked]="!item.UnPassService2" />
                                            </td>
                                            <td>服務教育(二)。</td>                                        
                                        </tr>                                    
                                    </table>
                                    <table style="font-size:13px;">
                                        <tr>
                                            <td colspan="12"><p style="margin:3px;">二、畢業門檻(須以成績單已有顯示者)</p></td>
                                        </tr>
                                        <tr>
                                            <td style="width:20px;text-align:center;">
                                                <input type="radio" disabled name="PassGradThreShold" [checked]="item.PassGradThreShold" />
                                            </td>
                                            <td colspan="11">已修滿本系畢業門檻。</td>
                                        </tr>
                                        <tr>
                                            <td style="width:15px;text-align:center;">
                                                <input type="radio" disabled name="PassGradThreShold" [checked]="!item.PassGradThreShold" />
                                            </td>
                                            <td>
                                                未修滿本系畢業門檻，尚缺
                                            </td>
                                            <td style="width:20px;text-align:center;">    
                                                <input type="checkbox" disabled [checked]="item.UnPassThreSholdCourse1" />
                                            </td>
                                            <td>英語能力</td>
                                            <td style="width:20px;text-align:center;">    
                                                <input type="checkbox" disabled [checked]="item.UnPassThreSholdCourse2" />
                                            </td>
                                            <td>資訊能力</td>
                                            <td style="width:20px;text-align:center;">    
                                                <input type="checkbox" disabled [checked]="item.UnPassThreSholdCourse3" />
                                            </td>
                                            <td>專業證照</td>
                                            <td style="width:20px;text-align:center;">    
                                                <input type="checkbox" disabled [checked]="item.UnPassThreSholdCourse4" />
                                            </td>
                                            <td>國際研習</td>
                                            <td style="width:20px;text-align:center;">    
                                                <input type="checkbox" disabled [checked]="item.UnPassThreSholdCourse5" />
                                            </td>
                                            <td>他院共同基礎核心。</td>                                                                                
                                        </tr>                                       
                                    </table>
                                    <table style="font-size:13px;">
                                        <tr>
                                            <td><p style="margin:3px;">三、體育課</p></td>
                                            <td>
                                                <p style="margin:3px;">應修科目數：{{item.PEMustPass}}&nbsp;已修科目數：{{item.PEPass}}</p>
                                            </td>
                                        </tr>                                          
                                    </table>
                                    <table>
                                        <tr>
                                            <td><p style="margin:3px;">如有任何說明請在以下欄位內備註說明，確認送出後不可再修改。</p></td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <p style="margin:3px;">系所承辦人審核說明<label>　(確認日期:{{item.CheckDateDept}})</label></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <textarea [disabled]="(item.UserType=='Dept' && ((item.Status=='1' && item.CheckDateDept=='') || item.Status=='B'))?false:true" #CheckMemoDept [(ngModel)]="item.CheckMemoDept" style="width:700px;height:90px;"></textarea>                                                                                                             
                                            </td>
                                        </tr>                                        
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <p style="margin:3px;">系主任/所長審核說明<label>　(確認日期:{{item.CheckDateDeptMaster}})</label></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <textarea [disabled]="(item.UserType=='DeptMA' && item.CheckDateDeptMaster=='' && item.Status=='2')?false:true" #CheckMemoDeptMaster [(ngModel)]="item.CheckMemoDeptMaster" style="width:700px;height:90px;"></textarea>                                                                                                            
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <p style="margin:3px;">體育室承辦人審核說明<label>　(確認日期:{{item.CheckDatePE}})</label></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <textarea [disabled]="true" #CheckMemoPE [(ngModel)]="item.CheckMemoPE" style="width:700px;height:90px;"></textarea>                                                                                                             
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <p style="margin:3px;">體育室主任審核說明<label>　(確認日期:{{item.CheckDatePEMaster}})</label></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <textarea [disabled]="true" #CheckMemoPEMaster [(ngModel)]="item.CheckMemoPEMaster" style="width:700px;height:90px;"></textarea>                                                                                                             
                                            </td>
                                        </tr>                                                                                                                        
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <p style="margin:3px;">註冊組審核說明<label>　(確認日期:{{item.CheckDateRegi}})</label></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <textarea [disabled]="(item.UserType=='Regi' && item.Status=='3')?false:true" #CheckMemoRegi [(ngModel)]="item.CheckMemoRegi" style="width:700px;height:90px;"></textarea>                                                                                                               
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <p style="margin:3px;"><label>學生確認日期:{{item.CheckDateStd}}</label></p>
                                            </td>
                                        </tr>                                        
                                        <tr>
                                            <td style="height:40px;text-align:center;">
                                                <button class="other-Button-gray me-3" *ngIf="EnableConfirm && ((item.Status=='4' && UserType.UserType=='Std') || ((item.Status=='1' || item.Status=='B') && ((UserType.UserType=='Dept' && item.CheckDateDept==''))) || (item.Status=='2' && ((item.CheckDateDeptMaster=='' && UserType.UserType=='DeptMA'))) || (item.Status=='3' && UserType.UserType=='Regi'))" (click)="doSend(item,false)">確認送出</button>
                                                <button class="other-Button-gray me-3" *ngIf="(item.Status=='2' && item.CheckDateDeptMaster=='' && UserType.UserType=='DeptMA') || (item.Status=='3' && UserType.UserType=='Regi')" (click)="doSend(item,true)">退回</button>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </tab>               
                <tab heading="已承認非四年計劃表必修課程" *ngIf="UserType.UserType!='PE' && UserType.UserType!='PEMA'">
                    <div class="row" style="width:760px;height:530px; overflow-y: scroll;">
                        <table class="table table-hover table-striped text-center" style="width:760px;height:30px;">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th style="width: 220px;text-align:left;">課程名稱</th>
                                    <th style="width: 50px;text-align:center;">學分</th>
                                    <th style="width: 50px;text-align:center;">成績</th>
                                    <th style="width: 440px;text-align:left;" colspan="4">承認科目</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of CosList08">
                                    <td style="width: 220px;text-align:left;vertical-align: middle;">{{item.CosID5}}{{item.CosName}}</td>
                                    <td style="width: 50px;text-align:center;vertical-align: middle;">{{item.CosCredit}}</td>
                                    <td style="width: 50px;text-align:center;vertical-align: middle;">{{item.Score}}</td>
                                    <td style="width: 50px;text-align:center;vertical-align: middle;">
                                        <input type="text" #AgreeCredit style="width:46px;text-align:center;" [disabled]="item.UserType=='Dept'?false:true" [value]="item.AgreeCredit" [(ngModel)]="item.AgreeCredit" />
                                    </td>
                                    <td style="width: 50px;text-align:center;vertical-align: middle;">學分</td>
                                    <td style="width: 280px;text-align:center;vertical-align: middle;">
                                        <select class="form-select shadow-none" #AgreeCosID
                                            [value]="item.AgreeCosID"
                                            [(ngModel)]="item.AgreeCosID"
                                            [disabled]="item.UserType=='Dept'?false:true">                                          
                                            <option value="" selected>--請選擇--</option>
                                            <option *ngFor="let item of PlanCosList" [value]="item.ItemValue">{{item.ItemName}}</option>
                                        </select>
                                    </td>
                                    <td style="width: 60px;text-align:center;vertical-align: middle;">
                                        <button class="other-Button-gray_SS" *ngIf="(stdSelect.Status=='1' || stdSelect.Status=='B') && item.UserType=='Dept'" (click)="ChangeAgree(item)">儲存</button>      
                                    </td>
                                </tr>   
                            </tbody>                                      
                        </table>                       
                    </div>
                </tab>                    
                <tab heading="已修習課程" *ngIf="UserType.UserType!='PE' && UserType.UserType!='PEMA'">
                    <div class="row" style="width:760px;height:530px; overflow-y: scroll;font-size:13px;">
                        <div class="col-lg-6">
                            <div style="width:370px;">
                                <table class="table table-hover table-striped text-center" style="width:370px;">
                                    <thead class="thead-dark-red">
                                        <tr>
                                            <th style="width: 250px;text-align:left;">科目名稱</th>
                                            <th style="width: 60px;text-align:center;">學分</th>
                                            <th style="width: 60px;text-align:center;">成績</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="3">必修</td>
                                        </tr>
                                        <tr *ngFor="let item of CosList01">
                                            <td style="width: 250px;text-align:left;">{{item.CosEduNo}}{{item.CosID5}}{{item.CosName}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.CosCredit}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.Score}}</td>                        
                                        </tr>   
                                    </tbody>                                      
                                </table>  
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div style="width:370px;">
                                <table class="table table-hover table-striped text-center" style="width:370px;">
                                    <thead class="thead-dark-red">
                                        <tr>
                                            <th style="width: 250px;text-align:left;">科目名稱</th>
                                            <th style="width: 60px;text-align:center;">學分</th>
                                            <th style="width: 60px;text-align:center;">成績</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="3">選修</td>
                                        </tr>
                                        <tr *ngFor="let item of CosList02">
                                            <td style="width: 250px;text-align:left;">{{item.CosEduNo}}{{item.CosID5}}{{item.CosName}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.CosCredit}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.Score}}</td>                        
                                        </tr>
                                        <tr>
                                            <td colspan="3">外系承認選修</td>
                                        </tr>
                                        <tr *ngFor="let item of CosList03">
                                            <td style="width: 250px;text-align:left;">{{item.CosEduNo}}{{item.CosID5}}{{item.CosName}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.CosCredit}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.Score}}</td>                        
                                        </tr>
                                        <tr>
                                            <td colspan="3">通識</td>
                                        </tr>
                                        <tr *ngFor="let item of CosList04">
                                            <td style="width: 250px;text-align:left;">{{item.CosEduNo}}{{item.CosID5}}{{item.CosName}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.CosCredit}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.Score}}</td>                        
                                        </tr>
                                        <tr>
                                            <td colspan="3">體育課</td>
                                        </tr>
                                        <tr *ngFor="let item of CosListPE">
                                            <td style="width: 250px;text-align:left;">{{item.CosID}}{{item.CosName}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.CosCredit}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.Score}}</td>                        
                                        </tr>                                          
                                        <tr>
                                            <td colspan="3">已修不及格科目</td>
                                        </tr>
                                        <tr *ngFor="let item of CosList05">
                                            <td style="width: 250px;text-align:left;">{{item.CosEduNo}}{{item.CosID5}}{{item.CosName}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.CosCredit}}</td>
                                            <td style="width: 60px;text-align:center;">{{item.Score}}</td>                        
                                        </tr>                                                                                                                             
                                    </tbody>                                      
                                </table>  
                            </div>
                        </div>                                               
                    </div>
                </tab>
                <tab heading="尚未修習課程" *ngIf="UserType.UserType!='PE' && UserType.UserType!='PEMA'">
                    <div class="row" style="width:760px;height:530px; overflow-y: scroll;font-size:13px;">
                        <table class="table table-hover table-striped text-center" style="width:760px;">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th style="width: 700px;text-align:left;">科目名稱</th>
                                    <th style="width: 60px;text-align:center;">學分</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of CosList06">
                                    <td style="width: 700px;text-align:left;">{{item.CosEduNo}}{{item.CosID5}}{{item.CosName}}</td>
                                    <td style="width: 60px;text-align:center;">{{item.CosCredit}}</td>                   
                                </tr>   
                            </tbody>                                      
                        </table>                  
                    </div>                        
                </tab>
                <tab heading="專業證照區" *ngIf="UserType.UserType!='PE' && UserType.UserType!='PEMA'">
                    <div class="row" style="width:760px;height:530px; overflow-y: scroll;font-size:13px;">
                        <table class="table table-hover table-striped text-center" style="width:760px;height:30px;">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th style="width: 300px;text-align:left;">課程名稱</th>
                                    <th style="width: 60px;text-align:center;">學分</th>
                                    <th style="width: 60px;text-align:center;">成績</th>
                                    <th style="width: 400px;text-align:left;">備註</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of CosList07">
                                    <td style="width: 300px;text-align:left;">{{item.CosID5}}{{item.CosName}}</td>
                                    <td style="width: 60px;text-align:center;">{{item.CosCredit}}</td>
                                    <td style="width: 60px;text-align:center;">{{item.Score}}</td>
                                    <td style="width: 400px;text-align:left;">{{item.Memo}}</td>
                                </tr>   
                            </tbody>                                      
                        </table>                  
                    </div> 
                </tab>
                <tab heading="體育課" *ngIf="UserType.UserType=='PE' || UserType.UserType=='PEMA'">
                    <div class="row" style="width:760px;height:240px; overflow-y: scroll;font-size:13px;">
                        <table class="table table-hover table-striped text-center" style="width:760px;height:30px;">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th style="width: 400px;text-align:left;">課程名稱</th>
                                    <th style="width: 60px;text-align:center;">學分</th>
                                    <th style="width: 60px;text-align:center;">成績</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of CosListPE">
                                    <td style="width: 400px;text-align:left;">{{item.CosID5}}{{item.CosName}}</td>
                                    <td style="width: 60px;text-align:center;">{{item.CosCredit}}</td>
                                    <td style="width: 60px;text-align:center;">{{item.Score}}</td>
                                </tr>   
                            </tbody>                                      
                        </table>                  
                    </div>
                    <div class="row">
                        <table style="font-family:微軟正黑體;font-size:14px;">
                            <tr *ngFor="let item of resultDatas">
                                <td>
                                    <table>
                                        <tr>
                                            <td style="height:24px;">
                                                <p style="margin:3px;">應修體育科目數：{{item.PEMustPass}}&nbsp;已修體育科目數：{{item.PEPass}}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="margin:3px;">如有任何說明請在以下欄位內備註說明，確認送出後不可再修改。</p></td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <p style="margin:3px;">承辦人審核說明<label>　(確認日期:{{item.CheckDatePE}})</label></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <textarea [disabled]="(item.UserType=='PE' && (item.Status=='1' || (item.Status=='2' && item.CheckDatePE==null)))?false:true" #CheckMemoPE [(ngModel)]="item.CheckMemoPE" style="width:760px;height:90px;"></textarea>                                                                                                             
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <p style="margin:3px;">主任審核說明<label>　(確認日期:{{item.CheckDatePEMaster}})</label></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:微軟正黑體;font-size:13px;">
                                                <textarea [disabled]="(item.UserType=='PEMA' && ((item.Status=='2' && item.CheckDatePEMaster=='')))?false:true" #CheckMemoPEMaster [(ngModel)]="item.CheckMemoPEMaster" style="width:760px;height:90px;"></textarea>                                                                                                             
                                            </td>
                                        </tr>                                          
                                        <tr>
                                            <td style="height:40px;text-align:center;">
                                                <button class="other-Button-gray me-3" *ngIf="EnableConfirm && (((item.Status=='1' || (item.Status=='2' && item.CheckDatePE=='')) && item.UserType=='PE') || (item.Status=='2' && item.UserType=='PEMA' && item.CheckDatePEMaster==''))" (click)="doSend(item,false)">確認送出</button>
                                            </td>
                                        </tr>
                                    </table>  
                                </td>
                            </tr>                           
                        </table>                      
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
</div>