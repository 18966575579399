import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import { AnswerTypeData } from'src/app/_Models/AD/ADASM/ADASM0103/AnswerTypedata';
import { QuestionData } from'src/app/_Models/AD/ADASM/ADASM0103/Questiondata';
import { StyleData } from'src/app/_Models/AD/ADASM/ADASM0103/Styledata';
import { SubjectData } from'src/app/_Models/AD/ADASM/ADASM0103/Subjectdata';
import { Data } from'src/app/_Models/AD/ADASM/ADASM0102/data';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
@Component({
    selector: 'app-ADASM0103',
    templateUrl: './ADASM0103.component.html',
    styleUrls: ['./ADASM0103.component.css']
})
export class ADASM0103Component implements OnInit {
    url = environment.apiUrl;
    data!:Data[];
    term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  model = {
    Years: '',
    Term: '',
    SelStyle:''
  }

  timemodel = {
    Now_Years: '',
    Now_Term: '',
    Asm_Years:'',
    Asm_Term:''
  }

    styledata!:StyleData[];
    modalRef?: BsModalRef;
    sel!: string;
    styledatadetail:StyleData={
        Years: '',
        Term: '',
        Style_No: '',
        Style_Name: '',
        Answer_Kind: '',
        Avg_Log: '',
        Order_Type: '',
        Update_User: '',
        Update_Time: '',
        SelStyle: '',
        checked: false
    }
    subjectdata!:SubjectData[];
    subjectdatadetail:SubjectData={
        Years: '',
        Term: '',
        Style_No: '',
        Subject_Name: '',
        Subject_Print: '',
        Subject_No: '',
        SelStyle: '',
        Update_User: '',
        Update_Time: '',
        Style_Name: '',
        checked: false
    }
    questiondata!:QuestionData[];
    questiondatadetail:QuestionData={
        Year: '',
        Term: '',
        Question_No: '',
        Question_Name: '',
        Style_No: '',
        Subject_No: '',
        Answer_Kind: '',
        Answer_Type: '',
        Update_User: '',
        Update_Time: '',
        SelStyle: '',
        Style_Name: '',
        Subject_Name: '',
        checked: false,
        Subject_Print: ''
    }
    answertypedata!:AnswerTypeData[];
    initialData = new InitialData(this.http);
    answertypedatadetail:AnswerTypeData={
        Answer_Type: '',
        Answer_No: '',
        Answer_Name: '',
        Answer_Grade: '',
        Text_Log: '',
        Update_User: '',
        Update_Time: '',
        checked: false
    }
    ngOnInit(): void {
        this.getYears();
       this.questionclass();
       this.searchanswertype();
    }
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,private modalService: BsModalService,) { }

    getYears() {
            this.initialData.getYears().subscribe((response) => {
              if (response) {
                this.Years = response;
              }
            });
          }

    questionclass(){
        this.http.get<any>(this.url+"ACAD/ASM/ADASM0102/ASM0102_DropDownList").subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.data=response;
        })
    }

    inserttime(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_InsertTime",this.timemodel).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
        })
    }

    clear(){
        this.styledatadetail={
            Years: '',
            Term: '',
            Style_No: '',
            Style_Name: '',
            Answer_Kind: '',
            Avg_Log: '',
            Order_Type: '',
            Update_User: '',
            Update_Time: '',
            SelStyle: '',
            checked: false
        }
    }

    openModal(class1:string,i:number,template: TemplateRef<any>) {
         if(class1=='style')
         {
            this.styledatadetail=this.styledata[i];
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };
            this.sel='style';
            this.modalRef = this.modalService.show(template, modalConfig);
         }
         else if(class1=='subject')
         {
            this.subjectdatadetail=this.subjectdata[i];
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };
            this.sel='subject';
            this.modalRef = this.modalService.show(template, modalConfig);
         }
         else if(class1=='question')
         {
            this.questiondatadetail=this.questiondata[i];
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };
            this.sel='question';
            this.modalRef = this.modalService.show(template, modalConfig);
         }
         else if(class1=='answer'){
            this.answertypedatadetail=this.answertypedata[i];
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };
            this.sel='answer';
            this.modalRef = this.modalService.show(template, modalConfig);
         }
         else if(class1=='answeredit'){
            this.answertypedatadetail={
                Answer_Type: '',
                Answer_No: '',
                Answer_Name: '',
                Answer_Grade: '',
                Text_Log: '',
                Update_User: '',
                Update_Time: '',
                checked: false
            }
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };
            this.sel='';
            this.modalRef = this.modalService.show(template, modalConfig);
         }
         else{
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };
            this.sel='';
            this.modalRef = this.modalService.show(template, modalConfig);
         }

    }

    searchstyle(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_SearchStyle",{Years:this.model.Years,Term:this.model.Term,SelStyle:this.model.SelStyle}).subscribe(response=>{
            this.styledata=[];
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.styledata=response;
        })
    }

    insertstyle(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_InsertStyle",this.styledatadetail).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.searchstyle();
        })
    }

    updatestyle(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_UpdateStyle",this.styledatadetail).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.searchstyle();
        })
    }
    changedelete(class1:string,i:number,event: EventTarget | null){
        let input = <HTMLInputElement>event;
        if(input.checked==true && class1 == 'style'){
            this.styledata[i].checked=true;
        }
        else if(input.checked==true && class1 == 'subject'){
            this.subjectdata[i].checked=true;
        }
        else if(input.checked==true && class1 == 'question'){
            this.questiondata[i].checked=true;
        }
        else if(input.checked==true && class1 == 'answer'){
            this.answertypedata[i].checked=true;
        }
        else if(input.checked==false && class1 == 'style'){
            this.styledata[i].checked=false;
        }
        else if(input.checked==false && class1 == 'subject'){
            this.subjectdata[i].checked=false;
        }
        else if(input.checked==false && class1 == 'question'){
            this.questiondata[i].checked=false;
        }
        else if(input.checked==false && class1 == 'answer'){
            this.answertypedata[i].checked=false;
        }
    }
    deletestyle(){
        this.styledata.forEach(m=>{
            if(m.checked==true){
                this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_DeleteStyle",m).subscribe(response=>{
                    if (response.Info == false) {
                        this.toastr.warning(response[0].Message);
                        return;
                    }
                    this.toastr.success(response[0].Message);
                })
            }
        })
        this.searchstyle();
    }

    searchsubject(i:number){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_SearchSubject",this.styledata[i]).subscribe(response=>{
            this.subjectdata=[];
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.subjectdata=response;
            this.subjectdatadetail.Years=this.styledata[i].Years;
            this.subjectdatadetail.Term=this.styledata[i].Term;
            this.subjectdatadetail.SelStyle=this.styledata[i].SelStyle;
            this.subjectdatadetail.Style_No=this.styledata[i].Style_No;
            this.subjectdatadetail.Style_Name=this.styledata[i].Style_Name;
        })
    }

    insertsubject(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_InsertSubject",this.subjectdatadetail).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.searchsubject(0);
        })
    }

    updatesubject(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_UpdateSubject",this.subjectdatadetail).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.searchsubject(0);
        })
    }

    deletesubject(){
        this.subjectdata.forEach(m=>{
            if(m.checked==true){
                this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_DeleteSubject",m).subscribe(response=>{
                    if (response.Info == false) {
                        this.toastr.warning(response[0].Message);
                        return;
                    }
                    this.toastr.success(response[0].Message);
                })
            }
        })
        this.searchsubject(0);
    }

    searchquestion(i:number){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_SearchQuestion",this.subjectdata[i]).subscribe(response=>{
            this.questiondata=[];
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
           this.questiondata=response;
           this.questiondatadetail.Year=this.subjectdatadetail.Years;
           this.questiondatadetail.Term=this.subjectdatadetail.Term;
           this.questiondatadetail.SelStyle=this.subjectdatadetail.SelStyle;
           this.questiondatadetail.Style_No=this.subjectdatadetail.Style_No;
           this.questiondatadetail.Style_Name=this.subjectdatadetail.Style_Name;
           this.questiondatadetail.Subject_Name=this.subjectdata[i].Subject_Name;
           this.questiondatadetail.Subject_No=this.subjectdata[i].Subject_No;
           this.questiondatadetail.Subject_Print=this.subjectdata[i].Subject_No;
        })
    }


    insertquestion(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_InsertQuestion",this.questiondatadetail).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.searchquestion(0);
        })
    }

    updatequestion(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_UpdateQuestion",this.questiondatadetail).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }

            this.toastr.success(response[0].Message);
            this.searchquestion(0);
        })
    }

    setDatakey(class1:string,event: EventTarget | null) {
        let input = <HTMLInputElement>event;
        if(input.checked==true && class1 == 'style'){
            this.styledata.forEach(m=>m.checked=true);
        }
        else if(input.checked==true && class1 == 'subject'){
            this.subjectdata.forEach(m=>m.checked=true);
        }
        else if(input.checked==true && class1 == 'question'){
            this.questiondata.forEach(m=>m.checked=true);
        }
        else if(input.checked==true && class1 == 'answer'){
            this.answertypedata.forEach(m=>m.checked=true);
        }
        if(input.checked==false && class1 == 'style'){
            this.styledata.forEach(m=>m.checked=false);
        }
        else if(input.checked==false && class1 == 'subject'){
            this.subjectdata.forEach(m=>m.checked=false);
        }
        else if(input.checked==false && class1 == 'question'){
            this.questiondata.forEach(m=>m.checked=false);
        }
        else if(input.checked==false && class1 == 'answer'){
            this.answertypedata.forEach(m=>m.checked=false);
        }
    }

    deletequestion(){
        this.questiondata.forEach(m=>{
            if(m.checked==true){
                this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_DeleteQuestion",m).subscribe(response=>{
                    if (response.Info == false) {
                        this.toastr.warning(response[0].Message);
                        return;
                    }
                    this.toastr.success(response[0].Message);
                })
            }
        })
        this.searchquestion(0);
    }

    searchanswertype(){
        this.http.get<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_SearchAnswerType").subscribe(response=>{
            this.answertypedata=[];
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.answertypedata=response;
        })
    }

    insertanswertype(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_InsertAnswerType",this.answertypedatadetail).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.searchanswertype();
        })
    }

    updateanswertype(){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_UpdateAnswerType",this.answertypedatadetail).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.toastr.success(response[0].Message);
            this.searchanswertype();
        })
    }

    deleteanswertype(){
        this.answertypedata.forEach(m=>{
            if(m.checked==true){
                this.http.post<any>(this.url+"ACAD/ASM/ADASM0103/ASM0103_DeleteAnswerType",m).subscribe(response=>{
                    if (response.Info == false) {
                        this.toastr.warning(response[0].Message);
                        return;
                    }
                    this.toastr.success(response[0].Message);
                })
            }
        })
        this.searchanswertype();
    }
}

