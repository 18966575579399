<div class="container">
    <div class="row mt-3">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="Years">學年</span>
                        </div>
                        <select class="form-select shadow-none" name="Years" [(ngModel)]="model.Years">
                            <option value="" selected>--全部--</option>
                            <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="Term">學期</span>
                        </div>
                        <select class="form-select shadow-none" name="Term" [(ngModel)]="model.Term">
                            <option value="" disabled>--全部--</option>
                            <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">問卷類別</span>
                        <select #SelStyle class="form-select shadow-none" [(ngModel)]="model.SelStyle">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let a of data" [value]="a.SelStyle">{{a.SelStyle_Name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button class="search-btn float-start me-3" (click)="searchstyle()">查詢</button>
                </div>
            </div>
            <div class="row">
               <h1>評量類型</h1> 
                <button class="other-Button-gray float-end me-3" (click)="deletestyle()">刪除</button>
                <button class="add-btn float-end me-3" (click)="openModal('',0,templatestyle)">新增</button>
                <label>點選評量類型名稱以查詢評量主題資料<br></label>
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th><input type="checkbox" (click)="setDatakey('style',$event.target)" class="pointer">&ensp;全選</th>
                            <th style="width: 60px;"></th>
                            <th>評量類型名稱</th>
                            <th>答案類型</th>
                            <th>納入平均統計</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of styledata;let i = index" (click)="searchsubject(i)">
                            <td><input type="checkbox" (click)="changedelete('style',i,$event.target)" [(ngModel)]="a.checked"></td>
                            <td #i style="width: 60px;">{{i}}</td>
                            <td>{{a.Style_Name}}</td>
                            <td>{{a.Order_Type}}</td>
                            <td>{{a.Avg_Log}}</td>
                            <td><button class="other-Button-gray float-end me-3" (click)="openModal('style',i,templatestyle)">修改</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <h1>評量主題</h1>
                <button class="other-Button-gray float-end me-3" (click)="deletesubject()">刪除</button>
                <button class="add-btn float-end me-3" (click)="openModal('',0,templatesubject)" >新增</button>
                <label>點選評量主題名稱以查詢問題名稱資料<br></label>
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr >
                            <th><input type="checkbox" (click)="setDatakey('subject',$event.target)" class="pointer">&ensp;全選</th>
                            <th style="width:30px;"></th>
                            <th>評量主題名稱</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of subjectdata;let i = index" (click)="searchquestion(i)">
                            <td><input type="checkbox" (click)="changedelete('subject',i,$event.target)" [(ngModel)]="a.checked"></td>
                            <td #i style="width:30px;">{{i}}</td>
                            <td>{{a.Subject_Name}}</td>
                            <td><button class="other-Button-gray float-end me-3" (click)="openModal('subject',i,templatesubject)">修改</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <h1>問題設定</h1>
                <button class="other-Button-gray float-end me-3" (click)="deletequestion()">刪除</button>
                <button class="add-btn float-end me-3" (click)="openModal('',0,templatequestion)" >新增</button>
            </div>
            <div class="row mt-3">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th><input type="checkbox" (click)="setDatakey('question',$event.target)" class="pointer">&ensp;全選</th>
                            <th style="width: 60px;"></th>
                            <th>問題名稱</th>
                            <th>答案類型</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of questiondata;let i = index">
                            <td><input type="checkbox" (click)="changedelete('question',i,$event.target)" [(ngModel)]="a.checked"></td>
                            <td #i style="width: 60px;">{{i}}</td>
                            <td>{{a.Question_Name}}</td>
                            <td>{{a.Answer_Type}}</td>
                            <td> <button class="other-Button-gray float-end me-3" (click)="openModal('question',i,templatequestion)">修改</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-1">
        </div>
        <div class="col-md-5">
            <div class="row mb-3">
                <button class="other-Button-gray float-end me-3" style="width: 200px;" (click)="openModal('answeredit',0,templateteach)">複製學期題型資料</button>
            </div>
            <div class="row mb-3">
                <button class="other-Button-gray float-end me-3" (click)="deleteanswertype()">刪除</button>
                <button class="add-btn float-end me-3" (click)="openModal('answeredit',0,templateanswer)">新增</button>
            </div>
            <div class="row">
                <label>答案設定<br></label>
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th><input type="checkbox" (click)="setDatakey('answer',$event.target)" class="pointer">&ensp;全選</th>
                            <th style="width: 60px;"></th>
                            <th>類別</th>
                            <th>答案名稱</th>
                            <th>評分</th>
                            <th>意見註記</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of answertypedata;let i = index">
                            <td><input type="checkbox" (click)="changedelete('answer',i,$event.target)" [(ngModel)]="a.checked"></td>
                            <td #i style="width: 60px;">{{i}}</td>
                            <td>{{a.Answer_Type}}</td>
                            <td>{{a.Answer_Name}}</td>
                            <td>{{a.Answer_Grade}}</td>
                            <td>{{a.Text_Log}}</td>
                            <td> <button class="other-Button-gray float-end " (click)="openModal('answer',i,templateanswer)">修改</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<ng-template #templatestyle>
    <div class="modal-header">
        <h4 class="modal-title pull-left">新增評量類別</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="Years">學年</span>
                    </div>
                    <select class="form-select shadow-none" name="Years" [(ngModel)]="styledatadetail.Years">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="Term">學期</span>
                    </div>
                    <select class="form-select shadow-none" name="Term" [(ngModel)]="styledatadetail.Term">
                        <option value="" disabled>--全部--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">科目類別代碼</span>
                    </div>
                    <input type="text"  class="form-control shadow-none pointer" [(ngModel)]="styledatadetail.SelStyle">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">類型代碼</span>
                    </div>
                    <input type="text" class="form-control shadow-none pointer" [(ngModel)]="styledatadetail.Style_No">
                </div>
            </div>
            <div class="col-md-8">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">類型名稱</span>
                    </div>
                    <div class="input-group-prepend">
                        <textarea class="form-control" class="common" name="mytext" rows="6" cols="70" [(ngModel)]="styledatadetail.Style_Name"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">答案類型</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="styledatadetail.Answer_Kind">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="Option">選擇題</option>
                        <option value="Text">自由填寫</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">答案排序</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="styledatadetail.Order_Type">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="0">遞減</option>
                        <option value="1">遞增</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">納入平均統計</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="styledatadetail.Avg_Log">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="0">否</option>
                        <option value="1">是</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">

            </div>
            <div class="col-md-6 mt-3">
                <button class="add-btn mx-auto me-3"  *ngIf="sel==''" (click)="insertstyle()">新增</button>
            </div>
            <div class="col-md-6 mt-3">
                <button class="other-Button-gray mx-auto mt-3" *ngIf="sel!=''" (click)="updatestyle()">修改</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #templatesubject>
    <div class="modal-header">
        <h4 class="modal-title pull-left">新增評量主題</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="subjectdatadetail.Years">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="subjectdatadetail.Term">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">科目類別</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="subjectdatadetail.SelStyle">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">類型代碼</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="subjectdatadetail.Style_No">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">類型名稱</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="subjectdatadetail.Style_Name">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">評量主題代碼</span>
                    </div>
                    <input type="text"  class="form-control shadow-none pointer" [(ngModel)]="subjectdatadetail.Subject_No">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">評量主題名稱</span>
                    </div>
                    <input type="text"  class="form-control shadow-none pointer" [(ngModel)]="subjectdatadetail.Subject_Name">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">主題列印名稱</span>
                    </div>
                    <input type="text"  class="form-control shadow-none pointer" [(ngModel)]="subjectdatadetail.Subject_Print">
                </div>
            </div>
            <div class="col-md-5">

            </div>
            <div class="col-md-6 mt-3">
                <button class="add-btn mx-auto  me-3" *ngIf="sel==''" (click)="insertsubject()">新增</button>
            </div>
            <div class="col-md-6 mt-3">
                <button class="other-Button-gray mx-auto mt-3" *ngIf="sel!=''" (click)="updatesubject()">修改</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #templatequestion>
    <div class="modal-header">
        <h4 class="modal-title pull-left">新增評量問題</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="questiondatadetail.Year">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="questiondatadetail.Term">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">科目類別</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="questiondatadetail.Style_No">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">類型代碼</span>
                    </div>
                    <input type="text" class="form-control shadow-none pointer" [(ngModel)]="questiondatadetail.Style_No">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">類型名稱</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="questiondatadetail.Style_Name">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">評量主題代碼</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="questiondatadetail.Subject_No">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">評量主題名稱</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none pointer" [(ngModel)]="questiondatadetail.Subject_Name">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">問題代碼</span>
                    </div>
                    <input type="text" class="form-control shadow-none pointer" [(ngModel)]="questiondatadetail.Question_No">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">問題名稱</span>
                    </div>
                    <div class="input-group-prepend">
                        <textarea class="form-control" class="common" name="mytext" [(ngModel)]="questiondatadetail.Question_Name"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">答題類型</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="questiondatadetail.Answer_Kind">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="Option">單選題</option>
                        <option value="CheckBox">複選題</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">答案類型</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="questiondatadetail.Answer_Type">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                    </select>
                </div>
            </div>
        </div>
        <div>
            <div class="col-md-5">

            </div>
            <div class="col-md-6 mt-3">
                <button class="add-btn mx-auto  me-3" *ngIf="sel==''" (click)="insertquestion()">新增</button>
            </div>
            <div class="col-md-6 mt-3">
                <button class="other-Button-gray mx-auto mt-3" *ngIf="sel!=''" (click)="updatequestion()">修改</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templateanswer>
    <div class="modal-header">
        <h4 class="modal-title pull-left">新增評量答案</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">答案類別</span>
                    </div>
                    <input type="text"  class="form-control shadow-none pointer" [(ngModel)]="answertypedatadetail.Answer_Type">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">答案代碼</span>
                    </div>
                    <input type="text" class="form-control shadow-none pointer" [(ngModel)]="answertypedatadetail.Answer_No">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">答案名稱</span>
                    </div>
                    <input type="text"  class="form-control shadow-none pointer" [(ngModel)]="answertypedatadetail.Answer_Name">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">評分</span>
                    </div>
                    <input type="text"  class="form-control shadow-none pointer" [(ngModel)]="answertypedatadetail.Answer_Grade">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">填寫問題註記</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="answertypedatadetail.Text_Log">
                        <option value="" selected >--請選擇--</option>
                        <option value="0">是</option>
                        <option value="1">否</option>
                    </select>
                </div>
            </div>
            <div class="col-md-5">

            </div>
            <div class="col-md-6 mt-3">
                <button class="add-btn mx-auto me-3" *ngIf="sel==''" (click)="insertanswertype()">新增</button>
            </div>
            <div class="col-md-6 mt-3">
                <button class="other-Button-gray mx-auto mt-3" *ngIf="sel!=''" (click)="updateanswertype()">修改</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #templateteach>
    <div class="modal-header">
        <h4 class="modal-title pull-left">複製教學評量</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">來源學年</span>
                    </div>
                    <select class="form-select shadow-none" name="Years" [(ngModel)]="timemodel.Asm_Years">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">來源學期</span>
                    </div>
                    <select class="form-select shadow-none" name="Term" [(ngModel)]="timemodel.Asm_Term">
                        <option value="" disabled>--全部--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">目前學年</span>
                    </div>
                    <select class="form-select shadow-none" name="Years" [(ngModel)]="timemodel.Now_Years">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">目前學期</span>
                    </div>
                    <select class="form-select shadow-none" name="Term" [(ngModel)]="timemodel.Now_Term">
                        <option value="" disabled>--全部--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-5">

            </div>
            <div class=" col-md-6 mt-3">
                <button class="other-Button-gray mx-auto mt-3" (click)="inserttime()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>