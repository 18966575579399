<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學年</span>
                        <select class="form-select shadow-none" [(ngModel)]="stdMoeNoSet.Years">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學期</span>
                        <select class="form-select shadow-none" [(ngModel)]="stdMoeNoSet.Term">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdMoeNoSet.EduNo">
                        <option value="" disabled>--請選擇學制--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                    </select>
                    </div>
                </div>
            </div>
            <div class="row">    
                <div class="col-md-4">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">文號類型</span>
                        <select class="form-select shadow-none" #MoeType (change)="getMoeList(MoeType.value)" [(ngModel)]="stdMoeNoSet.MoeNoType">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of moeTypeList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">文號清單</span>
                        <select class="form-select shadow-none" [(ngModel)]="stdMoeNoSet.MoeNo">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of moeNoList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10">
                    <div class="form-check form-check-inline float-end mb-3">                  
                        <input class="form-check-input pointer MarginT8" type="checkbox" id="replace"> 
                        <label class="form-check-label pointer MarginT5" for="replace">覆蓋舊文號</label>                           
                    </div>  
                </div>        
                <div class="col-md-2">
                    <button class="save-btn-gray float-end" (click)="getStdMoeNoSet()">儲存</button>          
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
