import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetStdGradSchNoListInput,GetStdGradSchNoList,GetStdGradSchNoSetADNoInput} from 'src/app/_Models/AD/ADSTD/ADSTD0701ISU/getStdGradSchNo';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSTD0701ISU',
    templateUrl: './ADSTD0701ISU.component.html',
    styleUrls: ['./ADSTD0701ISU.component.css']
})
export class ADSTD0701ISUComponent implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    month = require('src/app/_Models/_SelectInput/month.json').month;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    bsConfig = environment.bsDatePickerConfig;
    dataKeyArray: Array<string> = [];
    initialData = new InitialData(this.http);
    stdGradSchNoSetADNo:GetStdGradSchNoSetADNoInput={
        Years:'',
        Term:'',
        GradDate:'',
        Data:[]
    }

    stdGradSchNoListInput: GetStdGradSchNoListInput = {
        Years: '',
        Term: '',
        GradYear: '',
        EduNo: '',
        DeptNo: '',
        StdNoFrom: '',
        StdNoTo: '',
        Maked: false
    };

    model = {
        GradDate:new Date(),
        ShortYear:''
    }

    stdGradSchNoList!: GetStdGradSchNoList[];
    eduList!: GetEduList[];
    deptList!: GetDeptList[];

    constructor(private http: HttpClient,
        private localeService: BsLocaleService,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private datePipe: DatePipe,
        private systemService: SystemService,) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.getEduList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getStdGradSchNoList() {
        if (this.stdGradSchNoListInput.GradYear == '' && (this.stdGradSchNoListInput.Years == '' || this.stdGradSchNoListInput.Term == '')) {
            this.toastr.info("請選擇畢業學年期");
            return;
        }

        if (this.stdGradSchNoListInput.EduNo == '') {
            this.toastr.info("請選擇學制");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_01_ISU/Get_Std_Grad_SchNo_List", this.stdGradSchNoListInput).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdGradSchNoList = []
                return;
            }
            this.stdGradSchNoList = response;
            this.stdGradSchNoList.forEach(element => {
                element.Checked = false;
            });
        }, error => this.toastr.error(error.error))
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(eduno:string) {
        this.systemService.getDeptList(eduno, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    openModal(template: TemplateRef<any>) {

        let modalConfig!: any;

        modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-lg'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
        this.showYear();
    }

    setAll(iIndex:number,event: EventTarget | null) {
        if (!this.stdGradSchNoList) return;
        let input = <HTMLInputElement>event;

        if(iIndex==-1){
            this.stdGradSchNoList.forEach(element => {
                if(element.SchNo==""){
                    input.checked == true ? element.Checked = true : element.Checked = false;
                }
            })
        }
        else{
            if(this.stdGradSchNoList[iIndex].SchNo==""){
                this.stdGradSchNoList[iIndex].Checked = input.checked;
            }
        }
    }

    getStdGradSchNoSetADNo() {
        let blnAdd=false;

        if(this.stdGradSchNoListInput.Years==null || this.stdGradSchNoListInput.Years==""){
            this.toastr.info("請點選畢業學年");
            this.modalRef.hide();
            return;
        }

        if(this.stdGradSchNoListInput.Term==null || this.stdGradSchNoListInput.Term==""){
            this.toastr.info("請點選畢業學期");
            this.modalRef.hide();
            return;
        }

        if(this.model.GradDate==null){
            this.toastr.info("請點選畢業日期");
            return;
        }

        if(this.stdGradSchNoList==null){
            this.toastr.info("請先選擇學生");
            this.modalRef.hide();
            return;
        }

        this.stdGradSchNoSetADNo.Years=this.stdGradSchNoListInput.Years;
        this.stdGradSchNoSetADNo.Term=this.stdGradSchNoListInput.Term;
        this.stdGradSchNoSetADNo.GradDate=this.datePipe.transform(this.model.GradDate, 'yyyy-MM-dd')!;
        this.stdGradSchNoSetADNo.Data=[];
        this.stdGradSchNoList.forEach(element => {
            if(element.Checked){
                this.stdGradSchNoSetADNo.Data.push(element);
                blnAdd=true;
            }
        })

        if (!blnAdd) {
            this.toastr.info("請先選擇學生");
            this.modalRef.hide();
            return;
        };

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_01_ISU/Get_Std_Grad_SchNo_SetADNo", this.stdGradSchNoSetADNo).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.modalRef.hide();
                return;
            }
            this.toastr.success("畢業證號設定完成");
            this.getStdGradSchNoList();
            this.modalRef.hide();
        })
    }

    showYear(){
        let thisYear=0;
        thisYear=Number(this.datePipe.transform(this.model.GradDate, 'yyyy'))-1911;
        this.model.ShortYear=thisYear?.toString();
    }

    ModelShowYear(event:any){
        let thisYear=0;
        thisYear=Number(this.datePipe.transform(event, 'yyyy'))-1911;
        this.model.ShortYear=thisYear?.toString();
    }
}
