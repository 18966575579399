<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year>
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">製單類別</span>
                </div>
                <select #MakeDocNo class="form-select shadow-none">
                    <option value="" selected>--全部--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of makeDocList">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">狀態</span>
                </div>
                <select #MakeDocNo class="form-select shadow-none">
                    <option value="" selected>--全部--</option>
                    <option value="Y">已繳費</option>
                    <option value="N">未繳費</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">繳費起始日</span>
                </div>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly style="background-color: transparent;">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">繳費結束日</span>
                </div>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly style="background-color: transparent;">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">入帳起始日</span>
                </div>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly style="background-color: transparent;">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">入帳結束日</span>
                </div>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly style="background-color: transparent;">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <button class="search-btn float-start me-3">查詢</button>
            <button class="other-Button-gray float-start me-3">匯出清冊</button>
        </div>
    </div>

    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th>學年</th>
                <th>學期</th>
                <th>班級</th>
                <th>學號</th>
                <th>姓名</th>
                <th>繳費類別</th>
                <th>入帳日期</th>
                <th>繳費管道</th>
                <th>金額</th>
                <th>繳費狀態</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>