import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetStdMoeNoSet } from 'src/app/_Models/AD/ADSTD/ADSTD0207/getStdMoeNoSet';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSTD0207',
    templateUrl: './ADSTD0207.component.html',
    styleUrls: ['./ADSTD0207.component.css']
})
export class ADSTD0207Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    eduList!: GetEduList[];
    moeTypeList!: GetSelectList[];
    moeNoList!: GetSelectList[];
    initialData = new InitialData(this.http);
    stdMoeNoSet: GetStdMoeNoSet = {
        Years: '',
        Term: '',
        EduNo: '',
        MoeNoType: '',
        MoeNo: '',
        Replace: ''
    };
    DNMArkList!: GetSelectList[];
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private localeService: BsLocaleService,) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.getMoeType();
        this.getEduList('','');

    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList(_Years:string,_Term:string) {
        this.systemService.getEduList('', _Years, _Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDNMark() {
        this.systemService.getDNMark().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.DNMArkList = response;
        })
    }

    getMoeType() {
        this.systemService.getMoeType().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.moeTypeList = response;
        }, error => this.toastr.error(error.error))
    }

    getMoeList(_MoeType:string) {
        this.moeNoList = [];
        this.systemService.getMoeNo(_MoeType).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.moeNoList = response;
        }, error => this.toastr.error(error.error))
    }   

    getStdMoeNoSet() {
        let check = <HTMLInputElement>document.getElementById('replace');
        if (check.checked) {
            this.stdMoeNoSet.Replace = 'Y';
        }
        else {
            this.stdMoeNoSet.Replace = 'N';
        }

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_07/Get_Std_MoeNo_Set", this.stdMoeNoSet).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        }, error => {
            this.toastr.error(error.error);
        });
    }

}
