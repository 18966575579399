import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUR0509',
    templateUrl: './ADCUR0509.component.html',
    styleUrls: ['./ADCUR0509.component.css']
})
export class ADCUR0509Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    timeCaseList!: GetSelectList[];

    condition: any = {
        Years: '',
        Term: ''
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService
    ) {

    }

    ngOnInit(): void {
        this.initYear();
    }

    initYear(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    check_validation(): boolean {
        if (this.condition.Years == '') return true;
        if (this.condition.Term == '') return true;
        return false;
    }

    exportReport() {
        if (this.check_validation()) {
            this.toastr.info("欄位不正確");
            return;
        };

        this.fileExchangeServices.ExportFile("ACAD/CUR/ADCUR0509/Export_Report", this.condition)
            .subscribe((response) => {

                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }

                if (response.FileName == '') {
                    this.toastr.warning("查無資料");
                    return;
                }
                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = response.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }, error => this.toastr.error(error));
    }

}
