import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { parseDate } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetStdNewCheckInDateTime } from 'src/app/_Models/AD/ADSTD/ADSTD0102/getStdNewCheckInDateTime';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSTD0102',
    templateUrl: './ADSTD0102.component.html',
    styleUrls: ['./ADSTD0102.component.css']
})
export class ADSTD0102Component implements OnInit {
    initialData = new InitialData(this.http);
    bsConfig = environment.bsDatePickerConfig;
    Years!: Array<string>;
    eduList!: GetEduList[];
    term = require('src/app/_Models/_SelectInput/term.json').term;
    fromTime: Date | undefined = new Date();
    toTime: Date | undefined = new Date();
    fromDate: Date | undefined = new Date();
    toDate: Date | undefined = new Date();
    baseUrl = environment.apiUrl;
    stdNewCheckInDateTime!: GetStdNewCheckInDateTime;
    constructor(private localeService: BsLocaleService,
        private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.getEduList();
        this.fromTime = void 0;
        this.toTime = void 0;
        this.fromDate = void 0;
        this.toDate = void 0;
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        }, error => this.toastr.error(error.error));
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getStdNewCheckInDateTime(eduNo: string) {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_02/Get_Std_New_CheckInDateTime", { EduNo: eduNo }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdNewCheckInDateTime = response;


            // 時間的轉換操作
            if (this.stdNewCheckInDateTime.DateFrom != '') {
                this.fromDate = parseDate(this.stdNewCheckInDateTime.DateFrom)
            }
            else {
                this.fromDate = void 0;
            }

            if (this.stdNewCheckInDateTime.DateTo != '') {
                this.toDate = parseDate(this.stdNewCheckInDateTime.DateTo)
            }
            else {
                this.toDate = void 0;
            }

            if (this.stdNewCheckInDateTime.HrFrom == '' || this.stdNewCheckInDateTime.MinFrom == '') {
                this.fromTime = void 0;
            }
            else {
                const fromtime = new Date();
                fromtime.setHours(parseInt(this.stdNewCheckInDateTime.HrFrom), parseInt(this.stdNewCheckInDateTime.MinFrom));
                this.fromTime = fromtime;
            }

            if (this.stdNewCheckInDateTime.HrTo == '' || this.stdNewCheckInDateTime.MinTo == '') {
                this.fromTime = void 0;
            }
            else {
                const totime = new Date();
                totime.setHours(parseInt(this.stdNewCheckInDateTime.MinFrom), parseInt(this.stdNewCheckInDateTime.MinTo));
                this.toTime = totime;
            }
        })
    }

    getStdNewCheckInDateTimeSave(year: string, eduNo: string) {

        if (this.stdNewCheckInDateTime.InYear == '') {
            this.toastr.info("請設定學年");
            return;
        }

        if (this.stdNewCheckInDateTime.EduNo == '') {
            this.toastr.info("請設定學制");
            return;
        }


        if (!this.fromDate || !this.toDate) {
            this.toastr.info("請設定日期");
            return;
        }

        if (!this.fromTime || !this.toTime) {
            this.toastr.info("請設定時間");
            return;
        }

        if (this.fromDate > this.toDate) {
            this.toastr.info("起始日不可大於節日");
            return;
        }

        // 設定值
        this.stdNewCheckInDateTime.InYear = year;
        this.stdNewCheckInDateTime.EduNo = eduNo;
        this.stdNewCheckInDateTime.DateFrom = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd') || '';
        this.stdNewCheckInDateTime.DateTo = this.datePipe.transform(this.toDate, 'yyyy-MM-dd') || '';
        this.stdNewCheckInDateTime.HrFrom = this.fromTime?.getHours().toString() || '';
        this.stdNewCheckInDateTime.HrTo = this.toTime?.getHours().toString() || '';
        this.stdNewCheckInDateTime.MinFrom = this.fromTime?.getMinutes().toString() || '';
        this.stdNewCheckInDateTime.MinTo = this.toTime?.getMinutes().toString() || '';

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_02/Get_Std_New_CheckInDateTimeSave", this.stdNewCheckInDateTime).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }

}
