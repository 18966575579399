import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';


// 在Angular Ahead-Of-Time (AOT) compiler之前要先進行return，不然production mode編譯的時候會出錯(會寫FUNCTION不支援)。
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            progressBar: true,
            timeOut: 1500
        }),
        NgApexchartsModule,
        NgxSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // 記得要另外寫一個return function
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'zh-tw'
        }),
        ImageCropperModule
    ],
    exports: [
        ToastrModule,
        NgApexchartsModule,
        NgxSpinnerModule,
        TranslateModule,
        ImageCropperModule
    ]
})
export class ThirdPartModule { }
