<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業學年</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSchNoListInput.Years">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業學期</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSchNoListInput.Term">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業年度</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSchNoListInput.GradYear">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業科系</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSchNoListInput.DeptNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdGradSchNoListInput.StdNoFrom">
                    <span class="input-group-text">至</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdGradSchNoListInput.StdNoTo">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdGradSchNoListInput.StdName">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" [hidden]="!stdGradSchNoList"
                (click)="getStdGradSchNoDeleteCancel(stdGradSchNoList[0].Years, stdGradSchNoList[0].Term)">刪除</button>
            <button class="other-Button-gray float-end me-3" [hidden]="!stdGradSchNoList"
                (click)="getStdGradSchNoUndoCancel(stdGradSchNoList[0].Years, stdGradSchNoList[0].Term)">取消註銷</button>
            <button class="other-Button-gray float-end me-3" [hidden]="!stdGradSchNoList"
                (click)="getStdGradSchNoSetCancel(stdGradSchNoList[0].Years, stdGradSchNoList[0].Term)">註銷</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal('ADNo', ADNoTemplate)">畢業證號</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal('STD', setStdTemplate)">畢業學生</button>
            <button class="search-btn float-end me-3" (click)="getStdGradSchNoList()">查詢</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" (click)="setDatakey('all', '', $event.target)">&ensp;全選</th>
                <th>畢業學年</th>
                <th>畢業學期</th>
                <th>畢業年月</th>
                <th>學號</th>
                <th>姓名</th>
                <th>畢業班級</th>
                <th>畢業證書號</th>
                <th>狀態</th>
                <th>畢審通過</th>
                <th>是否註銷</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdGradSchNoList">
                <td><input type="checkbox" [hidden]="item.Edited != 'Y'" [checked]="item.checked"
                        (click)="setDatakey('', item.DataKey, $event.target)"></td>
                <td>{{item.Years}}</td>
                <td>{{item.Term}}</td>
                <td>{{item.GradYear}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.SchNo}}</td>
                <td>{{item.Status}}</td>
                <td>{{item.ContrastPass}}</td>
                <td>{{item.DelMark}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #setStdTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定畢業學生</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業學年</span>
                    <select class="form-select shadow-none" #gYears [(ngModel)]="stdGradSchNoListInput.Years"
                        (change)="reset()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業學期</span>
                    <select class="form-select shadow-none" #gTerm [(ngModel)]="stdGradSchNoListInput.Term"
                        (change)="reset()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業科系</span>
                    <select class="form-select shadow-none" #gDeptNo [(ngModel)]="stdGradSchNoListInput.DeptNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <button class="search-btn mb-3"
                    (click)="getStdGradSchNoUnSetList(gYears.value, gTerm.value, gDeptNo.value)">查詢</button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5">
                <select multiple class="form-control shadow-none" style="height: 300px;" #leftItems>
                    <option value="{{i}}" *ngFor="let item of itemLeft; let i = index">{{item.StdName}}</option>
                </select>
            </div>
            <div class="col-lg-2 text-center mt-3">
                <div class="btn-group">
                    <button class="other-Button-gray mb-3" (click)="setItems('goRightAll', leftItems)"></button><br>
                    <button class="other-Button-gray mb-3" (click)="setItems('goRight', leftItems)"></button><br>
                    <button class="other-Button-gray mb-3" (click)="setItems('goLeft', RightItems)"></button><br>
                    <button class="other-Button-gray mb-3" (click)="setItems('goLeftAll', RightItems)"></button><br>
                </div>
            </div>
            <div class="col-lg-5">
                <select multiple class="form-control shadow-none" style="height: 300px;" #RightItems>
                    <option value="{{i}}" *ngFor="let item of itemRight; let i = index">{{item.StdName}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <button class="save-btn-gray mx-auto mt-3"
                    (click)="getStdGradSchNoUnSetInsert(gYears.value, gTerm.value)">儲存</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #ADNoTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定畢業證號</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業學年</span>
                    <select class="form-select shadow-none" [ngModel]="stdGradSchNoListInput.Years"
                        (ngModel)="stdGradSchNoSetADNo.Years" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業學期</span>
                    <select class="form-select shadow-none" [ngModel]="stdGradSchNoListInput.Term"
                        (ngModel)="stdGradSchNoSetADNo.Term" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業科系</span>
                    <select class="form-select shadow-none" [ngModel]="stdGradSchNoListInput.DeptNo"
                        (ngModel)="stdGradSchNoSetADNo.DeptNo" disabled>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">畢業日期</span>
                    <select class="form-select shadow-none" [ngModel]="stdGradSchNoListInput.Years"
                        (ngModel)="stdGradSchNoSetADNo.GradYear">
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                    <select class="form-select shadow-none" (ngModel)="stdGradSchNoSetADNo.GradMonth">
                        <option *ngFor="let item of month" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                        <span class="input-group-text">月</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="save-btn-gray" (click)="getStdGradSchNoSetADNo()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>
