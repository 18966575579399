<div class="container">
    <alert type="danger">
        <strong>警告！</strong> 學籍畢業設定前，請先確認學生是否已設定畢業流水號與畢業資料，若尚未設定請先於<a class="alert-link" [routerLink]="['/ADSTD07_01']"
            routerLinkActive="router-link-active">畢業流水號管理與維護</a>功能做設定。
    </alert>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業學年</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSetListInput.Years">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業學期</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSetListInput.Term">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業科系</span>
                <select class="form-select shadow-none" [(ngModel)]="stdGradSetListInput.DeptNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdGradSetListInput.StdName">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdGradSetListInput.StdNoFrom">
                    <span class="input-group-text">至</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdGradSetListInput.StdNoTo">
            </div>
        </div>
        <div class="col-md-6">
            <button class="search-btn float-start me-3" (click)="getStdGradSetList()">查詢</button>
            <button class="other-Button-gray float-start" (click)="getStdGradSetGrad()">設定畢業</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" class="pointer" (click)="setDatakey('all', '', $event)">&ensp;全選</th>
                <th>畢業學年</th>
                <th>畢業學期</th>
                <th>學號</th>
                <th>姓名</th>
                <th>畢業班級</th>
                <th>畢業證書號</th>
                <th>學籍狀態</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdGradSetList">
                <td><input type="checkbox" class="pointer" (click)="setDatakey('', item.DataKey, $event)"></td>
                <td>{{item.Years}}</td>
                <td>{{item.Term}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.SchNo}}</td>
                <td>{{item.Status}}</td>
            </tr>
        </tbody>
    </table>
</div>