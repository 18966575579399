<div class="container">
    <app-year-term-input></app-year-term-input>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'週次' | translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="selectedWeek">
                    <option value="" disabled>{{'--請選擇週次--' | translate}}</option>
                    <option *ngFor="let item of week" value="{{item.Cur_Week}}">
                        <ng-container *ngIf="item.ThisWeek == 'Y'">({{'本周' | translate}}) {{item.WeekDate}}
                        </ng-container>
                        <ng-container *ngIf="item.ThisWeek == 'N'">{{item.WeekDate}}</ng-container>
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'教師' | translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="selectedTeacher">
                    <option value="" disabled>{{'--請選擇教師--' | translate}}</option>
                    <option *ngFor="let item of teacherList" value="{{item.ItemValue}}">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <button class="search-btn mb-3" (click)="getCurList()">{{'查詢' | translate}}</button>
    <app-cur-class id="cur-class" [curList]="data" [functionType]="functionType" [week]="selectedWeek">
    </app-cur-class>
    <app-cur-class-rwd id="cur-class-rwd" [curList]="data" [functionType]="functionType" [week]="selectedWeek">
    </app-cur-class-rwd>
</div>