<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year [(ngModel)]="savedata.S_Years">
                    <option value="" disabled selected>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term [(ngModel)]="savedata.S_Terms">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">修改者</span>
                </div>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="savedata.K_PC_EmpID">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
            <button class="save-btn-gray" (click)="save()">儲存</button>
        </div>
    </div>
</div>