<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學制代碼</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Edu_No" maxlength="1"
                    placeholder="至多1碼">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學制名稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Edu_Name">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學制簡稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Edu_Short">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">英文名稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Edu_Name_E">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">證書字軌</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.MoeChar">
            </div>
        </div>        
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">使用狀態</span>
                <select class="form-select" [(ngModel)]="dataDetail.State_Log">
                    <option value="">--請選擇--</option>
                    <option value="1">啟用</option>
                    <option value="0">不啟用</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">修改日期</span>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Update_Time">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">修改者</span>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Update_User">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="clear()">清除畫面</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="add-btn float-end me-3" (click)="insert()">新增</button>
        </div>
    </div>

    <div class="row mt-3">
        <table class="table table-hover table-striped text-center mt-3">
            <thead class="thead-dark-red">
                <tr>
                    <th></th>
                    <th>學制代碼</th>
                    <th>學制名稱</th>
                    <th>學制簡稱</th>
                    <th>使用狀態</th>
                </tr>
            </thead>
            <tbody>
                <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                    *ngFor="let item of data; let i = index" class="pointer" (click)="detail(i)">
                    <td>{{i + 1}}</td>
                    <td>{{item.Edu_No}}</td>
                    <td>{{item.Edu_Name}}</td>
                    <td>{{item.Edu_Short}}</td>
                    <td>{{item.State_Log == '1'? '啟用' : '未啟用'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>