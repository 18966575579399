<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year
                    (change)="getEduList(Year.value, Term.value); getGRDTimeList(Year.value, Term.value, tabIndex);">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term
                    (change)="getEduList(Year.value, Term.value); getGRDTimeList(Year.value, Term.value, tabIndex);">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>

    <div>
        <tabset>
            <tab heading="全校" (selectTab)="getGRDTimeList(Year.value, Term.value, 0)">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">開放時間</span>
                            <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                                [bsConfig]="bsConfig" readonly required [(ngModel)]="withOtherData.StartDate"
                                style="background-color: transparent;">
                            <timepicker [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="fromTime">
                            </timepicker>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">結束時間</span>
                            <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                                [bsConfig]="bsConfig" readonly required [(ngModel)]="withOtherData.EndDate"
                                style="background-color: transparent;">
                            <timepicker [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="toTime">
                            </timepicker>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <button class="save-btn-gray mx-auto"
                        (click)="getGRDTimeSave(Year.value, Term.value, 'All')">儲存</button>
                </div>
            </tab>
            <tab heading="依學制" (selectTab)="getGRDTimeList(Year.value, Term.value, 1)">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <table class="table table-hover table-striped text-center">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th style="width: 60px;"></th>
                                    <th>學制</th>
                                    <th>班別</th>
                                    <th>開始時間</th>
                                    <th>結束時間</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="withOtherDataList">
                                <tr *ngFor="let item of withOtherDataList; let i = index" (click)="getData(i)" style="cursor:pointer;">
                                    <th style="width: 60px;">{{i + 1}}</th>
                                    <td>{{item.EduName}}</td>
                                    <td>{{item.ClassType}}</td>
                                    <td>{{item.StartDateTime}}</td>
                                    <td>{{item.EndDateTime}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">學制</span>
                                    </div>
                                    <select class="form-select shadow-none" [(ngModel)]="withOtherData.EduNo">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                                            {{item.Edu_Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">班級</span>
                                    </div>
                                    <select class="form-select shadow-none" #ClassType
                                        [(ngModel)]="withOtherData.ClassTypeID">
                                        <option value="C">非畢業班</option>
                                        <option value="G">畢業班</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">開放時間</span>
                                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                        bsDatepicker [bsConfig]="bsConfig" readonly required
                                        [(ngModel)]="withOtherData.StartDate"
                                        style="background-color: transparent;">
                                    <timepicker [showMeridian]="true" [showSpinners]="false" required
                                        [(ngModel)]="fromTime">
                                    </timepicker>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">結束時間</span>
                                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                        bsDatepicker [bsConfig]="bsConfig" readonly required
                                        [(ngModel)]="withOtherData.EndDate" style="background-color: transparent;">
                                    <timepicker [showMeridian]="true" [showSpinners]="false" required
                                        [(ngModel)]="toTime">
                                    </timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button class="other-Button-gray float-end" (click)="clearData()">清除畫面</button>
                                <button class="other-Button-gray float-end me-3" (click)="deleteData()">刪除</button>
                                <button class="add-btn float-end me-3"
                                    (click)="getGRDTimeSave(Year.value, Term.value, 'Edu')">儲存</button>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="依年級" (selectTab)="getGRDTimeList(Year.value, Term.value, 2)">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <table class="table table-hover table-striped text-center">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th style="width: 60px;"></th>
                                    <th>學制</th>
                                    <th>年級</th>
                                    <th>開始時間</th>
                                    <th>結束時間</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="withOtherDataList">
                                <tr *ngFor="let item of withOtherDataList; let i = index" (click)="getData(i)" style="cursor:pointer;">
                                    <th style="width: 60px;">{{i + 1}}</th>
                                    <td>{{item.EduName}}</td>
                                    <td>{{item.Grade}}</td>
                                    <td>{{item.StartDateTime}}</td>
                                    <td>{{item.EndDateTime}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">學制</span>
                                    </div>
                                    <select class="form-select shadow-none" [(ngModel)]="withOtherData.EduNo">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                                            {{item.Edu_Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">年級</span>
                                    </div>
                                    <select class="form-select shadow-none" [(ngModel)]="withOtherData.Grade">
                                        <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">開放時間</span>
                                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                        bsDatepicker [bsConfig]="bsConfig" readonly required
                                        [(ngModel)]="withOtherData.StartDate"
                                        style="background-color: transparent;">
                                    <timepicker [showMeridian]="true" [showSpinners]="false" required
                                        [(ngModel)]="fromTime">
                                    </timepicker>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">結束時間</span>
                                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                        bsDatepicker [bsConfig]="bsConfig" readonly required
                                        [(ngModel)]="withOtherData.EndDate" style="background-color: transparent;">
                                    <timepicker [showMeridian]="true" [showSpinners]="false" required
                                        [(ngModel)]="toTime">
                                    </timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button class="other-Button-gray float-end" (click)="clearData()">清除畫面</button>
                                <button class="other-Button-gray float-end me-3" (click)="deleteData()">刪除</button>
                                <button class="add-btn float-end me-3"
                                    (click)="getGRDTimeSave(Year.value, Term.value, 'Grade')">儲存</button>
                            </div>                            
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="依班級" (selectTab)="getGRDTimeList(Year.value, Term.value, 3)">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <table class="table table-hover table-striped text-center">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th style="width: 60px;"></th>
                                    <th>班級</th>
                                    <th>開始時間</th>
                                    <th>結束時間</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="withOtherDataList">
                                <tr *ngFor="let item of withOtherDataList; let i = index" (click)="getData(i)" style="cursor:pointer;">
                                    <th style="width: 60px;">{{i + 1}}</th>
                                    <td>{{item.ClassName}}</td>
                                    <td>{{item.StartDateTime}}</td>
                                    <td>{{item.EndDateTime}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">學制</span>
                                    </div>
                                    <select class="form-select shadow-none" #edu
                                        (change)="getDeptList(edu.value, Year.value, Term.value)">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                                            {{item.Edu_Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">系所</span>
                                    </div>
                                    <select class="form-select shadow-none" #dept
                                        (change)="getClassList(edu.value, dept.value, Year.value, Term.value);">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">
                                            {{item.Dept_Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">開放時間</span>
                                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                        bsDatepicker [bsConfig]="bsConfig" readonly required
                                        [(ngModel)]="withOtherData.StartDate"
                                        style="background-color: transparent;">
                                    <timepicker [showMeridian]="true" [showSpinners]="false" required
                                        [(ngModel)]="fromTime">
                                    </timepicker>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">結束時間</span>
                                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                        bsDatepicker [bsConfig]="bsConfig" readonly required
                                        [(ngModel)]="withOtherData.EndDate" style="background-color: transparent;">
                                    <timepicker [showMeridian]="true" [showSpinners]="false" required
                                        [(ngModel)]="toTime">
                                    </timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button class="other-Button-gray float-end" (click)="clearData()">清除畫面</button>
                                <button class="other-Button-gray float-end me-3" (click)="deleteData()">刪除</button>
                                <button class="add-btn float-end me-3"
                                    (click)="getGRDTimeSave(Year.value, Term.value, 'Class')">儲存</button>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="依單一科目" (selectTab)="getGRDTimeList(Year.value, Term.value, 4)">
                <div class="row mt-3">
                    <div class="col-md-7">
                        <table class="table table-hover table-striped text-center">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th style="width: 60px;"></th>
                                    <th>開課班級</th>
                                    <th>開課序號</th>
                                    <th>科目</th>
                                    <th>授課教師</th>
                                    <th>開始時間</th>
                                    <th>結束時間</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="withOtherDataList">
                                <tr *ngFor="let item of withOtherDataList; let i = index" (click)="getData(i)" style="cursor:pointer;">
                                    <th style="width: 60px;">{{i + 1}}</th>
                                    <td>{{item.OpClassName}}</td>
                                    <td>{{item.Serial}}</td>
                                    <td>{{item.CosName}}</td>
                                    <td>{{item.TchName}}</td>
                                    <td>{{item.StartDateTime}}</td>
                                    <td>{{item.EndDateTime}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">學制</span>
                                    </div>
                                    <select class="form-select shadow-none" #sedu
                                        (change)="getDeptList(edu.value, Year.value, Term.value)">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                                            {{item.Edu_Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">系所</span>
                                    </div>
                                    <select class="form-select shadow-none" #sdept
                                        (change)="getClassList(sedu.value, sdept.value, Year.value, Term.value);">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">
                                            {{item.Dept_Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">班別</span>
                                    </div>
                                    <select class="form-select shadow-none" #sclass
                                        (change)="getGRDTimeCosList(Year.value, Term.value, sclass.value)">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of classList" value="{{item.Class}}">
                                            {{item.Class_Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">開課課程</span>
                                    </div>
                                    <select class="form-select shadow-none" [(ngModel)]="withOtherData.OpClass">
                                        <option value="" selected>--請選擇--</option>
                                        <option value="{{item.ItemValue}}" *ngFor="let item of gRDTimeCosList">
                                            {{item.ItemName}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">開放時間</span>
                                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                        bsDatepicker [bsConfig]="bsConfig" readonly required
                                        [(ngModel)]="withOtherData.StartDate"
                                        style="background-color: transparent;">
                                    <timepicker [showMeridian]="true" [showSpinners]="false" required
                                        [(ngModel)]="fromTime">
                                    </timepicker>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">結束時間</span>
                                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                        bsDatepicker [bsConfig]="bsConfig" readonly required
                                        [(ngModel)]="withOtherData.EndDate" style="background-color: transparent;">
                                    <timepicker [showMeridian]="true" [showSpinners]="false" required
                                        [(ngModel)]="toTime">
                                    </timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button class="other-Button-gray float-end" (click)="clearData()">清除畫面</button>
                                <button class="other-Button-gray float-end me-3" (click)="deleteData()">刪除</button>
                                <button class="add-btn float-end me-3"
                                    (click)="getGRDTimeSave(Year.value, Term.value, 'Cos')">儲存</button>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>
</div>