import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetFavourite } from 'src/app/_Models/Portal/getFavourite';
import { GetFavouriteList } from 'src/app/_Models/Portal/getFavouriteList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-favourite',
    templateUrl: './favourite.component.html',
    styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit {

    baseUrl = environment.apiUrl;

    data!: GetFavourite[] | undefined;
    favouriteList!: GetFavouriteList[] | undefined;
    modalRef!: BsModalRef;
    addDatakey: Array<string> = [];
    deleteDatakey: Array<string> = [];
    isOdd = true;
    model = {
        UserCode: '',
        Type: '',
        Language: '',
        IsMobile: true
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private accountService: AccountService,
        private modalService: BsModalService) { }

    ngOnInit(): void {
        this.getFavourite();
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.model.UserCode = response.Code;
                this.model.Type = response.Type;
            }

        });
    }


    getFavourite() {
        this.http.get<any>(this.baseUrl + "Portal/Get_Favourite").subscribe((response) => {
            if (response.Info == false) {
                this.favouriteList = undefined;
                this.addDatakey = [];
                this.deleteDatakey = [];
                return;
            }
            this.data = response;
        });
    }



    setDatakey(datakey: string, type: string) {
        if (type == 'add') {
            let index = this.addDatakey.indexOf(datakey);
            if (index == -1) {
                this.addDatakey.push(datakey);
            }
            else {
                this.addDatakey.splice(index, 1);
            }
        }
        else {
            let index = this.deleteDatakey.indexOf(datakey);
            if (index == -1) {
                this.deleteDatakey.push(datakey);
            }
            else {
                this.deleteDatakey.splice(index, 1);
            }
        }
    }

    getFavouriteList(template: TemplateRef<any>) {
        this.http.post<any>(this.baseUrl + "Portal/Get_Favourite_Query", this.model).subscribe((response) => {
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };

            this.modalRef = this.modalService.show(template, modalConfig);
            if (response.Info == false) {
                return;
            }
            this.favouriteList = undefined;
            this.favouriteList = response;


        })
    }

    refeshFavouriteList() {
        this.http.post<any>(this.baseUrl + "Portal/Get_Favourite_Query", this.model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.favouriteList = undefined;
                this.addDatakey = [];
                this.deleteDatakey = [];
                return;
            }
            this.favouriteList = undefined;
            this.favouriteList = response;
        })
    }

    addFavourite() {

        if (this.addDatakey.length == 0) return;

        let datakey = '';
        this.addDatakey.forEach(element => {
            datakey = datakey + element + ',';
        })
        datakey = datakey.substring(0, datakey.length - 1);
        this.addDatakey = [];
        this.http.post<any>(this.baseUrl + "Portal/Get_Favourite_Add", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.toastr.success("新增成功");
            this.getFavourite();
            this.refeshFavouriteList();
        }, error => this.toastr.error(error.error))
    }

    deleteFavourite() {

        if (this.deleteDatakey.length == 0) return;

        let datakey = '';
        this.deleteDatakey.forEach(element => {
            datakey = datakey + element + ',';
        })
        datakey = datakey.substring(0, datakey.length - 1);
        this.deleteDatakey = [];
        this.http.post<any>(this.baseUrl + "Portal/Get_Favourite_Delete", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.data = undefined;
            this.getFavourite();
            this.refeshFavouriteList();
        }, error => this.toastr.error(error.error))
    }

}
