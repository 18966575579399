import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetSACTotalList } from 'src/app/_Models/AD/ADSAC/ADSAC0202/getSACTotalList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSAC0202',
    templateUrl: './ADSAC0202.component.html',
    styleUrls: ['./ADSAC0202.component.css']
})
export class ADSAC0202Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    deptList!: GetDeptList[];
    eduList!: GetEduList[];
    classList!: GetClassList[];
    sACTotalList?: GetSACTotalList[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        StdNoFrom: new UntypedFormControl(''),
        StdNoTo: new UntypedFormControl('')
    });
    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
    ) { }

    ngOnInit(): void {
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList() {
        this.systemService.getDeptList(this.ngFormInput.get('EduNo')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList() {
        this.systemService.getClassList(this.ngFormInput.get('EduNo')?.value, this.ngFormInput.get('DeptNo')?.value, '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getSACTotalList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SAC/ADSAC0202/Get_SAC_Total_List", this.ngFormInput.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sACTotalList = response;
        });
    }

    getSACTotalSave() {

        if (!this.sACTotalList) return;

        this.http.post<any>(this.baseUrl + "ACAD/SAC/ADSAC0202/Get_SAC_Total_Save", this.sACTotalList).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('儲存成功');
        })
    }

    getSACTotalDelete() {

        if (!this.sACTotalList) return;

        Swal.fire({
            title: '確定刪除?',
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/SAC/ADSAC0202/Get_SAC_Total_Delete", this.sACTotalList).subscribe(response => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success('刪除成功');
                })
            }
            else {
                this.toastr.info('已取消');
            }
        })
    }
}
