import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { HttpClient } from '@angular/common/http';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { GetStdKeepList } from 'src/app/_Models/AD/ADSTD/ADSTD0101/getStdKeepList';
import { GetSelectList } from 'src/app/_Models/getSelectList';

@Component({
  selector: 'app-ADSTD0101',
  templateUrl: './ADSTD0101.component.html',
  styleUrls: ['./ADSTD0101.component.css']
})
export class ADSTD0101Component implements OnInit {
  modalRef!: BsModalRef;
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  baseUrl = environment.apiUrl;
  initialData = new InitialData(this.http);
  stdKeepList!: GetStdKeepList[] | undefined;
  datakeyArray: Array<string> = [];
  model = {
    Years: '',
    Term: '',
    Approve: ''
  }
  newStdKeepData!: any;
  keepReasonList!: GetSelectList[];
  constructor(private toastr: ToastrService,
    private systemService: SystemService,
    private modalService: BsModalService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.getYears();
    this.getKeepReason();
  }


  getKeepReason() {
    this.systemService.getKeepReason().subscribe((response) => {
      this.keepReasonList = response;
    })
  }

  getStdKeepList() {
    this.datakeyArray = [];
    this.stdKeepList = undefined;
    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_01/Get_Std_KeepList", this.model).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.stdKeepList = []
        return;
      }
      this.stdKeepList = response;
    });
  }

  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    });
  }

  openModel(template: TemplateRef<any>) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };
    this.newStdKeepData = {
      DataKey: '',
      Years: '',
      Term: '',
      EndYear: '',
      EndTerm: '',
      StdNo: '',
      StdName: '',
      ClassName: '',
      Status: '',
      KeepNo: '',
      KeepName: '',
      KeepMoe: '',
      ApplyDate: '',
      ApproveDate: '',
      Approve: '',
      Recover: '',
      RecoverYear: '',
      RecoverTerm: '',
      RecoverDate: '',
      Delay: '',
      Memo: ''
    }

    this.modalRef = this.modalService.show(template, modalConfig);
    this.getStdKeepList()
  }

  deleteData() {
    let str = '';
    this.datakeyArray.forEach(element => {
      str += element + ','
    });
    let datakey = str.substring(0, str.length - 1);

    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_01/Get_Std_Keep_Delete", { DataKey: datakey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("刪除成功");
      this.datakeyArray = [];
      this.getStdKeepList();
    })
  }

  newData() {

    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_01/Get_Std_Keep_Save", this.newStdKeepData).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
    });
    this.modalRef.hide();
    this.toastr.success("新增成功");
    this.getStdKeepList();
  }

  setDataKey(dataKey: string) {

    let index = this.datakeyArray.indexOf(dataKey);
    if (index == -1) {
      this.datakeyArray.push(dataKey);
    }
    else {
      this.datakeyArray.splice(index, 1);
    }

  }

}
