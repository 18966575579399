import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetCosTest } from 'src/app/_Models/TCHWEB/TCH0307/getCosTest';
import { GetStdTest } from 'src/app/_Models/TCHWEB/TCH0307/getStdTest';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0307',
    templateUrl: './TCH0307.component.html',
    styleUrls: ['./TCH0307.component.css']
})
export class TCH0307Component implements OnInit {

    baseUrl = environment.apiUrl;
    cosTestList!: GetCosTest[];
    testList!: GetSelectList[];
    stdTestList!: GetStdTest[];
    modalRef!: BsModalRef;
    btnHidden = true;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private timeService: TimeService) { }

    ngOnInit(): void {

        this.timeService.getTimeCntr().subscribe((response) => {
            this.getCosTest({ Years: response.Now_Years, Term: response.Now_Term });
        }, error => this.toastr.error(error.error))
    }

    getCosTest(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_07/Get_Cos_Testing", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cosTestList = []
                return;
            }

            this.cosTestList = response;
        })
    }

    openModal(template: TemplateRef<any>, dataKey: string) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_07/Get_Cos_Testing_List", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.testList = response;
            this.modalRef = this.modalService.show(template, modalConfig);
        }, error => this.toastr.error(error.error))
    }

    getStdHomeworkList(dataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_07/Get_Cos_Testing_Std", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdTestList = []
                return;
            }
            this.stdTestList = response;
        })
    }

    editData() {
        this.btnHidden = !this.btnHidden;
    }

    saveData() {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_07/Get_Cos_Testing_Save", this.stdTestList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.btnHidden = !this.btnHidden;
            this.toastr.success("儲存成功");
        }, error => this.toastr.error(error.error))
    }

}
