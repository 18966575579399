<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year (change)="getDeptList(Year.value, Term.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term (change)="getDeptList(Year.value, Term.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">系所</span>
                </div>
                <select class="form-select shadow-none" #Dept>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3 mb-3">
            <button class="search-btn float-start me-3" (click)="search(Dept.value)">查詢</button>
            <button class="other-Button-gray float-start"
                (click)="exportReport(Year.value, Term.value, Dept.value)">匯出</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" class="form-check-input pointer shadow-none"
                        (click)="setDatakey('all', '', $event.target)">&emsp;全選</th>
                <th>班級代碼</th>
                <th>班級名稱</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td><input type="checkbox" class="form-check-input pointer shadow-none" [checked]="item.Selected"
                        (click)="setDatakey('', item.Class, $event.target)"></td>
                <td>{{item.Class}}</td>
                <td>{{item.Class_Name}}</td>
            </tr>
        </tbody>
    </table>
</div>