<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'申請項目'|translate}}</span>
                </div>
                <select class="form-select shadow-none" required (change)="change(ApplyNo.value)" #ApplyNo>
                    <option value="">{{'全部'|translate}}</option>
                    <option *ngFor="let a of getApplyData" [value]="a.ItemValue">{{a.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <button class="search-btn float-start me-3" (click)="search()">{{'查詢'|translate}}</button>
        </div>
        <div class="col-md-6">
            <button class="other-Button-gray float-end " (click)="delete()" *ngIf="check">
                {{'刪除'|translate}}
            </button>
            <button class="other-Button-gray float-end me-3" (click)="openModal(template)" (click)="searchdetail()">
                {{'申請'|translate}}
            </button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3 table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th><input type="checkbox" class="pointer" [(ngModel)]="checkall" (change)="allchange()">{{'選擇'|translate}}</th>
                <th>{{'學年'|translate}}</th>
                <th>{{'學期'|translate}}</th>
                <th>{{'學號'|translate}}</th>
                <th>{{'申請項目'|translate}}</th>
                <th>{{'狀態'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let y of getSearchData let i=index">
                <td data-th="選擇">
                    <input *ngIf="y.ChkStatus=='1'" type="checkbox" class="pointer" [(ngModel)]="y.Checked"
                        (change)="checkchange()">
                </td>
                <td data-th="學年">{{y.Years}}</td>
                <td data-th="學期">{{y.Term}}</td>
                <td data-th="學號">{{y.StdNo}}</td>
                <td data-th="申請項目">{{y.ApplyName}}</td>
                <td data-th="狀態">{{y.ChkStatusName}}</td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">申請</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">申請項目</span>
                    <select class="form-select shadow-none" required (change)="change(ApplyNo.value)" #ApplyNo>
                        <option value="">請選擇</option>
                        <option *ngFor="let a of getApplyData" [value]="a.ItemValue">{{a.ItemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mt-3s">
            <table class="table table-hover table-striped text-center mt-3 table-rwd">
                <thead class="thead-dark-red table-rwd-hide">
                    <tr>
                        <th>學年</th>
                        <th>學期</th>
                        <th>學號</th>
                        <th>按鈕功能</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let y of  getSearchdetailData let i=index">
                        <td data-th="學年">{{y.Years}}</td>
                        <td data-th="學期">{{y.Term}}</td>
                        <td data-th="學號">{{y.StdNo}}</td>
                        <td data-th="按鈕功能">
                            <button *ngIf="!y.NeedSend" class="other-Button-gray" (click)="export(i)">列印</button>
                            <button *ngIf="y.NeedSend" class="send-btn" (click)="insert(i)">送出</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>