<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="ngFormInput" (ngSubmit)="getCUROpen01CosList()">
        <button hidden="true" #submit></button>
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none search-input" #Year
                        (ngModelChange)="getDeptList(Year.value, Term.value)" formControlName="Years">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none search-input" #Term
                        (ngModelChange)="getDeptList(Year.value, Term.value)" formControlName="Term">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="DeptNo" #DeptNo
                        (ngModelChange)="getClassList(Year.value, Term.value, DeptNo.value)">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class" #Class
                        (ngModelChange)="getTeamList(Year.value, Term.value, Class.value)">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">組別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="TeamNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of teamList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">項目</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="TRType">
                        <option value="" selected>--請選擇--</option>
                        <option value="T">教師</option>
                        <option value="R">教室</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <input type="text" class="form-control shadow-none" placeholder="項目的關鍵字" formControlName="TRValue">
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">合開課程序號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="MergeNo">
                </div>
            </div>
            <div class="col-md-1">
                <button class="search-btn me-3" (click)="submit.click()">查詢</button>
            </div>
            <div class="col-md-1">
                <button type="button" class="other-Button-gray me-3"
                    (click)="openModal(importTemplate, 'import', 0)">匯入課程</button>
            </div>
            <div class="col-md-1">
                <button type="button" class="other-Button-gray me-3"
                    (click)="openModal(cosTemplate, 'cos', 0)">新增科目</button>
            </div>
            <div class="col-md-1">
                <button type="button" class="other-Button-gray me-3" (click)="ClearCos()">清除</button>
            </div>
            <div class="col-md-1">
                <button type="button" class="other-Button-gray me-3" (click)="getCUROpen01Delete()">刪除科目</button>
            </div>                       
        </div>
        <div class="row">
            <div class="input-group flex-nowrap mb-3">
                <div class="col-md-7">
                    <div class="form-check pt-2">
                        <label *ngIf="cUROpen01Time">目前開課學年期為：<strong>{{cUROpen01Time.Years}}</strong> 學年第 <strong>{{cUROpen01Time.Term}}</strong> 學期，作業時間：<strong>{{cUROpen01Time.Times}}</strong></label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-check pt-2">
                        <input class="form-check-input shadow-none pointer" type="checkbox" id="flexCheckDefault"
                            checked #UseCurPlan>
                        <label class="form-check-label pointer" for="flexCheckDefault">課程科目查詢使用標準課程檔</label>
                    </div>
                </div>
                <div class="col-md-3">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button" class="other-Button-gray me-3"
                        (click)="openModal(copyTemplate, 'copy', 0)">複製資料</button>
                </div>                   
            </div>
        </div>        
    </form>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 30px;"></th>
                <th style="width: 50px;">序號</th>
                <th style="width: 100px;text-align: left;">班級</th>
                <th style="width: 70px;text-align: left;">組別</th>
                <th style="text-align: left;">科目</th>
                <th style="width: 60px;">修別</th>
                <th style="width: 90px;">學分/時數</th>
                <th style="text-align: left;">教師</th>
                <!--<th style="width: 80px;">教室</th>-->
                <th style="width: 60px;">人數</th>
                <th style="width: 50px;">停開</th>
                <th style="text-align: left;">備註</th>
                <th style="width: 120px;">合開序號</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cUROpen01CosList; let i = index"
                [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                (click)="getCUROpen01CosDetail(item.DataKey, i)">
                <td style="width: 30px;">{{i + 1}}</td>
                <td style="width: 50px;">{{item.Serial}}</td>
                <td style="width: 100px;text-align: left;">{{item.ClassName}}</td>
                <td style="width: 70px;text-align: left;">{{item.TeamName}}</td>
                <td style="text-align: left;">{{item.CosId}} {{item.CosName}}</td>
                <td style="width: 60px;">{{item.SelKindName}}</td>
                <td style="width: 90px;">{{item.Credits}} / {{item.Hours}}</td>
                <td style="text-align: left;">{{item.TchName}}</td>
                <!--<td style="width: 80px;">{{item.RoomName}}</td>-->
                <td style="width: 60px;">{{item.ElectNum}}</td>
                <td style="width: 50px;">{{item.StopMark}}</td>
                <td style="text-align: left;">{{item.Memo}}</td>
                <td style="width: 120px;">{{item.MergeNo}}</td>
            </tr>
        </tbody>
    </table>
    <form [formGroup]="ngForm">
        <div class="row">
            <div class="col-md-12">
                <button class="other-Button-gray float-end" (click)="getCUROpen01Modify()">儲存</button>
            </div>
        </div>
        <tabset>
            <tab heading="開課科目">
                <div class="row">
                    <div class="col-md-5">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-group flex-nowrap mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">科目名稱</span>
                                        </div>
                                        <input type="hidden" formControlName="CosID">
                                        <input type="text" class="form-control shadow-none" readonly
                                            formControlName="CosName">
                                        <button type="button" class="btn btn-light shadow-none"
                                            (click)="openModal(cosTemplate, 'cos', 0)">置換科目</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">選修別</span>
                                        </div>
                                        <input type="text" class="form-control shadow-none" readonly
                                            formControlName="SelKindName">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">學分/時數</span>
                                        </div>
                                        <input type="number" class="form-control shadow-none text-center" readonly
                                            formControlName="Credits">
                                        <input type="number" class="form-control shadow-none text-center" min="0"
                                            formControlName="Hours">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">鐘點註記</span>
                                        </div>
                                        <select class="form-select shadow-none" formControlName="TchMLog">
                                            <option value="" selected disabled>--請選擇--</option>
                                            <option value="0">一般</option>
                                            <option value="1">全程英語</option>
                                            <option value="2">數位學習</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">鐘點類別</span>
                                        </div>
                                        <select class="form-select shadow-none" formControlName="TchMClass">
                                            <option value="" selected disabled>--請選擇--</option>
                                            <option value="D">日鐘點</option>
                                            <option value="N">夜鐘點</option>
                                            <option value="E">EMBA</option>
                                            <option value="O">其他</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-group flex-nowrap mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">教學大綱繳交期限</span>
                                        </div>
                                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                            style="height: 38px; background-color: transparent;" bsDatepicker
                                            [bsConfig]="bsConfig" readonly formControlName="TSEndTime">
                                        <timepicker [showMeridian]="false" [showSpinners]="false" class="shadow-none"
                                            [(ngModel)]="Time" [ngModelOptions]="{standalone: true}"></timepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-group flex-nowrap mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">合開課程序號</span>
                                        </div>
                                        <input type="text" class="form-control pointer shadow-none" formControlName="MergeNo" 
                                                maxlength="10" placeholder="最多10碼">
                                    </div>
                                </div>
                            </div>                        
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-group mb-3">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input pointer shadow-none" type="checkbox"
                                                id="inlineCheckbox1" value="option1" formControlName="StopMark">
                                            <label class="form-check-label pointer" for="inlineCheckbox1">停開</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input pointer shadow-none" type="checkbox"
                                                id="inlineCheckbox3" value="option3" formControlName="WeekMarkS">
                                            <label class="form-check-label pointer" for="inlineCheckbox3">單週</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input pointer shadow-none" type="checkbox"
                                                id="inlineCheckbox4" value="option3" formControlName="WeekMarkD">
                                            <label class="form-check-label pointer" for="inlineCheckbox4">雙週</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input pointer shadow-none" type="checkbox"
                                                id="inlineCheckbox7" value="option3" formControlName="ChkCosArrange">
                                            <label class="form-check-label pointer" for="inlineCheckbox7">檢查衝堂</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input pointer shadow-none" type="checkbox"
                                                id="inlineCheckbox8" value="option3" formControlName="AllotMark">
                                            <label class="form-check-label pointer" for="inlineCheckbox8">配課</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div clsaa="col-md-12">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text">備註</span>
                                        <textarea class="form-control shadow-none" formControlName="Memo"
                                            appTextareaAutoresize></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-hover table-striped text-center">
                                    <thead class="thead-dark-red">
                                        <tr>
                                            <th style="width: 60px;"></th>
                                            <th>教師</th>
                                            <th style="width: 80px;">排課時數</th>
                                            <th style="width: 80px;">鐘點時數</th>
                                            <th style="width: 80px;">成績比例</th>
                                            <th style="width: 80px;">衝堂檢查</th>
                                            <th style="width: 80px;">合開註記</th>
                                            <th>備註</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of tchList; let i = index">
                                            <td style="width: 60px;">{{i + 1}}</td>
                                            <td>
                                                <ng-container *ngIf="item.TchName != ''">{{item.TchName}}</ng-container>
                                                <a class="pointer text-decoration-none" (click)="openModal(tchTemplate, '', i)"
                                                    *ngIf="item.TchName == ''">選擇教師</a>
                                            </td>
                                            <td style="width: 80px;"><input type="number"
                                                    class="form-control form-control-sm shadow-none text-center" min="0"
                                                    [(ngModel)]="item.ArrangeHours" [ngModelOptions]="{standalone: true}"></td>
                                            <td style="width: 80px;"><input type="number"
                                                    class="form-control form-control-sm shadow-none text-center" min="0"
                                                    step="0.01" [(ngModel)]="item.MoneyHours"
                                                    [ngModelOptions]="{standalone: true}"></td>
                                            <td style="width: 80px;"><input type="number"
                                                    class="form-control form-control-sm shadow-none text-center" max="1" min="0"
                                                    step="0.1" [(ngModel)]="item.Score" [ngModelOptions]="{standalone: true}">
                                            </td>
                                            <td style="width: 80px;"><input type="checkbox"
                                                    class="form-check-input pointer shadow-none" [(ngModel)]="item.ChkTime"
                                                    [ngModelOptions]="{standalone: true}"></td>
                                            <td style="width: 80px;"><input type="checkbox"
                                                    class="form-check-input pointer shadow-none" [(ngModel)]="item.ChkMerge"
                                                    [ngModelOptions]="{standalone: true}"></td>
                                            <td><input type="text" class="form-control form-control-sm shadow-none"
                                                    [(ngModel)]="item.Memo" [ngModelOptions]="{standalone: true}"></td>
                                        </tr>
                                        <tr>
                                            <td colspan="9">
                                                <a class="pointer text-decoration-none float-end"
                                                    (click)="deleteRow('tch')">刪除一筆</a>
                                                <a class="pointer text-decoration-none me-3 float-end"
                                                    (click)="addRow('tch')">新增一筆</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-hover table-striped text-center">
                                    <thead class="thead-dark-red">
                                        <tr>
                                            <th style="width: 60px;"></th>
                                            <th>教室</th>
                                            <th style="width: 80px;">使用時數</th>
                                            <th style="width: 80px;">刪除</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of classRoomList; let i = index">
                                            <td style="width: 60px;">{{i + 1}}</td>
                                            <td>
                                                <ng-container *ngIf="item.ClassRoom != ''">{{item.ClassRoom}}</ng-container>
                                                <a class="pointer text-decoration-none" *ngIf="item.ClassRoom == ''"
                                                    (click)="openModal(classTemplate, 'classRoom', i)">查詢教室</a>
                                            </td>
                                            <td style="width: 80px;"><input type="number"
                                                    class="form-control form-control-sm shadow-none text-center" max="1" min="0"
                                                    step="0.1" [(ngModel)]="item.ArrangeHours"
                                                    [ngModelOptions]="{standalone: true}"></td>
                                            <td style="width: 80px;"><input type="checkbox"
                                                    class="form-check-input pointer shadow-none text-center"
                                                    [(ngModel)]="item.Delete" [ngModelOptions]="{standalone: true}"></td>
                                        </tr>
                                        <tr>
                                            <td colspan="6">
                                                <a class="pointer text-decoration-none float-end"
                                                    (click)="deleteRow('class')">刪除一筆</a>
                                                <a class="pointer text-decoration-none me-3 float-end"
                                                    (click)="addRow('class')">新增一筆</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="其他資料">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3"
                        *ngFor="let item of columnList; let i = index">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{item.ColumnName}}</span>
                            </div>
                            <input type="text" class="form-control shadow-none" 
                                [(ngModel)]="item.ItemName"
                                [disabled]="item.DataType == '01'"
                                [ngModelOptions]="{standalone: true}">
                            <input type="hidden" [(ngModel)]="item.ItemValue" [ngModelOptions]="{standalone: true}">
                            <button class="btn btn-light shadow-none"
                                *ngIf="item.DataType == '01'"
                                (click)="openModal(columnitemsTemplate,'columnitems',i)"
                                (click)="getCUROpen01ColumnList(item)">...</button>
                        </div>
                    </div>                  
                </div>
            </tab>
            <tab heading="選課">
                <div class="row">
                    <div class="col-md-4">
                        <div class="list-group mt-3">
                            <label class="list-group-item">
                                <input class="form-control form-control-sm shadow-none me-1 float-start text-center"
                                    style="width: 15%;" type="number" max="7" min="1" formControlName="OverGrade">
                                <label class="mt-1">年級以上可選</label>
                            </label>
                            <label class="list-group-item">
                                <input class="form-control form-control-sm shadow-none me-1 float-start text-center"
                                    style="width: 15%;" type="number" max="7" min="1" formControlName="UnderGrade">
                                <label class="mt-1">年級以下可選</label>
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkAdd">
                                目前可加選
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkDel">
                                目前可退選
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkAddType">
                                特殊身份學身可選(輔系、雙主修、師培生)
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkEdu">
                                限學制選課
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkTime">
                                檢查學生選課衝堂
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="list-group mt-3">
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkSelDept">
                                限可修系所
                                <a class="pointer" (click)="openModal(limitTemplate, 'limitDept', 0)">設定</a>
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkSelClass">
                                限可修班級
                                <a class="pointer" (click)="openModal(limitTemplate, 'limitClass', 0)">設定</a>
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkDept">
                                限本系所學生選課
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkClass">
                                限本班學生選課
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkPrePass">
                                以前有修及格過不可選
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkCredit">
                                是否列入選課學分上限
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="list-group mt-3">
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkDelMust">
                                本班生必修可退
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-1" type="checkbox" formControlName="ChkPGSel">
                                允許研修生下修
                            </label>
                            <label class="list-group-item">
                                <label class="float-start me-1 mt-1">應繳學分費</label>
                                <input type="number" min="0"
                                    class="form-control form-control-sm float-start shadow-none text-center"
                                    style="width: 25%;" formControlName="CreditMoney">
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input mt-2 me-2 shadow-none float-start" type="checkbox"
                                    formControlName="ChkSelMax">
                                <label class="float-start me-1 mt-1">檢查人數上限</label>
                                <input type="number" class="form-control form-control-sm shadow-none" min="0"
                                    style="width: 20%;" formControlName="SelMax">
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input mt-2 me-2 shadow-none float-start" type="checkbox"
                                    formControlName="ChkSelMin">
                                <label class="float-start me-1 mt-1">檢查人數下限</label>
                                <input type="number" class="form-control form-control-sm shadow-none" min="0"
                                    style="width: 20%;" formControlName="SelMin">
                            </label>
                        </div>
                    </div>
                </div>
            </tab>
        </tabset>
    </form>
</div>

<ng-template #cosTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢科目</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">關鍵字</span>
                    </div>
                    <input type="text" class="form-control shadow-none" #KeyWord>
                </div>
            </div>
            <div class="col-md-8">
                <button class="search-btn" (click)="getCUROpen01QueryCos(KeyWord.value, UseCurPlan.checked)">查詢</button>
            </div>
        </div>
        <small class="text-danger">*請選取資料列以設定</small>
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th></th>
                    <th>課程名稱</th>
                    <th>必選修別</th>
                    <th>學分</th>
                    <th>時數</th>
                </tr>
            </thead>
            <tbody style="height: 500px;">
                <tr *ngFor="let item of cUROpen01QueryCos; let i = index" class="pointer"
                    (click)="saveCos(item.DataKey, item.CosID, item.CosName)">
                    <td>{{i + 1}}</td>
                    <td>{{item.CosName}}</td>
                    <td>{{item.SelKindName}}</td>
                    <td>{{item.Credits}}</td>
                    <td>{{item.Hours}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #limitTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定{{limitType == 'limitDept'? '限可修系所': '限可修班級'}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover table-striped text-center" *ngIf="limitType != 'limitDept'">
            <thead class="thead-dark-red">
                <tr>
                    <th>勾選</th>
                    <th>班級代碼</th>
                    <th>班級名稱</th>
                </tr>
            </thead>
            <tbody style="height: 500px;">
                <tr *ngFor="let item of selClassList" class="pointer">
                    <td><input type="checkbox" [(ngModel)]="item.Checked" [ngModelOptions]="{standalone: true}"></td>
                    <td>{{item.Class}}</td>
                    <td>{{item.ClassName}}</td>
                </tr>
            </tbody>
        </table>
        <table class="table table-hover table-striped text-center" *ngIf="limitType == 'limitDept'">
            <thead class="thead-dark-red">
                <tr>
                    <th>勾選</th>
                    <th>系所代碼</th>
                    <th>系所名稱</th>
                </tr>
            </thead>
            <tbody style="height: 500px;">
                <tr *ngFor="let item of selDeptList" class="pointer">
                    <td><input type="checkbox" [(ngModel)]="item.Checked" [ngModelOptions]="{standalone: true}"></td>
                    <td>{{item.DeptNo}}</td>
                    <td>{{item.DeptName}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #tchTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢教師</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width: 60px;"></th>
                    <th>職稱</th>
                    <th>教師代碼</th>
                    <th>教師姓名</th>
                </tr>
            </thead>
            <tbody style="height: 500px;">
                <tr *ngFor="let item of employeeList; let i = index" class="pointer"
                    (click)="setTeacher(item.Code, item.Name, tchListIndex)">
                    <td style="width: 60px;">{{i + 1}}</td>
                    <td>{{item.Title_Name}}</td>
                    <td>{{item.Code}}</td>
                    <td>{{item.Name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #classTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢教室</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width:40px;"></th>
                    <th>所屬大樓</th>
                    <th>教室型態</th>
                    <th>教室代碼</th>
                    <th>教室名稱</th>
                </tr>
            </thead>
            <tbody style="height: 500px;">
                <tr *ngFor="let item of data; let i = index" class="pointer"
                    (click)="setClass(item.Classroom, item.Classroom_Name, classRoomListIndex)">
                    <td style="width:40px;">{{i + 1}}</td>
                    <td>{{item.ClassKind_Name}}</td>
                    <td>{{item.ClassType_Name}}</td>
                    <td>{{item.Classroom}}</td>
                    <td>{{item.Classroom_Name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #copyTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">複製資料來源</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #copyFromYears>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #copyFromTerm>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <button class="save-btn-gray" (click)="getCUROpen01Copy(copyFromYears.value, copyFromTerm.value)">儲存</button>
    </div>
</ng-template>

<ng-template #importTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">匯入標準課程檔</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6">
                <div class="card mt-3">
                    <div class="card-header">一般</div>
                    <div class="card-body">
                        <table class="table table-hover table-striped text-center"
                            *ngIf="cUROpen01CurPlan">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th>勾選</th>
                                    <th>組別名稱</th>
                                    <th>課程代碼</th>
                                    <th>課程名稱</th>
                                    <th>學分</th>
                                    <th>選修別</th>
                                </tr>
                            </thead>
                            <tbody style="height: 500px;">
                                <tr *ngFor="let item of cUROpen01CurPlan.CosList1">
                                    <td><input type="checkbox" class="form-check-input pointer shadow-none"
                                            [(ngModel)]="item.Checked"></td>
                                    <td>{{item.TeamName}}</td>
                                    <td>{{item.CosID}}</td>
                                    <td>{{item.CosName}}</td>
                                    <td>{{item.Credits}}</td>
                                    <td>{{item.SelKindName}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card mt-3">
                    <div class="card-header">未指定</div>
                    <div class="card-body">
                        <table class="table table-hover table-striped text-center"
                            *ngIf="cUROpen01CurPlan">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th>勾選</th>
                                    <th>組別名稱</th>
                                    <th>課程代碼</th>
                                    <th>課程名稱</th>
                                    <th>學分</th>
                                    <th>選修別</th>
                                </tr>
                            </thead>
                            <tbody style="height: 500px;">
                                <tr *ngFor="let item of cUROpen01CurPlan.CosList2">
                                    <td><input type="checkbox" class="form-check-input pointer shadow-none"
                                            [(ngModel)]="item.Checked"></td>
                                    <td>{{item.TeamName}}</td>
                                    <td>{{item.CosID}}</td>
                                    <td>{{item.CosName}}</td>
                                    <td>{{item.Credits}}</td>
                                    <td>{{item.SelKindName}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <button class="import-btn mx-auto" (click)="getCUROpen01CurPlanImport()">匯入</button>
        </div>
    </div>
</ng-template>

<ng-template #columnitemsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">選擇子項目</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <small class="text-danger">*請選取資料列以設定</small>
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width: 80px;">項目代碼</th>
                    <th style="width: 200px;">項目名稱</th>
                </tr>
            </thead>
            <tbody style="height: 300px;">
                <tr *ngFor="let item of columnItems; let i = index" class="pointer"
                    (click)="saveColumnItem(item.ItemValue,item.ItemName,columnListIndex)">
                    <td style="width: 80px;">{{item.ItemValue}}</td>
                    <td style="width: 200px;">{{item.ItemName}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #nothing>
    <h2 class="text-danger text-center">暫無資料</h2>
</ng-template>