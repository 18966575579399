<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getEMPMainList()">
        <div class="row">
            <div class="col-md-2">
                <div class="input-group">
                    <span class="input-group-text">人事代碼</span>
                    <input type="text" class="form-control shadow-none" formControlName="Code">
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                    <span class="input-group-text">姓名</span>
                    <input type="text" class="form-control shadow-none" formControlName="Name">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">身分證號</span>
                    <input type="text" class="form-control shadow-none" formControlName="IDNo">
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">在職狀態</span>
                    <select class="form-select shadow-none" formControlName="StateNo">
                        <option value="">--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of titleStatus">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">單位</span>
                    <select class="form-select shadow-none" formControlName="UnitCode">
                        <option value="">--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of unitList">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
        </div>    
        <div class="row">    
            <div class="col-md-12">
                <button type="button" class="other-Button-gray float-end" (click)="openModal2(Exporttemplate)">匯出</button>
                <button type="button" class="other-Button-gray float-end me-3" (click)="clear()">清除</button>
                <button type="button" class="other-Button-gray float-end me-3"
                    (click)="getEMPMainDelete(eMPMainDetail.DataKey)">刪除</button>
                <button type="button" class="other-Button-gray float-end me-3"
                    (click)="getEMPMainDetailSave()">修改</button>
                <button type="button" class="other-Button-gray float-end me-3"
                    (click)="getEMPMainDetailSave()">新增</button>
                <button class="search-btn float-end me-3">查詢</button>
            </div>
        </div>
    </form>
    <hr>
    <div class="row">
        <div class="col-md-3">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 40px;"></th>
                        <th>代碼</th>
                        <th>名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of eMPMainList; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="getEMPMainDetail(item.DataKey,i)">
                        <td style="width: 40px;">{{i + 1}}</td>
                        <td>{{item.Code}}</td>
                        <td>{{item.Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-9">
            <tabset>
                <tab heading="基本資料">
                    <div class="row">
                        <div class="col-md-3 mb-3">
                            <figure class="figure">
                                <img [src]="ImgUrl" class="figure-img img-fluid rounded" />
                                <figcaption class="figure-caption">
                                    <button class="other-Button-gray img-btn"
                                        (click)="openModal1(template1)">上傳</button>
                                    <small class="text-danger fw-bolder ps-3">*請勿上傳生活照</small>
                                </figcaption>
                            </figure>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text text-primary fw-bolder">人事代碼</span>
                                        <input type="text" *ngIf="eMPMainDetail.DataKey==''" class="form-control shadow-none"
                                            [(ngModel)]="eMPMainDetail.Code">
                                        <input type="text" *ngIf="eMPMainDetail.DataKey!=''" class="form-control shadow-none"
                                            [(ngModel)]="eMPMainDetail.Code" readonly disabled>                                            
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text text-primary fw-bolder">身分證號</span>
                                        <input type="text" class="form-control shadow-none"
                                            [(ngModel)]="eMPMainDetail.IDNo">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="input-group mb-4">        
                                            <span class="input-group-text text-primary fw-bolder">中文姓名</span>  
                                        <input type="text" class="form-control shadow-none"
                                            [(ngModel)]="eMPMainDetail.Name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text text-primary fw-bolder">生日</span>
                                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                            bsDatepicker [bsConfig]="bsConfig" readonly
                                            style="background-color: transparent;" [(ngModel)]="eMPMainDetail.Birthday">
                                    </div>
                                </div>                                
                                <div class="col-md-5">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text">英文姓名</span>
                                        <input type="text" class="form-control shadow-none"
                                            [(ngModel)]="eMPMainDetail.EngName">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text">性別</span>
                                        <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.Sex">
                                            <option value="">--請選擇--</option>
                                            <option value="男">男</option>
                                            <option value="女">女</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text">血型</span>
                                        <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.Blood">
                                            <option value="">--請選擇--</option>
                                            <option value="{{item.itemValue}}" *ngFor="let item of bloodType">
                                                {{item.itemName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text">婚姻狀態</span>
                                        <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.Marry">
                                            <option value="">--請選擇--</option>
                                            <option value="{{item.itemValue}}" *ngFor="let item of marriage">
                                                {{item.itemName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text">國籍</span>
                                        <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.Country">
                                            <option value="{{item.ItemValue}}" *ngFor="let item of countryList">
                                                {{item.ItemName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text text-primary fw-bolder">學位</span>
                                        <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.DegreeType">
                                            <option value="">--請選擇--</option>
                                            <option value="{{item.ItemValue}}" *ngFor="let item of employeeDegree">
                                                {{item.ItemName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text">在職狀態</span>
                                        <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.StateNo">
                                            <option value="">--請選擇--</option>
                                            <option value="{{item.ItemValue}}" *ngFor="let item of titleStatus">
                                                {{item.ItemName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text">出家法名</span>
                                        <input type="text" class="form-control shadow-none"
                                            [(ngModel)]="eMPMainDetail.MonkName">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text">進修狀態</span>
                                        <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.Further">
                                            <option value="">--請選擇--</option>
                                            <option value="{{item.ItemValue}}" *ngFor="let item of studyStatus">
                                                {{item.ItemName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text">最高學歷</span>
                                        <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.Graduate">
                                            <option value="">--請選擇--</option>
                                            <option value="{{item.Item_No}}" *ngFor="let item of Graduate">
                                                {{item.Item_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>    
                            <div class="row">    
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text text-primary fw-bolder">到校日</span>
                                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                            bsDatepicker [bsConfig]="bsConfig" readonly
                                            style="background-color: transparent;" [(ngModel)]="eMPMainDetail.InDate">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group mb-4">
                                            <span class="input-group-text text-primary fw-bolder">離校日</span>
                                        <input type="text" class="form-control pointer shadow-none" readonly
                                            style="background-color: transparent;" [(ngModel)]="eMPMainDetail.EndDate">
                                    </div>
                                </div>                                                                     
                            </div>
                        </div>
                    </div>
                    <hr class="border-primary">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                    <span class="input-group-text text-primary fw-bolder">郵遞區號</span>
                                <input type="text" class="form-control shadow-none" placeholder="2+2或3+3"
                                    [(ngModel)]="eMPMainDetail.ComZip">
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="input-group mb-3">
                                    <span class="input-group-text text-primary fw-bolder">通訊地址</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.ComAddress">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                    <span class="input-group-text text-primary fw-bolder">郵遞區號</span>
                                <input type="text" class="form-control shadow-none" placeholder="2+2或3+3"
                                    [(ngModel)]="eMPMainDetail.NomZip">
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="input-group mb-3">
                                    <span class="input-group-text text-primary fw-bolder">戶籍地址</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.NomAddress">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text text-primary fw-bolder">聯絡電話</span>
                                <input type="text" class="form-control shadow-none" [(ngModel)]="eMPMainDetail.ComTel">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">行動電話</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.MobileTel">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text text-primary fw-bolder">辦公室電話</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.OfficeTel">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">備註</span>
                                <input type="text" class="form-control shadow-none" [(ngModel)]="eMPMainDetail.Memo">
                            </div>
                        </div>
                    </div>    
                    <div class="row">    
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">修改人員</span>
                                <input type="text" class="form-control shadow-none" disabled
                                    [(ngModel)]="eMPMainDetail.UpdateUser">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">修改時間</span>
                                <input type="text" class="form-control shadow-none" disabled
                                    [(ngModel)]="eMPMainDetail.UpdateDate">
                            </div>
                        </div>
                    </div>
                </tab>

                <ng-template #template1>
                    <div class="modal-header">
                        <h4 class="modal-title pull-left">設定大頭貼</h4>
                        <button type="button" class="btn-close btn-close-white pull-right shadow-none"
                            aria-label="Close" (click)="modalRef?.hide()">
                            <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-header">大頭貼設定</div>
                                    <div class="card-body" style="height: 350px;">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="input-group mb-3">
                                                    <input name="file" type="file" id="image" accept=".png"
                                                        class="form-control shadow-none"
                                                        (change)="fileChangeEvent($event)" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-5">
                                                <img [src]="croppedImageA" class="card-img-top" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <button class="other-Button-gray mt-3 float-end" [hidden]="!showCropper"
                                                    (click)="clearImage()">清除</button>
                                                <button class="other-Button-gray mt-3 float-end me-3"
                                                    [hidden]="!showCropper" (click)="uploadImage1()">上傳</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-header">大頭貼預覽</div>
                                    <div class="card-body" style="height: 350px; overflow-y: scroll;">
                                        <h5 [hidden]="!showCropper" class="card-title">移動匡線來調整位置</h5>
                                        <image-cropper [imageChangedEvent]="imageChangedEvent"
                                            [maintainAspectRatio]="true" [containWithinAspectRatio]="false"
                                            [cropperMinWidth]="128" [aspectRatio]="4.2/4.7" format="png"
                                            (imageCropped)="imageCropped1($event)" (imageLoaded)="imageLoaded($event)"
                                            (loadImageFailed)="loadImageFailed()" format="png"
                                            [style.display]="showCropper ? null : 'none'">
                                        </image-cropper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <tab heading="其他基本資料">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">護照號碼</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.PassPortNo">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">居留證號</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.ResidenceNo">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">稅務居住者識別碼</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.TaxIn">
                            </div>
                        </div>                                                 
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">原始居留證核准日</span>
                                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: white;"
                                [(ngModel)]="eMPMainDetail.ResidenceAllowDate">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">最新居留證核發日</span>
                                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: white;"
                                [(ngModel)]="eMPMainDetail.ResidenceLastAllowDate">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">居留期限</span>
                                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: white;"
                                [(ngModel)]="eMPMainDetail.ResidenceDeadline">
                            </div>
                        </div>                                                
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input pointer shadow-none" type="checkbox"
                                    id="inlineCheckbox1" value="option1" [(ngModel)]="eMPMainDetail.NewTaxWithheld">
                                <label class="form-check-label pointer" for="inlineCheckbox1">外國籍是否依新制所得扣繳方式扣繳</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-4">
                                    <span class="input-group-text">所得稅扣繳方式</span>
                                <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.IncomeTaxType">
                                    <option value="">請選擇</option>
                                    <option value="{{item.Item_No}}" *ngFor="let item of IncomeTaxTypeList">
                                        {{item.Item_Name}}
                                    </option>
                                </select>
                            </div>
                        </div>                        
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">固定扣所得稅%</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.IncomeTax">
                            </div>
                        </div>                     
                    </div>
                </tab>

                <tab heading="職務資料">
                    <div style="height:500px;overflow:scroll">
                        <button class='other-Button-gray mt-3 mb-3' style="width: 150px;"
                            (click)="addJob()">新增一筆</button>
                        <button *ngIf="eMPMainDetail.Jobs.length != 0" class='other-Button-gray mt-3 mb-3 me-3'
                            style="width: 120px;" (click)="deleteJob()">刪除第一筆</button>
                        <table class="table table-striped table-hover text-center">
                            <thead>
                                <tr class="thead-dark-red" style="width:135%;">
                                    <th style="width:24%;">單位</th>
                                    <th style="width:17%;">職稱</th>
                                    <th style="width:5%;">本職</th>
                                    <th style="width:9%;">專兼任</th>
                                    <th style="width:13%;">任職開始時間</th>
                                    <th style="width:13%;">任職結束時間</th>
                                    <th style="width:12%;">中文簽名檔</th>
                                    <th style="width:12%;">英文簽名檔</th>
                                    <th style="width:10%;"></th>
                                </tr>
                            </thead>
                            <tbody style="width:135%;">
                                <tr *ngFor="let item of eMPMainDetail.Jobs; let i = index">
                                    <td style="width:24%;">
                                        <select #UnitCode class="form-select form-select shadow-none"
                                            [(ngModel)]="item.UnitCode">
                                            <option value="">請選擇</option>
                                            <option value="{{item.ItemValue}}" *ngFor="let item of unitList">
                                                {{item.ItemName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style="width:17%;">
                                        <select #Title class="form-select form-select shadow-none"
                                            [(ngModel)]="item.Title">
                                            <option value="">請選擇</option>
                                            <option value="{{item.ItemValue}}" *ngFor="let item of titleList">
                                                {{item.ItemName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style="width:5%;">
                                        <input class="form-check-input pointer shadow-none" type="checkbox"
                                            id="inlineCheckbox1" value="option1" [(ngModel)]="item.MainJob">
                                        <label class="form-check-label pointer" for="inlineCheckbox1">是</label>
                                    </td>                                    
                                    <td style="width:9%;">
                                        <select class="form-select form-select shadow-none" [(ngModel)]="item.TimeCase">
                                            <option value="">請選擇</option>
                                            <option value="{{item.ItemValue}}" *ngFor="let item of timeCase">
                                                {{item.ItemName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td width="13%">
                                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                            bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: white;"
                                            [(ngModel)]="item.StartDate">
                                    </td>
                                    <td width="13%">
                                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                            bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: white;"
                                            [(ngModel)]="item.EndDate">
                                    </td>
                                    <td width="12%"><img *ngIf="item.SignPicC != ''"
                                            src="data:image/png;base64,{{item.SignPicC}}" height="38" width="133"
                                            alt="無法顯示圖片"></td>
                                    <td width="12%"><img *ngIf="item.SignPicE != ''"
                                            src="data:image/png;base64,{{item.SignPicE}}" height="38" width="133"
                                            alt="無法顯示圖片"></td>
                                    <td style="width:10%;"> <a class="pointer text-decoration-none float-end me-3 mt-3"
                                            (click)="openModal(template,UnitCode.value,Title.value)"> <b>簽名檔設定</b></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br><br><br>
                </tab>

                <tab heading="留職停薪資料">
                    <div style="height:400px;overflow:scroll">
                        <button class='other-Button-gray mt-3 mb-3' style="width: 150px;"
                            (click)="addHolds()">新增一筆</button>
                        <button *ngIf="eMPMainDetail.Holds.length != 0" class='other-Button-gray mt-3 mb-3 me-3'
                            style="width: 120px;" (click)="deleteHolds()">刪除第一筆</button>
                        <table class="table table-striped table-hover text-center">
                            <thead>
                                <tr class="thead-dark-red" style="width:100%;">
                                    <th style="width:14%;">申請日期</th>
                                    <th style="width:30%;">留職停薪事由</th>
                                    <th style="width:14%;">開始時間</th>
                                    <th style="width:14%;">結束時間</th>
                                    <th style="width:28%;">備註</th>
                                </tr>
                            </thead>
                            <tbody style="width:100%;">
                                <tr *ngFor="let item of eMPMainDetail.Holds; let i = index">
                                    <td style="width:14%;">
                                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                            bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: white;"
                                            [(ngModel)]="item.ApplyDate">
                                    </td>
                                    <td style="width:30%;">
                                        <input type="text" class="form-control pointer shadow-none"
                                            style="background-color: white;"
                                            [(ngModel)]="item.Reason">
                                    </td>
                                    <td width="14%">
                                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                            bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: white;"
                                            [(ngModel)]="item.StartDate">
                                    </td>
                                    <td width="14%">
                                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none"
                                            bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: white;"
                                            [(ngModel)]="item.EndDate">
                                    </td>
                                    <td style="width:28%;">
                                        <input type="text" class="form-control pointer shadow-none"
                                            style="background-color: white;"
                                            [(ngModel)]="item.Memo">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </tab>

                <tab heading="聯絡資訊">
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">銀行名稱</span>
                                <select class="form-select shadow-none" [(ngModel)]="eMPMainDetail.BankNo">
                                    <option value="">--請選擇--</option>
                                    <option *ngFor="let item of bankNo" value="{{item.ItemValue}}">
                                        {{item.ItemName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">銀行帳號</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.BankAccount">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">緊急聯絡人</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.LiaiseName">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">聯絡人電話</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.LiaiseTel">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">聯絡人關係</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.LiaiseRelation">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">聯絡人地址</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.LiaiseAddress">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">校內Email</span>
                                <input type="text" class="form-control shadow-none" [(ngModel)]="eMPMainDetail.EMail">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group mb-3">
                                    <span class="input-group-text">私人Email</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.OtherEmail">
                            </div>
                        </div>
                    </div>
                </tab>

                <tab heading="其他資訊">
                    <div class="row mt-3">
                        <div class="col-md-9">
                            <div class="input-group mb-3" style="display:none;">
                                    <span class="input-group-text">信仰宗教</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.Religion">
                            </div>
                            <div class="input-group mb-3">
                                    <span class="input-group-text">參加社團</span>
                                <input type="text" class="form-control shadow-none"
                                    [(ngModel)]="eMPMainDetail.Association">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="input-group flex-nowrap mb-3"
                                *ngFor="let x of eMPMainDetail.Columns; let i = index;">
                                    <span class="input-group-text">{{x.ColumnName}}</span>
                                <input type="text" class="form-control shadow-none" [(ngModel)]="x.ItemName">
                                <button class="btn btn-light shadow-none" (click)="openModal2(column)"
                                    (click)="indexModal(x.ColumnNo,i)">...</button>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
</div>
<ng-template #column>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定欄位</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th style="width: 60px;"></th>
                            <th></th>
                            <th>代碼編號</th>
                            <th>代碼名稱</th>
                        </tr>
                    </thead>
                    <tbody class="rtable1">
                        <tr *ngFor="let item of modal;let i=index" class="pointer" ng-model="all"
                            (click)="modalRef?.hide()" (click)="getchange(item,num)">
                            <td>{{i+1}}</td>
                            <td>{{item.ItemNo}}</td>
                            <td>{{item.ItemName}}</td>
                        </tr>
                    </tbody>
                </table>
                <input type="hidden" [(ngModel)]="num">
            </div>
        </div>
    </div>
</ng-template>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定簽名檔</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">簽名檔</div>
                    <div class="card-body" style="height: 350px;">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <input type="file" id="image" accept=".png" class="form-control shadow-none"
                                        (change)="fileChangeEvent($event)" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">語言</span>
                                    <select class="form-select shadow-none" #signatureType>
                                        <option value="C">中文簽名檔</option>
                                        <option value="E">英文簽名檔</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <img *ngIf="signatureType.value == 'E'" [src]="croppedImageE" class="card-img-top" />
                                <img *ngIf="signatureType.value == 'C'" [src]="croppedImageC" class="card-img-top" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button class="other-Button-gray mt-3 float-end" [hidden]="!showCropper"
                                    (click)="clearImage()">清除</button>
                                <button class="other-Button-gray mt-3 float-end me-3" [hidden]="!showCropper"
                                    (click)="uploadImage(signatureType.value,unitCode,Title)">上傳</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">簽名檔預覽</div>
                    <div class="card-body" style="height: 350px; overflow-y: scroll;">
                        <h5 [hidden]="!showCropper" class="card-title">移動匡線來調整位置</h5>
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                            [containWithinAspectRatio]="false" [cropperMinWidth]="128" [aspectRatio]="7/2" format="png"
                            (imageCropped)="imageCropped($event, signatureType.value)"
                            (imageLoaded)="imageLoaded($event)" (loadImageFailed)="loadImageFailed()" format="png"
                            [style.display]="showCropper ? null : 'none'">
                        </image-cropper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #Exporttemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">匯出資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header">基本欄位</div>
                    <div class="card-body" style="height: 300px; overflow-y: scroll;">
                        <div class="list-group">
                            <label class="list-group-item">
                                <input class="form-check-input shadow-none me-1" type="checkbox" checked disabled>
                                人事代碼
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input shadow-none me-1" type="checkbox" checked disabled>
                                中文姓名
                            </label>                            
                            <label class="list-group-item">
                                <input class="form-check-input shadow-none me-1" type="checkbox" checked disabled>
                                身分證號
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input shadow-none me-1" type="checkbox" checked disabled>
                                生日
                            </label>                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">
                        <label>選擇性欄位</label>
                        <label>&nbsp;&nbsp;&nbsp;</label>
                        <input class="form-check-input pointer shadow-none" type="checkbox" id="inlineCheckbox1" (click)="setAll($event.target)">
                        <label class="form-check-label pointer" for="inlineCheckbox1">全選</label>  
                    </div>
                    <div class="card-body" style="height: 300px; overflow-y: scroll;">
                        <div class="row">
                            <div class="col-md-6">
                                <ng-container *ngFor="let item of exportfield1">
                                    <label class="list-group-item pointer">
                                        <input class="form-check-input shadow-none me-1" type="checkbox"
                                            value="{{item.ItemValue}}" [(ngModel)]="item.Checked" (click)="setField(item.ItemValue)">
                                        {{item.ItemName}}
                                    </label>
                                </ng-container>
                            </div>
                            <div class="col-md-6">
                                <ng-container *ngFor="let item of exportfield2">
                                    <label class="list-group-item pointer">
                                        <input class="form-check-input shadow-none me-1" type="checkbox"
                                            value="{{item.ItemValue}}" [(ngModel)]="item.Checked" (click)="setField(item.ItemValue)">
                                        {{item.ItemName}}
                                    </label>
                                </ng-container>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12 text-center">
                <button class="other-Button-gray" (click)="exportReport()">匯出</button>
            </div>
        </div>
    </div>
</ng-template>