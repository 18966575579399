import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetCURTchMoneyList } from 'src/app/_Models/AD/ADCUR/ADCUR0502/getCURTchMoneyList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUR0502',
    templateUrl: './ADCUR0502.component.html',
    styleUrls: ['./ADCUR0502.component.css']
})
export class ADCUR0502Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    unitData!: GetSelectList[];
    tchTitle!: GetSelectList[];
    unitTitle!: GetSelectList[];

    dataKeyArray: Array<string> = [];
    isEdit: boolean = false;
    checkAll: boolean = false;
    condition: any = {
        Years: '',
        Term: '',
        UnitCode: '',
        TchNo: '',
        TchName: ''
    };

    dataList!: GetCURTchMoneyList[];
    data: GetCURTchMoneyList = {
        DataKey: '',
        Years: '',
        Term: '',
        TchNo: '',
        TchName: '',
        TchUnit: '',
        UnitName: '',
        TchTitle: '',
        TimeCase: '',
        checked: false
    };

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService) { }

    ngOnInit(): void {
        this.initYears();
        this.initUnitList();
    }

    initYears(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    initUnitList() {
        this.systemService.getUnitList(this.condition.Years, this.condition.Term, 'B').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.unitData = response;
        });
    }

    getCURTchMoneyList() {

        if (this.condition_validition()) {
            this.toastr.info("請選擇必要條件");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0502/Get_CUR_TchMoney_List", this.condition).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.dataList = [];
                return;
            }
            this.dataList = response;
            this.dataList.forEach(element => {
                element.checked = false;
            });
        });
    }

    getDetail(index: number) {
        this.data = this.dataList[index];
    }

    setCheckAll(index: number) {
        if (index == -1) {
            if (this.checkAll) {
                this.checkAll = false;
            } else {
                this.checkAll = true;
            }
            this.dataList.forEach(element => {
                element.checked = this.checkAll;
            });
        } else {
            this.dataList[index].checked = true;
        }
    }

    getCURTchMoneyModify() {
        if (this.condition_validition()) {
            this.toastr.info("請選擇必要項");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0502/Get_CUR_TchMoney_Modify", this.data).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.getCURTchMoneyList();
        })
    }

    getCURTchMoneyImport() {
        if (this.condition_validition()) {
            this.toastr.info("請選擇必要項");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0502/Get_CUR_TchMoney_Import", {Years:this.condition.Years,Term:this.condition.Term}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("轉入成功");
        })
    }

    condition_validition(): boolean {
        if (this.condition.Years == '') return true;
        if (this.condition.Term == '') return true;
        return false;
    }

    data_validition(): boolean {
        if (this.data.DataKey == '') return true;
        if (this.data.Years == '') return true;
        if (this.data.Term == '') return true;
        if (this.data.TchNo == '') return true;
        return false;
    }

}
