import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW40004',
    templateUrl: './STDW40004.component.html',
    styleUrls: ['./STDW40004.component.css']
})
export class STDW40004Component implements OnInit {
    baseUrl = environment.apiUrl;
    data!: Get_Cur_List[];
    buildingList!: GetSelectList[];
    classList!: GetSelectList[];
    functionType = 'classroom';
    classroom = '';
    model = {
        Years: '',
        Term: '',
        FormType: '05',
        KeyValue: ''
    }
    constructor(private http: HttpClient, private systemService: SystemService, private toastr: ToastrService, private timeService: TimeService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Term = response.Now_Term;
            this.model.Years = response.Now_Years;
            this.getBuildingList();
        });
    }



    getBuildingList() {
        this.http.post<any>(this.baseUrl + "Public/Get_Building_List", { Years: this.model.Years, Term: this.model.Term }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning("查無資料");
                return;
            }
            this.buildingList = response;
        });
    }


    getClassRoomList(event: any) {
        let building = <HTMLInputElement>event.target;
        this.http.post<any>(this.baseUrl + "Public/Get_ClassRoom_List", {
            Years: this.model.Years,
            Term: this.model.Term,
            KeyValue: building.value
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning("查無資料");
                return;
            }
            this.classList = response;
        })
    }

    getCurList() {
        if (this.classroom == '') {
            this.toastr.warning("請選擇教室");
            return;
        }
        this.model.KeyValue = this.classroom;
        this.http.post<any>(this.baseUrl + "Public/Get_CurForm", this.model)
            .subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning("查無資料");
                    return;
                }
                this.data = response;
            },
                error => this.toastr.error(error.error)
            );
    }

    setModel(model: any) {
        this.model.Years = model.Years;
        this.model.Term = model.Term;
    }

}
