import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW50002',
    templateUrl: './STDW50002.component.html',
    styleUrls: ['./STDW50002.component.css']
})
export class STDW50002Component implements OnInit {

    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    data!: Get_Cur_List[];
    functionType = "homework";
    model = {
        Years: '',
        Term: ''
    }

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private timeService: TimeService, private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
            this.getCurList(this.model);
        });
    }

    getCurList(model: any) {
        model.ClassForm = false;
        this.http.post<Get_Cur_List[]>(this.baseUrl + "ACAD/STDWEB/STDW500_01/Get_EL_CurForm", model)
            .subscribe((response) => {
                this.data = response;
            },
                error => this.toastr.error(error.error)
            );
    }

}
