<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.Years">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" name="Term" [(ngModel)]="getStdData.Term">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">獎懲</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.HType" (ngModelChange)="GetData()">
                    <option value="" selected disabled>--請選擇--</option>
                    <option value="1">獎勵</option>
                    <option value="2">懲處</option>
                </select>
            </div>
        </div>

    </div>
    <div class="row">
        <div>
            <button class="export-btn mx-auto" (click)="exportReportADSRP0305()">匯出 </button>
        </div>
    </div>
</div>