import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW30008',
    templateUrl: './STDW30008.component.html',
    styleUrls: ['./STDW30008.component.css']
})
export class STDW30008Component implements OnInit {
    hidden = true;
    hidden1 = true;
    modalRef!: BsModalRef;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    deptList!: GetDeptList[];
    constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService, private systemService: SystemService, private timeService: TimeService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
    }

    getDeptList(years: string, term: string) {
        this.systemService.getDeptList('', '2', years, term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error))
    }


    hide() {
        this.hidden = !this.hidden;
    }

    hide1() {
        this.hidden1 = !this.hidden1;
        this.modalRef.hide();
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-lg'
        };

        this.timeService.getTimeCntr().subscribe((response) => {
            this.getDeptList(response.Now_Years, response.Now_Term);
        })

        this.modalRef = this.modalService.show(template, modalConfig);
    }

}
