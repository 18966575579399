
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADSSS0204',
  templateUrl: './ADSSS0204.component.html',
  styleUrls: ['./ADSSS0204.component.css']
})
export class ADSSS0204Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}