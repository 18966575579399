<div class="container" style="margin-bottom: 100px;">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">獎懲代碼</span>
                    </div>
                    <select  class="form-select shadow-none" [(ngModel)]="search_data.R_Code">
                        <option value="">全部</option>
                        <option *ngFor="let a of data_detail" [value]="a.Rcode">{{a.Rname}}</option>
                    </select>
                    <div class="input-group-prepend">
                        全部表示查詢所有類別
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">獎懲建議敘述</span>
                    </div>
                    <input type="text" class="form-control " [(ngModel)]="search_data.SugName">
                    <div class="input-group-prepend">
                        空白表示查詢所有敘述
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-8">
                <button type="button" class="search-btn float-start me-3" (click)="search()">查詢</button>
            </div>
            <div class="col-md-4">
                <button type="button" class="other-Button-gray float-end me-3" (click)="openModal(column)">新增</button>
                <button type="button" class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
                <button type="button" class="other-Button-gray float-end me-3" (click)="update()">儲存</button>
            </div>
        </div>
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" (click)="setDatakey($event.target)" class="pointer">&ensp;全選</th>
                <th>代碼</th>
                <th>獎懲類別</th>
                <th>獎懲建議敘述</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of data;let i = index">
                <td><input type="checkbox" class="text-center" (click)="choose_delete(i,a.K_Delete)" [(ngModel)]="a.checked"></td>
                <td >{{a.R_Code}}</td>
                <td >{{a.SugCode}}</td>
                <td><input type="text" [(ngModel)]="a.SugName" size="70"></td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #column>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定欄位</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">獎懲代碼</span>
                    </div>
                    <select  class="form-select shadow-none" [(ngModel)]="insert_data.R_Code">
                        <option *ngFor="let a of data_detail" [value]="a.Rcode">{{a.Rname}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">獎懲類別</span>
                    </div>
                    <input type="text" class="form-control " [(ngModel)]="insert_data.SugCode">
                    <div class="input-group-prepend">
                        請填入數字
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">獎懲建議敘述</span>
                    </div>
                    <input type="text" class="form-control " [(ngModel)]="insert_data.SugName">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
            </div>
            <div>
                <button type="button" class="other-Button-gray float-end me-3" (click)="modalRef?.hide()" (click)="save()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>