import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetCURTchMoneyResultList } from 'src/app/_Models/AD/ADCUR/ADCUR0506/getCURTchMoneyResultList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUR0506ISU',
    templateUrl: './ADCUR0506ISU.component.html',
    styleUrls: ['./ADCUR0506ISU.component.css']
})
export class ADCUR0506ISUComponent implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    timeCaseList!: GetSelectList[];
    titleList!: GetSelectList[];
    unitData!: GetSelectList[];
    isCheckAll: boolean = false;

    condition: any = {
        Years: '110',
        Term: '1',
        TimeCase: '',
        UnitCode: '',
        TchNo: '',
        TchName: '',
        Title: ''
    };

    dataList!: GetCURTchMoneyResultList[];

    dataKeyArray: Array<string> = [];

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
        this.initYears();
        this.initTimeCase();
        this.initTitleList();
        this.initUnitList();
    }

    initYears(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    initTimeCase() {
        this.systemService.getTimeCase().subscribe((response) => {
            if (response.Info == false) {
                return;
            };
            this.timeCaseList = response;
        })
    }

    initTitleList() {
        this.systemService.getTitleList('B').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            };
            this.titleList = response;
        })
    }

    initUnitList() {
        this.systemService.getUnitList('', '', 'B').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.unitData = response;
        })
    }

    condition_validition(): boolean {
        if (this.condition.Years == '') return true;
        if (this.condition.Term == '') return true;

        return false;
    }

    getCURTchMoneyResultList() {

        if (this.condition_validition()) {
            this.toastr.info("請選擇學年、學期");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0506ISU/Get_CUR_TchMoney_ResultList", this.condition).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.dataList = [];
                return;
            };
            this.dataList = response;
            this.dataList.forEach(element => {
                element.checked = false;
            });
        });
    }

    getCURTchMoneyCompute() {
        // if (this.condition_validition()) {
        //     this.toastr.info("請選擇學年、學期");
        //     return;
        // }
        if (this.dataList.filter(data => data.checked == true).length == 0) {
            this.toastr.info("請至少選擇一筆");
            return;
        }


        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0506ISU/Get_CUR_TchMoney_Compute", this.dataList.filter(data => data.checked == true)).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            };
            this.toastr.success("計算完成");
        });
    }

    checkAll(): void {
        if (this.isCheckAll) {
            this.isCheckAll = false;
        } else {
            this.isCheckAll = true;
        }

        this.dataList.forEach(data => {
            data.checked = this.isCheckAll;
        });
    }

    setDatakey(type: string, DataKey: string, event: any) {
        if (!this.dataList) {

            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {

            if (this.dataKeyArray.length != this.dataList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.dataList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                });
            }
            else {
                this.dataKeyArray = [];
                this.dataList.forEach(element => {
                    element.checked = false;
                });
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(DataKey);
            if (index == -1) {
                this.dataKeyArray.push(DataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

}
