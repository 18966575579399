import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCUROpen01Time } from 'src/app/_Models/AD/ADCUR/ADCUR0202/getCUROpen01Time';
import { GetCURArrangeDetail } from 'src/app/_Models/AD/ADCUR/ADCUR0301/getCURArrangeDetail';
import { GetCURArrangeList, Week } from 'src/app/_Models/AD/ADCUR/ADCUR0301/getCURArrangeList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEmployeeList } from 'src/app/_Models/getEmployeeList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0104/search';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUR0301',
    templateUrl: './ADCUR0301.component.html',
    styleUrls: ['./ADCUR0301.component.css']
})
export class ADCUR0301Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    htmlTemplate = '';
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    YearsList!: Array<string>;
    modalRef?: BsModalRef;
    deptList!: GetDeptList[];
    classList?: GetClassList[];
    cURArrangeList!: GetCURArrangeList;
    cURArrangeDetail!: GetCURArrangeDetail;
    cUROpen01Time!: GetCUROpen01Time;
    employeeList!: GetEmployeeList[];
    data!: Search[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        Class: new UntypedFormControl('', [Validators.required])
    });
    tempModel = {
        DataKey: '',
        index: 0
    }
    tchListIndex = 0;
    classRoomListIndex = 0;
    curListIndex = 0;
    thisDeptNo="";

    constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService, private systemService: SystemService) { }

    ngOnInit(): void {
        this.getYears();
        this.getCUROpen01Time();
        this.getEmployeeList();
        this.getClassRoom();
    }

    getYears(): void {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.YearsList = response;
            }
        });
    }

    getClassRoom(): void {
        this.data = [];
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0104/Search", {
            ClassArea: '',
            ClassKind: '',
            ClassType: ''
        }).subscribe((response) => {

            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response;
        });
    }

    getEmployeeList() {
        this.systemService.getEmployeeList().subscribe((response) => {
            this.employeeList = response;
        });
    }

    getCUROpen01Time() {
        this.http.get<any>(this.baseUrl + "ACAD/CUR/ADCUR0301/Get_CUR_Open01_Time").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUROpen01Time = response;
            this.getDeptList(this.cUROpen01Time.Years, this.cUROpen01Time.Term);
        });
    }


    getDeptList(Years: string, Term: string) {

        if (Years == '' || Term == '') {
            return;
        }

        this.systemService.getDeptList('', '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList(dept: string, Years: string, Term: string) {
        this.systemService.getClassList('', dept, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))
    }

    getCURArrangeList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇學年、學期、班級");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0301/Get_CUR_Arrange_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cURArrangeList = response;

            this.cURArrangeList.CosData.forEach(element => {
                element.Selected = false;
            });

            if (this.cURArrangeDetail) {
                this.getCURArrangeDetail(this.tempModel.DataKey, this.tempModel.index);
            }
        });
    }

    getCURArrangeDetail(DataKey: string, index: number) {

        this.tempModel.DataKey = DataKey;
        this.tempModel.index = index;
        this.curListIndex = index;
        this.cURArrangeList.CosData.forEach(element => {
            element.Selected = false;
        });
        this.cURArrangeList.CosData[index].Selected = true;

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0301/Get_CUR_Arrange_Detail", { DataKey: DataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.cURArrangeDetail = response;

            this.setCurTime(index);
        });
    }

    openModal(template: TemplateRef<any>, type: string, index: number) {

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };

        if (type == 'classRoom') {
            this.classRoomListIndex = index;
            modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-lg'
            };
        }
        else {
            this.tchListIndex = index;
        }
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setCurTime(index: number) {

        let td = document.getElementsByName('curTd');

        td.forEach(element => {
            element.style.backgroundColor = "white";
        });

        let curTime = this.cURArrangeList.CosData[index].CurTime.split(' ');

        curTime.forEach(element => {
            let td = document.getElementById(element);
            if (td) td.style.backgroundColor = "yellow";
        });
    }

    onShown(week: Week[] | null) {
        if (week) {
            this.htmlTemplate = '';
            week.forEach(element => {
                this.htmlTemplate += `<span>開課序號:${element.Serial}</span><br>`
            });
        }
        else {
            this.htmlTemplate = '無';
        }
    }

    getCURArrangeDelete(type: string, event: MouseEvent) {

        if (!this.cURArrangeDetail) {
            this.toastr.info("請先選擇欲取消的課程");
            return;
        }

        if (type == 'all') {
            Swal.fire({
                title: `確定取消${this.cURArrangeDetail.CosData.CosName}的排課？`,
                showCancelButton: true,
                cancelButtonText: '取消',
                confirmButtonText: '確定'
            }).then(result => {
                if (result.isConfirmed) {
                    this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0301/Get_CUR_Arrange_Delete", { DataKey: this.cURArrangeDetail.CosData.DataKey, CurTime: '' }).subscribe((response) => {
                        if (response.Info == false) {
                            this.toastr.warning(response.Message);
                            return;
                        }
                        this.toastr.success("取消排課成功");
                        this.getCURArrangeList();
                    })
                } else {
                    this.toastr.info("取消動作");
                }
            })
        } else {
            let element = <HTMLElement>event.target
            Swal.fire({
                title: `確定取消${element.id}的排課？`,
                showCancelButton: true,
                cancelButtonText: '取消',
                confirmButtonText: '確定'
            }).then(result => {
                if (result.isConfirmed) {
                    this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0301/Get_CUR_Arrange_Delete", { DataKey: this.cURArrangeDetail.CosData.DataKey, CurTime: element.id }).subscribe((response) => {
                        if (response.Info == false) {
                            this.toastr.warning(response.Message);
                            return;
                        }
                        this.toastr.success("取消排課成功");
                        this.getCURArrangeList();
                    })
                } else {
                    this.toastr.info("取消動作");
                }
            })
        }

    }

    SelectTch(thisEmployee:any){
        Swal.fire({
            title: '確定置換教師為['+thisEmployee.Name+']？',
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
                this.getCURArrangeReplaceTch(thisEmployee.Code);
            } else {
                this.toastr.info("取消動作");
            }
        })
    }

    getCURArrangeReplaceTch(code: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0301/Get_CUR_Arrange_ReplaceTch", {
            DataKey: this.cURArrangeDetail.TchList[this.tchListIndex].DataKey,
            NewTchNo: code
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("設定成功");
            this.getCURArrangeDetail(this.tempModel.DataKey, this.tempModel.index);
            this.modalRef?.hide();
        })
    }

    getCURArrangeReplaceClassroom(code: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0301/Get_CUR_Arrange_ReplaceClassroom", {
            DataKey: this.cURArrangeDetail.ClassroomList[this.tchListIndex].DataKey,
            NewClassroom: code
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("設定成功");
            this.getCURArrangeDetail(this.tempModel.DataKey, this.tempModel.index);
            this.modalRef?.hide();
        })
    }

    getCURArrangeModify() {

        this.cURArrangeDetail.CosData.CurTime = this.cURArrangeList.CosData[this.curListIndex].CurTime;

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0301/Get_CUR_Arrange_Modify", this.cURArrangeDetail).subscribe((response) => {
            console.log(this.cURArrangeDetail);

            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }


            this.toastr.success("儲存成功");
            this.getCURArrangeList();
        })
    }

}
