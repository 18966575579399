import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


import { GetSelectList } from 'src/app/_Models/getSelectList';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0104/search';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUP0104',
    templateUrl: './ADCUP0104.component.html',
    styleUrls: ['./ADCUP0104.component.css']
})
export class ADCUP0104Component implements OnInit {
    baseUrl = environment.apiUrl;
    data!: Search[];
    readonly!: boolean;
    dataDetail: Search = {
        ClassArea: '',
        ClassArea_Name: '',
        ClassKind: '',
        ClassKind_Name: '',
        Classroom: '',
        Classroom_Name: '',
        ClassType: '',
        ClassType_Name: '',
        Capacity: 0,
        Class_Cols: 0,
        Class_Rows: 0,
        Memo: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
    }

    Condition_ClassArea_List!: GetSelectList[];
    Condition_ClassKind_List!: GetSelectList[];
    Condition_ClassType_List!: GetSelectList[];

    Data_ClassArea_List!: GetSelectList[];
    Data_ClassKind_List!: GetSelectList[];
    Data_ClassType_List!: GetSelectList[];

    Condition_ClassArea: string = '';
    Condition_ClassKind: string = '';
    Condition_ClassType: string = '';

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService) {

    }

    ngOnInit(): void {
        this.readonly = false;
        this.Page_Init();
    }

    Page_Init(): void {
        //校區
        this.systemService.getCampus().subscribe((response) => {
            this.Condition_ClassArea_List = response;
            this.Data_ClassArea_List = response;
        });
        //類型
        this.systemService.getClassType().subscribe((response) => {
            this.Condition_ClassType_List = response;
            this.Data_ClassType_List = response;
        });
    }

    //校區觸發 => 大樓
    ClassArea_Onchange(ClassArea: string, IsQuery: boolean) {

        this.systemService.getClassKind(ClassArea).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (IsQuery) {
                this.Condition_ClassKind_List = response;
                return;
            }
            this.Data_ClassKind_List = response;


        });
    }

    search(): void {
        this.data = [];
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0104/Search", {
            ClassArea: this.Condition_ClassArea,
            ClassKind: this.Condition_ClassKind,
            ClassType: this.Condition_ClassType
        }).subscribe((response) => {

            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.data = response;
            this.clear();
        });
    }

    searchDetail(Classroom: string, index: number) {
        this.readonly = true;
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0104/Search_Detail", { Classroom: Classroom }).subscribe((response) => {

            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.dataDetail = response;
            this.ClassArea_Onchange(this.dataDetail.ClassArea, false);
        });
    }

    insert(): void {
        if (
            this.dataDetail.Classroom == '' ||
            this.dataDetail.Classroom_Name == '' ||
            this.dataDetail.ClassArea == '' ||
            this.dataDetail.ClassKind == '' ||
            this.dataDetail.ClassType == ''
        ) {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0104/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search();
        }, error => this.toastr.error(error.error))
    }

    update(): void {
        if (
            this.dataDetail.Classroom == '' ||
            this.dataDetail.Classroom_Name == '' ||
            this.dataDetail.ClassArea == '' ||
            this.dataDetail.ClassKind == '' ||
            this.dataDetail.ClassType == ''
        ) {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0104/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.search();
        }, error => this.toastr.error(error.error))
    }

    delete() {
        if (this.dataDetail.Classroom == '') {
            this.toastr.info("請選擇教室");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0104/Delete", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.search();
            this.clear();
        }, error => this.toastr.error(error.error))
    }

    clear() {
        this.readonly = false;
        this.dataDetail = {
            ClassArea: '',
            ClassArea_Name: '',
            ClassKind: '',
            ClassKind_Name: '',
            Classroom: '',
            Classroom_Name: '',
            ClassType: '',
            ClassType_Name: '',
            Capacity: 0,
            Class_Cols: 0,
            Class_Rows: 0,
            Memo: '',
            Update_User: '',
            Update_Time: '',
            Selected: false
        }

        this.data.forEach(element => {
            element.Selected = false;
        });
    }
}
