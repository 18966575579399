<div class="container-fluid" style="margin-bottom: 100px;">
    <app-year-term-input (childEvent)="getMoneyList($event)"></app-year-term-input>
    <table class="table table-hover table-striped mt-3 table-rwd text-center">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'學年'|translate}}</th>
                <th>{{'學期'|translate}}</th>
                <th>{{'單位代碼'|translate}}</th>
                <th>{{'所屬單位'|translate}}</th>
                <th>{{'職稱'|translate}}</th>
                <th>{{'姓名'|translate}}</th>
                <th>{{'行政職稱'|translate}}</th>
                <th>{{'應授'|translate}}</th>
                <th>{{'減授'|translate}}</th>
                <th>{{'實授'|translate}}</th>
                <th>{{'支領超支/兼課時數'|translate}}</th>
                <th>{{'義務實數'|translate}}</th>
                <th>{{'不足時數'|translate}}</th>
                <th>{{'夜間時數'|translate}}</th>
                <th>{{'EMBA時數'|translate}}</th>
                <th>{{'備註'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of moneyList">
                <td data-th="學年">{{item.Years}}</td>
                <td data-th="學期">{{item.Term}}</td>
                <td data-th="單位代碼">{{item.Unit_Code1}}</td>
                <td data-th="所屬單位">{{item.Unit_Name}}</td>
                <td data-th="職稱">{{item.Tch_Level_Name}}</td>
                <td data-th="教師姓名">{{item.TchName}}</td>
                <td data-th="兼任行政職稱">{{item.Title_Name_Admin}}</td>
                <td data-th="每周應授">{{item.BasicHours}}</td>
                <td data-th="兼職行政減授">{{item.DelHours}}</td>
                <td data-th="每周實授">{{item.DHours}}</td>
                <td data-th="支領超支/兼課時數">{{item.DPay}}</td>
                <td data-th="義務實數">{{item.DDuty}}</td>
                <td data-th="不足基本時數">{{item.lessHours}}</td>
                <td data-th="夜間授課時數">{{item.NPay}}</td>
                <td data-th="EMBA授課時數">{{item.EmbaHours}}</td>
                <td data-th="備註">{{item.Memo}}</td>
            </tr>
        </tbody>
    </table>
</div>