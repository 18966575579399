import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GetHelpItemList, GetHelpItemSave, GetHelpItemID } from 'src/app/_Models/AD/ADSYS/ADSYS0302/getHelpItemList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSYS0302',
    templateUrl: './ADSYS0302.component.html',
    styleUrls: ['./ADSYS0302.component.css']
})
export class ADSYS0302Component implements OnInit {

    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    helpItemList!: GetHelpItemList[];
    helpSubItemList!: GetHelpItemList[];
    helpOperationItemList!: GetHelpItemList[];
    helpStepItemList!: GetHelpItemList[];
    helpDetail: GetHelpItemSave = {
        Type: '',
        ID: '',
        ItemData: []
    };

    CurrentSysKey!:string;
    CurrentSysSubKey!:string;
    CurrentOperationKey!:string;
    CurrentSysIndex!:number;
    CurrentSysSubIndex!:number;

    myParameter:GetHelpItemID={SysSub:'SysSub',Operation:'Title',Step:'Main'}

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService,private modalService: BsModalService) { }

    ngOnInit(): void {
        this.getHelpItemList();
    }

    getHelpItemList() {
        this.helpSubItemList=[];
        this.http.get<any>(this.baseUrl + "ACAD/SYS/ADSYS0302/Get_Help_ItemList_Sys").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.helpItemList = response;
        })
    }

    getHelpSubItemList(DataKey: string, index: number) {
        this.helpItemList.forEach(element => {
            element.Selected = false;
        });

        if(index>=0){
            this.helpItemList[index].Selected = true;
        }
        this.CurrentSysKey=DataKey;
        this.CurrentSysIndex=index;
        this.helpSubItemList = [];
        this.helpOperationItemList = [];
        this.helpStepItemList = [];
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0302/Get_Help_ItemList", { DataKey: DataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.helpSubItemList = response;
        })
    }

    getHelpOperationItemList(DataKey: string,index: number) {
        this.helpSubItemList.forEach(element => {
            element.Selected = false;
        });

        if(index>=0){
            this.helpSubItemList[index].Selected = true;
        }
        this.CurrentSysSubKey=DataKey;
        this.CurrentSysSubIndex=index;
        this.helpOperationItemList = [];
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0302/Get_Help_ItemList_Operation", { DataKey: DataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.helpOperationItemList = response;
        })
    }

    getHelpStepItemList(DataKey: string) {
        this.CurrentOperationKey=DataKey;
        this.helpStepItemList = [];
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0302/Get_Help_ItemList_Step", { DataKey: DataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.helpStepItemList = response;
        })
    }

    getHelpDetail(DataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0302/Get_Help_Detail", { DataKey: DataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.helpDetail = response;
        })
    }

    getHelpModify(DataType:string,DataItem:any) {
        let CurrentDataKey='';
        this.clear();
        this.helpDetail.Type=DataType;
        switch(DataType){
            case '2':
                CurrentDataKey=this.CurrentSysKey;
                break;
            case '3':
                CurrentDataKey=this.CurrentSysSubKey;
                break;
            case '4':
                CurrentDataKey=this.CurrentOperationKey;
                break;
        }
        this.helpDetail.ID=CurrentDataKey;
        this.helpDetail.ItemData=DataItem;
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0302/Get_Help_Modify", this.helpDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            switch(DataType){
                case '1':
                    this.getHelpItemList();
                    break;
                case '2':
                    this.getHelpSubItemList(this.CurrentSysKey,this.CurrentSysIndex);
                    break;
                case '3':
                    this.getHelpOperationItemList(this.CurrentSysSubKey,this.CurrentSysSubIndex);
                    break;
                case '4':
                    this.getHelpStepItemList(this.CurrentOperationKey);
                    break;
            }
        })
    }

    getHelpDelete(DataKey: string,DataType: string) {

        if (DataKey == '') {
            this.toastr.info("請選擇功能");
            return;
        }

        Swal.fire({
            title: "確定刪除整個項目？",
            showCancelButton: true,
            cancelButtonText: "取消",
            showConfirmButton: true,
            confirmButtonText: "確定"
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0302/Get_Help_Delete", { DataKey: DataKey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.clear();
                    switch(DataType){
                        case this.myParameter.SysSub:
                            this.getHelpSubItemList(this.CurrentSysKey,-1);
                            break;
                        case this.myParameter.Operation:
                            this.getHelpOperationItemList(this.CurrentSysSubKey,-1);
                            break;
                        case this.myParameter.Step:
                            this.getHelpStepItemList(this.CurrentOperationKey);
                            break;
                    }
                })
            }
            else {
                this.toastr.info("動作取消");
            }
        })
    }

    addItem(event: any, type: string, index: number) {
        event.stopPropagation();
        switch(type){
            case this.myParameter.Operation:
                this.helpOperationItemList.push({
                    DataKey: '',
                    ItemID:'',
                    ItemName:'',
                    OrderNo:99,
                    Selected:false
                })
                break;
            case this.myParameter.Step:
                this.helpStepItemList.push({
                    DataKey: '',
                    ItemID:'',
                    ItemName:'',
                    OrderNo:99,
                    Selected:false
                })
                break;
            case this.myParameter.SysSub:
                this.helpSubItemList.push({
                    DataKey: '',
                    ItemID:'',
                    ItemName:'',
                    OrderNo:99,
                    Selected:false
                })
                break;
        }
    }

    removeItem(event: any, type: string, DataKey: string) {
        event.stopPropagation();
        this.getHelpDelete(DataKey,type);
    }

    clear() {
        this.helpDetail = {
            Type: '',
            ID: '',
            ItemData: []
        };
    }

    showSample(template: TemplateRef<any>){
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-lg'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }
}
