import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetStdGradSchNoList } from 'src/app/_Models/AD/ADSTD/ADSTD0701/getStdGradSchNoList';
import { GetStdGradSchNoListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0701/getStdGradSchNoListInput';
import { GetStdGradSchNoSetADNo } from 'src/app/_Models/AD/ADSTD/ADSTD0701/getStdGradSchNoSetADNo';
import { GetStdGradSchNoUnSetList } from 'src/app/_Models/AD/ADSTD/ADSTD0701/getStdGradSchNoUnSetList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSTD0701',
    templateUrl: './ADSTD0701.component.html',
    styleUrls: ['./ADSTD0701.component.css']
})
export class ADSTD0701Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    month = require('src/app/_Models/_SelectInput/month.json').month;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    dataKeyArray: Array<string> = [];
    initialData = new InitialData(this.http);

    stdGradSchNoListInput: GetStdGradSchNoListInput = {
        Years: '',
        Term: '',
        GradYear: '',
        DeptNo: '',
        StdNoFrom: '',
        StdNoTo: '',
        StdName: ''
    };
    stdGradSchNoList!: GetStdGradSchNoList[];
    deptList!: GetDeptList[];
    itemLeft: GetStdGradSchNoUnSetList[] = [];
    itemRight: GetStdGradSchNoUnSetList[] = [];
    stdGradSchNoSetADNo!: GetStdGradSchNoSetADNo;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getDeptList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getStdGradSchNoList() {

        if (this.stdGradSchNoListInput.Years == '' || this.stdGradSchNoListInput.Term == '') {
            this.toastr.info("請選擇學年學期");
            return;
        }

        if (this.stdGradSchNoListInput.DeptNo == '') {
            this.toastr.info("請選擇系所");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_01/Get_Std_Grad_SchNo_List", this.stdGradSchNoListInput).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdGradSchNoList = []
                return;
            }
            this.stdGradSchNoList = response;
            this.stdGradSchNoList.forEach(element => {
                element.checked = false;
            });
        }, error => this.toastr.error(error.error))
    }

    getDeptList() {
        this.systemService.getDeptList('', '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    openModal(type: string, template: TemplateRef<any>) {

        let modalConfig!: any;

        if (type == 'ADNo') {
            if (!this.stdGradSchNoList) {
                this.toastr.info("請先查詢資料");
                return;
            }
            modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-lg'
            };
        }
        else {
            modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };
        }

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getStdGradSchNoUnSetList(Years: string, Term: string, DeptNo: string) {
        this.itemLeft = [];
        this.itemRight = [];
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_01/Get_Std_Grad_SchNo_UnSetList", {
            Years: Years,
            Term: Term,
            DeptNo: DeptNo
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.itemLeft = response;
        }, error => this.toastr.error(error.error))
    }

    setItems(type: string, event: any) {
        var result = [];
        var options = event && event.options;
        var opt;
        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];
            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        if (type == 'goRight') {
            result.forEach(element => {
                this.itemRight.push(this.itemLeft[parseInt(element)]);
            });
            this.itemLeft = this.itemLeft.filter(x => !this.itemRight.includes(x));
        }
        else if (type == 'goLeft') {
            result.forEach(element => {
                this.itemLeft.push(this.itemRight[parseInt(element)]);
            });
            this.itemRight = this.itemRight.filter(x => !this.itemLeft.includes(x));
        }
        else if (type == 'goRightAll') {
            let tempArray = this.itemLeft.filter(x => !this.itemRight.includes(x));
            tempArray.forEach(element => {
                this.itemRight.push(element);
            });
            this.itemLeft = [];
        }
        else {
            let tempArray = this.itemRight.filter(x => !this.itemLeft.includes(x));
            tempArray.forEach(element => {
                this.itemLeft.push(element);
            });
            this.itemRight = [];
        }
    }

    getStdGradSchNoUnSetInsert(Years: string, Term: string) {

        if (this.itemRight.length == 0) {
            this.toastr.info("請先選擇學生");
            return;
        }

        Swal.fire({
            title: `確定設定此${this.itemRight.length}位學生?`,
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                let dataKey = '';
                this.itemRight.forEach(element => {
                    dataKey = dataKey + element.DataKey + ',';
                });

                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_01/Get_Std_Grad_SchNo_UnSetInsert", {
                    Years: Years,
                    Term: Term,
                    StdList: dataKey.substring(0, dataKey.length - 1)
                }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.modalRef.hide();
                    this.getStdGradSchNoList()
                    this.itemLeft = [];
                    this.itemRight = [];
                    this.toastr.success("學生設定完成");
                })
            } else {
                this.toastr.info("已取消動作");
            }
        })
    }

    reset() {
        this.itemLeft = [];
        this.itemRight = [];
        this.dataKeyArray = [];
    }

    setDatakey(type: string, dataKey: string, event: any) {
        if (!this.stdGradSchNoList) {
            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.stdGradSchNoList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.stdGradSchNoList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.stdGradSchNoList.forEach(element => {
                    element.checked = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

    getStdGradSchNoSetCancel(Years: string, Term: string) {
        if (this.dataKeyArray.length == 0) {
            this.toastr.info("請先選擇學生");
            return;
        };
        let dataKey = '';
        this.dataKeyArray.forEach(element => {
            dataKey = dataKey + element + ',';
        });
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_01/Get_Std_Grad_SchNo_SetCancel", {
            Years: Years,
            Term: Term,
            StdList: dataKey.substring(0, dataKey.length - 1)
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.dataKeyArray = [];
            this.toastr.success("已註銷");
        })
    }

    getStdGradSchNoUndoCancel(Years: string, Term: string) {
        if (this.dataKeyArray.length == 0) {
            this.toastr.info("請先選擇學生");
            return;
        };
        let dataKey = '';
        this.dataKeyArray.forEach(element => {
            dataKey = dataKey + element + ',';
        });
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_01/Get_Std_Grad_SchNo_UndoCancel", {
            Years: Years,
            Term: Term,
            StdList: dataKey.substring(0, dataKey.length - 1)
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.dataKeyArray = [];
            this.toastr.success("已取消註銷");
        })
    }

    getStdGradSchNoDeleteCancel(Years: string, Term: string) {
        if (this.dataKeyArray.length == 0) {
            this.toastr.info("請先選擇學生");
            return;
        };

        Swal.fire({
            title: `確定刪除${this.dataKeyArray.length}筆資料?`,
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                let dataKey = '';
                this.dataKeyArray.forEach(element => {
                    dataKey = dataKey + element + ',';
                });
                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_01/Get_Std_Grad_SchNo_DeleteCancel", {
                    Years: Years,
                    Term: Term,
                    StdList: dataKey.substring(0, dataKey.length - 1)
                }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("已刪除");
                    this.dataKeyArray = [];
                })
            }
            else {
                this.toastr.info("動作已取消");
            }
        })
    }

    getStdGradSchNoSetADNo() {

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD07_01/Get_Std_Grad_SchNo_SetADNo", this.stdGradSchNoSetADNo).subscribe((response) => {
            if (this.dataKeyArray.length == 0) {
                this.toastr.info("請先選擇學生");
                return;
            };
            this.toastr.success("儲存成功");
            this.getStdGradSchNoList()
        })
    }

}
