<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <h5 for="">{{'目前選課學年期:'|translate}} {{'第'|translate}}{{modal.Sel_Years}}{{'學年'|translate}}{{modal.Sel_Term}}{{'學期'|translate}}</h5>
        </div>
        <div class="col-md-6">
            <div class="form-check form-check-inline">
                <input class="form-check-input pointer shadow-none" type="radio" name="flexRadioDefault"
                    id="flexRadioDefault1" value="1" checked [(ngModel)]="Status">
                <label class="form-check-label pointer" for="flexRadioDefault1">{{'預選或尚未開始'|translate}}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input pointer shadow-none" type="radio" name="flexRadioDefault"
                    id="flexRadioDefault2" value="2" [(ngModel)]="Status">
                <label class="form-check-label pointer shadow-none" for="flexRadioDefault2">{{'加退選階段'|translate}}</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input pointer shadow-none" type="radio" name="flexRadioDefault"
                    id="flexRadioDefault3" value="3" [(ngModel)]="Status">
                <label class="form-check-label pointer shadow-none" for="flexRadioDefault3">{{'暑修選課'|translate}}</label>
            </div>
        </div>
        <div class="col-md-3">
            <button class="float-end export-btn" (click)="getSELStdDataExport()">{{'匯出'|translate}}</button>
        </div>
    </div>


    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <button class="other-Button-gray float-start" (click)="getSELStdDataList()">{{'查學生'|translate}}</button>
                </div>
            </div>
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 80px;"><input type="checkbox" class="form-check-input pointer shadow-none"
                                (click)="setClassArray('all', '', $event.target)">&ensp;{{'全選'|translate}}</th>
                        <th>{{'班級代碼'|translate}}</th>
                        <th>{{'班級名稱'|translate}}</th>
                    </tr>
                </thead>
                <tbody style="height: 300px;">
                    <tr *ngFor="let item of classList">
                        <td style="width: 80px;"><input type="checkbox" class="form-check-input pointer shadow-none"
                                (click)="setClassArray('', item.Class, $event.target)" [(ngModel)]="item.checked"></td>
                        <td>{{item.Class}}</td>
                        <td>{{item.Class_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <button class="other-Button-gray float-start me-3" (click)="getSELStdDataDetail()">{{'查明細'|translate}}</button>
                </div>
            </div>
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 80px;"><input type="checkbox" class="form-check-input pointer shadow-none"
                                (click)="setStdArray('all', '', $event.target)">&ensp;{{'全選'|translate}}</th>
                        <th>{{'學號'|translate}}</th>
                        <th>{{'姓名'|translate}}</th>
                    </tr>
                </thead>
                <tbody style="height: 300px;">
                    <tr *ngFor="let item of sELStdDataList">
                        <td style="width: 80px;"><input type="checkbox" class="form-check-input pointer shadow-none"
                                (click)="setStdArray('', item.DataKey, $event)" [(ngModel)]="item.checked"></td>
                        <td>{{item.StdNo}}</td>
                        <td>{{item.StdName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <label class="text-danger">{{'*點選選課學分上下限可進行編輯'|translate}}</label>
    <div style="overflow-x: scroll;">
        <table class="table text-center table-hover table-striped table-sm table-bordered" style="width: 3100px;">
            <thead>
                <tr class="thead-dark-red">
                    <td colspan="5">{{'基本資料'|translate}}</td>
                    <td colspan="4">{{'選課學分'|translate}}</td>
                    <td colspan="2">{{'資格審查'|translate}}</td>
                    <td colspan="2">{{'興趣選修'|translate}}</td>
                    <td colspan="2">{{'體育相關'|translate}}</td>
                    <td colspan="4">{{'跨部互選'|translate}}</td>
                    <td colspan="2">{{'其他'|translate}}</td>
                </tr>
                <tr class="thead-dark-red">
                    <td>{{'學號'|translate}}</td>
                    <td>{{'姓名'|translate}}</td>
                    <td>{{'班級'|translate}}</td>
                    <td>{{'組別'|translate}}</td>
                    <td>{{'總修學分'|translate}}</td>
                    <td>{{'下限'|translate}}</td>
                    <td>{{'上限'|translate}}</td>
                    <td>{{'檢查學分'|translate}}</td>
                    <td>{{'已選'|translate}}</td>
                    <td>{{'輔系/雙主修資格'|translate}}</td>
                    <td>{{'教育學程資格'|translate}}</td>
                    <td>{{'可選學分'|translate}}</td>
                    <td>{{'已選學分'|translate}}</td>
                    <td>{{'學分上限'|translate}}</td>
                    <td>{{'已選學分'|translate}}</td>
                    <td>{{'學分上限'|translate}}</td>
                    <td>{{'已選學分'|translate}}</td>
                    <td>{{'總畢業學分比例'|translate}}</td>
                    <td>{{'總畢業學分'|translate}}</td>
                    <td>{{'是否申請學程'|translate}}</td>
                    <td>{{'是否完成教學評鑑'|translate}}</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of sELStdDataDetail; let i = index">
                    <td>{{item.StdNo}}</td>
                    <td>{{item.StdName}}</td>
                    <td>{{item.ClassName}}</td>
                    <td>{{item.TeamName}}</td>
                    <td>{{item.CreditAll}}</td>
                    <td class="pointer" (click)="openModal(template, i)">{{item.CreditMax}}</td>
                    <td class="pointer" (click)="openModal(template, i)">{{item.CreditMin}}</td>
                    <td>{{item.CreditCheck}}</td>
                    <td>{{item.CreditSel}}</td>
                    <td>{{item.IsAddDept}}</td>
                    <td>{{item.IsEduCourse}}</td>
                    <td>{{item.IntCreditMax}}</td>
                    <td>{{item.IntCreditSel}}</td>
                    <td>{{item.PhyCourseSelMax}}</td>
                    <td>{{item.PhyCourseSel}}</td>
                    <td>{{item.OverEduCreditMax}}</td>
                    <td>{{item.OverEduCreditSel}}</td>
                    <td>{{item.OverEduRate}}</td>
                    <td>{{item.OverEduCreditGrad}}</td>
                    <td>{{item.IsAcadCourse}}</td>
                    <td>{{item.IsFinishASM}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'設定選課學分'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <label>{{'學號:'|translate}} {{creditModel.stdno}}</label>
            </div>
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <span class="input-group-text">{{'上限'|translate}}</span>
                    <input type="number" class="form-control shadow-none" #max min="0" max="99"
                        [(ngModel)]="creditModel.max">
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <span class="input-group-text">{{'下限'|translate}}</span>
                    <input type="number" class="form-control shadow-none" #min min="0" max="99"
                        [(ngModel)]="creditModel.min">
                </div>
            </div>
        </div>
        <button class="save-btn-gray mx-auto" (click)="getSELStdDataSave(max.value, min.value)">{{'儲存'|translate}}</button>
    </div>
</ng-template>
