<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">職稱類別</span>
                </div>
                <select class="form-select shadow-none" #Type_Style (change)="search(Type_Style.value)">
                    <option value="">全部</option>
                    <option value="A">行政職稱</option>
                    <option value="B">教學職稱</option>
                </select>
            </div>
        </div>
        <div class="col-md-9">
            <button class="other-Button-gray float-end" (click)="reset()">清除</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="add-btn float-end me-3" (click)="insert()">新增</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-8">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th style="width:60px;">代碼</th>
                        <th>名稱</th>
                        <th style="width:120px;">授課時數</th>
                        <th style="width:120px;">減終點時數</th>
                        <th style="width:120px;">主管註記</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataList; let i = index" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" class="pointer" (click)="searchDetail(i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td style="width: 60px;">{{item.Title}}</td>
                        <td>{{item.Title_Name}}</td>
                        <td style="width:120px;">{{item.Tch_Hours}}</td>
                        <td style="width:120px;">{{item.Del_Hours}}</td>
                        <td style="width:120px;">{{item.ManagerMark}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">職稱代碼</span>
                        <input type="text" class="form-control shadow-none" required maxlength="4" placeholder="至多四碼" [(ngModel)]="data.Title" [disabled]="isEdit" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">職稱名稱</span>
                        <input type="text" class="form-control shadow-none" required [(ngModel)]="data.Title_Name" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">職稱英文名稱</span>
                        <input type="text" class="form-control shadow-none" required [(ngModel)]="data.Title_EngName">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <div class="input-group">
                            <span class="input-group-text">職稱類別</span>
                            <select class="form-select shadow-none" required [(ngModel)]="data.Title_Type">
                                <option value="A">行政職稱</option>
                                <option value="B">教學職稱</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">主管註記</span>
                        <select class="form-select shadow-none" [(ngModel)]="data.ManagerMark">
                            <option value="">無</option>
                            <option value="*">*</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">主管級別</span>
                        <select class="form-select shadow-none" [(ngModel)]="data.ManagerLevel">
                            <option value="9">無</option>
                            <option value="1">一級主管</option>
                            <option value="2">二級主管</option>
                        </select>
                    </div>
                </div>
            </div>            
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">授課時數</span>
                        <input type="number" class="form-control shadow-none" [(ngModel)]="data.Tch_Hours">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">減終點時數</span>
                        <input type="number" class="form-control shadow-none" [(ngModel)]="data.Del_Hours">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3" *ngFor="let x of hourSalaryList; let i = index;">
                        <div class="input-group">
                            <span class="input-group-text">{{x.TchMClassName}}</span>
                            <input type="text" class="form-control shadow-none" [(ngModel)]="x.HourSalary">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled [(ngModel)]="data.Update_Time">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled [(ngModel)]="data.Update_User">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
