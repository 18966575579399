import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetRedClassDetail } from 'src/app/_Models/AD/ADTFS/ADTFS0701/getRedClassDetail';
import { GetRedClassList } from 'src/app/_Models/AD/ADTFS/ADTFS0701/getRedClassList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADTFS0701',
  templateUrl: './ADTFS0701.component.html',
  styleUrls: ['./ADTFS0701.component.css']
})
export class ADTFS0701Component implements OnInit {

  baseUrl = environment.apiUrl;
  redClassList!: GetRedClassList[];
  redClassDetail: GetRedClassDetail = {
    RedClass_No: '',
    RedClass_Name: '',
    Tuition: 0,
    TuitionCapped: 0,
    Other: 0,
    OtherCapped: 0,
    Tuition_Percent: 0,
    Other_Percent: 0,
    Tch_No: '',
    RedClassCheck: '',
    Update_User: '',
    Update_Time: ''
  };

  constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService) { }

  ngOnInit(): void {
    this.getRedClassList();
  }


  getRedClassList() {
    this.systemService.getRedClassList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.redClassList = response;
      this.redClassList.forEach(element => {
        element.Selected = false;
      })
    }, error => this.toastr.error(error.error))
  }

  getRedClassDetail(RedClass_No: string, index: number) {

    this.redClassList.forEach(element => {
      element.Selected = false;
    })
    this.redClassList[index].Selected = true;

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0701/Search_Detail", { RedClass_No: RedClass_No }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.redClassDetail = response;
      this.redClassDetail.RedClass_Name = this.redClassDetail.RedClass_Name;
      this.redClassDetail.Update_Time = this.redClassDetail.Update_Time;
      this.redClassDetail.Update_User = this.redClassDetail.Update_User;
    }, error => this.toastr.error(error.error));
  }

  addData() {

    if (this.redClassDetail.RedClass_Name == '' ||
      this.redClassDetail.RedClass_No == '' ||
      this.redClassDetail.Tch_No == '') {
      this.toastr.info("欄位請輸入完整");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0701/Insert", this.redClassDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("新增成功");
      this.getRedClassList();
      this.redClassDetail = {
        RedClass_No: '',
        RedClass_Name: '',
        Tuition: 0,
        TuitionCapped: 0,
        Other: 0,
        OtherCapped: 0,
        Tuition_Percent: 0,
        Other_Percent: 0,
        Tch_No: '',
        RedClassCheck: '',
        Update_User: '',
        Update_Time: ''
      };
    })
  }

  deleteData() {
    if (this.redClassDetail.Update_Time == '' || this.redClassDetail.Update_User == '') return;

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0701/Delete", { RedClass_No: this.redClassDetail.RedClass_No }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("刪除成功");
      this.getRedClassList();
      this.redClassDetail = {
        RedClass_No: '',
        RedClass_Name: '',
        Tuition: 0,
        TuitionCapped: 0,
        Other: 0,
        OtherCapped: 0,
        Tuition_Percent: 0,
        Other_Percent: 0,
        Tch_No: '',
        RedClassCheck: '',
        Update_User: '',
        Update_Time: ''
      };
    })
  }

  saveData() {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0701/Update", this.redClassDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("更新成功");
      this.getRedClassList();
      this.redClassDetail = {
        RedClass_No: '',
        RedClass_Name: '',
        Tuition: 0,
        TuitionCapped: 0,
        Other: 0,
        OtherCapped: 0,
        Tuition_Percent: 0,
        Other_Percent: 0,
        Tch_No: '',
        RedClassCheck: '',
        Update_User: '',
        Update_Time: ''
      };
    })
  }

}
