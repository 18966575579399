import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetStdRecoverDetail } from 'src/app/_Models/AD/ADSTD/ADSTD0302/getStdRecoverDetail';
import { GetStdRecoverList } from 'src/app/_Models/AD/ADSTD/ADSTD0302/getStdRecoverList';
import { GetStdRecoverListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0302/getStdRecoverListInput';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetSTDStatusList } from 'src/app/_Models/getSTDStatusList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSTD0302',
    templateUrl: './ADSTD0302.component.html',
    styleUrls: ['./ADSTD0302.component.css']
})
export class ADSTD0302Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    RecdeptList!: GetDeptList[];
    classList!: GetClassList[];
    RecclassList!: GetClassList[];
    sTDStatusList!: GetSTDStatusList[];
    restReason!: GetSelectList[];
    checkType = new CheckType();
    stdRecoverDetail!: GetStdRecoverDetail;
    stdRecoverListInput: GetStdRecoverListInput = {
        EndYear: '',
        EndTerm: '',
        RecoverYear: '',
        RecoverTerm: '',
        Recover: '',
        EduNo: '',
        DeptNo: '',
        Class: '',
        Status: '',
        IDNo: '',
        StdNo: '',
        StdName: ''
    };
    stdRecoverList!: GetStdRecoverList[];
    bsConfig = environment.bsDatePickerConfig;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private systemService: SystemService,
        private localeService: BsLocaleService) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.getEduList();
        this.getRestReason();
    }

    getRestReason() {
        this.systemService.getRestReason().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.restReason = response;
        });
    }

    getSTDStatusList() {
        this.systemService.getSTDStatusList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sTDStatusList = response;
        }, error => this.toastr.error(error.error))
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getRecDeptList() {
        this.RecdeptList = [];
        this.systemService.getDeptList(this.stdRecoverDetail.EduNo, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.RecdeptList = response;

        }, error => this.toastr.error(error.error));
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))
    }

    getRecClassList(dept: string) {
        this.RecclassList=[];
        this.systemService.getClassList('', dept, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.RecclassList = response;

        }, error => this.toastr.error(error.error))
    }

    getStdRecoverList() {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_02/Get_StdRecover_List", this.stdRecoverListInput).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdRecoverList = []
                return;
            }
            this.stdRecoverList = response;
        })
    }

    getStdRecoverDetail(dataKey: string, template: TemplateRef<any>) {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_02/Get_StdRecover_Detail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdRecoverDetail = response;

            this.stdRecoverDetail.StdName = this.stdRecoverDetail.StdName;
            this.getRecDeptList();
            this.getRecClassList(this.stdRecoverDetail.RecoverDeptNo);
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };

            this.modalRef = this.modalService.show(template, modalConfig)

        this.getClassList(this.stdRecoverDetail.EduNo,this.stdRecoverDetail.DeptNo);
        })
    }

    getStdRecoverModify() {

        if (this.checkType.CheckIsNumber(this.stdRecoverDetail.RecoverYear) ||
            this.checkType.CheckIsNumber(this.stdRecoverDetail.EndYear) ||
            this.checkType.CheckIsNumber(this.stdRecoverDetail.ContrastYear)) {
            this.toastr.info("請輸入數字");
            return;
        }

        if (parseInt(this.stdRecoverDetail.RecoverYear + this.stdRecoverDetail.RecoverTerm) > parseInt(this.stdRecoverDetail.EndYear + this.stdRecoverDetail.EndTerm) ||
            parseInt(this.stdRecoverDetail.RecoverYear + this.stdRecoverDetail.RecoverTerm) < parseInt(this.stdRecoverDetail.RestYear + this.stdRecoverDetail.RestTerm)) {
            this.toastr.info("實復學年必須介於休學與應復學年");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_02/Get_StdRecover_Modify", this.stdRecoverDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.modalRef.hide();
            this.getStdRecoverList();
        });
    }

    caculate(years: string, term: string, termCount: string) {

        if (years == '' || term == '' || termCount == '') {
            return;
        }

        if (this.checkType.CheckIsNumber(termCount)) {
            this.toastr.info("休學期數請填入數字");
            this.stdRecoverDetail.TermCount = '';
            return;
        }

        var intTermCount = parseInt(termCount);
        var iYear;
        var iTerm;

        if (intTermCount % 2 == 0) {
            iYear = intTermCount / 2;
            iTerm = parseInt(term);
        }
        else {
            iYear = (intTermCount - 1) / 2;
            iYear += (term == "2") ? 1 : 0;
            iTerm = (term == "1") ? "2" : "1";
        }

        if (String(parseInt(years) + iYear).length == 2) {
            this.stdRecoverDetail.EndYear = '0' + String(parseInt(years) + iYear);
        }
        else {
            this.stdRecoverDetail.EndYear = String(parseInt(years) + iYear);
        }

        this.stdRecoverDetail.EndTerm = iTerm.toString();
    }

}
