<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group mb-3">
                <span class="input-group-text">人事代碼</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="condition.UserCode">
            </div>
        </div>
        <div class="col-md">
            <div class="input-group mb-3">
                <span class="input-group-text">中文姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="condition.UserName">
            </div>
        </div>
        <div class="col-md">
            <div class="input-group mb-3">
                <span class="input-group-text">帳號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="condition.Account">
            </div>
        </div>
        <div class="col-md">
            <div class="input-group mb-3">
                <span class="input-group-text">所屬單位</span>
                <select class="form-select shadow-none" [(ngModel)]="condition.UnitNo">
                    <option ngValue="" selected disabled>--請選擇--</option>
                    <option ngValue="{{item.ItemValue}}" *ngFor="let item of unitData">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md mb-3">
            <button type="button" class="other-Button-gray float-end">清除</button>
            <button class="search-btn float-end me-3" (click)="getSYSUserList()">查詢</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>人事代碼</th>
                        <th>姓名</th>
                    </tr>
                </thead>
                <tbody style="height: 633.5px">
                    <tr *ngFor="let item of sYSUserList; let i = index" (click)="getSYSUserFunDeptList(item.DataKey, item.UserCode , false)">
                        <td>{{i + 1}}</td>
                        <td>{{item.UserCode}}</td>
                        <td>{{item.UserName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-9">
            <tabset>
                <tab heading="系統功能">
                    <div class="row mt-3">
                        <div class="col-md-5">
                            <div class="input-group mb-3">
                                <span class="input-group-text">系統別分類</span>
                                <select class="form-select shadow-none" [(ngModel)]="SysID" (change)="getSYSUserFunDeptList(UserCodeDataKey, UserCode, check1.checked)">
                                    <option value="" selected disabled>--請選擇--</option>
                                    <option value="{{item.ItemValue}}" *ngFor="let item of allSysFuncList"> {{item.ItemName}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check pt-2">
                                <input class="form-check-input" type="checkbox" ngValue="" id="flexCheckDefault" #check1 (click)="getSYSUserFunDeptList(UserCodeDataKey, UserCode,check1.checked)">
                                <label class="form-check-label" for="flexCheckDefault">只顯示有權限的</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button class="other-Button-gray float-end" (click)="getSYSUserFunDeptSave()">儲存</button>
                        </div>
                    </div>
                    <table class="table table-hover table-striped text-center">
                        <thead class="thead-dark-red">
                            <tr>
                                <th><input type="checkbox" class="pointer form-check-input" (click)="setAll(true, $event.target)">&ensp;全選</th>
                                <th>系統</th>
                                <th>代碼</th>
                                <th>名稱</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of sYSUserFunDeptList.FunList">
                                <td><input type="checkbox" class="pointer form-check-input" [checked]="item.Checked" [(ngModel)]="item.Checked"></td>
                                <td>{{item.SysName}}</td>
                                <td>{{item.FunID}}</td>
                                <td>{{item.FunName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </tab>
                <tab heading="科系權限">
                    <div class="row mb-3 mt-3">
                        <div class="col-md-3">
                            <div class="form-check pt-2">
                                <input class="form-check-input" type="checkbox" ngValue="" id="flexCheckDefault1" #check (click)="getSYSUserFunDeptList(UserCodeDataKey, UserCode, check.checked)">
                                <label class="form-check-label" for="flexCheckDefault1">只顯示有權限的</label>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <button class="other-Button-gray float-end" (click)="getSYSUserFunDeptSave()">儲存</button>
                        </div>
                    </div>
                    <table class="table table-hover table-striped text-center">
                        <thead class="thead-dark-red">
                            <tr>
                                <th><input type="checkbox" class="pointer form-check-input" (click)="setAll(false, $event.target)">&ensp;全選</th>
                                <th>科系</th>
                                <th>名稱</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of sYSUserFunDeptList.DeptList">
                                <td><input type="checkbox" class="pointer form-check-input" [checked]="item.Checked" [(ngModel)]="item.Checked"></td>
                                <td>{{item.DeptNo}}</td>
                                <td>{{item.DeptName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </tab>
            </tabset>
        </div>
    </div>
</div>
