<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year (ngModelChange)="getEduList(Year.value, Term.value);"
                        formControlName="Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term (change)="getEduList(Year.value, Term.value)"
                        formControlName="Term">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" #edu
                        (change)="getDeptList(edu.value, Year.value, Term.value)" formControlName="EduNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                            {{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" #dept
                        (change)="getClassList(edu.value, dept.value, Year.value, Term.value);"
                        formControlName="DeptNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">
                            {{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class" (change)="getGRDTermScoreCosList()">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">
                            {{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">狀態</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Status">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="N">未登錄</option>
                        <option value="Y">已登錄</option>
                    </select>
                </div>
            </div>            
        </div>    
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNo">
                </div>
            </div>          
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">科目</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Serial">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of gRDTermScoreCosList">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">教師</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="TchName" placeholder="請輸入教師姓名或職編">
                    <button class="search-btn float-end me-3" (click)="getGRDTermScoreTchCos()">查科目</button>                    
                </div>
            </div>              
            <div class="col-md-3">
                <button type="button" class="other-Button-gray float-end" (click)="getTchSelect(Tchtemplate)">登錄成績</button>             
                <button class="search-btn float-end me-3" (click)="getGRDTermScoreList()">查詢</button>                 
            </div>
        </div>
    </form>
    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th>班級名稱</th>
                <th style="width: 100px;">學號</th>
                <th style="width: 100px;">學生姓名</th>
                <th>科目名稱</th>
                <th style="width: 80px;">必選修</th>
                <th style="width: 80px;">學分數</th>
                <th style="width: 80px;">成績</th>
                <th style="width: 100px;">狀態</th>
                <th>登錄日期</th>
                <th style="width: 80px;"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of gRDTermScoreList">
                <td>{{item.ClassName}}</td>
                <td style="width: 100px;">{{item.StdNo}}</td>
                <td style="width: 100px;">{{item.StdName}}</td>
                <td>{{item.CosName}}</td>
                <td style="width: 80px;">{{item.SelKindName}}</td>
                <td style="width: 80px;">{{item.Credit}}</td>
                <td style="width: 80px;">{{item.Score}}</td>
                <td style="width: 100px;">{{item.Status}}</td>
                <td>{{item.ScoreDate}}</td>
                <td style="width: 80px;"><a class="fw-bolder text-decoration-none pointer"
                        (click)="openModal(item.DataKey, template)"><span class="icon-Fill-in-button"></span>編輯</a></td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">成績更正</h4>
        <button type="button" class="btn-close close btn-close-white shadow-none pull-right" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="gRDTermScoreModifyList">
        <div class="row">
            <div class="col-md-12">
                <label class="me-3">學年：{{gRDTermScoreModifyList.Years}}</label>
                <label class="me-3">學期：{{gRDTermScoreModifyList.Term}}</label>
                <label class="me-3">學號：{{gRDTermScoreModifyList.StdNo}}</label>
                <label class="me-3">科目：{{gRDTermScoreModifyList.CosName}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <table class="table table-striped table-striped text-center table-hover">
                    <thead class="thead-dark-red">
                        <tr>
                            <th style="width: 80px;"></th>
                            <th>學年</th>
                            <th>學期</th>
                            <th>教師代碼</th>
                            <th>教師姓名</th>
                            <th>學期成績</th>
                            <th>評分方式</th>
                            <th>待捕登</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of gRDTermScoreModifyList.TchScore; let i = index" (click)="getTchScore(i)"
                            class="pointer"
                            [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}">
                            <td style="width: 80px;">{{i + 1}}</td>
                            <td>{{item.Years}}</td>
                            <td>{{item.Term}}</td>
                            <td>{{item.TchNo}}</td>
                            <td>{{item.TchName}}</td>
                            <td>{{item.TScore}}</td>
                            <td>{{item.ScoreTypeName}}</td>
                            <td>{{item.RestMark}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-4">
                <ng-container *ngIf="tchScore?.ScoreType == '2'">
                    <div class="input-group mb-3">
                        <span class="input-group-text">異動原因</span>
                        <select class="form-select shadow-none" [(ngModel)]="tchScore!.ChangeReason">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of gDTermScoreChangeReason">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">學期成績</span>
                        <input type="number" class="form-control shadow-none" max="100" min="0"
                            [(ngModel)]="tchScore!.TScore">
                    </div>
                    <div>
                        <button class="modify-btn mx-auto" (click)="getGRDTermScoreModifySave()">修改</button>
                    </div>
                </ng-container>
                <ng-container *ngIf="tchScore?.ScoreType == '3'">
                    <div class="input-group mb-3">
                        <span class="input-group-text">異動原因</span>
                        <select class="form-select shadow-none" [(ngModel)]="tchScore!.ChangeReason">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of gDTermScoreChangeReason">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">學期成績</span>
                        <select class="form-select shadow-none" [(ngModel)]="tchScore!.TScore">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="777">不通過</option>
                            <option value="888">通過</option>
                        </select>
                    </div>
                    <div>
                        <button class="modify-btn mx-auto" (click)="getGRDTermScoreModifySave()">修改</button>
                    </div>
                </ng-container>
                <ng-container *ngIf="tchScore?.ScoreType == '1'">
                    <div class="input-group mb-3">
                        <span class="input-group-text">異動原因</span>
                        <select class="form-select shadow-none" [(ngModel)]="tchScore!.ChangeReason">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of gDTermScoreChangeReason">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">期中成績</span>
                        <input type="number" class="form-control shadow-none" max="100" min="0"
                            [(ngModel)]="tchScore!.MScore">
                        <input type="number" disabled class="form-control shadow-none" max="100" min="0"
                            [(ngModel)]="tchScore!.MP">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">平時成績</span>
                        <input type="number" class="form-control shadow-none" max="100" min="0"
                            [(ngModel)]="tchScore!.QScore">
                        <input type="number" disabled class="form-control shadow-none" max="100" min="0"
                            [(ngModel)]="tchScore!.QP">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">期末成績</span>
                        <input type="number" class="form-control shadow-none" max="100" min="0"
                            [(ngModel)]="tchScore!.FScore">
                        <input type="number" disabled class="form-control shadow-none" max="100" min="0"
                            [(ngModel)]="tchScore!.FP">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">學期成績</span>
                        <input type="number" class="form-control shadow-none" max="100" min="0"
                            [(ngModel)]="tchScore!.TScore" disabled>
                    </div>
                    <div>
                        <button class="modify-btn mx-auto" (click)="getGRDTermScoreModifySave()">修改</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #Tchtemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">成績登錄</h4>
        <button type="button" class="btn-close close btn-close-white shadow-none pull-right" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-8">
                <table class="table table-striped table-striped text-center table-hover">
                    <thead class="thead-dark-red">
                        <tr>
                            <th style="width: 40px;"></th>
                            <th style="width: 220px;">教師代碼/姓名</th>
                            <th style="width: 200px;">成績登錄</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of gRDTermScoreTchList; let i = index"
                            class="pointer">
                            <td style="width: 40px;">{{i + 1}}</td>
                            <td style="width: 220px;">{{item.ItemName}}</td>
                            <td style="width: 200px;">
                                <button type="button" class="other-Button-gray float" (click)="getTchScoreDetail(item.ItemValue,ScoreInputtemplate)">登錄成績</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #ScoreInputtemplate>
    <div class="modal-header" id="modal-table">
        <h4 class="modal-title pull-left">登分作業</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
        </button>
    </div>    
    <div>
        <app-GrdScoreInput id="GrdScoreInput" *ngFor="let item of TchSelect" [DataKey]="item.DataKey" [IsTch]="item.IsTch"></app-GrdScoreInput>
    </div>
</ng-template>