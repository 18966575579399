import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSRP0302',
    templateUrl: './ADSRP0302.component.html',
    styleUrls: ['./ADSRP0302.component.css']
})
export class ADSRP0302Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    classListStart: GetClassList[] = [];
    classListEnd: GetClassList[] = [];
    ngFormInput = new UntypedFormGroup({
        YearsFrom: new UntypedFormControl('', [Validators.required]),
        YearsTo: new UntypedFormControl('', [Validators.required]),
        TermFrom: new UntypedFormControl('', [Validators.required]),
        TermTo: new UntypedFormControl('', [Validators.required]),
        ClassFrom: new UntypedFormControl(''),
        ClassTo: new UntypedFormControl(''),
        StdNoFrom: new UntypedFormControl(''),
        StdNoTo: new UntypedFormControl('')
    })
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,) {
    }

    ngOnInit(): void {
        this.getYears();
        this.getClassList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getClassList() {
        this.systemService.getClassList('', '', '0', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classListStart = response;
            this.classListEnd = response;
        }, error => this.toastr.error(error.error))

    }

    exportReportADSRP0302() {

        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        this.fileExchangeServices.ExportFile("ACAD/SRP/ADSRP0302/Export_Report_ADSRP0302", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

}
