<div class="container">
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="Years">學年</span>
                </div>
                <select class="form-select shadow-none" name="Years" [(ngModel)]="transferdata.Years">
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="Term">學期</span>
                </div>
                <select class="form-select shadow-none" name="Term" [(ngModel)]="transferdata.Term">
                    <option value="" disabled>--全部--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group mb-3">
                <span class="input-group-text">問卷類別</span>
                <select #SelStyle class="form-select shadow-none" [(ngModel)]="transferdata.SelStyle">
                    <option value="">--全部--</option>
                    <option *ngFor="let a of data" [value]="a.SelStyle">{{a.SelStyle_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div  style="height: 500px; overflow-y: scroll;">
            <tabset #tabs>
                <tab heading="選課轉期中評量">
                    <ng-container>
                        <div class="row">
                            <div class="input-group mb-3">
                                <span class="input-group-text">科系</span>
                                <select [(ngModel)]="Subject" #Dept_No class="form-select shadow-none" (change)="getstartclass(Dept_No.value)">
                                    <option value="">--請選擇--</option>
                                    <option *ngFor="let a of subjectdata;let i = index" [value]="a.Dept_No">{{a.Dept_Short}}</option>
                                </select>
                            </div>
                            <button class="other-Button-gray float-end me-3" (click)="gettransfersubject()">開始轉檔</button>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md">
                                <table class="table table-hover table-striped text-center">
                                    <thead class="thead-dark-red">
                                        <tr>
                                            <th><input type="checkbox" class="pointer" (click)="changesubjectall($event.target)">&ensp;全選</th>
                                            <th>科系</th>
                                            <th>開課班級代碼</th>
                                            <th>開課班級名稱</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="pointer" *ngFor="let a of startsubjectdata; let i = index">
                                            <td><input [(ngModel)]="a.checked" type="checkbox" class="pointer"  (click)="changesubject($event.target,i)"></td>
                                            <td>{{a.Dept_Short}}</td>
                                            <td>{{a.Class}}</td>
                                            <td>{{a.Class_Short}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                </tab>
                <tab heading="特殊處理">
                    <ng-container>
                        <div class="row">
                            <div class="input-group mb-3">
                                <span class="input-group-text">班級</span>
                                <select [(ngModel)]="Class" #Class_Short class="form-select shadow-none" (change)="getstartstudent(Class_Short.value)">
                                    <option value="" disabled>--請選擇--</option>
                                    <option *ngFor="let a of classdata" [value]="a.Class_Short">{{a.Class_Short}}</option>
                                </select>
                            </div>
                            <button class="other-Button-gray float-end me-3" (click)="gettransferclass()">開始轉檔</button>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md">
                                <table class="table table-hover table-striped text-center">
                                    <thead class="thead-dark-red">
                                        <tr>
                                            <th><input type="checkbox" class="pointer" (click)="changestudentall($event.target)">&ensp;全選</th>
                                            <th>學號</th>
                                            <th>姓名</th>
                                            <th>所屬班級</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="pointer" *ngFor="let a of startstudentdata; let i=index">
                                            <td><input [(ngModel)]="a.checked" type="checkbox" class="pointer" (click)="changeclass($event.target,i)"></td>
                                            <td>{{a.Std_No}}</td>
                                            <td>{{a.Std_Name}}</td>
                                            <td>{{a.Class_Short}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                </tab>
              
            </tabset>
        </div>
    </div>
</div>
