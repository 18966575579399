import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADABS0103',
    templateUrl: './ADABS0103.component.html',
    styleUrls: ['./ADABS0103.component.css']
})
export class ADABS0103Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    dataKeyArray: Array<string> = [];

    ngOnInit(): void {
        this.getYears();
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService) { }

    getYears() {
        this.initialData.getYears().subscribe((response: string[]) => {
            if (response) {
                this.Years = response;
            }
        }, (error: { error: string | undefined; }) => this.toastr.error(error.error));
    }
    setDatakey(type: string, dataKey: string, event: any) {
        let input = <HTMLInputElement>event;
        if (type == 'all') {
        }
        else {
            this.dataKeyArray = [];
        }
    }
}
