import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetStdADDMDetail } from 'src/app/_Models/AD/ADSTD/ADSTD0401/getStdADDMDetail';
import { GetStdADDMList } from 'src/app/_Models/AD/ADSTD/ADSTD0401/getStdADDMList';
import { GetStdADDMListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0401/getStdADDMListInput';
import { GetAddTypeList } from 'src/app/_Models/getAddTypeList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSTDStatusList } from 'src/app/_Models/getSTDStatusList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSTD0401',
    templateUrl: './ADSTD0401.component.html',
    styleUrls: ['./ADSTD0401.component.css']
})
export class ADSTD0401Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    bsConfig = environment.bsDatePickerConfig;
    modalRef!: BsModalRef;
    dataKeyArray: Array<string> = [];
    sTDStatusList!: GetSTDStatusList[];
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    newdeptList!: GetDeptList[];
    addTypeList!: GetAddTypeList[];
    stdADDMListInput: GetStdADDMListInput = {
        AddYear: '',
        AddTerm: '',
        AddType: '',
        Approve: '',
        Result: '',
        EduNo: '',
        DeptNo: '',
        Class: '',
        Status: '',
        IDNo: '',
        StdNo: '',
        StdName: ''
    };
    stdADDMList!: GetStdADDMList[];
    stdADDMDetail!: GetStdADDMDetail;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private systemService: SystemService,
        private localeService: BsLocaleService,) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.getSTDStatusList();
        this.getAddType();
        this.getAllDeptList();
        this.getEduList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getSTDStatusList() {
        this.systemService.getSTDStatusList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sTDStatusList = response;
        }, error => this.toastr.error(error.error))
    }



    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getAllDeptList() {
        this.systemService.getDeptList('3', '', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.newdeptList = response;

        }, error => this.toastr.error(error.error));
    }

    getStdADDMList() {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD04_01/Get_StdADDM_List", this.stdADDMListInput).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdADDMList = []
                return;
            }
            this.stdADDMList = response;
            this.stdADDMList.forEach(element => {
                element.checked = false;
            });
        })
    }

    getStdADDMDetail(dataKey: string, isNew: boolean, template: TemplateRef<any>) {
        this.stdADDMDetail = {
            DataKey: '',
            AddYear: '',
            AddTerm: '',
            StdNo: '',
            StdName: '',
            EduName: '',
            ClassName: '',
            ApplyDate: '',
            AddType: '',
            AddEduNo: '',
            AddDeptNo: '',
            AddTeamNo: '',
            ApproveDate: '',
            Approve: '',
            GiveupYear: '',
            GiveupTerm: '',
            GiveupDate: '',
            GiveupApproveDate: '',
            GiveupApprove: '',
            GiveupReasonNo: '',
            Result: '',
            Memo: ''
        }

        if (!isNew) {
            this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD04_01/Get_StdADDM_Detail", { Datakey: dataKey }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.stdADDMDetail = response;
            }, error => this.toastr.error(error.error));
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig)
    }

    getAddType() {
        this.systemService.getAddType().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.addTypeList = response;
        })
    }

    getStdADDMModify() {

        if (this.stdADDMDetail.StdNo == '') {
            this.toastr.info("未設定學生");
            return;
        }

        if (this.stdADDMDetail.ApplyDate == '' ||
            this.stdADDMDetail.ApproveDate == '' ||
            this.stdADDMDetail.Approve == '' ||
            this.stdADDMDetail.AddYear == '' ||
            this.stdADDMDetail.AddTerm == '' ||
            this.stdADDMDetail.AddType == '' ||
            this.stdADDMDetail.Result == '' ||
            this.stdADDMDetail.AddDeptNo == '') {
            this.toastr.info("必填欄位請填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD04_01/Get_StdADDM_Modify", this.stdADDMDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.getStdADDMList()
            this.modalRef.hide();
        })
    }

    setDatakey(type: string, dataKey: string, event: any) {
        if (!this.stdADDMList) {
            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.stdADDMList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.stdADDMList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.stdADDMList.forEach(element => {
                    element.checked = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

    getStdADDMDelete() {
        if (this.dataKeyArray.length == 0) {
            this.toastr.info("請先選擇學生");
            return;
        };

        Swal.fire({
            title: `確定刪除${this.dataKeyArray.length}筆資料?`,
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                let dataKey = '';
                this.dataKeyArray.forEach(element => {
                    dataKey = dataKey + element + ',';
                });

                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD04_01/Get_StdADDM_Delete", { DataKey: dataKey.substring(0, dataKey.length - 1) }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.dataKeyArray = [];
                    this.getStdADDMList();
                })
            }
            else if (result.isDenied) {
                this.toastr.info("已取消動作");
            }
        });
    }
}
