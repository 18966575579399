<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">文號類型</span>
                </div>
                <select [(ngModel)]="search1.Moe_Type" class="form-select shadow-none" #Moe_Type>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of moeTypeList" value="{{item.ItemValue}}">{{item.ItemName}} </option>
                </select>
            </div>
        </div>
        <div class="col-md-9">
            <button class="other-Button-gray float-end" (click)="clear()">清除</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="other-Button-gray float-end me-3" (click)="insert()">新增</button>
            <button class="search-btn float-end me-3 mb-3" (click)="search(Moe_Type.value)">查詢</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 50px;"></th>
                        <th>文號代碼</th>
                        <th>文號類型</th>
                        <th>公文字</th>
                        <th>公文號</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" (click)="searchDetail(item.Moe_No, i)">
                        <td style="width: 50px;">{{i + 1}}</td>
                        <td>{{item.Moe_No}}</td>
                        <td>{{item.Moe_Type_Name}}</td>
                        <td>{{item.Moe_Title}}</td>
                        <td>{{item.Moe_Number}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">文號代碼</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Moe_No">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">文號類型</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Moe_Type">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of moeTypeList" value="{{item.ItemValue}}"> {{item.ItemName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">公文字</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Moe_Title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">公文號</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Moe_Number">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">發文日期</span>
                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly [(ngModel)]="dataDetail.Moe_Date" style="background-color: transparent;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">公文主旨</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Moe_Main">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">備註</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Memo">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Update_User">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Update_Time">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
