import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetOpClassList } from 'src/app/_Models/getOpClassList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetTchFileList } from 'src/app/_Models/TCHWEB/TCH0301/getTchFileList';
import { GetTestDetail } from 'src/app/_Models/TCHWEB/TCH0305/getTestDetail';
import { GetTestList } from 'src/app/_Models/TCHWEB/TCH0305/getTestList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCh0305',
    templateUrl: './TCh0305.component.html',
    styleUrls: ['./TCh0305.component.css']
})
export class TCH0305Component implements OnInit {
    opClassList!: GetSelectList[];
    cosList!: GetSelectList[];
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    tchFileList!: GetTchFileList[];
    fileHidden = true;
    getOpClassListInput: GetOpClassList = {
        Years: '',
        Term: '',
        DeptNo: '',
        TeamNo: '',
        TchNo: '',
        Classroom: '',
        UserCode: '',
        CHeckRight: ''
    };
    testDetail!: GetTestDetail;
    testList!: GetTestList[] | undefined;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private accountService: AccountService,
        private modalService: BsModalService,
        private datePipe: DatePipe,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.getOpClassListInput.TchNo = response.Code;
            }
        }, error => this.toastr.error(error.error))

        this.timeService.getTimeCntr().subscribe((response) => {
            this.getOpClassListInput.Years = response.Now_Years;
            this.getOpClassListInput.Term = response.Now_Term;
            this.getOpClassList(this.getOpClassListInput)
        }, error => this.toastr.error(error.error))
    }

    setModel(model: any) {
        this.getOpClassListInput.Years = model.Years;
        this.getOpClassListInput.Term = model.Term;
    }

    getOpClassList(model: GetOpClassList) {
        this.systemService.getOpClassList(model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.opClassList = response;
        }, error => this.toastr.error(error.error));
    }

    getCosList(event: any) {
        let opClass = <HTMLInputElement>event.target;
        this.http.post<any>(this.baseUrl + "Public/List_Cos", {
            Years: this.getOpClassListInput.Years,
            Term: this.getOpClassListInput.Term,
            OpClass: opClass.value
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosList = response;
        }, error => this.toastr.error(error.error));
    }

    getTestList(dataKey: string) {
        if (dataKey == '') {
            this.toastr.info("請選擇科目");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_05/Get_Testing_List", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.testList = undefined;
                return;
            }
            this.testList = response;
        })
    }

    openModal(template: TemplateRef<any>, dataKey: string) {

        if (dataKey == '') {
            this.toastr.info("請選擇科目");
            return;
        }

        // 初始化
        this.testDetail = {
            DataKey: dataKey,
            Years: this.getOpClassListInput.Years,
            Term: this.getOpClassListInput.Term,
            Op_Class: '',
            Serial: '',
            Start_Date: '',
            End_Date: '',
            Test_No: '',
            Test_Name: '',
            Usual_Mark: 'N',
            File_No: '',
            Tch_No: '',
            File_Name: '',
            Update_User: '',
            Update_Time: ''
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_01/Get_TchFile_List", {
            Type_No: 'B',
            FileName: ''
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.tchFileList = response;
            this.modalRef = this.modalService.show(template, modalConfig);
        }, error => this.toastr.error(error.error))
    }

    saveData(dataKey: string) {

        if (this.testDetail.End_Date < this.testDetail.Start_Date) {
            this.toastr.warning("截止日需大於起始日");
            return;
        }

        this.testDetail.Start_Date = this.datePipe.transform(this.testDetail.Start_Date, "yyyy-MM-dd hh:mm:ss") || '';
        this.testDetail.End_Date = this.datePipe.transform(this.testDetail.End_Date, "yyyy-MM-dd hh:mm:ss") || '';
        this.testDetail.Test_Name = escape(this.testDetail.Test_Name);
        this.testDetail.Update_User = escape(this.testDetail.Update_User);
        this.testDetail.Update_Time = escape(this.testDetail.Update_Time);

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_05/Save_Testing", this.testDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_05/Get_Testing_List", { DataKey: dataKey }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.testList = response;
                this.toastr.success("儲存成功");
                this.modalRef.hide();
            }, error => this.toastr.error(error.error))
        }, error => this.toastr.error(error.error))
    }

    getTestkDetail(dataKey: string, template: TemplateRef<any>) {

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_05/Get_Testing_Detail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.testDetail = response;
            this.testDetail.Test_Name = response.Test_Name;
            this.testDetail.Start_Date = response.Start_Date.replace(/\s+/g, "T");
            this.testDetail.End_Date = response.End_Date.replace(/\s+/g, "T");
            this.testDetail.Update_User = response.Update_User;
            this.testDetail.Update_Time = response.Update_Time;

            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_01/Get_TchFile_List", {
                Type_No: 'C',
                FileName: ''
            }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.tchFileList = response;
                this.modalRef = this.modalService.show(template, modalConfig);
            }, error => this.toastr.error(error.error))
        }, error => this.toastr.error(error.error));
    }

    setHidden() {
        this.fileHidden = false;
    }

    setFile(fileNo: string, fileName: string) {
        this.fileHidden = true;
        this.testDetail.File_No = fileNo;
        this.testDetail.File_Name = fileName;
    }

    deleteData(dataKey: string, cosValue: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_05/Delete_Testing", { DataKey: dataKey }).subscribe((response) => {
            if (response == false) {
                this.toastr.warning(response.Warning);
                return;
            }
            this.toastr.success("刪除成功");
            this.getTestList(cosValue);
        }, error => this.toastr.error(error.error))
    }
}
