import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { error } from 'selenium-webdriver';
import { AnswerType, ASMQuestions, ASMSubjects } from 'src/app/_Models/STDWEB/STDW2000607/ASMSubjects';
import { GetASMList } from 'src/app/_Models/STDWEB/STDW2000607/getASMList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW2000607',
    templateUrl: './STDW2000607.component.html',
    styleUrls: ['./STDW2000607.component.css']
})
export class STDW2000607Component implements OnInit {


    baseUrl = environment.apiUrl;
    initData = {
        Std_No: '',
        Years: '',
        Term: '',
        MF: ''
    }
    data!: GetASMList[];
    ASMOtherData!: string;
    ASMCos!: string;
    ASMTch!: string;
    dataKey!: string;
    ASMModelRef!: BsModalRef;
    aSMList!: GetASMList[];
    aSMSubjects!: ASMSubjects[];
    aSMQuestions: ASMQuestions[] = []
    ansFormGroup = new UntypedFormGroup({});
    studentAns: AnswerType[] = [];
    checkBoxArray: AnswerType[] = [];
    answerTextArray: string[] = [];

    constructor(private systemService: SystemService,
        private accountService: AccountService,
        private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private timeService: TimeService, private translateService: TranslateService) {

    }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        // 接routing傳過來的值
        this.initData.MF = this.route.snapshot.paramMap.get('MF') || '';

        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.initData.Std_No = response.Code;
            }

            this.timeService.getTimeCntr().subscribe((response) => {
                this.initData.Years = response.Now_Years;
                this.initData.Term = response.Now_Term;

                this.getASMList();
            });
        });
    }

    getASMList() {
        this.http.post<GetASMList[]>(this.baseUrl + "ACAD/STDWEB/STDW200_0607/Get_ASM_List", this.initData).subscribe((response) => {
            this.data = response;
        });
    }

    getASMContent(dataKey: string, ASMCos: string, ASMTch: string, template: TemplateRef<any>) {
        this.aSMList = [];
        this.studentAns = [];
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.dataKey = dataKey;
        this.ASMTch = ASMTch;
        this.ASMCos = ASMCos;
        this.http.post<GetASMList[]>(this.baseUrl + "ACAD/STDWEB/STDW200_0607/Get_ASM_Content", { Std_No: this.initData.Std_No, DataKey: dataKey }).subscribe((response) => {
            if (response) {
                this.aSMList = response;
                this.ASMOtherData = response[1].SubjectsText;
                this.aSMSubjects = response[0].Subjects;
                this.setFormControl(response[0].Subjects);
                this.ASMModelRef = this.modalService.show(template, modalConfig);
            }
            else {
                this.toastr.warning("查無資料!");
            }
        })
    }


    setFormControl(data: ASMSubjects[]) {
        for (let index = 0; index < data.length; index++) {
            for (let q = 0; q < data[index].Question.length; q++) {
                if (data[index].Question[q].AnswerKind == "CheckBox") {
                    for (let e = 0; e < data[index].Question[q].AnswerType.length; e++) {
                        this.ansFormGroup.setControl(data[index].Question[q].DataKey + data[index].Question[q].AnswerType[e].AnswerNo, new UntypedFormControl());
                    }
                }
            }
        }
    }

    setAns(type: string, ansNum: string, dataKey: string, controlName: string, event: any) {
        if (type == 'checkbox') {
            let text = <HTMLInputElement>event.target;
            if (this.ansFormGroup.get(controlName)?.value == true || text.value == '') {
                this.checkBoxArray.push({
                    AnswerName: "",
                    AnswerText: text.value || "",
                    AnswerNo: ansNum,
                    TextLog: ""
                })
            }
            else {
                for (let index = 0; index < this.checkBoxArray.length; index++) {
                    if (this.checkBoxArray[index].AnswerNo == ansNum) {
                        this.checkBoxArray.splice(index, 1);
                    }
                }

            }

            for (let index = 0; index < this.aSMSubjects.length; index++) {
                for (let q = 0; q < this.aSMSubjects[index].Question.length; q++) {
                    if (this.aSMSubjects[index].Question[q].DataKey == dataKey) {
                        this.aSMSubjects[index].Question[q].StdAnswer = this.checkBoxArray;
                        break;
                    }
                }
            }

        }
        else {
            let text = <HTMLInputElement>event.target;
            this.studentAns = [];
            this.studentAns.push({
                AnswerName: "",
                AnswerText: text.value || "",
                AnswerNo: ansNum,
                TextLog: ""
            });

            for (let index = 0; index < this.aSMSubjects.length; index++) {
                for (let q = 0; q < this.aSMSubjects[index].Question.length; q++) {
                    if (this.aSMSubjects[index].Question[q].DataKey == dataKey) {
                        this.aSMSubjects[index].Question[q].StdAnswer = this.studentAns;
                        break;
                    }
                }
            }
        }
    }


    saveASMContent() {

        // 檢查是否全部都有填寫
        for (let index = 0; index < this.aSMSubjects.length; index++) {
            for (let q = 0; q < this.aSMSubjects[index].Question.length; q++) {
                if (this.aSMSubjects[index].Question[q].StdAnswer == null) {
                    this.toastr.error(`${this.aSMSubjects[index].Question[q].QuestionName + " 尚未填寫"}`);
                }
            }
        }

        this.aSMList[1].SubjectsText = (<HTMLInputElement>document.getElementById("otherAnswer")).value;
        this.aSMList[0].Subjects = this.aSMSubjects;

        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW200_0607/Save_ASM_Content", this.aSMList).subscribe((response: any) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.ASMModelRef.hide();
            this.toastr.success("儲存成功!");
            this.getASMList();
        }, error => this.toastr.error(error.error));
    }
}
