<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" required [(ngModel)]="condition.Years">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學期</span>
                    <select class="form-select shadow-none" required [(ngModel)]="condition.Term">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">單位</span>
                    <select class="form-select shadow-none" [(ngModel)]="condition.UnitCode">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of unitData" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">職號/姓名</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="condition.TchNo">
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <button type="button" class="search-btn float-start me-3" (click)="getCURTchMoneyList();">查詢</button>
            <button type="button" class="other-Button-gray float-start" (click)="getCURTchMoneyImport();">匯入資料</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th class="ColW70">職號</th>
                        <th class="ColW100">姓名</th>
                        <th class="ColW270 ColAlignLeft">單位</th>
                        <th class="ColW60">專兼職別</th>                        
                        <th class="ColW130 ColAlignLeft">教師職稱</th>
                        <th class="ColW200 ColAlignLeft">行政職稱</th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" *ngFor="let item of dataList; let i = index" (click)="getDetail(i)">
                        <td class="ColW70">{{item.TchNo}}</td>
                        <td class="ColW100">{{item.TchName}}</td>
                        <td class="ColW270 ColAlignLeft">{{item.TchUnit}}</td>
                        <td class="ColW60">{{item.TimeCase}}</td>                        
                        <td class="ColW130 ColAlignLeft">{{item.TchTitle}}</td>
                        <td class="ColW200 ColAlignLeft">{{item.UnitName}}</td>                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
