import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetSRPReportNote } from 'src/app/_Models/AD/ADSRP/ADSRP0301/getSRPReportNote';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSRP0301ISU',
    templateUrl: './ADSRP0301ISU.component.html',
    styleUrls: ['./ADSRP0301ISU.component.css']
})
export class ADSRP0301ISUComponent implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    bsConfig = environment.bsDatePickerConfig;
    typeList: Array<string> = [];
    sRPReportNote?: GetSRPReportNote[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        IssueDateFrom: new UntypedFormControl(null),
        IssueDateTo: new UntypedFormControl(null),
        Reason: new UntypedFormControl(''),
        TypeList: new UntypedFormControl(''),
        FieldTypeValue: new UntypedFormControl(''),
        OrderBy: new UntypedFormControl('1')
    })
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.getYears();
        this.localeService.use('zh-cn');
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getSRPReportNote() {
        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SRP/ADSRP0301/Get_SRP_Report_Note", this.ngFormInput.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sRPReportNote = undefined;
                return;
            }
            this.sRPReportNote = response;
        })
    }

    setCheck(event: EventTarget | null) {
        let input = <HTMLInputElement>event;
        let index = this.typeList.indexOf(input.value);
        if (input.checked && index == -1) {
            this.typeList.push(input.value);
        }
        else {
            this.typeList.splice(index, 1);
        }

        if (this.typeList.length == 0) return;

        let data = '';

        this.typeList.forEach(element => {
            data += element + ','
        });

        this.ngFormInput.get('TypeList')?.setValue(data);
    }

    exportReportADSRP0301() {

        if (!this.sRPReportNote) {
            this.toastr.info('請先查詢');
            return;
        }

        this.fileExchangeServices.ExportFile("ACAD/SRP/ADSRP0301/Export_Report_ADSRP0301", this.sRPReportNote).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

}
