<div id="chart" class="container">
  <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis" [plotOptions]="chartOptions.plotOptions" [title]="chartOptions.title"></apx-chart>
</div>

<div class="container">
  <table class="table table-bordered table-hover text-center">
    <thead>
      <tr>
        <th>{{'核心能力別' | translate}}</th>
        <th scope="col">A1</th>
        <th scope="col">A2</th>
        <th scope="col">A3</th>
        <th scope="col">A4</th>
        <th scope="col">A5</th>
        <th scope="col">A6</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">{{'各項能力總積分(學生)' | translate}}</th>
        <td>1774</td>
        <td>964</td>
        <td>315</td>
        <td>799</td>
        <td>773</td>
        <td>633</td>
      </tr>
      <tr>
        <th scope="row">{{'各項能力總積分(系訂)' | translate}}</th>
        <td>2210</td>
        <td>1245</td>
        <td>1015</td>
        <td>1020</td>
        <td>890</td>
        <td>760</td>
      </tr>
      <tr>
        <th scope="row">{{'各項能力達成度' | translate}}</th>
        <td style="color:red;">80%</td>
        <td style="color:red;">77%</td>
        <td style="color:red;">31%</td>
        <td style="color:red;">78%</td>
        <td style="color:red;">87%</td>
        <td style="color:red;">83%</td>
      </tr>
    </tbody>
  </table>
</div>