<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.Years">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" name="Term" [(ngModel)]="getStdData.Term">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.EduNo"
                    (change)="getDeptList(getStdData.EduNo)" (ngModelChange)="GetData()">
                    <option value="" selected disabled>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">系所</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.DeptNo" (ngModelChange)="GetData()">
                    <option value="" selected disabled>--請選擇系所--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">獎懲</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.Htype">
                    <option value="">--請選擇--</option>
                    <option value="1">獎勵</option>
                    <option value="2">懲處</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">區間日期</span>
                </div>
                <input type="text" placeholder="起" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                    [(ngModel)]="getStdData.RecDateFrom">
                <input type="text" placeholder="訖" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                    [(ngModel)]="getStdData.RecDateTo">
                <!-- <input type="text" placeholder="-訖-(月/日/年)" class="form-control shadow-none" bsDatepicker [(ngModel)]="getStdData.RecDateTo" > -->
            </div>
        </div>
        <div>
            <button class="export-btn mx-auto" (click)="exportReportADSRP0303()">匯出 </button>
        </div>
    </div>
</div>