<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getSELSelMaxMinList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'學年'|translate}}
                        </span>
                    <select class="form-select shadow-none" #Year (change)="getEduList(Year.value, Term.value)"
                        formControlName="Years">
                        <option value="" selected>{{'--請選擇--'|translate}}
                        </option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}{{'學年'|translate}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'學期'|translate}}
                        </span>
                    <select class="form-select shadow-none" #Term (change)="getEduList(Year.value, Term.value)"
                        formControlName="Term">
                        <option value="" selected disabled>{{'--請選擇--'|translate}}
                        </option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'學制'|translate}}
                        </span>
                    <select class="form-select shadow-none" #EduNo
                        (change)="getDeptList(Year.value, Term.value, EduNo.value)">
                        <option value="" selected disabled>{{'--請選擇學制--'|translate}}
                        </option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'系所'|translate}}
                        </span>
                    <select class="form-select shadow-none" #DeptNo formControlName="DeptNo"
                        (change)="getClassList(Year.value, Term.value, EduNo.value, DeptNo.value)">
                        <option value="" selected disabled>{{'--請選擇--'|translate}}
                        </option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">
                            {{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'班級'|translate}}
                        </span>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" disabled selected>{{'--請選擇--'|translate}}
                        </option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'人數上限'|translate}}
                        </span>
                    <input type="number" min="0" class="form-control shadow-none" value="0" #Max>
                        <span class="input-group-text">{{'人數下限'|translate}}
                        </span>
                    <input type="number" min="0" class="form-control shadow-none" value="0" #Min>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <button class="search-btn float-start me-3" float-start me-3 (click)="getSELSelMaxMinList()">{{'查詢'|translate}}
                </button>
                <button type="button" class="other-Button-gray float-start me-3"
                    (click)="setNumber(Max.value, Min.value)">{{'設定'|translate}}
                </button>
                <button type="button" class="other-Button-gray float-start" (click)="getSELSelMaxMinSave()">{{'儲存'|translate}}
                </button>
            </div>
        </div>
    </form>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" class="form-check-input pointer shadow-none"
                        (click)="selectAll($event.target)">&ensp;{{'全選'|translate}}
                    </th>
                <th>{{'科系'|translate}}
                </th>
                <th>{{'開課班級'|translate}}
                </th>
                <th>{{'組別'|translate}}
                </th>
                <th>{{'科目'|translate}}
                </th>
                <th>{{'開課序號'|translate}}
                </th>
                <th>{{'授課教師'|translate}}
                </th>
                <th>{{'選課人數'|translate}}
                </th>
                <th>{{'人數上限'|translate}}
                </th>
                <th>{{'人數下限'|translate}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sELSelMaxMinList">
                <td><input type="checkbox" class="form-check-input pointer shadow-none" [(ngModel)]="item.checked"></td>
                <td>{{item.DeptName}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.TeamName}}</td>
                <td>{{item.CosName}}</td>
                <td>{{item.Serial}}</td>
                <td>{{item.TchName}}</td>
                <td>{{item.ElectNum}}</td>
                <td>{{item.SelMax}}</td>
                <td>{{item.SelMin}}</td>
            </tr>
        </tbody>
    </table>
</div>