<div class="container">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'開課班級'|translate}}</span>
                </div>
                <select class="form-select shadow-none" #opClass (change)="getCosList(opClass.value)">
                    <option value="" disabled selected>{{'--請選擇開課班級--'|translate}}</option>
                    <option *ngFor="let item of opClassList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'授課科目'|translate}}</span>
                </div>
                <select class="form-select shadow-none" #cos>
                    <option value="" disabled selected>{{'--請選擇授課科目--'|translate}}</option>
                    <option *ngFor="let item of cosList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'問答狀況'|translate}}</span>
                </div>
                <select class="form-select shadow-none" #status>
                    <option value="" selected>{{'--全部--'|translate}}</option>
                    <option value="Y">{{'已答覆'|translate}}</option>
                    <option value="N">{{'未答覆'|translate}}</option>
                </select>
            </div>
        </div>
    </div>
    <button class="search-btn mb-3" (click)="getCosQuestionList(cos.value, status.value)">{{'查詢'|translate}}</button>

    <table class="table table-hover table-striped text-center table-rwd" id="table">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'問題編號'|translate}}</th>
                <th>{{'問題內容'|translate}}</th>
                <th>{{'提問時間'|translate}}</th>
                <th>{{'回覆狀態'|translate}}</th>
                <th>{{'回覆內容'|translate}}</th>
                <th>{{'操作'|translate}}</th>
                <th>{{'回覆人員'|translate}}</th>
                <th>{{'回覆時間'|translate}}</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cosQuestionList; let i = index">
                <td data-th="問題編號">{{item.QuestNo}}</td>
                <td data-th="問題內容">{{item.QuestContent}}</td>
                <td data-th="提問時間">{{item.Date}}</td>
                <td data-th="回覆狀態" class="fw-bolder" [ngClass]="{'text-success': item.Status == '已答覆',
                                'text-danger': item.Status =='未答覆'}">{{item.Status}}</td>
                <td data-th="回覆內容">
                    <input type="text" [(ngModel)]="item.AnsContent" [disabled]="item.Status == '已答覆'">
                </td>
                <td data-th="操作">
                    <ng-container *ngIf="item.Status =='未答覆'">
                        <a class="pointer text-decoration-none"
                            (click)="getCosQuestionAns(item.AnsContent, item.DataKey, i)"><span
                                class="icon-Verify-button"></span>{{'答覆'|translate}}</a>
                    </ng-container>
                    <ng-container *ngIf="item.Status =='已答覆'">
                        <a class="pointer text-decoration-none" (click)="editAnswer(i)"><span
                                class="icon-Modify-button"></span>{{'編輯'|translate}}</a>
                    </ng-container>
                </td>
                <td data-th="回覆人員">{{item.Anser}}</td>
                <td data-th="回覆時間">{{item.AnsDate}}</td>
            </tr>
        </tbody>
    </table>
</div>