export class GetCosData{
    EduNo: string = ''
    DeptNo: string = ''
    Status: string = ''
    KeyWord: string = ''
    SelKind: string = ''
    OrderBy: string = ''
    FiledsNo: string = ''
}

export class GetCreditsListData{
    Selkind: string = ''
    SelkindName: string = ''
    Values: number = 0
    Memo: string = ''
    DataKey: string = ''
    Show: boolean=true
}