<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學年</span>
                    <select [(ngModel)]="condition.Years" class="form-select shadow-none">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學期</span>
                    <select [(ngModel)]="condition.Term" class="form-select shadow-none">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">單位</span>
                    <select class="form-select shadow-none" [(ngModel)]="condition.UnitCode">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of unitData" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">職號/姓名</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="condition.TchNo">
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <button class="search-btn float-start me-3" (click)="getCURTchMoneyTchList()">查詢</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th class="ColW40"></th>
                        <th class="ColW130">單位</th>
                        <th class="ColW80">職稱</th>
                        <th class="ColW50">職號</th>
                        <th class="ColW70">姓名</th>
                    </tr>
                </thead>
                <tbody class="Height450">
                    <tr class="pointer" *ngFor="let item of TchList; let i = index" (click)="getCURTchMoneyAddHourList(item,i)" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}">
                        <td class="ColW40">{{i + 1}}</td>
                        <td class="ColW130 ColAlignLeft">{{item.UnitName}}</td>
                        <td class="ColW80 ColAlignLeft">{{item.TitleName}}</td>
                        <td class="ColW50">{{item.TchNo}}</td>
                        <td class="ColW70">{{item.TchName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>        
        <div class="col-md-5">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th class="ColW270">鐘點類別</th>
                        <th class="ColW40">時數</th>
                        <th class="ColW100">備註</th>
                    </tr>
                </thead>
                <tbody class="Height450">
                    <tr class="pointer" *ngFor="let item of dataList; let i = index" (click)="getDetail(i)" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}">
                        <td class="ColW270 ColAlignLeft">{{item.HourTypeNo}}{{item.HourTypeName}}</td>
                        <td class="ColW40">{{item.Hours}}</td>
                        <td class="ColW100 ColAlignLeft">{{item.Memo}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <div class="input-group">
                            <span class="input-group-text">學年</span>
                            <select class="form-select shadow-none" [(ngModel)]="data.Years" required [disabled]="isEdit">
                                <option value="" selected disabled>--請選擇--</option>
                                <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <div class="input-group">
                            <span class="input-group-text">學期</span>
                            <select class="form-select shadow-none" [(ngModel)]="data.Term" required [disabled]="isEdit">
                                <option value="" selected disabled>--請選擇--</option>
                                <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <div class="input-group ">
                            <span class="input-group-text">教師</span>
                            <input type="text" class="form-control shadow-none" readonly [(ngModel)]="data.TchName" [disabled]="isEdit">
                            <input type="text" [hidden]="true" required [(ngModel)]="data.TchNo">
                            <button class="btn btn-light" (click)="openModal(template)" [disabled]="isEdit">...</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <div class="input-group">
                            <span class="input-group-text">鐘點類別</span>
                            <select class="form-select shadow-none" required [(ngModel)]="data.HourTypeNo" [disabled]="isEdit">
                                <option value="" selected disabled>--請選擇--</option>
                                <option *ngFor="let item of HourTypeList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <div class="input-group">
                            <span class="input-group-text">應補鐘點</span>
                            <input type="number" class="form-control shadow-none" min="-99" max="99" step="0.01" required [(ngModel)]="data.Hours">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <div class="input-group">
                            <span class="input-group-text">備註</span>
                            <input type="text" class="form-control shadow-none" [(ngModel)]="data.Memo">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <div class="input-group">
                            <span class="input-group-text">修改者</span>
                            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="data.UpdateUser">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <div class="input-group">
                            <span class="input-group-text">修改時間</span>
                            <input type="text" class="form-control shadow-none" disabled [(ngModel)]="data.UpdateTime">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                </div>                
                <div class="col-md-8">
                    <div class="input-group mb-3">
                        <button class="other-Button-gray" (click)="getCURTchMoneyAddHourModify()">儲存</button>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="input-group mb-3">
                        <button class="other-Button-gray " (click)="getCURTchMoneyAddHourDelete()">刪除</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢教師</h4>
        <button type="button" class="btn-close close btn-close-white pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width: 60px;"></th>
                    <th>職稱</th>
                    <th>教師代碼</th>
                    <th>教師姓名</th>
                </tr>
            </thead>
            <tbody style="height: 500px;">
                <tr *ngFor="let item of teacherList; let i = index" class="pointer" (click)="setTeacher(item.Code, item.Name)">
                    <td style="width: 60px;">{{i + 1}}</td>
                    <td>{{item.Title_Name}}</td>
                    <td>{{item.Code}}</td>
                    <td>{{item.Name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>
