<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year [(ngModel)]="model.Years">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term [(ngModel)]="model.Term">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-12">
            <button class="search-btn float-start me-3" (click)="getTFSMakeDocOFHIList()">查詢</button>
            <button class="other-Button-gray float-start me-3" (click)="setCopy(copyTemplate)">複製</button>
            <button class="other-Button-gray float-start" (click)="getTFSMakeDocOFHIDelete()">刪除</button>
            <button class="save-btn-gray float-end" (click)="getTFSMakeDocOFHISave()">儲存</button>
            <button class="add-btn float-end me-3" (click)="addData()">新增</button>
        </div>
    </div>

    <div class="row">
        <div class="col-md">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 50px;"></th>
                        <th style="width: 60px;">學年</th>
                        <th style="width: 60px;">學期</th>
                        <th>身分別</th>
                        <th>保險費金額</th>
                        <th>年級</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of tFSMakeDocOFHIList; let i = index" class="pointer"
                        (click)="getTFSMakeDocOFHIDetail(i, item.DataKey)">
                        <td style="width: 50px;">{{i + 1}}</td>
                        <td style="width: 60px;">{{item.Years}}</td>
                        <td style="width: 60px;">{{item.Term}}</td>
                        <td>{{item.EntryName}}</td>
                        <td>{{item.Money}}</td>
                        <td>{{item.Grade}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #dYear [disabled]="!edit"
                    [(ngModel)]="tFSMakeDocOFHIDetail.Years">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of detailYear" [value]="item">{{item}}學年</option>
                </select>
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #dTerm [disabled]="!edit"
                    [(ngModel)]="tFSMakeDocOFHIDetail.Term">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of detailTerm" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">身分別</span>
                </div>
                <select [(ngModel)]="tFSMakeDocOFHIDetail.EntryID" [disabled]="!edit" class="form-control shadow-none">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of entryIdList" value="{{item.ItemValue}}">{{item.ItemName}}
                    </option>
                </select>
                <div class="input-group-prepend">
                    <span class="input-group-text">保險費金額</span>
                </div>
                <input type="text" class="form-control shadow-none" [(ngModel)]="tFSMakeDocOFHIDetail.Money">
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">年級</span>
                </div>
                <select class="form-select shadow-none" [disabled]="!edit" [(ngModel)]="tFSMakeDocOFHIDetail.Grade">
                    <option value="" selected disabled>--請選擇--</option>
                    <option value="{{item.itemValue}}" *ngFor="let item of grade">{{item.itemName}}</option>
                </select>
            </div>
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">修改者</span>
                </div>
                <input type="text" readonly class="form-control shadow-none"
                    [(ngModel)]="tFSMakeDocOFHIDetail.UpdateUser">
                <div class="input-group-prepend">
                    <span class="input-group-text">修改時間</span>
                </div>
                <input type="text" readonly class="form-control shadow-none"
                    [(ngModel)]="tFSMakeDocOFHIDetail.UpdateTime">
            </div>
        </div>
    </div>
</div>

<ng-template #copyTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">複製資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="copyModalRef.hide()">

        </button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-md">
                <h4>從</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #copyFromYears>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of copyYears" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #copyFromTerm>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <h4>複製到</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #copyToYears>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of copyYears" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #copyToTerm>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <button class="save-btn-gray"
            (click)="copyData(copyFromYears.value, copyFromTerm.value, copyToYears.value, copyToTerm.value)">儲存</button>
    </div>
</ng-template>