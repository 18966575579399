import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetStdListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0201/getStdListInput';
import { GetStdRegiList } from 'src/app/_Models/AD/ADSTD/ADSTD0206/getStdRegiList';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADSTD0206',
  templateUrl: './ADSTD0206.component.html',
  styleUrls: ['./ADSTD0206.component.css']
})
export class ADSTD0206Component implements OnInit {

  getStdListInput!: GetStdListInput;
  baseUrl = environment.apiUrl;
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  initialData = new InitialData(this.http);
  stdRegiList!: GetStdRegiList[];
  checkType = new CheckType();
  dataKeyArray: Array<string> = [];
  Year = '';
  Term = '';
  ResultLog = '';
  constructor(private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getYears();
  }

  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    });
  }

  setModel(model: GetStdListInput) {
    this.getStdListInput = model;
  }

  setDatakey(type: string, dataKey: string, event: any) {
    let input = <HTMLInputElement>event;
    if (type == 'all') {
      if (this.dataKeyArray.length != this.stdRegiList.length && input.checked == true) {
        this.dataKeyArray = [];
        this.stdRegiList.forEach(element => {
          this.dataKeyArray.push(element.DataKey);
          element.checked = true;
        })
      }
      else {
        this.dataKeyArray = [];
        this.stdRegiList.forEach(element => {
          element.checked = false;
        })
      }
    }
    else {
      let index = this.dataKeyArray.indexOf(dataKey);
      if (index == -1) {
        this.dataKeyArray.push(dataKey);
      }
      else {
        this.dataKeyArray.splice(index, 1);
      }
    }
  }

  getStdRegiList(Years: string, Term: string, Resultlog: string) {

    this.Year = Years;
    this.Term = Term;
    this.ResultLog = Resultlog;

    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_06/Get_Std_Regi_List", {
      Years: Years,
      Term: Term,
      EduNo: this.getStdListInput.EduNo,
      DeptNo: this.getStdListInput.DeptNo,
      TeamNo: this.getStdListInput.TeamNo,
      Grade: this.getStdListInput.Grade,
      Class: this.getStdListInput.Class,
      ClassID: this.getStdListInput.ClassID,
      StdNoFrom: this.getStdListInput.StdNoFrom,
      StdNoTo: this.getStdListInput.StdNoTo,
      Resultlog: Resultlog
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.stdRegiList = []
        return;
      }
      this.stdRegiList = response;
      for (let index = 0; index < this.stdRegiList.length; index++) {
        this.stdRegiList[index].edit = false;
        this.stdRegiList[index].checked = false;
      }
    })
  }

  editData(index: number) {
    this.stdRegiList[index].edit = true;
  }

  getStdRegiSave(index: number) {

    // 欄位檢查
    if (this.stdRegiList[index].ResultLog != 'Y' && this.stdRegiList[index].ResultLog != 'N' && this.stdRegiList[index].ResultLog != '') {
      this.toastr.info("註冊欄位只能填大寫Y/N");
      return;
    }

    if (this.stdRegiList[index].StatusSub != 'Y' && this.stdRegiList[index].StatusSub != 'N' && this.stdRegiList[index].StatusSub != '') {
      this.toastr.info("延修欄位只能填大寫Y/N");
      return;
    }

    if(this.stdRegiList[index].TotalTerm!=null){
      if(this.stdRegiList[index].TotalTerm!=""){
        if (this.checkType.CheckIsNumber(this.stdRegiList[index].TotalTerm)) {
          this.toastr.info("總修學期只能填數字");
          return;
        }
      }
    }

    if(this.stdRegiList[index].RemainTerm!=null){
      if(this.stdRegiList[index].RemainTerm!=""){
        if (this.checkType.CheckIsNumber(this.stdRegiList[index].RemainTerm)) {
          this.toastr.info("可修學期只能填數字");
          return;
        }
      }
    }

    if(this.stdRegiList[index].AlreadyTerm!=null){
      if(this.stdRegiList[index].AlreadyTerm!=""){
        if (this.checkType.CheckIsNumber(this.stdRegiList[index].AlreadyTerm)) {
          this.toastr.info("已修學期只能填數字");
          return;
        }
      }
    }

    if(!(this.stdRegiList[index].TotalTerm!=null && this.stdRegiList[index].RemainTerm!=null && this.stdRegiList[index].AlreadyTerm!=null) && (this.stdRegiList[index].TotalTerm!=null || this.stdRegiList[index].RemainTerm!=null || this.stdRegiList[index].AlreadyTerm!=null)){
      this.toastr.info("總修學期、可修學期與已修學期請填寫完整");
      return;      
    }

    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_06/Get_Std_Regi_Save", this.stdRegiList[index]).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.stdRegiList[index].edit = false;
      this.toastr.success("儲存成功");
    })
  }

  getStdRegiDelete() {

    if (this.dataKeyArray.length == 0) {
      this.toastr.info("請先選擇學生");
      return;
    };

    Swal.fire({
      title: `確定刪除${this.dataKeyArray.length}筆資料?`,
      showDenyButton: true,
      confirmButtonText: '是',
      denyButtonText: '否',
    }).then((result) => {
      if (result.isConfirmed) {
        let dataKey = '';
        this.dataKeyArray.forEach(element => {
          dataKey = dataKey + element + ',';
        });

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_06/Get_Std_Regi_Delete", { DataKey: dataKey.substring(0, dataKey.length - 1) }).subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          this.toastr.success("刪除成功");
          this.dataKeyArray = [];
          this.getStdRegiList(this.Year, this.Term, this.ResultLog);
        })
      }
      else if (result.isDenied) {
        this.toastr.info("已取消動作");
      }
    });
  }
}
