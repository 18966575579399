import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADCUR0101',
  templateUrl: './ADCUR0101.component.html',
  styleUrls: ['./ADCUR0101.component.css']
})
export class ADCUR0101Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
