import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { getYearScoreListData } from 'src/app/_Models/STDWEB/STDW30002/getYearScoreListData';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-STDW30002',
  templateUrl: './STDW30002.component.html',
  styleUrls: ['./STDW30002.component.css']
})
export class STDW30002Component implements OnInit {

  baseUrl = environment.apiUrl;
  data: getYearScoreListData = { YearScore: [], GrdReport: [] };

  constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.use(this.systemService.currentLanguage)
    this.getYearScoreList();
  }


  checkScore(score: string) {
    if (score.indexOf('*') != -1) {
      return true;
    }
    return false;
  }

  getYearScoreList() {
    this.http.get<getYearScoreListData>(this.baseUrl + "ACAD/STDWEB/STDW300_02/Get_YearScore_List").subscribe((response) => {
      if (response) {
        this.data = response;
      }
      else {
        this.toastr.warning("查無資料!");
      }
    })
  }

}
