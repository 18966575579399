<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #EduNo (change)="getStdModifyList(EduNo.value)">
                    <option value="" selected>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-9" *ngIf="stdModifyList">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">開放維護時間</span>
                <input type="text" placeholder="點選設定起始日期" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly [(ngModel)]="stdModifyList.sDate"
                    style="background-color: transparent;">
                <input type="time" class="form-control pointer shadow-none" [(ngModel)]="stdModifyList.sTime">
                    <span class="input-group-text">至</span>
                <input type="text" placeholder="點選設定結束日期" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly [(ngModel)]="stdModifyList.eDate"
                    style="background-color: transparent;">
                <input type="time" class="form-control pointer shadow-none" [(ngModel)]="stdModifyList.eTime">
            </div>
        </div>
    </div>
    <div class="row" *ngIf="stdModifyList">
        <div class="col-md-6">
            <div class="input-group mb-3">
                    <div class="input-group-text check">
                        <input type="checkbox" class="pointer"
                            [checked]="stdModifyList && stdModifyList.Freshman != 'N'"
                            (click)="setCheck(0, $event.target, 'freshman')">
                </div>
                    <span class="input-group-text check">僅開放</span>
                <select class="form-select shadow-none" #Year [(ngModel)]="stdModifyList.EntryYear">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
                    <span class="input-group-text check">學年度入學新生</span>
            </div>
        </div>
        <div class="col-md-3">
            <button class="save-btn-gray" (click)="getStdModifyListSave()">儲存</button>
        </div>
    </div>

    <div *ngIf="stdModifyList">
        <div class="form-check form-check-inline" *ngFor="let item of stdModifyList.ModifyList; let i = index">
            <input class="form-check-input pointer" type="checkbox" id="{{i}}" value="option1"
                [checked]="item.Modify == '1'" (click)="setCheck(i, $event.target, '')">
            <label class="form-check-label pointer">{{item.ItemName}}</label>
        </div>
    </div>


</div>