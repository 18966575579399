import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { getHours } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ToastrService } from 'ngx-toastr';
import { SaveData } from'src/app/_Models/AD/ADABS/ADABS0101/Data';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { Data } from'src/app/_Models/AD/ADASM/ADASM0102/data';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SubjectData } from'src/app/_Models/AD/ADASM/ADASM0105/Subject';
import { ClassData } from 'src/app/_Models/AD/ADASM/ADASM0105/Class';
import { StartStudentData } from 'src/app/_Models/AD/ADASM/ADASM0105/StartStudent';
import { StartSubjectData } from 'src/app/_Models/AD/ADASM/ADASM0105/StartSubject';
import { TransferData } from 'src/app/_Models/AD/ADASM/ADASM0105/Transfer';

@Component({
    selector: 'app-ADASM0105',
    templateUrl: './ADASM0105.component.html',
    styleUrls: ['./ADASM0105.component.css']
})
export class ADASM0105Component implements OnInit {
    url = environment.apiUrl;
    data!:Data[];
    subjectdata!:SubjectData[];
    classdata!:ClassData[];
    startstudentdata!:StartStudentData[];
    startsubjectdata!:StartSubjectData[];
    Class!: string;
    Subject!: string;
    transferdata:TransferData={
        Years: '',
        Term: '',
        StrWhere: '',
        SelStyle: '',
        ItemsNo: 0
    }
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    constructor(private http: HttpClient,
        private toastr: ToastrService,private modalService: BsModalService){ }
    ngOnInit(): void {
        this.Class="";
        this.Subject="";
        this.getYears();
        this.questionclass();
        this.getsubject();
        this.getclass();
    }

    questionclass(){
        this.http.get<any>(this.url+"ACAD/ASM/ADASM0102/ASM0102_DropDownList").subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.data=response;
        })
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
          if (response) {
            this.Years = response;
          }
        });
      }

      getsubject(){
        this.http.get<any>(this.url+"ACAD/ASM/ADASM0105/ASM0105_DropDownListSubject").subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.subjectdata=response;
        })
    }

    getclass(){
        this.http.get<any>(this.url+"ACAD/ASM/ADASM0105/ASM0105_DropDownListClass").subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.classdata=response;
        })
    }

    getstartclass(Dept_No:string){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0105/ASM0105_SearchStartClass",{Dept_No:Dept_No}).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.startsubjectdata=response;
        })
    }

    getstartstudent(Class_Short:string){
        this.http.post<any>(this.url+"ACAD/ASM/ADASM0105/ASM0105_SearchStartStudent",{Class_Short:Class_Short}).subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response[0].Message);
                return;
            }
            this.startstudentdata=response;
        })
    }

    gettransfersubject(){
        this.startsubjectdata.forEach(m=>{
            if(this.transferdata.SelStyle == ''){
                this.transferdata.StrWhere = m.Class_Short;
                this.transferdata.ItemsNo = 2;
             }

             if(this.transferdata.SelStyle != ''){
                this.transferdata.StrWhere = m.Class_Short;
                this.transferdata.ItemsNo = 6;
             }

             if(m.checked==true){
                this.http.post<any>(this.url+"ACAD/ASM/ADASM0105/ASM0105_TransferFile",this.transferdata).subscribe(response=>{
                    if (response.Info == false) {
                        this.toastr.warning("轉檔失敗");
                        return;
                    }
                  this.toastr.success("轉檔成功");
                })
             }
        })
    }

    changesubjectall(event: EventTarget | null ){
        let input = <HTMLInputElement>event;
        if(input.checked==true){
            this.startsubjectdata.forEach(m=>m.checked=true)
        }
        else{
            this.startsubjectdata.forEach(m=>m.checked=false)
        }
    }

    changeclassall(event: EventTarget | null ){
        let input = <HTMLInputElement>event;
        if(input.checked==true){
            this.startstudentdata.forEach(m=>m.checked=true)
        }
        else{
            this.startstudentdata.forEach(m=>m.checked=false)
        }
    }

    changesubject(event: EventTarget | null ,i :number){
        let input = <HTMLInputElement>event;
        if(input.checked==true){
            this.startsubjectdata[i].checked=true;
        }
        else{
            this.startsubjectdata[i].checked=false;
        }
    }

    changeclass(event: EventTarget | null ,i :number){
        let input = <HTMLInputElement>event;
        if(input.checked==true){
            this.startstudentdata[i].checked=true;
        }
        else{
            this.startstudentdata[i].checked=false;
        }
    }

    gettransferclass(){
        this.startstudentdata.forEach(m=>{
            if(this.transferdata.SelStyle == ''){
                this.transferdata.StrWhere = m.Std_Name;
                this.transferdata.ItemsNo = 2;
             }

             if(this.transferdata.SelStyle != ''){
                this.transferdata.StrWhere = m.Std_Name;
                this.transferdata.ItemsNo = 6;
             }

             if(m.checked==true){
                this.http.post<any>(this.url+"ACAD/ASM/ADASM0105/ASM0105_TransferFile",this.transferdata).subscribe(response=>{
                    if (response.Info == false) {
                        this.toastr.warning("轉檔失敗");
                        return;
                    }
                  this.toastr.success("轉檔成功");
                })
             }
        })
    }
}

