import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetStdNewDetail } from 'src/app/_Models/AD/ADSTD/ADSTD0104/getStdNewDetail';
import { GetStdNewList } from 'src/app/_Models/AD/ADSTD/ADSTD0104/getStdNewList';
import { GetStdNewListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0104/getStdNewListInput';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetOccNoList } from 'src/app/_Models/getOccNoList';
import { GetRelNameList } from 'src/app/_Models/getRelNameList';
import { GetSchoolNameList } from 'src/app/_Models/getSchoolNameList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';
import { getSchoolClassList } from 'src/app/_Models/getSchoolTypeList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { TimeService } from 'src/app/_Services/_Shared/time.service';

@Component({
    selector: 'app-ADSTD0104',
    templateUrl: './ADSTD0104.component.html',
    styleUrls: ['./ADSTD0104.component.css']
})
export class ADSTD0104Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    baseUrl = environment.apiUrl;
    getStdNewListInput: GetStdNewListInput = {
        Years: '',
        Term: '',
        EduNo: '',
        DeptNo: '',
        TeamNo: '',
        Class: '',
        StdNo: '',
        IDNo: '',
        CheckIn: '',
        InsertIn: ''
    };
    model = {
        Years: '',
        Term: ''
    }
    month = require('src/app/_Models/_SelectInput/month.json').month;
    stdNewList!: GetStdNewList[] | undefined;
    initialData = new InitialData(this.http);
    deptList!: GetDeptList[];
    stdDeptList!: GetDeptList[];
    eduList!: GetEduList[];
    stdEduList!: GetEduList[];
    classList!: GetClassList[];
    stdClassList!: GetClassList[];
    teamList!: GetTeamList[];
    stdTeamList!: GetTeamList[];
    Years!: Array<string>;
    transDatakeyArray: Array<string> = [];
    deleteDatakeyArray: Array<string> = [];
    stdNewDetail!: GetStdNewDetail;
    modalRef!: BsModalRef;
    nestedModalRef!: BsModalRef;
    fileType!: GetSelectList[];
    countryList!: GetSelectList[];
    nomCityList!: GetSelectList[];
    comCityList!: GetSelectList[];
    comTownList!: GetSelectList[];
    nomTownList!: GetSelectList[];
    relNameList!: GetRelNameList[];
    occNoList!: GetOccNoList[];
    schoolTypeList!: getSchoolClassList[];
    entryDuctList!: GetSelectList[];
    schoolNameList!: GetSchoolNameList[];
    entrtIdList!: GetSelectList[];
    entryIDList!: GetSelectList[];
    entryNewList!: GetSelectList[];
    entryDivList!: GetSelectList[];
    entrySpecialTypeList!: GetSelectList[];
    entryDate: string = '';
    constructor(private http: HttpClient, private toastr: ToastrService,
        private systemService: SystemService,
        private modalService: BsModalService, private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
            this.getEduList(false);
            this.getYears();
            this.getSchoolClass();
            this.getEntryDuct();
            this.getEntryIDList();
            this.getEntryNew();
            this.getEntrySpecialType();
            this.getEntryDiv();
        });
    }

    parseInt(string: string) {
        return parseInt(string);
    }

    getFieldType() {
        this.fileType = [];
        this.systemService.getFieldType().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.fileType = response;
        })
    }

    getCountryList() {
        this.countryList = [];
        this.systemService.getCountryList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.countryList = response;
        })
    }

    getSchoolClass() {
        this.schoolTypeList = [];
        this.systemService.getSchoolClass().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.schoolTypeList = response;
        })
    }

    setTransDataKey(dataKey: string, event: any) {
        let input = <HTMLInputElement>event.target;
        let index = this.transDatakeyArray.indexOf(dataKey);

        if(input.checked){
            if (index == -1) {
                this.transDatakeyArray.push(dataKey);
            }
        }
        else{
            if (index != -1) {
                this.transDatakeyArray.splice(index, 1);
            }
        }

    }

    setDeleteDataKey(dataKey: string) {

        let index = this.deleteDatakeyArray.indexOf(dataKey);
        if (index == -1) {
            this.deleteDatakeyArray.push(dataKey);
        }
        else {
            this.deleteDatakeyArray.splice(index, 1);
        }

    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getRelNameList() {
        this.relNameList = [];
        this.systemService.getRelNameList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.relNameList = response;
        })
    }

    getOccNoList() {
        this.occNoList = [];
        this.systemService.getOccNoList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.occNoList = response;
        })
    }

    getEduList(detail: boolean) {
        let Years=(detail)?'':this.model.Years;
        let Term=(detail)?'':this.model.Term;

        if (detail){
            this.stdEduList = [];
        }

        this.systemService.getEduList('3', Years, Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (detail) {
                this.stdEduList = response;
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, detail: boolean) {
        let Years=(detail)?'':this.model.Years;
        let Term=(detail)?'':this.model.Term;

        this.systemService.getDeptList(edu, '3', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (detail) {
                this.stdDeptList = response;
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getTeamList(dept: string, detail: boolean) {
        let Years=(detail)?'':this.model.Years;
        let Term=(detail)?'':this.model.Term;

        this.systemService.getTeamList('3', Years, Term, dept, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (detail) {
                this.stdTeamList = response;
                return;
            }
            this.teamList = response;

        });
    }

    getClassList(edu: string, dept: string, detail: boolean) {
        let Years=(detail)?'':this.model.Years;
        let Term=(detail)?'':this.model.Term;

        this.systemService.getClassList(edu, dept, '3', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (detail) {
                this.stdClassList = response;
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))

    }

    getStdNewList() {
        this.stdNewList = [];
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_04/Get_Std_New_List", this.getStdNewListInput).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdNewList = []
                return;
            }
            this.stdNewList = response;
        }, error => this.toastr.error(error.error))
    }

    getStdNewTrans() {

        if (this.transDatakeyArray.length == 0) {
            this.toastr.info("請先勾選學籍");
            return;
        }

        let str = '';
        this.transDatakeyArray.forEach(element => {
            str += element + ','
        });
        let datakey = str.substring(0, str.length - 1);

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_04/Get_Std_New_Trans", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("轉入學籍成功");
            this.transDatakeyArray = [];
            document.getElementById("chkAll")!.setAttribute("checked","false");
            this.getStdNewList();
        })
    }

    getStdNewDelete() {

        if (this.deleteDatakeyArray.length == 0) {
            this.toastr.info("請先勾選學籍");
            return;
        }

        let str = '';
        this.deleteDatakeyArray.forEach(element => {
            str += element + ','
        });
        let datakey = str.substring(0, str.length - 1);

        let count = this.deleteDatakeyArray.length;

        Swal.fire({
            title: `確定刪除此${count}筆學籍資料?`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `刪除`,
            denyButtonText: `取消`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_04/Get_Std_New_Delete", { DataKey: datakey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("學籍刪除成功");
                    this.deleteDatakeyArray = [];
                    this.stdNewList = undefined;
                    this.getStdNewList();
                })
            } else if (result.isDenied) {
                this.toastr.info("動作已取消");
            }
        })
    }

    getStdNewDetail(dataKey: string, template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_04/Get_Std_New_Detail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdNewDetail = response;

            // 解碼
            this.stdNewDetail.Sex = response.Sex;
            this.stdNewDetail.StdName = response.StdName;
            this.stdNewDetail.NomElseAddr = response.NomElseAddr;
            this.stdNewDetail.ComElseAddr = response.ComElseAddr;
            this.stdNewDetail.VillageName = response.VillageName;
            this.stdNewDetail.NeighborHood = response.NeighborHood;

            if(response.FamilyData.length!=0){
                this.stdNewDetail.FamilyData[0].DepName = response.FamilyData[0].DepName;
            }

            if(response.SchoolData.length!=0){
                this.stdNewDetail.SchoolData[0].DeptName = response.SchoolData[0].DeptName;
                this.stdNewDetail.SchoolData[0].SchName = response.SchoolData[0].SchName;
            }

            //日期拼湊成西元年給date用
            this.entryDate = parseInt(this.stdNewDetail.DeptList[0].EntryYear) + 1911 + '-' + this.stdNewDetail.DeptList[0].EntryMonth + '-' + this.stdNewDetail.DeptList[0].EntryDay

            // // 初始化下拉選單
            this.getCityList();
            this.getCountryList();
            this.getFieldType();
            this.getEduList(true);
            this.initiateDeptList(this.stdNewDetail.DeptList[0].EduNo);
            this.getEntryDuct();
            this.getOccNoList();
            this.getRelNameList();
            this.getEntryIDList();

            this.modalRef = this.modalService.show(template, modalConfig)
        })
    }

    getCityList() {
        this.nomCityList = [];
        this.comCityList = [];
        this.http.post<any>(this.baseUrl + "Public/Get_City_List",{Country:"",City:"",City_Name:""}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.nomCityList = response;
            this.comCityList = response;
            if (this.stdNewDetail.ComCity != '')
                this.initiateTownList(this.stdNewDetail.ComCity);
            if (this.stdNewDetail.NomCity != '')
                this.initiateTownList(this.stdNewDetail.NomCity);

        }, error => this.toastr.error(error.error))
    }

    initiateTownList(city: string) {
        this.nomTownList = [];
        this.systemService.getTownList('000', city).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.nomTownList = response;
        }, error => this.toastr.error(error.error));
    }

    initiateDeptList(edu: string) {
        this.stdDeptList = [];
        this.systemService.getDeptList(edu, '', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdDeptList = response;
            this.initiateTeamList(this.stdNewDetail.DeptList[0].DeptNo);
            this.initateClassList(this.stdNewDetail.DeptList[0].EduNo, this.stdNewDetail.DeptList[0].DeptNo);
        }, error => this.toastr.error(error.error));
    }

    initiateTeamList(dept: string) {
        this.stdTeamList = [];
        this.systemService.getTeamList('', this.model.Years, this.model.Term, dept, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdTeamList = response;
        });
    }

    initateClassList(edu: string, dept: string) {
        this.stdClassList = [];
        this.systemService.getClassList(edu, dept, '', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdClassList = response;
        }, error => this.toastr.error(error.error))
    }

    getTownList(event: any, type: string) {
        let city = <HTMLInputElement>event.target
        if (type == 'Com') {
            this.comTownList = [];
        } else {
            this.nomTownList = [];
        }
        this.systemService.getTownList('000', city.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (type == 'Com') {
                this.comTownList = response;
            } else {
                this.nomTownList = response;
            }

        }, error => this.toastr.error(error.error));
    }

    getEntryDuct() {
        this.entryDuctList = [];
        this.systemService.getEntryDuct().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.entryDuctList = response;
        })
    }

    setEnterDate(stdEnterDate: string) {
        let date = stdEnterDate.split('-');
        this.stdNewDetail.DeptList[0].EntryYear = (parseInt(date[0]) - 1911).toString();
        this.stdNewDetail.DeptList[0].EntryMonth = date[1];
        this.stdNewDetail.DeptList[0].EntryDay = date[2];
    }

    getSchoolName(schClass: string, SchName: string, template: TemplateRef<any>, open: boolean) {
        if (schClass == '') {
            this.toastr.info("請先選擇學校類別");
            return;
        }
        this.systemService.getSchoolName(schClass, SchName, '', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.schoolNameList = response;
            if (open) {
                let modalConfig = {
                    backdrop: true,
                    ignoreBackdropClick: true,
                    class: 'modal-xl'
                };
                this.nestedModalRef = this.modalService.show(template, modalConfig);
            }
        }, error => this.toastr.error(error.error))
    }

    getEntryIDList() {
        this.entrtIdList = [];
        this.systemService.getEntryIDList('').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.entrtIdList = response;
        })
    }

    getEntryNew() {
        this.systemService.getEntryType().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.entryNewList = response;
        })
    }

    getEntrySpecialType() {
        this.systemService.getEntrySpecialType().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.entrySpecialTypeList = response;
        })
    }

    getEntryDiv() {
        this.systemService.getEntryDiv().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.entryDivList = response;
        })
    }

    chooseSchool(Sch_No: string, Sch_Name: string) {
        this.stdNewDetail.SchoolData[0].SchName = Sch_Name;
        this.stdNewDetail.SchoolData[0].SchNo = Sch_No;
        this.nestedModalRef.hide();
    }

    saveData() {

        // 編碼回去
        this.stdNewDetail.Sex = this.stdNewDetail.Sex;
        this.stdNewDetail.StdName = this.stdNewDetail.StdName;
        this.stdNewDetail.NomElseAddr = this.stdNewDetail.NomElseAddr;
        this.stdNewDetail.ComElseAddr = this.stdNewDetail.ComElseAddr;
        this.stdNewDetail.VillageName = this.stdNewDetail.VillageName;
        this.stdNewDetail.NeighborHood = this.stdNewDetail.NeighborHood;
        this.stdNewDetail.FamilyData[0].DepName = this.stdNewDetail.FamilyData[0].DepName;
        this.stdNewDetail.SchoolData[0].DeptName = this.stdNewDetail.SchoolData[0].DeptName;
        this.stdNewDetail.SchoolData[0].SchName = this.stdNewDetail.SchoolData[0].SchName;


        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_04/Get_Std_New_Modify", this.stdNewDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.modalRef.hide();

        }, error => this.toastr.error(error.error));
    }

    getStdNewDivideClass(Classmates: number) {

        if (this.getStdNewListInput.Years == '' || this.getStdNewListInput.EduNo == '' || this.getStdNewListInput.DeptNo == '' || Classmates == 0) {
            this.toastr.info("請選擇學年、學制、系所、人數");
            return;
        }

        Swal.fire({
            title: `即將針對${this.getStdNewListInput.Years}學年，學制：${this.getStdNewListInput.EduNo}，系所：${this.getStdNewListInput.DeptNo}進行每${Classmates}人分班，確定執行？`,
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_04/Get_Std_New_DivideClass", {
                    InYears: this.getStdNewListInput.Years,
                    EduNo: this.getStdNewListInput.EduNo,
                    DeptNo: this.getStdNewListInput.DeptNo,
                    Classmates: Classmates
                }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("分班完成");
                });
            }
            else {
                this.toastr.info("動作已取消");
            }
        })


    }

    getStdNewSetStdNo(StartNo: number) {

        if (this.getStdNewListInput.Years == '' || this.getStdNewListInput.EduNo == '' || this.getStdNewListInput.DeptNo == '') {
            this.toastr.info("請選擇學年、學制、系所");
            return;
        }

        Swal.fire({
            title: `即將針對${this.getStdNewListInput.Years}學年，學制：${this.getStdNewListInput.EduNo}，系所：${this.getStdNewListInput.DeptNo}編學號，確定執行？`,
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_04/Get_Std_New_SetStdNo", {
                    InYears: this.getStdNewListInput.Years,
                    EduNo: this.getStdNewListInput.EduNo,
                    DeptNo: this.getStdNewListInput.DeptNo,
                    StartNo: StartNo
                }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("編學號完成");
                });
            }
            else {
                this.toastr.info("動作已取消");
            }
        })
    }

    setChecked(type: string, event: any) {

        let input = <HTMLInputElement>event.target;

        if (input.checked) {
            if (this.stdNewDetail) {
                if (type == 'IsAddition') {
                    this.stdNewDetail.DeptList[0].IsAddition = 'Y';
                }

                if (type == 'Form5Student') {
                    this.stdNewDetail.DeptList[0].Form5Student = 'Y';
                }

                if (type == 'IsNewCitizen') {
                    this.stdNewDetail.IsNewCitizen = 'Y';
                }

                if (type == 'Regi') {
                    this.stdNewDetail.DeptList[0].Regi = true;
                }
            }

        }
        else {
            if (this.stdNewDetail) {
                if (type == 'IsAddition') {
                    this.stdNewDetail.DeptList[0].IsAddition = 'N';
                }

                if (type == 'Form5Student') {
                    this.stdNewDetail.DeptList[0].Form5Student = 'N';
                }

                if (type == 'IsNewCitizen') {
                    this.stdNewDetail.IsNewCitizen = 'N';
                }

                if (type == 'Regi') {
                    this.stdNewDetail.DeptList[0].Regi = false;
                }
            }
        }
    }

    setCheckedAll(event: any){
        let input = <HTMLInputElement>event.target;

        if(this.stdNewList!=undefined){
            document.getElementsByName("transKey").forEach(element => {
                element.click();
            });
        }
    }
}
