import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADEMP/ADEMP0101/search';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADEMP0101',
    templateUrl: './ADEMP0101.component.html',
    styleUrls: ['./ADEMP0101.component.css']
})
export class ADEMP0101Component implements OnInit {
    baseUrl = environment.apiUrl;
    data!: Search[];
    detail = new UntypedFormGroup({
        ShowCode: new UntypedFormControl(false),
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        Unit_Code: new UntypedFormControl('', Validators.required),
        Unit_Name: new UntypedFormControl('', Validators.required),
        Unit_EngName: new UntypedFormControl(''),
        Unit_EngShortName: new UntypedFormControl(''),
        Unit_Style: new UntypedFormControl('', Validators.required),
        Eorder: new UntypedFormControl(0, Validators.required),
        Link_Unit: new UntypedFormControl('', Validators.required),
        Update_User: new UntypedFormControl({ value: '', disabled: true }),
        Update_Time: new UntypedFormControl({ value: '', disabled: true }),
        Selected: new UntypedFormControl(false),
    });
    constructor(private http: HttpClient, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.search('');
    }

    search(Unit_Style: string) {
        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0101/Search", { Unit_Style: Unit_Style }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        })
    }

    searchDetail(index: number) {
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        //this.detail.setValue(this.data[index]);
        this.detail.get("ShowCode")?.setValue(this.data[index].ShowCode);
        this.detail.get("Years")?.setValue(this.data[index].Years);
        this.detail.get("Term")?.setValue(this.data[index].Term);
        this.detail.get("Unit_Code")?.setValue(this.data[index].Unit_Code);
        this.detail.get("Unit_Name")?.setValue(this.data[index].Unit_Name);
        this.detail.get("Unit_EngName")?.setValue(this.data[index].Unit_EngName);
        this.detail.get("Unit_EngShortName")?.setValue(this.data[index].Unit_EngShortName);
        this.detail.get("Unit_Style")?.setValue(this.data[index].Unit_Style);
        this.detail.get("Eorder")?.setValue(this.data[index].Eorder);
        this.detail.get("Link_Unit")?.setValue(this.data[index].Link_Unit);
        this.detail.get("Update_User")?.setValue(this.data[index].Update_User);
        this.detail.get("Update_Time")?.setValue(this.data[index].Update_Time);
        this.detail.get("Selected")?.setValue(this.data[index].Selected);
    }

    insert() {

        if (!this.detail.valid) {
            this.toastr.info("請填寫欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0101/Insert", this.detail.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.search('');
            this.reset();
            this.toastr.success("儲存成功");
        });
    }

    update() {

        if (!this.detail.valid) {
            this.toastr.info("請填寫欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0101/Update", this.detail.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.search('');
            this.reset();
            this.toastr.success("修改成功");
        });
    }

    delete() {

        if (!this.detail.valid) {
            this.toastr.info("請選擇項目");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0101/Delete", { Unit_Code: this.detail.get('Unit_Code')?.value }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.search('');
            this.reset();
            this.toastr.success("刪除成功");
        });
    }

    reset() {
        this.detail.reset();
    }
}
