import { environment } from 'src/assets/environments/environment';
import { Component, OnInit } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADEMP/ADEMP0104/search'
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { GetOtherCode } from 'src/app/_Models/getOtherCode';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-ADEMP0104',
    templateUrl: './ADEMP0104.component.html',
    styleUrls: ['./ADEMP0104.component.css']
})
export class ADEMP0104Component implements OnInit {
    url: string = environment.apiUrl;

    IsEdit: boolean = false;
    old_row: number = -1;

    Con_Column_No: string = "";

    dropDownListOtherCode!: GetOtherCode[];
    searchDataList!: Search[];

    searchData = new UntypedFormGroup({
        Column_No: new UntypedFormControl('', Validators.required),
        Column_Name: new UntypedFormControl(''),
        Item_No: new UntypedFormControl('', Validators.required),
        Item_Name: new UntypedFormControl('', Validators.required),
        Item_EngName: new UntypedFormControl(''),
        Key1: new UntypedFormControl(''),
        Key2: new UntypedFormControl(''),
        Key3: new UntypedFormControl('')
    });

    constructor(
        private http: HttpClient,
        private systemService: SystemService,
        private toastr: ToastrService
    ) {

    }

    ngOnInit(): void {
        this.Select_New_Row(-1);
        this.getOtherCode();
    }

    getOtherCode(): void {
        this.systemService.getOtherCode().subscribe(response => {
            if (response.Info == false) {
                return;
            }

            this.dropDownListOtherCode = response;

            this.Con_Column_No = this.dropDownListOtherCode[0].ItemValue;
            this.searchData.patchValue({ Column_No: this.dropDownListOtherCode[0].ItemValue });
            //this.searchData.setValue({ });
            // console.log(this.Con_Column_No);
            this.search();
        });
    }

    search() {
        this.clearData();
        this.searchDataList = [];
        console.log(this.Con_Column_No);
        this.http.post<any>(this.url + "ACAD/EMP/ADEMP0104/Search", { Column_No: this.Con_Column_No }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.searchDataList = response;
        });
    }

    searchDetail(index: number) {
        this.Select_New_Row(index);

        this.searchData.patchValue(this.searchDataList[index]);
    }

    insertData() {
        if (this.searchData.valid == false) {
            this.toastr.warning('請填寫欄位');
            return;
        }


        this.http.post<any>(this.url + "ACAD/EMP/ADEMP0104/Insert", this.searchData.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search();
            this.clearData();
        });
    }

    updateData() {
        if (this.searchData.valid == false) {
            this.toastr.warning('請填寫欄位');
            return;
        }
        console.log(this.searchData.value);
        this.http.post<any>(this.url + "ACAD/EMP/ADEMP0104/Update", this.searchData.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.search();
            this.clearData();
        });
    }

    deleteData() {
        this.http.post<any>(this.url + "ACAD/EMP/ADEMP0104/Delete", this.searchData.value).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.toastr.success("刪除成功");
            this.search();
            this.clearData();
        });
    }

    clearData() {
        this.Select_New_Row(-1);

        this.searchData.reset();
        this.searchData.patchValue({ Column_No: this.dropDownListOtherCode[0].ItemValue });
        //this.searchData.controls['Column_No'].enable();
    }

    Select_New_Row(new_row: number): void {
        if (new_row == -1) {
            this.IsEdit = false;
        } else {
            this.IsEdit = true;
        }

        if (this.old_row != -1) {
            this.searchDataList[this.old_row].selected = false;
        }

        if (new_row != -1) {
            this.searchDataList[new_row].selected = true;
        }

        this.old_row = new_row;
    }

}
