import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-year-term-input',
    templateUrl: './year-term-input.component.html',
    styleUrls: ['./year-term-input.component.css']
})
export class YearTermInputComponent implements OnInit {


    @Output() childEvent = new EventEmitter<any>();
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    model = {
        Years: '',
        Term: ''
    }

    constructor(private http: HttpClient, private systemService: SystemService, private timeService: TimeService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.getYears();
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
        });
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    GetData(model: any) {
        this.childEvent.emit(model);
    }
}
