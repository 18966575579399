import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0301/search';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUP0301',
    templateUrl: './ADCUP0301.component.html',
    styleUrls: ['./ADCUP0301.component.css']
})
export class ADCUP0301Component implements OnInit {
    baseUrl = environment.apiUrl;
    data!: Search[];
    constructor(private http: HttpClient, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.search();
    }

    search() {
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0301/Search").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response;
        });
    }
}
