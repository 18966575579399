
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADSAC0302',
  templateUrl: './ADSAC0302.component.html',
  styleUrls: ['./ADSAC0302.component.css']
})
export class ADSAC0302Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}