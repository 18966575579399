import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADABS0201',
    templateUrl: './ADABS0201.component.html',
    styleUrls: ['./ADABS0201.component.css']
})
export class ADABS0201Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    dataKeyArray: Array<string> = [];
    modalRef?: BsModalRef;
    fileExchange = new FileExchange();
    ngOnInit(): void {
        this.getYears();
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService, private modalService: BsModalService) { }

    getYears() {
        this.initialData.getYears().subscribe((response: string[]) => {
            if (response) {
                this.Years = response;
            }
        }, (error: { error: string | undefined; }) => this.toastr.error(error.error));
    }
    setDatakey(type: string, dataKey: string, event: any) {
        let input = <HTMLInputElement>event;
        if (type == 'all') {
        }
        else {
            this.dataKeyArray = [];
        }
    }
    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }
    importData($event: any) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, '', '')
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.aBSAdminContent.Base64 = base64;
            }
            else if (result.isDenied) {
                let file = <HTMLInputElement>document.querySelector('#file');
                file.value = '';
                that.toastr.info("已取消上傳。");
                that.aBSAdminContent.Base64 = '';
            }
        });
    }
}
