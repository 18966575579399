<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" [(ngModel)]="input.Years">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" [(ngModel)]="input.Term">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學生狀態</span>
                <select class="form-select shadow-none" [(ngModel)]="input.Status">
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of sTDStatusList" value="{{item.Status_No}}">{{item.Status_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #edu [(ngModel)]="input.EduNo"
                    (change)="getDeptList(edu.value)">
                    <option value="" selected disabled>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" #dept [(ngModel)]="input.DeptNo"
                    (change)="getClassList(edu.value, dept.value); getTeamList(dept.value)">
                    <option value="" selected disabled>--請選擇系所--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">組別</span>
                <select class="form-select shadow-none" [(ngModel)]="input.TeamNo">
                    <option value="" selected>--請選擇組別--</option>
                    <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班別</span>
                <select class="form-select shadow-none" [(ngModel)]="input.Class">
                    <option value="" selected>--請選擇班別--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="export-btn mx-auto" (click)="openModal(template)">匯出</button>
        </div>
    </div>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">報表欄位選擇</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">報表顯示欄位</div>
                    <div class="card-body" style="height: 300px; overflow-y: scroll;">
                        <div class="list-group">
                            <label class="list-group-item">
                                <input class="form-check-input shadow-none me-1" type="checkbox" checked disabled>
                                學號
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input shadow-none me-1" type="checkbox" checked disabled>
                                姓名
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">報表選用欄位</div>
                    <div class="card-body" style="height: 300px; overflow-y: scroll;">
                        <div class="list-group">
                            <ng-container *ngFor="let item of field ;let i = index">
                                <label class="list-group-item pointer">
                                    <input class="form-check-input shadow-none me-1" type="checkbox"
                                        value="{{item.value}}" (click)="setField(i, $event)">
                                    {{item.name}}
                                </label>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12 text-center">
                <button class="other-Button-gray" (click)="exportReportADSTD0810()">匯出</button>
            </div>
        </div>
    </div>
</ng-template>
