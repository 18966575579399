import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { menu } from 'src/app/_Models/menu';
import { environment } from 'src/assets/environments/environment';
import { SystemService } from './system.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    baseUrl = environment.apiUrl;
    private currentMenuSource = new ReplaySubject<menu>(1);
    currentMenu$ = this.currentMenuSource.asObservable();
    menu!: menu;
    constructor(private http: HttpClient, private systemService: SystemService) { }

    getMenu() {

        return this.http.post<any>(this.baseUrl + 'Portal/Get_Menu', {
            UserCode: JSON.parse((sessionStorage.getItem('auth') || '')).Code,
            Type: JSON.parse((sessionStorage.getItem('auth') || '')).Type,
            Language: '',
            IsMobile: this.systemService.checkIsMobile(),
        }).pipe(
            map((model: menu) => {
                if (model) {
                    this.setMenu(model);
                }
            })
        );
    }

    setMenu(model: menu) {
        this.currentMenuSource.next(model);
    }

}
