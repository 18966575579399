<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled selected>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學號</span>
                </div>
                <input type="text" class="form-control ">
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">姓名</span>
                </div>
                <input type="text" class="form-control ">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">發生日期</span>
                </div>
                <input type="text" placeholder="--請選擇日期--" class="form-control shadow-none" bsDaterangepicker
                    #dpr="bsDaterangepicker" formControlName="Date">
            </div>
        </div>

        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">銷過日期</span>
                </div>
                <input type="text" placeholder="--請選擇日期--" class="form-control shadow-none" bsDaterangepicker
                    #dpr="bsDaterangepicker" formControlName="Date">
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-8">
            <button type="button" class="search-btn float-start me-3">查詢</button>
        </div>
        <div class="col-md-4">
            <button type="button" class="other-Button-gray float-end me-3" style="width: 160px;">刪除
            </button>
        </div>
    </div>
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 150px;">學年</th>
                <th style="width: 150px;">學期</th>
                <th>學號</th>
                <th>姓名</th>
                <th>發生日期</th>
                <th>懲罰記錄</th>
                <th>改 / 銷過說明</th>
                <th>改 / 銷過日期</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style="width: 150px;"></td>
                <td style="width: 150px;"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>