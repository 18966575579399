import { HttpClient } from '@angular/common/http';
import { Component, NgModule, Input,OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { environment } from 'src/assets/environments/environment';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { ResultInput,ResultOutput,ResultData,ContrastUserType,ResultCosList } from 'src/app/_Models/AD/ADSTD/ADSTD0601ISU01/getData';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-Contrast',
    templateUrl: './Contrast.component.html',
    styleUrls: ['./Contrast.component.css']
})

export class ContrastComponent implements OnInit {
    @Input() inputstd!: string;
    @Input() stdSelect!: ResultOutput;
    @Input() UserType!: ContrastUserType;
    @Input() thisUserType!: string;
    @Input() EnableConfirm!: boolean;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    resultData!: ResultData;
    resultDatas!: ResultData[];
    CosList!: ResultCosList[];
    CosList01!: ResultCosList[];
    CosList02!: ResultCosList[];
    CosList03!: ResultCosList[];
    CosList04!: ResultCosList[];
    CosList05!: ResultCosList[];
    CosList06!: ResultCosList[];
    CosList07!: ResultCosList[];
    CosList08!: ResultCosList[];
    CosListPE!: ResultCosList[];
    PlanCosList!: GetSelectList[];
    ControlUrl!:string;

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService) { }

    ngOnInit(): void {
        this.ControlUrl=(this.thisUserType=='Dept' || this.thisUserType=='PE' || this.thisUserType=='PEMA' || this.thisUserType=='Regi')?"STD/ADSTD06_01_ISU01":((this.thisUserType=='DeptMA')?"TCHWEB/TCH01_17":"STDWEB/STDW300_10");

        if(this.inputstd!=''){
            this.getResultData(this.inputstd,this.stdSelect);
            this.getPlanCosList(this.inputstd);
            this.getCosData(this.inputstd);
        }
    }

    getResultData(sDataKey:string,thisStd: ResultOutput){
        this.stdSelect=thisStd;
        this.resultDatas=[];
        this.http.post<any>(this.baseUrl + "ACAD/"+this.ControlUrl+"/Get_ADSTD0601_ISU01_Result", {DataKey:sDataKey}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.resultData = response;
            this.resultDatas.push(this.resultData);
        })
    }

    getPlanCosList(sDataKey:string){
        this.PlanCosList=[];
        this.http.post<any>(this.baseUrl + "ACAD/"+this.ControlUrl+"/Get_ADSTD0601_ISU01_PlanCosList", {DataKey:sDataKey}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.PlanCosList = response;
        })
    }

    getCosData(sDataKey:string){
        this.CosList=[];
        this.CosList01=[];
        this.CosList02=[];
        this.CosList03=[];
        this.CosList04=[];
        this.CosList05=[];
        this.CosList06=[];
        this.CosList07=[];
        this.CosList08=[];
        this.CosListPE=[];
        this.http.post<any>(this.baseUrl + "ACAD/"+this.ControlUrl+"/Get_ADSTD0601_ISU01_CosList", {DataKey:sDataKey}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.CosList = response;
            this.CosList.forEach(element => {
                switch(element.CosType){
                    case '必修':
                        this.CosList01.push(element);
                        break;
                    case '選修':
                        this.CosList02.push(element);
                        break;
                    case '外系承認選修':
                        this.CosList03.push(element);
                        break;
                    case '通識':
                        this.CosList04.push(element);
                        break;
                    case '已修不及格科目':
                        this.CosList05.push(element);
                        break;
                    case '尚未修習課程':
                        this.CosList06.push(element);
                        break;
                    case '專業證照':
                        this.CosList07.push(element);
                        break;
                    case '已承認非四年計畫表必修課程':
                        this.CosList08.push(element);
                        break;
                    case '體育課':
                        this.CosListPE.push(element);
                        break;
                }
            });
        })
    }

    ChangeAgree(thisItem:ResultCosList){
        Swal.fire({
            title: "確定要變更資料？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/"+this.ControlUrl+"/Get_ADSTD0601_ISU01_Agree", thisItem).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("變更成功");
                    this.getPlanCosList(this.stdSelect.DataKey);
                    this.getResultData(this.stdSelect.DataKey,this.stdSelect);
                    this.getCosData(this.stdSelect.DataKey);
                })
            }
        });
    }

    doSend(thisResult:ResultData,sendBack:boolean){
        let alter="確定要"+((sendBack)?'退回':'送出')+"？";
        let msg="已成功"+((sendBack)?'退回':'送出');

        Swal.fire({
            title: alter,
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                let orgStatus=thisResult.Status;
                thisResult.Status=((sendBack)?'B': (parseInt(thisResult.Status)+1).toString());
                this.stdSelect.Status=thisResult.Status;
                this.http.post<any>(this.baseUrl + "ACAD/"+this.ControlUrl+"/Get_ADSTD0601_ISU01_Check", thisResult).subscribe((response) => {
                    if (response.Info == false) {
                        thisResult.Status=orgStatus;
                        this.stdSelect.Status=thisResult.Status;
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success(msg);
                    this.getPlanCosList(this.stdSelect.DataKey);
                    this.getResultData(this.stdSelect.DataKey,this.stdSelect);
                    this.getCosData(this.stdSelect.DataKey);
                })
            }
        });
    }
}
