import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetTchFileList } from 'src/app/_Models/TCHWEB/TCH0301/getTchFileList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-TCH0302',
    templateUrl: './TCH0301.component.html',
    styleUrls: ['./TCH0301.component.css']
})
export class TCH0301Component implements OnInit {

    baseUrl = environment.apiUrl;
    tchFileList!: GetTchFileList[];
    fileExchange = new FileExchange();
    constructor(private http: HttpClient, private toastr: ToastrService, private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {

    }

    importInputCkick() {
        document.getElementById('fileInput')?.click();
    }

    ImportFile(type: string, $event: any) {
        let file = $event.target.files[0];
        if (!file || type == '') {
            this.toastr.info("尚未選取檔案或類型");
            return;
        }
        this.fileExchange.FileToBase64(this, file, this.GetBase64, '', type)
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        let json = {
            TypeNo: type,
            FileName: file.name,
            ContentType: file.type,
            Base64: base64,
            IP: '',
        }

        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.fileExchangeServices.ImportFile('ACAD/TCHWEB/TCH03_01/Insert_TchFile_Base64', json)
                    .subscribe((response: any) => {
                        if (response.Info == true) { that.toastr.success("上傳成功!!"); }
                        else { that.toastr.error(response.Message, "執行錯誤!"); }
                    });
            }
            else if (result.isDenied) {
                document.getElementById(dataKey)?.setAttribute('value', '');
                that.toastr.info("已取消上傳。");
            }
        });
    }

    getTchFileList(type: string, keyword: string) {
        if (type == '') {
            this.toastr.info("請選擇類型");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_01/Get_TchFile_List", {
            Type_No: type,
            FileName: keyword
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.tchFileList = []
                return;
            }
            this.tchFileList = response;
        })
    }

    deleteData(dataKey: string, type: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_01/Delete_TchFile", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.getTchFileList(type, '');
        }, error => this.toastr.error(error.error));
    }

    exportFile(type: string, fileNo: string) {
        this.fileExchangeServices.ExportFile("ACAD/TCHWEB/TCH03_01/Export_TchFile_Base64", {
            Type_No: type,
            File_No: fileNo
        }).subscribe((response) => {
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

}
