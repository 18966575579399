<div class="container">
    <app-year-term-input (childEvent)="getCurList($event)"></app-year-term-input>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'開課班級' | translate}}</th>
                <th>{{'課程代碼' | translate}}</th>
                <th>{{'課程名稱' | translate}}</th>
                <th>{{'授課教師' | translate}}</th>
                <th>{{'操作' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of curList">
                <td data-th="開課班級">{{item.Class_Name}}</td>
                <td data-th="課程代碼">{{item.Cos_Id}}</td>
                <td data-th="課程名稱">{{item.Cos_Name}}</td>
                <td data-th="授課教師">{{item.Tch_Name}}</td>
                <td data-th="操作"><span class="other-Button-gray" (click)="openModal(template, item.DataKey)">{{'問題列表' |
                        translate}}</span></td>
                <td class="td-rwd-hide" data-th=""></td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'問題清單' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'問答狀況' | translate}}</span>
                    </div>
                    <select class="form-select shadow-none" #status>
                        <option value="" selected>{{'--全部--' | translate}}</option>
                        <option value="Y">{{'已答覆' | translate}}</option>
                        <option value="N">{{'未答覆' | translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <button class="btn btn-success me-3" (click)="getCosQuestionList(status.value)">{{'查詢' |
                    translate}}</button>
                <button class="btn btn-primary me-3" (click)="newData()">{{'提問' | translate}}</button>
            </div>
        </div>
        <table class="table table-hover table-striped text-center" id="table">
            <thead>
                <tr>
                    <th>{{'問題編號' | translate}}</th>
                    <th>{{'問題內容' | translate}}</th>
                    <th>{{'提問時間' | translate}}</th>
                    <th>{{'回覆狀態' | translate}}</th>
                    <th>{{'回覆內容' | translate}}</th>
                    <th>{{'操作' | translate}}</th>
                    <th>{{'回覆人員' | translate}}</th>
                    <th>{{'回覆時間' | translate}}</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of cosQuestionList; let i = index">
                    <td>{{item.QuestNo}}</td>
                    <td><input type="text" [(ngModel)]="item.QuestContent"
                            [disabled]="item.Status == '已答覆' || !item.isEdit"></td>
                    <td>{{item.Date}}</td>
                    <td class="fw-bolder" [ngClass]="{'text-success': item.Status == '已答覆',
                                    'text-danger': item.Status =='未答覆'}">{{item.Status}}</td>
                    <td>{{item.AnsContent}}</td>
                    <td>
                        <button class="btn btn-warning btn-sm me-1" [hidden]="item.isEdit || item.Status == '已答覆'"
                            (click)="editData(i)">{{'編輯' | translate}}</button>
                        <button class="btn btn-primary btn-sm me-1" [hidden]="!item.isEdit"
                            (click)="saveData(i, item.DataKey)">{{'送出' | translate}}</button>
                        <button class="btn btn-danger btn-sm" [hidden]="item.Status == '已答覆'"
                            (click)="deleteData(item.DataKey)">{{'刪除' | translate}}</button>
                    </td>
                    <td>{{item.Anser}}</td>
                    <td>{{item.AnsDate}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>