<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-7">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學制</span>
                        <select [(ngModel)]="search1.Edu_No" class="form-select shadow-none" #EduNo
                            (change)="getDeptList(EduNo.value)">
                            <option value="" selected disabled>--請選擇--</option>
                            <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select [(ngModel)]="search1.Dept_No" class="form-select shadow-none" #DeptNo>
                            <option value="" selected>--全部--</option>
                            <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="search-btn float-start me-3" (click)="search(EduNo.value, DeptNo.value)">查詢</button>
                </div>
            </div>

            <table class="table table-hover table-striped text-center mt-3">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th style="width: 80px;">科系代碼</th>
                        <th>科系名稱</th>
                        <th style="width: 80px;">組別代碼</th>
                        <th style="width: 80px;">組別名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="searchDetail(item.Dept_No, item.Team_No, i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td style="width: 80px;">{{item.Dept_No}}</td>
                        <td>{{item.Dept_Name}}</td>
                        <td style="width: 80px;">{{item.Team_No}}</td>
                        <td style="width: 80px;">{{item.Team_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-5 border-start">
            <div class="row">
                <div class="col-md-12">
                    <button class="other-Button-gray float-start me-3" (click)="insert()">新增</button>
                    <button class="other-Button-gray float-start me-3" (click)="update()">修改</button>
                    <button class="other-Button-gray float-start me-3" (click)="delete()">刪除</button>
                    <button class="other-Button-gray float-start me-3" (click)="clear()">清除</button>
                </div>
            </div>
            <div class="row mt-3">
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學制</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Edu_No" #dEduNo
                            (change)="getDeptList(dEduNo.value)">
                            <option value="" selected disabled>--請選擇--</option>
                            <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Dept_No">
                            <option value="" selected disabled>--請選擇--</option>
                            <option *ngFor="let item of deptList" [ngValue]="item.Dept_No">{{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">組別代碼</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Team_No"
                            placeholder="至多兩碼" maxlength="2">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">組別名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Team_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">組別英文名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Team_EngName">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學位名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.ADName">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學位英文名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.EADName">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修業年數</span>
                        <input type="text" class="form-control shadow-none" min="1"
                            [(ngModel)]="dataDetail.Grade_Years">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">狀態</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.State_Log">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="1">啟用</option>
                            <option value="0">未啟用</option>
                        </select>
                    </div>
                </div>               
                <div class="col-md-4">
                    <div class="form-check pt-1">
                        <input class="form-check-input" type="checkbox" value="" [checked]="dataDetail.Print_Log">
                        <label class="form-check-label">證書列印組別名稱</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">一般可延長年限</span>
                        <input type="text" class="form-control shadow-none" min="1"
                            [(ngModel)]="dataDetail.Limit_Years">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">在職生可延長年限</span>
                        <input type="text" class="form-control shadow-none" min="1"
                            [(ngModel)]="dataDetail.OnJobLimit_Years">
                    </div>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">聯招會代碼</span>
                        <input type="text" class="form-control shadow-none"
                            [(ngModel)]="dataDetail.Exam_DeptNo">
                    </div>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改日期</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_Time">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_User">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>