import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-STDW20004',
  templateUrl: './STDW20004.component.html',
  styleUrls: ['./STDW20004.component.css']
})
export class STDW20004Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
