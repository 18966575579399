<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">專兼職別</span>
                </div>
                <select class="form-select shadow-none" #TimeCase>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of timeCaseList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <button class="other-Button-gray"
                (click)="getCURTchMoneyCompute(Year.value, Term.value, TimeCase.value)">計算</button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-4">
            <form [formGroup]="ngFormInput" (ngSubmit)="getCURTchMoneyResultList()">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">學年</span>
                            </div>
                            <select class="form-select shadow-none" #dYear
                                (change)="getUnitList(dYear.value, dTerm.value)" formControlName="Years">
                                <option value="" selected disabled>--請選擇--</option>
                                <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">學期</span>
                            </div>
                            <select class="form-select shadow-none" #dTerm
                                (change)="getUnitList(dYear.value, dTerm.value)" formControlName="Term">
                                <option value="" selected disabled>--請選擇--</option>
                                <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">鐘點類別</span>
                            </div>
                            <select class="form-select shadow-none" formControlName="ResultType">
                                <option value="" selected disabled>--請選擇--</option>
                                <option value="D">日鐘點</option>
                                <option value="N">夜鐘點</option>
                                <option value="E">EMBA</option>
                                <option value="O">其他</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">專兼職別</span>
                            </div>
                            <select class="form-select shadow-none" formControlName="TimeCase">
                                <option value="" selected disabled>--請選擇--</option>
                                <option *ngFor="let item of timeCaseList" value="{{item.ItemValue}}">{{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">所屬單位</span>
                            <select class="form-select shadow-none" formControlName="UnitCode">
                                <option value="" selected disabled>--請選擇--</option>
                                <option value="{{item.ItemValue}}" *ngFor="let item of unitData">{{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">職稱</span>
                            </div>
                            <select class="form-select shadow-none" formControlName="Title">
                                <option value="" selected disabled>--請選擇--</option>
                                <option *ngFor="let item of titleList" value="{{item.ItemValue}}">{{item.ItemName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">人事代碼</span>
                            </div>
                            <input type="text" class="form-control shadow-none" formControlName="TchNo">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">姓名</span>
                            </div>
                            <input type="text" class="form-control shadow-none" formControlName="TchName">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-check mb-3">
                            <input class="form-check-input pointer shadow-none" type="checkbox" value=""
                                id="flexCheckDefault" formControlName="ByDept">
                            <label class="form-check-label pointer" for="flexCheckDefault">依系所分頁籤</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">資料排序</span>
                            </div>
                            <select class="form-select shadow-none" formControlName="SortIndex">
                                <option value="" selected disabled>--請選擇--</option>
                                <option value="A">教師代碼</option>
                                <option value="B">單位、教師代碼</option>
                                <option value="C">單位、職稱</option>
                                <option value="D">職稱、教師代碼</option>
                                <option value="E">職稱、單位</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button class="search-btn float-start me-3">查詢</button>
                        <button type="button" class="other-Button-gray" (click)="exportReport()">匯出檔案</button>
                    </div>
                </div>
            </form>

        </div>
        <div class="col-md-8">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th><input type="checkbox" class="form-check-input shadow-none pointer"
                                (click)="setDatakey('all', '', $event.target)">&emsp;全選</th>
                        <th>人事代碼</th>
                        <th>教師姓名</th>
                        <th>教師職稱</th>
                        <th>教學單位</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of cURTchMoneyResultList">
                        <td><input type="checkbox" class="form-check-input shadow-none pointer"
                                (click)="setDatakey('', item.DataKey, $event.target)" [(ngModel)]="item.checked"></td>
                        <td>{{item.TchNo}}</td>
                        <td>{{item.TchName}}</td>
                        <td>{{item.Title}}</td>
                        <td>{{item.UnitCode}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
