import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCUPMailStdList } from 'src/app/_Models/AD/ADCUP/ADCUP0603/getCUPMailStdList';
import { GetCUPMailStdSendEMail } from 'src/app/_Models/AD/ADCUP/ADCUP0603/getCUPMailStdSendEMailInput';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUP0603',
    templateUrl: './ADCUP0603.component.html',
    styleUrls: ['./ADCUP0603.component.css']
})
export class ADCUP0603Component implements OnInit {
    baseUrl = environment.apiUrl;
    modalRef?: BsModalRef;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    cUPMailStdList!: GetCUPMailStdList[];
    initialData = new InitialData(this.http);
    dataKeyArray: Array<string> = [];
    Years!: Array<string>;
    deptList!: GetDeptList[];
    eduList!: GetEduList[];
    classList!: GetClassList[];
    fileExchange = new FileExchange();
    json: GetCUPMailStdSendEMail = {
        DataKey: "",
        Subject: "",
        Content: "",
        Attachments: []
    }
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private modalService: BsModalService, private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
    }

    setDatakey(type: string, dataKey: string, event: any) {

        if (!this.cUPMailStdList) return;

        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.cUPMailStdList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.cUPMailStdList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.cUPMailStdList.forEach(element => {
                    element.checked = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error));
    }

    import($event: any, dataKey: string, type: string) {
        let files = $event.target.files;
        if (!files) {
            this.toastr.info("無偵測到檔案");
            return;
        }

        for (let index = 0; index < files.length; index++) {
            this.fileExchange.FileToBase64(this, files[index], this.GetBase64, dataKey, type);
        }
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        that.json.Attachments.push({
            FileName: file.name,
            FileBase64: base64,
            FileContentType: file.type
        })
    }

    getCUPMailStdList(Years: string, Term: string, EduNo: string, DeptNo: string, Class: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0603/Get_CUP_MailStd_List", {
            Years: Years,
            Term: Term,
            EduNo: EduNo,
            DeptNo: DeptNo,
            Class: Class
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cUPMailStdList = []
                return;
            }
            this.cUPMailStdList = response;
            this.cUPMailStdList.forEach(element => {
                element.checked = false;
            });
        })
    }

    openModal(template: TemplateRef<any>) {

        if (this.dataKeyArray.length == 0) {
            this.toastr.info("請先選擇學生");
            return;
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getCUPMailStdSendEMail(Subject: string, Content: string) {
        let str = '';
        this.dataKeyArray.forEach(element => {
            str += element + ','
        });

        this.json.DataKey = str.substring(0, str.length - 1);

        this.json.Content = Content;

        this.json.Subject = Subject;

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0603/Get_CUP_MailStd_SendEMail", this.json).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("發送完成");
            this.modalRef?.hide();
            this.dataKeyArray = [];
            this.json = {
                DataKey: "",
                Subject: "",
                Content: "",
                Attachments: []
            };
            this.cUPMailStdList.forEach(element => {
                element.checked = false;
            });
        })
    }
}
