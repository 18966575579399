<div class="container">
    <div>
        <table class="table text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width: 300px;">獎懲代碼</th>
                    <th style="width: 300px;">獎懲名稱</th>
                    <th>加/扣分</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of searchdata">
                    <td style="width: 300px;">{{a.Rcode}}</td>
                    <td style="width: 300px;">{{a.Rname}}</td>
                    <td><input type="text" class="text-center" [(ngModel)]="a.Rscore1"></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <div class="col-md-5">
        </div>
        <div class="col-md-6">
            <button class="save-btn-gray" (click)="save()">儲存</button>
        </div>
    </div>
</div>