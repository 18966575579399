<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select [(ngModel)]="search1.eduNo" class="form-select shadow-none" #eduNo
                    (change)="getDeptList(eduNo.value, false)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>

        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select [(ngModel)]="search1.DeptNo" class="form-select shadow-none" #DeptNo>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-7">
            <button class="other-Button-gray float-end me-3" (click)="clear()">清除畫面</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="other-Button-gray float-end me-3" (click)="insert()">新增</button>
            <button class="search-btn float-end me-3" (click)="search(eduNo.value, DeptNo.value, '')">查詢</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>班級代碼</th>
                        <th>班級簡稱</th>
                        <th>狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="searchDetail(item.Class, i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.Class}}</td>
                        <td>{{item.Class_Short}}</td>
                        <td>{{item.State_Log == '1'?'啟用':'未啟用'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">班級代碼</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Class">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">班級名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Class_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">班級簡稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Class_Short">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學制</span>
                        <select class="form-select shadow-none" #eduNo (change)="getDeptList(eduNo.value, true)"
                            [(ngModel)]="dataDetail.Edu_No">
                            <option value="" selected disabled>--請選擇--</option>
                            <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Dept_No">
                            <option value="" selected disabled>--請選擇--</option>
                            <option *ngFor="let item of detailDeptList" [ngValue]="item.Dept_No">
                                {{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">狀態</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.State_Log">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="1">啟用</option>
                            <option value="0">未啟用</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">升級班級</span>
                        <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.UpGrade">
                        <button class="btn btn-light shadow-none" (click)="openModel(template)">...</button>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.UpGrade_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">年級</span>
                        <select class='form-select' [(ngModel)]="dataDetail.Grade">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input pointer shadow-none" type="checkbox"
                                id="inlineCheckbox1" [(ngModel)]="dataDetail.Assign_Class">
                            <label class="form-check-label pointer" for="inlineCheckbox1">啟用編班</label>
                        </div>   
                    </div>
                </div>             
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改日期</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Update_Time"
                            disabled>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Update_user"
                            disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">班級查詢</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #eduNo (change)="getDeptList(eduNo.value, false)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" #DeptNo>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <button class="search-btn float-end me-3"
                    (click)="search(eduNo.value, DeptNo.value, 'upgrade')">查詢</button>
            </div>
        </div>

        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width: 60px;"></th>
                    <th>班級代碼</th>
                    <th>班級簡稱</th>
                    <th>狀態</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of upgradeData; let i = index" class="pointer"
                    [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                    (click)="setUpgradeClass(i)">
                    <td style="width: 60px;">{{i + 1}}</td>
                    <td>{{item.Class}}</td>
                    <td>{{item.Class_Short}}</td>
                    <td>{{item.State_Log == '1'?'啟用':'未啟用'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>