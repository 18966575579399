<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">註冊學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">註冊學期</span>
                <select class="form-select shadow-none" name="Term" #Term>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">是否註冊</span>
                <select class="form-select shadow-none" name="Resultlog" #Resultlog>
                    <option value="" disabled>--請選擇--</option>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
    </div>
    <app-ADSTD-search-input (childEvent)="setModel($event)"></app-ADSTD-search-input>
    <div class="row mb-3">
        <div class="col-md-3">
            <button class="search-btn float-start me-3"
                (click)="getStdRegiList(Year.value, Term.value, Resultlog.value)">查詢</button>
            <button class="other-Button-gray float-start" (click)="getStdRegiDelete()">刪除</button>
        </div>
    </div>
</div>
<div class="container-fluid">
    <table class="table text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 80px;"><input type="checkbox" (click)="setDatakey('all', '', $event.target)"
                        class="pointer">&ensp;全選</th>
                <th style="width: 60px;">學年</th>
                <th style="width: 60px;">學期</th>
                <th>學號</th>
                <th style="width: 80px;">姓名</th>
                <th>系所代碼</th>
                <th>組別代碼</th>
                <th>班級代碼</th>
                <th>註冊日</th>
                <th>是否註冊</th>
                <th>是否延修</th>
                <th>總修學期</th>
                <th>已修學期</th>
                <th>可修學期</th>
                <th style="width: 90px;"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdRegiList; let i = index">
                <td style="width: 80px;"><input type="checkbox" [checked]="item.checked"
                        (click)="setDatakey('', item.DataKey, $event.target)" class="pointer"></td>
                <td style="width: 60px;">{{item.Years}}</td>
                <td style="width: 60px;">{{item.Term}}</td>
                <td>{{item.StdNo}}</td>
                <td style="width: 80px;">{{item.StdName}}</td>
                <td><input type="text" [(ngModel)]="item.DeptNo" [disabled]="!item.edit" [class.input]="!item.edit"
                        style="width: 60px;" class="text-center"></td>
                <td><input type="text" [(ngModel)]="item.TeamNo" [disabled]="!item.edit" [class.input]="!item.edit"
                        style="width: 60px;" class="text-center"></td>
                <td><input type="text" [(ngModel)]="item.Class" [disabled]="!item.edit" [class.input]="!item.edit"
                        style="width: 80px;" class="text-center"></td>
                <td>{{item.RegiDate}}</td>
                <td><input type="text" [(ngModel)]="item.ResultLog" [disabled]="!item.edit" [class.input]="!item.edit"
                        style="width: 30px;" class="text-center"></td>
                <td><input type="text" [(ngModel)]="item.StatusSub" [disabled]="!item.edit" [class.input]="!item.edit"
                        style="width: 30px;" class="text-center"></td>
                <td><input type="text" [(ngModel)]="item.TotalTerm" [disabled]="!item.edit" [class.input]="!item.edit"
                        style="width: 30px;" class="text-center"></td>
                <td><input type="text" [(ngModel)]="item.AlreadyTerm" [disabled]="!item.edit" [class.input]="!item.edit"
                        style="width: 30px;" class="text-center"></td>
                <td><input type="text" [(ngModel)]="item.RemainTerm" [disabled]="!item.edit" [class.input]="!item.edit"
                        style="width: 30px;" class="text-center"></td>
                <td style="width: 90px;">
                    <a class="pointer"><span class="icon-Fill-in-button" [hidden]="item.edit"
                            (click)="editData(i)">編輯</span></a>
                    <a class="pointer"><span class="icon-Save-Button" [hidden]="!item.edit"
                            (click)="getStdRegiSave(i)">修改</span></a>
                </td>
            </tr>
        </tbody>
    </table>
</div>