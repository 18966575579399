<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">入學學年</span>
                <select class="form-select shadow-none" name="Years" #Year>
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">入學學期</span>
                <select class="form-select shadow-none" name="Term" #Term>
                    <option value="" disabled >--全部--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">入學日期</span>
                <input type="date" class="form-control shadow-none" #date>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">工作表名稱</span>
                <input type="text" class="form-control shadow-none" value="sheet1" #SheetName>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-4">
            <p class="text-danger fw-bolder">*上傳時請確認Excel之儲存格格式是否為【文字】</p>
            <div class="row">
                <div class="col-md-12">
                    <button class="import-btn float-start me-3" style="width: 120px;" (click)="importDataClick(Year.value, Term.value, date.value, SheetName.value)">匯入資料</button>
                    <input type="file" accept=".xls, .xlsx" class="form-control shadow-none" hidden="true" id="file" (change)="importData($event, '', '')">
                    <button class="other-Button-gray float-start" (click)="exportTemplate()">匯出格式</button>
                   
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <p class="text-danger fw-bolder">*大考中心新生錄取資料匯入</p>
            <p class="text-danger fw-bolder">*TXT文字檔編碼須為UTF8</p>
            <div class="row">
                <p class="text-danger fw-bolder">一、資料欄位設定</p>
                <div class="col-md-12">
                    <button class="other-Button-gray float-start" (click)="openModal(FieldsTemplate)">設定</button>
                </div>             
            </div>
            <div class="row">
                <p class="text-danger fw-bolder"></p>
                <p class="text-danger fw-bolder">二、匯入檔案</p>
                <div class="col-md-12">
                    <button class="import-btn float-start me-3" style="width: 120px;" (click)="importCEECClick(Year.value, Term.value, date.value)">匯入</button>
                    <input type="file" accept=".txt" class="form-control shadow-none" hidden="true" id="ceecfile" (change)="importCEECData($event, '', '')">
                </div>
            </div>
        </div>
    </div>   
</div>
<ng-template #FieldsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定資料欄位</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
        </button>
    </div>
    <div class="modal-body bodyHeight">
        <div class="row">
            <p class="text-danger fw-bolder">*請輸入各欄位的長度即可</p>
            <table class="table table-hover table-striped text-center mt-3">
                <thead class="thead-dark-red">
                    <tr>
                        <th class="Fields01">項次</th>                
                        <th class="Fields021">欄位名稱</th>
                        <th class="Fields03">起始欄位</th>
                        <th class="Fields04">結束欄位</th>
                        <th class="Fields05">長度</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of FieldsList; let i = index">
                        <td class="Fields01">{{i+1}}</td>
                        <td class="Fields022">{{item.Field_Name}}</td>
                        <td class="Fields03">
                            <input type="text" readonly class="form-control shadow-none inputTextAlign" min="1" max="1000"
                            [(ngModel)]="item.S_Point">
                        </td>
                        <td class="Fields04">
                            <input type="text" readonly class="form-control shadow-none inputTextAlign" min="1" max="1000"
                            [(ngModel)]="item.E_Point">
                        </td>                  
                        <td class="Fields05">
                            <input type="text" class="form-control shadow-none inputTextAlign" min="1" max="1000"
                            [(ngModel)]="item.Field_Length" (blur)="ReSetPoint()">                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row RowHeight">
        <div class="col-md-5"></div>
        <div class="col-md-7">
            <button class="save-btn-gray" (click)="SaveFields()">儲存</button>
        </div>
    </div>      
</ng-template>
