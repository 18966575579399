import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { getScoreCurList } from 'src/app/_Models/TCHWEB/TCH0114/getScoreCurList';
import { environment } from 'src/assets/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { getSetModeList,DataKeyInput } from 'src/app/_Models/TCHWEB/TCH0114/getSetModeList';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import Swal from 'sweetalert2';
import { getStdList } from 'src/app/_Models/TCHWEB/TCH0114/getStdList';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { GetCosInfo } from 'src/app/_Models/TCHWEB/TCH0114/getCosInfo';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';

@Component({
    selector: 'app-TCH0114',
    templateUrl: './TCH0114.component.html',
    styleUrls: ['./TCH0114.component.css']
})
export class TCH0114Component implements OnInit {
    baseUrl = environment.apiUrl;
    fileExchange = new FileExchange();
    checkType = new CheckType();
    modalRef!: BsModalRef;
    model = {
        Years: '',
        Term: ''
    }
    totalData!: Array<getScoreCurList>;
    modeData!: getSetModeList;
    CurSelect?:DataKeyInput[];
    ScoreData!: Array<getStdList>;
    disabled: boolean = false;
    cosInfo!: GetCosInfo;
    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private fileExchangeServices: FileExchangeService,
        private timeService: TimeService
    ) { }


    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
            this.query(this.model);
        });
    }

    query(model: any) {
        this.http.post<getScoreCurList[]>(this.baseUrl + "ACAD/TCHWEB/TCH01_14/Get_ScoreCur_List", model)
            .subscribe((response) => {
                if (response.length == undefined) {
                    this.toastr.warning("無符合條件的資料", "查詢結果");
                    this.totalData = [];
                    return;
                }
                this.model = model;
                this.totalData = response;
            });
    }

    openModal(template: TemplateRef<any>, type: string, dataKey: string) {
        if (type == "setGrade_Mode") {
            this.Get_SetMode_List(dataKey, '', template);
        }

        if (type == "ScoreData") {
            this.CurSelect=[];
            this.CurSelect.push({DataKey:dataKey,IsTch:true});
            this.modalRef?.hide()
            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };
            this.modalRef = this.modalService.show(template, modalConfig);
        }
    }

    Get_SetMode_List(dataKey: string, ipAddress: string, template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };
        this.http.post<getSetModeList>(this.baseUrl + 'ACAD/TCHWEB/TCH01_14/Get_SetMode_List', { DataKey: dataKey, IP: ipAddress })
            .subscribe((response) => {
                if (response) {
                    this.modeData = response;
                    this.modalRef = this.modalService.show(template, modalConfig);
                }
            });
    }

    CheckData(type: string) {
        if (type == "setGrade_Mode") {

            try {
                if (this.modeData.Grade_Mode == '1' &&
                    parseFloat(this.modeData.FTerm_Ratio) +
                    parseFloat(this.modeData.MTerm_Ratio) +
                    parseFloat(this.modeData.Quiz_Ratio) != 100) {
                    this.toastr.error("分數相加必須為100!", "儲存錯誤!");
                    return;
                }
                else if (this.modeData.Grade_Mode == '2') {
                    this.modeData.FTerm_Ratio = '0';
                    this.modeData.MTerm_Ratio = '0';
                    this.modeData.Quiz_Ratio = '0';
                    this.SaveSetMode(type);
                    this.modalService.hide();
                }
                else {
                    this.SaveSetMode(type);
                    this.modalService.hide();
                }
            }
            catch (ex: any) {
                console.error(ex);
            }
        }
    }

    SaveSetMode(type: string) {
        if (type == "setGrade_Mode") {
            this.http.post<getSetModeList>(this.baseUrl + "ACAD/TCHWEB/TCH01_14/Save_SetMode", {
                Grade_Mode: this.modeData.Grade_Mode,
                Quiz_Ratio: this.modeData.Quiz_Ratio,
                MTerm_Ratio: this.modeData.MTerm_Ratio,
                FTerm_Ratio: this.modeData.FTerm_Ratio,
                DataKey: this.modeData.DataKey,
                IP: ''
            })
                .subscribe(() => {
                    this.toastr.success("資料儲存成功!");
                    this.query(this.model);
                },
                    error => {
                        this.toastr.error(error.error, "系統錯誤!");
                    });
        }
    }

    exportData(dataKey: string) {
        this.fileExchangeServices.ExportFile("ACAD/TCHWEB/TCH01_14/Export_ScoreFile_Base64", { DataKey: dataKey, IP: '' })
            .subscribe((response) => {
                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = response.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            });
    }

    importInputCkick(dataKey: string) {
        document.getElementById(dataKey)?.click();
    }

    importData($event: any, dataKey: string, type: string) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type)
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string) {
        let json = {
            DataKey: dataKey,
            IP: that.ipAddress,
            Base64: base64
        }
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.fileExchangeServices.ImportFile('ACAD/TCHWEB/TCH01_14/Import_ScoreFile_Base64', json)
                    .subscribe((response: any) => {
                        if (response.Info == true) { that.toastr.success("上傳成功!!"); }
                        else { that.toastr.error(response.Message, "執行錯誤!"); }
                    });
            }
            else if (result.isDenied) {
                document.getElementById(dataKey)?.setAttribute('value', '');
                that.toastr.info("已取消上傳。");
            }
        });
    }
}
