<div class="container">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
    <div class="row">
        <div class="col-md">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'校區樓別'|translate}}</span>
                </div>
                <select class="form-select shadow-none" (change)="getClassRoomList($event)">
                    <option disabled selected value="">{{'--請選擇校區樓別--'|translate}}</option>
                    <option *ngFor="let item of buildingList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'教室'|translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="classroom">
                    <option disabled selected value="">{{'--請選擇教室--'|translate}}</option>
                    <option *ngFor="let item of classList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <button class="search-btn mb-3" (click)="getCurList()">{{'查詢'|translate}}</button>
    <app-cur-class id="cur-class" [curList]="data" [functionType]="functionType"></app-cur-class>
    <app-cur-class-rwd id="cur-class-rwd" [curList]="data" [functionType]="functionType"></app-cur-class-rwd>
</div>