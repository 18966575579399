import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0502',
    templateUrl: './ADSEL0502.component.html',
    styleUrls: ['./ADSEL0502.component.css']
})
export class ADSEL0502Component implements OnInit {
    baseUrl = environment.apiUrl;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    deptList!: GetDeptList[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', Validators.required),
        Term: new UntypedFormControl('', Validators.required),
        CheckType: new UntypedFormControl('A1', Validators.required),
        DeptNo: new UntypedFormControl('')
    });

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getDeptList() {

        if (this.ngFormInput.get("Years")?.value == '' || this.ngFormInput.get("Term")?.value == '') return;


        this.systemService.getDeptList('', '0', this.ngFormInput.get("Years")?.value, this.ngFormInput.get("Term")?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    exportReportADSEL0502() {
        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }
        this.fileExchangeServices.ExportFile("ACAD/SEL/ADSEL0502/Export_Report_ADSEL0502", this.ngFormInput.value).subscribe((response) => {
            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

}
