<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Years">
                        <option value="">--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Term">
                        <option value="">--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getDeptList()" formControlName="EduNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getClassList();" formControlName="DeptNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>    
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">建議人</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="CodeName">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">填單日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                        formControlName="ApplyDateFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                        formControlName="ApplyDateTo">
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">狀態</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Status">
                        <option value="" selected>--請選擇--</option>
                        <option value="1">審核中</option>
                        <option value="2">已審核</option>
                    </select>
                </div>
            </div>             
        </div>    
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">備註</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="Memo">
                </div>
            </div>
            <div class="col-md-6">
                <button class="other-Button-gray float-end me-3" type="button" (click)="getSRPBatchDelete()">刪除</button>                
                <button class="other-Button-gray float-end me-3" type="button"
                    (click)="openModel(template, 'new',this.sRPEmpty[0], 0)">新增</button>
                <button class="search-btn float-end me-3" (click)="getSRPList()">查詢</button>                    
            </div>                       
        </div>
    </form>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 70px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                        (click)="setAll($event.target)">&ensp;全選
                </th>
                <th style="width: 60px;">學年期</th>
                <th style="width: 100px;">建議人</th>
                <th style="width: 100px;">案號</th>
                <th style="width: 140px;">填單日期</th>
                <th style="width: 100px;">記功過日期</th>
                <th style="width: 200px;">備註</th>
                <th style="width: 100px;">審核日期</th>
                <th style="width: 100px;">處理狀態</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sRPList; let i = index">
                <td style="width: 70px;">
                    <input type="checkbox" class="form-check-input shadow-none pointer" [(ngModel)]="item.Checked">
                </td>
                <td style="width: 60px;">{{item.Years}}-{{item.Term}}</td>
                <td style="width: 100px;">{{item.ApplyUser}}</td>
                <td style="width: 100px;">
                    <a class="pointer text-decoration-none"
                    (click)="openModel(template, '', item, (i+1))">{{item.SerialNo}}</a>               
                </td>
                <td style="width: 140px;">{{item.ApplyDate}}</td>
                <td style="width: 100px;">{{item.RecDate}}</td>
                <td style="width: 200px;text-align:left;">{{item.Memo}}</td>
                <td style="width: 100px;">{{item.ApproveDate}}</td>
                <td style="width: 100px;">{{item.StatusName}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">學生獎懲建議</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormDetail">
            <div class="row">
                <div class="col-md-2">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">學年</span>
                        </div>
                        <select class="form-select shadow-none" #Year formControlName="Years" (change)="getSuggestList()">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">學期</span>
                        </div>
                        <select class="form-select shadow-none" #Term formControlName="Term">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">單位</span>
                        </div>
                        <ng-template>
                            <span class="input-group-text">{{ngFormDetail.controls.ApplyUnitName.value}}</span>
                        </ng-template>                        
                    </div>
                </div>                  
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">證明文件</span>
                        </div>
                        <input class="form-control shadow-none" type="file" (change)="importAttFile($event, '', '')">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <label (click)="getSRPFile(ngFormDetail.get('DataKey')?.value)">{{ngFormDetail.get('FileName')?.value}}</label>
                    </div>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">備註</span>
                        </div>
                        <input class="form-control shadow-none" type="text" #Memo formControlName="Memo" maxlength="300">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">記功過日期</span>
                        </div>
                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly  #Memo formControlName="RecDate">  
                    </div>                    
                </div> 
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">審核日期</span>
                        </div>
                        <input type="text" class="form-control pointer shadow-none" readonly  #Memo formControlName="ApproveDate"> 
                    </div>                    
                </div>                            
            </div>
        </form>    
        <div class="row" style="height:400px;overflow-y:scroll;">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 20px;"></th>
                        <th style="width: 90px;">學號</th>
                        <th style="width: 80px;">姓名</th>
                        <th style="width: 90px;">獎懲建議</th>
                        <th style="width: 60px;">次數</th>
                        <th style="width: 110px;">發生日期</th>
                        <th style="width: 140px;">引證條文</th>
                        <th style="width: 150px;">獎懲事由</th>                       
                        <th style="width: 90px;">批示日期</th>
                        <th style="width: 90px;">狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of sRPDetailList; let i = index">
                        <td style="width: 20px;height:30px;">{{i + 1}}</td>
                        <td style="width: 80px;">
                            <input type="text"
                                class="form-control form-control-sm shadow-none text-center"
                                [(ngModel)]="item.StdNo" maxlength="10">
                        </td>
                        <td style="width: 80px;">
                            <ng-container *ngIf="item.StdName != ''">{{item.StdName}}</ng-container>
                        </td>
                        <td style="width: 80px;">
                            <select class="form-select shadow-none" #TypeID [(ngModel)]="item.TypeID">
                                <option value="" disabled>--請選擇--</option>
                                <option *ngFor=" let itemRewards of rewardsList" [value]="itemRewards.ItemValue">{{itemRewards.ItemName}}</option>
                            </select>                                
                        </td>
                        <td style="width: 40px;"><input type="number"
                                class="form-control form-control-sm shadow-none text-center" min="1"
                                step="1" [(ngModel)]="item.Times"
                                [ngModelOptions]="{standalone: true}"></td>
                        <td style="width: 110px;">
                            <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                                    [bsConfig]="bsConfig" readonly
                                    [(ngModel)]="item.IssueDate">                                
                        </td>                         
                        <td style="width: 140px;">
                            <select class="form-select shadow-none" #SugCode [(ngModel)]="item.SugCode">
                                <option value="" disabled>--請選擇--</option>
                                <option *ngFor=" let itemSugCode of sugCodeList" [value]="itemSugCode.ItemValue">{{itemSugCode.ItemValue}}{{itemSugCode.ItemName}}</option>
                            </select>
                        </td>
                        <td style="width: 150px;">
                            <input type="text"
                            class="form-control form-control-sm shadow-none text-center"
                            [(ngModel)]="item.Means" maxlength="300">                                   
                        </td>
                        <td style="width: 90px;">
                            <ng-container *ngIf="item.ApproveDate != ''">{{item.ApproveDate}}</ng-container>                             
                        </td>
                        <td style="width: 90px;">
                            <select class="form-select shadow-none" #Status [(ngModel)]="item.Status">
                                <option value="" disabled></option>
                                <option value="2">通過</option>
                                <option value="3">未通過</option>
                            </select>
                        </td>                            
                    </tr>
                    <tr>
                        <td colspan="11">
                            <a class="pointer text-decoration-none float-end"
                                (click)="deleteRow()">刪除一筆</a>
                            <a class="pointer text-decoration-none me-3 float-end"
                                (click)="addRow()">新增一筆</a>
                        </td>
                    </tr>
                </tbody>                    
            </table>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <button class="other-Button-gray float-end me-3" type="button" (click)="getSRPSave('2')">完成審核</button>                  
            </div>
        </div>
    </div>
</ng-template>