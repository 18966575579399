import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { getScoreCurList } from 'src/app/_Models/TCHWEB/TCH0114/getScoreCurList';
import { environment } from 'src/assets/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { getSetModeList } from 'src/app/_Models/TCHWEB/TCH0114/getSetModeList';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import Swal from 'sweetalert2';
import { getStdList } from 'src/app/_Models/TCHWEB/TCH0114/getStdList';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { GetCosInfo } from 'src/app/_Models/TCHWEB/TCH0114/getCosInfo';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';

@Component({
    selector: 'app-GrdScoreInput',
    templateUrl: './GrdScoreInput.component.html',
    styleUrls: ['./GrdScoreInput.component.css']
})
export class GrdScoreInputComponent implements OnInit {
    @Input() DataKey!: string;
    @Input() IsTch!: boolean;
    baseUrl = environment.apiUrl;
    fileExchange = new FileExchange();
    checkType = new CheckType();
    modalRef!: BsModalRef;
    totalData!: Array<getScoreCurList>;
    modeData!: getSetModeList;
    ScoreData!: Array<getStdList>;
    disabled: boolean = false;
    cosInfo!: GetCosInfo;
    thiscosInfo!: GetCosInfo[];
    ControlUrl!:string;

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService) { }

    ngOnInit(): void {
        this.ControlUrl=(this.IsTch)?"ACAD/TCHWEB/TCH01_14/":"ACAD/GRD/ADGRD0302/";

        if(this.DataKey!=''){
            this.GetCosInfo(this.DataKey, '');
            this.GetScoreData(this.DataKey,'');
        }
    }

    caculateScore(id: string) {
        let MTerm_Score = parseFloat((<HTMLInputElement>document.getElementById(`${id + 'MT'}`))?.value);
        let Quiz_Score = parseFloat((<HTMLInputElement>document.getElementById(`${id + 'QU'}`))?.value);
        let FTerm_Score = parseFloat((<HTMLInputElement>document.getElementById(`${id + 'FT'}`))?.value);
        let FTerm_Ratio = (parseFloat((<HTMLInputElement>document.getElementById(`${id + 'FTR'}`))?.value)) / 100;
        let MTerm_Ratio = (parseFloat((<HTMLInputElement>document.getElementById(`${id + 'MTR'}`))?.value)) / 100;
        let Quiz_Ratio = (parseFloat((<HTMLInputElement>document.getElementById(`${id + 'QUR'}`))?.value)) / 100;
        let Final_Score = Math.round(((MTerm_Score * MTerm_Ratio) + (Quiz_Score * Quiz_Ratio) + (FTerm_Score * FTerm_Ratio))).toString();
        let Final_Score_Input = (<HTMLInputElement>document.getElementById(`${id + 'FI'}`));
        Final_Score_Input.value = Final_Score;
    }

    GetScoreData(dataKey: string, ipAddress: string) {
        this.http.post<any>(this.baseUrl + this.ControlUrl + 'Get_Std_List', { DataKey: dataKey, IP: ipAddress }).subscribe((response) => {
            if (response) {
                this.ScoreData = response;
            }
        });
    }

    GetCosInfo(dataKey: string, ipAddress: string) {
        this.http.post<any>(this.baseUrl + this.ControlUrl + 'Get_CosInfo', { DataKey: dataKey, IP: ipAddress }).subscribe((response) => {

            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };

            if (response.Info == false) {
                this.toastr.warning(response.Message, "課程資訊");
                return;
            }
            this.cosInfo = response[0];
            this.thiscosInfo = response;
            this.thiscosInfo[0].Grade_Mode_Id=(this.thiscosInfo[0].Grade_Mode.indexOf('總分')>=0)?'2':'1';
        });
    }

    CheckData(type: string) {
        let pass = true;

        for (let index = 0; index < this.ScoreData.length; index++) {

            if (this.cosInfo.Grade_Mode_Id == '2') {
                let finalScore = (<HTMLInputElement>document.getElementById(`${this.ScoreData[index].Std_No + 'FI2'}`))?.value;
                this.ScoreData[index].Final_Score = finalScore;
            }
            else {
                let finalScore = (<HTMLInputElement>document.getElementById(`${this.ScoreData[index].Std_No + 'FI'}`))?.value;
                this.ScoreData[index].Final_Score = finalScore;
            }

            this.ScoreData[index].MTerm_Score=(this.ScoreData[index].MTerm_Score==null)?'':this.ScoreData[index].MTerm_Score;
            this.ScoreData[index].Quiz_Score=(this.ScoreData[index].Quiz_Score==null)?'':this.ScoreData[index].Quiz_Score;
            this.ScoreData[index].FTerm_Score=(this.ScoreData[index].FTerm_Score==null)?'':this.ScoreData[index].FTerm_Score;
            this.ScoreData[index].Recise_Score='';
            this.ScoreData[index].Recise_Personal_Score='';
            this.ScoreData[index].Send = (type == "save_ScoreData")?'1':'0';
            this.ScoreData[index].IP = '';

            if (this.ScoreData[index].Rest_Mark_boolean == false) {
                this.ScoreData[index].Rest_Mark = "";
            }
            else {
                this.ScoreData[index].Rest_Mark = "Y";
            }

            if(this.cosInfo.Grade_Mode_Id == '2'){
                if(this.ScoreData[index].Final_Score!=''){
                    if(this.checkType.CheckIsNumber(this.ScoreData[index].Final_Score)){
                        document.getElementById(this.ScoreData[index].Std_No)?.setAttribute('class', 'error');
                        this.toastr.error("請輸入數字!");
                        pass = false;
                        continue;
                    }
                    else{
                        document.getElementById(this.ScoreData[index].Std_No)?.removeAttribute('class');
                    }
                }
                else{
                    if (type == "save_ScoreData"){
                        if(this.ScoreData[index].Rest_Mark != "Y"){
                            document.getElementById(this.ScoreData[index].Std_No+'MT')?.setAttribute('class', 'error');
                            this.toastr.error("請輸入學期成績!");
                            pass = false;
                            continue;
                        }
                    }
                }
            }
            else{
                if(this.ScoreData[index].MTerm_Score!=''){
                    if(this.checkType.CheckIsNumber(this.ScoreData[index].MTerm_Score)){
                        document.getElementById(this.ScoreData[index].Std_No+'MT')?.setAttribute('class', 'error');
                        this.toastr.error("請輸入數字(期中成績)!");
                        pass = false;
                        continue;
                    }
                    else{
                        document.getElementById(this.ScoreData[index].Std_No+'MT')?.removeAttribute('class');
                    }
                }
                else{
                    if (type == "save_ScoreData"){
                        if(this.ScoreData[index].Rest_Mark != "Y"){
                            if(parseInt(this.ScoreData[index].MTerm_Ratio)>0){
                                document.getElementById(this.ScoreData[index].Std_No+'MT')?.setAttribute('class', 'error');
                                this.toastr.error("請輸入期中成績!");
                                pass = false;
                                continue;
                            }
                        }
                    }
                }

                if(this.ScoreData[index].Quiz_Score!=''){
                    if(this.checkType.CheckIsNumber(this.ScoreData[index].Quiz_Score)){
                        document.getElementById(this.ScoreData[index].Std_No+'QU')?.setAttribute('class', 'error');
                        this.toastr.error("請輸入數字(平時成績)!");
                        pass = false;
                        continue;
                    }
                    else{
                        document.getElementById(this.ScoreData[index].Std_No+'QU')?.removeAttribute('class');
                    }
                }
                else{
                    if (type == "save_ScoreData"){
                        if(this.ScoreData[index].Rest_Mark != "Y"){
                            if(parseInt(this.ScoreData[index].Quiz_Ratio)>0){
                                document.getElementById(this.ScoreData[index].Std_No+'QU')?.setAttribute('class', 'error');
                                this.toastr.error("請輸入平時成績!");
                                pass = false;
                                continue;
                            }
                        }
                    }
                }

                if(this.ScoreData[index].FTerm_Score!=''){
                    if(this.checkType.CheckIsNumber(this.ScoreData[index].FTerm_Score)){
                        document.getElementById(this.ScoreData[index].Std_No+'FT')?.setAttribute('class', 'error');
                        this.toastr.error("請輸入數字(期末成績)!");
                        pass = false;
                        continue;
                    }
                    else{
                        document.getElementById(this.ScoreData[index].Std_No+'FT')?.removeAttribute('class');
                    }
                }
                else{
                    if (type == "save_ScoreData"){
                        if(this.ScoreData[index].Rest_Mark != "Y"){
                            if(parseInt(this.ScoreData[index].FTerm_Ratio)>0){
                                document.getElementById(this.ScoreData[index].Std_No+'FT')?.setAttribute('class', 'error');
                                this.toastr.error("請輸入期中成績!");
                                pass = false;
                                continue;
                            }
                        }
                    }
                }
            }
        }

        if (pass == true) {
            this.SaveScoreData();
        }
    }

    SaveScoreData() {
        this.http.post<any>(this.baseUrl + this.ControlUrl + 'Save_ScoreData', this.ScoreData).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message, "資料儲存失敗!");
                return;
            }
            else {
                this.toastr.success("資料儲存成功!");
            }

            this.GetScoreData(this.DataKey,'');
        },
            error => {
                this.toastr.error(error, "系統錯誤!");
            });
    }
}
