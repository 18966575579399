<div class="container" style="margin-bottom: 100px;">
    <div class="my-3 p-3 bg-white rounded shadow">
        <h6 class="border-bottom border-primary pb-2 mb-0 fw-bolder" style="color: #2655a5">
            <span class="icon-Profile-2 icon-text"></span> 個人檔案
        </h6>
        <accordion [closeOthers]="true" [isAnimated]="true">
            <accordion-group [hidden]="type != '0'">
                <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none fw-bolder text-decoration-none" accordion-heading type="button">
                    <div class="pull-left float-left">&#8226;&emsp;學生證</div>
                </button>
                <img [src]="ImgUrl" alt="無法顯示學生證" id="std-img" width="100%">
            </accordion-group>
            <accordion-group>
                <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none fw-bolder text-decoration-none" accordion-heading type="button">
                    <div class="pull-left float-left">&#8226;&emsp;緊急連絡電話</div>
                </button>
                <h3>校內緊急聯絡：</h3>
                <p *ngFor="let item of emergencyTel">{{item.UnitName}} - <a href="tel:{{item.EmergencyTel}}">{{item.EmergencyTel}}</a></p>
            </accordion-group>
        </accordion>
        <ul class="list-group list-group-flush" *ngIf="fileList">
            <li *ngFor="let item of fileList" class="list-group-item pointer" (click)="getFileDownload(item.URL, item.FileName)">
                <a class="text-decoration-none pointer fw-bolder">&#8226;&emsp;{{item.FileName}}</a>
            </li>
        </ul>
    </div>
    <div class="my-3 p-3 bg-white rounded shadow">
        <h6 class="border-bottom border-primary pb-2 mb-0 fw-bolder" style="color: #2655a5">
            <span class="icon-Auth icon-text"></span> 帳號綁定
        </h6>
        <ul class="list-group list-group-flush" *ngIf="fileList">
            <li *ngFor="let item of ssoList" class="list-group-item">
                <a *ngIf="item.Used == false" class="text-decoration-none pointer fw-bolder" (click)="setAuth(item.LoginType, regist)">&#8226;&emsp;{{item.LoginTypeName}}</a>
                <a *ngIf="item.Used == true" class="text-decoration-none pointer fw-bolder" (click)="getLoginTypeRemove(item.LoginType)">&#8226;&emsp;{{item.LoginTypeName}}</a>
            </li>
        </ul>
    </div>
</div>


<ng-template #regist>
    <div class="modal-header">
        <h3>{{schoolName|| ''}}</h3>
    </div>
    <div class="modal-body">
        <div class="container pb-3 pt-3">
            <h6 class="text-center mb-3" style="color: #868f95;"><img height="30px" width="30px" src="../../../assets/icon/triangle-icon.svg" alt="警示">尚未綁定{{loginType == 'google'?'GoogleSSO':'指紋辨識'}}</h6>
            <h6 class="text-decoration-underline text-center" style="color: #727272;">請輸入密碼驗證</h6>
            <div class="mt-3 text-center" style="width: 70%; margin: auto;">
                <input type="password" class="form-control shadow-none" #pwd placeholder="請輸入密碼" aria-label="請輸入密碼" aria-describedby="button-addon">
                <button class="btn btn-secondary mt-3 " type="button" id="button-addon" (click)="checkUserAccount(pwd.value)">確定</button>
                <button class="btn btn-secondary mt-3 ms-3" type="button" (click)="modalRef.hide()">取消</button>
            </div>
        </div>
    </div>
</ng-template>
