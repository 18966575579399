import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0106/search';
import { GetSELReportStdList } from 'src/app/_Models/AD/ADSEL/ADSEL0501/getSELReportStdList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0501',
    templateUrl: './ADSEL0501.component.html',
    styleUrls: ['./ADSEL0501.component.css']
})
export class ADSEL0501Component implements OnInit {
    baseUrl = environment.apiUrl;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    data!: Search[];
    sELReportStdList!: GetSELReportStdList[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', Validators.required),
        Term: new UntypedFormControl('', Validators.required),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        ClassList: new UntypedFormControl(''),
        Grade: new UntypedFormControl(''),
        StdNoFrom: new UntypedFormControl(''),
        StdNoTo: new UntypedFormControl('')
    });

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.getYears();
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getSELReportStdList() {

        let datakey = '';

        this.data.forEach(element => {
            if (element.Selected) {
                datakey += element.Class + ','
            }
        });

        this.ngFormInput.get("ClassList")?.setValue(datakey.substring(0, datakey.length - 1));

        if (datakey == '') {
            this.toastr.info("請選擇班級");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0501/Get_SEL_Report_StdList", this.ngFormInput.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELReportStdList = response;

            this.sELReportStdList.forEach(element => {
                element.checked = false;
            });
        });
    }

    exportReportADSEL0501(type: string) {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇學年學期");
            return;
        }

        let request = {
            Years: this.ngFormInput.get("Years")?.value,
            Term: this.ngFormInput.get("Term")?.value,
            ClassList: '',
            StdNoList: ''
        };

        if (type == "class") {
            request.StdNoList = '';
            let datakey = '';
            this.data.forEach(element => {
                if (element.Selected) {
                    datakey += element.Class + ','
                }
            });
            request.ClassList = datakey.substring(0, datakey.length - 1);
        }
        else {
            request.ClassList = '';
            let datakey = '';
            this.sELReportStdList.forEach(element => {
                if (element.checked) {
                    datakey += element.DataKey + ','
                }
            });
            request.StdNoList = datakey.substring(0, datakey.length - 1);
        }

        if (request.ClassList == '' && type == "class") {
            this.toastr.info("請選擇班級");
            return;
        }

        if (request.StdNoList == '' && type == "std") {
            this.toastr.info("請選擇學生");
            return;
        }

        this.fileExchangeServices.ExportFile("ACAD/SEL/ADSEL0501/Export_Report_ADSEL0501", request).subscribe((response) => {
            if(response.Info==false){
                this.toastr.info(response.Message);
                return;
            }

            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    getEduList() {

        if (this.ngFormInput.get("Years")?.value == '' || this.ngFormInput.get("Term")?.value == '') {
            return;
        }

        this.systemService.getEduList('0', this.ngFormInput.get("Years")?.value, this.ngFormInput.get("Term")?.value, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList() {
        this.systemService.getDeptList(this.ngFormInput.get("EduNo")?.value, '0', this.ngFormInput.get("Years")?.value, this.ngFormInput.get("Term")?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList() {

        this.http.post<any>(this.baseUrl + "Public/Get_Class_List", { Type:"0",Years:this.ngFormInput.get("Years")?.value,Term:this.ngFormInput.get("Term")?.value,Edu_No: this.ngFormInput.get("EduNo")?.value, Dept_No: this.ngFormInput.get("DeptNo")?.value,Short:"N" }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        })
    }

    setAll(eventTarget: EventTarget | null, type: string) {
        let input = <HTMLInputElement>eventTarget;

        switch (type) {
            case 'class':
                if (input.checked) {
                    this.data.forEach(element => {
                        element.Selected = true;
                    });
                }
                else {
                    this.data.forEach(element => {
                        element.Selected = false;
                    });
                }
                break;
            case 'std':
                if (input.checked) {
                    this.sELReportStdList.forEach(element => {
                        element.checked = true;
                    });
                }
                else {
                    this.sELReportStdList.forEach(element => {
                        element.checked = false;
                    });
                }
                break;
        }


    }

}
