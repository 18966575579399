<div class="container mb-5">
    <h2 class="text-info fw-bolder">{{'自主修課計畫摘要' | translate}}​</h2>
    <div class="row mt-3 border border-secondary">
        <div class="col-md-2">
            <h4>{{'本​系​​課​​程' | translate}}​</h4>
        </div>
        <div class="col-md-10">
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0"
                    aria-valuemax="100">{{'專業必修學分(28/40)-70%​' | translate}}​</div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 68%" aria-valuenow="68" aria-valuemin="0"
                    aria-valuemax="100">{{'專業選修學分(34/50)-68%​' | translate}}</div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0"
                    aria-valuemax="100">{{'通識必修學分(08/12)-75%' | translate}}</div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                    aria-valuemax="100">{{'通識選修學分(10/20)-50%' | translate}}</div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 64%" aria-valuenow="64" aria-valuemin="0"
                    aria-valuemax="100">{{'本系課程學分(85/132)-64%' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="row border border-secondary">
        <div class="col-md-2">
            <h4>{{'自主修課計畫摘要' | translate}}</h4>
        </div>
        <div class="col-md-10">
            <div class="row border border-secondary">
                <div class="col-md-4">
                    <h6>{{'旅館系：休閒旅館專業人才' | translate}}</h6>
                </div>
                <div class="col-md-8">
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 30%" aria-valuenow="30"
                            aria-valuemin="0" aria-valuemax="100">{{'專業必修學分(06/10)-30%' | translate}}​​</div>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 33%" aria-valuenow="33"
                            aria-valuemin="0" aria-valuemax="100">{{'專業選修學分(04/12)-33%' | translate}}​​</div>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 45%" aria-valuenow="45"
                            aria-valuemin="0" aria-valuemax="100">{{'學分完成度(10/22)-45%​' | translate}}​</div>
                    </div>
                </div>
            </div>
            <div class="row border border-secondary">
                <div class="col-md-4">
                    <h6>{{'旅運系：旅遊經紀管理人才​' | translate }}</h6>
                </div>
                <div class="col-md-8">
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 30%" aria-valuenow="30"
                            aria-valuemin="0" aria-valuemax="100">{{'專業必修學分(06/10)-30%​​' | translate}}</div>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 33%" aria-valuenow="33"
                            aria-valuemin="0" aria-valuemax="100">{{'專業選修學分(04/12)-33%' | translate}}​​</div>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 45%" aria-valuenow="45"
                            aria-valuemin="0" aria-valuemax="100">{{'學分完成度(10/22)-45%​' | translate}}​​</div>
                    </div>
                </div>
            </div>
            <div class="row border border-secondary">
                <div class="col-md-4">
                    <h6>{{'會展行銷系：旅遊經紀管理人才' | translate}}</h6>​
                </div>
                <div class="col-md-8">
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 30%" aria-valuenow="30"
                            aria-valuemin="0" aria-valuemax="100">{{'專業必修學分(06/10)-30%​​' | translate}}</div>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 33%" aria-valuenow="33"
                            aria-valuemin="0" aria-valuemax="100">{{'專業選修學分(04/12)-33%' | translate}}​​</div>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 45%" aria-valuenow="45"
                            aria-valuemin="0" aria-valuemax="100">{{'學分完成度(10/22)-45%​' | translate}}​​</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row border border-secondary">
        <div class="col-md-2">
            <h4>{{'興趣選修' | translate}}</h4>
        </div>
        <div class="col-md-10">
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 30%" aria-valuenow="30" aria-valuemin="0"
                    aria-valuemax="100">{{'必修學分(06/10)-30%' | translate}}​</div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 33%" aria-valuenow="33" aria-valuemin="0"
                    aria-valuemax="100">{{'選修學分(04/12)-33%​' | translate}}</div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 45%" aria-valuenow="45" aria-valuemin="0"
                    aria-valuemax="100">{{'學分完成度(10/22)-45%​' | translate}}​</div>
            </div>
        </div>
    </div>
</div>