<div class="container">
    <form [formGroup]="searchForm" (ngSubmit)="getLeaveList()">
        <div class="row">
            <div class="col-md-6">
                <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'假別' | translate}}</span>
                    <select class="form-select shadow-none" formControlName="H_Code">
                        <option value="" selected>{{'--所有假別--' | translate}}</option>
                        <option *ngFor="let item of holidayList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'日期' | translate}}</span>
                    <input type="text" placeholder="{{'點擊選擇日期'|translate}}" class="form-control pointer shadow-none" bsDaterangepicker [bsConfig]="bsConfig" #dpr="bsDaterangepicker" formControlName="Date" readonly>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <button class="search-btn ms-3 float-start">{{'查詢' | translate}}</button>
                <button class="add-btn ms-3 float-start" type="button" (click)="newData(leaveContent)">{{'新增' |
                    translate}}</button>
                <button class="other-Button-gray ms-3 float-start" type="button" (click)="deleteData()">{{'刪除' |
                    translate}}</button>
            </div>
        </div>
    </form>
    <ng-container *ngIf="leaveListData">
        <table class="table table-hover text-center table-rwd">
            <thead class="table-rwd-hide thead-dark-red">
                <tr>
                    <th><span>{{'全選' | translate}}&ensp;</span><input type="checkbox" id="selectAll" (click)="selectAll()"></th>
                    <th>{{'學年' | translate}}</th>
                    <th>{{'學期' | translate}}</th>
                    <th>{{'假別' | translate}}</th>
                    <th>{{'部別' | translate}}</th>
                    <th>{{'請假日期' | translate}}</th>
                    <th>{{'節次' | translate}}</th>
                    <th>{{'天數' | translate}}</th>
                    <th>{{'編輯' | translate}}</th>
                    <th>{{'審核狀態' | translate}}</th>
                    <th [hidden]="true"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of leaveList">
                    <td data-th="選擇"><input type="checkbox" *ngIf="item.Del == 'Y'" name="deleteCheck" (click)="countDelete(item.DataKey)">
                        <label *ngIf="item.Del == 'N'" class="text-danger fw-bolder">{{'結案' | translate}}</label>
                    </td>
                    <td data-th="學年">{{item.Years}}</td>
                    <td data-th="學期">{{item.Term}}</td>
                    <td data-th="假別">{{item.H_Name}}</td>
                    <td data-th="部別">{{item.Class_Name}}</td>
                    <td data-th="請假日期">{{item.RecDate}}</td>
                    <td data-th="節次">{{item.Cur_TimeList}}</td>
                    <td data-th="天數">{{item.Days}}</td>
                    <td data-th="編輯" class="fw-bolder text-primary" (click)="getLeaveContent(item.DataKey, leaveContent, item.Chk_Status)">
                        {{'詳細資訊' | translate}}
                    </td>
                    <td data-th="審核狀態" class="fw-bolder text-primary" (click)="getLeaveFlowList(item.DataKey, leaveFlowList)">{{item.Status_Name}}</td>
                    <td data-th="" [hidden]="true">{{item.DataKey}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>


<ng-template #leaveContent>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'請假資訊' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close" (click)="leaveContentModalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="leaveContentData">
            {{'學年學期：' | translate}}
            {{leaveContentData.Years}}{{'學年第' | translate}}
            {{leaveContentData.Term}}{{'學期' | translate}}
        </p>
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">{{'學號' | translate}}</span>
            <input type="text" [(ngModel)]="leaveContentData.Std_No" class="form-control shadow-none" readonly disabled>
        </div>
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">{{'姓名' | translate}}</span>
            <input type="text" [(ngModel)]="leaveContentData.Std_Name" class="form-control shadow-none" readonly disabled>
        </div>
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">{{'日期' | translate}}</span>
            <input type="text" placeholder="點擊選擇日期" class="form-control shadow-none pointer" bsDaterangepicker [bsConfig]="bsConfig" #dpr="bsDaterangepicker" [(ngModel)]="daterangepickerModel" [disabled]="Chk_Status =='1' || Chk_Status =='3'" readonly>
        </div>
        <small class="text-danger fw-bolder">{{'※請依照選修課程所屬學制、節次請假。' | translate}}</small>
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">{{'假別' | translate}}</span>
            <select class="form-select shadow-none" [(ngModel)]="leaveContentData.H_Code" [disabled]="Chk_Status =='1' || Chk_Status =='3'">
                <option value="" selected disabled>{{'--請選擇假別--' | translate}}</option>
                <option *ngFor="let item of holidayList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
            </select>
        </div>
        <div class="row mb-3">
            <div class="col-lg-1">
                    <span class="input-group-text">{{'節次' | translate}}</span>
            </div>
            <div class="col-lg-11">
                <ng-container *ngFor="let item of initiateCurTimeData;">
                    <input type="checkbox" class="form-check-input me-1 pointer shadow-none" value="{{item.number}}" [checked]="item.checked" [disabled]="Chk_Status =='1' || Chk_Status =='3'" (click)="setCurTime($event)">
                    <span>
                        {{'第' | translate}}
                        {{item.number}}
                        {{'節' | translate}}
                        &ensp;
                    </span>
                </ng-container>
            </div>
        </div>
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">{{'事由' | translate}}</span>
            <input type="text" class="form-control shadow-none" [(ngModel)]="leaveContentData.Memo" placeholder="請輸入事由" [disabled]="Chk_Status =='1' || Chk_Status =='3'" />
        </div>
        <label class="text-danger fw-bolder mb-3">
            {{'※上傳副檔名需為 *.jpg,*.gif,*.jpeg,*.png,*.bmp,*,doc,*.pdf。' | translate}}
        </label>
        <br>
        <label class="mb-3 me-3">{{'證明文件' | translate}}<span class="icon-Camera"></span></label>
        <input type="file" class="mb-3" accept=".jpg,.gif,.jpeg,.png,.bmp,.doc,.pdf" id="{{leaveContentData.DataKey}}" (change)="importData($event, leaveContentData.DataKey, '')" [disabled]="Chk_Status =='1' || Chk_Status =='3'">
        <input type="hidden" [(ngModel)]="leaveContentData.DataKey">
        <div>
            <h4><strong>{{'學生線上請假注意事項：請假規則' | translate}}</strong></h4>
            <ol>

                <li>{{'1-16週期間學生事後補請假，須於請假日之次日起5日內(不含例假日)依准假權責規定完成，不得藉故逾期補假。' | translate}}</li>
                <li>{{'17-18週學生事後補請假，須於請假日之次2日內(不含例假日)依准假權責規定完成。' | translate}}</li>
                <li>{{'准假權責：' | translate}}<br>
                    {{'1日以內者，由導師或系科主任核准。超過1日、2日以內者，循程序由系科主任、生活輔導組組長核准。' | translate}}
                    {{'超過2日、5日以內者，循程序由學務長核准。超過5日者，循程序由校長核准，並將證明文件，繳存於生活輔導組。' | translate}}
                </li>
                <li>{{'缺曠資料仍以網路公布為準，請同學隨時上網查詢，若有疑問，請至生輔組查對更正。' | translate}}</li>
                <li>{{'依本校學生獎懲辦法第10條第6項曠課達23節者應予退學。' | translate}}</li>
                <li>{{'請公假、喪假一日以上及請事、病假二日以上者，須附證明文件。' | translate}}</li>
                <li>{{'請假須附證明文件者，須以附加檔案方式加入，系統始得線上傳輸，如無則請同學至學務處/生輔組/相關表單下載紙本請假申請單，自行循程序完成請假手續。' | translate}}</li>
                <li>{{'考試期間如因故未能到校應考者，請至教務處辦理請假及補考事宜，切勿使用線上請假。' | translate}}</li>
                <li>{{'缺課總節數達該科全學期授課總節數三分之一時，不得參加該科目期末考試，且該科目學期成績以零分計算。' | translate}}</li>
            </ol>
        </div>
        <div>
            <ng-container *ngIf="Chk_Status == '0' || Chk_Status == ''">
                <button class="other-Button-gray float-end" (click)="saveContent('save')">{{'送出' | translate}}</button>
                <button class="save-btn-gray float-end me-3" (click)="saveContent('temp')">{{'儲存' | translate}}</button>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #leaveFlowList>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'學生請假簽核流程' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close" (click)="leaveFlowListModalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <table class="table table-hover table-striped table-rwd text-center">
            <thead class="thead-dark-red table-rwd-hide">
                <tr>
                    <th>{{'關卡' | translate}}</th>
                    <th>{{'簽核人員' | translate}}</th>
                    <th>{{'簽核狀態' | translate}}</th>
                    <th>{{'簽核意見' | translate}}</th>
                    <th>{{'簽核時間' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of leaveFlowData">
                    <td data-th="關卡">{{item.Stage}}</td>
                    <td data-th="簽核人員">{{item.UserName}}</td>
                    <td data-th="簽核狀態">{{item.FlowStatus}}</td>
                    <td data-th="簽核意見">{{item.ChkMemo}}</td>
                    <td data-th="簽核時間">{{item.SendDate}}</td>
                    <td class="td-rwd-hide" data-th=""></td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>
