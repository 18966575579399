<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getCURTchMoneyModifyList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #dYear (change)="getUnitList(dYear.value, dTerm.value)"
                        formControlName="Years">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #dTerm (change)="getUnitList(dYear.value, dTerm.value)"
                        formControlName="Term">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">專兼職別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="TimeCase">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of timeCaseList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">所屬單位</span>
                    <select class="form-select shadow-none" formControlName="UnitCode">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of unitData">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">職稱</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Title">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of titleList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">人事代碼</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="TchNo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">姓名</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="TchName">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">資料排序</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="SortIndex">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="A">教師代碼</option>
                        <option value="B">單位、教師代碼</option>
                        <option value="C">單位、職稱</option>
                        <option value="D">職稱、教師代碼</option>
                        <option value="E">職稱、單位</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">鐘點範圍</span>
                    </div>
                    <input type="number" class="form-control shadow-none" min="-99" max="99" step="0.01"
                        formControlName="MinHours">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="number" class="form-control shadow-none" min="-99" max="99" step="0.01"
                        formControlName="MaxHours">
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <button class="search-btn float-start me-3">查詢</button>
                <button type="button" class="other-Button-gray me-3" (click)="getCURTchMoneyModifySave()">儲存</button>
                <button type="button" class="other-Button-gray" (click)="exportReport()">匯出檔案</button>
            </div>
        </div>
    </form>
    <div class="row">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th>代碼</th>
                    <th>姓名</th>
                    <th>職稱</th>
                    <th>單位</th>
                    <th>原超(欠)鐘點</th>
                    <th>調整後(欠)鐘點</th>
                    <th>備註</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of cURTchMoneyModifyList">
                    <td>{{item.TchNo}}</td>
                    <td>{{item.TchName}}</td>
                    <td>{{item.Title}}</td>
                    <td>{{item.UnitCode}}</td>
                    <td>{{item.TchHours}}</td>
                    <td><input type="number" class="form-control form-control-sm shadow-none"
                            [(ngModel)]="item.TchAdjHours"></td>
                    <td><input type="text" class="form-control form-control-sm shadow-none" [(ngModel)]="item.Memo">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
