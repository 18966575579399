import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { environment } from 'src/assets/environments/environment';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { GetStdRestListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0301/getStdRestListInput';
import { Input } from 'src/app/_Models/AD/ADSTD/ADSTD0811ISU/input';
import { GetData } from 'src/app/_Models/AD/ADSTD/ADSTD0811ISU/getdata';
import { GetDetailData } from 'src/app/_Models/AD/ADSTD/ADSTD0811ISU/getdetaildata';
import { GetApplyData } from 'src/app/_Models/STDWEB/STDW10013ISU/getApplyData';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-ADSTD0811ISU',
    templateUrl: './ADSTD0811ISU.component.html',
    styleUrls: ['./ADSTD0811ISU.component.css']
})
export class ADSTD0811ISUComponent implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    getdata!: GetData[];
    getdetaildata!: GetDetailData[];
    modalRef!: BsModalRef;
    check!: boolean;
    checkall!: boolean;
    input: Input = {
        EduNo: '',
        DeptNo: '',
        Class: '',
        StdNo: '',
        ApplyNo: ''
    }
    initialData = new InitialData(this.http);
    getApplyData!: GetApplyData[];
    baseUrl = environment.apiUrl;
    getStdRestListInput: GetStdRestListInput = {
        RestYear: '',
        RestTerm: '',
        Approve: '',
        EduNo: '',
        DeptNo: '',
        Class: '',
        Status: '',
        IDNo: '',
        StdNo: '',
        StdName: ''
    };

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService, private fileExchangeServices: FileExchangeService, private modalService: BsModalService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
        this.applyselect();
    }

    getYears() {
        this.initialData.getYears().subscribe((response: string[]) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response: any) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, (error: { error: any; }) => this.toastr.error(error.error))
    }

    applyselect() {
        this.http.post<any>(this.baseUrl + "Public/ApplyCertificate_List", { ApplyType: "B" }).subscribe((response: any) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.getApplyData = response;
        })
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '3', this.getStdRestListInput.RestYear, this.getStdRestListInput.RestTerm, 'Y', '').subscribe((response: any) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, (error: { error: any; }) => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', this.getStdRestListInput.RestYear, this.getStdRestListInput.RestTerm, 'Y', '').subscribe((response: any) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, (error: { error: any; }) => this.toastr.error(error.error));
    }

    search() {
        if(this.input.ApplyNo=="")
        {
            this.toastr.warning("請選擇申請項目")
            return;
        }
        this.getdata = [];
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD08_11ISU/Search", this.input).subscribe((response: any) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.getdata = response;
        })
    }

    delete() {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD08_11ISU/Delete", this.getdata).subscribe((response: { Info: boolean; Message: any; }) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.toastr.success("刪除成功")
            this.check = false;
            this.search();
        })
    }

    allchange() {
        for (let y of this.getdata) {
            if (this.checkall == true) {
                y.Checked = true;
                this.check = true;
            }
            else {
                y.Checked = false;
                this.check = false;
            }
        }
    }

    checkchange() {
        for (let y of this.getdata) {
            if (y.Checked == true) {
                this.check = true;
                return;
            }
            this.check = false;
        }
    }

    update() {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD08_11ISU/Update", this.getdata).subscribe((response: { Info: boolean; Message: any; }) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.toastr.success("修改成功")
            this.check = false;
            this.search();
        })
    }

    exportAll(){
        this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD08_11ISU/Export", this.getdata).subscribe((response: {Info:boolean ;Message:string;FileName: string; blob: Blob | MediaSource; }) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }, (error: { error: any; }) => this.toastr.error(error.error))
    }

    export(i: number) {
        this.getdetaildata.forEach(element => {
            element.Checked=false;
        });

        this.getdetaildata[i].Checked=true;

        this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD08_11ISU/Export", this.getdetaildata).subscribe((response: { FileName: string; blob: Blob | MediaSource; }) => {
            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }, (error: { error: any; }) => this.toastr.error(error.error))
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: "modal-lg"
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    searchdetail(ApplyNo: string, StdNo: string) {
        this.getdetaildata = [];
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD08_11ISU/SearchDetail", { ApplyNo: ApplyNo, StdNo: StdNo }).subscribe((response: any) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.getdetaildata = response;
        })
    }

    insert(i: number) {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD08_11ISU/Insert", this.getdetaildata[i]).subscribe((response: any) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            }
            this.toastr.success("新增成功")
            this.search()
        })
    }

}
