import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetFuncList } from 'src/app/_Models/AD/ADFunctionList/getFuncList';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADFunctionList',
  templateUrl: './ADFunctionList.component.html',
  styleUrls: ['./ADFunctionList.component.css']
})
export class ADFunctionListComponent implements OnInit {
  baseUrl = environment.apiUrl;
  funcList!: GetFuncList[] | undefined;

  constructor(private toastr: ToastrService, private http: HttpClient, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getFuncList();
  }



  getFuncList() {
    let id = this.activeRoute.snapshot.params.SysId;
    this.http.post<any>(this.baseUrl + "Portal/ADFunc", { SysID: id }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.funcList = response;
    })
  }

}
