import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetSELRecordOpClassSelList } from 'src/app/_Models/AD/ADSEL/ADSEL0304/getSELRecordOpClassSelList';
import { GetSELRecordStdSelList } from 'src/app/_Models/AD/ADSEL/ADSEL0304/getSELRecordStdSelList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0304',
    templateUrl: './ADSEL0304.component.html',
    styleUrls: ['./ADSEL0304.component.css']
})
export class ADSEL0304Component implements OnInit {
    baseUrl = environment.apiUrl;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    classList!: GetClassList[];
    deptList!: GetDeptList[];
    sELRecordList!: any;
    sELRecordOpClassSelList!: GetSELRecordOpClassSelList[];
    sELRecordStdSelList!: GetSELRecordStdSelList[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', Validators.required),
        Term: new UntypedFormControl('', Validators.required),
        Type: new UntypedFormControl('1', Validators.required),
        Phase: new UntypedFormControl('Y'),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl('', Validators.required),
        CosData: new UntypedFormControl(''),
        StdNo: new UntypedFormControl('')
    });

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService) { }

    ngOnInit(): void {
        this.getYears();
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getSELRecordList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0304/Get_SEL_Record_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sELRecordList = [];
                return;
            }
            this.sELRecordList = response;
        })
    }

    getEduList() {

        if (this.ngFormInput.get("Years")?.value == '' || this.ngFormInput.get("Term")?.value == '') {
            return;
        }

        this.systemService.getEduList('0', this.ngFormInput.get("Years")?.value, this.ngFormInput.get("Term")?.value, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList() {
        this.systemService.getDeptList(this.ngFormInput.get("EduNo")?.value, '0', this.ngFormInput.get("Years")?.value, this.ngFormInput.get("Term")?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList() {
        this.systemService.getClassList(this.ngFormInput.get("EduNo")?.value, this.ngFormInput.get("DeptNo")?.value, '0', this.ngFormInput.get("Years")?.value, this.ngFormInput.get("Term")?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getSELRecordOpClassSelList(datakey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0304/Get_SEL_Record_OpClass_SelList", {
            DataKey: datakey,
            Phase: this.ngFormInput.get("Phase")?.value
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sELRecordOpClassSelList = [];
                return;
            }
            this.sELRecordOpClassSelList = response;
        });
    }

    getSELRecordStdSelList(datakey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0304/Get_SEL_Record_Std_SelList", {
            DataKey: datakey,
            Phase: this.ngFormInput.get("Phase")?.value
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sELRecordStdSelList = [];
                return;
            }
            this.sELRecordStdSelList = response;
        });
    }

    reset() {
        this.sELRecordList = [];
        this.sELRecordOpClassSelList = [];
        this.sELRecordStdSelList = [];
    }

}
