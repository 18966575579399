<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getClassList()">
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" formControlName="Years" (change)="getEduList()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學期</span>
                    <select class="form-select shadow-none" formControlName="Term" (change)="getEduList()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" formControlName="EduNo" (change)="getDeptList()">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="" selected>--請選擇系所--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="col-md-12 mb-3">
                    <button class="search-btn float-start me-3">查詢</button>
                    <button type="button" class="other-Button-gray float-start"
                        (click)="exportReportADSEL0501('class')">列印</button>
                </div>
            </div>                       
        </div>
        <div class="row">    
            <div class="col-md-4">
                <!-- <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">年級</span>
                    <select class="form-select shadow-none" formControlName="Grade">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div> -->
            </div>
            <div class="col-md-5">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學號</span>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoFrom">
                        <span class="input-group-text">至</span>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoTo">
                </div>
            </div>
            <div class="col-md-3">
                <button type="button" class="other-Button-gray float-start me-3"
                    (click)="getSELReportStdList()">查學生</button>
                <button type="button" class="other-Button-gray float-start"
                    (click)="exportReportADSEL0501('std')">列印</button>
            </div>            
        </div>
        <div class="row">
            <div class="col-md-4">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th style="width:60px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                    (click)="setAll($event.target, 'class')">&ensp;全選</th>
                            <th style="width:200px;">班級名稱</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor=" let item of data;">
                            <td style="width:60px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                    [(ngModel)]="item.Selected" [ngModelOptions]="{standalone: true}"></td>
                            <td style="width:200px;text-align:left;">{{item.Class_Name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-8">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th><input type="checkbox" class="form-check-input shadow-none pointer"
                                    (click)="setAll($event.target, 'std')">&ensp;全選</th>
                            <th>班級</th>
                            <th>學號</th>
                            <th>姓名</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of sELReportStdList">
                            <td><input type="checkbox" class="form-check-input shadow-none pointer"
                                    [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}"></td>
                            <td>{{item.ClassName}}</td>
                            <td>{{item.StdNo}}</td>
                            <td>{{item.StdName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</div>