import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetSELStdDataDetail } from 'src/app/_Models/AD/ADSEL/ADSEL0202/getSELStdDataDetail';
import { GetSELStdDataList } from 'src/app/_Models/AD/ADSEL/ADSEL0202/getSELStdDataList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0202',
    templateUrl: './ADSEL0202.component.html',
    styleUrls: ['./ADSEL0202.component.css']
})
export class ADSEL0202Component implements OnInit {
    classArray: Array<string> = [];
    stdArray: Array<string> = [];
    classList!: GetClassList[];
    sELStdDataList!: GetSELStdDataList[];
    sELStdDataDetail!: GetSELStdDataDetail[];
    baseUrl = environment.apiUrl;
    modal = {
        Sel_Years: '',
        Sel_Term: ''
    };
    modalRef?: BsModalRef;
    Status = '1';
    rowIndex!: number;

    creditModel = {
        stdno: '',
        max: 0,
        min: 0
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private timeService: TimeService,
        private systemService: SystemService,
        private modalService: BsModalService) { }

    ngOnInit(): void {
        this.getTimeCntr();
    }

    getTimeCntr() {
        this.timeService.getTimeCntr().subscribe(response => {
            this.modal.Sel_Years = response.Sel_Years;
            this.modal.Sel_Term = response.Sel_Term
            this.getClassList();
        });
    }

    getClassList() {
        this.systemService.getClassList('', '', '0', this.modal.Sel_Years, this.modal.Sel_Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error));
    }

    setClassArray(type: string, dataKey: string, event: any) {
        if (!this.classList) {
            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.classArray.length != this.classList.length && input.checked == true) {
                this.classArray = [];
                this.classList.forEach(element => {
                    this.classArray.push(element.Class);
                    element.checked = true;
                })
            }
            else {
                this.classArray = [];
                this.classList.forEach(element => {
                    element.checked = false;
                });
            }
        }
        else {
            let index = this.classArray.indexOf(dataKey);
            if (index == -1) {
                this.classArray.push(dataKey);
            }
            else {
                this.classArray.splice(index, 1);
            }
        }
    }

    setStdArray(type: string, dataKey: string, event: any) {
        if (!this.sELStdDataList) {
            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.stdArray.length != this.sELStdDataList.length && input.checked == true) {
                this.stdArray = [];
                this.sELStdDataList.forEach(element => {
                    this.stdArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.stdArray = [];
                this.sELStdDataList.forEach(element => {
                    element.checked = false;
                });
            }
        }
        else {
            let index = this.stdArray.indexOf(dataKey);
            if (index == -1) {
                this.stdArray.push(dataKey);
            }
            else {
                this.stdArray.splice(index, 1);
            }
        }
    }

    getSELStdDataList() {
        if (this.classArray.length == 0) {
            this.toastr.info("請選擇班級");
            return;
        }
        let classList = '';
        this.classArray.forEach(element => {
            classList += element + ','
        });

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0202/Get_SEL_StdData_List", {
            Years: this.modal.Sel_Years,
            Term: this.modal.Sel_Term,
            Status: this.Status,
            ClassList: classList.substring(0, classList.length - 1),
            StdNo: ''
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELStdDataList = response;
        });
    }

    getSELStdDataDetail() {
        if (this.stdArray.length == 0) {
            this.toastr.info("請選擇學生");
            return;
        }
        let stdList = '';
        this.stdArray.forEach(element => {
            stdList += element + ','
        });

        console.log({
            Years: this.modal.Sel_Years,
            Term: this.modal.Sel_Term,
            Status: this.Status,
            ClassList: '',
            StdNo: stdList.substring(0, stdList.length - 1)
        })
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0202/Get_SEL_StdData_Detail", {
            Years: this.modal.Sel_Years,
            Term: this.modal.Sel_Term,
            Status: this.Status,
            ClassList: '',
            StdNo: stdList.substring(0, stdList.length - 1)
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELStdDataDetail = response;
        })
    }

    getSELStdDataSave(max: string, min: string) {

        this.sELStdDataDetail[this.rowIndex].CreditMax = parseInt(max);
        this.sELStdDataDetail[this.rowIndex].CreditMin = parseInt(min);

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0202/Get_SEL_StdData_Save", this.sELStdDataDetail[this.rowIndex])
            .subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                };
                this.toastr.success("儲存成功");
                this.modalRef?.hide();
            });
    }

    openModal(template: TemplateRef<any>, index: number) {
        this.rowIndex = index;
        this.creditModel.stdno = this.sELStdDataDetail[index].StdNo;
        this.creditModel.max = this.sELStdDataDetail[index].CreditMax;
        this.creditModel.min = this.sELStdDataDetail[index].CreditMin;
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-sm'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getSELStdDataExport() {
        let stdList = '';
        this.stdArray.forEach(element => {
            stdList += element + ','
        });
        let classList = '';
        this.classArray.forEach(element => {
            classList += element + ','
        });
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0202/Get_SEL_StdData_Export", {
            Years: this.modal.Sel_Years,
            Term: this.modal.Sel_Term,
            Status: this.Status,
            ClassList: classList == '' ? '' : classList.substring(0, stdList.length - 1),
            StdNo: stdList == '' ? '' : stdList.substring(0, stdList.length - 1)
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            };
            this.toastr.success("匯出成功");
        });
    }

}
