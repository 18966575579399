<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getSELRecordList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" formControlName="Years" (change)="getEduList()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學期</span>
                    <select class="form-select shadow-none" formControlName="Term" (change)="getEduList()">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" formControlName="EduNo" #eduNo (change)="getDeptList()">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" formControlName="DeptNo" (change)="getClassList()">
                        <option value="" selected disabled>--請選擇系所--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">班別</span>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇班別--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">選課階段</span>
                    <select class="form-select shadow-none" formControlName="Phase">
                        <option value="Y">第一階段</option>
                        <option value="N">第二階段</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">依據</span>
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" class="btn-check" #openClass value="1" name="Type" id="btnradio1"
                            formControlName="Type" autocomplete="off" checked (click)="reset()">
                        <label class="btn btn-outline-secondary shadow-none" for="btnradio1">班級開課</label>

                        <input type="radio" class="btn-check" name="Type" #std value="2" id="btnradio2"
                            formControlName="Type" autocomplete="off" (click)="reset()">
                        <label class="btn btn-outline-secondary shadow-none" for="btnradio2">學生選課</label>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3" *ngIf="openClass.checked">
                        <span class="input-group-text">課程名稱/課號</span>
                    <input type="text" class="form-control shadow-none" formControlName="CosData">
                </div>
                <div class="input-group flex-nowrap mb-3" *ngIf="std.checked">
                        <span class="input-group-text">學號</span>
                    <input type="text" class="form-control shadow-none" formControlName="StdNo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mb-3">
                <button class="search-btn float-end">查詢</button>
            </div>
        </div>
    </form>
    <div class="row" *ngIf="openClass.checked">
        <div class="col-md-4">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th>班級</th>
                        <th>課程名稱</th>
                        <th>序號</th>
                        <th>選課別</th>
                        <th>學分數</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" *ngFor="let item of sELRecordList"
                        (click)="getSELRecordOpClassSelList(item.DataKey)">
                        <td>{{item.ClassName}}</td>
                        <td>{{item.CosName}}</td>
                        <td>{{item.Serial}}</td>
                        <td>{{item.SelkindName}}</td>
                        <td>{{item.Credit}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th>學生班級</th>
                        <th>學生姓名</th>
                        <th>類別</th>
                        <th>時間</th>
                        <th>處理人員</th>
                        <th>IP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of sELRecordOpClassSelList">
                        <td>{{item.ClassName}}</td>
                        <td>{{item.StdName}}</td>
                        <td>{{item.InsertType}}</td>
                        <td>{{item.UpdateTime}}</td>
                        <td>{{item.UpdateUser}}</td>
                        <td>{{item.UpdateIP}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row" *ngIf="std.checked">
        <div class="col-md-3">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th>班級</th>
                        <th>學號</th>
                        <th>姓名</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" *ngFor="let item of sELRecordList"
                        (click)="getSELRecordStdSelList(item.DataKey)">
                        <td>{{item.ClassName}}</td>
                        <td>{{item.StdNo}}</td>
                        <td>{{item.StdName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-9">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th>開課班級</th>
                        <th>課程名稱</th>
                        <th>序號</th>
                        <th>選修別</th>
                        <th>學分</th>
                        <th>類別</th>
                        <th>時間</th>
                        <th>處理人員</th>
                        <th>IP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of sELRecordStdSelList">
                        <td>{{item.ClassName}}</td>
                        <td>{{item.CosName}}</td>
                        <td>{{item.Serial}}</td>
                        <td>{{item.SelkindName}}</td>
                        <td>{{item.Credit}}</td>
                        <td>{{item.InsertType}}</td>
                        <td>{{item.UpdateTime}}</td>
                        <td>{{item.UpdateUser}}</td>
                        <td>{{item.UpdateIP}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>