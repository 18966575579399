<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" #Term>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value)">
                    <option value="" selected disabled>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" #dept (change)="getClassList(edu.value, dept.value)">
                    <option value="" selected disabled>--請選擇系所--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班別</span>
                <select class="form-select shadow-none" #class>
                    <option value="" disabled selected>--請選擇班別--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-9">
            <button class="other-Button-gray float-end" (click)="openModal(template)">發送內容</button>
            <button class="search-btn float-end me-3"
                (click)="getCUPMailStdList(year.value, Term.value, edu.value, dept.value, class.value)">查詢</button>
        </div>
    </div>
    <table class="table table-striped table-hover text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" class="form-check-input pointer shadow-none"
                        (click)="setDatakey('all', '', $event.target)">&ensp;全選</th>
                <th>學號</th>
                <th>姓名</th>
                <th>班級</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cUPMailStdList">
                <td><input type="checkbox" class="form-check-input pointer shadow-none"
                        (click)="setDatakey('', item.DataKey, $event.target)" [checked]="item.checked"></td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.ClassName}}</td>
            </tr>
        </tbody>
    </table>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">編輯信件內容</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="input-group mb-3">
            <span class="input-group-text">主旨</span>
            <input type="text" class="form-control shadow-none" #Subject>
        </div>
        <div class="input-group mb-3">
            <span class="input-group-text">內容</span>
            <textarea class="form-control shadow-none" rows="10" #Content></textarea>
        </div>
        <div class="input-group">
            <input type="file" class="form-control shadow-none" multiple (change)="import($event, '', '')">
        </div>
        <button class="send-btn mx-auto mt-3" (click)="getCUPMailStdSendEMail(Subject.value, Content.value)">送出</button>
    </div>
</ng-template>