<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getABSLeaveList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Term">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getDeptList()" formControlName="EduNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getClassList();" formControlName="DeptNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">假別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="HCode">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor=" let item of holidayList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">指定</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="FieldType">
                        <option value="1">學號</option>
                        <option value="2">姓名</option>
                        <option value="3">身分證號</option>
                    </select>
                    <input type="text" class="form-control shadow-none" formControlName="FieldTypeValueFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="FieldTypeValueTo">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">請假日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" formControlName="RecDateFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" formControlName="RecDateTo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">審核狀態</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Status">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor=" let item of leaveListStatus" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <button class="search-btn float-start me-3">查詢</button>
                <button class="other-Button-gray float-start" type="button" (click)="getABSLeaveBatchSign()">審核通過</button>
            </div>
        </div>
    </form>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" class="form-check-input pointer shadow-none" (click)="setAll($event.target)">&ensp;全選</th>
                <th>學年</th>
                <th>學期</th>
                <th>部別</th>
                <th>假別</th>
                <th>科系</th>
                <th>學號</th>
                <th>姓名</th>
                <th>日期</th>
                <th>節次</th>
                <th>結果</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of aBSLeaveList">
                <td><input type="checkbox" class="form-check-input pointer shadow-none" [(ngModel)]="item.Checked"></td>
                <td>{{item.Years}}</td>
                <td>{{item.Term}}</td>
                <td>{{item.DNMark}}</td>
                <td>{{item.HCodeName}}</td>
                <td>{{item.DeptName}}</td>
                <td><a class="text-decoration-none pointer" (click)="openModel(template, item.DataKey)">{{item.StdNo}}</a></td>
                <td>{{item.StdName}}</td>
                <td>{{item.RecDate}}</td>
                <td>{{item.RecSection}}</td>
                <td>{{item.Status}}</td>
            </tr>
        </tbody>
    </table>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">學生請假維護</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="aBSLeaveContent">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year [(ngModel)]="aBSLeaveContent.Years" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term [(ngModel)]="aBSLeaveContent.Term" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="aBSLeaveContent.ClassName" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="aBSLeaveContent.StdNo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">姓名</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="aBSLeaveContent.StdName">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">請假日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly [(ngModel)]="aBSLeaveContent.RecDateS" disabled>
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly [(ngModel)]="aBSLeaveContent.RecDateE" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">假別</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="aBSLeaveContent.HCode" disabled>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor=" let item of holidayList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">審核截止日</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly [(ngModel)]="aBSLeaveContent.LeaveDeadline" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">審核結果</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="aBSLeaveContent.Status">
                        <option *ngFor=" let item of approveListStatus" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <label class="me-3">節次</label>
                <ng-container *ngFor="let item of curTime;">
                    <div class="form-check form-check-inline me-2">
                        <input class="form-check-input pointer shadow-none" type="checkbox" value="{{item.number}}" id="{{item.number}}" [checked]="item.checked" disabled>
                        <label class="form-check-label pointer" for="{{item.number}}">第{{item.number}}節</label>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-12 mb-3">
                <label for="">證明文件: <a class="pointer text-decoration-none" (click)="getABSLeaveFile(aBSLeaveContent.DataKey)">{{aBSLeaveContent.FileName}}</a></label>
            </div>
            <div class="col-md-12 mb-3">
                <div class="input-group">
                    <span class="input-group-text">請假事由</span>
                    <textarea class="form-control shadow-none" aria-label="With textarea" appTextareaAutoresize [(ngModel)]="aBSLeaveContent.Memo" disabled></textarea>
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group">
                    <span class="input-group-text">審核意見</span>
                    <textarea class="form-control shadow-none" aria-label="With textarea" appTextareaAutoresize [(ngModel)]="aBSLeaveContent.ChkMemo"></textarea>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="send-btn float-start mt-3 me-3" (click)="getABSLeaveSign()">送出</button>
            </div>
        </div>
    </div>
</ng-template>
