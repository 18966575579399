import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { GetTeacherList } from 'src/app/_Models/STDWEB/STDW40005/getTeacherList';
import { Week } from 'src/app/_Models/week';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW40005',
    templateUrl: './STDW40005.component.html',
    styleUrls: ['./STDW40005.component.css']
})
export class STDW40005Component implements OnInit {

    baseUrl = environment.apiUrl;
    data!: Get_Cur_List[];
    functionType = 'stdOfficeHour';
    selectedWeek: string = ''
    selectedTeacher: string = ''
    week!: Week[];
    teacherList!: GetTeacherList[];
    model = {
        Years: '',
        Term: '',
        FormType: '07',
        KeyValue: ''
    }

    constructor(private http: HttpClient, private systemService: SystemService, private toastr: ToastrService, private accountService: AccountService, private timeService: TimeService, private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.model.KeyValue = response.Code;
            }
        });

        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Term = response.Now_Term;
            this.model.Years = response.Now_Years;
            this.getWeek();
            this.getTeacherList();
        });
    }


    getCurList() {
        this.model.KeyValue = this.selectedTeacher;
        this.http.post<any>(this.baseUrl + "Public/Get_CurForm", this.model)
            .subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning("查無資料");
                    this.data = [];
                    return;
                }
                this.data = response;
            },
                error => this.toastr.error(error.error)
            );
    }

    getWeek() {
        this.systemService.getWeek(this.model.Years, this.model.Term).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.week = response;
        },
            error => this.toastr.error(error.error)
        );
    }

    getTeacherList() {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW400_05/Get_OH_TchList", { Years: this.model.Years, Term: this.model.Term }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.teacherList = response;

        }, error => this.toastr.error(error.error));
    }

}
