<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="ngFormInput" (ngSubmit)="getSRPList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Years">
                        <option value="">--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Term">
                        <option value="">--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getDeptList()" formControlName="EduNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" (change)="getClassList();" formControlName="DeptNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>    
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號/姓名</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoName">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">記過日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                        formControlName="ApplyDateFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                        formControlName="ApplyDateTo">
                </div>
            </div>
        </div>    
        <div class="row">    
            <div class="col-md-8">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">獎懲種類</span>
                    </div>
                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                        <div *ngFor="let item of rewardsList; let i = index">
                            <input type="checkbox" class="btn-check" autocomplete="off"
                            id="btncheck{{i+1}}"
                            value="{{item.ItemValue}}"
                            (click)="setCheck($event.target)">
                            <label class="btn btn-outline-secondary shadow-none" for="btncheck{{i+1}}">{{item.ItemName}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <button class="search-btn float-start me-3">查詢</button>
                <button class="other-Button-gray float-start me-3" type="button"
                    (click)="openModel(template, 'new', '')">新增</button>
                <button class="other-Button-gray float-start" type="button" (click)="getSRPBatchDelete()">刪除</button>
            </div>
        </div>
    </form>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 80px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                        (click)="setAll($event.target)">&ensp;全選
                </th>
                <th style="width: 80px;">學年期</th>
                <th>班級</th>
                <th>學號(姓名)</th>
                <th style="width: 140px;">發生日期</th>
                <th style="width: 120px;">建議人</th>
                <th style="width: 150px;">獎懲意見</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sRPList">
                <td style="width: 80px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                        [(ngModel)]="item.Checked"></td>
                <td style="width: 80px;">{{item.Years}}-{{item.Term}}</td>
                <td>{{item.ClassName}}</td>
                <td><a class="pointer text-decoration-none"
                        (click)="openModel(template, '', item.DataKey)">{{item.StdNo}}</a>-{{item.StdName}}</td>
                <td style="width: 140px;">{{item.IssueDate | date: 'yyyy-MM-dd'}}</td>
                <td style="width: 120px;">{{item.IssueUserName}}</td>
                <td style="width: 150px;">{{item.TypeName}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">學生獎懲紀錄作業</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <alert type="danger">
            <strong>請注意目前獎懲學年期是否正確</strong>
        </alert>
        <form [formGroup]="ngFormDetail" (ngSubmit)="getSRPSave()">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">學年</span>
                        </div>
                        <select class="form-select shadow-none" #Year formControlName="Years" (change)="getSuggestList(Year.value)">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">學期</span>
                        </div>
                        <select class="form-select shadow-none" #Term formControlName="Term">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">學號</span>
                        </div>
                        <input type="text" class="form-control shadow-none" #stdNo formControlName="StdNo">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">姓名</span>
                        </div>
                        <input type="text" class="form-control shadow-none" #stdName formControlName="StdName">
                    </div>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6" [hidden]="ngFormDetail.get('DataKey')?.value != ''">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">上傳範本</span>
                        </div>
                        <input class="form-control shadow-none" type="file" (change)="importData($event)">
                        <div class="input-group-prepend">
                            <button class="btn btn-light shadow-none" type="button"
                                (click)="getSRPSampleFile()">範本下載</button>
                        </div>
                        <div class="input-group-prepend">
                            <button class="btn btn-light shadow-none" type="button"
                                (click)="resetFile()">清除</button>
                        </div>
                    </div>
                </div>
            </div>            
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">獎懲意見</span>
                        </div>
                        <select class="form-select shadow-none" formControlName="TypeID">
                            <option value="" disabled>--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of rewardsList">{{item.ItemName}}</option>
                        </select>
                        <div class="input-group-prepend">
                            <span class="input-group-text">次數</span>
                        </div>
                        <input type="number" min="1" class="form-control shadow-none" formControlName="Times">
                    </div>
                </div>                
            </div>    
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">發生日期</span>
                        </div>
                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                            [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                            formControlName="IssueDate">
                    </div>
                </div>                
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">記功(過)日期</span>
                        </div>
                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                            [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                            formControlName="ApplyDate">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">批示日期</span>
                        </div>
                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                            [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                            formControlName="ApproveDate">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">建議人</span>
                        </div>
                        <input type="text" class="form-control shadow-none pointer"
                            style="background-color: transparent;" formControlName="IssueUserName">
                    </div>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">證明文件</span>
                        </div>
                        <input class="form-control shadow-none" type="file" (change)="importAttFile($event, '', '')">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                        <label (click)="getSRPFile(ngFormDetail.get('DataKey')?.value)">{{ngFormDetail.get('FileName')?.value}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">引證條文</span>
                        </div>
                        <select class="form-select shadow-none" #SugCode formControlName="SugCode">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor=" let itemSugCode of sugCodeList" [value]="itemSugCode.ItemValue">{{itemSugCode.ItemValue}}{{itemSugCode.ItemName}}</option>
                        </select>
                    </div>
                </div>
            </div>            
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">獎懲事由</span>
                        </div>
                        <input type="text" class="form-control shadow-none" formControlName="Means">
                    </div>
                </div>
            </div>
            <div class="row">
                <div>
                    <button class="save-btn-gray mx-auto mt-3">儲存</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>