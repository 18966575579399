<div class="container" style="width: 40%;">
    <form [formGroup]="ngForm" (ngSubmit)="getCURTchMoneyOverLimitModify()">
        <div class="row">
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <div class="input-group">
                        <span class="input-group-text">專任教師超支鐘點限制</span>
                        <input type="number" class="form-control shadow-none" min="0" step="0.01" max="99" formControlName="FullTimeLimit">
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <div class="input-group">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" readonly formControlName="UpdateUser">
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <div class="input-group">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" readonly formControlName="UpdateTime">
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <button class="modify-btn mx-auto">修改</button>
            </div>
        </div>
    </form>
</div>
