import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetHolidayList } from 'src/app/_Models/SAC/SAC1701/getHolidayList';
import { GetTchLeaveContent } from 'src/app/_Models/TCHWEB/TCH0203/getTchLeaveContent';
import { GetTchLeaveList } from 'src/app/_Models/TCHWEB/TCH0203/getTchLeaveList';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0203',
    templateUrl: './TCH0203.component.html',
    styleUrls: ['./TCH0203.component.css']
})
export class TCH0203Component implements OnInit {
    bsConfig = {
        isAnimated: true,
        dateInputFormat: 'YYYY-MM-DD',
        containerClass: 'theme-dark-blue',
        returnFocusToInput: true,
        showTodayButton: true,
        todayPosition: 'center',
        showClearButton: true,
        clearPosition: 'right'
    }
    tchLeaveContentModalRef!: BsModalRef;
    tchLeaveContentData!: GetTchLeaveContent;
    daterangepickerModel: Array<Date> = [];
    baseUrl = environment.apiUrl;
    searchForm!: UntypedFormGroup;
    holidayList!: GetHolidayList[];
    tchLeaveListData!: GetTchLeaveList[];
    Years = '';
    Term = '';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.getHolidayList();
        this.searchForm = this.formBuilder.group({
            Date: [],
            H_Code: '',
            Years: '',
            Term: '',
            RecDate: ''
        });

        this.timeService.getTimeCntr().subscribe((response) => {
            this.Years = response.Now_Years;
            this.Term = response.Now_Term;
            this.getTchLeaveList();
        });

    }

    setModel(model: any) {
        this.Years = model.Years;
        this.Term = model.Term;
    }

    getHolidayList() {
        this.http.post<any>(this.baseUrl + 'Public/Get_Holiday_List', { Type: 'A', Del: '0' }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message);
            }
            this.holidayList = response;
        })
    }

    getTchLeaveList() {
        this.searchForm.value.Years = this.Years;
        this.searchForm.value.Term = this.Term;

        if (this.Years == '' || this.Term == '') {
            this.toastr.info("請先選擇學年期");
            return;
        }
        // if(this.searchForm.value.Date != null)
        // {
        //   this.searchForm.value.RecDate_S = this.datepipe.transform(this.searchForm.value.Date[0], 'yyyy-MM-dd');
        //   this.searchForm.value.RecDate_E =  this.datepipe.transform(this.searchForm.value.Date[1], 'yyyy-MM-dd');
        // }

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH02_03/Get_TchLeave_List", this.searchForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.tchLeaveListData = []
                return;
            }
            this.tchLeaveListData = response;
        });
    }

    getTchLeaveContent(dataKey: string, template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH02_03/Get_TchLeave_Content", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.tchLeaveContentData = response[0];
            this.tchLeaveContentModalRef = this.modalService.show(template, modalConfig);
        });
    }

    sign(signMemo: string, optionTrue: boolean) {
        this.tchLeaveContentData.Options = optionTrue == true ? '1' : '2';
        this.tchLeaveContentData.SignMemo = signMemo;
        this.http.post<any>(this.baseUrl + 'ACAD/TCHWEB/TCH02_03/Get_TchLeave_Sign', this.tchLeaveContentData).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.tchLeaveContentModalRef.hide();
            this.toastr.success("審核結果已送出");
            this.getTchLeaveList();
        });
    }
}
