export class GetCUROpen01CosDetail{
    DataKey: string = ''
    Years: string = ''
    Term: string = ''
    Class: string = ''
    TeamNo: string = ''
    CosID: string = ''
    CosName: string = ''
    Serial: string = ''
    SelKindName: string = ''
    Credits: string = ''
    Hours: number = 0
    TchMLog: string = ''
    TchMClass: string = ''
    TSEndTime: Date = new Date()
    StopMark: boolean = false
    WeekMarkS: boolean = false
    WeekMarkD: boolean = false
    ChkCosArrange: boolean = false
    AllotMark: boolean = false
    PayNo: string = ''
    Memo: string = ''
    OverGrade: number = 1
    UnderGrade: number = 1
    SelMax: number = 0
    SelMin: number = 0
    ChkSelMax: boolean = false
    ChkSelMin: boolean = false
    ChkAdd: boolean = false
    ChkDel: boolean = false
    ChkAddType: boolean = false
    ChkEdu: boolean = false
    ChkTime: boolean = false
    ChkSelDept: boolean = false
    ChkSelClass: boolean = false
    ChkDept: boolean = false
    ChkClass: boolean = false
    ChkPrePass: boolean = false
    ChkCredit: boolean = false
    ChkDelMust: boolean = false
    ChkPGSel: boolean = false
    CreditMoney: number = 0
    MergeNo: string = ''
    SelDeptList: SelDeptList[] = []
    SelClassList: SelClassList[] = []
    TchList: TchList[] = []
    ClassRoomList: ClassRoomList[] = []
    ExpandList: ColumnList[] = []
}

export interface SelDeptList{
    DataKey: string,
    DeptNo: string,
    DeptName: string,
    Checked: boolean,
}

export interface SelClassList {
    DataKey: string
    Class: string
    ClassName: string
    Checked: boolean 
}

export interface TchList{
    DataKey: string
    TchName: string 
    ArrangeHours: number 
    MoneyHours: number 
    Score: number 
    ChkTime: boolean
    ChkMerge: boolean 
    Memo: string
    Delete: boolean
}

export interface ClassRoomList{
    DataKey: string
    ClassRoom: string
    ClassRoomType: string
    ArrangeHours: number
    Delete: boolean
}

export interface ColumnList{
    DataKey: string,
    ColumnNo: string,
    ColumnName: string,
    DataType: string,
    ItemValue: string,
    ItemName: string
}