import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-TCH0112',
    templateUrl: './TCH0112.component.html',
    styleUrls: ['./TCH0112.component.css']
})
export class TCH0112Component implements OnInit {

    baseUrl = environment.apiUrl;
    pwdRule: any;
    constructor(private http: HttpClient, private toastr: ToastrService, private router: Router) { }

    ngOnInit(): void {
        this.http.get<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_12/Get_Pwd_Rules").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.pwdRule = response;
        })
    }


    changePassword(oldPwd: string, newPwd: string, confirmNewPwd: string) {

        if (newPwd !== confirmNewPwd) {
            this.toastr.warning("密碼不一致");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_12/Get_ChangePWD", {
            PassWord: oldPwd,
            NewPassWord: newPwd
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            let timerInterval: any;
            Swal.fire({
                title: '變更成功!',
                html: '再 <b class="text-danger"></b> 秒後將回到登入頁。',
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    // Swal.showLoading(null);
                    timerInterval = setInterval(() => {
                        const content = Swal.getHtmlContainer();
                        if (content) {
                            const b: any = content.querySelector('b');
                            if (b) {
                                b.textContent = Swal.getTimerLeft();
                            }
                        }
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                    this.router.navigateByUrl('/');
                }
            })
        })
    }
}
