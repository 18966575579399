<div class="container" style="margin-bottom: 100px;">
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>{{'項目/通用'|translate}}</th>
                <th>{{'參數值'|translate}}</th>
                <th>{{'說明'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sELParametersPublicList; let i = index">
                <td>{{item.ParameterName}}</td>
                <td>
                    <ng-container *ngIf="item.ValueType == 'bool'">
                        <input class="form-check-input shadow-none pointer text-center" type="checkbox"
                            id="flexCheckDefault" [(ngModel)]="item.BoolValue">
                    </ng-container>
                    <ng-container *ngIf="item.ValueType == 'int'">
                        <input type="number" class="form-control shadow-none text-center" min="0" value="0"
                            [(ngModel)]="item.IntValue">
                    </ng-container>
                    <ng-container *ngIf="item.ValueType == 'select'">
                        <div class="input-group flex-nowrap mb-3">
                            <input type="text" class="form-control shadow-none text-center" min="0" value="0"
                                [(ngModel)]="item.TextValue" disabled>
                                <button class="btn btn-light"
                                    (click)="openModal(template, item.SelectType, i)">...</button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.ValueType == 'date'">
                        <div class="input-group flex-nowrap mb-3">
                            <input type="text" placeholder="{{'點選設定'|translate}}" class="form-control pointer shadow-none text-center"
                                style="height: 38px; background-color: transparent;" bsDatepicker [bsConfig]="bsConfig"
                                readonly [(ngModel)]="item.DateTimeValue">
                            <timepicker [showMeridian]="true" [showSpinners]="false" required
                                [(ngModel)]="item.DateTimeValue">
                            </timepicker>
                        </div>
                    </ng-container>
                </td>
                <td>{{item.ParameterMemo}}</td>
            </tr>
        </tbody>
    </table>
    <button class="save-btn-gray mx-auto" (click)="getSELParametersPublicSave()">{{'儲存'|translate}}</button>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'選取'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3 mb-3">
                <button class="other-Button-gray float-end" (click)="setData()">{{'設定'|translate}}</button>
            </div>
        </div>
        <ng-container *ngIf="selectType == 'Dept'">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>{{'代碼'|translate}}</th>
                        <th>{{'名稱'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of deptList">
                        <td><input type="checkbox" class="form-check-input"
                                (click)="setArray(item.Dept_No, $event.target)" [checked]="checkData(item.Dept_No)">
                        </td>
                        <td>{{item.Dept_No}}</td>
                        <td>{{item.Dept_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-container *ngIf="selectType == 'Edu'">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>{{'代碼'|translate}}</th>
                        <th>{{'名稱'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of eduList">
                        <td><input type="checkbox" class="form-check-input"
                                (click)="setArray(item.Edu_No, $event.target)" [checked]="checkData(item.Edu_No)"></td>
                        <td>{{item.Edu_No}}</td>
                        <td>{{item.Edu_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </ng-container>

    </div>
</ng-template>
