<div class="container">
    <form (ngSubmit)="query(model)" #queryForm="ngForm">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="Years">{{'學年'|translate}}</span>
                    </div>
                    <select class="form-select shadow-none"[(ngModel)]="model.Years" name="Years" (change)="reset()">
                        <option value="" disabled selected>{{'--請選擇學年--'|translate}}</option>
                        <option *ngFor="let item of items" [value]="item">{{item}}{{'學年'|translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="Term">{{'學期'|translate}}</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="model.Term" name="Term" (change)="reset()">
                        <option value="" selected disabled >{{'--請選擇學期--'|translate}}</option>
                        <option value="1">{{'第一學期'|translate}}</option>
                        <option value="2">{{'第二學期'|translate}}</option>
                        <option value="3">{{'寒修'|translate}}</option>
                        <option value="4">{{'暑修'|translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="Type">{{'統計類別'|translate}}</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="model.Type" name="Type" (change)="reset()">
                        <option value="" selected disabled>{{'--請選擇類別--'|translate}}</option>
                        <option value="A">{{'討論度統計'|translate}}</option>
                        <option value="B">{{'作業繳交狀況'|translate}}</option>
                        <option value="C">{{'班級平均成績'|translate}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="input-group-prepend">
            <button class="search-btn" type="submit">{{'查詢'|translate}}</button>
        </div>
    </form>
    <hr />
    <ng-container *ngIf="IsQuery">
        <ng-container *ngIf="model.Type == 'A'">
            <apx-chart
            [series]="barchartOptions.series"
            [chart]="barchartOptions.chart"
            [xaxis]="barchartOptions.xaxis"
            [title]="barchartOptions.title"
        ></apx-chart>
        </ng-container>
        <ng-container *ngIf="model.Type == 'B'">
            <apx-chart
            [series]="homeworkOptions.series"
            [chart]="homeworkOptions.chart"
            [labels]="homeworkOptions.labels"
            [responsive]="homeworkOptions.responsive"
            [plotOptions]="homeworkOptions.plotOptions"
            [dataLabels]="homeworkOptions.dataLabels"
            [xaxis]="homeworkOptions.xaxis"
            [theme]="homeworkOptions.theme"
            [title]="homeworkOptions.title"
            ></apx-chart>
        </ng-container>
        <ng-container *ngIf="model.Type == 'C'">
            <apx-chart
            [series]="scoreOptions.series"
            [chart]="scoreOptions.chart"
            [labels]="scoreOptions.labels"
            [responsive]="scoreOptions.responsive"
            [plotOptions]="scoreOptions.plotOptions"
            [dataLabels]="scoreOptions.dataLabels"
            [xaxis]="scoreOptions.xaxis"
            [title]="scoreOptions.title"
            ></apx-chart>
        </ng-container>
    </ng-container>
  </div>
