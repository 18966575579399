<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-12 mb-3">
            <button class="save-btn-gray float-start me-3" (click)="getCURTchMoneyHourRateModify()">儲存</button>
            <button class="other-Button-gray float-start me-3" (click)="getCURTchMoneyHourRateDelete()">刪除</button>
            <button class="other-Button-gray float-start me-3" (click)="clear()">清除</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>鐘點比例</th>
                        <th>修課最大人數</th>
                        <th>修課最小人數</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of cURTchMoneyHourRateList; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="getDetail(i)">
                        <td>{{i + 1}}</td>
                        <td>{{item.HourRate}}</td>
                        <td>{{item.MaxNum}}</td>
                        <td>{{item.MinNum}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <form [formGroup]="ngForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">鐘點比例</span>
                            </div>
                            <input type="number" class="form-control shadow-none" min="1" step="0.01"
                                formControlName="HourRate">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">修課最大人數</span>
                            </div>
                            <input type="number" class="form-control shadow-none" min="1" formControlName="MaxNum">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">修課人最小人數</span>
                            </div>
                            <input type="number" class="form-control shadow-none" min="1" formControlName="MinNum">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">修改者</span>
                            </div>
                            <input type="text" readonly class="form-control shadow-none" formControlName="UpdateUser">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">修改時間</span>
                            </div>
                            <input type="text" readonly class="form-control shadow-none" formControlName="UpdateTime">
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>
