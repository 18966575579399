import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/assets/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { search } from 'src/app/_Models/AD/ADPUB/ADPUB0101/search';
import { searchdetail } from 'src/app/_Models/AD/ADPUB/ADPUB0101/searchdetail';
import { GetEMPCertFile } from 'src/app/_Models/AD/ADEMP/ADEMP0301/getEMPCertList';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { GetEDocFlow } from 'src/app/_Models/getEDocFlow';
import { DomSanitizer } from '@angular/platform-browser';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';

@Component({
    selector: 'app-ADPUB0101',
    templateUrl: './ADPUB0101.component.html',
    styleUrls: ['./ADPUB0101.component.css']
})
export class ADPUB0101Component implements OnInit {
    modalRef!: BsModalRef;
    bsConfig = environment.bsDatePickerConfig;
    baseUrl = environment.apiUrl;
    datakeyArray: Array<string> = [];
    eDocFlow!: GetEDocFlow[];
    dataDetail: searchdetail = {
        Years: '',
        Term: '',
        SerialNo: '',
        UnitName: '',
        Code: '',
        UserName: '',
        JobName: '',
        StartDate: '',
        EndDate: '',
        Reason: '',
        Status: '',
        Send: false,
        DataKey: ''
    }

    search_list!: search[];
    CertFile!:GetEMPCertFile;
    minDate!: Date;
    fileExchange = new FileExchange();
    MemoContents!:string;

    myFilter = (d: Date): boolean => {
        const day = d.getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    }

    constructor(
        private toastr: ToastrService,
        private http: HttpClient,
        private modalService: BsModalService,
        private localeService: BsLocaleService,
        private datePipe: DatePipe,
        private domSanitizer: DomSanitizer,
        private fileExchangeServices: FileExchangeService
    ) {

    }

    ngOnInit(): void {
        const currentYear = Date.now();
        this.minDate = new Date(currentYear);
        this.search();
        this.getCertMemo();
        this.localeService.use('zh-cn');
    }

    search() {
        this.http.get<any>(this.baseUrl + "ACAD/PUB/ADPUB0101/Get_EMP_Apply_List").subscribe((response) => {
            this.search_list = []
            if (response.Info == false) {
                this.toastr.warning(response.Message)
                return;
            };
            this.search_list = response;
        });
    }

    setDataKey_all(event: any) {
        let input = <HTMLInputElement>event.target;
        if (input.checked == true) {
            this.datakeyArray = [];
            this.search_list.forEach(element => {
                if(element.Status=='0'){
                    this.datakeyArray.push(element.DataKey);
                    element.Checked = true as false;
                }
            });
        }
        else {
            this.datakeyArray = [];
            this.search_list.forEach(element => {
                if(element.Status=='0'){
                    this.datakeyArray.push(element.DataKey);
                    element.Checked = false as false;
                }
            });
        }
    }

    searchdetail() {
        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0101/Get_EMP_Apply_Detail", {}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message)
                return;
            };
            //console.log(response);
            this.dataDetail = response;
        });
    }

    searchdetail_leavesave(i: number) {
        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0101/Get_EMP_Apply_Leave_Save", this.search_list[i]).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message);
                return;
            };
            this.toastr.success("申請成功");
            this.search();
        });
    }

    searchdetail_edit(i: number) {
        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0101/Get_EMP_Apply_Detail", this.search_list[i]).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message)
                return;
            };
            this.dataDetail = response;
        });
    }

    openModal(template: TemplateRef<any>) {

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    openModal_status(template: TemplateRef<any>, dataKey: string) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.getEDocFlow(dataKey);
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    openModalFile(template: TemplateRef<any>,Cert:search ){
        this.CertFile={
          DataKey:Cert.FileDataKey,
          SerialNo:Cert.SerialNo,
          Code:Cert.Code,
          FileData:{Info:true,FileName:"",Base64:""}
        };

        let modalConfig = {
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-md'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
      }

    save() {

        if (this.dataDetail.EndDate == '') {
            this.toastr.warning("任職截止日期未填");
            return;
        }

        if (Date.parse(this.minDate.toString()) > Date.parse(this.dataDetail.EndDate)) {
            this.toastr.warning("已過現在日期");
            return;
        }

        if (this.dataDetail.Reason == '') {
            this.toastr.warning("離職原因未填");
            return;
        }

        this.dataDetail.EndDate = this.datePipe.transform(this.dataDetail.EndDate, 'yyyy-MM-dd')!.toString();
        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0101/Get_EMP_Apply_Save", this.dataDetail).subscribe((response) => {
            if(response.length==undefined){
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
            }
            else{
                for (let i = 0; i < response.length; i++) {
                    if (response[i].Info == false) {
                        this.toastr.warning(response[i].Message);
                        return;
                    }
                }
            }

            this.toastr.success("儲存成功");
            this.modalRef.hide();
            this.search();
        });
    }

    send() {

        if (this.dataDetail.EndDate == '') {
            this.toastr.warning("任職截止日期未填");
            return;
        }

        if (Date.parse(this.minDate.toString()) > Date.parse(this.dataDetail.EndDate)) {
            this.toastr.warning("已過現在日期");
            return;
        }

        if (this.dataDetail.Reason == '') {
            this.toastr.warning("離職原因未填");
            return;
        }

        this.dataDetail.EndDate = this.datePipe.transform(this.dataDetail.EndDate, 'yyyy-MM-dd')!.toString();

        Swal.fire({
            title: "一旦送出即不能修改，確定?",
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                this.dataDetail.Send = true;
                this.http.post<any>(this.baseUrl + 'ACAD/PUB/ADPUB0101/Get_EMP_Apply_Save', this.dataDetail).subscribe((response) => {

                    for (let i = 0; i < 2; i++) {
                        if (response[i].Info == false) {
                            this.toastr.warning(response[i].Message);
                            return;
                        }
                    }

                    this.toastr.success("送出成功");
                    this.modalRef.hide();
                    this.search();
                }, error => this.toastr.error(error.error))
            }
            else {
                this.toastr.info("已取消");
            }
        })
    }

    delete() {
        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0101/Get_EMP_Apply_Delete", this.search_list).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message)
                return;
            };
            this.search();
        });
    }

    getEDocFlow(dataKey: string) {
        this.http.post<any>(this.baseUrl + "Public/Get_EDoc_Flow", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eDocFlow = response;
            this.eDocFlow.forEach(element => {
                element.SafeUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + element.StampBase64);
            });
        }, error => this.toastr.error(error.error))
    }

    setDataKey(check: boolean) {
        let y = false;
        if (check == false) {
            y = true;
            this.search_list[0].Checked = y as false;
        }
        else {
            y = false;
            this.search_list[0].Checked = y as false;
        };
    }

    getCertFileDownLoad(Cer:search){
        this.CertFile={
          DataKey:Cer.FileDataKey,
          SerialNo:Cer.SerialNo,
          Code:Cer.Code,
          FileData:{Info:false,FileName:"",Base64:""}
        };

        this.fileExchangeServices.ExportFile("ACAD/PUB/ADPUB0101/Get_EMP_Apply_FileDownload", this.CertFile).subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning("無附件");
            return;
          }

          if (response.FileName == '') {
            this.toastr.warning("查無資料");
            return;
          }

          let url = window.URL.createObjectURL(response.blob);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = response.FileName;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }, error => console.log(error.error))
    }

    importAttFile($event: any, dataKey: string, type: string) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetFileBase64, dataKey, type)
    }

    GetFileBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
              that.CertFile.FileData.FileName=file.name;
              that.CertFile.FileData.Base64=base64;
            }
            else if (result.isDenied) {
                let file = <HTMLInputElement>document.querySelector('#file');
                file.value = '';
                that.toastr.info("已取消上傳。");
                that.CertFile.FileData.FileName="";
                that.CertFile.FileData.Base64="";
            }
        });
    }

    getCertFileUpload(){
        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0101/Get_EMP_Apply_FileUpload", this.CertFile).subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          this.toastr.success("附件上傳成功!");
          this.search();
          this.modalRef?.hide();
        })
    }

    getCertFileDelete(Cer:search){
        this.CertFile={
            DataKey:Cer.FileDataKey,
            SerialNo:Cer.SerialNo,
            Code:Cer.Code,
            FileData:{Info:false,FileName:"",Base64:""}
        };

        Swal.fire({
            title: '確定進行刪除？',
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '確定'
        }).then(result => {
            if (result.isConfirmed) {
              this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0101/Get_EMP_Apply_FileDelete", this.CertFile).subscribe((response) => {
                if (response.Info == false) {
                  this.toastr.warning(response.Message);
                  return;
                }
                this.toastr.success("附件刪除完成!");
                this.search();
              });
            } else {
                this.toastr.info('動作已取消');
            }
        })
    }

    getCertMemo() {
        this.http.get<any>(this.baseUrl + "Public/Get_EMP_Cert_Memo").subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          let memo=response.Contents;
          let re = /\\n\\/gi;

          this.MemoContents="<p style='margin:3px;'>"+memo.replace(re,"</p><p style='margin:3px;'>")+"</p>";
        })
    }
}
