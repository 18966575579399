<div class="container">
    <h4>伺服器錯誤!重新整理會導致錯誤消失。</h4>
    <ng-container>
        <h5 class="text-danger">錯誤：{{error}}</h5>
        <p class="fw-bold">如果你看到這個，代表網站並沒有出錯。</p>
        <p>你可以做的事情：</p>
        <ol>
            <li>開啟開發人員模式。</li>
            <li>移至network頁籤。</li>
            <li>檢查錯誤請求。</li>
            <li>檢查請求Url是否正確。</li>
            <li>請洽系統廠商協助解決您的問題。</li>
        </ol>
        <p>請追蹤以下歷程，這裡是你開始檢查的地方。</p>
        <code class="mt-5">{{error}}</code>
    </ng-container>
</div>