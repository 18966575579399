import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADTFS0603',
  templateUrl: './ADTFS0603.component.html',
  styleUrls: ['./ADTFS0603.component.css']
})
export class ADTFS0603Component implements OnInit {
  initialData = new InitialData(this.http);
  fileExchange = new FileExchange();
  baseUrl = environment.apiUrl;
  Years!: Array<string>;
  makeDocList!: GetSelectList[];
  term = require('src/app/_Models/_SelectInput/term.json').term;
  bsConfig = environment.bsDatePickerConfig;
  constructor(private toastr: ToastrService, private http: HttpClient, private localeService: BsLocaleService, private systemService: SystemService) { }

  ngOnInit(): void {
    this.getYears();
    this.localeService.use('zh-cn');
    this.getMakeDocList();
  }



  getMakeDocList() {
    this.systemService.getMakeDocList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.makeDocList = response;
    }, error => this.toastr.error(error.error))
  }

  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getTFSReport0603Base64(Years: string, Term: string, MakeDocNo: string, Loan: string, InDateFrom: string, InDateTo: string) {
    this.http.post<any>(this.baseUrl + 'ACAD/TFS/ADTFS0603/Get_TFS_Report_0603_Base64', {
      Years: Years,
      Term: Term,
      MakeDocNo: MakeDocNo,
      Loan: Loan,
      InDateFrom: InDateFrom,
      InDateTo: InDateTo,
      ReportType: 'N'
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      if (response.Base64 == '') {
        this.toastr.warning('查無資料');
        return;
      }
      let contentType = "application/octet-stream"
      let blob = this.fileExchange.Base64ToBlob(response.Base64, contentType, 512);

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = "已繳清冊.xls";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    })
  }

}
