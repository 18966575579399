<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <button class="search-btn float-start me-3"
                (click)="getCUROpenClassList(Year.value, Term.value)">查詢</button>
            <button class="other-Button-gray float-start me-3" (click)="openModal(copyTemplate, '', 'copy')">複製</button>
            <button class="other-Button-gray float-start me-3"
                (click)="exportCUROpenClass(Year.value, Term.value)">匯出檔案</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 80px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                (click)="setAll('unset', $event.target)">&ensp;全選</th>
                        <th>班級代碼</th>
                        <th>班級名稱</th>
                    </tr>
                </thead>
                <tbody *ngIf="cUROpenClassList">
                    <tr *ngFor="let item of cUROpenClassList.UnSetClass">
                        <td style="width: 60px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                [(ngModel)]="item.Checked"></td>
                        <td>{{item.Class}}</td>
                        <td>{{item.ClassName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-2 text-center" style="padding-top: 15%;">
            <button class="other-Button-gray" (click)="getCUROpenClassAddNew()">加入</button>
            <br>
            <button class="other-Button-gray mt-3" (click)="getCUROpenClassDelete()">移除</button>
        </div>
        <div class="col-md-5">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 80px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                (click)="setAll('set', $event.target)">&ensp;全選</th>
                        <th>班級代碼</th>
                        <th>班級名稱</th>
                        <th>授權人員</th>
                    </tr>
                </thead>
                <tbody *ngIf="cUROpenClassList">
                    <tr *ngFor="let item of cUROpenClassList.SetClass">
                        <td style="width: 60px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                [(ngModel)]="item.Checked"></td>
                        <td>{{item.Class}}</td>
                        <td>{{item.ClassName}}</td>
                        <td><a class="pointer  text-decoration-none"
                                (click)="openModal(template, item.DataKey, '')"><span class="icon-Modify-button"></span>
                                設定</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定授權人員</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">關鍵字</span>
                    </div>
                    <input type="text" class="form-control" placeholder="請輸入代碼或姓名"
                        [(ngModel)]="sYSAccountListInput.UserName">
                </div>

            </div>
            <div class="col-md-2">
                <button class="search-btn float-start" (click)="getSYSAccountList()">查詢</button>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-5 pt-2">
                <label>已授權人員</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th style="width: 60px;"></th>
                            <th>代碼</th>
                            <th>姓名</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="sYSAccountList">
                        <tr *ngFor="let item of sYSAccountList">
                            <td style="width: 60px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                    (click)="setCodeList(item.UserCode, $event.target)"></td>
                            <td>{{item.UserCode}}</td>
                            <td>{{item.UserName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-2 text-center" style="padding-top: 15%;">
                <button class="other-Button-gray" (click)="getCUROpenClassEmployeeAddNew()">加入</button>
                <br>
                <button class="other-Button-gray mt-3" (click)="getCUROpenClassEmployeeDelete()">移除</button>
            </div>
            <div class="col-md-5">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th style="width: 60px;"></th>
                            <th>代碼</th>
                            <th>姓名</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="cUROpenClassEmployeeList">
                        <tr *ngFor="let item of cUROpenClassEmployeeList.EmployeeList">
                            <td style="width: 60px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                    [(ngModel)]="item.Checked"></td>
                            <td>{{item.Code}}</td>
                            <td>{{item.Name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #copyTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">複製資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-md">
                <h4>從</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #copyFromYears>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of copyYears" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #copyFromTerm>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <h4>複製到</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #copyToYears>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of copyYears" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #copyToTerm>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <button class="save-btn-gray"
            (click)="getCUROpenClassCopyTo(copyFromYears.value, copyFromTerm.value, copyToYears.value, copyToTerm.value)">儲存</button>
    </div>
</ng-template>
