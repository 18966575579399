<div class="container">
    <app-year-term-input (childEvent)="getRecord($event)"></app-year-term-input>
    <div class="row">
        <div class="col">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'學號' | translate}}</span>
                </div>
                <input type="text" disabled readonly class="form-control shadow-none" [(ngModel)]="user.Code">
            </div>
        </div>
        <div class="col">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'姓名' | translate}}</span>
                </div>
                <input type="text" disabled readonly class="form-control shadow-none" [(ngModel)]="user.UserName">
            </div>
        </div>
    </div>
    <button type="button" class="search-btn" (click)="query(inputModel)">{{'查詢' | translate}}</button>
    <button type="button" [hidden]="true" class="other-Button-gray">{{'獎懲紀錄證明' | translate}}</button>

    <table class="table table-hover table-striped mt-3 text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>{{'獎懲紀錄' | translate}}</th>
                <th>{{'學年' | translate}}</th>
                <th>{{'發生日期' | translate}}</th>
                <th>{{'事由' | translate}}</th>
            </tr>
        </thead>
        <tbody *ngIf="records">
            <tr *ngFor="let record of records">
                <td>{{record.Rname + record.Times + "次"}}</td>
                <td>{{record.S_Years}}</td>
                <td>{{record.occurDate}}</td>
                <td>{{record.Notes}}</td>
            </tr>
        </tbody>
    </table>
</div>