import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApexAxisChartSeries, ApexChart, ApexPlotOptions, ApexTitleSubtitle, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    title: ApexTitleSubtitle;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    plotOptions: ApexPlotOptions;
};

@Component({
    selector: 'app-STDW30005',
    templateUrl: './STDW30005.component.html',
    styleUrls: ['./STDW30005.component.css']
})

export class STDW30005Component implements OnInit {
    baseUrl = environment.apiUrl;
    @ViewChild("chart") chart!: ChartComponent;
    chartOptions: Partial<any>;
    constructor(private toastr: ToastrService, private http: HttpClient, private systemService: SystemService, private translateService: TranslateService
    ) {
        this.chartOptions = {
            series: [
                {
                    name: "Series 1",
                    data: [80, 77, 31, 78, 87, 83]
                }
            ],
            chart: {
                height: 700,
                type: "radar",
            },
            title: {
                text: "各專業人才之系級核心能力發展圖(以烘焙系畢業生為例)",
                margin: 10,
                align: 'center',
                style: {
                    fontSize: "25px"
                }
            },
            xaxis: {
                categories: ["烘焙產品製作能力(A1)", "烘焙廚藝學理知能(A2)", "經營管理學理知能(A3)", "創新設計研發知能(A4)", "積極服務敬業態度(A5)", "主動溝通學習能力(A6)"],
                labels: {
                    style: {
                        colors: ["#000000", "#000000", "#000000", "#000000", "#000000", "#000000"],
                        fontSize: "20px",
                        fontFamily: 'Arial'
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: "17px",
                        fontFamily: 'Arial',
                    },
                    formatter: function (val: number) {
                        return val + "%";
                    }
                }
            },
            plotOptions: {
                radar: {
                    polygons: {
                        fill: {
                            colors: ["#f8f8f8", "#fff"]
                        }
                    }
                }
            },
        };

    }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW300_05/Get_CurMap_List").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
        })

    }

}
