import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetSYSAccountDetail } from 'src/app/_Models/AD/ADSYS/ADSYS0101/getSYSAccountDetail';
import { GetSYSAccountList } from 'src/app/_Models/AD/ADSYS/ADSYS0101/getSYSAccountList';
import { GetSYSAccountListInput } from 'src/app/_Models/AD/ADSYS/ADSYS0101/getSYSAccountListInput';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADSYS0101',
  templateUrl: './ADSYS0101.component.html',
  styleUrls: ['./ADSYS0101.component.css']
})
export class ADSYS0101Component implements OnInit {

  baseUrl = environment.apiUrl;
  sYSAccountListInput: GetSYSAccountListInput = {
    UserCode: '',
    Account: '',
    UserName: '',
    UserType: '',
    UnitNo: '',
    Status: ''
  };
  sYSAccountList!: GetSYSAccountList[];
  sYSAccountDetail: GetSYSAccountDetail = {
    DataKey: '',
    UserCode: '',
    UserName: '',
    Account: '',
    UnitName1: '',
    UnitName2: '',
    UserType: '',
    EMail: '',
    Status: false,
    PassWord: '',
    OnlineChangePwd: false,
    DefaultPwd: false,
    UpdateUser: '',
    UpdateTime: '',
    ChangePwdUser: '',
    ChangePwdTime: ''
  };
  unitList!: GetSelectList[];
  constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService) { }

  ngOnInit(): void {
    this.getUnitList();
  }


  getUnitList() {
    this.systemService.getUnitList('', '', '').subscribe((response) => {
      this.unitList = response;
    })
  }

  getSYSAccountList() {
    this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0101/Get_SYS_AccountList", this.sYSAccountListInput).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.sYSAccountList = []
        return;
      }
      this.sYSAccountList = response;
      this.sYSAccountList.forEach(element => {
        element.Selected = false;
      });
    })
  }

  getSYSAccountDetail(DataKey: string, index: number) {
    this.sYSAccountList.forEach(element => {
      element.Selected = false;
    });
    this.sYSAccountList[index].Selected = true;

    this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0101/Get_SYS_AccountDetail", { DataKey: DataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.sYSAccountDetail = response;
    })
  }

  getSYSAccountModify() {

    if (this.sYSAccountDetail.DataKey == '') {
      this.toastr.info("尚未選取人員");
      return;
    }

    if (this.sYSAccountDetail.Account == '') {
      this.toastr.info("帳號必填");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0101/Get_SYS_AccountModify", this.sYSAccountDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("修改成功");
      this.getSYSAccountList()
    })
  }

}
