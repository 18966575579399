
<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.Years">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" name="Term" [(ngModel)]="getStdData.Term">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.EduNo" (change)="getDeptList(getStdData.EduNo)"
                    [(ngModel)]="getStdListInput.EduNo" (ngModelChange)="GetData()">
                    <option value="" selected disabled>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">系所</span>
                </div>
                <select class="form-select shadow-none"  [(ngModel)]="getStdData.DeptNo"
                    (ngModelChange)="GetData()">
                    <option value="" selected disabled>--請選擇系所--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">年級</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="getStdData.Grade">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
</div>
    <div >
        <button class="export-btn mx-auto" (click)="exportReportADSAC0303()">匯出 </button>
    </div>


