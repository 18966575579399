import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0102/search';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADRGP0102',
  templateUrl: './ADRGP0102.component.html',
  styleUrls: ['./ADRGP0102.component.css']
})
export class ADRGP0102Component implements OnInit {
  baseUrl = environment.apiUrl;
  data!: Search[];
  dataDetail: Search = {
    Edu_No: '',
    Edu_Name: '',
    Edu_Short: '',
    Edu_Name_E: '',
    State_Log: '',
    MoeChar: '',
    Update_User: '',
    Update_Time: '',
    Selected: false
  }
  constructor(private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.search();
  }


  search() {
    this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0102/Search").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.data = []
        return;
      }
      this.data = response;
      this.data.forEach(element => {
        element.Selected = false;
      });
    }, error => this.toastr.error(error.error))
  }

  detail(index: number) {
    this.dataDetail.Edu_No = this.data[index].Edu_No;
    this.dataDetail.Edu_Name = this.data[index].Edu_Name;
    this.dataDetail.Edu_Short = this.data[index].Edu_Short;
    this.dataDetail.Edu_Name_E = this.data[index].Edu_Name_E;
    this.dataDetail.State_Log = this.data[index].State_Log;
    this.dataDetail.MoeChar = this.data[index].MoeChar;
    this.dataDetail.Update_Time = this.data[index].Update_Time;
    this.dataDetail.Update_User = this.data[index].Update_User;
    this.data.forEach(element => {
      element.Selected = false;
    });
    this.data[index].Selected = true;
  }

  insert() {
    if (this.dataDetail.Edu_No == '' ||
      this.dataDetail.Edu_Name == '' ||
      this.dataDetail.Edu_Name_E == '' ||
      this.dataDetail.Edu_Short == '' ||
      this.dataDetail.State_Log == '') {
      this.toastr.info("欄位請勿空白");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0102/Insert", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("新增成功");
      this.dataDetail = {
        Edu_No: '',
        Edu_Name: '',
        Edu_Short: '',
        Edu_Name_E: '',
        State_Log: '',
        MoeChar: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
      }
      this.search();
    }, error => this.toastr.error(error.error))
  }

  update() {

    if (this.dataDetail.Edu_No == '' ||
      this.dataDetail.Edu_Name == '' ||
      this.dataDetail.Edu_Name_E == '' ||
      this.dataDetail.Edu_Short == '' ||
      this.dataDetail.State_Log == '') {
      this.toastr.info("欄位請勿空白");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0102/Update", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("修改成功");
      this.dataDetail = {
        Edu_No: '',
        Edu_Name: '',
        Edu_Short: '',
        Edu_Name_E: '',
        State_Log: '',
        MoeChar: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
      }
      this.search();
    }, error => this.toastr.error(error.error))
  }

  delete() {

    if (this.dataDetail.Edu_No == '' ||
      this.dataDetail.Edu_Name == '' ||
      this.dataDetail.Edu_Name_E == '' ||
      this.dataDetail.Edu_Short == '' ||
      this.dataDetail.State_Log == '') {
      this.toastr.info("請選擇資料");
      return;
    }

    Swal.fire({
      title: '確定刪除?',
      confirmButtonText: `確定刪除學制：${this.dataDetail.Edu_Name}?`,
      showCancelButton: true,
      cancelButtonText: '取消'
    }).then(result => {
      if (result.isConfirmed) {
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0102/Delete", { Edu_No: this.dataDetail.Edu_No }).subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          this.toastr.success("刪除成功");
          this.dataDetail = {
            Edu_No: '',
            Edu_Name: '',
            Edu_Short: '',
            Edu_Name_E: '',
            State_Log: '',
            MoeChar: '',
            Update_User: '',
            Update_Time: '',
            Selected: false
          }
          this.search();
        }, error => this.toastr.error(error.error))
      }
      else {
        this.toastr.info("已取消");
      }
    })
  }

  clear() {
    this.dataDetail = {
      Edu_No: '',
      Edu_Name: '',
      Edu_Short: '',
      Edu_Name_E: '',
      State_Log: '',
      MoeChar: '',
      Update_User: '',
      Update_Time: '',
      Selected: false
    }
  }

}
