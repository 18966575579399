import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { GetCUPTimeCenterList } from 'src/app/_Models/AD/ADCUP/ADCUP0602/getCUPTimeCenterList';
import { TimeCntr } from 'src/app/_Models/timeCntr';
import { environment } from 'src/assets/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TimeService {

    baseUrl = environment.apiUrl;
    private currentTimeSource = new ReplaySubject<GetCUPTimeCenterList[]>(1);
    currentTime$ = this.currentTimeSource.asObservable();
    constructor(private http: HttpClient) {
        this.getCUPTimeCenterList();
    }

    // 取得各項時間
    getTimeCntr() {
        return this.http.post<TimeCntr>(this.baseUrl + "Public/Get_TimeCntr", {});
    }


    // 取得各項時間設定清單
    getCUPTimeCenterList() {
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0602/Get_CUP_TimeCenter_List").subscribe((response) => {
            this.setTime(response);
        });
    }

    setTime(time: GetCUPTimeCenterList[]) {
        this.currentTimeSource.next(time);
    }
}
