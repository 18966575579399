import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { getYearScoreListData } from 'src/app/_Models/STDWEB/STDW30002/getYearScoreListData';
import { GetStdABS } from 'src/app/_Models/TCHWEB/TCH0113/getStdABS';
import { GetStdDetail } from 'src/app/_Models/TCHWEB/TCH0113/getStdDetail';
import { GetStdList } from 'src/app/_Models/TCHWEB/TCH0113/getStdList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0113',
    templateUrl: './TCH0113.component.html',
    styleUrls: ['./TCH0113.component.css']
})
export class TCH0113Component implements OnInit {

    baseUrl = environment.apiUrl
    modalRef!: BsModalRef;
    cosList!: GetSelectList[];
    stdList!: GetStdList[];
    stdDetail!: GetStdDetail[];
    stdABS!: GetStdABS[] | undefined;
    stdScore!: getYearScoreListData | undefined;
    constructor(private http: HttpClient, private toastr: ToastrService,
        private systemService: SystemService, private modalService: BsModalService, private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.getCosList({ Years: response.Now_Years, Term: response.Now_Term, OpClass: '' });
        }, error => this.toastr.error(error.error))
    }


    checkScore(score: string) {
        if (score.indexOf('*') != -1) {
            return true;
        }
        return false;
    }

    getCosList(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_13/List_Cos", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosList = response;
        }, error => this.toastr.error(error.error))
    }

    getStdList(dataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_13/List_Std", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdList = []
                return;
            }
            this.stdList = response;
            for (let index = 0; index < this.stdList.length; index++) {
                this.stdList[index].Selected = false;
            }
        }, error => this.toastr.error(error.error))
    }

    getStdDetail(dataKey: string, index: number) {

        for (let index = 0; index < this.stdList.length; index++) {
            this.stdList[index].Selected = false;
        }

        this.stdList[index].Selected = true;

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_13/List_StdData", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdDetail = response;
        }, error => this.toastr.error(error.error))
    }

    getStdInfo(type: string, dataKey: string, template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        if (type == '缺曠紀錄') {
            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_13/List_ABS", { DataKey: dataKey }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.stdScore = undefined;
                this.stdABS = response;
                this.modalRef = this.modalService.show(template, modalConfig);
            }, error => this.toastr.error(error.error))
        }
        else {
            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_13/List_Score", { DataKey: dataKey }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.stdABS = undefined;
                this.stdScore = response;
                this.modalRef = this.modalService.show(template, modalConfig);
            }, error => this.toastr.error(error.error))
        }
    }

}
