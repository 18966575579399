import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FileExchangeService {

    baseUrl = environment.apiUrl;
    fileExchange = new FileExchange();
    constructor(private http: HttpClient) { }

    ExportFile(apiUrl: string, model: any) {
        return this.http.post<any>(this.baseUrl + apiUrl, model).pipe(
            map((file: any) => {
                if (file.Info == false) {
                    return file;
                }
                let contentType = "application/octet-stream"
                let blob = this.fileExchange.Base64ToBlob(file.Base64, contentType, 512);
                return {
                    blob: blob,
                    FileName: file.FileName,
                }
            })
        );
    }

    ImportFile(apiUrl: string, model: any) {
        return this.http.post<any>(this.baseUrl + apiUrl, model);
    }

}
