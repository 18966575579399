import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexAxisChartSeries,
  ApexYAxis,
  ApexPlotOptions,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexTitleSubtitle,
  ApexXAxis,
  ChartComponent
} from "ng-apexcharts";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';


export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  datalabels: ApexDataLabels;
  grid: ApexGrid;
};

export type ChartOptions1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
};

export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
};

export type ChartOptions3 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-STDW30004',
  templateUrl: './STDW30004.component.html',
  styleUrls: ['./STDW30004.component.css']
})
export class STDW30004Component implements OnInit {

  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions!: Partial<any>;
  public chartOptions1!: Partial<any>;
  baseUrl = environment.apiUrl;
  modalRef!: BsModalRef;
  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private http: HttpClient, private systemService: SystemService, private translateService: TranslateService

  ) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 900,
        type: "pie"
      },
      labels: [],
      legend: {
        position: "bottom",
        fontSize: "12px",
        markers: {
          radius: 0
        },
        itemMargin: {
          horizontal: 50,
          vertical: 8
        }
      },
      title: {
        text: "八大核心能力之比重分配圖",
        align: "center",
        margin: 60,
        style: {
          fontSize: "30px"
        }
      },
      dataLabels: {
        formatter: function (val: number) {
          return val + "%";
        },
        style: {
          fontSize: "36px",
          fontWeight: "bold",
        },
      },
      grid: {
        padding: {
          top: 30
        }
      }
    };

    this.chartOptions1 = {
      series: [
        {
          name: "Series 1",
          data: []
        }
      ],
      chart: {
        height: 500,
        type: "radar"
      },
      title: {
        text: "",
        align: "center",
        margin: 60,
        style: {
          fontSize: "25px"
        }
      },
      xaxis: {
        categories: ["(1)", "(2)", "(3)", "(4)", "(5)", "(6)", "(7)", "(8)"],
        labels: {
          style: {
            colors: ["#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000"],
            fontSize: "20px",
            fontFamily: 'Arial'
          }
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 5,
        labels: {
          style: {
            fontSize: "17px",
            fontFamily: 'Arial',
          },
          formatter: function (val: number) {
            return val + "%";
          }
        }
      },
      plotOptions: {
        radar: {
          polygons: {
            fill: {
              colors: ["#f8f8f8", "#fff"]
            }
          }
        }
      }
    };
  }

  ngOnInit(): void {
    this.translateService.use(this.systemService.currentLanguage)
    this.http.get<any>(this.baseUrl + "ACAD/STDWEB/STDW300_04/Get_CurMap_List").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
    })


    this.chartOptions.series = [27, 8, 11, 11, 13, 11, 11, 8];
    this.chartOptions.labels = ["具備紮實專業本位之職場就業能力(1)", "具備廣泛資訊統合之實用創新能力(2)", "具備博雅人文涵養之服務關懷能力(3)", "具備得宜禮節儀態之誠心敬業態度(4)", "具備適應企業實務之經營管理能力(5)",
      "具備內化自律負責之團隊合作能力(6)", "具備良好中外語文之溝通表達能力(7)", "具備尊重多元文化之宏觀國際視野(8)"];
  }

  onSelect(data: TabDirective) {

    if (data.heading == "核心能力圓餅圖") {
      this.chartOptions.series = [27, 8, 11, 11, 13, 11, 11, 8];
      this.chartOptions.labels = ["具備紮實專業本位之職場就業能力(1)", "具備廣泛資訊統合之實用創新能力(2)", "具備博雅人文涵養之服務關懷能力(3)", "具備得宜禮節儀態之誠心敬業態度(4)", "具備適應企業實務之經營管理能力(5)",
        "具備內化自律負責之團隊合作能力(6)", "具備良好中外語文之溝通表達能力(7)", "具備尊重多元文化之宏觀國際視野(8)"];
    }

    if (data.heading == "大一雷達圖") {
      this.chartOptions1.series = [
        {
          name: "Series 1",
          data: [33, 33, 25, 25, 7, 25, 25, 22]
        }
      ];
      this.chartOptions1.title = {
        text: "校級核心能力與全人教育構面之比重分配(大一)",
        align: "center",
        margin: 60,
        style: {
          fontSize: "25px"
        }
      };
    }

    if (data.heading == "大二雷達圖") {
      this.chartOptions1.series = [
        {
          name: "Series 1",
          data: [66, 66, 50, 50, 21, 50, 50, 44]
        }
      ];
      this.chartOptions1.title = {
        text: "校級核心能力與全人教育構面之比重分配(大二)",
        align: "center",
        margin: 60,
        style: {
          fontSize: "25px"
        }
      };
    }

    if (data.heading == "大三雷達圖") {
      this.chartOptions1.series = [
        {
          name: "Series 1",
          data: [73, 66, 75, 75, 93, 75, 75, 78]
        }
      ];
      this.chartOptions1.title = {
        text: "校級核心能力與全人教育構面之比重分配(大三)",
        align: "center",
        margin: 60,
        style: {
          fontSize: "25px"
        }
      };
    }
  }

  openModal(template: TemplateRef<any>) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };
    this.modalRef = this.modalService.show(template, modalConfig);
  }

}
