<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" name="eduNo" #EduNo (change)="getDeptList(EduNo.value, false)"
                    [(ngModel)]="EduNoData.EduNo">
                    <option value="" disabled>--請選擇--</option>
                    <option value="{{item.Edu_No}}" *ngFor="let item of eduList">{{item.Edu_Name}}</option>

                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" name="deptNo" #DeptNo>
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <button class="other-Button-gray" (click)="getStdClassUp(Year.value, EduNo.value, DeptNo.value)">執行</button>
        </div>
    </div>
    <!-- <h4 class="text-danger fw-bolder">注意!</h4>
    <ul class="list-group list-group-flush">
        <li class="list-group-item text-danger fw-bolder">1. 請將所有學生之異動作業處理完畢(如：休學、復學、退學...等)。</li>
        <li class="list-group-item text-danger fw-bolder">2. 請確認所有班級之連貫性(如：一年乙班、二年乙班)。</li>
        <li class="list-group-item text-danger fw-bolder">3. 請個使用者暫停所有作業，並退出教務系統，待升級作業完成。</li>
        <li class="list-group-item text-danger fw-bolder">4. 執行升級作業之前，請由系統管理員先行備份資料，以避免突發狀況。</li>
    </ul> -->
</div>