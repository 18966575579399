<form class="form-main">
    <div class="row">
        <div class="col-md-3">
            <button type="button" id="Loginform" style="visibility:hidden;" class="other-Button-gray float-start me-3" (click)="openModal(Accounttemplate)">登入</button>
        </div>      
    </div>
</form>

<ng-template #Accounttemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">切換帳號</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <form class="form-signin">
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">身份</span>
                        <select class="form-select shadow-none bg-light" name="type" #id>
                            <option value="" selected disabled>--{{'請選擇身份' | translate}}--</option>
                            <option *ngFor="let item of type" value="{{item.ItemValue}}">
                                {{item.ItemName }}
                            </option>
                        </select>
                    </div>  
                </div>          
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">帳號</span>
                        <input type="text" class="form-control shadow-none bg-light" placeholder=請輸入帳號 name="userAccount"
                            autofocus #psd>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <button type="button" class="other-Button-gray float-start me-3" (click)="search(id.value,psd.value)">登入</button>
                    </div>
                </div>
                <div class="col-md-4"></div>       
            </div>
        </form>
    </div>
</ng-template>