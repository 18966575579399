import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetStdListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0201/getStdListInput';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSTDStatusList } from 'src/app/_Models/getSTDStatusList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';

@Component({
    selector: 'app-ADSTD-search-input',
    templateUrl: './ADSTD-search-input.component.html',
    styleUrls: ['./ADSTD-search-input.component.css']
})
export class ADSTDSearchInputComponent implements OnInit {
    @Output() childEvent = new EventEmitter<any>();
    deptList!: GetDeptList[];
    eduList!: GetEduList[];
    classList!: GetClassList[];
    teamList!: GetTeamList[];
    sTDStatusList!: GetSTDStatusList[];
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    getStdListInput: GetStdListInput = {
        EduNo: '',
        DeptNo: '',
        TeamNo: '',
        Grade: '',
        Class: '',
        ClassID: '',
        Status: '',
        IDNo: '',
        StdName: '',
        StdNoFrom: '',
        StdNoTo: ''
    }
    model = {
        Years: '',
        Term: ''
    }
    constructor(private toastr: ToastrService, private systemService: SystemService, private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
            this.getEduList();
            this.getSTDStatusList();
            this.childEvent.emit(this.getStdListInput);
        });
    }



    getEduList() {
        this.systemService.getEduList('', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getTeamList(dept: string) {
        this.systemService.getTeamList('', this.model.Years, this.model.Term, dept, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.teamList = response;
        });
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getSTDStatusList() {
        this.systemService.getSTDStatusList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sTDStatusList = response;
            for (let index = 0; index < this.sTDStatusList.length; index++) {
                this.sTDStatusList[index].Status_No = this.sTDStatusList[index].Status_No;
            }
        }, error => this.toastr.error(error.error))
    }

    GetData() {
        this.childEvent.emit(this.getStdListInput);
    }

}
