import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetTFSMakeDocWeakList } from 'src/app/_Models/AD/ADTFS/ADTFS0401/getTFSMakeDocWeakList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADTFS0401',
  templateUrl: './ADTFS0401.component.html',
  styleUrls: ['./ADTFS0401.component.css']
})
export class ADTFS0401Component implements OnInit {
  initialData = new InitialData(this.http);
  baseUrl = environment.apiUrl;
  Years!: Array<string>;
  makeDocList!: GetSelectList[];
  term = require('src/app/_Models/_SelectInput/term.json').term;
  fileExchange = new FileExchange();
  bsConfig = environment.bsDatePickerConfig;
  model = {
    Years: '',
    Term: '',
    StdNo: '',
    MakeDocNo: '',
    MakeDocStatus: ''
  }
  tFSMakeDocWeakList!: GetTFSMakeDocWeakList[];
  constructor(private toastr: ToastrService, private http: HttpClient, private systemService: SystemService, private fileExchangeServices: FileExchangeService) { }

  ngOnInit(): void {
    this.getYears();
    this.getMakeDocList();
  }


  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getMakeDocList() {
    this.systemService.getMakeDocList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.makeDocList = response;
    }, error => this.toastr.error(error.error))
  }

  exportWeakFileBase64() {
    this.http.get<any>(this.baseUrl + "ACAD/TFS/ADTFS0401/Export_WeakFile_Base64").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }

      let contentType = "application/octet-stream"
      let blob = this.fileExchange.Base64ToBlob(response.Base64, contentType, 512);

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = "弱勢助學金上傳範本.xls";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    })
  }

  importClick() {
    if (this.model.Years == '' || this.model.Term == '' || this.model.MakeDocNo == '') {
      this.toastr.info("請選擇學年學期及製單類別");
      return;
    }
    document.getElementById("import")?.click();
  }

  importWeakFileBase64($event: any, dataKey: string, type: string) {
    let file = $event.target.files[0];
    if (!file) return;
    this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type);
  }

  GetBase64(that: any, base64: string, file: any, dataKey: string) {
    let json = {
      Years: that.model.Years,
      Term: that.model.Term,
      MakeDocNo: that.model.MakeDocNo,
      UnitNo: '01',
      FileBase64: base64
    }
    Swal.fire({
      title: '確定上傳 ' + file.name + ' ?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: '是',
      denyButtonText: '否',
    }).then((result) => {
      if (result.isConfirmed) {
        that.fileExchangeServices.ImportFile('ACAD/TFS/ADTFS0401/Import_WeakFile_Base64', json)
          .subscribe((response: any) => {
            if (response.Info == true) { that.toastr.success("上傳成功!!"); }
            else { that.toastr.error(response.Message, "執行錯誤!"); }
          });
      }
      else if (result.isDenied) {
        document.getElementById("import")?.setAttribute('value', '');
        that.toastr.info("已取消上傳。");
      }
    });
  }

  getTFSMakeDocWeakList() {

    if (this.model.Years == '' || this.model.Term == '' || this.model.MakeDocNo == '') {
      this.toastr.info("請選擇學年學期及製單類別");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0401/Get_TFS_MakeDoc_WeakList", {
      DataKey: '',
      Years: this.model.Years,
      Term: this.model.Term,
      EduNo: '',
      DeptNo: '',
      Class: '',
      StdNo: this.model.StdNo,
      EntryID: '',
      MakeDocNo: this.model.MakeDocNo,
      MakeDocStatus: '',
      UnitNo: '01',
      RedClassNo: ''
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocWeakList = []
        return;
      }
      this.tFSMakeDocWeakList = response;
    })
  }

}
