<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year [(ngModel)]="model.Years">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term [(ngModel)]="model.Term">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">製單類別</span>
                </div>
                <select #MakeDocNo class="form-select shadow-none" [(ngModel)]="model.MakeDocNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of makeDocList">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學號</span>
                </div>
                <input type="text" class="form-control shadow-none" #StdNo [(ngModel)]="model.StdNo">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3" [hidden]="true">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">狀態</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected>--全部--</option>
                    <option value="0">申請中</option>
                    <option value="1">已收件</option>
                    <option value="2">合格</option>
                    <option value="3">不合格</option>
                    <option value="4">核定完成</option>
                    <option value="5">取消</option>
                </select>
            </div>
        </div>
        <div class="col-md-3" [hidden]="true">
            <input type="text" placeholder="申請日期區間" class="form-control pointer shadow-none" bsDaterangepicker
                [bsConfig]="bsConfig" readonly>
        </div>
        <div class="col-md-6">
            <button class="search-btn float-start me-3" (click)="getTFSMakeDocWeakList()">查詢</button>
            <button style="width: 110px;" class="other-Button-gray float-start me-3"
                (click)="exportWeakFileBase64()">下載匯入檔</button>
            <button class="other-Button-gray float-start me-3" (click)="importClick()">匯入清冊</button>
            <input type="file" style="display: none;" id="import" (change)="importWeakFileBase64($event, '', '')">
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th>班級</th>
                <th>學號</th>
                <th>姓名</th>
                <th>申請種類</th>
                <th>核定金額</th>
                <th>狀態</th>
                <th>備註</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tFSMakeDocWeakList">
                <td>{{item.ClassName}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.RedClassName}}</td>
                <td>{{item.Money}}</td>
                <td class="text-primary fw-bolder">{{item.StatusName}}</td>
                <td>{{item.Memo}}</td>
            </tr>
        </tbody>
    </table>
</div>