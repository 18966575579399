import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/assets/environments/environment';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0103/search';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUP0103',
    templateUrl: './ADCUP0103.component.html',
    styleUrls: ['./ADCUP0103.component.css']
})
export class ADCUP0103Component implements OnInit {
    baseUrl = environment.apiUrl;
    data!: Search[];
    dataDetail: Search = {
        ClassType: '',
        ClassType_Name: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
    }

    readonly!: boolean;

    ClassType: string = '';

    constructor(private http: HttpClient, private toastr: ToastrService) {

    }

    ngOnInit(): void {
        this.readonly = false;
        this.search();
    }

    search(): void {
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0103/Search").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response;
            this.clear();
        })
    }

    searchDetail(index: number): void {
        this.readonly = true;
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.dataDetail.ClassType = this.data[index].ClassType;
        this.dataDetail.ClassType_Name = this.data[index].ClassType_Name;
        this.dataDetail.Update_Time = this.data[index].Update_Time;
        this.dataDetail.Update_User = this.data[index].Update_User;
    }

    insert(): void {
        if (this.dataDetail.ClassType == '' || this.dataDetail.ClassType_Name == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0103/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search();
        }, error => this.toastr.error(error.error));
    }

    update(): void {
        if (this.dataDetail.ClassType == '' || this.dataDetail.ClassType_Name == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0103/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.search();
        }, error => this.toastr.error(error.error))
    }

    delete() {
        if (this.dataDetail.ClassType == '') {
            this.toastr.info("請選擇教室型態");
            return;
        }

        Swal.fire({
            title: '確認是否刪除?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: '是',
            denyButtonText: '否'
        }).then((result) => {
            if (result.isDenied) { return; }

            this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0103/Delete", this.dataDetail).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("刪除成功");
                this.search();
                this.clear();
            }, error => this.toastr.error(error.error))

        });

    }

    clear() {
        this.readonly = false;
        this.dataDetail = {
            ClassType: '',
            ClassType_Name: '',
            Update_User: '',
            Update_Time: '',
            Selected: false
        }

        this.data.forEach(element => {
            element.Selected = false;
        });
    }

}
