<div class="container">
    <div class="row">
        <div style="width:600px;">
            <form [formGroup]="ngFormInput">
                <div class="row">
                    <div style="width:285px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'學年'|translate}}</span>
                            <select class="form-select shadow-none" #Year formControlName="Years"
                                (change)="getClassList(Year.value, Term.value,'1');">
                                <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor="let item of Years" [value]="item">{{item}}{{'學年'|translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div style="width:285px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'學期'|translate}}</span>
                            <select class="form-select shadow-none" #Term formControlName="Term"
                                (change)="getClassList(Year.value, Term.value,'1');">
                                <option value="" disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                            </select>
                        </div>
                    </div>                
                </div>
                <div class="row">
                    <div style="width:285px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'開課班級'|translate}}</span>
                            <select class="form-select shadow-none" #OpClass formControlName="OpClass"
                                (change)="getCosList(Year.value, Term.value,OpClass.value,'1');">
                                <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor="let item of ClassList" value="{{item.Class}}">{{item.Class_Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div style="width:285px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'科目'|translate}}</span>
                            <select class="form-select shadow-none" #CosData formControlName="CosData">
                                <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor="let item of CosList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                            </select>
                        </div>
                    </div>                                  
                </div>
                <div class="row">
                    <div style="width:580px;margin:0 auto;height:50px;vertical-align:middle;">
                        <div>
                            <button class="search-btn float-start me-3" (click)="getStdList(Year.value, Term.value,CosData.value,'1');">{{'查詢'|translate}}</button>
                        </div>
                        <div>
                            <button class="other-Button-gray" (click)="getSELSetDefaultProcess(true);">{{'加入'|translate}}</button>
                        </div>                    
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="row">
                    <table class="table table-hover table-striped text-center" style="width:560px;">
                        <thead class="thead-dark-red">
                            <tr>
                                <th style="width: 80px;text-align:center;">
                                    <input type="checkbox" class="form-check-input shadow-none pointer" (click)="setAll($event.target,'1');" />{{'全選'|translate}}
                                </th>
                                <th style="width: 280px;">{{'學號姓名'|translate}}</th>
                                <th style="width: 240px;">{{'班級'|translate}}</th>
                            </tr>   
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of SELSelChooseOpClassSelList">
                                <td style="width: 80px;text-align:center;">
                                    <input type="checkbox" class="form-check-input shadow-none pointer" [(ngModel)]="item.Checked" />
                                </td>
                                <td style="width: 280px;">{{item.StdName}}</td>
                                <td style="width: 240px;">{{item.ClassName}}</td>                            
                            </tr>  
                        </tbody>                      
                    </table>
                </div>
            </div>
        </div>
        <div style="width:20px;margin:0 auto;">
        </div>
        <div style="width:600px;">
            <form [formGroup]="ngFormInput">
                <div class="row">
                    <div style="width:285px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'學年'|translate}}</span>
                            <select class="form-select shadow-none" #YearTo formControlName="YearsTo"
                                (change)="getClassList(YearTo.value, TermTo.value,'2');">
                                <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor="let item of Years" [value]="item">{{item}}{{'學年'|translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div style="width:285px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'學期'|translate}}</span>
                            <select class="form-select shadow-none" #TermTo formControlName="TermTo"
                                (change)="getClassList(YearTo.value, TermTo.value,'2');">
                                <option value="" disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                            </select>
                        </div>
                    </div>                
                </div>
                <div class="row">
                    <div style="width:285px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'開課班級'|translate}}</span>
                            <select class="form-select shadow-none" #OpClassTo formControlName="OpClassTo"
                                (change)="getCosList(YearTo.value, TermTo.value,OpClassTo.value,'2');">
                                <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor="let item of ClassListTo" value="{{item.Class}}">{{item.Class_Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div style="width:285px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{'科目'|translate}}</span>
                            <select class="form-select shadow-none" #CosDataTo formControlName="CosDataTo">
                                <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                                <option *ngFor="let item of CosListTo" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                            </select>
                        </div>
                    </div>                                  
                </div>
                <div class="row">
                    <div style="width:580px;margin:0 auto;height:50px;vertical-align:middle;">
                        <div>
                            <button class="search-btn float-start me-3" (click)="getStdList(YearTo.value, TermTo.value,CosDataTo.value,'2');">{{'查詢'|translate}}</button>
                        </div>
                        <div>
                            <button class="other-Button-gray" (click)="getSELSetDefaultProcess(false);">{{'移除'|translate}}</button>
                        </div>                    
                    </div>
                </div>
            </form>           
            <div class="row">
                <div class="row">
                    <table class="table table-hover table-striped text-center" style="width:560px;">
                        <thead class="thead-dark-red">
                            <tr>
                                <th style="width: 80px;text-align:center;">
                                    <input type="checkbox" class="form-check-input shadow-none pointer" (click)="setAll($event.target,'2');" />{{'全選'|translate}}
                                </th>
                                <th style="width: 280px;">{{'學號姓名'|translate}}</th>
                                <th style="width: 240px;">{{'班級'|translate}}</th>
                            </tr>   
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of SELSelChooseOpClassSelListTo">
                                <td style="width: 80px;text-align:center;">
                                    <input type="checkbox" class="form-check-input shadow-none pointer" [(ngModel)]="item.Checked" />
                                </td>
                                <td style="width: 280px;">{{item.StdName}}</td>
                                <td style="width: 240px;">{{item.ClassName}}</td>                            
                            </tr>  
                        </tbody>  
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>