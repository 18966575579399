import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetOpClassList } from 'src/app/_Models/getOpClassList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetTchFileList } from 'src/app/_Models/TCHWEB/TCH0301/getTchFileList';
import { GetHomeworkDetail } from 'src/app/_Models/TCHWEB/TCH0304/getHomeworkDetail';
import { GetHomeworkList } from 'src/app/_Models/TCHWEB/TCH0304/getHomeworkList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0304',
    templateUrl: './TCH0304.component.html',
    styleUrls: ['./TCH0304.component.css']
})
export class TCH0304Component implements OnInit {
    opClassList!: GetSelectList[] | undefined;
    cosList!: GetSelectList[] | undefined;
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    homeworkList!: GetHomeworkList[] | undefined;
    tchFileList!: GetTchFileList[];
    fileHidden = true;
    homeworkDetail!: GetHomeworkDetail;
    getOpClassListInput: GetOpClassList = {
        Years: '',
        Term: '',
        DeptNo: '',
        TeamNo: '',
        TchNo: '',
        Classroom: '',
        UserCode: '',
        CHeckRight: ''
    };
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private accountService: AccountService,
        private modalService: BsModalService,
        private datePipe: DatePipe,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.getOpClassListInput.TchNo = response.Code;
            }
        }, error => this.toastr.error(error.error))

        this.timeService.getTimeCntr().subscribe((response) => {
            this.getOpClassListInput.Years = response.Now_Years;
            this.getOpClassListInput.Term = response.Now_Term;
            this.getOpClassList(this.getOpClassListInput)
        }, error => this.toastr.error(error.error))
    }

    setModel(model: any) {
        this.getOpClassListInput.Years = model.Years;
        this.getOpClassListInput.Term = model.Term;
        this.opClassList = undefined;
        this.cosList = undefined;
        this.getOpClassList(this.getOpClassListInput);
    }

    getOpClassList(model: GetOpClassList) {
        this.systemService.getOpClassList(model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.opClassList = response;
            this.getCosList(response[0].ItemValue);
        }, error => this.toastr.error(error.error));
    }

    getCosList(opClass: string) {
        this.http.post<any>(this.baseUrl + "Public/List_Cos", {
            Years: this.getOpClassListInput.Years,
            Term: this.getOpClassListInput.Term,
            OpClass: opClass
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosList = response;
        }, error => this.toastr.error(error.error));
    }

    getHomeworkList(dataKey: string) {
        if (dataKey == '') {
            this.toastr.info("請選擇科目");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_04/Get_HomeWork_List", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.homeworkList = undefined;
                return;
            }
            this.homeworkList = response;
        })
    }

    openModal(template: TemplateRef<any>, dataKey: string) {

        if (dataKey == '') {
            this.toastr.info("請選擇科目");
            return;
        }

        // 初始化
        this.homeworkDetail = {
            DataKey: dataKey,
            Years: this.getOpClassListInput.Years,
            Term: this.getOpClassListInput.Term,
            Op_Class: '',
            Serial: '',
            Homework_No: '',
            Homework_Type: '0',
            Homework_Name: '',
            Start_Date: '',
            End_Date: '',
            Usual_Mark: 'N',
            File_No: '',
            Tch_No: '',
            File_Name: '',
            Update_User: '',
            Update_Time: ''
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_01/Get_TchFile_List", {
            Type_No: 'B',
            FileName: ''
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.tchFileList = response;
            this.modalRef = this.modalService.show(template, modalConfig);
        }, error => this.toastr.error(error.error))
    }

    saveData(dataKey: string) {

        if (this.homeworkDetail.End_Date < this.homeworkDetail.Start_Date) {
            this.toastr.warning("截止日需大於起始日");
            return;
        }

        this.homeworkDetail.Start_Date = this.datePipe.transform(this.homeworkDetail.Start_Date, "yyyy-MM-dd hh:mm:ss") || '';
        this.homeworkDetail.End_Date = this.datePipe.transform(this.homeworkDetail.End_Date, "yyyy-MM-dd hh:mm:ss") || '';
        this.homeworkDetail.Homework_Name = this.homeworkDetail.Homework_Name;
        this.homeworkDetail.Update_User = this.homeworkDetail.Update_User;
        this.homeworkDetail.Update_Time = this.homeworkDetail.Update_Time;

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_04/Save_HomeWork", this.homeworkDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_04/Get_HomeWork_List", { DataKey: dataKey }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.homeworkList = response;
                this.toastr.success("儲存成功");
                this.modalRef.hide();
            }, error => this.toastr.error(error.error))
        }, error => this.toastr.error(error.error))
    }

    getHomeworkDetail(dataKey: string, template: TemplateRef<any>) {

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_04/Get_HomeWork_Detail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.homeworkDetail = response;
            this.homeworkDetail.Homework_Name = response.Homework_Name;
            this.homeworkDetail.Start_Date = response.Start_Date.replace(/\s+/g, "T");
            this.homeworkDetail.End_Date = response.End_Date.replace(/\s+/g, "T");
            this.homeworkDetail.Update_User = response.Update_User;
            this.homeworkDetail.Update_Time = response.Update_Time;

            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_01/Get_TchFile_List", {
                Type_No: 'B',
                FileName: ''
            }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.tchFileList = response;
                this.modalRef = this.modalService.show(template, modalConfig);
            }, error => this.toastr.error(error.error))
        }, error => this.toastr.error(error.error));
    }

    setHidden() {
        this.fileHidden = false;
    }

    setFile(fileNo: string, fileName: string) {
        this.fileHidden = true;
        this.homeworkDetail.File_No = fileNo;
        this.homeworkDetail.File_Name = fileName;
    }

    deleteData(dataKey: string, cosValue: string) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH03_04/Delete_HomeWork", { DataKey: dataKey }).subscribe((response) => {
            if (response == false) {
                this.toastr.warning(response.Warning);
                return;
            }
            this.toastr.success("刪除成功");
            this.getHomeworkList(cosValue);
        }, error => this.toastr.error(error.error))
    }

}
