<div class="container">
    <div class="row">
        <div class="input-group flex-nowrap mb-3">
            <span class="input-group-text">學系/體育室審查作業時間：{{model.Years}}學年第{{model.Term}}學期由{{model.DeptfromDate}}&nbsp;{{model.DeptfromTime}}至{{model.DepttoDate}}&nbsp;{{model.DepttoTime}}。</span>
        </div>
    </div>
    <div class="row">
        <div style="width:500px;">
            <form [formGroup]="ngFormInput">
                <div class="row">
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">學年</span>
                            <select class="form-select shadow-none" #Year formControlName="Years" [(ngModel)]="model.Years">
                                <option value="" selected disabled>--請選擇--</option>
                                <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                            </select>
                        </div>
                    </div>
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">學期</span>
                            <select class="form-select shadow-none" #Term formControlName="Term" [(ngModel)]="model.Term">
                                <option value="" disabled>--請選擇--</option>
                                <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                            </select>
                        </div>
                    </div>                
                </div>
                <div class="row">
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">系所</span>
                            <select class="form-select shadow-none" #DeptNo formControlName="DeptNo"
                                (change)="getClassList('',DeptNo.value);getTeamList(DeptNo.value);">
                                <option value="" selected>--請選擇--</option>
                                <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">組別</span>
                            <select class="form-select shadow-none" #TeamNo formControlName="TeamNo">
                                <option value="" selected>--請選擇--</option>
                                <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}</option>
                            </select>
                        </div>
                    </div>                       
                </div>  
                <div class="row">                 
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">班級</span>
                            <select class="form-select shadow-none" #Class formControlName="Class">
                                <option value="" selected>--請選擇--</option>
                                <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">學號</span>
                            <input type="text" #StdNoFrom formControlName="StdNoFrom" maxlength="10" style="width:170px;">
                        </div>
                    </div>                      
                </div>                             
                <div class="row">
                    <div style="width:250px;">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">狀態</span>
                            <select class="form-select shadow-none" #Status formControlName="Status">
                                <option *ngFor="let item of StatusList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                            </select>
                        </div>                         
                    </div>                         
                    <div style="width:250px;">
                        <table style="width:100%;height:30px;">
                            <tr>
                                <td style="text-align:center;vertical-align: middle;">
                                    <button class="search-btn" (click)="getStdList();">查詢</button>
                                </td>
                                <td style="text-align:center;vertical-align: middle;">
                                    <ng-container *ngIf="thisUserType=='Dept'">
                                        <button class="other-Button-gray" (click)="doCheck();">審查</button>
                                    </ng-container>                                    
                                </td>
                            </tr>
                        </table>               
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="row" style="height:340px;overflow-y:scroll;">
                    <table class="table table-hover table-striped text-center" style="width:500px;height:30px;font-size:14px;">
                        <thead class="thead-dark-red">
                            <tr>
                                <th style="width: 70px;">
                                    <input type="checkbox" class="form-check-input shadow-none pointer"
                                    (click)="setAll($event.target)">&ensp;全選
                                </th>
                                <th style="width: 100px;">學制</th>
                                <th style="width: 160px;">班級</th>
                                <th style="width: 170px;">學號姓名</th>
                            </tr>   
                        </thead>
                        <tbody>
                            <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" *ngFor="let item of stdList; let i = index" (click)="getContrastResultData(item,i);" class="pointer">
                                <td style="width: 70px;">
                                    <input type="checkbox" 
                                    *ngIf="(item.Status=='1' || item.Status=='B') && thisUserType!='PE' && thisUserType!='PEMA'"
                                        class="form-check-input shadow-none pointer"
                                        (click)="setCheck(i,$event.target)">
                                </td>                                
                                <td style="width: 100px;">{{item.EduName}}</td>
                                <td style="width: 160px;">{{item.ClassName}}</td>
                                <td style="width: 170px;">{{item.StdNo}}{{item.StdName}}</td>                        
                            </tr>   
                        </tbody>                                      
                    </table>
                </div>
            </div>
        </div>
        <div style="width:780px;">
            <app-Contrast id="Contrast" *ngFor="let item of stdSelects" [inputstd]="item.DataKey" [stdSelect]="stdSelect" [UserType]="UserType" [thisUserType]="thisUserType" [EnableConfirm]="true"></app-Contrast>
        </div>
    </div>
</div>