import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { getYearScoreList } from 'src/app/_Models/STDWEB/STDW30002/getYearScoreList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW30001',
    templateUrl: './STDW30001.component.html',
    styleUrls: ['./STDW30001.component.css']
})
export class STDW30001Component implements OnInit {

    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    data: getYearScoreList[] = [];
    initialData = new InitialData(this.http);
    model = {
        Years: '',
        Term: ''
    }
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private timeService: TimeService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
            this.getTermScoreList(this.model);
        });
    }

    checkScore(score: string) {
        if (score.indexOf('*') != -1) {
            return true;
        }
        return false;
    }

    getTermScoreList(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW300_01/Get_TermScore_List", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = [];
            }
            else {
                this.data = response;
            }
        })
    }
}
