import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { GetTFSMakeDocSIStdList } from 'src/app/_Models/AD/ADTFS/ADTFS01D02/getTFSMakeDocSIStdList';
import { GetTFSMakeDocSIDetail } from 'src/app/_Models/AD/ADTFS/ADTFS01D02/getTFSMakeDocSIDetail';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
@Component({
  selector: 'app-ADTFS01D02',
  templateUrl: './ADTFS01D02.component.html',
  styleUrls: ['./ADTFS01D02.component.css']
})
export class ADTFS01D02Component implements OnInit {
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  baseUrl = environment.apiUrl;
  initialData = new InitialData(this.http);
  makeDocList!: GetSelectList[];
  tFSMakeDocSIStdList!: GetTFSMakeDocSIStdList[];
  tFSMakeDocSIDetail!: GetTFSMakeDocSIDetail[] | undefined;
  model = {
    Years: '',
    Term: '',
    MakeDocNo: '',
  }
  checkType = new CheckType();
  constructor(private toastr: ToastrService,
    private systemService: SystemService,
    private http: HttpClient,
    private fileExchangeServices: FileExchangeService) { }

  ngOnInit(): void {
    this.getYears();
    this.getMakeDocList();
  }



  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getMakeDocList() {
    this.systemService.getMakeDocList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.makeDocList = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocSIStdList() {
    if (this.model.Years == '' || this.model.Term == '' || this.model.MakeDocNo == '') {
      this.toastr.info("請選擇學年學期及製單類別");
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D02/Get_TFS_MakeDoc_SI_StdList", {
      DataKey: '',
      Years: this.model.Years,
      Term: this.model.Term,
      EduNo: '',
      DeptNo: '',
      Class: '',
      StdNo: '',
      EntryID: '',
      MakeDocNo: this.model.MakeDocNo,
      MakeDocStatus: '',
      UnitNo: '01'
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocSIStdList = []
        return;
      }
      this.tFSMakeDocSIStdList = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocSIDetail(index: number, dataKey: string) {

    this.tFSMakeDocSIStdList.forEach(element => {
      element.Selected = false;
    })
    this.tFSMakeDocSIStdList[index].Selected = true;

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D02/Get_TFS_MakeDoc_SIDetail", { DataKey: dataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocSIDetail = undefined;
        return;
      }
      this.tFSMakeDocSIDetail = response;
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocSISave() {

    if (!this.tFSMakeDocSIDetail) return;
    let pass = true;
    for (let index = 0; index < this.tFSMakeDocSIDetail.length; index++) {
      if (this.checkType.CheckIsNumber(this.tFSMakeDocSIDetail[index].MustMoney)) {
        this.toastr.warning(`第${index + 1}項非數字`);
        pass = false;
        break;
      }
    }

    if (pass) {
      this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D02/Get_TFS_MakeDoc_SISave", this.tFSMakeDocSIDetail).subscribe((response) => {
        if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
        }
        this.toastr.success("儲存成功");
      })
    }
  }

  getTFSMakeDocSIMakeDoc() {
    if (this.model.Years == '' || this.model.Term == '' || this.model.MakeDocNo == '') {
      this.toastr.info("請選擇學年學期及製單類別");
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D02/Get_TFS_MakeDoc_SIMakeDoc", {
      DataKey: '',
      Years: this.model.Years,
      Term: this.model.Term,
      EduNo: '',
      DeptNo: '',
      Class: '',
      StdNo: '',
      EntryID: '',
      MakeDocNo: this.model.MakeDocNo,
      MakeDocStatus: '',
      UnitNo: '01'
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("製作完成");
    })
  }

  getTFSMakeDocSISend() {
    if (this.model.Years == '' || this.model.Term == '' || this.model.MakeDocNo == '') {
      this.toastr.info("請選擇學年學期及製單類別");
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01D02/Get_TFS_MakeDoc_SISend", {
      DataKey: '',
      Years: this.model.Years,
      Term: this.model.Term,
      EduNo: '',
      DeptNo: '',
      Class: '',
      StdNo: '',
      EntryID: '',
      MakeDocNo: this.model.MakeDocNo,
      MakeDocStatus: '',
      UnitNo: '01'
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("送繳成功");
    })
  }

  getTFSMakeDocReportBase64(Years: string, Term: string, MakeDocNo: string) {

    if (Years == '' || Term == '' || MakeDocNo == '') {
      this.toastr.info("請選擇學年學期及製單類別");
      return;
    }

    this.fileExchangeServices.ExportFile("Public/Get_TFS_MakeDoc_Report_Base64", {
      DataKey: '',
      Years: Years,
      Term: Term,
      EduNo: '',
      DeptNo: '',
      Class: '',
      StdNo: '',
      EntryID: '',
      MakeDocNo: MakeDocNo,
      MakeDocStatus: '',
      UnitNo: '01'
    })
      .subscribe((response) => {
        let url = window.URL.createObjectURL(response.blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = '繳交清冊.xls';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => this.toastr.error(error.error));
  }

}
