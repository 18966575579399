import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CurTimeList } from 'src/app/_Models/curTimeList';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0109',
    templateUrl: './TCH0109.component.html',
    styleUrls: ['./TCH0109.component.css']
})
export class TCH0109Component implements OnInit {

    baseUrl = environment.apiUrl;
    data!: Get_Cur_List[];
    functionType = "tchOfficeHour";
    code!: string;
    selectedWeek: string = '';
    curTime: string = '';
    curTimeList!: CurTimeList[];
    model = {
        Years: '',
        Term: ''
    }
    room: string = '';
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private accountService: AccountService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.code = response.Code;
            }
        });

        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years,
                this.model.Term = response.Now_Term
            this.getCurList(this.model);
        });

        this.http.get(this.baseUrl + "Public/Get_Section_List").subscribe((response: any) => {
            if (response.Info == false) {
                this.toastr.error(response.Message);
                return;
            }
            this.curTimeList = response;
        }, error => this.toastr.error(error.error));
    }

    addOfficeHour() {
        if (this.curTime == '' || this.model.Years == '' || this.model.Term == '' || this.room == '') {
            this.toastr.warning("請確認資料完整性");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_09/Get_OH_Set", {
            Years: this.model.Years,
            Term: this.model.Term,
            Section: this.selectedWeek + this.curTime,
            Room: this.room
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.getCurList(this.model);
        }, error => this.toastr.error(error.error));
    }


    getCurList(model: any) {
        this.model.Years = model.Years;
        this.model.Term = model.Term;
        model.WeekIndex = null;
        model.KeyValue = this.code;
        model.FormType = '07';
        this.http.post<Get_Cur_List[]>(this.baseUrl + "Public/Get_CurForm", model)
            .subscribe((response) => {
                this.data = response;
            },
                error => this.toastr.error(error.error)
            );
    }

    resfresh() {
        this.getCurList(this.model);
    }

}
