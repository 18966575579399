import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCUPPermanentList } from 'src/app/_Models/AD/ADCUP/ADCUP0401/getCUPPermanentList';
import { GetCosData,GetCreditsListData } from 'src/app/_Models/AD/ADCUP/ADCUP0501/getCosData';
import { GetCUPCurPlanAddNewInput } from 'src/app/_Models/AD/ADCUP/ADCUP0501/getCUPCurPlanAddNewInput';
import { GetCUPCurPlanCopyToInput } from 'src/app/_Models/AD/ADCUP/ADCUP0501/getCUPCurPlanCopyToInput';
import { GetCUPCurPlanDetail } from 'src/app/_Models/AD/ADCUP/ADCUP0501/getCUPCurPlanDetail';
import { GetCUPCurPlanDetailInput } from 'src/app/_Models/AD/ADCUP/ADCUP0501/getCUPCurPlanDetailInput';
import { GetCUPCurPlanModifyInput } from 'src/app/_Models/AD/ADCUP/ADCUP0501/getCUPCurPlanModifyInput';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-adcup0501',
    templateUrl: './adcup0501.component.html',
    styleUrls: ['./adcup0501.component.css']
})
export class ADCUP0501Component implements OnInit {
    dataKey = '';
    modalRef!: BsModalRef;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    group = require('src/app/_Models/_SelectInput/group.json').group;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    Years!: Array<string>;
    deptList!: GetDeptList[];
    newDeptList!: GetDeptList[];
    copyDeptList!: GetDeptList[];
    targetDeptList!: GetDeptList[];
    eduList!: GetEduList[];
    newEduList!: GetEduList[];
    copyEduList!: GetEduList[];
    targetEduList!: GetEduList[];
    teamList!: GetTeamList[];
    newNeamList!: GetTeamList[];
    copyTeamList!: GetTeamList[];
    targetTeamList!: GetTeamList[];
    cUPPermanentList!: GetCUPPermanentList[];
    gradCreditsList!:GetCreditsListData[];
    cosData = new GetCosData();
    cUPCurPlanDetailInput = new GetCUPCurPlanDetailInput();
    cUPCurPlanDetail?: GetCUPCurPlanDetail[];
    dataKeyArray: Array<string> = [];
    cUPCurPlanAddNewInput = new GetCUPCurPlanAddNewInput();
    cUPCurPlanModifyInput = new GetCUPCurPlanModifyInput();
    cUPCurPlanCopyToInput = new GetCUPCurPlanCopyToInput();

    ngFormInput = new UntypedFormGroup({
        DeptNo: new UntypedFormControl(this.cUPCurPlanDetailInput.DeptNo, [Validators.required]),
        TeamNo: new UntypedFormControl(this.cUPCurPlanDetailInput.TeamNo, [Validators.required]),
        Years: new UntypedFormControl(this.cUPCurPlanDetailInput.Years, [Validators.required]),
    });

    ngFormNewInput = new UntypedFormGroup({
        EduNo: new UntypedFormControl(this.cosData.EduNo),
        DeptNo: new UntypedFormControl(this.cosData.DeptNo),
        Status: new UntypedFormControl(this.cosData.Status),
        KeyWord: new UntypedFormControl(this.cosData.KeyWord),
        SelKind: new UntypedFormControl(this.cosData.SelKind),
        OrderBy: new UntypedFormControl(this.cosData.OrderBy),
        FiledsNo: new UntypedFormControl(this.cosData.FiledsNo),
    });

    ngFormNew = new UntypedFormGroup({
        Years: new UntypedFormControl({ value: this.cUPCurPlanDetailInput.Years, disabled: true }, [Validators.required]),
        DeptNo: new UntypedFormControl({ value: this.cUPCurPlanDetailInput.DeptNo, disabled: true }, [Validators.required]),
        TeamNo: new UntypedFormControl({ value: this.cUPCurPlanDetailInput.TeamNo, disabled: true }, [Validators.required]),
        Grade: new UntypedFormControl(this.cUPCurPlanAddNewInput.Grade),
        Term: new UntypedFormControl(this.cUPCurPlanAddNewInput.Term),
        CosList: new UntypedFormControl(this.cUPCurPlanAddNewInput.CosList, [Validators.required]),
        CosCluster: new UntypedFormControl(this.cUPCurPlanAddNewInput.CosCluster),
        MustPassNum: new UntypedFormControl(this.cUPCurPlanAddNewInput.MustPassNum),
    });

    ngFormEdit = new UntypedFormGroup({
        DataKey: new UntypedFormControl(this.cUPCurPlanModifyInput.DataKey),
        PlanYear: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.PlanYear, disabled: true }),
        PlanTerm: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.PlanTerm, disabled: true }),
        CosID: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.CosID, disabled: true }, [Validators.required]),
        CosName: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.CosName, disabled: true }),
        SelKindName: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.SelKindName, disabled: true }),
        Credits: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.Credits, disabled: true }),
        CosHours: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.CosHours}, [Validators.required]),
        CosTerm: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.CosTerm, disabled: true }, [Validators.required]),
        Grade: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.Grade, disabled: true }, [Validators.required]),
        SelStyle: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.SelStyle, disabled: true }),
        CurClass: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.CurClass, disabled: true }),
        CosCluster: new UntypedFormControl(this.cUPCurPlanModifyInput.CosCluster),
        MustPassNum: new UntypedFormControl(this.cUPCurPlanModifyInput.MustPassNum),
        SelOpenLog: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.SelOpenLog}),
        UpdateUser: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.UpdateUser, disabled: true }),
        UpdateTime: new UntypedFormControl({ value: this.cUPCurPlanModifyInput.UpdateTime, disabled: true }),
        Selected: new UntypedFormControl(this.cUPCurPlanModifyInput.Selected),
    });

    ngFormCopy = new UntypedFormGroup({
        Years: new UntypedFormControl(this.cUPCurPlanCopyToInput.Years, [Validators.required]),
        DeptNo: new UntypedFormControl(this.cUPCurPlanCopyToInput.DeptNo, [Validators.required]),
        TeamNo: new UntypedFormControl(this.cUPCurPlanCopyToInput.TeamNo, [Validators.required]),
        TargetYears: new UntypedFormControl(this.cUPCurPlanCopyToInput.TargetYears, [Validators.required]),
        TargetDeptNo: new UntypedFormControl(this.cUPCurPlanCopyToInput.TargetDeptNo, [Validators.required]),
        TargetTeamNo: new UntypedFormControl(this.cUPCurPlanCopyToInput.TargetTeamNo, [Validators.required]),
    })

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private modalService: BsModalService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;
            this.newEduList = response;
            this.copyEduList = response;
            this.targetEduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, isNew: boolean, isCopy: boolean, isTarget: boolean) {
        this.systemService.getDeptList(edu, '', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (isNew) {
                this.newDeptList = response;
            }
            else if (isCopy) {
                if (isTarget) {
                    this.targetDeptList = response
                }
                else {
                    this.copyDeptList = response;
                }
            }
            else {
                this.deptList = response;
            }
        }, error => this.toastr.error(error.error));
    }

    getTeamList(dept: string, isNew: boolean, isCopy: boolean, isTarget: boolean) {
        this.systemService.getTeamList('', '', '', dept, '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (isNew) {
                this.newNeamList = response;
            }
            else if (isCopy) {

                if (isTarget) {
                    this.targetTeamList = response
                }
                else {
                    this.copyTeamList = response;
                }
            }
            else {
                this.teamList = response;
            }
        });
    }

    getCUPCurPlanDetail() {
        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇學年及組別");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0501/Get_CUP_CurPlan_Detail", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cUPCurPlanDetail = undefined;
                return;
            }
            this.cUPCurPlanDetail = response;
            this.cUPCurPlanDetail?.forEach(element => {
                element.Term1CosList.forEach(element => {
                    element.Selected = false;
                });
                element.Term2CosList.forEach(element => {
                    element.Selected = false;
                });
            });
        })
    }

    chooseData(dataKey: string, mainIndex: number, index: number, term: number) {
        this.cUPCurPlanDetail?.forEach(element => {
            element.Term1CosList.forEach(element => {
                element.Selected = false;
            });
            element.Term2CosList.forEach(element => {
                element.Selected = false;
            });
        });

        if (term == 1 && this.cUPCurPlanDetail) {
            this.cUPCurPlanDetail[mainIndex].Term1CosList[index].Selected = true;
            this.ngFormEdit.setValue(this.cUPCurPlanDetail[mainIndex].Term1CosList[index]);
        } else if (term == 2 && this.cUPCurPlanDetail) {
            this.cUPCurPlanDetail[mainIndex].Term2CosList[index].Selected = true;
            this.ngFormEdit.setValue(this.cUPCurPlanDetail[mainIndex].Term2CosList[index]);
        } else {
            this.toastr.error("系統出錯");
            return;
        }

        this.dataKey = dataKey;
    }

    getCUPCurPlanDelete() {
        if (this.dataKey == '') {
            this.toastr.info("請選擇欲刪除的資料");
            return;
        }

        console.log(this.dataKey);

        Swal.fire({
            title: "確定要刪除此筆資料？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0501/Get_CUP_CurPlan_Delete", { DataKey: this.dataKey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.dataKey == '';
                    this.toastr.success("刪除成功");
                    this.getCUPCurPlanDetail();
                })
            }
            else {
                this.toastr.info("已取消動作");
                this.dataKey = '';
                this.cUPCurPlanDetail?.forEach(element => {
                    element.Term1CosList.forEach(element => {
                        element.Selected = false;
                    });
                    element.Term2CosList.forEach(element => {
                        element.Selected = false;
                    });
                });
            }
        });
    }

    getCUPCurPlanDeleteAll() {
        Swal.fire({
            title: "確定要刪除所有年級的資料？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0501/Get_CUP_CurPlan_DeleteAll", { DataKey: this.cUPCurPlanDetail ? this.cUPCurPlanDetail[0].DataKey : '' }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.getCUPCurPlanDetail();
                })
            }
        });

    }

    getCosData() {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0401/Get_CUP_Permanent_List", this.ngFormNewInput.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUPPermanentList = response;
            this.cUPPermanentList.forEach(element => {
                element.Selected = false;
            });
        })
    }

    openModal(type: string, template: TemplateRef<any>) {
        let sClass='modal-xl';

        switch(type){
            case 'edit':
                if (this.dataKey == '') {
                    this.toastr.info("請選擇欲修改的資料");
                    return;
                }
                break;
            case 'new':
                if (!this.ngFormInput.valid) {
                    this.toastr.info("請選擇學年及組別");
                    return;
                }
                this.ngFormNew.get('Years')?.setValue(this.ngFormInput.get('Years')?.value);
                this.ngFormNew.get('DeptNo')?.setValue(this.ngFormInput.get('DeptNo')?.value);
                this.ngFormNew.get('TeamNo')?.setValue(this.ngFormInput.get('TeamNo')?.value);
                break;
            case 'credits':
                if (!this.ngFormInput.valid) {
                    this.toastr.info("請選擇學年及系所組別");
                    return;
                }
                sClass='modal-l';
                this.getGradCredits();
                break;
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: sClass
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setDatakey(type: string, dataKey: string, event: any) {
        if (!this.cUPPermanentList) {
            return;
        }
        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.cUPPermanentList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.cUPPermanentList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.cUPPermanentList.forEach(element => {
                    element.checked = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }

    getCUPCurPlanAddNew() {
        if (this.dataKeyArray.length == 0) {
            this.toastr.info("尚未選取科目");
            return;
        }

        let dataKey = '';
        this.dataKeyArray.forEach(element => {
            dataKey = dataKey + element + ',';
        });

        this.ngFormNew.get('CosList')?.setValue(dataKey.substring(0, dataKey.length - 1));

        if (this.ngFormNew.get("CosCluster")?.value != '' && this.ngFormNew.get("MustPassNum")?.value == 0) {
            this.toastr.info("有選群組就必須設定應過科目數");
            return;
        }

        if (this.ngFormNew.get("Grade")?.value != '' && this.ngFormNew.get("Term")?.value == '') {
            this.toastr.info("有選年級就必須設定學期");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0501/Get_CUP_CurPlan_AddNew", this.ngFormNew.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.dataKeyArray = [];
            this.modalRef.hide();
            this.getCUPCurPlanDetail();
        })
    }

    getCUPCurPlanModify() {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0501/Get_CUP_CurPlan_Modify", this.ngFormEdit.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.modalRef.hide();
            this.getCUPCurPlanDetail();
        });
    }

    getCUPCurPlanCopyTo() {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0501/Get_CUP_CurPlan_CopyTo", this.ngFormCopy.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("建立完成");
            this.modalRef.hide();
            this.getCUPCurPlanDetail();
        })
    }

    getGradCredits(){
        this.gradCreditsList=[];
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0501/Get_CUP_CurPlan_GradCredit",{"Years":this.ngFormInput.get('Years')?.value,"DeptNo":this.ngFormInput.get('DeptNo')?.value,"TeamNo":this.ngFormInput.get('TeamNo')?.value,"OrderBy":""}).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.gradCreditsList = response;
        })
    }

    saveGradCredits(){
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0501/Get_CUP_CurPlan_GradCredit_Save", this.gradCreditsList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.dataKeyArray = [];
            this.getGradCredits();
        })
    }
}
