import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ParseSourceFile } from '@angular/compiler';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { parseDate } from 'ngx-bootstrap/chronos';
import { isUndefined } from 'ngx-bootstrap/chronos/utils/type-checks';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { isEmpty } from 'rxjs/operators';
import { Columns,ItemsList ,GetEMPMainDetail, Jobs,Holds } from 'src/app/_Models/AD/ADEMP/ADEMP0201/getEMPMainDetail';
import { GetEMPMainList } from 'src/app/_Models/AD/ADEMP/ADEMP0201/getEMPMainList';
import { GetBankNo } from 'src/app/_Models/getBankNo';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';
import { GetFieldType1,GetFieldTypeSelect } from 'src/app/_Models/getFieldType';
import { GetOtherCode } from 'src/app/_Models/getOtherCode1';
import { Search } from 'src/app/_Models/AD/ADEMP/ADEMP0104/search'
import { modal } from 'src/app/_Models/AD/ADEMP/ADEMP0201/modal';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';

@Component({
    selector: 'app-ADEMP0201',
    templateUrl: './ADEMP0201.component.html',
    styleUrls: ['./ADEMP0201.component.css']
})
export class ADEMP0201Component implements OnInit {
    baseUrl = environment.apiUrl;
    imageChangedEvent: any = '';
    bloodType = require('src/app/_Models/_SelectInput/bloodType.json').bloodType;
    marriage = require('src/app/_Models/_SelectInput/marriage.json').marriage;
    month = require('src/app/_Models/_SelectInput/month.json').month;
    croppedImageA: any = '';
    croppedImageC: any = '';
    croppedImageE: any = '';
    showCropper = false;
    modalRef?: BsModalRef;
    fileExchange = new FileExchange();
    ImgUrl!: SafeUrl;
    bankNo!: GetBankNo[];
    search1!: Search[];
    IncomeTaxTypeList!: Search[];
    modal!: modal[];
    unitCode: string = '';
    Title: string = '';
    countryList!: GetSelectList[];
    titleStatus!: GetSelectList[];
    eMPMainList!: GetEMPMainList[];
    unitList!: GetSelectList[];
    employeeDegree!: GetSelectList[];
    studyStatus!: GetSelectList[];
    titleList!: GetSelectList[];
    timeCase!: GetSelectList[];
    Graduate!: ItemsList[];
    getfieldtype1!: GetFieldType1[];
    getothercode!: GetOtherCode[];
    tmpJobs!:Jobs[];
    tmpHolds!:Holds[];
    exportfield1!: GetFieldTypeSelect[];
    exportfield2!: GetFieldTypeSelect[];
    bsConfig = environment.bsDatePickerConfig;
    ngFormInput = new UntypedFormGroup({
        Code: new UntypedFormControl(''),
        Name: new UntypedFormControl(''),
        IDNo: new UntypedFormControl(''),
        StateNo: new UntypedFormControl(''),
        UnitCode: new UntypedFormControl(''),
        Fields: new UntypedFormControl(''),
        IsJob: new UntypedFormControl('')
    });
    croppedImage: any = '';
    eMPMainDetail: GetEMPMainDetail = {
        DataKey: '',
        Code: '',
        IDNo: '',
        Name: '',
        EngName: '',
        Birthday: '',
        Sex: '',
        Blood: '',
        Marry: '',
        Country: '',
        DegreeType: '',
        StateNo: '',
        MonkName: '',
        Further: '',
        Graduate: '',
        ComZip: '',
        ComAddress: '',
        NomZip: '',
        NomAddress: '',
        ComTel: '',
        MobileTel: '',
        OfficeTel: '',
        Memo: '',
        UpdateUser: '',
        UpdateDate: '',
        BankNo: '',
        BankAccount: '',
        LiaiseName: '',
        LiaiseTel: '',
        LiaiseRelation: '',
        LiaiseAddress: '',
        EMail: '',
        OtherEmail: '',
        Religion: '',
        Association: '',
        PassPortNo:  '',
        ResidenceNo:  '',
        ResidenceAllowDate: '',
        ResidenceLastAllowDate: '',
        ResidenceDeadline: '',
        IncomeTax: 0,
        NewTaxWithheld: false,
        IncomeTaxType: '',
        TaxIn: '',
        InDate:'',
        EndDate: '',
        PIC: '',
        Jobs: [],
        Columns: [],
        Holds:[]
    }
    num: string = '';

    name = ['英文姓名', '性別', '血型', '婚姻狀態', '國籍', '學位', '在職狀態', '出家法名', '進修狀態', '學歷', '通訊郵遞區號', '通訊地址',
        '戶籍郵遞區號', '戶籍地址', '聯絡電話', '行動電話', '辦公室電話', '備註', '護照號碼', '居留證號',
        '稅務居住者識別碼', '原始居留證核准日', '最新居留證核發日', '居留期限', '外國籍是否依新制所得扣繳方式扣繳', '所得稅扣繳方式', '固定扣所得稅%', '單位',
        '職稱', '專兼任', '任職開始時間', '任職結束時間','留職停薪','銀行名稱','銀行帳號','緊急聯絡人','聯絡人電話','聯絡人關係','聯絡人地址','校內Email','私人Email'];
    value = ['05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
        '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33',
        '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47'];
    filedsNoArray: Array<string> = ['01', '02', '03', '04'];

    constructor(private http: HttpClient,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private localeService: BsLocaleService,
        private datePipe: DatePipe,
        private domSanitizer: DomSanitizer) {
            this.exportfield1=[];
            this.exportfield2=[];
            for (let index = 0; index < this.name.length; index++) {
                if(index%2==0){
                    this.exportfield1.push({ ItemName: this.name[index], ItemValue: this.value[index],Checked:false })
                }
                else{
                    this.exportfield2.push({ ItemName: this.name[index], ItemValue: this.value[index],Checked:false })
                }
            }
    }

    ngOnInit(): void {
        this.getCountryList();
        this.getBankNo();
        this.getTitleStatus();
        this.getUnitList();
        this.getStudyStatus();
        this.getEmployeeDegree();
        this.getTitleList();
        this.getTimeCase();
        this.searchIncomeTaxType();
        this.localeService.use('zh-cn');
        this.indexItem();
        this.searchGraduate();
    }

    getTimeCase() {
        this.systemService.getTimeCase().subscribe(response => {
            if (response.Info == false) {
                return;
            }
            this.timeCase = response;
        });
    }

    getTitleList() {
        this.systemService.getTitleList('').subscribe(response => {
            if (response.Info == false) {
                return;
            }
            this.titleList = response;
        })
    }

    getUnitList() {
        this.systemService.getUnitList('', '', '').subscribe(response => {
            if (response.Info == false) {
                return;
            }
            this.unitList = response;
        })
    }

    getStudyStatus() {
        this.systemService.getStudyStatus().subscribe(response => {
            if (response.Info == false) {
                return;
            }
            this.studyStatus = response;
        });
    }

    getTitleStatus() {
        this.systemService.getTitleStatus().subscribe(response => {
            if (response.Info == false) {
                return;
            }
            this.titleStatus = response;
        });
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        sessionStorage.setItem("file", event.target.files[0].name)
    }

    imageCropped(event: ImageCroppedEvent, type: string) {
        if (type == 'C') {
            this.croppedImageC = event.base64;
        }
        else this.croppedImageE = event.base64;
    }

    imageCropped1(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.croppedImageA = event.base64;
    }

    imageLoaded(image: LoadedImage) {
        this.showCropper = true;
        this.toastr.info("圖片加載成功!");
    }

    loadImageFailed() {
        this.toastr.info("圖片格式不符");
    }

    uploadImage(type: string, unitCode1: string, Title1: string) {
        if (type == 'C') {
            for (let i = 0; i < this.eMPMainDetail.Jobs.filter(n => n.UnitCode.includes(unitCode1) && n.Title.includes(Title1)).length; i++) {
                this.eMPMainDetail.Jobs.filter(n => n.UnitCode.includes(unitCode1) && n.Title.includes(Title1))[i].SignPicC = this.croppedImageC.split(',')[1];
            }
        }
        else {
            for (let i = 0; i < this.eMPMainDetail.Jobs.filter(n => n.UnitCode.includes(unitCode1) && n.Title.includes(Title1)).length; i++) {
                this.eMPMainDetail.Jobs.filter(n => n.UnitCode.includes(unitCode1) && n.Title.includes(Title1))[i].SignPicE = this.croppedImageE.split(',')[1];
            }
        }
        this.toastr.success("上傳成功");
        this.showCropper = false;
        this.imageChangedEvent = null;
        this.croppedImageC = '';
        this.croppedImageE = '';
        let img = <HTMLInputElement>document.getElementById('image');
        img.value = '';
    }

    indexModal(code: string, nuber: number) {
        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0201/Get_EMP_Main_Item_List", { Code: "HLAD", ColumnNo: code }).subscribe(response => {
            this.modal = []
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.modal = response;
        });
        this.num = nuber.toString();
    }

    clearImage() {
        this.showCropper = false;
        this.imageChangedEvent = null;
        this.croppedImageC = '';
        let img = <HTMLInputElement>document.getElementById('image');
        img.value = '';
    }

    openModal(template: TemplateRef<any>, unitCode: string, Title: string) {
        this.unitCode = unitCode;
        this.Title = Title;
        this.imageChangedEvent = null;
        this.croppedImageC = '';
        this.croppedImageE = '';
        if (this.eMPMainDetail.Jobs.length == 0) {
            this.toastr.info("未選擇職員或目前沒有職務");
            return;
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    openModal1(template: TemplateRef<any>) {
        this.croppedImageA = '';
        this.imageChangedEvent = null;
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    openModal2(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getEmployeeDegree() {
        this.systemService.getEmployeeDegree().subscribe(response => {
            if (response.Info == false) {
                return;
            }
            this.employeeDegree = response;
        })
    }

    getCountryList() {
        this.systemService.getCountryList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.countryList = response;
        })
    }

    getBankNo() {
        this.systemService.getBankNo().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.bankNo = response;
        })
    }

    importInputCkick() {
        document.getElementById('img')?.click();
    }

    importData($event: any) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, '', '')
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file + ' ?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.eMPMainDetail.PIC = base64;
                that.ImgUrl = that.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + base64);
            }
            else if (result.isDenied) {
                document.getElementById('img')?.setAttribute('value', '');
                that.toastr.info("已取消上傳。");
            }
        });
    }

    uploadImage1() {
        let y = sessionStorage.getItem("file")
        this.GetBase64(this, this.croppedImage.split(',')[1], y, '', '')
        this.imageChangedEvent = '';
        this.croppedImageA = '';
    }

    getEMPMainList() {
        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0201/Get_EMP_Main_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.eMPMainList = []
                return;
            }
            this.eMPMainList = response;
            this.eMPMainList.forEach(element => {
                element.Selected = false;
            });
        })
    }

    getchange(Item: modal, a: string) {
        this.eMPMainDetail.Columns[Number(a)].ItemName = Item.ItemName;
        this.eMPMainDetail.Columns[Number(a)].ItemNo = Item.ItemNo;
    }

    getEMPMainDetail(datakey: string, index: number) {
        this.eMPMainList.forEach(element => {
            element.Selected = false;
        });
        this.eMPMainList[index].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0201/Get_EMP_Main_Detail", { DataKey: datakey }).subscribe(response => {
            this.ImgUrl = '';
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eMPMainDetail = response;
            this.ImgUrl = "data:image/png;base64," + response.PIC;
        })
    }

    addJob() {
        this.tmpJobs=[];
        this.tmpJobs.push({
            DataKey: '',
            UnitCode: '',
            UnitName: '',
            Title: '',
            TimeCase: '',
            MainJob:false,
            StartDate:'',
            EndDate:undefined,
            SignPicC: '',
            SignPicE: '',
            Delete: false
        });

        this.eMPMainDetail.Jobs.forEach(element => {
            this.tmpJobs.push(element);
        });

        this.eMPMainDetail.Jobs=[];
        this.eMPMainDetail.Jobs=this.tmpJobs;
    }

    deleteJob() {
        var i=0;
        this.tmpJobs=[];
        this.eMPMainDetail.Jobs.forEach(element => {
            if(i>0){
                this.tmpJobs.push(element);
            }
            i++;
        });

        this.eMPMainDetail.Jobs=[];
        this.eMPMainDetail.Jobs=this.tmpJobs;

        //this.eMPMainDetail.Jobs = this.eMPMainDetail.Jobs.slice(0, this.eMPMainDetail.Jobs.length - 1);
    }

    addHolds(){
        this.tmpHolds=[];
        this.tmpHolds.push({
            DataKey: '',
            ApplyDate: '',
            Reason: '',
            StartDate: '',
            EndDate: '',
            Memo: '',
            UpdateUser: '',
            UpdateTime: '',
            UpdateIP: ''
        });

        this.eMPMainDetail.Holds.forEach(element => {
            this.tmpHolds.push(element);
        });

        this.eMPMainDetail.Holds=[];
        this.eMPMainDetail.Holds=this.tmpHolds;
    }

    deleteHolds(){
        var i=0;
        this.tmpHolds=[];
        this.eMPMainDetail.Holds.forEach(element => {
            if(i>0){
                this.tmpHolds.push(element);
            }
            i++;
        });

        this.eMPMainDetail.Holds=[];
        this.eMPMainDetail.Holds=this.tmpHolds;
    }

    indexItem() {
        this.http.get<any>(this.baseUrl + "ACAD/EMP/ADEMP0201/Get_EMP_Main_Column_List").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eMPMainDetail.Columns = response;
        })
    }

    clear() {
        this.eMPMainDetail = {
            DataKey: '',
            Code: '',
            IDNo: '',
            Name: '',
            EngName: '',
            Birthday: '',
            Sex: '',
            Blood: '',
            Marry: '',
            Country: '',
            DegreeType: '',
            StateNo: '',
            MonkName: '',
            Further: '',
            Graduate: '',
            ComZip: '',
            ComAddress: '',
            NomZip: '',
            NomAddress: '',
            ComTel: '',
            MobileTel: '',
            OfficeTel: '',
            Memo: '',
            UpdateUser: '',
            UpdateDate: '',
            BankNo: '',
            BankAccount: '',
            LiaiseName: '',
            LiaiseTel: '',
            LiaiseRelation: '',
            LiaiseAddress: '',
            EMail: '',
            OtherEmail: '',
            Religion: '',
            Association: '',
            PassPortNo:  '',
            ResidenceNo:  '',
            ResidenceAllowDate: '',
            ResidenceLastAllowDate: '',
            ResidenceDeadline: '',
            IncomeTax: 0,
            NewTaxWithheld: false,
            IncomeTaxType: '',
            TaxIn: '',
            PIC: '',
            InDate:'',
            EndDate:'',
            Jobs: [],
            Columns: [],
            Holds:[]
        }
        this.indexItem();
    }

    checkData(): boolean {

        let pass = true;

        if (this.eMPMainDetail.Code == '' || this.eMPMainDetail.IDNo == '' || this.eMPMainDetail.Name == '' || this.eMPMainDetail.Birthday == '') {
            this.toastr.info('請檢查人事代碼、身分證號、姓名以及生日是否填妥');
            return false;
        }

        if (this.eMPMainDetail.StateNo == '') {
            this.toastr.info('請點選在職狀態');
            return false;
        }

        for (let index = 0; index < this.eMPMainDetail.Jobs.length; index++) {

            if (this.eMPMainDetail.Jobs[index].UnitCode == '' || this.eMPMainDetail.Jobs[index].Title == '' || this.eMPMainDetail.Jobs[index].StartDate == '') {
                this.toastr.info('請檢查職務資料是否有填妥');
                pass = false;
            }
        }

        return pass;
    }

    getEMPMainDetailSave() {

        if (!this.checkData()) return;

        this.eMPMainDetail.Birthday = this.datePipe.transform(this.eMPMainDetail.Birthday, 'yyyy-MM-dd')!.toString();
        this.eMPMainDetail.InDate = this.datePipe.transform(this.eMPMainDetail.InDate, 'yyyy-MM-dd')!.toString();

        for (let i = 0; i < this.eMPMainDetail.Jobs.length; i++) {
            if (this.eMPMainDetail.Jobs[i].EndDate != null) {
                this.eMPMainDetail.Jobs[i].StartDate = this.datePipe.transform(this.eMPMainDetail.Jobs[i].StartDate, 'yyyy-MM-dd')!.toString();
                this.eMPMainDetail.Jobs[i].EndDate = this.datePipe.transform(this.eMPMainDetail.Jobs[i].EndDate, 'yyyy-MM-dd')!.toString();
            }
            else {
                this.eMPMainDetail.Jobs[i].StartDate = this.datePipe.transform(this.eMPMainDetail.Jobs[i].StartDate, 'yyyy-MM-dd')!.toString();
                this.eMPMainDetail.Jobs[i].EndDate=undefined;
            }
        }

        for (let i = 0; i < this.eMPMainDetail.Holds.length; i++) {
            if (this.eMPMainDetail.Holds[i].ApplyDate != null) {
                this.eMPMainDetail.Holds[i].ApplyDate = this.datePipe.transform(this.eMPMainDetail.Holds[i].ApplyDate, 'yyyy-MM-dd')!.toString();
            }
            if (this.eMPMainDetail.Holds[i].StartDate != null) {
                this.eMPMainDetail.Holds[i].StartDate = this.datePipe.transform(this.eMPMainDetail.Holds[i].StartDate, 'yyyy-MM-dd')!.toString();
            }
            if (this.eMPMainDetail.Holds[i].EndDate != null) {
                this.eMPMainDetail.Holds[i].EndDate = this.datePipe.transform(this.eMPMainDetail.Holds[i].EndDate, 'yyyy-MM-dd')!.toString();
            }
        }

        if (this.eMPMainDetail.ResidenceAllowDate!= null) {
            if (this.eMPMainDetail.ResidenceAllowDate!= "") {
                this.eMPMainDetail.ResidenceAllowDate = this.datePipe.transform(this.eMPMainDetail.ResidenceAllowDate, 'yyyy-MM-dd')!.toString();
            }
        }

        if (this.eMPMainDetail.ResidenceLastAllowDate!= null) {
            if (this.eMPMainDetail.ResidenceLastAllowDate!= "") {
                this.eMPMainDetail.ResidenceLastAllowDate = this.datePipe.transform(this.eMPMainDetail.ResidenceLastAllowDate, 'yyyy-MM-dd')!.toString();
            }
        }
        
        if (this.eMPMainDetail.ResidenceDeadline!= null) {
            if (this.eMPMainDetail.ResidenceDeadline!= "") {
                this.eMPMainDetail.ResidenceDeadline = this.datePipe.transform(this.eMPMainDetail.ResidenceDeadline, 'yyyy-MM-dd')!.toString();
            }
        }        

        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0201/Get_EMP_Main_Detail_Save", this.eMPMainDetail).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success('儲存成功');
            this.getEMPMainList();
        }, error => console.log(error));
        this.indexItem();
    }

    getEMPMainDelete(datakey: string) {

        if (datakey == '') {
            this.toastr.info("請選擇人員");
            return;
        }

        Swal.fire({
            title: `確定刪除${this.eMPMainDetail.Name}的資料?`,
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0201/Get_EMP_Main_Delete", { DataKey: datakey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.getEMPMainList();
                });
            }
            else {
                this.toastr.info("已取消動作");
            }
        })
        this.indexItem();
    }

    searchIncomeTaxType() {
        this.IncomeTaxTypeList = [];
        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0104/Search", { Column_No: "50" }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.IncomeTaxTypeList = response;
        });
    }

    searchGraduate(){
        this.Graduate = [];
        this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0104/Search", { Column_No: "21" }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.Graduate = response;
        });
    }

    setField(value: string) {
        let index = this.filedsNoArray.indexOf(value);
        if (index == -1) {
            this.filedsNoArray.push(value);
        }
        else {
            this.filedsNoArray.splice(index, 1);
        }
    }

    setAll(event: EventTarget | null) {
        if (!this.exportfield1 && !this.exportfield1) return;

        let input = <HTMLInputElement>event;
        this.filedsNoArray=['01', '02', '03', '04'];

        this.exportfield1.forEach(element => {
            input.checked == true ? element.Checked = true : element.Checked = false;

            if(input.checked){
                this.setField(element.ItemValue);
            }
        });

        this.exportfield2.forEach(element => {
            input.checked == true ? element.Checked = true : element.Checked = false;
            if(input.checked){
                this.setField(element.ItemValue);
            }
        });
    }

    exportReport(){
        let filedsNo = '';
        let IsJob='';

        this.filedsNoArray.forEach(element => {
            filedsNo = filedsNo + element + ',';

            if(parseInt(element)>=32 && parseInt(element)<=36){
                IsJob='Y';
            }
        });

        this.ngFormInput.get("Fields")?.setValue(filedsNo.substring(0, filedsNo.length - 1));
        this.ngFormInput.get("IsJob")?.setValue(IsJob);

        this.fileExchangeServices.ExportFile("ACAD/EMP/ADEMP0201/Get_EMP_Main_Report", this.ngFormInput.value).subscribe((response) => {
            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }, error => {
            this.toastr.error("伺服器發生問題");
            console.error(error);
        })
    }
}
