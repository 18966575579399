<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group-prepend">
                <span class="input-group-text">審查學年期</span>
            </div>
        </div>        
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year [(ngModel)]="model.Years">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" #Term [(ngModel)]="model.Term">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <div class="input-group-prepend">
                <span class="input-group-text">學系/體育室審查</span>
            </div>
        </div>
        <div class="col-md-5">
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">開始時間</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" #DeptStartDate bsDatepicker
                    [bsConfig]="bsConfig" readonly required [bsValue]="model.DeptfromDate" [(ngModel)]="model.DeptfromDate"
                    style="background-color: transparent;">
                <timepicker #DeptStartTime [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="model.DeptfromTime"></timepicker>
            </div>
        </div>
        <div class="col-md-5"> 
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">結束時間</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" #DeptEndDate bsDatepicker
                    [bsConfig]="bsConfig" readonly required [bsValue]="model.DepttoDate" [(ngModel)]="model.DepttoDate"
                    style="background-color: transparent;">
                <timepicker #DeptEndTime [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="model.DepttoTime"></timepicker>
            </div>        
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <div class="input-group-prepend">
                <span class="input-group-text">學生線上確認</span>
            </div>            
        </div>
        <div class="col-md-5">
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">開始時間</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" #StartDate bsDatepicker
                    [bsConfig]="bsConfig" readonly required [bsValue]="model.fromDate" [(ngModel)]="model.fromDate"
                    style="background-color: transparent;">
                <timepicker #StartTime [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="model.fromTime"></timepicker>
            </div>
        </div>
        <div class="col-md-5"> 
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">結束時間</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" #EndDate bsDatepicker
                    [bsConfig]="bsConfig" readonly required [bsValue]="model.toDate" [(ngModel)]="model.toDate"
                    style="background-color: transparent;">
                <timepicker #EndTime [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="model.toTime"></timepicker>
            </div>        
        </div>
    </div>    
    <div class="row">
        <div class="col-md-2">
            <div class="input-group-prepend">
                <span class="input-group-text">開放學生查詢</span>
            </div>            
        </div>
        <div class="col-md-5">
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">開始時間</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" #OpenStartDate bsDatepicker
                    [bsConfig]="bsConfig" readonly required [bsValue]="model.OpenfromDate" [(ngModel)]="model.OpenfromDate"
                    style="background-color: transparent;">
                <timepicker #OpenStartTime [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="model.OpenfromTime"></timepicker>
            </div>
        </div>
        <div class="col-md-5"> 
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">結束時間</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" #OpenEndDate bsDatepicker
                    [bsConfig]="bsConfig" readonly required [bsValue]="model.OpentoDate" [(ngModel)]="model.OpentoDate"
                    style="background-color: transparent;">
                <timepicker #OpenEndTime [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="model.OpentoTime"></timepicker>
            </div>        
        </div>
    </div>    
    <div class="row">
        <div class="col-md-6">
        </div>        
        <div class="col-md-6">
            <button class="save-btn-gray mb-3" (click)="SaveData();">儲存</button>
        </div>        
    </div>
</div>