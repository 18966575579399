<div class="container">
    <small class="text-danger fw-bolder">*製單只需選擇學年、學期及製單類別即可</small>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year (change)="getEduList(Year.value, Term.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term (change)="getEduList(Year.value, Term.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select #EduNo class="form-select shadow-none">
                    <option value="">--全部--</option>
                    <option value="{{item.Edu_No}}" *ngFor="let item of eduList">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">身分別</span>
                </div>
                <select #EntryID class="form-select shadow-none">
                    <option value="">--全部--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of entryIDList">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">製單類別</span>
                </div>
                <select #MakeDocNo class="form-select shadow-none">
                    <option value="">--全部--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of makeDocList">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-9">
            <button class="search-btn float-start me-3"
                (click)="getTFSMakeDocFeeList(Year.value, Term.value, EduNo.value, EntryID.value, MakeDocNo.value)">查詢</button>
            <button class="other-Button-gray float-start me-3" (click)="getTFSMakeDocFeeSave()">儲存</button>
            <button class="other-Button-gray float-start me-3"
                (click)="getTFSMakeDocReportBase64(Year.value, Term.value, MakeDocNo.value, EduNo.value, EntryID.value)">繳交清冊</button>
            <button class="other-Button-gray float-start me-3" (click)="openModal(template)">單筆設定</button>
            <button class="other-Button-gray float-start me-3"
                (click)="getTFSMakeDocFeeMakeDoc(Year.value, Term.value, EduNo.value)">製作</button>
            <button class="other-Button-gray float-start me-3"
                (click)="getTFSMakeDocFeeSend(Year.value, Term.value, MakeDocNo.value)">繳交出納</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-7">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 50px;"></th>
                        <th style="width: 60px;">學年</th>
                        <th style="width: 60px;">學期</th>
                        <th style="width: 90px;">系所代碼</th>
                        <th>系所名稱</th>
                        <th style="width: 60px;">年級</th>
                        <th style="width: 90px;">身分別</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of tFSMakeDocFeeList; let i = index" class="pointer"
                        (click)="getTFSMakeDocFeeDetail(i, item.DataKey)">
                        <td style="width: 50px;">{{i+1}}</td>
                        <td style="width: 60px;">{{item.Years}}</td>
                        <td style="width: 60px;">{{item.Term}}</td>
                        <td style="width: 90px;">{{item.DeptNo}}</td>
                        <td>{{item.DeptName}}</td>
                        <td style="width: 60px;">{{item.Grade}}</td>
                        <td style="width: 90px;">{{item.EntryName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-5">
            <table class="table table-hover text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 50px;"></th>
                        <th>收費代碼</th>
                        <th>收費項目</th>
                        <th>收費金額</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tFSMakeDocFeeDetail; let i = index">
                        <td style="width: 50px;">{{i+1}}</td>
                        <td>{{item.FeeNo}}</td>
                        <td>{{item.FeeName}}</td>
                        <td><input type="text" class="form-control form-control-input" [(ngModel)]="item.Money"
                                style="width: 80px;" [disabled]="item.Edit != 'Y'"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="container-fluid">

</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">單筆設定</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="closeSignalModalRef()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #stdYears (change)="getEduList(stdYears.value, Term.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #stdTerm
                        (change)="getEduList(stdYears.value, stdTerm.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select #StdEduNo class="form-select shadow-none"
                        (change)="getDeptList(StdEduNo.value, stdYears.value, stdTerm.value)">
                        <option value="">--全部--</option>
                        <option value="{{item.Edu_No}}" *ngFor="let item of stdEduList">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>


        </div>

        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select #DeptNo class="form-select shadow-none"
                        (change)="getClassList(StdEduNo.value, DeptNo.value, stdYears.value, stdTerm.value)">
                        <option value="">--全部--</option>
                        <option value="{{item.Dept_No}}" *ngFor="let item of deptList">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <select #Class class="form-select shadow-none">
                        <option value="">--全部--</option>
                        <option value="{{item.Class}}" *ngFor="let item of classList">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">製單類別</span>
                    </div>
                    <select #MakeDocNo class="form-select shadow-none">
                        <option value="" disabled>--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of makeDocList">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" #StdNo>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">狀態</span>
                    </div>
                    <select class="form-select shadow-none" #Status>
                        <option value="" selected>--全部--</option>
                        <option value="0">製作中</option>
                        <option value="1">已送繳</option>
                        <option value="2">退單</option>
                        <option value="3">已製單</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <button class="search-btn mb-3 me-3 float-start"
                    (click)="getTFSMakeDocStdList(stdYears.value, stdTerm.value, StdEduNo.value, DeptNo.value, Class.value, MakeDocNo.value, StdNo.value, Status.value)">查詢</button>
                <button class="save-btn-gray mb-3 float-start" (click)="getTFSMakeDocStdSave()">儲存</button>
            </div>
        </div>


        <div class="row">
            <div class="col-md">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th></th>
                            <th>班級</th>
                            <th>學號</th>
                            <th>姓名</th>
                            <th>狀態</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [ngClass]="{'':item.Selected == false, 'bg-warning':item.Selected == true}"
                            *ngFor="let item of tFSMakeDocStdList; let i = index" class="pointer"
                            (click)="getTFSMakeDocStdDetail(i, item.DataKey)">
                            <td>{{i+1}}</td>
                            <td>{{item.ClassName}}</td>
                            <td>{{item.StdNo}}</td>
                            <td>{{item.StdName}}</td>
                            <td>{{item.StatusName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th></th>
                            <th>收費代碼</th>
                            <th>收費項目</th>
                            <th>預設金額</th>
                            <th>應繳金額</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tFSMakeDocStdDetail; let i = index">
                            <td>{{i+1}}</td>
                            <td>{{item.FeeNo}}</td>
                            <td style="width: 120px;">{{item.FeeName}}</td>
                            <td>{{item.DefaultMoney}}</td>
                            <td><input type="text" [(ngModel)]="item.MustMoney" [disabled]="item.Edit != 'Y'"
                                    style="width: 80px;"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</ng-template>