import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0505/search';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0505/searchDetail';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADRGP0505',
  templateUrl: './ADRGP0505.component.html',
  styleUrls: ['./ADRGP0505.component.css']
})
export class ADRGP0505Component implements OnInit {

  baseUrl = environment.apiUrl;
  data!: Search[];
  dataDetail: SearchDetail = {
    Keep_No: '',
    Keep_Name: '',
    Update_User: '',
    Update_Time: ''
  }
  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private systemService: SystemService,) { }

  ngOnInit(): void {
    this.search();
  }

  search() {
    this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0505/Search").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.data = response;
      this.data.forEach(element => {
        element.Selected = false;
      });
    })
  }

  searchDetail(Keep_No: string, index: number) {
    this.data.forEach(element => {
      element.Selected = false;
    });
    this.data[index].Selected = true;
    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0505/Search_Detail", { Keep_No: Keep_No }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.dataDetail = response;
      this.dataDetail.Keep_Name = this.dataDetail.Keep_Name;
      this.dataDetail.Update_Time = this.dataDetail.Update_Time;
      this.dataDetail.Update_User = this.dataDetail.Update_User;
    });
  }

  insert() {
    if (this.dataDetail.Keep_Name == '' || this.dataDetail.Keep_No == '') {
      this.toastr.info("請將所有欄位填妥");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0505/Insert", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("新增成功");
      this.search();
    }, error => this.toastr.error(error.error))
  }

  update() {
    if (this.dataDetail.Keep_Name == '' || this.dataDetail.Keep_No == '') {
      this.toastr.info("請將所有欄位填妥");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0505/Update", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("修改成功");
      this.search();
    }, error => this.toastr.error(error.error))
  }

  delete() {
    if (this.dataDetail.Keep_No == '') {
      this.toastr.info("請選擇原因");
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0505/Delete", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("刪除成功");
      this.search();
      this.clear();
    }, error => this.toastr.error(error.error))
  }

  clear() {
    this.dataDetail = {
      Keep_No: '',
      Keep_Name: '',
      Update_User: '',
      Update_Time: ''
    }
  }

}
