<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="ngFormInput" (ngSubmit)="getSELSelChooseList()">
        <div class="row">
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year formControlName="Years"
                        (change)="getClassList(Year.value, Term.value,'')">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term formControlName="Term"
                        (change)="getClassList(Year.value, Term.value,'')">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">項目</span>
                    </div>
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" class="btn-check" #openClass value="1" name="Type" id="btnradio1"
                            formControlName="Type" autocomplete="off" checked
                            (click)="getClassList(Year.value, Term.value,'1')">
                        <label class="btn btn-outline-secondary shadow-none" for="btnradio1">開課班級</label>

                        <input type="radio" class="btn-check" name="Type" #stdClass value="2" id="btnradio2"
                            formControlName="Type" autocomplete="off" (click)="getClassList(Year.value, Term.value,'2')">
                        <label class="btn btn-outline-secondary shadow-none" for="btnradio2">學生班級</label>

                        <input type="radio" class="btn-check" name="Type" #std value="3" id="btnradio3"
                            formControlName="Type" autocomplete="off">
                        <label class="btn btn-outline-secondary shadow-none" for="btnradio3">學生</label>
                    </div>
                </div>
            </div>
            <div class="col-md-3" *ngIf="!std.checked">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class" style="width:100px;">
                        <option value="" disabled selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3" *ngIf="std.checked">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input class="form-control shadow-none" type="text" formControlName="StdNo" placeholder="請輸入學號" style="width:100px;">
                </div>
            </div>
            <div class="col-md-2" *ngIf="!openClass.checked" style="display:none;">
                <div class="form-check mt-2">
                    <input class="form-check-input shadow-none pointer" type="checkbox" id="flexCheckDefault"
                        formControlName="CheckRegi">
                    <label class="form-check-label pointer" for="flexCheckDefault">參照註冊資料</label>
                </div>
            </div>
            <div class="col-md-2">
                <button class="search-btn float-end">查詢</button>
            </div>                
        </div>
    </form>
    <table class="table table-hover table-striped text-center" *ngIf="openClass.checked">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 50px;"></th>
                <th style="width: 100px;vertical-align: middle;">開課班級</th>
                <th style="width: 40px;vertical-align: middle;">開課序號</th>
                <th style="width: 220px;vertical-align: middle;">科目</th>
                <th style="width: 50px;vertical-align: middle;">學期</th>
                <th style="width: 60px;vertical-align: middle;">組別</th>
                <th style="width: 50px;vertical-align: middle;">選修別</th>
                <th style="width: 40px;vertical-align: middle;">學分</th>
                <th style="width: 40px;vertical-align: middle;">人數上限</th>
                <th style="width: 40px;vertical-align: middle;">已選人數</th>
                <th style="width: 90px;vertical-align: middle;">上課時間</th>
                <th style="width: 80px;vertical-align: middle;">合班開課</th>
            </tr>
        </thead>
        <tbody style="height: 200px;">
            <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" class="pointer"
                *ngFor="let item of sELSelChooseList; let i = index"
                (click)="getSELSelChooseOpClassSelList(item.DataKey,i)">
                <td style="width: 50px;">{{i + 1}}</td>
                <td style="width: 100px;">{{item.ClassName}}</td>
                <td style="width: 40px;">{{item.Serial}}</td>
                <td style="width: 220px;">{{item.CosName}}</td>
                <td style="width: 50px;">{{item.TermName}}</td>
                <td style="width: 60px;">{{item.TeamName}}</td>
                <td style="width: 50px;">{{item.SelKindName}}</td>
                <td style="width: 40px;">{{item.Credits}}</td>
                <td style="width: 40px;">{{item.SelMax}}</td>
                <td style="width: 40px;">{{item.SelCounts}}</td>
                <td style="width: 90px;">{{item.CurTime}}</td>
                <td style="width: 80px;">{{item.MergeClassName}}</td>
            </tr>
        </tbody>
    </table>
    <table class="table table-hover table-striped text-center" *ngIf="!openClass.checked">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 60px;"></th>
                <th>班級</th>
                <th>學生</th>
                <th>組別</th>
                <th>輔系雙主修</th>
                <th>教育學程</th>
                <th>學分上限</th>
                <th>學分下限</th>
                <th>已選學分</th>
            </tr>
        </thead>
        <tbody style="height: 200px;">
            <tr [ngClass]="{'':item.selected == false, 'row-selected-bg':item.Selected == true}" class="pointer"
                *ngFor="let item of sELSelChooseList; let i = index"
                (click)="getSELSelChooseStdSelList(item.DataKey,i);getSELSelChooseStdReVokeList(item.DataKey);">
                <td style="width: 60px;">{{i + 1}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.StdName}}</td>
                <td>{{item.TeamName}}</td>
                <td>{{item.AddDeptName}}</td>
                <td>{{item.EduCourseName}}</td>
                <td>{{item.SelMax}}</td>
                <td>{{item.SelMin}}</td>
                <td>{{item.SelCredits}}</td>
            </tr>
        </tbody>
    </table>
    <div>
        <tabset>
            <tab heading="{{openClass.checked ? '修課學生名單' : '學生選課清單'}}">
                <div class="row mt-3 mb-3">
                    <div class="col-md-12">
                        <button class="other-Button-gray float-end"
                            (click)="getSELSelChooseProcess('D', !openClass.checked ? sELSelChooseStdSelList : sELSelChooseOpClassSelList)">移除</button>
                        <button style="width: 120px;" class="other-Button-gray float-end me-3"
                            (click)="getSELSelChooseProcess('N', !openClass.checked ? sELSelChooseStdSelList : sELSelChooseOpClassSelList)">無條件移除</button>
                    </div>
                </div>
                <table class="table table-hover table-striped text-center" *ngIf="openClass.checked">
                    <thead class="thead-dark-red">
                        <tr>
                            <th class="ColW50"></th>
                            <th class="ColW70"><input type="checkbox" class="form-check-input shadow-none pointer">&ensp;全選</th>
                            <th class="ColW300">班級</th>
                            <th class="ColW200">學生</th>
                            <th class="ColW70">組別</th>
                            <th class="ColW70">狀態</th>
                            <th class="ColW70">類別</th>
                            <th class="ColW100">期末考試假</th>
                        </tr>
                    </thead>
                    <tbody style="height: 300px;">
                        <tr *ngFor="let item of sELSelChooseOpClassSelList; let i = index">
                            <th class="ColW50">{{i + 1}}</th>
                            <td class="ColW70"><input type="checkbox" class="form-check-input shadow-none pointer"
                                    [(ngModel)]="item.checked" (click)="setCheck($event.target,item)"></td>
                            <td class="ColW300">{{item.ClassName}}</td>
                            <td class="ColW200">{{item.StdName}}</td>
                            <td class="ColW70">{{item.TeamName}}</td>
                            <td class="ColW70">{{item.Status}}</td>
                            <td class="ColW70">{{item.InsertType}}</td>
                            <td class="ColW100"><input type="checkbox" class="form-check-input shadow-none pointer"
                                [(ngModel)]="item.RestMark" (click)="getSELSelChooseReMark($event.target,item.DataKey)"></td>                            
                        </tr>
                    </tbody>
                </table>
                <table class="table table-hover table-striped text-center" *ngIf="!openClass.checked">
                    <thead class="thead-dark-red">
                        <tr>
                            <th class="ColW70"><input type="checkbox"
                                    class="form-check-input shadow-none pointer">&ensp;全選</th>
                            <th class="ColW200">開課班級</th>
                            <th class="ColW50">序號</th>
                            <th class="ColW200">科目</th>
                            <th class="ColW80">組別</th>
                            <th class="ColW50">學分</th>
                            <th class="ColW80">選修別</th>
                            <th class="ColW100">授課教師</th>
                            <th class="ColW50">類別</th>
                            <th class="ColW100">時間</th>
                            <th class="ColW100">期末考試假</th>
                        </tr>
                    </thead>
                    <tbody style="height: 300px;">
                        <tr *ngFor="let item of sELSelChooseStdSelList">
                            <th class="ColW70"><input type="checkbox" class="form-check-input shadow-none pointer"
                                    [(ngModel)]="item.checked" (click)="setCheck($event.target,item)"></th>
                            <td class="ColW200">{{item.ClassName}}</td>
                            <td class="ColW50">{{item.Serial}}</td>
                            <td class="ColW200">{{item.CosName}}</td>
                            <td class="ColW80">{{item.TeamName}}</td>
                            <td class="ColW50">{{item.Credits}}</td>
                            <td class="ColW80">{{item.SelKindName}}</td>
                            <td class="ColW100">{{item.TchName}}</td>
                            <td class="ColW50">{{item.InsertType}}</td>
                            <td class="ColW100">{{item.CurTime}}</td>
                            <td class="ColW100"><input type="checkbox" class="form-check-input shadow-none pointer"
                                [(ngModel)]="item.RestMark" (click)="getSELSelChooseReMark($event.target,item.DataKey)"></td>                             
                        </tr>
                    </tbody>
                </table>
            </tab>
            <tab heading="新增學生" *ngIf="openClass.checked">
                <div class="row mt-3">
                    <div class="col-md-2">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">班級</span>
                            </div>
                            <select class="form-select shadow-none" #addClass>
                                <option value="" disabled selected>--請選擇--</option>
                                <option *ngFor="let item of classListAdd" value="{{item.Class}}">
                                    {{item.Class_Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">學號</span>
                            </div>
                            <input class="form-control shadow-none" type="text" placeholder="請輸入學號" #addStdNo>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button class="other-Button-gray"
                            (click)="getSELSelChooseOpClassAddStdList(addClass.value, addStdNo.value)">查詢</button>
                    </div>
                    <div class="col-md-6">
                        <button class="other-Button-gray float-end"
                            (click)="getSELSelChooseProcess('A', sELSelChooseOpClassAddStdList)">加選</button>
                        <button style="width: 120px;" class="other-Button-gray float-end me-3"
                            (click)="getSELSelChooseProcess('C', sELSelChooseOpClassAddStdList)">無條件加選</button>
                    </div>
                </div>
                <table class="table table-hover table-striped text-center" *ngIf="openClass.checked">
                    <thead class="thead-dark-red">
                        <tr>
                            <th style="width: 80px;"><input type="checkbox"
                                    class="form-check-input shadow-none pointer">&ensp;全選</th>
                            <th style="width:200px;">班級</th>
                            <th style="width:150px;">學生</th>
                            <th>組別</th>
                            <th>輔系雙主修</th>
                            <th>教育學程</th>
                            <th>學分上限</th>
                            <th>學分下限</th>
                            <th>已選學分</th>
                        </tr>
                    </thead>
                    <tbody style="height: 200px;">
                        <tr *ngFor="let item of sELSelChooseOpClassAddStdList">
                            <td style="width: 80px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                    [(ngModel)]="item.checked" (click)="setCheck($event.target,item)"></td>
                            <td style="width:200px;">{{item.ClassName}}</td>
                            <td style="width:150px;">{{item.StdName}}</td>
                            <td>{{item.TeamName}}</td>
                            <td>{{item.AddDeptName}}</td>
                            <td>{{item.EduCourseName}}</td>
                            <td>{{item.SelMax}}</td>
                            <td>{{item.SelMin}}</td>
                            <td>{{item.SelCredits}}</td>
                        </tr>
                    </tbody>
                </table>
            </tab>
            <tab heading="新增科目" *ngIf="!openClass.checked">
                <div class="row mt-3">
                    <div class="col-md-2">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">班級</span>
                            </div>
                            <select class="form-select shadow-none" #addClassStd>
                                <option value="" disabled selected>--請選擇--</option>
                                <option *ngFor="let item of classListAdd" value="{{item.Class}}">
                                    {{item.Class_Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">科目</span>
                            </div>
                            <input class="form-control shadow-none" type="text" placeholder="請輸入科目" #addCos>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button class="other-Button-gray"
                            (click)="getSELSelChooseStdAddCosList(addClassStd.value, addCos.value)">查詢</button>
                    </div>
                    <div class="col-md-6">
                        <button class="other-Button-gray float-end"
                            (click)="getSELSelChooseProcess('A', sELSelChooseStdAddCosList)">加選</button>
                        <button style="width: 120px;" class="other-Button-gray float-end me-3"
                            (click)="getSELSelChooseProcess('C', sELSelChooseStdAddCosList)">無條件加選</button>
                    </div>
                </div>
                <table class="table table-hover table-striped text-center" *ngIf="!openClass.checked">
                    <thead class="thead-dark-red">
                        <tr>
                            <th style="width: 80px;"><input type="checkbox"
                                    class="form-check-input shadow-none pointer">&ensp;全選</th>
                            <th style="width: 150px;">開課班級</th>
                            <th style="width: 50px;">序號</th>
                            <th style="width: 250px;">科目</th>
                            <th style="width: 70px;">學期</th>
                            <th style="width: 70px;">組別</th>
                            <th style="width: 70px;">選修別</th>
                            <th style="width: 50px;">學分</th>
                            <th style="width: 50px;">人數上限</th>
                            <th style="width: 50px;">已選人數</th>
                            <th style="width: 130px;">上課時間</th>
                            <th style="width: 100px;">合班開課</th>
                        </tr>
                    </thead>
                    <tbody style="height: 200px;">
                        <tr *ngFor="let item of sELSelChooseStdAddCosList">
                            <td style="width: 80px;"><input type="checkbox" class="form-check-input shadow-none pointer"
                                    [(ngModel)]="item.checked" (click)="setCheck($event.target,item)"></td>
                            <td style="width: 150px;">{{item.ClassName}}</td>
                            <td style="width: 50px;">{{item.Serial}}</td>
                            <td style="width: 250px;">{{item.CosName}}</td>
                            <td style="width: 70px;">{{item.TermName}}</td>
                            <td style="width: 70px;">{{item.TeamName}}</td>
                            <td style="width: 70px;">{{item.SelKindName}}</td>
                            <td style="width: 50px;">{{item.Credits}}</td>
                            <td style="width: 50px;">{{item.SelMax}}</td>
                            <td style="width: 50px;">{{item.SelCounts}}</td>
                            <td style="width: 130px;">{{item.CurTime}}</td>
                            <td style="width: 100px;">{{item.MergeClassName}}</td>
                        </tr>
                    </tbody>
                </table>
            </tab>
            <tab heading="期中停修紀錄" *ngIf="!openClass.checked">
                <table class="table table-hover table-striped text-center" *ngIf="!openClass.checked">
                    <thead class="thead-dark-red">
                        <tr>
                            <th style="width: 40px;">學年</th>                            
                            <th style="width: 40px;">學期</th>
                            <th style="width: 150px;">開課班級</th>
                            <th style="width: 40px;">序號</th>
                            <th style="width: 250px;">科目</th>
                            <th style="width: 70px;">組別</th>
                            <th style="width: 70px;">選修別</th>
                            <th style="width: 40px;">學分</th>
                        </tr>
                    </thead>
                    <tbody style="height: 200px;">
                        <tr *ngFor="let item of sELSelChooseStdReVokeList">
                            <td style="width: 40px;">{{item.Years}}</td>
                            <td style="width: 40px;">{{item.Term}}</td>
                            <td style="width: 150px;">{{item.ClassName}}</td>
                            <td style="width: 50px;">{{item.Serial}}</td>
                            <td style="width: 250px;">{{item.CosName}}</td>
                            <td style="width: 70px;">{{item.TeamName}}</td>
                            <td style="width: 70px;">{{item.SelKindName}}</td>
                            <td style="width: 40px;">{{item.Credits}}</td>
                        </tr>
                    </tbody>
                </table>
            </tab>
        </tabset>
    </div>
</div>