<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">公告類型</span>
                <select class="form-select shadow-none" [(ngModel)]="condition.Type_ID">
                    <option value="">全部</option>
                    <option value="1">一般</option>
                    <option value="2">防疫</option>
                    <option value="3">遠距</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group mb-3">
                <span class="input-group-text">對象</span>
                <select class="form-select shadow-none" [(ngModel)]="condition.Post_Object" (change)="conditionObjectOnchange()">
                    <option value="A">全體</option>
                    <option value="T">教職員</option>
                    <option value="S">學生</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group mb-3">
                <span class="input-group-text">對象單位<br> </span>
                <select class="form-select shadow-none" [(ngModel)]="condition.Unit_Code" [disabled]="UnitListLock">
                    <option value="" selected>--全部--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of unitList">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">對象系所</span>
                <select class="form-select shadow-none" [(ngModel)]="condition.Dept_No" [disabled]="DeptListLock">
                    <option value="" selected>--全部--</option>
                    <option value="{{item.Dept_No}}" *ngFor="let item of deptList">{{item.Dept_Name}} </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group  mb-3">
                <span class="input-group-text">公告主旨</span>
                <input value="" type="text" class="form-control shadow-none ng-pristine ng-valid ng-touched" size="30" placeholder="範例:補助" [(ngModel)]="condition.Post_Subject">
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group  mb-3">
                <span class="input-group-text">公告單位</span>
                <input value="" type="text" class="form-control shadow-none ng-pristine ng-valid ng-touched" size="30" placeholder="範例:教務處" [(ngModel)]="condition.Post_UnitName">
            </div>
        </div>
        <div class="col-md-4">
            <button class="search-btn float-end col-md-1" (click)="Search()">查詢</button>
            <button class="other-Button-gray float-end me-3" (click)="OpenDialog(template, -1)">新增</button>
        </div>
    </div>
    <hr>
    <div class="row">
        <table class="table table-hover table-striped table-rwd text-center">
            <thead class="table-rwd-hide thead-dark-red">
                <tr>
                    <th>公告類型</th>
                    <th>公告主旨</th>
                    <th>公告單位</th>
                    <th>對象單位</th>
                    <th>公告日期</th>
                    <th>詳細資料</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of listData; let i=index">
                    <td data-th="公告類型" style="width:100px;">{{item.Type_Name}}</td>
                    <td data-th="公告主旨" style="width:400px; text-align: left;">{{item.Post_Subject}}</td>
                    <td data-th="公告單位" style="">{{item.Post_UnitName}}</td>
                    <td data-th="對象單位" style="width:200px;">{{item.Unit_Name}}</td>
                    <td data-th="公告日期" style="width:140px;">{{item.Post_Date}}</td>
                    <td data-th="詳細資料" style="width:140px;"><a class="pointer text-decoration-none" (click)="OpenDialog(template, i)">詳細資料</a></td>
                </tr>
                <tr *ngIf="listData.length == 0">
                    <td colspan="6" style="height:60px; vertical-align: middle;"><label style="color:red;">查無資料</label></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<!--小視窗-->
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">公告內容</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">公告類型</span>
                    <select class="form-select shadow-none" [(ngModel)]="detailData.Type_ID">
                        <option value="1">一般</option>
                        <option value="2">防疫</option>
                        <option value="3">遠距</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <span class="input-group-text">對象</span>
                    <select class="form-select shadow-none" (change)="detailObjectOnchange()" [(ngModel)]="detailData.Post_Object">
                        <option value="A">全體</option>
                        <option value="T">教職員</option>
                        <option value="S">學生</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group mb-3">
                    <span class="input-group-text">單位</span>
                    <select class="form-select shadow-none" [(ngModel)]="detailData.Unit_Code" [disabled]="UnitListLock">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of unitList" [value]="item.ItemValue">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" [(ngModel)]="detailData.Unit_Code" [disabled]="DeptListLock">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of deptList" [value]="item.Dept_No">{{item.Dept_Name}} </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">公告單位</span>
                    <input type="text" class="form-control shadow-none ng-pristine ng-valid ng-touched" [(ngModel)]="detailData.Post_UnitName" size="30" placeholder="請輸入公告單位">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">公告主旨</span>
                    <input type="text" class="form-control shadow-none ng-pristine ng-valid ng-touched" [(ngModel)]="detailData.Post_Subject" size="30" placeholder="請輸入主旨">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-2">
                    <span class="input-group-text">公告內容</span>
                    <textarea class="form-control shadow-none" placeholder="請輸入公告內容" [(ngModel)]="detailData.Post_Content" style="height:180px;"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group mb-3">
                    <input type="file" id="image" (change)="importData($event);" class="form-control shadow-none" multiple />
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">公告日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" [(ngModel)]="detailData.Post_Date" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">截止日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" [(ngModel)]="detailData.End_Date" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;">
                </div>
            </div>
        </div>
        <div class="row">
            <label>附件檔案：</label>
        </div>
        <div class="row">
            <div class="input-group flex-nowrap mb-3" *ngFor="let item of detailData.Attachment">
                <label class="link1" (click)="Download(item.FileName,item.Base64)">{{item.FileName}} </label>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button class="save-btn-gray float-end me-3 mx-auto" (click)="InsertPost()" *ngIf="isInsert">新增</button>
                <button class="other-Button-gray float-end  mx-auto" (click)="DeletePost()" *ngIf="isUpdate">刪除</button>
                <button class="save-btn-gray float-end me-3 mx-auto " (click)="UpdatePost()" *ngIf="isUpdate">修改</button>
            </div>
        </div>
    </div>
</ng-template>
