<div class="container" style="margin-bottom: 100px;">
    <app-year-term-input (childEvent)="getCosList($event)"></app-year-term-input>
    <button class="export-btn mb-3" style="width: 200px;" (click)="export()">{{'匯出勾選的資料'| translate}}</button>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th>{{'選取'| translate}}</th>
                <th>{{'開課班級'| translate}}</th>
                <th>{{'科目名稱'| translate}}</th>
                <th>{{'上課時間'| translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cosList; let i = index">
                <td>{{i+1}}</td>
                <td><input type="checkbox" class="pointer" (click)="setexportDataKey(item.DataKey)"></td>
                <td>{{item.ClassName}}</td>
                <td>{{item.CosName}}</td>
                <td>{{item.Times}}</td>
            </tr>
        </tbody>
    </table>

</div>