<div class="container">
    <table class="table table-borderedless text-center">
        <tbody>
            <tr>
                <td class="pt-3">第 學年 第 學期</td>
                <td class="pt-3">大學部學雜費</td>
                <td>
                    <input type="text" placeholder="點選設定超商繳費期限" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;">
                </td>
                <td>
                    <input type="text" placeholder="點選設定學校繳費期限" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;">
                </td>
                <td>
                    <button class="other-Button-gray">單筆製單</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
