import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetSELSetDefaultList } from 'src/app/_Models/AD/ADSEL/ADSEL0301/getSELSetDefaultList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0301',
    templateUrl: './ADSEL0301.component.html',
    styleUrls: ['./ADSEL0301.component.css']
})
export class ADSEL0301Component implements OnInit {
    baseUrl = environment.apiUrl;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    deptList!: GetDeptList[];
    sELSetDefaultList!: GetSELSetDefaultList[];
    model = {
        start: '',
        end: '',
    }
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
        Term: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
        DeptNo: new UntypedFormControl('', Validators.required)
    });

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private timeService: TimeService) { }

    ngOnInit(): void {
        this.getYears();
        this.timeService.currentTime$.subscribe((response) => {
            this.model.start = response.find(n => n.ItemID == "SelStart")?.ItemValue || '';
            this.model.end = response.find(n => n.ItemID == "SelEnd")?.ItemValue || '';
            this.ngFormInput.get("Years")?.setValue(response.find(n => n.ItemID == "SelYear")?.ItemValue || '');
            this.ngFormInput.get("Term")?.setValue(response.find(n => n.ItemID == "SelTerm")?.ItemValue || '');
            this.getDeptList();
        });

    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getDeptList() {
        this.systemService.getDeptList('', '0', this.ngFormInput.get("Years")?.value, this.ngFormInput.get("Term")?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getSELSetDefaultList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0301/Get_SEL_SetDefault_List", this.ngFormInput.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sELSetDefaultList = []
                return;
            }
            this.sELSetDefaultList = response;

            this.sELSetDefaultList.forEach(element => {
                element.checked = false;
            })
        });
    }

    getSELSetDefaultProcess() {

        let datakey = ''

        this.sELSetDefaultList.forEach(element => {
            if (element.checked) {
                datakey += element.DataKey + ','
            }
        });

        if (datakey == '') {
            this.toastr.info("請選擇班級");
            return;
        }

        this.http.post<any>(this.baseUrl + 'ACAD/SEL/ADSEL0301/Get_SEL_SetDefault_Process', {
            Years: this.ngFormInput.get("Years")?.value,
            Term: this.ngFormInput.get("Term")?.value,
            ClassList: datakey.substring(0, datakey.length - 1)
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.getSELSetDefaultList();
            this.toastr.success("配課完成");
        });
    }

    setAll(eventTarget: EventTarget | null) {
        let input = <HTMLInputElement>eventTarget;
        if (input.checked) {
            this.sELSetDefaultList.forEach(element => {
                element.checked = true;
            });
        }
        else {
            this.sELSetDefaultList.forEach(element => {
                element.checked = false;
            });
        }
    }

}
