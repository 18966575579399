export class GetCUPPreCourseDetail{
    DataKey: string = ''
    Years: string = ''
    CosID: string = ''
    CosName: string = ''
    CosTerm: string = ''
    SelKindName: string = ''
    Credits: string = ''
    PreCosID: string = ''
    PreCosName: string = ''
    PreCosTerm: string = ''
    PreSelKindName: string = ''
    PreCredits: string = ''
    CosCluster: string = ''
    MustPassNum: number = 0
    PassScore: number = 0
    CanReplace: boolean = true
    UpdateUser: string = ''
    UpdateTime: string = ''
}