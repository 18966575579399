export class GetCUPPermanentDetail{
    DataKey: string = ''
    CosID: string = ''
    CosName: string = ''
    CosShort: string = ''
    CosPrint: string = ''
    CosEngName: string = ''
    EduNo: string = ''
    DeptNo: string = ''
    CosTerm: string = '1'
    SelKind: string = ''
    Status: string = '1'
    CurClass: string = ''
    AllCredits: number = 0
    CosCredits: number = 0
    CosHours: number = 0
    PracticeHours: number = 0
    SelMax: number = 0
    SelMin: number = 0
    PassScore: number = 0
    ScoreInputType: string = '1'
    TSMark: boolean = false
    PrintMark: boolean = false
    RelCosID: string = ''
    RelCosName: string = ''
    SelStyle: string = ''
    UpdateUser: string = ''
    UpdateTime: string = ''
}