<div class="container" style="margin-bottom: 200px;">

    <table class="table table-bordered" *ngIf="data">
        <tbody>
            <tr>
                <td>{{data.Years}}{{'學年' | translate}}</td>
                <td>{{'第' | translate}}{{data.Term}}{{'學期' | translate}}</td>
                <td>{{'任課老師:' | translate}}{{data.Tch_Name}}</td>
            </tr>
            <tr>
                <td>{{'開課班級： ' | translate}}{{data.Class_Name}}</td>
                <td>{{'科目名稱：' | translate}} {{data.Cos_Name}}</td>
                <td>{{'科目名稱(英文)：' | translate}} {{data.Cos_Name_Eng}}</td>
            </tr>
            <tr>
                <td>{{'學分：' | translate}} {{data.Cos_Credit}}</td>
                <td>{{'必選修別：' | translate}} {{data.Sel_Kind_Name}}</td>
                <td></td>
            </tr>
        </tbody>
    </table>


    <table class="table table-rwd" *ngIf="data">
        <thead class="table-rwd-hide">
            <tr>
                <th style="width: 250px;">{{'項目：' | translate}}</th>
                <th>{{'內容：' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td data-th="項目">{{'科目描述' | translate}}<span class="text-danger fw-bolder">{{'(必填)' | translate}}</span>
                </td>
                <td data-th="內容"><textarea [readonly]="!isTeacher || data.Send_State == '1'" appTextareaAutoresize
                        placeholder="請於此輸入內容"
                        id="Cos_Descrip">{{data.Cos_Descrip == ''? '無': data.Cos_Descrip}}</textarea></td>
            </tr>
            <tr>
                <td data-th="項目">{{'學習目標' | translate}}<span class="text-danger fw-bolder">{{'(必填)' | translate}}</span>
                </td>
                <td data-th="內容"><textarea [readonly]="!isTeacher || data.Send_State == '1'" appTextareaAutoresize
                        placeholder="請於此輸入內容" id="Cos_Target">{{data.Cos_Target == ''? '無': data.Cos_Target}}</textarea>
                </td>
            </tr>
            <tr>
                <td data-th="項目">{{'課程屬性' | translate}}
                    <span class="text-danger fw-bolder">{{'(必填)' | translate}}</span>
                    <span class="text-primary fw-bolder">{{'(可複選)' | translate}}</span>
                </td>
                <td data-th="內容">
                    <ng-container *ngFor="let Attributes of data.Attributes; let i = index">
                        <ng-container *ngIf="Attributes.Attributes_Check=='Y'; else NoChecked">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="{{Attributes.Attributes_No}}"
                                    checked [disabled]="!isTeacher || data.Send_State == '1'"
                                    (click)="setAttributesCheck(i)">
                                <label class="form-check-label"
                                    for="{{Attributes.Attributes_No}}">{{Attributes.Attributes_Name}}</label>
                            </div>
                        </ng-container>
                        <ng-template #NoChecked>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="{{Attributes.Attributes_No}}"
                                    [disabled]="!isTeacher || data.Send_State == '1'" (click)="setAttributesCheck(i)">
                                <label class="form-check-label"
                                    for="{{Attributes.Attributes_No}}">{{Attributes.Attributes_Name}}</label>
                            </div>
                        </ng-template>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td data-th="項目">{{'使用教材' | translate}}<span class="text-danger fw-bolder">{{'(必填)' | translate}}</span>
                </td>
                <td data-th="內容">
                    <ng-container *ngFor="let Materials of data.Materials; let i = index">
                        <ng-container *ngIf="Materials.Materials_Check == 'Y'; else NoChecked">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="Materials_Check"
                                    id="{{Materials.Materials_No}}" checked
                                    [disabled]="!isTeacher || data.Send_State == '1'" (click)="setMaterialsCheck(i)">
                                <label class="form-check-label"
                                    for="{{Materials.Materials_No}}">{{Materials.Materials_Name}}</label>
                            </div>
                        </ng-container>
                        <ng-template #NoChecked>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="Materials_Check"
                                    id="{{Materials.Materials_No}}" [disabled]="!isTeacher || data.Send_State == '1'"
                                    (click)="setMaterialsCheck(i)">
                                <label class="form-check-label"
                                    for="{{Materials.Materials_No}}">{{Materials.Materials_Name}}</label>
                            </div>
                        </ng-template>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td data-th="項目">{{'教學內涵' | translate}}<span class="text-danger fw-bolder">{{'(必填)' |
                        translate}}</span><span class="text-primary fw-bolder">{{'(單選)' | translate}}</span></td>
                <td data-th="內容">
                    <ng-container *ngFor="let Connotation of data.Connotation; let i = index">
                        <ng-container *ngIf="Connotation.Connotation_Check == 'Y'; else NoChecked">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="{{Connotation.Connotation_No}}"
                                    checked [disabled]="!isTeacher || data.Send_State == '1'"
                                    (click)="setConnotationCheck(i)">
                                <label class="form-check-label"
                                    for="{{Connotation.Connotation_No}}">{{Connotation.Connotation_Name}}</label>
                            </div>
                        </ng-container>
                        <ng-template #NoChecked>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox"
                                    [disabled]="!isTeacher || data.Send_State == '1'"
                                    id="{{Connotation.Connotation_No}}" (click)="setConnotationCheck(i)">
                                <label class="form-check-label"
                                    for="{{Connotation.Connotation_No}}">{{Connotation.Connotation_Name}}</label>
                            </div>
                        </ng-template>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td data-th="項目">{{'主要內容進度' | translate}}<span class="text-danger fw-bolder">{{'(必填)' |
                        translate}}</span></td>
                <td data-th="內容"><textarea [readonly]="!isTeacher || data.Send_State == '1'" placeholder="{{'請於此輸入內容'|translate}}"
                        id="Main_Content" appTextareaAutoresize>{{data.Main_Content}}</textarea></td>
            </tr>
            <tr>
                <td data-th="項目">{{'主要教材與用書' | translate}}<span class="text-danger fw-bolder">{{'(必填)' |
                        translate}}</span></td>
                <td data-th="內容"><textarea [readonly]="!isTeacher || data.Send_State == '1'" placeholder="{{'請於此輸入內容'|translate}}"
                        id="Teach_Material" appTextareaAutoresize>{{data.Teach_Material}}</textarea></td>
            </tr>
            <tr>
                <td data-th="項目">{{'上課方式' | translate}}<span class="text-danger fw-bolder">{{'(必填)' | translate}}</span>
                </td>
                <td data-th="內容"><textarea [readonly]="!isTeacher || data.Send_State == '1'" placeholder="{{'請於此輸入內容'|translate}}"
                        id="Teach_Mode" appTextareaAutoresize>{{data.Teach_Mode}}</textarea></td>
            </tr>
            <tr>
                <td data-th="項目">{{'課程要求' | translate}}<span class="text-danger fw-bolder">{{'(必填)' | translate}}</span>
                </td>
                <td data-th="內容"><textarea [readonly]="!isTeacher || data.Send_State == '1'" placeholder="{{'請於此輸入內容'|translate}}"
                        id="Larn_Require" appTextareaAutoresize>{{data.Learn_Require}}</textarea></td>
            </tr>
            <tr>
                <td data-th="項目">{{'評量方式' | translate}}<span class="text-danger fw-bolder">{{'(必填)' | translate}}</span>
                </td>
                <td data-th="內容"><textarea [readonly]="!isTeacher || data.Send_State == '1'" placeholder="{{'請於此輸入內容'|translate}}"
                        id="Score_Mode" appTextareaAutoresize>{{data.Score_Mode}}</textarea></td>
            </tr>
            <tr>
                <td data-th="項目">{{'備註' | translate}}</td>
                <td data-th="內容"><textarea [readonly]="!isTeacher || data.Send_State == '1'" placeholder="{{'請於此輸入內容'|translate}}"
                        id="Memo" appTextareaAutoresize>{{data.Memo}}</textarea></td>
            </tr>
            <tr>
                <td data-th="校核心能力指標">{{'校核心能力指標' | translate}}</td>
                <td data-th="項目">
                    <div class="input-group mb-3" *ngFor="let item of data.DeptCore">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">{{item.DeptCA_Name}}(%)</span>
                        </div>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="item.Allot_Num"
                            [readonly]="!isTeacher || data.Send_State == '1'">
                    </div>
                </td>
            </tr>
            <tr>
                <td data-th="系核心能力指標">{{'系核心能力指標' | translate}}</td>
                <td data-th="項目">
                    <div class="input-group mb-3" *ngFor="let item of data.SchCore">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">{{item.SchCA_Name}}(%)</span>
                        </div>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="item.Allot_Num"
                            [readonly]="!isTeacher || data.Send_State == '1'">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="data" [hidden]="!isTeacher || data.Send_State == '1'">
        <button type="button" class="other-Button-blue float-end">{{'清除' | translate}}</button>
        <button type="button" class="save-btn-gray float-end me-3" (click)="save('save')">{{'儲存' | translate}}</button>
        <button type="button" class="send-btn float-end me-3" (click)="save('send')">{{'送繳' | translate}}</button>
    </div>
    <div *ngIf="data" [hidden]="!isTeacher || data.Send_State == '0'">
        <button type="button" class="other-Button-blue float-end">{{'列印' | translate}}</button>
    </div>
</div>