<div class="row">
    <div class="col-md-3">
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">學生狀態</span>
            <select class="form-select shadow-none" [(ngModel)]="getStdListInput.Status" (ngModelChange)="GetData()">
                <option value="" selected>--請選擇--</option>
                <option *ngFor="let item of sTDStatusList" value="{{item.Status_No}}">{{item.Status_Name}}
                </option>
            </select>
        </div>
    </div>
    <div class="col-md-3">
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">學制</span>
            <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value)"
                [(ngModel)]="getStdListInput.EduNo" (ngModelChange)="GetData()">
                <option value="" selected>--請選擇學制--</option>
                <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
            </select>
        </div>
    </div>
    <div class="col-md-3">
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">系所</span>
            <select class="form-select shadow-none" #dept
                (change)="getClassList(edu.value, dept.value); getTeamList(dept.value)"
                [(ngModel)]="getStdListInput.DeptNo" (ngModelChange)="GetData()">
                <option value="" selected>--請選擇系所--</option>
                <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
            </select>
        </div>
    </div>
    <div class="col-md-3">
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">組別</span>
            <select class="form-select shadow-none" [(ngModel)]="getStdListInput.TeamNo" (ngModelChange)="GetData()">
                <option value="" selected>--請選擇組別--</option>
                <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}</option>
            </select>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-3">
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">年級</span>
            <select class="form-select shadow-none" [(ngModel)]="getStdListInput.Grade" (ngModelChange)="GetData()">
                <option value="" selected>--請選擇年級--</option>
                <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}}</option>
            </select>
        </div>
    </div>
    <div class="col-md-3">
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">班別</span>
            <select class="form-select shadow-none" [(ngModel)]="getStdListInput.Class" (ngModelChange)="GetData()">
                <option value="" selected>--請選擇班別--</option>
                <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
            </select>
        </div>
    </div>
    <div class="col-md-3">
        <div class="input-group flex-nowrap mb-3" [hidden]="true">
                <span class="input-group-text">年班</span>
            <select class="form-select shadow-none" [(ngModel)]="getStdListInput.ClassID" (ngModelChange)="GetData()">
                <option value="" selected>--請選擇年班--</option>
                <option></option>
            </select>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-3">
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">身分證號</span>
            <input type="text" class="form-control shadow-none" [(ngModel)]="getStdListInput.IDNo"
                (ngModelChange)="GetData()">
        </div>
    </div>
    <div class="col-md-3">
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">姓名</span>
            <input type="text" class="form-control shadow-none" [(ngModel)]="getStdListInput.StdName"
                (ngModelChange)="GetData()">
        </div>
    </div>
    <div class="col-md-6">
        <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text">學號</span>
            <input type="text" class="form-control shadow-none" [(ngModel)]="getStdListInput.StdNoFrom"
                (ngModelChange)="GetData()">
                <span class="input-group-text">至</span>
            <input type="text" class="form-control shadow-none" [(ngModel)]="getStdListInput.StdNoTo"
                (ngModelChange)="GetData()">
        </div>
    </div>
</div>