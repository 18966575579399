import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCurDropdownListInput } from 'src/app/_Models/getCurDropdownListInput';
import { GetOpClassList } from 'src/app/_Models/getOpClassList';
import { TimeCntr } from 'src/app/_Models/timeCntr';
import { environment } from 'src/assets/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SystemService {

    baseUrl = environment.apiUrl;

    currentLanguage = (sessionStorage.getItem('key') == undefined ? "zh-tw" : sessionStorage.getItem('key') || "zh-tw");

    changelanguage = new BehaviorSubject<string>(this.currentLanguage);

    // Service定義方法 --> 子組件調用此方法傳遞的值，並在父組件通過Subscribe去訂閱傳遞的值
    recoverModel(status: string) {
        sessionStorage.setItem('key', status)
        this.changelanguage.next(sessionStorage.getItem('key') || "");
        this.changelanguage.subscribe((value: any) => {
            this.currentLanguage = value;
        });
    }

    constructor(private http: HttpClient, private titleService: Title) { }

    // 取得目前學年期
    getNowYearTerm() {
        return this.http.post<TimeCntr>(this.baseUrl + "Public/Get_TimeCntr", {}).pipe(
            map((response: TimeCntr) => {
                let data = {
                    Now_Years: response.Now_Years,
                    Now_Term: response.Now_Term,
                }
                return data;
            })
        )
    }

    // 取得各項設定時間
    getASMInfo() {
        return this.http.post<TimeCntr>(this.baseUrl + "Public/Get_TimeCntr", {}).pipe(
            map((response: TimeCntr) => {
                let data = {
                    Asm_Years: response.Asm_Years,
                    Asm_Term: response.Asm_Term,
                    AsmM_StartTime: response.AsmM_StartTime,
                    AsmM_EndTime: response.AsmM_EndTime,
                    AsmM_OpenTime: response.AsmM_OpenTime,
                    Asm_StartTime: response.Asm_StartTime,
                    Asm_EndTime: response.Asm_EndTime,
                    Asm_OpenTime: response.Asm_OpenTime,
                }
                return data;
            })
        )
    }

    // 檢查是否為手機
    checkIsMobile() {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)

            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4)))
            return true;
        return false;
    }

    //取得學校資料
    getSchoolInfo() {
        return this.http.get<any>(this.baseUrl + "Public/Get_SchoolInfo").pipe(
            map((response) => {
                if (!response) {
                    return;
                }
                this.titleService.setTitle(response.Sch_Name);
                return response;
            })
        )
    }

    // 取得學校類別
    getSchoolClass() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0012/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得周次資料
    getWeek(Years: string, Term: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_CurWeek_List", {
            Years: Years,
            Term: Term
        });
    }




    // 取得學制資料
    getEduList(type: string, years: string, term: string, short: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Edu_List", {
            Type: type,
            Years: years,
            Term: term,
            Short: short
        })
    }


    // 取得系所資料
    getDeptList(eduNo: string, type: string, years: string, term: string, short: string, classID: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Dept_List", {
            Type: type,
            Years: years,
            Term: term,
            Short: short,
            Edu_No: eduNo,
            Class_ID: classID
        });
    }

    //依製單類別取得系所資料
    getMakeDocDeptList(SingleValue: string) {
        return this.http.post<any>(this.baseUrl + "Public/List_MakeDoc_Dept", { SingleValue: SingleValue })
    }


    // 取得班級下拉
    getClassList(eduNo: string, deptNo: string, type: string, years: string, term: string, short: string, classID: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Class_List", {
            Type: type,
            Years: years,
            Term: term,
            Edu_No: eduNo,
            Short: short,
            Dept_No: deptNo,
            Class_ID: classID
        })
    }

    // 取得班級下拉-依學籍狀態
    getClassListStdStatus(Status: string, years: string, term: string, eduNo: string, deptNo: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_ClassList_StdStatus", {
            Status: Status,
            Years: years,
            Term: term,
            EduNo: eduNo,
            DeptNo: deptNo
        })
    }

    // 取得國家資料
    getCountryList() {
        return this.http.get<any>(this.baseUrl + "Public/Get_Country_List").pipe(map(response => {
            return response;
        }));
    }

    // 取得縣市資料
    getCityList(country: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_City_List", { Country: country }).pipe(map(response => {
            return response;
        }));
    }

    // 取得區域資料
    getTownList(country: string, city: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Town_List", { Country: country, City: city }).pipe(map(response => {
            return response;
        }));
    }

    // 取得稱謂列表
    getRelNameList() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0003/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得職業列表
    getOccNoList() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0004/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得學籍狀態
    getSTDStatusList() {
        return this.http.get<any>(this.baseUrl + "Public/Get_STD_Status_List").pipe(map(response => {
            return response;
        }));
    }

    // 取得開課班級
    getOpClassList(model: GetOpClassList) {
        return this.http.post<any>(this.baseUrl + "Public/Get_OpClass_List", model).pipe(map(response => {
            return response;
        }));
    }

    //取得年班資料
    getClassId(edu: string, dept: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_OpClass_List", { Edu_No: edu, Dept_No: dept }).pipe(map(response => {
            return response;
        }));
    }

    //取得組別資料
    getTeamList(Type: string, Years: string, Term: string, Dept_No: string, Class_ID: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Team_List", {
            Type: Type,
            Years: Years,
            Term: Term,
            Dept_No: Dept_No,
            Class_ID: Class_ID
        }).pipe(map(response => {
            return response;
        }));
    }

    // 取得原住民別
    getFieldType() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0403/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得入學管道
    getEntryDuct() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0402/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得特殊類別
    getEntrySpecialType() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0407/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得入學區分
    getEntryDiv() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0408/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得入學類型
    getEntryType() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0405/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得學校名稱
    getSchoolName(schClass: string, SchName: string, Country: string, Capital: string) {

        return this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0302/Search", {
            SchClass: schClass,
            Sch_Name: SchName,
            Country: Country,
            Capital: Capital
        }).pipe(map(response => {
            return response;
        }));
    }

    // 取得學費收費類別
    getFeeAcadList() {
        return this.http.get<any>(this.baseUrl + "Public/List_FeeAcad").pipe(map(response => {
            return response;
        }));
    }

    // 取得入學身份
    getEntryIDList(SingleValue: string) {
        return this.http.post<any>(this.baseUrl + "Public/List_EntryID", {
            SingleValue: SingleValue
        }).pipe(map(response => {
            return response;
        }));
    }

    // 取得製單類別
    getMakeDocList() {
        return this.http.get<any>(this.baseUrl + "Public/List_MakeDoc").pipe(map(response => {
            return response;
        }));
    }

    //學雜費繳費單類別下拉選單_依單位與學制
    getMakeDocUnitEduList(Years: string, Term: string, UnitNo: string, EduNo: string) {
        return this.http.post<any>(this.baseUrl + "Public/List_MakeDoc_UnitEdu", {
            Years: Years,
            Term: Term,
            UnitNo: UnitNo,
            EduNo: EduNo
        }).pipe(map(response => {
            return response;
        }));
    }

    // 取得減免身分
    getRedClassList() {
        return this.http.get<any>(this.baseUrl + "ACAD/TFS/ADTFS0701/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得輔系雙主修狀態
    getDoubleMajor() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0002/Search").pipe(map(response => {
            return response;
        }));
    }

    // 取得核准文號
    getMoeNo(_MoeType:string) {
        return this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0501/DropDownList", { Moe_Type: _MoeType }).pipe(map(response => {
            return response;
        }));
    }    

    // 取得入學核准文號
    getEntryMoeNo() {
        return this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0501/DropDownList", { Moe_Type: 'A' }).pipe(map(response => {
            return response;
        }));
    }

    // 畢業核准文號
    getGradMoeNo() {
        return this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0501/DropDownList", { Moe_Type: 'D' }).pipe(map(response => {
            return response;
        }));
    }


    // 取得文號類型
    getMoeType() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0005/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得銀行
    getBankNo() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0507/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得學雜費繳費管道
    getInType() {
        return this.http.get<any>(this.baseUrl + "ACAD/TFS/ADTFS0001/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得加修狀態
    getAddType() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0002/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得休學原因
    getRestReason() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0502/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得轉系原因
    getStdTurnReason() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0504/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得退學原因
    getStdOutReason() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0503/DropDownList").pipe(map(response => {
            return response;
        }));
    }


    // 取得學位(學籍)
    getDegree() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0001/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得學位(人事)
    getEmployeeDegree() {
        return this.http.get<any>(this.baseUrl + "ACAD/EMP/ADEMP0003/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得畢(肄)業別
    getGraduateType() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0006/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得保留入學原因
    getKeepReason() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0505/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得學院下拉
    getAcademyList() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0103/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得日夜間別
    getDNMark() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0011/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得假別
    getHolidayList() {
        return this.http.post<any>(this.baseUrl + 'Public/Get_Holiday_List', { Type: 'A', Del: '0' }).pipe(map(response => {
            return response;
        }));
    }

    // 取得畢業證書與證明書下拉
    getDiploma() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0013/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得教育部休學原因
    getMOERestReason() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0014/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得教育部退學原因
    getMOELeaveReason() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0015/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得所有系統清單
    getAllSysFunc() {
        return this.http.get<any>(this.baseUrl + "ACAD/SYS/ADSYS0301/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得校區資料
    getCampus() {
        return this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0101/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得大專院校體系別資料
    getSchoolType() {
        return this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0016/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得學校大樓資料
    getClassKind(ClassArea: string) {
        return this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0102/DropDownList", { ClassArea: ClassArea }).pipe(map(response => {
            return response;
        }));
    }

    // 取得所有教師列表
    getEmployeeList() {
        return this.http.get<any>(this.baseUrl + "Public/Get_Employee_List").pipe(map(response => {
            return response;
        }));
    }

    // 取得所有教師列表
    getEmployeeOptionList(NameCode:string,DeptNo:string,OnTheJob:boolean) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Employee_Option_List",{NameCode:NameCode,DeptNo:DeptNo,OnTheJob:OnTheJob}).pipe(map(response => {
            return response;
        }));
    }

    // 取得所有教師列表
    getEmployeeTeacherList(NameCode:string,DeptNo:string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Employee_Teacher_List",{NameCode:NameCode,DeptNo:DeptNo}).pipe(map(response => {
            return response;
        }));
    }


    // 取得必選修別下拉
    getSelKind() {
        return this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0301/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得課程類別下拉
    getCurClass(Dept_No: string) {
        return this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0306/DropDownList", { Dept_No: Dept_No }).pipe(map(response => {
            return response;
        }));
    }

    // 取得選課特殊註記
    getSelStyle() {
        return this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0302/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得人事所屬單位
    getUnitList(Years: string, Term: string, Unit_Style: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Unit_List", { Years: Years, Term: Term, Unit_Style: Unit_Style, ShowCode: true}).pipe(map(response => {
            return response;
        }));
    }

    // 取得教室型態
    getClassType() {
        return this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0103/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得職稱類型
    getTitleTypeList(Title_Type: string) {
        return this.http.post<any>(this.baseUrl + "ACAD/EMP/ADEMP0005/DropDownList", { Title_Type: Title_Type }).pipe(map(response => {
            return response;
        }));
    }

    // 取得職稱資料
    getTitleList(Title_Type: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Title_List", { Title_Type: Title_Type, ShowCode: true}).pipe(map(response => {
            return response;
        }));
    }

    // 取得在職狀態
    getTitleStatus() {
        return this.http.get<any>(this.baseUrl + "ACAD/EMP/ADEMP0001/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得專兼任別
    getTimeCase() {
        return this.http.get<any>(this.baseUrl + "ACAD/EMP/ADEMP0002/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    // 取得年度學分學程
    getYearPrograms(Years: string, DeptNo: string) {
        return this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0505/DropDownList", { Years: Years, DeptNo: DeptNo }).pipe(map(response => {
            return response;
        }));
    }

    // 取得學分學程
    getPrograms(DeptNo: string) {
        return this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0107/DropDownList", { DeptNo: DeptNo }).pipe(map(response => {
            return response;
        }));
    }

    // 取得科目清單
    getCurDropdownList(curDropdownList: GetCurDropdownListInput) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Cur_List", curDropdownList).pipe(map(response => {
            return response;
        }));
    }

    // 取得進修狀態
    getStudyStatus() {
        return this.http.get<any>(this.baseUrl + "ACAD/EMP/ADEMP0103/DropDownList").pipe(map(response => {
            return response;
        }));
    }
    //取得其他代碼
    getOtherCode() {
        return this.http.get<any>(this.baseUrl + "ACAD/EMP/ADEMP0006/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    //請假審核狀態代碼(查詢條件)
    getLeaveListStatus() {
        return this.http.get<any>(this.baseUrl + "Public/LeaveList_Status").pipe(map(response => {
            return response;
        }));
    }

    //請假審核狀態代碼
    getApproveLeaveList() {
        return this.http.get<any>(this.baseUrl + "Public/ApproveLeaveList").pipe(map(response => {
            return response;
        }));
    }

    //獎懲類型
    getRewardsList(Type: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Rewards_List", { Type: Type }).pipe(map(response => {
            return response;
        }));
    }

    //獎懲事由
    getSuggestList(Years: string) {
        return this.http.post<any>(this.baseUrl + "Public/Get_Suggest_List", { Years: Years, Term: '' }).pipe(map(response => {
            return response;
        }));
    }

    //調整鐘點類別
    getHourTypeList() {
        return this.http.get<any>(this.baseUrl + "ACAD/EMP/ADEMP0007/DropDownList").pipe(map(response => {
            return response;
        }));
    }

    //課程鐘點類型
    getTchMClassList() {
        return this.http.get<any>(this.baseUrl + "ACAD/EMP/ADEMP0008/DropDownList").pipe(map(response => {
            return response;
        }));
    }
}
