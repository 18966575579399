import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADCUR0501',
  templateUrl: './ADCUR0501.component.html',
  styleUrls: ['./ADCUR0501.component.css']
})
export class ADCUR0501Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
