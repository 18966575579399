<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" #Term>
                    <option value="" disabled >--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <alert type="danger">
        <strong>請注意！</strong>
        <ol>
            <li>學年度/學期請由下拉式選單選擇填寫期間。</li>
            <ul>
                <li>三月填寫為109學年度下學期，即110年2月1日至110年7月31日資料；(以 3月15日為資料調查基準日)</li>
                <li>十月填寫為110學年度上學期，即110年8月1日至111年1月31日資料。(以10月15日為資料調查基準日)</li>
            </ul>
            <li>『學院』請由下拉式選單選擇所屬之學院，該選單之資料來源為學校管理者所設定之學院資料。（不得空白）</li>
            <li>『系所』請由下拉式選單選擇所屬之系所，該選單之資料來源為學校管理者所設定之系所資料。（不得空白）</li>
            <li>『學制』請由下拉式選單選擇所屬之學制，該選單之資料來源為學校管理者所設定之學制資料。（不得空白）</li>
            <li>『年級』請依學生『實際入學』第幾年 (休學期間不計)及依據註冊後之實際人數填寫資料。例如：</li>
            <ul>
                <li>大學四年制第1年入學新生，年級應填寫「第1年」;四年制第2年入學學生，年級應填寫「第2年」。</li>
                <li>大學二年制第1年入學新生，年級應填寫「第1年(請勿填報第3年)」；二年制第2年入學學生，年級應填寫「第2年」。</li>
                <li>轉學生：請依實際轉入之「學制/年級」，填報入學第幾年。</li>
                <li>延畢生：係指超過各校學則所定修業年限之延畢生。</li>
            </ul>
            <li>『正式學籍之在學學生』本表係以學生之「入學身份」為填報基準。請填報本學期具備正式學籍且以當年度3/15或10/15實際在學之學生總人數(含轉學生)，且不包括休(退)學生、選讀生及就讀學分班、無學籍學生及保留入學資格之學生（亦含全學年均於國外之學生數）。</li>
          </ol>
    </alert>
    <div>
        <button class="export-btn mx-auto" (click)="exportReportADSTD0801(Year.value, Term.value)">匯出</button>
    </div>
</div>
