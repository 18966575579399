import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/assets/environments/environment";

export class InitialData {


    baseUrl = environment.apiUrl;
    items: string[] = [];

    constructor(private http: HttpClient) { }

    getYears() {
        return this.http.post<any>(this.baseUrl + 'Public/Get_TimeCntr', {}).pipe(
            map((response) => {
                this.items = [];
                for (let i = Number.parseInt(response.Now_Years) + 1; i >= Number.parseInt(response.Start_Years); i--) {
                    if (i.toString().length == 2) {
                        this.items.push('0' + i.toString());
                    }
                    else {
                        this.items.push(i.toString());
                    }
                }
                return this.items;
            }
            ));
    }
}
