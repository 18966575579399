import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCUPPermanentDetail } from 'src/app/_Models/AD/ADCUP/ADCUP0401/getCUPPermanentDetail';
import { GetCUPPermanentList } from 'src/app/_Models/AD/ADCUP/ADCUP0401/getCUPPermanentList';
import { GetCUPPermanentListInput } from 'src/app/_Models/AD/ADCUP/ADCUP0401/getCUPPermanentListInput';
import { Field } from 'src/app/_Models/field';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUP0401',
    templateUrl: './ADCUP0401.component.html',
    styleUrls: ['./ADCUP0401.component.css']
})
export class ADCUP0401Component implements OnInit {
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    deptList!: GetDeptList[];
    detailDeptList!: GetDeptList[];
    classDeptList!: GetDeptList[];
    eduList!: GetEduList[];
    selKindList!: GetSelectList[];
    curClassList!: GetSelectList[];
    selStyleList!: GetSelectList[];
    cUPPermanentListInput = new GetCUPPermanentListInput();
    cUPPermanentListClassInput = new GetCUPPermanentListInput();
    cUPPermanentList!: GetCUPPermanentList[];
    cUPPermanentClassList!: GetCUPPermanentList[];
    cUPPermanentDetail = new GetCUPPermanentDetail();
    name = ['科目簡稱', '列印名稱', '英文名稱', '所屬科系', '開課學期', '必選修別', '使用狀態', '課程類別', '總學分數', '開課學分數',
        '開課時數', '實習時數', '選課人數上限', '選課人數下限', '及格分數', '成績登打方式', '上(下)學期科目代碼', '填寫教學進度', '列印於歷年成績單'];
    value = ['03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'];
    field: Field[] = [];
    filedsNoArray: Array<string> = ['01', '02'];

    ngFormInput = new UntypedFormGroup({
        EduNo: new UntypedFormControl(this.cUPPermanentListInput.EduNo),
        DeptNo: new UntypedFormControl(this.cUPPermanentListInput.DeptNo),
        Status: new UntypedFormControl(this.cUPPermanentListInput.Status),
        SelKind: new UntypedFormControl(this.cUPPermanentListInput.SelKind),
        KeyWord: new UntypedFormControl(this.cUPPermanentListInput.KeyWord),
        OrderBy: new UntypedFormControl(this.cUPPermanentListInput.OrderBy),
        FiledsNo: new UntypedFormControl(this.cUPPermanentListInput.FiledsNo)
    });

    ngFormClassInput = new UntypedFormGroup({
        EduNo: new UntypedFormControl(this.cUPPermanentListClassInput.EduNo),
        DeptNo: new UntypedFormControl(this.cUPPermanentListClassInput.DeptNo),
        Status: new UntypedFormControl(this.cUPPermanentListClassInput.Status),
        SelKind: new UntypedFormControl(this.cUPPermanentListClassInput.SelKind),
        KeyWord: new UntypedFormControl(this.cUPPermanentListClassInput.KeyWord),
        OrderBy: new UntypedFormControl(this.cUPPermanentListClassInput.OrderBy),
        FiledsNo: new UntypedFormControl(this.cUPPermanentListClassInput.FiledsNo)
    });

    ngForm = new UntypedFormGroup({
        DataKey: new UntypedFormControl(this.cUPPermanentDetail.DataKey),
        CosID: new UntypedFormControl(this.cUPPermanentDetail.CosID, [Validators.required, Validators.maxLength(10)]),
        CosName: new UntypedFormControl(this.cUPPermanentDetail.CosName, [Validators.required]),
        CosShort: new UntypedFormControl(this.cUPPermanentDetail.CosShort, [Validators.required, Validators.maxLength(9)]),
        CosPrint: new UntypedFormControl(this.cUPPermanentDetail.CosPrint, [Validators.required, Validators.maxLength(9)]),
        CosEngName: new UntypedFormControl(this.cUPPermanentDetail.CosEngName, [Validators.required]),
        EduNo: new UntypedFormControl(this.cUPPermanentDetail.EduNo, [Validators.required]),
        DeptNo: new UntypedFormControl(this.cUPPermanentDetail.DeptNo, [Validators.required]),
        CosTerm: new UntypedFormControl(this.cUPPermanentDetail.CosTerm, [Validators.required]),
        SelKind: new UntypedFormControl(this.cUPPermanentDetail.SelKind, [Validators.required]),
        Status: new UntypedFormControl(this.cUPPermanentDetail.Status, [Validators.required]),
        CurClass: new UntypedFormControl(this.cUPPermanentDetail.CurClass),
        AllCredits: new UntypedFormControl(this.cUPPermanentDetail.AllCredits, [Validators.required]),
        CosCredits: new UntypedFormControl(this.cUPPermanentDetail.CosCredits, [Validators.required]),
        CosHours: new UntypedFormControl(this.cUPPermanentDetail.CosHours, [Validators.required]),
        PracticeHours: new UntypedFormControl(this.cUPPermanentDetail.PracticeHours, [Validators.required]),
        SelMax: new UntypedFormControl(this.cUPPermanentDetail.SelMax, [Validators.required]),
        SelMin: new UntypedFormControl(this.cUPPermanentDetail.SelMin, [Validators.required]),
        PassScore: new UntypedFormControl(this.cUPPermanentDetail.PassScore, [Validators.required]),
        ScoreInputType: new UntypedFormControl(this.cUPPermanentDetail.ScoreInputType, [Validators.required]),
        TSMark: new UntypedFormControl(this.cUPPermanentDetail.TSMark),
        PrintMark: new UntypedFormControl(this.cUPPermanentDetail.PrintMark),
        RelCosID: new UntypedFormControl(this.cUPPermanentDetail.RelCosID),
        RelCosName: new UntypedFormControl(this.cUPPermanentDetail.RelCosName),
        SelStyle: new UntypedFormControl(this.cUPPermanentDetail.SelStyle, [Validators.required]),
        UpdateUser: new UntypedFormControl(this.cUPPermanentDetail.UpdateUser),
        UpdateTime: new UntypedFormControl(this.cUPPermanentDetail.UpdateTime),
    });

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private modalService: BsModalService,
        private fileExchangeServices: FileExchangeService) {
        for (let index = 0; index < this.name.length; index++) {
            this.field.push({ name: this.name[index], value: this.value[index],Checked:false })
        }
    }

    ngOnInit(): void {
        this.getEduList();
        this.getSelKind();
        this.getSelStyle();

    }

    getSelKind() {
        this.systemService.getSelKind().subscribe((response) => {
            this.selKindList = response;
        })
    }

    getCurClass(DeptNo: string) {
        this.systemService.getCurClass(DeptNo).subscribe((response) => {
            console.log(response);
            if (response.Info == false) {
                //this.toastr.warning(response.Message);
                return;
            }
            this.curClassList = response;
        })
    }

    getSelStyle() {
        this.systemService.getSelStyle().subscribe((response) => {
            this.selStyleList = response;
        })
    }

    getEduList() {
        this.systemService.getEduList('', '', '', 'Y').subscribe((response) => {
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, detail: boolean, searchClass: boolean) {
        this.systemService.getDeptList(edu, '', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (detail) {
                this.detailDeptList = response;
                return;
            }

            if (searchClass) {
                this.classDeptList = response;
                return;
            }

            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getCUPPermanentList(searchClass: boolean) {
        this.cUPPermanentClassList = [];
        this.cUPPermanentList = [];

        if (searchClass) {
            this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0401/Get_CUP_Permanent_List", this.ngFormClassInput.value).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.cUPPermanentClassList = response;
                this.cUPPermanentClassList.forEach(element => {
                    element.Selected = false;
                });
            })
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0401/Get_CUP_Permanent_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUPPermanentList = response;
            this.cUPPermanentList.forEach(element => {
                element.Selected = false;
            });
        })
    }

    setCos(CosID: string, CosName: string) {
        this.ngForm.get('RelCosID')?.setValue(CosID);
        this.ngForm.get('RelCosName')?.setValue(CosName);
        this.modalRef.hide();
    }
    clearCos() {
        this.ngForm.get('RelCosID')?.setValue('');
        this.ngForm.get('RelCosName')?.setValue('');
    }

    getCUPPermanentModify() {
        console.log(this.ngForm)
        if (!this.ngForm.valid) {
            this.toastr.info("請檢查欄位正確或填寫完畢")
            return;
        }

        console.log(this.ngForm.value);

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0401/Get_CUP_Permanent_Modify", this.ngForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }

    getCUPPermanentDelete() {

        if (this.ngForm.get('DataKey')?.value == '') {
            this.toastr.info("請選擇項目");
            return;
        }

        Swal.fire({
            title: "確定刪除？",
            showCancelButton: true,
            cancelButtonText: "取消",
            showConfirmButton: true,
            confirmButtonText: "確定",
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0401/Get_CUP_Permanent_Delete", { DataKey: this.ngForm.get('DataKey')?.value }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.ngForm.reset();
                    this.getCUPPermanentList(false);
                })
            }
            else {
                this.toastr.info("動作已取消");
            }
        })
    }

    getCUPPermanentDetail(dataKey: string, index: number) {

        this.cUPPermanentList.forEach(element => {
            element.Selected = false;
        });

        this.cUPPermanentList[index].Selected = true;

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0401/Get_CUP_Permanent_Detail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.ngForm.setValue(response);
            this.getCurClass(this.ngForm.get("DeptNo")?.value);
        })
    }

    openModal(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setField(value: string) {
        let index = this.filedsNoArray.indexOf(value);
        if (index == -1) {
            this.filedsNoArray.push(value);
        }
        else {
            this.filedsNoArray.splice(index, 1);
        }
    }

    exportCUPPermanent() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請至少選擇啟用狀態");
            return;
        }

        let filedsNo = '';
        this.filedsNoArray.forEach(element => {
            filedsNo = filedsNo + element + ',';
        });

        this.ngFormInput.get('FiledsNo')?.setValue(filedsNo.substring(0, filedsNo.length - 1));

        this.fileExchangeServices.ExportFile("ACAD/CUP/ADCUP0401/Export_CUPPermanent", this.ngFormInput.value).subscribe((response) => {
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = '永久課程檔.xls';
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        })
    }
}
