<form #queryForm="ngForm">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="Years">{{'學年' | translate}}</span>
                <select class="form-select shadow-none" name="Years" [(ngModel)]="model.Years"
                    (change)="GetData(model)">
                    <option value="" disabled selected>{{'--請選擇學年--' | translate}}</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="Term">{{'學期' | translate}}</span>
                <select class="form-select shadow-none" name="Term" [(ngModel)]="model.Term" (change)="GetData(model)">
                    <option value="" selected disabled>{{'--請選擇學期--' | translate}}</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
</form>