<div class="container" style="margin-bottom: 100px;">
    <div class="row mb-3">
        <div class="col-md">
            <div class="card">
                <div class="card-header header"></div>
                <div class="card-body">
                    <h5 class="card-title">{{'現有教材查詢'|translate}}</h5>
                    <div class="card-text">
                        <div class="row">
                            <div class="col-md">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{'類型'|translate}}</span>
                                    </div>
                                    <select class="form-select shadow-none" #searchType>
                                        <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                                        <option value="A">{{'教材'|translate}}</option>
                                        <option value="B">{{'作業'|translate}}</option>
                                        <option value="C">{{'測驗'|translate}}</option>
                                        <option value="D">{{'影片'|translate}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{'關鍵字'|translate}}</span>
                                    </div>
                                    <input type="text" class="form-control shadow-none" placeholder="{{'關鍵字'|translate}}" #keyword>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="search-btn" (click)="getTchFileList(searchType.value, keyword.value)">{{'查詢'|translate}}</button>
                </div>
            </div>
        </div>
        <div class="col-md">
            <div class="card">
                <div class="card-header header"></div>
                <div class="card-body">
                    <h5 class="card-title">{{'上傳教材'|translate}}</h5>
                    <div class="card-text">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'類型'|translate}}</span>
                            </div>
                            <select class="form-select shadow-none" #uploadType>
                                <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                                <option value="A">{{'教材'|translate}}</option>
                                <option value="B">{{'作業'|translate}}</option>
                                <option value="C">{{'測驗'|translate}}</option>
                                <option value="D">{{'影片'|translate}}</option>
                            </select>
                        </div>
                    </div>
                    <input type="file" [hidden]="true" id="fileInput" (change)="ImportFile(uploadType.value, $event)">
                    <button class="other-Button-gray me-3" (click)="importInputCkick()">{{'選擇檔案'|translate}}</button>
                    <span class="text-danger fw-bolder">{{'*檔案大小限制：512Mb。'|translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <table class="table table-hover table-striped text-center table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'檔案序號'|translate}}</th>
                <th>{{'檔案類型'|translate}}</th>
                <th>{{'檔案名稱'|translate}}</th>
                <th>{{'操作'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tchFileList">
                <td data-th="檔案序號">{{item.File_No}}</td>
                <td data-th="檔案類型">
                    <ng-container [ngSwitch]="item.Type_No">
                        <ng-container *ngSwitchCase="'A'">{{'教材'|translate}}</ng-container>
                        <ng-container *ngSwitchCase="'B'">{{'作業'|translate}}</ng-container>
                        <ng-container *ngSwitchCase="'C'">{{'測驗'|translate}}</ng-container>
                        <ng-container *ngSwitchCase="'D'">{{'影片'|translate}}</ng-container>
                    </ng-container>
                </td>
                <td data-th="檔案名稱">{{item.File_Name}}</td>
                <td data-th="操作">
                    <a class="pointer text-decoration-none" (click)="exportFile(item.Type_No, item.File_No)"><span
                            class="icon-Downlad-button"></span>{{'下載'|translate}}</a>
                    <a class="pointer text-decoration-none" (click)="deleteData(item.DataKey, item.Type_No)"><span
                            class="icon-Clear-button"></span>{{'刪除'|translate}}</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>