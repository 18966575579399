import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADSTD0205',
  templateUrl: './ADSTD0205.component.html',
  styleUrls: ['./ADSTD0205.component.css']
})
export class ADSTD0205Component implements OnInit {
  baseUrl = environment.apiUrl;
  Years!: Array<string>;
  initialData = new InitialData(this.http);
  eduList!: GetEduList[];
  deptList!: GetDeptList[];
  EduNoData = {
    EduNo: ''
  };
  constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService) { }

  ngOnInit(): void {
    this.getYears();
    this.getEduList();
    this.getDeptList(this.EduNoData.EduNo, false);
  }

  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    });
  }



  getEduList() {
    this.systemService.getEduList('', '', '', 'Y').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.eduList = response;

    }, error => this.toastr.error(error.error))
  }

  getDeptList(edu: string, detail: boolean) {
    this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      if (detail) {
        this.deptList = response;
        return;
      }
      this.deptList = response;

    }, error => this.toastr.error(error.error));
  }

  getStdClassUp(Year: string, EduNo: string, DeptNo: string) {

    Swal.fire({
      title: `注意事項是否已確認過?`,
      showDenyButton: true,
      confirmButtonText: `確認`,
      denyButtonText: `取消`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_05/Get_Std_ClassUp", { Years: Year, EduNo: EduNo, DeptNo: DeptNo }).subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          this.toastr.success("升級作業完成");
        }, error => this.toastr.error(error.error))
      } else if (result.isDenied) {
        this.toastr.info("動作已取消");
      }
    })
  }

}
