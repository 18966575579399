import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appCurClassTimeInput]',
})
export class CurClassTimeInputDirective {

    constructor(private elementRef: ElementRef<HTMLInputElement>) { }

    @HostListener('window:keydown', ['$event'])
    setValue(keyEvent: KeyboardEvent) {
        if(keyEvent.code == 'Backspace'){
           let index = this.elementRef.nativeElement.value.lastIndexOf(' ');
           this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.substring(0, index + 1);
           return
        }
        if (isNaN(Number(keyEvent.key)) && keyEvent.code != 'Backspace') {
            keyEvent.preventDefault();
            return;
        }
        else if(keyEvent.code == 'Space')
        {
            keyEvent.preventDefault();
            return;
        }
        else if (this.elementRef.nativeElement.value.replace(/\s/g, "").length % 3 == 0 &&
            this.elementRef.nativeElement.value.length != 0 &&
            !isNaN(Number(keyEvent.key))) {
            this.elementRef.nativeElement.value += ' ';
        }
        else {
            return;
        }
    }

}
