<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year>
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term>
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">繳款類別</span>
                </div>
                <select #MakeDocNo class="form-select shadow-none">
                    <option value="">--全部--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of makeDocList">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">含就學貸款?</span>
                </div>
                <select class="form-select shadow-none" #Loan>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">入帳日</span>
                </div>
                <input type="text" placeholder="點選設定起始日期" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly #InDateFrom style="background-color: transparent;">
                <div class="input-group-prepend">
                    <span class="input-group-text">至</span>
                </div>
                <input type="text" placeholder="點選設定結束日期" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly #InDateTo style="background-color: transparent;">
            </div>
        </div>
        <div class="col-md-3">
            <button class="export-btn"
                (click)="getTFSReport0603Base64(Year.value, Term.value, MakeDocNo.value, Loan.value, InDateFrom.value, InDateTo.value)">匯出</button>
        </div>
    </div>
</div>