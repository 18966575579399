<div class="container">
    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="clear()">清除</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="add-btn float-end me-3" (click)="insert()">新增</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>課程屬性代碼</th>
                        <th>課程屬性名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="searchDetail(i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.Attributes_No}}</td>
                        <td>{{item.Attributes_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">課程屬性代碼</span>
                        <input type="text" class="form-control shadow-none" maxlength="3"
                            [(ngModel)]="dataDetail.Attributes_No" placeholder="至多三碼">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">課程屬性名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Attributes_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_User">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_Time">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
