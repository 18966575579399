<div class="container">
    <h4 *ngIf="tFSC04List">{{tFSC04List[0].ConYear}}</h4>
    <h4 *ngIf="tFSC04List">{{tFSC04List[0].UseYear}}</h4>
    <button class="other-Button-gray mb-3 float-end" (click)="getTFSC04(template)">{{'減免申請' | translate}}</button>
    <table class="table table-hover text-center table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'申請學年' | translate}}</th>
                <th>{{'申請學期' | translate}}</th>
                <th>{{'申請日期' | translate}}</th>
                <th>{{'申請狀態' | translate}}</th>
                <th>{{'減免金額' | translate}}</th>
                <th>{{'備註' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tFSC04List">
                <td data-th="申請學年">{{item.Years}}</td>
                <td data-th="申請學期">{{item.Term}}</td>
                <td data-th="申請日期">{{item.Update_Time}}</td>
                <td data-th="申請狀態">{{item.MakeDoc_Mark}}</td>
                <td data-th="減免金額">{{item.Relief_Money}}</td>
                <td data-th="備註">{{item.Memo}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'弱勢助學金申請' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <table class="table table-bordered">
            <tr>
                <th colspan="4">{{'學生基本資料' | translate}}</th>
            </tr>
            <tr>
                <td style="width: 80px;">{{'班級' | translate}}</td>
                <td>{{tFSC04Data.Class_Name}}</td>
                <td style="width: 80px;">{{'學號' | translate}}</td>
                <td>{{tFSC04Data.Std_No}}</td>
            </tr>
            <tr>
                <td style="width: 80px;">{{'姓名' | translate}}</td>
                <td>{{tFSC04Data.Std_Name}}</td>
                <td style="width: 80px;">{{'身分證' | translate}}</td>
                <td>{{tFSC04Data.ID_No}}</td>
            </tr>
            <tr>
                <td style="width: 80px;">{{'電話' | translate}}</td>
                <td><input type="text" [(ngModel)]="tFSC04Data.Com_Tel"></td>
                <td style="width: 80px;">{{'手機' | translate}}</td>
                <td><input type="text" [(ngModel)]="tFSC04Data.Mobile_Tel"></td>
            </tr>
            <tr>
                <td style="width: 80px;">E-mail</td>
                <td colspan="3"><input type="text" [(ngModel)]="tFSC04Data.EMail"></td>
            </tr>
            <tr>
                <th colspan="4">{{'請填寫關係人資料' | translate}}</th>
            </tr>
            <tr>
                <th>{{'序號' | translate}}</th>
                <th>{{'稱謂' | translate}}</th>
                <th>{{'姓名' | translate}}</th>
                <th>{{'身分證字號' | translate}}</th>
            </tr>
            <tr *ngFor="let item of tFSC04Data.Family; let i = index">
                <td>{{i+1}}</td>
                <td></td>
                <td>{{item.Guardian_Name}}</td>
                <td>{{item.Guardian_No}}</td>
            </tr>
        </table>
    </div>
</ng-template>