<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">轉系學年</span>
                <select class="form-select shadow-none" [(ngModel)]="stdTurnListInput.TurnYear">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">轉系學期</span>
                <select class="form-select shadow-none" [(ngModel)]="stdTurnListInput.TurnTerm">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學籍狀態</span>
                <select class="form-select shadow-none" [(ngModel)]="stdTurnListInput.Status">
                    <option value="" selected>--請選擇--</option>
                    <option *ngFor="let item of sTDStatusList" value="{{item.Status_No}}">{{item.Status_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">核示狀態</span>
                <select class="form-select shadow-none" [(ngModel)]="stdTurnListInput.Approve">
                    <option value="" selected>--請選擇--</option>
                    <option value="Y">已核准</option>
                    <option value="N">未核准</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">轉入學制</span>
                <select class="form-select shadow-none" #eduNo (change)="getDeptList(eduNo.value)"
                    [(ngModel)]="stdTurnListInput.EduNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">轉入系所</span>
                <select class="form-select shadow-none" #deptNo (change)="getClassList(eduNo.value, deptNo.value)"
                    [(ngModel)]="stdTurnListInput.DeptNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">轉入班級</span>
                <select class="form-select shadow-none" [(ngModel)]="stdTurnListInput.Class">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">身分證號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdTurnListInput.IDNo">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdTurnListInput.StdName">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="stdTurnListInput.StdNo">
            </div>
        </div>
        <div class="col-md-6">
            <button class="other-Button-gray float-end" (click)="getStdTurnDelete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="getStdTurnDetail(true, '', template)">新增</button>
            <button class="search-btn float-end me-3" (click)="getStdTurnList()">查詢</button>
            <button class="other-Button-gray float-end me-3" (click)="import(true, '', templateFile)">匯入</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 70px;"><input type="checkbox" class="pointer"
                        (click)="setDatakey('all', '', $event.target)">&ensp;刪除</th>
                <th>轉系學年</th>
                <th>轉系學期</th>
                <th>學號</th>
                <th>姓名</th>
                <th>申請日</th>
                <th>轉係原因</th>
                <th>核示日</th>
                <th>狀態</th>
                <th>原系所</th>
                <th>原班級</th>
                <th>轉入系所</th>
                <th>轉入班級</th>
                <th>是否降轉</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of stdTurnList">
                <td style="width: 70px;"><input type="checkbox" class="pointer"
                        (click)="setDatakey('', item.DataKey, $event.target)" [checked]="item.checked"></td>
                <td>{{item.TurnYear}}</td>
                <td>{{item.TurnTerm}}</td>
                <td><a class="pointer text-decoration-none"
                        (click)="getStdTurnDetail(false, item.DataKey, template)">{{item.StdNo}}</a></td>
                <td>{{item.StdName}}</td>
                <td>{{item.ApplyDate}}</td>
                <td>{{item.TurnReason}}</td>
                <td>{{item.ApproveDate}}</td>
                <td>{{item.Approve == 'Y'?'已核示':'未核示'}}</td>
                <td>{{item.DeptName}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.TurnDeptName}}</td>
                <td>{{item.TurnClassName}}</td>
                <td>{{item.TurnDown == 'Y'?'是':'否'}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">轉系資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <small class="text-danger">*為必填欄位，請檢查後再送出。</small>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>學號</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdTurnDetail.StdNo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">姓名</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdTurnDetail.StdName" disabled
                        placeholder="儲存時自動帶入">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>轉系學年</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdTurnDetail.TurnYear">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>轉系學期</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdTurnDetail.TurnTerm">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">原班級</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdTurnDetail.ClassName" disabled
                        placeholder="儲存時自動帶入">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>轉系原因</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdTurnDetail.TurnNo">
                        <option value="" disabled>--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of stdTurnReason">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>轉入系所</span>
                    <select class="form-select shadow-none" #newDeptNo
                        (change)="getClassList('', newDeptNo.value); getTeamList(newDeptNo.value)"
                        [(ngModel)]="stdTurnDetail.TurnDeptNo">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of newdeptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>轉入班級</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdTurnDetail.TurnClass">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>轉入組別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdTurnDetail.TurnTeamNo">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text"><span class="text-danger">*</span>申請日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly [(ngModel)]="stdTurnDetail.ApplyDate"
                        style="background-color: transparent;">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text"><span class="text-danger">*</span>核示日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly [(ngModel)]="stdTurnDetail.ApproveDate"
                        style="background-color: transparent;">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text"><span class="text-danger">*</span>核示狀態</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdTurnDetail.Approve">
                        <option value="" selected>--請選擇--</option>
                        <option value="Y">已核准</option>
                        <option value="N">未核准</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label>備註</label>
                <textarea class="form-control shadow-none" appTextareaAutoresize></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="save-btn-gray mx-auto mt-3" (click)="getStdTurnModify()">儲存</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templateFile>
    <div class="modal-header">
        <h4 class="modal-title pull-left">匯入轉系資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group mb-3">
                    <input type="file" id="image" (change)="importData($event);" class="form-control shadow-none" multiple />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="save-btn-gray float-end me-3 mx-auto" (click)="InsertPost()">匯入</button>
            </div>
        </div>
    </div>
</ng-template>


