<div class="modal-header">
    <h4 class="modal-title pull-left">{{'簽核流程' | translate}}</h4>
    <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body">
    <table class="table table-hover table-striped text-center table-rwd table-borderless">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th>{{'項次' | translate}}</th>
                <th>{{'簽核名單' | translate}}</th>
                <th>{{'代理/加簽' | translate}}</th>
                <th>{{'簽核單位' | translate}}</th>
                <th>{{'簽收時間' | translate}}</th>
                <th>{{'核稿時間' | translate}}</th>
                <th>{{'簽核意見' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of eDocFlow">
                <td data-th="項次">{{item.Stage}}</td>
                <td data-th="簽核名單">{{item.UserName}}</td>
                <td data-th="代理/加簽">{{item.AgentName}}</td>
                <td data-th="簽核單位">{{item.UnitName}}</td>
                <td data-th="簽收時間">{{item.ReadDate}}</td>
                <td data-th="核稿時間">{{item.SignDate}}</td>
                <td data-th="簽核意見">{{item.Content}}</td>
            </tr>
        </tbody>
    </table>
</div>
