import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetSELSelMaxMinList } from 'src/app/_Models/AD/ADSEL/ADSEL0106/getSELSelMaxMinList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0106',
    templateUrl: './ADSEL0106.component.html',
    styleUrls: ['./ADSEL0106.component.css']
})
export class ADSEL0106Component implements OnInit {
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    term = require('src/app/_Models/_SelectInput/term.json').term;
    deptList!: GetDeptList[];
    eduList!: GetEduList[];
    classList!: GetClassList[];
    sELSelMaxMinList!: GetSELSelMaxMinList[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl('')
    })
    constructor(private toastr: ToastrService, private http: HttpClient, private systemService: SystemService) { }

    ngOnInit(): void {
        this.getYears();
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList(Years: string, Term: string) {

        if (Years == '' || Term == '') {
            return;
        }

        this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(Years: string, Term: string, edu: string) {
        this.systemService.getDeptList(edu, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    getClassList(Years: string, Term: string, edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;
        }, error => this.toastr.error(error.error))

    }

    getSELSelMaxMinList() {
        if (!this.ngFormInput) {
            this.toastr.info("請選擇欄位");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0106/Get_SEL_SelMaxMin_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sELSelMaxMinList = []
                return;
            }
            this.sELSelMaxMinList = response;
        });
    }

    getSELSelMaxMinSave() {
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0106/Get_SEL_SelMaxMin_Save", this.sELSelMaxMinList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.getSELSelMaxMinList()
                return;
            }
            this.toastr.success("儲存成功");
        });
    }

    selectAll(event: EventTarget | null) {

        if (!this.sELSelMaxMinList) return;

        let input = <HTMLInputElement>event;

        if (input.checked) {
            this.sELSelMaxMinList.forEach(element => {
                element.checked = true;
            });
        }
        else {
            this.sELSelMaxMinList.forEach(element => {
                element.checked = false;
            });
        }
    }

    setNumber(max: string, min: string) {

        if (parseInt(max) < parseInt(min)) {
            this.toastr.info("人數上限不得小於下限");
            return;
        }

        this.sELSelMaxMinList.forEach(element => {
            if (element.checked) {
                element.SelMax = parseInt(max);
                element.SelMin = parseInt(min);
            }
        });
    }
}
