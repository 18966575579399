import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { GetEDocFlow } from "src/app/_Models/getEDocFlow";
import { HttpClient } from '@angular/common/http';
import { SystemService } from "src/app/_Services/_Shared/system.service";
import { environment } from "src/assets/environments/environment";
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-std-sign-off',
    templateUrl: './std-sign-off.component.html',
    styleUrls: ['./std-sign-off.component.css']
})

export class StdSignOffComponent implements OnInit {
    @Input() inputstd: any
    eDocFlow!: GetEDocFlow[];
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    subpageconfig = { backdrop: true, ignoreBackdropClick: true, class: "modal-lg" };
    constructor(private http: HttpClient, private systemService: SystemService, private toastr: ToastrService, private modalService: BsModalService) { }
    ngOnInit(): void {
    }
}
