<div class="container" style="margin-bottom: 100px;" *ngIf="data">
    <h2 class="text-center fw-bolder">{{data.Type == 'M'?'期中':'期末'}}教學評量查詢結果</h2>
    <div class="row">
        <div class="col-md-4 fw-bolder">{{data.Years}}學年第{{data.Term}}學期</div>
        <div class="col-md-4 fw-bolder">開課班級：{{data.Class_Name}}</div>
        <div class="col-md-4 fw-bolder">科目名稱：{{data.Cos_Name}}</div>
    </div>
    <div class="row">
        <div class="col-md-4 fw-bolder">任課老師：{{data.Tch_Name}}</div>
        <div class="col-md-4 fw-bolder">選課人數：{{data.Select_Count}}</div>
        <div class="col-md-4 fw-bolder">填寫人數：{{data.Write_Count}}</div>
    </div>
    <div class="row">
        <div class="col-md-4 fw-bolder">總平均分數：{{data.Avg_Score}}</div>
        <div class="col-md-4 fw-bolder">標準差：{{data.SD_Score}}</div>
        <div class="col-md-4"></div>
    </div>
    <h2 class="text-center text-danger mt-3">一般問卷</h2>
    <div class="mt-3" *ngFor="let item of data.Styles">
        <ng-container *ngIf="item.StyleType == 'Option'">
            <h2 class="text-center">{{item.Style_Name}}</h2>
            <div class="" *ngFor="let subject of item.Subjects">
                <h3 class="text-info">{{subject.SubjectName}}</h3>
                <div class="" *ngFor="let question of subject.Question">
                    <div>{{question.Question_Name}}</div>
                    <div class="row mb-3">
                        <div class="col-6 col-md border p-1" *ngFor="let Answer of question.AnswerType">
                            <font class="fw-bolder">{{Answer.Answer_Name}}</font>
                            {{'：' + Answer.Answer_Count}}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- <ng-container *ngIf="item.StyleType == 'Text'">
            <h2 class="text-center">{{item.Style_Name}}</h2>
            <ul>
                <li *ngFor="let text of item.SubjectsText">{{text}}</li>
            </ul>
        </ng-container> -->
    </div>
    <div>
        <h2 class="text-center">其他意見</h2>
        <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let text of data.SubjectsText">{{text.Suggestion}}</li>
        </ul>
    </div>

</div>