export class GetCUPPgUnRegularPlanModifyInput{
    DataKey: string = ''
    CosID: string = ''
    CosName: string = ''
    SelKindName: string = ''
    Credits: string = ''
    CosHours: string = ''
    CosTerm: string = ''
    Grade: string = ''
    SelStyle: string = ''
    CurClass: string = ''
    CosCluster: string = ''
    MustPassNum: number = 0
    UpdateUser: string = ''
    UpdateTime: string = ''
    Selected: boolean = false
}