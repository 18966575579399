<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'學年'|translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="model.Years">
                    <option value="" selected disabled>{{'--請選擇學年--'|translate}}</option>
                    <option value="{{item}}" *ngFor="let item of Years">{{item}}{{'學年'|translate}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'學期'|translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="model.Term">
                    <option value="" selected disabled>{{'--請選擇學期--'|translate}}</option>
                    <option value="1">{{'第一學期'|translate}}</option>
                    <option value="2">{{'第二學期'|translate}}</option>
                    <option value="3">{{'寒假'|translate}}</option>
                    <option value="4">{{'暑假'|translate}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'學制'|translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="model.EduNo" (change)="getDeptList($event)">
                    <option value="" selected disabled>{{'--請選擇學制--'|translate}}</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'學系'|translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="model.DeptNo" (change)="getClassList($event)">
                    <option value="" selected disabled>{{'--請選擇學系--'|translate}}</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'班級'|translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="model.Class">
                    <option value="" selected>{{'--請選擇班級--'|translate}}</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'科目名稱/教師姓名查詢'|translate}}</span>
                </div>
                <input type="text" class="form-control shadow-none" [(ngModel)]="model.KeyValue">
            </div>
        </div>
        <div class="col-md-3">
            <button type="button" class="search-btn" (click)="getPublicCurList()">{{'查詢'|translate}}</button>
        </div>
    </div>

    <table class="table table-hover table-sm table-rwd text-center" *ngIf="courseList">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'班級'|translate}}</th>
                <th>{{'開課序號'|translate}}</th>
                <th>{{'科目代碼'|translate}}</th>
                <th>{{'科目名稱'|translate}}</th>
                <th>{{'學分'|translate}}</th>
                <th>{{'時數'|translate}}</th>
                <th>{{'全半學年'|translate}}</th>
                <th>{{'修別'|translate}}</th>
                <th>{{'老師'|translate}}</th>
                <th>{{'時間'|translate}}</th>
                <th>{{'地點'|translate}}</th>
                <th>{{'已選/上限人數'|translate}}</th>
                <th>{{'合班開課班級'|translate}}</th>
                <th>{{'備註'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let curList of courseList">
                <td data-th="開課班級">{{curList.OP_Class}}</td>
                <td data-th="開課序號">{{curList.OP_Series}}</td>
                <td data-th="科目代碼">{{curList.Cos_ID}}</td>
                <td data-th="科目名稱">{{curList.Cos_Name}}</td>
                <td data-th="學分數">{{curList.Cos_Credit}}</td>
                <td data-th="時數">{{curList.Cos_Hours}}</td>
                <td data-th="全半學年">{{curList.Semester}}</td>
                <td data-th="修別">{{curList.Cos_Category}}</td>
                <td data-th="任課老師">{{curList.Tch_Name}}</td>
                <td data-th="上課時間">{{curList.Cur_Time}}</td>
                <td data-th="上課地點">{{curList.Class_Location}}</td>
                <td data-th="已選/上限人數">{{curList.People_Limit}}</td>
                <td data-th="合班開課班級"></td>
                <td data-th="備註">{{curList.Memo}}</td>
            </tr>
        </tbody>
    </table>
</div>