import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { decode, encode } from 'src/app/_SharedFunctions/base64URL-ArrayBuffer';
import { environment } from 'src/assets/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class FidoService {

    baseUrl = environment.apiUrl;
    domain = environment.domain;
    constructor(private http: HttpClient) {
    }

    getDeviceKey() {
        return this.http.get<any>(this.baseUrl + "Login/Get_DeviceKey").pipe(map(response => {
            return response;
        }));
    }

    checkRegiAccount(code: string, deviceKey: string) {
        return this.http.post<any>(this.baseUrl + "Login/Check_RegiAccount", {
            Code: code,
            DeviceKey: deviceKey,
            Verify: "F"
        }).pipe(map(response => {
            return response;
        }))
    }

    requestReg(code: string, deviceKey: string) {
        var fido2RequestRegStr =
        {
            header: {
                appVersion: "v1",
                channelCode: "hi.fido2.poc",
                deviceBrand: "google",
                deviceType: "chrome",
                deviceUuid: "xxx",
                deviceVersion: "92.0.4515.107",
                userIp: "localhost​"
            },
            body: {
                attestation: "none",
                displayName: code,
                friendlyName: "",
                origin: this.domain.homePage,
                rpId: this.domain.name,
                rpName: "Hi Fido2",
                username: code
            }
        };

        return this.http.post<any>(this.baseUrl + "Login/RequestRegi_FIDO", {
            Code: code,
            DeviceKey: deviceKey,
            PublicKey: JSON.stringify(fido2RequestRegStr),
            Model: "requestReg"
        }).pipe(map(response => {
            return response;
        }));
    }

    preformatMakeCredReq(makeCredReq: any) {
        makeCredReq.challenge = decode(makeCredReq.challenge);
        makeCredReq.user.id = decode(makeCredReq.user.id);
        makeCredReq.excludeCredentials = [];
        return makeCredReq
    }

    preformatGetAssertReq(getAssert: any) {
        getAssert.challenge = decode(getAssert.challenge);
        if (getAssert.allowCredentials) {
            for (let allowCred of getAssert.allowCredentials) {
                allowCred.id = decode(allowCred.id);
            }
        }
        return getAssert
    }

    publicKeyCredentialToJSON(pubKeyCred: any): any {
        if (pubKeyCred instanceof Array) {
            let arr = [];
            for (let i of pubKeyCred)
                arr.push(this.publicKeyCredentialToJSON(i));
            return arr
        }

        if (pubKeyCred instanceof ArrayBuffer) {
            return encode(pubKeyCred)
        }

        if (pubKeyCred instanceof Object) {
            let obj: any = {};

            for (let key in pubKeyCred) {
                obj[key] = this.publicKeyCredentialToJSON(pubKeyCred[key])
            }

            return obj
        }

        return pubKeyCred
    }


    regiFIDO(model: any) {
        return this.http.post<any>(this.baseUrl + "Login/Regi_FIDO", model).pipe(map(response => {
            return response;
        }))
    }

    requestAuth(code: string, deviceKey: string) {
        var fido2RequestAuthStr = {
            header: {
                appVersion: "v1",
                channelCode: "hi.fido2.poc",
                deviceBrand: "google",
                deviceType: "chrome",
                deviceUuid: "xxx",
                deviceVersion: "92.0.4515.107",
                userIp: "localhost"
            },
            body: {
                username: code,
                rpId: this.domain.name,
                origin: this.domain.homePage
            }
        }

        return this.http.post<any>(this.baseUrl + "Login/RequestAuth_FIDO", {
            Code: code,
            DeviceKey: deviceKey,
            PublicKey: JSON.stringify(fido2RequestAuthStr),
            Model: "requestAuth",
        }).pipe(map(response => {
            return response;
        }))
    }

    doAuth(model: any) {
        return this.http.post<any>(this.baseUrl + "Login/Auth_FIDO", model).pipe(map(response => {
            return response;
        }));
    }
}

