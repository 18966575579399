import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/assets/environments/environment';
import { GetCurrentLocation } from './_Models/getCurrentLocation';
import { SystemService } from './_Services/_Shared/system.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})


export class AppComponent {
    routerPath: string = '';
    baseUrl = environment.apiUrl;
    currentLocation!: GetCurrentLocation[] | undefined;

    useSearch: boolean = false;

    constructor(private router: Router, private systemService: SystemService, private http: HttpClient, private toastr: ToastrService, private translateService: TranslateService) {
        //同時具有監聽的功能
        this.router.events.subscribe((data) => {
            //data返回一堆路由事件，所以得篩選自己需要的，這裡選擇路由導航之後。
            if (data instanceof NavigationEnd) {
                this.routerPath = data.url.substr(1);
                //console.log(this.routerPath);
                // console.log(this.routerPath.split('?'));
                // console.log(this.routerPath.toLocaleLowerCase().indexOf("code"));
                if (this.routerPath.split('?')[1] != undefined) {
                    if (this.routerPath.split('?')[1].indexOf("code") > 0 && this.routerPath.split('?')[0] == "") {
                        this.routerPath = '';
                    }
                }

                if (this.routerPath != '') {
                    let FunID=this.routerPath.split('?')[0].split('/')[0];

                    if (FunID == 'ADFunc') {
                        this.getCurrentLocation(this.routerPath.substring(7));
                        this.pageLoad('ADFunc');
                    } else {
                        if (this.routerPath == 'Page500') {
                            this.getCurrentLocation(FunID);
                            this.pageLoad(FunID);
                        }
                        else{
                            this.getCurrentLocation(FunID);
                            this.pageLoad(FunID);
                        }
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.systemService.getSchoolInfo();

        this.CheckPurviewSearch();
    }

    //現在位子
    getCurrentLocation(FunID: string) {
        if (FunID == 'Home' || FunID == 'Favourite' || FunID == 'profile') {
            this.currentLocation = undefined
            return;
        }
        this.http.post<any>(this.baseUrl + "Portal/Get_Location", {
            FunID: FunID,
            IsMobile: this.systemService.checkIsMobile() == true ? 'Y' : ''
        }).subscribe((response) => {
            this.currentLocation = response;
        })
    }

    //每頁檢查權限
    pageLoad(FunID: string) {
        this.http.post<any>(this.baseUrl + "Public/Page_Load", { FunID: FunID }).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.router.navigateByUrl('/Home');
            }
        });
    }

    //檢查搜尋權限
    CheckPurviewSearch() {

        if (sessionStorage.getItem('auth') != null) {
            // console.log(JSON.parse(sessionStorage.getItem('auth') || '').UseSearch);
            if (JSON.parse(sessionStorage.getItem('auth') || '').UseSearch) {
                this.useSearch = true;
            }
        }

    }
}

