<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">{{'學制'|translate}}</span>
                <select class="form-select shadow-none search-input" #EduNo>
                    <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3" *ngIf="parameterValues">
            <div class="input-group flex-nowrap mb-3" *ngIf="parameterValues[0].ValueType != 'bool'">
                    <span class="input-group-text">{{'設定預設值'|translate}}</span>
                <ng-container *ngIf="parameterValues[0].ValueType == 'select'">
                    <select class="form-select shadow-none search-input" [(ngModel)]="stringDefaultValue">
                        <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                        <option value="1/2">1/2</option>
                        <option value="1/3">1/3</option>
                        <option value="2/3">2/3</option>
                        <option value="1/4">1/4</option>
                        <option value="3/4">3/4</option>
                        <option value="1/5">1/5</option>
                        <option value="2/5">2/5</option>
                        <option value="3/5">3/5</option>
                        <option value="4/5">4/5</option>
                        <option value="98/99">98/99</option>
                    </select>
                </ng-container>
                <ng-container *ngIf="parameterValues[0].ValueType == 'int'">
                    <input type="number" class="form-control shadow-none" min="0" value="0"
                        [(ngModel)]="intDefaultValue">
                </ng-container>
            </div>
            <ng-container *ngIf="parameterValues[0].ValueType == 'bool'">
                <div class="form-check mt-2">
                    <input class="form-check-input shadow-none pointer" type="checkbox" id="flexCheckDefault"
                        [(ngModel)]="boolDefaultValue">
                    <label class="form-check-label pointer" for="flexCheckDefault">{{'設定預設全選'|translate}}</label>
                </div>
            </ng-container>
        </div>
        <div class="col-md-6 mb-3">
            <button class="other-Button-gray float-start me-3" *ngIf="parameterValues"
                (click)="setDefault(EduNo.value)">{{'設定預設'|translate}}</button>
            <button class="save-btn-gray float-start" *ngIf="parameterValues"
                (click)="getSELParametersSave()">{{'儲存'|translate}}</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="list-group">
                <a class="list-group-item list-group-item-action pointer"
                    *ngFor="let item of sELParametersList; let i = index"
                    (click)="setParameterValues(i)">{{item.ParameterName}}</a>
            </div>
        </div>
        <div class="col-md-8">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th>{{'學制'|translate}}</th>
                        <th>{{'系所'|translate}}</th>
                        <th>{{'設定'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of parameterValues">
                        <td>{{item.EduName}}</td>
                        <td>{{item.DeptName}}</td>
                        <td>
                            <ng-container *ngIf="item.ValueType == 'int'">
                                <input type="number" min="0" class="form-control form-control-sm shadow-none"
                                    [(ngModel)]="item.IntValue">
                            </ng-container>
                            <ng-container *ngIf="item.ValueType == 'bool'">
                                <input type="checkbox" class="form-check-input shadow-none"
                                    [(ngModel)]="item.BoolValue">
                            </ng-container>
                            <ng-container *ngIf="item.ValueType == 'select'">
                                <select class="form-select form-select-sm shadow-none" [(ngModel)]="item.TextValue">
                                    <option value="">{{'請選擇'|translate}}</option>
                                    <option value="1/2">1/2</option>
                                    <option value="1/3">1/3</option>
                                    <option value="2/3">2/3</option>
                                    <option value="1/4">1/4</option>
                                    <option value="3/4">3/4</option>
                                    <option value="1/5">1/5</option>
                                    <option value="2/5">2/5</option>
                                    <option value="3/5">3/5</option>
                                    <option value="4/5">4/5</option>
                                    <option value="98/99">98/99</option>
                                </select>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>