import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GetEDocFlow } from 'src/app/_Models/getEDocFlow';


@Component({
    selector: 'app-flow',
    templateUrl: './flow.component.html',
    styleUrls: ['./flow.component.css']
})
export class FlowComponent implements OnInit {
    modalRef!: BsModalRef;
    eDocFlow!: GetEDocFlow[];

    constructor() { }

    ngOnInit(): void {
    }

}
