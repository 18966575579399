<div class="container">
    <!-- <alert type="danger">
        <strong>{{model.SchName}} {{model.Years}} 學年度第 {{model.Term}} 學期系際承認維護</strong>
    </alert> -->
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"學年"|translate}}</span>
                    <select class="form-select shadow-none" formControlName="Years">
                        <option value="" disabled>{{"--請選擇--"|translate}}</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}{{"學年"|translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"學期"|translate}}</span>
                    <select class="form-select shadow-none" formControlName="Term">
                        <option value="" disabled>{{"--請選擇--"|translate}}</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{"系所"|translate}}</span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="" selected disabled>{{"--請選擇系所--"|translate}}</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="col-md-12 mb-3">
                    <button class="search-btn float-start me-3" (click)="getCosList()">{{"查詢"|translate}}</button>
                </div>
            </div>            
        </div>
    </form>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width:30px;vertical-align:middle;"><p style="margin:3px;">{{"開課"|translate}}</p><p style="margin:3px;">{{"部別"|translate}}</p></th>
                <th style="width:120px;vertical-align:middle;text-align:left;">{{"開課系別"|translate}}</th>
                <th style="width:30px;vertical-align:middle;"><p style="margin:3px;">{{"開課"|translate}}</p><p style="margin:3px;">{{"組別"|translate}}</p></th>
                <th style="width:200px;vertical-align:middle;text-align:left;">{{"科目名稱"|translate}}</th>
                <th style="width:40px;vertical-align:middle;">{{"學分"|translate}}</th>
                <th style="width:70px;vertical-align:middle;">{{"開課選修別"|translate}}</th>
                <th style="width:70px;vertical-align:middle;">{{"系際選修別"|translate}}</th>
                <th style="width:90px;vertical-align:middle;">{{"設定系際承認"|translate}}</th>
                <th style="width:70px;vertical-align:middle;"><p style="margin:3px;">{{"勾選不承認"|translate}}</p><p style="margin:3px;">{{"學生名單"|translate}}</p></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of CosList">
                <td style="width:30px;text-align:center;vertical-align:middle;">{{item.EduNo}}</td>
                <td style="width:120px;text-align:left;vertical-align:middle;">{{item.DeptNo}}{{item.DeptName}}</td>
                <td style="width:30px;text-align:center;vertical-align:middle;">{{item.TeamNo}}</td>
                <td style="width:200px;text-align:left;vertical-align:middle;">{{item.CosID5}}{{item.CosName}}</td>
                <td style="width:40px;text-align:center;vertical-align:middle;">{{item.CosCredit}}</td>
                <td style="width:70px;text-align:center;vertical-align:middle;">{{item.SelKindName}}</td>
                <td style="width:70px;text-align:center;vertical-align:middle;">
                    <select class="form-select shadow-none" #AgreeSelkind [(ngModel)]="item.AgreeSelkind"
                        (change)="CosItemSave(item)">
                        <option *ngFor="let item of selKindList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>                    
                </td>
                <td style="width:90px;text-align:center;vertical-align:middle;">
                    <select class="form-select shadow-none" #Agree [(ngModel)]="item.Agree" 
                        (change)="CosItemChange(item,Agree)" (focus)="CosItemValue(Agree)">
                        <option value=""></option>
                        <option value="Y">{{"本系承認"|translate}}</option>
                        <option value="N">{{"本系不承認"|translate}}</option>
                    </select>                      
                </td>
                <td style="width:70px;text-align:center;vertical-align:middle;">
                    <button class="other-Button-gray float-start"
                    (click)="openModel(template,item)">{{"學生名單"|translate}}</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{"勾選不承認學生名單|translate"}}</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body"> 
        <div class="row">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 120px;">{{"開課系級"|translate}}</th>
                        <th style="width: 160px;">{{"課程名稱"|translate}}</th>
                        <th style="width: 90px;">{{"學號"|translate}}</th>
                        <th style="width: 90px;">{{"姓名"|translate}}</th>
                        <th style="width: 120px;">{{"系級"|translate}}</th>
                        <th style="width: 70px;">{{"設定系際承認"|translate}}</th>
                    </tr>
                </thead>
                <tbody style="height:400px;overflow-y:scroll;">
                    <tr *ngFor="let item of StdList">
                        <td style="width: 120px;">{{item.OpClass}}{{item.OpClassName}}</td>
                        <td style="width: 160px;">{{item.CosID}}{{item.CosName}}</td>
                        <td style="width: 90px;">{{item.StdNo}}</td>
                        <td style="width: 90px;">{{item.StdName}}</td>
                        <td style="width: 120px;">{{item.ClassName}}</td>
                        <td style="width: 70px;">
                            <input class="form-check-input me-1" type="checkbox" (click)="StdItemChange(item)" [checked]="Agree" #Agree [(ngModel)]="item.Agree">{{"不承認"|translate}}
                        </td>                           
                    </tr>
                </tbody>                    
            </table>
        </div>
    </div>
</ng-template>