<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year [(ngModel)]="getStdRestListInput.RestYear">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" #Term [(ngModel)]="getStdRestListInput.RestTerm">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">取回否</span>
                <select class="form-select shadow-none" [(ngModel)]="getStdRestListInput.Approve">
                    <option value="" disabled>--請選擇--</option>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" [(ngModel)]="getStdRestListInput.EduNo" #eduNo
                    (change)="getDeptList(eduNo.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" [(ngModel)]="getStdRestListInput.DeptNo" #deptNo
                    (change)="getClassList(eduNo.value, deptNo.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班級</span>
                <select class="form-select shadow-none" [(ngModel)]="getStdRestListInput.Class">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">證書樣式</span>
                <select class="form-select shadow-none">
                    <option value="" disabled>--請選擇--</option>
                    <option value="ch">中文</option>
                    <option value="en">英文</option>
                    <option value="ce">中英文</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-md-4">
            <button class="search-btn float-start me-3">查詢</button>
            <button class="other-Button-gray float-start me-3">匯出檔案</button>
            <button class="other-Button-gray float-start me-3">取回</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>學年</th>
                <th>學期</th>
                <th>學號</th>
                <th>姓名</th>
                <th>班級</th>
                <th>取回否</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </table>
</div>