import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject } from 'rxjs';
import { environment } from 'src/assets/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LineSsoService {
    baseUrl = environment.apiUrl;
    private lineApiSource = new ReplaySubject<{
        LoginChannelID: string,
        LoginCallbackURL: string
    }>(1);
    lineApiSource$ = this.lineApiSource.asObservable();
    constructor(private http: HttpClient, private toastr: ToastrService) {
        this.GetLineAPI();
    }

    GetLineAPI() {
        this.http.get<any>(this.baseUrl + "Login/Get_LineAPI").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning("LineAPI錯誤");
                return;
            }
            this.lineApiSource.next(response);
        })
    }

    regiLine(LineCode: string) {
        return this.http.post<any>(this.baseUrl + "Login/Regi_LINE", { LineCode: LineCode });
    }

    authLine(LineCode: string) {
        return this.http.post<any>(this.baseUrl + "Login/Auth_LINE", { LineCode: LineCode });
    }
}
