
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADCUR0603',
  templateUrl: './ADCUR0603.component.html',
  styleUrls: ['./ADCUR0603.component.css']
})
export class ADCUR0603Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}


