export class FileExchange {

    Base64ToBlob(b64Data: string, contentType: string, sliceSize: number) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    FileToBase64(that: any, file: any, GetBase64: any, dataKey: string, type: string) {
        let reader = new FileReader();
        let q: Array<string> = [];
        reader.readAsDataURL(file);
        reader.onload = function () {
            q = reader.result?.toString().split(',') || [];
            GetBase64(that, q[1], file, dataKey, type);
        }
    }

    //檔案轉Base64字串
    FileToBase64String(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let dataarray: Array<string> = [];
                dataarray = reader.result?.toString().split(',') || [];
                resolve(dataarray[1]);
            };
            reader.onerror = error => reject(error);
        });
    }

    //Base64字串下載檔案
    Base64StringToDownloadFile(fileName: string, base64String: string): void {
        let link1 = document.createElement('a');
        link1.href = 'data:octet-stream;base64,' + base64String
        link1.download = fileName;
        document.body.appendChild(link1);
        link1.click();
        document.body.removeChild(link1);
    }
}
