<div class="container">
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 60px;"></th>
                <th>代碼</th>
                <th>必選修別名稱</th>
                <th>必選修別簡稱</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data; let i = index">
                <td style="width: 60px;">{{i + 1}}</td>
                <td>{{item.SelKind}}</td>
                <td>{{item.SelKind_Name}}</td>
                <td>{{item.SelKind_Short}}</td>
            </tr>
        </tbody>
    </table>
</div>
