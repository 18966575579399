<div class="container">
    <div class="row">
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">入學學年</span>
                <select class="form-select shadow-none" name="Years" [(ngModel)]="getStdNewListInput.Years">
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">入學學期</span>
                <select class="form-select shadow-none" name="Term" [(ngModel)]="getStdNewListInput.Term">
                    <option value="" disabled>--全部--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value, false)"
                    [(ngModel)]="getStdNewListInput.EduNo">
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" #dept
                    (change)="getClassList(edu.value, dept.value, false); getTeamList(dept.value, false)"
                    [(ngModel)]="getStdNewListInput.DeptNo">
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">組別</span>
                <select class="form-select shadow-none" [(ngModel)]="getStdNewListInput.TeamNo">
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班別</span>
                <select class="form-select shadow-none" [(ngModel)]="getStdNewListInput.Class">
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="getStdNewListInput.StdNo">
            </div>
        </div>        
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">身分證號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="getStdNewListInput.IDNo">
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">報到狀態</span>
                <select class="form-select shadow-none" [(ngModel)]="getStdNewListInput.CheckIn">
                    <option value="" selected>--全部--</option>
                    <option value="Y">已完成報到</option>
                    <option value="N">未完成報到</option>
                </select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">轉入狀態</span>
                <select class="form-select shadow-none" [(ngModel)]="getStdNewListInput.InsertIn">
                    <option value="" selected>--全部--</option>
                    <option value="Y">已轉入學籍</option>
                    <option value="N">未轉入學籍</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">每班學生數</span>
                <input type="number" min="0" class="form-control shadow-none" [defaultValue]="30" #Classmates>                 
            </div>
        </div>
        <div class="col-md-2">
            <button class="other-Button-gray float-start ms-3"
            (click)="getStdNewDivideClass(parseInt(Classmates.value))">分班</button>   
        </div>
        <div class="col-md-2">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">開始學號</span>
                <input type="number" min="0" class="form-control shadow-none" [defaultValue]="1" #StartNo>
            </div>
        </div>
        <div class="col-md-2">
            <button class="other-Button-gray float-start ms-3" (click)="getStdNewSetStdNo(parseInt(StartNo.value))">編學號</button>
        </div>
        <div class="col-md-4">
            <button class="other-Button-gray float-end ms-3" style="width: 100px;"
                (click)="getStdNewDelete()">刪除新生</button>
            <button class="other-Button-gray float-end ms-3" style="width: 100px;"
                (click)="getStdNewTrans()">匯入學籍</button>
            <button class="search-btn float-end" (click)="getStdNewList()">查詢</button>
        </div>
    </div>
    <hr>
    <div class="div_data">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th class="td_W20">
                        <input type="checkbox" id="chkAll" (click)="setCheckedAll($event)">
                        <label for="check2">全選</label>                        
                    </th>
                    <th class="td_W20">完成轉入</th>
                    <th class="td_W20">入學學年</th>
                    <th class="td_W20">入學學期</th>
                    <th class="td_W50">學號</th>
                    <th class="td_W60 td_textAlign_Left">姓名</th>
                    <th class="td_W60">入學日期</th>
                    <th class="td_W60 td_textAlign_Left">錄取學制</th>
                    <th class="td_W120 td_textAlign_Left">錄取系所</th>
                    <th class="td_W60 td_textAlign_Left">錄取班級</th>
                    <th class="td_W20">性別</th>
                    <th class="td_W60">出生日期</th>
                    <th class="td_W70 td_textAlign_Left">國籍</th>
                    <th class="td_W20">完成註冊</th>
                    <th class="td_W20">刪除</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of stdNewList">
                    <td class="td_W20 td_textAlign_Center">
                        <ng-container *ngIf="item.Regi == false"></ng-container>
                        <ng-container *ngIf="item.Regi != false && item.InsertIn != 'Y'">
                            <input type="checkbox" name="transKey" (click)="setTransDataKey(item.DataKey, $event)">
                        </ng-container>                        
                    </td>
                    <td class="td_W20 td_textAlign_Center">
                        <ng-container *ngIf="item.InsertIn == ''"></ng-container>
                        <ng-container *ngIf="item.InsertIn != ''">V</ng-container>
                    </td>
                    <td class="td_W20 td_textAlign_Center">{{item.Years}}</td>
                    <td class="td_W20 td_textAlign_Center">{{item.Term}}</td>
                    <td class="text-primary pointer td_W50 td_textAlign_Center" (click)="getStdNewDetail(item.DataKey, template)">
                        {{item.StdNo}}</td>
                    <td class="td_W60 td_textAlign_Left">{{item.StdName}}</td>
                    <td class="td_W60 td_textAlign_Center">{{item.EntryYear}}-{{item.EntryMonth}}-{{item.EntryDay}}</td>
                    <td class="td_W60 td_textAlign_Left">{{item.EduName}}</td>
                    <td class="td_W120 td_textAlign_Left">{{item.DeptName}}</td>
                    <td class="td_W60 td_textAlign_Left">{{item.ClassName}}</td>
                    <td class="td_W20 td_textAlign_Center">{{item.Sex}}</td>
                    <td class="td_W60 td_textAlign_Center">{{item.Birthday}}</td>
                    <td class="td_W70 td_textAlign_Left">{{item.CountryName}}</td>
                    <td class="td_W20 td_textAlign_Center">
                        <ng-container *ngIf="item.Regi == true">V</ng-container>
                        <ng-container *ngIf="item.Regi != true"></ng-container>
                    </td>                
                    <td class="td_W20 td_textAlign_Center">
                        <input type="checkbox" *ngIf="item.InsertIn == ''" (click)="setDeleteDataKey(item.DataKey)">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">修改新生學籍資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-8">
                <button class="save-btn-gray" (click)="saveData()">儲存</button>
            </div>
            <div class="col-md-4">
                <div class="form-check">
                    <input class="form-check-input pointer" type="checkbox" id="check2" [checked]="stdNewDetail.DeptList[0].Regi == true" (click)="setChecked('Regi', $event)">
                    <label class="form-check-label pointer" for="check2">已完成註冊</label>
                </div>  
            </div>            
        </div>

        <hr class="border-primary">

        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #stdEdu (change)="getDeptList(stdEdu.value, true)"
                        [(ngModel)]="stdNewDetail.DeptList[0].EduNo">
                        <option value="" disabled selected>--全部--</option>
                        <option *ngFor="let item of stdEduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" #stdDept
                        (change)="getClassList(stdEdu.value, stdDept.value, true); getTeamList(stdDept.value, false)"
                        [(ngModel)]="stdNewDetail.DeptList[0].DeptNo">
                        <option value="" disabled selected>--全部--</option>
                        <option *ngFor="let item of stdDeptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">組別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].TeamNo">
                        <option value="" disabled selected>--全部--</option>
                        <option *ngFor="let item of stdTeamList" value="{{item.Team_No}}">{{item.Team_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">班別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].Class">
                        <option value="" disabled selected>--全部--</option>
                        <option *ngFor="let item of stdClassList" value="{{item.Class}}">{{item.Class_Name}}</option>
                    </select>
                </div>
            </div>            
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學號</span>
                    <input type="text" disabled class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.DeptList[0].StdNo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">入學學年</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].InYear">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">入學學期</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].InTerm">
                        <option value="" disabled>--全部--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">入學日期</span>
                    <input type="date" class="form-control shadow-none" [(ngModel)]="entryDate" #stdEnterDate
                        (change)="setEnterDate(stdEnterDate.value)">
                </div>
            </div>            
        </div>
        <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">中文姓名</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="stdNewDetail.StdName">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">英文姓名</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="stdNewDetail.StdEngName">
                    </div>
                </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">身分證號</span>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="stdNewDetail.IDNo">
                </div>
            </div>            
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">護照號碼</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdNewDetail.PassPortNo">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">入台證號</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdNewDetail.ResidenceNo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">出生日期</span>
                    <input type="date" class="form-control shadow-none" [(ngModel)]="stdNewDetail.Birthday">
                </div>
            </div>             
            <div class="col-md-4">
                <div class="input-group flex-nowrap">
                        <span class="input-group-text">性別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.Sex">
                        <option value="" disabled selected>請選擇</option>
                        <option value="男">男</option>
                        <option value="女">女</option>
                    </select>
                </div>
            </div>            
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">原住民別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.FieldType">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of fileType" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group mb-3">
                    <label class="input-group-text">國籍</label>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.CountryID">
                        <option selected disabled value="">請選擇</option>
                        <option *ngFor="let item of countryList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group mb-3">
                    <label class="input-group-text">僑居地</label>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.AbroadID">
                        <option selected disabled value="">請選擇</option>
                        <option *ngFor="let item of countryList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>            
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">個人Email</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdNewDetail.EMail">
                </div>
            </div>            
        </div>

        <hr class="border-primary">

        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學生類別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].EntryID">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of entrtIdList" ngValue="{{item.ItemValue}}"> {{item.ItemName}} </option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">入學類別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].EntryDuct">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of entryDuctList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">入學身份</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].EntryNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of entryNewList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                    </select>
                </div>
            </div>           
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">公自費別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].ExpenseType">
                        <option value="" selected disabled>--請選擇--</option>
                        <option value="公">公費生</option>
                        <option value="自">自費生</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">入學區分</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].EntryDiv">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of entryDivList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                    </select>
                </div>
            </div>             
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">特殊類別</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.DeptList[0].EntrySpecialType">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of entrySpecialTypeList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                    </select>
                </div>
            </div> 
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-check">
                    <input class="form-check-input pointer" type="checkbox" id="check2" [checked]="stdNewDetail.DeptList[0].IsAddition == 'Y'" (click)="setChecked('IsAddition', $event)">
                    <label class="form-check-label pointer" for="check2">是否為額外錄取</label>
                </div>  
            </div>
            <div class="col-md-4">
                <div class="form-check">
                    <input class="form-check-input pointer" type="checkbox" id="check1" [checked]="stdNewDetail.DeptList[0].Form5Student == 'Y'" (click)="setChecked('Form5Student', $event)">
                    <label class="form-check-label pointer" for="check1">是否為中五生</label>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-check">
                    <input class="form-check-input pointer" type="checkbox" id="check1" [checked]="stdNewDetail.IsNewCitizen == 'Y'" (click)="setChecked('IsNewCitizen', $event)">
                    <label class="form-check-label pointer" for="check1">是否為新住民</label>
                </div>
            </div>            
        </div>

        <hr class="border-primary">

        <div class="row">
            <div class="col-md mb-3">
                <div class="input-group flex-nowrap">
                    <span class="input-group-text">戶籍地址</span>
                    <span class="input-group-text">郵遞區號</span>
                    <input type="text" maxlength="6" placeholder="3+2 或 3+3" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.NomZip">
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <label class="input-group-text">縣市</label>
                    <select class="form-select shadow-none" (change)="getTownList($event, 'Nom')"
                        [(ngModel)]="stdNewDetail.NomCity">
                        <option selected disabled value="">請選擇</option>
                        <option *ngFor="let item of nomCityList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                        <label class="input-group-text">鄉鎮區</label>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.NomTown">
                        <option selected disabled value="">請選擇</option>
                        <option *ngFor="let item of nomTownList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdNewDetail.VillageName">
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.VillageType">
                        <option selected value="">請選擇</option>
                        <option value="里">里</option>
                        <option value="村">村</option>
                    </select>
                </div>
            </div>            
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdNewDetail.NeighborHood">
                    <span class="input-group-text">鄰</span>
                </div>
            </div>                
        </div>
        <div class="row">        
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <input type="text" class="form-control shadow-none" placeholder="地址剩下部分請於此填寫，請勿再從頭寫一次"
                        [(ngModel)]="stdNewDetail.NomElseAddr">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md mb-3">
                <div class="input-group flex-nowrap">
                    <span class="input-group-text">通訊地址</span>
                    <span class="input-group-text">郵遞區號</span>
                    <input type="text" maxlength="6" placeholder="3+2 或 3+3" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.ComZip">
                </div>
            </div>
            <div class="col-md-9">
                <div class="input-group mb-3">
                    <input type="text" class="form-control shadow-none" placeholder="請勿填寫郵遞區號"
                        [(ngModel)]="stdNewDetail.ComAddress">
                </div>
            </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
            <div class="col">
                <div class="input-group mb-3">
                        <label class="input-group-text">國外通訊地址</label>
                    <input type="email" class="form-control shadow-none"
                        placeholder="Please Enter Your Full English Address" [(ngModel)]="stdNewDetail.EngAddress">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <div class="input-group mb-3">
                        <label class="input-group-text">戶籍電話</label>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdNewDetail.NomTel">
                </div>
            </div>
            <div class="col-md">
                <div class="input-group mb-3">
                        <label class="input-group-text">通訊電話</label>
                    <input type="tel" class="form-control shadow-none" [(ngModel)]="stdNewDetail.ComTel">
                </div>
            </div>
            <div class="col-md">
                <div class="input-group mb-3">
                        <label class="input-group-text">行動電話</label>
                    <input type="tel" class="form-control shadow-none" [(ngModel)]="stdNewDetail.MobileTel">
                </div>
            </div>
        </div>

        <hr class="border-primary">

        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">監護人</label>
                    <label class="input-group-text">姓名</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.FamilyData[0].DepName">
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group mb-3">
                        <label class="input-group-text">稱謂</label>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.FamilyData[0].RelNo">
                        <option value="" disabled select>--請選擇--</option>
                        <option value="{{item.Rel_No}}" *ngFor="let item of relNameList">{{item.Rel_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                        <label class="input-group-text">連絡電話</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.FamilyData[0].ComTel">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group mb-3">
                        <label class="input-group-text">通訊地址</label>
                    <input type="text" class="form-control shadow-none" placeholder="請填寫完整地址"
                        [(ngModel)]="stdNewDetail.FamilyData[0].ComAddress">
                </div>
            </div>              
        </div>

        <hr class="border-primary">

        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">畢業學校</label>
                    <label class="input-group-text">類別</label>
                    <select class="form-select shadow-none" [(ngModel)]="stdNewDetail.SchoolData[0].SchClass" #SchClass>
                        <option value="" disabled select>--請選擇--</option>
                        <option value="{{item.SchClass}}" *ngFor="let item of schoolTypeList">{{item.SchClass_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                        <label class="input-group-text">校名</label>
                    <input type="hidden" [(ngModel)]="stdNewDetail.SchoolData[0].SchNo">
                    <input type="text" class="form-control shadow-none" readonly placeholder="請先點選右側瀏覽按鈕"
                        [(ngModel)]="stdNewDetail.SchoolData[0].SchName">
                        <button class="btn btn-light fw-bolder"
                            (click)="getSchoolName(SchClass.value, '', templateNested, true)">···</button>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                        <label class="input-group-text">畢業</label>
                    <input type="text" class="form-control text-center" maxlength="3"
                        [(ngModel)]="stdNewDetail.SchoolData[0].GradYear" placeholder="民國年">
                        <label class="input-group-text">年</label>
                    <select [(ngModel)]="stdNewDetail.SchoolData[0].GradMonth" class="form-control shadow-none">
                        <option value="{{item.itemValue}}" *ngFor="let item of month">{{item.itemName}}</option>
                    </select>
                        <label class="input-group-text">月</label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                        <label class="input-group-text">系所</label>
                    <input type="text" class="form-control shadow-none" placeholder="請輸入科系名稱"
                        [(ngModel)]="stdNewDetail.SchoolData[0].DeptName">
                </div>
            </div>           
        </div>

        <hr class="border-primary">

        <div class="row">
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <label class="input-group-text">服務機關</label>
                    <label class="input-group-text">機關名稱</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.JobData[0].ComName">                    
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">職稱</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.JobData[0].ComJobTitle">                    
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">電話</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.JobData[0].ComJobTel">                    
                </div>
            </div>             
        </div>

        <hr class="border-primary">

        <div class="row">
            <div class="col-md-12"><label class="input-group-text">學測15級分制成績</label></div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">總分</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.Score.EduScore">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">國文</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.Score.EduScoreChinese">
                </div>
            </div> 
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">英文</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.Score.EduScoreEnglish">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">數學</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.Score.EduScoreMath">
                </div>
            </div>            
        </div>

        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">數學A</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.Score.EduScoreMathA">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">數學B</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.Score.EduScoreMathB">
                </div>
            </div> 
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">社會</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.Score.EduScoreSocial">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <label class="input-group-text">自然</label>
                    <input type="text" class="form-control shadow-none"
                        [(ngModel)]="stdNewDetail.Score.EduScoreNatural">
                </div>
            </div>            
        </div>        

        <hr class="border-primary">        
        
        <div class="row">
            <div class="col-md-12">
                <button class="save-btn-gray" (click)="saveData()">儲存</button>
            </div>
        </div>        
    </div>
</ng-template>

<ng-template #templateNested>
    <div class="modal-header">
        <h4 class="modal-title pull-left">選擇一學校</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="nestedModalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                        <label class="input-group-text">學校名稱</label>
                    <input type="text" class="form-control shadow-none" placeholder="請輸入學校名稱" #SchName>
                </div>
            </div>
            <div class="col-md-3">
                <button class="search-btn"
                    (click)="getSchoolName(stdNewDetail.SchoolData[0].SchClass, SchName.value, templateNested, false)">查詢</button>
            </div>
        </div>
        <div style="height: 500px; overflow-y: scroll;">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th>學校代碼</th>
                        <th>學校名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" *ngFor="let item of schoolNameList"
                        (click)="chooseSchool(item.Sch_No, item.Sch_Name)">
                        <td>{{item.Sch_No}}</td>
                        <td>{{item.Sch_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</ng-template>