<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getGRDUnPassList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year formControlName="Years"
                        (change)="getEduList(Year.value, Term.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term formControlName="Term"
                        (change)="getEduList(Year.value, Term.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" #edu
                        (change)="getDeptList(edu.value, Year.value, Term.value)" formControlName="EduNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">
                            {{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" #Dept
                        (change)="getClassList(edu.value, Dept.value, Year.value, Term.value)" formControlName="DeptNo">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Class">
                        <option value="" selected>--請選擇--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學分下限</span>
                    </div>
                    <input type="number" class="form-control shadow-none" min="0" formControlName="MinCredit">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">種類</span>
                    </div>
                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                        <input type="radio" class="btn-check" id="btncheck1" name="UnPassType" autocomplete="off"
                            value="1" formControlName="UnPassType">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck1">1/2</label>

                        <input type="radio" class="btn-check" id="btncheck2" name="UnPassType" autocomplete="off"
                            value="2" formControlName="UnPassType">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck2">2/3</label>

                        <input type="radio" class="btn-check" id="btncheck3" name="UnPassType" autocomplete="off"
                            value="3" formControlName="UnPassType">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck3">連續1/2或2/3</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <button type="button" class="other-Button-gray float-end" (click)="exportReportADGRD0306()">匯出</button>
                <button type="button" style="width: 150px;" class="other-Button-gray float-end me-3"
                    (click)="openModel(setTypeTemplate)">設定2/3類別</button>
                <button class="search-btn float-end me-3">查詢</button>
            </div>
        </div>
    </form>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th style="width: 80px;"></th>
                <th style="width: 80px;">學年期</th>
                <th>班級</th>
                <th>學號</th>
                <th style="width: 80px;">姓名</th>
                <th style="width: 80px;">修習學分</th>
                <th style="width: 80px;">實得學分</th>
                <th style="width: 100px;">不及格學分</th>
                <th style="width: 80px;">學業平均</th>
                <th style="width: 80px;">班級排名</th>
                <th>1/2或2/3</th>
                <th>連續1/2或2/3</th>
                <th style="width: 80px;">學年期</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of gRDUnPassList; let i = index">
                <td style="width: 60px;">{{i + 1}}</td>
                <td style="width: 80px;">{{item.Years}}-{{item.Term}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.StdNo}}</td>
                <td style="width: 80px;">{{item.StdName}}</td>
                <td style="width: 80px;">{{item.SelCredits}}</td>
                <td style="width: 80px;">{{item.GetCredits}}</td>
                <td style="width: 100px;">{{item.UnPassCredits}}</td>
                <td style="width: 80px;">{{item.AvgScore}}</td>
                <td style="width: 80px;">{{item.Orders}}</td>
                <td>{{item.UnPassType}}</td>
                <td>{{item.SerialUnPass}}</td>
                <td style="width: 80px;">{{item.SerialUnPassYear}}-{{item.SerialUnPassTerm}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #setTypeTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定2/3學生類別</h4>
        <button type="button" class="btn-close btn-close-white shadow-none close pull-right" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-check form-check-inline" *ngFor="let item of gRDUnPassEntryList; let i = index">
            <input class="form-check-input shadow-none pointer" type="checkbox" id="inlineCheckbox{{i}}"
                [(ngModel)]="item.Used">
            <label class="form-check-label pointer" for="inlineCheckbox{{i}}">{{item.EntryName}}</label>
        </div>
        <div>
            <button class="save-btn-gray mt-3 mx-auto" (click)="getGRDUnPassEntrySave()">儲存</button>
        </div>
    </div>
</ng-template>