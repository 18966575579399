import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCurList } from 'src/app/_Models/STDWEB/STDW50004/getCurList';
import { GetCosQuestionList } from 'src/app/_Models/TCHWEB/TCH0309/getCosQuestionList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-STDW50004',
    templateUrl: './STDW50004.component.html',
    styleUrls: ['./STDW50004.component.css']
})
export class STDW50004Component implements OnInit {
    modalRef!: BsModalRef;
    baseUrl = environment.apiUrl;
    cosQuestionList!: GetCosQuestionList[] | undefined;
    curList!: GetCurList[];
    cosDataKey = "";
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private timeService: TimeService, private systemService: SystemService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.getCurList({ Years: response.Now_Years, Term: response.Now_Term });
        }, error => this.toastr.error(error.error))
    }

    getCurList(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW500_01/Get_Cur_List", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.curList = []
                return;
            }
            this.curList = response;
        });
    }

    getCosQuestionList(status: string) {

        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW500_04/Get_Cos_Question_List", { Status: status, DataKey: this.cosDataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cosQuestionList = []
                return;
            }
            this.cosQuestionList = response;
            if (this.cosQuestionList) {
                this.cosQuestionList.forEach(element => {
                    element.AnsContent = element.AnsContent;
                    element.Status = element.Status;
                    element.QuestContent = element.QuestContent;
                    element.isEdit = false;
                });
            }
        })
    }

    askCosQuestion(ans: string, dataKey: string, index: number) {

        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW500_04/Get_Cos_Question_Ask", {
            QuestContent: ans,
            DataKey: dataKey
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (this.cosQuestionList) {
                this.cosQuestionList[index].Status = '已答覆';
                this.toastr.success("已送出答覆");
            }
        }, error => this.toastr.error(error.error));
    }

    editAnswer(index: number) {
        if (this.cosQuestionList) {
            this.cosQuestionList[index].Status = '未答覆';
        }

    }

    openModal(template: TemplateRef<any>, dataKey: string) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.cosDataKey = dataKey;
        this.cosQuestionList = undefined;
        this.getCosQuestionList('');
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    editData(index: number) {
        if (this.cosQuestionList) {
            this.cosQuestionList[index].isEdit = true;
        }

    }

    saveData(index: number, dataKey: string) {

        if (this.cosQuestionList) {
            this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW500_04/Get_Cos_Question_Ask", {
                QuestContent: (this.cosQuestionList[index].QuestContent),
                DataKey: dataKey
            }).subscribe((response => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                if (this.cosQuestionList) {
                    this.cosQuestionList[index].isEdit = false;
                }
            }))
        }
    }

    deleteData(dataKey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW500_04/Get_Cos_Question_Delete", {
            QuestContent: '',
            DataKey: dataKey
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.cosQuestionList = undefined;
            this.getCosQuestionList('');
        })
    }

    newData() {
        Swal.fire({
            title: '請輸入提問問題。',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: '送出',
            cancelButtonText: '取消',
            showLoaderOnConfirm: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW500_04/Get_Cos_Question_Ask", {
                    QuestContent: result.value,
                    DataKey: this.cosDataKey
                }).subscribe((response => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.getCosQuestionList('');
                }))
            }
        })
    }
}
