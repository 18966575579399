import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetHolidayList } from 'src/app/_Models/SAC/SAC1701/getHolidayList';
import { GetLeaveList } from 'src/app/_Models/STDWEB/STDW60001/getLeaveList';
import { environment } from 'src/assets/environments/environment';
import { DatePipe } from '@angular/common'
import { GetLeaveContent } from 'src/app/_Models/STDWEB/STDW60001/getLeaveContent';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { GetLeaveFlow } from 'src/app/_Models/STDWEB/STDW60001/getLeaveFlow';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-STDW60001',
    templateUrl: './STDW60001.component.html',
    styleUrls: ['./STDW60001.component.css']
})
export class STDW60001Component implements OnInit {

    initiateCurTimeData: Array<any> = [];
    daterangepickerModel: Array<Date> = [];
    holidayList!: GetHolidayList[];
    leaveListData!: GetLeaveList[];
    leaveList!: GetLeaveList["List"];
    leaveFlowData!: GetLeaveFlow[];
    leaveContentData!: GetLeaveContent
    curTime: Array<string> = [];
    isNewData = false;
    leaveContentModalRef!: BsModalRef;
    leaveFlowListModalRef!: BsModalRef;
    searchForm!: UntypedFormGroup;
    Chk_Status = '';
    fileBase64 = '';
    fileName = '';
    bsConfig = {
        isAnimated: true,
        dateInputFormat: 'YYYY-MM-DD',
        containerClass: 'theme-dark-blue',
        returnFocusToInput: true,
        showTodayButton: true,
        todayPosition: 'center',
        showClearButton: true,
        clearPosition: 'right'
    }

    baseUrl = environment.apiUrl;
    fileExchange = new FileExchange();
    deleteDataArray: Array<string> = [];

    constructor(private localeService: BsLocaleService,
        private formBuilder: UntypedFormBuilder,
        private http: HttpClient,
        private toastr: ToastrService,
        private datepipe: DatePipe,
        private modalService: BsModalService,
        private accountService: AccountService,
        private systemService: SystemService,
        private timeService: TimeService, private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.initiateCurTime();
        this.getHolidayList();
        this.localeService.use('zh-cn');
        this.searchForm = this.formBuilder.group({
            Date: [],
            H_Code: '',
            Years: '',
            Term: '',
            RecDate_S: '',
            RecDate_E: ''
        });

        this.timeService.getTimeCntr().subscribe((response) => {
            this.searchForm.value.Years = response.Now_Years;
            this.searchForm.value.Term = response.Now_Term;
            this.getLeaveList();
        });
    }

    // 節次前面補0
    initiateCurTime() {
        this.initiateCurTimeData = [];
        for (let index = 1; index < 16; index++) {
            this.initiateCurTimeData.push({ number: this.padLeft(index.toString()), checked: false });
        }
    }

    padLeft(str: string): any {
        if (str.length >= 2) return str;
        else return this.padLeft("0" + str);
    }

    setModel(model: any) {
        this.searchForm.value.Years = model.Years;
        this.searchForm.value.Term = model.Term;
    }

    newData(template: TemplateRef<any>) {

        // 重設資料避免汙染
        this.initiateCurTime();
        this.Chk_Status = '';
        this.leaveContentData = {
            Years: '',
            Term: '',
            SerialNo: '',
            Std_No: '',
            Std_Name: '',
            Class_Name: '',
            RecDateS: '',
            RecDateE: '',
            H_Code: '',
            Cur_TimeList: [],
            Cur_TimeList_String: '',
            Memo: '',
            FileName: '',
            FileContent: '',
            DataKey: this.leaveListData[0].DataKey,
            Send: ''
        };
        this.daterangepickerModel = [];
        this.curTime = [];

        // 取得學生資訊
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.leaveContentData.Std_Name = response.UserName;
                this.leaveContentData.Std_No = response.Code;
            }
        });

        // 取得目前學年期
        this.timeService.getTimeCntr().subscribe((response) => {
            this.leaveContentData.Years = response.Now_Years;
            this.leaveContentData.Term = response.Now_Term;
        });

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.isNewData = true;

        this.leaveContentModalRef = this.modalService.show(template, modalConfig);
        this.getLeaveList()
    }

    getHolidayList() {
        this.systemService.getHolidayList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message);
            }
            this.holidayList = response;
        })
    }

    getLeaveList() {
        this.leaveList=[];
        this.leaveListData = [];

        if (this.searchForm.value.Date != null) {
            this.searchForm.value.RecDate_S = this.datepipe.transform(this.searchForm.value.Date[0], 'yyyy-MM-dd');
            this.searchForm.value.RecDate_E = this.datepipe.transform(this.searchForm.value.Date[1], 'yyyy-MM-dd');
        }

        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW600_01/Get_Leave_List", this.searchForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.leaveList=[];
                this.leaveListData = []
                return;
            }
            this.leaveListData = response;
            this.leaveList=this.leaveListData[0].List;
        });
    }

    getLeaveContent(dataKey: string, template: TemplateRef<any>, Chk_Status: string) {
        this.initiateCurTime();
        // 清除設定
        this.curTime = [];
        this.Chk_Status = Chk_Status;
        this.isNewData = false;
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW600_01/Get_Leave_Content', { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message, "系統訊息");
                return;
            }

            // console.log(response);

            this.leaveContentData = response;
            //this.leaveContentData.Std_Name = response[0].Std_Name;
            //this.leaveContentData.Memo = response[0].Memo;
            this.daterangepickerModel.push(new Date(this.leaveContentData.RecDateS));
            this.daterangepickerModel.push(new Date(this.leaveContentData.RecDateE));
            this.leaveContentModalRef = this.modalService.show(template, modalConfig);

            // 設置checkbox
            response.Cur_TimeList.forEach((element: { Cur_Time: string; }) => {
                for (let index = 0; index < this.initiateCurTimeData.length; index++) {
                    if (this.initiateCurTimeData[index].number == element.Cur_Time.substring(1)) {
                        this.initiateCurTimeData[index].checked = true;
                        this.curTime.push(element.Cur_Time.substring(1));
                    }
                }
            });
        })
    }

    setCurTime(event: any) {
        let result = (element: string) => element == event.target.value;
        let index = this.curTime.findIndex(result);
        if (index != -1) {
            this.curTime.splice(index, 1);
            return;
        }
        this.curTime.push(event.target.value);
    }

    importData($event: any, dataKey: string, type: string) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type)
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.fileBase64 = base64;
                that.fileName = file.name;
            }
            else if (result.isDenied) {
                document.getElementById(dataKey)?.setAttribute('value', '');
                that.toastr.info("已取消上傳。");
            }
        });
    }

    saveContent(type: string) {
        if (type == 'temp') {
            let element = "";
            // 設定時間存入
            this.leaveContentData.RecDateS = this.datepipe.transform(this.daterangepickerModel[0], 'yyyy-MM-dd') || '';
            this.leaveContentData.RecDateE = this.datepipe.transform(this.daterangepickerModel[1], 'yyyy-MM-dd') || '';

            // 節次轉字串存入
            for (let index = 0; index < this.curTime.length; index++) {
                element += this.curTime[index] + ',';
            }
            element = element.slice(0, -1);
            this.leaveContentData.Cur_TimeList_String = element;

            // 設定上傳檔案存入
            this.leaveContentData.FileContent = this.fileBase64;
            this.leaveContentData.FileName = this.fileName;

            this.leaveContentData.Send = '0';

            this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW600_01/Get_Leave_Save', this.leaveContentData).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.error(response.Message, "系統錯誤");
                    return;
                }
                this.toastr.success("儲存成功!");
                this.getLeaveList();
                this.leaveContentModalRef.hide();
            })
        }
        else {
            Swal.fire({
                title: '確定送出假單? 一但送出即無法修改。',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: '是',
                denyButtonText: '否',
            }).then((result) => {
                if (result.isConfirmed) {
                    let element = "";
                    // 設定時間存入
                    this.leaveContentData.RecDateS = this.datepipe.transform(this.daterangepickerModel[0], 'yyyy-MM-dd') || '';
                    this.leaveContentData.RecDateE = this.datepipe.transform(this.daterangepickerModel[1], 'yyyy-MM-dd') || '';

                    // 節次轉字串存入
                    for (let index = 0; index < this.curTime.length; index++) {
                        element += this.curTime[index] + ',';
                    }
                    element = element.slice(0, -1);
                    this.leaveContentData.Cur_TimeList_String = element;

                    // 設定上傳檔案存入
                    this.leaveContentData.FileContent = this.fileBase64;
                    this.leaveContentData.FileName = this.fileName;
                    if (this.isNewData) {
                        this.leaveContentData.DataKey = this.leaveListData[0].DataKey;
                    }
                    this.leaveContentData.Send = '1';

                    this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW600_01/Get_Leave_Save', this.leaveContentData).subscribe((response) => {
                        if (response.Info == false) {
                            this.toastr.error(response.Message, "系統錯誤");
                            return;
                        }
                        this.toastr.success("儲存成功!");
                        this.getLeaveList();
                        this.leaveContentModalRef.hide();
                    })
                }
                else if (result.isDenied) {
                    this.toastr.info("已取消上傳。");
                }
            });
        }

    }

    getLeaveFlowList(dataKey: string, template: TemplateRef<any>) {
        this.http.post<any>(this.baseUrl + 'ACAD/STDWEB/STDW600_01/Get_LeaveFlow_List', { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.error(response.Message);
                return;
            }
            this.leaveFlowData = response;

            let modalConfig = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-xl'
            };

            this.leaveFlowListModalRef = this.modalService.show(template, modalConfig);
        })
    }

    countDelete(dataKey: string) {

        let i = this.deleteDataArray.indexOf(dataKey);

        if (i != -1) {
            this.deleteDataArray.splice(i);
        }
        else {
            this.deleteDataArray.push(dataKey);
        }
    }

    deleteData() {

        if (this.deleteDataArray.length == 0) {
            this.toastr.warning("尚未選取欲刪除的資料")
            return;
        }

        Swal.fire({
            title: '確定刪除假單? 。',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                let result = '';
                for (let index = 0; index < this.deleteDataArray.length; index++) {
                    result += this.deleteDataArray[index] + ';';
                }

                this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW600_01/Get_Leave_Delete", { DataKey: result }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.error(response.Message);
                    }
                    this.deleteDataArray = [];
                    this.getLeaveList();
                    this.toastr.info("已刪除選取假單");
                }, error => this.toastr.error(error.error));


            }
            else if (result.isDenied) {
                this.toastr.info("已取消動作");
            }
        });
    }

    selectAll() {
        let checkBoxs = document.getElementsByName('deleteCheck');
        let selectAll = <HTMLInputElement>document.getElementById('selectAll');
        if (selectAll.checked == false) {
            checkBoxs.forEach(element => {
                let checkBox = <HTMLInputElement>element;
                checkBox.checked = false;
            });
        }
        else {
            checkBoxs.forEach(element => {
                let checkBox = <HTMLInputElement>element;
                checkBox.checked = true;
            });
        }

    }

}
