import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/assets/environments/environment';
import { GetTFSMakeDocSIList } from 'src/app/_Models/AD/ADTFS/ADTFS01D01/getTFSMakeDocSIList';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { GetTFSMakeDocSIListDetail } from 'src/app/_Models/AD/ADTFS/ADTFS01D01/getTFSMakeDocSIListDetail';
import { GetRedClassList } from 'src/app/_Models/AD/ADTFS/ADTFS0701/getRedClassList';

@Component({
	selector: 'app-ADTFS01D01',
	templateUrl: './ADTFS01D01.component.html',
	styleUrls: ['./ADTFS01D01.component.css']
})
export class ADTFS01D01Component implements OnInit {
	checktype = new CheckType();
	initialData = new InitialData(this.http);
	years_data: Array<string> = [];
	term_data = require('src/app/_Models/_SelectInput/term.json').term;;
	baseUrl = environment.apiUrl;
	condition_model = {
		Years: '',
		Term: ''
	};
	data_list_model!: GetTFSMakeDocSIList[];
	data_model!: GetTFSMakeDocSIListDetail;
	have_selected: boolean = false;
	old_row: number = -1;

	redClass!: GetRedClassList[];

	constructor(private http: HttpClient, private systemService: SystemService, private toastr: ToastrService) { }

	//Init_Control()
	ngOnInit(): void {
		this.getYears();

		this.Select_New_Row(-1);
		//初始化表單
		this.Init_Model();

		this.getRedClassList();
	}



	//學年清單
	getYears(): void {
		this.initialData.getYears().subscribe((response) => {
			if (response) {
				this.years_data = response;
			}
		});
	}

	getRedClassList() {
		this.systemService.getRedClassList().subscribe((response) => {
			if (response.Info == false) {
				this.toastr.warning(response.Message);
				return;
			}
			this.redClass = response;
		})
	}

	//Query_List
	Search(years: string, term: string): void {
		if (years == '' || term == '') {
			this.toastr.info('請選擇學年/學期');
			return;
		}

		let Search_Model = { Years: years, Term: term };

		this.http.post<any>(this.baseUrl + 'ACAD/TFS/ADTFS01D01/Get_TFS_MakeDoc_SIList', Search_Model).subscribe(
			response => {
				if (response.Info == false) {
					this.toastr.warning(response.Message);
					this.data_list_model = []
					return;
				}

				if (this.have_selected) {
					this.Clear_Data();
				}

				this.data_list_model = response;
			},
			error => {
				this.toastr.error(error.error)
			}
		);
	}

	//Query_Data
	Search_Detail(DataKey: string, row_index: number): void {
		//選擇列
		this.Select_New_Row(row_index);


		//初始話表單
		this.Init_Model();


		this.http.post<any>(this.baseUrl + 'ACAD/TFS/ADTFS01D01/Get_TFS_MakeDoc_SIDetail', { DataKey: DataKey }).subscribe(
			response => {
				if (response.Info == false) {
					this.toastr.warning(response.Message);
					return;
				}
				this.data_model = response;
				this.data_model.UpdateIP = this.data_model.UpdateIP;
				this.data_model.UpdateTime = this.data_model.UpdateTime;
				this.data_model.UpdateUser = this.data_model.UpdateUser;
			},
			error => {
				this.toastr.error(error.error)
			}
		);
	}

	//Insert_Data
	Insert(): void {

		if (this.Check_Column()) {
			return;
		}

		this.http.post<any>(this.baseUrl + 'ACAD/TFS/ADTFS01D01/Get_TFS_MakeDoc_SISave', this.data_model).subscribe(
			response => {
				if (response.Info == false) {
					this.toastr.warning(response.Message);
					return;
				}
				this.toastr.success('新增成功');
				this.Search(this.condition_model.Years, this.condition_model.Term);
			},
			error => {
				this.toastr.error(error.error)
			}
		);
	}

	//Delete_Data
	Delete(): void {
		this.http.post<any>(this.baseUrl + 'ACAD/TFS/ADTFS01D01/Get_TFS_MakeDoc_SIDelete', this.data_model).subscribe(
			response => {
				if (response.Info == true) {
					this.toastr.success('刪除成功');
					this.Search(this.condition_model.Years, this.condition_model.Term);
				} else {
					this.toastr.error(response.Message);
				}
				this.Search(this.condition_model.Years, this.condition_model.Term);
			},
			error => {
				this.toastr.error(error.error)
			}
		);
	}

	Clear_Data(): void {
		this.Init_Model();
		this.Select_New_Row(-1);
	}

	Select_New_Row(new_row: number): void {
		if (new_row == -1) {
			this.have_selected = false;
		} else {
			this.have_selected = true;
		}
		if (this.old_row != -1) {
			this.data_list_model[this.old_row].Selected = false;
		}
		if (new_row != -1) {
			this.data_list_model[new_row].Selected = true;
		}
		this.old_row = new_row;
	}

	//Init、Clear Use
	Init_Model(): void {
		this.data_model = {
			DataKey: '',
			Years: '',
			Term: '',
			RedClassNo: '',
			SIMoney: '',
			RCMoney: '',
			UpdateUser: '',
			UpdateTime: '',
			UpdateIP: ''
		};
	}


	Check_Column(): boolean {
		if (this.data_model?.Years == '') {
			this.toastr.warning('請選擇學年');
			return true;
		}

		if (this.data_model?.Term == '') {
			this.toastr.warning('請選擇學期');
			return true;
		}

		if (this.data_model?.RedClassNo == '') {
			this.toastr.warning('請選擇身分別');
			return true;
		}

		if (this.checktype.CheckIsNumber(this.data_model?.SIMoney)) {
			this.toastr.warning('保險金額請輸入數字');
			return true;
		}

		if (parseInt(this.data_model?.SIMoney || '') < 0) {
			this.toastr.warning('保險金額請輸入正數');
			return true;
		}

		if (this.checktype.CheckIsNumber(this.data_model?.RCMoney)) {
			this.toastr.warning('減免金額請輸入數字');
			return true;
		}

		if (parseInt(this.data_model?.RCMoney || '') < 0) {
			this.toastr.warning('減免金額請輸入正數');
			return true;
		}


		return false;
	}

}
