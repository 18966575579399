<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select [(ngModel)]="search1.deptNo" class="form-select shadow-none" #deptNo>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-9">
            <button class="other-Button-gray float-end" (click)="clear()">清除畫面</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="other-Button-gray float-end me-3" (click)="insert()">新增</button>
            <button class="search-btn float-end me-3" (click)="search(deptNo.value)">查詢</button>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>學程代碼</th>
                        <th>學程名稱</th>
                        <th>使用狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="searchDetail(item.AcadCourse_No, i)">
                        <td>{{i + 1}}</td>
                        <td>{{item.AcadCourse_No}}</td>
                        <td>{{item.AcadCourse_Name}}</td>
                        <td>{{item.State_Log == '1'?'啟用':'未啟用'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學程代碼</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.AcadCourse_No"
                            placeholder="至多四碼" maxlength="4">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學程名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.AcadCourse_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">英文名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.AcadCourse_EngName">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Dept_No">
                            <option value="" selected disabled>--請選擇--</option>
                            <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">狀態</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.State_Log">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="1">啟用</option>
                            <option value="0">未啟用</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_Time">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_User">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>