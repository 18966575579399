<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">在職狀態</span>
                <select class="form-select shadow-none" [(ngModel)]="searchdata.item" >
                    <option value="">--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of titleStatus">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">單位</span>
                <select class="form-select shadow-none" [(ngModel)]="searchdata.unit">
                    <option value="">--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of unitList">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8 mb-1">
            <button class="search-btn float-end me-3" (click)="export()">匯出</button>
        </div>
    </div>
</div>