<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">學年</span>
                                <select class="form-select shadow-none" #Year>
                                    <option value="" disabled>--請選擇--</option>
                                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button class="export-btn mx-auto" (click)="export(Year.value)">匯出</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>  
            </div>         
        </div>
    </div>
</div>