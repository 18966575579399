import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetEduList } from 'src/app/_Models/getEduList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADSTD0806',
  templateUrl: './ADSTD0806.component.html',
  styleUrls: ['./ADSTD0806.component.css']
})
export class ADSTD0806Component implements OnInit {
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  baseUrl = environment.apiUrl;
  eduList!: GetEduList[];
  initialData = new InitialData(this.http);
  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private fileExchangeServices: FileExchangeService,
    private systemService: SystemService,) { }

  ngOnInit(): void {
    this.getYears();
    this.getEduList();
  }


  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    });
  }


  getEduList() {
    this.systemService.getEduList('', '', '', 'Y').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.eduList = response;

    }, error => this.toastr.error(error.error))
  }

  exportReportADSTD0806(Years: string, Term: string, EduNo: string) {
    this.fileExchangeServices.ExportFile("ACAD/STD/ADSTD08_06/Export_Report_ADSTD0806", { Years: Years, Term: Term, EduNo: EduNo }).subscribe((response) => {
      if (response.FileName == '') {
        this.toastr.warning("查無資料");
        return;
      }
      let url = window.URL.createObjectURL(response.blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = response.FileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => this.toastr.error(error.error))
  }

}
