import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetCoseList } from 'src/app/_Models/TCHWEB/TCH0115/getCosList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0115',
    templateUrl: './TCH0115.component.html',
    styleUrls: ['./TCH0115.component.css']
})
export class TCH0115Component implements OnInit {

    baseUrl = environment.apiUrl;
    cosList!: GetCoseList[];
    exportDataKey: Array<string> = [];
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private fileExchangeServices: FileExchangeService, private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.getCosList({ Years: response.Now_Years, Term: response.Now_Term });
        });
    }

    getCosList(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_15/List_Cos", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cosList = [];
                return;
            }
            this.cosList = response;
        })
    }

    setexportDataKey(dataKey: string) {
        let index = this.exportDataKey.indexOf(dataKey)
        if (index == -1) {
            this.exportDataKey.push(dataKey);
            return;
        }
        this.exportDataKey.splice(index, 1);
    }

    export() {

        if (this.exportDataKey.length == 0) {
            this.toastr.info("請先選擇班級");
            return;
        }

        let dataKey = '';
        for (let i = 0; i < this.exportDataKey.length; i++) {
            dataKey += this.exportDataKey[i] + ','
        }
        dataKey = dataKey.substring(0, dataKey.length - 1);
        this.fileExchangeServices.ExportFile("ACAD/TCHWEB/TCH01_15/Export_Report_Base64", { DataKey: dataKey, IP: '' })
            .subscribe((response) => {
                let url = window.URL.createObjectURL(response.blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = response.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                this.exportDataKey = [];
            }, error => this.toastr.error(error.error));
    }

}
