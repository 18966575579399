<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select class="form-select shadow-none">
                    <option value="" selected disabled>--請選擇--</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">年級</span>
                </div>
                <select class="form-select shadow-none">
                    <option value="" selected disabled>--請選擇--</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-inline d-flex align-items-center">
                <div class="btn-group" btnRadioGroup>
                  <label class="btn btn-outline-secondary shadow-none" btnRadio="Left">自訂</label>
                  <label class="btn btn-outline-secondary shadow-none" btnRadio="Middle">皆可</label>
                  <label class="btn btn-outline-secondary shadow-none" btnRadio="Right">皆不可</label>
                </div>
                <h5 class="pt-1 ms-3"><span class="badge bg-secondary">已排通識時段</span></h5>
            </div>
          
        </div>
        <div class="col-md-2">
            <button class="search-btn float-end">查詢</button>
        </div>
    </div> 
    <app-ADCUR-class-set></app-ADCUR-class-set>
</div>
