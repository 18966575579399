import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCUPMailTchList } from 'src/app/_Models/AD/ADCUP/ADCUP0604/getCUPMailTchList';
import { GetCUPMailTchSendEMail } from 'src/app/_Models/AD/ADCUP/ADCUP0604/getCUPMailTchSendEMail';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUP0604',
    templateUrl: './ADCUP0604.component.html',
    styleUrls: ['./ADCUP0604.component.css']
})
export class ADCUP0604Component implements OnInit {
    baseUrl = environment.apiUrl;
    modalRef?: BsModalRef;
    unitList!: GetSelectList[];
    titleStatus!: GetSelectList[];
    timeCase!: GetSelectList[];
    term = require('src/app/_Models/_SelectInput/term.json').term;
    initialData = new InitialData(this.http);
    dataKeyArray: Array<string> = [];
    Years!: Array<string>;
    fileExchange = new FileExchange();
    json: GetCUPMailTchSendEMail = {
        DataKey: "",
        Subject: "",
        Content: "",
        Attachments: []
    }
    cUPMailTchList!: GetCUPMailTchList[];
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private modalService: BsModalService, private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
        this.getUnitList();
        this.getTitleStatus();
        this.getTimeCase();
    }


    getUnitList() {
        this.systemService.getUnitList('', '', '').subscribe((response) => {
            this.unitList = response;
        })
    }

    getTitleStatus() {
        this.systemService.getTitleStatus().subscribe((response) => {
            this.titleStatus = response;
        })
    }

    getTimeCase() {
        this.systemService.getTimeCase().subscribe((response) => {
            this.timeCase = response;
        })
    }

    setDatakey(type: string, dataKey: string, event: any) {

        if (!this.cUPMailTchList) return;

        let input = <HTMLInputElement>event;
        if (type == 'all') {
            if (this.dataKeyArray.length != this.cUPMailTchList.length && input.checked == true) {
                this.dataKeyArray = [];
                this.cUPMailTchList.forEach(element => {
                    this.dataKeyArray.push(element.DataKey);
                    element.checked = true;
                })
            }
            else {
                this.dataKeyArray = [];
                this.cUPMailTchList.forEach(element => {
                    element.checked = false;
                })
            }
        }
        else {
            let index = this.dataKeyArray.indexOf(dataKey);
            if (index == -1) {
                this.dataKeyArray.push(dataKey);
            }
            else {
                this.dataKeyArray.splice(index, 1);
            }
        }
    }



    import($event: any, dataKey: string, type: string) {
        let files = $event.target.files;
        if (!files) {
            this.toastr.info("無偵測到檔案");
            return;
        }

        for (let index = 0; index < files.length; index++) {
            this.fileExchange.FileToBase64(this, files[index], this.GetBase64, dataKey, type);
        }
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        that.json.Attachments.push({
            FileName: file.name,
            FileBase64: base64,
            FileContentType: file.type
        })
    }

    getCUPMailTchList(UnitCode: string, Status: string, TimeCase: string, TchName: string) {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0604/Get_CUP_MailTch_List", {
            UnitCode: UnitCode,
            Status: Status,
            TimeCase: TimeCase,
            TchName: TchName
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cUPMailTchList = [];
                return;
            }
            this.cUPMailTchList = response;
            this.cUPMailTchList.forEach(element => {
                element.checked = false;
            });
        });
    }

    openModal(template: TemplateRef<any>) {

        if (this.dataKeyArray.length == 0) {
            this.toastr.info("請先選擇老師");
            return;
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }


    getCUPMailTchSendEMail(Subject: string, Content: string) {
        let str = '';
        this.dataKeyArray.forEach(element => {
            str += element + ','
        });

        this.json.DataKey = str.substring(0, str.length - 1);

        this.json.Content = Content;

        this.json.Subject = Subject;

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0604/Get_CUP_MailTch_SendEMail", this.json).subscribe(response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("發送完成");
            this.modalRef?.hide();
            this.dataKeyArray = [];
            this.json = {
                DataKey: "",
                Subject: "",
                Content: "",
                Attachments: []
            };
            this.cUPMailTchList.forEach(element => {
                element.checked = false;
            });
        })
    }

}
