import { postDeptList } from './../../../_Models/AD/ADPUB/ADPUB0103/postDeptList';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { GetDeptList } from 'src/app/_Models/getDeptList';

import { IPost_DeptModel } from 'src/app/_Models/AD/ADSYS/ADSYS0501/IPost_DeptModel';
import { IAttachment } from '../../../_Models/AD/ADSYS/ADSYS0501/IPost_DeptModel';

@Component({
    selector: 'app-ADPUB0103',
    templateUrl: './ADPUB0103.component.html',
    styleUrls: ['./ADPUB0103.component.css']
})
export class ADPUB0103Component implements OnInit {
    baseUrl = environment.apiUrl;
    modalRef?: BsModalRef;

    //人事單位(下拉清單)
    unitList: GetSelectList[] = [];
    //系所資料(下拉清單)
    deptList: GetDeptList[] = [];

    //查詢列表
    listData: IPost_DeptModel[] = [];

    //新增、修改、查詢詳細資料(資料模型)
    detailData: IPost_DeptModel = {
        Post_Id: '',
        Type_ID: '',
        Type_Name: '',
        Unit_Code: '',
        Unit_Name: '',
        Post_UnitName: '',
        Post_Subject: '',
        Post_Content: '',
        Post_Date: '',
        End_Date: '',
        Post_Object: '',
        Attachment: []
    };

    //查詢條件
    condition = {
        Type_ID: '',
        Type_Name: '',
        Unit_Code: '',
        Unit_Name: '',
        Post_UnitName:'',
        Post_Subject: '',
        Post_Date: '',
        End_Date: '',
        Post_Object: '',
        Dept_No: '',
    };

    bsConfig = environment.bsDatePickerConfig;

    isInsert: boolean = false;
    isUpdate: boolean = false;


    isNewData = false;

    model: any;
    teacherList: any;
    UnitListLock: boolean = false;
    DeptListLock: boolean = false;
    fileExchange = new FileExchange();
    fileBase64 = '';
    fileName = '';
    Attachment: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private systemService: SystemService,
        private modalService: BsModalService,
    ) { }

    //初始化
    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.Search();
    }


    //視窗資料
    OpenDialog(template: TemplateRef<any>, i: number) {
        this.detailData = {
            Post_Id: null,
            Type_ID: '1',
            Type_Name: '',
            Unit_Code: '',
            Unit_Name: '',
            Post_UnitName: '',
            Post_Subject: '',
            Post_Content: '',
            Post_Date: '',
            End_Date: '',
            Post_Object: '',
            Attachment: []
        };

        this.isNewData = true;
        this.isInsert = true;

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        if (i > -1) {
            this.isInsert = false;
            this.isUpdate = true;
        } else {
            this.isInsert = true;
            this.isUpdate = false;
        }

        if (this.isUpdate) {
            this.SearchDetail(i);
        }

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    //查詢列表
    Search(): void {
        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0103/Search", this.condition).subscribe((response) => {
            this.listData = response;

            if (this.listData.length == 0) {
                this.toastr.warning('查無資料');
            }
        });
    }

    //詳細資料
    SearchDetail(i: number): void {
        this.http.post<any>(this.baseUrl + "ACAD/PUB/ADPUB0103/search_detail", this.listData[i]).subscribe((response) => {

            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.detailData = response;
        });
    }

    //下載
    Download(FileName: string, base64: string) {

        let link1 = document.createElement('a');
        link1.href = 'data:octet-stream;base64,' + base64
        link1.download = FileName;
        document.body.appendChild(link1);
        link1.click();
        document.body.removeChild(link1);

    }

}
