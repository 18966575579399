import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetSELParametersList, ParameterValues } from 'src/app/_Models/AD/ADSEL/ADSEL0104/getSELParametersList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0104',
    templateUrl: './ADSEL0104.component.html',
    styleUrls: ['./ADSEL0104.component.css']
})
export class ADSEL0104Component implements OnInit {
    baseUrl = environment.apiUrl;
    eduList!: GetEduList[];
    sELParametersList!: GetSELParametersList[];
    parameterValues!: ParameterValues[];
    boolDefaultValue?: boolean = undefined;
    intDefaultValue?: number = undefined;
    stringDefaultValue?: string = undefined;
    paraIndex = 0;
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getEduList();
        this.getSELParametersList();
    }


    getEduList() {
        this.systemService.getEduList('1', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getSELParametersList() {
        this.http.get<any>(this.baseUrl + "ACAD/SEL/ADSEL0104/Get_SEL_Parameters_List").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELParametersList = response;
        });
    }

    setParameterValues(index: number) {
        this.paraIndex = index;
        this.parameterValues = this.sELParametersList[index].ParameterValues;
        this.stringDefaultValue = undefined;
        this.intDefaultValue = undefined;
        this.boolDefaultValue = undefined;
    }

    getSELParametersSave() {

        console.log(this.parameterValues);

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0104/Get_SEL_Parameters_Save", this.parameterValues).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stringDefaultValue = undefined;
            this.intDefaultValue = undefined;
            this.boolDefaultValue = undefined;
            this.toastr.success("儲存成功");
        });
    }

    setDefault(eduNo: string) {

        if (eduNo != '') {
            this.parameterValues.filter(n => n.EduNo == eduNo).forEach(element => {
                if (this.intDefaultValue) {
                    element.IntValue = this.intDefaultValue;
                }
                else if (this.boolDefaultValue) {
                    element.BoolValue = this.boolDefaultValue;
                }
                else if (this.stringDefaultValue) {
                    element.TextValue = this.stringDefaultValue;
                }
            });
        }
        else {
            this.parameterValues.forEach(element => {
                if (this.intDefaultValue) {
                    element.IntValue = this.intDefaultValue;
                }
                else if (this.boolDefaultValue) {
                    element.BoolValue = this.boolDefaultValue;
                }
                else if (this.stringDefaultValue) {
                    element.TextValue = this.stringDefaultValue;
                }
            });
        }
        this.sELParametersList[this.paraIndex].ParameterValues = this.parameterValues;
    }
}
