<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" #Term>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <button class="search-btn" (click)="search(Year.value, Term.value)">查詢</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">第一日</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" #FirstDate bsDatepicker
                    [bsConfig]="bsConfig" readonly style="background-color: transparent;">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">最後一日</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" #LastDate bsDatepicker
                    [bsConfig]="bsConfig" readonly style="background-color: transparent;">
            </div>
        </div>
        <div class="col-md-3">
            <button class="other-Button-gray"
                (click)="insert(Year.value, Term.value, FirstDate.value, LastDate.value)">設定</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>週次</th>
                        <th>起始日期</th>
                        <th>結束日期</th>
                        <th>備註</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="searchDetail(i)">
                        <td>{{i + 1}}</td>
                        <td>{{item.Cur_Week}}</td>
                        <td>{{item.Week_StartDay}}</td>
                        <td>{{item.Week_EndDay}}</td>
                        <td>{{item.Memo}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">週次</span>
                        <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Cur_Week">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">本週起始日</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Week_StartDay">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">本週結束日</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Week_EndDay">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">備註</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Memo">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_User">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_Time">
                    </div>
                </div>
                <div class="col-md-12">
                    <button class="other-Button-gray" (click)="update(Year.value, Term.value)">修改備註</button>
                </div>
            </div>
        </div>
    </div>
</div>
