<div class="container">
    <div class="row">
        <div class="col-md-10">
            <div class="input-group flex-nowrap mb-1">
                <button class="search-btn" style="width: 130px;" (click)="search()">{{'查詢'|translate}}</button>
            </div>
        </div>
        <div class="col-md-1">
            <div class=" input-group flex-nowrap mb-1">
                <button class="other-Button-gray float-end" style="width: 130px;" (click)="openModal(template)" (click)="searchdetail()">{{'申請'|translate}}</button>
            </div>
        </div>
        <div class="col-md-1">
            <div class="input-group flex-nowrap mb-1">
                <button class="other-Button-gray float-end" style="width: 130px;" (click)="delete()">{{'刪除'|translate}}</button>
            </div>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3 table-rwd">
        <thead class="thead-dark-red table-rwd-hide">
            <tr>
                <th style="width:40px;vertical-align:middle;">
                    <p style="margin:2px;"><input type="checkbox" class="pointer shadow-none form-check-input" (click)="setDataKey_all($event)"></p>
                    <p style="margin:2px;">{{'全選'|translate}}</p>
                </th>
                <th style="width:40px;vertical-align:middle;">{{'學年期'|translate}}</th>
                <th style="width:50px;vertical-align:middle;">{{'職編'|translate}}</th>
                <th style="width:70px;vertical-align:middle;">{{'姓名'|translate}}</th>
                <th style="width:60px;vertical-align:middle;">{{'單號'|translate}}</th>
                <th style="width:50px;vertical-align:middle;">{{'編輯'|translate}}</th>
                <th style="width:90px;vertical-align:middle;">{{'離職申請日期'|translate}}</th>
                <th style="width:50px;vertical-align:middle;">{{'狀態'|translate}}</th>
                <th style="width:80px;vertical-align:middle;">{{'附件'|translate}}</th>
                <th style="width:60px;vertical-align:middle;">{{'申請離校'|translate}}</th>
                <th style="width:90px;vertical-align:middle;">{{'離校申請日期'|translate}}</th>
                <th style="width:60px;vertical-align:middle;">{{'離校狀態'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of search_list; let i=index">
                <td style="width:40px;vertical-align:middle;"><input *ngIf="item.Status === '0'" type="checkbox" [checked]="item.Checked" (change)="setDataKey(item.Checked)" class="pointer">
                </td>
                <td data-th="學年期" style="width:40px;vertical-align:middle;">{{item.Years}}{{item.Term}}</td>
                <td data-th="老師編號" style="width:50px;vertical-align:middle;">{{item.Code}}</td>
                <td data-th="老師名稱" style="width:70px;vertical-align:middle;">{{item.TchName}}</td>
                <td data-th="單號" style="width:60px;vertical-align:middle;">{{item.SerialNo}}</td>
                <td data-th="編輯" style="width:50px;vertical-align:middle;">
                    <a class="pointer text-decoration-none" (click)="openModal(template)" (click)="searchdetail_edit(i)">
                        <span class="icon-Fill-in-button"></span>{{'編輯'|translate}}
                    </a>
                </td>
                <td data-th="離職申請日期" style="width:90px;vertical-align:middle;">{{item.ApplyDate}}</td>
                <td data-th="狀態" style="width:50px;vertical-align:middle;">
                    <ng-container *ngIf="item.Status != '0' else other">
                        <a class="pointer text-decoration-none" (click)="openModal_status(floatTemplate,item.DataKey)">
                            {{item.StatusName}}
                        </a>
                    </ng-container>
                    <ng-template #other>{{item.StatusName}}</ng-template>
                </td>
                <td data-th="附件" style="width:80px;vertical-align:middle;">
                    <p style="margin:3px;" *ngIf="item.FileName != ''">
                        <a class="pointer text-decoration-none" (click)="getCertFileDownLoad(item)">
                            <span>{{item.FileName}}</span>
                        </a>
                    </p>
                    <ng-container *ngIf="item.FileName == '' && item.Status == '2'">
                        <a class="pointer text-decoration-none" (click)="openModalFile(CertFiletemplate,item)">
                            <span>{{'上傳'|translate}}</span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="item.FileName != ''">
                        <a class="pointer text-decoration-none" (click)="getCertFileDelete(item)">
                            <span>{{'刪除'|translate}}</span>
                        </a>
                    </ng-container>
                </td>
                <td data-th="申請離校" style="width:60px;vertical-align:middle;">
                    <ng-container *ngIf="item.Status == '2' && item.LeaveStatus=='0'">
                        <a class="pointer text-decoration-none" (click)="searchdetail_leavesave(i)">
                            {{'申請離校'|translate}}
                        </a>
                    </ng-container>
                </td>
                <td data-th="離校申請日期" style="width:90px;vertical-align:middle;">
                    {{item.LeaveApplyDate}}
                </td>
                <td data-th="離校狀態" style="width:60px;vertical-align:middle;">
                    <ng-container *ngIf="item.LeaveStatus != '0' else leaveother">
                        <a class="pointer text-decoration-none" (click)="openModal_status(floatTemplate,item.LeaveDocNo)">
                            {{item.LeaveStatusName}}
                        </a>
                    </ng-container>
                    <ng-template #leaveother>{{item.LeaveStatusName}}</ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'離職申請'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close" (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'單位'|translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="dataDetail.UnitName">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'姓名'|translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="dataDetail.UserName">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'職稱'|translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="dataDetail.JobName">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'到校日期'|translate}}</span>
                    </div>
                    <input type="text" disabled class="form-control shadow-none" [(ngModel)]="dataDetail.StartDate">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'任職截止日期'|translate}}</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker [bsConfig]="bsConfig" readonly style="background-color: transparent;" [(ngModel)]="dataDetail.EndDate" [min]="minDate">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'離職原因'|translate}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <textarea class="form-control" class="common" name="mytext" rows="6" cols="135" [(ngModel)]="dataDetail.Reason"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span [innerHTML]="MemoContents"></span>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row mt-3">
            <div class="col-md-12">
                <button *ngIf="dataDetail.Status === '0'" class="send-btn float-start me-3" (click)="send()">{{'送出'|translate}}</button>
                <button *ngIf="dataDetail.Status === '0'" class="other-Button-gray float-start me-3" (click)="save()">{{'儲存'|translate}}</button>
                <button class="other-Button-gray float-start me-3" (click)="modalRef.hide()">{{'關閉'|translate}}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #floatTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'簽核流程' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close" (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <table class="table table-hover table-striped text-center table-rwd table-borderless">
            <thead class="thead-dark-red table-rwd-hide">
                <tr>
                    <th>{{'項次' | translate}}</th>
                    <th>{{'簽核名單' | translate}}</th>
                    <th>{{'代理/加簽' | translate}}</th>
                    <th>{{'簽核單位' | translate}}</th>
                    <th>{{'簽收時間' | translate}}</th>
                    <th>{{'核稿時間' | translate}}</th>
                    <th>{{'簽核意見' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of eDocFlow">
                    <td data-th="項次">{{item.Stage}}</td>
                    <td data-th="簽核名單">{{item.UserName}}</td>
                    <td data-th="代理/加簽">{{item.AgentName}}</td>
                    <td data-th="簽核單位">{{item.UnitName}}</td>
                    <td data-th="簽收時間">{{item.ReadDate}}</td>
                    <td data-th="核稿時間">{{item.SignDate}}</td>
                    <td data-th="簽核意見">{{item.Content}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #CertFiletemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'上傳附件'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef!.hide()">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <input class="form-control shadow-none" type="file" (change)="importAttFile($event, '', '')">
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <button class="other-Button-gray float-end me-3" (click)="getCertFileUpload()">{{'上傳'|translate}}</button>
            </div>
        </div>
    </div>
</ng-template>
