<div class="container">
    <app-year-term-input (childEvent)="getCurList($event)"></app-year-term-input>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'星期'|translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="selectedWeek">
                    <option value="" disabled selected>{{'--請選擇星期--'|translate}}</option>
                    <option value="1">{{'星期一'|translate}}</option>
                    <option value="2">{{'星期二'|translate}}</option>
                    <option value="3">{{'星期三'|translate}}</option>
                    <option value="4">{{'星期四'|translate}}</option>
                    <option value="5">{{'星期五'|translate}}</option>
                    <option value="6">{{'星期六'|translate}}</option>
                    <option value="7">{{'星期日'|translate}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'節次'|translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="curTime">
                    <option value="" disabled selected>{{'--請選擇星期--'|translate}}</option>
                    <option value="{{item.Section}}" *ngFor="let item of curTimeList">{{item.Section_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'地點'|translate}}</span>
                </div>
                <input type="text" placeholder="{{'例如：美術樓 H504'|translate}}" class="form-control shadow-none" maxlength="20"
                    [(ngModel)]="room">
            </div>
        </div>
        <div class="col-md-2">
            <button class="add-btn" (click)="addOfficeHour()">{{'新增'|translate}}</button>
        </div>
    </div>
    <small class="text-danger fw-bolder">{{'*點選課程可以進行刪除'|translate}}</small>
    <app-cur-class id="cur-class" [curList]="data" [functionType]="functionType" (childEvent)="resfresh()">
    </app-cur-class>
    <app-cur-class-rwd id="cur-class-rwd" [curList]="data" [functionType]="functionType" (childEvent)="resfresh()">
    </app-cur-class-rwd>
</div>