<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="ngFormInput">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">開課年度</span>
                    <select class="form-select shadow-none" #year formControlName="Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value, false)"
                        formControlName="EduNo">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">關鍵字</span>
                    <input type="text" class="form-control shadow-none" formControlName="KeyWord">
                </div>
            </div>
        </div>
    </form>
    <div class="row mb-3">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="clear()">清除畫面</button>
            <button class="other-Button-gray float-end me-3" (click)="getCUPPreCourseDelete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="getCUPPreCourseModify()">儲存</button>
            <button class="search-btn float-end me-3" (click)="getCUPPreCourseList()">查詢</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>科目代碼</th>
                        <th>科系名稱</th>
                        <th>學期別</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of cUPPreCourseList; let i = index"
                        (click)="getCUPPreCourseCosList(item.DataKey, i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.CosID}}</td>
                        <td>{{item.CosName}}</td>
                        <td>{{item.CosTerm}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-5">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>群組</th>
                        <th>科目代碼</th>
                        <th>科目名稱</th>
                        <th>學期別</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of cUPPreCourseCosList; let i = index"
                        (click)="getCUPPreCourseDetail(item.DataKey, i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.CosCluster}}</td>
                        <td>{{item.CosID}}</td>
                        <td>{{item.CosName}}</td>
                        <td>{{item.CosTerm}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-3" style="height: 565px; overflow-y: scroll;">
            <form [formGroup]="ngForm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">入學年</span>
                            <select class="form-select shadow-none" formControlName="Years">
                                <option value="" disabled>--請選擇--</option>
                                <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">科目代碼</span>
                            <input type="text" class="form-control shadow-none" formControlName="CosID">
                            <button class="btn btn-light shadow-none" (click)="openModal(template, false)">...</button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">科目名稱</span>
                            <input type="text" class="form-control shadow-none" formControlName="CosName">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">學期別</span>
                            <input type="text" class="form-control shadow-none" formControlName="CosTerm">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">必選修別</span>
                            <input type="text" class="form-control shadow-none" formControlName="SelKindName">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">學分數</span>
                            <input type="text" class="form-control shadow-none" formControlName="Credits">
                        </div>
                    </div>
                    <hr>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">科目代碼</span>
                            <input type="text" class="form-control shadow-none" formControlName="PreCosID">
                            <button class="btn btn-light shadow-none" (click)="openModal(template, true)">...</button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">科目名稱</span>
                            <input type="text" class="form-control shadow-none" formControlName="PreCosName">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">學期別</span>
                            <input type="text" class="form-control shadow-none" formControlName="PreCosTerm">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">必選修別</span>
                            <input type="text" class="form-control shadow-none" formControlName="PreSelKindName">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">學分數</span>
                            <input type="text" class="form-control shadow-none" formControlName="PreCredits">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">群組</span>
                            <select class='form-select shadow-none' formControlName="CosCluster">
                                <option value="">--請選擇--</option>
                                <option *ngFor="let item of group" value="{{item.itemValue}}">{{item.itemName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">應過科目數</span>
                            <input type="number" class="form-control shadow-none" formControlName="MustPassNum" min="0">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">限制分數</span>
                            <input type="number" class="form-control shadow-none" formControlName="PassScore" min="0"
                                max="100">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-check form-switch">
                            <input class="form-check-input pointer" type="checkbox" role="switch"
                                id="flexSwitchCheckDefault" formControlName="CanReplace">
                            <label class="form-check-label pointer" for="flexSwitchCheckDefault">先修是否可替代</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">修改者</span>
                            <input type="number" class="form-control shadow-none" formControlName="UpdateUser">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">修改時間</span>
                            <input type="number" class="form-control shadow-none" formControlName="UpdateTime">
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢科目</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormClassInput">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學制</span>
                        <select class="form-select shadow-none" #edu (ngModelChange)="getDeptList(edu.value, true)"
                            formControlName="EduNo">
                            <option value="" selected disabled>--請選擇學制--</option>
                            <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" formControlName="DeptNo">
                            <option value="" disabled>--請選擇系所--</option>
                            <option *ngFor="let item of detailDeptList" value="{{item.Dept_No}}">
                                {{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">狀態</span>
                        <select class="form-select shadow-none" formControlName="Status">
                            <option value="" disabled>--請選擇--</option>
                            <option value="1">啟用</option>
                            <option value="0">未啟用</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">必選修別</span>
                        <select class="form-select shadow-none" formControlName="SelKind">
                            <option value="" disabled>--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of selKindList">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group mb-3">
                        <span class="input-group-text">關鍵字</span>
                        <input type="text" class="form-control shadow-none" formControlName="KeyWord">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-check form-check-inline mt-2">
                        <input class="form-check-input pointer shadow-none" type="radio" name="OrderBy"
                            id="inlineRadio1" value="1" formControlName="OrderBy">
                        <label class="form-check-label pointer" for="inlineRadio1">依科目排列</label>
                    </div>
                    <div class="form-check form-check-inline mt-2">
                        <input class="form-check-input pointer shadow-none" type="radio" name="OrderBy"
                            id="inlineRadio2" value="2" formControlName="OrderBy">
                        <label class="form-check-label pointer" for="inlineRadio2">依選修別排列</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <button class="search-btn me-3 float-end" (click)="getCUPPermanentList()">查詢</button>
                </div>
            </div>
        </form>
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th style="width: 60px;"></th>
                    <th>代碼</th>
                    <th>名稱</th>
                    <th>系所</th>
                    <th style="width: 80px;">選修別</th>
                    <th style="width: 80px;">總學分</th>
                    <th style="width: 80px;">開課學分</th>
                    <th style="width: 80px;">狀態</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of cUPPermanentList; let i = index" class="pointer"
                    [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                    (click)="setClass(item.CosID, item.CosName, item.CosTerm, item.SelKindName, item.CosCredits)">
                    <td style="width: 60px;">{{i + 1}}</td>
                    <td>{{item.CosID}}</td>
                    <td>{{item.CosName}}</td>
                    <td>{{item.DeptName}}</td>
                    <td style="width: 80px;">{{item.SelKindName}}</td>
                    <td style="width: 80px;">{{item.AllCredits}}</td>
                    <td style="width: 80px;">{{item.CosCredits}}</td>
                    <td style="width: 80px;">{{item.Status}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>