<div class="container">
    <div class="row">
        <div>
            <alert type="danger">
                <strong>{{'畢業資格審查預審'|translate}}{{model.Years}}{{'學年度第'|translate}}{{model.Term}}{{'學期'|translate}}{{'確認作業時間:'|translate}}{{model.SDate}}{{'至'|translate}}{{model.EDate}}{{'開放查詢時間：'|translate}}{{model.OpenSDate}}{{'至'|translate}}{{model.OpenEDate}}</strong>
            </alert>
        </div>
        <div style="width:780px;">
            <ng-container *ngIf="EnableConfirm || EnableQuery">
                <app-Contrast id="Contrast" *ngFor="let item of stdSelects" [inputstd]="item.DataKey"
                    [stdSelect]="stdSelect" [UserType]="UserType" [thisUserType]="item.UserType"
                    [EnableConfirm]="EnableConfirm"></app-Contrast>
            </ng-container>
        </div>
    </div>
</div>