<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getCUPCurAssistPlanDetail()">
        <button #submit [hidden]="true"></button>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" formControlName="Years">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學制</span>
                    <select class="form-select shadow-none" #edu (change)="getDeptList(edu.value, false, false, false)">
                        <option value="" selected disabled>--請選擇學制--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">系所</span>
                    <select class="form-select shadow-none" #dept
                        (change)="getTeamList(dept.value, false, false, false)" formControlName="DeptNo">
                        <option value="" selected>--請選擇系所--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">組別</span>
                    <select class="form-select shadow-none" formControlName="TeamNo">
                        <option value="" selected>--請選擇組別--</option>
                        <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </form>

    <div class="row mb-3">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="getCUPCurAssistPlanDeleteAll()">刪除全部</button>
            <button class="other-Button-gray float-end me-3" (click)="getCUPCurAssistPlanDelete()">刪除單筆</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal('', copyTemplate)">複製課程</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal('edit', editTemplate)">修改</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal('new', newTemplate)">新增</button>
            <button class="search-btn float-end me-3" (click)="submit.click()">查詢</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th>代碼</th>
                <th>名稱</th>
                <th>學分</th>
                <th>時數</th>
                <th>選修別</th>
                <th>註記</th>
                <th>群組</th>
            </tr>
        </thead>
        <tbody>
            <tr class="pointer" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                *ngFor="let item of cUPCurAssistPlanDetail; let i = index" (click)="chooseData(item.DataKey, i)">
                <td>{{i + 1}}</td>
                <td>{{item.CosID}}</td>
                <td>{{item.CosName}}</td>
                <td>{{item.Credits}}</td>
                <td>{{item.CosHours}}</td>
                <td>{{item.SelKindName}}</td>
                <td>{{item.SelStyle}}</td>
                <td>{{item.CosCluster}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #newTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">新增雙主修課程</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormNewInput" (ngSubmit)="getCosData()">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學制</span>
                        <select class="form-select shadow-none" #edu
                            (ngModelChange)="getDeptList(edu.value, true, false, false)" formControlName="EduNo">
                            <option value="" selected disabled>--請選擇學制--</option>
                            <option *ngFor="let item of newEduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" formControlName="DeptNo">
                            <option value="" disabled>--請選擇系所--</option>
                            <option *ngFor="let item of newDeptList" value="{{item.Dept_No}}">
                                {{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">狀態</span>
                        <select class="form-select shadow-none" formControlName="Status">
                            <option value="" disabled>--請選擇--</option>
                            <option value="1">啟用</option>
                            <option value="0">未啟用</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group mb-3">
                        <span class="input-group-text">關鍵字</span>
                        <input type="text" class="form-control shadow-none" formControlName="KeyWord">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="search-btn">查詢</button>
                </div>
            </div>
        </form>
        <hr>
        <form [formGroup]="ngFormNew" (ngSubmit)="getCUPCurAssistPlanAddNew()">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學年</span>
                        <select class="form-select shadow-none" formControlName="Years">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">系所</span>
                        <select class="form-select shadow-none" #dept
                            (change)="getTeamList(dept.value, false, false, false)" formControlName="DeptNo">
                            <option value="" selected disabled>--請選擇系所--</option>
                            <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">組別</span>
                        <select class="form-select shadow-none" formControlName="TeamNo">
                            <option value="" selected>--請選擇組別--</option>
                            <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">群組</span>
                        <select class='form-select shadow-none' formControlName="CosCluster">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of group" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">應過科目數</span>
                        <input type="number" [min]="0" class="form-control shadow-none" formControlName="MustPassNum">
                    </div>
                </div>
                <div class="col-md-3">
                    <button class="other-Button-gray">儲存</button>
                </div>
            </div>
        </form>

        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th></th>
                    <th>科目代碼</th>
                    <th>科目名稱</th>
                    <th>學分</th>
                    <th>選修別</th>
                    <th>開課學期別</th>
                    <th>狀態</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of cUPPermanentList">
                    <td><input type="checkbox" class="pointer form-check-input"
                            (click)="setDatakey('', item.DataKey, $event)"></td>
                    <td>{{item.CosID}}</td>
                    <td>{{item.CosName}}</td>
                    <td>{{item.CosCredits}}</td>
                    <td>{{item.SelKindName}}</td>
                    <td>{{item.CosTerm}}</td>
                    <td>{{item.Status}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>


<ng-template #editTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">修改雙主修課程</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormEdit" (ngSubmit)="getCUPCurAssistPlanModify()">
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">科目代碼</span>
                        <input type="text" class="form-control shadow-none" formControlName="CosID">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">科目名稱</span>
                        <input type="text" class="form-control shadow-none" formControlName="CosName">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">必選修別</span>
                        <input type="text" class="form-control shadow-none" formControlName="SelKindName">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">學分數</span>
                        <input type="text" class="form-control shadow-none" formControlName="Credits">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">時數</span>
                        <input type="text" class="form-control shadow-none" formControlName="CosHours">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">課程類別</span>
                        <input type="text" class="form-control shadow-none" formControlName="CurClass">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">特殊註記</span>
                        <input type="text" class="form-control shadow-none" formControlName="SelStyle">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">群組</span>
                        <select class='form-select shadow-none' formControlName="CosCluster">
                            <option value="" disabled>--請選擇--</option>
                            <option *ngFor="let item of group" value="{{item.itemValue}}">{{item.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">應過科目數</span>
                        <input type="number" [min]="0" class="form-control shadow-none" formControlName="MustPassNum">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" formControlName="UpdateUser">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group flex-nowrap mb-3">
                            <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" formControlName="UpdateTime">
                    </div>
                </div>
                <div class="col-md-3">
                    <button class="save-btn-gray">儲存</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #copyTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">複製雙主修課程檔</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ngFormCopy" (ngSubmit)="getCUPCurAssistPlanCopyTo()">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">來源課程</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">學年</span>
                                        <select class="form-select shadow-none" formControlName="Years">
                                            <option value="" disabled>--請選擇--</option>
                                            <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">學制</span>
                                        <select class="form-select shadow-none" #copyEdu
                                            (change)="getDeptList(copyEdu.value, false, true, false)">
                                            <option value="" selected disabled>--請選擇學制--</option>
                                            <option *ngFor="let item of copyEduList" value="{{item.Edu_No}}">
                                                {{item.Edu_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">系所</span>
                                        <select class="form-select shadow-none" #copyDept
                                            (change)="getTeamList(copyDept.value, false, true, false)"
                                            formControlName="DeptNo">
                                            <option value="" selected disabled>--請選擇系所--</option>
                                            <option *ngFor="let item of copyDeptList" value="{{item.Dept_No}}">
                                                {{item.Dept_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">組別</span>
                                        <select class="form-select shadow-none" formControlName="TeamNo">
                                            <option value="" selected>--請選擇組別--</option>
                                            <option *ngFor="let item of copyTeamList" value="{{item.Team_No}}">
                                                {{item.Team_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">建立目標</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">學年</span>
                                        <select class="form-select shadow-none" formControlName="TargetYears">
                                            <option value="" disabled>--請選擇--</option>
                                            <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">學制</span>
                                        <select class="form-select shadow-none" #targetEdu
                                            (change)="getDeptList(targetEdu.value, false, true, true)">
                                            <option value="" selected disabled>--請選擇學制--</option>
                                            <option *ngFor="let item of targetEduList" value="{{item.Edu_No}}">
                                                {{item.Edu_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">系所</span>
                                        <select class="form-select shadow-none" #targetDept
                                            (change)="getTeamList(targetDept.value, false, true, true)"
                                            formControlName="TargetDeptNo">
                                            <option value="" selected disabled>--請選擇系所--</option>
                                            <option *ngFor="let item of targetDeptList" value="{{item.Dept_No}}">
                                                {{item.Dept_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-group flex-nowrap mb-3">
                                            <span class="input-group-text">組別</span>
                                        <select class="form-select shadow-none" formControlName="TargetTeamNo">
                                            <option value="" selected>--請選擇組別--</option>
                                            <option *ngFor="let item of targetTeamList" value="{{item.Team_No}}">
                                                {{item.Team_Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <button class="save-btn-gray mx-auto">儲存</button>
            </div>
        </form>
    </div>
</ng-template>
