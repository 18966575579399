<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學院代碼</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Acad_No" maxlength="3"
                    placeholder="至多三碼">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學院名稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Acad_Name">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">英文名稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Acad_EngName">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">使用狀態</span>
                <select class="form-select" [(ngModel)]="dataDetail.State_Log">
                    <option value="">--請選擇--</option>
                    <option value="1">啟用</option>
                    <option value="0">未啟用</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學院院長</span>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Master_Name">
                <input type="hidden" [(ngModel)]="dataDetail.Master_Code">
                <button class="btn btn-light shadow-none" (click)="openModal(template)">...</button>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">修改日期</span>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Update_Time">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">修改者</span>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="dataDetail.Update_User">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end me-3" (click)="clear()">清除畫面</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="add-btn float-end me-3" (click)="insert()">新增</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center mt-3">
        <thead class="thead-dark-red">
            <tr>
                <th></th>
                <th>學院代碼</th>
                <th>學院名稱</th>
                <th>使用狀態</th>
            </tr>
        </thead>
        <tbody>
            <tr class="pointer" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                *ngFor="let item of data; let i = index" (click)="searchDetail(item.Acad_No, i)">
                <td>{{i + 1}}</td>
                <td>{{item.Acad_No}}</td>
                <td>{{item.Acad_Name}}</td>
                <td>{{item.State_Log == '1'?'啟用':'未啟用'}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢教師</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group mb-3">
                    <span class="input-group-text">系所查詢</span>
                    <input type="text" class="form-control shadow-none" placeholder="輸入系所名稱" [(ngModel)]="selected"
                        [typeaheadScrollable]="true" [typeahead]="employeeList" typeaheadOptionField="Name"
                        [isAnimated]="true">
                </div>
            </div>
        </div>
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th></th>
                    <th>教學單位</th>
                    <th>職稱</th>
                    <th>專兼任</th>
                    <th>教師代碼</th>
                    <th>教師姓名</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of employeeList; let i = index" class="pointer"
                    (click)="setTeacher(item.Code, item.Name)">
                    <td>{{i + 1}}</td>
                    <td>{{item.Unit_Name}}</td>
                    <td>{{item.Title_Name}}</td>
                    <td>{{item.Time_Case_Name}}</td>
                    <td>{{item.Code}}</td>
                    <td>{{item.Name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>