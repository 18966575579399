<div class="container">
    <alert type="danger">
        <strong>{{'注意：'|translate}}
        </strong>{{'配課作業相依於學生註冊資料'|translate}}
        {{'，請確認已完成學年期註冊資料'|translate}}

        <strong>&emsp;{{'作業時間:'|translate}}
        </strong>{{model.start}}&ensp;{{'至'|translate}}
        &ensp;{{model.end}}
    </alert>
    <form [formGroup]="ngFormInput" (ngSubmit)="getSELSetDefaultList()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'學年'|translate}}
                        </span>
                    <select class="form-select shadow-none" formControlName="Years">
                        <option value="" disabled>{{'--請選擇--'|translate}}
                        </option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}{{'學年'|translate}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'學期'|translate}}
                        </span>
                    <select class="form-select shadow-none" formControlName="Term">
                        <option value="" disabled>{{'--請選擇--'|translate}}
                        </option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'系所'|translate}}
                        </span>
                    <select class="form-select shadow-none" formControlName="DeptNo">
                        <option value="" selected disabled>{{'--請選擇系所--'|translate}}
                        </option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mb-3">
                <button class="search-btn float-start me-3">{{'查詢'|translate}}
                </button>
                <button type="button" class="other-Button-gray float-start"
                    (click)="getSELSetDefaultProcess()">{{'配課'|translate}}
                </button>
            </div>
        </div>
    </form>

    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" class="form-check-input shadow-none pointer"
                        (click)="setAll($event.target)">&ensp;{{'全選'|translate}}

                </th>
                <th>{{'系所'|translate}}
                </th>
                <th>{{'班級代碼'|translate}}
                </th>
                <th>{{'班級名稱'|translate}}
                </th>
                <th>{{'已配'|translate}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sELSetDefaultList">
                <td><input type="checkbox" class="form-check-input shadow-none pointer" [(ngModel)]="item.checked"></td>
                <td>{{item.DeptName}}</td>
                <td>{{item.Class}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.SetDefault}}</td>
            </tr>
        </tbody>
    </table>
</div>