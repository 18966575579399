import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0107/search';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0107/searchDetail';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
	selector: 'app-ADRGP0107',
	templateUrl: './ADRGP0107.component.html',
	styleUrls: ['./ADRGP0107.component.css']
})
export class ADRGP0107Component implements OnInit {
	deptList!: GetDeptList[];
	baseUrl = environment.apiUrl;
	data!: Search[];
	dataDetail: SearchDetail = {
		AcadCourse_No: '',
		AcadCourse_Name: '',
		AcadCourse_EngName: '',
		Dept_No: '',
		State_Log: '',
		Update_User: '',
		Update_Time: ''
	}
	search1 = {
		deptNo: ''
	}
	constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService, private systemService: SystemService,) { }

	ngOnInit(): void {
		this.getDeptList();
	}


	getDeptList() {
		this.systemService.getDeptList('', '', '', '', 'Y', '').subscribe((response) => {
			this.deptList = response;
		}, error => this.toastr.error(error.error));
	}

	search(Dept_No: string) {
		this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0107/Search", { Dept_No: Dept_No }).subscribe((response) => {
			if (response.Info == false) {
				this.toastr.warning(response.Message);
				this.data = []
				return;
			}
			this.data = response;
			this.data.forEach(element => {
				element.Selected = false;
			});
		})
	}

	searchDetail(AcadCourse_No: string, index: number) {
		this.data.forEach(element => {
			element.Selected = false;
		});
		this.data[index].Selected = true;
		this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0107/Search_Detail", { AcadCourse_No: AcadCourse_No }).subscribe((response) => {
			if (response.Info == false) {
				this.toastr.warning(response.Message);
				return;
			}
			this.dataDetail = response;
			this.dataDetail.AcadCourse_EngName = this.dataDetail.AcadCourse_EngName;
			this.dataDetail.AcadCourse_Name = this.dataDetail.AcadCourse_Name;
			this.dataDetail.Update_Time = this.dataDetail.Update_Time;
			this.dataDetail.Update_User = this.dataDetail.Update_User;
		});
	}

	insert() {
		if (this.dataDetail.AcadCourse_No == '' || this.dataDetail.AcadCourse_Name == '' || this.dataDetail.AcadCourse_EngName == '' ||
			this.dataDetail.Dept_No == '' || this.dataDetail.State_Log == '') {
			this.toastr.info("請將所有欄位填妥");
			return;
		}

		this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0107/Insert", this.dataDetail).subscribe((response) => {
			if (response.Info == false) {
				this.toastr.warning(response.Message);
				return;
			}
			this.toastr.success("新增成功，請重新查詢");
			this.search(this.search1.deptNo)
		}, error => this.toastr.error(error.error))
	}

	update() {
		if (this.dataDetail.AcadCourse_No == '' || this.dataDetail.AcadCourse_Name == '' || this.dataDetail.AcadCourse_EngName == '' ||
			this.dataDetail.Dept_No == '' || this.dataDetail.State_Log == '') {
			this.toastr.info("請將所有欄位填妥");
			return;
		}
		this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0107/Update", this.dataDetail).subscribe((response) => {
			if (response.Info == false) {
				this.toastr.warning(response.Message);
				return;
			}
			this.toastr.success("修改成功，請重新查詢");
			this.search(this.search1.deptNo)
		}, error => this.toastr.error(error.error))
	}

	delete() {
		if (this.dataDetail.AcadCourse_No == '') {
			this.toastr.info("請選擇學程");
			return;
		}
		this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0107/Delete", this.dataDetail).subscribe((response) => {
			if (response.Info == false) {
				this.toastr.warning(response.Message);
				return;
			}
			this.toastr.success("刪除成功，請重新查詢");
			this.clear();
		}, error => this.toastr.error(error.error))
	}

	clear() {
		this.dataDetail = {
			AcadCourse_No: '',
			AcadCourse_Name: '',
			AcadCourse_EngName: '',
			Dept_No: '',
			State_Log: '',
			Update_User: '',
			Update_Time: ''
		}
	}

}
