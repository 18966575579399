<div class="container" style="margin-bottom: 100px;" *ngIf="data">
    <div class="row">
        <div class="col-md-3 mb-3">
            <figure class="figure">
                <img [src]="ImgUrl" class="figure-img img-fluid rounded" />
                <figcaption class="figure-caption">
                    <button class="other-Button-gray img-btn" (click)="openModal1(template1)">上傳</button>
                    <small class="text-danger fw-bolder ps-3">*上傳背景照片</small>
                </figcaption>
            </figure>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學校代碼</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Sch_No">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學校名稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Sch_Name">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學校簡稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Sch_Short">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">通訊電話</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Com_Tel">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">傳真電話</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Com_Fax">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">郵遞區號</span>
                <input type="text" class="form-control shadow-none" placeholder="3+3郵遞區號" maxlength="6"
                    [(ngModel)]="data.Zip">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">統一編號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Unit_ID">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">通訊地址</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Com_Address">
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">學校英文名稱</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Sch_EngName">
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">英文地址</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Eng_Address">
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text">校務系統網址</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Website">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">校長姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Principal">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">報稅稽徵機關</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="data.Revenue">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">修改日期</span>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="data.Update_User">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">修改者</span>
                <input type="text" class="form-control shadow-none" disabled [(ngModel)]="data.Update_Time">
            </div>
        </div>
    </div>
    <div>
        <button class="save-btn-gray mx-auto" (click)="update()">儲存</button>
    </div>
</div>
<ng-template #template1>
    <div class="modal-header">
        <h4 class="modal-title pull-left">設定背景照片</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">背景照片設定</div>
                    <div class="col-md-3">
                        <input name="file" type="file" id="image" accept=".png" class="form-control shadow-none"
                            (change)="fileChangeEvent($event)" />
                    </div>
                    <div class="col">
                        <div class="card-body" style="height:300px; overflow-y: scroll;">
                            <h5 [hidden]="!showCropper" class="card-title">移動匡線來調整位置</h5>
                            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                [containWithinAspectRatio]="false" [cropperMinWidth]="600.0"
                                [aspectRatio]="2430.0/748.0" format="png" (imageCropped)="imageCropped1($event)"
                                (imageLoaded)="imageLoaded($event)" (loadImageFailed)="loadImageFailed()" format="png"
                                [style.display]="showCropper ? null : 'none'">
                            </image-cropper>
                        </div>
                        <div class="col">
                            <button class="other-Button-gray mt-3 float-end me-3" (click)="uploadImage1()">上傳</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12  mt-3">
                <div class="card" style="height:230px;">
                    <div class="card-header">背景照片預覽</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <img [src]="croppedImageA" class="card-img-top" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>