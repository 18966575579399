<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year (change)="getEduList(Year.value, Term.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term (change)="getEduList(Year.value, Term.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學制</span>
                </div>
                <select #EduNo class="form-select shadow-none">
                    <option value="">--全部--</option>
                    <option value="{{item.Edu_No}}" *ngFor="let item of eduList">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">身分別</span>
                </div>
                <select #EntryID class="form-select shadow-none">
                    <option value="">--全部--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of entryIDList">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">收費類別</span>
                </div>
                <select #AcadNo class="form-select shadow-none">
                    <option value="">--全部--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of feeAcadList">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="search-btn float-start"
                (click)="getTFSModuleList(Year.value, Term.value, EduNo.value, EntryID.value, AcadNo.value)">查詢</button>
            <button class="other-Button-gray float-end"
                (click)="getTFSModuleDeptFeeTypeList(Year.value, Term.value, EduNo.value, EntryID.value, AcadNo.value, typeTemplate)">類別設定</button>
            <button class="other-Button-gray float-end me-3" (click)="getTFSModuleSave()">儲存</button>
            <button class="other-Button-gray float-end me-3" (click)="setCopy(copyTemplate)">複製</button>
        </div>
    </div>



    <div class="row mt-3">
        <div class="col-md">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>學年</th>
                        <th>學期</th>
                        <th>學制</th>
                        <th>身份別</th>
                        <th>收費類別</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of tFSModuleList; let i = index" class="pointer"
                        (click)="getTFSModuleDetail(i, item.DataKey)">
                        <td>{{i+1}}</td>
                        <td>{{item.Years}}</td>
                        <td>{{item.Term}}</td>
                        <td>{{item.EduName}}</td>
                        <td>{{item.EntryName}}</td>
                        <td>{{item.AcadName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md">
            <table class="table table-hover text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>收費代碼</th>
                        <th>收費項目</th>
                        <th>收費金額</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tFSModuleDetail; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.FeeNo}}</td>
                        <td>{{item.FeeName}}</td>
                        <td><input type="text" [(ngModel)]="item.Money" class="form-control form-control-input"
                                style="width: 80px;" [disabled]="item.Edit != 'Y'"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #copyTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">複製資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="copyModalRef.hide()">

        </button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-md">
                <h4>從</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #copyFromYears>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of copyYears" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #copyFromTerm>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <h4>複製到</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #copyToYears>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of copyYears" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #copyToTerm>
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <button class="save-btn-gray"
            (click)="copyData(copyFromYears.value, copyFromTerm.value, copyToYears.value, copyToTerm.value)">儲存</button>
    </div>
</ng-template>


<ng-template #typeTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">類別設定</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="typeModalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">收費類別</span>
                    </div>
                    <select #AcadNo class="form-select shadow-none">
                        <option value="" disabled>--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of setFeeAcadList">
                            {{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <button class="other-Button-blue" (click)="saveType(AcadNo.value)">勾選設定</button>
            </div>
        </div>
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th><input type="checkbox" (click)="setDataKey('', 'all', $event)">&ensp;全選</th>
                    <th>學年</th>
                    <th>學期</th>
                    <th>系所代碼</th>
                    <th>系所名稱</th>
                    <th>收費類別</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of tFSModuleDeptFeeTypeList">
                    <td><input type="checkbox" (click)="setDataKey(item.DataKey, 'one', $event)"
                            [checked]="item.Checked"></td>
                    <td>{{item.Years}}</td>
                    <td>{{item.Term}}</td>
                    <td>{{item.DeptNo}}</td>
                    <td>{{item.DeptName}}</td>
                    <td>{{item.AcadName}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>