import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/assets/environments/environment';
import { ReplaySubject } from 'rxjs';
import { auth } from 'src/app/_Models/auth';

@Injectable({
    providedIn: 'root'
})
export class GoogleSSOService {

    baseUrl = environment.apiUrl;
    ClientID: string | null = null;
    user!: auth;
    currentUserSource = new ReplaySubject<auth | null>(1);
    currentUser$ = this.currentUserSource.asObservable();

    constructor(private http: HttpClient) {
        //this.getGoogleCode();
    }

    //登入Google驗證[(Type = Login) Or (Type = Register)]
    GetAuthGoogleURL(Type: string) {
        this.http.get(this.baseUrl + "GoogleLogin/GetAuthGoogleURL/" + Type, { responseType: 'text' }).subscribe((URL) => {
            window.location.href = URL;
        });
    }

    //取得GoogleSSO ClientID
    getGoogleCode() {
        this.http.get(this.baseUrl + "GoogleLogin/Get_GoogleSSOAPI", { responseType: 'text' }).subscribe((response) => {
            this.ClientID = response;
        });
    }

    //Google登入
    GoogleLogin(GoogleCode: string) {
        return this.http.post<any>(this.baseUrl + "GoogleLogin/GoogleLogin", { GoogleCode: GoogleCode }).pipe(
            map((auth: auth) => {
                if (auth) {
                    this.setCurrentUser(auth);
                }
                return auth;
            }));
    }

    setCurrentUser(auth: auth) {
        sessionStorage.setItem('auth', JSON.stringify(auth));
        this.currentUserSource.next(auth);
    }

    autoLogin() {
        this.user = JSON.parse(sessionStorage.getItem('auth') || '{}');
        this.currentUserSource.next(this.user);
    }

}
