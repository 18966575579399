import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0101',
    templateUrl: './TCH0101.component.html',
    styleUrls: ['./TCH0101.component.css']
})
export class TCH0101Component implements OnInit {

    baseUrl = environment.apiUrl;
    data!: any;
    model = {
        Years: '',
        Term: ''
    }
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private router: Router,
        private systemService: SystemService,
        private timeService: TimeService, private translateService: TranslateService) { }


    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
            this.QuerySyllabus(this.model);
        });
    }

    QuerySyllabus(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_01/Search", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = undefined;
                return;
            }

            for (let i = 0; i < response.length; i++) {
                this.ChangeSendState(response[i]);
                this.ChangeDate(response[i]);
            }
            this.data = response;
        })
    }

    ChangeSendState(response: any) {
        if (response.Send_State == "1") {
            response.Send_State = "已送繳";
        }
        else {
            response.Send_State = "未送繳";
        }
    }

    ChangeDate(response: any) {
        if (response.End_Date == null) {
            response.End_Date = "未設定";
        }
    }

    WriteData(DataKey: string) {
        if (DataKey == null)
            return;
        this.router.navigate(['/TCH01_01', DataKey], { skipLocationChange: true });
    }

    duplicate() {
        this.toastr.info("已複製");
    }

}
