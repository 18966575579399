import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetASMDetail } from 'src/app/_Models/TCHWEB/TCH0111/getASMDetail';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0111-detail',
    templateUrl: './TCH0111-detail.component.html',
    styleUrls: ['./TCH0111-detail.component.css']
})
export class TCH0111DetailComponent implements OnInit {
    dataKey!: string;
    baseUrl = environment.apiUrl;
    data!: GetASMDetail;

    constructor(private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.dataKey = (this.route.snapshot.paramMap.get('id') || '');
        this.getDetailData();
    }

    getDetailData() {

        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_11/Search_Detail", { DataKey: this.dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response[0];
        })
    }

}
