import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { parseDate } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetSELTimeList } from 'src/app/_Models/AD/ADSEL/ADSEL0103/getSELTimeList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0103',
    templateUrl: './ADSEL0103.component.html',
    styleUrls: ['./ADSEL0103.component.css']
})
export class ADSEL0103Component implements OnInit {
    eduList!: GetEduList[];
    baseUrl = environment.apiUrl;
    bsConfig = environment.bsDatePickerConfig;
    Time!: Date;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    sELTimeList: GetSELTimeList[] = [];
    ngForm = new UntypedFormGroup({
        EduNo: new UntypedFormControl(''),
        Grade: new UntypedFormControl(''),
        SelStart: new UntypedFormControl(null, [Validators.required]),
        SelStartTime: new UntypedFormControl(null, [Validators.required]),
        SelEnd: new UntypedFormControl(null, [Validators.required]),
        SelEndTime: new UntypedFormControl(null, [Validators.required]),
        DesireStart: new UntypedFormControl(null, [Validators.required]),
        DesireStartTime: new UntypedFormControl(null, [Validators.required]),
        DesireEnd: new UntypedFormControl(null, [Validators.required]),
        DesireEndTime: new UntypedFormControl(null, [Validators.required]),
        SummerStart: new UntypedFormControl(null, [Validators.required]),
        SummerStartTime: new UntypedFormControl(null, [Validators.required]),
        SummerEnd: new UntypedFormControl(null, [Validators.required]),
        SummerEndTime: new UntypedFormControl(null, [Validators.required])
    })
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private localeService: BsLocaleService,) { }

    ngOnInit(): void {
        this.getEduList();
        this.getSELTimeList();
        this.localeService.use('zh-cn');
    }


    getEduList() {
        this.systemService.getEduList('0', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        });
    }

    getSELTimeList() {
        this.http.get<any>(this.baseUrl + "ACAD/SEL/ADSEL0103/Get_SEL_Time_List").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            };
            this.sELTimeList = response;

            this.sELTimeList.forEach(element => {
                element.SelStartTime = parseDate(element.SelStart);
                element.SelEndTime = parseDate(element.SelEnd);
                element.DesireStartTime = parseDate(element.DesireStart);
                element.DesireEndTime = parseDate(element.DesireEnd);
                element.SummerStartTime = parseDate(element.SummerStart);
                element.SummerEndTime = parseDate(element.SummerEnd);
            });



        });
    }

    getSELTimeSave() {

        if (!this.checkData()) {
            this.toastr.info("所有欄位必須填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0103/Get_SEL_Time_Save", this.sELTimeList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            };
            this.toastr.success("儲存成功");
            this.getSELTimeList();
        });
    }

    setTime() {
        if (!this.ngForm.valid) {
            this.toastr.info("欄位資料不正確");
            return;
        }

        let eduNo = this.ngForm.get("EduNo")?.value;
        let grade = this.ngForm.get("Grade")?.value;

        if (this.ngForm.get("EduNo")?.value != '' && this.ngForm.get("Grade")?.value != '') {
            this.sELTimeList.filter(n => n.EduNo == eduNo && n.Grade == grade).forEach(element => {
                element.SelEnd = this.ngForm.get("SelEnd")?.value;
                element.SelEndTime = this.ngForm.get("SelEndTime")?.value;
                element.SelStart = this.ngForm.get("SelStart")?.value;
                element.SelStartTime = this.ngForm.get("SelStartTime")?.value;
                element.SummerEnd = this.ngForm.get("SummerEnd")?.value;
                element.SummerEndTime = this.ngForm.get("SummerEndTime")?.value;
                element.SummerStart = this.ngForm.get("SummerStart")?.value;
                element.SummerStartTime = this.ngForm.get("SummerStartTime")?.value;
                element.DesireEnd = this.ngForm.get("DesireEnd")?.value;
                element.DesireEndTime = this.ngForm.get("DesireEndTime")?.value;
                element.DesireStart = this.ngForm.get("DesireStart")?.value;
                element.DesireStartTime = this.ngForm.get("DesireStartTime")?.value;
            });
        }
        else if (this.ngForm.get("EduNo")?.value != '') {
            this.sELTimeList.filter(n => n.EduNo == eduNo).forEach(element => {
                element.SelEnd = this.ngForm.get("SelEnd")?.value;
                element.SelEndTime = this.ngForm.get("SelEndTime")?.value;
                element.SelStart = this.ngForm.get("SelStart")?.value;
                element.SelStartTime = this.ngForm.get("SelStartTime")?.value;
                element.SummerEnd = this.ngForm.get("SummerEnd")?.value;
                element.SummerEndTime = this.ngForm.get("SummerEndTime")?.value;
                element.SummerStart = this.ngForm.get("SummerStart")?.value;
                element.SummerStartTime = this.ngForm.get("SummerStartTime")?.value;
                element.DesireEnd = this.ngForm.get("DesireEnd")?.value;
                element.DesireEndTime = this.ngForm.get("DesireEndTime")?.value;
                element.DesireStart = this.ngForm.get("DesireStart")?.value;
                element.DesireStartTime = this.ngForm.get("DesireStartTime")?.value;
            });
        }
        else if (this.ngForm.get("Grade")?.value != '') {
            this.sELTimeList.filter(n => n.Grade == grade).forEach(element => {
                element.SelEnd = this.ngForm.get("SelEnd")?.value;
                element.SelEndTime = this.ngForm.get("SelEndTime")?.value;
                element.SelStart = this.ngForm.get("SelStart")?.value;
                element.SelStartTime = this.ngForm.get("SelStartTime")?.value;
                element.SummerEnd = this.ngForm.get("SummerEnd")?.value;
                element.SummerEndTime = this.ngForm.get("SummerEndTime")?.value;
                element.SummerStart = this.ngForm.get("SummerStart")?.value;
                element.SummerStartTime = this.ngForm.get("SummerStartTime")?.value;
                element.DesireEnd = this.ngForm.get("DesireEnd")?.value;
                element.DesireEndTime = this.ngForm.get("DesireEndTime")?.value;
                element.DesireStart = this.ngForm.get("DesireStart")?.value;
                element.DesireStartTime = this.ngForm.get("DesireStartTime")?.value;
            });
        }
        else {
            this.sELTimeList.forEach(element => {
                element.SelEnd = this.ngForm.get("SelEnd")?.value;
                element.SelEndTime = this.ngForm.get("SelEndTime")?.value;
                element.SelStart = this.ngForm.get("SelStart")?.value;
                element.SelStartTime = this.ngForm.get("SelStartTime")?.value;
                element.SummerEnd = this.ngForm.get("SummerEnd")?.value;
                element.SummerEndTime = this.ngForm.get("SummerEndTime")?.value;
                element.SummerStart = this.ngForm.get("SummerStart")?.value;
                element.SummerStartTime = this.ngForm.get("SummerStartTime")?.value;
                element.DesireEnd = this.ngForm.get("DesireEnd")?.value;
                element.DesireEndTime = this.ngForm.get("DesireEndTime")?.value;
                element.DesireStart = this.ngForm.get("DesireStart")?.value;
                element.DesireStartTime = this.ngForm.get("DesireStartTime")?.value;
            });
        }
    }

    checkData(): boolean {
        let pass = true;

        let item = [];

        for (let index = 0; index < this.sELTimeList.length; index++) {
            if (!this.sELTimeList[index].SelStartTime || !this.sELTimeList[index].SelEndTime || !this.sELTimeList[index].DesireStartTime ||
                !this.sELTimeList[index].DesireEndTime || !this.sELTimeList[index].SummerStartTime || !this.sELTimeList[index].SummerEndTime) {
                item.push(index + 1);
                pass = false;
            }

        }

        if (!pass) {
            let str = '';
            item.forEach(element => {
                str += element + ',';
            })
            this.toastr.info(`第${str.substring(0, str.length - 1)}項時間未填寫或格式不對`);
            return pass;
        }

        // 時間重疊判斷

        for (let index = 0; index < this.sELTimeList.length; index++) {

            if ((this.sELTimeList[index].SummerStart < this.sELTimeList[index].SelStart && this.sELTimeList[index].SelStart < this.sELTimeList[index].SummerEnd) ||
                (this.sELTimeList[index].SummerEnd > this.sELTimeList[index].SelEnd && this.sELTimeList[index].SelEnd > this.sELTimeList[index].SummerStart) ||
                (this.sELTimeList[index].DesireStart < this.sELTimeList[index].SelStart && this.sELTimeList[index].SelStart < this.sELTimeList[index].DesireEnd) ||
                (this.sELTimeList[index].DesireEnd > this.sELTimeList[index].SelEnd && this.sELTimeList[index].SelEnd > this.sELTimeList[index].DesireStart) ||

                (this.sELTimeList[index].SummerStart < this.sELTimeList[index].DesireStart && this.sELTimeList[index].DesireStart < this.sELTimeList[index].SummerEnd) ||
                (this.sELTimeList[index].SummerEnd > this.sELTimeList[index].DesireEnd && this.sELTimeList[index].DesireEnd > this.sELTimeList[index].SummerStart) ||
                (this.sELTimeList[index].SelStart < this.sELTimeList[index].DesireStart && this.sELTimeList[index].DesireStart < this.sELTimeList[index].SelEnd) ||
                (this.sELTimeList[index].SelEnd > this.sELTimeList[index].DesireEnd && this.sELTimeList[index].DesireEnd > this.sELTimeList[index].SelStart) ||

                (this.sELTimeList[index].SelStart < this.sELTimeList[index].SummerStart && this.sELTimeList[index].SummerStart < this.sELTimeList[index].SelEnd) ||
                (this.sELTimeList[index].SelEnd > this.sELTimeList[index].SummerEnd && this.sELTimeList[index].SummerEnd > this.sELTimeList[index].SelStart) ||
                (this.sELTimeList[index].DesireStart < this.sELTimeList[index].SummerStart && this.sELTimeList[index].SummerStart < this.sELTimeList[index].DesireEnd) ||
                (this.sELTimeList[index].DesireEnd > this.sELTimeList[index].SummerEnd && this.sELTimeList[index].SummerEnd > this.sELTimeList[index].DesireStart)
            ) {
                item.push(index + 1);
                pass = false;
            }
        }

        if (!pass) {
            let str = '';
            item.forEach(element => {
                str += element + ',';
            })
            this.toastr.info(`第${str.substring(0, str.length - 1)}項時間重疊`);
            return pass;
        }

        this.sELTimeList.forEach(element => {
            parseDate(element.SelStart).setHours(element.SelStartTime.getHours(), element.SelStartTime.getMinutes(), element.SelStartTime.getSeconds());
            parseDate(element.SelEnd).setHours(element.SelEndTime.getHours(), element.SelEndTime.getMinutes(), element.SelEndTime.getSeconds());
            parseDate(element.DesireStart).setHours(element.DesireStartTime.getHours(), element.DesireStartTime.getMinutes(), element.DesireStartTime.getSeconds());
            parseDate(element.DesireEnd).setHours(element.DesireEndTime.getHours(), element.DesireEndTime.getMinutes(), element.DesireEndTime.getSeconds());
            parseDate(element.SummerStart).setHours(element.SummerStartTime.getHours(), element.SummerStartTime.getMinutes(), element.SummerStartTime.getSeconds());
            parseDate(element.SummerEnd).setHours(element.SummerEndTime.getHours(), element.SummerEndTime.getMinutes(), element.SummerEndTime.getSeconds());
        });

        return pass;
    }

}
