<main role="main" class="container" style="margin-bottom: 100px;">
    <div class="d-flex align-items-center rounded p-2">
        <img class="me-3" [src]="ImgUrl" alt="" width="60" height="60" id="userImg">
        <div class="lh-100">
            <h6 class="mb-0 text-black lh-100 fw-bolder"><span *ngIf="accountService.currentUser$ | async as user">{{user.UnitName}} - {{user.UserName}}</span></h6>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8">
            <div class="my-3 p-3 bg-white rounded shadow " id="course-Div" *ngIf="dailyCourse">
                <ng-container>
                    <h6 class="border-bottom border-primary pb-2 mb-0 fw-bolder text-center" style="color: #2655a5">
                        <span class="icon-My-schedule icon-text"></span> {{'今日課程' | translate}} ({{dailyCourse[0].Today}})
                    </h6>
                </ng-container>
                <carousel [isAnimated]="true" [showIndicators]="false" [interval]="0" [noPause]="true" class="carousel">
                    <ng-container *ngIf="dailyCourseSlides">
                        <slide *ngFor="let slide of dailyCourseSlides">
                            <div *ngFor="let item of slide" class="border border-gray mt-2 slide">
                                <div class="row m-0">
                                    <div class="col-9 p-0">
                                        <div class="row m-0">
                                            <div class="col p-0 text-center fw-bolder"> {{'第' | translate}}{{item.CurSection}}{{'節' | translate}}<br> {{item.CurTime01 }}-{{item.CurTime02 }} </div>
                                            <div class="col p-0 fw-bolder">
                                                <span class="me-1 icon-Location"></span>{{item.Data02}} <br>
                                                <span class="me-1"></span>{{item.Data01}}
                                            </div>
                                            <div class="col p-0 fw-bolder">
                                                <ng-container *ngIf="item.TeamMeetingJoinURL == '' else TeamMeetingJoinURL"> {{item.CosName }} </ng-container>
                                                <ng-template #TeamMeetingJoinURL> {{item.CosName }}<br>
                                                    <span (click)="openNewTab(item.TeamMeetingJoinURL)" class="icon-Distance-teaching icon-text"></span>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-4 p-0 text-center">
                                                <ng-container *ngIf="item.ABS_BA_Today != '0'">
                                                    <label style="font-weight: bolder; color: red;">{{'曠課' | translate}} </label>
                                                </ng-container>
                                            </div>
                                            <div class="col-8 p-0">
                                                <span class="badge bg-primary me-1"> {{'缺' | translate}}<span class="badge bg-light ms-1">{{item.ABS_BA }}</span>
                                                </span>
                                                <span class="badge bg-primary me-1"> {{'遲' | translate}}<span class="badge bg-light ms-1">{{item.ABS_BB}}</span>
                                                </span>
                                                <span class="badge bg-primary me-1"> {{'假' | translate}}<span class="badge bg-light ms-1">{{item.ABS_A }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 p-0 text-center fw-bolder">
                                        <a class="text-decoration-none" [routerLink]="item.FunItem01[0].URL"><span class="icon-Online-leave"></span></a>
                                        <br> {{item.FunItem01[0].FunName }}
                                    </div>
                                </div>
                            </div>
                        </slide>
                    </ng-container>
                </carousel>
            </div>
            <div class="my-3 p-3 bg-white rounded shadow" *ngIf="alertInfo">
                <ng-container>
                    <h6 class="border-bottom border-danger pb-2 mb-0 fw-bolder" style="color: #990000">
                        <span class="icon-Message-notification icon-text"></span> {{'通知訊息' | translate}}({{alertInfo.length}})
                    </h6>
                </ng-container>
                <div *ngFor="let item of alertInfo; let i = index" class="mt-1 slide">
                    <div class="row m-0">
                        <div class="col-1 text-center p-0 fw-bolder pt-4"> {{i+1}} </div>
                        <div class="col-11 p-0 pt-4 border-bottom"> {{item.Content}} <span class="fw-bolder text-primary">{{item.DeadLine}}</span>
                            <a [routerLink]="item.URL" class="text-decoration-none" style="float: right; margin-right: 30px;"><span class="icon-Right-arrow"></span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-3 p-3 bg-white rounded shadow">
                <h6 class="border-bottom border-danger pb-2 mb-0 fw-bolder" style="color: #990000">
                    <span class="icon-Message-notification icon-text"></span>{{'公佈欄' | translate}} ({{postList.length}})
                </h6>
                <ul class="list-group list-group-flush">
                    <label class="list-group-item list-group-item-action" *ngFor="let item of postList; let i = index">{{i+1}} {{item.Post_Subject}}</label>
                </ul>
                <h6 class="fw-bolder" style="color: #990000; text-align: right; cursor: pointer;" (click)="GoToBulletinBoard();"> {{'查看更多...' | translate}} </h6>
            </div>
        </div>
        <div class="col-lg-4" *ngIf="appLink">
            <div class="my-3 p-3 bg-white rounded shadow">
                <h6 *ngIf="appLink.AppLink.length > 0" class="border-bottom border-primary pb-2 mb-0 fw-bolder" style="color: #2655a5">
                    <span class="icon-Application icon-text"></span> {{'應用程式' | translate}}
                </h6>
                <ul *ngIf="appLink.AppLink.length > 0" class="list-group list-group-flush">
                    <a *ngFor="let item of appLink.AppLink" target="_blank" #URL (click)="posturl(item.URL);" class="list-group-item list-group-item-action">&#8226;&emsp;{{item.SiteName }}&emsp;<span class="icon-Right-arrow"></span></a>
                </ul>
                <h6 *ngIf="appLink.OtherAppLink.length > 0" class="border-bottom border-primary pb-2 mb-0 fw-bolder mt-3" style="color: #2655a5">
                    <span class="icon-Other-app icon-text"></span> {{'其他程式' | translate}}
                </h6>
                <ul *ngIf="appLink.OtherAppLink.length > 0" class="list-group list-group-flush">
                    <a *ngFor="let item of appLink.OtherAppLink" href="{{item.URL}}" target="_blank" class="list-group-item list-group-item-action">&#8226;&emsp;{{item.SiteName }}&emsp;<span class="icon-Right-arrow"></span></a>
                </ul>
            </div>
        </div>
    </div>
</main>
