<div class="modal-body TableHeight500">
    <div class="row">
        <div class="col-md-8">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">教師姓名/職編</span>
                </div>
                <input type="text" class="form-control shadow-none" #NameCode>
            </div>
        </div>
        <div class="col-md-4">
            <button class="search-btn" (click)="getEmployeeList(NameCode.value)">查詢</button>
        </div>
    </div>
    <div class="TableHeight420 TableOverflow">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th class="ColW60"></th>
                    <th>職稱</th>
                    <th>教師代碼</th>
                    <th>教師姓名</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of employeeList; let i = index" class="pointer"
                    (click)="triggerOutputEvent(item.Code, item.Name)">
                    <td class="ColW60">{{i + 1}}</td>
                    <td>{{item.Title_Name}}</td>
                    <td>{{item.Code}}</td>
                    <td>{{item.Name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>