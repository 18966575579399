<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">保留學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">保留學期</span>
                <select class="form-select shadow-none" name="Term" #Term>
                    <option value="" disabled >--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <button class="export-btn" (click)="exportReportBase64(Year.value, Term.value)">匯出</button>
        </div>
    </div>
</div>
