<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學年</span>
                    <select class="form-select shadow-none" [(ngModel)]="condition.Years">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <div class="input-group">
                    <span class="input-group-text">學期</span>
                    <select class="form-select shadow-none" [(ngModel)]="condition.Term">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <button type="button" class="export-btn" (click)="exportReport();">匯出</button>
        </div>
    </div>
</div>
