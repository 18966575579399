import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCUPCosNoList } from 'src/app/_Models/AD/ADCUP/ADCUP0499ISU01/getCUPCosNoList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUP0499ISU01',
    templateUrl: './ADCUP0499ISU01.component.html',
    styleUrls: ['./ADCUP0499ISU01.component.css']
})
export class ADCUP0499ISU01Component implements OnInit {
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    deptList!: GetSelectList[];
    cosnoList!:GetCUPCosNoList[];
    thisCosnoLis!:GetCUPCosNoList;
    CollTypeList!:GetSelectList[];
    GcTypeList!:GetSelectList[];
    SubjGrpList!:GetSelectList[];
    SubjKindList!:GetSelectList[];

    ngFormInput = new UntypedFormGroup({
        DeptNo: new UntypedFormControl(''),
        KeyWord: new UntypedFormControl('')
    });

    ngForm = new UntypedFormGroup({
        DataKey: new UntypedFormControl(''),
        CosNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        CosName: new UntypedFormControl(''),
        CosShort: new UntypedFormControl(''),
        CosEngName: new UntypedFormControl(''),
        EduCode: new UntypedFormControl(''),
        SubjKind: new UntypedFormControl(''),
        SubjGrp: new UntypedFormControl(''),
        GCType: new UntypedFormControl(''),
        CollType: new UntypedFormControl(''),
        ProgType: new UntypedFormControl(''),
        PraType: new UntypedFormControl(''),
        IPraType: new UntypedFormControl(''),
        UpdateUser: new UntypedFormControl(''),
        UpdateDate: new UntypedFormControl('')
    });

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private modalService: BsModalService){}

    ngOnInit(): void {
        this.getDeptList();
        this.getCollTypeList();
        this.getGcTypeList();
        this.getSubjGrpList();
        this.getSubjKindList();
    }

    getDeptList() {
        this.deptList=[];
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0499ISU01/Get_CUP_ISU01_DeptNo").subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        }));
    }

    getCollTypeList(){
        this.CollTypeList=[];
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0499ISU01/Get_CUP_ISU01_CollType").subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.CollTypeList = response;
        }));
    }

    getGcTypeList(){
        this.GcTypeList=[];
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0499ISU01/Get_CUP_ISU01_GcType").subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.GcTypeList = response;
        }));
    }

    getSubjGrpList(){
        this.SubjGrpList=[];
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0499ISU01/Get_CUP_ISU01_SubjGrp").subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.SubjGrpList = response;
        }));
    }

    getSubjKindList(){
        this.SubjKindList=[];
        this.http.get<any>(this.baseUrl + "ACAD/CUP/ADCUP0499ISU01/Get_CUP_ISU01_SubjKind").subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.SubjKindList = response;
        }));
    }

    getCUPCosNoList(){
        this.cosnoList=[];
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0499ISU01/Get_CUP_ISU01_List",this.ngFormInput.value).subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cosnoList = response;
        }));
    }

    getCUPCosNoDetail(thisItem:GetCUPCosNoList){
        this.thisCosnoLis=thisItem;
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0499ISU01/Get_CUP_ISU01_Detail", thisItem).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.ngForm.setValue(response);
        })
    }

    getCUPCosNoDetailSave(){
        if (!this.ngForm.valid) {
            this.toastr.info("請檢查欄位正確或填寫完畢")
            return;
        }

        if(this.ngForm.get("ProgType")?.value=="" || this.ngForm.get("ProgType")?.value==null){
            this.ngForm.get("ProgType")?.setValue(false);
        }

        if(this.ngForm.get("IPraType")?.value=="" || this.ngForm.get("IPraType")?.value==null){
            this.ngForm.get("IPraType")?.setValue(false);
        }

        if(this.ngForm.get("PraType")?.value=="" || this.ngForm.get("PraType")?.value==null){
            this.ngForm.get("PraType")?.setValue(false);
        }

        if(this.ngForm.get("CollType")?.value==null){
            this.ngForm.get("CollType")?.setValue("");
        }

        if(this.ngForm.get("EduCode")?.value==null){
            this.ngForm.get("EduCode")?.setValue("");
        }

        if(this.ngForm.get("GCType")?.value==null){
            this.ngForm.get("GCType")?.setValue("");
        }

        if(this.ngForm.get("SubjKind")?.value==null){
            this.ngForm.get("SubjKind")?.setValue("");
        }

        if(this.ngForm.get("SubjGrp")?.value==null){
            this.ngForm.get("SubjGrp")?.setValue("");
        }

        if(this.ngForm.get("DataKey")?.value==null){
            this.ngForm.get("DataKey")?.setValue("");
        }

        if(this.ngForm.get("UpdateUser")?.value==null){
            this.ngForm.get("UpdateUser")?.setValue("");
        }

        if(this.ngForm.get("UpdateDate")?.value==null){
            this.ngForm.get("UpdateDate")?.setValue("");
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0499ISU01/Get_CUP_ISU01_Save", this.ngForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            if(this.ngForm.get("DataKey")?.value==""){
                this.ngForm.reset();
            }
        })
    }

    getCUPCosNoDetailDelete(){
        if (this.ngForm.get('DataKey')?.value == '') {
            this.toastr.info("請選擇項目");
            return;
        }

        Swal.fire({
            title: "確定刪除？",
            showCancelButton: true,
            cancelButtonText: "取消",
            showConfirmButton: true,
            confirmButtonText: "確定",
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0499ISU01/Get_CUP_ISU01_Delete", this.thisCosnoLis).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.ngForm.reset();
                    this.getCUPCosNoList();
                })
            }
            else {
                this.toastr.info("動作已取消");
            }
        })
    }
}
