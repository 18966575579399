<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="exportReportADSRP0302()">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年學期(起)</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="YearsFrom">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                    <select class="form-select shadow-none" formControlName="YearsTo">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年學期(迄)</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="TermFrom">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                    <select class="form-select shadow-none" formControlName="TermTo">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級(起迄)</span>
                    </div>
                    <input formControlName="ClassFrom" [typeahead]="classListStart" [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="15" [isAnimated]="true" typeaheadOptionField="Class"
                        placeholder="輸入代碼查詢" class="form-control shadow-none">
                    <input formControlName="ClassTo" [typeahead]="classListEnd" [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="15" [isAnimated]="true" typeaheadOptionField="Class"
                        placeholder="輸入代碼查詢" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號(起迄)</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="StdNoFrom">
                    <input type="text" class="form-control shadow-none" formControlName="StdNoTo">
                </div>
            </div>
            <div class="col-md-12">
                <button class="export-btn mx-auto">匯出</button>
            </div>
        </div>
    </form>
</div>
