<div class="container-fluid">
    <app-year-term-input (childEvent)="query($event)"></app-year-term-input>
    <table class="table table-hover table-striped table-rwd text-center" id="table">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th></th>
                <th>{{'科目名稱'|translate}}</th>
                <th>{{'開課班級'|translate}}</th>
                <th>{{'成績計算模式'|translate}}</th>
                <th>{{'送繳期限'|translate}}</th>
                <th>{{'是否送繳'|translate}}</th>
                <th>{{'送繳日期'|translate}}</th>
                <th>{{'選課人數'|translate}}</th>
                <th>{{'待補登人數'|translate}}</th>
                <th>{{'已登入人數'|translate}}</th>
                <th style="display: none;"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of totalData; let rowIndex = index" id="{{rowIndex}}">
                <td data-th="操作">
                    <ng-container *ngIf="item.SendStatus === '否'">
                        <a class="pointer text-decoration-none" (click)="exportData(item.DataKey)"
                            id="{{rowIndex + 'd'}}"><span class="icon-Downlad-button"></span>{{'下載'|translate}}</a>
                        <a class="pointer text-decoration-none" (click)="importInputCkick(item.DataKey)"><span
                                class="icon-Import-button"></span>{{'匯入'|translate}}</a>
                        <input type="file" style="display: none;" id="{{item.DataKey}}"
                            (change)="importData($event, item.DataKey, 'score')" />
                        <a class="pointer text-decoration-none"
                            (click)="openModal(ScoreInputtemplate, 'ScoreData', item.DataKey)"><span
                                class="icon-Score-registration-and-import"></span>{{'登分'|translate}}</a>
                    </ng-container>
                    <ng-container *ngIf="item.SendStatus === '是'">
                        <a class="pointer text-decoration-none" (click)="exportData(item.DataKey)"><span
                                class="icon-Downlad-button"></span>{{'下載登分清冊'|translate}}</a>
                    </ng-container>
                </td>
                <td style="text-align: left;" data-th="科目名稱">{{item.Cos_ID}} | {{item.Cos_Name}}</td>
                <td style="text-align: left;" data-th="開課班級">{{item.Class_Short}}</td>
                <td data-th="成績計算模式">{{item.Grade_Mode}}
                    <ng-container *ngIf="item.SendStatus === '否'">
                        <a class="pointer text-decoration-none"
                            (click)="openModal(SetGradeModeTemplate, 'setGrade_Mode', item.DataKey)"><span
                                class="icon-Modify-button"></span>{{'設定'|translate}}</a>
                    </ng-container>
                </td>
                <td data-th="送繳期限">{{item.SendDateLine}}</td>
                <td data-th="是否送繳">{{item.SendStatus}}</td>
                <td data-th="送繳日期">{{item.SendDate}}</td>
                <td data-th="選課人數">{{item.Elect_Num}}</td>
                <td data-th="待補登人數">{{item.ReMarkCount}}</td>
                <td data-th="已登入人數">{{item.ScoreCount}}</td>
                <td data-th="" style="display: none;">{{item.DataKey}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #SetGradeModeTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'評分方式設定'|translate}}</h4>
        <button type="button" class="btn-close close btn-close-white shadow-none pull-right" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form #setGrade_ModeForm="ngForm" (ngSubmit)="CheckData('setGrade_Mode')">
            <p>{{'開課班級：'|translate}}{{modeData.Class_Name}}</p>
            <p>{{'開課序號：'|translate}}{{modeData.Serial}}</p>
            <p>{{'科目名稱：'|translate}}{{modeData.Cos_Name}}</p>
            <p>{{'評分方式：'|translate}}
                <select name="Grade_Mode" [(ngModel)]="modeData.Grade_Mode">
                    <option value="2" selected>{{'打總分'|translate}}</option>
                    <option value="1">{{'百分比'|translate}}</option>
                </select>
            </p>
            <ng-container *ngIf="modeData.Grade_Mode == '1'">
                <p>{{'期中：'|translate}}<input type="text" name="MTerm_Ratio" [(ngModel)]="modeData.MTerm_Ratio" style="width: 50px;" />
                </p>
                <p>{{'平時：'|translate}}<input type="text" name="Quiz_Ratio" [(ngModel)]="modeData.Quiz_Ratio" style="width: 50px;" /></p>
                <p>{{'期末：'|translate}}<input type="text" name="FTerm_Ratio" [(ngModel)]="modeData.FTerm_Ratio" style="width: 50px;" />
                </p>
            </ng-container>
            <button class="save-btn-gray float-start me-3" type="submit">{{'儲存'|translate}}</button>
            <button class="other-Button-blue float-start" type="button" (click)="modalRef.hide()">{{'取消'|translate}}</button>
        </form>
    </div>
</ng-template>

<ng-template #ScoreInputtemplate>
    <div class="modal-header" id="modal-table">
        <h4 class="modal-title pull-left">{{'成績登錄'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">
        </button>
    </div>    
    <div>
        <app-GrdScoreInput id="GrdScoreInput" *ngFor="let item of CurSelect" [DataKey]="item.DataKey" [IsTch]="item.IsTch"></app-GrdScoreInput>
    </div>
</ng-template>
