<div class="container" style="margin-bottom: 100px;">
    <app-year-term-input (childEvent)="QuerySyllabus($event)"></app-year-term-input>
    <table class="table table-hover table-striped table-rwd text-center">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'項次' | translate}}</th>
                <th>{{'開課班級' | translate}}</th>
                <th>{{'開課序號' | translate}}</th>
                <th>{{'科目名稱' | translate}}</th>
                <th>{{'送繳狀態' | translate}}</th>
                <th>{{'送繳時間' | translate}}</th>
                <th>{{'應繳時間' | translate}}</th>
                <th>{{'填寫' | translate}}</th>
                <th>{{'複製' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let syllabus of data;let i=index">
                <td data-th="項次">{{i+1}}</td>
                <td data-th="開課班級">{{syllabus.Class_Name}}</td>
                <td data-th="開課序號">{{syllabus.Serial}}</td>
                <td data-th="科目名稱">{{syllabus.Cos_Name}}</td>
                <td data-th="送繳狀態">{{syllabus.Send_State}}</td>
                <td data-th="送繳時間">{{syllabus.Send_Date}}</td>
                <td data-th="應繳時間">{{syllabus.End_Date}}</td>
                <td data-th="填寫教學大綱">
                    <ng-container *ngIf="syllabus.Send_State == '已送繳'; else not">
                        <a class="pointer text-decoration-none" (click)="WriteData(syllabus.DataKey)"><span
                                class="icon-View-button"></span>{{'查看' | translate}}</a>
                    </ng-container>
                    <ng-template #not>
                        <a class="pointer text-decoration-none" (click)="WriteData(syllabus.DataKey)"><span
                                class="icon-Fill-in-button"></span>
                <th>{{'填寫' | translate}}</th></a>
                </ng-template>
                </td>
                <td data-th="複製教學大綱"><a class="pointer text-decoration-none" (click)="duplicate()"
                        [hidden]="syllabus.Send_State == '已送繳'"><span class="icon-Copy-button"></span>{{'複製' |
                        translate}}</a></td>
                <td class="td-rwd-hide" data-th=""></td>
            </tr>
        </tbody>
    </table>
</div>