import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { environment } from 'src/assets/environments/environment';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { ResultInput,ResultOutput,ContrastUserType } from 'src/app/_Models/AD/ADSTD/ADSTD0601ISU01/getData';

@Component({
    selector: 'app-TCH0117',
    templateUrl: './TCH0117.component.html',
    styleUrls: ['./TCH0117.component.css']
})

export class TCH0117Component implements OnInit {
    baseUrl = environment.apiUrl;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    deptList!: GetDeptList[];
    teamList!: GetTeamList[];
    classList!: GetClassList[];
    initialData = new InitialData(this.http);
    stdList!: ResultOutput[];
    stdSelects!: ResultOutput[];
    stdSelect!: ResultOutput;
    UserType!:ContrastUserType;
    thisUserType:string="";

    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl({ value: ''}, Validators.required),
        Term: new UntypedFormControl({ value: ''}, Validators.required),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        TeamNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        StdNoFrom: new UntypedFormControl(''),
        StdNoTo: new UntypedFormControl(''),
        Status: new UntypedFormControl(''),
        DataKey: new UntypedFormControl('')
    });

    model = {
        Years: '',
        Term: ''
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Contrast_Years;
            this.model.Term = response.Contrast_Term;
        });
        this.getUserType();
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getClassList(edu: string, dept: string) {
        this.classList = [];
        this.systemService.getClassList(edu, dept, (this.UserType.UserType=='DeptMA')?'5':'3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))
    }

    getTeamList(dept: string) {
        this.teamList = [];
        this.systemService.getTeamList((this.UserType.UserType=='DeptMA')?'5':'3','','',dept,'').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.teamList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.deptList = [];
        this.systemService.getDeptList(edu, (this.UserType.UserType=='DeptMA')?'5':'3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getUserType(){
        this.http.get<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_17/Get_ADSTD0601_ISU01_UserKey").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.UserType = response;
            this.thisUserType=this.UserType.UserType;
            this.getDeptList('');
        })
    }

    getStdList(){
        this.stdList = [];
        this.ngFormInput.get("DataKey")?.setValue("");
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_17/Get_ADSTD0601_ISU01_StdList", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.stdList = response;
        })
    }

    getContrastResultData(thisStd: ResultOutput){
        this.stdSelect=thisStd;
        this.stdSelects=[];
        this.stdSelects.push(thisStd);
    }
}
