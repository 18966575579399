import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetCURTchMoneyHourRateList } from 'src/app/_Models/AD/ADCUR/ADCUR0504/getCURTchMoneyHourRateList';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUR0504',
    templateUrl: './ADCUR0504.component.html',
    styleUrls: ['./ADCUR0504.component.css']
})
export class ADCUR0504Component implements OnInit {
    baseUrl = environment.apiUrl;
    cURTchMoneyHourRateList!: GetCURTchMoneyHourRateList[];
    dataKey = '';
    ngForm = new UntypedFormGroup({
        DataKey: new UntypedFormControl(''),
        HourRate: new UntypedFormControl(1, [Validators.required]),
        MinNum: new UntypedFormControl(1, [Validators.required]),
        MaxNum: new UntypedFormControl(1, [Validators.required]),
        UpdateUser: new UntypedFormControl(''),
        UpdateTime: new UntypedFormControl(''),
        Selected: new UntypedFormControl(false)
    })
    constructor(private http: HttpClient, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.getCURTchMoneyHourRateList();
    }

    getCURTchMoneyHourRateList() {
        this.http.get<any>(this.baseUrl + "ACAD/CUR/ADCUR0504/Get_CUR_TchMoney_HourRate_List").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cURTchMoneyHourRateList = response;
            this.cURTchMoneyHourRateList.forEach(element => {
                element.Selected = false;
            });
        });
    }

    getDetail(index: number) {
        this.cURTchMoneyHourRateList.forEach(element => {
            element.Selected = false;
        });
        this.cURTchMoneyHourRateList[index].Selected = true;
        this.ngForm.setValue(this.cURTchMoneyHourRateList[index]);
        this.dataKey = this.cURTchMoneyHourRateList[index].DataKey;
    }

    getCURTchMoneyHourRateModify() {

        if (!this.ngForm.valid) {
            this.toastr.info("欄位有誤");
            return;
        }

        if (this.ngForm.get('MaxNum')?.value < this.ngForm.get('MinNum')?.value) {
            this.toastr.info("人數最大最小區間錯誤");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0504/Get_CUR_TchMoney_HourRate_Modify", this.ngForm.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.getCURTchMoneyHourRateList();
        });
    }

    getCURTchMoneyHourRateDelete() {

        if (!this.cURTchMoneyHourRateList) {
            this.toastr.info("請選擇資料");
            return;
        }

        if (this.dataKey == '') {
            this.toastr.info("請選擇資料");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUR/ADCUR0504/Get_CUR_TchMoney_HourRate_Delete", { DataKey: this.dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("刪除成功");
            this.dataKey = '';
            this.getCURTchMoneyHourRateList();
            this.clear();
        })
    }

    clear() {
        this.ngForm.reset();
    }

}
