<div class="container">
    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="clear()">清除</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="add-btn float-end me-3" (click)="insert()">新增</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>代碼</th>
                        <th>名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="searchDetail(item.Rest_No, i)">
                        <td>{{i + 1}}</td>
                        <td>{{item.Rest_No}}</td>
                        <td>{{item.Rest_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">原因代碼</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Rest_No">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">原因名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Rest_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">意願</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Rest_Kind">
                            <option value="">--請選擇--</option>
                            <option value="A">自願</option>
                            <option value="B">非自願</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">報表統計原因</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.EduReport_Code">
                            <option value="">--請選擇--</option>
                            <option *ngFor="let item of mOERestReasonList" value="{{item.ItemValue}}">
                                {{item.ItemName}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>