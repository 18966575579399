
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADSSS0207',
  templateUrl: './ADSSS0207.component.html',
  styleUrls: ['./ADSSS0207.component.css']
})
export class ADSSS0207Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}