<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學年</span>
                </div>
                <select class="form-select shadow-none" #Year
                    (change)="getTFSMakeDocWaitforList(Year.value, Term.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">學期</span>
                </div>
                <select class="form-select shadow-none" #Term
                    (change)="getTFSMakeDocWaitforList(Year.value, Term.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <table class="table table-borderless text-center" *ngIf="tFSMakeDocWaitforList">
        <thead>
            <tr>
                <th>製單類別</th>
                <th>繳費區間</th>
                <th>學生數</th>
                <th>總金額</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tFSMakeDocWaitforList; let i = index">
                <td>{{item.MakeDocName}}</td>
                <td style="width: 300px;"><input type="text" placeholder="點選設定繳費區間"
                        class="form-control pointer shadow-none" bsDaterangepicker [bsConfig]="bsConfig" readonly
                        [(ngModel)]="item.Date"></td>
                <td>{{item.Students}}位</td>
                <td>{{item.Amount}}</td>
                <td><button class="other-Button-gray float-start me-3" (click)="getTFSMakeDocBuild(i)">進行製單</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>