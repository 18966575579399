import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADCUR0601',
  templateUrl: './ADCUR0601.component.html',
  styleUrls: ['./ADCUR0601.component.css']
})
export class ADCUR0601Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}


