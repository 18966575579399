import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { SaveData } from'src/app/_Models/AD/ADSRP/ADSRP0101/saveData';

@Component({
    selector: 'app-ADSRP0101',
    templateUrl: './ADSRP0101.component.html',
    styleUrls: ['./ADSRP0101.component.css']
})
export class ADSRP0101Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    url=environment.apiUrl;
    data!:SaveData[];
    savedata :SaveData={
        S_Years: '',
        S_Terms: '',
        K_PC_EmpID: ''
    };
    initialData = new InitialData(this.http);
    ngOnInit(): void {
        this.getYears();
        this.search();
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService, private modalService: BsModalService) { }

    getYears() {
        this.initialData.getYears().subscribe((response: string[]) => {
            if (response) {
                this.Years = response;
            }
        }, (error: { error: string | undefined; }) => this.toastr.error(error.error));
    }

    search(){
        this.http.get<any>(this.url+ "ACAD/SRP/ADSRP0101/SRP0101_search").subscribe(response=>{
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
           this.data=response;
           this.data.forEach(m=>{
            this.savedata.S_Years=m.S_Years;
            this.savedata.S_Terms=m.S_Terms;
            this.savedata.K_PC_EmpID= m.K_PC_EmpID;
           })
        })
    }

    save(){
        if(this.savedata.K_PC_EmpID=='')
        {
            this.http.post<any>(this.url+ "ACAD/SRP/ADSRP0101/SRP0101_insert",this.savedata).subscribe(response=>{
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("新增成功");
                this.search();
            })
        }
        else{
            this.http.post<any>(this.url+ "ACAD/SRP/ADSRP0101/SRP0101_update",this.savedata).subscribe(response=>{
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("修改成功");
                this.search();
            })
        }
    }
}
