import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetCUPPermanentList } from 'src/app/_Models/AD/ADCUP/ADCUP0401/getCUPPermanentList';
import { GetCUPPermanentListInput } from 'src/app/_Models/AD/ADCUP/ADCUP0401/getCUPPermanentListInput';
import { GetCUPPreCourseCosList } from 'src/app/_Models/AD/ADCUP/ADCUP0402/getCUPPreCourseCosList';
import { GetCUPPreCourseDetail } from 'src/app/_Models/AD/ADCUP/ADCUP0402/getCUPPreCourseDetail';
import { GetCUPPreCourseList } from 'src/app/_Models/AD/ADCUP/ADCUP0402/getCUPPreCourseList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUP0402',
    templateUrl: './ADCUP0402.component.html',
    styleUrls: ['./ADCUP0402.component.css']
})
export class ADCUP0402Component implements OnInit {
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    group = require('src/app/_Models/_SelectInput/group.json').group;
    Years!: Array<string>;
    modalRef?: BsModalRef;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    detailDeptList!: GetDeptList[];
    selKindList!: GetSelectList[];
    cUPPreCourseList!: GetCUPPreCourseList[];
    cUPPreCourseCosList!: GetCUPPreCourseCosList[];
    cUPPermanentList!: GetCUPPermanentList[];
    cPPreCourseDetail = new GetCUPPreCourseDetail();
    cUPPermanentListClassInput = new GetCUPPermanentListInput();
    isPre = false;
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl(''),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        KeyWord: new UntypedFormControl(''),
    })
    ngForm = new UntypedFormGroup({
        DataKey: new UntypedFormControl(this.cPPreCourseDetail.DataKey),
        Years: new UntypedFormControl(this.cPPreCourseDetail.Years, [Validators.required]),
        CosID: new UntypedFormControl({ value: this.cPPreCourseDetail.CosID, disabled: true }, [Validators.required]),
        CosName: new UntypedFormControl({ value: this.cPPreCourseDetail.CosName, disabled: true }, [Validators.required]),
        CosTerm: new UntypedFormControl({ value: this.cPPreCourseDetail.CosTerm, disabled: true }, [Validators.required]),
        SelKindName: new UntypedFormControl({ value: this.cPPreCourseDetail.SelKindName, disabled: true }, [Validators.required]),
        Credits: new UntypedFormControl({ value: this.cPPreCourseDetail.Credits, disabled: true }, [Validators.required]),
        PreCosID: new UntypedFormControl({ value: this.cPPreCourseDetail.PreCosID, disabled: true }, [Validators.required]),
        PreCosName: new UntypedFormControl({ value: this.cPPreCourseDetail.PreCosName, disabled: true }, [Validators.required]),
        PreCosTerm: new UntypedFormControl({ value: this.cPPreCourseDetail.PreCosTerm, disabled: true }, [Validators.required]),
        PreSelKindName: new UntypedFormControl({ value: this.cPPreCourseDetail.PreSelKindName, disabled: true }, [Validators.required]),
        PreCredits: new UntypedFormControl({ value: this.cPPreCourseDetail.PreCredits, disabled: true }, [Validators.required]),
        CosCluster: new UntypedFormControl(this.cPPreCourseDetail.CosCluster),
        MustPassNum: new UntypedFormControl(this.cPPreCourseDetail.MustPassNum, [Validators.required]),
        PassScore: new UntypedFormControl(this.cPPreCourseDetail.PassScore, [Validators.required]),
        CanReplace: new UntypedFormControl(this.cPPreCourseDetail.CanReplace),
        UpdateUser: new UntypedFormControl({ value: this.cPPreCourseDetail.UpdateUser, disabled: true }),
        UpdateTime: new UntypedFormControl({ value: this.cPPreCourseDetail.UpdateTime, disabled: true }),
    })
    ngFormClassInput = new UntypedFormGroup({
        EduNo: new UntypedFormControl(this.cUPPermanentListClassInput.EduNo),
        DeptNo: new UntypedFormControl(this.cUPPermanentListClassInput.DeptNo),
        Status: new UntypedFormControl(this.cUPPermanentListClassInput.Status),
        SelKind: new UntypedFormControl(this.cUPPermanentListClassInput.SelKind),
        KeyWord: new UntypedFormControl(this.cUPPermanentListClassInput.KeyWord),
        OrderBy: new UntypedFormControl(this.cUPPermanentListClassInput.OrderBy),
        FiledsNo: new UntypedFormControl(this.cUPPermanentListClassInput.FiledsNo)
    });
    constructor(private http: HttpClient, private systemService: SystemService, private toastr: ToastrService, private modalService: BsModalService) { }

    ngOnInit(): void {
        this.getYears();
        this.getEduList();
        this.getSelKind();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList() {
        this.systemService.getEduList('0', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getSelKind() {
        this.systemService.getSelKind().subscribe((response) => {
            this.selKindList = response;
        })
    }

    getDeptList(edu: string, isDetail: boolean) {
        this.systemService.getDeptList(edu, '0', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            isDetail ? this.detailDeptList = response : this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getCUPPreCourseList() {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0402/Get_CUP_PreCourse_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.cUPPreCourseCosList = [];
                return;
            }
            this.cUPPreCourseCosList = [];
            this.clear();
            this.cUPPreCourseList = response;
            this.cUPPreCourseList.forEach(element => {
                element.Selected = false;
            })
        })
    }

    getCUPPreCourseCosList(datakey: string, index: number) {
        this.cUPPreCourseList.forEach(element => {
            element.Selected = false;
        })
        this.cUPPreCourseList[index].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0402/Get_CUP_PreCourse_CosList", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUPPreCourseCosList = response;
            this.cUPPreCourseCosList.forEach(element => {
                element.Selected = false;
            });

        })
    }

    getCUPPreCourseDetail(datakey: string, index: number) {
        this.cUPPreCourseCosList.forEach(element => {
            element.Selected = false;
        })
        this.cUPPreCourseCosList[index].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0402/Get_CUP_PreCourse_Detail", { DataKey: datakey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.ngForm.setValue(response);
            this.ngForm.get("Years")?.disable();
        })
    }


    getCUPPreCourseModify() {

        if (!this.ngForm.valid) {
            this.toastr.info("請將欄位填妥");
            return;
        }

        if (this.ngForm.get("CosCluster")?.value != '' && this.ngForm.get("MustPassNum")?.value == 0) {
            this.toastr.info("有選群組就必須設定應過科目數");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0402/Get_CUP_PreCourse_Modify", this.ngForm.getRawValue()).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }


    getCUPPreCourseDelete() {
        if (this.ngForm.get('DataKey')?.value == '') {
            this.toastr.info("請選擇刪除項目");
            return;
        }
        Swal.fire({
            title: "確定要刪除？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0402/Get_CUP_PreCourse_Delete", { DataKey: this.ngForm.get('DataKey')?.value }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("已刪除");
                    this.getCUPPreCourseList();
                })
            }
            else {
                this.toastr.info("動作已取消");
            }
        })
    }

    clear() {
        this.ngForm.reset();
        this.ngForm.get("Years")?.enable();
    }

    openModal(template: TemplateRef<any>, isPre: boolean) {

        isPre ? this.isPre = false : this.isPre = true;

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    getCUPPermanentList() {
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0401/Get_CUP_Permanent_List", this.ngFormClassInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.cUPPermanentList = response;
        })
    }

    setClass(CosID: string, CosName: string, CosTerm: string, SelKindName: string, Credits: string) {
        this.modalRef?.hide();
        this.cUPPermanentList = [];
        if (this.isPre) {
            this.ngForm.get('CosID')?.setValue(CosID);
            this.ngForm.get('CosName')?.setValue(CosName);
            this.ngForm.get('CosTerm')?.setValue(CosTerm);
            this.ngForm.get('SelKindName')?.setValue(SelKindName);
            this.ngForm.get('Credits')?.setValue(Credits);
        }
        else {
            this.ngForm.get('PreCosID')?.setValue(CosID);
            this.ngForm.get('PreCosName')?.setValue(CosName);
            this.ngForm.get('PreCosTerm')?.setValue(CosTerm);
            this.ngForm.get('PreSelKindName')?.setValue(SelKindName);
            this.ngForm.get('PreCredits')?.setValue(Credits);
        }
    }

}
