<div class="container">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
    <div class="row">
        <div class="col">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'階段' | translate}}</span>
                </div>
                <select class="form-select shadow-none" [(ngModel)]="model.Desire">
                    <option value="" disabled selected>{{'--請選擇--' | translate}}
                    </option>
                    <option value="Y">{{'第一階段' | translate}}</option>
                    <option value="N">{{'第二階段' | translate}}</option>
                </select>
            </div>
        </div>
        <div class="col">
            <button class="search-btn mb-3" (click)="getSelLog()">{{'查詢' | translate}}</button>
        </div>
    </div>


    <table class="table table-hover table-striped text-center table-bordered">
        <thead class="thead-dark-red">
            <tr>
                <th>{{'開課班級' | translate}}</th>
                <th>{{'課程名稱' | translate}}</th>
                <th>{{'序號' | translate}}</th>
                <th>{{'選修別' | translate}}</th>
                <th>{{'學分' | translate}}</th>
                <th>{{'類別' | translate}}</th>
                <th>{{'時間' | translate}}</th>
                <th>{{'處理人員' | translate}}</th>
                <th>IP</th>
            </tr>
        </thead>
        <tbody style="height: 500px; overflow-y: scroll;">
            <tr *ngFor="let item of data">
                <td>{{item.Class_Short}}</td>
                <td>{{item.Cos_Name}}</td>
                <td>{{item.Serial}}</td>
                <td>{{item.SelKind_Name}}</td>
                <td>{{item.Cos_Credit}}</td>
                <td>{{item.Insert_Type}}</td>
                <td>{{item.Insert_Data}}</td>
                <td>{{item.Update_User}}</td>
                <td>{{item.Update_IP}}</td>
            </tr>
        </tbody>
    </table>
</div>