<div class="container-fluid">
    <div class="card mb-2">
        <div class="card-header fw-bolder" style="font-size: 24px;"><span class="icon-Course-selection"></span>{{'選課系統'
            | translate}}</div>
        <div class="card-body">
            <div class="d-inline-flex mb-3" *ngIf="infoData">
                <small class="text-muted me-3">{{infoData.Class_Name}} - {{infoData.Std_No}}
                    {{infoData.Std_Name}}</small>
                <small class="text-muted me-3">{{'學分上下限：' | translate}}{{infoData.Credit_Min}} -
                    {{infoData.Credit_Max}}</small>
                <small class="text-muted">{{'總學分/已選學分：' |
                    translate}}{{infoData.Credit_SelAll}}/{{infoData.Credit_Sel}}</small>
            </div>
            <div class="row" *ngIf="getSelQueryInput">
                <div class="col-md-2">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{'系所' | translate}}</span>
                        </div>
                        <select class="form-select shadow-none" [(ngModel)]="getSelQueryInput.Dept_No">
                            <option value="" selected disabled>{{'--請選擇系所--' | translate}}</option>
                            <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{'年級' | translate}}</span>
                        </div>
                        <select class="form-select shadow-none" [(ngModel)]="getSelQueryInput.Grade">
                            <option value="" selected disabled>{{'--請選擇年級--' | translate}}</option>
                            <option value="1">{{'一年級' | translate}}</option>
                            <option value="2">{{'二年級' | translate}}</option>
                            <option value="3">{{'三年級' | translate}}</option>
                            <option value="4">{{'四年級' | translate}}</option>
                            <option value="5">{{'五年級' | translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{'科目代碼' | translate}}</span>
                        </div>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="getSelQueryInput.Cos_ID">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{'科目名稱' | translate}}</span>
                        </div>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="getSelQueryInput.Cos_Name">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{'授課教師' | translate}}</span>
                        </div>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="getSelQueryInput.Tch_Name">
                    </div>
                </div>
                <div class="col-md-2">
                    <button class="search-btn" style="width: 130px;" (click)="getSelQuery()">{{'查課程' |
                        translate}}</button>
                </div>
            </div>

        </div>
    </div>
    <small class="fw-bolder text-danger">{{'*點擊標題可縮放' | translate}}</small>
    <div class="row sel-row">
        <div class="col-md-4">
            <div class="card">
                <div class="card-header header" role="button" (click)="scheduleIsCollapsed = !scheduleIsCollapsed"
                    [attr.aria-expanded]="!scheduleIsCollapsed" aria-controls="collapseBasic1">{{'目前課表' | translate}}
                </div>
                <div class="card-body" id="collapseBasic1" [collapse]="scheduleIsCollapsed">
                    <span class="badge badge-info me-1 mb-1">{{'加選課程' | translate}}</span><span
                        class="badge badge-success mb-1 me-1">{{'配課課程' | translate}}</span><span
                        class="badge badge-danger mb-1">{{'預選課程' | translate}}</span>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{{'一' | translate}}</th>
                                <th>{{'二' | translate}}</th>
                                <th>{{'三' | translate}}</th>
                                <th>{{'四' | translate}}</th>
                                <th>{{'五' | translate}}</th>
                                <th>{{'六' | translate}}</th>
                                <th>{{'日' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of SelMySelFormData; let i = index">
                                <td>{{i+1}}</td>
                                <ng-container *ngIf="item.Week01 else nothing" [ngSwitch]="item.Week01[0].Mark">
                                    <td (click)="getSelInfo(item.Week01)" *ngSwitchCase="'1'"
                                        class="schedule-td bg-success text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week01.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week01)" *ngSwitchCase="'2'"
                                        class="schedule-td bg-danger text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week01.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week01)" *ngSwitchCase="'3'"
                                        class="schedule-td bg-info text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week01.length}}
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="item.Week02 else nothing" [ngSwitch]="item.Week02[0].Mark">
                                    <td (click)="getSelInfo(item.Week02)" *ngSwitchCase="'1'"
                                        class="schedule-td bg-success text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week02.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week02)" *ngSwitchCase="'2'"
                                        class="schedule-td bg-danger text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week02.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week02)" *ngSwitchCase="'3'"
                                        class="schedule-td bg-info text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week02.length}}
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="item.Week03 else nothing" [ngSwitch]="item.Week03[0].Mark">
                                    <td (click)="getSelInfo(item.Week03)" *ngSwitchCase="'1'"
                                        class="schedule-td bg-success text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week03.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week03)" *ngSwitchCase="'2'"
                                        class="schedule-td bg-danger text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week03.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week03)" *ngSwitchCase="'3'"
                                        class="schedule-td bg-info text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week03.length}}
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="item.Week04 else nothing" [ngSwitch]="item.Week04[0].Mark">
                                    <td (click)="getSelInfo(item.Week04)" *ngSwitchCase="'1'"
                                        class="schedule-td bg-success text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week04.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week04)" *ngSwitchCase="'2'"
                                        class="schedule-td bg-danger text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week04.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week04)" *ngSwitchCase="'3'"
                                        class="schedule-td bg-info text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week04.length}}
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="item.Week05 else nothing" [ngSwitch]="item.Week05[0].Mark">
                                    <td (click)="getSelInfo(item.Week05)" *ngSwitchCase="'1'"
                                        class="schedule-td bg-success text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week05.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week05)" *ngSwitchCase="'2'"
                                        class="schedule-td bg-danger text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week05.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week05)" *ngSwitchCase="'3'"
                                        class="schedule-td bg-info text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week05.length}}
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="item.Week06 else nothing" [ngSwitch]="item.Week06[0].Mark">
                                    <td (click)="getSelInfo(item.Week06)" *ngSwitchCase="'1'"
                                        class="schedule-td bg-success text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week06.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week06)" *ngSwitchCase="'2'"
                                        class="schedule-td bg-danger text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week06.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week06)" *ngSwitchCase="'3'"
                                        class="schedule-td bg-info text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week06.length}}
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="item.Week07 else nothing" [ngSwitch]="item.Week07[0].Mark">
                                    <td (click)="getSelInfo(item.Week07)" *ngSwitchCase="'1'"
                                        class="schedule-td bg-success text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week07.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week07)" *ngSwitchCase="'2'"
                                        class="schedule-td bg-danger text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week07.length}}
                                    </td>
                                    <td (click)="getSelInfo(item.Week07)" *ngSwitchCase="'3'"
                                        class="schedule-td bg-info text-white pointer" [popover]="popTemplate"
                                        popoverTitle="課程資訊" [outsideClick]="true" container="body">
                                        {{item.Week07.length}}
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header header" role="button" (click)="cosIsCollapsed = !cosIsCollapsed"
                    [attr.aria-expanded]="!cosIsCollapsed" aria-controls="collapseBasic2">{{'課程資訊' | translate}}</div>
                <div class="card-body" id="collapseBasic2" [collapse]="cosIsCollapsed">
                    <div><small class="text-danger fw-bolder">{{'*可捲動畫面查看' | translate}}</small></div>
                    <div class="sel-col" *ngIf="getSelQueryData">
                        <div class="card mb-2" *ngFor="let item of getSelQueryData">
                            <div class="card-body cos-card-body">
                                <div style="position: relative;">
                                    {{item.Cos_ID }}&ensp;
                                    <span style="font-size: 24px;" class="fw-bolder">{{item.Cos_Name }}</span>
                                    <a class="badge text-primary float-end pointer text-decoration-none"
                                        style="font-size: 20px;" (click)="getSelAdd(item.DataKey)"><span
                                            class="icon-Plus-button"></span>{{'加選' | translate}}</a>
                                </div>
                                <div>{{item.Tch_Name}}&ensp;{{item.Cur_Time }}</div>
                                <small class="text-muted">{{'開課班級：' |
                                    translate}}{{getSelQueryData[0].Class_Name}}</small><br>
                                <small class="text-muted">{{item.SelKind_Name}}&ensp;{{'學分：' |
                                    translate}}{{item.Cos_Credit}}&ensp;{{'人數：' |
                                    translate}}{{item.Sel_Counts}}/{{item.Sel_Max}}</small><br>
                                <small class="text-muted">{{'備註:' | translate}}{{item.Cos_Memo }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div *ngIf="infoData" class="card-header header" role="button"
                    (click)="selIsCollapsed = !selIsCollapsed" [attr.aria-expanded]="!selIsCollapsed"
                    aria-controls="collapseBasic3">
                    {{' 我的課程(上限-已選-下限：' |
                    translate}}{{infoData.Credit_Max}}-{{infoData.Credit_Sel}}-{{infoData.Credit_Min}})
                </div>
                <div class="card-body" id="collapseBasic3" [collapse]="selIsCollapsed">
                    <div><small class="text-danger fw-bolder">{{'*可捲動畫面查看' | translate}}</small></div>
                    <div class="sel-col">
                        <div class="card mb-2" *ngFor="let item of selChooseData">
                            <div class="card-body cos-card-body">
                                <div style="position: relative;">
                                    {{item.Cos_ID}}&ensp;
                                    <span style="font-size: 24px;" class="fw-bolder">{{item.Cos_Name}}</span>
                                    <a class="badge text-danger pointer float-end text-decoration-none"
                                        style="font-size: 20px;" (click)="getSelDel(item.DataKey)"><span
                                            class="icon-Minus-button"></span>{{'退選' | translate}}</a>
                                </div>
                                <div>{{item.Tch_Name}}&ensp;{{item.Cur_Time}}
                                </div>
                                <small class="text-muted">{{'開課班級：' | translate}}{{item.Class_Name}}</small><br>
                                <small class="text-muted">{{item.SelKind_Name}}&ensp;{{'學分：' |
                                    translate}}{{item.Cos_Credit}}&ensp;{{'人數：' |
                                    translate}}{{item.Sel_Counts}}/{{item.Sel_Max}}</small><br>
                                <small class="text-muted"><span class="text-danger fw-bolder">{{'備註：' |
                                        translate}}</span>{{item.Cos_Memo}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #nothing>
    <td></td>
</ng-template>
<ng-template #popTemplate>
    <p *ngFor="let item of cosDetail">{{item.CosName}}<span class="badge text-danger ms-1 pointer"
            (click)="getSelDel(item.DataKey)"><span class="icon-Minus-button"></span>{{'退選' | translate}}</span></p>
</ng-template>