<div class="container" style="margin-bottom: 100px;">
    <h2 class="text-info fw-bolder">{{'自主修課計畫維護​' | translate}}</h2>
    <ul class="list-group list-group-horizontal text-center mb-3">
        <li class="list-group-item list-group-item-action list-group-item-secondary">{{'尚未修課​' | translate}}</li>
        <li class="list-group-item list-group-item-action list-group-item-danger">{{'學分未過​' | translate}}</li>
        <li class="list-group-item list-group-item-action list-group-item-warning">{{'正在修課' | translate}}</li>
        <li class="list-group-item list-group-item-action list-group-item-success">{{'學分已過' | translate}}</li>
    </ul>

    <div style="height: 2em;" class="mb-3">
        <div class="progress" [hidden]="hidden">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 25%"
                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                {{'必修10學分，選修12學分 / 必修已修2學分，選修已修7學分' | translate}}
                ​
            </div>
        </div>
    </div>
    <tabset #staticTabs>
        <tab heading="{{'全部課程' | translate}}">
            <div class="row">
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'一上​​' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action list-group-item-success icon-container">{{'本系通識'
                            | translate}}​<br>{{'大一體育(必/0)​' | translate}}</a>
                        <a class="list-group-item list-group-item-action list-group-item-danger icon-container">{{'本系通識'
                            | translate}}​<br>{{'大一英文(必/3)​' | translate}}​​</a>
                        <a class="list-group-item list-group-item-action list-group-item-success icon-container">{{'本系專業'
                            | translate}}​<br>{{'經濟學一(必/3)​' | translate}}</a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'一下' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action list-group-item-success icon-container">{{'本系專業'
                            | translate}}​<br>{{'經濟學二(必/3)​' | translate}}​</a>
                        <a class="list-group-item list-group-item-action list-group-item-success icon-container">{{'本系專業'
                            | translate}}​​<br>{{'經濟學二(必/3)​' | translate}}​​​</a>
                        <a class="list-group-item list-group-item-action list-group-item-success icon-container">{{'本系專業'
                            | translate}}​<br>{{'經濟學二(必/3)​' | translate}}​</a>
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'自主課程​'
                            | translate}}​<br>{{'經濟學二(必/3)​' | translate}}​​<span
                                class="icon-Clear-button pointer"></span></a>
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'自主課程​'
                            | translate}}​<br>{{'經濟學二(必/3)​' | translate}}<span
                                class="icon-Clear-button pointer"></span></a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'二上' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'本系專業'
                            | translate}}<br>​{{'會計學一(必/3)​​'
                            | translate}}</a>
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'自主課程​'
                            | translate}}<br>​​{{'經濟學二(必/3)​'
                            | translate}}​​<span class="icon-Clear-button pointer"></span></a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'二下' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'本系專業'
                            | translate}}<br>{{'會計學二(必/3)​​' | translate}}​​</a>
                        <a class="list-group-item list-group-item-action list-group-item-success icon-container">{{'自主課程​'
                            | translate}}<br>​​{{'經濟學二(必/3)​'
                            | translate}}</a>
                        <a class="list-group-item list-group-item-action list-group-item-warning icon-container">{{'自主課程​'
                            | translate}}<br>{{'經濟學二(必/3)​' | translate}}</a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'三上' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'自主課程​'
                            | translate}}<br>{{'經濟學二(必/3)​' | translate}}<span
                                class="icon-Clear-button pointer"></span><span class="icon-Clock pointer"></span></a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'三下' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'四上' | translate}}
                            <span class="icon-Add pointer" (click)="openModal(template)"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a [hidden]="hidden1"
                            class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'自主課程​'
                            | translate}}<br>{{'大二體育二(必/3)​​' | translate}}<span class="icon-Clear-button pointer"
                                (click)="hide1()"></span><span class="icon-Clock pointer"></span></a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'四下' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                </div>
            </div>
        </tab>
        <tab heading="{{'本系課程' | translate}}"></tab>
        <tab heading="{{'旅館系​休閒旅館專業人才' | translate}}​" (selectTab)="hide()" (deselect)="hide()">
            <div class="row">
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'一上​​' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'一下​​' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'自主課程​'
                            | translate}}​<br>{{'經濟學二(必/3)​' | translate}}<span class="icon-Clear-button"></span></a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'二上​​' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'自主課程​'
                            | translate}}<br>{{'經濟學二(必/3)​' | translate}}<span class="icon-Clear-button"></span></a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'二下​​' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'本系專業'
                            | translate}}<br>​{{'會計學二(必/3)​​'
                            | translate}}​​</a>
                        <a class="list-group-item list-group-item-action list-group-item-warning icon-container">{{'自主課程​'
                            | translate}}<br>{{'經濟學二(必/3)​' | translate}}</a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'三上​​' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action list-group-item-secondary icon-container">{{'自主課程​'
                            | translate}}<br>{{'經濟學二(必/3)​' | translate}}<span class="icon-Clear-button"></span><span
                                class="icon-Clock"></span></a>
                    </div>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'三下​​' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'四上​​' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                </div>
                <div class="col-md text-center">
                    <h2>
                        <span class="badge bg-secondary">
                            {{'四上​​' | translate}}
                            <span class="icon-Add"></span>
                        </span>
                    </h2>
                </div>
            </div>
        </tab>
        <tab heading="{{'旅運系​旅遊經紀管理人才' | translate}}"></tab>
        <tab heading="{{'會展行銷系​旅遊經紀管理人才' | translate}}"></tab>
        <tab heading="{{'興趣選修' | translate}}"></tab>
    </tabset>
</div>

<ng-template #nothing>
    <a class="list-group-item list-group-item-action list-group-item-light">{{'尚未有資料' | translate}}</a>
</ng-template>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'新增自主課程' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'系所' | translate}}</span>
                    </div>
                    <select #Dept class="form-select shadow-none">
                        <option value="" selected disabled>{{'--請選擇--' | translate}}</option>
                        <option value="{{item.Dept_No}}" *ngFor="let item of deptList">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'年級' | translate}}</span>
                    </div>
                    <select class='form-select'>
                        <option value="" disabled>{{'--請選擇--' | translate}}</option>
                        <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'課程代碼' | translate}}</span>
                    </div>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'課程名稱' | translate}}</span>
                    </div>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'授課教師' | translate}}</span>
                    </div>
                    <input type="text" class="form-control shadow-none">
                </div>
            </div>
            <div class="col-md-4">
                <button class="search-btn">{{'查詢' | translate}}</button>
            </div>
        </div>
        <hr>
        <div style="height: 500px; overflow-y: scroll;">
            <div class="card mb-2">
                <div class="card-body cos-card-body">
                    <div style="position: relative;">
                        AG106100&ensp;
                        <span style="font-size: 24px;" class="fw-bolder">{{'大二體育二' | translate}}</span>
                        <a (click)="hide1()" class="float-end pointer text-decoration-none fw-bolder"
                            style="position: absolute; right: 10px; top: 5px; font-size: 20px;"><span
                                class="icon-Plus-button pointer"></span>{{'加選' | translate}}
                        </a>
                    </div>
                    <div>{{'簡X芸' | translate}}&ensp;{{'三(10,11)' | translate}}&ensp;{{'#G403韻律教室' | translate}}</div>
                    <small class="text-muted">{{'開課班級：大二體育' | translate}}</small><br>
                    <small class="text-muted">{{'必修' | translate}}&ensp;{{'學分：3.0' | translate}}&ensp;{{'人數：15/30' |
                        translate}}</small><br>
                    <small class="text-muted"><span class="text-danger fw-bolder">{{'備註：' | translate}}</span>{{'有氧舞蹈' |
                        translate}}</small>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body cos-card-body">
                    <div style="position: relative;">
                        AG106100&ensp;
                        <span style="font-size: 24px;" class="fw-bolder">{{'大二體育二' | translate}}</span>
                        <a class="float-end pointer text-decoration-none fw-bolder"
                            style="position: absolute; right: 10px; top: 5px; font-size: 20px;"><span
                                class="icon-Plus-button"></span>{{'加選' | translate}}
                        </a>
                    </div>
                    <div>{{'簡X芸' | translate}}&ensp;{{'三(10,11)' | translate}}&ensp;{{'#G403韻律教室' | translate}}</div>
                    <small class="text-muted">{{'開課班級：大二體育' | translate}}</small><br>
                    <small class="text-muted">{{'必修' | translate}}&ensp;{{'學分：3.0' | translate}}&ensp;{{'人數：15/30' |
                        translate}}</small><br>
                    <small class="text-muted"><span class="text-danger fw-bolder">{{'備註：' | translate}}</span>{{'有氧舞蹈' |
                        translate}}</small>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body cos-card-body">
                    <div style="position: relative;">
                        AG106100&ensp;
                        <span style="font-size: 24px;" class="fw-bolder">{{'大二體育二' | translate}}</span>
                        <a class="float-end pointer text-decoration-none fw-bolder"
                            style="position: absolute; right: 10px; top: 5px; font-size: 20px;"><span
                                class="icon-Plus-button"></span>{{'加選' | translate}}
                        </a>
                    </div>
                    <div>{{'簡X芸' | translate}}&ensp;{{'三(10,11)' | translate}}&ensp;{{'#G403韻律教室' | translate}}</div>
                    <small class="text-muted">{{'開課班級：大二體育' | translate}}</small><br>
                    <small class="text-muted">{{'必修' | translate}}&ensp; {{'學分：3.0' | translate}}&ensp;人{{'人數：15/30' |
                        translate}}</small><br>
                    <small class="text-muted"><span class="text-danger fw-bolder">{{'備註：' | translate}}</span>{{'有氧舞蹈' |
                        translate}}</small>
                </div>
            </div>
        </div>

    </div>
</ng-template>