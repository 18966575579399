import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    constructor() { }

    useFavorite: boolean = false;

    ngOnInit(): void {
        this.CheckPurviewFavorite();
    }

    //檢查我的最愛權限
    CheckPurviewFavorite() {

        if (sessionStorage.getItem('auth') != null) {
            // console.log(JSON.parse(sessionStorage.getItem('auth') || '').UseFavorite);
            if (JSON.parse(sessionStorage.getItem('auth') || '').UseFavorite) {
                this.useFavorite = true;
            }
        }
    }

}
