import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetSELSelChooseOpClassAddStdList } from 'src/app/_Models/AD/ADSEL/ADSEL0303/getSELSelChooseOpClassAddStdList';
import { GetSELSelChooseOpClassSelList } from 'src/app/_Models/AD/ADSEL/ADSEL0303/getSELSelChooseOpClassSelList';
import { GetSELSelChooseStdAddCosList, GetSELSelChooseStdReVokeList} from 'src/app/_Models/AD/ADSEL/ADSEL0303/getSELSelChooseStdAddCosList';
import { GetSELSelChooseStdSelList } from 'src/app/_Models/AD/ADSEL/ADSEL0303/getSELSelChooseStdSelList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-adsel0303',
    templateUrl: './adsel0303.component.html',
    styleUrls: ['./adsel0303.component.css']
})
export class ADSEL0303Component implements OnInit {
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    classList!: GetClassList[];
    classListAdd!: GetClassList[];
    dataKey = '';
    rowIndex=0;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        Type: new UntypedFormControl('1', [Validators.required]),
        Class: new UntypedFormControl(''),
        StdNo: new UntypedFormControl(''),
        CheckRegi: new UntypedFormControl(true),
    });
    sELSelChooseList!: any;
    sELSelChooseOpClassSelList!: GetSELSelChooseOpClassSelList[];
    sELSelChooseStdSelList!: GetSELSelChooseStdSelList[];
    sELSelChooseOpClassAddStdList!: GetSELSelChooseOpClassAddStdList[];
    sELSelChooseStdAddCosList!: GetSELSelChooseStdAddCosList[];
    sELSelChooseStdReVokeList!: GetSELSelChooseStdReVokeList[];

    constructor(private toastr: ToastrService, private http: HttpClient, private systemService: SystemService) { }

    ngOnInit(): void {
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getClassList(Years: string, Term: string,Stype: string) {

        this.sELSelChooseList = undefined;

        if (Years == '' || Term == '') return;

        if(Stype==''){
            Stype=this.ngFormInput.get('Type')?.value;
        }

        let type = Stype == '1' ? '1' : '0';

        let typeAdd = Stype == '1' ? '' : '2';

        console.log('type='+type+' ,typeAdd='+typeAdd);

        this.systemService.getClassList('', '', type, Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classList = response;
        });

        this.systemService.getClassList('', '', typeAdd, Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classListAdd = response;
        });
    }

    setCheck(event: EventTarget | null,thisItem:any) {
        let input = <HTMLInputElement>event;
        thisItem.checked = input.checked;
    }

    getSELSelChooseList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
        }

        if ((this.ngFormInput.get('Type')?.value == '1' || this.ngFormInput.get('Type')?.value == '2') && this.ngFormInput.get('Class')?.value == '') {
            this.toastr.info("請選擇班級");
            return;
        }

        if (this.ngFormInput.get('Type')?.value == '3' && this.ngFormInput.get('StdNo')?.value == '') {
            this.toastr.info("請輸入學號");
            return;
        }

        this.sELSelChooseList=[];
        this.sELSelChooseStdSelList = [];
        this.sELSelChooseOpClassSelList = [];
        this.sELSelChooseStdAddCosList = [];
        this.sELSelChooseOpClassAddStdList=[];

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0303/Get_SEL_SelChoose_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELSelChooseList = response;
        });
    }

    getSELSelChooseOpClassSelList(dataKey: string,index:number) {
        this.sELSelChooseStdSelList = [];
        this.sELSelChooseOpClassSelList = [];
        this.sELSelChooseStdAddCosList = [];
        this.sELSelChooseOpClassAddStdList=[];
        this.dataKey = dataKey;
        this.sELSelChooseList[this.rowIndex].Selected = false;
        this.rowIndex=index;
        this.sELSelChooseList[this.rowIndex].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0303/Get_SEL_SelChoose_OpClass_SelList", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            this.sELSelChooseOpClassSelList = response;
            this.sELSelChooseOpClassSelList.forEach(element => {
                element.checked = false;
            })
        })
    }

    getSELSelChooseStdSelList(dataKey: string,index:number) {
        this.sELSelChooseStdSelList = [];
        this.sELSelChooseOpClassSelList = [];
        this.sELSelChooseStdAddCosList = [];
        this.sELSelChooseOpClassAddStdList=[];
        this.dataKey = dataKey;
        this.sELSelChooseList[this.rowIndex].Selected = false;
        this.rowIndex=index;
        this.sELSelChooseList[this.rowIndex].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0303/Get_SEL_SelChoose_Std_SelList", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELSelChooseStdSelList = response;
            this.sELSelChooseStdSelList.forEach(element => {
                element.checked = false;
            })
        });
    }

    getSELSelChooseOpClassAddStdList(Class: string, StdNo: string) {

        if (this.dataKey == '') return;

        if (Class == '' && StdNo == '') {
            this.toastr.info("請選擇查詢條件");
            return;
        }

        this.sELSelChooseOpClassAddStdList=[];

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0303/Get_SEL_SelChoose_OpClass_AddStdList", {
            DataKey: this.dataKey,
            Class: Class,
            StdNo: StdNo
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELSelChooseOpClassAddStdList = response;
            this.sELSelChooseOpClassAddStdList.forEach(element => {
                element.checked = false;
            })
        });
    }

    getSELSelChooseStdAddCosList(OpClass: string, CosID: string) {
        this.sELSelChooseStdAddCosList = [];
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0303/Get_SEL_SelChoose_Std_AddCosList", {
            DataKey: this.dataKey,
            OpClass: OpClass,
            CosID: CosID
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELSelChooseStdAddCosList = response;
            console.log(response);
            this.sELSelChooseStdAddCosList.forEach(element => {
                element.checked = false;
            })
        })
    }

    getSELSelChooseProcess(Type: string, data: any) {

        if (!data) return;

        let dataKey = '';

        data.forEach((element: any) => {
            if (element.checked) {
                dataKey += element.DataKey + ','
            }
        });

        if (dataKey == '') {
            this.toastr.info("請勾選資料");
            return;
        };

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0303/Get_SEL_SelChoose_Process", {
            DataKey: dataKey.substring(0, dataKey.length - 1),
            Type: Type
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("執行成功");

            switch(this.ngFormInput.get('Type')?.value){
                case '1':
                    this.getSELSelChooseOpClassSelList(this.dataKey,this.rowIndex);
                    break;
                case '2':
                    this.getSELSelChooseStdSelList(this.dataKey,this.rowIndex);
                    break;
                case '3':
                    this.getSELSelChooseStdSelList(this.dataKey,this.rowIndex);
                    break;
            }
        });
    }

    getSELSelChooseStdReVokeList(dataKey: string){
        this.sELSelChooseStdReVokeList=[];
        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0303/Get_SEL_SelChoose_Std_ReVokeList", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sELSelChooseStdReVokeList = response;
        })
    }

    getSELSelChooseReMark(event: EventTarget | null,dataKey: string) {
        let input = <HTMLInputElement>event;

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0303/Get_SEL_SelChoose_RestMark", {
            DataKey: dataKey,
            RestMark: input.checked
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success(response.Message);
        });
    }
}
