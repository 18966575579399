<div class="container" style="margin-bottom: 100px;">
    <form [formGroup]="searchForm" (ngSubmit)="getOfficeApplyRecord()">
        <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'日期'|translate}}</span>
                    </div>
                    <input type="text" placeholder="{{'--請選擇日期--'|translate}}" class="form-control shadow-none" bsDaterangepicker
                        [bsConfig]="bsConfig" #dpr="bsDaterangepicker" formControlName="Date">
                </div>
            </div>
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'狀態'|translate}}</span>
                    </div>
                    <select formControlName="status" class="form-control shadow-none">
                        <option value="">{{'全部'| translate}}</option>
                        <option value="0">{{'待審核'| translate}}</option>
                        <option value="1">{{'同意'| translate}}</option>
                        <option value="2">{{'不同意'| translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <button class="search-btn">{{'查詢'| translate}}</button>
            </div>
        </div>
    </form>

    <table class="table table-hover text-center table-striped table-rwd">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'約見面日'|translate}}</th>
                <th>{{'節次'|translate}}</th>
                <th>{{'班級'|translate}}</th>
                <th>{{'學號'|translate}}</th>
                <th>{{'姓名'|translate}}</th>
                <th>{{'申請原因'|translate}}</th>
                <th>{{'申請日期'|translate}}</th>
                <th>{{'狀態'|translate}}</th>
                <th>{{'回覆內容'|translate}}</th>
                <th>{{'回覆日期'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td data-th="約見面日">{{item.Meeting_Date}}</td>
                <td data-th="節次">{{item.Cur_Time}}</td>
                <td data-th="班級">{{item.Class_Short}}</td>
                <td data-th="學號">{{item.Std_No}}</td>
                <td data-th="姓名">{{item.Std_Name}}</td>
                <td data-th="申請原因">{{item.Apply_Memo}}</td>
                <td data-th="申請日期">{{item.Apply_Date}}</td>
                <ng-container *ngIf="item.Status == '待審核'">
                    <td data-th="狀態"><a class="text-primary fw-bolder pointer"
                            (click)="reply(item.DataKey)">{{item.Status}}</a></td>
                </ng-container>
                <ng-container *ngIf="item.Status == '同意'">
                    <td data-th="狀態" class="text-success fw-bolder">{{item.Status}}</td>
                </ng-container>
                <ng-container *ngIf="item.Status == '不同意'">
                    <td data-th="狀態" class="text-danger fw-bolder">{{item.Status}}</td>
                </ng-container>
                <td data-th="回覆內容">{{item.Reply_Memo}}</td>
                <td data-th="回覆日期">{{item.Reply_Date}}</td>
            </tr>
        </tbody>
    </table>
</div>