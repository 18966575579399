import { GetStdData } from './../../../_Models/STDWEB/STDW00001/getStdData';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetStdListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0201/getStdListInput';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSTDStatusList } from 'src/app/_Models/getSTDStatusList';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { GetHolidayList } from 'src/app/_Models/SAC/SAC1701/getHolidayList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { Get_Cur_List } from 'src/app/_Models/getCurList';
import { Week } from 'src/app/_Models/week';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-ADSRP0304',
  templateUrl: './ADSRP0304.component.html',
  styleUrls: ['./ADSRP0304.component.css']
})
export class ADSRP0304Component implements OnInit {
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  initialData = new InitialData(this.http);
  baseUrl = environment.apiUrl;
  dataKeyArray: Array<string> = [];
  eduList!: GetEduList[];
  teamList!: GetTeamList[];
  holidayList!: GetHolidayList[];
  deptList!: GetDeptList[];
  sTDStatusList!: GetSTDStatusList[];
  @Output() childEvent = new EventEmitter<any>();
  bsConfig = environment.bsDatePickerConfig;

  getStdListInput: GetStdListInput = {
      EduNo: '',
      DeptNo: '',
      TeamNo: '',
      Grade: '',
      Class: '',
      ClassID: '',
      Status: '',
      IDNo: '',
      StdName: '',
      StdNoFrom: '',
      StdNoTo: ''
  }
  getStdData:any = {
    Years: '',
    Term: '',
    EduNo:'',
    DeptNo:'',
    RecDateFrom:'',
    RecDateTo:'',
    HType:'',

  };

  data!: Get_Cur_List[];
  code!: string;
  week!: Week[];
  nowYear = '';
  nowTerm = '';
  selectedWeek: string = ''
  model = {
      Years: '',
      Term: ''
  }
  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private fileExchangeServices: FileExchangeService, private systemService: SystemService, private timeService: TimeService) { }
    ngOnInit(): void {
        this.getYears();
        this.getEduList();
        this.getHolidayList();
        this.timeService.getTimeCntr().subscribe((response) => {
        this.nowTerm = response.Now_Term;
        this.nowYear = response.Now_Years;
        this.getCurList({ Years: this.nowYear, Term: this.nowTerm })

    });
}
  exportReportADSRP0304(){
        this.getStdData.RecDateFrom = this.datePipe.transform(this.getStdData.RecDateFrom, 'yyyy-MM-dd')!.toString();
        this.getStdData.RecDateTo = this.datePipe.transform(this.getStdData.RecDateTo, 'yyyy-MM-dd')!.toString();
     this.fileExchangeServices.ExportFile("ACAD/SRP/ADSRP0304/Get_ADSRP0304", this.getStdData).subscribe((response:any)=>{

        if (response.Info == false) {
            this.toastr.warning("查無資料");
            return;
        }

        let url = window.URL.createObjectURL(response.blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = response.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    });
}

getHolidayList() {
    this.systemService.getHolidayList().subscribe(response => {
        this.holidayList = response;

    });
}
// getRewardsList(){
//     this.systemService.getRewardsList().subscribe(Response=>{
//         this.
//     })
// }



getYears() {
    this.initialData.getYears().subscribe((response: string[]) => {
        if (response) {
            this.Years = response;
        }
    }, (error: { error: string | undefined; }) => this.toastr.error(error.error));
}

GetData() {
    this.childEvent.emit(this.getStdListInput);
}

getDeptList(Edu: string) {
    this.systemService.getDeptList(Edu, '', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
        if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
        }
        this.deptList = response;
    }, error => this.toastr.error(error.error));
}

getTeamList(DeptNo: string) {
    this.systemService.getTeamList('', this.model.Years, this.model.Term, DeptNo, '').subscribe((response) => {
        if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
        }
        this.teamList = response;
        console.log(this.holidayList)
    });
}

getEduList() {
    this.systemService.getEduList('', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
        if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
        }
        this.eduList = response;
    }, error => this.toastr.error(error.error))
}

getCurList(model: any) {

    // 給周次onchange用
    this.nowYear = model.Years;
    this.nowTerm = model.Term;
    model.WeekIndex = this.selectedWeek;
    model.KeyValue = this.code;
    model.FormType = '06';
    this.http.post<Get_Cur_List[]>(this.baseUrl + "Public/Get_CurForm", model)
        .subscribe((response) => {
            this.data = response;
        },
            error => this.toastr.error(error.error)
        );
}

}
