<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">所屬國家</span>
                <select class="form-select shadow-none" #country (ngModelChange)="getCityList(country.value)"
                    [(ngModel)]="input.Country">
                    <option value="">--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of countryList">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">所屬城市</span>
                <select class="form-select shadow-none" [(ngModel)]="input.City">
                    <option value="">--請選擇--</option>
                    <option value="{{item.ItemValue}}" *ngFor="let item of cityList">{{item.ItemName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學校類別</span>
                <select class="form-select shadow-none" [(ngModel)]="input.SchClass">
                    <option value="">--請選擇--</option>
                    <option value="{{item.SchClass}}" *ngFor="let item of schoolClassList">
                        {{item.SchClass_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">查詢代碼或關鍵字</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="input.Search_Word">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <input type="text" style="width: 150px;" class="form-control float-end shadow-none" placeholder="工作表名稱"
                [(ngModel)]="workbook">
            <button class="other-Button-gray float-end me-3" (click)="import.click()">匯入資料</button>
            <button class="other-Button-gray float-end me-3" (click)="download.click()">匯出範本</button>
            <a href="../../../../assets/file/學校資料匯入範本.xlsx" #download hidden></a>
            <input id="file" type="file" hidden #import (change)="importData($event, '', '')">
            <button class="search-btn float-end me-3 mb-3" (click)="search()">查詢</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>學校類別</th>
                        <th>學校代碼</th>
                        <th>學校名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="searchDetail(item.SchClass, item.Sch_No, i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.SchClass_Name}}</td>
                        <td>{{item.Sch_No}}</td>
                        <td>{{item.Sch_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text shadow-none">學校類別</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.SchClass">
                            <option value="">--請選擇--</option>
                            <option value="{{item.SchClass}}" *ngFor="let item of schoolClassList">
                                {{item.SchClass_Name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學校代碼</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Sch_No">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學校名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Sch_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">所屬國家</span>
                        <select class="form-select shadow-none" (change)="getCityList(country.value)"
                            [(ngModel)]="dataDetail.Country">
                            <option value="">--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of countryList">
                                {{item.ItemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">所屬城市</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.City">
                            <option value="">--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of cityList">{{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">地址</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Com_Address">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學校電話</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Com_Tel">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">學校首頁</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Website">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">體系別</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.SchType">
                            <option value="">--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of schoolTypeList">
                                {{item.ItemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <span class="input-group-text fw-bolder"
                        style="background-color: transparent; border: none;">(限大轉院校用)</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">備註</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Memo">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_User">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_Time">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>