import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADCUP/ADCUP0306/search';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADCUP0306',
    templateUrl: './ADCUP0306.component.html',
    styleUrls: ['./ADCUP0306.component.css']
})
export class ADCUP0306Component implements OnInit {
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    detailDeptList!: GetDeptList[];
    baseUrl = environment.apiUrl;
    data!: Search[];
    dataDetail: Search = {
        Dept_No: '',
        CurClass: '',
        CurClass_Name: '',
        Update_User: '',
        Update_Time: '',
        Selected: false
    };
    Dept_No: string = '';
    isEdit = true;
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getDeptList('', true);
        this.getEduList();
    }

    getEduList() {
        this.systemService.getEduList('', '', '', 'Y').subscribe((response) => {
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, detail: boolean) {
        this.systemService.getDeptList(edu, '', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            detail ? this.detailDeptList = response : this.deptList = response;
        }, error => this.toastr.error(error.error));
    }

    search() {

        if (this.Dept_No == '') {
            this.toastr.info("請選擇系所");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0306/Search", { Dept_No: this.Dept_No }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        });
    }

    searchDetail(index: number) {
        this.isEdit = false;
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.dataDetail = this.data[index];
    }

    update() {
        if (this.dataDetail.CurClass == '' || this.dataDetail.CurClass_Name == '') {
            this.toastr.info("請填妥欄位");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0306/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功");
            this.search();
        });
    }

    insert() {
        if (this.dataDetail.CurClass == '' || this.dataDetail.CurClass_Name == '' || this.dataDetail.Dept_No == '') {
            this.toastr.info("請填妥欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0306/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功");
            this.search();
        });
    }

    clear() {
        this.isEdit = true;
        this.dataDetail = {
            Dept_No: '',
            CurClass: '',
            CurClass_Name: '',
            Update_User: '',
            Update_Time: '',
            Selected: false
        };
    }

    delete() {

        if (this.dataDetail.CurClass == '' || this.dataDetail.CurClass_Name == '') {
            this.toastr.info("請選擇資料");
            return;
        }

        Swal.fire({
            title: `確定要刪除？`,
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0306/Delete", this.dataDetail).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.search();
                });
            }
            else {
                this.toastr.info("動作取消");
            }
        })
    }


}
