<div class="container">
    <app-year-term-input (childEvent)="getTermScoreList($event)"></app-year-term-input>
    <table class="table table-hover table-striped table-rwd text-center">
        <thead class="table-rwd-hide thead-dark-red">
            <tr>
                <th>{{'學年' | translate}}</th>
                <th>{{'學期' | translate}}</th>
                <th>{{'課程代碼' | translate}}</th>
                <th>{{'課程名稱' | translate}}</th>
                <th>{{'選修別' | translate}}</th>
                <th>{{'學分' | translate}}</th>
                <th>{{'期中成績' | translate}}</th>
                <th>{{'學期成績' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td data-th="學年">{{item.Years}}</td>
                <td data-th="學期">{{item.Term}}</td>
                <td data-th="課程代碼">{{item.Cos_ID}}</td>
                <td data-th="課程名稱">{{item.Cos_Name}}</td>
                <td data-th="選修別">{{item.SelKind_Name}}</td>
                <td data-th="學分">{{item.Cos_Credit}}</td>
                <ng-container *ngIf="checkScore(item.MTerm_Score) == false">
                    <td data-th="期中成績">{{item.MTerm_Score}}</td>
                </ng-container>
                <ng-container *ngIf="checkScore(item.MTerm_Score) == true">
                    <td style="font-weight: bolder;" data-th="期中成績"><span
                            class="text-danger">{{item.MTerm_Score}}</span></td>
                </ng-container>
                <ng-container *ngIf="checkScore(item.Final_Score) == false">
                    <td data-th="學期成績">{{item.Final_Score}}</td>
                </ng-container>
                <ng-container *ngIf="checkScore(item.Final_Score) == true">
                    <td style="font-weight: bolder;" data-th="學期成績"><span
                            class="text-danger">{{item.Final_Score}}</span></td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>