import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0104/search';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0104/searchDetail';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetEmployeeList } from 'src/app/_Models/getEmployeeList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADRGP0104',
    templateUrl: './ADRGP0104.component.html',
    styleUrls: ['./ADRGP0104.component.css']
})
export class ADRGP0104Component implements OnInit {
    modalRef?: BsModalRef;
    eduList!: GetEduList[];
    selected?: string;
    academyList!: GetSelectList[];
    unitmyList!: GetSelectList[];
    baseUrl = environment.apiUrl;
    data!: Search[];
    dataDetail: SearchDetail = {
        Edu_No: '',
        Dept_No: '',
        Dept_Name: '',
        Dept_Short: '',
        Dept_EngName: '',
        Dept_EngShort: '',
        Manager_Code: '',
        Manager_Name: '',
        Unit_Code:'',
        State_Log: '',
        DN_Mark: '',
        Acad_No: '',
        Update_User: '',
        Update_Time: ''
    };
    search1 = {
        EduNo: '',
        Status: '',
        Keyword: ''
    }
    DNMArkList!: GetSelectList[];
    employeeList!: GetEmployeeList[];
    constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService, private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getEduList();
        this.getDNMark();
        this.getAcademyList();
        this.getUnitList();
    }

    getEmployeeList() {
        this.systemService.getEmployeeList().subscribe((response) => {
            console.log(response)
            this.employeeList = response;
        })
    }

    getDNMark() {
        this.systemService.getDNMark().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.DNMArkList = response;
        })
    }

    getAcademyList() {
        this.systemService.getAcademyList().subscribe((response) => {
            this.academyList = response;
        });
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getUnitList() {
        this.systemService.getUnitList('', '', '').subscribe(response => {
            if (response.Info == false) {
                return;
            }
            this.unitmyList = response;
        })
    }

    search(EduNo: string, Status: string, Keyword: string) {
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0104/Search", { Edu_No: EduNo, State_Log: Status, Search_Word: Keyword }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        })
    }

    searchDetail(DeptNo: string, index: number) {
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0104/Search_Detail", { Dept_No: DeptNo }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.dataDetail = response;
            this.dataDetail.Dept_EngName = this.dataDetail.Dept_EngName;
            this.dataDetail.Dept_EngShort = this.dataDetail.Dept_EngShort;
            this.dataDetail.Dept_Name = this.dataDetail.Dept_Name;
            this.dataDetail.Dept_Short = this.dataDetail.Dept_Short;
            this.dataDetail.Manager_Name = this.dataDetail.Manager_Name;
            this.dataDetail.Update_Time = this.dataDetail.Update_Time;
            this.dataDetail.Update_User = this.dataDetail.Update_User;
        })

    }

    insert() {

        if (this.dataDetail.Dept_No == '' || this.dataDetail.Dept_Name == '' || this.dataDetail.Dept_EngName == '' || this.dataDetail.Dept_Short == '' ||
            this.dataDetail.Edu_No == '' || this.dataDetail.Manager_Code == '' || this.dataDetail.Manager_Name == '' || this.dataDetail.State_Log == '' ||
            this.dataDetail.Dept_EngShort == '' || this.dataDetail.Acad_No == '' || this.dataDetail.DN_Mark == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0104/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功，請重新查詢");
            this.search(this.search1.EduNo, this.search1.Status, this.search1.Keyword)
        }, error => this.toastr.error(error.error))
    }

    update() {
        if (this.dataDetail.Dept_No == '' || this.dataDetail.Dept_Name == '' || this.dataDetail.Dept_EngName == '' || this.dataDetail.Dept_Short == '' ||
            this.dataDetail.Edu_No == '' || this.dataDetail.Manager_Code == '' || this.dataDetail.Manager_Name == '' || this.dataDetail.State_Log == '' ||
            this.dataDetail.Dept_EngShort == '' || this.dataDetail.Acad_No == '' || this.dataDetail.DN_Mark == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0104/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功，請重新查詢");
            this.search(this.search1.EduNo, this.search1.Status, this.search1.Keyword)
        }, error => this.toastr.error(error.error))
    }

    delete() {
        if (this.dataDetail.Dept_No == '') {
            this.toastr.info("請選擇系所");
            return;
        }

        Swal.fire({
            title: "確定刪除？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0104/Delete", this.dataDetail).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功，請重新查詢");
                    this.clear();
                }, error => this.toastr.error(error.error))
            }
            else {
                this.toastr.info("動作取消");
            }
        })


    }

    clear() {
        this.dataDetail = {
            Edu_No: '',
            Dept_No: '',
            Dept_Name: '',
            Dept_Short: '',
            Dept_EngName: '',
            Dept_EngShort: '',
            Manager_Code: '',
            Manager_Name: '',
            Unit_Code: '',
            State_Log: '',
            DN_Mark: '',
            Acad_No: '',
            Update_User: '',
            Update_Time: ''
        };
    }

    openModal(template: TemplateRef<any>) {
        this.getEmployeeList();
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-lg'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setTeacher(code: string, name: string) {
        this.dataDetail.Manager_Code = code;
        this.dataDetail.Manager_Name = name;
        this.modalRef?.hide();
    }

}
