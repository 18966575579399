
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ADSSS0205',
  templateUrl: './ADSSS0205.component.html',
  styleUrls: ['./ADSSS0205.component.css']
})
export class ADSSS0205Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}