import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { GetTFSModuleDeptFeeTypeList } from 'src/app/_Models/AD/ADTFS/ADTFS01B01/getTFSModuleDeptFeeTypeList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetTFSModuleList } from 'src/app/_Models/AD/ADTFS/ADTFS01B01/getTFSModuleList';
import { GetTFSModuleDetail } from 'src/app/_Models/AD/ADTFS/ADTFS01B01/getTFSModuleDetail';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-ADTFS01B01',
  templateUrl: './ADTFS01B01.component.html',
  styleUrls: ['./ADTFS01B01.component.css']
})
export class ADTFS01B01Component implements OnInit {
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  copyYears!: Array<string>;
  baseUrl = environment.apiUrl;
  initialData = new InitialData(this.http);
  eduList!: GetEduList[];
  feeAcadList!: GetSelectList[];
  setFeeAcadList!: GetSelectList[];
  entryIDList!: GetSelectList[];
  tFSModuleDeptFeeTypeList!: GetTFSModuleDeptFeeTypeList[];
  tFSModuleList!: GetTFSModuleList[];
  tFSModuleDetail!: GetTFSModuleDetail[] | undefined;
  copyModalRef!: BsModalRef;
  typeModalRef!: BsModalRef;
  datakeyArray: Array<string> = [];
  checkType = new CheckType()


  constructor(private systemService: SystemService, private http: HttpClient, private modalService: BsModalService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getYears();
    this.getFeeAcadList();
    this.getEntryIDList();
  }


  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
        this.copyYears = response;
      }
    });
  }

  getFeeAcadList() {
    this.systemService.getFeeAcadList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.feeAcadList = response;
      this.setFeeAcadList = response;
    })
  }

  getEntryIDList() {
    this.systemService.getEntryIDList('A').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.entryIDList = response;
    })
  }

  getEduList(Years: string, Term: string) {

    if (Years == '' || Term == '') return;

    this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.eduList = response;

    }, error => this.toastr.error(error))
  }

  getTFSModuleDeptFeeTypeList(Years: string, Term: string, EduNo: string, EntryID: string, AcadNo: string, template: TemplateRef<any>) {
    if (Years == '' || Term == '') {
      this.toastr.info("請選擇學年學期");
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B01/Get_TFS_ModuleDeptFeeTypeList", {
      DataKey: '',
      Years: Years,
      Term: Term,
      EduNo: EduNo,
      EntryID: EntryID,
      AcadNo: AcadNo
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tFSModuleDeptFeeTypeList = response;


      // 預設為false，用於選取識別
      for (let index = 0; index < this.tFSModuleDeptFeeTypeList.length; index++) {
        this.tFSModuleDeptFeeTypeList[index].Checked = false;
      }

      let modalConfig = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-xl'
      };

      this.typeModalRef = this.modalService.show(template, modalConfig);

    })
  }


  getTFSModuleList(Years: string, Term: string, EduNo: string, EntryID: string, AcadNo: string) {

    if (Years == '' || Term == '') {
      this.toastr.info("請選擇學年學期");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B01/Get_TFS_ModuleList", {
      DataKey: '',
      Years: Years,
      Term: Term,
      EduNo: EduNo,
      EntryID: EntryID,
      AcadNo: AcadNo
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSModuleList = []
        return;
      }
      this.tFSModuleList = response;
    })
  }

  getTFSModuleDetail(index: number, datakey: string) {

    this.tFSModuleList.forEach(element => {
      element.Selected = false;
    })
    this.tFSModuleList[index].Selected = true;

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B01/Get_TFS_ModuleDetail", { DataKey: datakey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSModuleDetail = undefined
        return;
      }
      this.tFSModuleDetail = response;
    })
  }

  setCopy(template: TemplateRef<any>) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-md'
    };
    this.copyModalRef = this.modalService.show(template, modalConfig);
  }

  copyData(YearFrom: string, TermFrom: string, YearTo: string, TermTo: string) {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B01/Get_TFS_ModuleCopy", {
      YearFrom: YearFrom,
      TermFrom: TermFrom,
      YearTo: YearTo,
      TermTo: TermTo
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("複製成功");
    }, error => this.toastr.error(error))
  }

  getTFSModuleSave() {

    if (!this.tFSModuleDetail) return;

    let pass = true;
    for (let index = 0; index < this.tFSModuleDetail.length; index++) {
      if (this.checkType.CheckIsNumber(this.tFSModuleDetail[index].Money)) {
        this.toastr.warning(`第${index + 1}項非數字`);
        pass = false;
        break;
      }
    }

    if (pass) {
      this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B01/Get_TFS_ModuleSave", this.tFSModuleDetail).subscribe((response) => {
        if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
        }
        this.toastr.success("儲存成功");
        this.getTFSModuleList('', '', '', '', '')
      }, error => this.toastr.error(error))
    }
  }

  setDataKey(datakey: string, type: string, event: any) {
    let input = <HTMLInputElement>event.target;
    if (type != 'all') {
      let index = this.datakeyArray.indexOf(datakey);
      if (index == -1 && input.checked == true) {
        this.datakeyArray.push(datakey);
      }
      else {
        this.datakeyArray.splice(index, 1);
      }
    }
    else if (type == 'all' && input.checked == true) {
      this.datakeyArray = [];
      this.tFSModuleDeptFeeTypeList.forEach(element => {
        this.datakeyArray.push(element.DataKey);
        element.Checked = true;
      })
    }
    else {
      this.datakeyArray = [];
      for (let index = 0; index < this.tFSModuleDeptFeeTypeList.length; index++) {
        this.tFSModuleDeptFeeTypeList[index].Checked = false;
      }
    }
  }

  saveType(acadNo: string) {
    if (this.datakeyArray.length == 0) return;
    if (acadNo == '') {
      this.toastr.info("請選擇收費類別");
      return;
    }

    let str = '';
    this.datakeyArray.forEach(element => {
      str += element + ','
    });
    let datakey = str.substring(0, str.length - 1);

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS01B01/Get_TFS_ModuleDeptFeeTypeSave", { DataKey: datakey, AcadNo: acadNo }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("設定完成");
      this.datakeyArray = [];
      this.typeModalRef.hide();
    }, error => this.toastr.error(error));
  }

}
