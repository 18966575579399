import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetGRDTermScoreChangeReason } from 'src/app/_Models/AD/ADGRD/ADGRD0302/getGRDTermScoreChangeReason';
import { GetGRDTermScoreCosList,DataKeyInput } from 'src/app/_Models/AD/ADGRD/ADGRD0302/getGRDTermScoreCosList';
import { GetGRDTermScoreList } from 'src/app/_Models/AD/ADGRD/ADGRD0302/getGRDTermScoreList';
import { GetGRDTermScoreModifyList, TchScore } from 'src/app/_Models/AD/ADGRD/ADGRD0302/getGRDTermScoreModifyList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADGRD0302',
    templateUrl: './ADGRD0302.component.html',
    styleUrls: ['./ADGRD0302.component.css']
})
export class ADGRD0302Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    eduList?: GetEduList[];
    deptList?: GetDeptList[];
    classList?: GetClassList[];
    datakey?: string
    TchSelect?:DataKeyInput[];
    gRDTermScoreCosList?: GetGRDTermScoreCosList[];
    gRDTermScoreList?: GetGRDTermScoreList[];
    gRDTermScoreModifyList!: GetGRDTermScoreModifyList;
    gDTermScoreChangeReason!: GetGRDTermScoreChangeReason[];
    gRDTermScoreTchList!:GetSelectList[];
    tchScore?: TchScore;
    modalRef?: BsModalRef;
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        Serial: new UntypedFormControl(''),
        StdNo: new UntypedFormControl(''),
        Status: new UntypedFormControl(''),
        TchName: new UntypedFormControl('')
    });
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private modalService: BsModalService) { }

    ngOnInit(): void {
        this.getYears();
        this.getGRDTermScoreChangeReason();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList(Years: string, Term: string) {
        this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.eduList = undefined;
            this.eduList = response;
            this.gRDTermScoreCosList = undefined;
            this.deptList = undefined;
            this.classList = undefined;
        })
    }

    getDeptList(edu: string, Years: string, Term: string) {
        this.systemService.getDeptList(edu, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = undefined;
            this.deptList = response;
            this.classList = undefined;
            this.gRDTermScoreCosList = undefined;
        });
    }

    getClassList(edu: string, dept: string, Years: string, Term: string) {
        this.systemService.getClassList(edu, dept, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classList = undefined;
            this.classList = response;
            this.gRDTermScoreCosList = undefined;
        })

    }

    getGRDTermScoreList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0302/Get_GRD_TermScore_List", this.ngFormInput.value).subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.gRDTermScoreList = undefined;
                return;
            }
            this.gRDTermScoreList = response;
        }));
    }

    getGRDTermScoreCosList() {
        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0302/Get_GRD_TermScore_Cos_List", this.ngFormInput.value).subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.gRDTermScoreCosList = response;
        }));
    }

    openModal(datakey: string, template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
        this.getGRDTermScoreModifyList(datakey);
    }

    getGRDTermScoreModifyList(datakey: string) {
        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0302/Get_GRD_TermScore_Modify_List", { DataKey: datakey }).subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.gRDTermScoreModifyList = response;
            this.gRDTermScoreModifyList.TchScore.forEach(element => {
                element.Selected = false;
            });
            this.tchScore = undefined;
            console.log(response);
        }));
    }

    getTchScore(index: number) {

        this.gRDTermScoreModifyList.TchScore.forEach(element => {
            element.Selected = false;
        });
        this.gRDTermScoreModifyList.TchScore[index].Selected = true;
        this.tchScore = this.gRDTermScoreModifyList.TchScore[index];
    }

    getGRDTermScoreModifySave() {

        console.log(this.tchScore);

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0302/Get_GRD_TermScore_Modify_Save", this.tchScore).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }

    getGRDTermScoreTchCos(){
        if(this.ngFormInput.get("Years")?.value==''){
            this.toastr.warning('請輸入學年!');
            return;
        }

        if(this.ngFormInput.get("Term")?.value==''){
            this.toastr.warning('請點選學期!');
            return;
        }

        if(this.ngFormInput.get("TchName")?.value==''){
            this.toastr.warning('請輸入教師姓名或職編!');
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0302/Get_GRD_TermScore_Cos_List", this.ngFormInput.value).subscribe((response => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.gRDTermScoreCosList = response;
        }));
    }

    getGRDTermScoreTchList(datakey: string) {
        this.gRDTermScoreTchList=[];
        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0302/Get_GRD_TermScore_Tch_List", { DataKey: datakey }).subscribe(((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.gRDTermScoreTchList=response;
        }));
    }

    getGRDTermScoreChangeReason() {
        this.http.get<any>(this.baseUrl + "ACAD/GRD/ADGRD0302/Get_GRD_TermScore_ChangeReason").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.gDTermScoreChangeReason = response;
        })
    }

    getTchSelect(template: TemplateRef<any>){
        let DataKey=this.ngFormInput.get("Serial")?.value;

        if (DataKey == '') {
            this.toastr.warning('請選取科目!');
            return;
        }

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-l'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
        this.getGRDTermScoreTchList(DataKey);
    }

    getTchScoreDetail(DataKey: string, template: TemplateRef<any>){
        this.TchSelect=[];
        this.TchSelect.push({DataKey:DataKey,IsTch:false});
        this.modalRef?.hide()
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }
}
