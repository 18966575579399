import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { GetConfirmList } from 'src/app/_Models/TCHWEB/TCH0118/getData';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-TCH0118',
  templateUrl: './TCH0118.component.html',
  styleUrls: ['./TCH0118.component.css']
})
export class TCH0118Component implements OnInit {
  baseUrl = environment.apiUrl;
  modalRef!: BsModalRef;
  ConfirmLis!: GetConfirmList[];
  bsConfig = environment.bsDatePickerConfig;

  ngFormInput = new UntypedFormGroup({
    Years: new UntypedFormControl(''),
    Term: new UntypedFormControl(''),
    UnitCode: new UntypedFormControl(''),
    Title: new UntypedFormControl(''),
    TchNo: new UntypedFormControl(''),
    TchName: new UntypedFormControl(''),
    DataKey: new UntypedFormControl('')
  });

  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private localeService: BsLocaleService,
    private fileExchangeServices: FileExchangeService) { }

  ngOnInit(): void {
    this.getConfirmList();
    this.localeService.use('zh-cn');
  }

  getConfirmList() {
    this.ConfirmLis = [];
    this.http.get<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_18/Get_TchMoney_List").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.ConfirmLis = response;
    })
  }

  exportCertFile(dataKey:string) {
    this.ngFormInput.get("Years")?.setValue("");
    this.ngFormInput.get("Term")?.setValue("");
    this.ngFormInput.get("TchNo")?.setValue(dataKey);
    this.fileExchangeServices.ExportFile("ACAD/TCHWEB/TCH01_18/Get_TchMoney_Form", this.ngFormInput.value).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }

      if (response.FileName == '') {
        this.toastr.warning("查無資料");
        return;
      }

      let url = window.URL.createObjectURL(response.blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = response.FileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => console.log(error.error))
  }
}
