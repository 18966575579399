import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetSYSStdDetail } from 'src/app/_Models/AD/ADSYS/ADSYS0102/getSYSStdDetail';
import { GetSYSStdList } from 'src/app/_Models/AD/ADSYS/ADSYS0102/getSYSStdList';
import { GetSYSStdListInput } from 'src/app/_Models/AD/ADSYS/ADSYS0102/getSYSStdListInput';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADSYS0102',
  templateUrl: './ADSYS0102.component.html',
  styleUrls: ['./ADSYS0102.component.css']
})
export class ADSYS0102Component implements OnInit {
  baseUrl = environment.apiUrl;
  deptList!: GetDeptList[];
  eduList!: GetEduList[];
  classList!: GetClassList[];

  sYSStdListInput: GetSYSStdListInput = {
    StdNo: '',
    StdName: '',
    Class: ''
  }
  sYSStdList!: GetSYSStdList[];
  sYSStdDetail: GetSYSStdDetail = {
    DataKey: '',
    StdNo: '',
    StdName: '',
    PassWord: '',
    OnlineChangePwd: false,
    DefaultPwd: false,
    ChangePwdUser: '',
    ChangePwdTime: ''
  }

  constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService,) { }

  ngOnInit(): void {
    this.getEduList();
  }



  getEduList() {
    this.systemService.getEduList('2', '', '', 'Y').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.eduList = response;
    }, error => this.toastr.error(error.error))
  }

  getDeptList(edu: string) {
    this.systemService.getDeptList(edu, '2', '', '', 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.deptList = response;
    }, error => this.toastr.error(error.error));
  }

  getClassList(edu: string, dept: string) {
    this.systemService.getClassList(edu, dept, '2', '', '', 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.classList = response;
    }, error => this.toastr.error(error.error))
  }

  getSYSStdList() {
    this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0102/Get_SYS_StdList", this.sYSStdListInput).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.sYSStdList = []
        return;
      }
      this.sYSStdList = response;
      this.sYSStdList.forEach(element => {
        element.Selected = false;
      });
    }, error => this.toastr.error(error.error))
  }

  getSYSStdDetail(DataKey: string, index: number) {

    this.sYSStdList.forEach(element => {
      element.Selected = false;
    });
    this.sYSStdList[index].Selected = true;

    this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0102/Get_SYS_StdDetail", { DataKey: DataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.sYSStdDetail = response;
    }, error => this.toastr.error(error.error))
  }

  getSYSStdModify() {

    if (this.sYSStdDetail.DataKey == '') {
      this.toastr.info("請先選擇學生");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0102/Get_SYS_StdModify", this.sYSStdDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.info("修改成功");
      this.getSYSStdList()
    }, error => this.toastr.error(error.error))
  }

}
