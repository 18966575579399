<div class="container">
    <form [formGroup]="ngFormInput" (ngSubmit)="getSRPReportNote()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Years">
                        <option value="">--請選擇--</option>
                        <option *ngFor=" let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="Term">
                        <option value="">--請選擇--</option>
                        <option *ngFor=" let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">登錄日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                        formControlName="IssueDateFrom">
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly style="background-color: transparent;"
                        formControlName="IssueDateTo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">事由</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="Reason">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號/姓名/身分證號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" formControlName="FieldTypeValue">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">排序方式</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="OrderBy">
                        <option value="1">登錄日期</option>
                        <option value="2">學號</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">獎懲種類</span>
                    </div>
                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                        <input type="checkbox" class="btn-check" id="btncheck1" autocomplete="off" value="A"
                            (click)="setCheck($event.target)">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck1">嘉獎</label>

                        <input type="checkbox" class="btn-check" id="btncheck2" autocomplete="off" value="B"
                            (click)="setCheck($event.target)">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck2">小功</label>

                        <input type="checkbox" class="btn-check" id="btncheck3" autocomplete="off" value="C"
                            (click)="setCheck($event.target)">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck3">大功</label>

                        <input type="checkbox" class="btn-check" id="btncheck4" autocomplete="off" value="H"
                            (click)="setCheck($event.target)">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck4">申誡</label>

                        <input type="checkbox" class="btn-check" id="btncheck5" autocomplete="off" value="I"
                            (click)="setCheck($event.target)">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck5">小過</label>

                        <input type="checkbox" class="btn-check" id="btncheck6" autocomplete="off" value="J"
                            (click)="setCheck($event.target)">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck6">大過</label>

                        <input type="checkbox" class="btn-check" id="btncheck7" autocomplete="off" value="W"
                            (click)="setCheck($event.target)">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck7">定期查看</label>

                        <input type="checkbox" class="btn-check" id="btncheck8" autocomplete="off" value="X"
                            (click)="setCheck($event.target)">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck8">休學</label>

                        <input type="checkbox" class="btn-check" id="btncheck9" autocomplete="off" value="Z"
                            (click)="setCheck($event.target)">
                        <label class="btn btn-outline-secondary shadow-none" for="btncheck9">退學</label>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <button class="search-btn float-start me-3">查詢</button>
                <button class="other-Button-gray float-start me-3" type="button" style="width: 150px;"
                    (click)="exportReportADSRP0301()">列印獎懲通知單</button>
            </div>
        </div>
    </form>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>學年期</th>
                <th>班級</th>
                <th>學號(姓名)</th>
                <th>發生日期</th>
                <th>獎懲紀錄</th>
                <th>事由</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sRPReportNote">
                <td>{{item.Years}}-{{item.Term}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.StdNo}}</td>
                <td>{{item.IssueDate}}</td>
                <td>{{item.TypeData}}</td>
                <td>{{item.Reason}}</td>
            </tr>
        </tbody>
    </table>
</div>
