<div class="container">
    <form [formGroup]="ngForm" (ngSubmit)="exportReport()">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學年</span>
                    </div>
                    <select class="form-select shadow-none" #Year (ngModelChange)="getEduList(); getUnitList();"
                        formControlName="Years">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學期</span>
                    </div>
                    <select class="form-select shadow-none" #Term (ngModelChange)="getEduList(); getUnitList();"
                        formControlName="Term">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">課表類型</span>
                    </div>
                    <select class="form-select shadow-none" #Cur formControlName="Type">
                        <option value="C">班級課表</option>
                        <option value="T">教師課表</option>
                        <option value="R">教室課表</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <button class="export-btn">匯出</button>
            </div>
        </div>
        <div class="row" *ngIf="Cur.value == 'C'">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學制</span>
                    </div>
                    <select class="form-select shadow-none" #EduNo (change)="getDeptList(EduNo.value)">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">系所</span>
                    </div>
                    <select class="form-select shadow-none" #DeptNo (change)="getClassList(EduNo.value, DeptNo.value);"
                        formControlName="DeptNo">
                        <option value="" selected disabled>--請選擇--</option>
                        <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班別</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="ClassFrom">
                        <option value="" selected>--請選擇班別--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="ClassTo">
                        <option value="" selected>--請選擇班別--</option>
                        <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="Cur.value == 'R'">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">樓別</span>
                    </div>
                    <select class="form-select shadow-none" #Building formControlName="BuildingNo"
                        (ngModelChange)="getClassRoomList(Building.value)">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of buildingList" value="{{item.ClassKind}}">{{item.ClassKind_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">教室</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="ClassroomFrom">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of classRoomList" value="{{item.Classroom}}">{{item.Classroom_Name}}
                        </option>
                    </select>
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="ClassroomTo">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of classRoomList" value="{{item.Classroom}}">{{item.Classroom_Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="Cur.value == 'T'">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">單位</span>
                    </div>
                    <select class="form-select shadow-none" #Unit formControlName="UnitCode"
                        (ngModelChange)="getSYSUserList(Unit.value)">
                        <option value="">--請選擇--</option>
                        <option value="{{item.ItemValue}}" *ngFor="let item of unitData">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">教師</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="TchNoFrom">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of sYSUserList" value="{{item.UserCode}}">
                            {{item.UserCode}}-{{item.UserName}}
                        </option>
                    </select>
                    <div class="input-group-prepend">
                        <span class="input-group-text">至</span>
                    </div>
                    <select class="form-select shadow-none" formControlName="TchNoTo">
                        <option value="">--請選擇--</option>
                        <option *ngFor="let item of sYSUserList" value="{{item.UserCode}}">
                            {{item.UserCode}}-{{item.UserName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </form>

</div>