import { Component, NgModule, Input,Output,OnInit,EventEmitter } from '@angular/core';
import { GetEmployeeList } from 'src/app/_Models/getEmployeeList';
import { HttpClient } from '@angular/common/http';
import { SystemService } from "src/app/_Services/_Shared/system.service";
import { environment } from "src/assets/environments/environment";
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-SearchTeacher',
    templateUrl: './SearchTeacher.component.html',
    styleUrls: ['./SearchTeacher.component.css']
})

export class SearchTeacherComponent implements OnInit {
    @Input() DeptNo!: string;
    @Output() triggerEvent = new EventEmitter();
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    employeeList!: GetEmployeeList[];
    employee={
        Code:"",
        Name:""
    };
    subpageconfig = { backdrop: true, ignoreBackdropClick: true, class: "modal-lg" };
    constructor(private http: HttpClient, private systemService: SystemService, private toastr: ToastrService, private modalService: BsModalService) { }
    ngOnInit(): void {
    }

    triggerOutputEvent(code:string,name:string) {
        this.employee.Code=code;
        this.employee.Name=name;
        this.triggerEvent.emit(this.employee);
    }

    getEmployeeList(NameCode:string) {
        this.systemService.getEmployeeTeacherList(NameCode,this.DeptNo).subscribe((response) => {
            this.employeeList = response;
        });
    }
}
