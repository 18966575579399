import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from '../_Services/_Shared/account.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private accountService: AccountService,
        private router: Router,
        private toastr: ToastrService) { }

    canActivate(): Observable<boolean> {
        this.accountService.autoLogin();
        return this.accountService.currentUser$.pipe(
            map(user => {
                if (user) {
                    return true;
                }
                this.toastr.error("驗證失敗，請重新登入。");
                this.router.navigateByUrl('/');
                return false
            })
        );
    }
}
