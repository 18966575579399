<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">人事代碼</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="sYSAccountListInput.UserCode">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">帳號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="sYSAccountListInput.Account">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">身份</span>
                <select class="form-select shadow-none" [(ngModel)]="sYSAccountListInput.UserType">
                    <option value="">--請選擇--</option>
                    <option value="T">老師</option>
                    <option value="E">教職員</option>
                    <option value="I">教官</option>
                    <option value="O">特殊身份</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">姓名</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="sYSAccountListInput.UserName">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">所屬單位</span>
                <select class="form-select shadow-none" [(ngModel)]="sYSAccountListInput.UnitNo">
                    <option value="">--請選擇--</option>
                    <option *ngFor="let item of unitList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">帳號狀態</span>
                <select class="form-select shadow-none" [(ngModel)]="sYSAccountListInput.Status">
                    <option value="">--請選擇--</option>
                    <option value="1">啟用</option>
                    <option value="0">未啟用</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <button class="other-Button-gray float-end me-3" (click)="getSYSAccountModify()">修改</button>
            <button class="search-btn float-end me-3" (click)="getSYSAccountList()">查詢</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>代碼</th>
                        <th>姓名</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of sYSAccountList; let i = index" class="pointer"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="getSYSAccountDetail(item.DataKey, i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.UserCode}}</td>
                        <td>{{item.UserName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">人事代碼</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="sYSAccountDetail.UserCode">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">姓名</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="sYSAccountDetail.UserName">
                    </div>
                </div>              
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">帳號</span>
                        <input type="text" class="form-control shadow-none" disabled [(ngModel)]="sYSAccountDetail.Account">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-check form-switch  mt-1">
                        <input class="form-check-input shadow-none pointer" type="checkbox" role="switch" id="status"
                            [(ngModel)]="sYSAccountDetail.Status" [checked]="sYSAccountDetail.Status">
                        <label class="form-check-label" for="status">帳號啟用</label>
                    </div>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">密碼</span>
                        <input type="password" class="form-control shadow-none" [(ngModel)]="sYSAccountDetail.PassWord">
                    </div>
                </div>                  
                <div class="col-md-4">
                    <div class="form-check form-switch  mt-1">
                        <input class="form-check-input shadow-none pointer" type="checkbox" role="switch"
                            id="reset-password" [(ngModel)]="sYSAccountDetail.DefaultPwd"
                            [checked]="sYSAccountDetail.DefaultPwd">
                        <label class="form-check-label" for="reset-password">還原為預設密碼(身分證字號)</label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-check form-switch  mt-1">
                        <input class="form-check-input shadow-none pointer" type="checkbox" role="switch"
                            id="change-password" [(ngModel)]="sYSAccountDetail.OnlineChangePwd"
                            [checked]="sYSAccountDetail.OnlineChangePwd">
                        <label class="form-check-label" for="change-password">啟用線上修改密碼</label>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="sYSAccountDetail.UpdateUser">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改時間</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="sYSAccountDetail.UpdateTime">
                    </div>
                </div>
            </div>    
            <div class="row">         
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">變更密碼者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="sYSAccountDetail.ChangePwdUser">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="input-group mb-3">
                        <span class="input-group-text">變更密碼時間</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="sYSAccountDetail.ChangePwdTime">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>