import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassListCheckbox';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSTDStatusList } from 'src/app/_Models/getSTDStatusList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADGRD0602ISU',
    templateUrl: './ADGRD0602ISU.component.html',
    styleUrls: ['./ADGRD0602ISU.component.css']
})
export class ADGRD0602ISUComponent implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    eduList?: GetEduList[];
    deptList?: GetDeptList[];
    classList?: GetClassList[];
    fileExchange = new FileExchange();
    baseUrl = environment.apiUrl;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    sTDStatusList?: GetSTDStatusList[];
    ngFormInput = new UntypedFormGroup({
        ReportType: new UntypedFormControl('C', [Validators.required]),
        Status: new UntypedFormControl('', [Validators.required]),
        Years: new UntypedFormControl(''),
        Term: new UntypedFormControl(''),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        ClassList: new UntypedFormControl(''),
        StdNoFrom: new UntypedFormControl(''),
        StdNoTo: new UntypedFormControl(''),
        Grad: new UntypedFormControl(false),
        Print: new UntypedFormControl('Y', [Validators.required])
    });

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.getYears();
        this.getSTDStatusList();
        this.getEduList();
    }

    clearall(status:string){
        this.ngFormInput.get('Status')?.setValue(status);
        this.ngFormInput.get('Years')?.setValue('');
        this.ngFormInput.get('EduNo')?.setValue('');
        this.ngFormInput.get('DeptNo')?.setValue('');
        this.ngFormInput.get('StdNoFrom')?.setValue('');
        this.ngFormInput.get('StdNoTo')?.setValue('');
        this.ngFormInput.get('Grad')?.setValue(false);
        this.eduList=[];
        this.deptList=[];
        this.classList=[];
        if(status=='退學' || status=='畢業'){
            this.getYears();
        }
        else{
            this.getEduList();
        }
    }

    getYears() {
        this.Years=[];
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getSTDStatusList() {
        this.systemService.getSTDStatusList().subscribe((response) => {
            this.sTDStatusList = response;
        })
    }

    getEduList() {
        this.eduList=[];
        this.deptList=[];
        this.classList=[];
        this.systemService.getEduList('1', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.eduList = response;
        })
    }

    getDeptList(edu: string) {
        this.deptList=[];
        this.classList=[];
        this.systemService.getDeptList(edu, '1', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = response;
        });
    }

    getClassList(Status: string,Eduno: string,Deptno: string) {
        this.classList=[];
        this.systemService.getClassListStdStatus(Status,this.ngFormInput.get('Years')?.value,'',Eduno,Deptno).subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classList = response;

        });
    }

    setAll(eventTarget: EventTarget | null) {
        let input = <HTMLInputElement>eventTarget;
        this.classList?.forEach(element => {
            input.checked == true ? element.checked = true : element.checked = false;
        });
    }

    export_Report_ADGRD0602() {

        if (!this.ngFormInput.valid && this.ngFormInput.get('StdNoFrom')?.value=="" && this.ngFormInput.get('StdNoTo')?.value=="") {
            this.toastr.info('請選擇欄位');
            return;
        }

        let classList = '';

        this.classList?.forEach(element => {
            if (element.checked == true) {
                classList += element.ItemValue + ','
            }
        });

        if(this.ngFormInput.get('StdNoFrom')?.value=='' && this.ngFormInput.get('StdNoTo')?.value==''){
            if (classList == '') {
                this.toastr.info('請勾選班級');
                return;
            }
        }

        this.ngFormInput.get('ClassList')?.setValue(classList.substring(0, classList.length - 1));

        this.fileExchangeServices.ExportFile("ACAD/GRD/ADGRD0602ISU/Export_Report_ADGRD0602", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

}
