<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">單位類別</span>
                </div>
                <select class="form-select shadow-none" #Unit_Style (change)="search(Unit_Style.value)">
                    <option value="">全部</option>
                    <option value="A">行政單位</option>
                    <option value="B">教學單位</option>
                </select>
            </div>
        </div>
        <div class="col-md-9">
            <button class="other-Button-gray float-end" (click)="reset()">清除</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="add-btn float-end me-3" (click)="insert()">新增</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>代碼</th>
                        <th>名稱</th>
                        <th>類別</th>
                        <th>排序</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" class="pointer"
                        (click)="searchDetail(i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.Unit_Code}}</td>
                        <td>{{item.Unit_Name}}</td>
                        <td>{{item.Unit_Style}}</td>
                        <td>{{item.Eorder}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <form [formGroup]="detail">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">單位代碼</span>
                            <input type="text" class="form-control shadow-none" maxlength="6" placeholder="至多六碼"
                                formControlName="Unit_Code">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">單位名稱</span>
                            <input type="text" class="form-control shadow-none" formControlName="Unit_Name">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">單位英文名稱</span>
                            <input type="text" class="form-control shadow-none" maxlength="60" formControlName="Unit_EngName">
                        </div>
                    </div>                    
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text">單位英文簡稱</span>
                            <input type="text" class="form-control shadow-none" maxlength="60" formControlName="Unit_EngShortName">
                        </div>
                    </div>                    
                </div>                
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">單位類型</span>
                            <select class="form-select shadow-none" formControlName="Unit_Style">
                                <option value="" disabled>--請選擇--</option>
                                <option value="A">行政單位</option>
                                <option value="B">教學單位</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">排序</span>
                            <input type="number" min="0" class="form-control shadow-none" formControlName="Eorder">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">上層單位代碼</span>
                            <input type="text" class="form-control shadow-none" maxlength="6" formControlName="Link_Unit">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改時間</span>
                            <input type="text" class="form-control shadow-none" formControlName="Update_Time">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <span class="input-group-text">修改者</span>
                            <input type="text" class="form-control shadow-none" formControlName="Update_User">
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>