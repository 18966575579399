<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select [(ngModel)]="search1.EduNo" class="form-select shadow-none" #EduNo>
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">狀態</span>
                <select [(ngModel)]="search1.Status" class="form-select shadow-none" #Status>
                    <option value="" selected disabled>--請選擇--</option>
                    <option value="1">啟用</option>
                    <option value="0">未啟用</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">關鍵字</span>
                <input [(ngModel)]="search1.Keyword" type="text" class="form-control shadow-none" #Keyword>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="other-Button-gray float-end me-3" (click)="clear()">清除畫面</button>
            <button class="other-Button-gray float-end me-3" (click)="delete()">刪除</button>
            <button class="other-Button-gray float-end me-3" (click)="update()">修改</button>
            <button class="other-Button-gray float-end me-3" (click)="insert()">新增</button>
            <button class="search-btn float-end me-3"
                (click)="search(EduNo.value, Status.value, Keyword.value)">查詢</button>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th style="width: 80px;">系所代碼</th>
                        <th>系所名稱</th>
                        <th style="width: 80px;">使用狀態</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        *ngFor="let item of data; let i=index" (click)="searchDetail(item.Dept_No, i)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td style="width: 80px;">{{item.Dept_No}}</td>
                        <td>{{item.Dept_Name}}</td>
                        <td style="width: 80px;">{{item.State_Log == '1'?'啟用':'未啟用'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">系所代碼</span>
                        <input type="text" class="form-control shadow-none" maxlength="3" placeholder="至多三碼"
                            [(ngModel)]="dataDetail.Dept_No">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">系所名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Dept_Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">系所簡稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Dept_Short">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">系所主任</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Manager_Name">
                        <input type="hidden" [(ngModel)]="dataDetail.Manager_Code">
                        <button class="btn btn-light shadow-none" (click)="openModal(template)">...</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">英文名稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Dept_EngName">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">英文簡稱</span>
                        <input type="text" class="form-control shadow-none" [(ngModel)]="dataDetail.Dept_EngShort">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">所屬學制</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Edu_No">
                            <option value="" selected disabled>--請選擇--</option>
                            <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">所屬學院</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Acad_No">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of academyList">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">日夜間別</span>
                        <select class="form-select" [(ngModel)]="dataDetail.DN_Mark">
                            <option value="">--請選擇--</option>
                            <option *ngFor="let item of DNMArkList" value="{{item.ItemValue}}">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">使用狀態</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.State_Log">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="1">啟用</option>
                            <option value="0">未啟用</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text">對應單位</span>
                        <select class="form-select shadow-none" [(ngModel)]="dataDetail.Unit_Code">
                            <option value="" selected disabled>--請選擇--</option>
                            <option value="{{item.ItemValue}}" *ngFor="let item of unitmyList">
                                {{item.ItemName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>            
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改日期</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_Time">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group mb-3">
                        <span class="input-group-text">修改者</span>
                        <input type="text" class="form-control shadow-none" disabled
                            [(ngModel)]="dataDetail.Update_User">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢教師</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group mb-3">
                    <span class="input-group-text">系所查詢</span>
                    <input type="text" class="form-control shadow-none" placeholder="輸入系所名稱" [(ngModel)]="selected"
                        [typeaheadScrollable]="true" [typeahead]="employeeList" typeaheadOptionField="Name"
                        [isAnimated]="true">
                </div>
            </div>
        </div>
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <th></th>
                    <th>教學單位</th>
                    <th>職稱</th>
                    <th>專兼任</th>
                    <th>教師代碼</th>
                    <th>教師姓名</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of employeeList; let i = index" class="pointer"
                    (click)="setTeacher(item.Code, item.Name)">
                    <td>{{i + 1}}</td>
                    <td>{{item.Unit_Name}}</td>
                    <td>{{item.Title_Name}}</td>
                    <td>{{item.Time_Case_Name}}</td>
                    <td>{{item.Code}}</td>
                    <td>{{item.Name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>