import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { environment } from 'src/assets/environments/environment';
import { GetTFSMakeDocRollbackList } from 'src/app/_Models/AD/ADTFS/ADTFS0502/getTFSMakeDocRollbackList';
import { GetTFSMakeDocRollbackSingleDetail } from 'src/app/_Models/AD/ADTFS/ADTFS0502/getTFSMakeDocRollbackSingleDetail';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADTFS0502',
  templateUrl: './ADTFS0502.component.html',
  styleUrls: ['./ADTFS0502.component.css']
})
export class ADTFS0502Component implements OnInit {

  Years!: Array<string>;
  sYears!: Array<string>;
  Term = require('src/app/_Models/_SelectInput/term.json').term;
  sTerm = require('src/app/_Models/_SelectInput/term.json').term;
  initialData = new InitialData(this.http);
  baseUrl = environment.apiUrl;

  deptList!: GetDeptList[];
  sDeptList!: GetDeptList[];

  makeDocList!: GetSelectList[];
  sMakeDocList!: GetSelectList[];

  eduList!: GetEduList[];
  sEduList!: GetEduList[];

  classList!: GetClassList[];
  sClassList!: GetClassList[];
  singleModalRef!: BsModalRef;

  tFSMakeDocRollbackList!: GetTFSMakeDocRollbackList[] | undefined;
  tFSMakeDocRollbackSingleDetail!: GetTFSMakeDocRollbackSingleDetail[] | undefined;

  feeUnitNo: Array<string> = [];
  dataKeyArray: Array<string> = [];

  constructor(private toastr: ToastrService,
    private systemService: SystemService,
    private http: HttpClient,
    private modalService: BsModalService,) { }

  ngOnInit(): void {
    this.getYears();
    this.getMakeDocList();
  }


  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
        this.sYears = response;
      }
    }, error => this.toastr.error(error.error));
  }

  getEduList(Years: string, Term: string, type: string) {
    this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      if (type == 'single') {
        this.sEduList = response;
      }
      else {
        this.eduList = response;
      }
    }, error => this.toastr.error(error.error))
  }

  getDeptList(years: string, term: string, eduNo: string, type: string) {
    this.systemService.getDeptList(eduNo, '1', years, term, 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      if (type == 'single') {
        this.sDeptList = response;
      }
      else {
        this.deptList = response;
      }

    }, error => this.toastr.error(error.error))
  }

  getClassList(Years: string, Term: string, edu: string, dept: string, type: string) {
    this.systemService.getClassList(edu, dept, '1', Years, Term, 'Y', '').subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      if (type == 'single') {
        this.sClassList = response;
      }
      else {
        this.classList = response;
      }
    }, error => this.toastr.error(error.error))

  }

  getMakeDocList() {
    this.systemService.getMakeDocList().subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.sMakeDocList = response;
      this.makeDocList = response;
    }, error => this.toastr.error(error.error))
  }

  opensingleModal(template: TemplateRef<any>) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };
    this.singleModalRef = this.modalService.show(template, modalConfig);
  }

  getTFSMakeDocRollbackList(Years: string, Term: string, EduNo: string, DeptNo: string, Class: string, MakeDocNo: string) {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0502/Get_TFS_MakeDoc_RollbackList", {
      DataKey: '',
      Years: Years,
      Term: Term,
      EduNo: EduNo,
      DeptNo: DeptNo,
      Class: Class,
      StdNo: '',
      EntryID: '',
      MakeDocNo: MakeDocNo,
      MakeDocStatus: '',
      UnitNo: '01',
      RedClassNo: ''
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocRollbackList = []
        return;
      }
      this.tFSMakeDocRollbackList = response;

      if (this.tFSMakeDocRollbackList) {
        for (let index = 0; index < this.tFSMakeDocRollbackList.length; index++) {
          this.tFSMakeDocRollbackList[index].checked = false;
        }
      }
    }, error => this.toastr.error(error.error))
  }

  getTFSMakeDocRollbackSingleDetail(index: number, dataKey: string) {

    if (this.tFSMakeDocRollbackList) {
      this.tFSMakeDocRollbackList.forEach(element => {
        element.Selected = false;
      })
      this.tFSMakeDocRollbackList[index].Selected = true;
    }


    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0502/Get_TFS_MakeDoc_Rollback_SingleDetail", { DataKey: dataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocRollbackSingleDetail = undefined;
        return;
      }
      this.tFSMakeDocRollbackSingleDetail = response;
    }, error => this.toastr.error(error.error))
  }

  setFeeUnitNo(feeUnitNo: string, event: any) {
    let input = <HTMLInputElement>event;
    let index = this.feeUnitNo.indexOf(feeUnitNo);
    if (input.checked == false && index != -1) {
      this.feeUnitNo.splice(index, 1);
    }
    else if (input.checked == true && index == -1) {
      this.feeUnitNo.push(feeUnitNo);
    }
    else {
      this.toastr.warning("出現錯誤，請重新整理頁面");
    }
  }

  setDatakey(type: string, dataKey: string, event: any) {
    let input = <HTMLInputElement>event;
    if (type == 'all') {
      if (this.tFSMakeDocRollbackList) {
        if (this.dataKeyArray.length != this.tFSMakeDocRollbackList.length && input.checked == true) {
          this.dataKeyArray = [];
          this.tFSMakeDocRollbackList.forEach(element => {
            this.dataKeyArray.push(element.DataKey);
            element.checked = true;
          })
        }
        else {
          this.dataKeyArray = [];
          this.tFSMakeDocRollbackList.forEach(element => {
            element.checked = false;
          })
        }
      }
    }
    else {
      let index = this.dataKeyArray.indexOf(dataKey);
      if (index == -1) {
        this.dataKeyArray.push(dataKey);
      }
      else {
        this.dataKeyArray.splice(index, 1);
      }
    }
  }

  getTFSMakeDocRollback() {

    if (this.feeUnitNo.length == 0) {
      Swal.fire({
        title: '請選擇單位',
        confirmButtonText: '確定',
      })
      return;
    }

    if (this.dataKeyArray.length == 0) {
      this.toastr.info("請先選擇學生");
      return;
    }

    let feeNo = '';
    let dataKey = '';

    for (let index = 0; index < this.feeUnitNo.length; index++) {
      feeNo += this.feeUnitNo[index] + ',';
    }

    for (let index = 0; index < this.dataKeyArray.length; index++) {
      dataKey += this.dataKeyArray[index] + ',';
    }

    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0502/Get_TFS_MakeDoc_Rollback", {
      DataKey: '',
      Years: '',
      Term: '',
      EduNo: '',
      DeptNo: '',
      Class: '',
      StdNo: dataKey.substring(0, dataKey.length - 1),
      EntryID: '',
      MakeDocNo: '',
      MakeDocStatus: '',
      UnitNo: feeNo.substring(0, dataKey.length - 1),
      RedClassNo: ''
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("退單成功");
      this.tFSMakeDocRollbackList = undefined;
      this.tFSMakeDocRollbackSingleDetail = undefined;
      this.dataKeyArray = [];
      this.feeUnitNo = [];
    })
  }
}
