import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetEduList } from 'src/app/_Models/getEduList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0204',
    templateUrl: './ADSEL0204.component.html',
    styleUrls: ['./ADSEL0204.component.css']
})
export class ADSEL0204Component implements OnInit {
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    term = require('src/app/_Models/_SelectInput/term.json').term;
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', [Validators.required]),
        Term: new UntypedFormControl('', [Validators.required]),
        EduNo: new UntypedFormControl('', [Validators.required]),
        Type: new UntypedFormControl('', [Validators.required]),
        CheckNextYear: new UntypedFormControl(false)
    })
    constructor(private toastr: ToastrService, private http: HttpClient, private systemService: SystemService) { }

    ngOnInit(): void {
        this.getYears();
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList(Years: string, Term: string) {

        if (Years == '' || Term == '') {
            return;
        }

        this.systemService.getEduList('', Years, Term, 'Y').subscribe((response) => {
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getSELRegiImport() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0204/Get_SEL_Regi_Import", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("匯出成功");
        })
    }

}
