import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GetSAABS } from 'src/app/_Models/STDWEB/STDW60002/getSAABS';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW60002',
    templateUrl: './STDW60002.component.html',
    styleUrls: ['./STDW60002.component.css']
})
export class STDW60002Component implements OnInit {

    baseUrl = environment.apiUrl;
    data!: GetSAABS[];
    stdInfo!: string;

    constructor(private http: HttpClient,
        private accountService: AccountService,
        private toastr: ToastrService,
        private timeService: TimeService, private systemService: SystemService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                return this.stdInfo = response.Code + ' ' + response.UserName;
            }
            return "";
        });
        this.timeService.getTimeCntr().subscribe((response) => {
            this.getRecord({ Years: response.Now_Years, Term: response.Now_Term });
        })
    }

    getRecord(model: any) {
        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW600_02/Search", model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = [];
                return;
            }

            this.data = response;

        });
    }

}
