import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    user!: string;

    token!: string;

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        this.user = sessionStorage.getItem('auth') || '';
        if (this.user != '') {
            // 把Header加上Authorization以供傳到後端驗證。
            request = request.clone({
               
                setHeaders: { Authorization: `Bearer ${JSON.parse(this.user).Token}` }
            });
        }

        // 如此一來每次的Request都可以進行驗證。
        return next.handle(request);
    }
}
