import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0103/search';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0103/searchDetail';
import { GetEmployeeList } from 'src/app/_Models/getEmployeeList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADRGP0103',
  templateUrl: './ADRGP0103.component.html',
  styleUrls: ['./ADRGP0103.component.css']
})
export class ADRGP0103Component implements OnInit {
  selected?: string;
  modalRef?: BsModalRef;
  baseUrl = environment.apiUrl;
  data!: Search[];
  dataDetail: SearchDetail = {
    Acad_No: '',
    Acad_Name: '',
    Acad_EngName: '',
    Acad_Short: '',
    Master_Code: '',
    Master_Name: '',
    State_Log: '',
    Update_User: '',
    Update_Time: ''
  };
  employeeList!: GetEmployeeList[];
  constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService, private systemService: SystemService,) { }

  ngOnInit(): void {
    this.search();
  }


  search() {
    this.http.get<any>(this.baseUrl + "ACAD/RGP/ADRGP0103/Search").subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.data = response;
      this.data.forEach(element => {
        element.Selected = false;
      });
    }, error => this.toastr.error(error.error))
  }

  searchDetail(Acad_No: string, index: number) {
    this.data.forEach(element => {
      element.Selected = false;
    });
    this.data[index].Selected = true;
    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0103/Search_Detail", { Acad_No: Acad_No }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.dataDetail = response;
      this.dataDetail.Acad_EngName = this.dataDetail.Acad_EngName;
      this.dataDetail.Acad_Name = this.dataDetail.Acad_Name;
      this.dataDetail.Master_Name = this.dataDetail.Master_Name;
      this.dataDetail.Update_Time = this.dataDetail.Update_Time;
      this.dataDetail.Update_User = this.dataDetail.Update_User;
    }, error => this.toastr.error(error.error))
  }

  insert() {
    if (this.dataDetail.Acad_No == '' || this.dataDetail.Acad_EngName == '' || this.dataDetail.Master_Name == '' || this.dataDetail.Acad_Name == '' || this.dataDetail.State_Log == '') {
      this.toastr.info("請填寫各項資料");
      return;
    }

    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0103/Insert", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("新增成功");
      this.search();
    }, error => this.toastr.error(error.error))
  }

  update() {
    if (this.dataDetail.Acad_No == '' || this.dataDetail.Acad_EngName == '' || this.dataDetail.Master_Name == '' || this.dataDetail.Acad_Name == '' || this.dataDetail.State_Log == '') {
      this.toastr.info("請填寫各項資料");
      return;
    }
    this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0103/Update", this.dataDetail).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("修改成功");
      this.search();
    }, error => this.toastr.error(error.error))
  }

  delete() {
    if (this.dataDetail.Acad_No == '') {
      this.toastr.info("請選擇要刪除的學院");
      return;
    }

    Swal.fire({
      title: "確定刪除？",
      showCancelButton: true,
      cancelButtonText: "取消",
      confirmButtonText: "確定"
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0103/Delete", this.dataDetail).subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          this.toastr.success("刪除成功");
          this.search();
          this.clear();
        }, error => this.toastr.error(error.error))
      }
      else {
        this.toastr.info("動作取消");
      }
    })


  }

  clear() {
    this.dataDetail = {
      Acad_No: '',
      Acad_Name: '',
      Acad_EngName: '',
      Acad_Short: '',
      Master_Code: '',
      Master_Name: '',
      State_Log: '',
      Update_User: '',
      Update_Time: ''
    };
  }

  getEmployeeList() {
    this.systemService.getEmployeeList().subscribe((response) => {
      this.employeeList = response;
    })
  }

  openModal(template: TemplateRef<any>) {
    this.getEmployeeList();
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    };
    this.modalRef = this.modalService.show(template, modalConfig);
  }

  setTeacher(code: string, name: string) {
    this.dataDetail.Master_Code = code;
    this.dataDetail.Master_Name = name;
    this.modalRef?.hide();
  }

}
