<div class="container">
    <div class="row">
        <div class="col-12 col-md-3 mb-3" *ngFor="let item of funcList">
            <div class="card">
                <div class="card-header">
                    {{item.FunName}}
                </div>
                <div class="card-body">
                    <ul class="list-group list-group-flush">
                        <a [routerLink]="item.HyperLink" class="list-group-item list-group-item-action fw-bolder"
                            *ngFor="let item of item.SubMenu">{{item.FunName}}</a>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>