import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { HttpClient } from '@angular/common/http';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { GetStdKeepList } from 'src/app/_Models/AD/ADSTD/ADSTD0101/getStdKeepList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetEduList } from 'src/app/_Models/getEduList';

@Component({
  selector: 'app-ADSTD0101ISU',
  templateUrl: './ADSTD0101ISU.component.html',
  styleUrls: ['./ADSTD0101ISU.component.css']
})
export class ADSTD0101ISUComponent implements OnInit {
  modalRef!: BsModalRef;
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  baseUrl = environment.apiUrl;
  initialData = new InitialData(this.http);
  eduList!: GetEduList[];
  stdKeepList!: GetStdKeepList[];
  datakeyArray: Array<string> = [];
  model = {
    Years: '',
    Term: '',
    EduNo: '',
    Approve: ''
  }
  newStdKeepData={
    DataKey: '',
    Years: '',
    Term: '',
    EndYear: '',
    EndTerm: '',
    StdNo: '',
    StdName: '',
    ClassName: '',
    Status: '',
    KeepNo: '',
    KeepName: '',
    KeepMoeChar:'',
    KeepMoe: '',
    ApplyDate: '',
    ApproveDate: '',
    Approve: '',
    Recover: '',
    RecoverYear: '',
    RecoverTerm: '',
    RecoverDate: '',
    Delay: '',
    Memo: '',
    quota:'',
    KeepDate:'',
    KeepDateStart:'',
    KeepDateEnd:''
  };
  keepReasonList!: GetSelectList[];
  
  constructor(private toastr: ToastrService,
    private systemService: SystemService,
    private modalService: BsModalService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.getYears();
    this.getEduList();
    this.getKeepReason();
  }

  getEduList() {
    this.systemService.getEduList('', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
        if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
        }
        this.eduList = response;
    }, error => this.toastr.error(error.error))
  }

  getKeepReason() {
    this.systemService.getKeepReason().subscribe((response) => {
      this.keepReasonList = response;
    })
  }

  getStdKeepList() {
    this.datakeyArray = [];
    this.stdKeepList = [];
    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_01ISU/Get_Std_KeepList", this.model).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.stdKeepList = []
        return;
      }
      this.stdKeepList = response;
    });
  }

  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    });
  }

  openModel(template: TemplateRef<any>,DataIndex:number) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };

    this.newStdKeepData = {
      DataKey: (DataIndex==-1)?'':this.stdKeepList[DataIndex].DataKey,
      Years: (DataIndex==-1)?'':this.stdKeepList[DataIndex].Years,
      Term: (DataIndex==-1)?'':this.stdKeepList[DataIndex].Term,
      EndYear: (DataIndex==-1)?'':this.stdKeepList[DataIndex].EndYear,
      EndTerm: (DataIndex==-1)?'':this.stdKeepList[DataIndex].EndTerm,
      StdNo: (DataIndex==-1)?'':this.stdKeepList[DataIndex].StdNo,
      StdName: (DataIndex==-1)?'':this.stdKeepList[DataIndex].StdName,
      ClassName: (DataIndex==-1)?'':this.stdKeepList[DataIndex].ClassName,
      Status: (DataIndex==-1)?'':this.stdKeepList[DataIndex].Status,
      KeepNo: (DataIndex==-1)?'':this.stdKeepList[DataIndex].KeepNo,
      KeepName: (DataIndex==-1)?'':this.stdKeepList[DataIndex].KeepName,
      KeepMoeChar: (DataIndex==-1)?'':this.stdKeepList[DataIndex].KeepMoeChar,
      KeepMoe: (DataIndex==-1)?'':this.stdKeepList[DataIndex].KeepMoe,
      ApplyDate: (DataIndex==-1)?'':this.stdKeepList[DataIndex].ApplyDate,
      ApproveDate: (DataIndex==-1)?'':this.stdKeepList[DataIndex].ApproveDate,
      Approve: (DataIndex==-1)?'':this.stdKeepList[DataIndex].Approve,
      Recover: (DataIndex==-1)?'':this.stdKeepList[DataIndex].Recover,
      RecoverYear: (DataIndex==-1)?'':this.stdKeepList[DataIndex].RecoverYear,
      RecoverTerm: (DataIndex==-1)?'':this.stdKeepList[DataIndex].RecoverTerm,
      RecoverDate: (DataIndex==-1)?'':this.stdKeepList[DataIndex].RecoverDate,
      Delay: (DataIndex==-1)?'':this.stdKeepList[DataIndex].Delay,
      Memo: (DataIndex==-1)?'':this.stdKeepList[DataIndex].Memo,
      quota:(DataIndex==-1)?'':((this.stdKeepList[DataIndex].quota)?'Y':'N'),
      KeepDate: (DataIndex==-1)?'':this.stdKeepList[DataIndex].KeepDate,
      KeepDateStart: (DataIndex==-1)?'':this.stdKeepList[DataIndex].KeepDateStart,
      KeepDateEnd: (DataIndex==-1)?'':this.stdKeepList[DataIndex].KeepDateEnd
    };

    this.modalRef = this.modalService.show(template, modalConfig);
  }

  deleteData() {
    let str = '';
    this.datakeyArray.forEach(element => {
      str += element + ','
    });
    let datakey = str.substring(0, str.length - 1);

    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_01ISU/Get_Std_Keep_Delete", { DataKey: datakey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("刪除成功");
      this.datakeyArray = [];
      this.getStdKeepList();
    })
  }

  newData() {
    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_01ISU/Get_Std_Keep_Save", this.newStdKeepData).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
    });
    this.modalRef.hide();
    this.toastr.success(((this.newStdKeepData.DataKey=="")?"新增":"儲存")+"成功");
    this.getStdKeepList();
  }

  setDataKey(dataKey: string) {

    let index = this.datakeyArray.indexOf(dataKey);
    if (index == -1) {
      this.datakeyArray.push(dataKey);
    }
    else {
      this.datakeyArray.splice(index, 1);
    }

  }

}
