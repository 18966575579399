<div class="container" style="margin-bottom: 100px;">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">{{"學制"|translate}}
                    </span>
                <select [(ngModel)]="search1.EduNo" class="form-select shadow-none" #EduNo
                    (change)="getDeptList(EduNo.value, false)">
                    <option value="" selected disabled>{{"--請選擇--"|translate}}
                    </option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">{{"系所"|translate}}
                    </span>
                <select [(ngModel)]="search1.DeptNo" class="form-select shadow-none" #DeptNo
                    (change)="getTeamList(DeptNo.value, false)">
                    <option value="" selected>{{"--全部--"|translate}}
                    </option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">{{"組別"|translate}}
                    </span>
                <select [(ngModel)]="search1.TeamNo" class="form-select shadow-none" #TeamNo>
                    <option value="" selected>{{"--全部--"|translate}}
                    </option>
                    <option *ngFor="let item of teamList" value="{{item.Team_No}}">{{item.Team_Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-12">
            <button class="other-Button-gray float-end" (click)="clear()">{{"清除"|translate}}
            </button>
            <button class="other-Button-gray float-end me-3" (click)="submit.click()">{{"儲存"|translate}}
            </button>
            <button class="search-btn float-end me-3"
                (click)="getSELCreditsLimitList(EduNo.value, DeptNo.value, TeamNo.value)">{{"查詢"|translate}}
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th></th>
                        <th>{{"系所代碼"|translate}}
                        </th>
                        <th>{{"系所名稱"|translate}}
                        </th>
                        <th>{{"組別代碼"|translate}}
                        </th>
                        <th>{{"組別名稱"|translate}}
                        </th>
                        <th>{{"年級"|translate}}
                        </th>
                        <th>{{"上限"|translate}}
                        </th>
                        <th>{{"下限"|translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" *ngFor="let item of sELCreditsLimitList; let i = index"
                        [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}"
                        (click)="getSELCreditsLimitDetail(item.DataKey, i)">
                        <td>{{i + 1}}</td>
                        <td>{{item.DeptNo}}</td>
                        <td>{{item.DeptName}}</td>
                        <td>{{item.TeamNo}}</td>
                        <td>{{item.TeamName}}</td>
                        <td>{{item.Grade}}</td>
                        <td>{{item.CreditMax}}</td>
                        <td>{{item.CreditMin}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-4">
            <form [formGroup]="ngForm" (ngSubmit)="getSELCreditsLimitSave()">
                <button [hidden]="true" #submit></button>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"學制"|translate}}
                                </span>
                            <select class="form-select shadow-none" #dEduNo (ngModelChange)="getDeptList($event, true)"
                                formControlName="EduNo">
                                <option value="" selected disabled>{{"--請選擇--"|translate}}
                                </option>
                                <option *ngFor="let item of detailEduList" ngValue="{{item.Edu_No}}">
                                    {{item.Edu_Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"系所"|translate}}
                                </span>
                            <select class="form-select shadow-none" #dDeptNo (ngModelChange)="getTeamList($event, true)"
                                formControlName="DeptNo">
                                <option value="" selected>{{"--請選擇--"|translate}}
                                </option>
                                <option *ngFor="let item of detailDeptList" ngValue="{{item.Dept_No}}">
                                    {{item.Dept_Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"組別"|translate}}
                                </span>
                            <select class="form-select shadow-none" formControlName="TeamNo">
                                <option value="" selected>{{"--請選擇--"|translate}}
                                </option>
                                <option *ngFor="let item of detailTeamList" ngValue="{{item.Team_No}}">
                                    {{item.Team_Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"年級"|translate}}
                                </span>
                            <select class="form-select shadow-none" formControlName="Grade">
                                <option value="" selected disabled>{{"--請選擇--"|translate}}
                                </option>
                                <option value="{{item.itemValue}}" *ngFor="let item of grade">{{item.itemName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"學分上限"|translate}}
                                </span>
                            <input type="number" class="form-control shadow-none" min="0" formControlName="CreditMax">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"學分下限"|translate}}
                                </span>
                            <input type="number" class="form-control shadow-none" min="0" formControlName="CreditMin">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"抵免後學分下限"|translate}}
                                </span>
                            <input type="number" class="form-control shadow-none" min="0"
                                formControlName="CedCreditMin">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"交換生上限"|translate}}
                                </span>
                            <input type="number" class="form-control shadow-none" min="0"
                                formControlName="OtherCreditMax">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"交換生下限"|translate}}
                                </span>
                            <input type="number" class="form-control shadow-none" min="0"
                                formControlName="OtherCreditMin">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"修改者"|translate}}
                                </span>
                            <input type="text" class="form-control shadow-none" formControlName="UpdateUser">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group flex-nowrap mb-3">
                                <span class="input-group-text">{{"修改時間"|translate}}
                                </span>
                            <input type="text" class="form-control shadow-none" formControlName="UpdateTime">
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>