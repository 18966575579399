import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-STDW10002',
  templateUrl: './STDW10002.component.html',
  styleUrls: ['./STDW10002.component.css']
})
export class STDW10002Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
