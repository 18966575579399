import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetStdContrastReMark } from 'src/app/_Models/AD/ADSTD/ADSTD0602/getStdContrastReMark';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ADSTD0602',
  templateUrl: './ADSTD0602.component.html',
  styleUrls: ['./ADSTD0602.component.css']
})
export class ADSTD0602Component implements OnInit {
  stdContrastReMark: GetStdContrastReMark = {
    StdData: [],
    YearScoreData: [],
    CurPlanData: [],
    ReMarkData: []
  };
  model = {
    YearScoreDataKey: '',
    CurPlanDataKey: ''
  }
  stdNo = '';
  baseUrl = environment.apiUrl;
  dataKeyArray: Array<string> = [];
  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private systemService: SystemService,
  ) { }

  ngOnInit(): void {
  }


  getStdContrastReMark() {
    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_02/Get_Std_Contrast_ReMark", { StdNo: this.stdNo }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      if (response.StdData.length == 0) {
        this.toastr.warning("該學生尚未做畢審");
        return;
      }
      this.stdContrastReMark = response;
      this.stdContrastReMark.CurPlanData.forEach(element => {
        element.Selected = false;
      });
      this.stdContrastReMark.YearScoreData.forEach(element => {
        element.Selected = false;
      });
    }, error => this.toastr.error(error.error));
  }

  setModel(type: string, Datakey: string, index: number) {
    if (type == 'CurPlanDataKey') {
      this.model.CurPlanDataKey = Datakey;
      this.stdContrastReMark.CurPlanData.forEach(element => {
        element.Selected = false;
      });
      this.stdContrastReMark.CurPlanData[index].Selected = true;
      return;
    }
    this.model.YearScoreDataKey = Datakey;
    this.stdContrastReMark.YearScoreData.forEach(element => {
      element.Selected = false;
    });
    this.stdContrastReMark.YearScoreData[index].Selected = true;
  }

  getStdContrastReMarkAdd() {

    if (this.model.CurPlanDataKey == '' || this.model.YearScoreDataKey == '') {
      this.toastr.info("請選擇替代項目");
      return;
    }

    console.log(this.model);

    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_02/Get_Std_Contrast_ReMarkAdd", this.model).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.toastr.success("處理完成");
      this.getStdContrastReMark();
    }, error => this.toastr.error(error.error))
  }

  setDatakey(type: string, dataKey: string, event: any) {
    if (!this.stdContrastReMark.ReMarkData) {
      return;
    }
    let input = <HTMLInputElement>event;
    if (type == 'all') {
      if (this.dataKeyArray.length != this.stdContrastReMark.ReMarkData.length && input.checked == true) {
        this.dataKeyArray = [];
        this.stdContrastReMark.ReMarkData.forEach(element => {
          this.dataKeyArray.push(element.DataKey);
          element.checked = true;
        })
      }
      else {
        this.dataKeyArray = [];
        this.stdContrastReMark.ReMarkData.forEach(element => {
          element.checked = false;
        })
      }
    }
    else {
      let index = this.dataKeyArray.indexOf(dataKey);
      if (index == -1) {
        this.dataKeyArray.push(dataKey);
      }
      else {
        this.dataKeyArray.splice(index, 1);
      }
    }
  }

  getStdContrastReMarkDelete() {
    if (this.dataKeyArray.length == 0) {
      this.toastr.info("請先選擇學生");
      return;
    }

    Swal.fire({
      title: `確定設定此${this.dataKeyArray.length}位學生?`,
      showDenyButton: true,
      confirmButtonText: '是',
      denyButtonText: '否',
    }).then(result => {
      if (result.isConfirmed) {
        let dataKey = '';
        this.dataKeyArray.forEach(element => {
          dataKey = dataKey + element + ',';
        });

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD06_02/Get_Std_Contrast_ReMarkDelete", { DataKey: dataKey.substring(0, dataKey.length - 1) }).subscribe((response) => {
          if (response.Info == false) {
            this.toastr.warning(response.Message);
            return;
          }
          this.toastr.success("設定完成");
          this.dataKeyArray = [];
        }, error => this.toastr.error(error.error))
      }
      else {
        this.toastr.info("動作已取消");
      }
    })
  }

}
