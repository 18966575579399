import { Component, Input, OnInit, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { ConvertToFormControl } from 'src/app/_SharedFunctions/convertToFormControl';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.css']
})
export class InputTextComponent implements ControlValueAccessor {
    private ConvertToFormControl = new ConvertToFormControl();
    @Input() placeholder!: '';
    @Input() type!: 'text'
    @Input() label!: ''
    constructor(@Self() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }
    writeValue(obj: any): void { }
    registerOnChange(fn: any): void { }
    registerOnTouched(fn: any): void { }

    convertToFormControl(absCtrl: AbstractControl | null): UntypedFormControl {
        return this.ConvertToFormControl.convertToFormControl(absCtrl) as UntypedFormControl;
    }

}
