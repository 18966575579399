<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" required #Year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" #Term>
                    <option value="">全部</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">審核狀態</span>
                <select class="form-select shadow-none">
                    <option value="">全部</option>
                    <option value="1">簽核中</option>
                    <option value="3">同意</option>
                    <option value="2">不同意</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #eduNo [(ngModel)]="input.EduNo"
                    (change)="getDeptList(eduNo.value)">
                    <option value="" selected>全部</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" [(ngModel)]="input.DeptNo" #deptNo
                    (change)="getClassList(eduNo.value, deptNo.value)">
                    <option value="" selected>全部</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班級</span>
                <select class="form-select shadow-none" [(ngModel)]="input.Class">
                    <option value="" selected>全部</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none" [(ngModel)]="input.StdNo">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">申請項目</span>
                <select class="form-select shadow-none"   #ApplyNo [(ngModel)]="input.ApplyNo">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let a of getApplyData" value="{{a.ItemValue}}">{{a.ItemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-md">
            <button class="search-btn float-start me-3" (click)="search()">查詢</button>
            <button class="other-Button-gray float-end me-3" (click)="openModal(template)">
                申請
            </button>
            <button class="other-Button-gray float-end me-3" (click)="delete()" *ngIf="check">刪除 </button>
            <button class="other-Button-gray float-end me-3" *ngIf="check" (click)="exportAll()">匯出檔案</button>
            <button class="other-Button-gray float-end me-3" (click)="update()" *ngIf="check">同意</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th><input type="checkbox" class="pointer" [(ngModel)]="checkall" (change)="allchange()">選擇</th>
                <th>學年</th>
                <th>學期</th>
                <th>學號</th>
                <th>姓名</th>
                <th>班級</th>
                <th>申請項目</th>
                <th>審核狀態</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let y of this.getdata">
                <td data-th="選擇">
                    <input type="checkbox" class="pointer" [(ngModel)]="y.Checked" (change)="checkchange()">
                </td>
                <td>{{y.Years}}</td>
                <td>{{y.Term}}</td>
                <td>{{y.StdNo}}</td>
                <td>{{y.StdName}}</td>
                <td>{{y.ClassName}}</td>
                <td>{{y.ApplyName}}</td>
                <td>{{y.ChkStatusName}}</td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">申請</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">申請項目</span>
                    <select class="form-select shadow-none" required #ApplyNo>
                        <option *ngFor="let a of getApplyData" [value]="a.ItemValue">{{a.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">學號</span>
                    <input type="text" class="form-control shadow-none" #StdNo>
                </div>
            </div>
            <div class="col-md-4">
                <button class="search-btn float-start me-3"
                    (click)="searchdetail(ApplyNo.value,StdNo.value)">查詢</button>
            </div>
        </div>
        <div class="row mt-3s">
            <table class="table table-hover table-striped text-center mt-3 table-rwd">
                <thead class="thead-dark-red table-rwd-hide">
                    <tr>
                        <th>學年</th>
                        <th>學期</th>
                        <th>學號</th>
                        <th>按鈕功能</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let y of  getdetaildata let i=index">
                        <td data-th="學年">{{y.Years}}</td>
                        <td data-th="學期">{{y.Term}}</td>
                        <td data-th="學號">{{y.StdNo}}</td>
                        <td data-th="按鈕功能">
                            <button *ngIf="!y.NeedSend" class="other-Button-gray" (click)="export(i)">列印</button>
                            <button *ngIf="y.NeedSend" class="send-btn" (click)="insert(i)">送出</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>