import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { GetCEECFiledsList} from 'src/app/_Models/AD/ADSTD/ADSTD0103/getFieldsList';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-ADSTD0103',
  templateUrl: './ADSTD0103.component.html',
  styleUrls: ['./ADSTD0103.component.css']
})
export class ADSTD0103Component implements OnInit {
  fileExchange = new FileExchange();
  Years!: Array<string>;
  baseUrl = environment.apiUrl;
  modalRef!: BsModalRef;
  initialData = new InitialData(this.http);
  term = require('src/app/_Models/_SelectInput/term.json').term;
  FieldsList!:GetCEECFiledsList[];
  json = {
    FileBase64: '',
    Years: '',
    Term: '',
    EntryYear: '',
    EntryMonth: '',
    EntryDay: '',
    SheetName: ''
  }

  constructor(private toastr: ToastrService,
    private http: HttpClient,
    private modalService: BsModalService,
    private fileExchangeServices: FileExchangeService,) { }

  ngOnInit(): void {
    this.getYears();
  }

  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
      }
    });
  }

  importDataClick(Year: string, Term: string, date: string, SheetName: string) {

    if (Year == '' || Term == '' || date == '' || SheetName == '') {
      this.toastr.info("請先填寫各項資料");
      return;
    }

    let enterDate = date.split('-');
    this.json.Years = Year;
    this.json.Term = Term;
    this.json.SheetName = SheetName;
    this.json.EntryYear = enterDate[0];
    this.json.EntryMonth = enterDate[1];
    this.json.EntryDay = enterDate[2];
    document.getElementById("file")?.click();
  }

  importData($event: any, dataKey: string, type: string) {
    let file = $event.target.files[0];
    if (!file) return;
    this.fileExchange.FileToBase64(this, file, this.GetBase64, dataKey, type)
  }

  GetBase64(that: any, base64: string, file: any, dataKey: string) {
    that.json.FileBase64 = base64;
    Swal.fire({
      title: '確定上傳 ' + file.name + ' ?',
      html:
        `入學學年： ${that.json.Years}學年<br/>` +
        `入學日期： ${that.json.EntryYear}年${that.json.EntryMonth}月${that.json.EntryDay}日<br/>` +
        `匯入的工作表： ${that.json.SheetName}`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: '是',
      denyButtonText: '否',
    }).then((result) => {
      if (result.isConfirmed) {
        that.fileExchangeServices.ImportFile('ACAD/STD/ADSTD01_03/Import_NewStdFile_Base64', that.json)
          .subscribe((response: any) => {
            if (response.Info == true) { that.toastr.success("上傳成功!!"); }
            else { that.toastr.error(response.Message, "執行錯誤!"); }
          });
      }
      else if (result.isDenied) {
        document.getElementById(dataKey)?.setAttribute('value', '');
        that.toastr.info("已取消上傳。");
      }
    });
  }

  exportTemplate() {
    this.http.get<any>(this.baseUrl + "ACAD/STD/ADSTD01_03/Export_NewStdFile_Base64").pipe(
      map((file: any) => {
        let contentType = "application/octet-stream"
        let blob = this.fileExchange.Base64ToBlob(file.FileBase64, contentType, 512);
        return {
          blob: blob
        }
      })
    ).subscribe((response) => {
      let url = window.URL.createObjectURL(response.blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = "新生資料匯入格式.xls";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  getFieldsList() {
    this.FieldsList = [];
    this.http.get<any>(this.baseUrl + "ACAD/STD/ADSTD01_03/Get_NewStdFile_CEEC_Fields").subscribe((response) => {
        if (response.Info == false) {
            this.toastr.warning(response.Message);
            this.FieldsList = []
            return;
        }
        this.FieldsList = response;
    }, error => this.toastr.error(error.error))
  }

  openModal(template: TemplateRef<any>) {
    let modalConfig!: any;

    modalConfig = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-lg'
    };

    this.modalRef = this.modalService.show(template, modalConfig);
    this.getFieldsList();
  }

  ReSetPoint(): void {
    let spoint=0;
    let epoint=0;
    let fieldlength=0;
    let addtimes=1;

    this.FieldsList.forEach(element => {
      fieldlength=element.Field_Length;

      if(fieldlength>0){
        if(spoint==0){
          spoint=1;
        }
        else{
          spoint=epoint+1;
        }

        epoint=spoint;
        epoint+=fieldlength-1;

        element.S_Point=spoint;
        element.E_Point=epoint;
      }
      addtimes+=1;
    })
  }

  SaveFields(){
    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD01_03/Get_NewStdFile_CEEC_Fields_Save", this.FieldsList).subscribe((response) => {
      if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
      }
      this.toastr.success("儲存完成");
    })
  }

  importCEECClick(Year: string, Term: string, date: string){
    if (Year == '' || Term == '' || date == '') {
      this.toastr.info("請先填寫各項資料");
      return;
    }

    let enterDate = date.split('-');
    this.json.Years = Year;
    this.json.Term = Term;
    this.json.EntryYear = enterDate[0];
    this.json.EntryMonth = enterDate[1];
    this.json.EntryDay = enterDate[2];
    document.getElementById("ceecfile")?.click();
  }

  importCEECData($event: any, dataKey: string, type: string){
    let file = $event.target.files[0];
    if (!file) return;
    this.fileExchange.FileToBase64(this, file, this.GetBase64CEEC, dataKey, type)
  }

  GetBase64CEEC(that: any, base64: string, file: any, dataKey: string) {
    that.json.FileBase64 = base64;
    Swal.fire({
      title: '確定上傳 ' + file.name + ' ?',
      html:
        `入學學年： ${that.json.Years}學年<br/>` +
        `入學日期： ${that.json.EntryYear}年${that.json.EntryMonth}月${that.json.EntryDay}日<br/>`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: '是',
      denyButtonText: '否',
    }).then((result) => {
      if (result.isConfirmed) {
        that.fileExchangeServices.ImportFile('ACAD/STD/ADSTD01_03/Import_NewStdFile_CEEC_Base64', that.json)
          .subscribe((response: any) => {
            if (response.Info == true) { that.toastr.success("上傳成功!!"); }
            else {
              document.getElementById(dataKey)?.setAttribute('value', '');
              that.toastr.error(response.Message, "執行錯誤!");
            }
          });
      }
      else if (result.isDenied) {
        document.getElementById(dataKey)?.setAttribute('value', '');
        that.toastr.info("已取消上傳。");
      }
    });
  }
}
