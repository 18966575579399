import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TeachingOutLine } from 'src/app/_Models/TCHWEB/TCH0101/getTeachingOutLineDeatail';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-TCH0101-detail',
    templateUrl: './TCH0101-detail.component.html',
    styleUrls: ['./TCH0101-detail.component.css']
})
export class TCH0101DetailComponent implements OnInit {
    dataKey: string = '';
    baseUrl = environment.apiUrl;
    data!: TeachingOutLine;
    isTeacher = true;
    checkType = new CheckType()
    constructor(private route: ActivatedRoute,
        private toastr: ToastrService,
        private http: HttpClient,
        private accountService: AccountService,
        private router: Router, private systemService: SystemService, private translateService: TranslateService) { }


    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.dataKey = (this.route.snapshot.paramMap.get('id') || '');
        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                if (response.Type == '1') {
                    this.isTeacher = true;
                    return;
                }
            }
            this.isTeacher = false;
        })
        this.getData();
    }

    getData() {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_01/Search_Detail", { DataKey: this.dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response[0];
        })
    }

    setAttributesCheck(index: number) {
        if (this.data.Attributes[index].Attributes_Check == 'Y') {
            this.data.Attributes[index].Attributes_Check = 'N';
        }
        else {
            this.data.Attributes[index].Attributes_Check = 'Y';
        }
    }

    setMaterialsCheck(index: number) {

        // 先將所有Value設為N，再將目標設為Y
        this.data.Materials.forEach(element => {
            element.Materials_Check = 'N';
        });

        this.data.Materials[index].Materials_Check = 'Y';
    }

    setConnotationCheck(index: number) {
        if (this.data.Connotation[index].Connotation_Check == 'Y') {
            this.data.Connotation[index].Connotation_Check = 'N';
        }
        else {
            this.data.Connotation[index].Connotation_Check = 'Y';
        }
    }


    save(type: string) {

        let totalDeptCore = 0;
        let totalSchCore = 0;

        // 因為涉及需要編碼及解碼，這裡不使用ngModel進行雙向binding。
        let Main_Content = <HTMLInputElement>document.getElementById("Main_Content");
        let Cos_Target = <HTMLInputElement>document.getElementById("Cos_Target");
        let Cos_Descrip = <HTMLInputElement>document.getElementById("Cos_Descrip");
        let Teach_Material = <HTMLInputElement>document.getElementById("Teach_Material");
        let Teach_Mode = <HTMLInputElement>document.getElementById("Teach_Mode");
        let Larn_Require = <HTMLInputElement>document.getElementById("Larn_Require");
        let Score_Mode = <HTMLInputElement>document.getElementById("Score_Mode");
        let Memo = <HTMLInputElement>document.getElementById("Memo");

        this.data.Main_Content = (Main_Content.value);
        this.data.Cos_Target = (Cos_Target.value);
        this.data.Cos_Descrip = (Cos_Descrip.value);
        this.data.Teach_Material = (Teach_Material.value);
        this.data.Teach_Mode = (Teach_Mode.value);
        // console.log(this.data.Learn_Require);
        this.data.Learn_Require = (Larn_Require.value);
        this.data.Score_Mode = (Score_Mode.value);
        this.data.Memo = (Memo.value);


        // 檢查指標是否為數字及加總是否為100
        this.data.DeptCore.forEach(element => {
            if (this.checkType.CheckIsNumber(element.Allot_Num)) {
                this.toastr.error("校級指標請寫數字");
                return;
            }
            totalDeptCore += parseFloat(element.Allot_Num);
        })

        this.data.SchCore.forEach(element => {
            if (this.checkType.CheckIsNumber(element.Allot_Num)) {
                this.toastr.error("系級指標請寫數字");
                return;
            }
            totalSchCore += parseFloat(element.Allot_Num);
        })

        if (totalDeptCore != 100 || totalSchCore != 100) {
            this.toastr.error("指標總和須為100");
            return;
        }

        if (type == 'send') {
            Swal.fire({
                title: '確定送出?一旦送出即無法修改。',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: '是',
                denyButtonText: '否',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.data.Send_State = '1';
                    this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_01/Update", this.data).subscribe((response) => {
                        if (response.Info == false) {
                            this.toastr.warning(response.Message);
                            return;
                        }

                        this.toastr.success("送繳成功");
                        this.router.navigateByUrl("/TCH01_01");
                    }, error => this.toastr.error(error.error));
                }
                else if (result.isDenied) {
                    this.toastr.info("已取消上傳。");
                }
            });
        }
        else {
            this.data.Send_State = '0';
            this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_01/Update", this.data).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }

                this.toastr.success("儲存成功");
                this.router.navigateByUrl("/TCH01_01");

            }, error => this.toastr.error(error.error));
        }
    }
}
