import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0106/search';
import { GetSELReportCosList } from 'src/app/_Models/AD/ADSEL/ADSEL0504/getSELReportCosList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSEL0504',
    templateUrl: './ADSEL0504.component.html',
    styleUrls: ['./ADSEL0504.component.css']
})
export class ADSEL0504Component implements OnInit {
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    deptList!: GetDeptList[];
    data!: Search[];
    sELReportCosList!: GetSELReportCosList[];
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', Validators.required),
        Term: new UntypedFormControl('', Validators.required),
        DeptNo: new UntypedFormControl('', Validators.required),
        TchData: new UntypedFormControl(''),
        CosData: new UntypedFormControl('')
    });


    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private fileExchangeServices: FileExchangeService) { }

    ngOnInit(): void {
        this.getYears();
    }

    getClassList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0106/Search", { Edu_No: '', Dept_No: this.ngFormInput.get('DeptNo')?.value }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
            this.data.forEach(element => {
                element.Selected = false;
            });
        })
    }

    getSELReportCosList() {
        let classList = '';

        this.data.forEach(element => {
            if (element.Selected == true) {
                classList += element.Class + ',';
            }
        });

        if (classList == '') {
            this.toastr.info('請勾選班級');
            return;
        }

        if (!this.ngFormInput.valid) {
            this.toastr.info('請選擇欄位');
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/SEL/ADSEL0504/Get_SEL_Report_CosList", {
            Years: this.ngFormInput.get('Years')?.value,
            Term: this.ngFormInput.get('Term')?.value,
            ClassList: classList.substring(0, classList.length - 1),
            TchData: this.ngFormInput.get('TchData')?.value,
            CosData: this.ngFormInput.get('CosData')?.value
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sELReportCosList = []
                return;
            }
            this.sELReportCosList = response;
            this.sELReportCosList.forEach(element => element.Selected = false);
        });
    }

    getDeptList() {
        this.systemService.getDeptList('', '0', this.ngFormInput.get('Years')?.value, this.ngFormInput.get('Term')?.value, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;
        });
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    setAll(type: string, eventTarget: EventTarget | null) {

        var input = <HTMLInputElement>eventTarget;

        if (type == 'cos' && input.checked == true && this.sELReportCosList) {
            this.sELReportCosList.forEach(element => {
                element.Selected = true;
            });
        }
        else if (type == 'cos' && input.checked == false && this.sELReportCosList) {
            if (!this.sELReportCosList) return;
            this.sELReportCosList.forEach(element => {
                element.Selected = false;
            });
        }
        else if (type == 'class' && input.checked == true && this.data) {
            this.data.forEach(element => {
                element.Selected = true;
            });
        }
        else if (type == 'class' && input.checked == false && this.data) {
            this.data.forEach(element => {
                element.Selected = false;
            });
        }
        else {
            return;
        }
    }

    exportReportADSEL0504(type: string) {

        let cosList = '';

        let classList = '';

        this.sELReportCosList.forEach(element => {
            if (element.Selected == true) {
                cosList += element.DataKey + ',';
            }
        });

        this.data.forEach(element => {
            if (element.Selected == true) {
                classList += element.Class + ',';
            }
        });

        if (type == 'cos' && cosList == '') {
            this.toastr.info('請選擇課程');
            return;
        }

        if (type == 'class' && classList == '') {
            this.toastr.info('請選擇班級');
            return;
        }

        this.fileExchangeServices.ExportFile("ACAD/SEL/ADSEL0504/Export_Report_ADSEL0504", {
            Years: this.ngFormInput.get('Years')?.value,
            Term: this.ngFormInput.get('Term')?.value,
            CosList: type == 'cos' ? cosList.substring(0, cosList.length - 1) : '',
            ClassList: type == 'class' ? classList.substring(0, classList.length - 1) : ''
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }, error => this.toastr.error(error));
    }

}
