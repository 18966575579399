<div class="container" style="margin-bottom: 80px;">
  <div class="row pt-2">
    <ng-container *ngIf="data">
      <div class="col-6 col-md-3  text-center item" *ngFor="let item of data">
        <a routerLink="{{item.HyperLink}}" class="text-decoration-none">
          <span class="{{item.IconName}} icon"></span>
          <p class=" fw-bolder">{{item.FunName}} </p>
        </a>
      </div>

    </ng-container>


    <div class="col-6 col-md-3 text-center item" (click)="getFavouriteList(template)">
      <a class="pointer text-decoration-none">
        <span class="icon-Add icon"></span>
        <p class="text-center fw-bolder">新增更多</p>
      </a>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">編輯我的最愛</h4>
    <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md">
        <button class="other-Button-gray mb-3" (click)="deleteFavourite()">移除</button>
        <table class="table table-hover table-striped text-center">
          <thead class="thead-dark-red">
            <tr>
              <th>勾選</th>
              <th>系統名稱</th>
              <th>功能名稱</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data">
              <td style="width: 60px;"><input type="checkbox" class="pointer shadow-none form-check-input"
                  (click)="setDatakey(item.DataKey, 'delete')"></td>
              <td>{{item.SysName}}</td>
              <td style="width: 170px;">{{item.FunName}}</td>
            </tr>
          </tbody>
        </table>

      </div>
      <div class="col-md">
        <button class="add-btn mb-3" (click)="addFavourite()">新增</button>
        <table class="table table-hover table-striped text-center">
          <thead class="thead-dark-red">
            <tr>
              <th style="width: 60px;">勾選</th>
              <th>系統名稱</th>
              <th style="width: 170px;">功能名稱</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of favouriteList">
              <td style="width: 60px;"><input type="checkbox" class="pointer shadow-none form-check-input"
                  (click)="setDatakey(item.DataKey, 'add')"></td>
              <td>{{item.SysName}}</td>
              <td style="width: 170px;">{{item.FunName}}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</ng-template>