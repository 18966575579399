<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">設定學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled selected>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group mb-3">
                <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #EduNo (change)="getStdNewCheckInDateTime(EduNo.value)">
                    <option value="" disabled selected>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <button class="save-btn-gray mb-3"
                (click)="getStdNewCheckInDateTimeSave(Year.value, EduNo.value)">設定</button>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">開放時間</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly required [bsValue]="fromDate" [(ngModel)]="fromDate"
                    style="background-color: transparent;">
                <timepicker [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="fromTime"></timepicker>
            </div>

        </div>
        <div class="col-md-6">
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">至</span>
                <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                    [bsConfig]="bsConfig" readonly required [bsValue]="toDate" [(ngModel)]="toDate"
                    style="background-color: transparent;">
                <timepicker [showMeridian]="true" [showSpinners]="false" required [(ngModel)]="toTime"></timepicker>
            </div>
        </div>
    </div>
</div>