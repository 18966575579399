<div class="container">
    <alert type="danger">{{'第一學期升級前要勾'|translate}}『{{'跨年度預先註冊'|translate}}』{{'，升級後不勾選才能做此動作。'|translate}}{{'例如:110-2要預先產生111-1註冊資料檔'|translate}}</alert>
    <form [formGroup]="ngFormInput" (ngSubmit)="getSELRegiImport()">
        <div class="row">
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'學年'|translate}}</span>
                    <select class="form-select shadow-none" #Year (change)="getEduList(Year.value, Term.value)"
                        formControlName="Years">
                        <option value="" disabled>{{'--請選擇--'|translate}}</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年{{''|translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'學期'|translate}}</span>
                    <select class="form-select shadow-none" #Term (change)="getEduList(Year.value, Term.value)"
                        formControlName="Term">
                        <option value="" disabled>{{'--請選擇--'|translate}}</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'學制'|translate}}</span>
                    <select class="form-select shadow-none" formControlName="EduNo">
                        <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                        <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text">{{'新舊生'|translate}}</span>
                    <select class="form-select shadow-none" formControlName="Type">
                        <option value="" selected disabled>{{'--請選擇--'|translate}}</option>
                        <option value="N">{{'新生'|translate}}</option>
                        <option value="O">{{'舊生'|translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md">
                <div class="form-check mt-2">
                    <input class="form-check-input pointer" type="checkbox" value="" id="flexCheckDefault"
                        formControlName="CheckNextYear">
                    <label class="form-check-label pointer" for="flexCheckDefault">{{'跨年度預先註冊'|translate}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <button class="import-btn mx-auto">{{'匯入'|translate}}</button>
        </div>
    </form>

</div>