import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { parseDate } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { GetCUPTimeCenterList } from 'src/app/_Models/AD/ADCUP/ADCUP0602/getCUPTimeCenterList';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADCUP0602',
    templateUrl: './ADCUP0602.component.html',
    styleUrls: ['./ADCUP0602.component.css']
})
export class ADCUP0602Component implements OnInit {
    baseUrl = environment.apiUrl;
    cUPTimeCenterList!: GetCUPTimeCenterList[];
    term = require('src/app/_Models/_SelectInput/term.json').term;
    bsConfig = environment.bsDatePickerConfig;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private datePipe: DatePipe,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getCUPTimeCenterList();
    }

    getCUPTimeCenterList() {

        this.timeService.currentTime$.subscribe((response) => {

            this.cUPTimeCenterList = response;

            this.cUPTimeCenterList.forEach(element => {
                if (element.IsDatetime == true) {
                    let date = element.ItemValue.split(' ')[0];
                    let time = element.ItemValue.split(' ')[1];
                    element.Date = date;
                    element.Time = new Date();
                    element.Time.setHours(parseInt(time.split(':')[0]));
                    element.Time.setMinutes(parseInt(time.split(':')[1]));
                }
            });
        })
    }


    getCUPTimeCenterModify() {

        this.cUPTimeCenterList.forEach(element => {
            if (element.IsDatetime) {
                element.ItemValue = this.datePipe.transform(element.Date, 'yyyy-MM-dd') + ' ' + this.datePipe.transform(element.Time, 'hh:mm');
            }
        });

        if (parseDate(this.cUPTimeCenterList[4].ItemValue) > parseDate(this.cUPTimeCenterList[5].ItemValue)) {
            this.toastr.info("期中教學評量開始時間大於結束時間");
            return;
        }

        if (parseDate(this.cUPTimeCenterList[7].ItemValue) > parseDate(this.cUPTimeCenterList[8].ItemValue)) {
            this.toastr.info("期末教學評量開始時間大於結束時間");
            return;
        }

        if (parseDate(this.cUPTimeCenterList[12].ItemValue) > parseDate(this.cUPTimeCenterList[13].ItemValue)) {
            this.toastr.info("教學大綱填寫開始時間大於結束時間");
            return;
        }

        if (parseDate(this.cUPTimeCenterList[16].ItemValue) > parseDate(this.cUPTimeCenterList[17].ItemValue)) {
            this.toastr.info("開課排課開始時間大於結束時間");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/CUP/ADCUP0602/Get_CUP_TimeCenter_Modify", this.cUPTimeCenterList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        })
    }

}
