import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetPublicCurList } from 'src/app/_Models/TCHWEB/TCH0104/getPublicCurList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-TCH0104',
    templateUrl: './TCH0104.component.html',
    styleUrls: ['./TCH0104.component.css']
})
export class TCH0104Component implements OnInit {

    courseList!: GetPublicCurList[];
    Years!: Array<string>;
    initiateData = new InitialData(this.http);
    baseUrl = environment.apiUrl;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    eduNo!: any;
    deptNo!: any;
    model = {
        Years: '',
        Term: '',
        EduNo: '',
        DeptNo: '',
        Class: '',
        KeyValue: ''
    }

    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
            this.systemService.getEduList('2', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
                this.eduList = response;
            });
        })
        this.getYears();
    }

    getYears() {
        this.initiateData.getYears().subscribe((response) => {
            this.Years = response;
        })
    }

    getPublicCurList() {
        this.http.post<any>(this.baseUrl + "ACAD/TCHWEB/TCH01_04/Get_Public_CurList", this.model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.courseList = []
                return;
            }
            this.courseList = response;
        }, error => this.toastr.error(error.error));
    }

    getDeptList(event: any) {
        this.eduNo = <HTMLInputElement>event.target;
        this.systemService.getDeptList(this.eduNo.value, '2', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning("查無資料");
                return;
            }
            this.deptList = response;
        })

    }

    getClassList(event: any) {
        this.deptNo = <HTMLInputElement>event.target;
        this.systemService.getClassList(this.eduNo.value, this.deptNo.value, '2', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning("查無資料");
                return;
            }
            this.classList = response;
        })
    }

}
