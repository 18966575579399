import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0106/search';
import { SearchDetail } from 'src/app/_Models/AD/ADRGP/ADRGP0106/searchDetail';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADRGP0106',
    templateUrl: './ADRGP0106.component.html',
    styleUrls: ['./ADRGP0106.component.css']
})
export class ADRGP0106Component implements OnInit {
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    detailDeptList!: GetDeptList[];
    DNMArkList!: GetSelectList[];
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    data!: Search[];
    dataDetail: SearchDetail = {
        Class: '',
        Class_Name: '',
        Class_Short: '',
        Class_ID: '',
        Edu_No: '',
        Acad_No: '',
        Dept_No: '',
        Grade: '',
        UpGrade: '',
        UpGrade_Name: '',
        DN_Mark: '',
        State_Log: '',
        Assign_Class: false,
        Update_user: '',
        Update_Time: ''
    }
    search1 = {
        eduNo: '',
        DeptNo: ''
    }
    upgradeData!: Search[];
    upgradeClass = '';
    upgradeClassName = '';
    constructor(private http: HttpClient, private toastr: ToastrService, private modalService: BsModalService, private systemService: SystemService,) { }

    ngOnInit(): void {
        this.getEduList();
    }


    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            this.eduList = response;
        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, isdDetail: boolean) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            isdDetail ? this.detailDeptList = response : this.deptList = response

        }, error => this.toastr.error(error.error));
    }

    search(Edu_No: string, Dept_No: string, type: string) {
        if (type == 'upgrade') {
            if (Dept_No == '') {
                this.toastr.info("請選擇系所");
                return;
            }

            this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0106/Search", { Edu_No: Edu_No, Dept_No: Dept_No }).subscribe((response) => {
                this.upgradeData = []
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    this.upgradeData = []
                    return;
                }
                this.upgradeData = response;
                this.upgradeData.forEach(element => {
                    element.Selected = false;
                });
            })
        }
        else {
            if (Dept_No == '') {
                this.toastr.info("請選擇系所");
                return;
            }

            this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0106/Search", { Edu_No: Edu_No, Dept_No: Dept_No }).subscribe((response) => {
                this.data = []
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.data = response;
                this.data.forEach(element => {
                    element.Selected = false;
                });
            })
        }
    }

    openModel(template: TemplateRef<any>) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-lg'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    setUpgradeClass(index: number) {
        this.dataDetail.UpGrade = this.upgradeData[index].Class
        this.dataDetail.UpGrade_Name = this.upgradeData[index].Class_Name;
        this.modalRef.hide();
    }

    searchDetail(Class: string, index: number) {
        this.data.forEach(element => {
            element.Selected = false;
        });
        this.data[index].Selected = true;
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0106/Search_Detail", { Class: Class }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.dataDetail = response;
            this.dataDetail.Class_Name = this.dataDetail.Class_Name;
            this.dataDetail.Class_Short = this.dataDetail.Class_Short;
            this.dataDetail.UpGrade_Name = this.dataDetail.UpGrade_Name;
            this.dataDetail.Update_Time = this.dataDetail.Update_Time;
            this.dataDetail.Update_user = this.dataDetail.Update_user;
            this.getDeptList(this.dataDetail.Edu_No, true);
        });

    }

    insert() {
        if (this.dataDetail.Class == '' || this.dataDetail.Class_Name == '' || this.dataDetail.Class_Short == '' ||
            this.dataDetail.Edu_No == '' || this.dataDetail.Dept_No == '' || this.dataDetail.State_Log == '' ||
            this.dataDetail.Grade == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0106/Insert", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("新增成功，請重新查詢");
            this.search(this.search1.eduNo, this.search1.DeptNo, '')
        }, error => this.toastr.error(error.error))
    }

    update() {
        if (this.dataDetail.Class == '' || this.dataDetail.Class_Name == '' || this.dataDetail.Class_Short == '' ||
            this.dataDetail.Edu_No == '' || this.dataDetail.Dept_No == '' || this.dataDetail.State_Log == '' ||
            this.dataDetail.Grade == '') {
            this.toastr.info("請將所有欄位填妥");
            return;
        }
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0106/Update", this.dataDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("修改成功，請重新查詢");
            this.search(this.search1.eduNo, this.search1.DeptNo, '')
        }, error => this.toastr.error(error.error))
    }

    delete() {
        if (this.dataDetail.Class == '') {
            this.toastr.info("請選擇班級");
            return;
        }

        Swal.fire({
            title: "確定刪除？",
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "確定"
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0106/Delete", this.dataDetail).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功，請重新查詢");
                    this.search(this.search1.eduNo, this.search1.DeptNo, '')
                    this.clear();
                }, error => this.toastr.error(error.error))
            }
            else {
                this.toastr.info("動作取消");
            }
        })


    }

    clear() {
        this.dataDetail = {
            Class: '',
            Class_Name: '',
            Class_Short: '',
            Class_ID: '',
            Edu_No: '',
            Acad_No: '',
            Dept_No: '',
            Grade: '',
            UpGrade: '',
            UpGrade_Name: '',
            DN_Mark: '',
            State_Log: '',
            Assign_Class: false,
            Update_user: '',
            Update_Time: ''
        }
    }

}
