<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學年</span>
                <select class="form-select shadow-none" #Year>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學期</span>
                <select class="form-select shadow-none" #Term>
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">取回否</span>
                <select class="form-select shadow-none">
                    <option value="" disabled>--請選擇--</option>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none" #eduNo (change)="getDeptList(eduNo.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" #deptNo (change)="getClassList(eduNo.value, deptNo.value)">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班級</span>
                <select class="form-select shadow-none">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of classList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學號</span>
                <input type="text" class="form-control shadow-none">
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">證書樣式</span>
                <select class="form-select shadow-none">
                    <option value="" disabled>--請選擇--</option>
                    <option value="ch">中文</option>
                    <option value="en">英文</option>
                    <option value="ce">中英文</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-md-4">
            <button class="search-btn float-start me-3">查詢</button>
            <button class="other-Button-gray float-start me-3">匯出檔案</button>
            <button class="other-Button-gray float-start me-3">取回</button>
        </div>
    </div>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>學年</th>
                <th>學期</th>
                <th>學號</th>
                <th>姓名</th>
                <th>班級</th>
                <th>取回否</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </table>
</div>

<!-- <ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{isNewData==true? '新增休學資料' : '休學資料'}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">學號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRestDetail.StdNo" #StdNo
                        [disabled]="!isNewData" (blur)="getStdRestDetail(StdNo.value, template, true)">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">姓名</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRestDetail.StdName" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">休學學年</span>
                    </div>
                    <select class="form-select shadow-none" #RestYear [(ngModel)]="stdRestDetail.RestYear"
                        [disabled]="!isNewData" (change)="caculate(RestYear.value, RestTerm.value, TermCount.value)">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">休學學期</span>
                    </div>
                    <select class="form-select shadow-none" #RestTerm [(ngModel)]="stdRestDetail.RestTerm"
                        [disabled]="!isNewData" (change)="caculate(RestYear.value, RestTerm.value, TermCount.value)">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">班級</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRestDetail.RestClassName"
                        disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">休學延長</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="stdRestDetail.Delay" [disabled]="!isNewData">
                        <option value="" disabled>--請選擇--</option>
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">休學原因</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="stdRestDetail.RestNo">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of restReason" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">申請日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly [disabled]="!isNewData" [(ngModel)]="stdRestDetail.ApplyDate"
                        style="background-color: transparent;">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">休學期數</span>
                    </div>
                    <input type="text" class="form-control shadow-none" #TermCount [(ngModel)]="stdRestDetail.TermCount"
                        [disabled]="!isNewData" (change)="caculate(RestYear.value, RestTerm.value, TermCount.value)">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">應復學學年</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="stdRestDetail.EndYear" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">應復學學期</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="stdRestDetail.EndTerm" disabled>
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3" [hidden]="!isNewData">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">累計休學期數</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRestDetail.TotalTermCount"
                        disabled>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md">
                <label for="">備註</label>
                <textarea class="form-control shadow-none" [(ngModel)]="stdRestDetail.Memo"
                    appTextareaAutoresize></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">核准日期</span>
                    </div>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly [disabled]="!isNewData" [(ngModel)]="stdRestDetail.ApproveDate"
                        style="background-color: transparent;">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">是否核准</span>
                    </div>
                    <select class="form-select shadow-none" [(ngModel)]="stdRestDetail.Approve">
                        <option value="" disabled>--請選擇--</option>
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3" [hidden]="isNewData">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">審核人員</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRestDetail.RegiName" disabled>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">證書字號</span>
                    </div>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdRestDetail.CerSchNo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button class="other-Button-gray float-end  mx-auto" (click)="getStdRestDelete()">刪除</button>
                <button class="save-btn-gray float-end me-3 mx-auto" (click)="getStdRestModify()">儲存</button>
            </div>
        </div>
    </div>
</ng-template> -->