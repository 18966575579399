import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GetTFSMakeDocLoanFeeList } from 'src/app/_Models/AD/ADTFS/ADTFS0302/getTFSMakeDocLoanFeeList';
import { GetTFSMakeDocLoanFeeDetail } from 'src/app/_Models/AD/ADTFS/ADTFS0302/getTFSMakeDocLoanFeeDetail';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
@Component({
  selector: 'app-ADTFS0302',
  templateUrl: './ADTFS0302.component.html',
  styleUrls: ['./ADTFS0302.component.css']
})
export class ADTFS0302Component implements OnInit {
  term = require('src/app/_Models/_SelectInput/term.json').term;
  Years!: Array<string>;
  dYears!: Array<string>;
  baseUrl = environment.apiUrl;
  initialData = new InitialData(this.http);
  locales = listLocales();
  bsConfig = environment.bsDatePickerConfig;
  modalRef!: BsModalRef;
  bsValue!: (Date | undefined)[] | undefined;
  checkType = new CheckType();
  tFSMakeDocLoanFeeList!: GetTFSMakeDocLoanFeeList[];
  tFSMakeDocLoanFeeDetail: GetTFSMakeDocLoanFeeDetail = {
    DataKey: '',
    ClassName: '',
    StdNo: '',
    StdName: '',
    IDNo: '',
    Birthday: '',
    EntryDate: '',
    ComTel: '',
    MobilePhone: '',
    EMail: '',
    ComAddress: '',
    MustTMoney: '',
    TEMoney: '',
    LoanMoney: '',
    SIMoney: '',
    FinalMoney: '',
    MakeDocMark: '',
    PaymentCheck: '',
    LoanType: '',
    LoanFeeData: []
  };
  constructor(private toastr: ToastrService, private systemService: SystemService, private http: HttpClient, private localeService: BsLocaleService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.localeService.use('zh-cn');
    this.getYears();
  }

  clearDatePicker() {
    this.bsValue = [];
  }

  getYears() {
    this.initialData.getYears().subscribe((response) => {
      if (response) {
        this.Years = response;
        this.dYears = response;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    let modalConfig = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };

    this.tFSMakeDocLoanFeeDetail = {
      DataKey: '',
      ClassName: '',
      StdNo: '',
      StdName: '',
      IDNo: '',
      Birthday: '',
      EntryDate: '',
      ComTel: '',
      MobilePhone: '',
      EMail: '',
      ComAddress: '',
      MustTMoney: '',
      TEMoney: '',
      LoanMoney: '',
      SIMoney: '',
      FinalMoney: '',
      MakeDocMark: '',
      PaymentCheck: '',
      LoanType: '',
      LoanFeeData: []
    };;

    this.modalRef = this.modalService.show(template, modalConfig);
  }

  importData(Years: string, Term: string) {
    if (Years == '' || Term == '') {
      this.toastr.info("請先選擇欲匯入的學年學期")
      return;
    }
  }

  getTFSMakeDocLoanFeeList(Years: string, Term: string, StdNo: string, IDNo: string, MakeDocMark: string, LoanType: string) {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0302/Get_TFS_MakeDoc_LoanFee_List", {
      Years: Years,
      Term: Term,
      StdNo: StdNo,
      IDNo: IDNo,
      MakeDocMark: MakeDocMark,
      LoanType: LoanType
    }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        this.tFSMakeDocLoanFeeList = []
        return;
      }
      this.tFSMakeDocLoanFeeList = response;
    })
  }

  getTFSMakeDocLoanFeeDetail(dataKey: string, template: TemplateRef<any>, search: boolean) {
    this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0302/Get_TFS_MakeDoc_LoanFee_Detail", { DataKey: dataKey }).subscribe((response) => {
      if (response.Info == false) {
        this.toastr.warning(response.Message);
        return;
      }
      this.tFSMakeDocLoanFeeDetail = response;
      this.tFSMakeDocLoanFeeDetail.MobilePhone = this.tFSMakeDocLoanFeeDetail.MobilePhone;
      this.tFSMakeDocLoanFeeDetail.StdName = this.tFSMakeDocLoanFeeDetail.StdName;
      this.tFSMakeDocLoanFeeDetail.ClassName = this.tFSMakeDocLoanFeeDetail.ClassName;
      this.tFSMakeDocLoanFeeDetail.Birthday = this.tFSMakeDocLoanFeeDetail.Birthday;
      this.tFSMakeDocLoanFeeDetail.ComAddress = this.tFSMakeDocLoanFeeDetail.ComAddress;
      this.tFSMakeDocLoanFeeDetail.EMail = this.tFSMakeDocLoanFeeDetail.EMail;
      this.tFSMakeDocLoanFeeDetail.ComTel = this.tFSMakeDocLoanFeeDetail.ComTel;
      this.tFSMakeDocLoanFeeDetail.EntryDate = this.tFSMakeDocLoanFeeDetail.EntryDate;
      if (!search) {
        let modalConfig = {
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-xl'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
      }
    })
  }

  caculate(LooanMoney: string, index: number) {
    if (this.checkType.CheckIsNumber(LooanMoney)) {
      this.toastr.warning("請輸入數字");
      this.tFSMakeDocLoanFeeDetail.LoanFeeData[index].LooanMoney = ''
      return;
    }

    if (parseInt(LooanMoney) < 0) {
      this.toastr.warning("請輸入正整數");
      this.tFSMakeDocLoanFeeDetail.LoanFeeData[index].LooanMoney = ''
      return;
    }

    let total = 0;

    for (let index = 0; index < this.tFSMakeDocLoanFeeDetail.LoanFeeData.length; index++) {
      if (this.tFSMakeDocLoanFeeDetail.LoanFeeData[index].LooanMoney != '') {
        total += parseInt(this.tFSMakeDocLoanFeeDetail.LoanFeeData[index].LooanMoney);
      }
    }

    this.tFSMakeDocLoanFeeDetail.LoanMoney = total.toString();
  }

  getTFSMakeDocLoanFeeSave() {
    let pass = true;

    for (let index = 0; index < this.tFSMakeDocLoanFeeDetail.LoanFeeData.length; index++) {
      if (this.checkType.CheckIsNumber(this.tFSMakeDocLoanFeeDetail.LoanFeeData[index].LooanMoney)) {
        this.toastr.warning(`第${index + 1}項非數字`);
        pass = false;
        break;
      }

      if (parseInt(this.tFSMakeDocLoanFeeDetail.LoanFeeData[index].LooanMoney) > (parseInt(this.tFSMakeDocLoanFeeDetail.LoanFeeData[index].MustMoney))) {
        this.toastr.warning(`第${index + 1}項金額大於上限`);
        pass = false;
        break;
      }
    }

    if (pass) {
      this.http.post<any>(this.baseUrl + "ACAD/TFS/ADTFS0302/Get_TFS_MakeDoc_LoanFee_Save", this.tFSMakeDocLoanFeeDetail).subscribe((response) => {
        if (response.Info == false) {
          this.toastr.warning(response.Message);
          return;
        }
        this.toastr.success("儲存成功");
        this.modalRef.hide();
        this.tFSMakeDocLoanFeeDetail = {
          DataKey: '',
          ClassName: '',
          StdNo: '',
          StdName: '',
          IDNo: '',
          Birthday: '',
          EntryDate: '',
          ComTel: '',
          MobilePhone: '',
          EMail: '',
          ComAddress: '',
          MustTMoney: '',
          TEMoney: '',
          LoanMoney: '',
          SIMoney: '',
          FinalMoney: '',
          MakeDocMark: '',
          PaymentCheck: '',
          LoanType: '',
          LoanFeeData: []
        };
      })
    }
  }
}
