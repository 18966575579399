<div class="container">
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業學年</span>
                <select class="form-select shadow-none" [(ngModel)]="data.Years">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">畢業學期</span>
                <select class="form-select shadow-none" [(ngModel)]="data.Term">
                    <option value="" disabled>--請選擇--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">身分證字號</span>
                <input type="text" class="form-control shadow-none" 
                    [(ngModel)]="data.IDNo">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none"  (change)="getDeptList(EduNo.value)" #EduNo
                    [(ngModel)]="data.EduNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">系所</span>
                <select class="form-select shadow-none" #DeptNo (change)="getClassList(EduNo.value, DeptNo.value)"
                  (change)="getTeamList(DeptNo.value)"  [(ngModel)]="data.DeptNo">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of deptList" value="{{item.Dept_No}}">{{item.Dept_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">組別</span>
                <select class="form-select shadow-none" [(ngModel)]="data.TeamNo">
                    <option value="" selected>--請選擇組別--</option>
                    <option *ngFor="let item of stdTeamList" ngValue="{{item.Team_No}}"> {{item.Team_Name}} </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">年級</span>
                <select class="form-select shadow-none" [(ngModel)]="data.Grade">
                    <option value="" selected>--請選擇年級--</option>
                    <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}} </option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">班別</span>
                <select class="form-select shadow-none" [(ngModel)]="data.Class">
                    <option value="" selected disabled>--請選擇--</option>
                    <option *ngFor="let item of stdClassList" value="{{item.Class}}">{{item.Class_Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">僅查詢已畢業</span>
                <select class="form-select shadow-none" [(ngModel)]="data.Grad">
                    <option value="" disabled selected>請選擇</option>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
    </div>
    <div>
        <button class="export-btn mx-auto" (click)="export()">匯出</button>
    </div>
</div>
