<div class="container">
    <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
    <div class="row">
        <div class="col-md">
            <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{'開課班級'|translate}}</span>
                </div>
                <select class="form-select shadow-none" #cos>
                    <option value="" disabled selected>{{'--請選擇開課班級--'|translate}}</option>
                    <option *ngFor="let item of opClassList" value="{{item.ItemValue}}">{{item.ItemName}}</option>
                </select>
            </div>
        </div>
    </div>
    <button class="search-btn mb-3" (click)="getCosList(cos.value)">{{'查詢'|translate}}</button>
    <table class="table table-hover table-striped text-center">
        <thead class="thead-dark-red">
            <tr>
                <th>{{'學年'|translate}}</th>
                <th>{{'學期'|translate}}</th>
                <th>{{'開課班級'|translate}}</th>
                <th>{{'科目'|translate}}</th>
                <th>{{'上課時間'|translate}}</th>
                <th>{{'操作'|translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cosList">
                <td>{{getOpClassListInput.Years}}</td>
                <td>{{getOpClassListInput.Term}}</td>
                <td>{{item.ClassName}}</td>
                <td>{{item.CosName}}</td>
                <td>{{item.Times}}</td>
                <td><button class="btn btn-primary btn-sm" (click)="getMaterialList(item.DataKey, template)">{{'設定'|translate}}</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'設定教材'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th>{{'檔案序號'|translate}}</th>
                            <th>{{'檔案名稱'|translate}}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tchFileList">
                            <td>{{item.File_No}}</td>
                            <td>{{item.File_Name}}</td>
                            <td><a class="pointer text-decoration-none" (click)="addFile(item.File_No)"><span
                                        class="icon-Plus-button"></span>{{'增加'|translate}}</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md">
                <table class="table table-hover table-striped text-center">
                    <thead class="thead-dark-red">
                        <tr>
                            <th>{{'檔案序號'|translate}}</th>
                            <th>{{'檔案名稱'|translate}}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of materialList">
                            <td>{{item.File_No}}</td>
                            <td>{{item.File_Name}}</td>
                            <td><a class="pointer text-decoration-none text-danger"
                                    (click)="removeFile(item.DataKey)"><span class="icon-Clear-button"></span>{{'取消'|translate}}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>