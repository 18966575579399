import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { STDW2000607Component } from './STDWEB/STDW2000607/STDW2000607.component';
import { TCH0308Component } from './TCHWEB/TCH0308/TCH0308.component';
import { STDW50001Component } from './STDWEB/STDW50001/STDW50001.component';
import { STDW50002Component } from './STDWEB/STDW50002/STDW50002.component';
import { STDW50003Component } from './STDWEB/STDW50003/STDW50003.component';
import { STDW30001Component } from './STDWEB/STDW30001/STDW30001.component';
import { STDW30002Component } from './STDWEB/STDW30002/STDW30002.component';
import { TCH0114Component } from './TCHWEB/TCH0114/TCH0114.component';
import { HomeComponent } from './Portal/home/home.component';
import { LoginComponent } from './Portal/login/login.component';
import { Page404Component } from './_Errors/page404/page404.component';
import { Page500Component } from './_Errors/page500/page500.component';
import { AuthGuard } from './_Guards/auth.guard';
import { STDW00003Component } from './STDWEB/STDW00003/STDW00003.component';
import { FavouriteComponent } from './Portal/favourite/favourite.component';
import { STDW00002Component } from './STDWEB/STDW00002/STDW00002.component';
import { STDW00001Component } from './STDWEB/STDW00001/STDW00001.component';
import { STDW10001Component } from './STDWEB/STDW10001/STDW10001.component';
import { STDW10002Component } from './STDWEB/STDW10002/STDW10002.component';
import { STDW10003Component } from './STDWEB/STDW10003/STDW10003.component';
import { STDW10004Component } from './STDWEB/STDW10004/STDW10004.component';
import { STDW10005Component } from './STDWEB/STDW10005/STDW10005.component';
import { STDW10006Component } from './STDWEB/STDW10006/STDW10006.component';
import { STDW10013ISUComponent } from './STDWEB/STDW10013ISU/STDW10013ISU.component';
import { STDW20001Component } from './STDWEB/STDW20001/STDW20001.component';
import { STDW20002Component } from './STDWEB/STDW20002/STDW20002.component';
import { STDW20003Component } from './STDWEB/STDW20003/STDW20003.component';
import { STDW20004Component } from './STDWEB/STDW20004/STDW20004.component';
import { STDW30003Component } from './STDWEB/STDW30003/STDW30003.component';
import { STDW30004Component } from './STDWEB/STDW30004/STDW30004.component';
import { STDW30005Component } from './STDWEB/STDW30005/STDW30005.component';
import { STDW30006Component } from './STDWEB/STDW30006/STDW30006.component';
import { STDW40001Component } from './STDWEB/STDW40001/STDW40001.component';
import { STDW40002Component } from './STDWEB/STDW40002/STDW40002.component';
import { STDW40003Component } from './STDWEB/STDW40003/STDW40003.component';
import { STDW40004Component } from './STDWEB/STDW40004/STDW40004.component';
import { STDW40005Component } from './STDWEB/STDW40005/STDW40005.component';
import { STDW40006Component } from './STDWEB/STDW40006/STDW40006.component';
import { STDW50004Component } from './STDWEB/STDW50004/STDW50004.component';
import { STDW60001Component } from './STDWEB/STDW60001/STDW60001.component';
import { STDW60002Component } from './STDWEB/STDW60002/STDW60002.component';
import { STDW60003Component } from './STDWEB/STDW60003/STDW60003.component';
import { STDW70001Component } from './STDWEB/STDW70001/STDW70001.component';

import { TCH0101Component } from './TCHWEB/TCH0101/TCH0101.component';
import { TCH0102Component } from './TCHWEB/TCH0102/TCH0102.component';
import { TCH0103Component } from './TCHWEB/TCH0103/TCH0103.component';
import { TCH0104Component } from './TCHWEB/TCH0104/TCH0104.component';
import { TCH0105Component } from './TCHWEB/TCH0105/TCH0105.component';
import { TCH0106Component } from './TCHWEB/TCH0106/TCH0106.component';
import { TCH0107Component } from './TCHWEB/TCH0107/TCH0107.component';
import { TCH0108Component } from './TCHWEB/TCH0108/TCH0108.component';
import { TCH0109Component } from './TCHWEB/TCH0109/TCH0109.component';
import { TCH0110Component } from './TCHWEB/TCH0110/TCH0110.component';
import { TCH0111Component } from './TCHWEB/TCH0111/TCH0111.component';
import { TCH0112Component } from './TCHWEB/TCH0112/TCH0112.component';
import { TCH0113Component } from './TCHWEB/TCH0113/TCH0113.component';
import { TCH0115Component } from './TCHWEB/TCH0115/TCH0115.component';
import { TCH0201Component } from './TCHWEB/TCH0201/TCH0201.component';
import { TCH0202Component } from './TCHWEB/TCH0202/TCH0202.component';
import { TCH0203Component } from './TCHWEB/TCH0203/TCH0203.component';
import { TCH0204Component } from './TCHWEB/TCH0204/TCH0204.component';
import { TCH0301Component } from './TCHWEB/TCH0301/TCH0301.component';
import { TCH0302Component } from './TCHWEB/TCH0302/TCH0302.component';
import { TCH0303Component } from './TCHWEB/TCH0303/TCH0303.component';
import { TCH0304Component } from './TCHWEB/TCH0304/TCH0304.component';
import { TCH0305Component } from './TCHWEB/TCH0305/TCH0305.component';
import { TCH0306Component } from './TCHWEB/TCh0306/TCh0306.component';
import { TCH0307Component } from './TCHWEB/TCH0307/TCH0307.component';
import { TCH0309Component } from './TCHWEB/TCH0309/TCH0309.component';
import { TCH0401Component } from './TCHWEB/TCH0401/TCH0401.component';
import { TCH0402Component } from './TCHWEB/TCH0402/TCH0402.component';
import { TCH0101DetailComponent } from './TCHWEB/TCH0101-detail/TCH0101-detail.component';
import { TCH0111DetailComponent } from './TCHWEB/TCH0111-detail/TCH0111-detail.component';
import { ProfileComponent } from './Portal/profile/profile.component';
import { TCH0310Component } from './TCHWEB/TCH0310/TCH0310.component';
import { TCH0116Component } from './TCHWEB/TCH0116/TCH0116.component';
import { TCH0117Component } from './TCHWEB/TCH0117/TCH0117.component';
import { TCH0118Component } from './TCHWEB/TCH0118/TCH0118.component';
import { ADFunctionListComponent } from './_SharedComponont/ADFunctionList/ADFunctionList.component';
import { ADSTD0201Component } from './ADWEB/ADSTD/ADSTD0201/ADSTD0201.component';
import { ADSTD0101Component } from './ADWEB/ADSTD/ADSTD0101/ADSTD0101.component';
import { ADSTD0101ISUComponent } from './ADWEB/ADSTD/ADSTD0101ISU/ADSTD0101ISU.component';
import { ADSTD0103Component } from './ADWEB/ADSTD/ADSTD0103/ADSTD0103.component';
import { ADSTD0104Component } from './ADWEB/ADSTD/ADSTD0104/ADSTD0104.component';
import { ADTFS01A03Component } from './ADWEB/ADTFS/ADTFS01A03/ADTFS01A03.component';
import { ADTFS01B02Component } from './ADWEB/ADTFS/ADTFS01B02/ADTFS01B02.component';
import { ADTFS01B01Component } from './ADWEB/ADTFS/ADTFS01B01/ADTFS01B01component';
import { ADTFS01B04Component } from './ADWEB/ADTFS/ADTFS01B04/ADTFS01B04.component';
import { ADTFS01D01Component } from './ADWEB/ADTFS/ADTFS01D01/ADTFS01D01.component';
import { ADTFS01D02Component } from './ADWEB/ADTFS/ADTFS01D02/ADTFS01D02.component';
import { ADTFS01D03Component } from './ADWEB/ADTFS/ADTFS01D03/ADTFS01D03.component';
import { ADTFS0301Component } from './ADWEB/ADTFS/ADTFS0301/ADTFS0301.component';
import { ADTFS0302Component } from './ADWEB/ADTFS/ADTFS0302/ADTFS0302.component';
import { ADTFS0502Component } from './ADWEB/ADTFS/ADTFS0502/ADTFS0502.component';
import { ADTFS0501Component } from './ADWEB/ADTFS/ADTFS0501/ADTFS0501.component';
import { ADTFS0601Component } from './ADWEB/ADTFS/ADTFS0601/ADTFS0601.component';
import { ADTFS0602Component } from './ADWEB/ADTFS/ADTFS0602/ADTFS0602.component';
import { ADTFS01B03Component } from './ADWEB/ADTFS/ADTFS01B03/ADTFS01B03.component';
import { ADTFS01E01Component } from './ADWEB/ADTFS/ADTFS01E01/ADTFS01E01.component';
import { ADTFS01E02Component } from './ADWEB/ADTFS/ADTFS01E02/ADTFS01E02.component';
import { ADTFS01E03Component } from './ADWEB/ADTFS/ADTFS01E03/ADTFS01E03.component';
import { ADTFS0701Component } from './ADWEB/ADTFS/ADTFS0701/ADTFS0701.component';
import { ADTFS0702Component } from './ADWEB/ADTFS/ADTFS0702/ADTFS0702.component';
import { ADTFS01C01Component } from './ADWEB/ADTFS/ADTFS01C01/ADTFS01C01.component';
import { ADTFS0401Component } from './ADWEB/ADTFS/ADTFS0401/ADTFS0401.component';

import { ADTFS01A04Component } from './ADWEB/ADTFS/ADTFS01A04/ADTFS01A04.component';
import { ADTFS0303Component } from './ADWEB/ADTFS/ADTFS0303/ADTFS0303.component';
import { ADSTD0203Component } from './ADWEB/ADSTD/ADSTD0203/ADSTD0203.component';
import { ADSTD0204Component } from './ADWEB/ADSTD/ADSTD0204/ADSTD0204.component';
import { ADSTD0205Component } from './ADWEB/ADSTD/ADSTD0205/ADSTD0205.component';
import { ADSTD0206Component } from './ADWEB/ADSTD/ADSTD0206/ADSTD0206.component';
import { ADSTD0202Component } from './ADWEB/ADSTD/ADSTD0202/ADSTD0202.component';
import { ADSTD0106Component } from './ADWEB/ADSTD/ADSTD0106/ADSTD0106.component';
import { STDW30007Component } from './STDWEB/STDW30007/STDW30007.component';
import { STDW30008Component } from './STDWEB/STDW30008/STDW30008.component';
import { STDW10009Component } from './STDWEB/STDW10009/STDW10009.component';
import { STDW10010Component } from './STDWEB/STDW10010/STDW10010.component';
import { STDW10011Component } from './STDWEB/STDW10011/STDW10011.component';
import { ADASM0101Component } from './ADWEB/ADASM/ADASM0101/ADASM0101.component';
import { ADASM0102Component } from './ADWEB/ADASM/ADASM0102/ADASM0102.component';
import { ADASM0103Component } from './ADWEB/ADASM/ADASM0103/ADASM0103.component';
import { ADASM0104Component } from './ADWEB/ADASM/ADASM0104/ADASM0104.component';
import { ADASM0105Component } from './ADWEB/ADASM/ADASM0105/ADASM0105.component';
import { ADTFS0603Component } from './ADWEB/ADTFS/ADTFS0603/ADTFS0603.component';
import { ADTFS0503Component } from './ADWEB/ADTFS/ADTFS0503/ADTFS0503.component';
import { ADSTD0102Component } from './ADWEB/ADSTD/ADSTD0102/ADSTD0102.component';
import { ADSTD0301Component } from './ADWEB/ADSTD/ADSTD0301/ADSTD0301.component';
import { ADSTD0302Component } from './ADWEB/ADSTD/ADSTD0302/ADSTD0302.component';
import { ADSTD0303Component } from './ADWEB/ADSTD/ADSTD0303/ADSTD0303.component';
import { ADSTD0304Component } from './ADWEB/ADSTD/ADSTD0304/ADSTD0304.component';
import { ADSTD0305Component } from './ADWEB/ADSTD/ADSTD0305/ADSTD0305.component';
import { ADSTD0401Component } from './ADWEB/ADSTD/ADSTD0401/ADSTD0401.component';
import { ADSTD0401ISU01Component } from './ADWEB/ADSTD/ADSTD0401ISU01/ADSTD0401ISU01.component';

import { ADSTD0501Component } from './ADWEB/ADSTD/ADSTD0501/ADSTD0501.component';
import { ADSTD0501ISU01Component } from './ADWEB/ADSTD/ADSTD0501ISU01/ADSTD0501ISU01.component';

import { ADSTD0601Component } from './ADWEB/ADSTD/ADSTD0601/ADSTD0601.component';
import { ADSTD0601ISU01Component } from './ADWEB/ADSTD/ADSTD0601ISU01/ADSTD0601ISU01.component';
import { ADSTD0601ISU02Component } from './ADWEB/ADSTD/ADSTD0601ISU02/ADSTD0601ISU02.component';
import { ADSTD0601ISU03Component } from './ADWEB/ADSTD/ADSTD0601ISU03/ADSTD0601ISU03.component';
import { ADSTD0602Component } from './ADWEB/ADSTD/ADSTD0602/ADSTD0602.component';
import { ADSTD0207Component } from './ADWEB/ADSTD/ADSTD0207/ADSTD0207.component';
import { ADSTD0701Component } from './ADWEB/ADSTD/ADSTD0701/ADSTD0701.component';
import { ADSTD0701ISUComponent } from './ADWEB/ADSTD/ADSTD0701ISU/ADSTD0701ISU.component';
import { ADSTD0703Component } from './ADWEB/ADSTD/ADSTD0703/ADSTD0703.component';
import { ADSTD0704Component } from './ADWEB/ADSTD/ADSTD0704/ADSTD0704.component';
import { ADSTD0706Component } from './ADWEB/ADSTD/ADSTD0706/ADSTD0706.component';
import { ADSTD0705Component } from './ADWEB/ADSTD/ADSTD0705/ADSTD0705.component';
import { ADSTD0815Component } from './ADWEB/ADSTD/ADSTD0815/ADSTD0815.component';
import { ADSTD0819Component } from './ADWEB/ADSTD/ADSTD0819/ADSTD0819.component';
import { ADPUB0101Component } from './ADWEB/ADPUB/ADPUB0101/ADPUB0101.component';
import { ADPUB0102Component } from './ADWEB/ADPUB/ADPUB0102/ADPUB0102.component';
import { ADPUB0103Component } from './ADWEB/ADPUB/ADPUB0103/ADPUB0103.component';

import { ADRGP0101Component } from './ADWEB/ADRGP/ADRGP0101/ADRGP0101.component';
import { ADRGP0102Component } from './ADWEB/ADRGP/ADRGP0102/ADRGP0102.component';
import { ADRGP0103Component } from './ADWEB/ADRGP/ADRGP0103/ADRGP0103.component';
import { ADRGP0104Component } from './ADWEB/ADRGP/ADRGP0104/ADRGP0104.component';
import { ADRGP0105Component } from './ADWEB/ADRGP/ADRGP0105/ADRGP0105.component';
import { ADRGP0106Component } from './ADWEB/ADRGP/ADRGP0106/ADRGP0106.component';
import { ADRGP0107Component } from './ADWEB/ADRGP/ADRGP0107/ADRGP0107.component';
import { ADRGP0108Component } from './ADWEB/ADRGP/ADRGP0108/ADRGP0108.component';
import { ADRGP0201Component } from './ADWEB/ADRGP/ADRGP0201/ADRGP0201.component';
import { ADRGP0202Component } from './ADWEB/ADRGP/ADRGP0202/ADRGP0202.component';
import { ADRGP0203Component } from './ADWEB/ADRGP/ADRGP0203/ADRGP0203.component';
import { ADRGP0302Component } from './ADWEB/ADRGP/ADRGP0302/ADRGP0302.component';
import { ADRGP0401Component } from './ADWEB/ADRGP/ADRGP0401/ADRGP0401.component';
import { ADRGP0402Component } from './ADWEB/ADRGP/ADRGP0402/ADRGP0402.component';
import { ADRGP0403Component } from './ADWEB/ADRGP/ADRGP0403/ADRGP0403.component';
import { ADRGP0405Component } from './ADWEB/ADRGP/ADRGP0405/ADRGP0405.component';
import { ADRGP0501Component } from './ADWEB/ADRGP/ADRGP0501/ADRGP0501.component';
import { ADRGP0502Component } from './ADWEB/ADRGP/ADRGP0502/ADRGP0502.component';
import { ADRGP0503Component } from './ADWEB/ADRGP/ADRGP0503/ADRGP0503.component';
import { ADRGP0504Component } from './ADWEB/ADRGP/ADRGP0504/ADRGP0504.component';
import { ADRGP0505Component } from './ADWEB/ADRGP/ADRGP0505/ADRGP0505.component';
import { ADRGP0507Component } from './ADWEB/ADRGP/ADRGP0507/ADRGP0507.component';
import { ADSTD0801Component } from './ADWEB/ADSTD/ADSTD0801/ADSTD0801.component';
import { ADSTD0802Component } from './ADWEB/ADSTD/ADSTD0802/ADSTD0802.component';
import { ADSTD0803Component } from './ADWEB/ADSTD/ADSTD0803/ADSTD0803.component';
import { ADSTD0804Component } from './ADWEB/ADSTD/ADSTD0804/ADSTD0804.component';
import { ADSTD0805Component } from './ADWEB/ADSTD/ADSTD0805/ADSTD0805.component';
import { ADSTD0806Component } from './ADWEB/ADSTD/ADSTD0806/ADSTD0806.component';
import { ADSTD0807Component } from './ADWEB/ADSTD/ADSTD0807/ADSTD0807.component';
import { ADSTD0808Component } from './ADWEB/ADSTD/ADSTD0808/ADSTD0808.component';
import { ADSTD0809Component } from './ADWEB/ADSTD/ADSTD0809/ADSTD0809.component';
import { ADSTD0810Component } from './ADWEB/ADSTD/ADSTD0810/ADSTD0810.component';
import { ADSTD0811Component } from './ADWEB/ADSTD/ADSTD0811/ADSTD0811.component';
import { ADSTD0812Component } from './ADWEB/ADSTD/ADSTD0812/ADSTD0812.component';
import { ADSTD0813Component } from './ADWEB/ADSTD/ADSTD0813/ADSTD0813.component';
import { ADSTD0814Component } from './ADWEB/ADSTD/ADSTD0814/ADSTD0814.component';
import { ADSTD0820Component } from './ADWEB/ADSTD/ADSTD0820/ADSTD0820.component';


import { ADSTD0811ISUComponent } from './ADWEB/ADSTD/ADSTD0811ISU/ADSTD0811ISU.component';
import { ADSTD0812ISUComponent } from './ADWEB/ADSTD/ADSTD0812ISU/ADSTD0812ISU.component';
import { ADSTD0813ISUComponent } from './ADWEB/ADSTD/ADSTD0813ISU/ADSTD0813ISU.component';
import { ADSTD0814ISUComponent } from './ADWEB/ADSTD/ADSTD0814ISU/ADSTD0814ISU.component';
import { ADSTD0816Component } from './ADWEB/ADSTD/ADSTD0816/ADSTD0816.component';
import { ADSTD0817Component } from './ADWEB/ADSTD/ADSTD0817/ADSTD0817.component';
import { ADSTD0818Component } from './ADWEB/ADSTD/ADSTD0818/ADSTD0818.component';
import { ADSYS0101Component } from './ADWEB/ADSYS/ADSYS0101/ADSYS0101.component';
import { ADSYS0102Component } from './ADWEB/ADSYS/ADSYS0102/ADSYS0102.component';
import { ADSYS0103Component } from './ADWEB/ADSYS/ADSYS0103/ADSYS0103.component';
import { ADSYS0302Component } from './ADWEB/ADSYS/ADSYS0302/ADSYS0302.component';
import { ADSYS0401Component } from './ADWEB/ADSYS/ADSYS0401/ADSYS0401.component';
import { ADSYS0501Component } from './ADWEB/ADSYS/ADSYS0501/ADSYS0501.component';

import { ADCUP0101Component } from './ADWEB/ADCUP/ADCUP0101/ADCUP0101.component';
import { ADCUP0102Component } from './ADWEB/ADCUP/ADCUP0102/ADCUP0102.component';
import { ADCUP0401Component } from './ADWEB/ADCUP/ADCUP0401/ADCUP0401.component';
import { ADCUP0499ISU01Component } from './ADWEB/ADCUP/ADCUP0499ISU01/ADCUP0499ISU01.component';
import { ADCUP0499ISU02Component } from './ADWEB/ADCUP/ADCUP0499ISU02/ADCUP0499ISU02.component';
import { ADCUP0103Component } from './ADWEB/ADCUP/ADCUP0103/ADCUP0103.component';
import { ADCUP0104Component } from './ADWEB/ADCUP/ADCUP0104/ADCUP0104.component';
import { ADCUP0201Component } from './ADWEB/ADCUP/ADCUP0201/ADCUP0201.component';
import { ADCUP0202Component } from './ADWEB/ADCUP/ADCUP0202/ADCUP0202.component';
import { ADCUP0301Component } from './ADWEB/ADCUP/ADCUP0301/ADCUP0301.component';
import { ADCUP0302Component } from './ADWEB/ADCUP/ADCUP0302/ADCUP0302.component';
import { ADCUP0303Component } from './ADWEB/ADCUP/ADCUP0303/ADCUP0303.component';
import { ADCUP0304Component } from './ADWEB/ADCUP/ADCUP0304/ADCUP0304.component';
import { ADCUP0402Component } from './ADWEB/ADCUP/ADCUP0402/ADCUP0402.component';
import { ADCUP0501Component } from './ADWEB/ADCUP/ADCUP0501/ADCUP0501.component';
import { ADCUP0502Component } from './ADWEB/ADCUP/ADCUP0502/ADCUP0502.component';
import { ADCUP0503Component } from './ADWEB/ADCUP/ADCUP0503/ADCUP0503.component';
import { ADCUP0504Component } from './ADWEB/ADCUP/ADCUP0504/ADCUP0504.component';
import { ADCUP0505Component } from './ADWEB/ADCUP/ADCUP0505/ADCUP0505.component';
import { ADCUP0506Component } from './ADWEB/ADCUP/ADCUP0506/ADCUP0506.component';
import { ADCUP0601Component } from './ADWEB/ADCUP/ADCUP0601/ADCUP0601.component';
import { ADCUP0602Component } from './ADWEB/ADCUP/ADCUP0602/ADCUP0602.component';
import { ADSYS0201Component } from './ADWEB/ADSYS/ADSYS0201/ADSYS0201.component';
import { ADCUP0305Component } from './ADWEB/ADCUP/ADCUP0305/ADCUP0305.component';
import { ADCUP0306Component } from './ADWEB/ADCUP/ADCUP0306/ADCUP0306.component';
import { ADCUP0603Component } from './ADWEB/ADCUP/ADCUP0603/ADCUP0603.component';
import { ADCUP0604Component } from './ADWEB/ADCUP/ADCUP0604/ADCUP0604.component';
import { ADCUR0201Component } from './ADWEB/ADCUR/ADCUR0201/ADCUR0201.component';
import { ADCUR0202Component } from './ADWEB/ADCUR/ADCUR0202/ADCUR0202.component';
import { ADCUR0202ISUComponent } from './ADWEB/ADCUR/ADCUR0202ISU/ADCUR0202ISU.component';
import { ADCUR0301Component } from './ADWEB/ADCUR/ADCUR0301/ADCUR0301.component';
import { ADCUR0302Component } from './ADWEB/ADCUR/ADCUR0302/ADCUR0302.component';
import { ADEMP0201Component } from './ADWEB/ADEMP/ADEMP0201/ADEMP0201.component';
import { ADEMP0202Component } from './ADWEB/ADEMP/ADEMP0202/ADEMP0202.component';
import { ADCUR0101Component } from './ADWEB/ADCUR/ADCUR0101/ADCUR0101.component';
import { ADCUR0102Component } from './ADWEB/ADCUR/ADCUR0102/ADCUR0102.component';
import { ADCUR0103Component } from './ADWEB/ADCUR/ADCUR0103/ADCUR0103.component';
import { ADCUR0104Component } from './ADWEB/ADCUR/ADCUR0104/ADCUR0104.component';
import { ADCUR0105Component } from './ADWEB/ADCUR/ADCUR0105/ADCUR0105.component';
import { ADCUR0401Component } from './ADWEB/ADCUR/ADCUR0401/ADCUR0401.component';
import { ADCUR0402Component } from './ADWEB/ADCUR/ADCUR0402/ADCUR0402.component';
import { ADCUR0403Component } from './ADWEB/ADCUR/ADCUR0403/ADCUR0403.component';
import { ADCUR0404Component } from './ADWEB/ADCUR/ADCUR0404/ADCUR0404.component';
import { ADCUR0405Component } from './ADWEB/ADCUR/ADCUR0405/ADCUR0405.component';
import { ADCUR0406Component } from './ADWEB/ADCUR/ADCUR0406/ADCUR0406.component';
import { ADCUR0501Component } from './ADWEB/ADCUR/ADCUR0501/ADCUR0501.component';
import { ADCUR0502Component } from './ADWEB/ADCUR/ADCUR0502/ADCUR0502.component';
import { ADCUR0503Component } from './ADWEB/ADCUR/ADCUR0503/ADCUR0503.component';
import { ADCUR0504Component } from './ADWEB/ADCUR/ADCUR0504/ADCUR0504.component';
import { ADCUR0505Component } from './ADWEB/ADCUR/ADCUR0505/ADCUR0505.component';
import { ADCUR0505ISUComponent } from './ADWEB/ADCUR/ADCUR0505ISU/ADCUR0505ISU.component';
import { ADCUR0506Component } from './ADWEB/ADCUR/ADCUR0506/ADCUR0506.component';
import { ADCUR0506ISUComponent } from './ADWEB/ADCUR/ADCUR0506ISU/ADCUR0506ISU.component';
import { ADCUR0507Component } from './ADWEB/ADCUR/ADCUR0507/ADCUR0507.component';
import { ADCUR0508Component } from './ADWEB/ADCUR/ADCUR0508/ADCUR0508.component';
import { ADCUR0509Component } from './ADWEB/ADCUR/ADCUR0509/ADCUR0509.component';
import { ADCUR0509ISUComponent } from './ADWEB/ADCUR/ADCUR0509ISU/ADCUR0509ISU.component';
import { ADCUR0601Component } from './ADWEB/ADCUR/ADCUR0601/ADCUR0601.component';
import { ADCUR0602Component } from './ADWEB/ADCUR/ADCUR0602/ADCUR0602.component';
import { ADCUR0603Component } from './ADWEB/ADCUR/ADCUR0603/ADCUR0603.component';
import { ADCUR0604Component } from './ADWEB/ADCUR/ADCUR0604/ADCUR0604.component';
import { ADSEL0102Component } from './ADWEB/ADSEL/ADSEL0102/ADSEL0102.component';
import { ADSEL0103Component } from './ADWEB/ADSEL/ADSEL0103/ADSEL0103.component';
import { ADSEL0104Component } from './ADWEB/ADSEL/ADSEL0104/ADSEL0104.component';
import { ADSEL0105Component } from './ADWEB/ADSEL/ADSEL0105/ADSEL0105.component';
import { ADSEL0106Component } from './ADWEB/ADSEL/ADSEL0106/ADSEL0106.component';
import { ADSEL0201Component } from './ADWEB/ADSEL/ADSEL0201/ADSEL0201.component';
import { STDW30009Component } from './STDWEB/STDW30009/STDW30009.component';
import { STDW30010Component } from './STDWEB/STDW30010/STDW30010.component';
import { ADSEL0202Component } from './ADWEB/ADSEL/ADSEL0202/ADSEL0202.component';
import { ADSEL0203Component } from './ADWEB/ADSEL/ADSEL0203/ADSEL0203.component';
import { ADSEL0204Component } from './ADWEB/ADSEL/ADSEL0204/ADSEL0204.component';
import { ADSEL0300ISU01Component } from './ADWEB/ADSEL/ADSEL0300ISU01/ADSEL0300ISU01.component';
import { ADSEL0303Component } from './ADWEB/ADSEL/ADSEL0303/ADSEL0303.component';
import { ADSEL0304Component } from './ADWEB/ADSEL/ADSEL0304/ADSEL0304.component';
import { ADSEL0305Component } from './ADWEB/ADSEL/ADSEL0305/ADSEL0305.component';
import { ADSEL0401Component } from './ADWEB/ADSEL/ADSEL0401/ADSEL0401.component';
import { ADSEL0402Component } from './ADWEB/ADSEL/ADSEL0402/ADSEL0402.component';
import { ADSEL0403Component } from './ADWEB/ADSEL/ADSEL0403/ADSEL0403.component';
import { ADSEL0301Component } from './ADWEB/ADSEL/ADSEL0301/ADSEL0301.component';
import { ADSEL0302Component } from './ADWEB/ADSEL/ADSEL0302/ADSEL0302.component';
import { ADSEL0501Component } from './ADWEB/ADSEL/ADSEL0501/ADSEL0501.component';
import { ADSEL0502Component } from './ADWEB/ADSEL/ADSEL0502/ADSEL0502.component';
import { ADSEL0504Component } from './ADWEB/ADSEL/ADSEL0504/ADSEL0504.component';
import { ADGRD0102Component } from './ADWEB/ADGRD/ADGRD0102/ADGRD0102.component';
import { ADGRD0103Component } from './ADWEB/ADGRD/ADGRD0103/ADGRD0103.component';
import { ADGRD0201Component } from './ADWEB/ADGRD/ADGRD0201/ADGRD0201.component';
import { ADGRD0202Component } from './ADWEB/ADGRD/ADGRD0202/ADGRD0202.component';
import { ADGRD0301Component } from './ADWEB/ADGRD/ADGRD0301/ADGRD0301.component';
import { ADGRD0302Component } from './ADWEB/ADGRD/ADGRD0302/ADGRD0302.component';
import { ADSYS0301Component } from './ADWEB/ADSYS/ADSYS0301/ADSYS0301.component';
import { ADGRD0303Component } from './ADWEB/ADGRD/ADGRD0303/ADGRD0303.component';
import { ADGRD0304Component } from './ADWEB/ADGRD/ADGRD0304/ADGRD0304.component';
import { ADGRD0305Component } from './ADWEB/ADGRD/ADGRD0305/ADGRD0305.component';
import { ADGRD0306Component } from './ADWEB/ADGRD/ADGRD0306/ADGRD0306.component';
import { ADGRD0307Component } from './ADWEB/ADGRD/ADGRD0307/ADGRD0307.component';
import { ADGRD0401Component } from './ADWEB/ADGRD/ADGRD0401/ADGRD0401.component';
import { ADGRD0402Component } from './ADWEB/ADGRD/ADGRD0402/ADGRD0402.component';
import { ADGRD0403Component } from './ADWEB/ADGRD/ADGRD0403/ADGRD0403.component';
import { ADGRD0404Component } from './ADWEB/ADGRD/ADGRD0404/ADGRD0404.component';
import { ADGRD0501Component } from './ADWEB/ADGRD/ADGRD0501/ADGRD0501.component';
import { ADGRD0502Component } from './ADWEB/ADGRD/ADGRD0502/ADGRD0502.component';
import { ADGRD0601Component } from './ADWEB/ADGRD/ADGRD0601/ADGRD0601.component';
import { ADGRD0602Component } from './ADWEB/ADGRD/ADGRD0602/ADGRD0602.component';
import { ADGRD0602ISUComponent } from './ADWEB/ADGRD/ADGRD0602ISU/ADGRD0602ISU.component';
import { ADGRD0604Component } from './ADWEB/ADGRD/ADGRD0604/ADGRD0604.component';
import { ADGRD0701Component } from './ADWEB/ADGRD/ADGRD0701/ADGRD0701.component';
import { ADGRD0702Component } from './ADWEB/ADGRD/ADGRD0702/ADGRD0702.component';
import { ADGRD0703Component } from './ADWEB/ADGRD/ADGRD0703/ADGRD0703.component';
import { ADABS0101Component } from './ADWEB/ADABS/ADABS0101/ADABS0101.component';
import { ADABS0102Component } from './ADWEB/ADABS/ADABS0102/ADABS0102.component';
import { ADABS0103Component } from './ADWEB/ADABS/ADABS0103/ADABS0103.component';
import { ADABS0104Component } from './ADWEB/ADABS/ADABS0104/ADABS0104.component';
import { ADABS0105Component } from './ADWEB/ADABS/ADABS0105/ADABS0105.component';
import { ADABS0201Component } from './ADWEB/ADABS/ADABS0201/ADABS0201.component';
import { ADABS0202Component } from './ADWEB/ADABS/ADABS0202/ADABS0202.component';
import { ADABS0203Component } from './ADWEB/ADABS/ADABS0203/ADABS0203.component';
import { ADABS0301Component } from './ADWEB/ADABS/ADABS0301/ADABS0301.component';
import { ADABS0302Component } from './ADWEB/ADABS/ADABS0302/ADABS0302.component';
import { ADABS0303Component } from './ADWEB/ADABS/ADABS0303/ADABS0303.component';
import { ADABS0304Component } from './ADWEB/ADABS/ADABS0304/ADABS0304.component';
import { ADABS0305Component } from './ADWEB/ADABS/ADABS0305/ADABS0305.component';
import { ADABS0306Component } from './ADWEB/ADABS/ADABS0306/ADABS0306.component';
import { ADABS0307Component } from './ADWEB/ADABS/ADABS0307/ADABS0307.component';
import { ADABS0308Component } from './ADWEB/ADABS/ADABS0308/ADABS0308.component';
import { ADABS0309Component } from './ADWEB/ADABS/ADABS0309/ADABS0309.component';


import { ADSRP0101Component } from './ADWEB/ADSRP/ADSRP0101/ADSRP0101.component';
import { ADSRP0102Component } from './ADWEB/ADSRP/ADSRP0102/ADSRP0102.component';
import { ADSRP0103Component } from './ADWEB/ADSRP/ADSRP0103/ADSRP0103.component';
import { ADSRP0201Component } from './ADWEB/ADSRP/ADSRP0201/ADSRP0201.component';
import { ADSRP0202Component } from './ADWEB/ADSRP/ADSRP0202/ADSRP0202.component';
import { ADSRP0202ISUComponent } from './ADWEB/ADSRP/ADSRP0202ISU/ADSRP0202ISU.component';
import { ADSRP0203Component } from './ADWEB/ADSRP/ADSRP0203/ADSRP0203.component';
import { ADSRP0204Component } from './ADWEB/ADSRP/ADSRP0204/ADSRP0204.component';
import { ADSRP0301Component } from './ADWEB/ADSRP/ADSRP0301/ADSRP0301.component';
import { ADSRP0301ISUComponent } from './ADWEB/ADSRP/ADSRP0301ISU/ADSRP0301ISU.component';
import { ADSRP0302Component } from './ADWEB/ADSRP/ADSRP0302/ADSRP0302.component';
import { ADSRP0303Component } from './ADWEB/ADSRP/ADSRP0303/ADSRP0303.component';
import { ADSRP0304Component } from './ADWEB/ADSRP/ADSRP0304/ADSRP0304.component';
import { ADSRP0305Component } from './ADWEB/ADSRP/ADSRP0305/ADSRP0305.component';
import { ADSRP0306Component } from './ADWEB/ADSRP/ADSRP0306/ADSRP0306.component';
import { ADSRP0307Component } from './ADWEB/ADSRP/ADSRP0307/ADSRP0307.component';
import { ADSRP0308Component } from './ADWEB/ADSRP/ADSRP0308/ADSRP0308.component';
import { ADSRP0309Component } from './ADWEB/ADSRP/ADSRP0309/ADSRP0309.component';

import { ADSAC0101Component } from './ADWEB/ADSAC/ADSAC0101/ADSAC0101.component';
import { ADSAC0102Component } from './ADWEB/ADSAC/ADSAC0102/ADSAC0102.component';
import { ADSAC0103Component } from './ADWEB/ADSAC/ADSAC0103/ADSAC0103.component';
import { ADSAC0201Component } from './ADWEB/ADSAC/ADSAC0201/ADSAC0201.component';
import { ADSAC0202Component } from './ADWEB/ADSAC/ADSAC0202/ADSAC0202.component';
import { ADSAC0203Component } from './ADWEB/ADSAC/ADSAC0203/ADSAC0203.component';
import { ADSAC0301Component } from './ADWEB/ADSAC/ADSAC0301/ADSAC0301.component';
import { ADSAC0302Component } from './ADWEB/ADSAC/ADSAC0302/ADSAC0302.component';
import { ADSAC0303Component } from './ADWEB/ADSAC/ADSAC0303/ADSAC0303.component';
import { ADSAC0304Component } from './ADWEB/ADSAC/ADSAC0304/ADSAC0304.component';

import { ADCOM0101Component } from './ADWEB/ADCOM/ADCOM0101/ADCOM0101.component';
import { ADSSS0101Component } from './ADWEB/ADSSS/ADSSS0101/ADSSS0101.component';
import { ADSSS0201Component } from './ADWEB/ADSSS/ADSSS0201/ADSSS0201.component';
import { ADSSS0202Component } from './ADWEB/ADSSS/ADSSS0202/ADSSS0202.component';
import { ADSSS0203Component } from './ADWEB/ADSSS/ADSSS0203/ADSSS0203.component';
import { ADSSS0204Component } from './ADWEB/ADSSS/ADSSS0204/ADSSS0204.component';
import { ADSSS0205Component } from './ADWEB/ADSSS/ADSSS0205/ADSSS0205.component';
import { ADSSS0206Component } from './ADWEB/ADSSS/ADSSS0206/ADSSS0206.component';
import { ADSSS0207Component } from './ADWEB/ADSSS/ADSSS0207/ADSSS0207.component';
import { ADSSS0208Component } from './ADWEB/ADSSS/ADSSS0208/ADSSS0208.component';
import { ADSSS0301Component } from './ADWEB/ADSSS/ADSSS0301/ADSSS0301.component';
import { ADEMP0101Component } from './ADWEB/ADEMP/ADEMP0101/ADEMP0101.component';
import { ADEMP0102Component } from './ADWEB/ADEMP/ADEMP0102/ADEMP0102.component';
import { ADEMP0103Component } from './ADWEB/ADEMP/ADEMP0103/ADEMP0103.component';
import { ADEMP0104Component } from './ADWEB/ADEMP/ADEMP0104/ADEMP0104.component';
import { ADEMP0301Component } from './ADWEB/ADEMP/ADEMP0301/ADEMP0301.component';
import { ADEMP0302Component } from './ADWEB/ADEMP/ADEMP0302/ADEMP0302.component';
import { ADEMP0303Component } from './ADWEB/ADEMP/ADEMP0303/ADEMP0303.component';


// 設定路由規則，有順序性，一旦找到符合的就不往下找。
const routes: Routes = [
    //設定預設路由
    { path: '', component: LoginComponent },
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
            { path: 'Home', component: HomeComponent },
            { path: 'Favourite', component: FavouriteComponent },
            { path: 'profile', component: ProfileComponent },

            { path: 'ADPUB0101', component: ADPUB0101Component },
            { path: 'ADPUB0102', component: ADPUB0102Component },
            { path: 'ADPUB0103', component: ADPUB0103Component },


            { path: 'STDW000_01', component: STDW00001Component },
            { path: 'STDW000_02', component: STDW00002Component },
            { path: 'STDW000_03', component: STDW00003Component },
            { path: 'STDW100_01', component: STDW10001Component },
            { path: 'STDW100_02', component: STDW10002Component },
            { path: 'STDW100_03', component: STDW10003Component },
            { path: 'STDW100_04', component: STDW10004Component },
            { path: 'STDW100_05', component: STDW10005Component },
            { path: 'STDW100_06', component: STDW10006Component },
            { path: 'STDW100_09', component: STDW10009Component },
            { path: 'STDW100_10', component: STDW10010Component },
            { path: 'STDW100_11', component: STDW10011Component },
            { path: 'STDW100_13ISU', component: STDW10013ISUComponent },
            { path: 'STDW200_01', component: STDW20001Component },
            { path: 'STDW200_02', component: STDW20002Component },
            { path: 'STDW200_03', component: STDW20003Component },
            { path: 'STDW200_04', component: STDW20004Component },
            { path: 'STDW200_0607/:MF', component: STDW2000607Component },
            { path: 'STDW300_01', component: STDW30001Component },
            { path: 'STDW300_02', component: STDW30002Component },
            { path: 'STDW300_03', component: STDW30003Component },
            { path: 'STDW300_04', component: STDW30004Component },
            { path: 'STDW300_05', component: STDW30005Component },
            { path: 'STDW300_06', component: STDW30006Component },
            { path: 'STDW300_07', component: STDW30007Component },
            { path: 'STDW300_08', component: STDW30008Component },
            { path: 'STDW300_09', component: STDW30009Component },
            { path: 'STDW300_10', component: STDW30010Component },
            { path: 'STDW400_01', component: STDW40001Component },
            { path: 'STDW400_02', component: STDW40002Component },
            { path: 'STDW400_03', component: STDW40003Component },
            { path: 'STDW400_04', component: STDW40004Component },
            { path: 'STDW400_05', component: STDW40005Component },
            { path: 'STDW400_06', component: STDW40006Component },
            { path: 'STDW500_01', component: STDW50001Component },
            { path: 'STDW500_02', component: STDW50002Component },
            { path: 'STDW500_03', component: STDW50003Component },
            { path: 'STDW500_04', component: STDW50004Component },
            { path: 'STDW600_01', component: STDW60001Component },
            { path: 'STDW600_02', component: STDW60002Component },
            { path: 'STDW600_03', component: STDW60003Component },
            { path: 'STDW700_01', component: STDW70001Component },
            { path: 'TCH01_01', component: TCH0101Component },
            { path: 'TCH01_01/:id', component: TCH0101DetailComponent },
            { path: 'TCH01_02', component: TCH0102Component },
            { path: 'TCH01_03', component: TCH0103Component },
            { path: 'TCH01_04', component: TCH0104Component },
            { path: 'TCH01_05', component: TCH0105Component },
            { path: 'TCH01_06', component: TCH0106Component },
            { path: 'TCH01_07', component: TCH0107Component },
            { path: 'TCH01_08', component: TCH0108Component },
            { path: 'TCH01_09', component: TCH0109Component },
            { path: 'TCH01_10', component: TCH0110Component },
            { path: 'TCH01_11', component: TCH0111Component },
            { path: 'TCH01_11/:id', component: TCH0111DetailComponent },
            { path: 'TCH01_12', component: TCH0112Component },
            { path: 'TCH01_13', component: TCH0113Component },
            { path: 'TCH01_14', component: TCH0114Component },
            { path: 'TCH01_15', component: TCH0115Component },
            { path: 'TCH01_16', component: TCH0116Component },
            { path: 'TCH01_17', component: TCH0117Component },
            { path: 'TCH01_18', component: TCH0118Component },
            { path: 'TCH02_01', component: TCH0201Component },
            { path: 'TCH02_02', component: TCH0202Component },
            { path: 'TCH02_03', component: TCH0203Component },
            { path: 'TCH02_04', component: TCH0204Component },
            { path: 'TCH03_01', component: TCH0301Component },
            { path: 'TCH03_02', component: TCH0302Component },
            { path: 'TCH03_03', component: TCH0303Component },
            { path: 'TCH03_04', component: TCH0304Component },
            { path: 'TCH03_05', component: TCH0305Component },
            { path: 'TCH03_06', component: TCH0306Component },
            { path: 'TCH03_07', component: TCH0307Component },
            { path: 'TCH03_08', component: TCH0308Component },
            { path: 'TCH03_09', component: TCH0309Component },
            { path: 'TCH03_10', component: TCH0310Component },
            { path: 'TCH04_01', component: TCH0401Component },
            { path: 'TCH04_02', component: TCH0402Component },

            { path: 'ADFunc/:SysId', component: ADFunctionListComponent },

            { path: 'ADSTD02_01', component: ADSTD0201Component },
            { path: 'ADSTD01_01', component: ADSTD0101Component },
            { path: 'ADSTD01_01ISU', component: ADSTD0101ISUComponent },
            { path: 'ADSTD01_02', component: ADSTD0102Component },
            { path: 'ADSTD01_03', component: ADSTD0103Component },
            { path: 'ADSTD01_04', component: ADSTD0104Component },
            { path: 'ADSTD01_05', component: ADSTD0203Component },
            { path: 'ADSTD01_06', component: ADSTD0106Component },
            { path: 'ADSTD02_02', component: ADSTD0202Component },
            { path: 'ADSTD02_03', component: ADSTD0203Component },
            { path: 'ADSTD02_04', component: ADSTD0204Component },
            { path: 'ADSTD02_05', component: ADSTD0205Component },
            { path: 'ADSTD02_06', component: ADSTD0206Component },
            { path: 'ADSTD02_07', component: ADSTD0207Component },
            { path: 'ADSTD03_01', component: ADSTD0301Component },
            { path: 'ADSTD03_02', component: ADSTD0302Component },
            { path: 'ADSTD03_03', component: ADSTD0303Component },
            { path: 'ADSTD03_04', component: ADSTD0304Component },
            { path: 'ADSTD03_05', component: ADSTD0305Component },
            { path: 'ADSTD04_01', component: ADSTD0401Component },
            { path: 'ADSTD04_01ISU', component: ADSTD0401ISU01Component },
            { path: 'ADSTD05_01', component: ADSTD0501Component },
            { path: 'ADSTD05_01ISU', component: ADSTD0501ISU01Component },


            { path: 'ADSTD06_01', component: ADSTD0601Component },
            { path: 'ADSTD06_01_ISU01', component: ADSTD0601ISU01Component },
            { path: 'ADSTD06_01_ISU02', component: ADSTD0601ISU02Component },
            { path: 'ADSTD06_01_ISU03', component: ADSTD0601ISU03Component },
            { path: 'ADSTD06_02', component: ADSTD0602Component },
            { path: 'ADSTD07_01', component: ADSTD0701Component },
            { path: 'ADSTD07_01_ISU', component: ADSTD0701ISUComponent },
            { path: 'ADSTD07_03', component: ADSTD0703Component },
            { path: 'ADSTD07_04', component: ADSTD0704Component },
            { path: 'ADSTD07_06', component: ADSTD0706Component },
            { path: 'ADSTD07_05', component: ADSTD0705Component },
            { path: 'ADSTD08_01', component: ADSTD0801Component },
            { path: 'ADSTD08_02', component: ADSTD0802Component },
            { path: 'ADSTD08_03', component: ADSTD0803Component },
            { path: 'ADSTD08_04', component: ADSTD0804Component },
            { path: 'ADSTD08_05', component: ADSTD0805Component },
            { path: 'ADSTD08_06', component: ADSTD0806Component },
            { path: 'ADSTD08_07', component: ADSTD0807Component },
            { path: 'ADSTD08_08', component: ADSTD0808Component },
            { path: 'ADSTD08_09', component: ADSTD0809Component },
            { path: 'ADSTD08_10', component: ADSTD0810Component },
            { path: 'ADSTD08_11', component: ADSTD0811Component },
            { path: 'ADSTD08_12', component: ADSTD0812Component },
            { path: 'ADSTD08_13', component: ADSTD0813Component },
            { path: 'ADSTD08_14', component: ADSTD0814Component },
            { path: 'ADSTD08_19', component: ADSTD0819Component },
            { path: 'ADSTD08_20', component: ADSTD0820Component },


            { path: 'ADSTD08_11ISU', component: ADSTD0811ISUComponent },
            { path: 'ADSTD08_12ISU', component: ADSTD0812ISUComponent },
            { path: 'ADSTD08_13ISU', component: ADSTD0813ISUComponent },
            { path: 'ADSTD08_14ISU', component: ADSTD0814ISUComponent },
            { path: 'ADSTD08_15', component: ADSTD0815Component },
            { path: 'ADSTD08_16', component: ADSTD0816Component },
            { path: 'ADSTD08_17', component: ADSTD0817Component },
            { path: 'ADSTD08_18', component: ADSTD0818Component },
            { path: 'ADTFS01A03', component: ADTFS01A03Component },
            { path: 'ADTFS01A04', component: ADTFS01A04Component },
            { path: 'ADTFS01B03', component: ADTFS01B03Component },
            { path: 'ADTFS01B02', component: ADTFS01B02Component },
            { path: 'ADTFS01B01', component: ADTFS01B01Component },
            { path: 'ADTFS01B04', component: ADTFS01B04Component },
            { path: 'ADTFS01C01', component: ADTFS01C01Component },
            { path: 'ADTFS01D01', component: ADTFS01D01Component },
            { path: 'ADTFS01D02', component: ADTFS01D02Component },
            { path: 'ADTFS01D03', component: ADTFS01D03Component },
            { path: 'ADTFS01E01', component: ADTFS01E01Component },
            { path: 'ADTFS01E02', component: ADTFS01E02Component },
            { path: 'ADTFS01E03', component: ADTFS01E03Component },
            { path: 'ADTFS0301', component: ADTFS0301Component },
            { path: 'ADTFS0302', component: ADTFS0302Component },
            { path: 'ADTFS0303', component: ADTFS0303Component },
            { path: 'ADTFS0401', component: ADTFS0401Component },
            { path: 'ADTFS0501', component: ADTFS0501Component },
            { path: 'ADTFS0502', component: ADTFS0502Component },
            { path: 'ADTFS0503', component: ADTFS0503Component },
            { path: 'ADTFS0601', component: ADTFS0601Component },
            { path: 'ADTFS0602', component: ADTFS0602Component },
            { path: 'ADTFS0603', component: ADTFS0603Component },
            { path: 'ADTFS0701', component: ADTFS0701Component },
            { path: 'ADTFS0702', component: ADTFS0702Component },

            { path: 'ADASM0101', component: ADASM0101Component },
            { path: 'ADASM0102', component: ADASM0102Component },
            { path: 'ADASM0103', component: ADASM0103Component },
            { path: 'ADASM0104', component: ADASM0104Component },
            { path: 'ADASM0105', component: ADASM0105Component },


            { path: 'ADRGP0101', component: ADRGP0101Component },
            { path: 'ADRGP0102', component: ADRGP0102Component },
            { path: 'ADRGP0103', component: ADRGP0103Component },
            { path: 'ADRGP0104', component: ADRGP0104Component },
            { path: 'ADRGP0105', component: ADRGP0105Component },
            { path: 'ADRGP0106', component: ADRGP0106Component },
            { path: 'ADRGP0107', component: ADRGP0107Component },
            { path: 'ADRGP0108', component: ADRGP0108Component },
            { path: 'ADRGP0201', component: ADRGP0201Component },
            { path: 'ADRGP0202', component: ADRGP0202Component },
            { path: 'ADRGP0203', component: ADRGP0203Component },
            { path: 'ADRGP0302', component: ADRGP0302Component },
            { path: 'ADRGP0401', component: ADRGP0401Component },
            { path: 'ADRGP0402', component: ADRGP0402Component },
            { path: 'ADRGP0403', component: ADRGP0403Component },
            { path: 'ADRGP0404', component: ADRGP0405Component },
            { path: 'ADRGP0501', component: ADRGP0501Component },
            { path: 'ADRGP0502', component: ADRGP0502Component },
            { path: 'ADRGP0503', component: ADRGP0503Component },
            { path: 'ADRGP0504', component: ADRGP0504Component },
            { path: 'ADRGP0505', component: ADRGP0505Component },
            { path: 'ADRGP0507', component: ADRGP0507Component },

            { path: 'ADSYS0101', component: ADSYS0101Component },
            { path: 'ADSYS0102', component: ADSYS0102Component },
            { path: 'ADSYS0103', component: ADSYS0103Component },
            { path: 'ADSYS0201', component: ADSYS0201Component },
            { path: 'ADSYS0301', component: ADSYS0301Component },
            { path: 'ADSYS0302', component: ADSYS0302Component },
            { path: 'ADSYS0401', component: ADSYS0401Component },
            { path: 'ADSYS0501', component: ADSYS0501Component },

            { path: 'ADCUP0101', component: ADCUP0101Component },
            { path: 'ADCUP0102', component: ADCUP0102Component },
            { path: 'ADCUP0103', component: ADCUP0103Component },
            { path: 'ADCUP0104', component: ADCUP0104Component },
            { path: 'ADCUP0201', component: ADCUP0201Component },
            { path: 'ADCUP0202', component: ADCUP0202Component },
            { path: 'ADCUP0301', component: ADCUP0301Component },
            { path: 'ADCUP0302', component: ADCUP0302Component },
            { path: 'ADCUP0303', component: ADCUP0303Component },
            { path: 'ADCUP0304', component: ADCUP0304Component },
            { path: 'ADCUP0305', component: ADCUP0305Component },
            { path: 'ADCUP0306', component: ADCUP0306Component },
            { path: 'ADCUP0401', component: ADCUP0401Component },
            { path: 'ADCUP0499ISU01', component: ADCUP0499ISU01Component },
            { path: 'ADCUP0499ISU02', component: ADCUP0499ISU02Component },
            { path: 'ADCUP0402', component: ADCUP0402Component },
            { path: 'ADCUP0501', component: ADCUP0501Component },
            { path: 'ADCUP0502', component: ADCUP0502Component },
            { path: 'ADCUP0503', component: ADCUP0503Component },
            { path: 'ADCUP0504', component: ADCUP0504Component },
            { path: 'ADCUP0505', component: ADCUP0505Component },
            { path: 'ADCUP0506', component: ADCUP0506Component },
            { path: 'ADCUP0601', component: ADCUP0601Component },
            { path: 'ADCUP0602', component: ADCUP0602Component },
            { path: 'ADCUP0603', component: ADCUP0603Component },
            { path: 'ADCUP0604', component: ADCUP0604Component },



            { path: 'ADCUR0101', component: ADCUR0101Component },
            { path: 'ADCUR0102', component: ADCUR0102Component },
            { path: 'ADCUR0103', component: ADCUR0103Component },
            { path: 'ADCUR0104', component: ADCUR0104Component },
            { path: 'ADCUR0105', component: ADCUR0105Component },
            { path: 'ADCUR0201', component: ADCUR0201Component },
            { path: 'ADCUR0202', component: ADCUR0202Component },
            { path: 'ADCUR0202ISU', component: ADCUR0202ISUComponent },
            { path: 'ADCUR0301', component: ADCUR0301Component },
            { path: 'ADCUR0302', component: ADCUR0302Component },
            { path: 'ADCUR0401', component: ADCUR0401Component },
            { path: 'ADCUR0402', component: ADCUR0402Component },
            { path: 'ADCUR0403', component: ADCUR0403Component },
            { path: 'ADCUR0404', component: ADCUR0404Component },
            { path: 'ADCUR0405', component: ADCUR0405Component },
            { path: 'ADCUR0406', component: ADCUR0406Component },
            { path: 'ADCUR0501', component: ADCUR0501Component },
            { path: 'ADCUR0502', component: ADCUR0502Component },
            { path: 'ADCUR0503', component: ADCUR0503Component },
            { path: 'ADCUR0504', component: ADCUR0504Component },
            { path: 'ADCUR0505', component: ADCUR0505Component },
            { path: 'ADCUR0505ISU', component: ADCUR0505ISUComponent },
            { path: 'ADCUR0506', component: ADCUR0506Component },
            { path: 'ADCUR0506ISU', component: ADCUR0506ISUComponent },
            { path: 'ADCUR0507', component: ADCUR0507Component },
            { path: 'ADCUR0508', component: ADCUR0508Component },
            { path: 'ADCUR0509', component: ADCUR0509Component },
            { path: 'ADCUR0509ISU', component: ADCUR0509ISUComponent },
            { path: 'ADCUR0601', component: ADCUR0601Component },
            { path: 'ADCUR0602', component: ADCUR0602Component },
            { path: 'ADCUR0603', component: ADCUR0603Component },
            { path: 'ADCUR0604', component: ADCUR0604Component },


            { path: 'ADEMP0101', component: ADEMP0101Component },
            { path: 'ADEMP0102', component: ADEMP0102Component },
            { path: 'ADEMP0103', component: ADEMP0103Component },
            { path: 'ADEMP0104', component: ADEMP0104Component },
            { path: 'ADEMP0201', component: ADEMP0201Component },
            { path: 'ADEMP0202', component: ADEMP0202Component },
            { path: 'ADEMP0301', component: ADEMP0301Component },
            { path: 'ADEMP0302', component: ADEMP0302Component },
            { path: 'ADEMP0303', component: ADEMP0303Component },

            { path: 'ADSEL0102', component: ADSEL0102Component },
            { path: 'ADSEL0103', component: ADSEL0103Component },
            { path: 'ADSEL0104', component: ADSEL0104Component },
            { path: 'ADSEL0105', component: ADSEL0105Component },
            { path: 'ADSEL0106', component: ADSEL0106Component },
            { path: 'ADSEL0201', component: ADSEL0201Component },
            { path: 'ADSEL0202', component: ADSEL0202Component },
            { path: 'ADSEL0203', component: ADSEL0203Component },
            { path: 'ADSEL0204', component: ADSEL0204Component },
            { path: 'ADSEL0300ISU01', component: ADSEL0300ISU01Component },
            { path: 'ADSEL0301', component: ADSEL0301Component },
            { path: 'ADSEL0302', component: ADSEL0302Component },
            { path: 'ADSEL0303', component: ADSEL0303Component },
            { path: 'ADSEL0304', component: ADSEL0304Component },
            { path: 'ADSEL0305', component: ADSEL0305Component },
            { path: 'ADSEL0401', component: ADSEL0401Component },
            { path: 'ADSEL0402', component: ADSEL0402Component },
            { path: 'ADSEL0403', component: ADSEL0403Component },
            { path: 'ADSEL0501', component: ADSEL0501Component },
            { path: 'ADSEL0502', component: ADSEL0502Component },
            { path: 'ADSEL0504', component: ADSEL0504Component },

            { path: 'ADGRD0102', component: ADGRD0102Component },
            { path: 'ADGRD0103', component: ADGRD0103Component },
            { path: 'ADGRD0201', component: ADGRD0201Component },
            { path: 'ADGRD0202', component: ADGRD0202Component },
            { path: 'ADGRD0301', component: ADGRD0301Component },
            { path: 'ADGRD0302', component: ADGRD0302Component },
            { path: 'ADGRD0303', component: ADGRD0303Component },
            { path: 'ADGRD0304', component: ADGRD0304Component },
            { path: 'ADGRD0305', component: ADGRD0305Component },
            { path: 'ADGRD0306', component: ADGRD0306Component },
            { path: 'ADGRD0307', component: ADGRD0307Component },
            { path: 'ADGRD0401', component: ADGRD0401Component },
            { path: 'ADGRD0402', component: ADGRD0402Component },
            { path: 'ADGRD0403', component: ADGRD0403Component },
            { path: 'ADGRD0404', component: ADGRD0404Component },
            { path: 'ADGRD0501', component: ADGRD0501Component },
            { path: 'ADGRD0502', component: ADGRD0502Component },
            { path: 'ADGRD0601', component: ADGRD0601Component },
            { path: 'ADGRD0602', component: ADGRD0602Component },
            { path: 'ADGRD0602ISU', component: ADGRD0602ISUComponent },
            { path: 'ADGRD0604', component: ADGRD0604Component },
            { path: 'ADGRD0701', component: ADGRD0701Component },
            { path: 'ADGRD0702', component: ADGRD0702Component },
            { path: 'ADGRD0703', component: ADGRD0703Component },

            { path: 'ADABS0101', component: ADABS0101Component },
            { path: 'ADABS0102', component: ADABS0102Component },
            { path: 'ADABS0103', component: ADABS0103Component },
            { path: 'ADABS0104', component: ADABS0104Component },
            { path: 'ADABS0105', component: ADABS0105Component },
            { path: 'ADABS0201', component: ADABS0201Component },
            { path: 'ADABS0202', component: ADABS0202Component },
            { path: 'ADABS0203', component: ADABS0203Component },
            { path: 'ADABS0301', component: ADABS0301Component },
            { path: 'ADABS0302', component: ADABS0302Component },
            { path: 'ADABS0303', component: ADABS0303Component },
            { path: 'ADABS0304', component: ADABS0304Component },
            { path: 'ADABS0305', component: ADABS0305Component },
            { path: 'ADABS0306', component: ADABS0306Component },
            { path: 'ADABS0307', component: ADABS0307Component },
            { path: 'ADABS0308', component: ADABS0308Component },
            { path: 'ADABS0309', component: ADABS0309Component },

            { path: 'ADSRP0101', component: ADSRP0101Component },
            { path: 'ADSRP0102', component: ADSRP0102Component },
            { path: 'ADSRP0103', component: ADSRP0103Component },
            { path: 'ADSRP0201', component: ADSRP0201Component },
            { path: 'ADSRP0202', component: ADSRP0202Component },
            { path: 'ADSRP0202ISU', component: ADSRP0202ISUComponent },
            { path: 'ADSRP0203', component: ADSRP0203Component },
            { path: 'ADSRP0204', component: ADSRP0204Component },
            { path: 'ADSRP0301', component: ADSRP0301Component },
            { path: 'ADSRP0301ISU', component: ADSRP0301ISUComponent },
            { path: 'ADSRP0302', component: ADSRP0302Component },
            { path: 'ADSRP0303', component: ADSRP0303Component },
            { path: 'ADSRP0304', component: ADSRP0304Component },
            { path: 'ADSRP0305', component: ADSRP0305Component },
            { path: 'ADSRP0306', component: ADSRP0306Component },
            { path: 'ADSRP0307', component: ADSRP0307Component },
            { path: 'ADSRP0308', component: ADSRP0308Component },
            { path: 'ADSRP0309', component: ADSRP0309Component },

            { path: 'ADSAC0101', component: ADSAC0101Component },
            { path: 'ADSAC0102', component: ADSAC0102Component },
            { path: 'ADSAC0103', component: ADSAC0103Component },
            { path: 'ADSAC0201', component: ADSAC0201Component },
            { path: 'ADSAC0202', component: ADSAC0202Component },
            { path: 'ADSAC0203', component: ADSAC0203Component },
            { path: 'ADSAC0301', component: ADSAC0301Component },
            { path: 'ADSAC0302', component: ADSAC0302Component },
            { path: 'ADSAC0303', component: ADSAC0303Component },
            { path: 'ADSAC0304', component: ADSAC0304Component },


            { path: 'ADCOM0101', component: ADCOM0101Component },

            { path: 'ADSSS0101', component: ADSSS0101Component },
            { path: 'ADSSS0201', component: ADSSS0201Component },
            { path: 'ADSSS0202', component: ADSSS0202Component },
            { path: 'ADSSS0203', component: ADSSS0203Component },
            { path: 'ADSSS0204', component: ADSSS0204Component },
            { path: 'ADSSS0205', component: ADSSS0205Component },
            { path: 'ADSSS0206', component: ADSSS0206Component },
            { path: 'ADSSS0207', component: ADSSS0207Component },
            { path: 'ADSSS0208', component: ADSSS0208Component },
            { path: 'ADSSS0301', component: ADSSS0301Component },
        ]
    },

    { path: 'Page404', component: Page404Component },
    { path: 'Page500', component: Page500Component },
    // 萬用路由，當以上都找不到時，則觸發此路由。
    { path: '**', component: Page404Component }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        //啟用路由追蹤功能
        //enableTracing: true,
        //預先載入功能，用於延遲載入時避免畫面卡頓。
        preloadingStrategy: PreloadAllModules,
        // useHash : true
    })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
