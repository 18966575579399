import { Component, OnInit } from '@angular/core';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-ADTFS0303',
  templateUrl: './ADTFS0303.component.html',
  styleUrls: ['./ADTFS0303.component.css']
})
export class ADTFS0303Component implements OnInit {
  baseUrl = environment.apiUrl;
  locales = listLocales();
  bsConfig = environment.bsDatePickerConfig;

  constructor(private localeService: BsLocaleService) { }

  ngOnInit(): void {
    this.localeService.use('zh-cn');
  }

}
