import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetGRDGradScoreOrderList } from 'src/app/_Models/AD/ADGRD/ADGRD0304/getGRDGradScoreOrderList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADGRD0304',
    templateUrl: './ADGRD0304.component.html',
    styleUrls: ['./ADGRD0304.component.css']
})
export class ADGRD0304Component implements OnInit {

    term = require('src/app/_Models/_SelectInput/term.json').term;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    Years!: Array<string>;
    initialData = new InitialData(this.http);
    eduList?: GetEduList[];
    deptList?: GetDeptList[];
    classList?: GetClassList[];
    fileExchange = new FileExchange();
    ngFormInput = new UntypedFormGroup({
        Years: new UntypedFormControl('', Validators.required),
        Term: new UntypedFormControl('', Validators.required),
        EduNo: new UntypedFormControl(''),
        DeptNo: new UntypedFormControl(''),
        Class: new UntypedFormControl(''),
        Grade: new UntypedFormControl(''),
        StdNo: new UntypedFormControl(''),
        Points: new UntypedFormControl(0, [Validators.max(4), Validators.min(0)]),
        Orderby: new UntypedFormControl('1', Validators.required),
        ShowOrder: new UntypedFormControl(0, [Validators.min(0)]),
        ShowPercent: new UntypedFormControl(0, [Validators.max(100), Validators.min(0)]),
    });
    gRDGradScoreOrderList?: GetGRDGradScoreOrderList[];
    baseUrl = environment.apiUrl;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private systemService: SystemService,
        private fileExchangeServices: FileExchangeService,) { }

    ngOnInit(): void {
        this.getYears();
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }


    getEduList(Years: string, Term: string) {
        this.systemService.getEduList('1', Years, Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.eduList = undefined;
            this.deptList = undefined;
            this.classList = undefined;
            this.eduList = response;
        })
    }

    getDeptList(edu: string, Years: string, Term: string) {
        this.systemService.getDeptList(edu, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.deptList = undefined;
            this.classList = undefined;
            this.deptList = response;

        });
    }

    getClassList(edu: string, dept: string, Years: string, Term: string) {
        this.systemService.getClassList(edu, dept, '1', Years, Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.classList = undefined;
            this.classList = response;
        })
    }

    getGRDGradScoreOrderList() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0304/Get_GRD_GradScoreOrder_List", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.info(response.Message);
                return;
            }
            this.gRDGradScoreOrderList = response;
        })
    }

    getGRDGradScoreOrderCompute() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0304/Get_GRD_GradScoreOrder_Compute", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.info(response.Message);
                return;
            }

            this.toastr.success("計算完成");
        })
    }

    getGRDGradScoreOrderConfirm() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/GRD/ADGRD0304/Get_GRD_GradScoreOrder_Confirm", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.info(response.Message);
                return;
            }

            this.toastr.success("確認資料完成");
        })
    }

    exportReportADGRD0304() {

        if (!this.ngFormInput.valid) {
            this.toastr.info("請選擇欄位");
            return;
        }

        this.fileExchangeServices.ExportFile("ACAD/GRD/ADGRD0304/Export_Report_ADGRD0304", this.ngFormInput.value).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }

            if (response.FileName == '') {
                this.toastr.warning("查無資料");
                return;
            }
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

}
