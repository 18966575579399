import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GetSelLog } from 'src/app/_Models/STDWEB/STDW20003/getSelLog';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TimeService } from 'src/app/_Services/_Shared/time.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-STDW20003',
    templateUrl: './STDW20003.component.html',
    styleUrls: ['./STDW20003.component.css']
})
export class STDW20003Component implements OnInit {

    baseUrl = environment.apiUrl;
    model = {
        Years: '',
        Term: '',
        Desire: ''
    }
    data!: GetSelLog[];
    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService, private timeService: TimeService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
        })
    }


    setModel(model: any) {
        this.model.Years = model.Years;
        this.model.Term = model.Term;
    }

    getSelLog() {
        if (this.model.Desire == '') {
            this.toastr.info("請選擇階段");
            return;
        }

        this.http.post<any>(this.baseUrl + "ACAD/STDWEB/STDW200_03/List_SelLog", this.model).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.data = []
                return;
            }
            this.data = response;
        })
    }

}
