<div class="container">
    <form [formGroup]="searchForm" (ngSubmit)="getTchLeaveList()">
        <div class="row">
            <div class="col-md-6">
                <app-year-term-input (childEvent)="setModel($event)"></app-year-term-input>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'假別'|translate}}</span>
                    </div>
                    <select class="form-control shadow-none" formControlName="H_Code">
                        <option value="" selected>{{'--全部--'|translate}}</option>
                        <option *ngFor="let item of holidayList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <button class="search-btn">{{'查詢'|translate}}</button>
    </form>

    <ng-container *ngIf="tchLeaveListData">
        <table class="table table-hover mt-3 text-center table-striped table-rwd">
            <thead class="table-rwd-hide thead-dark-red">
                <tr>
                    <th>{{'學年'|translate}}</th>
                    <th>{{'學期'|translate}}</th>
                    <th>{{'班級'|translate}}</th>
                    <th>{{'學號'|translate}}</th>
                    <th>{{'姓名'|translate}}</th>
                    <th>{{'假別'|translate}}</th>
                    <th>{{'請假日期'|translate}}</th>
                    <th>{{'請假事由'|translate}}</th>
                    <th>{{'操作'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of tchLeaveListData">
                    <td data-th="學年">{{item.Years}}</td>
                    <td data-th="學期">{{item.Term}}</td>
                    <td data-th="班級">{{item.Class_Name}}</td>
                    <td data-th="學號">{{item.Std_No}}</td>
                    <td data-th="姓名">{{item.Std_Name}}</td>
                    <td data-th="假別">{{item.H_Name}}</td>
                    <td data-th="請假日期">{{item.RecDate}}</td>
                    <td data-th="請假事由">{{item.Memo || ''}}</td>
                    <td data-th="操作"><a class="pointer text-decoration-none" (click)="getTchLeaveContent(item.DataKey, tchLeaveContent)"><span class="icon-Online-appointment-review"></span>審核</a></td>
                    <td data-th="" class="td-rwd-hide"></td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>

<ng-template #tchLeaveContent>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'學生假單審核'|translate}}</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close" (click)="tchLeaveContentModalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學年學期'|translate}}</span>
                    </div>
                    <span class="form-control shadow-none">{{tchLeaveContentData.Years}}{{'學年第'|translate}}{{tchLeaveContentData.Term}}{{'學期'|translate}}</span>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'學號姓名'|translate}}</span>
                    </div>
                    <span class="form-control shadow-none">{{tchLeaveContentData.Std_Name}}</span>

                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'請假日期'|translate}}</span>
                    </div>
                    <span class="form-control shadow-none">{{tchLeaveContentData.RecDate}}</span>

                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'請假類別'|translate}}</span>
                    </div>
                    <span class="form-control shadow-none">{{tchLeaveContentData.H_Name}}</span>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'請假節次'|translate}}</span>
                    </div>
                    <span class="form-control shadow-none">{{tchLeaveContentData.Cur_Time}}</span>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'請假事由'|translate}}</span>
                    </div>
                    <span class="form-control shadow-none">{{tchLeaveContentData.Memo || ''}}</span>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3" *ngIf="tchLeaveContentData.FileKey">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'證明文件'|translate}}</span>
                    </div>
                    <button class="btn btn-outline-info">{{'下載證明文件'|translate}}</button>
                </div>
            </div>
            <div class="col-lg-3"></div>
        </div>

        <div class="row">
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'審核結果'|translate}}</span>
                    </div>
                    <span class="form-control shadow-none"><input type="radio" name="result" #option><span class="pe-3">{{'通過'|translate}}</span><input type="radio" name="result"><span>{{'不通過'|translate}}</span></span>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{'簽核意見'|translate}}</span>
                    </div>
                    <input type="text" class="form-control shadow-none" #singMemo>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group flex-nowrap mb-3">
                    <button class="send-btn" (click)="sign(singMemo.value, option.checked)">{{'送出審核'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
