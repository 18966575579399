import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GetSYSUserFunDeptList } from 'src/app/_Models/AD/ADSYS/ADSYS0201/getSYSUserFunDeptList';
import { GetSYSUserList } from 'src/app/_Models/AD/ADSYS/ADSYS0201/getSYSUserList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';

@Component({
    selector: 'app-ADSYS0201',
    templateUrl: './ADSYS0201.component.html',
    styleUrls: ['./ADSYS0201.component.css']
})
export class ADSYS0201Component implements OnInit {
    baseUrl = environment.apiUrl;
    unitData!: GetSelectList[];
    sYSUserList!: GetSYSUserList[];
    allSysFuncList!: GetSelectList[];
    sYSUserFunDeptList: GetSYSUserFunDeptList = {
        DataKey: '',
        UserCode: '',
        SysID: '',
        FunList: [],
        DeptList: []
    };

    funcList: Array<any> = [];
    deptList: Array<any> = [];
    dataKeyArray: Array<string> = [];
    SysID = '';
    UserCode = '';
    UserCodeDataKey = '';

    constructor(private http: HttpClient, private toastr: ToastrService, private systemService: SystemService) { }

    ngOnInit(): void {
        this.getUnitList();
        this.getAllSysFunc();
    }

    condition: any = {
        UserCode: '',
        UserName: '',
        Account: '',
        UnitNo: ''
    };

    getUnitList() {
        this.systemService.getUnitList('', '', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.unitData = response;
        });
    }

    getAllSysFunc() {
        this.systemService.getAllSysFunc().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.allSysFuncList = response;
        });
    }

    setAll(isFunc: boolean, event: any) {
        if (isFunc) {
            if (event.checked) {
                this.sYSUserFunDeptList.FunList.forEach(element => {
                    element.Checked = true;
                });
                return;
            }
            this.sYSUserFunDeptList.FunList.forEach(element => {
                element.Checked = false;
            });
        }
        else {
            if (event.checked) {
                this.sYSUserFunDeptList.DeptList.forEach(element => {
                    element.Checked = true;
                });
                return;
            }
            this.sYSUserFunDeptList.DeptList.forEach(element => {
                element.Checked = false;
            });
        }
    }

    getSYSUserList() {
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0201/Get_SYS_UserList", this.condition).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.sYSUserList = []
                return;
            }
            this.sYSUserList = response;
        });
    }

    getSYSUserFunDeptList(DataKey: string, UserCode: string, Checked: boolean) {

        if (UserCode == '') {
            return;
        }

        // console.log({
        //   UserCode: UserCode,
        //   SysID: SysID,
        //   Checked: Checked
        // })

        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0201/Get_SYS_UserFunDeptList", {
            DataKey: DataKey,
            UserCode: UserCode,
            SysID: this.SysID,
            Checked: Checked
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sYSUserFunDeptList = response;
            this.UserCode = UserCode;
            this.UserCodeDataKey = DataKey;
        });
    }

    getSYSUserFunDeptSave() {
        console.log(this.sYSUserFunDeptList);
        this.http.post<any>(this.baseUrl + "ACAD/SYS/ADSYS0201/Get_SYS_UserFunDeptSave", this.sYSUserFunDeptList).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
        });
    }
}
